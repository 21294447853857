import React, { useMemo } from 'react'
import {
  badgeSignalAccuracy,
  calculateBadgeSignalStatus,
  getCmmsManagedDisplayText,
} from '../../../../helpers'
import { getLocationBasedBatteryStatus } from '../../../../helpers/battery'
import {
  formatLocalDateTimeByUserPreference,
  localMomentFormatted,
  momentUTCFormatted,
} from '../../../../helpers/date'
import { useFetchUserPreferences } from '../../../../hooks'
import { Manufacturer, SignalType, TelemetryAsset } from '../../../../models'
import { DetailedItem } from '../../../../models/DetailPaneItems'
import { DetailDrawerAgentTelemetry } from '../../../../models/telemetry'
import DetailsList from '../../../DetailsList/DetailsList'

interface Props {
  data: DetailDrawerAgentTelemetry<TelemetryAsset>
  manufacturers: Manufacturer[]
}

function AssetDetailPane(props: Props): React.ReactElement {
  const { data, manufacturers } = props
  const { agent, telemetry, resolvedLocationHierachy } = data

  const { data: userPreferences } = useFetchUserPreferences()

  const identificationItems = useMemo(() => {
    const manufacturer =
      agent.manufacturerGuid && manufacturers.length > 0
        ? manufacturers.find(
            (x) => x.manufacturerGuid === agent.manufacturerGuid
          )
        : undefined
    return !!agent
      ? [
          {
            field: 'Name',
            value: agent.name,
          },
          {
            field: 'Display Name',
            value: agent.displayName,
          },
          {
            field: 'Asset ID',
            value: agent.externalUniqueId,
          },
          {
            field: 'Asset Type',
            value: agent.assetTypeName,
          },
          {
            field: 'Asset Sub-Type',
            value: agent.assetSubTypeName,
          },
          {
            field: 'Manufacturer',
            value: !!manufacturer ? manufacturer.name : '',
          },
          {
            field: 'Serial Number',
            value: agent.serialNumber,
          },
          {
            field: 'Model',
            value: agent.model,
          },
          {
            field: 'Owner',
            value: agent.owner,
          },
          {
            field: 'CMMS Managed',
            value: getCmmsManagedDisplayText(agent.cmmsManaged),
          },
          {
            // Do not convert the PM Date to local time, as this can cause the date to be brought back a day
            // or push it to the next date and will not have the date that was entered on import/creation.
            field: 'Next PM Date',
            value: agent?.preventativeMaintenanceDate
              ? momentUTCFormatted(
                  agent.preventativeMaintenanceDate,
                  'MM/DD/YYYY'
                )
              : 'None',
          },
          {
            field: 'Assigned To',
            value: agent?.unitName,
          },
          {
            field: 'RTLS ID',
            value: telemetry?.trackingId,
          },
        ]
      : []
  }, [agent, manufacturers, telemetry])

  const locationItems = useMemo(() => {
    let locationAccuracy = 'Unknown'
    let signalStatus = 'Unknown'
    let lastDetected = telemetry?.timestamp
    if (telemetry && telemetry.signalTypeId) {
      locationAccuracy = badgeSignalAccuracy(telemetry.signalTypeId)
      signalStatus = calculateBadgeSignalStatus(telemetry.timestamp)
      lastDetected = telemetry.timestamp
    }
    return [
      {
        field: 'Building Group',
        value: resolvedLocationHierachy?.buildingGroupLocation.name,
      },
      {
        field: 'Building',
        value: resolvedLocationHierachy?.buildingLocation.name,
      },
      {
        field: 'Floor',
        value: resolvedLocationHierachy?.floorLocation.name,
      },
      {
        field: 'Room',
        value: resolvedLocationHierachy?.roomLocation.name,
      },
      {
        field: 'Unit',
        value: resolvedLocationHierachy?.locationUnitName ?? '',
      },
      {
        field: 'Sub-Unit',
        value: resolvedLocationHierachy?.locationSubUnitName ?? '',
      },
      {
        field: 'Location Accuracy',
        value: locationAccuracy ?? '',
      },
      {
        field: 'Last Detected',
        value: formatLocalDateTimeByUserPreference(
          lastDetected,
          userPreferences.timeDisplayId
        ),
      },
      {
        field: 'Signal Status',
        value: signalStatus ?? '',
      },
      {
        field: 'Signal Type',
        value: telemetry ? SignalType[telemetry.signalTypeId] : '',
      },
      {
        field: 'Battery Status',
        value: getLocationBasedBatteryStatus(
          resolvedLocationHierachy?.roomLocation,
          telemetry.isLowBattery
        ),
      },
      {
        field: 'Battery Change Date',
        value: agent.tagBatteryChangeDate
          ? localMomentFormatted(agent.tagBatteryChangeDate, 'MM/DD/YYYY')
          : 'None',
      },
    ]
  }, [
    resolvedLocationHierachy,
    telemetry,
    agent,
    userPreferences.timeDisplayId,
  ])

  const detailItems: DetailedItem[] = []
  detailItems.push({
    header: 'ASSET IDENTIFICATION',
    items: identificationItems,
  })
  detailItems.push({
    header: 'RTLS LOCATION DATA',
    items: locationItems,
  })

  return (
    <div style={{ paddingTop: 24 }}>
      <DetailsList detailedItems={detailItems} narrowRows={true} />
    </div>
  )
}

export default AssetDetailPane
