import { getType } from 'typesafe-actions'
import RootAction from '../actions'
import {
  setUserBadgeIdAction,
  setConcentratorAction,
  setCollectorAction,
  setConfigurationModeAction,
  openWalkingSetupModalAction,
  closeWalkingSetupModalAction,
  popWalkingSetupModalAction,
  selectDeviceAction,
  resetWalkingSetupStatusAction,
  setDeviceIdAction,
  clearDeviceIdAction,
  setBadgeIdAction,
} from '../actions/ui'
import {
  WalkingSetupModalOptions,
  DeviceConfigurationStatus,
} from '../models/ui'
import {
  configureSensorAction,
  unconfigureSensorAction,
} from '@midmarkrtls/common/actions/sensors'

export type UIState = Readonly<{
  // eslint-disable-next-line
  [id: string]: any
  walkingSetup: {
    mode: boolean
    badgeID: string | null
    deviceId: string
    // eslint-disable-next-line
    modal?: any
    modals: WalkingSetupModalOptions[]
    device: string | null
    status: DeviceConfigurationStatus
  }
  mapView: {
    selectedBadgeId: string
  }
  hardware: {
    concentrator: string
    collector: string
  }
}>

const initialState = {
  walkingSetup: {
    mode: false,
    badgeID: localStorage.getItem('badgeID'),
    deviceId: '',
    modal: null,
    modals: [],
    device: null,
    status: DeviceConfigurationStatus.false,
  },
  mapView: {
    selectedBadgeId: '',
  },
  hardware: {
    concentrator: '',
    collector: '',
  },
}

const uiReducer = (
  state: UIState = initialState,
  action: RootAction
): UIState => {
  switch (action.type) {
    case getType(setConfigurationModeAction):
      return {
        ...state,
        walkingSetup: {
          ...state.walkingSetup,
          mode: action.payload,
        },
      }

    case getType(openWalkingSetupModalAction):
      return {
        ...state,
        walkingSetup: {
          ...state.walkingSetup,
          modals: [...state.walkingSetup.modals, action.payload],
        },
      }

    case getType(popWalkingSetupModalAction):
      return {
        ...state,
        walkingSetup: {
          ...state.walkingSetup,
          modals: state.walkingSetup.modals.slice(0, -1),
        },
      }

    case getType(closeWalkingSetupModalAction):
      return {
        ...state,
        walkingSetup: {
          ...state.walkingSetup,
          modals: [],
        },
      }

    case getType(selectDeviceAction):
      return {
        ...state,
        walkingSetup: {
          ...state.walkingSetup,
          device: action.payload,
        },
      }

    case getType(setUserBadgeIdAction):
      return {
        ...state,
        walkingSetup: {
          ...state.walkingSetup,
          badgeID: action.payload,
        },
      }

    case getType(setConcentratorAction):
      return {
        ...state,
        hardware: {
          ...state.hardware,
          concentrator: action.payload,
        },
      }

    case getType(setCollectorAction):
      return {
        ...state,
        hardware: {
          ...state.hardware,
          collector: action.payload,
        },
      }

    case getType(resetWalkingSetupStatusAction):
      return {
        ...state,
        walkingSetup: {
          ...state.walkingSetup,
          status: DeviceConfigurationStatus.false,
        },
      }

    // case getType(putSensorAssignAction.request):
    case getType(configureSensorAction.request):
    case getType(unconfigureSensorAction.request):
      return {
        ...state,
        walkingSetup: {
          ...state.walkingSetup,
          status: DeviceConfigurationStatus.requesting,
        },
      }

    // case getType(putSensorAssignAction.success):
    case getType(configureSensorAction.success):
    case getType(unconfigureSensorAction.success):
      return {
        ...state,
        walkingSetup: {
          ...state.walkingSetup,
          status: DeviceConfigurationStatus.success,
        },
      }

    // case getType(putSensorAssignAction.failure):
    case getType(configureSensorAction.failure):
    case getType(unconfigureSensorAction.failure):
      return {
        ...state,
        walkingSetup: {
          ...state.walkingSetup,
          status: DeviceConfigurationStatus.failure,
        },
      }
    case getType(setDeviceIdAction):
      return {
        ...state,
        walkingSetup: {
          ...state.walkingSetup,
          deviceId: action.payload,
        },
      }

    case getType(clearDeviceIdAction):
      return {
        ...state,
        walkingSetup: {
          ...state.walkingSetup,
          deviceId: '',
        },
      }

    case getType(setBadgeIdAction):
      return {
        ...state,
        mapView: {
          ...state.mapView,
          selectedBadgeId: action.payload,
        },
      }

    default:
      return state
  }
}

export default uiReducer
