import React from 'react'
import DialogBox from '../Dialogs/DialogBox'
import DialogHeaderClose from '../Dialogs/DialogHeaderClose'
import DeleteConfirmationForm from '../Forms/DeleteConfirmationForm'

interface Props {
  handleClose: () => void
  handleSubmitClick: (itemId: string) => void
  itemType: string
  itemName: string
  itemId: string
  includeDescription?: boolean
}

const DeleteConfirmationModal = (props: Props): JSX.Element => {
  const {
    handleClose,
    handleSubmitClick,
    itemType,
    itemName,
    itemId,
    includeDescription = true,
  } = props

  const title = `Delete ${itemType}: ${itemName}`

  return (
    <DialogBox
      maxWidth='xs'
      open
      handleClose={handleClose}
      customHeader={<DialogHeaderClose title={title} onClose={handleClose} />}
    >
      <div
        style={{
          fontFamily: 'Ubuntu',
          fontSize: '16px',
          fontWeight: 'normal',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: 'normal',
          textAlign: 'left',
          color: '#333',
        }}
      >
        Are you sure you want to delete <b>{itemName}</b>?
        {!includeDescription && <>&nbsp; This action cannot be undone. </>}
        <br />
        <br />
        {includeDescription && (
          <>
            <>
              This will delete <b>{itemName}</b> and unassign this {itemType}{' '}
              locations, staff, and assets. This action cannot be undone.
            </>
            <br />
            <br />
          </>
        )}
        Please type DELETE to confirm.
      </div>
      <DeleteConfirmationForm
        itemId={itemId}
        itemType={itemType}
        handleSubmitClick={handleSubmitClick}
        handleCancelClick={handleClose}
      />
    </DialogBox>
  )
}

export default DeleteConfirmationModal
