import { Epic } from 'redux-observable'
import { filter, mergeMap, map, catchError } from 'rxjs/operators'
import { action, isActionOf } from 'typesafe-actions'
import { from, of } from 'rxjs'
import {
  getAllSubUnitsAction,
  getSubUnitAction,
  postSubUnitAction,
  deleteSubUnitAction,
  putSubUnitAction,
} from '../actions/subUnits'
import { SubUnitsApi } from '../middleware/subUnits'
import { SubUnitAction } from '../actions'
import { SubUnitsState } from '../models'

type SubUnitsEpic = Epic<
  SubUnitAction,
  SubUnitAction,
  SubUnitsState,
  { subUnitsApi: SubUnitsApi }
>
export const getAllSubUnitsEpic: SubUnitsEpic = (
  action$,
  state,
  { subUnitsApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllSubUnitsAction.request)),
    mergeMap(() =>
      from(subUnitsApi.getAllSubUnits()).pipe(
        map((payload) => getAllSubUnitsAction.success(payload)),
        catchError((error) => of(getAllSubUnitsAction.failure(error)))
      )
    )
  )
}

export const getSubUnitEpic: SubUnitsEpic = (
  action$,
  state,
  { subUnitsApi }
) => {
  return action$.pipe(
    filter(isActionOf(getSubUnitAction.request)),
    mergeMap((action) =>
      from(subUnitsApi.getSubUnit(action.payload)).pipe(
        map((payload) => getSubUnitAction.success(payload)),
        catchError((error) => of(getSubUnitAction.failure(error)))
      )
    )
  )
}

export const postSubUnitEpic: SubUnitsEpic = (
  action$,
  state,
  { subUnitsApi }
) => {
  return action$.pipe(
    filter(isActionOf(postSubUnitAction.request)),
    mergeMap((action) =>
      from(subUnitsApi.postSubUnit(action.payload)).pipe(
        map((payload) => postSubUnitAction.success(payload)),
        catchError((error) => of(postSubUnitAction.failure(error)))
      )
    )
  )
}

export const putSubUnitEpic: SubUnitsEpic = (
  action$,
  state,
  { subUnitsApi }
) => {
  return action$.pipe(
    filter(isActionOf(putSubUnitAction.request)),
    mergeMap((action) =>
      from(subUnitsApi.putSubUnit(action.payload)).pipe(
        map((payload) => putSubUnitAction.success(payload)),
        catchError((error) => of(putSubUnitAction.failure(error)))
      )
    )
  )
}

export const deleteSubUnitEpic: SubUnitsEpic = (
  action$,
  state,
  { subUnitsApi }
) => {
  return action$.pipe(
    filter(isActionOf(deleteSubUnitAction.request)),
    mergeMap((action) =>
      from(subUnitsApi.deleteSubUnit(action.payload)).pipe(
        map((payload) => deleteSubUnitAction.success(payload)),
        catchError((error) => of(deleteSubUnitAction.failure(error)))
      )
    )
  )
}
