import { getAllSensorsAction } from '../../actions/sensors'
import { Sensors, SensorsState } from '../../models'
import { EntityResult, useEntities } from '../useEntities'

const useFetchSensors = (): EntityResult<Sensors> =>
  useEntities<SensorsState, Sensors>(
    () => getAllSensorsAction.request(),
    (state: any) => state.sensors // eslint-disable-line @typescript-eslint/no-explicit-any
  )

export { useFetchSensors }
