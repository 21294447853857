import { Header } from '../components/Tables/TableCsvExport'

export const staffExportHeaders: Header[] = [
  { label: 'First Name', key: 'firstName' },
  { label: 'Last Name', key: 'lastName' },
  { label: 'Display Name', key: 'displayName' },
  { label: 'Staff Type', key: 'staffTypeName' },
  { label: 'RTLS ID', key: 'badgeNumber' },
  { label: 'Floor', key: 'floor' },
  { label: 'Room', key: 'locationName' },
  { label: 'RTLS Signal Confidence', key: 'accuracy' },
  { label: 'RTLS Signal Status', key: 'signalStatus' },
  { label: 'RTLS Signal Type', key: 'signalType' },
  { label: 'RTLS Battery Status', key: 'batteryStatus' },
  { label: 'RTLS Battery Percent', key: 'batteryPercent' },
  { label: 'RTLS Battery Change Date', key: 'tagBatteryChangeDateString' },
  { label: 'Last Sensed Date', key: 'lastSensedDateString' },
  { label: 'Assigned To', key: 'unitName' },
]
