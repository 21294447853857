import { getUserSettingsAction } from '../../actions/userSettings'
import { FetchingStatus, UserSettings, UserSettingsState } from '../../models'
import { EntityResult, useEntities } from '../useEntities'

const useFetchUserSettings = (): EntityResult<UserSettings> =>
  useEntities<UserSettingsState, UserSettings>(
    () => getUserSettingsAction.request(),
    (state: any) => state.userSettings, // eslint-disable-line @typescript-eslint/no-explicit-any
    (status: FetchingStatus | undefined, lastRefreshTime: Date | undefined) =>
      !lastRefreshTime
  )

export { useFetchUserSettings }
