import { getType } from 'typesafe-actions'
import { SelectedSavedFilterAction } from '../actions'
import { setSelectedSavedFilterAction } from '../actions/selectedSavedFilter'

export default (
  state: string | null = null,
  action: SelectedSavedFilterAction
): string | null => {
  switch (action.type) {
    case getType(setSelectedSavedFilterAction):
      return action.payload
    default:
      return state
  }
}
