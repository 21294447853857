import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { AgentSilentUpdateAction, AssetAction, FormAction } from '../actions'
import { trackSilentUpdateSuccess } from '../actions/agentSilentUpdate'
import {
  getAllAssetsAction,
  putAssetAction,
  postAssetAction,
  deleteAssetAction,
  getAssetsByLocationIdAction,
  getAssetByIdAction,
  silentUpdateAssetAction,
  getPaginatedAssetsAction,
  getFullAssetsDownloadAction,
} from '../actions/assets'
import { clearFormErrorsAction, setFormErrorsAction } from '../actions/forms'
import { AssetsApi } from '../middleware/assets'
import { AssetsState } from '../models'

type AssetEpic = Epic<
  AssetAction | FormAction | AgentSilentUpdateAction,
  AssetAction | FormAction | AgentSilentUpdateAction,
  AssetsState,
  { assetsApi: AssetsApi }
>

export const getAllAssetsEpic: AssetEpic = (action$, state$, { assetsApi }) => {
  return action$.pipe(
    filter(isActionOf(getAllAssetsAction.request)),
    mergeMap(() =>
      from(assetsApi.getAllAssets()).pipe(
        map((assets) => getAllAssetsAction.success(assets)),
        catchError((error) => of(getAllAssetsAction.failure(error)))
      )
    )
  )
}

export const getAssetsByLocationIdEpic: AssetEpic = (
  action$,
  state$,
  { assetsApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAssetsByLocationIdAction.request)),
    switchMap((action) =>
      from(assetsApi.getAssetsByLocationId(action.payload)).pipe(
        map((assets) =>
          getAssetsByLocationIdAction.success({
            assets,
            locationId: action.payload.locationGuid,
            signalStatus: action.payload.signalStatus,
          })
        ),
        catchError((error) => of(getAssetsByLocationIdAction.failure(error)))
      )
    )
  )
}

export const getPaginatedAssetsIdEpic: AssetEpic = (
  action$,
  state$,
  { assetsApi }
) => {
  return action$.pipe(
    filter(isActionOf(getPaginatedAssetsAction.request)),
    mergeMap((action) =>
      from(assetsApi.getPaginatedAssets(action.payload)).pipe(
        map((assets) => getPaginatedAssetsAction.success(assets)),
        catchError((error) => of(getPaginatedAssetsAction.failure(error)))
      )
    )
  )
}

export const getAssetByIdEpic: AssetEpic = (action$, state$, { assetsApi }) => {
  return action$.pipe(
    filter(isActionOf(getAssetByIdAction.request)),
    mergeMap((action) =>
      from(assetsApi.getAssetById(action.payload)).pipe(
        map((asset) => getAssetByIdAction.success(asset)),
        catchError((error) => of(getAssetByIdAction.failure(error)))
      )
    )
  )
}

export const putAssetEpic: AssetEpic = (action$, state$, { assetsApi }) => {
  return action$.pipe(
    filter(isActionOf(putAssetAction.request)),
    mergeMap((action) =>
      from(assetsApi.putAsset(action.payload)).pipe(
        mergeMap((asset) => [
          clearFormErrorsAction({ formName: 'assetEdit' }),
          putAssetAction.success(asset),
        ]),
        catchError((error) =>
          of(
            setFormErrorsAction({ formName: 'assetEdit', error }),
            putAssetAction.failure(error)
          )
        )
      )
    )
  )
}

export const postAssetEpic: AssetEpic = (action$, state$, { assetsApi }) => {
  return action$.pipe(
    filter(isActionOf(postAssetAction.request)),
    mergeMap((action) =>
      from(assetsApi.postAsset(action.payload)).pipe(
        mergeMap((asset) => [
          clearFormErrorsAction({ formName: 'assetEdit' }),
          postAssetAction.success(asset),
        ]),
        catchError((error) =>
          of(
            setFormErrorsAction({ formName: 'assetEdit', error }),
            postAssetAction.failure(error)
          )
        )
      )
    )
  )
}

export const deleteAssetEpic: AssetEpic = (action$, state$, { assetsApi }) => {
  return action$.pipe(
    filter(isActionOf(deleteAssetAction.request)),
    mergeMap((action) =>
      from(assetsApi.deleteAsset(action.payload)).pipe(
        map(() => deleteAssetAction.success(action.payload)),
        catchError((error) => of(deleteAssetAction.failure(error)))
      )
    )
  )
}

export const silentUpdateAssetEpic: AssetEpic = (
  action$,
  state$,
  { assetsApi }
) => {
  return action$.pipe(
    filter(isActionOf(silentUpdateAssetAction.request)),
    mergeMap((action) =>
      from(assetsApi.getAssetsByIds(action.payload)).pipe(
        mergeMap((assets) => [
          silentUpdateAssetAction.success({
            assets: assets,
            locationId: action.payload.locationGuid,
            badgeTelemetry: action.payload.badgeTelemetry,
          }),
          trackSilentUpdateSuccess(assets.map((x) => x.agentGuid)),
        ]),
        catchError((error) => of(silentUpdateAssetAction.failure(error)))
      )
    )
  )
}

export const getAssetsFullDownloadEpic: AssetEpic = (
  action$,
  state$,
  { assetsApi }
) => {
  return action$.pipe(
    filter(isActionOf(getFullAssetsDownloadAction.request)),
    mergeMap((action) =>
      from(assetsApi.getFullAssetsDownload(action.payload)).pipe(
        map((assets) => getFullAssetsDownloadAction.success(assets)),
        catchError((error) => of(getFullAssetsDownloadAction.failure(error)))
      )
    )
  )
}
