import { StaffType } from '../models'
import { BaseService } from './baseService'

export class StaffTypesApi extends BaseService {
  constructor() {
    super({ entityType: 'staffTypes' })
  }
  route = 'staff/types'

  async getAllStaffTypes(): Promise<StaffType[]> {
    return await this.get<StaffType[]>(this.route)
  }
}
