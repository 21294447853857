import * as AgentEventsLocationEpics from './agentEventsLocation'
import * as AgentEventViews from './agentEventViews'
import * as AltitudeMapEpics from './altitudeMaps'
import * as AssetsEpics from './assets'
import * as AssetSubTypesEpics from './assetSubTypes'
import * as AssetTypesEpics from './assetTypes'
import * as BuildingEpics from './buildings'
import * as BadgeEpics from './badges'
import * as BadgeAssignedCount from './badgeAssignedCount'
import * as BadgeSensorStatesEpics from './badgeSensorStates'
import * as ChannelTypesEpics from './channelTypes'
import * as CurrentUserEpics from './currentUser'
import * as DevicesEpics from './devices'
import * as ExternalLinksEpic from './externalLinks'
import * as FeatureFlagsEpic from './featureFlags'
import * as FilterEpics from './filters'
import * as GeofenceEpics from './geofence'
import * as HardwareEpics from './hardware'
import * as IssuesEpics from './issues'
import * as IssueTypesEpics from './issueTypes'
import * as LocationsEpics from './locations'
import * as LocationGeofenceEpics from './locationGeofences'
import * as LocationMetricsEpics from './locationMetrics'
import * as LogLevelTypesEpics from './logLevelTypes'
import * as ManufacturersEpics from './manufacturers'
import * as MapsEpic from './maps'
import * as MapPresetsEpics from './mapPresets'
import * as MapReferenceViewsEpics from './mapReferenceViews'
import * as ModelsEpics from './models'
import * as OwnersEpics from './owners'
import * as PermissionsEpics from './permissions'
import * as ReleasesEpics from './release'
import * as RolesEpics from './roles'
import * as SelectableLocationsEpics from './selectableLocations'
import * as SensorsEpics from './sensors'
import * as SensoryReferenceEpics from './sensoryReference'
import * as StaffEpics from './staff'
import * as StaffTypesEpics from './staffTypes'
import * as StaffSubTypesEpics from './staffSubTypes'
import * as SubUnitsEpics from './subUnits'
import * as TenantConfigurationEpics from './tenantConfiguration'
import * as UnitsEpics from './units'
import * as UsersEpics from './users'
import * as UserPreferencesEpics from './userPreferences'
import * as UserSettingsEpics from './userSettings'
import * as VenuesEpics from './venues'
import * as WatchlistEpics from './watchlist'
import * as StaffEventsEpic from './staffEvents'
import * as AlertsEpic from './alerts'
import * as AlertTypesEpic from './alertTypes'
import * as QualifierEpic from './qualifier'
import * as TriggersEpic from './triggers'
import * as CurrentUserLocationEpic from './currentUserLocation'
import * as LocationRoutersEpic from './locationRouters'
import * as SecurityRoutersEpic from './securityRouters'
import * as UserRoutersEpic from './userRouters'
import * as ChannelSoundsEpic from './channelSounds'
import * as AlertSubscriptionKeysEpic from './alertSubscriptionKeys'
import * as CloudReportingEpic from './cloudReporting'

const commonEpics = [
  ...Object.values(AgentEventsLocationEpics),
  ...Object.values(AgentEventViews),
  ...Object.values(MapReferenceViewsEpics),
  // v2
  ...Object.values(AltitudeMapEpics),
  ...Object.values(AssetsEpics),
  ...Object.values(AssetSubTypesEpics),
  ...Object.values(AssetTypesEpics),
  ...Object.values(BuildingEpics),
  ...Object.values(BadgeEpics),
  ...Object.values(BadgeAssignedCount),
  ...Object.values(BadgeSensorStatesEpics),
  ...Object.values(ChannelTypesEpics),
  ...Object.values(CurrentUserEpics),
  ...Object.values(DevicesEpics),
  ...Object.values(ExternalLinksEpic),
  ...Object.values(FeatureFlagsEpic),
  ...Object.values(FilterEpics),
  ...Object.values(GeofenceEpics),
  ...Object.values(HardwareEpics),
  ...Object.values(IssuesEpics),
  ...Object.values(IssueTypesEpics),
  ...Object.values(LocationsEpics),
  ...Object.values(LocationGeofenceEpics),
  ...Object.values(LocationMetricsEpics),
  ...Object.values(LogLevelTypesEpics),
  ...Object.values(ManufacturersEpics),
  ...Object.values(MapsEpic),
  ...Object.values(MapPresetsEpics),
  ...Object.values(ModelsEpics),
  ...Object.values(OwnersEpics),
  ...Object.values(PermissionsEpics),
  ...Object.values(ReleasesEpics),
  ...Object.values(RolesEpics),
  ...Object.values(SelectableLocationsEpics),
  ...Object.values(SensorsEpics),
  ...Object.values(SensoryReferenceEpics),
  ...Object.values(StaffEpics),
  ...Object.values(StaffTypesEpics),
  ...Object.values(StaffSubTypesEpics),
  ...Object.values(SubUnitsEpics),
  ...Object.values(TenantConfigurationEpics),
  ...Object.values(UnitsEpics),
  ...Object.values(UsersEpics),
  ...Object.values(UserPreferencesEpics),
  ...Object.values(UserSettingsEpics),
  ...Object.values(VenuesEpics),
  ...Object.values(WatchlistEpics),
  ...Object.values(StaffEventsEpic),
  ...Object.values(AlertsEpic),
  ...Object.values(AlertTypesEpic),
  ...Object.values(QualifierEpic),
  ...Object.values(TriggersEpic),
  ...Object.values(CurrentUserLocationEpic),
  ...Object.values(LocationRoutersEpic),
  ...Object.values(SecurityRoutersEpic),
  ...Object.values(UserRoutersEpic),
  ...Object.values(ChannelSoundsEpic),
  ...Object.values(AlertSubscriptionKeysEpic),
  ...Object.values(CloudReportingEpic),
]

export default commonEpics
