import { Typography } from '@mui/material'
import React from 'react'

export interface Props {
  primaryMessage: string
  secondaryMessage?: string
}

const PageInformationMessage = (props: Props): JSX.Element => {
  const { primaryMessage, secondaryMessage } = { ...props }

  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '0 24px',
      }}
    >
      <Typography variant='h5'>{primaryMessage}</Typography>
      <Typography>{secondaryMessage}</Typography>
    </div>
  )
}

export default PageInformationMessage
