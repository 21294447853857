import { AltitudeMap } from '../models/altitudeMap'
import { BaseService } from './baseService'

export class AltitudeMapsApi extends BaseService {
  constructor() {
    super({ entityType: 'altitudeMaps' })
  }
  route = 'altitudeMaps'

  async getAllAltitudeMaps(): Promise<AltitudeMap[]> {
    return await this.get<AltitudeMap[]>(`${this.route}/all`)
  }

  async createAltitudeMap(altitudemap: AltitudeMap): Promise<AltitudeMap> {
    return await this.post<AltitudeMap>(`${this.route}`, altitudemap)
  }

  async updateAltitudeMap(altitudemap: AltitudeMap): Promise<AltitudeMap> {
    return await this.put<AltitudeMap>(`${this.route}`, altitudemap)
  }

  async deleteAltitudeMap(referenceId: number): Promise<AltitudeMap> {
    return await this.delete<AltitudeMap>(`${this.route}/${referenceId}`)
  }
}
