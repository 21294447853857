import {
  calculateAssetPositionCoords,
  defaultAgentIconHeight,
  getConfidenceBubbleConfig,
} from '.'
import { calculateBadgeSignalStatus, isLowBattery } from '..'
import {
  BaseInpixonAssetConfigOptions,
  IconUrlBadgeOptions,
  IconUrlOptions,
  InpixonAssetAgentCreateOptions,
} from '../../models/inpixonMap'
import { buildCDNPath } from '../inpixonIcons'

/**
 * Build the base config for creating a new asset/staff icon on the map.
 * Additional defaul configs will need to be appended before Inpixon assets can be created.
 * @param inpixonAssetAgent
 * @param assetKitId
 * @param geofenceInstances
 * @param jibestreamController
 * @param useGeofenceCentroid
 * @returns base configs for creating a new asset/staff icon on the map
 */
export function buildBaseInpixonAssetConfigsForNewAgent(
  inpixonAssetAgent: InpixonAssetAgentCreateOptions,
  assetKitId: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  geofenceInstances: any[],
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any,
  useGeofenceCentroid: boolean
): BaseInpixonAssetConfigOptions {
  const confidenceBubbleConfig = getConfidenceBubbleConfig(
    inpixonAssetAgent.signalTypeId,
    inpixonAssetAgent.iconType,
    {
      hasActiveDuress: inpixonAssetAgent.hasActiveDuress,
      hasActiveAssist: inpixonAssetAgent.hasActiveAssist,
      isSelected: inpixonAssetAgent.isSelected,
    }
  )
  const positionCoords = calculateAssetPositionCoords(
    jibestreamController,
    geofenceInstances,
    inpixonAssetAgent.lat,
    inpixonAssetAgent.lon,
    inpixonAssetAgent.mapId,
    useGeofenceCentroid
  )

  const badgeSignalStatus = calculateBadgeSignalStatus(
    inpixonAssetAgent.timestamp
  )
  const name =
    inpixonAssetAgent.displayName && inpixonAssetAgent.displayName !== ''
      ? inpixonAssetAgent.displayName
      : inpixonAssetAgent.trackingId

  const iconUrlOptions: IconUrlOptions = {
    image: inpixonAssetAgent.iconUrl,
    name,
    scale: inpixonAssetAgent.iconScale,
    type: inpixonAssetAgent.iconType,
  }
  const iconUrlBadgeOptions: IconUrlBadgeOptions = {
    isLowBattery: isLowBattery(inpixonAssetAgent.isLowBattery),
    hasIssues: false,
    signalStatus: badgeSignalStatus,
  }
  const url = buildCDNPath(iconUrlOptions, iconUrlBadgeOptions, {
    hasActiveDuress: inpixonAssetAgent.hasActiveDuress,
    hasActiveAssist: inpixonAssetAgent.hasActiveAssist,
    isSelected: inpixonAssetAgent.isSelected,
  })
  const config: BaseInpixonAssetConfigOptions = {
    id: assetKitId,
    position: positionCoords,
    height: defaultAgentIconHeight,
    name,
    url,
    ...confidenceBubbleConfig,
  }
  return config
}
