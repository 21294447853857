import { AssetInstance } from '../../models'
import { SelectedAgent } from '../../models/realtimeMap'
import { updateSelectedGroupIconUrl } from '../inpixonIcons'

export type GroupIconHighlightingUpdate = {
  inpixonAssetType: string
  url: string
}

export type RemoveIconHighlightUpdates = {
  groupsIconToRemoveHighlight: GroupIconHighlightingUpdate | undefined
  prevSelectedAgentTypeToRemoveConfidenceBubble: string | undefined
}

export interface SelectingGroupUpdates extends RemoveIconHighlightUpdates {
  groupsIconToAddHighlight: GroupIconHighlightingUpdate | undefined
}

/**
 * After an agent is selected on the map, a confidence bubble is to be added to the agent icon
 * (if the agent is currently rendered as an Asset or Staff icon) or the group icon is to be highlighted
 * (if the agent is currently rendered as a group). In addition, we need to deselect currently selected
 * agent icon or group icon by removing the confidence bubble for the agent icon or removing the highlighting
 * for the group icon.
 * - If an agent is currently selected, remove the confidence bubble for the agent icon.
 * - If a group is currently selected, remove the group icon highlighting
 * - If an agent in a group is currently selected, remove the group icon highlighting
 * @param selectedAgentInpixonAsset Inpixon Asset instance of the selecting agent
 * @param prevSelectedAgentInpixonAsset
 * @param prevSelectedGroupInpixonAsset
 * @returns prev group icon to remove highlight or prev agent icon to remove highlight
 */
export function calculateMapIconUpdatesForSelectingAgent(
  selectedAgentInpixonAsset: AssetInstance,
  prevSelectedAgentInpixonAsset: AssetInstance | undefined,
  prevSelectedGroupInpixonAsset: AssetInstance | undefined
): RemoveIconHighlightUpdates | undefined {
  const updates: RemoveIconHighlightUpdates = {
    groupsIconToRemoveHighlight: undefined,
    prevSelectedAgentTypeToRemoveConfidenceBubble: undefined,
  }
  if (
    prevSelectedAgentInpixonAsset &&
    prevSelectedAgentInpixonAsset.id === selectedAgentInpixonAsset.id
  ) {
    return
  }
  if (prevSelectedGroupInpixonAsset) {
    const deselectedIconUrl = updateSelectedGroupIconUrl(
      prevSelectedGroupInpixonAsset.url,
      false
    )
    updates.groupsIconToRemoveHighlight = {
      inpixonAssetType: prevSelectedGroupInpixonAsset._type,
      url: deselectedIconUrl,
    }
  }
  if (prevSelectedAgentInpixonAsset && !prevSelectedGroupInpixonAsset) {
    updates.prevSelectedAgentTypeToRemoveConfidenceBubble =
      prevSelectedAgentInpixonAsset._type
  }
  return updates
}

/**
 * After a group is selected on the map, the group icon is to be highlighted.
 * In addition, we need to deselect currently selected agent icon or group icon
 * by removing the confidence bubble for the agent icon or removing the highlighting
 * for the group icon.
 * - If an agent is currently selected, remove the confidence bubble for the agent icon.
 * - If a group is currently selected, remove the group icon highlighting
 * - If an agent in a group is currently selected, remove the group icon highlighting
 * @param selectedGroupInpixonAsset Inpixon Asset instance of the selecting group
 * @param prevSelectedAgentInpixonAsset
 * @param prevSelectedGroupInpixonAsset
 * @returns group icon to add highlight and prev group icon to remove highlight or
 * prev agent icon to remove highlight
 */
export function calculateMapIconUpdatesForSelectingGroup(
  selectedGroupInpixonAsset: AssetInstance,
  prevSelectedAgentInpixonAsset: SelectedAgent | undefined,
  prevSelectedGroupInpixonAsset: AssetInstance | undefined
): SelectingGroupUpdates {
  const updates: SelectingGroupUpdates = {
    groupsIconToRemoveHighlight: undefined,
    prevSelectedAgentTypeToRemoveConfidenceBubble: undefined,
    groupsIconToAddHighlight: undefined,
  }
  if (prevSelectedGroupInpixonAsset) {
    if (
      prevSelectedGroupInpixonAsset.id === selectedGroupInpixonAsset.id &&
      prevSelectedAgentInpixonAsset
    ) {
      return updates
    }
    const deselectedIconUrl = updateSelectedGroupIconUrl(
      prevSelectedGroupInpixonAsset.url,
      false
    )
    updates.groupsIconToRemoveHighlight = {
      inpixonAssetType: prevSelectedGroupInpixonAsset._type,
      url: deselectedIconUrl,
    }
    if (prevSelectedGroupInpixonAsset.id === selectedGroupInpixonAsset.id) {
      return updates
    }
  }
  if (
    prevSelectedAgentInpixonAsset &&
    prevSelectedAgentInpixonAsset.inpixonAsset &&
    !prevSelectedGroupInpixonAsset
  ) {
    updates.prevSelectedAgentTypeToRemoveConfidenceBubble =
      prevSelectedAgentInpixonAsset.inpixonAsset._type
  }
  const selectedIconUrl = updateSelectedGroupIconUrl(
    selectedGroupInpixonAsset.url,
    true
  )
  if (selectedGroupInpixonAsset._type) {
    updates.groupsIconToAddHighlight = {
      inpixonAssetType: selectedGroupInpixonAsset._type,
      url: selectedIconUrl,
    }
  }
  return updates
}

/**
 * After an agent is deselected on the map, remove the confidence bubble for the agent icon.
 * After a group is deselected on the map, remove the highlighting for the group icon.
 * @param prevSelectedAgent
 * @param prevSelectedGroupInpixonAsset
 * @returns group icon to remove highlight or agent icon to remove confidence bubble
 */
export function calculateUpdatesToRemoveIconHighlight(
  prevSelectedAgent: AssetInstance | undefined,
  prevSelectedGroupInpixonAsset: AssetInstance | undefined
): RemoveIconHighlightUpdates {
  const updates: RemoveIconHighlightUpdates = {
    groupsIconToRemoveHighlight: undefined,
    prevSelectedAgentTypeToRemoveConfidenceBubble: undefined,
  }
  if (prevSelectedGroupInpixonAsset) {
    const deselectedIconUrl = updateSelectedGroupIconUrl(
      prevSelectedGroupInpixonAsset.url,
      false
    )
    updates.groupsIconToRemoveHighlight = {
      inpixonAssetType: prevSelectedGroupInpixonAsset._type,
      url: deselectedIconUrl,
    }
  }
  if (prevSelectedAgent && !prevSelectedGroupInpixonAsset) {
    updates.prevSelectedAgentTypeToRemoveConfidenceBubble =
      prevSelectedAgent._type
  }
  return updates
}
