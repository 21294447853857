import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllTriggersAction } from '../actions/triggers'
import { shouldDispatch } from '../helpers'
import { Triggers, TriggerState } from '../models/trigger'

const useFetchTriggers = (shouldRefresh: boolean = false) => {
  const dispatch = useDispatch()
  const status = useSelector(
    ({ triggers }: { triggers: TriggerState }) => triggers.status
  )
  const lastRefreshTime = useSelector(
    ({ triggers }: { triggers: TriggerState }) => triggers.lastRefreshTime
  )

  useEffect(() => {
    if (shouldDispatch(status, lastRefreshTime) || shouldRefresh) {
      dispatch(getAllTriggersAction.request())
    }
  }, [status, lastRefreshTime, shouldRefresh, dispatch])

  const triggers: Triggers = useSelector(
    ({ triggers }: { triggers: TriggerState }) => triggers.data
  )

  return {
    triggers,
    status,
  }
}

export { useFetchTriggers }
