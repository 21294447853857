import React from 'react'
import { Tooltip, IconButton } from '@mui/material'
import { EditOutlined } from '@mui/icons-material'

interface Props<T> {
  item: T
  onEditClick: (item: T) => void
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EditButton = <T extends string | number | any | undefined>(
  props: Props<T>
): JSX.Element => {
  const { item, onEditClick } = props
  const handleClick = (): void => {
    onEditClick(item)
  }
  return (
    <Tooltip title='View Details'>
      <IconButton
        color='primary'
        onClick={handleClick}
        aria-label='View Details'
        size='large'
      >
        <EditOutlined />
      </IconButton>
    </Tooltip>
  )
}

export { EditButton }
