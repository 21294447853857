import { Grid, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { putLocationAction } from '../../actions/locations'
import { cleanUpNullGuid } from '../../helpers'
import { useSaveSuccessFailureUtility } from '../../hooks'
import { FetchingStatus, Location, LocationsState } from '../../models'
import { colors, useFormStyle } from '../../styles'
import { Button, SaveButton } from '../Buttons'
import { CreateOrEditModalProps } from '../Common/HOC'
import { requiredMessage } from '../Forms'

const FloorEditForm: React.ComponentType<
  CreateOrEditModalProps<Location | null>
> = (props: CreateOrEditModalProps<Location | null>) => {
  const { data: selectedFloor, handleClose } = props

  const locationStatus = useSelector(
    ({ locations }: { locations: LocationsState }) => locations.status
  )

  const dispatch = useDispatch()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Location>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: selectedFloor?.name,
    } as Location,
  })

  const onSubmit = (formData: Location) => {
    const updatedFloor: Location = {
      ...selectedFloor,
      ...formData,
    }
    cleanUpNullGuid(updatedFloor, ['unitId', 'subUnitId'])
    setIsSaving(true)
    if (showError) {
      setShowError(false)
    }
    dispatch(putLocationAction.request(updatedFloor))
  }

  const { isSaving, setIsSaving, showError, setShowError } =
    useSaveSuccessFailureUtility(
      locationStatus === FetchingStatus.Success,
      locationStatus === FetchingStatus.Failure,
      `Floor was successfully updated.`,
      `There was an error saving Floor information, please try again. If the problem persists, please contact support for assistance.`,
      handleClose
    )

  const classes = useFormStyle()

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name='name'
        control={control}
        rules={{
          required: true,
          maxLength: {
            value: 50,
            message: 'Floor name must be between 1 and 50 characters',
          },
        }}
        render={({ field }) => {
          return (
            <TextField
              {...field}
              margin='normal'
              id='locationEditName'
              label='Floor Name'
              variant='filled'
              fullWidth
              required
              error={!!errors.name}
              helperText={
                errors.name?.message ? errors.name?.message : requiredMessage
              }
              className={classes.inputStyle}
            />
          )
        }}
      />
      {showError && (
        <Typography
          style={{
            color: colors.error,
            paddingBottom: 10,
          }}
        >
          There was an error saving Floor information, please try again. If the
          problem persists, contact support.
        </Typography>
      )}
      <Grid
        container
        justifyContent='space-between'
        style={{ paddingBottom: 10 }}
      >
        <Grid item>
          <Button
            id='location-edit-cancel-button'
            onClick={handleClose}
            styleOverride={{ wrapper: { margin: 0 } }}
            bgColor={colors.transparent}
            textColor={colors.error}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <SaveButton
            id='location-edit-save-button'
            loading={isSaving}
            disabled={Object.values(errors).length > 0}
          >
            Save Floor
          </SaveButton>
        </Grid>
      </Grid>
    </form>
  )
}

export default FloorEditForm
