import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setActiveDuressEvents,
  setAllDuressFilteredByUser,
  setShowNowAndShowNextDuress,
} from '../actions/staffDuress'
import { sortEventsByOldestFirst } from '../helpers'
import { shouldShowAlertNotification } from '../helpers/shouldShowAlertNotification'
import {
  AgentEventType,
  EventStatus,
  FetchingStatus,
  StaffEvent,
} from '../models'
import {
  selectAgentEventLocationFetchingStatus,
  selectAuthState,
  selectCurrentUserLocationState,
  selectStaffEventData,
} from '../selectors'
import { useFetchAlerts } from './entities/useFetchAlerts'
import { useFetchUserById } from './entities/useFetchUserById'

const useStaffDuressEvents = (): {
  activeDuressEvents: StaffEvent[]
} => {
  const [activeDuress, setActiveDuress] = useState<StaffEvent[]>([])

  const currentUserLocationState = useSelector(selectCurrentUserLocationState)
  const agentEventLocationFetchingStatus = useSelector(selectAgentEventLocationFetchingStatus)
  const {
    data: currentUserLocation,
    status: currentUserLocationFetchingStatus,
  } = currentUserLocationState
  const { user } = useSelector(selectAuthState)
  const { data: userInfo, status: currentUserFetchingStatus } =
    useFetchUserById(user.id)
  const { alerts, alertsFetchingStatus } = useFetchAlerts()
  const dispatch = useDispatch()

  const allEvents = useSelector(selectStaffEventData)

  const alreadyShownEventIds = useRef<string[]>([])

  useEffect(() => {
    if (
      currentUserLocationFetchingStatus === FetchingStatus.Request ||
      currentUserFetchingStatus === FetchingStatus.Request ||
      alertsFetchingStatus === FetchingStatus.Request ||
      agentEventLocationFetchingStatus === FetchingStatus.Request
    ) {
      return
    }
    const filteredStaffDuress: StaffEvent[] = []

    Object.values(allEvents)
      .filter(
        (staffEvent) => staffEvent.agentEventTypeId === AgentEventType.Duress
      )
      .forEach((staffEvent: StaffEvent) => {
        const alert = Object.values(alerts).find(
          (x) => x.id === staffEvent.alertId
        )

        const userRoleIds = userInfo?.roleIds ?? []

        if (
          shouldShowAlertNotification(
            staffEvent,
            currentUserLocation,
            userRoleIds,
            alert,
            alreadyShownEventIds.current
          )
        ) {
          filteredStaffDuress.push(staffEvent)
          if (!alreadyShownEventIds.current.includes(staffEvent.id)) {
            alreadyShownEventIds.current.push(staffEvent.id)
          }
        }
      })
    dispatch(setAllDuressFilteredByUser(filteredStaffDuress))

    const activeDuress = filteredStaffDuress.filter(
      (e) => e.status !== EventStatus.RESOLVED
    )
    setActiveDuress(activeDuress)
    dispatch(setActiveDuressEvents(activeDuress))

    const undismissedDuressEvents = activeDuress.filter(
      (e) => e.status !== EventStatus.DISMISSED
    )
    const sortedEvents = sortEventsByOldestFirst(undismissedDuressEvents)
    const showNow = sortedEvents.length > 0 ? sortedEvents[0] : undefined
    const showNext = sortedEvents.length > 1 ? sortedEvents[1] : undefined
    dispatch(
      setShowNowAndShowNextDuress({
        showNow,
        showNext,
      })
    )
  }, [
    alerts,
    alertsFetchingStatus,
    allEvents,
    currentUserFetchingStatus,
    agentEventLocationFetchingStatus,
    currentUserLocation,
    currentUserLocationFetchingStatus,
    dispatch,
    userInfo?.roleIds,
  ])

  return {
    activeDuressEvents: activeDuress,
  }
}

export default useStaffDuressEvents
