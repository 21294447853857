import { ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material'
import { ClassNameMap } from '@mui/styles'
import React, { ReactElement, useState } from 'react'
import { DataTestIds } from '../../../utils/test-utils/dataTestIds'

interface Props {
  icon: ReactElement
  name: string
  isCollapsed: boolean
  classes: {
    listItem: Partial<ClassNameMap<string>>
    listItemIcon: Partial<ClassNameMap<string>>
    listItemText?: Partial<ClassNameMap<string>>
  }
  component: React.ElementType
  onSelectPage: () => void
}

const SideNavLink: React.FC<Props> = (props: Props) => {
  const { icon, name, component, isCollapsed, classes, onSelectPage } = props

  const [open, setOpen] = useState(false)

  return (
    <Tooltip
      title={isCollapsed ? name : ''}
      open={isCollapsed && open}
      disableHoverListener
      disableFocusListener
      placement='right'
    >
      <div
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        onClick={() => setOpen(false)}
      >
        <ListItem
          button
          component={component}
          onClick={onSelectPage}
          disableGutters
          classes={classes.listItem}
        >
          <ListItemIcon
            classes={classes.listItemIcon}
            data-testid={DataTestIds.sideNavLinkIcon}
          >
            {icon}
          </ListItemIcon>
          <ListItemText
            primary={name}
            primaryTypographyProps={{ variant: 'button' }}
            classes={classes.listItemText ?? {}}
          />
        </ListItem>
      </div>
    </Tooltip>
  )
}

export default SideNavLink
