import { useDispatch, useSelector } from 'react-redux'
import { shouldDispatch } from '../helpers/shouldDispatch'
import { FetchingState } from '../interfaces/fetchingState'
import { FetchingStatus } from '../models/fetchingStatus'
import { HttpErrorResponse } from '../services/http'

export type EntityResult<U> = {
  data: U
  status: FetchingStatus | undefined
  error?: HttpErrorResponse
}

export function useEntities<T extends Readonly<FetchingState<U>>, U>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: (() => any | undefined) | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selector: (state: any) => T,
  additionalCondition?: (
    status: FetchingStatus | undefined,
    lastRefreshTime: Date | undefined
  ) => boolean | undefined, // eslint-disable-line,
  canDispatch: () => boolean = () => true // eslint-disable-line
): EntityResult<U> {
  const dispatch = useDispatch()
  const { status, lastRefreshTime, data, error } = useSelector((state) =>
    selector(state)
  )

  if (
    canDispatch() &&
    shouldDispatch(
      status,
      lastRefreshTime,
      additionalCondition && additionalCondition(status, lastRefreshTime)
    ) &&
    action
  ) {
    dispatch(action())
  }

  return {
    data,
    status,
    error,
  }
}
