import {
  removeAgentLeavingMap,
  showRemainingAgentsAfterAgentLeft,
  updateGroupIconAfterAgentLeft,
} from '.'
import { AssetKitInstance } from '../../models'
import { MapRenderByGeofence } from '../../models/realtimeMap'
import {
  AgentTelemetriesGroupedByTrackingId,
  BadgeTelemetryMessageWithAgentEvent,
} from '../../models/telemetry'
import { findGeofenceAgentIsIn } from '../realtimeMap'

/**
 * For each agent leaving the map,
 *    - if the agent is NOT currently rendered as a Group icon,
 *      - remove the agent icon on the map,
 *      - remove geofence highlighting if agent has an active duress,
 *      - close Detail Drawer if agent is currently selected
 *    - if the agent is currently rendered as a Group icon AND the remaining agents are NO longer grouped,
 *      - remove the current group icon and rerendered all remaining agents as either Asset or Staff icon,
 *      - if the geofence is currently highlighted BUT the remaining agents DO NOT have any active duress, then remove
 *      the geofence highlighting,
 *      - if the leaving agent is currently selected on the map, the close the Detail Drawer. Otherwise,
 *      if the leaving agent is in a selected group, then remove the agent from the Detail Drawer agent list
 *    - if the agent is currently rendered as a Group icon AND the remaining agents are to remained grouped,
 *      - update the current group icon to decrease the group count
 *      - if the geofence is currently highlighted BUT the remaining agents DO NOT have any active duress, then
 *      remove the geofence highlighting,
 *      - if the leaving agent is currently selected on the map, the close the Detail Drawer. Otherwise,
 *      if the leaving agent is in a selected group, then remove the agent from the Detail Drawer agent list
 * @param agentsLeavingMap
 * @param currentMapRender
 * @param assetKit
 * @param jibestreamController
 * @param geofenceInstances
 * @param useGeofenceCentroid
 * @param iconScale
 * @param selectedTrackingId
 * @param selectedGroupId
 * @param assetKitId
 * @param removeGeofenceHighlight
 * @param removeSelectedAgent
 * @param removeAgentFromSelectedGroup
 * @returns updated map render state after map rerendering and the nextInpixonAssetId
 * used for subsequent Inpixon Asset creation on the map
 */
export function processRemoveAgentsLeavingMap(
  agentsLeavingMap: AgentTelemetriesGroupedByTrackingId,
  currentMapRender: MapRenderByGeofence,
  assetKit: AssetKitInstance,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  geofenceInstances: any[],
  useGeofenceCentroid: boolean,
  iconScale: number,
  selectedTrackingId: string | undefined,
  selectedGroupId: number | undefined,
  assetKitId: number,
  removeGeofenceHighlight: (geofenceId: number) => void,
  removeSelectedAgent: (
    agentTelemtry: BadgeTelemetryMessageWithAgentEvent
  ) => void,
  removeAgentFromSelectedGroup: (
    agentTelemtry: BadgeTelemetryMessageWithAgentEvent
  ) => void
): {
  nextInpixonAssetId: number | undefined
  updatedMapRender: MapRenderByGeofence
} {
  let updatedMapRender = currentMapRender
  let nextInpixonAssetId = assetKitId
  Object.values(agentsLeavingMap).forEach((agentTelemetry) => {
    const leavingGeofenceId = findGeofenceAgentIsIn(
      agentTelemetry.trackingId,
      currentMapRender
    )
    if (!leavingGeofenceId) {
      console.warn('Geofence not found!')
      return
    }
    const leavingGeofenceMapRender = currentMapRender[leavingGeofenceId]
    const currentGroupCount = leavingGeofenceMapRender.renderedAgents.length
    // Current geofence agent is NOT grouped
    if (
      currentGroupCount < leavingGeofenceMapRender.maxAgentsAllowedInGeofence
    ) {
      updatedMapRender = removeAgentLeavingMap(
        agentTelemetry,
        updatedMapRender,
        leavingGeofenceId,
        assetKit,
        selectedTrackingId,
        removeGeofenceHighlight,
        removeSelectedAgent
      )
    } else {
      // Current geofence agent is in is grouped
      const newGroupCount = currentGroupCount - 1
      const remainingAgentsInGeofence =
        leavingGeofenceMapRender.renderedAgents.filter(
          (telemetry) => telemetry.trackingId !== agentTelemetry.trackingId
        )
      if (newGroupCount < leavingGeofenceMapRender.maxAgentsAllowedInGeofence) {
        const degroupResult = showRemainingAgentsAfterAgentLeft(
          agentTelemetry,
          updatedMapRender,
          remainingAgentsInGeofence,
          leavingGeofenceId,
          assetKit,
          nextInpixonAssetId,
          selectedTrackingId,
          selectedGroupId,
          useGeofenceCentroid,
          iconScale,
          geofenceInstances,
          jibestreamController,
          removeGeofenceHighlight,
          removeSelectedAgent,
          removeAgentFromSelectedGroup
        )
        if (degroupResult.nextInpixonAssetId) {
          nextInpixonAssetId = degroupResult.nextInpixonAssetId
        }
        updatedMapRender = degroupResult.updatedMapRender
      } else {
        // Group remains grouped after agent left
        updatedMapRender = updateGroupIconAfterAgentLeft(
          agentTelemetry,
          updatedMapRender,
          remainingAgentsInGeofence,
          leavingGeofenceId,
          assetKit,
          jibestreamController,
          iconScale,
          selectedGroupId,
          selectedTrackingId,
          removeGeofenceHighlight,
          removeSelectedAgent,
          removeAgentFromSelectedGroup
        )
      }
    }
  })
  return {
    nextInpixonAssetId,
    updatedMapRender,
  }
}
