import { fromEventPattern, Observable, EMPTY } from 'rxjs'
import { HubConnection, IHttpConnectionOptions } from '@microsoft/signalr'
import { shareReplay, switchMap, share, take } from 'rxjs/operators'
import { useCallback, useEffect, useMemo } from 'react'

import {
  createConnection,
  HubConnectionBuilderDelegate,
} from '../helpers/signalR/createConnection'

// This code is pulled from the following repository so that we can modify it for our specific use case
// https://github.com/known-as-bmf/react-signalr

type SendFunction = (methodName: string, arg?: unknown) => Promise<void>
type InvokeFunction = <TResponse = unknown>(
  methodName: string,
  arg?: unknown
) => Promise<TResponse>
type OnFunction = <TMessage = unknown>(
  methodName: string
) => Observable<TMessage>

interface UseSignalrHookResult {
  /**
   * Proxy to `HubConnection.invoke`.
   *
   * @typeparam TResponse - The expected response type.
   * @param methodName - The name of the server method to invoke.
   * @param arg - The argument used to invoke the server method. If no arg is passed or the value passed is undefined, nothing will be sent to the SignalR endpoint.
   *
   * @returns A promise that resolves what `HubConnection.invoke` would have resolved.
   *
   * @see https://docs.microsoft.com/fr-fr/javascript/api/%40aspnet/signalr/hubconnection?view=signalr-js-latest#invoke
   */
  invoke: InvokeFunction
  /**
   * Utility method used to subscribe to realtime events (`HubConnection.on`, `HubConnection.off`).
   *
   * @typeparam TMessage - The expected message type.
   * @param methodName - The name of the server method to subscribe to.
   *
   * @returns An observable that emits every time a realtime message is recieved.
   *
   * @see https://docs.microsoft.com/fr-fr/javascript/api/%40aspnet/signalr/hubconnection?view=signalr-js-latest#on
   * @see https://docs.microsoft.com/fr-fr/javascript/api/%40aspnet/signalr/hubconnection?view=signalr-js-latest#off
   */
  on: OnFunction
  /**
   * Proxy to `HubConnection.send`
   *
   * @param methodName - The name of the server method to invoke.
   * @param arg - The argument used to invoke the server method. If no arg is passed or the value passed is undefined, nothing will be sent to the SignalR endpoint.
   *
   * @returns A promise that resolves when `HubConnection.send` would have resolved.
   *
   * @see https://docs.microsoft.com/fr-fr/javascript/api/%40aspnet/signalr/hubconnection?view=signalr-js-latest#send
   */
  send: SendFunction
}

function getOrSetupConnection(
  hubUrl: string,
  options?: IHttpConnectionOptions,
  delegate?: HubConnectionBuilderDelegate
): Observable<HubConnection> {
  let connection$

  if (!connection$) {
    // if no connection is established, create one and wrap it in an shared replay observable
    connection$ = new Observable<HubConnection>((observer) => {
      const connection = createConnection(hubUrl, options, delegate)

      // when the connection closes
      connection.onclose(() => {
        // close the observable (trigger the teardown)
        observer.complete()
      })

      // start the connection and emit to the observable when the connection is ready
      void connection
        .start()
        .then(() => {
          observer.next(connection)
        })
        .catch(() => {
          connection.stop()
          observer.complete()
        })

      // teardown logic will be executed when there is no subscribers left (close the connection)
      return () => {
        void connection.stop()
      }
    }).pipe(
      // everyone subscribing will get the same connection
      // refCount is used to complete the observable when there is no subscribers left
      shareReplay({ refCount: true, bufferSize: 1 })
    )
  }

  return connection$
}

/**
 * Hook used to interact with a signalr connection.
 * Parameter changes (`hubUrl`, `options`) are not taken into account and will not rerender.
 *
 * @param hubUrl - The URL of the signalr hub endpoint to connect to.
 * @param options - Options object to pass to connection builder.
 *
 * @returns An object containing methods to interact with the hub connection.
 */
export function useEventSignalr(
  hubUrl: string,
  options?: IHttpConnectionOptions,
  delegate?: HubConnectionBuilderDelegate,
  enabled: boolean = true
): UseSignalrHookResult {
  // ignore hubUrl, options & delegate changes, todo: useRef, useState ?
  const connection$ = useMemo(
    () => (enabled ? getOrSetupConnection(hubUrl, options, delegate) : EMPTY),
    [enabled]
  )

  useEffect(() => {
    // used to maintain 1 active subscription while the hook is rendered
    const subscription = connection$.subscribe((connection: HubConnection) => {
      if (!enabled) {
        connection.stop()
      }
      return connection
    }) // todo: handle on complete (unexpected connection stop) ?

    return () => subscription.unsubscribe()
  }, [connection$, enabled])

  const send = useCallback<SendFunction>(
    (methodName: string, arg?: unknown) => {
      return connection$
        .pipe(
          // only take the current value of the observable
          take(1),
          // use the connection
          switchMap((connection) => {
            if (arg === undefined) {
              // no argument provided
              return connection.send(methodName)
            } else {
              return connection.send(methodName, arg)
            }
          })
        )
        .toPromise()
    },
    [connection$]
  )

  const invoke = useCallback<InvokeFunction>(
    <TResponse>(methodName: string, arg?: unknown) => {
      return connection$
        .pipe(
          // only take the current value of the observable
          take(1),
          // use the connection
          switchMap((connection) => {
            if (arg === undefined) {
              // no argument provided
              return connection.invoke<TResponse>(methodName)
            } else {
              return connection.invoke<TResponse>(methodName, arg)
            }
          })
        )
        .toPromise()
    },
    [connection$]
  )

  const on = useCallback<OnFunction>(
    <TMessage>(methodName: string) => {
      return connection$
        .pipe(
          // only take the current value of the observable
          take(1),
          // use the connection
          switchMap((connection) =>
            // create an observable from the server events
            fromEventPattern<TMessage>(
              (handler: (...args: unknown[]) => void) =>
                connection.on(methodName, handler),
              (handler: (...args: unknown[]) => void) =>
                connection.off(methodName, handler)
            )
          )
        )
        .pipe(share())
    },
    [connection$]
  )

  return { invoke, on, send }
}
