import { ButtonBase, Grid, Theme, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { ChevronRight, ExpandLess } from '@mui/icons-material'
import React from 'react'
import { Location } from '../../models/locations'
import LocationMenuList from './LocationMenuList'

interface Props {
  open: boolean
  isIDNRestrictedHierarchy?: boolean
  selectedCampus?: Location
  selectedBuilding?: Location
  selectedFloor?: Location
  campuses?: Location[]
  onCampusChange: (value: Location | undefined) => void
  onBuildingChange: (value: Location | undefined) => void
  onFloorChange: (value: Location | undefined) => void
  handleClose: () => void
  isDeselectable?: boolean
}

interface SelectionStylesProps {
  defaultSelection?: boolean
  menuOpen?: boolean
  first: boolean
  last: boolean
}

const usePopoverHeaderItemStyles = makeStyles<Theme, SelectionStylesProps>(() =>
  createStyles({
    root: {
      width: '232px',
      height: '100%',
    },
  })
)

const useTextStyles = makeStyles<Theme, SelectionStylesProps>(() =>
  createStyles({
    root: {
      color: ({ defaultSelection }) =>
        defaultSelection ? '#8d8d8d' : '#1c1b1b',
      margin: '0px 7px',
    },
  })
)
const useIconStyles = makeStyles<Theme, SelectionStylesProps>(() =>
  createStyles({
    root: {
      height: ({ menuOpen }) => (menuOpen ? 'initial' : '16px'),
      color: ({ first, last }) => (first || last ? '#666666' : '#c4c4c4'),
      marginLeft: ({ last }) => (last ? '8px' : '0px'),
      marginRight: ({ last }) => (last ? '8px' : '0px'),
    },
  })
)

const usePopoverHeaderStyles = makeStyles(() =>
  createStyles({
    root: {
      borderWidth: '0px 0px 1px 0px',
      borderStyle: 'solid',
      borderColor: '#e4e4e4',
      padding: '8px 0px',
    },
  })
)

const useListItemContainerStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: '#f5f5f5',
    },
  })
)

const useListItemItemStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '232px',
      borderRight: '1px solid #e4e4e4',
      '&:last-of-type': {
        borderRight: 'none',
      },
    },
  })
)

const LocationSelectMenu = React.forwardRef(
  (props: Props, ref): JSX.Element => {
    const {
      open,
      isIDNRestrictedHierarchy,
      campuses,
      selectedCampus,
      selectedBuilding,
      selectedFloor,
      onCampusChange,
      onBuildingChange,
      onFloorChange,
      handleClose,
      isDeselectable,
    } = props

    const popoverHeaderClasses = usePopoverHeaderStyles({})

    const listItemContainerClasses = useListItemContainerStyles()
    const listItemItemClasses = useListItemItemStyles()

    return (
      <>
        <Grid container wrap='nowrap' classes={popoverHeaderClasses}>
          <Grid
            container
            item
            justifyContent='space-between'
            alignItems='center'
            wrap='nowrap'
            classes={usePopoverHeaderItemStyles({
              menuOpen: open,
              first: false,
              last: false,
            })}
          >
            <Typography
              variant='body2'
              classes={useTextStyles({
                defaultSelection: !selectedCampus,
                menuOpen: open,
                first: false,
                last: false,
              })}
              noWrap
            >
              {selectedCampus?.name ?? 'All Building Groups'}
            </Typography>
            <ChevronRight
              classes={useIconStyles({
                defaultSelection: !selectedCampus,
                menuOpen: open,
                first: false,
                last: false,
              })}
            />
          </Grid>
          <Grid
            container
            item
            justifyContent='space-between'
            alignItems='center'
            wrap='nowrap'
            classes={usePopoverHeaderItemStyles({
              defaultSelection: !selectedBuilding,
              menuOpen: open,
              first: false,
              last: false,
            })}
          >
            <Typography
              variant='body2'
              classes={useTextStyles({
                defaultSelection: !selectedBuilding,
                menuOpen: open,
                first: false,
                last: false,
              })}
              noWrap
            >
              {selectedBuilding?.name ?? 'All Buildings'}
            </Typography>
            <ChevronRight
              classes={useIconStyles({
                defaultSelection: !selectedBuilding,
                menuOpen: open,
                first: false,
                last: false,
              })}
            />
          </Grid>
          <Grid
            container
            item
            justifyContent='space-between'
            alignItems='center'
            wrap='nowrap'
            classes={usePopoverHeaderItemStyles({
              defaultSelection: !selectedFloor,
              menuOpen: open,
              first: false,
              last: false,
            })}
          >
            <Typography
              variant='body2'
              classes={useTextStyles({
                defaultSelection: !selectedFloor,
                menuOpen: open,
                first: false,
                last: false,
              })}
              noWrap
            >
              {selectedFloor?.name ?? 'All Floors'}
            </Typography>
            <ButtonBase
              disabled={isIDNRestrictedHierarchy}
              onClick={handleClose}
              classes={{}}
              disableRipple
            >
              <ExpandLess
                classes={useIconStyles({
                  defaultSelection: !selectedFloor,
                  menuOpen: true,
                  first: false,
                  last: true,
                })}
              />
            </ButtonBase>
          </Grid>
        </Grid>
        <Grid container classes={listItemContainerClasses}>
          <Grid item classes={listItemItemClasses}>
            <LocationMenuList
              options={campuses ?? []}
              selectedLocation={selectedCampus}
              onChange={onCampusChange}
              isDeselectable={isDeselectable}
            />
          </Grid>
          <Grid item classes={listItemItemClasses}>
            <LocationMenuList
              options={selectedCampus?.children ?? []}
              selectedLocation={selectedBuilding}
              onChange={onBuildingChange}
              isDeselectable={isDeselectable}
            />
          </Grid>
          <Grid item classes={listItemItemClasses}>
            <LocationMenuList
              options={selectedBuilding?.children ?? []}
              selectedLocation={selectedFloor}
              onChange={onFloorChange}
              isDeselectable={isDeselectable}
            />
          </Grid>
        </Grid>
      </>
    )
  }
)

export default LocationSelectMenu
