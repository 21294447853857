import React from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { setCurrentLocationAction } from '../../actions/currentLocation'
import {
  setSelectedGroupIdAction,
  setSelectedTrackingIdAction,
} from '../../actions/mapGroups'
import { buildURL, isAnySecurityRole } from '../../helpers'
import { useAuth, useCurrentView, useFetchCurrentUser } from '../../hooks'
import { AgentType, Location, RealTimePageViews } from '../../models'
import { colors } from '../../styles'
import { Button } from '../Buttons'

interface Props {
  badgeId: string
  floorLocation?: Location
  onViewOnMap?: () => void
  isBanner?: boolean
}

const ViewStaffOnMapButton = (props: Props): JSX.Element => {
  const { badgeId, floorLocation, isBanner = false, onViewOnMap } = props

  const url = useLocation()
  const history = useNavigate()
  const { currentView } = useCurrentView<RealTimePageViews>()
  const dispatch = useDispatch()
  const { user } = useAuth()
  const { data: userInfo } = useFetchCurrentUser(user.id)

  const handleViewOnMap = () => {
    if (onViewOnMap) {
      onViewOnMap()
    }
    if (floorLocation) {
      dispatch(setCurrentLocationAction(floorLocation))
    }
    const newUrl = buildURL('/realtime', [
      ['view', 'map'],
      ['agentType', AgentType[AgentType.Staff]],
      ['badgeId', badgeId],
      ['zoom', '10'],
    ])

    if (url.pathname !== '/realtime' || currentView === 'list') {
      history(newUrl)
    } else if (newUrl !== url.pathname + url.search) {
      history(newUrl)
      dispatch(setSelectedGroupIdAction(undefined))
      dispatch(setSelectedTrackingIdAction(badgeId))
    }
  }

  const backgroundColor = () => {
    if (isBanner) {
      return colors.error
    } else if (isAnySecurityRole(userInfo)) {
      return colors.primary
    } else {
      return colors.transparent
    }
  }

  const textColor = () => {
    if (isBanner || isAnySecurityRole(userInfo)) {
      return colors.white
    } else {
      return colors.primary
    }
  }

  return (
    <div data-testid={`view-on-map-${badgeId}`}>
      <Button
        {...props}
        bgColor={backgroundColor()}
        textColor={textColor()}
        style={isBanner ? { borderColor: colors.white } : {}}
        variant='outlined'
        onClick={handleViewOnMap}
      >
        VIEW ON MAP
      </Button>
    </div>
  )
}

export default ViewStaffOnMapButton
