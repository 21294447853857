import React from 'react'
import { AlertActiveConfig } from '../../models/alert'
import DetailsPaneHeader from '../Drawer/DetailsPaneHeader'
import AlertDetailsPaneQuickActionButtons from './AlertDetailsPaneQuickActionsButtons'
import { DataTestIds } from '../../utils/test-utils/dataTestIds'

interface Props {
  alert: AlertActiveConfig
  canEdit: boolean
  isReviewed?: boolean
  onClose?: () => void
  deleteAlert?: (id: string) => void
  disableAlert?: (id: string) => void
}

function AlertDetailsPaneHeader(props: Props): React.ReactElement {
  const {
    alert,
    canEdit,
    isReviewed = false,
    onClose,
    deleteAlert,
    disableAlert,
  } = props

  return (
    <div data-testid={DataTestIds.alertDetailPaneHeader}>
      <DetailsPaneHeader
        handleClose={onClose}
        headerText={isReviewed ? `${alert.name} Review` : alert.name}
        unboldHeader={isReviewed ? true : false}
      />
      {!isReviewed && (
        <AlertDetailsPaneQuickActionButtons
          alert={alert}
          deleteAlert={deleteAlert}
          disableAlert={disableAlert}
          canEdit={canEdit}
        />
      )}
    </div>
  )
}

export default AlertDetailsPaneHeader
