export const buildURL = (pathName: string, parameters: string[][]): string => {
  const queryParams = new URLSearchParams()

  parameters.forEach((x) => {
    if (x.length >= 2) {
      queryParams.set(x[0], x[1])
    }
  })

  return `${pathName}?${queryParams.toString()}`
}
