import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, switchMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { OperationsPermissionAction } from '../actions'
import { OperationsPermissionsApi } from 'v2/middleware'
import { getOperationsPermissionsAction } from 'v2/actions/operationsPermissions'
import { OperationsPermissionsState } from 'v2/models'

type OperationsPermissionsEpics = Epic<
  OperationsPermissionAction,
  OperationsPermissionAction,
  OperationsPermissionsState,
  { operationsPermissionsApi: OperationsPermissionsApi }
>

export const getOperationsPermissionsEpic: OperationsPermissionsEpics = (
  action$,
  state,
  { operationsPermissionsApi }
) => {
  return action$.pipe(
    filter(isActionOf(getOperationsPermissionsAction.request)),
    switchMap(() =>
      from(operationsPermissionsApi.getAllOperationsPermissions()).pipe(
        map((permissions) =>
          getOperationsPermissionsAction.success(permissions)
        ),
        catchError((error) => of(getOperationsPermissionsAction.failure(error)))
      )
    )
  )
}
