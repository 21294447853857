import { createAsyncAction } from 'typesafe-actions'
import { Location } from '../models'

export const getAllLocationsAction = createAsyncAction(
  'GET_ALL_LOCATIONS_REQUEST',
  'GET_ALL_LOCATIONS_SUCCESS',
  'GET_ALL_LOCATIONS_FAILURE'
)<void, Location[], Error>()

export const getLocationAction = createAsyncAction(
  'GET_LOCATION_REQUEST',
  'GET_LOCATION_SUCCESS',
  'GET_LOCATION_FAILURE'
)<string, Location, Error>()

export const postLocationAction = createAsyncAction(
  'POST_LOCATION_REQUEST',
  'POST_LOCATION_SUCCESS',
  'POST_LOCATION_FAILURE'
)<Location, Location, Error>()

export const putLocationAction = createAsyncAction(
  'PUT_LOCATION_REQUEST',
  'PUT_LOCATION_SUCCESS',
  'PUT_LOCATION_FAILURE'
)<Location, Location, Error>()

export const deleteLocationAction = createAsyncAction(
  'DELETE_LOCATION_REQUEST',
  'DELETE_LOCATION_SUCCESS',
  'DELETE_LOCATION_FAILURE'
)<string, string, Error>()

export const putLocationsAction = createAsyncAction(
  'PUT_LOCATIONS_REQUEST',
  'PUT_LOCATIONS_SUCCESS',
  'PUT_LOCATIONS_FAILURE'
)<Location[], Location[], Error>()
