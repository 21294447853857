import React, { useMemo } from 'react'
import {
  BadgeCurrentLocation,
  Staff,
  StaffEvent,
  StaffEvents,
  Watchlist,
} from '../../../../models'
import { StaffRow } from '../../../../models/staffRow'
import { RightDrawerLayout } from '../../RightDrawer'
import DeprecatedStaffDetailPane from './DeprecatedStaffDetailPane'
import DeprecatedStaffDetailPaneHeader from './DeprecatedStaffDetailPaneHeader'
import { StaffFilters } from '../../../../models/filters'

interface RealTimeListPageOptions {
  badgeCurrentLocation: BadgeCurrentLocation[] | undefined
}

interface Props<T = StaffRow | Staff> {
  selectedStaff: T
  staffFilterState: StaffFilters
  watchList: Watchlist
  isRTMapPage: boolean
  activeDuressEvents: StaffEvent[]
  activeAssistEvents: StaffEvent[]
  onCloseDetailDrawer: () => void
  onEditStaff?: () => void
  realTimeListPageOptions?: RealTimeListPageOptions
  setFilterButtonToggled: (filterButtonStates: any) => void
  forceRefresh: () => void
}

const DeprecatedStaffDetailDrawer = (props: Props): JSX.Element => {
  const {
    selectedStaff,
    watchList,
    isRTMapPage,
    activeAssistEvents,
    activeDuressEvents,
    staffFilterState,
    onCloseDetailDrawer,
    onEditStaff,
    setFilterButtonToggled,
    forceRefresh,
  } = props

  const staffEvent = useMemo(() => {
    return (
      activeDuressEvents.find((e) => e.badgeId === selectedStaff.badgeIds[0]) ??
      activeAssistEvents.find((e) => e.badgeId === selectedStaff.badgeIds[0])
    )
  }, [activeAssistEvents, activeDuressEvents, selectedStaff.badgeIds])
  const isDuress = useMemo(() => {
    return (
      activeDuressEvents.findIndex(
        (e) => e.badgeId === selectedStaff.badgeIds[0]
      ) > -1
    )
  }, [activeDuressEvents, selectedStaff.badgeIds])
  const isAssist = useMemo(() => {
    return (
      activeAssistEvents.findIndex(
        (e) => e.badgeId === selectedStaff.badgeIds[0]
      ) > -1
    )
  }, [activeAssistEvents, selectedStaff.badgeIds])

  return (
    <RightDrawerLayout
      header={
        <DeprecatedStaffDetailPaneHeader
          staff={selectedStaff}
          onClose={onCloseDetailDrawer}
          isMap={isRTMapPage}
          isDuress={isDuress}
          isAssist={isAssist}
          staffEvent={staffEvent}
          watchlist={watchList}
          staffFilterState={staffFilterState}
          onEditStaff={onEditStaff}
          setFilterButtonToggled={setFilterButtonToggled}
          forceRefresh={forceRefresh}
        />
      }
      content={
        <DeprecatedStaffDetailPane staff={selectedStaff} isDuress={isDuress} />
      }
    />
  )
}

export default DeprecatedStaffDetailDrawer
