import moment from 'moment'
import { localMoment } from './date'

// Thanks Twitter!
export const timeSince = (timeStamp: Date): string | undefined => {
  const now = new Date()
  const secondsPast = (now.getTime() - timeStamp.getTime()) / 1000
  if (secondsPast < 60) {
    return Math.round(secondsPast) + 's'
  }
  if (secondsPast < 3600) {
    return Math.round(secondsPast / 60) + 'm'
  }
  if (secondsPast <= 86400) {
    return Math.round(secondsPast / 3600) + 'h'
  }
  if (secondsPast > 86400) {
    const day = timeStamp.getDate()
    const monthRegex = timeStamp.toDateString().match(/ [a-zA-Z]*/)
    const month = monthRegex !== null ? monthRegex[0].replace(' ', '') : ''
    const year =
      timeStamp.getFullYear() === now.getFullYear()
        ? ''
        : ' ' + timeStamp.getFullYear()
    return day + ' ' + month + year
  }
}

// Sort an object with a key of 'sortBy'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const objectArraySort =
  (sortBy: string) =>
  (a: any, b: any): number =>
    a[sortBy] > b[sortBy] ? 1 : -1

// Create an array of range
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/from#Sequence_generator_(range)
export const range = (start: number, stop: number, step: number): number[] =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step)

// Generate array for punchdowns
// ["1", "2", ..., "24"]
export const initCollector = (): string[] =>
  Array.from({ length: 24 }, (_, i) => (i + 1).toString())

// Recursive immutable check.
// Use this for TESTING cloned objects (remove calls before committing)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deepFreeze = (object: any): any => {
  // Retrieve the property names defined on object
  const propNames = Object.getOwnPropertyNames(object)
  // Freeze properties before freezing self
  for (const name of propNames) {
    const value = object[name]
    object[name] =
      value && typeof value === 'object' ? deepFreeze(value) : value
  }
  return Object.freeze(object)
}

export const deletePropWithoutMutation = <T extends object = {}>(
  obj: T,
  prop: keyof T
) => {
  const newObj = { ...obj }
  delete newObj[prop]
  return newObj
}

export const timeBetween = (
  datePart: moment.MomentFormatSpecification,
  dateFrom: Date,
  dateTo?: Date
): number => {
  const to = dateTo ?? localMoment()
  return localMoment(dateFrom).diff(localMoment(to), 'minute')
}

export const guidValidation = (guid: string) => {
  if (guid === nullGuid) {
    guid = ''
  }
  return guid
}

export const cleanUpNullGuid = (object: any, keys: string[]) => {
  keys.forEach((key) => {
    if (object[key] === nullGuid) {
      object[key] = undefined
    }
  })
  return object
}

export const nullGuid = '00000000-0000-0000-0000-000000000000'
