export enum HardwarePageView {
  List,
  Map,
  Issues,
}

export enum AssetPageView {
  List,
  Import,
}

export enum WalkingSetupModalOptions {
  DeviceDetails,
  DeviceHistory,
  DeviceIssuesForm,
  DeviceConfiguration,
  DeviceUnconfigure,
  BadgeModal,
  ConfiguringNewDevice,
  ConfiguringRequest,
  ConfiguringSuccess,
  UnconfiguringDevice,
  UnconfiguringSuccess,
  DeviceNotFound,
  AlreadyConfigured,
  MultipleDevices,
  DeviceOverwrite,
}

export enum DeviceConfigurationStatus {
  false,
  requesting,
  success,
  failure,
}

export enum WalkingSetupErrors {
  DeviceNotFound,
  AlreadyConfigured,
  MultipleDevices,
  DeviceOverwrite,
}
