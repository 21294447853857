import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { TenantConfigurationAction } from '../actions'
import { getCurrentTenantConfigurationAction } from '../actions/tenantConfiguration'
import { TenantConfigurationApi } from '../middleware'
import { TenantConfigurationState } from '../models'

type TenantConfigurationEpic = Epic<
  TenantConfigurationAction,
  TenantConfigurationAction,
  TenantConfigurationState,
  { tenantConfigurationApi: TenantConfigurationApi }
>

export const getCurrentTenantConfigurationEpic: TenantConfigurationEpic = (
  action$,
  state$,
  { tenantConfigurationApi }
) => {
  return action$.pipe(
    filter(isActionOf(getCurrentTenantConfigurationAction.request)),
    mergeMap((action) =>
      from(tenantConfigurationApi.getCurrentTenantConfiguration()).pipe(
        map((config) => getCurrentTenantConfigurationAction.success(config)),
        catchError((error) =>
          of(getCurrentTenantConfigurationAction.failure(error))
        )
      )
    )
  )
}
