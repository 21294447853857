import { differenceInSeconds } from 'date-fns'
import { isEqual } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { mapBadgeEventTelemetryToBaseBadgeTelemetry } from '../helpers/telemetry'
import { TelemetryEventLocation } from '../models'
import { BaseBadgeTelemetryMessage } from '../models/telemetry/baseBadgeTelemetry'

/**
 * Combine location telemetry and agent event telemetry received via SignalR and
 * map them to the same data model
 * @param baseBadgeTelemetry location telemetry
 * @param badgeEventTelemetry  agent event telemetry
 * @returns combined telemetry from both location and agent event telemetries
 */
export const useCombinedLiveTelemetry = (
  baseBadgeTelemetry: BaseBadgeTelemetryMessage[],
  badgeEventTelemetry: TelemetryEventLocation[]
): {
  badgeTelemetry: BaseBadgeTelemetryMessage[]
  lastLiveTelemetry: Date
  lastLatency: number
  clearBaseTelemetry: () => void
} => {
  const [lastLiveTelemetry, setLastLiveTelemetry] = useState<Date>(new Date())
  const [lastLatency, setLastLatency] = useState<number>(0)
  const [baseTelemetry, setBaseTelemetry] = useState<
    BaseBadgeTelemetryMessage[]
  >([])

  const cachedBaseBadgeTelemetryRef = useRef<BaseBadgeTelemetryMessage[]>([])
  const cacheBadgeEventTelemetryRef = useRef<TelemetryEventLocation[]>([])
  const cacheLastLiveTelemetryRef = useRef<Date>(lastLiveTelemetry)

  useEffect(() => {
    const combinedTelemetry: BaseBadgeTelemetryMessage[] = []
    if (
      baseBadgeTelemetry.length > 0 &&
      !isEqual(baseBadgeTelemetry, cachedBaseBadgeTelemetryRef.current)
    ) {
      const transformedTelemetry = baseBadgeTelemetry.map((x) => ({
        ...x,
        isAgentEventTelemetry: false,
      }))
      combinedTelemetry.push(...transformedTelemetry)
      cachedBaseBadgeTelemetryRef.current = baseBadgeTelemetry
    }
    if (
      badgeEventTelemetry.length > 0 &&
      !isEqual(badgeEventTelemetry, cacheBadgeEventTelemetryRef.current)
    ) {
      const transformBadgeEventTelemetry =
        mapBadgeEventTelemetryToBaseBadgeTelemetry(badgeEventTelemetry)
      combinedTelemetry.push(...transformBadgeEventTelemetry)
      cacheBadgeEventTelemetryRef.current = badgeEventTelemetry
    }
    if (combinedTelemetry.length > 0) {
      setBaseTelemetry(combinedTelemetry)
      if (
        differenceInSeconds(new Date(), cacheLastLiveTelemetryRef.current) > 3
      ) {
        const updatedLastLiveTelemetry = new Date()
        setLastLiveTelemetry(updatedLastLiveTelemetry)
        cacheLastLiveTelemetryRef.current = updatedLastLiveTelemetry
        setLastLatency(
          differenceInSeconds(
            new Date(),
            new Date(combinedTelemetry[0].timestamp.toString())
          )
        )
      }
    }
  }, [baseBadgeTelemetry, badgeEventTelemetry])

  const clearBaseTelemetry = () => setBaseTelemetry([])
  return {
    badgeTelemetry: baseTelemetry,
    lastLiveTelemetry,
    lastLatency,
    clearBaseTelemetry,
  }
}
