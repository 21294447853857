import { AssetKitInstance } from '../../models'
import {
  InpixonAssetAgentCreateOptions,
  InpixonAssetGroupCreateOptions,
} from '../../models/inpixonMap'
import {
  buildInpixonAssetConfigsForNewAgents,
  buildInpixonAssetConfigsForNewGroups,
  createInpixonAssets,
} from '../inpixon'

export function executeInitialMapRender(
  agentsToCreate: InpixonAssetAgentCreateOptions[],
  groupsToCreate: InpixonAssetGroupCreateOptions[],
  assetKit: AssetKitInstance,
  assetId: number,
  useGeofenceCentroid: boolean,
  iconScale: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  geofenceInstances: any[],
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any,
  addGeofenceHighlights: (geofenceIds: number[]) => void,
  removeAllGeofenceHighlights: () => void
): number {
  removeAllGeofenceHighlights()
  const { inpixonAssetConfigs, nextInpixonAssetId } =
    buildInpixonAssetConfigsForNewAgents(
      agentsToCreate,
      assetId,
      useGeofenceCentroid,
      geofenceInstances,
      jibestreamController
    )

  const groupInpixonAssetConfigs = buildInpixonAssetConfigsForNewGroups(
    groupsToCreate,
    useGeofenceCentroid,
    iconScale,
    geofenceInstances,
    jibestreamController
  )
  inpixonAssetConfigs.push(...groupInpixonAssetConfigs)
  if (inpixonAssetConfigs.length > 0) {
    createInpixonAssets(assetKit, inpixonAssetConfigs, jibestreamController)
  }
  const geofenceIdsWithActiveDuress = [
    ...agentsToCreate
      .filter((agent) => agent.hasActiveDuress)
      .map((agent) => agent.geoFenceId),
    ...groupsToCreate
      .filter((group) => group.hasActiveDuress)
      .map((group) => group.geoFenceId),
  ]
  if (geofenceIdsWithActiveDuress.length > 0) {
    addGeofenceHighlights(geofenceIdsWithActiveDuress)
  }
  return nextInpixonAssetId
}
