import { useCallback, useEffect, useMemo, useState } from 'react'
import { useFetchUserPreferences, useSearchAndFilters } from '.'
import { batteryStatusSearch } from '../filters'
import {
  badgeSignalStatus,
  createAssetRow,
  isCurrentLocationOrChild,
} from '../helpers'
import {
  Asset,
  BadgeCurrentLocation,
  BadgeSignalStatus,
  FetchingStatus,
  LocationType,
} from '../models'
import { AssetRow } from '../models/assetRow'
import { useFetchGeofencesByLocation } from './entities/useFetchGeofencesByLocation'
import { useFetchLocations } from './entities/useFetchLocations'
import { useFetchVenues } from './entities/useFetchVenues'
import { useFetchWatchlist } from './entities/useFetchWatchlist'
import { useAuth } from './useAuth'
import useBadgeLocationMonitor from './useBadgeLocationMonitor'
import { useCurrentLocation } from './useCurrentLocation'
import useCurrentUnitLocations from './useCurrentUnitLocations'
import { useFetchManufacturers } from './useFetchManufacturers'
import { useListTelemetry } from './useListTelemetry'
import useDeprecatedAssetFilters from './filters/useDeprecatedAssetFilters'

const useRealTimeAssetTableData = (
  liveTelemetryEnabled: boolean,
  refreshTable: boolean,
  assetsByLocation: Asset[],
  assets: Asset[] | undefined,
  assetsStatus: FetchingStatus | undefined
) => {
  const { user } = useAuth()
  const { data: watchlist } = useFetchWatchlist(user.id)
  const { data: venues } = useFetchVenues()
  const { data: locations } = useFetchLocations()
  const { currentLocation } = useCurrentLocation()
  const { filter: assetFilter } = useDeprecatedAssetFilters<AssetRow>()
  const { data: userPreferences } = useFetchUserPreferences()

  const { manufacturers } = useFetchManufacturers()
  const [assetRows, setAssetRows] = useState<AssetRow[] | undefined>(undefined)
  const { currentUnitLocations, allUnits } = useCurrentUnitLocations()

  const watchlistItems = useMemo(
    () => (watchlist ? Object.values(watchlist) : []),
    [watchlist]
  )

  const { data: geofences, status: geofenceStatus } =
    useFetchGeofencesByLocation(
      Object.values(locations).find((x) => x.level === LocationType.Root)?.id ??
        ''
    )

  // set signalr filters for given location+map reference views
  const {
    badgeLocations: assetBadgeLocations,
    lastLiveTelemetry: assetLastLiveTelemetry,
    badgeLocationsChangedAt,
  } = useListTelemetry(
    locations,
    geofences,
    venues,
    assetsByLocation ?? [],
    [],
    assetsStatus || FetchingStatus.Success,
    FetchingStatus.Success,
    [],
    []
  )
  const allAssetBadges = useMemo(() => {
    return assetsByLocation
      ? assetsByLocation
          .filter((asset) => !!asset.badgeIds.length)
          .map((asset) => asset.badgeIds[0])
      : []
  }, [assetsByLocation])

  const shouldMonitorBadgeLocation = useCallback(
    (badgeLocation: BadgeCurrentLocation): boolean => {
      return allAssetBadges.includes(badgeLocation.badgeId)
    },
    [allAssetBadges]
  )

  const {
    state: assetBadgeLocationsState, // use to send custom location updates
  } = useBadgeLocationMonitor(
    liveTelemetryEnabled ? assetBadgeLocations : [],
    shouldMonitorBadgeLocation
  )

  useEffect(() => {
    if (
      assets &&
      Object.values(geofences).length &&
      Object.values(locations).length
    ) {
      const rows = (assetsByLocation ?? [])
        .filter((asset) => {
          const status = badgeSignalStatus(asset.badgeTelemetry?.timestamp)
          return !asset.badgeTelemetry?.timestamp ||
            status === BadgeSignalStatus.TimedOut
            ? false
            : true
        })
        .map((asset) =>
          createAssetRow(
            asset,
            assetBadgeLocationsState,
            geofences,
            watchlistItems,
            locations,
            userPreferences
          )
        )
        .filter((asset) => {
          return (
            allUnits ||
            currentUnitLocations?.includes(asset?.roomLocation?.id ?? '')
          )
        })
      setAssetRows(rows)
    }
  }, [
    assetsByLocation,
    assetBadgeLocationsState,
    manufacturers,
    locations,
    watchlistItems,
    geofences,
    currentUnitLocations,
    assets,
    userPreferences,
    allUnits,
  ])

  const assetTableData = useSearchAndFilters(
    assetRows?.filter((x) => {
      return isCurrentLocationOrChild(
        x?.floorLocation?.id ?? '',
        currentLocation,
        locations
      )
    }),
    assetFilter.criteria,
    [
      'agentGuid',
      'badgeIds',
      'assetTypeId',
      'assetSubTypeId',
      'hasWorkOrder',
      'manufacturerGuid',
      'hasRecall',
      'iconUrl',
      'badgeTelemetry',
      'icon',
      'badgeLocation',
      'signalTypeIds',
    ],
    {
      preventativeMaintenanceDate: 'datetime',
      tagBatteryChangeDate: 'datetime',
      isLowBattery: batteryStatusSearch,
    }
  )

  return {
    assetTableData,
    badgeLocationsChangedAt,
    badgeLocations: assetBadgeLocations,
  }
}

export default useRealTimeAssetTableData
