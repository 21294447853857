import { Epic } from 'redux-observable'
import { filter, mergeMap, map, catchError } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { from, of } from 'rxjs'
import {
  getAllLocationsAction,
  getLocationAction,
  postLocationAction,
  putLocationAction,
  deleteLocationAction,
  putLocationsAction,
} from '../actions/locations'
import { LocationsApi } from '../middleware/locations'
import { LocationAction, SelectableLocationsAction } from '../actions'
import { LocationsState } from '../models'
import { getSelectableLocationsAction } from '../actions/selectableLocations'

type LocationsEpic = Epic<
  LocationAction | SelectableLocationsAction,
  LocationAction | SelectableLocationsAction,
  LocationsState,
  { locationsApi: LocationsApi }
>
export const getAllLocationsEpic: LocationsEpic = (
  action$,
  state,
  { locationsApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllLocationsAction.request)),
    mergeMap(() =>
      from(locationsApi.getAllLocations()).pipe(
        map((payload) => getAllLocationsAction.success(payload)),
        catchError((error) => of(getAllLocationsAction.failure(error)))
      )
    )
  )
}

export const getLocationEpic: LocationsEpic = (
  action$,
  state,
  { locationsApi }
) => {
  return action$.pipe(
    filter(isActionOf(getLocationAction.request)),
    mergeMap((action) =>
      from(locationsApi.getLocation(action.payload)).pipe(
        map((payload) => getLocationAction.success(payload)),
        catchError((error) => of(getLocationAction.failure(error)))
      )
    )
  )
}

export const postLocationEpic: LocationsEpic = (
  action$,
  state,
  { locationsApi }
) => {
  return action$.pipe(
    filter(isActionOf(postLocationAction.request)),
    mergeMap((action) =>
      from(locationsApi.postLocation(action.payload)).pipe(
        map((payload) => postLocationAction.success(payload)),
        catchError((error) => of(postLocationAction.failure(error)))
      )
    )
  )
}

export const putLocationEpic: LocationsEpic = (
  action$,
  state,
  { locationsApi }
) => {
  return action$.pipe(
    filter(isActionOf(putLocationAction.request)),
    mergeMap((action) =>
      from(locationsApi.putLocation(action.payload)).pipe(
        mergeMap((payload) => [
          putLocationAction.success(payload),
          getSelectableLocationsAction.request(),
        ]),
        catchError((error) => of(putLocationAction.failure(error)))
      )
    )
  )
}

export const putLocationsEpic: LocationsEpic = (
  action$,
  state,
  { locationsApi }
) => {
  return action$.pipe(
    filter(isActionOf(putLocationsAction.request)),
    mergeMap((action) =>
      from(locationsApi.putLocations(action.payload)).pipe(
        mergeMap((payload) => [
          putLocationsAction.success(payload),
          getSelectableLocationsAction.request(),
        ]),
        catchError((error) => of(putLocationsAction.failure(error)))
      )
    )
  )
}

export const deleteLocationEpic: LocationsEpic = (
  action$,
  state,
  { locationsApi }
) => {
  return action$.pipe(
    filter(isActionOf(deleteLocationAction.request)),
    mergeMap((action) =>
      from(locationsApi.deleteLocation(action.payload)).pipe(
        map((payload) => deleteLocationAction.success(payload)),
        catchError((error) => of(deleteLocationAction.failure(error)))
      )
    )
  )
}
