import { Building } from '../models'
import { BaseService } from './baseService'

export class BuildingsApi extends BaseService {
  constructor() {
    super({ entityType: 'buildings' })
  }
  route = 'buildings'

  async getAllBuildings(): Promise<Building[]> {
    return await this.get<Building[]>(this.route)
  }
}
