import { EntityResult, useEntities } from '../useEntities'
import {
  LocationGeofences,
  LocationGeofencesState,
} from '../../models/locationGeofence'
import { getAllGeofencesByLocation } from '../../actions/locationGeofences'

const useFetchGeofencesByLocation = (
  locationGuid: string
): EntityResult<LocationGeofences> =>
  useEntities<LocationGeofencesState, LocationGeofences>(
    Boolean(locationGuid && locationGuid.length)
      ? () => getAllGeofencesByLocation.request(locationGuid)
      : undefined,
    (state: any) => state.locationGeofences // eslint-disable-line @typescript-eslint/no-explicit-any
  )

export { useFetchGeofencesByLocation }
