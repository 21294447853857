import { useEffect, useCallback, useState } from 'react'

const doc = document as any
const defaultHideenProperties = ['hidden', 'visibilitychange']
const msHiddenProperties = ['msHidden', 'msvisibilitychange']
const webkitHiddenProperties = ['webkitHidden', 'webkitvisibilitychange']

const isUndefined = (obj: any): boolean => typeof obj === 'undefined'

const isDocumentHidden = (isHiddenProperty: any): boolean =>
  doc[isHiddenProperty]

const getVisibilityPropertyNames = () => {
  // Opera 12.10 and Firefox 18 and later support
  if (!isUndefined(doc.hidden)) {
    return defaultHideenProperties
  }

  if (!isUndefined(doc.msHidden)) {
    return msHiddenProperties
  }

  if (!isUndefined(doc.webkitHidden)) {
    return webkitHiddenProperties
  }

  return defaultHideenProperties
}

interface VisibilityChangeProps {
  notifyAfterNotVisibleMiliseconds?: number
  enabled?: boolean
}

export enum VisibilityState {
  Hidden,
  Visible,
}

export default function useBrowserVisibilityChange(
  props: VisibilityChangeProps
) {
  const { enabled = true } = props

  const [visible, setVisible] = useState<boolean>(true)

  const [hiddenProperty, visibilityChangeProperty] =
    getVisibilityPropertyNames()

  const handlVisibilityChange = useCallback(
    () => setVisible(!isDocumentHidden(hiddenProperty)),
    [hiddenProperty]
  )

  useEffect(() => {
    if (enabled) {
      document.addEventListener(visibilityChangeProperty, handlVisibilityChange)
    } else {
      document.removeEventListener(
        visibilityChangeProperty,
        handlVisibilityChange
      )
    }

    return () => {
      document.removeEventListener(
        visibilityChangeProperty,
        handlVisibilityChange
      )
    }
  }, [enabled, visibilityChangeProperty, handlVisibilityChange])

  return { visible }
}
