import React from 'react'
import { RightDrawerLayout } from '../Common/RightDrawer'
import UserDetailPaneHeader from './UserDetailPaneHeader'
import UserDetailsPane from './UserDetailsPane'
import { UserRow } from './UserManagementList'
import { Roles, StaffMembers, User } from '../../models'

interface Props<T> {
  selectedUser: UserRow<T>
  roles: Roles
  staff?: StaffMembers
  isOpsUser: boolean
  onEditUser: () => void
  onClose: () => void
}

const UserDetailDrawer = <T extends User>(props: Props<T>): JSX.Element => {
  const { selectedUser, roles, staff, isOpsUser, onClose, onEditUser } = props
  return (
    <RightDrawerLayout
      header={
        <UserDetailPaneHeader
          user={selectedUser}
          onClose={onClose}
          onEditUser={onEditUser}
        />
      }
      content={
        <UserDetailsPane
          user={selectedUser}
          roles={roles}
          staff={staff}
          isOpsUser={isOpsUser}
        />
      }
    />
  )
}

export default UserDetailDrawer
