import { getType } from 'typesafe-actions'
import { BaseBadgeTelemetryAction } from '../actions'
import { setBaseBadgeTelemetryAction } from '../actions/baseBadgeTelemetry'
import {
  BaseBadgeTelemetryState,
  BaseBadgeTelemetryStore,
} from '../models/telemetry'

const initialState: BaseBadgeTelemetryStore = {
  badgeTelemetry: [],
}

const baseBadgeTelemetryReducer = (
  state: BaseBadgeTelemetryStore = initialState,
  action: BaseBadgeTelemetryAction
): BaseBadgeTelemetryState => {
  switch (action.type) {
    case getType(setBaseBadgeTelemetryAction):
      return {
        badgeTelemetry: action.payload,
      }
    default:
      return state
  }
}

export default baseBadgeTelemetryReducer
