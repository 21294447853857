import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useGlobalFilterState } from '.'
import { FilterProvider } from '../../filters'
import { setGlobalUserFiltersAction } from '../../actions/globalFilters'
import { createQuery, whereValue } from '../../filters'
import { roleIdsToNames } from '../../helpers'
import { RolesState, User, UserFilters } from '../../models'

type UserFiltersProvider<T extends User = User> = FilterProvider<T, UserFilters>
const useUserFiltersState = <
  T extends User = User
>(): UserFiltersProvider<T> => {
  const {
    filterState: filters,
    setFilterState: setFilters,
    updateFilterState: mergeFilters,
    clearFilterState: clearFilters,
  } = useGlobalFilterState(
    (globalFilters) => globalFilters.users,
    setGlobalUserFiltersAction
  )

  const roles = useSelector(({ roles }: { roles: RolesState }) => roles.data)

  const filter = useMemo(() => {
    const usersFilter = createQuery<T>([
      whereValue(
        (user) =>
          filters.selectedRoles.some((selectedRole) =>
            roleIdsToNames(user.roleIds, roles).includes(selectedRole.name)
          ),
        filters.selectedRoles
      ),
    ])
    return usersFilter
  }, [filters, roles])

  return {
    filterState: filters,
    setFilterState: setFilters,
    updateFilterState: mergeFilters,
    clearFilterState: clearFilters,
    filterCount: filter.filterCount,
    filter,
  }
}

export default useUserFiltersState
