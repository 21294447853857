import { createAsyncAction } from 'typesafe-actions'
import {
  Sensor,
  SensorBadgePayload,
  SensorConfigure,
  SensorUnconfigure,
} from '../models/sensors'
import { HttpErrorResponse } from '../services/http'

export const getAllSensorsByLocationIdAction = createAsyncAction(
  'GET_ALL_SENSORS_BY_LOCATION_ID_REQUEST',
  'GET_ALL_SENSORS_BY_LOCATION_ID_SUCCESS',
  'GET_ALL_SENSORS_BY_LOCATION_ID_FAILURE'
)<string, Sensor[], Error>()

export const getAllSensorsAction = createAsyncAction(
  'GET_ALL_SENSORS_REQUEST',
  'GET_ALL_SENSORS_SUCCESS',
  'GET_ALL_SENSORS_FAILURE'
)<void, Sensor[], HttpErrorResponse>()

export const getAllSensorsBySensorIdAction = createAsyncAction(
  'GET_ALL_SENSORS_BY_SENSOR_ID_REQUEST',
  'GET_ALL_SENSORS_BY_SENSOR_ID_SUCCESS',
  'GET_ALL_SENSORS_BY_SENSOR_ID_FAILURE'
)<string, Sensor[], HttpErrorResponse>()

export const putSensorAction = createAsyncAction(
  'PUT_SENSOR_REQUEST',
  'PUT_SENSOR_SUCCESS',
  'PUT_SENSOR_FAILURE'
)<Sensor, Sensor, HttpErrorResponse>()

export const getSensorBadgesAction = createAsyncAction(
  'GET_SENSOR_BADGES_REQUEST',
  'GET_SENSOR_BADGES_SUCCESS',
  'GET_SENSOR_BADGES_FAILURE'
)<string, SensorBadgePayload, HttpErrorResponse>()

export const getSensorBadgesBySensorAction = createAsyncAction(
  'GET_SENSOR_BADGES_BY_SENSOR_REQUEST',
  'GET_SENSOR_BADGES_BY_SENSOR_SUCCESS',
  'GET_SENSOR_BADGES_BY_SENSOR_FAILURE'
)<string, SensorBadgePayload, HttpErrorResponse>()

export const getLastSeenBadgesAction = createAsyncAction(
  'GET_LAST_SEEN_SENSOR_BADGES_REQUEST',
  'GET_LAST_SEEN_SENSOR_BADGES_SUCCESS',
  'GET_LAST_SEEN_SENSOR_BADGES_FAILURE'
)<string, SensorBadgePayload, HttpErrorResponse>()

export const configureSensorAction = createAsyncAction(
  'CONFIGURE_SENSOR_REQUEST',
  'CONFIGURE_SENSOR_SUCCESS',
  'CONFIGURE_SENSOR_FAILURE'
)<SensorConfigure, string, Error>()

export const unconfigureSensorAction = createAsyncAction(
  'UNCONFIGURE_SENSOR_REQUEST',
  'UNCONFIGURE_SENSOR_SUCCESS',
  'UNCONFIGURE_SENSOR_FAILURE'
)<SensorUnconfigure, string, Error>()
