import {
  convertLatLonToJibestreamCoordinates,
  getGeofenceInstancesCenterByPoint,
} from '.'

export function calculateAssetPositionCoords(
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  geofenceInstances: any[],
  lat: number,
  lon: number,
  mapId: number,
  useGeofenceCentroid: boolean
): [number, number] {
  const jibestreamCoords = convertLatLonToJibestreamCoordinates(
    jibestreamController,
    lat,
    lon
  )
  const geofenceCentroidCoords = getGeofenceInstancesCenterByPoint(
    jibestreamCoords,
    geofenceInstances,
    mapId
  )
  if (geofenceCentroidCoords.length === 0) {
    return jibestreamCoords
  }
  const positionCoords: [number, number] = useGeofenceCentroid
    ? [geofenceCentroidCoords[0], geofenceCentroidCoords[1]]
    : jibestreamCoords
  return positionCoords
}
