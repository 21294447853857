import React from 'react'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() =>
  createStyles({
    sectionColumn: {
      display: 'flex',
      gap: '20px',
      flexDirection: 'row',
      minWidth: '320px',
      maxWidth: '450px',
      flex: 1,
      wordBreak: 'break-all',
      justifyContent: 'space-between',
    },
  })
)

interface Props {
  style?: React.CSSProperties
  children: React.ReactNode
}

const UserProfileColumn: React.FC<Props> = (props: Props) => {
  const { style, children } = props
  const classes = useStyles()

  return (
    <div style={style} className={classes.sectionColumn}>
      {children}
    </div>
  )
}

export default UserProfileColumn
