import Snackbar from '@mui/material/Snackbar'
import Typography from '@mui/material/Typography'
import React from 'react'

interface Props {
  status: readonly string[]
  clearStatus: () => void
}

const Notifications = (props: Props): JSX.Element => {
  const { status, clearStatus } = props

  const [open, setOpen] = React.useState<boolean>(false)
  const [message, setMessage] = React.useState<string | null>(null)

  React.useEffect(() => {
    if (status.length) {
      setMessage(status[0])
      setOpen(true)
    }
    return () => {
      setMessage(null)
    }
  }, [status])

  const handleClose = () => {
    setOpen(false)
  }

  // this is just a helper to ensure the notification message begins with a capital letter
  const capitalizeMessage = (message: string) => {
    return `${message.charAt(0).toUpperCase()}${message.slice(1)}`
  }

  return message ? (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={open}
      autoHideDuration={2000}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      onClose={handleClose}
      message={
        <Typography color='secondary' id='message-id'>
          {capitalizeMessage(message)}
        </Typography>
      }
      TransitionProps={{
        onExited: clearStatus,
      }}
    />
  ) : (
    <></>
  )
}

export default Notifications
