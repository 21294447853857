import { AssetKitInstance } from '../../models'
import { InpixonAssetGroupCreateOptions } from '../../models/inpixonMap'
import { GroupIconUpdate } from '../../models/realtimeMap'
import {
  buildInpixonAssetConfigsForNewGroup,
  createInpixonAssets,
} from '../inpixon'

/**
 * Create new group icon with dummy iconUrl in order to hide the group icon until ready to show
 * @returns the actual group icon config with the correct icon url that can be used to later show the group icon
 */
export function createNewGroup(
  groupToCreate: InpixonAssetGroupCreateOptions,
  assetKit: AssetKitInstance,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  geofenceInstances: any[],
  useGeofenceCentroid: boolean,
  iconScale: number
): GroupIconUpdate {
  const groupInpixonAssetConfig = buildInpixonAssetConfigsForNewGroup(
    groupToCreate,
    useGeofenceCentroid,
    iconScale,
    geofenceInstances,
    jibestreamController
  )
  // addGeofenceHighlight(groupToCreate.geoFenceId)
  // This is a hack until Inpixon fixes the issue with inconsistent
  // asset creation in the callback passed into the onComplete callback
  // of updateAssetWithPosition()
  const hiddenGroupConfig = {
    ...groupInpixonAssetConfig,
    url: 'hiddenGroup',
  }
  createInpixonAssets(assetKit, [hiddenGroupConfig], jibestreamController)
  return {
    geofenceId: groupInpixonAssetConfig.id,
    url: groupInpixonAssetConfig.url,
  }
}
