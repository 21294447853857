import React, { ReactNode, useEffect, useMemo, useState, useRef } from 'react'
import { TextField, Checkbox } from '@mui/material'
import { Autocomplete } from '@mui/material'
import { defaultAutocompleteProps } from '../../helpers/filters'
import { useFormStyle } from '../../styles/index'

interface Props<T, K extends keyof T> {
  value: T[] | undefined
  items: T[]
  itemKey: K
  itemValueCompareKey: K // used for comparing item equality
  label: string
  placeholder?: string
  handleChange: (event: any, selected: T[]) => void
  dataTestId?: string
}

const SortedAutocomplete = <T extends object, K extends keyof T>(
  props: Props<T, K>
): JSX.Element => {
  const {
    value,
    items,
    itemKey,
    itemValueCompareKey,
    label,
    placeholder,
    handleChange,
    dataTestId,
  } = props
  const { props: autoCompleteProps } = defaultAutocompleteProps()
  const [loadingText, setLoadingText] = useState<string>('Loading...')

  const sortedData = useMemo(() => {
    if (items && items.length > 0) {
      setLoadingText('No options')
    }
    return items.sort((a, b) =>
      String(a[itemKey])
        .toLowerCase()
        .localeCompare(String(b[itemKey]).toLowerCase())
    )
  }, [itemKey, items])

  const classes = useFormStyle()

  const valueInitialRef = useRef([])
  const [valueLocal, setValueLocal] = useState<T[]>(valueInitialRef.current)

  // autocomplete checkbox selected state depends on referential equality
  // build local value state from the item list so that referential equality works
  useEffect(() => {
    if (!value) {
      setValueLocal(valueInitialRef.current)
      return
    }

    const valueTemp: T[] = []

    items.forEach((item: T) => {
      if (
        value.find(
          (v) =>
            String(v[itemValueCompareKey]) === String(item[itemValueCompareKey])
        )
      ) {
        valueTemp.push(item)
      }
    })

    setValueLocal(valueTemp)
  }, [itemValueCompareKey, items, value])

  return (
    <Autocomplete
      multiple
      value={valueLocal}
      options={sortedData}
      getOptionLabel={(item): string => String(item[itemKey])}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option[itemValueCompareKey]}>
          <Checkbox color='primary' checked={selected} />
          {String(option[itemKey])}
        </li>
      )}
      onChange={handleChange}
      loading={items && items.length > 0}
      loadingText={loadingText}
      renderInput={(params): ReactNode => (
        <TextField
          {...params}
          className={classes.inputStyle}
          variant='filled'
          margin='normal'
          label={label}
          placeholder={placeholder}
        />
      )}
      data-testid={dataTestId}
      {...autoCompleteProps}
    />
  )
}

export default SortedAutocomplete
