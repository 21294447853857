import { BaseService, MiddlewareServiceInit } from '../middleware/baseService'
import { CloudReportingPartner } from '../models'
import { CloudReportingViews } from '../models/cloudReportingViews'
import { HttpClient } from '../services/http'

export class CloudReportingApi extends BaseService {
  constructor(init?: MiddlewareServiceInit, httpClient?: HttpClient) {
    super(
      init
        ? { ...init, entityType: 'cloudReporting' }
        : { entityType: 'cloudReporting' },
      httpClient
    )
  }
  route = 'cloudreporting'

  async getEmbedUrl(embedPath: string): Promise<string> {
    return await this.get<string>(`${this.route}/embed`, {
      params: { embedPath },
    })
  }

  async getPartner(partnerId: string): Promise<CloudReportingPartner> {
    return await this.get<CloudReportingPartner>(
      `${this.route}/partners/${partnerId}`
    )
  }

  async getViews(): Promise<CloudReportingViews> {
    return await this.get<CloudReportingViews>(`${this.route}/landingpage`)
  }
}
