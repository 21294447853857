import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { AlertConfigSteps, defaultAlertConfigSteps } from '../../models'
import { AlertActiveConfig } from '../../models/alert'
import QuickActionAvatarCard, { IconType } from '../Cards/QuickActionCard'
import { DataTestIds } from '../../utils/test-utils/dataTestIds'

interface Props {
  alert: AlertActiveConfig
  deleteAlert?: (id: string) => void
  disableAlert?: (id: string) => void
  canEdit: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    buildingTextContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      height: 68,
      marginTop: 13,
    },
    actionName: {
      fontSize: 12,
    },
    centerButton: {
      marginLeft: 12,
      marginRight: 12,
    },
    leftButton: {
      marginLeft: 12,
    },
    rightButton: {
      marginRight: 12,
    },
  })
)

function AlertDetailsPaneQuickActionButtons(props: Props): React.ReactElement {
  const { alert, deleteAlert, disableAlert, canEdit } = props

  const history = useNavigate()

  const openAlertEdit = (id: string | undefined) => (): void => {
    if (id === undefined) {
      return
    }
    history(
      `/admin/alerts/${id}/${
        defaultAlertConfigSteps[AlertConfigSteps[AlertConfigSteps.CONDITIONS]]
          .path
      }`
    )
  }

  const disableAlertClick = (id: string | undefined) => (): void => {
    if (id === undefined) {
      return
    }

    if (disableAlert) {
      disableAlert(id)
    }
  }

  const deleteAlertClick = (id: string | undefined) => (): void => {
    if (id === undefined) {
      return
    }

    if (deleteAlert) {
      deleteAlert(id)
    }
  }

  const classes = useStyles()

  return (
    <>
      <div className={classes.buildingTextContainer}>
        <div
          className={classes.leftButton}
          data-testid={DataTestIds.alertDetailPaneHeaderEdit}
        >
          <QuickActionAvatarCard
            cardText='Edit Alert'
            cardIconType={IconType.EditOutlined}
            onActionAreaClick={openAlertEdit(alert.id)}
            isDisabled={!canEdit}
          />
        </div>
        <div
          className={classes.centerButton}
          data-testid={DataTestIds.alertDetailPaneHeaderEnable}
        >
          <QuickActionAvatarCard
            cardText={`${alert.alertStatus === 0 ? 'Disable' : 'Enable'} Alert`}
            cardIconType={IconType.DisabledOutlined}
            onActionAreaClick={disableAlertClick(alert.id)}
            isDisabled={!canEdit}
          />
        </div>
        <div
          className={classes.rightButton}
          data-testid={DataTestIds.alertDetailPaneHeaderDelete}
        >
          <QuickActionAvatarCard
            cardText='Delete Alert'
            cardIconType={IconType.DeleteOutlined}
            onActionAreaClick={deleteAlertClick(alert.id)}
            isDisabled={!canEdit}
          />
        </div>
      </div>

      {/* Comment out for now until we're ready to implement the Preview button */}
      {/* <div className={classes.buildingTextContainer}>
        <div className={classes.leftButton}>
          <QuickActionAvatarCard
            cardText='Preview Alert'
            cardIconType={IconType.PreviewOutlined}
            onActionAreaClick={openAlertEdit(alert.id)}
          />
        </div>
        <div className={classes.centerButton}></div>
        <div className={classes.rightButton}>
          <QuickActionAvatarCard
            cardText='Delete Alert'
            cardIconType={IconType.DeleteOutlined}
            onActionAreaClick={openAlertEdit(alert.id)}
          />
        </div>
      </div> */}
    </>
  )
}

export default AlertDetailsPaneQuickActionButtons
