import { getType } from 'typesafe-actions'
import { IssueTypesAction } from '../actions'
import { getIssueTypesAction } from '../actions/issueTypes'
import {
  FetchingStatus,
  IssueType,
  IssueTypes,
  IssueTypesState,
} from '../models'
import { mergeRecords } from '../utils'

const initialState: IssueTypesState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

export default (
  state = initialState,
  action: IssueTypesAction
): IssueTypesState => {
  switch (action.type) {
    case getType(getIssueTypesAction.success):
      return {
        data: mergeRecords<IssueTypes, IssueType, 'id'>(
          state.data,
          action.payload,
          'id'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }

    case getType(getIssueTypesAction.request):
      return {
        ...state,
        status: FetchingStatus.Request,
      }
    case getType(getIssueTypesAction.failure):
      return {
        ...state,
        status: FetchingStatus.Failure,
      }
    default:
      return state
  }
}
