import React from 'react'
import { FilterDrawerHeader, RightDrawerLayout } from '../../RightDrawer'
import { FilterDrawerHeaderProps } from '../../RightDrawer/FilterDrawerHeader'
import AssetStaffFilterContent, {
  AssetStaffFilterContentProps,
} from './AssetStaffFilterContent'

export interface AssetListFilterDrawerProps
  extends FilterDrawerHeaderProps,
    AssetStaffFilterContentProps {}

const AssetStaffFilterDrawer = (
  props: AssetListFilterDrawerProps
): JSX.Element => {
  const {
    onCloseDrawer,
    clearFilters,
    assetFilterOptions,
    staffFilterOptions,
    commonFilterOptions,
    filterStates,
    units,
    filterType,
    mapFilterOptions,
    listFilterOptions,
  } = props

  return (
    <RightDrawerLayout
      header={
        <FilterDrawerHeader
          onCloseDrawer={onCloseDrawer}
          clearFilters={clearFilters}
        />
      }
      content={
        <AssetStaffFilterContent
          assetFilterOptions={assetFilterOptions}
          staffFilterOptions={staffFilterOptions}
          commonFilterOptions={commonFilterOptions}
          filterStates={filterStates}
          units={units}
          filterType={filterType}
          mapFilterOptions={mapFilterOptions}
          listFilterOptions={listFilterOptions}
        />
      }
    />
  )
}

export default AssetStaffFilterDrawer
