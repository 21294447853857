import React, { useMemo } from 'react'
import { FeatureFlagLabels, FeatureFlagNames } from '../../constants'
import { GetManagerDisplayOption } from '../../helpers'
import { roleIdsToNames } from '../../helpers/roles'
import { useFetchFeatureFlags } from '../../hooks/entities/useFetchFeatureFlags'
import { Roles, StaffMembers, User } from '../../models'
import { DetailedItem } from '../../models/DetailPaneItems'
import DetailsList from '../DetailsList/DetailsList'

interface Props {
  user: User
  roles: Roles
  staff?: StaffMembers
  isOpsUser?: boolean
}

function UserDetailsPane(props: Props): JSX.Element {
  const { user, roles, isOpsUser, staff } = props

  const { data: featureFlags } = useFetchFeatureFlags(
    FeatureFlagLabels.Navigation
  )

  const isCloudReportingFeatureEnabled =
    Object.values(featureFlags).filter(
      (x) => x.name === FeatureFlagNames.cloudReporting && x.enabled
    ).length > 0

  const matchingStaff = useMemo(() => {
    if (staff) {
      if (user.email) {
        return Object.values(staff).find(
          (x) => x.email !== null && x.userId === user.id
        )
      } else if (user && user.email?.length > 0 && user.email === '') {
        return undefined
      } else {
        const results = Object.values(staff).find(
          (x) => x.email !== null && x.userId === user.id
        )
        return results
      }
    }
  }, [staff, user])

  const manager = useMemo(() => {
    if (staff && matchingStaff?.managerId) {
      return staff[matchingStaff.managerId]
    }
  }, [staff, matchingStaff])

  const userDetailItems = useMemo(() => {
    const cloudReportingAccessField = 'Analytics Access'

    const items = !!user
      ? [
          {
            field: 'First Name',
            value: user.firstName,
          },
          {
            field: 'Last Name',
            value: user.lastName,
          },
          {
            field: 'Email',
            value: user.email,
          },
          {
            field: 'Role',
            value: roleIdsToNames(user.roleIds, roles).join(', '),
          },
        ]
      : []

    // TODO: when this feature is no longer feature flagged move it into the items array above
    if (isCloudReportingFeatureEnabled && !isOpsUser) {
      items.push({
        field: cloudReportingAccessField,
        value:
          user.isCloudReportingEnabled === null ||
          user.isCloudReportingEnabled === undefined
            ? 'Unavailable'
            : user.isCloudReportingEnabled
            ? 'Yes'
            : 'No',
      })
    }

    return items
  }, [user, roles])

  const identificationItems = useMemo(() => {
    return !!matchingStaff
      ? [
          {
            field: 'Display Name',
            value: matchingStaff.displayName,
          },
          {
            field: 'First Name',
            value: matchingStaff.firstName,
          },
          {
            field: 'Last Name',
            value: matchingStaff.lastName,
          },
          {
            field: 'Email',
            value: matchingStaff.email,
          },
          {
            field: 'Staff Type',
            value: matchingStaff.staffTypeName,
          },
          {
            field: 'Staff Sub-Type',
            value: matchingStaff.staffSubTypeName,
          },
          {
            field: 'Assigned To',
            value: matchingStaff.unitName,
          },
          {
            field: 'Reports To',
            value: manager ? GetManagerDisplayOption(manager) : '',
          },
          {
            field: 'RTLS ID',
            value: matchingStaff.badgeIds.join(','),
          },
        ]
      : []
  }, [matchingStaff, manager])

  const detailItems: DetailedItem[] = []
  detailItems.push({
    header: 'USER DETAILS',
    items: userDetailItems,
  })

  const staffDetailItems: DetailedItem[] = []
  staffDetailItems.push({
    header: 'STAFF DETAILS',
    items: identificationItems,
  })

  return (
    <>
      <div style={{ paddingTop: 24 }}>
        <DetailsList
          detailedItems={detailItems}
          headerDivider={false}
          narrowRows={true}
        />
      </div>
      {matchingStaff && (
        <div style={{ paddingTop: 24 }}>
          <DetailsList
            detailedItems={staffDetailItems}
            headerDivider={false}
            narrowRows={true}
          />
        </div>
      )}
    </>
  )
}

export default UserDetailsPane
