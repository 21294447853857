import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllQualifiersAction } from '../actions/qualifiers'
import { shouldDispatch } from '../helpers'
import { Qualifiers, QualifierState } from '../models/qualifier'

const useFetchQualifiers = (shouldRefresh: boolean = false) => {
  const dispatch = useDispatch()
  const status = useSelector(
    ({ qualifiers }: { qualifiers: QualifierState }) => qualifiers.status
  )
  const lastRefreshTime = useSelector(
    ({ qualifiers }: { qualifiers: QualifierState }) =>
      qualifiers.lastRefreshTime
  )

  useEffect(() => {
    if (shouldDispatch(status, lastRefreshTime) || shouldRefresh) {
      dispatch(getAllQualifiersAction.request())
    }
  }, [status, lastRefreshTime, shouldRefresh, dispatch])

  const qualifiers: Qualifiers = useSelector(
    ({ qualifiers }: { qualifiers: QualifierState }) => qualifiers.data
  )

  return {
    qualifiers,
    status,
  }
}

export { useFetchQualifiers }
