import { getType } from 'typesafe-actions'
import { CloudReportingAction } from '../actions'
import {
  getCloudReportingEmbedUrlAction,
  getCloudReportingPartnerAction,
  getCloudReportingViewsAction,
} from '../actions/cloudReporting'
import { CloudReporting, CloudReportingState } from '../models/cloudReporting'
import { FetchingStatus } from '../models/fetchingStatus'
import { setFetchingStatus } from '../utils'

const initialState: CloudReportingState = {
  data: {
    embedUrl: undefined,
    partner: undefined,
    views: undefined,
  },
  status: undefined,
  lastRefreshTime: undefined,
}

const reducer = (
  state: CloudReportingState = initialState,
  action: CloudReportingAction
): CloudReportingState => {
  switch (action.type) {
    case getType(getCloudReportingEmbedUrlAction.success):
      return {
        ...state,
        data: {
          ...state.data,
          embedUrl: action.payload,
        },
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getCloudReportingPartnerAction.success):
      return {
        ...state,
        data: {
          ...state.data,
          partner: action.payload,
        },
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getCloudReportingViewsAction.success):
      return {
        ...state,
        data: {
          ...state.data,
          views: action.payload,
        },
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getCloudReportingEmbedUrlAction.request):
    case getType(getCloudReportingPartnerAction.request):
    case getType(getCloudReportingViewsAction.request):
      return setFetchingStatus<CloudReporting>(state, FetchingStatus.Request)
    case getType(getCloudReportingEmbedUrlAction.failure):
    case getType(getCloudReportingPartnerAction.failure):
    case getType(getCloudReportingViewsAction.failure):
      return setFetchingStatus<CloudReporting>(
        state,
        FetchingStatus.Failure,
        action.payload
      )
    default:
      return state
  }
}

export default reducer
