import { createAsyncAction } from 'typesafe-actions'
import { Permission } from '../models/permissions'

export const getPermissionsAction = createAsyncAction(
  'GET_PERMISSIONS_REQUEST',
  'GET_PERMISSIONS_SUCCESS',
  'GET_PERMISSIONS_FAILURE'
)<void, Permission[], Error>()

export const createPermissionAction = createAsyncAction(
  'CREATE_PERMISSIONS_REQUEST',
  'CREATE_PERMISSIONS_SUCCESS',
  'CREATE_PERMISSIONS_FAILURE'
)<Permission, Permission, Error>()
