import { FetchingState } from '../interfaces/fetchingState'

export interface SecurityRouter {
  id: string
  name: string
  routeSecurityTypeId: number
}

export interface SecurityRouters {
  [id: string]: SecurityRouter
}

export type SecurityRouterState = Readonly<FetchingState<SecurityRouters>>

export enum RouteSecurityType {
  Role,
}
