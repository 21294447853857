import { Autocomplete, Checkbox, FormControl, TextField } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { defaultAutocompleteProps } from '../../../../helpers/filters'
import { Role, SortedRole } from '../../../../models'
import { useFormStyle } from '../../../../styles'

interface Props {
  value: SortedRole[]
  loading: boolean
  error: boolean
  className: string | undefined
  onChange: (event: any, selected: Role[]) => void
  data: SortedRole[]
  fieldName: string
  helperText?: string
  disabled?: boolean
  unselectedValue?: number
}

const AlertRoleDropDown: React.FC<Props> = (props: Props) => {
  const {
    value,
    loading,
    error,
    className,
    onChange,
    data,
    fieldName,
    helperText,
    disabled,
    unselectedValue,
  } = props

  const [managedValue, setManagedValue] = useState<SortedRole[]>([])

  const sortedData = useMemo(() => {
    const temp = data.sort((a, b) => a.sortOrder - b.sortOrder)
    const index = temp.findIndex((x: Role) => x.id === -1)
    const item = temp.splice(index, 1)[0]
    temp.splice(0, 0, item)
    return temp
  }, [data])

  const classes = useFormStyle()

  useEffect(() => {
    if (
      data.length &&
      (value === undefined || value.length === 0) &&
      unselectedValue
    ) {
      setManagedValue(data.filter((x) => x.id === unselectedValue))
    } else {
      setManagedValue(value)
    }
  }, [data, unselectedValue, value])

  const { props: autoCompleteProps, renderTags } = defaultAutocompleteProps()

  return (
    <FormControl
      variant='standard'
      className={classes.inputStyle}
      style={{ width: '100%' }}
    >
      <Autocomplete
        {...autoCompleteProps}
        style={{ width: '100%' }}
        value={managedValue}
        options={sortedData}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(role: Role): string => role.name}
        loading={loading}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox color='primary' checked={selected} />
            {option.name}
          </li>
        )}
        multiple
        disabled={disabled ?? false}
        onChange={onChange}
        renderInput={(params) => (
          <div>
            <TextField
              {...params}
              margin='normal'
              label={fieldName}
              variant='filled'
              error={error}
              required
              className={className}
              helperText={helperText}
            />
          </div>
        )}
        renderTags={renderTags((option) => option.name)}
      />
    </FormControl>
  )
}

export { AlertRoleDropDown }
