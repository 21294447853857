import { AlertConfigSteps, defaultAlertConfigSteps } from '../models'

export const CommonRouteKeys = {
  RealTimePage: { path: '/realtime', key: 'RealTimePage' },
  AssetManagementPage: { path: '/admin/assets', key: 'AssetManagementPage' },
  StaffManagementPage: { path: '/admin/staff', key: 'StaffManagementPage' },
  DashboardPage: { path: '/', key: 'DashboardPage' },
  DownForMaintenance: {
    path: '/downformaintenance',
    key: 'DownForMaintenancePage',
  },
  HardwareManagementPage: {
    path: '/admin/hardware',
    key: 'HardwareManagementPage',
  },
  LocationManagementPage: {
    path: '/admin/locations',
    key: 'LocationManagementPage',
  },
  BadgeTrafficPage: {
    path: '/admin/badge-traffic',
    key: 'BadgeTrafficPage',
  },
  BadgePage: { path: '/admin/badges', key: 'BadgePage' },
  UserManagementPage: { path: '/admin/users', key: 'UserManagementPage' },
  ErrorPage: { path: '/errors', key: 'ErrorPage' },
  MapPage: { path: '/admin/maps', key: 'MapPage' },
  AlertManagementPage: { path: '/admin/alerts', key: 'AlertManagementPage' },
  DevToolsPage: { path: '/admin/devtools', key: 'DevToolsPage' },
  AlertConfigConditionPage: {
    path: `/admin/alerts/:id/${
      defaultAlertConfigSteps[AlertConfigSteps[AlertConfigSteps.CONDITIONS]]
        .path
    }`,
    key: 'AlertConfigConditionPage',
  },
  AlertConfigChannelPage: {
    path: `/admin/alerts/:id/${
      defaultAlertConfigSteps[AlertConfigSteps[AlertConfigSteps.CHANNELS]].path
    }`,
    key: 'AlertConfigChannelPage',
  },
  AlertConfigRoutingPage: {
    path: `/admin/alerts/:id/${
      defaultAlertConfigSteps[AlertConfigSteps[AlertConfigSteps.ROUTING]].path
    }`,
    key: 'AlertConfigRoutingPage',
  },
  AlertConfigResolutionPage: {
    path: `/admin/alerts/:id/${
      defaultAlertConfigSteps[AlertConfigSteps[AlertConfigSteps.RESOLUTION]]
        .path
    }`,
    key: 'AlertConfigResolutionPage',
  },
  AlertConfigReviewPage: {
    path: `/admin/alerts/:id/${
      defaultAlertConfigSteps[AlertConfigSteps[AlertConfigSteps.REVIEW]].path
    }`,
    key: 'AlertConfigReviewPage',
  },
  CloudReportingLandingPage: {
    path: '/analytics',
    key: 'CloudReportingLandingPage ',
  },
  CloudReportingReportPage: {
    path: '/analytics/report',
    key: 'CloudReportingReportPage ',
  },
  NotificationsPage: {
    path: '/notifications',
    key: 'NotificationsPage ',
  },
} as const
