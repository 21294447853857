import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import RootAction from '../../actions'
import {
  getAllTenantsAction,
  getCurrentUserTenantsAction,
  postOperationsCustomerAction,
} from 'v2/actions/tenants'
import { TenantsApi } from 'v2/middleware/tenants'
import { RootState } from '../../reducers'
import { OperationsUsersApi } from 'v2/middleware'

type TenantEpic = Epic<
  RootAction,
  RootAction,
  RootState,
  { tenantsApi: TenantsApi; operationsUsersApi: OperationsUsersApi }
>

export const getAllTenantsEpic: TenantEpic = (
  action$,
  state,
  { tenantsApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllTenantsAction.request)),
    mergeMap(() =>
      from(tenantsApi.getAllTenants()).pipe(
        map((tenants) => getAllTenantsAction.success(tenants)),
        catchError((error) => of(getAllTenantsAction.failure(error)))
      )
    )
  )
}

export const getCurrentUserTenantsEpic: TenantEpic = (
  action$,
  state,
  { operationsUsersApi }
) => {
  return action$.pipe(
    filter(isActionOf(getCurrentUserTenantsAction.request)),
    mergeMap((action) =>
      from(operationsUsersApi.getTenants(action.payload)).pipe(
        map((tenants) => getCurrentUserTenantsAction.success(tenants)),
        catchError((error) => of(getCurrentUserTenantsAction.failure(error)))
      )
    )
  )
}

export const postOperationsCustomersActionEpic: TenantEpic = (
  action$,
  state$,
  { tenantsApi }
) => {
  return action$.pipe(
    filter(isActionOf(postOperationsCustomerAction.request)),
    mergeMap((action) =>
      from(tenantsApi.createOperationsCustomer(action.payload)).pipe(
        map((payload) => postOperationsCustomerAction.success(payload)),
        catchError((error) => of(postOperationsCustomerAction.failure(error)))
      )
    )
  )
}
