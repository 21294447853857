import {
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React from 'react'
import { badgeSignalStatus, calculateBadgeSignalStatus } from '../../../helpers'
import { isLowBattery } from '../../../helpers/battery'
import {
  getDefaultAssetIcon,
  getIconStatusList,
} from '../../../helpers/inpixon/icons'
import {
  AgentType,
  BadgeSignalStatus,
  TelemetryAsset,
  TelemetryStaff,
} from '../../../models'
import icons from '../../../models/icons'
import {
  AgentInGroup,
  BadgeTelemetryMessageWithAgentEvent,
} from '../../../models/telemetry'
import { colors } from '../../../styles'

const useStyles = makeStyles(() =>
  createStyles({
    groupListAssetItemText: {
      margin: '0 0 0 5px',
      fontFamily: 'Ubuntu',
      fontSize: '16px',
      fontWeight: 'bold',
      textAlign: 'start',
      overflow: 'hidden',
      whiteSpace: 'pre',
      color: colors.darkBlue,
    },
    groupListAssetItemTextRecent: {
      margin: '0 0 0 5px',
      fontFamily: 'Ubuntu',
      fontSize: '16px',
      fontStyle: 'italic',
      fontWeight: 'bold',
      textAlign: 'start',
      overflow: 'hidden',
      whiteSpace: 'pre',
      color: colors.asseRecent,
    },
    groupListStaffItemText: {
      margin: '0 0 0 5px',
      fontFamily: 'Ubuntu',
      fontSize: '16px',
      fontWeight: 'bold',
      textAlign: 'start',
      overflow: 'hidden',
      whiteSpace: 'pre',
      color: colors.staff,
    },
    groupListStaffItemDuressText: {
      margin: '0 0 0 5px',
      fontFamily: 'Ubuntu',
      fontSize: '16px',
      fontWeight: 'bold',
      textAlign: 'start',
      overflow: 'hidden',
      whiteSpace: 'pre',
      color: colors.error,
    },
    groupListStaffItemAssistText: {
      margin: '0 0 0 5px',
      fontFamily: 'Ubuntu',
      fontSize: '16px',
      fontWeight: 'bold',
      textAlign: 'start',
      overflow: 'hidden',
      whiteSpace: 'pre',
      color: colors.assistIconColor,
    },
    groupListStaffItemTextRecent: {
      margin: '0 0 0 5px',
      fontFamily: 'Ubuntu',
      fontSize: '16px',
      fontStyle: 'italic',
      fontWeight: 'bold',
      textAlign: 'start',
      overflow: 'hidden',
      whiteSpace: 'pre',
      color: colors.staffRecent,
    },
    groupListItemIcon: {
      width: '30px',
      minWidth: '30px',
    },
    groupListIconImage: {
      height: 30,
      width: 30,
    },
    groupListIconArrow: {
      width: '30px',
      minWidth: '37px',
    },
    groupListIconArrowImage: {
      height: 24,
      width: 24,
      margin: '11px 11px 12px 19px',
      objectFit: 'contain',
    },
  })
)

export interface AgentGroupDetailListItemProps {
  data: AgentInGroup
  onSelectAgent: (telemetry: BadgeTelemetryMessageWithAgentEvent) => void
}

const AgentGroupDetailListItem = (
  props: AgentGroupDetailListItemProps
): JSX.Element => {
  const { data, onSelectAgent } = props
  const { agent, telemetry } = data
  const classes = useStyles()

  const handleSelectAgent = (event: React.FormEvent) => {
    event.preventDefault()
    onSelectAgent(telemetry)
  }

  const signalStatus = badgeSignalStatus(telemetry.timestamp)
  const image = getIconStatusList(
    signalStatus ?? BadgeSignalStatus.Current,
    isLowBattery(telemetry.isLowBattery),
    process.env.REACT_APP_STATIC_CONTENT + agent.iconUrl,
    getDefaultAssetIcon,
    telemetry.hasActiveDuress,
    telemetry.hasActiveAssist
  )

  const agentStyle = (
    agent: TelemetryAsset | TelemetryStaff,
    isDuress = false,
    isAssist = false
  ) => {
    if (agent.agentType === AgentType.Asset) {
      if (
        calculateBadgeSignalStatus(telemetry.timestamp) ===
        BadgeSignalStatus.Recent
      ) {
        return classes.groupListAssetItemTextRecent
      } else {
        return classes.groupListAssetItemText
      }
    } else {
      if (
        calculateBadgeSignalStatus(telemetry.timestamp) ===
          BadgeSignalStatus.Recent &&
        !telemetry.hasActiveDuress &&
        !telemetry.hasActiveAssist
      ) {
        return classes.groupListStaffItemTextRecent
      } else if (isDuress) {
        return classes.groupListStaffItemDuressText
      } else if (isAssist) {
        return classes.groupListStaffItemAssistText
      } else {
        return classes.groupListStaffItemText
      }
    }
  }

  return (
    <>
      <ListItemButton
        key={agent.agentGuid}
        onClick={(event: React.FormEvent) => handleSelectAgent(event)}
      >
        <ListItemIcon classes={{ root: clsx(classes.groupListItemIcon) }}>
          <div
            className={classes.groupListIconImage}
            dangerouslySetInnerHTML={{ __html: image }}
          />
        </ListItemIcon>
        <ListItemText
          primary={agent.displayName}
          classes={{
            primary: clsx(
              agentStyle(
                agent,
                telemetry.hasActiveDuress,
                telemetry.hasActiveAssist
              )
            ),
          }}
        />
        <ListItemIcon classes={{ root: clsx(classes.groupListIconArrow) }}>
          <div
            className={classes.groupListIconArrowImage}
            dangerouslySetInnerHTML={{
              __html: icons.assetGroupListArrow,
            }}
          />
        </ListItemIcon>
      </ListItemButton>
      <Divider />
    </>
  )
}

export default AgentGroupDetailListItem
