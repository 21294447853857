import { createAction } from 'typesafe-actions'
import { WalkingSetupModalOptions } from '../models/ui'

// -----------------------------------------------------------------------------
// Clear the status after a notification is displayed
// -----------------------------------------------------------------------------
export const clearStatusAction = createAction('CLEAR_STATUS')()
export const setStatusAction = createAction('SET_STATUS')<string>()

// -----------------------------------------------------------------------------
// Walking Setup
// -----------------------------------------------------------------------------
export const setConfigurationModeAction = createAction(
  'SET_CONFIGURATION_MODE'
)<boolean>()

export const openWalkingSetupModalAction = createAction(
  'OPEN_WALKING_SETUP_MODAL'
)<WalkingSetupModalOptions>()

export const popWalkingSetupModalAction = createAction(
  'POP_WALKING_SETUP_MODAL'
)<void>()

export const closeWalkingSetupModalAction = createAction(
  'CLOSE_WALKING_SETUP_MODAL'
)<void>()

export const selectDeviceAction = createAction('SELECT_DEVICE')<string>() // deviceGuid

export const setUserBadgeIdAction = createAction('SET_USER_BADGE_ID')<string>()

export const resetWalkingSetupStatusAction = createAction(
  'RESET_WALKING_SETUP_STATUS'
)<void>()

// -----------------------------------------------------------------------------
// Device Management
// -----------------------------------------------------------------------------
export const setConcentratorAction = createAction('SET_CONCENTRATOR')<string>()
export const setCollectorAction = createAction('SET_COLLECTOR')<string>()

// -----------------------------------------------------------------------------
// Device Configuration
// -----------------------------------------------------------------------------
export const setDeviceIdAction = createAction('SET_DEVICE_ID')<string>()
export const clearDeviceIdAction = createAction('CLEAR_DEVICE_ID')<void>()

export const setBadgeIdAction = createAction('SET_BADGE_ID')<string>()
