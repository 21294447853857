import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { renderCurrentMapViewControl } from '../../helpers/inpixon'
import {
  updateAssetHideAccuracy,
  updateAssetShowAccuracy,
  updateGroupIcon,
} from '../../helpers/inpixon/assetUpdate'
import {
  Assets,
  InpixonAssetRefStore,
  MapGroupState,
  StaffMembers,
} from '../../models'

export function useInpixonAssetHighlighting(
  control: any,
  assetKit: any,
  assets: Assets | undefined,
  staff: StaffMembers | undefined
) {
  const currentSelectedGroup = useRef<number | undefined>(undefined)
  const currentSelectedBadge = useRef<string>('')

  const mapGroups: MapGroupState = useSelector(
    ({ mapGroups }: { mapGroups: MapGroupState }) => mapGroups
  )
  const { agentReferences } = useSelector(
    ({ agentReferences }: { agentReferences: InpixonAssetRefStore }) =>
      agentReferences
  )

  // handle groups
  useEffect(() => {
    if (control && assetKit) {
      if (
        currentSelectedGroup.current &&
        currentSelectedGroup.current !== mapGroups.selectedGroupId
      ) {
        // deselect previous group
        const previousGroupAsset = assetKit.getAssetById(
          currentSelectedGroup.current
        )
        if (previousGroupAsset) {
          updateGroupIcon(assetKit, previousGroupAsset)
          renderCurrentMapViewControl(control)
        }
      }

      // update ref
      currentSelectedGroup.current = mapGroups.selectedGroupId

      // select new group
      if (currentSelectedGroup.current) {
        const newGroupAsset = assetKit.getAssetById(
          currentSelectedGroup.current
        )
        if (newGroupAsset) {
          updateGroupIcon(assetKit, newGroupAsset, true)
          renderCurrentMapViewControl(control)
        }
      }
    }
  }, [
    assetKit,
    control,
    mapGroups,
    mapGroups.selectedGroupId,
    currentSelectedGroup,
  ])

  // handle assets
  useEffect(() => {
    let inGroup = false
    Object.values(mapGroups.groups).forEach((x) => {
      if (
        mapGroups.selectedTrackingId &&
        x.trackingIds.includes(mapGroups.selectedTrackingId) &&
        x.trackingIds.length > 1
      ) {
        inGroup = true
      }
    })
    if (assetKit && (assets || staff) && control && !inGroup) {
      if (currentSelectedBadge.current !== mapGroups.selectedTrackingId) {
        // deselect previous asset
        const previousAssetKitId = agentReferences.find((x) =>
          x.trackingIds.includes(currentSelectedBadge.current)
        )?.assetKitId
        const previousAsset = assetKit.getAssetById(previousAssetKitId)

        if (previousAsset) {
          updateAssetHideAccuracy(assetKit, String(previousAssetKitId))
        }

        // update ref
        currentSelectedBadge.current = mapGroups.selectedTrackingId ?? ''
      }

      if (assets) {
        const matchingAsset = Object.values(assets).find((a) =>
          a.badgeIds.includes(currentSelectedBadge.current)
        )
        const currentAssetKitId = agentReferences.find((x) =>
          x.trackingIds.includes(currentSelectedBadge.current)
        )?.assetKitId

        // highlight asset
        if (matchingAsset && currentAssetKitId) {
          updateAssetShowAccuracy(
            assetKit,
            String(currentAssetKitId),
            matchingAsset.signalTypeIds ?? []
          )
          renderCurrentMapViewControl(control)
        }
      }

      if (staff) {
        const matchingAsset = Object.values(staff).find((a) =>
          a.badgeIds.includes(currentSelectedBadge.current)
        )
        const currentAssetKitId = agentReferences.find((x) =>
          x.trackingIds.includes(currentSelectedBadge.current)
        )?.assetKitId

        // highlight asset
        if (matchingAsset && currentAssetKitId) {
          updateAssetShowAccuracy(
            assetKit,
            String(currentAssetKitId),
            matchingAsset.signalTypeIds ?? []
          )
          renderCurrentMapViewControl(control)
        }
      }
    }
  }, [assetKit, control, mapGroups, mapGroups.groups, currentSelectedBadge])
}
