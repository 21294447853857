import React, { ReactNode } from 'react'
import Dialog, { DialogClassKey } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActionButtons from './DialogActionButtons'
import { DialogActionButtonType } from './DialogActionButtonType'

export type DialogWidth = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false

interface Props {
  open: boolean
  handleClose: () => void
  title?: string
  customHeader?: ReactNode
  actionButtons?: DialogActionButtonType[]
  leftActionButtons?: DialogActionButtonType[]
  children: ReactNode
  maxWidth?: DialogWidth
  classes?: Partial<Record<DialogClassKey, string> | undefined>
  dialogContentClasses?: Partial<Record<DialogClassKey, string> | undefined>
}

const DialogBox = (props: Props) => {
  const {
    customHeader,
    open,
    handleClose,
    title,
    actionButtons,
    leftActionButtons,
    children,
    maxWidth,
    classes: dialogClasses,
    dialogContentClasses: dialogContentClasses,
  } = props

  const justify =
    leftActionButtons && actionButtons
      ? 'space-between'
      : leftActionButtons
      ? 'flex-start'
      : 'flex-end'

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='dialog-title'
      aria-describedby='dialog-content'
      fullWidth
      disableRestoreFocus
      maxWidth={maxWidth ?? 'sm'}
      classes={dialogClasses}
    >
      {customHeader || <DialogTitle id='dialog-title'>{title}</DialogTitle>}
      <DialogContent classes={dialogContentClasses}>{children}</DialogContent>
      <div style={{ display: 'flex', justifyContent: justify }}>
        {leftActionButtons && (
          <DialogActions>
            <DialogActionButtons actionButtons={leftActionButtons} />
          </DialogActions>
        )}
        {actionButtons && (
          <DialogActions>
            <DialogActionButtons actionButtons={actionButtons} />
          </DialogActions>
        )}
      </div>
    </Dialog>
  )
}

export default DialogBox
