import React, {
  createContext,
  useReducer,
  useContext,
  ProviderProps,
} from 'react'
import { SnackbarType } from '../../../models/snackbarType'

export type SnackbarState = {
  message: string | null
  linkText?: string | null
  link?: string | null
  type?: SnackbarType
  autoClose?: number | null
}

const SnackbarStateContext = createContext<SnackbarState | undefined>(undefined)
const SnackbarDispatchContext = createContext<
  React.Dispatch<SnackbarState | undefined> | undefined
>(undefined)

function secondaryHeaderReducer(
  snackbar: SnackbarState | undefined,
  nextState: SnackbarState | undefined
): SnackbarState | undefined {
  return nextState
}

export function SnackbarProvider({
  children,
}: Pick<ProviderProps<Record<string, never>>, 'children'>): JSX.Element {
  const initialState = { message: null, type: SnackbarType.None }
  const [snackbar, setSnackbar] = useReducer(
    secondaryHeaderReducer,
    initialState
  )
  return (
    <SnackbarStateContext.Provider value={snackbar}>
      <SnackbarDispatchContext.Provider value={setSnackbar}>
        {children}
      </SnackbarDispatchContext.Provider>
    </SnackbarStateContext.Provider>
  )
}

function useSnackbarState(): SnackbarState {
  const context = useContext(SnackbarStateContext)
  if (context === undefined) {
    throw new Error('fail')
  }
  return context
}

function useSnackbarDispatch(): React.Dispatch<SnackbarState | undefined> {
  const context = useContext(SnackbarDispatchContext)
  if (context === undefined) {
    throw new Error('fail')
  }
  return context
}

export default function useSnackbar(): {
  snackbarData: SnackbarState
  setSnackbarData: React.Dispatch<SnackbarState | undefined>
} {
  return {
    snackbarData: useSnackbarState(),
    setSnackbarData: useSnackbarDispatch(),
  }
}
