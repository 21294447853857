import React, { useState } from 'react'
import ConfirmationModal from '@midmarkrtls/common/components/Modals/ConfirmationModal'
import { DialogActionButtonType } from '@midmarkrtls/common/components/Dialogs/DialogActionButtonType'
import { colors } from '@midmarkrtls/common/styles'
import ActionConfirmationMessage from '@midmarkrtls/common/components/Common/ActionConfirmationMessage'
import { Typography } from '@mui/material'
import { ConfirmationFormInput } from '@midmarkrtls/common/components/Forms/ActionConfirmationForm'
import { useAuth } from '@midmarkrtls/common/hooks'
import { useFetchTenants } from 'hooks/fetch/useFetchTenants'

interface Props {
  showDialog: boolean
  handleClose: () => void
  handleContinue: (venueId: string) => void
}

function MapDeleteAllConfirmationModal(props: Props): JSX.Element {
  const { showDialog, handleClose, handleContinue } = props

  const confirmationInput: ConfirmationFormInput = {
    inputLabel: 'Confirm Map Deletion',
    keyword: 'delete',
  }
  const [isConfirmed, setIsConfirmed] = useState(!confirmationInput)
  const leftActionButtons: DialogActionButtonType[] = [
    {
      label: 'CANCEL',
      action: handleClose,
      color: colors.error,
      variant: 'link',
    },
  ]

  const rightActionButtons: DialogActionButtonType[] = [
    {
      label: 'DELETE',
      action: handleContinue,
      variant: 'contained',
      color: '#ffffff',
      backgroundColor: !isConfirmed ? colors.darkGrey : colors.darkBlue,
      disabled: !isConfirmed,
    },
  ]
  const { currentCustomerId } = useAuth()
  const { data: tenants } = useFetchTenants()

  const closeHandler = () => {
    setIsConfirmed(false)
    handleClose()
  }

  return (
    <>
      <ConfirmationModal
        isOpen={showDialog}
        handleClose={closeHandler}
        title={`Delete all maps?`}
        leftActionButtons={leftActionButtons}
        rightActionButtons={rightActionButtons}
        content={
          <ActionConfirmationMessage
            primaryMessage={
              <Typography variant='body1'>
                Are you sure you want to delete all venues for Tenant:
                <br />
                <b>
                  {
                    Object.values(tenants).find(
                      (x) => x.tenantId === currentCustomerId
                    )?.name
                  }
                </b>
              </Typography>
            }
            additionalMessage={
              <Typography variant='body1' style={{ color: 'red' }}>
                This will remove all maps and all associated location data. This
                cannot be undone!
              </Typography>
            }
          />
        }
        confirmationInput={
          confirmationInput
            ? {
                ...confirmationInput,
                setIsConfirmed,
              }
            : undefined
        }
      />
    </>
  )
}

export default MapDeleteAllConfirmationModal
