import { createAction } from 'typesafe-actions'
import { RealTimeMapControlStore, Rectangle } from '../models'

export const resetRealTimeMapControlStateAction = createAction(
  'RESET_REAL_TIME_MAP_INTERFACE_STATE'
)()

export const setRealTimeMapControlStateAction = createAction(
  'SET_REAL_TIME_MAP_INTERFACE_STATE'
)<RealTimeMapControlStore>()

export const setShouldShowRealTimeMapAction =
  createAction('SET_SHOULD_SHOW')<boolean>()

export const setRectangleAction = createAction('SET_MAP_RECT')<Rectangle>()

export const setJibestreamControlAction = createAction(
  'SET_JIBESTREAM_CONTROL'
)<any>()
