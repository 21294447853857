import { BaseService } from '../middleware/baseService'
import { Device } from '../models'

export class DevicesApi extends BaseService {
  constructor() {
    super({ entityType: 'devices' })
  }
  route = 'devices'

  async getAllDevices(): Promise<Device[]> {
    return await this.get<Device[]>(this.route)
  }

  async getDevice(deviceGuid: string): Promise<Device> {
    return await this.get<Device>(`${this.route}/${deviceGuid}`)
  }

  async putDevice(device: Device): Promise<Device> {
    return await this.put<Device>(`${this.route}/${device.deviceGuid}`, device)
  }

  async putDevices(devices: Device[]): Promise<Device[]> {
    return await this.put<Device[]>(`${this.route}`, devices)
  }
}
