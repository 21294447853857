import { Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { useSelector } from 'react-redux'
import { formatLocalDateTimeByUserPreference } from '../../helpers/date'
import { useFetchUserPreferences } from '../../hooks'
import useTimeAgo from '../../hooks/useTimeAgo'
import { LocationsState, StaffEvent } from '../../models'
import icons from '../../models/icons'
import { colors } from '../../styles/MidmarkTheme'
import { Button } from '../Buttons'
import CustomSVGIcon from '../Icons/CustomSVGIcon'
import { FlexBox } from '../Layout'
import { fixedDuressTimerInterval } from './constants'
import ViewStaffOnMapButton from './ViewStaffOnMapButton'

interface Props {
  duressEvent: StaffEvent
  onResolve: (duressToResolve: StaffEvent) => void
  isSecurityUser: boolean
  canViewOnMap: boolean
}

const StaffDuressBannerRecord = (props: Props): JSX.Element => {
  const { duressEvent, onResolve, isSecurityUser, canViewOnMap } = props

  const { data: userPreferences } = useFetchUserPreferences()

  const handleResolve = () => {
    onResolve(duressEvent)
  }

  const useStyles = makeStyles((canViewOnMap: boolean) =>
    createStyles({
      duressMessage: {
        fontSize: 18,
        color: colors.white,
        fontWeight: 'bold',
      },
      warning: {
        color: colors.white,
        marginLeft: 20,
        marginRight: 8,
      },
      reportedAt: {
        color: colors.white,
        fontSize: 12,
        paddingRight: canViewOnMap ? 10 : 0,
      },
      viewOnMap: {
        color: colors.white,
      },
      resolve: {
        color: colors.error,
      },
      icon: {
        marginTop: 5,
        marginLeft: 20,
        marginRight: 5,
        height: 30,
        minWidth: 30,
        position: 'relative',

        '& svg': {
          position: 'absolute',
          top: 0,
          left: 0,
        },
      },
    })
  )

  const classes = useStyles(canViewOnMap)

  const locations = useSelector(
    ({ locations }: { locations: LocationsState }) => locations.data
  )

  const { timeAgoText } = useTimeAgo(
    duressEvent?.reportedTime,
    fixedDuressTimerInterval
  )

  const duressMessage = `STAFF DURESS on ${duressEvent.floorName} in ${
    duressEvent.roomName
  } by ${duressEvent.reportedBy}  at ${
    duressEvent.reportedTime
      ? formatLocalDateTimeByUserPreference(
          duressEvent.reportedTime,
          userPreferences.timeDisplayId
        )
      : 'Unknown Time'
  }`

  return (
    <>
      <FlexBox
        flex='grow'
        layout='row'
        layoutAlign={{ item: 'center', main: 'flex-start' }}
      >
        <CustomSVGIcon
          svgText={icons.staffDuressIconWhite}
          classes={{ ...classes }}
        />
        <Typography
          className={classes.duressMessage}
          data-testid='bannerDuressMessage'
        >
          {duressMessage}
        </Typography>
      </FlexBox>
      <FlexBox
        flex='grow'
        layout='row'
        layoutAlign={{ item: 'center', main: 'flex-end' }}
      >
        <Typography
          className={classes.reportedAt}
          data-testid='bannerTimeElapsed'
        >
          Reported {timeAgoText ?? 'Unknown Time'}
        </Typography>
        {canViewOnMap && (
          <ViewStaffOnMapButton
            badgeId={duressEvent?.badgeId ?? ''}
            isBanner={true}
            floorLocation={
              duressEvent?.floorLocationId
                ? locations[duressEvent.floorLocationId]
                : undefined
            }
          />
        )}
        {isSecurityUser && (
          <div style={{ padding: '0 10px' }}>
            <Button
              bgColor={colors.white}
              textColor={colors.error}
              variant='outlined'
              onClick={handleResolve}
            >
              RESOLVE
            </Button>
          </div>
        )}
      </FlexBox>
    </>
  )
}

export default StaffDuressBannerRecord
