import {
  DefaultAnimationDuration,
  DefaultRotation,
  DefaultScale,
} from '../../constants'
import {
  AgentType,
  AssetInstance,
  AssetKitInstance,
  IconType,
} from '../../models'
import {
  MapRenderByGeofence,
  SelectAgentInGroupPayload,
} from '../../models/realtimeMap'
import { BadgeTelemetryMessageWithAgentEvent } from '../../models/telemetry/baseBadgeTelemetry'
import { focusView, updateInpixonGroupIcon } from '../inpixon'
import { updateSelectedGroupIconUrl } from '../inpixonIcons'
import { findAgentInCurrentMapRender } from '../realtimeMap'

/**
 * Focus on the preselected agent as indicated by the trackingId in the url search param and
 * select the agent on the map, and open the Detail Drawer
 * @param urlSearchString
 * @param assetKit
 * @param jibestreamController
 * @param isInitialMapRendered
 * @param currentMapRender
 * @param selectAgent
 */
export function focusOnPreselectedAgentOnMap(
  urlSearchString: string,
  currentlySelectedTrackingId: string | undefined,
  assetKit: AssetKitInstance | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  jibestreamController: any,
  isInitialMapRendered: boolean,
  currentMapRender: MapRenderByGeofence,
  selectAgent: (
    inpixonAsset: AssetInstance,
    agentTelemetry: BadgeTelemetryMessageWithAgentEvent
  ) => void,
  selectAgentInGroup: (payload: SelectAgentInGroupPayload) => void
): void {
  const searchParams = new URLSearchParams(urlSearchString)
  const urlBadgeId = searchParams.get('badgeId')
  const agentType =
    searchParams.get('agentType') === AgentType[AgentType.Staff]
      ? AgentType.Staff
      : AgentType.Asset
  if (
    assetKit &&
    urlBadgeId &&
    isInitialMapRendered &&
    currentlySelectedTrackingId !== urlBadgeId
  ) {
    let inpixonAsset: AssetInstance[] = []
    const agentTelemetry = findAgentInCurrentMapRender(
      urlBadgeId,
      currentMapRender
    )
    if (!agentTelemetry) {
      console.warn(
        'Agent not currently rendered on map. Unable to focus on agent!'
      )
      return
    }
    const agentMapRender = currentMapRender[agentTelemetry.geoFenceId]
    if (!agentMapRender) {
      console.warn(
        'Agent not currently rendered on map. Unable to focus on agent!'
      )
      return
    }
    const isAgentInAGroup =
      agentMapRender.renderedAgents.length >=
      agentMapRender.maxAgentsAllowedInGeofence
    if (isAgentInAGroup) {
      inpixonAsset = assetKit.getAssetsByType(
        `${IconType.Group}_${agentTelemetry.geoFenceId}`
      )
    } else {
      inpixonAsset = assetKit.getAssetsByType(
        `${AgentType[agentType]}_${urlBadgeId}`
      )
    }
    if (inpixonAsset.length > 0 && agentTelemetry) {
      focusView(
        jibestreamController,
        inpixonAsset[0]._position[0],
        inpixonAsset[0]._position[1],
        DefaultAnimationDuration,
        DefaultScale,
        DefaultRotation
      )
      if (isAgentInAGroup) {
        const selectedIconUrl = updateSelectedGroupIconUrl(
          inpixonAsset[0].url,
          true
        )
        updateInpixonGroupIcon(
          assetKit,
          jibestreamController,
          inpixonAsset[0]._type,
          selectedIconUrl
        )
        selectAgentInGroup({
          group: {
            inpixonAsset: {
              ...inpixonAsset[0],
              url: selectedIconUrl,
            },
            groupTelemetries: agentMapRender,
          },
          selectedAgent: agentTelemetry,
        })
      } else {
        selectAgent(inpixonAsset[0], agentTelemetry)
      }
    }
  }
}
