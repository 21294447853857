import { getType } from 'typesafe-actions'
import { AltitudeMapAction } from '../actions'
import {
  createAltitudeMapAction,
  deleteAltitudeMapAction,
  getAllAltitudeMapsAction,
  updateAltitudeMapAction,
} from '../actions/altitudeMaps'
import {
  AltitudeMap,
  AltitudeMaps,
  AltitudeMapsState,
} from '../models/altitudeMap'
import { FetchingStatus } from '../models/fetchingStatus'
import {
  deleteRecord,
  mergeRecord,
  mergeRecords,
  setFetchingStatus,
} from '../utils'

const initialState: AltitudeMapsState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

export default (
  state: AltitudeMapsState = initialState,
  action: AltitudeMapAction
): AltitudeMapsState => {
  switch (action.type) {
    case getType(createAltitudeMapAction.success):
    case getType(updateAltitudeMapAction.success):
      return {
        ...state,
        data: mergeRecord<AltitudeMaps, AltitudeMap, 'id'>(
          state.data,
          action.payload,
          'id'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getAllAltitudeMapsAction.success):
      return {
        ...state,
        data: mergeRecords<AltitudeMaps, AltitudeMap, 'id'>(
          state.data,
          action.payload,
          'id'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(deleteAltitudeMapAction.success):
      return {
        ...state,
        data: deleteRecord<AltitudeMaps, AltitudeMap>(
          state.data,
          action.payload.id
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getAllAltitudeMapsAction.request):
    case getType(createAltitudeMapAction.request):
    case getType(updateAltitudeMapAction.request):
    case getType(deleteAltitudeMapAction.request):
      return setFetchingStatus<AltitudeMaps>(state, FetchingStatus.Request)
    case getType(getAllAltitudeMapsAction.failure):
    case getType(createAltitudeMapAction.failure):
    case getType(updateAltitudeMapAction.failure):
    case getType(deleteAltitudeMapAction.failure):
      return setFetchingStatus<AltitudeMaps>(state, FetchingStatus.Failure)
    default:
      return state
  }
}
