import React from 'react'
import { PageLayout } from '../../components/Layout'
import { useCurrentView } from '../../hooks'
import { LocationManagementToolbar } from './LocationManagementToolbar'
import { LocationManagementView } from './Locations/LocationManagmentMapPage'
import SubUnitManagementPage from './SubUnits/SubUnitManagementPage'
import UnitManagementPage from './Units/UnitManagementPage'
import { LocationManagementPageViews } from '../../models'

const LocationManagementPage: React.FC = () => {
  // Data Hooks
  const { currentView, setCurrentView } =
    useCurrentView<LocationManagementPageViews>('locations')

  return (
    <PageLayout>
      <LocationManagementToolbar
        activeView={currentView}
        onViewChange={(view: LocationManagementPageViews): void =>
          setCurrentView(view)
        }
      />
      {currentView === 'locations' && <LocationManagementView />}
      {currentView === 'units' && <UnitManagementPage />}
      {currentView === 'subunits' && <SubUnitManagementPage />}
    </PageLayout>
  )
}

export { LocationManagementPage }
