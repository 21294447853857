import { User } from '../models'
import { BaseService } from './baseService'

export class CurrentUserApi extends BaseService {
  constructor() {
    super({ entityType: 'currentUser' })
  }
  route = 'users'

  async getUserById(id: string): Promise<User> {
    return await this.get(`${this.route}/${id}`)
  }
}
