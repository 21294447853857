import { getType } from 'typesafe-actions'
import { ExternalLinksAction } from '../actions'
import {
  getAllExternalLinksAction,
  putExternalLinkAction,
  postExternalLinkAction,
  postExternalLinksAction,
} from '../actions/externalLinks'
import {
  ExternalLink,
  ExternalLinks,
  ExternalLinksState,
  FetchingStatus,
} from '../models'
import { deleteLoadRecords, mergeRecord } from '../utils'

const initialState: ExternalLinksState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

export default (
  state = initialState,
  action: ExternalLinksAction
): ExternalLinksState => {
  switch (action.type) {
    case getType(getAllExternalLinksAction.request):
    case getType(putExternalLinkAction.request):
    case getType(postExternalLinksAction.request):
    case getType(postExternalLinkAction.request):
      return {
        ...state,
        status: FetchingStatus.Request,
      }

    case getType(getAllExternalLinksAction.failure):
    case getType(putExternalLinkAction.failure):
    case getType(postExternalLinksAction.failure):
    case getType(postExternalLinkAction.failure):
      return {
        ...state,
        status: FetchingStatus.Failure,
      }
    case getType(getAllExternalLinksAction.success):
      return {
        data: deleteLoadRecords<ExternalLinks, ExternalLink, 'id'>(
          state.data,
          action.payload,
          'id'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(putExternalLinkAction.success):
      return {
        data: state.data,
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(postExternalLinkAction.success):
      return {
        ...state,
        data: mergeRecord<ExternalLinks, ExternalLink, 'id'>(
          state.data,
          action.payload,
          'id'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(postExternalLinksAction.success):
      return {
        ...state,
        data: deleteLoadRecords<ExternalLinks, ExternalLink, 'id'>(
          state.data,
          action.payload,
          'id'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    default:
      return state
  }
}
