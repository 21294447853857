import { useFilters, useSearchFilter } from '.'
import { CustomSearchTypes } from '../../filters'

export const useGlobalSearch = <D extends object = {}>(
  data: D[] | undefined,
  ignorePropNames?: (keyof D)[],
  customTypes?: CustomSearchTypes
): D[] | undefined => {
  const { filter: searchFilter } = useSearchFilter<D>(
    ignorePropNames,
    customTypes
  )

  return useFilters<D>(data, [searchFilter])
}

export default useGlobalSearch
