import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import React, { useMemo, useState } from 'react'
import { BatteryStatus } from '../../helpers/battery'
import { defaultAutocompleteStyles } from '../../helpers/filters'
import { useRealTimeFilters } from '../../hooks'
import {
  BadgeSignalAccuracy,
  BadgeSignalStatus,
  SignalType,
  StringObj,
  Unit,
} from '../../models'
import { useFormStyle } from '../../styles/index'
import { DataTestIds } from '../../utils/test-utils/dataTestIds'
import SortedAutocomplete from '../Filters/SortedAutoComplete'
import { AssetFilters, StaffFilters } from '../../models/filters'

interface Props {
  hideLowBatteryFilter?: boolean
  isManagement?: boolean
  showAssetsSection: boolean
  units: Unit[]
  handleFilterChange: (
    changes: Partial<AssetFilters> | Partial<StaffFilters>,
    savedFilter?: boolean
  ) => void
}

const CommonFilterOptions: React.FC<
  Props & WithStyles<typeof defaultAutocompleteStyles>
> = (props: Props & WithStyles<typeof defaultAutocompleteStyles>) => {
  const { hideLowBatteryFilter, isManagement, units, handleFilterChange } =
    props

  const { commonFilterState: commonFilters } = useRealTimeFilters()

  const [errorMsg, setErrorMsg] = useState<string>()
  const [errorValidation, setErrorValidation] = useState<boolean>()

  const signalAccuracy = useMemo(
    () =>
      Object.values(BadgeSignalAccuracy).reduce(
        (acc: StringObj[], value: string, index: number) => {
          if (!Number.isNaN(Number(value))) {
            return acc
          }
          return [...acc, { id: index, name: value }]
        },
        []
      ),
    []
  )

  const signalStatus = useMemo(
    () =>
      Object.values(BadgeSignalStatus).reduce(
        (acc: StringObj[], value: string, index: number) => {
          if (!Number.isNaN(Number(value))) {
            return acc
          }
          return [...acc, { id: index, name: value }]
        },
        []
      ),
    []
  )

  const signalType = useMemo(
    () =>
      Object.values(SignalType).reduce(
        (acc: StringObj[], value: string | SignalType, index: number) => {
          if (!Number.isNaN(Number(value))) {
            return acc
          }
          return [...acc, { id: index, name: value.toString() }]
        },
        []
      ),
    []
  )

  const batteryStatus = useMemo(
    () =>
      Object.values(BatteryStatus).reduce(
        (acc: StringObj[], value: string | BatteryStatus, index: number) => {
          if (!Number.isNaN(Number(value))) {
            return acc
          }
          return [...acc, { id: index, name: value.toString() }]
        },
        []
      ),
    []
  )

  const [selectedDate, setSelectedDate] = useState<Date | string | null>()

  const handleChange = (event: any) => {
    if (event !== null) {
      const momentObj = moment(event._d)
      if (momentObj !== undefined && event._d !== null) {
        const validDate = momentObj.format('MM/DD/YYYY')
        setSelectedDate(validDate)
        if (validDate !== 'Invalid date') {
          handleFilterChange({
            tagBatteryChangeDate: new Date(
              moment(validDate).format('MM/DD/YYYY')
            ),
          })
          setErrorValidation(false)
          setErrorMsg('')
        }
      }
    } else {
      setSelectedDate(event)
    }
  }

  const handleBlur = (event: any) => {
    if (selectedDate !== undefined) {
      const momentObj = moment(selectedDate)
      if (momentObj !== undefined && selectedDate !== null) {
        const validDate = momentObj.format('MM/DD/YYYY')
        if (validDate !== 'Invalid date') {
          handleFilterChange({
            tagBatteryChangeDate: new Date(
              moment(validDate).format('MM/DD/YYYY')
            ),
          })
          setErrorValidation(false)
          setErrorMsg('')
        } else {
          handleFilterChange({
            tagBatteryChangeDate: undefined,
          })
          setErrorMsg(validDate)
          setErrorValidation(true)
        }
      } else {
        handleFilterChange({
          tagBatteryChangeDate: undefined,
        })
        setErrorValidation(false)
        setErrorMsg('')
      }
    }
  }

  const formClasses = useFormStyle()

  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              color='primary'
              icon={<CheckBoxOutlineBlankIcon color='primary' />}
              checked={commonFilters.isWatchlist ?? false}
              onChange={(event, checked: boolean): void =>
                handleFilterChange({ isWatchlist: checked })
              }
              name='isWatchlisted'
            />
          }
          label='On My List'
          data-testid={`${DataTestIds.commonFilterOptionsOnMyList}`}
        />
        {isManagement && (
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                icon={<CheckBoxOutlineBlankIcon color='primary' />}
                checked={commonFilters.hasBadge ?? false}
                onChange={(event, checked: boolean): void =>
                  handleFilterChange({ hasBadge: checked })
                }
                name='hasBadge'
              />
            }
            label='Has Badge'
            data-testid={`${DataTestIds.commonFilterOptionsHasBadge}`}
          />
        )}
        {!isManagement && (
          <>
            <FormControl variant='standard' className={formClasses.inputStyle}>
              <SortedAutocomplete
                value={commonFilters.signalAccuracy}
                items={signalAccuracy}
                itemKey='name'
                itemValueCompareKey='id'
                label='RTLS Location Accuracy'
                handleChange={(event, selected: StringObj[]) =>
                  selected &&
                  handleFilterChange({
                    signalAccuracy: selected,
                  })
                }
                dataTestId={`${DataTestIds.commonFilterOptionsLocationAccuracy}`}
              />
            </FormControl>
            <FormControl variant='standard' className={formClasses.inputStyle}>
              <SortedAutocomplete
                value={commonFilters.signalStatus}
                items={signalStatus}
                itemKey='name'
                itemValueCompareKey='id'
                label='RTLS Signal Status'
                handleChange={(event, selected: StringObj[]) =>
                  selected &&
                  handleFilterChange({
                    signalStatus: selected,
                  })
                }
                dataTestId={`${DataTestIds.commonFilterOptionsSignalStatus}`}
              />
            </FormControl>
            <FormControl variant='standard' className={formClasses.inputStyle}>
              <SortedAutocomplete
                value={commonFilters.signalType}
                items={signalType}
                itemKey='name'
                itemValueCompareKey='id'
                label='RTLS Signal Type'
                handleChange={(event, selected: StringObj[]) =>
                  selected &&
                  handleFilterChange({
                    signalType: selected,
                  })
                }
                dataTestId={`${DataTestIds.commonFilterOptionsSignalType}`}
              />
            </FormControl>
            <FormControl variant='standard' className={formClasses.inputStyle}>
              <SortedAutocomplete
                value={commonFilters.batteryStatus}
                items={batteryStatus}
                itemKey='name'
                itemValueCompareKey='id'
                label='RTLS Battery Status'
                handleChange={(event, selected: StringObj[]) =>
                  selected &&
                  handleFilterChange({
                    batteryStatus: selected,
                  })
                }
                dataTestId={`${DataTestIds.commonFilterOptionsBatteryStatus}`}
              />
            </FormControl>
          </>
        )}
        <FormControl
          variant='standard'
          className={formClasses.inputStyle}
          data-testid={`${DataTestIds.commonFilterOptionsBatteryChangeDate}`}
        >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              format='MM/DD/YYYY'
              label='RTLS Battery Change Date'
              value={
                commonFilters.tagBatteryChangeDate
                  ? moment(commonFilters.tagBatteryChangeDate)
                  : null
              }
              defaultValue={null}
              onChange={handleChange}
              slotProps={{
                textField: {
                  variant: 'filled',
                  margin: 'normal',
                  name: 'tagBatteryChangeDate',
                  color: 'primary',
                  error: errorValidation,
                  helperText: errorMsg,
                  onBlur: handleBlur,
                },
              }}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl variant='standard' className={formClasses.inputStyle}>
          <SortedAutocomplete
            value={commonFilters.assignedTo}
            items={units}
            itemKey='name'
            itemValueCompareKey='id'
            label='Assigned To'
            handleChange={(event, selected: Unit[]): void =>
              selected &&
              handleFilterChange({
                assignedTo: selected,
              })
            }
            dataTestId={`${DataTestIds.commonFilterOptionsAssignedTo}`}
          />
        </FormControl>
      </FormGroup>
    </>
  )
}

export default withStyles(defaultAutocompleteStyles)(CommonFilterOptions)
