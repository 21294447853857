import { staffAssistEnabled, staffDuressEnabled } from '..'
import {
  AgentTypePlural,
  Assets,
  BaseBadgeTelemetryMessage,
  Staff,
  StaffEvents,
  StaffMembers,
} from '../../models'
import { getBadgeState } from './badgeState'

export function getAgentGroupCount(
  geoFenceId: number,
  agentTypes?: string[],
  assets?: Assets,
  staff?: StaffMembers,
  isDuress = false,
  isAssist = false,
  duressNotifications?: StaffEvents,
  assistNotifications?: StaffEvents,
  isPrivate = false
): string[] {
  const badgeState = getBadgeState()
  const agentsInGroup: string[] = []
  if (assets && agentTypes?.includes(AgentTypePlural[AgentTypePlural.Assets])) {
    const filteredAssetsWithBadge = Object.values(assets).filter((asset) =>
      Boolean(asset.badgeIds?.length)
    )
    const validTrackingIds = filteredAssetsWithBadge.flatMap((asset) =>
      asset.badgeIds.map(String)
    )

    const geoFenceAssets = Object.values(badgeState)?.filter(
      (x: BaseBadgeTelemetryMessage) => x.geoFenceId === geoFenceId
    )
    const trackingIds = geoFenceAssets?.map(
      (asset: BaseBadgeTelemetryMessage) => asset.trackingId
    )
    trackingIds
      ?.filter((asset: string) => {
        return validTrackingIds.includes(asset)
      })
      .forEach((x) => agentsInGroup.push(x))
  }
  if (staff && agentTypes?.includes(AgentTypePlural[AgentTypePlural.Staff])) {
    const geoFenceAgents = Object.values(badgeState)?.filter(
      (x: BaseBadgeTelemetryMessage) => x.geoFenceId === geoFenceId
    )
    let filteredStaffWithBadge: Staff[] = []

    filteredStaffWithBadge = Object.values(staff).filter((staff) =>
      Boolean(staff.badgeIds?.length)
    )

    if (isDuress && isPrivate) {
      filteredStaffWithBadge = filteredStaffWithBadge.filter(
        (staff) =>
          staffDuressEnabled(staff.badgeIds[0], duressNotifications) === true &&
          geoFenceAgents.filter((item) => item.trackingId === staff.badgeIds[0])
            .length > 0
      )
    }

    if (isAssist && isPrivate) {
      filteredStaffWithBadge = filteredStaffWithBadge.filter(
        (staff) =>
          staffAssistEnabled(staff.badgeIds[0], assistNotifications) === true &&
          geoFenceAgents.filter((item) => item.trackingId === staff.badgeIds[0])
            .length > 0
      )
    }

    const validTrackingIds = filteredStaffWithBadge.flatMap((staff) =>
      staff.badgeIds.map(String)
    )

    const trackingIds = geoFenceAgents?.map(
      (staff: BaseBadgeTelemetryMessage) => staff.trackingId
    )

    trackingIds
      ?.filter((staff: string) => {
        return validTrackingIds.includes(staff)
      })
      .forEach((x) => agentsInGroup.push(x))
  }

  return agentsInGroup
}
