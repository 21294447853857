import { getType } from 'typesafe-actions'
import { AgentEventViewsAction } from '../actions'
import { getAgentEventViewsByUnitsAndLocationAction } from '../actions/agentEventViews'
import { FetchingStatus } from '../models/fetchingStatus'
import { setFetchingStatus } from '../utils'
import {
  AgentEventViewsByUnitsAndLocationPagination,
  AgentEventViewsByUnitsAndLocationState,
} from '../models'

const initialState: AgentEventViewsByUnitsAndLocationState = {
  data: {
    // Future improvement: set up a dictionary for all location/unitIds combinations to avoid re-fetching
    agentEventViews: [],
    cacheKey: '',
    currentPage: 0,
    hasMore: true,
    totalCount: 0,
    pageSize: 100,
    lastTimePeriod: 10, // 48 hours
  },
  status: undefined,
}

const generateKey = function (locationId: string, unitIds: string[]) {
  return `location:${locationId}|unitIds:${unitIds?.join(',') ?? ''}`
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (
  state: AgentEventViewsByUnitsAndLocationState = initialState,
  action: AgentEventViewsAction
): AgentEventViewsByUnitsAndLocationState => {
  switch (action.type) {
    case getType(getAgentEventViewsByUnitsAndLocationAction.success):
      return {
        ...state,
        data: {
          agentEventViews: [
            ...state.data.agentEventViews,
            ...action.payload.values,
          ],
          cacheKey: state.data.cacheKey,
          currentPage:
            action.payload.values.length > 0
              ? action.payload.page
              : state.data.currentPage, // only advance to the next page if there were new items
          hasMore: action.payload.nextLink !== null,
          totalCount: action.payload.totalCount,
          pageSize: state.data.pageSize,
          lastTimePeriod: state.data.lastTimePeriod,
        },
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getAgentEventViewsByUnitsAndLocationAction.request):
      const cacheKey = generateKey(
        action.payload.locationId,
        action.payload.unitIds
      )
      if (state.data.cacheKey === cacheKey || state.data.cacheKey === '') {
        // we are still using the same filters, okay to proceed
        if (state.data.hasMore) {
          // there is more data to fetch, okay to make request
          return setFetchingStatus<AgentEventViewsByUnitsAndLocationPagination>(
            { ...state, data: { ...state.data, cacheKey } },
            FetchingStatus.Request
          )
        } else {
          // this data is cached
          return { ...state }
        }
      } else {
        return setFetchingStatus<AgentEventViewsByUnitsAndLocationPagination>(
          {
            ...state,
            data: {
              ...state.data,
              cacheKey,
              agentEventViews: [],
              currentPage: 0,
            },
          },
          FetchingStatus.Request
        )
      }

      // already have all the data in state
      return { ...state }

    case getType(getAgentEventViewsByUnitsAndLocationAction.failure):
      return setFetchingStatus<AgentEventViewsByUnitsAndLocationPagination>(
        state,
        FetchingStatus.Failure
      )
    default:
      return { ...state }
  }
}
