import { isEmpty } from 'lodash'
import { Geofences } from '../../models'
import jmap from 'jmap.js'
import { colors } from '../../styles'
import { renderCurrentMapViewControl } from './renderCurrentMap'
import { GeofenceInstance, GeofenceKitInstance } from '../../models/jibestream'

export function addInpixonGeofenceHighlight(
  geofenceKit: GeofenceKitInstance,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any,
  geofenceId: number,
  fetchedGeofences: Geofences
): GeofenceInstance | undefined {
  if (!isEmpty(fetchedGeofences)) {
    let inpixonGeofence: GeofenceInstance | undefined
    const geofenceCoreRef = Object.values(fetchedGeofences).find(
      (geofence) => geofence.coreReferenceId === geofenceId
    )
    if (geofenceCoreRef) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      geofenceKit.geofences._items.forEach((item: any) => {
        if (item?.instances) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          item.instances.forEach((instance: any) => {
            if (instance.id === geofenceCoreRef.id) {
              inpixonGeofence = instance
            }
          })
        }
      })
    }
    if (inpixonGeofence) {
      const styles = new jmap.Style({
        fill: colors.duress,
        opacity: 0.2,
        stroke: colors.duress,
        strokeWidth: 0.4,
        strokeOpacity: 10,
      })
      geofenceKit.drawPolygonOfGeofenceInstance(inpixonGeofence, styles)
      renderCurrentMapViewControl(jibestreamController)
    } else {
      console.warn(
        'Inpixon geofence instance not found! Unable to add geofence highlight!'
      )
    }
    return inpixonGeofence
  }
}

export function removeInpixonGeofenceHighlights(
  geofenceKit: GeofenceKitInstance,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any,
  geofenceInstances: GeofenceInstance[],
  mapId: number
): void {
  geofenceInstances.forEach((geofenceInstance) => {
    if (geofenceInstance.floor.map.id === mapId) {
      geofenceKit.removePolygonOfGeofenceInstance(geofenceInstance)
    }
  })
  renderCurrentMapViewControl(jibestreamController)
}

export function removeInpixonGeofenceHighlight(
  geofenceKit: GeofenceKitInstance,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any,
  geofenceInstance: GeofenceInstance
): void {
  geofenceKit.removePolygonOfGeofenceInstance(geofenceInstance)
  renderCurrentMapViewControl(jibestreamController)
}
