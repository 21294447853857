import { DialogClassKey, Modal } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { ReactNode, useCallback, useState } from 'react'
import { DataTestIds } from '../../utils/test-utils/dataTestIds'
import FrontModal from './FrontModal'

interface Props {
  openFrontModal: boolean
  onClose: () => void
  onModalExitedTransition: () => void
  width?: number
  dialogContainerClasses?: Partial<Record<DialogClassKey, string> | undefined>
  showStackedModal?: boolean
  backModalContent?: ReactNode
  frontModalContent: ReactNode
}

const useStyles = makeStyles((theme) => ({
  paper: (props: Props) => ({
    position: 'absolute',
    width: props.width ? props.width - 40 : 560,
    backgroundColor: theme.palette.background.paper,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '4px',
    outline: 'none',
    boxShadow:
      '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)',
  }),
}))

const StackingModals = (props: Props): JSX.Element | null => {
  const {
    openFrontModal,
    width,
    dialogContainerClasses,
    showStackedModal = true,
    frontModalContent,
    backModalContent,
  } = props
  const [modalHeight, setModalHeight] = useState(286)

  const style = {
    height: modalHeight,
    width: 560,
    transform: 'translate(-50%, -45%)',
  }

  if (width) {
    style.width = width - 40
  }

  const classes = useStyles(props)

  const modalRef = useCallback((node) => {
    if (node !== null) {
      setModalHeight(node.getBoundingClientRect().height + 20)
    }
  }, [])

  return showStackedModal ? (
    <>
      {backModalContent ? (
        <>
          <Modal open>
            <div
              data-testid={DataTestIds.stackedModalBack}
              className={`${classes.paper} ${dialogContainerClasses?.paper}`}
              style={style}
            >
              {backModalContent}
              <FrontModal
                open={openFrontModal}
                {...props}
                classes={classes}
                hideBackdrop={true}
                ref={modalRef}
                modalContent={frontModalContent}
              />
            </div>
          </Modal>
        </>
      ) : (
        <div className={classes.paper} style={style}>
          <FrontModal
            open={openFrontModal}
            {...props}
            classes={classes}
            ref={modalRef}
            modalContent={frontModalContent}
          />
        </div>
      )}
    </>
  ) : (
    <></>
  )
}

export { StackingModals }
