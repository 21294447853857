import React, { ReactNode } from 'react'
import { Popper } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

interface Props {
  isOpen: boolean
  anchorEl: HTMLDivElement | null
  verticalOffset: number
  horizontalOffset: number
  maxHeight: number
  width: number
  children: ReactNode
}

const ScrollableModal = (props: Props): JSX.Element | null => {
  const {
    isOpen,
    anchorEl,
    verticalOffset,
    horizontalOffset,
    maxHeight,
    width,
    children,
  } = props

  const useContainerStyles = makeStyles((theme) => ({
    parent: {
      overflow: 'hidden',
      maxHeight: maxHeight,
      height: '100%',
      width: width,
    },
    child: {
      overflowY: 'scroll',
      overflowX: 'hidden',
      boxSizing: 'content-box',
      maxHeight: maxHeight,
      height: '100%',
      width: '100%',
      paddingRight: 20,
    },
  }))

  const classes = useContainerStyles()

  return (
    <Popper
      open={isOpen}
      placement='bottom-end'
      anchorEl={anchorEl}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [horizontalOffset, verticalOffset],
          },
        },
      ]}
      style={{
        right: 0,
        left: 'auto',
        width: width,
        zIndex: 1300,
        height: maxHeight,
      }}
    >
      <div className={classes.parent}>
        <div className={classes.child}>{children}</div>
      </div>
    </Popper>
  )
}

export default ScrollableModal
