import { getType } from 'typesafe-actions'
import { GeofenceAction } from '../actions'
import { mergeRecords } from '../utils'
import { Geofence, GeofencesState, FetchingStatus, Geofences } from '../models'
import { getAllGeofencesAction } from '../actions/geofence'

const initialState: GeofencesState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}
export default (
  state = initialState,
  action: GeofenceAction
): GeofencesState => {
  switch (action.type) {
    case getType(getAllGeofencesAction.success):
      return {
        ...state,
        data: mergeRecords<Geofences, Geofence, 'id'>(
          state.data,
          action.payload,
          'id'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getAllGeofencesAction.request):
      return {
        ...state,
        status: FetchingStatus.Request,
      }
    case getType(getAllGeofencesAction.failure):
      return {
        ...state,
        status: FetchingStatus.Failure,
      }
    default:
      return state
  }
}
