import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import React, { useEffect } from 'react'
import { FlexBox } from '.'
import { StyledComponent, StyledComponentProps, stylize } from '..'
import { useDispatch } from 'react-redux'
import { setRightDrawerOpen } from '../../actions/pageLayout'

const styles = createStyles({
  root: {
    background: '#fafafa',
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },
})

// eslint-disable-next-line @typescript-eslint/ban-types
const PageLayout: StyledComponent<{}> = (
  // eslint-disable-next-line @typescript-eslint/ban-types
  props: StyledComponentProps<{}, typeof styles>
) => {
  const { classes, children } = props
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(setRightDrawerOpen(false))
    }
  }, [dispatch])
  return (
    <FlexBox layout='column' {...stylize(props, [classes.root])}>
      {children}
    </FlexBox>
  )
}

export default withStyles(styles, { name: 'PageLayout' })(PageLayout)
