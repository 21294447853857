import { getType } from 'typesafe-actions'
import { CurrentUserLocationAction } from '../actions'
import {
  getCurrentUserLocationAction,
  updateCurrentUserLocation,
} from '../actions/currentUserLocation'
import { FetchingStatus } from '../models'
import {
  CurrentUserLocationState,
  CurrentUserLocation,
} from '../models/currentUserLocation'
import { setFetchingStatus } from '../utils'

const initialState: CurrentUserLocationState = {
  data: undefined,
  status: undefined,
  lastRefreshTime: undefined,
}

const currentUserLocationReducer = (
  state: CurrentUserLocationState = initialState,
  action: CurrentUserLocationAction
): CurrentUserLocationState => {
  switch (action.type) {
    case getType(getCurrentUserLocationAction.success):
      if (action.payload) {
        return {
          data: action.payload,
          status: FetchingStatus.Success,
          lastRefreshTime: new Date(),
        }
      } else {
        return state
      }

    case getType(updateCurrentUserLocation):
      if (action.payload) {
        if (
          action.payload.badgeTelemetry.mapId ===
            state.data?.badgeTelemetry.mapId &&
          action.payload.badgeTelemetry.geoFenceId ===
            state.data?.badgeTelemetry.geoFenceId &&
          state.data?.geoFenceId === action.payload.badgeTelemetry.geoFenceId
        ) {
          return state
        }
        return {
          data: action.payload,
          status: FetchingStatus.Success,
          lastRefreshTime: new Date(),
        }
      } else {
        return state
      }
    case getType(getCurrentUserLocationAction.request):
      return setFetchingStatus<CurrentUserLocation | undefined>(
        state,
        FetchingStatus.Request
      )
    case getType(getCurrentUserLocationAction.failure):
      return setFetchingStatus<CurrentUserLocation | undefined>(
        state,
        FetchingStatus.Failure
      )
    default:
      return state
  }
}

export default currentUserLocationReducer
