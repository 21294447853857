import { AssetSubType } from '../models'
import { BaseService } from './baseService'

export class AssetSubTypesApi extends BaseService {
  constructor() {
    super({ entityType: 'assetSubTypes' })
  }
  route = 'assets/subTypes'

  async getAllAssetSubTypes(): Promise<AssetSubType[]> {
    return await this.get<AssetSubType[]>(this.route)
  }
}
