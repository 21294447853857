import { useState, useEffect } from 'react'
import { useLocation } from 'react-router'

// React Router pulls `search` from location object.
// `search` is formatted like '?view=map' and '?view=map&is=awesome'
// This function extracts each param and returns:
// {
//   view: 'map',
//   is: 'awesome',
// }
export function useQueryParams(): { [key: string]: string } {
  const { search } = useLocation()
  const [params, setParams] = useState<{ [param: string]: string }>({})

  useEffect(() => {
    setParams(
      search
        ? search
            .split('?')[1]
            .split('&')
            .map((param) => param.split('='))
            .reduce(
              (params, pairs) => ({
                ...params,
                [pairs[0]]: pairs[1],
              }),
              {}
            )
        : {}
    )
  }, [search])

  return params
}
