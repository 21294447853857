import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { RealTimeToolbar } from '.'
import { setTelemetryEnabledAction } from '../../actions/telemetrySubscription'
import { PageLayout } from '../../components/Layout'
import RealtimeMultiSelect from '../../components/Selects/RealtimeMultiSelect'
import RealtimeSelect from '../../components/Selects/RealtimeSelect'
import UnitMultiSelect from '../../components/Selects/UnitMultiSelect'
import TelemetryNotificationBarContainer from '../../components/TelemetryNotificationBar/TelemetryNotificationBarContainer'
import { FeatureFlagLabels, FeatureFlagNames } from '../../constants'
import { GetManagerDisplayOption, lowercaseFirstChar } from '../../helpers'
import {
  useCurrentFloor,
  useCurrentLocation,
  useCurrentView,
  useFetchAssetSubTypes,
  useFetchAssetTypes,
  useFetchAssetsByLocation,
  useFetchCurrentUser,
  useFetchLocations,
  useFetchManufacturers,
  useFetchModels,
  useFetchOwners,
  useFetchStaffByLocation,
  useFetchStaffTypes,
  useFetchUnits,
  useFloorSelection,
  useRealTimeFilters,
  useStaffVisibility,
  useAssetVisibility,
  useTelemetrySubscription,
} from '../../hooks'
import { useFetchFeatureFlags } from '../../hooks/entities/useFetchFeatureFlags'
import {
  AgentTypePlural,
  AuthState,
  FetchingStatus,
  RealTimePageViews,
  SignalStatus,
} from '../../models'
import RealTimeAssetListPage from './Asset/RealTimeAssetListPage'
import RealTimeMapPage from './Map/RealTimeMapPage'
import RealTimeStaffListPage from './Staff/RealTimeStaffListPage'
import { InpixonProvider } from '../../hooks/inpixon'

interface FilterButtonToggled {
  assetListPage: boolean | undefined
  staffListPage: boolean | undefined
  mapPage: boolean | undefined
}

const RealTimePage = (): JSX.Element => {
  const { assetTypes, status: assetTypeStatus } = useFetchAssetTypes()
  const { assetSubTypes, status: assetSubTypeStatus } = useFetchAssetSubTypes()
  const { manufacturers } = useFetchManufacturers()
  const { models } = useFetchModels()
  const { owners } = useFetchOwners()
  const { data: units } = useFetchUnits()
  const { staffTypes } = useFetchStaffTypes()
  const { building, floors } = useFloorSelection()
  const { currentLocation, venueId, setCurrentLocation } = useCurrentLocation()
  const currentFloor = useCurrentFloor()

  const [liveTelemetryEnabled, setLiveTelemetryEnabled] =
    useState<boolean>(true)
  const [refreshTable, setRefreshTable] = useState<boolean>(false)
  const [isStaffView, setIsStaffView] = useState<boolean>(false)
  const [isAssetView, setIsAssetView] = useState<boolean>(false)
  const hasStaffVisibility = useStaffVisibility()
  const hasAssetVisibility = useAssetVisibility()
  const {
    assetsByLocation,
    assetsFetchingStatus,
    isFetched: isAssetsByLocationFetched,
  } = useFetchAssetsByLocation(SignalStatus.Recent, refreshTable)
  const {
    staffsByLocation,
    staffs,
    staffsFetchingStatus,
    isFetched: isStaffsByLocationFetched,
  } = useFetchStaffByLocation(SignalStatus.Recent, refreshTable)

  const { user } = useSelector(({ auth }: { auth: AuthState }) => auth)
  const { data: userInfo, status: userInfoStatus } = useFetchCurrentUser(
    user.id
  )

  const [selectedAgentTypes, setSelectedAgentTypes] = useState<string[]>([
    hasAssetVisibility
      ? AgentTypePlural[AgentTypePlural.Assets]
      : hasStaffVisibility
      ? AgentTypePlural[AgentTypePlural.Staff]
      : '',
  ])
  const [filterButtonToggled, setFilterButtonToggled] =
    useState<FilterButtonToggled>({
      assetListPage: undefined,
      mapPage: undefined,
      staffListPage: undefined,
    })

  const { data: locations, status: locationStatus } = useFetchLocations()
  const { currentView, setCurrentView } =
    useCurrentView<RealTimePageViews>('list')
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const agentTypeParam = searchParams.get('agentType')
  const [navParameterSet, setNavParameterSet] = useState(false)
  const dispatch = useDispatch()
  const { enabled: telemetrySubEnabled } = useTelemetrySubscription()

  const { data: featureFlags } = useFetchFeatureFlags(
    FeatureFlagLabels.Navigation
  )

  const isUnitSelectorEnabled =
    Object.values(featureFlags).filter(
      (x) => x.name === FeatureFlagNames.unitSelector && x.enabled
    ).length > 0

  useEffect(() => {
    if (!hasStaffVisibility && !hasAssetVisibility) {
      // this case shouldn't occur in reality, but just a safeguard
      setSelectedAgentTypes([])
      setNavParameterSet(true)
    } else if (!hasStaffVisibility) {
      // prevent setting agent type to Staff if they do not have permission
      setSelectedAgentTypes([AgentTypePlural[AgentTypePlural.Assets]])
      setNavParameterSet(true)
    } else if (
      agentTypeParam &&
      !navParameterSet &&
      selectedAgentTypes.length === 1 &&
      selectedAgentTypes[0] !== agentTypeParam
    ) {
      setSelectedAgentTypes([agentTypeParam])
      setNavParameterSet(true)
    }
  }, [
    agentTypeParam,
    navParameterSet,
    refreshTable,
    hasStaffVisibility,
    hasAssetVisibility,
    userInfo,
    selectedAgentTypes,
  ])

  useEffect(() => {
    if (agentTypeParam === 'Staff') {
      setIsAssetView(false)
      setIsStaffView(true)
      setSelectedAgentTypes(['Staff'])
    } else if (agentTypeParam === 'Asset') {
      setIsAssetView(true)
      setIsStaffView(false)
      setSelectedAgentTypes(['Assets'])
    }
  }, [agentTypeParam])

  useEffect(() => {
    refreshTable && setRefreshTable(false)
  }, [refreshTable])

  useEffect(() => {
    if (
      floors.length > 0 &&
      !currentFloor &&
      building &&
      currentView === 'map'
    ) {
      setCurrentLocation(floors[0])
    }
  }, [floors, building, currentFloor, currentView])

  // extract display name, from asssetsByLocation while removing objects with duplicate names so the name only shows up once on the filter dropdown
  const displayNames = useMemo(() => {
    return assetsByLocation
      ? _.uniqBy(
          assetsByLocation.flatMap((x, index) => ({
            id: index,
            name: x.displayName,
          })),
          'name'
        )
      : []
  }, [assetsByLocation])

  const managerList = useMemo(
    () =>
      _.uniqBy(
        Object.values(staffs).flatMap((x, index) => ({
          id: index,
          name: GetManagerDisplayOption(x, true),
          key: x.agentGuid,
        })),
        'name'
      ),
    [staffs]
  )

  const realTimeFilterState = useRealTimeFilters()
  const {
    assetFilterCountExclusive: assetFilterCount,
    staffFilterCountExclusive: staffFilterCount,
    commonFilterCount,
    clearAssetFilters,
    clearStaffFilters,
  } = realTimeFilterState

  useEffect(() => {
    return () => {
      clearAssetFilters()
      clearStaffFilters()
    }
  }, [clearAssetFilters, clearStaffFilters])

  const filterCount = useMemo((): number => {
    let total = 0

    if (assetFilterCount) {
      total += assetFilterCount
    }

    if (staffFilterCount) {
      total += staffFilterCount
    }

    if (commonFilterCount) {
      total += commonFilterCount
    }

    return total
  }, [assetFilterCount, staffFilterCount, commonFilterCount])

  const handleListChange = useCallback((value?: string) => {
    setFilterButtonToggled({
      assetListPage: undefined,
      staffListPage: undefined,
      mapPage: undefined,
    })
    setSelectedAgentTypes(
      value ? [value] : [AgentTypePlural[AgentTypePlural.Assets]]
    )
  }, [])

  const handleMultiListChange = useCallback((value?: string[]) => {
    setSelectedAgentTypes(value ?? [AgentTypePlural[AgentTypePlural.Assets]])
  }, [])

  const initialized = useMemo(() => {
    return (
      assetTypeStatus === FetchingStatus.Success &&
      assetSubTypeStatus === FetchingStatus.Success &&
      isAssetsByLocationFetched &&
      isStaffsByLocationFetched &&
      userInfoStatus === FetchingStatus.Success
    )
  }, [
    assetTypeStatus,
    assetSubTypeStatus,
    isAssetsByLocationFetched,
    isStaffsByLocationFetched,
    userInfoStatus,
  ])

  const notificationMessage = useMemo(() => {
    let message = ''

    if (selectedAgentTypes.length === 1) {
      message = selectedAgentTypes[0]
      message = lowercaseFirstChar(message)
    } else {
      message = selectedAgentTypes.map((x) => lowercaseFirstChar(x)).join('& ')
    }

    if (message === 'asset') {
      message = 'assets'
    }

    return `While viewing a large number of ${message} automatic updates are
  unavailable.`
  }, [selectedAgentTypes])

  useEffect(() => {
    const isAssetView = selectedAgentTypes.includes(
      AgentTypePlural[AgentTypePlural.Assets]
    )
    const isStaffView = selectedAgentTypes.includes(
      AgentTypePlural[AgentTypePlural.Staff]
    )
    setIsAssetView(isAssetView)
    setIsStaffView(isStaffView)
  }, [selectedAgentTypes])

  const reconnectEvent = useCallback(
    (force: boolean) => {
      if ((!telemetrySubEnabled && initialized) || force) {
        setLiveTelemetryEnabled(true)
        dispatch(setTelemetryEnabledAction(true))
      }
    },
    [dispatch, initialized, telemetrySubEnabled]
  )

  const disconnectEvent = useCallback(() => {
    if (telemetrySubEnabled && initialized) {
      setLiveTelemetryEnabled(false)
      dispatch(setTelemetryEnabledAction(false))
    }
  }, [dispatch, initialized, telemetrySubEnabled])

  const handleToggleFilter = useCallback(() => {
    if (currentView === 'list') {
      if (isAssetView) {
        setFilterButtonToggled((prevState) => {
          return {
            ...prevState,
            assetListPage: !prevState.assetListPage,
          }
        })
      } else if (isStaffView) {
        setFilterButtonToggled((prevState) => {
          return {
            ...prevState,
            staffListPage: !prevState.staffListPage,
          }
        })
      }
    } else if (currentView === 'map') {
      setFilterButtonToggled((prevState) => {
        return {
          ...prevState,
          mapPage: !prevState.mapPage,
        }
      })
    }
  }, [currentView, isAssetView, isStaffView])

  const handleChangeView = useCallback(
    (view: RealTimePageViews) => {
      setFilterButtonToggled({
        assetListPage: undefined,
        mapPage: undefined,
        staffListPage: undefined,
      })
      setLiveTelemetryEnabled(true)
      setCurrentView(view)
      setRefreshTable(true)
      setNavParameterSet(false)
    },
    [setCurrentView]
  )

  const assetFilterOptions = useMemo(
    () => ({
      assetSubTypes: Object.values(assetSubTypes),
      assetTypes: Object.values(assetTypes),
      manufacturers: Object.values(manufacturers),
      displayNames,
      models,
      owners,
    }),
    [assetSubTypes, assetTypes, displayNames, manufacturers, models, owners]
  )

  const staffFilterOptions = useMemo(
    () => ({
      staffTypes: Object.values(staffTypes),
      managers: managerList,
    }),
    [managerList, staffTypes]
  )

  const allUnits = useMemo(() => Object.values(units), [units])

  const listSelectorContent = useMemo((): React.ReactElement => {
    if (!initialized) return <></>

    // remove agent type selector if they dont have staff permission or asset permission
    if (!hasStaffVisibility || !hasAssetVisibility) return <></>

    return currentView === 'list' ? (
      <RealtimeSelect
        onSelectChange={handleListChange}
        currentView={selectedAgentTypes[0]}
      ></RealtimeSelect>
    ) : (
      <RealtimeMultiSelect
        onSelectChange={handleMultiListChange}
        currentView={selectedAgentTypes}
      ></RealtimeMultiSelect>
    )
  }, [
    currentView,
    hasStaffVisibility,
    hasAssetVisibility,
    selectedAgentTypes,
    userInfo,
    initialized,
  ])

  return (
    <PageLayout>
      <RealTimeToolbar
        activeView={currentView}
        onViewChange={handleChangeView}
        filterCount={filterCount}
        onFilterButtonClicked={handleToggleFilter}
        listSelector={listSelectorContent}
        unitSelector={
          isUnitSelectorEnabled ? (
            <UnitMultiSelect
              multiSelect={currentView === 'list'}
            ></UnitMultiSelect>
          ) : (
            <></>
          )
        }
      ></RealTimeToolbar>
      <TelemetryNotificationBarContainer
        key={currentView}
        showLiveTelemetryTurnedOff={!liveTelemetryEnabled}
        refreshButtonAction={() => setRefreshTable(true)}
        reconnectEventAction={() => reconnectEvent(false)}
        disconnectEventAction={() => disconnectEvent()}
        isLoading={!isAssetsByLocationFetched}
        notificationMessage={notificationMessage}
        refreshText={`REFRESH ${currentView?.toLocaleUpperCase()}`}
      />
      {currentView === 'list' && isAssetView && (
        <RealTimeAssetListPage
          assets={assetsByLocation}
          assetsStatus={assetsFetchingStatus}
          venueId={venueId}
          locations={locations}
          locationStatus={locationStatus}
          assetsByLocation={assetsByLocation ?? []}
          liveTelemetryEnabled={liveTelemetryEnabled}
          refreshTable={refreshTable}
          assetFilterOptions={assetFilterOptions}
          filterStates={realTimeFilterState}
          filterButtonToggled={filterButtonToggled.assetListPage}
          units={allUnits}
          setFilterButtonToggled={setFilterButtonToggled}
        />
      )}
      {currentView === 'list' && isStaffView && !isAssetView && (
        <RealTimeStaffListPage
          staffMembers={staffsByLocation}
          staffStatus={staffsFetchingStatus}
          locations={locations}
          locationStatus={locationStatus}
          venueId={venueId}
          staffByLocation={staffsByLocation ?? []}
          liveTelemetryEnabled={liveTelemetryEnabled}
          refreshTable={refreshTable}
          staffFilterOptions={staffFilterOptions}
          filterButtonToggled={filterButtonToggled.staffListPage}
          filterStates={realTimeFilterState}
          units={allUnits}
          setFilterButtonToggled={setFilterButtonToggled}
        />
      )}
      {currentView === 'map' && (
        <InpixonProvider>
          <RealTimeMapPage
            initialized={initialized}
            venueId={venueId}
            currentLocation={currentLocation}
            assetsByLocation={assetsByLocation}
            isAssetsByLocationFetched={isAssetsByLocationFetched}
            assetsStatus={assetsFetchingStatus}
            staffByLocation={staffsByLocation}
            isStaffsByLocationFetched={isStaffsByLocationFetched}
            staffStatus={staffsFetchingStatus}
            agentTypes={selectedAgentTypes}
            currentFloor={currentFloor}
            assetFilterOptions={assetFilterOptions}
            staffFilterOptions={staffFilterOptions}
            filterButtonToggled={filterButtonToggled.mapPage}
            filterStates={realTimeFilterState}
            units={allUnits}
            locations={locations}
            setFilterButtonToggled={setFilterButtonToggled}
          />
        </InpixonProvider>
      )}
    </PageLayout>
  )
}

export default RealTimePage
