import { getType } from 'typesafe-actions'
import { BadgesAtDestinationAction } from '../actions'
import { updateBadgesAtDestinationAction } from '../actions/badgesAtDestination'
import { BadgesAtDestination } from '../models/badgesAtDestination'

export type BadgesAtDestinationState = Readonly<BadgesAtDestination>

const badgesAtDestinationReducer = (
  state: BadgesAtDestinationState = {},
  action: BadgesAtDestinationAction
): BadgesAtDestinationState => {
  switch (action.type) {
    case getType(updateBadgesAtDestinationAction):
      return action.payload
    default:
      return state
  }
}

export default badgesAtDestinationReducer

/*
{
  '93044': [
    '11111', 
    '22222', 
  ],
  '93045': [
    '33333',
    '44444',
  ],
  '93046': [
    '55555',
  ],
  '93047': [
    '66666',
  ],
  '93048': [
    '77777',
    '88888',
    '99999',
  ],
}
*/
