export function renderCurrentMapView(
  jibestream?: any // eslint-disable-line @typescript-eslint/no-explicit-any
): void {
  //Make sure the control.state.map value is not Null
  //This helps keep the renderCurrentMapView from throwing the container error
  if (jibestream?.control?.state?.map) {
    jibestream?.control?.renderCurrentMapView()
  } else {
    console.log(
      'An error occured rendering the current view: renderCurrentMapView'
    )
  }
}

export function renderCurrentMapViewControl(
  control?: any // eslint-disable-line @typescript-eslint/no-explicit-any
): void {
  //Make sure the control.state.map value is not Null
  //This helps keep the renderCurrentMapView from throwing the container error
  if (control?.state?.map) {
    control?.renderCurrentMapView()
  } else {
    console.log(
      'An error occured rendering the current view: renderCurrentMapViewControl'
    )
  }
}
