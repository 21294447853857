import React from 'react'
import { RightDrawerLayout } from '../../Common/RightDrawer'
import { Location, Locations, SubUnits, Units } from '../../../models'
import LocationDetailPaneHeader from './LocationDetailPanelHeader'
import LocationDetailPane from './LocationDetailPanel'

interface Props {
  selectedRoom: Location
  onEditRoom: () => void
  onViewRoomSensors: () => void
  canEdit: boolean
  onClose: () => void
  units: Units
  subUnits: SubUnits
  locations: Locations
}

const LocationDetailDrawer = (props: Props): JSX.Element => {
  const {
    selectedRoom,
    canEdit,
    units,
    subUnits,
    locations,
    onClose,
    onEditRoom,
    onViewRoomSensors,
  } = props
  return (
    <RightDrawerLayout
      header={
        <LocationDetailPaneHeader
          selectedRoom={selectedRoom}
          onEditRoom={onEditRoom}
          onClose={onClose}
          onViewRoomSensors={onViewRoomSensors}
          canEdit={canEdit}
        />
      }
      content={
        <LocationDetailPane
          selectedRoom={selectedRoom}
          units={units}
          subUnits={subUnits}
          locations={locations}
        />
      }
    />
  )
}

export default LocationDetailDrawer
