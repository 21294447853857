import { createAsyncAction } from 'typesafe-actions'
import { Release } from '../models/release'
import { HttpErrorResponse } from '../services/http'

export const getUpcomingReleaseAction = createAsyncAction(
  'GET_UPCOMING_RELEASE_BY_APPLICATION_ID_REQUEST',
  'GET_UPCOMING_RELEASE_BY_APPLICATION_ID_SUCCESS',
  'GET_UPCOMING_RELEASE_BY_APPLICATION_ID_FAILURE'
)<number, Release, HttpErrorResponse>()

export const getPastReleasesAction = createAsyncAction(
  'GET_PAST_RELEASES_BY_APPLICATION_ID_REQUEST',
  'GET_PAST_RELEASES_BY_APPLICATION_ID_SUCCESS',
  'GET_PAST_RELEASES_BY_APPLICATION_ID_FAILURE'
)<number, Release[], HttpErrorResponse>()

export const getAllReleasesAction = createAsyncAction(
  'GET_ALL_RELEASES_REQUEST',
  'GET_ALL_RELEASES_SUCCESS',
  'GET_ALL_RELEASES_FAILURE'
)<void, Release[], HttpErrorResponse>()

export const createReleaseAction = createAsyncAction(
  'CREATE_RELEASES_REQUEST',
  'CREATE_RELEASES_SUCCESS',
  'CREATE_RELEASES_FAILURE'
)<Release, Release, HttpErrorResponse>()

export const updateReleaseAction = createAsyncAction(
  'UPDATE_RELEASES_REQUEST',
  'UPDATE_RELEASES_SUCCESS',
  'UPDATE_RELEASES_FAILURE'
)<Release, Release, HttpErrorResponse>()
