import React from 'react'
import { useSelector } from 'react-redux'
import { Permissions, Role, SortedRole } from '../../models'
import { FetchingStatus } from '../../models/fetchingStatus'
import { PageLayoutState } from '../../models/pageLayout'
import { PageContent } from '../Layout'
import RolesManagementList from './RolesManagementList'

interface Props {
  roles: Role[] | SortedRole[]
  rolesStatus?: FetchingStatus
  permissions: Permissions
}

function RolesManagementListPage(props: Props): JSX.Element {
  const { roles, permissions, rolesStatus } = props

  const maxHeight = useSelector(
    ({ pageLayout }: { pageLayout: PageLayoutState }) => pageLayout.maxHeight
  )

  return (
    <PageContent
      maxHeight={maxHeight}
      content={
        <RolesManagementList
          roles={roles}
          permissions={permissions}
          rolesStatus={rolesStatus}
        />
      }
    />
  )
}

export default RolesManagementListPage
