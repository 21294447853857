import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, mergeMap, map } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { BadgeSensorStateAction } from '../actions'
import { BadgeSensorStateState } from '../models'
import {
  fetchBadgeSensorStatesByBadgeIdsAction,
  fetchBadgeSensorStatesByLocationIdAction,
} from '../actions/badgeSensorStates'
import { BadgeSensorStatesApi } from '../middleware'

type BadgeSensorStateEpic = Epic<
  BadgeSensorStateAction,
  BadgeSensorStateAction,
  BadgeSensorStateState,
  { badgeSensorStatesApi: BadgeSensorStatesApi }
>

export const fetchBadgeAssignmentsByIdEpic: BadgeSensorStateEpic = (
  action$,
  state,
  { badgeSensorStatesApi }
) => {
  return action$.pipe(
    filter(isActionOf(fetchBadgeSensorStatesByLocationIdAction.request)),
    mergeMap((action) =>
      from(
        badgeSensorStatesApi.fetchBadgeSensorStatesByLocationId(action.payload)
      ).pipe(
        map((badgeSensorState) =>
          fetchBadgeSensorStatesByLocationIdAction.success(badgeSensorState)
        ),
        catchError((error) =>
          of(
            fetchBadgeSensorStatesByLocationIdAction.failure({
              locationId: action.payload,
              error,
            })
          )
        )
      )
    )
  )
}

export const fetchBadgeAssignmentByBadgeIdsEpic: BadgeSensorStateEpic = (
  action$,
  state$,
  { badgeSensorStatesApi }
) => {
  return action$.pipe(
    filter(isActionOf(fetchBadgeSensorStatesByBadgeIdsAction.request)),
    mergeMap((action) =>
      from(badgeSensorStatesApi.fetchBadgeSensorStates(action.payload)).pipe(
        map((badgeAssignments) =>
          fetchBadgeSensorStatesByBadgeIdsAction.success(badgeAssignments)
        ),
        catchError((error) =>
          of(
            fetchBadgeSensorStatesByBadgeIdsAction.failure({
              badgeTrafficRequest: action.payload,
              error,
            })
          )
        )
      )
    )
  )
}
