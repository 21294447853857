import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { BuildingAction } from '../actions'
import { getAllBuildingsAction } from '../actions/buildings'
import { BuildingsApi } from '../middleware'
import { BuildingsState } from '../models/buildings'

type BuildingsEpic = Epic<
  BuildingAction,
  BuildingAction,
  BuildingsState,
  { buildingsApi: BuildingsApi }
>

export const getAllBuildingsEpic: BuildingsEpic = (
  action$,
  state,
  { buildingsApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllBuildingsAction.request)),
    mergeMap(() =>
      from(buildingsApi.getAllBuildings()).pipe(
        map((Buildings) => getAllBuildingsAction.success(Buildings)),
        catchError((error) => of(getAllBuildingsAction.failure(error)))
      )
    )
  )
}
