import React, { useMemo } from 'react'
import { buildChip } from '../../helpers/filters'
import { UserFilters } from '../../models'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'

const useStyles = makeStyles(() =>
  createStyles({
    chipContainer: {
      marginBottom: 12,
    },
  })
)

interface Props {
  filterState: UserFilters
  handleChange: (changes: UserFilters) => void
}

const UserListFilterChips = (props: Props): JSX.Element => {
  const { filterState, handleChange } = props
  const classes = useStyles()

  const filterChips = useMemo(() => {
    let chips: JSX.Element[] = []

    if (filterState.selectedRoles) {
      chips = chips.concat(
        filterState.selectedRoles.map((role) =>
          buildChip(`role-${role.id}`, role.name, () => {
            const newRole = filterState.selectedRoles
              ? [...filterState.selectedRoles]
              : []

            newRole.splice(
              newRole.findIndex((x) => x.id === role.id),
              1
            )

            return handleChange({ ...filterState, selectedRoles: newRole })
          })
        )
      )
    }

    return chips
  }, [filterState, handleChange])

  return <div className={classes.chipContainer}>{filterChips}</div>
}

export default UserListFilterChips
