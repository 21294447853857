import {
  FilteredQuickLinkSettings,
  QuickLinkSettings,
  QuickLinkTypes,
} from '../models'

export const getDefaultQuickLinkSettings = (
  assetAlertEnabled: boolean,
  hasStaffVisibility: boolean,
  hasAssetVisibility: boolean
): FilteredQuickLinkSettings => {
  const allIds = Object.keys(QuickLinkTypes)
    .filter((x) => !isNaN(Number(x)))
    .map((x) => parseInt(x))
  const filteredIds = filterQuickLinkTypeIds(
    allIds,
    assetAlertEnabled,
    hasStaffVisibility,
    hasAssetVisibility
  )

  // build object with list of keys and default order and show values
  const defaultDashboardCardSettings: FilteredQuickLinkSettings =
    Object.fromEntries(
      allIds.map((x) => [
        x,
        { id: x, order: x - 1, show: true, enabled: filteredIds.includes(x) },
      ])
    )

  return defaultDashboardCardSettings
}

function filterQuickLinkTypeIds(
  quickLinkTypeIds: number[],
  assetAlertEnabled: boolean,
  hasStaffVisibility: boolean,
  hasAssetVisibility: boolean
): number[] {
  const filteredIds = quickLinkTypeIds.filter((x) => {
    if (x === QuickLinkTypes['Asset Notifications'] && !assetAlertEnabled) {
      return false
    }
    if (!hasStaffVisibility) {
      if (
        x === QuickLinkTypes['My Staff List'] ||
        x === QuickLinkTypes['Real-Time Staff']
      ) {
        return false
      }
    }
    if (!hasAssetVisibility) {
      if (
        x === QuickLinkTypes['Real-Time Assets'] ||
        x === QuickLinkTypes['My Asset List'] ||
        x === QuickLinkTypes['Asset Notifications']
      ) {
        return false
      }
    }
    return true
  })
  return filteredIds
}

export function filterUserQuickLinkSettings(
  userQuickLinkSettings: QuickLinkSettings,
  assetAlertEnabled: boolean,
  hasStaffVisibility: boolean,
  hasAssetVisibility: boolean
): FilteredQuickLinkSettings {
  const allIds = Object.keys(userQuickLinkSettings).map((x) => parseInt(x))
  const filteredIds = filterQuickLinkTypeIds(
    allIds,
    assetAlertEnabled,
    hasStaffVisibility,
    hasAssetVisibility
  )
  const filteredSettings: FilteredQuickLinkSettings = Object.fromEntries(
    allIds.map((x) => [
      x,
      {
        id: x,
        order: userQuickLinkSettings[x].order,
        show: userQuickLinkSettings[x].show,
        enabled: filteredIds.includes(x),
      },
    ])
  )

  return filteredSettings
}
