import { getType } from 'typesafe-actions'
import { StaffSubTypeAction } from '../actions'
import { getAllStaffSubTypesAction } from '../actions/staffSubTypes'
import { StaffSubType, StaffSubTypes, StaffSubTypesState } from '../models'
import { FetchingStatus } from '../models/fetchingStatus'
import { mergeRecords, setFetchingStatus } from '../utils'

const initialState: StaffSubTypesState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

export default (
  state: StaffSubTypesState = initialState,
  action: StaffSubTypeAction
): StaffSubTypesState => {
  switch (action.type) {
    case getType(getAllStaffSubTypesAction.success):
      return {
        data: mergeRecords<StaffSubTypes, StaffSubType, 'staffSubTypeId'>(
          state.data,
          action.payload,
          'staffSubTypeId'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getAllStaffSubTypesAction.request):
      return setFetchingStatus<StaffSubTypes>(state, FetchingStatus.Request)
    case getType(getAllStaffSubTypesAction.failure):
      return setFetchingStatus<StaffSubTypes>(state, FetchingStatus.Failure)
    default:
      return state
  }
}
