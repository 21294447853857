import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { PageContent } from '../../../components/Layout'
import { useReactTableRightDrawer } from '../../../components/Tables/useReactTableRightDrawer'
import { useFetchUnits } from '../../../hooks'
import { Unit } from '../../../models'
import { PageLayoutState } from '../../../models/pageLayout'
import UnitDetailDrawer from './UnitDetailDrawer'
import UnitManagementList from './UnitManagementList'
import UnitManagementModal from './UnitManagementModal'
import { DrawerType } from '../../../components/Layout/PageContent'

const UnitManagementPage = (): JSX.Element => {
  const [openUnitForm, setOpenUnitForm] = useState<boolean>(false)

  const maxHeight = useSelector(
    ({ pageLayout }: { pageLayout: PageLayoutState }) => pageLayout.maxHeight
  )

  const { data: units, status: unitFetchingStatus } = useFetchUnits()

  const {
    rightDrawerState,
    selectRow,
    closeDetailDrawer,
    clearSelectedItem,
    resetSelectedRowIndex,
    updateSelectedRow,
  } = useReactTableRightDrawer<Unit>()
  const {
    currentDrawerType,
    item: selectedRow,
    tableSelectedRowIndex,
  } = rightDrawerState

  const handleCreateUnit = useCallback(() => {
    closeDetailDrawer()
    setOpenUnitForm(true)
  }, [closeDetailDrawer])

  return (
    <PageContent
      maxHeight={maxHeight}
      currentRightDrawerType={currentDrawerType}
      content={
        <>
          <UnitManagementList
            unitFetchingStatus={unitFetchingStatus}
            units={Object.values(units)}
            onEditUnit={() => setOpenUnitForm(true)}
            onCreateUnit={handleCreateUnit}
            tableRowSelectionOptions={{
              selectedRow,
              selectedRowIndex: tableSelectedRowIndex,
              onSelectRow: selectRow,
              clearSelectedRow: clearSelectedItem,
              resetSelectedRowIndex,
              updateSelectedRow,
            }}
          />

          {openUnitForm && (
            <UnitManagementModal
              data={selectedRow ?? null}
              handleClose={() => setOpenUnitForm(false)}
              modalHeaderTitle={
                !!selectedRow ? `Edit Unit ${selectedRow.name}` : 'Create Unit'
              }
            />
          )}
        </>
      }
      rightDrawer={
        currentDrawerType === DrawerType.DetailDrawer && selectedRow ? (
          <UnitDetailDrawer
            selectedUnit={selectedRow}
            onClose={closeDetailDrawer}
            onEditUnit={() => setOpenUnitForm(true)}
          />
        ) : null
      }
    />
  )
}

export default UnitManagementPage
