import { User } from '../models'
import { Role, Roles, SortedRole, UserRoles } from '../models/roles'

export const roleNamesToId = (roleNames: string[], roles: Roles): number[] => {
  return roleNames.map(
    (roleName) =>
      Object.values(roles).filter((role: Role) => role.name === roleName)[0]?.id
  )
}

export const roleIdsToNames = (roleIds: number[], roles: Roles): string[] => {
  if (
    roleIds &&
    roleIds?.length > 0 &&
    roles &&
    Object.values(roles).length > 0
  ) {
    return roleIds.map(
      (roleId) =>
        Object.values(roles).filter((role: Role) => role.id === roleId)[0]
          ?.description
    )
  } else {
    return []
  }
}

export function getSortOrder(roleId: number): number {
  // sortOrder 0 is preserved for All Roles option in dropdown
  switch (roleId) {
    case UserRoles.SystemAdmin:
      return 1
    case UserRoles.ClinicalAdmin:
      return 2
    case UserRoles.ClinicalAssetAdmin:
      return 3
    case UserRoles.SecurityAdmin:
      return 4
    case UserRoles.BioMedAdmin:
      return 5
    case UserRoles.BioMed:
      return 6
    case UserRoles.ClinicalUser:
      return 7
    case UserRoles.ClinicalAssetUser:
      return 8
    case UserRoles.SecurityUser:
      return 9
    default:
      console.warn('Unspecified sort order for role!')
      return 10
  }
}

export function getVisibleRolesByCurrentUserRole(
  currentUserRoleIdIds: number[],
  allRoles: SortedRole[]
): SortedRole[] {
  const currentUserRoleSortOrder = getSortOrder(
    Math.min(...currentUserRoleIdIds)
  )
  return allRoles.filter((role) => role.sortOrder >= currentUserRoleSortOrder)
}

export function isSecurityUserRole(user: User | undefined): boolean {
  if (!user) return false

  return user?.roleIds.includes(UserRoles.SecurityUser)
}

export function isSecurityAdminRole(user: User | undefined): boolean {
  if (!user) return false

  return user?.roleIds.includes(UserRoles.SecurityAdmin)
}

export function isAnySecurityRole(user: User | undefined): boolean {
  if (!user) return false

  return isSecurityAdminRole(user) || isSecurityUserRole(user)
}
