import { getType } from 'typesafe-actions'
import { AssetAction } from '../actions'
import {
  removeAssetFromMapAction,
  removeAssetsFromMapAction,
  deleteAssetAction,
  getAllAssetsAction,
  postAssetAction,
  putAssetAction,
  getAssetsByLocationIdAction,
  getAssetByIdAction,
  silentUpdateAssetAction,
  getPaginatedAssetsAction,
  getFullAssetsDownloadAction,
} from '../actions/assets'
import { downloadBlob } from '../helpers/downloadBlob'
import { Asset, Assets, AssetsState, BadgeState, SignalStatus } from '../models'
import { FetchingStatus } from '../models/fetchingStatus'
import {
  deleteRecord,
  deleteRecords,
  mergeRecord,
  mergeRecords,
  setFetchingStatus,
} from '../utils'

const initialState: AssetsState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
  assetsByLocation: {},
  recordCount: undefined,
  page: undefined,
  prevLink: undefined,
  nextLink: undefined,
}

const assetReducer = (
  state: AssetsState = initialState,
  action: AssetAction
): AssetsState => {
  switch (action.type) {
    case getType(removeAssetFromMapAction):
      return {
        ...state,
        data: deleteRecord<Assets, Asset>(state.data, action.payload),
      }
    case getType(removeAssetsFromMapAction):
      const newState = { ...state.assetsByLocation }

      action.payload.forEach((staff) => {
        Object.entries(newState).forEach((x) => {
          if (x[1].some((s) => s.agentGuid === staff)) {
            const index = x[1].findIndex((s) => s.agentGuid === staff)
            if (index > -1) {
              x[1].splice(index, 1)
            }
          }
        })
      })
      return {
        ...state,
        data: deleteRecords<Assets, Asset>(state.data, action.payload),
        assetsByLocation: newState,
      }
    case getType(postAssetAction.success):
      return {
        ...state,
        data: mergeRecord<Assets, Asset, 'agentGuid'>(
          state.data,
          action.payload,
          'agentGuid'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
        recordCount: state.recordCount ? state.recordCount + 1 : undefined,
      }
    case getType(putAssetAction.success):
      return {
        ...state,
        data: mergeRecord<Assets, Asset, 'agentGuid'>(
          state.data,
          action.payload,
          'agentGuid'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getAllAssetsAction.success):
      return {
        ...state,
        data: mergeRecords<Assets, Asset, 'agentGuid'>(
          state.data,
          action.payload,
          'agentGuid'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getPaginatedAssetsAction.success):
      return {
        ...state,
        data: mergeRecords<Assets, Asset, 'agentGuid'>(
          {},
          action.payload.values,
          'agentGuid'
        ),
        recordCount: action?.payload?.totalCount ?? 0,
        page: action?.payload?.page ?? 1,
        prevLink: action.payload.previousLink,
        nextLink: action.payload.nextLink,
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getAssetByIdAction.success):
      return {
        ...state,
        data: mergeRecord<Assets, Asset, 'agentGuid'>(
          state.data,
          action.payload,
          'agentGuid'
        ),
        status: FetchingStatus.Success,
      }
    case getType(getAssetsByLocationIdAction.success):
      return {
        ...state,
        data: mergeRecords<Assets, Asset, 'agentGuid'>(
          state.data,
          action.payload.assets,
          'agentGuid'
        ),
        assetsByLocation:
          action.payload.signalStatus !== SignalStatus.TimedOut
            ? {
                ...state.assetsByLocation,
                [action.payload.locationId]: action.payload.assets,
              }
            : { ...state.assetsByLocation },
        assetsByLocationLastRefreshTime: new Date(),
        status: FetchingStatus.Success,
      }
    case getType(deleteAssetAction.success):
      return {
        ...state,
        data: deleteRecord<Assets, Asset>(state.data, action.payload),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
        recordCount:
          state.recordCount && state.recordCount >= 1
            ? state.recordCount - 1
            : undefined,
      }

    case getType(getAssetsByLocationIdAction.request):
      return {
        ...state,
        status: FetchingStatus.Request,
        assetsByLocation: {},
        assetsByLocationLastRefreshTime: new Date(),
      }
    case getType(getAllAssetsAction.request):
    case getType(putAssetAction.request):
    case getType(postAssetAction.request):
    case getType(deleteAssetAction.request):
    case getType(getPaginatedAssetsAction.request):
    case getType(getFullAssetsDownloadAction.request):
      return setFetchingStatus<Assets>(state, FetchingStatus.Request)

    case getType(getAssetsByLocationIdAction.failure):
    case getType(getAllAssetsAction.failure):
    case getType(putAssetAction.failure):
    case getType(postAssetAction.failure):
    case getType(deleteAssetAction.failure):
    case getType(getFullAssetsDownloadAction.failure):
    case getType(getPaginatedAssetsAction.failure):
      return setFetchingStatus<Assets>(state, FetchingStatus.Failure)
    case getType(silentUpdateAssetAction.request):
      return state
    case getType(silentUpdateAssetAction.success): {
      if (action.payload.assets.length === 0) {
        return state
      }
      action.payload.assets.forEach((asset) => {
        asset.badgeTelemetry = action.payload.badgeTelemetry?.find(
          (telemetry) =>
            telemetry !== undefined &&
            asset.badgeIds.includes(telemetry.trackingId)
        ) as BadgeState
      })
      const currentAssetsByLocation = state.assetsByLocation
        ? state.assetsByLocation
        : {}
      const currentAssetsByCurrentLocation: Asset[] =
        state.assetsByLocation &&
        state.assetsByLocation[action.payload.locationId]
          ? state.assetsByLocation[action.payload.locationId]
          : []

      if (action?.payload && action.payload.assets.length > 0) {
        return {
          ...state,
          data: mergeRecords<Assets, Asset, 'agentGuid'>(
            state.data,
            action.payload.assets,
            'agentGuid'
          ),
          assetsByLocation: {
            ...currentAssetsByLocation,
            [action.payload.locationId]: [
              ...currentAssetsByCurrentLocation,
              ...action.payload.assets,
            ],
          },
        }
      } else {
        //If the response is empty from the server, return current state to reduce excessive re-renders from state mutation
        return state
      }
    }
    case getType(getFullAssetsDownloadAction.success): {
      let blob = new Blob([action.payload], { type: 'text/csv' })
      downloadBlob('AssetList.csv', blob)
      return {
        ...state,
        status: FetchingStatus.Success,
      }
    }
    default:
      return state
  }
}

export default assetReducer
