import { AgentType, AssetInstance, AssetKitInstance } from '../../models'
import { SelectedAgent } from '../../models/realtimeMap'
import { BadgeTelemetryMessageWithAgentEvent } from '../../models/telemetry'
import {
  RemoveIconHighlightUpdates,
  calculateMapIconUpdatesForSelectingAgent,
} from '../calculateRTMapUpdates'
import {
  removeAgentConfidenceBubble,
  showAgentConfidenceBubble,
  updateInpixonGroupIcon,
} from '../inpixon'

/**
 * After selecting an agent on the map,
 * - open the Detail Drawer
 * - add confidence bubble to selecting agent
 * - remove confidence bubble from currently selected agent (if any)
 * - remove highlighting from currently selected group (if any)
 * @param inpixonAsset
 * @param agentTelemetry
 * @param assetKit
 * @param jibestreamController
 * @param openAssetDetailDrawer
 * @param openStaffDetailDrawer
 */
export function selectAgentOnMap(
  inpixonAsset: AssetInstance,
  agentTelemetry: BadgeTelemetryMessageWithAgentEvent,
  currentlySelectedAgentInpixonAsset: AssetInstance | undefined,
  currentlySelectedGroupInpixonAsset: AssetInstance | undefined,
  assetKit: AssetKitInstance | undefined,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any,
  openAssetDetailDrawer: (payload: SelectedAgent) => void,
  openStaffDetailDrawer: (payload: SelectedAgent) => void
): void {
  if (!assetKit || !jibestreamController) {
    return
  }
  let prevSelectedIconUpdates: RemoveIconHighlightUpdates | undefined
  if (agentTelemetry.agent.agentType === AgentType.Asset) {
    prevSelectedIconUpdates = calculateMapIconUpdatesForSelectingAgent(
      inpixonAsset,
      currentlySelectedAgentInpixonAsset,
      currentlySelectedGroupInpixonAsset
    )
    openAssetDetailDrawer({
      telemetry: agentTelemetry,
      inpixonAsset,
    })
  } else if (agentTelemetry.agent.agentType === AgentType.Staff) {
    prevSelectedIconUpdates = calculateMapIconUpdatesForSelectingAgent(
      inpixonAsset,
      currentlySelectedAgentInpixonAsset,
      currentlySelectedGroupInpixonAsset
    )
    openStaffDetailDrawer({
      telemetry: agentTelemetry,
      inpixonAsset,
    })
  } else {
    console.error('Unsupported agent type. Unable to select agent!')
  }
  if (prevSelectedIconUpdates) {
    const {
      groupsIconToRemoveHighlight,
      prevSelectedAgentTypeToRemoveConfidenceBubble,
    } = prevSelectedIconUpdates
    if (groupsIconToRemoveHighlight) {
      updateInpixonGroupIcon(
        assetKit,
        jibestreamController,
        groupsIconToRemoveHighlight.inpixonAssetType,
        groupsIconToRemoveHighlight.url
      )
    }
    if (prevSelectedAgentTypeToRemoveConfidenceBubble) {
      removeAgentConfidenceBubble(
        assetKit,
        prevSelectedAgentTypeToRemoveConfidenceBubble,
        jibestreamController
      )
    }
    showAgentConfidenceBubble(
      assetKit,
      inpixonAsset,
      jibestreamController,
      agentTelemetry.hasActiveDuress,
      agentTelemetry.hasActiveAssist
    )
  }
}
