import Radio, { RadioProps } from '@mui/material/Radio'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import React from 'react'
import { DataTestIds } from '../../utils/test-utils/dataTestIds'

interface RadioPropsExtra extends RadioProps {
  checkBoxDisabled: boolean
}

const RadioSavedFilterButton = (props: RadioPropsExtra): JSX.Element => {
  const { checkBoxDisabled, ...radioProps } = props

  return (
    <Radio
      color='primary'
      icon={
        <CheckBoxOutlineBlankIcon
          color={checkBoxDisabled ? 'disabled' : 'primary'}
          data-testid={DataTestIds.radioSavedFilterButtonUnChecked}
        />
      }
      checkedIcon={
        <CheckBoxIcon
          color={checkBoxDisabled ? 'disabled' : 'primary'}
          data-testid={DataTestIds.radioSavedFilterButtonChecked}
        />
      }
      {...radioProps}
    />
  )
}

export default RadioSavedFilterButton
