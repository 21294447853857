import { useEffect, useRef } from 'react'

const usePrevious = <T>(value: T, initialValue?: T): T | undefined => {
  const ref = useRef<T>()
  if (initialValue !== undefined) {
    ref.current = initialValue
  }
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export default usePrevious
