import { Autocomplete, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { setRouterPromptIsEnabledStatusAction } from '../../../../actions/routerPrompt'
import { CancelButton, PrimaryButton } from '../../../../components/Buttons'
import AudioPlayButton from '../../../../components/Buttons/AudioPlayButton'
import RouterPrompt from '../../../../components/Common/RouterPrompt'
import { requiredMessage } from '../../../../components/Forms'
import { PageContent } from '../../../../components/Layout'
import FormSkeleton from '../../../../components/Skeleton/FormSkeleton'
import { useFetchChannelTypes } from '../../../../hooks'
import { useFetchChannelSounds } from '../../../../hooks/entities/useFetchChannelSounds'
import { useAlertConfigPageReload } from '../../../../hooks/useAlertConfigPageReload'
import {
  AlertChannel,
  AlertConfigSteps,
  ChannelTypeEnum,
  FetchingStatus,
} from '../../../../models'
import { AlertTypes } from '../../../../models/alert'
import { noSoundChannel } from '../../../../models/channelSounds'
import { PageLayoutState } from '../../../../models/pageLayout'
import { RouterPromptState } from '../../../../models/routerPrompt'
import { useFormStyle } from '../../../../styles'
import { AlertConfigStepperHeaderHeight } from '../../constants'
import useAlertActiveConfig from '../../useAlertActiveConfig'
import AlertConfigStepperHeader from '../AlertConfigStepperHeader'
import AlertConfigRightDrawer from './AlertConfigRightDrawer'
import { formWidth } from './constants'

export const AlertConfigChannelPage = () => {
  const location = useLocation()
  const history = useNavigate()
  const maxHeight = useSelector(
    ({ pageLayout }: { pageLayout: PageLayoutState }) => pageLayout.maxHeight
  )

  const { channelTypes, status: channelTypesStatus } = useFetchChannelTypes()
  const { channelSounds, status: channelSoundsStatus } = useFetchChannelSounds()
  const { activeConfig, setActiveConfig } = useAlertActiveConfig()
  const {
    control,
    formState: { isDirty },
  } = useForm<{
    displayChannelOne: AlertChannel
    displayChannelTwo: AlertChannel
    soundChannel: AlertChannel
    displayChannelAsset: AlertChannel
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: activeConfig
      ? {
          displayChannelOne: activeConfig.channels[0],
          displayChannelTwo:
            activeConfig.alertType?.id === AlertTypes.StaffDuress
              ? activeConfig.channels[1]
              : undefined,
          soundChannel:
            activeConfig.alertType?.id === AlertTypes.StaffDuress
              ? activeConfig.channels[2]
              : activeConfig.channels[1],
          displayChannelAsset:
            activeConfig.alertType?.id === AlertTypes.AssetAlert
              ? activeConfig.channels[0]
              : undefined,
        }
      : {
          displayChannelOne: undefined,
          displayChannelTwo: undefined,
          soundChannel: undefined,
          displayChannelAsset: undefined,
        },
  })
  const activeConfigLoaded = useAlertConfigPageReload(
    activeConfig,
    setActiveConfig
  )
  const dispatch = useDispatch()
  const [initialLoad, setInitialLoad] = useState<boolean>(false)
  const [alertSound, setAlertSound] = useState<string>('')
  const [headerTitle, setHeaderTitle] = useState<string>('')

  const routerPromptState = useSelector(
    ({ routerPrompt }: { routerPrompt: RouterPromptState }) => routerPrompt
  )

  const classes = useFormStyle()

  const handleNext = () => {
    const path = `${location?.pathname.slice(
      0,
      location?.pathname.lastIndexOf('/')
    )}`
    history(
      `${path}/${AlertConfigSteps[AlertConfigSteps.ROUTING].toLowerCase()}`
    )
  }

  const handlePrevious = () => {
    const path = `${location.pathname.slice(
      0,
      location.pathname.lastIndexOf('/')
    )}`
    history(
      `${path}/${AlertConfigSteps[AlertConfigSteps.CONDITIONS].toLowerCase()}`
    )
  }

  useEffect(() => {
    if (!routerPromptState.isEnabled && isDirty) {
      dispatch(setRouterPromptIsEnabledStatusAction(isDirty))
    }
  }, [isDirty])

  useEffect(() => {
    if (activeConfig.alertType?.id === AlertTypes.AssetAlert) {
      setHeaderTitle('Display method')
    } else {
      setHeaderTitle('Channels')
    }
  }, [activeConfig])

  useEffect(() => {
    const soundConfig = activeConfig.channels.find(
      (x) => x.channelTypeId === ChannelTypeEnum.Sound
    )?.channelSoundId

    if (soundConfig && channelSounds && channelSounds[soundConfig]) {
      setAlertSound(channelSounds[soundConfig]?.cdnPath)
    } else {
      setAlertSound('')
    }
  }, [channelSounds, activeConfig, channelTypes])

  useEffect(() => {
    let channels: AlertChannel[] = []
    if (
      activeConfig &&
      !initialLoad &&
      channelTypesStatus === FetchingStatus.Success &&
      channelSoundsStatus === FetchingStatus.Success
    ) {
      // If no conditions already exist, create based on alert type
      if (activeConfig.channels.length === 0) {
        const sound: AlertChannel = {
          channelTypeId: channelTypes[3].id,
        }
        if (activeConfig.alertType?.id === AlertTypes.StaffDuress) {
          const duressBanner: AlertChannel = {
            channelTypeId: channelTypes[0].id,
          }
          const duressModal: AlertChannel = {
            channelTypeId: channelTypes[1].id,
          }
          channels.push(sound)
          channels.push(duressBanner)
          channels.push(duressModal)
        } else if (activeConfig.alertType?.id === AlertTypes.StaffAssist) {
          const assistModal: AlertChannel = {
            channelTypeId: channelTypes[2].id,
          }
          channels.push(sound)
          channels.push(assistModal)
        } else if (activeConfig.alertType?.id === AlertTypes.AssetAlert) {
          const assetNotification: AlertChannel = {
            channelTypeId: channelTypes[4].id,
          }
          channels.push(assetNotification)
        }
      } else {
        channels = channels.concat(activeConfig.channels)
      }

      setActiveConfig({
        ...activeConfig,
        channels: channels,
      })
      setInitialLoad(true)
    }
  }, [activeConfig, channelTypes, channelTypesStatus])

  const onSubmit = (): void => {
    if (activeConfig) {
      handleNext()
    }
  }

  return (
    <PageContent
      maxHeight={maxHeight}
      openRightDrawer={true}
      style={{ maxHeight: '100%' }}
      hideScrollbar={true}
      content={
        <>
          <AlertConfigStepperHeader activeStep={AlertConfigSteps.CHANNELS} />
          <div
            style={{
              overflow: 'auto',
              height: '100%',
              maxHeight: `calc(100% - ${AlertConfigStepperHeaderHeight})`,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                position: 'absolute',
                width: '100%',
                marginTop: '12px',
                paddingRight: '12px',
              }}
            >
              <CancelButton onClick={handlePrevious}>Previous</CancelButton>

              <PrimaryButton onClick={onSubmit}>Next</PrimaryButton>
            </div>
            <form>
              <div
                style={{
                  margin: '22px 0px 10px 24px',
                }}
              >
                <Typography variant='h5'>
                  Alert Builder: {headerTitle}
                </Typography>
              </div>
              <div
                style={{
                  width: formWidth,
                  margin: '0 24px',
                }}
              >
                <Typography
                  variant='h6'
                  style={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                  }}
                >
                  {headerTitle}
                </Typography>
                {activeConfigLoaded &&
                  activeConfig &&
                  activeConfig.channels.length > 0 &&
                  activeConfig.alertType?.id === AlertTypes.StaffDuress && (
                    <div>
                      <Controller
                        control={control}
                        name='displayChannelOne'
                        rules={{ required: true }}
                        render={({ field: { ref, ...field } }) => {
                          return (
                            <Autocomplete
                              {...field}
                              key={'channel-index-0'}
                              style={{ width: '100%' }}
                              value={Object.values(channelTypes).find(
                                (x) =>
                                  x.id == activeConfig.channels[0].channelTypeId
                              )}
                              options={Object.values(channelTypes)}
                              getOptionLabel={(option): string =>
                                option.name ?? ''
                              }
                              isOptionEqualToValue={(option, value): boolean =>
                                option.id === value?.id
                              }
                              onChange={(event, newValue) => {
                                const newChannels = activeConfig.channels
                                newChannels[0].channelTypeId = newValue?.id

                                setActiveConfig({
                                  ...activeConfig,
                                  channels: newChannels,
                                })

                                return newValue
                              }}
                              selectOnFocus
                              disabled
                              clearOnBlur
                              handleHomeEndKeys
                              renderInput={(params) => (
                                <TextField
                                  {...field}
                                  {...params}
                                  margin='normal'
                                  label='Channel'
                                  variant='filled'
                                  error={false}
                                  required
                                  className={classes.inputStyle}
                                  helperText={requiredMessage}
                                  inputRef={ref}
                                />
                              )}
                            />
                          )
                        }}
                      />
                      <Controller
                        control={control}
                        name='displayChannelTwo'
                        rules={{ required: true }}
                        render={({ field: { ref, ...field } }) => {
                          return (
                            <Autocomplete
                              {...field}
                              key={'channel-index-1'}
                              style={{ width: '100%' }}
                              value={Object.values(channelTypes).find(
                                (x) =>
                                  x.id == activeConfig.channels[1].channelTypeId
                              )}
                              options={Object.values(channelTypes)}
                              getOptionLabel={(option): string =>
                                option.name ?? ''
                              }
                              isOptionEqualToValue={(option, value): boolean =>
                                option.id === value?.id
                              }
                              selectOnFocus
                              clearOnBlur
                              handleHomeEndKeys
                              disabled
                              onChange={(event, newValue) => {
                                const newChannels = activeConfig.channels
                                newChannels[1].channelTypeId = newValue?.id

                                setActiveConfig({
                                  ...activeConfig,
                                  channels: newChannels,
                                })

                                return newValue
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...field}
                                  {...params}
                                  margin='normal'
                                  label='Channel'
                                  variant='filled'
                                  error={false}
                                  required
                                  className={classes.inputStyle}
                                  helperText={requiredMessage}
                                  inputRef={ref}
                                />
                              )}
                            />
                          )
                        }}
                      />
                    </div>
                  )}
                {activeConfigLoaded &&
                  activeConfig &&
                  activeConfig.channels.length > 0 &&
                  activeConfig.alertType?.id === AlertTypes.StaffAssist && (
                    <Controller
                      control={control}
                      name='displayChannelOne'
                      rules={{ required: true }}
                      render={({ field: { ref, ...field } }) => {
                        return (
                          <Autocomplete
                            {...field}
                            key={'channel-index-0'}
                            style={{ width: '100%' }}
                            value={Object.values(channelTypes).find(
                              (x) =>
                                x.id == activeConfig.channels[0]?.channelTypeId
                            )}
                            options={Object.values(channelTypes)}
                            getOptionLabel={(option): string =>
                              option.name ?? ''
                            }
                            isOptionEqualToValue={(option, value): boolean =>
                              option.id === value?.id
                            }
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            disabled
                            onChange={(event, newValue) => {
                              const newChannels = activeConfig.channels
                              newChannels[0].channelTypeId = newValue?.id

                              setActiveConfig({
                                ...activeConfig,
                                channels: newChannels,
                              })

                              return newValue
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...field}
                                {...params}
                                margin='normal'
                                label='Channel'
                                variant='filled'
                                error={false}
                                required
                                className={classes.inputStyle}
                                helperText={requiredMessage}
                                inputRef={ref}
                              />
                            )}
                          />
                        )
                      }}
                    />
                  )}
                {activeConfigLoaded &&
                  activeConfig &&
                  activeConfig.channels.length > 0 &&
                  activeConfig.alertType?.id !== AlertTypes.AssetAlert && (
                    <div style={{ width: '100%' }}>
                      <Controller
                        control={control}
                        name='soundChannel'
                        rules={{ required: true }}
                        render={({ field: { ref, ...field } }) => {
                          return (
                            <Autocomplete
                              {...field}
                              key={'channel-index-3'}
                              style={{
                                width: alertSound !== '' ? '90%' : '100%',
                                float: 'left',
                              }}
                              options={[
                                noSoundChannel,
                                ...Object.values(channelSounds),
                              ]}
                              getOptionLabel={(option): string =>
                                option.name ?? ''
                              }
                              isOptionEqualToValue={(option, value): boolean =>
                                option.id === value?.id
                              }
                              value={
                                Object.values(channelSounds).find(
                                  (s) =>
                                    s.id ===
                                    activeConfig.channels.find(
                                      (x) =>
                                        x.channelTypeId ===
                                        ChannelTypeEnum.Sound
                                    )?.channelSoundId
                                ) ?? noSoundChannel
                              }
                              onChange={(event, newValue) => {
                                const newChannels = activeConfig.channels
                                const existing = newChannels.find(
                                  (x) =>
                                    x.channelTypeId === ChannelTypeEnum.Sound
                                )

                                if (!newValue?.id || newValue.id === '-1') {
                                  if (existing) {
                                    setActiveConfig({
                                      ...activeConfig,
                                      channels: newChannels.filter(
                                        (x) =>
                                          x.channelTypeId !==
                                          ChannelTypeEnum.Sound
                                      ),
                                    })
                                  }

                                  return noSoundChannel
                                }

                                if (newValue?.id && existing) {
                                  existing.channelSoundId = newValue.id
                                } else {
                                  newChannels.push({
                                    channelTypeId: ChannelTypeEnum.Sound,
                                    channelSoundId: newValue.id,
                                    alertId: activeConfig.id,
                                  })
                                }

                                setActiveConfig({
                                  ...activeConfig,
                                  channels: newChannels,
                                })

                                return newValue
                              }}
                              selectOnFocus
                              clearOnBlur
                              handleHomeEndKeys
                              renderInput={(params) => (
                                <TextField
                                  {...field}
                                  {...params}
                                  margin='normal'
                                  label='Sound'
                                  variant='filled'
                                  error={false}
                                  className={classes.inputStyle}
                                  inputRef={ref}
                                />
                              )}
                            />
                          )
                        }}
                      />
                      {alertSound !== '' && (
                        <div style={{ float: 'right', marginTop: '20px' }}>
                          <AudioPlayButton cdnPath={alertSound} />
                        </div>
                      )}
                    </div>
                  )}
                {activeConfigLoaded &&
                  activeConfig &&
                  activeConfig.channels.length > 0 &&
                  activeConfig.alertType?.id === AlertTypes.AssetAlert && (
                    <div>
                      <Controller
                        control={control}
                        name='displayChannelAsset'
                        rules={{ required: true }}
                        render={({ field: { ref, ...field } }) => {
                          return (
                            <Autocomplete
                              {...field}
                              key={'channel-index-4'}
                              style={{ width: '100%' }}
                              value={Object.values(channelTypes).find(
                                (x) =>
                                  x.id ===
                                  activeConfig.channels[0].channelTypeId
                              )}
                              options={Object.values(channelTypes)}
                              getOptionLabel={(option): string =>
                                option.name ?? ''
                              }
                              isOptionEqualToValue={(option, value): boolean =>
                                option.id === value?.id
                              }
                              selectOnFocus
                              clearOnBlur
                              handleHomeEndKeys
                              disabled
                              onChange={(event, newValue) => {
                                const newChannels = activeConfig.channels
                                newChannels[0].channelTypeId = newValue?.id

                                setActiveConfig({
                                  ...activeConfig,
                                  channels: newChannels,
                                })

                                return newValue
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...field}
                                  {...params}
                                  margin='normal'
                                  label='Type'
                                  variant='filled'
                                  error={false}
                                  required
                                  className={classes.inputStyle}
                                  helperText={requiredMessage}
                                  inputRef={ref}
                                />
                              )}
                            />
                          )
                        }}
                      />
                    </div>
                  )}
                {!activeConfigLoaded && <FormSkeleton width={formWidth} />}
              </div>
            </form>
          </div>
          <RouterPrompt />
        </>
      }
      rightDrawer={
        <AlertConfigRightDrawer
          activeConfig={activeConfig}
          activeConfigLoaded={activeConfigLoaded}
          canEdit={false}
        />
      }
    />
  )
}

export default AlertConfigChannelPage
