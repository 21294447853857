import React, { useEffect, useState } from 'react'
import { Checkbox, ListItemText, MenuItem, Typography } from '@mui/material'
import { Select } from '../ActionBar'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { AgentTypePlural } from '../../models'
import { useFetchFeatureFlags } from '../../hooks/entities/useFetchFeatureFlags'
import { FeatureFlagLabels } from '../../constants'

interface Props {
  currentView: string[]
  onSelectChange: (value?: string[]) => void
}

const RealtimeSelect: React.FC<Props> = (props: Props) => {
  const { onSelectChange: handleChange, currentView } = props

  const [selectedValue, setSelectedValue] = useState([
    AgentTypePlural[AgentTypePlural.Assets],
  ])

  const { data: FeatureFlags } = useFetchFeatureFlags(
    FeatureFlagLabels.toString()
  )

  const onChange = (event: any) => {
    const {
      target: { value },
    } = event
    if (value?.length) {
      const temp: string[] = []
      if (value.includes(AgentTypePlural[AgentTypePlural.Assets])) {
        temp.push(AgentTypePlural[AgentTypePlural.Assets])
      }
      if (value.includes(AgentTypePlural[AgentTypePlural.Staff])) {
        temp.push(AgentTypePlural[AgentTypePlural.Staff])
      }
      setSelectedValue(temp)
      handleChange(temp)
    }
  }

  const types = [
    AgentTypePlural[AgentTypePlural.Assets],
    AgentTypePlural[AgentTypePlural.Staff],
  ]

  useEffect(() => {
    if (currentView && selectedValue !== currentView) {
      setSelectedValue(currentView)
    }
  }, [currentView, selectedValue])

  const realTimeSelectionHide =
    Object.values(FeatureFlags).filter(
      (x) => x.name.includes('/realtimeselection') == true && x.enabled === true
    ).length > 0

  return (
    <>
      {realTimeSelectionHide === true ? (
        <Select
          margin='dense'
          value={selectedValue}
          onChange={onChange}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          }}
          multiple
          style={{ minWidth: '204px' }}
          renderValue={(selected: any) => selected.join(', ')}
        >
          {types.map((type) => (
            <MenuItem
              key={type}
              value={type}
              style={{
                height: '36px',
                margin: '1px 0 0 0',
                padding: '8px 37px 4px 10px',
              }}
            >
              <Checkbox
                color='primary'
                icon={<CheckBoxOutlineBlankIcon color='primary' />}
                checked={selectedValue.includes(type)}
                style={{ width: '19px', height: '19px', margin: '0 8px 0 0' }}
              />
              <ListItemText>
                <Typography
                  style={{
                    fontSize: '16px',
                    fontWeight: 500,
                  }}
                >
                  {type}
                </Typography>
              </ListItemText>
            </MenuItem>
          ))}
        </Select>
      ) : (
        <></>
      )}
    </>
  )
}

export default RealtimeSelect
