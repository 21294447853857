import { AuthConfig } from '@midmarkrtls/common/models'

const host = window.location.origin
const instance = process.env.REACT_APP_B2C_INSTANCE || ''
const tenant = process.env.REACT_APP_B2C_TENANT || ''
const policy = process.env.REACT_APP_B2C_POLICY || ''
const appId = process.env.REACT_APP_B2C_APPID || ''
const apiScopes =
  process.env.REACT_APP_B2C_OPERATION_API_SCOPES?.split(',') || ''
const redirectUri = process.env.REACT_APP_SITE_URL || host

const msalConfig = {
  auth: {
    clientId: appId,
    authority: `${instance}${tenant}/${policy}`,
    knownAuthorities: [instance],
    validateAuthority: false,
    redirectUri: redirectUri,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
}

const loginRequest = {
  scopes: [...(apiScopes || [])],
}

const config: AuthConfig = { msalConfig, loginRequest }

export default config
