import { Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { PageContent } from '../../../components/Layout'
import { DrawerType } from '../../../components/Layout/PageContent'
import LoadingIndicator from '../../../components/LoadingIndicator/LoadingIndicator'
import { CampusBuildingEditForm } from '../../../components/LocationManagement/CampusBuildingEditForm'
import { LocationList } from '../../../components/LocationManagement/LocationList'
import LocationDetailDrawer from '../../../components/Maps/LocationManagementMap/LocationDetailDrawer'
import { LocationManagementMap } from '../../../components/Maps/LocationManagementMap/LocationManagementMap'
import { getVenueIdFromLocation } from '../../../helpers'
import {
  useAuth,
  useFetchSubUnits,
  useFetchUnits,
  useFetchVenues,
} from '../../../hooks'
import { useFetchLocations } from '../../../hooks/entities/useFetchLocations'
import { useFetchMapPresets } from '../../../hooks/entities/useFetchMapPresets'
import { useCurrentIDN } from '../../../hooks/useCurrentIDN'
import { usePermissionCheck } from '../../../hooks/usePermissionCheck'
import {
  FetchingStatus,
  Location,
  LocationType,
  PermissionsEnum,
} from '../../../models'
import { PageLayoutState } from '../../../models/pageLayout'
import { useLocationManagementMapRightDrawer } from './useLocationManagementMapRightDrawer'

const drawerOpenedWidth = 310

const LocationManagementMapPage: React.FC = () => {
  // Use State Props
  const [selectedListLocationId, setSelectedListLocationId] = useState('')
  const { currentCustomerId } = useAuth()
  const [currentLocation, setCurrentLocation] = useState<Location | undefined>()
  const { data: venues, status: venuesFetchingStatus } = useFetchVenues(
    !!currentCustomerId
  )
  const [currentVenueId, setCurrentVenueId] = useState<number | undefined>()
  const [currentFloor, setCurrentFloor] = useState<Location | undefined>()
  const [openRoomEditModal, setOpenRoomEditModal] = useState(false)

  const maxHeight = useSelector(
    ({ pageLayout }: { pageLayout: PageLayoutState }) => pageLayout.maxHeight
  )

  // Data Hooks
  const { data: locations } = useFetchLocations()
  const { data: units } = useFetchUnits()
  const { data: subUnits } = useFetchSubUnits()

  //const currentFloor = useCurrentFloor()
  const currentIDN = useCurrentIDN()
  const hasWritePermission = usePermissionCheck([
    PermissionsEnum.LocationsWrite,
  ])
  const { data: mapPresets } = useFetchMapPresets()
  const {
    rightDrawerState,
    selectRoom,
    closeDetailDrawer,
    updateSelectedRoom,
  } = useLocationManagementMapRightDrawer()
  const { currentDrawerType, selectedRoom } = rightDrawerState

  useEffect(() => {
    if (venuesFetchingStatus === FetchingStatus.Success && currentLocation) {
      setCurrentVenueId(getVenueIdFromLocation(currentLocation, venues))
    }
  }, [currentLocation, currentCustomerId, venues, venuesFetchingStatus])

  // Update currentFloor state after saving Floor Edit
  useEffect(() => {
    if (currentFloor) {
      setCurrentFloor(locations[currentFloor.id])
    }
  }, [locations])

  // Use Styles
  const useStyles = makeStyles(() =>
    createStyles({
      locationContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
      },
      locationListWindow: {
        flexBasis: 'auto',
        position: 'relative',
        width: drawerOpenedWidth,
        backgroundColor: '#f5f5f5',
        height: '100%',
        zIndex: 1002,
      },
      locationEditWindow: {
        flexGrow: 1,
        position: 'relative',
        display: 'flex',
        justifyContent: !!selectedListLocationId ? 'flex-start' : 'center',
        alignItems: !!selectedListLocationId ? 'flex-start' : 'center',
        backgroundColor: '#f5f5f5',
        minWidth: 400,
      },
      locationEdit: {
        padding: '48px',
      },
      locationSelectMessage: {
        fontSize: 24,
        textAlign: 'center',
        marginTop: 20,
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 20,
      },
    })
  )

  const classes = useStyles()

  // Functions
  const listLocationSelected = (locationId: string): void => {
    setSelectedListLocationId(locationId)
    setCurrentLocation(locations[locationId])
    if (locations[locationId].locationType == LocationType.Floor) {
      setCurrentFloor(locations[locationId])
    } else {
      setCurrentFloor(undefined)
    }
  }

  useEffect(() => {
    if (!!selectedRoom) {
      const updatedRoom = locations[selectedRoom.id]
      updateSelectedRoom(updatedRoom)
    }
  }, [locations, selectedRoom, updateSelectedRoom])

  return (
    <PageContent
      maxHeight={maxHeight}
      currentRightDrawerType={currentDrawerType}
      content={
        <div className={classes.locationContainer}>
          <div className={classes.locationListWindow}>
            {locations && Object.keys(locations).length > 0 ? (
              <LocationList
                locations={locations}
                locationSelected={listLocationSelected}
                selectedLocationId={selectedListLocationId}
              />
            ) : (
              <LoadingIndicator />
            )}
          </div>
          <div className={classes.locationEditWindow}>
            {locations && Object.keys(locations).length > 0 ? (
              selectedListLocationId in locations ? (
                currentVenueId && currentFloor && currentIDN ? (
                  <LocationManagementMap
                    data-testid='locationManagementMap'
                    canEdit={hasWritePermission}
                    venueId={currentVenueId}
                    selectedFloor={currentFloor}
                    mapPresets={mapPresets}
                    locations={locations}
                    rightDrawerOptions={{
                      closeDetailDrawer,
                      selectRoom,
                      rightDrawerType: currentDrawerType,
                    }}
                    openRoomEditModal={openRoomEditModal}
                    setOpenRoomEditModal={setOpenRoomEditModal}
                    units={units}
                  />
                ) : (
                  <div className={classes.locationEdit}>
                    <CampusBuildingEditForm
                      canEdit={hasWritePermission}
                      selectedLocation={locations[selectedListLocationId]}
                    />
                  </div>
                )
              ) : (
                <Typography className={classes.locationSelectMessage}>
                  Please Select a Location from the Location Administration List
                  to the Left
                </Typography>
              )
            ) : (
              <LoadingIndicator />
            )}
          </div>
        </div>
      }
      rightDrawer={
        currentDrawerType === DrawerType.DetailDrawer &&
        selectedRoom && (
          <LocationDetailDrawer
            selectedRoom={selectedRoom}
            onClose={closeDetailDrawer}
            onEditRoom={() => setOpenRoomEditModal(true)}
            onViewRoomSensors={() => {
              console.log('Not implemented yet')
            }}
            canEdit={hasWritePermission}
            units={units}
            subUnits={subUnits}
            locations={locations}
          />
        )
      }
    />
  )
}

export { LocationManagementMapPage as LocationManagementView }
