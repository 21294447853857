import { BaseService } from './baseService'
import { Alert, AlertCreateOrUpdate } from '../models/alert'

export class AlertsApi extends BaseService {
  constructor() {
    super({ entityType: 'alerts' })
  }
  route = 'alert'

  async getAllAlerts(): Promise<Alert[]> {
    return await this.get<Alert[]>(this.route)
  }
  async getAlert(id: string): Promise<Alert> {
    return await this.get<Alert>(`${this.route}/${id}`)
  }
  async postAlert(alert: AlertCreateOrUpdate): Promise<Alert> {
    return await this.post<Alert>(this.route, alert)
  }
  async putAlert(alert: AlertCreateOrUpdate): Promise<Alert> {
    return await this.put<Alert>(`${this.route}/${alert.id}`, alert)
  }
  async deleteAlert(id: string): Promise<string> {
    return await this.delete(`${this.route}/${id}`)
  }
}
