import { AssetFilterRequest } from '../../models'
import { AssetFilters } from '../../models/filters'

export const assetFilterMap = (
  filterRequest?: AssetFilters
): AssetFilterRequest => {
  const filter: AssetFilterRequest = {
    assetTypes: filterRequest?.assetTypes?.map((x) => x.assetTypeId) ?? [],
    assetSubTypes:
      filterRequest?.assetSubTypes?.map((x) => x.assetTypeId) ?? [],
    hasRecall: filterRequest?.hasRecall ?? false,
    hasCurrentMonthPMs: filterRequest?.isPmThisMonth ?? false,
    manufacturers:
      filterRequest?.manufacturers?.map((x) => x.manufacturerGuid) ?? [],
    models: filterRequest?.models?.map((x) => x.name.toString()) ?? [],
    owners: filterRequest?.owners?.map((x) => x.name.toString()) ?? [],
    nextPMDate: filterRequest?.preventativeMaintenanceDate
      ? new Date(filterRequest?.preventativeMaintenanceDate).toDateString()
      : '',
    assetAssignedTo:
      filterRequest?.assignedTo?.map((x) => x.id?.toString() ?? '') ?? [],
    cmmsManaged: filterRequest?.cmmsManaged ?? false,
  }

  return filter
}
