import { getType } from 'typesafe-actions'
import { LocationGeofencesAction } from '../actions'
import { FetchingStatus } from '../models'
import { mergeRecords, setFetchingStatus } from '../utils'
import {
  LocationGeofence,
  LocationGeofences,
  LocationGeofencesState,
} from '../models/locationGeofence'
import {
  getAllGeofencesByLocation,
  postAssociateGeofencesAction,
} from '../actions/locationGeofences'

const initialState: LocationGeofencesState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

export default (
  state = initialState,
  action: LocationGeofencesAction
): LocationGeofencesState => {
  switch (action.type) {
    case getType(postAssociateGeofencesAction.request):
    case getType(getAllGeofencesByLocation.request):
      return setFetchingStatus<LocationGeofences>(state, FetchingStatus.Request)

    case getType(postAssociateGeofencesAction.failure):
    case getType(getAllGeofencesByLocation.failure):
      return setFetchingStatus<LocationGeofences>(state, FetchingStatus.Failure)

    case getType(postAssociateGeofencesAction.success):
    case getType(getAllGeofencesByLocation.success):
      return {
        data: mergeRecords<LocationGeofences, LocationGeofence, 'geofenceId'>(
          state.data,
          action.payload,
          'geofenceId'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }

    default:
      return state
  }
}
