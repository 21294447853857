import { getType } from 'typesafe-actions'
import { PageLayoutAction } from '../actions'
import {
  setPageLayoutMaxHeight,
  setRightDrawerOpen,
} from '../actions/pageLayout'
import { headerMaxHeight } from '../components/Common'
import { PageLayoutState } from '../models/pageLayout'

const initialState: PageLayoutState = {
  maxHeight: window.innerHeight - headerMaxHeight,
  rightDrawerOpen: false,
}
const pageLayoutReducer = (
  state: PageLayoutState = initialState,
  action: PageLayoutAction
): PageLayoutState => {
  switch (action.type) {
    case getType(setPageLayoutMaxHeight):
      return {
        ...state,
        maxHeight: action.payload,
      }
    case getType(setRightDrawerOpen):
      return {
        ...state,
        rightDrawerOpen: action.payload,
      }
    default:
      return state
  }
}

export default pageLayoutReducer
