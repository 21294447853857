import { Epic } from 'redux-observable'
import { CurrentUserLocationAction } from '../actions'
import { CurrentUserLocationApi } from '../middleware/currentUserLocation'
import { CurrentUserLocationState } from '../models/currentUserLocation'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { getCurrentUserLocationAction } from '../actions/currentUserLocation'
import { HttpErrorResponse } from '../services/http'

type CurrentUserLocationEpic = Epic<
  CurrentUserLocationAction,
  CurrentUserLocationAction,
  CurrentUserLocationState,
  { currentUserLocationApi: CurrentUserLocationApi }
>

export const getCurrentUserLocationEpic: CurrentUserLocationEpic = (
  action$,
  state$,
  { currentUserLocationApi }
) => {
  return action$.pipe(
    filter(isActionOf(getCurrentUserLocationAction.request)),
    mergeMap((action) =>
      from(currentUserLocationApi.getCurrentUserLocation(action.payload)).pipe(
        map((currentUserLocation) =>
          getCurrentUserLocationAction.success(currentUserLocation)
        ),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 404) {
            return of(getCurrentUserLocationAction.success(null))
          } else {
            return of(getCurrentUserLocationAction.failure(error))
          }
        })
      )
    )
  )
}
