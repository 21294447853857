import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetRealTimeMapControlStateAction,
  setJibestreamControlAction,
  setRectangleAction,
  setShouldShowRealTimeMapAction,
} from '../../../actions/realTimeMapControlAction'
import {
  setTelemetryCurrentMapIdAction,
  setTelemetryEnabledAction,
} from '../../../actions/telemetrySubscription'
import {
  getGeofenceIdFromInpixonAssetType,
  getTrackingIdFromInpixonAssetType,
  inpixonAssetIsOfType,
} from '../../../helpers/inpixon/assetType'
import { findAgentInCurrentMapRender } from '../../../helpers/realtimeMap'
import { useMapReferenceViews, useTelemetrySubscription } from '../../../hooks'
import { useInpixonContext } from '../../../hooks/inpixon'
import { useMapResize } from '../../../hooks/inpixon/useMapResize'
import { useRealTimeMapUpdates } from '../../../hooks/inpixonMapUpdates'
import { useRTMapTelemetry } from '../../../hooks/realtimeMapTelemetry'
import {
  Asset,
  AssetInstance,
  Devices,
  FetchingStatus,
  Geofences,
  Location,
  MapPresets,
  Staff,
  domRectToRectangle,
} from '../../../models'
import {
  AssetFiltersProvider,
  StaffFiltersProvider,
} from '../../../models/filters'
import { SelectAgentOnMapHandlers } from '../../../models/realtimeMap'
import { RightDrawerState } from '../../../pages/RealTime/Map/useRealTimeMapPageRightDrawer'
import useRealTimeMapRenderContext from '../../../pages/RealTime/Map/useRealTimeMapRenderContext'
import {
  selectStaffAssistState,
  selectStaffDuressState,
  selectStaffEventFetchingStatus,
} from '../../../selectors'
import { InpixonMap } from '../../Inpixon/InpixonMap'
import LoadingIndicator from '../../LoadingIndicator'
import useBrowserVisibilityChange from '../../../hooks/useBrowserVisibilityChange'

export interface SensoryNetworkMapProps extends SelectAgentOnMapHandlers {
  venueId: number
  assetsByLocation: Asset[]
  isAssetsByLocationFetched: boolean
  assetFilterProvider: AssetFiltersProvider
  devices: Devices
  mapPresets: MapPresets
  geofences: Geofences
  geofencesFetchingStatus: FetchingStatus | undefined
  staffByLocaton: Staff[]
  isStaffsByLocationFetched: boolean
  staffFilterProvider: StaffFiltersProvider
  agentTypes: string[]
  assetStatus: FetchingStatus | undefined
  staffStatus: FetchingStatus | undefined
  initialized: boolean
  currentFloor: Location | null
  rightDrawerState: RightDrawerState
}

const SensoryNetworkMap: React.FC<SensoryNetworkMapProps> = (
  props: SensoryNetworkMapProps
) => {
  const {
    venueId,
    assetsByLocation,
    isAssetsByLocationFetched,
    assetFilterProvider,
    mapPresets,
    geofences,
    geofencesFetchingStatus,
    staffByLocaton,
    isStaffsByLocationFetched,
    staffFilterProvider,
    agentTypes,
    assetStatus,
    staffStatus,
    initialized,
    currentFloor,
    rightDrawerState,
    onSelectAgent,
    onSelectGroupOnMap,
    onSelectAgentTypes,
    updateSelectedAgentTelemetry,
    moveSelectedAgentOutOfGroup,
    removeAgentFromSelectedGroup,
    addAgentToSelectedGroup,
    updateAgentInSelectedGroup,
    removeSelectedAgent,
    selectAgentInGroup,
    resetRightDrawerState,
  } = props
  const { currentDrawerType, selectedAgent, selectedGroup } = rightDrawerState
  const selectedAgentTrackingId = selectedAgent?.telemetry.trackingId
  const selectedGroupId = selectedGroup?.groupTelemetries.geoFenceId

  const [mapIsLoaded, setMapIsLoaded] = useState(false)
  const { data: mapReferenceViews } = useMapReferenceViews(venueId)
  const { inpixonState } = useInpixonContext()
  const { mapRenderState } = useRealTimeMapRenderContext()
  const { jibestream } = inpixonState
  const dispatch = useDispatch()

  const { mapId } = useTelemetrySubscription()
  const setMapId = (mapId: number) => {
    dispatch(setTelemetryCurrentMapIdAction(mapId))
  }
  useMapResize(jibestream?.control)

  const staffEventFetchingStatus = useSelector(selectStaffEventFetchingStatus)
  const { allDuressFilteredByUser, filteredDuressLoaded } = useSelector(
    selectStaffDuressState
  )
  const { allAssistsFilteredByUser } = useSelector(selectStaffAssistState)

  useEffect(() => {
    if (mapIsLoaded) {
      dispatch(setTelemetryEnabledAction(true))
    }
  }, [mapIsLoaded, dispatch])

  useEffect(() => {
    if (jibestream && jibestream.control) {
      dispatch(setJibestreamControlAction(jibestream.control))
    }
  }, [jibestream, dispatch])

  const assetData = useMemo(
    () => ({
      fetchedAssetsByLocation: assetsByLocation,
      assetStatus,
      isAssetsByLocationFetched,
      assetFilterCriteria: assetFilterProvider.filter.criteria,
    }),
    [
      assetStatus,
      assetsByLocation,
      isAssetsByLocationFetched,
      assetFilterProvider,
    ]
  )

  const staffData = useMemo(
    () => ({
      fetchedStaffsByLocation: staffByLocaton,
      staffStatus,
      isStaffsByLocationFetched,
      staffFilterCriteria: staffFilterProvider.filter.criteria,
      allDuressEvents: allDuressFilteredByUser,
      allAssistEvents: allAssistsFilteredByUser,
      staffEventFetchingStatus,
    }),
    [
      allAssistsFilteredByUser,
      allDuressFilteredByUser,
      staffByLocaton,
      isStaffsByLocationFetched,
      staffFilterProvider.filter.criteria,
      staffStatus,
      staffEventFetchingStatus,
    ]
  )

  const geofenceData = useMemo(
    () => ({
      geofences,
      geofencesFetchingStatus,
    }),
    [geofences, geofencesFetchingStatus]
  )

  const handleSetMapIsLoaded = useCallback(
    (isLoaded: boolean) => {
      setMapIsLoaded(isLoaded)
    },
    [setMapIsLoaded]
  )

  useEffect(() => {
    if (currentFloor?.id) {
      const { mapId: mapReferenceMapId } =
        Object.values(mapReferenceViews).find(
          (x) => x.floorLocationId === currentFloor.id
        ) || {}

      // Check if map changed
      if (mapReferenceMapId && mapReferenceMapId !== mapId) {
        setMapId(mapReferenceMapId)
      }
    }
  }, [currentFloor, mapId, mapReferenceViews, setMapId])

  const {
    initialMapRender,
    reloadedMapRender,
    liveMapTelemetriesToUpdate,
    newAgentsEnteringMapTelemetries,
  } = useRTMapTelemetry(
    assetData,
    staffData,
    geofenceData,
    agentTypes,
    filteredDuressLoaded,
    onSelectAgentTypes
  )

  useRealTimeMapUpdates(
    inpixonState,
    initialMapRender,
    reloadedMapRender,
    liveMapTelemetriesToUpdate,
    newAgentsEnteringMapTelemetries,
    mapId,
    agentTypes,
    selectedAgentTrackingId,
    selectedGroupId,
    geofenceData.geofences,
    onSelectAgent,
    selectAgentInGroup,
    updateSelectedAgentTelemetry,
    moveSelectedAgentOutOfGroup,
    removeAgentFromSelectedGroup,
    removeSelectedAgent,
    addAgentToSelectedGroup,
    updateAgentInSelectedGroup
  )

  const handleInpixonAssetClick = useCallback(
    (asset: AssetInstance) => {
      // handle unique click behavior per assetkit type
      if (inpixonAssetIsOfType(asset, 'Group')) {
        const geofenceId = getGeofenceIdFromInpixonAssetType(asset)
        if (geofenceId) {
          const mapRender = mapRenderState[geofenceId]
          if (mapRender) {
            onSelectGroupOnMap(asset, mapRender)
          } else {
            console.error('Group not found. Unable to select group!')
          }
        }
      } else {
        const trackingId = getTrackingIdFromInpixonAssetType(asset)
        if (trackingId) {
          const agentTelemetry = findAgentInCurrentMapRender(
            trackingId,
            mapRenderState
          )
          if (!agentTelemetry) {
            console.error('Agent not found. Unable to select agent!')
            return
          }
          onSelectAgent(asset, agentTelemetry)
        }
      }
    },
    [mapRenderState, onSelectGroupOnMap, onSelectAgent]
  )

  const mapPlaceholderRef = useRef<HTMLDivElement>(null)

  const { visible } = useBrowserVisibilityChange({ enabled: true })
  useEffect(() => {
    const rect = mapPlaceholderRef.current?.getBoundingClientRect()
    dispatch(setShouldShowRealTimeMapAction(true))
    if (rect) {
      dispatch(setRectangleAction(domRectToRectangle(rect)))
    }
  }, [
    JSON.stringify(mapPlaceholderRef?.current?.getBoundingClientRect()),
    visible,
    dispatch,
  ])

  // cleanup when component unmounts
  useEffect(() => {
    return (): void => {
      resetRightDrawerState()
      dispatch(resetRealTimeMapControlStateAction())
    }
  }, [])

  return initialized && filteredDuressLoaded && mapPlaceholderRef !== null ? (
    <div style={{ height: '100%', width: '100%' }}>
      <div style={{ height: '100%', width: '100%' }} ref={mapPlaceholderRef} />
      <InpixonMap
        venueId={venueId}
        mapPresets={mapPresets}
        mapId={mapId}
        mapIsLoaded={mapIsLoaded ?? false}
        handleAssetClickDelegate={handleInpixonAssetClick}
        onMapChanged={setMapId}
        setMapLoaded={handleSetMapIsLoaded}
        currentRightDrawerType={currentDrawerType}
      />
    </div>
  ) : (
    <LoadingIndicator fetching={initialized} />
  )
}

export { SensoryNetworkMap }
