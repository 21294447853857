import { getType } from 'typesafe-actions'
import { RoleAction } from '../actions'
import {
  deleteRoleAction,
  getRoleByIdAction,
  getRolesAction,
  getRolesByUserIdAction,
  postRoleAction,
  putRoleAction,
} from '../actions/roles'
import { getSortOrder } from '../helpers'
import { FetchingStatus } from '../models/fetchingStatus'
import {
  Role,
  SortedRole,
  SortedRoles,
  TenantUserRolesState,
} from '../models/roles'
import {
  deleteRecord,
  mergeRecord,
  mergeRecords,
  setFetchingStatus,
} from '../utils'

const initialState: TenantUserRolesState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

const key = 'id'
const roleReducer = (
  state: TenantUserRolesState = initialState,
  action: RoleAction
): TenantUserRolesState => {
  switch (action.type) {
    case getType(getRolesAction.success):
    case getType(getRolesByUserIdAction.success):
      const sortedRoles: SortedRole[] = action.payload.map((role) => ({
        ...role,
        sortOrder: getSortOrder(role.id),
      }))
      return {
        data: mergeRecords<SortedRoles, SortedRole, typeof key>(
          state.data,
          sortedRoles,
          key
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getRoleByIdAction.success):
    case getType(postRoleAction.success):
    case getType(putRoleAction.success):
      const sortedRole: SortedRole = {
        ...action.payload,
        sortOrder: getSortOrder(action.payload.id),
      }
      return {
        data: mergeRecord<SortedRoles, SortedRole, typeof key>(
          state.data,
          sortedRole,
          key
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(deleteRoleAction.success):
      return {
        data: deleteRecord<SortedRoles, Role>(state.data, action.payload),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }

    case getType(getRolesAction.request):
    case getType(getRolesByUserIdAction.request):
    case getType(getRoleByIdAction.request):
    case getType(postRoleAction.request):
    case getType(putRoleAction.request):
    case getType(deleteRoleAction.request):
      return setFetchingStatus<SortedRoles>(state, FetchingStatus.Request)
    case getType(getRolesAction.failure):
    case getType(getRolesByUserIdAction.failure):
    case getType(getRoleByIdAction.failure):
    case getType(postRoleAction.failure):
    case getType(putRoleAction.failure):
    case getType(deleteRoleAction.failure):
      return setFetchingStatus<SortedRoles>(state, FetchingStatus.Failure)

    default:
      return state
  }
}

export default roleReducer
