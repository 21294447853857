import React, {
  createContext,
  useReducer,
  useContext,
  useCallback,
} from 'react'

type Dispatch = (nextState: State | null) => void
type State = {
  left?: React.ReactNode
  right?: React.ReactNode
  middle?: React.ReactNode
}

const emptyState = {
  left: null,
  right: null,
  middle: null,
}

const SecondaryHeaderStateContext = createContext<State | undefined>(undefined)
const SecondaryHeaderDispatchContext = createContext<Dispatch | undefined>(
  undefined
)

interface ProviderProps {
  children: React.ReactNode
}

function secondaryHeaderReducer(
  secondaryHeader: State,
  nextState: State | null
): State {
  const next = nextState
    ? {
        ...secondaryHeader,
        ...nextState,
      }
    : emptyState

  return next
}

export function SecondaryHeaderProvider({ children }: ProviderProps) {
  const initialState = emptyState
  const [secondaryHeader, setSecondaryHeader] = useReducer(
    secondaryHeaderReducer,
    initialState
  )
  return (
    <SecondaryHeaderStateContext.Provider value={secondaryHeader}>
      <SecondaryHeaderDispatchContext.Provider value={setSecondaryHeader}>
        {children}
      </SecondaryHeaderDispatchContext.Provider>
    </SecondaryHeaderStateContext.Provider>
  )
}

function useSecondaryHeaderState() {
  const context = useContext(SecondaryHeaderStateContext)
  if (context === undefined) {
    throw new Error('fail')
  }
  return context
}
function useSecondaryHeaderDispatch() {
  const context = useContext(SecondaryHeaderDispatchContext)
  if (context === undefined) {
    throw new Error('fail')
  }
  return context
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function useSecondaryHeader() {
  const secondaryHeader = useSecondaryHeaderState()
  const setSecondaryHeader = useSecondaryHeaderDispatch()

  const clearSecondaryHeader = useCallback(
    () => setSecondaryHeader(null),
    [setSecondaryHeader]
  )

  return {
    secondaryHeader,
    setSecondaryHeader,
    clearSecondaryHeader,
  }
}
