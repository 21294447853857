import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { Fragment, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { filteredPageGroups } from '../../../helpers/permissions'
import { pathMatches } from '../../../helpers/routing'
import { PageGroup } from '../../../interfaces/modules'
import { PermissionsEnum } from '../../../models'
import { colors } from '../../../styles/MidmarkTheme'
import RouterLink from '../../RouterLink'
import GroupedNavLinks from './GroupedNavLinks'
import SideNavLink from './SideNavLink'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    row: {
      color: 'white',
      height: 56,
      '&:hover': {
        backgroundColor: colors.hoverBackground,
      },
    },
    pageGroup: {
      borderBottom: '1px solid ' + colors.background,
    },
    pageListItemText: {
      fontWeight: 'bold',
    },
    selectedRow: {
      backgroundColor: colors.indigo600,
    },
    subSelectedRow: {
      backgroundColor: colors.darkTeal,
    },
    iconCell: {
      color: 'white',
      minWidth: '56px',
      justifyContent: 'center',
    },
    opaqueIcon: {
      opacity: '0.54',
    },
    caretExpanded: {
      '&::after': {
        content: '""',
        border: '5px solid transparent',
        borderBottom: '5px solid white',
        marginRight: '25px',
        marginBottom: '5px',
        display: 'inline-block',
        opacity: '0.6',
      },
    },
    caretCollapsed: {
      '&::after': {
        content: '""',
        border: '5px solid transparent',
        borderTop: '5px solid white',
        marginRight: '25px',
        marginTop: '5px',
        display: 'inline-block',
        opacity: '0.6',
      },
    },
  })
)

interface Props {
  isCollapsed: boolean
  pageGroups: PageGroup[]
  permissions: PermissionsEnum[]
}

const SideNavLinks: React.FC<Props> = (props: Props) => {
  const { isCollapsed, pageGroups, permissions } = props

  const classes = useStyles()
  const { pathname } = useLocation()

  const permissionPageGroups = useMemo(
    () => filteredPageGroups(pageGroups, permissions),
    [pageGroups, permissions]
  )

  const [expandedGroups, setExpandedGroups] = useState(() => {
    const expandedGroup = permissionPageGroups.find((x) =>
      x.pages.find((page) =>
        page.associatedRoute
          ? pathMatches(page.associatedRoute, pathname)
          : null
      )
    )

    return expandedGroup ? [expandedGroup.key] : []
  })

  return (
    <div id='SideNavLinks' className={classes.root}>
      {permissionPageGroups.map((pageGroup) => {
        const handlePageSelect = () => {
          setExpandedGroups(pageGroup.key ? [pageGroup.key] : [])
        }

        const selectedChildPage = pageGroup.pages.find((page) =>
          page.associatedRoute
            ? pathMatches(page.associatedRoute, pathname)
            : null
        )?.key

        const handlePageGroupClick = () => {
          if (selectedChildPage) {
            return
          }

          if (!expandedGroups.find((x) => x === pageGroup.key)) {
            setExpandedGroups((prevVal) => [...prevVal, pageGroup.key])
          } else {
            setExpandedGroups((prevVal) => [
              ...prevVal.filter((key) => key !== pageGroup.key),
            ])
          }
        }

        return (
          <Fragment key={pageGroup.key}>
            {pageGroup.pages.length ? (
              <GroupedNavLinks
                name={pageGroup.name}
                icon={pageGroup.icon}
                pages={pageGroup.pages}
                classes={classes}
                isSideNavCollapsed={isCollapsed}
                isGroupedNavLinksExpanded={
                  !!expandedGroups.find((x) => x === pageGroup.key)
                }
                selectedChildPage={selectedChildPage}
                onSelectPage={handlePageSelect}
                onPageGroupClick={handlePageGroupClick}
              />
            ) : !!pageGroup.associatedRoute ? (
              <SideNavLink
                component={RouterLink(`/${pageGroup.link}`)}
                name={pageGroup.name}
                icon={pageGroup.icon}
                isCollapsed={isCollapsed}
                classes={{
                  listItem: {
                    root: clsx(
                      classes.row,
                      pathMatches(pageGroup.associatedRoute, pathname) &&
                        classes.selectedRow,
                      classes.pageGroup
                    ),
                  },
                  listItemIcon: {
                    root: clsx(classes.iconCell),
                  },
                  listItemText: { primary: classes.pageListItemText },
                }}
                onSelectPage={handlePageSelect}
              />
            ) : null}
          </Fragment>
        )
      })}
    </div>
  )
}

export default React.memo(SideNavLinks, (oldProps: Props, newProps: Props) => {
  return (
    oldProps.isCollapsed === newProps.isCollapsed &&
    oldProps.pageGroups.length === newProps.pageGroups.length &&
    oldProps.pageGroups.every((oldPageGroup, index) => {
      const newPageGroup = newProps.pageGroups[index]
      return oldPageGroup.key === newPageGroup.key
    }) &&
    oldProps.permissions.length === newProps.permissions.length &&
    oldProps.permissions.every((oldPermission, index) => {
      const newPermission = newProps.permissions[index]
      return oldPermission === newPermission
    })
  )
})
