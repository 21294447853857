import { DialogClassKey, Fade, Modal } from '@mui/material'
import React, { forwardRef, PropsWithChildren, ReactNode } from 'react'
import { DataTestIds } from '../../utils/test-utils/dataTestIds'

interface Props {
  open: boolean
  onClose: () => void
  onModalExitedTransition: () => void
  width?: number
  classes?: Partial<Record<DialogClassKey, string> | undefined>
  dialogContainerClasses?: Partial<Record<DialogClassKey, string> | undefined>
  hideBackdrop?: boolean
  modalContent: ReactNode
}

const FrontModal = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  (props: PropsWithChildren<Props>, forwardedRef) => {
    const {
      open,
      onClose,
      onModalExitedTransition,
      width,
      classes,
      dialogContainerClasses,
      hideBackdrop = false,
      modalContent,
    } = props

    return (
      <Modal // `disableBackdropClick` is removed by codemod.
        // You can find more details about this breaking change in [the migration guide](https://mui.com/material-ui/migration/v5-component-changes/#modal)

        disableRestoreFocus
        hideBackdrop={hideBackdrop}
        open={open}
        onClose={onClose}
      >
        <Fade in={open} timeout={100} onExited={onModalExitedTransition}>
          <div
            data-testid={DataTestIds.stackedModalFront}
            className={`${classes?.paper} ${dialogContainerClasses?.paper}`}
            style={{ width: width ? width : 600 }}
            ref={forwardedRef}
          >
            {modalContent}
          </div>
        </Fade>
      </Modal>
    )
  }
)

FrontModal.displayName = 'FrontModal'
export default FrontModal
