import { getType } from 'typesafe-actions'
import { BadgeAssignedCountAction } from '../actions'
import { FetchingStatus } from '../models/fetchingStatus'
import { setFetchingStatus } from '../utils'
import { BadgeAssignedCountState } from '../models/badgeAssignedCount'
import { getBadgeAssignedCountAction } from '../actions/badgeAssignedCount'

const initialState: BadgeAssignedCountState = {
  data: -1,
  status: undefined,
  lastRefreshTime: undefined,
}

export default (
  state: BadgeAssignedCountState = initialState,
  action: BadgeAssignedCountAction
): BadgeAssignedCountState => {
  switch (action.type) {
    case getType(getBadgeAssignedCountAction.success):
      return {
        data: action.payload,
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getBadgeAssignedCountAction.request):
      return setFetchingStatus<number>(state, FetchingStatus.Request)

    case getType(getBadgeAssignedCountAction.failure):
      return setFetchingStatus<number>(state, FetchingStatus.Failure)

    default:
      return state
  }
}
