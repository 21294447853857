import { getType } from 'typesafe-actions'
import { AgentEventsLocationAction, StaffEventsAction } from '../actions'
import {
  resolveStaffEvent,
  setStaffEvents,
} from '../actions/agentEventsLocation'
import {
  dismissStaffEvent,
  updateTelemetryStaffEvent,
} from '../actions/staffEvents'
import {
  EventStatus,
  FetchingStatus,
  StaffEvent,
  StaffEvents,
  StaffEventsState,
} from '../models'
import { mergeRecord, mergeRecords } from '../utils'

const initialState: StaffEventsState = {
  data: {},
}

const staffEventsReducer = (
  state: StaffEventsState = initialState,
  action: StaffEventsAction | AgentEventsLocationAction
): StaffEventsState => {
  switch (action.type) {
    case getType(setStaffEvents): {
      return {
        data: mergeRecords<StaffEvents, StaffEvent, 'badgeId'>(
          state.data,
          action.payload,
          'badgeId'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    }
    case getType(updateTelemetryStaffEvent): {
      const event = action.payload
      const currentEvent = Object.values(state.data).find(
        (e) => e.id === event?.id
      )
      if (currentEvent) {
        event.reportedTime = event.reportedTime
          ? event.reportedTime
          : currentEvent.reportedTime
        event.reportedBy = event.reportedBy
          ? event.reportedBy
          : currentEvent.reportedBy
        event.id = currentEvent.id ?? ''
        if (event.status !== EventStatus.RESOLVED) {
          event.status = currentEvent.status
        }
        event.agentId = currentEvent.agentId
        event.agentEventTypeId = currentEvent.agentEventTypeId
        event.resolved = currentEvent.resolved
      } else {
        event.status = EventStatus.ACTIVE
      }
      return {
        ...state,
        data: mergeRecord<StaffEvents, StaffEvent, 'badgeId'>(
          state.data,
          event,
          'badgeId'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    }
    case getType(dismissStaffEvent): {
      const dismissedEvent = action.payload
      if (dismissedEvent) {
        const clonedEvents = { ...state.data }

        // Check if event exists, as it might not for this user
        // 25802 - Tim Groven - 02/13/2023
        if (dismissedEvent.badgeId in clonedEvents) {
          clonedEvents[dismissedEvent.badgeId].status = EventStatus.DISMISSED
          return {
            data: mergeRecords<StaffEvents, StaffEvent, 'badgeId'>(
              state.data,
              Object.values(clonedEvents),
              'badgeId'
            ),
            status: FetchingStatus.Success,
            lastRefreshTime: new Date(),
          }
        } else {
          return {
            data: state.data,
            status: FetchingStatus.Success,
            lastRefreshTime: new Date(),
          }
        }
      }
      return state
    }
    case getType(resolveStaffEvent): {
      const staffEvent = action.payload
      if (staffEvent) {
        const clonedEvents = { ...state.data }
        const eventToUpdate = clonedEvents[staffEvent.badgeId]

        // Check if event exists, as it might not for this user
        // 25802 - Tim Groven - 02/13/2023
        if (eventToUpdate) {
          eventToUpdate.status = EventStatus.RESOLVED
          eventToUpdate.resolved = true
          clonedEvents[staffEvent.badgeId] = eventToUpdate
          return {
            data: mergeRecords<StaffEvents, StaffEvent, 'badgeId'>(
              state.data,
              Object.values(clonedEvents),
              'badgeId'
            ),
            status: FetchingStatus.Success,
            lastRefreshTime: new Date(),
          }
        } else {
          return {
            data: state.data,
            status: FetchingStatus.Success,
            lastRefreshTime: new Date(),
          }
        }
      }
      return state
    }
    default:
      return state
  }
}

export default staffEventsReducer
