import { DownForMaintenance } from '@midmarkrtls/common/components/Common/DownForMaintenance'
import { CommonRouteKeys } from '@midmarkrtls/common/constants'
import { PageRoute } from '@midmarkrtls/common/interfaces/modules'
import { PermissionsEnum } from '@midmarkrtls/common/models'
import { AlertAdminPage } from '@midmarkrtls/common/pages/AlertManagement'
import {
  AlertConfigConditionPage,
  AlertConfigChannelPage,
  AlertConfigRoutingPage,
  AlertConfigResolutionPage,
  AlertConfigReviewPage,
} from '@midmarkrtls/common/pages/AlertManagement/AlertConfig'
import { PageNotFound } from '@midmarkrtls/common/pages/Errors'
import { LocationManagementPage } from '@midmarkrtls/common/pages/LocationManagement'
import { RealTimePage } from '@midmarkrtls/common/pages/RealTime'
import { UserManagementPage } from '@midmarkrtls/common/pages/UserManagement'
import { UserProfilePage } from '@midmarkrtls/common/pages/UserProfile'
import DevToolsPage from 'containers/DevToolsPage'
import { NotificationsPage } from '@midmarkrtls/common/pages/Notifications'
import React from 'react'

export enum RouteKeys {
  BadgeTrafficPage = 'BadgeTrafficPage',
  ListView = 'ListView',
  LocationManagementPage = 'LocationManagementPage',
  MapViewPage = 'MapViewPage',
  OperationsDashboardPage = 'OperationsDashboardPage',
  OperationsUserManagementPage = 'OperationsUserManagementPage',
  OperationsCustomerManagementPage = 'OperationsCustomerManagementPage',
  UserProfilePage = 'UserProfilePage',
  DevToolsPage = 'DevToolsPage',
  OperationsReleaseManagementPage = 'OperationsReleaseManagementPage',
  OperationsDevToolsPage = 'OperationsDevToolsPage',
  NotificationsPage = 'NotificationsPage',
}

const pageRoutes: PageRoute[] = [
  {
    key: CommonRouteKeys.DownForMaintenance.key,
    path: CommonRouteKeys.DownForMaintenance.path,
    pageComponent: DownForMaintenance,
    exact: true,
    subroutes: [],
    permissions: [],
  },
  {
    key: CommonRouteKeys.DashboardPage.key,
    path: CommonRouteKeys.DashboardPage.path,
    pageComponent: React.lazy(() => import('./containers/DashboardPage')),
    exact: true,
    subroutes: [],
    permissions: [],
  },
  {
    key: RouteKeys.OperationsDashboardPage,
    path: '/operations',
    pageComponent: React.lazy(
      () => import('./containers/OperationsDashboardPage')
    ),
    exact: true,
    subroutes: [],
    permissions: [],
  },
  {
    key: RouteKeys.LocationManagementPage,
    path: CommonRouteKeys.LocationManagementPage.path,
    pageComponent: LocationManagementPage,
    exact: false,
    subroutes: [],
    permissions: [
      PermissionsEnum.LocationsRead,
      PermissionsEnum.LocationsWrite,
    ],
  },
  {
    key: CommonRouteKeys.HardwareManagementPage.key,
    path: CommonRouteKeys.HardwareManagementPage.path,
    pageComponent: React.lazy(
      () => import('./containers/HardwareManagementPage')
    ),
    exact: false,
    subroutes: [],
    permissions: [PermissionsEnum.HardwareRead, PermissionsEnum.HardwareWrite],
  },
  {
    key: CommonRouteKeys.UserManagementPage.key,
    path: `/admin/users`,
    pageComponent: UserManagementPage,
    exact: true,
    subroutes: [],
    permissions: [PermissionsEnum.UsersRead, PermissionsEnum.UsersWrite],
  },
  {
    key: RouteKeys.OperationsUserManagementPage,
    path: `/operations/users`,
    pageComponent: React.lazy(
      () => import('./containers/OperationsUserManagementPage')
    ),
    exact: true,
    subroutes: [],
    permissions: [
      PermissionsEnum.OperationsUsersRead,
      PermissionsEnum.OperationsUsersWrite,
    ],
  },
  {
    key: RouteKeys.OperationsCustomerManagementPage,
    path: '/operations/customers',
    pageComponent: React.lazy(
      () => import('./containers/OperationsCustomerManagementPage')
    ),
    exact: true,
    subroutes: [],
    permissions: [PermissionsEnum.CustomerRead],
  },
  {
    key: RouteKeys.OperationsReleaseManagementPage,
    path: '/operations/releases',
    pageComponent: React.lazy(
      () => import('./containers/OperationsReleaseManagementPage')
    ),
    exact: true,
    subroutes: [],
    permissions: [PermissionsEnum.CustomerRead],
  },
  {
    key: RouteKeys.OperationsDevToolsPage,
    path: '/operations/devtools',
    pageComponent: React.lazy(
      () => import('./containers/OperationsDevToolsPage')
    ),
    exact: true,
    subroutes: [],
    permissions: [PermissionsEnum.CustomerRead],
  },
  {
    key: CommonRouteKeys.AssetManagementPage.key,
    path: CommonRouteKeys.AssetManagementPage.path,
    pageComponent: React.lazy(() => import('./containers/AssetManagementPage')),
    exact: true,
    subroutes: [],
    permissions: [
      PermissionsEnum.AssetsAdminRead,
      PermissionsEnum.AssetsAdminWrite,
    ],
  },
  {
    key: CommonRouteKeys.StaffManagementPage.key,
    path: CommonRouteKeys.StaffManagementPage.path,
    pageComponent: React.lazy(() => import('./containers/StaffManagementPage')),
    exact: true,
    subroutes: [],
    permissions: [
      PermissionsEnum.StaffAdminRead,
      PermissionsEnum.StaffAdminWrite,
    ],
  },
  {
    key: RouteKeys.BadgeTrafficPage,
    path: CommonRouteKeys.BadgeTrafficPage.path,
    pageComponent: React.lazy(() => import('./containers/BadgeTrafficPage')),
    exact: true,
    subroutes: [],
    permissions: [
      PermissionsEnum.BadgeTrafficRead,
      PermissionsEnum.BadgeTrafficWrite,
    ],
  },
  {
    key: RouteKeys.MapViewPage,
    path: `/map-view`,
    redirectTo: '/assets?view=map',
    exact: false,
    subroutes: [],
    permissions: [PermissionsEnum.ListRead, PermissionsEnum.ListWrite],
  },
  {
    key: RouteKeys.ListView,
    path: `/list-view`,
    redirectTo: '/assets',
    exact: false,
    subroutes: [],
    permissions: [PermissionsEnum.ListRead, PermissionsEnum.ListWrite],
  },
  {
    key: CommonRouteKeys.RealTimePage.key,
    path: CommonRouteKeys.RealTimePage.path,
    pageComponent: RealTimePage,
    exact: false,
    subroutes: [],
    permissions: [PermissionsEnum.ListRead, PermissionsEnum.ListWrite],
  },
  {
    key: CommonRouteKeys.MapPage.key,
    path: CommonRouteKeys.MapPage.path,
    pageComponent: React.lazy(() => import('./containers/MapManagementPage')),
    exact: false,
    subroutes: [],
    permissions: [
      PermissionsEnum.ImportVenuesRead,
      PermissionsEnum.ImportVenuesWrite,
    ],
  },
  {
    key: CommonRouteKeys.BadgePage.key,
    path: CommonRouteKeys.BadgePage.path,
    pageComponent: React.lazy(() => import('./containers/BadgeManagementPage')),
    exact: false,
    subroutes: [],
    permissions: [PermissionsEnum.BadgesRead, PermissionsEnum.BadgesWrite],
  },
  {
    key: CommonRouteKeys.AlertManagementPage.key,
    path: CommonRouteKeys.AlertManagementPage.path,
    pageComponent: AlertAdminPage,
    exact: true,
    subroutes: [],
    permissions: [
      PermissionsEnum.AlertAdminRead,
      PermissionsEnum.AlertAdminWrite,
    ],
  },
  {
    key: CommonRouteKeys.AlertConfigConditionPage.key,
    path: `${CommonRouteKeys.AlertConfigConditionPage.path}`,
    pageComponent: AlertConfigConditionPage,
    exact: true,
    subroutes: [],
    permissions: [
      PermissionsEnum.AlertAdminRead,
      PermissionsEnum.AlertAdminWrite,
    ],
  },
  {
    key: CommonRouteKeys.AlertConfigChannelPage.key,
    path: CommonRouteKeys.AlertConfigChannelPage.path,
    pageComponent: AlertConfigChannelPage,
    exact: true,
    subroutes: [],
    permissions: [
      PermissionsEnum.AlertAdminRead,
      PermissionsEnum.AlertAdminWrite,
    ],
  },
  {
    key: CommonRouteKeys.AlertConfigRoutingPage.key,
    path: CommonRouteKeys.AlertConfigRoutingPage.path,
    pageComponent: AlertConfigRoutingPage,
    exact: true,
    subroutes: [],
    permissions: [
      PermissionsEnum.AlertAdminRead,
      PermissionsEnum.AlertAdminWrite,
    ],
  },
  {
    key: CommonRouteKeys.AlertConfigResolutionPage.key,
    path: CommonRouteKeys.AlertConfigResolutionPage.path,
    pageComponent: AlertConfigResolutionPage,
    exact: true,
    subroutes: [],
    permissions: [
      PermissionsEnum.AlertAdminRead,
      PermissionsEnum.AlertAdminWrite,
    ],
  },
  {
    key: CommonRouteKeys.AlertConfigReviewPage.key,
    path: CommonRouteKeys.AlertConfigReviewPage.path,
    pageComponent: AlertConfigReviewPage,
    exact: true,
    subroutes: [],
    permissions: [
      PermissionsEnum.AlertAdminRead,
      PermissionsEnum.AlertAdminWrite,
    ],
  },
  {
    key: CommonRouteKeys.DevToolsPage.key,
    path: CommonRouteKeys.DevToolsPage.path,
    pageComponent: DevToolsPage,
    exact: false,
    subroutes: [],
    permissions: [PermissionsEnum.DevToolsRead, PermissionsEnum.DevToolsWrite],
  },
  {
    path: CommonRouteKeys.ErrorPage.path,
    key: CommonRouteKeys.ErrorPage.key,
    pageComponent: PageNotFound,
    exact: true,
    subroutes: [],
    permissions: [],
  },
  {
    key: RouteKeys.UserProfilePage,
    path: '/user-profile',
    pageComponent: UserProfilePage,
    exact: false,
    subroutes: [],
    permissions: [],
  },
  {
    key: RouteKeys.NotificationsPage,
    path: CommonRouteKeys.NotificationsPage.path,
    pageComponent: NotificationsPage,
    exact: false,
    subroutes: [],
    permissions: [],
  },
]

export default pageRoutes
