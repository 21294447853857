import { EntityResult, useEntities } from '../useEntities'
import { getLocationMetricsAction } from '../../actions/locationMetrics'
import { LocationMetrics, LocationMetricsState } from '../../models'
import { useCallback, useRef, useState } from 'react'
import { isUndefined } from 'lodash'
import { isEmptyOrSpaces } from '../../helpers'

const useFetchLocationMetrics = (
  locationId: string
): EntityResult<LocationMetrics> => {
  const locationIdRef = useRef<string | undefined>()

  /*
   we only store metrics for a single location in state
   so we need to be able to determine
   if the location has changed and therefore need to refetch
   for the new location
  */
  const additionalCondition = useCallback(() => {
    if (isEmptyOrSpaces(locationId) || isUndefined(locationId)) {
      return false
    }
    if (isUndefined(locationIdRef.current)) {
      locationIdRef.current = locationId
      return true
    }
    if (locationIdRef.current !== locationId) {
      locationIdRef.current = locationId
      return true
    }
  }, [locationId])

  return useEntities<LocationMetricsState, LocationMetrics>(
    Boolean(locationId && locationId.length)
      ? () => getLocationMetricsAction.request(locationId)
      : undefined,
    (state: any) => state.locationMetrics, // eslint-disable-line @typescript-eslint/no-explicit-any
    additionalCondition
  )
}

export { useFetchLocationMetrics }
