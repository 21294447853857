import React, { useMemo } from 'react'
import { allUnits } from '../../models'
import { AlertActiveConfig, AlertTypes } from '../../models/alert'
import { DetailedItem } from '../../models/DetailPaneItems'
import DetailsList from '../DetailsList/DetailsList'

interface Props {
  alert: AlertActiveConfig
  isLoading?: boolean
  headerStylesOverrides?: any
  listItemStylesOverrides?: any
}

const AlertQualifierDetail = (props: Props) => {
  const {
    alert,
    isLoading = false,
    headerStylesOverrides,
    listItemStylesOverrides,
  } = props

  const qualifierItems = useMemo(() => {
    const items: any[] = []
    const isAssetAlert: boolean = alert.alertType?.id === AlertTypes.AssetAlert

    if (isAssetAlert) {
      items.push({
        field: 'Location Change',
        value: alert.locationChangeQualifier,
      })
    }

    if (alert?.badgeQualifier && !isAssetAlert) {
      items.push({
        field: 'Badges',
        value: alert.badgeQualifier,
      })
    }

    if (alert?.locationQualifier) {
      items.push(
        {
          field: 'Building Group',
          value: alert?.locationQualifier.campus?.name,
        },
        {
          field: 'Building',
          value: alert?.locationQualifier.building?.name,
        },
        {
          field: 'Floor',
          value: alert?.locationQualifier.floor?.name,
        }
      )
    }

    if (alert?.unitQualifier) {
      items.push({
        field: 'Unit',
        value: alert?.unitQualifier?.name,
      })
    } else {
      items.push({
        field: 'Unit',
        value: allUnits.name,
      })
    }

    if (isAssetAlert) {
      items.push({
        field: 'Asset Type',
        value: alert.assetType?.name,
      })
    }

    return items
  }, [alert])

  const alertQualifierDetailItems: DetailedItem[] = []
  alertQualifierDetailItems.push({
    header: 'QUALIFIERS',
    items: qualifierItems,
  })

  return (
    <DetailsList
      detailedItems={alertQualifierDetailItems}
      narrowRows={true}
      headerStylesOverrides={headerStylesOverrides}
      listItemStylesOverrides={listItemStylesOverrides}
      isLoading={isLoading}
    />
  )
}

export default AlertQualifierDetail
