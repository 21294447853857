import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  MsalAuthenticationTemplate,
  useMsal,
  useAccount,
} from '@azure/msal-react'
import {
  InteractionType,
  InteractionStatus,
  AuthenticationResult,
} from '@azure/msal-browser'
import { AuthState, B2CUser, FetchingStatus } from '../../models'
import { permissionStringsToEnums } from '../../helpers'
import { setAuthenticatedUserAction } from '../../actions/auth'
import BuildRoutes from '../Routes/Routes'
import { LoadingPageFull } from '../../pages/LoadingPageFull'
import './ProtectedContent.css'
import { Authentication } from '../../authentication/AuthenticationManager'
import { useFetchFeatureFlags } from '../../hooks/entities/useFetchFeatureFlags'
import { FeatureFlagLabels } from '../../constants'
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom'
import { PageRouteComponent, PageSubRoute } from '../../interfaces/modules'
import { PageWrapper } from '../../pages'
import { PageNotFound } from '../../pages/Errors'

const parseAuthenticationResult = (
  response: AuthenticationResult
): B2CUser | null => {
  if (!response || !response.account) {
    return null
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const account = response.account.idTokenClaims as any

  return {
    id: account.sub || account.idToken.sub,
    name: account.name || account.idToken.name,
    firstName: account.given_name || '',
    lastName: account.family_name || '',
    customerId: account.extension_PartnerLocationId || '',
    permissions: permissionStringsToEnums(account.permissions as string[]),
  }
}

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loginRequest: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  routes: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  RootLayout: React.ComponentType<any>
}

const ProtectedRoutes = ({
  loginRequest,
  routes,
  RootLayout,
}: Props): JSX.Element | null => {
  const { instance, accounts, inProgress } = useMsal()

  // each time useMsal adjusts its instance we set
  // it to a class which is accessible outside of components
  Authentication.updateClientApplication(instance)

  const account = useAccount(accounts[0] || {})
  const authState = useSelector(({ auth }: { auth: AuthState }) => auth)

  const dispatch = useDispatch()

  const handleUserAuthentication = useCallback(
    (response: AuthenticationResult) => {
      const b2cUser = parseAuthenticationResult(response)
      const accessToken = response.accessToken
      localStorage.setItem('automationAccessToken', accessToken)
      if (!b2cUser) {
        throw 'Could not parse the user from the token'
      }

      dispatch(setAuthenticatedUserAction(b2cUser))
    },
    [dispatch]
  )

  useEffect(() => {
    if (
      !authState.user.id &&
      account &&
      inProgress === InteractionStatus.None
    ) {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: account,
        })
        .then(handleUserAuthentication)
        .catch((err) => {
          Authentication.logout()
          return err
        })
    }
  }, [
    account,
    inProgress,
    instance,
    authState.user,
    handleUserAuthentication,
    loginRequest,
  ])

  const { status: featureFlagStatus } = useFetchFeatureFlags(
    FeatureFlagLabels.Navigation,
    !!authState.user.id
  )

  const fetchingFeatureFlags =
    featureFlagStatus === FetchingStatus.Request || !featureFlagStatus

  //Gets an array of route data filtered to the current user
  const userRoutes = BuildRoutes(routes)

  //Creates the Browser Router with the route data
  //Creates a top level route using the <RootLayout /> with the / path
  //https://reactrouter.com/en/6.14.2/upgrading/v6-data
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path='/' element={<RootLayout />} errorElement={<PageNotFound />}>
        {userRoutes.map((x: PageRouteComponent) => {
          return (
            <Route
              key={x.route.key}
              path={`${x.route.path}`}
              element={
                <PageWrapper>
                  <x.component />
                </PageWrapper>
              }
            >
              {x.route.subroutes.map((y: PageSubRoute) => {
                return (
                  <Route
                    key={y.key}
                    path={y.path}
                    element={<y.pageComponent />}
                  ></Route>
                )
              })}
            </Route>
          )
        })}
      </Route>
    )
  )

  //Returns the React-Router-Dom RouteProvider with the routes
  return !fetchingFeatureFlags && authState.user.id ? (
    <div style={{ animation: 'fadeIn 1s' }}>
      <RouterProvider router={router} />
    </div>
  ) : (
    <LoadingPageFull />
  )
}

const ProtectedContent = (props: Props): JSX.Element => {
  const authRequest = {
    ...props.loginRequest,
  }
  const [show, setShow] = React.useState(false)

  useEffect(() => {
    // MsalAuthenticationTemplate uses an internal context to determine whether
    // or not to show the LoadingComponent. Introducing an artificial delay fixes flashing
    const timeout = setTimeout(() => {
      setShow(true)
    }, 1000)

    return () => clearTimeout(timeout)
  }, [show])

  return show ? (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      loadingComponent={LoadingPageFull}
    >
      <ProtectedRoutes {...props} />
    </MsalAuthenticationTemplate>
  ) : (
    <LoadingPageFull />
  )
}

export { ProtectedContent }
