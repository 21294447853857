import { AgentTypePlural } from '../../models'
import {
  AgentTelemetriesGroupedByTrackingId,
  TelemetryByAgentType,
} from '../../models/telemetry'

export function getTelemetriesByAgentTypesPlural(
  agentTelemetries: TelemetryByAgentType,
  agentTypesPlural: string[]
): AgentTelemetriesGroupedByTrackingId {
  const { assetAgentTelemetries, staffAgentTelemetries } = agentTelemetries
  return agentTypesPlural.includes(AgentTypePlural[AgentTypePlural.Assets]) &&
    agentTypesPlural.includes(AgentTypePlural[AgentTypePlural.Staff])
    ? {
        ...assetAgentTelemetries,
        ...staffAgentTelemetries,
      }
    : agentTypesPlural.includes(AgentTypePlural[AgentTypePlural.Assets])
    ? assetAgentTelemetries
    : agentTypesPlural.includes(AgentTypePlural[AgentTypePlural.Staff])
    ? staffAgentTelemetries
    : {}
}
