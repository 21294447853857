import { getType } from 'typesafe-actions'
import { AlertAction } from '../actions'
import {
  deleteAlertAction,
  getAlertAction,
  getAllAlertsAction,
  postAlertAction,
  putAlertAction,
} from '../actions/alerts'
import { mapAlertTypeName } from '../helpers/alert'
import { Alert, Alerts, AlertsState } from '../models/alert'
import { FetchingStatus } from '../models/fetchingStatus'
import {
  deleteRecord,
  mergeRecord,
  mergeRecords,
  setFetchingStatus,
} from '../utils'

const initialState: AlertsState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

const key = 'id'

export default (
  state: AlertsState = initialState,
  action: AlertAction
): AlertsState => {
  switch (action.type) {
    case getType(getAllAlertsAction.success):
      Object.values(action.payload).forEach((alert) => {
        alert.alertTypeName = mapAlertTypeName(alert.alertTypeId)
      })
      return {
        data: mergeRecords<Alerts, Alert, typeof key>(
          state.data,
          action.payload,
          key
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getAlertAction.success):
    case getType(postAlertAction.success):
    case getType(putAlertAction.success):
      action.payload.alertTypeName = mapAlertTypeName(
        action.payload.alertTypeId
      )
      return {
        data: mergeRecord<Alerts, Alert, typeof key>(
          state.data,
          action.payload,
          key
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(deleteAlertAction.success):
      return {
        ...state,
        data: deleteRecord<Alerts, Alert>(state.data, action.payload),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getAllAlertsAction.request):
    case getType(getAlertAction.request):
    case getType(postAlertAction.request):
    case getType(putAlertAction.request):
    case getType(deleteAlertAction.request):
      return setFetchingStatus<Alerts>(state, FetchingStatus.Request)
    case getType(getAllAlertsAction.failure):
    case getType(getAlertAction.failure):
    case getType(postAlertAction.failure):
    case getType(putAlertAction.failure):
    case getType(deleteAlertAction.failure):
      return setFetchingStatus<Alerts>(state, FetchingStatus.Failure)
    default:
      return state
  }
}
