import { createAction } from 'typesafe-actions'
import { StaffEvent } from '../models'

export const setAllAssistFilteredByUser = createAction(
  'SET_ALL_ASSIST_FILTERED_BY_USER'
)<StaffEvent[]>()

export const setActiveAssistEvents = createAction('SET_ACTIVE_ASSIST_EVENTS')<
  StaffEvent[]
>()
