import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  badgeSignalAccuracy,
  badgeSignalStatus,
  getCmmsManagedDisplayText,
} from '../../../../helpers'
import { getLocationBasedBatteryStatus } from '../../../../helpers/battery'
import {
  formatLocalDateTimeByUserPreference,
  localMomentFormatted,
  momentUTCFormatted,
} from '../../../../helpers/date'
import { useFetchUserPreferences } from '../../../../hooks'
import { useAgentLocation } from '../../../../hooks/useAgentLocation'
import {
  Asset,
  BadgeCurrentLocation,
  Manufacturer,
  MapUpdateState,
  SignalType,
} from '../../../../models'
import { DetailedItem } from '../../../../models/DetailPaneItems'
import { AssetRow } from '../../../../models/assetRow'
import DetailsList from '../../../DetailsList/DetailsList'

interface Props<T = AssetRow | Asset> {
  asset: T
  manufacturers: Manufacturer[]
  liveTelemetry?: BadgeCurrentLocation[]
}

function DeprecatedAssetDetailPane(props: Props): React.ReactElement {
  const { asset, manufacturers, liveTelemetry } = props
  const mapUpdates = useSelector(
    ({ mapUpdates }: { mapUpdates: MapUpdateState }) => mapUpdates
  )
  const assetLocation = useAgentLocation(
    asset,
    liveTelemetry,
    mapUpdates?.mapUpdates ?? []
  )
  const { data: userPreferences } = useFetchUserPreferences()

  const identificationItems = useMemo(() => {
    const manufacturer =
      asset.manufacturerGuid && manufacturers.length > 0
        ? manufacturers.find(
            (x) => x.manufacturerGuid === asset.manufacturerGuid
          )
        : undefined
    return !!asset
      ? [
          {
            field: 'Name',
            value: asset.name,
          },
          {
            field: 'Display Name',
            value: asset.displayName,
          },
          {
            field: 'Asset ID',
            value: asset.externalUniqueId,
          },
          {
            field: 'Asset Type',
            value: asset.assetTypeName,
          },
          {
            field: 'Asset Sub-Type',
            value: asset.assetSubTypeName,
          },
          {
            field: 'Manufacturer',
            value: !!manufacturer ? manufacturer.name : '',
          },
          {
            field: 'Serial Number',
            value: asset.serialNumber,
          },
          {
            field: 'Model',
            value: asset.model,
          },
          {
            field: 'Owner',
            value: asset.owner,
          },
          {
            field: 'CMMS Managed',
            value: getCmmsManagedDisplayText(asset.cmmsManaged),
          },
          {
            // Do not convert the PM Date to local time, as this can cause the date to be brought back a day
            // or push it to the next date and will not have the date that was entered on import/creation.
            field: 'Next PM Date',
            value: asset?.preventativeMaintenanceDate
              ? momentUTCFormatted(
                  asset.preventativeMaintenanceDate,
                  'MM/DD/YYYY'
                )
              : 'None',
          },
          {
            field: 'Assigned To',
            value: asset?.unitName,
          },
          {
            field: 'RTLS ID',
            value: asset.badgeIds.join(','),
          },
        ]
      : []
  }, [asset, manufacturers])

  const locationItems = useMemo(() => {
    let locationAccuracy = 'Unknown'
    let signalStatus = 'Unknown'
    let lastDetected = assetLocation?.timestamp
    if (asset.badgeTelemetry?.signalTypeId) {
      locationAccuracy = badgeSignalAccuracy(asset.badgeTelemetry?.signalTypeId)
      signalStatus = badgeSignalStatus(asset?.badgeTelemetry.timestamp)
      lastDetected = asset?.badgeTelemetry?.timestamp
    }
    return !!asset
      ? [
          {
            field: 'Building Group',
            value: assetLocation?.buildingGroupLocation?.name,
          },
          {
            field: 'Building',
            value: assetLocation?.buildingLocation?.name,
          },
          {
            field: 'Floor',
            value: assetLocation?.floorLocation?.name,
          },
          {
            field: 'Room',
            value: assetLocation?.roomLocation?.name,
          },
          {
            field: 'Unit',
            value: assetLocation?.locationUnitName,
          },
          {
            field: 'Sub-Unit',
            value: assetLocation?.locationSubUnitName,
          },
          {
            field: 'Location Accuracy',
            value: locationAccuracy ?? '',
          },
          {
            field: 'Last Detected',
            value: formatLocalDateTimeByUserPreference(
              lastDetected,
              userPreferences.timeDisplayId
            ),
          },
          {
            field: 'Signal Status',
            value: signalStatus ?? '',
          },
          {
            field: 'Signal Type',
            value: SignalType[asset.badgeTelemetry?.signalTypeId] ?? '',
          },
          {
            field: 'Battery Status',
            value: getLocationBasedBatteryStatus(
              assetLocation?.roomLocation,
              asset?.isLowBattery
            ),
          },
          {
            field: 'Battery Change Date',
            value: asset?.tagBatteryChangeDate
              ? localMomentFormatted(asset.tagBatteryChangeDate, 'MM/DD/YYYY')
              : 'None',
          },
        ]
      : []
  }, [
    assetLocation?.timestamp,
    assetLocation?.buildingLocation?.name,
    assetLocation?.floorLocation?.name,
    assetLocation?.roomLocation,
    assetLocation?.locationUnitName,
    assetLocation?.locationSubUnitName,
    asset,
    userPreferences.timeDisplayId,
  ])

  const detailItems: DetailedItem[] = []
  detailItems.push({
    header: 'ASSET IDENTIFICATION',
    items: identificationItems,
  })
  detailItems.push({
    header: 'RTLS LOCATION DATA',
    items: locationItems,
  })

  return (
    <div style={{ paddingTop: 24 }}>
      <DetailsList detailedItems={detailItems} narrowRows={true} />
    </div>
  )
}

export default DeprecatedAssetDetailPane
