import React from 'react'
import { User } from '../../models/users'
import { CreateOrEditModalProps, makeCreateOrEditModal } from '../Common/HOC'
import UserManagementForm from './UserManagementForm'

const UserManagementModal = (props: CreateOrEditModalProps<User | null>) => (
  <UserManagementForm {...props}></UserManagementForm>
)

export default makeCreateOrEditModal<User | null>(UserManagementModal)
