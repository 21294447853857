import { useEffect } from 'react'
import { applyMapPreset } from '../../helpers/inpixon'
import { MapPreset, MapPresets } from '../../models'

export function useMapPresets(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jibestreamControl: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  assetKit: any,
  mapPresets: MapPresets,
  badgeId?: string,
  x?: number,
  y?: number,
  scale?: number
): void {
  useEffect(() => {
    // if both control and assetKit are present, map is loaded
    if (jibestreamControl && assetKit) {
      const preset =
        mapPresets !== undefined
          ? Object.values(mapPresets).filter(
              (x: MapPreset) => x.mapId === jibestreamControl?.currentMap?.id
            )?.[0]
          : null

      const asset = assetKit?.getAssetById(badgeId)

      applyMapPreset(jibestreamControl, preset, asset, x, y, scale)
    }
  }, [
    jibestreamControl,
    jibestreamControl?.currentMap?.id,
    assetKit,
    mapPresets,
    badgeId,
    x,
    y,
    scale,
  ])
}
