import React, { useCallback, useEffect, useState } from 'react'
import { Tooltip, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import WifiTetheringIcon from '@mui/icons-material/WifiTethering'
import { differenceInMilliseconds, differenceInSeconds } from 'date-fns'
import { useInterval } from '../../hooks'
import { fromNow } from '../../helpers/date'
import { IconButton } from '../../components/ActionBar'
import { useCombinedTelemetry } from '../../hooks/useCombinedTelemetry'
import { DataTestIds } from '../../utils/test-utils/dataTestIds'

const useStyles = makeStyles(() =>
  createStyles({
    error: {
      color: 'red',
    },
    good: {
      color: 'green',
    },
    alert: {
      color: 'orange',
    },
  })
)

const LiveTelemetryIndicator: React.FC = () => {
  const [errorState, setErrorState] = useState<boolean>(false)
  const [alertState, setAlertState] = useState<boolean>(false)
  const [reset, setReset] = useState<boolean>(false)
  const classes = useStyles()
  const { lastLiveTelemetry, lastLatency } = useCombinedTelemetry()
  const evaluateTelemetry = useCallback(() => {
    if (reset) {
      setReset(false)
      setErrorState(false)
    }
    if (
      lastLiveTelemetry &&
      differenceInSeconds(new Date(), lastLiveTelemetry) > 60
    ) {
      setErrorState(true)
    } else if (lastLatency && lastLatency > 30) {
      setAlertState(true)
    } else {
      setErrorState(false)
      setAlertState(false)
    }
  }, [lastLatency, lastLiveTelemetry, reset])

  useInterval(evaluateTelemetry, 10000)

  const onReconnect = useCallback(() => {
    window.location.reload()

    setReset(true)
  }, [])

  useEffect(() => {
    evaluateTelemetry()
  }, [evaluateTelemetry, lastLiveTelemetry])

  return (
    <>
      {lastLiveTelemetry && (
        <Tooltip
          title={
            <div style={{ textAlign: 'center' }}>
              <Typography color='inherit' style={{ fontSize: '10px' }}>
                {`Last Update: ${fromNow(
                  differenceInMilliseconds(new Date(), lastLiveTelemetry)
                )} (${differenceInSeconds(
                  new Date(),
                  lastLiveTelemetry
                )} seconds)`}
              </Typography>
              <Typography color='inherit' style={{ fontSize: '10px' }}>
                {`Latency: ${lastLatency} seconds`}
              </Typography>
              <Typography color='inherit' style={{ fontSize: '10px' }}>
                Click to refresh page
              </Typography>
            </div>
          }
        >
          <IconButton
            onClick={onReconnect}
            style={{ backgroundColor: 'transparent' }}
            data-testid={DataTestIds.liveTelemetryIndicator}
            size='large'
          >
            <WifiTetheringIcon
              className={
                errorState || alertState
                  ? alertState
                    ? classes.alert
                    : classes.error
                  : classes.good
              }
            />
          </IconButton>
        </Tooltip>
      )}
    </>
  )
}

export { LiveTelemetryIndicator }
