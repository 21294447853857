import { Map } from '../models'
import { BaseService } from './baseService'

export class MapsApi extends BaseService {
  constructor() {
    super({ entityType: 'maps' })
  }
  route = 'maps'

  async getAllMaps(): Promise<Map[]> {
    return await this.get<Map[]>(this.route)
  }
}
