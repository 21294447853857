import { Grid } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { setLocationSelectionAction } from '../../actions/locationSelection'
import midmarkLogo from '../../assets/Midmark-logo-48.png'
import { CommonRouteKeys, FeatureFlagLabels } from '../../constants'
import { pathMatches } from '../../helpers'
import {
  useCurrentLocation,
  useFetchLocations,
  useFetchSelectableLocations,
  useQueryParams,
} from '../../hooks'
import { useFetchFeatureFlags } from '../../hooks/entities/useFetchFeatureFlags'
import { useFetchPastReleases } from '../../hooks/entities/useFetchPastReleases'
import useWidthBreakpoints from '../../hooks/useWidthBreakpoints'
import { PageGroup } from '../../interfaces/modules'
import {
  FetchingStatus,
  Location,
  LocationSelection,
  LocationType,
} from '../../models'
import { AppTypeEnum } from '../../models/appType'
import { WidthBreakpoints } from '../../models/widthBreakpoints'
import { DataTestIds } from '../../utils/test-utils/dataTestIds'
import LocationSelectBreadcrumbs from '../LocationSelectMenu/LocationSelectBreadcrumbs'
import { headerMaxHeight } from './constants'
import RecentUpdateModal from './RecentUpdateModal/RecentUpdateModal'
import UserMenu from './UserMenu'

interface Props {
  currentCustomerId: string
  headerItems?: React.ReactNode[]
  handleLogoClick: () => void
  userProfilePage: string
  pageGroups: PageGroup[]
}

interface LocationSelectionResult {
  campus: Location | undefined
  building: Location | undefined
  floor: Location | undefined
}

const Header = (props: Props) => {
  const {
    currentCustomerId,
    headerItems = [],
    handleLogoClick,
    userProfilePage,
    pageGroups,
  } = props

  const { view } = useQueryParams()

  const widthBreakpoint = useWidthBreakpoints()
  const definedAppType = process.env.REACT_APP_APP_TYPE
  const applicationTypeId = definedAppType === AppTypeEnum.Operations ? 1 : 2
  const { data: pastReleases } = useFetchPastReleases(applicationTypeId)

  const { data: FeatureFlags } = useFetchFeatureFlags(
    FeatureFlagLabels.Navigation
  )

  const systemDown =
    Object.values(FeatureFlags).filter(
      (x) => x.name === 'DownForMaintenanceScreen' && x.enabled === true
    ).length > 0

  const { setCurrentLocation, currentLocation } = useCurrentLocation()
  const { data: locations } = useFetchLocations()
  const { data: selectableLocations, status: selectableLocationsStatus } =
    useFetchSelectableLocations()

  const idn: Location | null = selectableLocations.idn ?? null

  const [isIDNRestrictedHierarchy, setIsIDNRestrictedHierarchy] =
    useState<boolean>(false)

  const location = useLocation()

  const [locationSelectionInitialized, setLocationSelectionInitialized] =
    useState<boolean>(false)

  const [isDeselectable, setIsDeselectable] = useState<boolean>(true)
  const [locationHeaderVisible, setLocationHeaderVisible] =
    useState<boolean>(true)

  const locationSelection = useSelector(
    ({ locationSelection }: { locationSelection: LocationSelection }) =>
      locationSelection
  )
  const dispatch = useDispatch()

  useEffect(() => {
    let isAssetManagementPage = false
    let isStaffManagementPage = false
    let isMapPage = false
    let isHardwarePage = false
    let isBadgesPage = false
    let isUsersPage = false
    let isLocationsManagementPage = false
    let isBadgeTrafficPage = false
    let isAlertAdminPage = false

    const adminPages = pageGroups.find((p) => p.key === 'administration')?.pages
    if (adminPages) {
      const assetManagementPage = adminPages.find(
        (page) => page.key === CommonRouteKeys.AssetManagementPage.key
      )
      if (assetManagementPage && assetManagementPage.associatedRoute) {
        isAssetManagementPage = pathMatches(
          assetManagementPage.associatedRoute,
          location.pathname
        )
      }
      const staffManagementPage = adminPages.find(
        (page) => page.key === CommonRouteKeys.StaffManagementPage.key
      )
      if (staffManagementPage && staffManagementPage.associatedRoute) {
        isStaffManagementPage = pathMatches(
          staffManagementPage.associatedRoute,
          location.pathname
        )
      }
      const mapPage = adminPages.find(
        (page) => page.key === CommonRouteKeys.MapPage.key
      )
      const hardwarePage = adminPages.find(
        (page) => page.key === CommonRouteKeys.HardwareManagementPage.key
      )
      if (mapPage && mapPage.associatedRoute) {
        isMapPage = pathMatches(mapPage.associatedRoute, location.pathname)
      }
      if (hardwarePage && hardwarePage.associatedRoute) {
        isHardwarePage = pathMatches(
          hardwarePage.associatedRoute,
          location.pathname
        )
      }

      const badgesPage = adminPages.find(
        (page) => page.key === CommonRouteKeys.BadgePage.key
      )
      if (badgesPage && badgesPage.associatedRoute) {
        isBadgesPage = pathMatches(
          badgesPage.associatedRoute,
          location.pathname
        )
      }

      const usersPage = adminPages.find(
        (page) => page.key === CommonRouteKeys.UserManagementPage.key
      )
      if (usersPage && usersPage.associatedRoute) {
        isUsersPage = pathMatches(usersPage.associatedRoute, location.pathname)
      }

      const locationManagementPage = adminPages.find(
        (page) => page.key === CommonRouteKeys.LocationManagementPage.key
      )
      if (locationManagementPage && locationManagementPage.associatedRoute) {
        isLocationsManagementPage = pathMatches(
          locationManagementPage.associatedRoute,
          location.pathname
        )
      }

      const badgeTrafficPage = adminPages.find(
        (page) => page.key === CommonRouteKeys.BadgeTrafficPage.key
      )
      if (badgeTrafficPage && badgeTrafficPage.associatedRoute) {
        isBadgeTrafficPage = pathMatches(
          badgeTrafficPage.associatedRoute,
          location.pathname
        )
      }

      const alertAdminPage = adminPages.find(
        (page) => page.key === CommonRouteKeys.AlertManagementPage.key
      )
      if (alertAdminPage && alertAdminPage.associatedRoute) {
        isAlertAdminPage = pathMatches(
          alertAdminPage.associatedRoute,
          location.pathname
        )
      }
    }
    if (
      isAssetManagementPage ||
      isStaffManagementPage ||
      isMapPage ||
      isBadgesPage ||
      isUsersPage ||
      isLocationsManagementPage ||
      isBadgeTrafficPage
    ) {
      setIsIDNRestrictedHierarchy(true)
    } else {
      setIsIDNRestrictedHierarchy(false)
    }
    if ((isHardwarePage && view !== 'issues') || view === 'map') {
      setIsDeselectable(false)
    } else {
      setIsDeselectable(true)
    }
  }, [location.pathname, view])

  const [selectedCampus, setSelectedCampus] = useState<Location | undefined>()

  const [selectedBuilding, setSelectedBuilding] = useState<
    Location | undefined
  >()

  const [selectedFloor, setSelectedFloor] = useState<Location | undefined>()
  const { returnPath } = useQueryParams()

  useEffect(() => {
    if (
      location.pathname === CommonRouteKeys.CloudReportingLandingPage.path ||
      location.pathname === CommonRouteKeys.CloudReportingReportPage.path
    ) {
      setLocationHeaderVisible(false)
    } else {
      setLocationHeaderVisible(true)
    }
  }, [location, returnPath])

  const setLocationSelectionChanged = () => {
    if (locationSelection.isChanged) {
      return
    }
    dispatch(
      setLocationSelectionAction({
        isChanged: true,
      })
    )
  }

  const getToolbarStyle = () => {
    let paddingLeft = '80px'
    if (
      widthBreakpoint === WidthBreakpoints.ExtraSmall ||
      widthBreakpoint === WidthBreakpoints.Small ||
      widthBreakpoint === WidthBreakpoints.Large ||
      widthBreakpoint === WidthBreakpoints.Medium
    ) {
      paddingLeft = '8px'
    }
    return { paddingLeft: paddingLeft, paddingRight: '8px' }
  }
  const getToolbarSpacer = () => {
    if (
      widthBreakpoint === WidthBreakpoints.ExtraSmall ||
      widthBreakpoint === WidthBreakpoints.Small ||
      widthBreakpoint === WidthBreakpoints.Large ||
      widthBreakpoint === WidthBreakpoints.Medium
    ) {
      return <></>
    } else {
      return (
        <div
          data-testid={`${DataTestIds.headerToolbarSpacer}`}
          style={{ marginLeft: '40px' }}
        ></div>
      )
    }
  }

  const onCampusChange = (location: Location | undefined) => {
    setLocationSelectionChanged()
    setSelectedCampus(location)
  }

  const onBuildingChange = (location: Location | undefined) => {
    setLocationSelectionChanged()
    setSelectedBuilding(location)
  }

  const onFloorChange = (location: Location | undefined) => {
    setLocationSelectionChanged()
    setSelectedFloor(location)
  }

  const locationSelectionResult: LocationSelectionResult = useMemo(() => {
    const result: LocationSelectionResult = {
      campus: undefined,
      building: undefined,
      floor: undefined,
    }

    if (
      currentLocation &&
      Object.values(locations).length &&
      selectableLocationsStatus === FetchingStatus.Success
    ) {
      if (currentLocation.locationType === LocationType.BuildingGroup) {
        if (idn) {
          result.campus = idn.children?.find((x) => x.id === currentLocation.id)
        }
      }
      if (currentLocation.locationType === LocationType.Building) {
        if (currentLocation.parentId) {
          if (idn) {
            result.campus = idn.children?.find(
              (x) => x.id === currentLocation.parentId
            )
            if (result.campus) {
              result.building = result.campus.children?.find(
                (x) => x.id === currentLocation.id
              )
            }
          }
        }
      }
      if (currentLocation.locationType === LocationType.Floor) {
        if (currentLocation.parentId) {
          const building = locations[currentLocation.parentId]
          if (building?.parentId) {
            if (idn) {
              result.campus = idn.children?.find(
                (x) => x.id === building.parentId
              )
            }
            result.building = result.campus?.children?.find(
              (x) => x.id === building.id
            )
            result.floor = result.building?.children?.find(
              (x) => x.id === currentLocation.id
            )
          }
        }
      }

      setSelectedCampus(result.campus)
      setSelectedBuilding(result.building)
      setSelectedFloor(result.floor)
      setLocationSelectionInitialized(true)
    }

    return result
  }, [currentLocation, locations, idn, selectableLocationsStatus])

  useEffect(() => {
    if (locationSelectionInitialized) {
      setCurrentLocation(selectedCampus ?? idn)
    }
  }, [selectedCampus, locationSelectionInitialized])

  useEffect(() => {
    if (locationSelectionInitialized) {
      setCurrentLocation(selectedBuilding ?? selectedCampus ?? idn)
    }
  }, [selectedBuilding, locationSelectionInitialized])

  useEffect(() => {
    if (locationSelectionInitialized) {
      setCurrentLocation(
        selectedFloor ?? selectedBuilding ?? selectedCampus ?? idn
      )
    }
  }, [selectedFloor, locationSelectionInitialized])

  return (
    <>
      {Object.keys(pastReleases).length > 0 && !systemDown && (
        <RecentUpdateModal
          pastReleases={pastReleases}
          applicationTypeId={applicationTypeId}
        />
      )}
      <AppBar
        color='secondary'
        style={{ maxHeight: headerMaxHeight, zIndex: 1100 }}
        position='relative'
      >
        <Toolbar variant='dense' style={getToolbarStyle()}>
          <a
            style={{ cursor: 'pointer', marginRight: '8px' }}
            onClick={handleLogoClick}
          >
            <img src={midmarkLogo} width='72' alt='logo' />
          </a>
          {getToolbarSpacer()}
          {currentCustomerId &&
            locationSelectionInitialized &&
            locationHeaderVisible && (
              <LocationSelectBreadcrumbs
                isIDNRestrictedHierarchy={isIDNRestrictedHierarchy}
                idn={idn}
                selectedCampus={
                  isIDNRestrictedHierarchy
                    ? undefined
                    : locationSelectionResult.campus
                }
                selectedBuilding={
                  isIDNRestrictedHierarchy
                    ? undefined
                    : locationSelectionResult.building
                }
                selectedFloor={
                  isIDNRestrictedHierarchy
                    ? undefined
                    : locationSelectionResult.floor
                }
                campuses={idn?.children}
                onCampusChange={onCampusChange}
                onBuildingChange={onBuildingChange}
                onFloorChange={onFloorChange}
                isDeselectable={isDeselectable}
              />
            )}
          <div style={{ flexGrow: 1 }} />
          {systemDown === true &&
            headerItems.map((headerItem, i) => (
              <div key={`headerItem-${i}`} data-testid={`headerItem-${i}`}>
                {headerItem}
              </div>
            ))}
          <Grid
            container
            direction='row'
            justifyContent='flex-end'
            alignItems='center'
            spacing={2}
          >
            <Grid item>
              {systemDown === false &&
                headerItems.map((headerItem, i) => (
                  <div key={`headerItem-${i}`} data-testid={`headerItem-${i}`}>
                    {headerItem}
                  </div>
                ))}
            </Grid>
            <Grid item>
              <UserMenu
                userProfilePage={userProfilePage}
                profileDisabled={!currentCustomerId}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  )
}

export default Header
