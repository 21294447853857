import { Skeleton, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { putUserPreferencesAction } from '../../actions/userPreferences'
import {
  useAuth,
  useFetchCurrentUser,
  useFetchLocations,
  useFetchRoles,
  useFetchStaffByLocation,
  useFetchUnits,
  useFetchUserPreferences,
} from '../../hooks'
import { FetchingStatus, SignalStatus, UserPreferences } from '../../models'
import { selectCurrentUserLocation } from '../../selectors'
import UserCurrentLocationCard from './UserCurrentLocationCard'
import UserPreferencesCard from './UserPreferencesCard'
import UserProfilePersonalInfoCard from './UserProfilePersonalInfoCard'

const useStyles = makeStyles(() =>
  createStyles({
    viewContainer: {
      margin: '60px',
    },
    profileContainer: {
      margin: '20px 0',
      display: 'flex',
      flexDirection: 'column',
      gap: '40px',
    },
    profileTitle: {
      fontFamily: 'Ubuntu',
      fontSize: '30px',
      textAlign: 'left',
      color: '#000',
    },
    rowFlexContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: '40px',
      flexWrap: 'wrap',
    },
  })
)

const UserProfileView: React.FC = () => {
  const { user } = useAuth()
  const { data: userInfo, status: userFetchStatus } = useFetchCurrentUser(
    user.id
  )
  const { data: userPreferences } = useFetchUserPreferences()
  const { data: locations, status: locationStatus } = useFetchLocations()
  const { data: units, status: unitStatus } = useFetchUnits()
  const { data: roles } = useFetchRoles()
  const { staffs } = useFetchStaffByLocation(SignalStatus.TimedOut)
  const currentUserLocation = useSelector(selectCurrentUserLocation)

  const [title, setTitle] = useState<string>('')

  const classes = useStyles()
  const dispatch = useDispatch()

  const handleUpdateLocationPreference = (
    newLocationId: string | undefined
  ): void => {
    const newUserPreferences: UserPreferences = {
      locationId: newLocationId,
      unitId: userPreferences.unitId,
      timeDisplayId: userPreferences.timeDisplayId,
    }
    dispatch(putUserPreferencesAction.request(newUserPreferences))
  }

  const handleUpdateUnitPreference = (newUnitId: string | undefined): void => {
    const newUserPreferences: UserPreferences = {
      locationId: userPreferences.locationId,
      unitId: newUnitId,
      timeDisplayId: userPreferences.timeDisplayId,
    }
    dispatch(putUserPreferencesAction.request(newUserPreferences))
  }

  const handleUpdateTimeDisplayPreference = (
    newTimeDisplayId: number
  ): void => {
    const newUserPreferences: UserPreferences = {
      locationId: userPreferences.locationId,
      unitId: userPreferences.unitId,
      timeDisplayId: newTimeDisplayId,
    }
    dispatch(putUserPreferencesAction.request(newUserPreferences))
  }

  useEffect(() => {
    if (userInfo) {
      const tempTitle =
        userInfo.firstName +
        ' ' +
        userInfo.lastName +
        (userInfo.lastName.endsWith('s') ? "'" : "'s") +
        ' Profile'
      setTitle(tempTitle)
    }
  }, [userInfo, user])

  const isLoaded =
    userFetchStatus !== FetchingStatus.Request &&
    locationStatus !== FetchingStatus.Request &&
    unitStatus !== FetchingStatus.Request

  return (
    <div className={classes.viewContainer}>
      <Typography className={classes.profileTitle}>{title}</Typography>
      <div className={classes.profileContainer}>
        {isLoaded && userInfo ? (
          <UserProfilePersonalInfoCard
            userInfo={userInfo}
            roles={roles}
            staff={staffs}
          />
        ) : (
          <Skeleton
            variant='rectangular'
            style={{
              marginTop: '60px',
              borderRadius: '4px',
              height: '250px',
              width: '100%',
            }}
          />
        )}
        <div className={classes.rowFlexContainer}>
          {isLoaded ? (
            <UserPreferencesCard
              userPreferences={userPreferences}
              locations={Object.values(locations)}
              units={Object.values(units)}
              handleUpdateLocationPreference={handleUpdateLocationPreference}
              handleUpdateUnitPreference={handleUpdateUnitPreference}
              handleUpdateTimeDisplayPreference={
                handleUpdateTimeDisplayPreference
              }
              style={{ minWidth: 'fit-content' }}
            />
          ) : (
            <Skeleton
              variant='rectangular'
              style={{
                borderRadius: '4px',
                maxWidth: '430px',
                flex: 1,
                height: '400px',
              }}
            />
          )}

          {isLoaded ? (
            <UserCurrentLocationCard
              currentLocation={currentUserLocation}
              style={{ flex: 1 }}
            />
          ) : (
            <Skeleton
              variant='rectangular'
              style={{
                borderRadius: '4px',
                flex: 1,
                height: '250px',
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default UserProfileView
