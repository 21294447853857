import { getType } from 'typesafe-actions'
import { FiltersAction } from '../actions'
import {
  deleteFiltersAction,
  getFiltersAction,
  postFiltersAction,
} from '../actions/filters'
import { CustomerFilters, DeviceFilters, UserFilters } from '../models'
import { FetchingStatus } from '../models/fetchingStatus'
import {
  AssetFilters,
  FilterModel,
  FilterType,
  RealTimeFilters,
  StaffFilters,
  FiltersState,
  Filters,
} from '../models/filters'
import { setFetchingStatus } from '../utils'

const initialState: FiltersState = {
  data: {
    userFilters: [],
    assetFilters: [],
    deviceFilters: [],
    customerFilters: [],
    staffFilters: [],
    realTimeFilters: [],
  },
  status: undefined,
  lastRefreshTime: undefined,
}

//Function for converting FilterModel and Payload to UserFilters[]
const jsonConvertFilters = <
  T extends
    | AssetFilters
    | StaffFilters
    | RealTimeFilters
    | DeviceFilters
    | CustomerFilters
    | UserFilters
>(
  payload: FilterModel[],
  type: FilterType
): T[] => {
  if (payload.length) {
    const data: T[] = payload
      .filter((x) => x.filterTypeId === type)
      .map((x) => {
        const filter: T = JSON.parse(x.filterOptions)
        filter.filterGuid = x.id
        filter.filterName = x.name
        filter.filterType = type

        // In order for the sort order to load from the saved filter, the filterGuid MUST be set
        // on the contained asset and staff filter objects.
        if (type === FilterType.RealTime) {
          if ((filter as RealTimeFilters).assetFilters) {
            ;(filter as RealTimeFilters).assetFilters.filterGuid = x.id
          }

          if ((filter as RealTimeFilters).staffFilters) {
            ;(filter as RealTimeFilters).staffFilters.filterGuid = x.id
          }
        }
        return filter
      })

    return data
  } else {
    return []
  }
}

const insertFilter = <T extends {}>(
  data: Filters,
  actionPayload: FilterModel
): Filters => {
  let filterKey: keyof Filters | null = null

  switch (actionPayload.filterTypeId as FilterType) {
    case FilterType.Asset: {
      filterKey = 'assetFilters'
      break
    }
    case FilterType.Staff: {
      filterKey = 'staffFilters'
      break
    }
    case FilterType.User: {
      filterKey = 'userFilters'
      break
    }
    case FilterType.Device: {
      filterKey = 'deviceFilters'
      break
    }
    case FilterType.RealTime: {
      filterKey = 'realTimeFilters'
      break
    }
  }

  if (!filterKey) {
    return { ...data }
  } else
    return {
      ...data,
      [filterKey]: [
        ...data[filterKey],
        ...jsonConvertFilters<T>([actionPayload], actionPayload.filterTypeId),
      ],
    }
}

const filterReducer = (
  state: FiltersState = initialState,
  action: FiltersAction
): FiltersState => {
  switch (action.type) {
    case getType(getFiltersAction.success):
      return {
        data: {
          userFilters: jsonConvertFilters<UserFilters>(
            action.payload,
            FilterType.User
          ),
          assetFilters: jsonConvertFilters<AssetFilters>(
            action.payload,
            FilterType.Asset
          ),
          staffFilters: jsonConvertFilters<StaffFilters>(
            action.payload,
            FilterType.Staff
          ),
          deviceFilters: jsonConvertFilters<DeviceFilters>(
            action.payload,
            FilterType.Device
          ),
          customerFilters: jsonConvertFilters<CustomerFilters>(
            action.payload,
            FilterType.Device
          ),
          realTimeFilters: jsonConvertFilters<RealTimeFilters>(
            action.payload,
            FilterType.RealTime
          ),
        },
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(deleteFiltersAction.success): {
      return {
        data: {
          userFilters: state.data.userFilters,
          assetFilters: state.data.assetFilters.filter(
            (x) => x.filterGuid !== action.payload.id
          ),
          staffFilters: state.data.staffFilters.filter(
            (x) => x.filterGuid !== action.payload.id
          ),
          realTimeFilters: state.data.realTimeFilters.filter(
            (x) => x.filterGuid !== action.payload.id
          ),
          deviceFilters: state.data.deviceFilters,
          customerFilters: state.data.customerFilters,
        },
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    }
    case getType(postFiltersAction.success): {
      return {
        data: insertFilter(state.data, action.payload),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    }
    case getType(getFiltersAction.request):
    case getType(postFiltersAction.request):
    case getType(deleteFiltersAction.request):
      return setFetchingStatus<Filters>(state, FetchingStatus.Request)
    case getType(getFiltersAction.failure):
    case getType(postFiltersAction.failure):
    case getType(deleteFiltersAction.failure):
      return setFetchingStatus<Filters>(state, FetchingStatus.Failure)
    default:
      return state
  }
}

export default filterReducer
