import { TableSortLabel as MatTableSortLabel } from '@mui/material'

import withStyles from '@mui/styles/withStyles'

export const TableSortLabel = withStyles(
  {
    active: {
      color: '#666666 !important',
    },
  },
  { name: 'AbMatTableSortLabel' }
)(MatTableSortLabel)
