import { getAllMapPresets } from '../../actions/mapPresets'
import { MapPresets, MapPresetsState } from '../../models'
import { EntityResult, useEntities } from '../useEntities'

const useFetchMapPresets = (): EntityResult<MapPresets> =>
  useEntities<MapPresetsState, MapPresets>(
    () => getAllMapPresets.request(),
    (state: any) => state.mapPresets // eslint-disable-line @typescript-eslint/no-explicit-any
  )

export { useFetchMapPresets }
