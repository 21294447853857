import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import MuiDialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import { Grid } from '@mui/material'

interface Props {
  title: string
  onClose: () => void
}

const useStyles = makeStyles({
  titleText: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: '16px',
  },
  timeText: {
    color: '#9e9e9e',
    fontSize: '14px',
    opacity: 0.84,
  },
  closeButton: {
    fontSize: '14px',
    color: '#9e9e9e',
  },
})

const useDialogTitleStyles = makeStyles({
  root: {
    padding: '2px 20px',
    paddingRight: '0',
  },
})

const UpdateModalHeader = (props: Props) => {
  const { title, onClose } = props
  const classes = useStyles()
  const dialogTitleClasses = useDialogTitleStyles()
  return (
    <MuiDialogTitle classes={dialogTitleClasses}>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Grid item>
          <Typography
            variant='h6'
            component='span'
            className={classes.titleText}
          >
            {title}
          </Typography>
        </Grid>
        {onClose ? (
          <Grid item>
            <IconButton
              aria-label='Close'
              className={classes.closeButton}
              onClick={onClose}
              size='large'
            >
              <CloseIcon fontSize='inherit' />
            </IconButton>
          </Grid>
        ) : null}
      </Grid>
    </MuiDialogTitle>
  )
}

export default UpdateModalHeader
