import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getCurrentUserLocationAction,
  updateCurrentUserLocation,
} from '../actions/currentUserLocation'
import {
  setCurrentUserTelemetryEnabledAction,
  setCurrentUserTelemetryMapIdAction,
} from '../actions/currentUserTelemetrySubscription'
import { AgentType, AuthState, FetchingStatus } from '../models'
import { CurrentUserLocation } from '../models/currentUserLocation'
import {
  selectCurrentUserLocation,
  selectCurrentUserLocationFetchingStatus,
} from '../selectors'
import { useFetchGeofencesByLocation } from './entities/useFetchGeofencesByLocation'
import { useFetchLocations } from './entities/useFetchLocations'
import { useCurrentIDN } from './useCurrentIDN'
import { useCurrentUserTelemetry } from './useCurrentUserTelemetry'

const useCurrentUserLocation = (): CurrentUserLocation | undefined => {
  const [userLocationLoaded, setUserLocationLoaded] = useState(false)
  const [userMapIdSet, setUserMapIdSet] = useState(false)
  const [userLocationResolved, setUserLocationResolved] = useState(false)

  const dispatch = useDispatch()
  const status = useSelector(selectCurrentUserLocationFetchingStatus)
  const authState = useSelector(({ auth }: { auth: AuthState }) => auth)
  const currentUserLocation = useSelector(selectCurrentUserLocation)
  const telemetry = useCurrentUserTelemetry()
  const { data: locations, status: locationStatus } = useFetchLocations()
  const idnLocation = useCurrentIDN()
  const { data: locationGeofences, status: locGeofenceStatus } =
    useFetchGeofencesByLocation(idnLocation?.id ?? '')

  //Set MapId after Initial Load
  useEffect(() => {
    if (authState.user.id && userLocationLoaded && !userMapIdSet) {
      if (
        status === FetchingStatus.Success &&
        currentUserLocation?.badgeTelemetry?.mapId
      ) {
        dispatch(setCurrentUserTelemetryEnabledAction(true))
        dispatch(
          setCurrentUserTelemetryMapIdAction(
            currentUserLocation.badgeTelemetry.mapId
          )
        )
        setUserMapIdSet(true)
      }
    }
  }, [
    status,
    authState.user,
    currentUserLocation,
    dispatch,
    userLocationLoaded,
    userMapIdSet,
  ])

  //Initial Telemetry Load
  useEffect(
    () => {
      if (authState.currentCustomerId) {
        if (
          authState.user.id &&
          currentUserLocation === undefined &&
          !userLocationLoaded
        ) {
          dispatch(getCurrentUserLocationAction.request(authState.user.id))
          setUserLocationLoaded(true)
        }
      } else {
        dispatch(setCurrentUserTelemetryEnabledAction(false))
      }
    },
    // eslint-disable-next-line
    [currentUserLocation, authState.user, authState.currentCustomerId, dispatch]
  )

  //Initial Resolve from API
  useEffect(() => {
    if (
      userLocationLoaded &&
      !userLocationResolved &&
      locationStatus === FetchingStatus.Success &&
      locGeofenceStatus === FetchingStatus.Success &&
      Object.values(locations).length &&
      Object.values(locationGeofences).length &&
      currentUserLocation?.badgeTelemetry &&
      currentUserLocation?.geoFenceId !==
        currentUserLocation?.badgeTelemetry?.geoFenceId
    ) {
      const tmpLocationId = Object.values(locationGeofences).find(
        (x) => x.geofenceId == currentUserLocation?.badgeTelemetry?.geoFenceId
      )?.locationId
      const tmpLocation = Object.values(locations).find(
        (x) => x.id === tmpLocationId
      )
      if (tmpLocation) {
        const tmpFloor = Object.values(locations).find(
          (x) => x.id === tmpLocation.parentId
        )

        const tmpBuildingLocation = Object.values(locations).find(
          (x) => x.id === tmpFloor?.parentId
        )

        const tmpBuildingGroupLocation = Object.values(locations).find(
          (x) => x.id === tmpBuildingLocation?.parentId
        )

        dispatch(
          updateCurrentUserLocation({
            ...currentUserLocation,
            room: tmpLocation?.name,
            roomId: tmpLocation?.id,
            floor: tmpFloor?.name,
            floorId: tmpFloor?.id,
            building: tmpBuildingLocation?.name,
            buildingId: tmpBuildingLocation?.id,
            buildingGroup: tmpBuildingGroupLocation?.name,
            buildingGroupId: tmpBuildingGroupLocation?.id,
            unitName: tmpLocation.unitName,
            unitId: tmpLocation.unitId,
            subUnitName: tmpLocation.subUnitName,
            subUnitId: tmpLocation.subUnitId,
            badgeTelemetry: {
              geoFenceId: currentUserLocation?.badgeTelemetry?.geoFenceId,
              mapId: currentUserLocation?.badgeTelemetry?.mapId,
              lat: currentUserLocation?.badgeTelemetry?.lat,
              lon: currentUserLocation?.badgeTelemetry?.lon,
              locationUncertainty:
                currentUserLocation?.badgeTelemetry?.locationUncertainty,
              customerId: currentUserLocation?.badgeTelemetry?.customerId,
              trackingId: currentUserLocation?.badgeTelemetry?.trackingId,
              sensorId: currentUserLocation?.badgeTelemetry?.sensorId,
              signalTypeId: currentUserLocation?.badgeTelemetry?.signalTypeId,
              timestamp: currentUserLocation?.badgeTelemetry?.timestamp,
              agentType: AgentType.Staff,
            },
          })
        )
        setUserLocationResolved(true)
      }
    }
  }, [
    currentUserLocation,
    dispatch,
    locGeofenceStatus,
    locationGeofences,
    locationStatus,
    locations,
    userLocationLoaded,
    userLocationResolved,
  ])

  //Updates from Live Telemetry
  useEffect(() => {
    if (
      telemetry &&
      currentUserLocation &&
      userLocationLoaded &&
      locationStatus === FetchingStatus.Success &&
      locGeofenceStatus === FetchingStatus.Success &&
      Object.values(locations).length &&
      Object.values(locationGeofences).length
    ) {
      //If Telemetry contains location info
      if (telemetry.location) {
        if (telemetry?.location?.parent?.parent?.parentId) {
          const tmpBuildingGoupLocation = Object.values(locations).find(
            (x) => x.id === telemetry?.location?.parent?.parent?.parentId
          )
          dispatch(
            updateCurrentUserLocation({
              ...currentUserLocation,
              location: telemetry.location,
              room: telemetry?.location?.name,
              roomId: telemetry?.location.id,
              floor: telemetry?.location?.parent?.name,
              floorId: telemetry?.location?.parent?.id,
              building: telemetry?.location?.parent?.parent?.name,
              buildingId: telemetry?.location?.parent?.parent?.id,
              buildingGroup: tmpBuildingGoupLocation?.name,
              buildingGroupId: tmpBuildingGoupLocation?.name,
              unitId: telemetry?.location.unitId,
              unitName: telemetry?.location.unitName,
              subUnitName: telemetry?.location.subUnitName,
              subUnitId: telemetry?.location.subUnitId,
              geoFenceId: telemetry.geoFenceId,
              badgeTelemetry: {
                geoFenceId: telemetry.geoFenceId,
                mapId: telemetry.mapId,
                lat: telemetry.lat,
                lon: telemetry.lon,
                locationUncertainty: telemetry.locationUncertainty,
                customerId: telemetry.customerId,
                trackingId: telemetry.trackingId,
                sensorId: telemetry.sensorId,
                signalTypeId: telemetry.signalTypeId,
                timestamp: telemetry.timestamp,
                agentType: AgentType.Staff,
              },
            })
          )
        }
      }
      //Use Badge Telemetry as fallback
      else {
        if (telemetry.geoFenceId) {
          const tmpLocationId = Object.values(locationGeofences).find(
            (x) => x.geofenceId == telemetry.geoFenceId
          )?.locationId
          const tmpLocation = Object.values(locations).find(
            (x) => x.id === tmpLocationId
          )
          if (tmpLocation) {
            const tmpFloor = Object.values(locations).find(
              (x) => x.id === tmpLocation.parentId
            )

            const tmpBuildingLocation = Object.values(locations).find(
              (x) => x.id === tmpFloor?.parentId
            )

            const tmpBuildingGroupLocation = Object.values(locations).find(
              (x) => x.id === tmpBuildingLocation?.parentId
            )

            dispatch(
              updateCurrentUserLocation({
                ...currentUserLocation,
                location: telemetry.location,
                room: tmpLocation?.name,
                roomId: tmpLocation?.id,
                floor: tmpFloor?.name,
                floorId: tmpFloor?.id,
                building: tmpBuildingLocation?.name,
                buildingId: tmpBuildingLocation?.id,
                buildingGroup: tmpBuildingGroupLocation?.name,
                buildingGroupId: tmpBuildingGroupLocation?.id,
                unitName: tmpLocation.unitName,
                unitId: tmpLocation.unitId,
                subUnitName: tmpLocation.subUnitName,
                subUnitId: tmpLocation.subUnitId,
                geoFenceId: telemetry.geoFenceId,
                badgeTelemetry: {
                  geoFenceId: telemetry.geoFenceId,
                  mapId: telemetry.mapId,
                  lat: telemetry.lat,
                  lon: telemetry.lon,
                  locationUncertainty: telemetry.locationUncertainty,
                  customerId: telemetry.customerId,
                  trackingId: telemetry.trackingId,
                  sensorId: telemetry.sensorId,
                  signalTypeId: telemetry.signalTypeId,
                  timestamp: telemetry.timestamp,
                  agentType: AgentType.Staff,
                },
              })
            )
          }
        }
      }
    }
  }, [
    currentUserLocation,
    telemetry,
    dispatch,
    userLocationLoaded,
    locationStatus,
    locGeofenceStatus,
    locations,
    locationGeofences,
    userLocationResolved,
  ])

  return currentUserLocation
}

export { useCurrentUserLocation }
