import { createAsyncAction } from 'typesafe-actions'
import {
  JibestreamDestination,
  JibestreamDestinations,
} from '../models/destination'

export const getDestinationsByVenueAction = createAsyncAction(
  'GET_DESTINATIONS_BY_VENUE_REQUEST',
  'GET_DESTINATIONS_BY_VENUE_SUCCESS',
  'GET_DESTINATIONS_BY_VENUE_FAILURE'
)<number, JibestreamDestinations, Error>()

export const updateDestinationAction = createAsyncAction(
  'UPDATE_DESTINATION_REQUEST',
  'UPDATE_DESTINATION_SUCCESS',
  'UPDATE_DESTINATION_FAILURE'
)<JibestreamDestination, JibestreamDestination, Error>()
