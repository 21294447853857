import { ChannelSound } from '../models'
import { BaseService } from './baseService'

export class ChannelSoundsApi extends BaseService {
  constructor() {
    super({ entityType: 'channelSounds' })
  }
  route = 'channel/sounds'

  async getAllChannelSounds(): Promise<ChannelSound[]> {
    return await this.get<ChannelSound[]>(this.route)
  }
}
