import { createAssets, deleteAsset, renderCurrentMapView } from '.'
import {
  Assets,
  Geofences,
  InpixonAssetRef,
  MapGroups,
  MapUpdate,
  MapUpdateType,
  StaffMembers,
  Locations,
  LocationGeofences,
  FeatureFlags,
  StaffEvents,
  MapOutline,
  AgentTypePlural,
  MapOutlineState,
} from '../../models'
import { inpixonAssetIsOfType } from './assetType'
import { deleteAssets, hideAsset } from './assetDelete'
import { Dispatch } from 'react'
import {
  updateAssetIcons,
  updateAssetPositions,
  updateAssets,
  updateAssetsHide,
  updateAssetShowAccuracy,
  updateAssetsShow,
  updateAssetVisibilitys,
} from './assetUpdate'
import { processAgentUpdates, agentUpdates } from './mapProcessAgent'
import { groupUpdates, processGeoFenceUpdates } from './mapProcessGroups'

export function trackingIdToAssetKitId(
  trackingIds: string[],
  inpixonReference: InpixonAssetRef[]
) {
  return trackingIds.reduce((acc: number[], next: any) => {
    const ref = inpixonReference.find((x: any) =>
      x.trackingIds?.includes(next)
    )?.assetKitId

    if (ref) {
      return [...acc, ref]
    }

    return acc
  }, [])
}

export function removeFilteredTrackingIds(
  trackingIds: string[],
  assetKit: any,
  inpixonReference: InpixonAssetRef[],
  agentTypes?: string[]
) {
  const assetKitIds = trackingIdToAssetKitId(trackingIds, inpixonReference)

  // delete all assets that are filtered
  assetKit
    ._getAllAssets()
    .filter(
      (x: any) =>
        inpixonAssetIsOfType(x, 'Asset') && !assetKitIds.includes(x.id)
    )
    .map((x: any) => {
      hideAsset(assetKit, Number(x.id))
    })
  assetKit
    ._getAllAssets()
    .filter(
      (x: any) =>
        inpixonAssetIsOfType(x, 'Staff') && !assetKitIds.includes(x.id)
    )
    .map((x: any) => {
      hideAsset(assetKit, Number(x.id))
    })

  if (!agentTypes?.includes(AgentTypePlural[AgentTypePlural.Assets])) {
    assetKit
      ._getAllAssets()
      .filter((x: any) => inpixonAssetIsOfType(x, 'Asset'))
      .map((x: any) => {
        hideAsset(assetKit, Number(x.id))
      })
  }
  if (!agentTypes?.includes(AgentTypePlural[AgentTypePlural.Staff])) {
    assetKit
      ._getAllAssets()
      .filter((x: any) => inpixonAssetIsOfType(x, 'Staff'))
      .map((x: any) => {
        hideAsset(assetKit, Number(x.id))
      })
  }
}

function removeHiddenAssets(
  trackingIds: string[],
  inpixonReference: InpixonAssetRef[],
  assetKit: any
) {
  return trackingIds.forEach((trackingId: string) => {
    const assetKitId = inpixonReference.find((x: any) =>
      x.trackingIds?.includes(trackingId)
    )?.assetKitId
    if (assetKitId) {
      hideAsset(assetKit, assetKitId)
    }
  })
}

function executeAgentUpdates(
  agentUpdateData: agentUpdates | undefined,
  assetKit: any,
  agentTypes: string[] | undefined,
  jibestream: any,
  handleDeleteDesiredGeofenceMapState: (badgeId: string) => void,
  handleUpdateDesiredGeofenceMapState: (mapOutline: MapOutline) => void
) {
  if (agentUpdateData?.agentsToCreate.length) {
    createAssets(assetKit, agentUpdateData.agentsToCreate)
  }

  if (agentUpdateData?.agentsToUpdate.length) {
    updateAssets(assetKit, agentUpdateData.agentsToUpdate)
  }

  if (agentUpdateData?.agentPositionsToUpdate.length) {
    const assetShowUpdate: string[] = []
    agentUpdateData.agentPositionsToUpdate.map((update) => {
      if (agentTypes?.includes(AgentTypePlural[AgentTypePlural.Assets])) {
        assetShowUpdate.push(`Asset_${update.id}`)
      }
      if (agentTypes?.includes(AgentTypePlural[AgentTypePlural.Staff])) {
        assetShowUpdate.push(`Staff_${update.id}`)
      }
    })
    updateAssetsShow(assetKit, assetShowUpdate)
    updateAssetPositions(
      jibestream.control,
      assetKit,
      agentUpdateData.agentPositionsToUpdate
    )
  }

  if (agentUpdateData?.agentsToHideShow.length) {
    updateAssetVisibilitys(assetKit, agentUpdateData.agentsToHideShow)
  }

  if (agentUpdateData?.agentsToHandleDesiredGeofenceDelete.length) {
    agentUpdateData.agentsToHandleDesiredGeofenceDelete.map((x) => {
      handleDeleteDesiredGeofenceMapState(x)
    })
  }

  if (agentUpdateData?.agentsToHandleDesiredGeofenceUpdate.length) {
    agentUpdateData.agentsToHandleDesiredGeofenceUpdate.map((x) => {
      handleUpdateDesiredGeofenceMapState(x)
    })
  }

  if (agentUpdateData?.agentsToDelete.length) {
    deleteAssets(assetKit, agentUpdateData.agentsToDelete)
  }
}

function executeGroupUpdates(
  groupUpdateData: groupUpdates | undefined,
  assetKit: any,
  mapUpdates: MapUpdate[]
) {
  if (groupUpdateData?.groupsToCreate.length) {
    createAssets(assetKit, groupUpdateData.groupsToCreate)
  }

  if (groupUpdateData?.agentsToShow.length) {
    groupUpdateData.agentsToShow.map((x) => {
      assetKit.showAssetsOfType(x)
    })
  }

  if (groupUpdateData?.agentsToHide.length) {
    const updates: number[] = []
    groupUpdateData.agentsToHide.map((x) => {
      x.trackingIds.map((trackingId: string) => {
        const assetKitId = x.inpixonReference.find((x: any) =>
          x.trackingIds?.includes(trackingId)
        )?.assetKitId
        if (assetKitId) {
          updates.push(assetKitId)
        }
      })
    })
    updateAssetsHide(assetKit, updates)
  }

  if (groupUpdateData?.groupIconsToUpdate.length) {
    updateAssetIcons(assetKit, groupUpdateData.groupIconsToUpdate)
  }

  if (groupUpdateData?.agentsToDelete.length) {
    deleteAssets(assetKit, groupUpdateData.agentsToDelete)
  }

  if (groupUpdateData?.agentsToShowAccuracy.length) {
    groupUpdateData.agentsToShowAccuracy.map((x) => {
      updateAssetShowAccuracy(assetKit, x.assetId, x.signalTypeIds ?? [])
    })
  }

  if (mapUpdates.filter((x) => x.isRefresh).length) {
    deleteAsset(
      assetKit,
      assetKit
        ._getAllAssets()
        .filter(
          (x: any) =>
            inpixonAssetIsOfType(x, 'Group') &&
            !mapUpdates.filter((g) => g.geoFenceId === x.id).length
        )
    )
  }
}

export function processUpdates(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jibestream: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  assetKit: any,
  mapId: number,
  mapUpdates: MapUpdate[],
  inpixonReference: InpixonAssetRef[],
  currentAgentReference: number,
  selectedGroupId: number | undefined,
  selectedBadgeId: string,
  existingMapGroups: MapGroups,
  setSelectedGroupId: (groupId: number | undefined) => void,
  handleGroupStateUpdate: (groups: MapGroups) => void,
  dispatch: Dispatch<any>,
  currentGeofenceMapState: MapOutlineState,
  desiredGeofenceMapState: MapOutlineState,
  handleUpdateDesiredGeofenceMapState: (mapOutline: MapOutline) => void,
  handleDeleteDesiredGeofenceMapState: (badgeId: string) => void,
  handleClearDesiredGeofenceMapState: () => void,
  geofences?: Geofences,
  locations?: Locations,
  locationGeofences?: LocationGeofences,
  assets?: Assets,
  staff?: StaffMembers,
  featureFlags?: FeatureFlags,
  duressNotifications?: StaffEvents,
  assistNotifications?: StaffEvents,
  agentTypes?: string[]
): void {
  const agentUpdates = mapUpdates.filter(
    (t) => t.updateType === MapUpdateType.Agent
  )
  const geoFenceUpdates = mapUpdates.filter(
    (t) => t.updateType === MapUpdateType.GeoFence
  )

  let privateGeofenceIds: number[] = []
  if (locationGeofences && locations) {
    privateGeofenceIds = Object.values(locationGeofences)
      .filter((lg) => locations[lg.locationId].isPrivate)
      .map((lg) => lg.geofenceId)
  }

  let agentUpdateData: agentUpdates | undefined = undefined
  let groupUpdateData: groupUpdates | undefined = undefined

  if (geoFenceUpdates?.length && geofences) {
    // process updates to grouped geoFences
    groupUpdateData = processGeoFenceUpdates(
      jibestream,
      assetKit,
      privateGeofenceIds,
      inpixonReference,
      geoFenceUpdates,
      selectedGroupId,
      selectedBadgeId,
      geofences,
      existingMapGroups,
      setSelectedGroupId,
      mapId,
      assets,
      staff,
      featureFlags,
      duressNotifications,
      assistNotifications,
      agentTypes
    )
  }

  if (agentUpdates?.length) {
    if (
      groupUpdateData?.stateGroupsToUpdate &&
      Object.keys(groupUpdateData?.stateGroupsToUpdate).length > 0
    ) {
      Object.keys(groupUpdateData.stateGroupsToUpdate).forEach((groupId) => {
        if (groupUpdateData?.stateGroupsToUpdate[Number(groupId)]) {
          existingMapGroups[Number(groupId)] =
            groupUpdateData?.stateGroupsToUpdate[Number(groupId)]
        }
      })
    }
    agentUpdateData = processAgentUpdates(
      jibestream,
      assetKit,
      privateGeofenceIds,
      mapId,
      dispatch,
      currentGeofenceMapState,
      desiredGeofenceMapState,
      handleClearDesiredGeofenceMapState,
      selectedBadgeId,
      existingMapGroups,
      agentUpdates,
      inpixonReference,
      currentAgentReference,
      assets,
      staff,
      featureFlags,
      duressNotifications,
      assistNotifications,
      agentTypes
    )
  }

  executeAgentUpdates(
    agentUpdateData,
    assetKit,
    agentTypes,
    jibestream,
    handleDeleteDesiredGeofenceMapState,
    handleUpdateDesiredGeofenceMapState
  )

  executeGroupUpdates(groupUpdateData, assetKit, mapUpdates)

  if (groupUpdateData?.stateGroupsToUpdate) {
    handleGroupStateUpdate(groupUpdateData.stateGroupsToUpdate)
  }

  renderCurrentMapView(jibestream)
}
