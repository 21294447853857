import React from 'react'
import List from '@mui/material/List'
import DetailsListItem from './DetailsListItem'
import ActionListItem from './ActionListItem'
import { Divider, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { DetailedItem, Item } from '../../models/DetailPaneItems'

interface Props {
  detailedItems?: DetailedItem[]
  items?: Item[]
  narrowRows?: boolean
  headerDivider?: boolean
  multiLine?: boolean
  headerStylesOverrides?: any
  listItemStylesOverrides?: any
  hideHeader?: boolean
  isLoading?: boolean
}

const useStyles = makeStyles((theme) =>
  createStyles({
    chip: {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    list: {
      width: '100%',
    },
    row: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'start',
    },
    item: (props) => ({
      paddingTop: 14,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      ...props.listItemStylesOverrides,
    }),
    multiLineItem: {
      paddingTop: 6,
      paddingBottom: 0,
    },
    field: {
      marginRight: 24,
      flexGrow: 0,
    },
    detail: {
      color: '#979797',
      whiteSpace: 'pre',
      flexGrow: 1,
    },
    sectionHeader: (props: Props) => ({
      fontFamily: 'Ubuntu',
      fontSize: 14,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'left',
      color: '#666666',
      ...props.headerStylesOverrides,
    }),
    container: { marginBottom: 40 },
    text: {
      lineHeight: 1,
    },
    gridItemRight: {
      display: 'flex',
    },
    chipChevron: {
      paddingRight: theme.spacing(2),
    },
    chevronContainer: {
      display: 'flex',
      alignItems: 'center',
    },
  })
)
const DetailsList = (props: Props): JSX.Element => {
  const {
    items,
    hideHeader,
    detailedItems,
    narrowRows,
    headerDivider,
    multiLine,
    isLoading = false,
  } = props
  const classes = useStyles(props)

  return (
    <div>
      {detailedItems?.map((x, index) => (
        <div
          className={classes.container}
          key={`DetailsListItemContainer-${index}`}
        >
          {!hideHeader && (
            <Typography className={classes.sectionHeader}>
              {x.header}
            </Typography>
          )}
          <List disablePadding>
            {x.items.map(({ field, value = '', action, chips, divider }, i) =>
              Boolean(action) ? (
                <ActionListItem
                  key={`DetailsListItem-${i}`}
                  field={field}
                  value={value}
                  action={action}
                  chips={chips}
                  classes={classes}
                />
              ) : (
                <DetailsListItem
                  key={`DetailsListItem-${i}`}
                  field={field}
                  value={value}
                  classes={classes}
                  divider={divider ?? false}
                  narrowRows={narrowRows}
                  multiLine={multiLine}
                  isLoading={isLoading}
                />
              )
            )}
          </List>
        </div>
      ))}
      {items?.length && (
        <List disablePadding>
          {(headerDivider === undefined ? true : headerDivider) && <Divider />}
          {items.map(({ field, value = '', action, chips, divider }, i) =>
            Boolean(action) ? (
              <ActionListItem
                key={`DetailsListItem-${i}`}
                field={field}
                value={value}
                action={action}
                chips={chips}
                classes={classes}
              />
            ) : (
              <DetailsListItem
                key={`DetailsListItem-${i}`}
                field={field}
                value={value}
                classes={classes}
                divider={divider ?? true}
                multiLine={multiLine}
                isLoading={isLoading}
              />
            )
          )}
        </List>
      )}
    </div>
  )
}

export default DetailsList
