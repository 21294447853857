import { useMemo } from 'react'
import { useAuth, useFetchWatchlist, useGlobalFilterState } from '..'
import { setGlobalStaffFiltersAction } from '../../actions/globalFilters'
import { createQuery, whereValue } from '../../filters'
import { badgeSignalAccuracy, badgeSignalStatus } from '../../helpers'
import { batteryStatus, isLowBattery } from '../../helpers/battery'
import { isDateMatch, isInList } from '../../helpers/filters'
import { SignalType, Staff } from '../../models'
import { StaffFiltersProvider } from '../../models/filters'

export const useStaffFilters = (): StaffFiltersProvider => {
  const {
    filterState: filters,
    setFilterState: setFilters,
    updateFilterState: mergeFilters,
    clearFilterState: clearFilters,
  } = useGlobalFilterState(
    (globalFilters) => globalFilters.staff,
    setGlobalStaffFiltersAction
  )

  const { user } = useAuth()
  const { data: watchlist } = useFetchWatchlist(user.id)

  const filter = useMemo(() => {
    const filter = createQuery<Staff>([
      whereValue((staff) => Boolean(staff.badgeIds?.length), filters.hasBadge),
      whereValue(
        (staff) => isLowBattery(staff.isLowBattery),
        filters.isLowBattery
      ),
      whereValue(
        (staff) =>
          Object.values(watchlist).some(
            (item) => item.agentId === staff.agentGuid
          ),
        filters.isWatchlist
      ),
      whereValue(
        (staff) =>
          isDateMatch(
            filters?.tagBatteryChangeDate,
            staff?.tagBatteryChangeDate
          ),
        filters.tagBatteryChangeDate
      ),
      whereValue(
        (staff) =>
          isInList(
            batteryStatus(staff.isLowBattery),
            filters?.batteryStatus,
            (item) => item.name
          ),
        filters.batteryStatus
      ),
      whereValue(
        (staff) =>
          isInList(
            badgeSignalAccuracy(staff.badgeTelemetry?.signalTypeId),
            filters?.signalAccuracy,
            (item) => item.name
          ),
        filters.signalAccuracy
      ),
      whereValue(
        (staff) =>
          isInList(
            badgeSignalStatus(staff.badgeTelemetry?.timestamp),
            filters?.signalStatus,
            (item) => item.name
          ),
        filters.signalStatus
      ),
      whereValue(
        (staff) =>
          isInList(
            SignalType[staff.badgeTelemetry?.signalTypeId],
            filters?.signalType,
            (item) => item.name
          ),
        filters.signalType
      ),
      whereValue(
        (staff) =>
          isInList(
            staff.staffTypeId,
            filters?.staffTypes,
            (item) => item.staffTypeId
          ),
        filters.staffTypes
      ),
      whereValue(
        (staff) =>
          isInList(staff.unitId, filters?.assignedTo, (item) => item.id),
        filters.assignedTo
      ),
      whereValue(
        (staff) =>
          isInList(staff.managerId, filters?.reportsTo, (item) => item.key),
        filters.reportsTo
      ),
    ])
    return filter
  }, [filters, watchlist])

  return {
    filterState: filters,
    setFilterState: setFilters,
    updateFilterState: mergeFilters,
    clearFilterState: clearFilters,
    filter,
    filterCount: filter.filterCount,
  }
}
