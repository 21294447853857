import { Cell, CellContext, flexRender } from '@tanstack/react-table'
import React, { ReactElement, ReactNode } from 'react'
import { isEmpty } from '..'
import {
  DateFormatter,
  formatLocalDateTimeByUserPreference,
  localMomentFormatted,
  momentUTCFormatted,
} from '../date'

// eslint-disable-next-line @typescript-eslint/ban-types
export type CellRenderer = () => JSX.Element | string

export const EmptyCell: ReactElement = <div style={{ fontSize: 18 }}>-</div>

/**
 * Calls cell.render('Cell') if cell isnt empty:
 * This calls custom renders if they're specified otherwise renders content as normal
 * @param cell react-table cell
 * @param emptyReplacement content to be inserted if cell is empty
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const renderCellContent = <T extends object, V = unknown>(
  cell: Cell<T, V>,
  emptyReplacement: string | React.ReactElement = EmptyCell
): string | ReactNode | JSX.Element =>
  isEmpty(cell.getValue())
    ? emptyReplacement
    : flexRender(cell.column.columnDef.cell, cell.getContext())

/**
 * Render a cell with a custom renderer
 * @param renderer custom renderer
 * @param renderEmptyCell some custom renderers such as SVG's have empty bodies so we dont want to render a '-'
 */
export const renderCell = (
  renderer: CellRenderer,
  renderEmptyCell = true
): ReactNode | string => {
  if (renderEmptyCell) {
    return EmptyCell
  }
  return renderer()
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const renderSvgIcon = <T extends object>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cell: CellContext<T, any>
): ReactNode | string => {
  const rerender = () => {
    return (
      <div
        style={{
          height: cell.column.getSize(),
          width: cell.column.getSize(),
        }}
        dangerouslySetInnerHTML={{ __html: String(cell.getValue()) }}
      />
    )
  }
  return renderCell(rerender, false)
}

/**
 * Render date with given format.  Defaults to MM/DD/YYYY
 * @param format date format
 * @param defaultValue default value if date is null or undefined
 */
export const renderLocalDate = (
  date: Date | undefined,
  format: string | DateFormatter = 'MM/DD/YYYY',
  defaultValue?: string
): ReactNode | string => {
  const rerender = () => {
    return date ? localMomentFormatted(date, format) : defaultValue ?? ''
  }
  return renderCell(rerender, false)
}

/**
 * Render date with user's preference of format.  Defaults to MM/DD/YYYY
 * @param format date format
 * @param defaultValue default value if date is null or undefined
 */
export const renderUserPreferredDateTime = (
  date: Date | undefined,
  timeDisplayId: number
): ReactNode | string => {
  const rerender = () => {
    return date ? formatLocalDateTimeByUserPreference(date, timeDisplayId) : ''
  }
  return renderCell(rerender, false)
}

/**
 * Render date with given format as UTC.  Defaults to MM/DD/YYYY
 * @param format date format
 * @param defaultValue default value if date is null or undefined
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const renderUTCDate = (
  date: Date | undefined,
  format: string | DateFormatter = 'MM/DD/YYYY',
  defaultValue?: string
): ReactNode | string => {
  const rerender = () => {
    return date ? momentUTCFormatted(date, format) : defaultValue ?? ''
  }
  return renderCell(rerender, false)
}
