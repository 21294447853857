import React from 'react'
import {
  HomeOutlined,
  PeopleOutlined,
  SettingsOutlined,
  PublicOutlined,
  LayersOutlined,
  MemoryOutlined,
  DeviceHubOutlined,
  DeveloperBoardOutlined,
} from '@mui/icons-material'
import { PageGroup, PageRoute } from '@midmarkrtls/common/interfaces/modules'
import { getAssociatedRoute } from '@midmarkrtls/common/helpers/routing'
import pageRoutes, { RouteKeys } from 'routes'
import { PermissionsEnum } from '@midmarkrtls/common/models'
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined'
import { ReactComponent as LayersOutlinedAdmin } from './assets/layers_admin_24px.svg'
import icons from '@midmarkrtls/common/models/icons'
import CustomSVGIcon from '@midmarkrtls/common/components/Icons/CustomSVGIcon'
import { CommonRouteKeys } from '@midmarkrtls/common/constants'

export const pageGroups: PageGroup[] = [
  {
    key: CommonRouteKeys.DashboardPage.key,
    name: 'Dashboard',
    icon: <HomeOutlined />,
    link: '',
    pages: [],
    get associatedRoute(): PageRoute | undefined {
      return getAssociatedRoute(CommonRouteKeys.DashboardPage.key, pageRoutes)
    },
  },
  {
    key: CommonRouteKeys.RealTimePage.key,
    name: 'Real-Time',
    link: 'realtime',
    icon: <LayersOutlined />,
    pages: [],
    permissions: [PermissionsEnum.ListRead, PermissionsEnum.ListWrite],
    get associatedRoute(): PageRoute | undefined {
      return getAssociatedRoute(CommonRouteKeys.RealTimePage.key, pageRoutes)
    },
  },
  {
    key: 'administration',
    name: 'Administration',
    icon: <SettingsOutlined />,
    pages: [
      {
        key: CommonRouteKeys.AssetManagementPage.key,
        name: 'Assets',
        link: 'admin/assets',
        icon: <LayersOutlinedAdmin />,
        permissions: [
          PermissionsEnum.AssetsAdminRead,
          PermissionsEnum.AssetsAdminWrite,
        ],
        get associatedRoute(): PageRoute | undefined {
          return getAssociatedRoute(
            CommonRouteKeys.AssetManagementPage.key,
            pageRoutes
          )
        },
      },
      {
        key: CommonRouteKeys.StaffManagementPage.key,
        name: 'Staff',
        link: 'admin/staff',
        icon: <CustomSVGIcon svgText={icons.staffAdminIcon} />,
        permissions: [
          PermissionsEnum.StaffAdminRead,
          PermissionsEnum.StaffAdminWrite,
        ],
        get associatedRoute(): PageRoute | undefined {
          return getAssociatedRoute(
            CommonRouteKeys.StaffManagementPage.key,
            pageRoutes
          )
        },
      },
      {
        key: CommonRouteKeys.BadgePage.key,
        name: 'Badges',
        link: 'admin/badges',
        icon: <CustomSVGIcon svgText={icons.badgeIcon} />,
        permissions: [PermissionsEnum.BadgesRead, PermissionsEnum.BadgesWrite],
        get associatedRoute(): PageRoute | undefined {
          return getAssociatedRoute(CommonRouteKeys.BadgePage.key, pageRoutes)
        },
      },
      {
        key: CommonRouteKeys.MapPage.key,
        name: 'Maps',
        link: 'admin/maps',
        icon: <CustomSVGIcon svgText={icons.mapAdminIcon} />,
        permissions: [
          PermissionsEnum.ImportVenuesRead,
          PermissionsEnum.ImportVenuesWrite,
        ],
        get associatedRoute(): PageRoute | undefined {
          return getAssociatedRoute(CommonRouteKeys.MapPage.key, pageRoutes)
        },
      },
      {
        key: CommonRouteKeys.UserManagementPage.key,
        name: 'Users',
        link: 'admin/users',
        icon: <PeopleOutlined />,
        permissions: [PermissionsEnum.UsersRead, PermissionsEnum.UsersWrite],
        get associatedRoute(): PageRoute | undefined {
          return getAssociatedRoute(
            CommonRouteKeys.UserManagementPage.key,
            pageRoutes
          )
        },
      },
      {
        key: CommonRouteKeys.HardwareManagementPage.key,
        name: 'Hardware',
        link: 'admin/hardware',
        icon: <MemoryOutlined />,
        permissions: [
          PermissionsEnum.HardwareRead,
          PermissionsEnum.HardwareWrite,
        ],
        get associatedRoute(): PageRoute | undefined {
          return getAssociatedRoute(
            CommonRouteKeys.HardwareManagementPage.key,
            pageRoutes
          )
        },
      },
      {
        key: RouteKeys.LocationManagementPage,
        name: 'Locations',
        link: 'admin/locations',
        icon: <BusinessOutlinedIcon />,
        permissions: [
          PermissionsEnum.LocationsRead,
          PermissionsEnum.LocationsWrite,
        ],
        get associatedRoute(): PageRoute | undefined {
          return getAssociatedRoute(
            RouteKeys.LocationManagementPage,
            pageRoutes
          )
        },
      },
      {
        key: RouteKeys.BadgeTrafficPage,
        name: 'Badge Traffic',
        link: 'admin/badge-traffic',
        icon: <DeviceHubOutlined />,
        permissions: [
          PermissionsEnum.BadgeTrafficRead,
          PermissionsEnum.BadgeTrafficWrite,
        ],
        get associatedRoute(): PageRoute | undefined {
          return getAssociatedRoute(RouteKeys.BadgeTrafficPage, pageRoutes)
        },
      },
      {
        key: CommonRouteKeys.AlertManagementPage.key,
        name: 'Alerts',
        link: 'admin/alerts',
        icon: <CustomSVGIcon svgText={icons.alertAdminIcon} />,
        permissions: [
          PermissionsEnum.AlertAdminRead,
          PermissionsEnum.AlertAdminWrite,
        ],
        get associatedRoute(): PageRoute | undefined {
          return getAssociatedRoute(
            CommonRouteKeys.AlertManagementPage.key,
            pageRoutes
          )
        },
      },
      {
        key: CommonRouteKeys.DevToolsPage.key,
        name: 'Dev Tools',
        link: 'admin/devtools',
        icon: <DeveloperBoardOutlined />,
        permissions: [
          PermissionsEnum.DevToolsRead,
          PermissionsEnum.DevToolsWrite,
        ],
        get associatedRoute(): PageRoute | undefined {
          return getAssociatedRoute(
            CommonRouteKeys.DevToolsPage.key,
            pageRoutes
          )
        },
      },
    ],
  },
]

export const operationsPageGroups: PageGroup[] = [
  {
    key: RouteKeys.OperationsDashboardPage,
    name: 'Operations',
    icon: <HomeOutlined />,
    link: 'operations',
    pages: [],
    get associatedRoute(): PageRoute | undefined {
      return getAssociatedRoute(RouteKeys.OperationsDashboardPage, pageRoutes)
    },
  },
  {
    key: 'globalAdmin',
    name: 'Administration',
    icon: <PublicOutlined />,
    pages: [
      {
        key: RouteKeys.OperationsUserManagementPage,
        name: 'Operations Users',
        link: 'operations/users',
        icon: <PeopleOutlined />,
        permissions: [
          PermissionsEnum.OperationsUsersRead,
          PermissionsEnum.OperationsUsersWrite,
        ],
        get associatedRoute(): PageRoute | undefined {
          return getAssociatedRoute(
            RouteKeys.OperationsUserManagementPage,
            pageRoutes
          )
        },
      },
      {
        key: RouteKeys.OperationsCustomerManagementPage,
        name: 'Customers',
        link: 'operations/customers',
        icon: <BusinessOutlinedIcon />,
        permissions: [PermissionsEnum.CustomerRead],
        get associatedRoute(): PageRoute | undefined {
          return getAssociatedRoute(
            RouteKeys.OperationsCustomerManagementPage,
            pageRoutes
          )
        },
      },
      {
        key: RouteKeys.OperationsReleaseManagementPage,
        name: 'Releases',
        link: 'operations/releases',
        icon: (
          <img
            src={process.env.REACT_APP_STATIC_CONTENT + 'images/trophy.svg'}
            alt='loading'
            style={{
              filter: 'invert(100%)', //Inverts the black Icon to White
            }}
          ></img>
        ),
        permissions: [PermissionsEnum.CustomerRead],
        get associatedRoute(): PageRoute | undefined {
          return getAssociatedRoute(
            RouteKeys.OperationsReleaseManagementPage,
            pageRoutes
          )
        },
      },
      {
        key: RouteKeys.OperationsDevToolsPage,
        name: 'Dev Tools',
        link: 'operations/devtools',
        icon: <DeveloperBoardOutlined />,
        permissions: [
          PermissionsEnum.DevToolsRead,
          PermissionsEnum.DevToolsWrite,
        ],
        get associatedRoute(): PageRoute | undefined {
          return getAssociatedRoute(
            RouteKeys.OperationsDevToolsPage,
            pageRoutes
          )
        },
      },
    ],
  },
]
