import { AgentType } from '../../models'
import {
  BadgeTelemetryMessageWithAgentEvent,
  TelemetryByAgentType,
} from '../../models/telemetry'

/**
 * Since the agent data included in the telemetry does not include all the data
 * needed for the Detail Drawer, we need to map this object back to the agent retrieved
 * from the initial fetched assets/staffs by location data
 * @param agentTelemetry telemetry received via SignalR
 * @param fetchedAgents fetched assets/staffs from the API
 * @returns telemetry with agent remapped to agent data from the fetched assets/staffs
 */
export function replaceAgentInNewTelemetryWithFetchedAgentInState(
  agentTelemetry: BadgeTelemetryMessageWithAgentEvent,
  fetchedAgents: TelemetryByAgentType
): BadgeTelemetryMessageWithAgentEvent {
  if (agentTelemetry.agent.agentType === AgentType.Asset) {
    const prevFetchedAssetTelemetry =
      fetchedAgents.assetAgentTelemetries[agentTelemetry.trackingId]
    if (prevFetchedAssetTelemetry) {
      agentTelemetry.agent = prevFetchedAssetTelemetry.agent
    }
  } else if (agentTelemetry.agent.agentType === AgentType.Staff) {
    const prevFetchedStaffTelemetry =
      fetchedAgents.staffAgentTelemetries[agentTelemetry.trackingId]
    if (prevFetchedStaffTelemetry) {
      agentTelemetry.agent = prevFetchedStaffTelemetry.agent
    }
  }
  return agentTelemetry
}
