import {
  Box,
  Step,
  StepButton,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { validateActiveConfig } from '../../../helpers/alert'
import { AlertConfigSteps, defaultAlertConfigSteps } from '../../../models'
import { colors, tableColors } from '../../../styles/MidmarkTheme'
import { AlertConfigStepperHeaderHeight } from '../constants'
import useAlertActiveConfig from '../useAlertActiveConfig'
import { AlertTypes } from '../../../models/alert'

interface Props {
  activeStep: number
}

const useStepperStyles = makeStyles({
  root: {
    padding: '54px 24px 0px 24px',
    height: AlertConfigStepperHeaderHeight,
    backgroundColor: tableColors.background,
    '& .MuiStep-horizontal': {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
  },
})

const useStepperConnectorStyles = makeStyles({
  root: {
    '& .MuiStepConnector-lineHorizontal': {
      borderTopWidth: '4px',
      borderColor: colors.primary,
      paddingLeft: '0px',
      paddingRight: '0px',
    },
  },
})

const useStepperLabelStyles = makeStyles({
  root: {
    fontWeight: 'bold',
    '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
      position: 'relative',
      bottom: '75px',
      fontWeight: 'bold',
      color: 'black',
    },
  },
})

const useStepIconStyles = makeStyles({
  root: {
    backgroundColor: tableColors.background,
    display: 'flex',
    height: 24,
    alignItems: 'center',
  },
  active: {
    backgroundColor: colors.asseRecent,
    borderRadius: '50%',
    '& > div': {
      border: '0px',
      height: '15px',
      width: '25px',
      color: colors.white,
    },
  },
  circle: {
    width: 22,
    height: 22,
    borderRadius: '50%',
    border: `2px solid ${colors.primary}`,
    display: 'flex',
    fontSize: '16px',
    alignItems: 'center',
    justifyContent: 'center',
    color: colors.primary,
    fontFamily: 'ubuntu',
  },
  circleCompleted: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    fontSize: '16px',
    backgroundColor: colors.primary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: tableColors.background,
    fontFamily: 'ubuntu',
  },
})

function StyledStepIcon(props: StepIconProps) {
  const classes = useStepIconStyles()
  const { active, completed, icon } = props

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <div className={classes.circleCompleted}>{icon}</div>
      ) : (
        <div className={classes.circle}>{icon}</div>
      )}
    </div>
  )
}

const AlertConfigStepperHeader = (props: Props) => {
  const { activeStep } = props
  const stepperConnectorClasses = useStepperConnectorStyles()
  const stepperLabelClasses = useStepperLabelStyles()
  const stepperClasses = useStepperStyles()
  const { activeConfig } = useAlertActiveConfig()
  const location = useLocation()
  const history = useNavigate()

  const handleNav = (step: number) => () => {
    const path = `${location?.pathname.slice(
      0,
      location?.pathname.lastIndexOf('/')
    )}`
    history(`${path}/${AlertConfigSteps[step].toLowerCase()}`)
  }

  const isStepCompleted = (step: number) => step < activeStep

  return (
    <Box style={{ width: '100%', borderBottom: '1px solid #c4c4c4' }}>
      <Stepper
        style={{ padding: '54px 24px 0px 24px' }}
        alternativeLabel
        nonLinear={validateActiveConfig(activeConfig)}
        classes={stepperClasses}
        activeStep={activeStep}
        connector={<StepConnector classes={stepperConnectorClasses} />}
      >
        {Object.values(defaultAlertConfigSteps).map((step, index) => {
          const stepProps: { completed?: boolean } = {}
          const labelProps: {
            optional?: React.ReactNode
          } = {}
          if (isStepCompleted(index)) {
            stepProps.completed = true
          }

          return (
            <Step key={step.name} {...stepProps}>
              <StepButton
                disableRipple
                color='inherit'
                onClick={handleNav(index)}
              >
                <StepLabel
                  StepIconComponent={StyledStepIcon}
                  classes={stepperLabelClasses}
                  {...labelProps}
                >
                  {activeConfig.alertType?.id == AlertTypes.AssetAlert &&
                  step.name === 'CHANNELS'
                    ? 'DISPLAY METHOD'
                    : step.name}
                </StepLabel>
              </StepButton>
            </Step>
          )
        })}
      </Stepper>
    </Box>
  )
}

export default AlertConfigStepperHeader
