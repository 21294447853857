import { createAsyncAction } from 'typesafe-actions'
import { createAction } from 'typesafe-actions'
import { StaffUserCreateUpdateModel, User } from '../models/users'

export const acknowledgeHttpError = createAction(
  'ACK_USERS_HTTP_ERROR'
)<boolean>()

export const getAllUsersAction = createAsyncAction(
  'GET_ALL_USERS_REQUEST',
  'GET_ALL_USERS_SUCCESS',
  'GET_ALL_USERS_FAILURE'
)<void, User[], Error>()

export const getUserByIdAction = createAsyncAction(
  'GET_USER_BY_ID_REQUEST',
  'GET_USER_BY_ID_SUCCESS',
  'GET_USER_BY_ID_FAILURE'
)<string, User, Error>()

export const getUserByEmailAction = createAsyncAction(
  'GET_USER_BY_EMAIL_REQUEST',
  'GET_USER_BY_EMAIL_SUCCESS',
  'GET_USER_BY_EMAIL_FAILURE'
)<string, User, Error>()

export const postUserAction = createAsyncAction(
  'POST_USER_REQUEST',
  'POST_USER_SUCCESS',
  'POST_USER_FAILURE'
)<User, User, Error>()

export const postStaffUserAction = createAsyncAction(
  'POST_STAFF_USER_REQUEST',
  'POST_STAFF_USER_SUCCESS',
  'POST_STAFF_USER_FAILURE'
)<StaffUserCreateUpdateModel, User, Error>()

export const putUserAction = createAsyncAction(
  'PUT_USER_REQUEST',
  'PUT_USER_SUCCESS',
  'PUT_USER_FAILURE'
)<User, User, Error>()

export const putStaffUserAction = createAsyncAction(
  'PUT_STAFF_USER_REQUEST',
  'PUT_STAFF_USER_SUCCESS',
  'PUT_STAFF_USER_FAILURE'
)<StaffUserCreateUpdateModel, User, Error>()

export const deleteUserAction = createAsyncAction(
  'DELETE_USER_REQUEST',
  'DELETE_USER_SUCCESS',
  'DELETE_USER_FAILURE'
)<string, string, Error>()
