import { getType } from 'typesafe-actions'
import { LocationMetricsAction } from '../actions'
import { getLocationMetricsAction } from '../actions/locationMetrics'
import {
  LocationMetric,
  LocationMetrics,
  LocationMetricsState,
} from '../models'
import { FetchingStatus } from '../models/fetchingStatus'
import { mergeRecord, setFetchingStatus } from '../utils'

const initialState: LocationMetricsState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

export default (
  state: LocationMetricsState = initialState,
  action: LocationMetricsAction
): LocationMetricsState => {
  switch (action.type) {
    case getType(getLocationMetricsAction.success):
      return {
        data: mergeRecord<LocationMetrics, LocationMetric, 'locationId'>(
          state.data,
          action.payload,
          'locationId'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getLocationMetricsAction.request):
      return setFetchingStatus<LocationMetrics>(state, FetchingStatus.Request)
    case getType(getLocationMetricsAction.failure):
      return setFetchingStatus<LocationMetrics>(state, FetchingStatus.Failure)
    default:
      return state
  }
}
