import React from 'react'
import { useHistory } from 'react-router-dom'
import { Tooltip, IconButton } from '@mui/material'
import { Device, Issue } from '../../models'
import icons from '../../models/icons'

interface Props {
  onViewOnMapClick: () => void
  icon: string
}

const ViewOnMapButton = (props: Props): JSX.Element => {
  const { icon, onViewOnMapClick } = props

  return (
    <Tooltip title={`View On Map`}>
      <IconButton
        color='primary'
        onClick={onViewOnMapClick}
        aria-label={`View on map`}
        size='medium'
      >
        <div
          style={{
            height: 24,
            width: 24,
            display: 'flex',
          }}
          dangerouslySetInnerHTML={{ __html: icon }}
        />
      </IconButton>
    </Tooltip>
  )
}

export { ViewOnMapButton }
