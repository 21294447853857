import { FormControl } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import React, { useMemo } from 'react'
import {
  defaultAutocompleteProps,
  defaultAutocompleteStyles,
} from '../../helpers/filters'
import { Role, Roles, UserFilters } from '../../models'
import {
  DrawerContent,
  FilterDrawerHeader,
  RightDrawerLayout,
} from '../Common/RightDrawer'
import { FilterDrawerHeaderProps } from '../Common/RightDrawer/FilterDrawerHeader'
import SortedAutocomplete from '../Filters/SortedAutoComplete'
import UserListFilterChips from './UserListFilterChips'

interface Props {
  roles: Roles
  userFilters: UserFilters
  headerProps: FilterDrawerHeaderProps
  handleFilterChange: (filter: UserFilters) => void
}

function UserListFilterDrawer(
  props: Props & WithStyles<typeof defaultAutocompleteStyles>
): JSX.Element {
  const { classes, roles, userFilters, headerProps, handleFilterChange } = props

  const roleList = useMemo(() => Object.values(roles), [roles])

  const { props: autoCompleteProps } = defaultAutocompleteProps()

  return (
    <RightDrawerLayout
      header={<FilterDrawerHeader {...headerProps} />}
      content={
        <div>
          <UserListFilterChips
            filterState={userFilters}
            handleChange={handleFilterChange}
          />

          <DrawerContent>
            <FormControl variant='standard' className={classes.filterControl}>
              <SortedAutocomplete
                value={userFilters.selectedRoles}
                items={Object.values(roleList)}
                itemKey='name'
                itemValueCompareKey='id'
                label='Role'
                handleChange={(event, selected: Role[]): void =>
                  selected && handleFilterChange({ selectedRoles: selected })
                }
              />
            </FormControl>
          </DrawerContent>
        </div>
      }
    />
  )
}

export default withStyles(defaultAutocompleteStyles)(UserListFilterDrawer)
