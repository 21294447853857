import { BaseService } from './baseService'

export class BadgeAssignedCountApi extends BaseService {
  constructor() {
    super({ entityType: 'number' })
  }
  route = 'badges/assigned/count'

  async getBadgeAssignedCount(): Promise<number> {
    return await this.get<number>(this.route)
  }
}

export const badgeAssignedCountApi = new BadgeAssignedCountApi()
