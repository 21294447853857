import React, { ReactNode } from 'react'
import { CommonComponent, ComponentProps } from '../../components'
import { FilterToggleButton } from '../../components/ActionBar'
import { PageToolbar } from '../../components/Layout'
import { DataTestIds } from '../../utils/test-utils/dataTestIds'

interface Props {
  unitSelector?: ReactNode
  filterCount?: number
  onFilterButtonClicked: () => void
}

const AlertAdminToolbar: CommonComponent<Props> = (
  props: ComponentProps<Props>
) => {
  const { filterCount, unitSelector, onFilterButtonClicked } = props

  return (
    <PageToolbar
      searchStorageName={AlertAdminToolbar.name}
      data-testid={`${DataTestIds.alertAdminPageAlertToolbar}`}
    >
      {{
        filterButton: (
          <FilterToggleButton
            filterCount={filterCount}
            onClick={onFilterButtonClicked}
          ></FilterToggleButton>
        ),
        actions: <>{unitSelector}</>,
      }}
    </PageToolbar>
  )
}

export default AlertAdminToolbar
