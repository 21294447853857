import { ColumnDef, ColumnSort, Row } from '@tanstack/react-table'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  deleteUserSettingsAction,
  patchUserSettingsAction,
} from '../../actions/userSettings'
import { getRowStyles } from '../../helpers'
import { renderSvgIcon } from '../../helpers/reactTable'
import { useFetchUserSettings } from '../../hooks'
import {
  AgentType,
  FetchingStatus,
  StaffEvent,
  TableColumnSettings,
  UserSettingsType,
} from '../../models'
import { staffExportHeaders } from '../../models/staffExportHeaders'
import { StaffRow } from '../../models/staffRow'
import { WatchAgentIconButton } from '../Buttons/WatchAgentIconButton'
import { ReactTable, TableRowSelectionOptions } from '../Tables'
import TableCsvExport from '../Tables/TableCsvExport'
import StaffListViewStaffButton from './StaffListViewStaffButton'

interface Props {
  isFetching: boolean
  activeDuressEvents: StaffEvent[]
  activeAssistEvents: StaffEvent[]
  tableData: StaffRow[]
  tableRowSelectionOptions: Omit<
    TableRowSelectionOptions<StaffRow>,
    'dataMatchPredicate'
  >
  savedFilterSortBy: ColumnSort | undefined
  onSortingChange: (columnSort: ColumnSort[]) => void
}

const RealTimeStaffList: React.FC<Props> = (props: Props) => {
  const {
    isFetching,
    activeDuressEvents,
    activeAssistEvents,
    tableData,
    tableRowSelectionOptions,
    onSortingChange,
    savedFilterSortBy,
  } = props
  const { selectedRow } = tableRowSelectionOptions

  const [preSelectedRowFrolUrlBadgeId, setPreSelectedRowFrolUrlBadgeId] =
    useState<StaffRow | undefined>(undefined)

  // Tim Groven - 05/26/2023 - https://versuscarina.visualstudio.com/Carina/_workitems/edit/25991
  // Using URLSearchParams because pulling from useQueryParams()
  // Doesn't decode the HTML string, which is bad for MAC Addresses
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const urlBadgeId = searchParams.get('badgeId')
  const { data: userSettings, status: userSettingsStatus } =
    useFetchUserSettings()

  const dispatch = useDispatch()

  useEffect(() => {
    if (urlBadgeId && tableData.length > 0 && !preSelectedRowFrolUrlBadgeId) {
      const staff = tableData.find((x) =>
        x.badgeIds.some((s) => s === urlBadgeId)
      )
      if (staff) {
        setPreSelectedRowFrolUrlBadgeId(staff)
      }
    }
  }, [preSelectedRowFrolUrlBadgeId, urlBadgeId, tableData])

  const columns = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const columnDefinitions: ColumnDef<StaffRow, any>[] = [
      {
        size: 32,
        accessorKey: 'icon',
        header: 'Icon',
        cell: (cell) => renderSvgIcon(cell),
        enableSorting: false,
        enablePinning: true,
      },
      {
        accessorKey: 'displayName',
        header: 'Display Name',
        sortingFn: 'alphanumeric',
        enablePinning: true,
      },
      {
        accessorKey: 'firstName',
        header: 'First Name',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'lastName',
        header: 'Last Name',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'email',
        header: 'Email',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'staffTypeName',
        header: 'Type',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'staffSubTypeName',
        header: 'Sub-Type',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'unitName',
        header: 'Assigned To',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'managerName',
        header: 'Reports To',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'badgeNumber',
        header: 'RTLS ID',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'signalType',
        header: 'RTLS Signal Type',
        sortingFn: 'alphanumeric',
        enableHiding: true,
      },
      {
        accessorKey: 'tagBatteryChangeDateString',
        header: 'RTLS Battery Change Date',
        sortingFn: 'alphanumeric',
        enableHiding: true,
      },
      {
        accessorKey: 'building',
        header: 'Building',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'floor',
        header: 'Floor',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'campus',
        header: 'Building Group',
        sortingFn: 'alphanumeric',
        enableHiding: true,
      },
      {
        accessorKey: 'locationName',
        header: 'Room',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'locationUnitName',
        header: 'Unit',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'locationSubUnitName',
        header: 'Sub-Unit',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'accuracy',
        header: 'Location Accuracy',
        size: 125,
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'lastSensedDateString',
        header: 'Last Detected',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'signalStatus',
        header: 'Signal Status',
        size: 125,
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'batteryStatus',
        header: 'RTLS Battery Status',
        size: 220,
        sortingFn: 'alphanumeric',
      },
      {
        id: 'map',
        enableSorting: false,
        accessorFn: (row) => (
          <StaffListViewStaffButton
            room={row.locationName}
            signalStatus={row.signalStatus}
            size='small'
            badgeId={row.badgeIds[0] ?? ''}
            floorLocationId={row.floorLocation?.id ?? ''}
            isPrivateRoom={row.isPrivate}
          />
        ),
        cell: (cell) => cell.getValue(),
        header: 'Map',
        enablePinning: true,
        meta: {
          align: 'right',
          headerStyle: {
            paddingRight: 19,
          },
        },
      },
      {
        id: 'myList',
        enableSorting: false,
        accessorFn: (row) => (
          <WatchAgentIconButton
            size='small'
            agentId={row.agentGuid}
            agentType={AgentType.Asset}
            isWatchlisted={row.isWatchListed}
            color='primary'
          />
        ),
        cell: (cell) => cell.getValue(),
        header: 'My List',
        enablePinning: true,
        meta: {
          align: 'right',
          headerStyle: {
            paddingRight: 19,
          },
        },
      },
    ]
    return columnDefinitions
  }, [])

  const handleSave = useCallback(
    (settings: TableColumnSettings, saveDefault: boolean): void => {
      if (!saveDefault) {
        dispatch(
          patchUserSettingsAction.request({
            realtimeStaff: settings,
          })
        )
      } else {
        dispatch(deleteUserSettingsAction.request(UserSettingsType.StaffList))
      }
    },
    [dispatch]
  )

  const handleGetRowStyles = useCallback(
    (row: Row<StaffRow>) => {
      return getRowStyles(
        row,
        activeDuressEvents,
        activeAssistEvents,
        selectedRow
      )
    },
    [activeAssistEvents, activeDuressEvents, selectedRow]
  )

  const showLoading = useMemo(() => {
    return isFetching || userSettingsStatus === FetchingStatus.Request
  }, [isFetching, userSettingsStatus])

  const actions = useMemo(() => {
    return [
      <TableCsvExport
        isLoading={showLoading}
        key='CSVExport'
        csvLinkExportOptions={{
          csvFileName: 'StaffList.csv',
          data: tableData,
          headers: staffExportHeaders,
        }}
      />,
    ]
  }, [showLoading, tableData])

  const tableToolbarConfigs = useMemo(
    () => ({
      title: 'Staff List (Real-Time)',
      actions: actions,
    }),
    [actions]
  )

  const tableOptions = useMemo(
    () => ({
      data: tableData,
      columns: columns,
      showLoading: showLoading,
    }),
    [columns, showLoading, tableData]
  )

  const tableRowOptions = useMemo(
    () => ({
      rowSelectionOptions: {
        ...tableRowSelectionOptions,
        dataMatchPredicate: (staffA: StaffRow, staffB: StaffRow) =>
          staffA.agentGuid === staffB.agentGuid,
      },
      getDynamicRowStyle: handleGetRowStyles,
    }),
    [handleGetRowStyles, tableRowSelectionOptions]
  )

  const tableSortingOptions = useMemo(
    () => ({
      onSortingChange: onSortingChange,
      savedFilterSortBy: savedFilterSortBy,
    }),
    [onSortingChange, savedFilterSortBy]
  )

  const tableColumnUserCustomizationOptions = useMemo(
    () => ({
      onSaveColumnCustomization: handleSave,
      userSettings: Object.values(userSettings.realtimeStaff ?? {}),
    }),
    [handleSave, userSettings.realtimeStaff]
  )

  const preSelectedItemFromUrlSearchParams = useMemo(
    () => ({
      rowToSelectFromUrlSearchParams: preSelectedRowFrolUrlBadgeId,
      rowMatchPredicate: (staffA: StaffRow, staffB: StaffRow) =>
        staffA.agentGuid === staffB.agentGuid,
    }),
    [preSelectedRowFrolUrlBadgeId]
  )

  return (
    <>
      <ReactTable
        tableToolbarOptions={tableToolbarConfigs}
        tableOptions={tableOptions}
        tableRowOptions={tableRowOptions}
        tableSortingOptions={tableSortingOptions}
        tableColumnUserCustomizationOptions={
          tableColumnUserCustomizationOptions
        }
        preSelectedItemFromUrlSearchParams={preSelectedItemFromUrlSearchParams}
      />
    </>
  )
}

export default RealTimeStaffList
