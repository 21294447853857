/* ******************************************** */
/*  Global overrides of Material UI Components  */
/* ******************************************** */
import { createTheme } from '@mui/material/styles'

export default createTheme({
  palette: {
    primary: {
      main: '#236092',
      contrastText: '#fff',
    },
    secondary: {
      main: '#fff',
      contrastText: '#000',
    },
    info: {
      main: '#fff',
      contrastText: '#000',
    },
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          '&:focus': {
            backgroundColor: '$labelcolor',
          },
        },
      },
    },
  },
  typography: {
    fontFamily: 'Ubuntu, Verdana, Arial, sans-serif',
  },
})

export const colors = {
  primary: '#236092',
  secondaryBlue: '#58c9e7',
  secondaryBlueHalf: '#58c9e780',
  indigo600: '#2a9ad1',
  magenta: '#e54360',
  error: '#ce0e2d',
  green: '#78be20',
  secondaryGreen: '#9acf58',
  darkSurface: '#1c1b1b', // Fill Color
  background: '#6f6e6e',
  darkTeal: '#204152',
  lightTeal: '#41abc7',
  darkBlue: '#165788',
  darkGrey: '#666666',
  darkGrey2: '#4A545D',
  darkGrey3: '#373939',
  darkOlive: '#3A3A3A',
  midGrey: '#9e9e9e',
  midGrey2: '#8d8d8d',
  offWhite: '#d8d8d8',
  white: '#ffffff',
  whiteSmoke: '#f5f5f5',
  whiteGrey: '#E4E4E4',
  black: '#000000',
  hoverBackground: '#42b4e399', // 42b4e3 @ 60%opacity
  transparent: 'rgba(0, 0, 0, 0)',
  asseRecent: '#2a9ad1',
  assetTimedOut: '#333333',
  staffRecent: '#33b4af',
  staffTimeOut: '#313131',
  staff: '#006561',
  duressBubble: '#f6cdd3',
  duressTableSelect: '#D6CCD7',
  unitColor1: '#8D1B60',
  unitColor2: '#78BE20',
  unitColor3: '#D9597C',
  unitColor4: '#285F68',
  avatarBackground4: '#00A19B',
  outlineColor: '#54C8E8',
  warning: '#ff8300',

  // Staff Assist Colors
  assistTableHighlight: '#F2EAE1',
  assistTableSelect: '#C3E0E3',
  assistDetailDrawerColor: '#FFE6CC',
  assistIconColor: '#EC7115',
  assistConfidenceBubble: '#FF8300',
  assistGroupColor: 'FF8300',

  // defaults from MUI that we are overriding, bringing them back
  disabledButtonBackground: 'rgba(0, 0, 0, 0.26)',
  disabledButtonText: 'rgba(0, 0, 0, 0.26)',

  fillColor: '#165788',
  assetCurrent: '#165788',
  staffCurrent: '#006561',
  duress: '#ce0e2d',
  duressConfidenceBubble: 'ce0e2d',
  duressTableHighlight: '#f6cdd3',
  avatarBackground1: '#8D1B60',
  avatarBackground2: '#D9597C',
  avatarBackground3: '#78BE20',
  avatarBackground5: '#285F68',
  avatarBackground6: '#165788',
}

export const fontSizes = {
  largeFont: '1.5rem',
}

export const tableColors = {
  background: '#f2f2f2',
  borderColor: '#c4c4c4',
  textColor: '#333333',
  headerColor: '#666666',
}
