import { getType } from 'typesafe-actions'
import { VenueAction } from '../actions'
import { Venues, Venue, VenuesState, FetchingStatus } from '../models'
import { mergeRecords } from '../utils'
import {
  getAllVenuesAction,
  deleteAllVenueAction,
  deleteVenueAction,
} from '../actions/venues'

const initialState: VenuesState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: VenueAction): VenuesState => {
  switch (action.type) {
    case getType(getAllVenuesAction.success):
      return {
        data: mergeRecords<Venues, Venue, 'venueId'>(
          state.data,
          action.payload,
          'venueId'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(deleteAllVenueAction.success):
    case getType(deleteVenueAction.success):
      return {
        data: mergeRecords<Venues, Venue, 'venueId'>(
          {},
          action.payload,
          'venueId'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getAllVenuesAction.request):
    case getType(deleteAllVenueAction.request):
    case getType(deleteVenueAction.request):
      return {
        ...state,
        status: FetchingStatus.Request,
      }
    case getType(getAllVenuesAction.failure):
    case getType(deleteAllVenueAction.failure):
    case getType(deleteVenueAction.failure):
      return {
        ...state,
        status: FetchingStatus.Failure,
      }
    default:
      return state
  }
}
