import { useDispatch, useSelector } from 'react-redux'
import { ModelsState, StringObj } from '../models'
import { shouldDispatch } from '../helpers/shouldDispatch'
import { useEffect } from 'react'
import { getAllModelsAction } from '../actions/models'

const useFetchModels = (shouldRefresh = false) => {
  const dispatch = useDispatch()
  const status = useSelector(
    ({ models }: { models: ModelsState }) => models.status
  )
  const lastRefreshTime = useSelector(
    ({ models }: { models: ModelsState }) => models.lastRefreshTime
  )

  useEffect(() => {
    if (shouldDispatch(status, lastRefreshTime) || shouldRefresh) {
      dispatch(getAllModelsAction.request())
    }
  }, [status, lastRefreshTime, shouldRefresh, dispatch])

  const models: StringObj[] = useSelector(
    ({ models }: { models: ModelsState }) => models.data
  )

  return {
    models,
    status,
  }
}

export { useFetchModels }
