import {
  AgentEventView,
  AgentEventViewsPaginatedTrafficRequest,
} from '../models'
import { PaginationResponse } from '../models/paginationResponse'
import { BaseService } from './baseService'

export class AgentEventViewsApi extends BaseService {
  constructor() {
    super({ entityType: 'agentEventViews' })
  }
  route = 'agentEventViews'

  async getAgentEventViews(
    request: AgentEventViewsPaginatedTrafficRequest
  ): Promise<PaginationResponse<AgentEventView[]>> {
    const data = await this.get<PaginationResponse<AgentEventView[]>>(
      this.route,
      {
        params: request.filters,
      }
    )

    return data
  }
}

export const agentEventViewsApi = new AgentEventViewsApi()
