import { createAction } from 'typesafe-actions'
import { StaffEvent, TelemetryEventLocation } from '../models'

export const fetchLiveStaffEventsTelemetrySuccess = createAction(
  'FETCH_LIVE_STAFF_EVENT_TELEMETRY_SUCCESS'
)<TelemetryEventLocation[]>()

export const updateTelemetryStaffEvent = createAction(
  'UPDATE_TELEMETRY_STAFF_EVENT'
)<StaffEvent>()

export const dismissStaffEvent = createAction(
  'DISMISS_STAFF_EVENT'
)<StaffEvent>()
