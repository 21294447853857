import { DialogActions, DialogContent, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { useSelector } from 'react-redux'
import { formatLocalDateTimeByUserPreference } from '../../helpers/date'
import { useFetchUserPreferences } from '../../hooks'
import { LocationsState, StaffEvent } from '../../models'
import { colors } from '../../styles'
import { DataTestIds } from '../../utils/test-utils/dataTestIds'
import PrimaryContainedButton from '../Buttons/PrimaryContainedButton'
import PrimaryTextButton from '../Buttons/PrimaryTextButton'
import StaffDuressModalTitle from './StaffDuressModalTitle'
import ViewStaffOnMapButton from './ViewStaffOnMapButton'

interface Props {
  hideButtons: boolean
  duressEvent: StaffEvent | null
  onCloseDuressModal: () => void
  onViewOnMap: () => void
  onResolve: (duressToResolve: StaffEvent) => void
  isSecurityUser: boolean
  canViewOnMap: boolean
}

const StaffDuressModalContent = (props: Props): JSX.Element => {
  const {
    duressEvent,
    hideButtons,
    onCloseDuressModal,
    onViewOnMap,
    onResolve,
    isSecurityUser,
    canViewOnMap,
  } = props

  const locations = useSelector(
    ({ locations }: { locations: LocationsState }) => locations.data
  )

  const useDialogContentStyles = makeStyles(() => ({
    root: {
      padding: '16px 0px',
    },
  }))
  const useDialogActionStyles = makeStyles(() => ({
    root: {
      padding: 0,
    },
  }))

  const dialogActionClasses = useDialogActionStyles()
  const dialogContentClasses = useDialogContentStyles()
  const { data: userPreferences } = useFetchUserPreferences()

  const handleResolve = () => {
    if (duressEvent) {
      onResolve(duressEvent)
    }
  }

  return (
    <>
      <StaffDuressModalTitle
        onCloseDuressModal={onCloseDuressModal}
        reportedTime={duressEvent?.reportedTime}
      />
      <DialogContent classes={dialogContentClasses}>
        <Typography
          variant='h5'
          style={{
            fontSize: '1.875rem',
            color: colors.error,
            fontWeight: 'bold',
          }}
          data-testid={DataTestIds.inRoomHeader}
        >
          {`Duress in ${duressEvent?.roomName}`}
        </Typography>
        <Typography variant='h5' data-testid={DataTestIds.duressReportedBy}>
          {`Reported By: ${duressEvent?.reportedBy}`}
        </Typography>
        <br />
        <Typography
          variant='body1'
          style={{ fontSize: '1.125rem' }}
          data-testid={DataTestIds.duressMessage}
        >
          {`${duressEvent?.reportedBy} has reported a staff duress on ${
            duressEvent?.floorName
          } in ${duressEvent?.roomName} at ${
            duressEvent?.reportedTime
              ? formatLocalDateTimeByUserPreference(
                  duressEvent?.reportedTime,
                  userPreferences.timeDisplayId
                )
              : 'Unknown Time'
          }`}
        </Typography>
      </DialogContent>
      {!hideButtons && (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DialogActions classes={dialogActionClasses}>
            <PrimaryTextButton onClick={onCloseDuressModal}>
              DISMISS
            </PrimaryTextButton>
          </DialogActions>

          <DialogActions classes={dialogActionClasses}>
            <>
              {canViewOnMap && (
                <ViewStaffOnMapButton
                  badgeId={duressEvent?.badgeId ?? ''}
                  onViewOnMap={onViewOnMap}
                  floorLocation={
                    duressEvent?.floorLocationId
                      ? locations[duressEvent.floorLocationId]
                      : undefined
                  }
                />
              )}
              {isSecurityUser && (
                <PrimaryContainedButton
                  data-testid={DataTestIds.duressResolve}
                  styleOverride={{ wrapper: { margin: 0 } }}
                  onClick={handleResolve}
                >
                  RESOLVE
                </PrimaryContainedButton>
              )}
            </>
          </DialogActions>
        </div>
      )}
    </>
  )
}

export default StaffDuressModalContent
