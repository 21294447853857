import { Epic } from 'redux-observable'
import { CurrentUserAction } from '../actions'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { CurrentUserApi } from '../middleware/currentUser'
import { CurrentUserState } from '../models'
import { getCurrentUserByIdAction } from '../actions/currentUser'

type CurrentUserEpic = Epic<
  CurrentUserAction,
  CurrentUserAction,
  CurrentUserState,
  { currentUserApi: CurrentUserApi }
>

export const getUserByIdActionEpic: CurrentUserEpic = (
  action$,
  state$,
  { currentUserApi }
) => {
  return action$.pipe(
    filter(isActionOf(getCurrentUserByIdAction.request)),
    mergeMap((action) =>
      from(currentUserApi.getUserById(action.payload)).pipe(
        map((payload) => getCurrentUserByIdAction.success(payload)),
        catchError((error) => of(getCurrentUserByIdAction.failure(error)))
      )
    )
  )
}
