import React from 'react'
import { DetailDrawerGroupTelemetry } from '../../../../models/telemetry'
import AgentGroupDetailsPane from '../../../Maps/SensoryNetworkMap/AgentGroupDetailsPane'
import AssetMapGroupPaneHeader from '../../../Maps/SensoryNetworkMap/AssetMapGroupPaneHeader'
import { RightDrawerLayout } from '../../RightDrawer'
import { AgentGroupDetailListItemProps } from '../../../Maps/SensoryNetworkMap/AgentGroupDetailsListItem'

interface Props extends Pick<AgentGroupDetailListItemProps, 'onSelectAgent'> {
  onCloseDetailDrawer: () => void
  data: DetailDrawerGroupTelemetry
}

const MapGroupDetailDrawer = (props: Props): JSX.Element => {
  const { onCloseDetailDrawer, onSelectAgent, data } = props
  const { resolvedLocationHierachy } = data
  return resolvedLocationHierachy ? (
    <RightDrawerLayout
      header={
        <AssetMapGroupPaneHeader
          handleClose={onCloseDetailDrawer}
          roomLocation={resolvedLocationHierachy?.roomLocation}
        />
      }
      content={
        <AgentGroupDetailsPane onSelectAgent={onSelectAgent} data={data} />
      }
    />
  ) : (
    <></>
  )
}

export default MapGroupDetailDrawer
