import { moveAgentToNewGeofence, updateGroupIconAndShowLeavingAgent } from '.'
import { AssetKitInstance } from '../../models'
import { MapRenderByGeofence } from '../../models/realtimeMap'
import { BadgeTelemetryMessageWithAgentEvent } from '../../models/telemetry'
import { calculateLeavingGeofenceMapUpdatesToMoveAgentFromGroup } from '../calculateRTMapUpdates'
import {
  calculateMapRenderToUpdateForAgentOnMap,
  checkRemainingAgentsHaveDuress,
} from '../realtimeMap'

/**
 * Once the MapUpdateType has been determined to be MoveAgentFromGroup from leaving geofence,
 * use this function to process the corresponding * map rerendering for the received telemetry.
 * Calculate the required configs followed by:
 * 1. Update current group icon in leaving geofence to reduce group count
 * 2. Rerender the leaving agent at leaving geofence as Asset or Staff icon
 * 3. Move leaving agent to the entering geofence
 * Finally, calculate and return the updated map render state
 * @param newAgentTelemetry
 * @param currentMapRender
 * @param assetKit
 * @param jibestreamController
 * @param geofenceInstances
 * @param useGeofenceCentroid
 * @param iconScale
 * @param selectedTrackingId
 * @param selectedGroupId
 * @param assetKitId
 * @param addGeofenceHighlight
 * @param removeGeofenceHighlight
 * @returns updated map render state after map rerendering and the next inpixonAssetId to use
 * for subsequent Inpixon Asset creation
 */
export function moveAgentFromGroup(
  newAgentTelemetry: BadgeTelemetryMessageWithAgentEvent,
  currentMapRender: MapRenderByGeofence,
  assetKit: AssetKitInstance,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  geofenceInstances: any[],
  useGeofenceCentroid: boolean,
  iconScale: number,
  selectedTrackingId: string | undefined,
  selectedGroupId: number | undefined,
  assetKitId: number,
  addGeofenceHighlight: (geofenceId: number) => void,
  removeGeofenceHighlight: (geofenceId: number) => void
): {
  nextInpixonAssetId: number | undefined
  updatedMapRender: MapRenderByGeofence
} {
  const leavingGeofenceData =
    calculateLeavingGeofenceMapUpdatesToMoveAgentFromGroup(
      newAgentTelemetry,
      currentMapRender,
      iconScale,
      selectedTrackingId,
      selectedGroupId
    )
  if (!leavingGeofenceData) {
    return {
      nextInpixonAssetId: undefined,
      updatedMapRender: currentMapRender,
    }
  }
  const {
    groupToUpdateIcon: leavingGeofenceGroupsToUpdateIcon,
    agentsToDegroup,
    degroupedAgentToUpdatePosition: degroupedAgentsToUpdatePosition,
  } = leavingGeofenceData
  const updatedInpixonAssetId = updateGroupIconAndShowLeavingAgent(
    leavingGeofenceGroupsToUpdateIcon,
    agentsToDegroup,
    assetKit,
    assetKitId,
    iconScale,
    useGeofenceCentroid,
    geofenceInstances,
    jibestreamController
  )
  const remainingAgentsHaveDuress = checkRemainingAgentsHaveDuress(
    currentMapRender,
    newAgentTelemetry.trackingId,
    leavingGeofenceData.groupToUpdateIcon.geoFenceId
  )
  moveAgentToNewGeofence(
    degroupedAgentsToUpdatePosition,
    assetKit,
    jibestreamController,
    geofenceInstances,
    useGeofenceCentroid,
    addGeofenceHighlight,
    removeGeofenceHighlight,
    remainingAgentsHaveDuress
  )
  const updatedMapRender = calculateMapRenderToUpdateForAgentOnMap(
    currentMapRender,
    newAgentTelemetry
  )
  return {
    nextInpixonAssetId: updatedInpixonAssetId,
    updatedMapRender,
  }
}
