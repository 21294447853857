import { Divider, Grid, Typography } from '@mui/material'
import { createStyles } from '@mui/styles'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { filterDivStyle } from '../../helpers/filters'
import { AlertFilters } from '../../models'
import { flexLayout } from '../../styles'
import AlertFilterChips from './AlertFilterChips'
import AlertFilterOptions from './AlertFilterOptions'

interface Props {
  alertFilters: AlertFilters
  onFilterChanged: (filters: AlertFilters) => void
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      ...flexLayout({ direction: 'column' }),
    },
    filterControl: {
      marginBottom: 12,
      marginTop: 12,
    },
    commonFilter: {
      width: '100%',
      height: '19px',
      flexGrow: 0,
      fontFamily: 'Ubuntu',
      fontSize: '12px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'left',
      color: '#1c1b1b',
      display: 'inline-block',
      alignItems: 'center',
    },
  })
)

const AlertListFilterContent = (props: Props): JSX.Element => {
  const { alertFilters, onFilterChanged } = props

  const handleAlertFilterChange = (changes: Partial<AlertFilters>): void => {
    const newState = { ...alertFilters, ...changes }
    onFilterChanged(newState)
  }

  const classes = useStyles()

  return (
    <>
      <div style={filterDivStyle}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item>
            <Typography
              variant='body2'
              className={classes.commonFilter}
              style={{
                paddingTop: 14,
                paddingBottom: 14,
              }}
            >
              {'CURRENT FILTERS'}
            </Typography>
          </Grid>
        </Grid>
        <AlertFilterChips
          alertFilterState={alertFilters}
          onAlertFilterChanged={onFilterChanged}
        />
      </div>
      <div style={filterDivStyle}>
        <AlertFilterOptions onAlertFilterChange={handleAlertFilterChange} />
      </div>
    </>
  )
}

export default AlertListFilterContent
