import {
  LocationGeofences,
  Locations,
  TelemetryAsset,
  TelemetryStaff,
} from '../../models'
import { MapRender } from '../../models/realtimeMap'
import {
  AgentInGroup,
  BadgeTelemetryMessageWithAgentEvent,
  DetailDrawerAgentTelemetry,
  DetailDrawerGroupTelemetry,
} from '../../models/telemetry'
import { getLocationAncestors } from '../location'

export function mapBadgeTelemetryToDetailDrawerAgentTelemetry<
  T = TelemetryAsset | TelemetryStaff
>(
  agentTelemetry: BadgeTelemetryMessageWithAgentEvent<T>,
  locationGeofences: LocationGeofences,
  locations: Locations
): DetailDrawerAgentTelemetry<T> {
  let isPrivate = false
  let locationUnitName = ''
  let locationSubUnitName = ''
  const roomLocationGeofence = locationGeofences[agentTelemetry.geoFenceId]
  if (roomLocationGeofence) {
    const roomLocation = locations[roomLocationGeofence.locationId]
    if (roomLocation) {
      const locationAncestors = getLocationAncestors(roomLocation, locations)
      isPrivate = roomLocation.isPrivate ?? false
      locationUnitName = roomLocation.unitName ?? ''
      locationSubUnitName = roomLocation.subUnitName ?? ''
      const { floor, building, buildingGroup } = locationAncestors
      if (!floor || !building || !buildingGroup) {
        console.warn('Unable to resolve location hierarchy for geofence!')
        return {
          agent: agentTelemetry.agent,
          telemetry: agentTelemetry,
          resolvedLocationHierachy: undefined,
        }
      }
      return {
        agent: agentTelemetry.agent,
        telemetry: agentTelemetry,
        resolvedLocationHierachy: {
          roomLocation,
          floorLocation: floor,
          buildingLocation: building,
          buildingGroupLocation: buildingGroup,
          locationUnitName,
          locationSubUnitName,
          isPrivate,
        },
      }
    }
  }
  console.warn('Unable to resolve location hierarchy for geofence!')
  return {
    agent: agentTelemetry.agent,
    telemetry: agentTelemetry,
    resolvedLocationHierachy: undefined,
  }
}

export function mapGroupedTelemetryToDetailDrawerGroupTelemetry(
  groupedTelemetry: MapRender,
  locationGeofences: LocationGeofences,
  locations: Locations
): DetailDrawerGroupTelemetry {
  let isPrivate = false
  let locationUnitName = ''
  let locationSubUnitName = ''
  const agentsInGroup: AgentInGroup[] = groupedTelemetry.renderedAgents.map(
    (telemetry) => {
      return {
        agent: telemetry.agent,
        telemetry: telemetry,
      }
    }
  )
  const roomLocationGeofence = locationGeofences[groupedTelemetry.geoFenceId]
  if (roomLocationGeofence) {
    const roomLocation = locations[roomLocationGeofence.locationId]
    if (roomLocation) {
      const locationAncestors = getLocationAncestors(roomLocation, locations)
      isPrivate = roomLocation.isPrivate ?? false
      locationUnitName = roomLocation.unitName ?? ''
      locationSubUnitName = roomLocation.subUnitName ?? ''
      const { floor, building, buildingGroup } = locationAncestors
      if (!floor || !building || !buildingGroup) {
        console.warn('Unable to resolve location hierarchy for geofence!')
        return {
          agentsInGroup,
          resolvedLocationHierachy: undefined,
        }
      }
      return {
        agentsInGroup,
        resolvedLocationHierachy: {
          roomLocation,
          floorLocation: floor,
          buildingLocation: building,
          buildingGroupLocation: buildingGroup,
          locationUnitName,
          locationSubUnitName,
          isPrivate,
        },
      }
    }
  }
  console.warn('Unable to resolve location hierarchy for geofence!')
  return {
    agentsInGroup,
    resolvedLocationHierachy: undefined,
  }
}
