import { createAsyncAction } from 'typesafe-actions'
import { Role } from '../models/roles'

export const getRolesAction = createAsyncAction(
  'GET_ROLES_REQUEST',
  'GET_ROLES_SUCCESS',
  'GET_ROLES_FAILURE'
)<void, Role[], Error>()

export const getRolesByUserIdAction = createAsyncAction(
  'GET_ROLES_BY_USERID_REQUEST',
  'GET_ROLES_BY_USERID_SUCCESS',
  'GET_ROLES_BY_USERID_FAILURE'
)<string, Role[], Error>()

export const getRoleByIdAction = createAsyncAction(
  'GET_ROLE_BY_ID_REQUEST',
  'GET_ROLE_BY_ID_SUCCESS',
  'GET_ROLE_BY_ID_FAILURE'
)<number, Role, Error>()

export const postRoleAction = createAsyncAction(
  'POST_ROLE_REQUEST',
  'POST_ROLE_SUCCESS',
  'POST_ROLE_FAILURE'
)<Role, Role, Error>()

export const putRoleAction = createAsyncAction(
  'PUT_ROLE_REQUEST',
  'PUT_ROLE_SUCCESS',
  'PUT_ROLE_FAILURE'
)<Role, Role, Error>()

export const deleteRoleAction = createAsyncAction(
  'DELETE_ROLE_REQUEST',
  'DELETE_ROLE_SUCCESS',
  'DELETE_ROLE_FAILURE'
)<number, number, Error>()
