import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React, { useEffect, useState } from 'react'
import { StaffEvent } from '../../models'
import { DataTestIds } from '../../utils/test-utils/dataTestIds'
import { fixedBannerHeight } from '../Banners'
import BannerControl from '../Banners/BannerControl'
import StaffDuressBannerRecord from './StaffDuressBannerRecord'

interface Props {
  items: StaffEvent[]
  onResolve: (duressToResolve: StaffEvent) => void
  duressResolved: boolean
  isSecurityUser: boolean
  canViewOnMap: boolean
}

const StaffDuressBannerCarousel = (props: Props): JSX.Element => {
  const { items, duressResolved, onResolve, isSecurityUser, canViewOnMap } = props

  const [selectedEvent, setSelectedEvent] = useState<number>(1)

  const useStyles = makeStyles(() =>
    createStyles({
      carouselItem: {
        transition: 'top .8s ease',
        maxHeight: fixedBannerHeight,
        position: 'absolute',
        height: '100%',
        width: '100%',
        display: 'flex',
        alignContent: 'center',
      },
    })
  )

  const classes = useStyles()

  // Tim Groven - 11/27/2023 - https://versuscarina.visualstudio.com/Carina/_workitems/edit/33678
  // If viewing the last alert in the list, and it is resolved by someone else, move to the previous
  // alert for viewing.
  useEffect(() => {
    if (selectedEvent > items.length) {
      setSelectedEvent(items.length)
    }
  }, [items, selectedEvent])

  useEffect(() => {
    if (duressResolved) {
      setSelectedEvent(1)
    }
  }, [duressResolved])

  const handleBannerUp = () => {
    setSelectedEvent((currentEvent) => {
      if (currentEvent === 1) {
        return currentEvent
      }
      return currentEvent - 1
    })
  }

  const handleBannerDown = () => {
    setSelectedEvent((currentEvent) => {
      if (currentEvent === items.length) {
        return currentEvent
      }
      return currentEvent + 1
    })
  }

  return items.length > 0 ? (
    <>
      {items.length > 1 && (
        <BannerControl
          currentBannerValue={selectedEvent}
          maxBannerValues={items.length}
          handleUpEvent={handleBannerUp}
          handleDownEvent={handleBannerDown}
        />
      )}
      <div style={{ width: '100%', position: 'relative', overflow: 'hidden' }}>
        {items.map((item, index) => {
          return (
            <div
              className={classes.carouselItem}
              key={index}
              style={{
                top:
                  fixedBannerHeight * index -
                  fixedBannerHeight * (selectedEvent - 1),
              }}
              data-testid={`${DataTestIds.duressBannerItem}-${
                fixedBannerHeight * index -
                fixedBannerHeight * (selectedEvent - 1)
              }`}
            >
              <StaffDuressBannerRecord
                duressEvent={item}
                onResolve={onResolve}
                isSecurityUser={isSecurityUser}
                canViewOnMap={canViewOnMap}
              ></StaffDuressBannerRecord>
            </div>
          )
        })}
      </div>
    </>
  ) : (
    <></>
  )
}

export default StaffDuressBannerCarousel
