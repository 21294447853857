export const downloadUrl = (title: string, url: string) => {
  let a = document.createElement('a')
  a.href = url
  a.setAttribute('type', 'hidden')
  a.download = title
  document.body.appendChild(a)
  a.click()
  return a
}

export const downloadBlob = (title: string, blob: Blob) => {
  let url = URL.createObjectURL(blob)
  let a = downloadUrl(title, url)
  // firefox needs to delay revoking object url
  setTimeout(function () {
    URL.revokeObjectURL(url)
    a.remove()
  }, 100)
}
