import { SignalType, SignalTypePriority } from '../models'
import { ConfidenceConfig } from '../models/inpixonMap'
import { colors } from '../styles/MidmarkTheme'

export const confidenceColor = '#6495ED'

export function getAccuracyConfig(
  signalStatusIds: number[],
  isResolved = true
): ConfidenceConfig {
  const defaultConfig = {
    pulseColor: '#FFFFFF',
    confidenceMax: 9,
    confidencePercent: 1,
    confidenceAlpha: 0,
    confidenceColor: '#FFFFFF',
    pulseVisible: false,
  }
  const prioritizedSignalType: SignalType = Math.max(
    ...signalStatusIds.map((x) => SignalTypePriority[x as SignalType])
  )

  const confidenceValues = { ...defaultConfig }

  confidenceValues.confidenceColor = confidenceColor
  confidenceValues.pulseColor = confidenceColor

  if (isResolved === false) {
    confidenceValues.confidenceColor = colors.duressBubble
    confidenceValues.confidenceAlpha = 0.3
    confidenceValues.pulseVisible = true
  }

  switch (prioritizedSignalType) {
    case SignalType.BLE:
      confidenceValues.confidenceMax = 8
      return confidenceValues
    case SignalType['IR/RF']:
    case SignalType.IR:
      confidenceValues.confidenceMax = 6
      return confidenceValues
  }

  // Unknown
  return defaultConfig
}
