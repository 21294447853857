import { Geofence } from '../models'
import { BaseService } from './baseService'

export class GeofenceApi extends BaseService {
  constructor() {
    super({ entityType: 'geofence' })
  }

  route = 'geofence'

  async getAllGeofences(): Promise<Geofence[]> {
    return await this.get<Geofence[]>(`${this.route}`)
  }
}
