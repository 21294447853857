import { getType } from 'typesafe-actions'
import { OperationsUserAction } from '../actions'
import {
  getAllOperationsUsersAction,
  getOperationsUserByIdAction,
  getOperationsUsersByEmailAction,
  postOperationsUserAction,
  putOperationsUserAction,
  getOperationsUserByNameAction,
  deleteOperationsUserAction,
  acknowledgeHttpError,
} from '../actions/operationsUsers'
import {
  OperationsUser,
  OperationsUsers,
  OperationsUsersState,
} from '../models/operationsUsers'
import {
  mergeRecord,
  mergeRecords,
  deleteRecord,
} from '@midmarkrtls/common/utils'
import { FetchingStatus } from '@midmarkrtls/common/models/fetchingStatus'
import { HttpErrorResponse } from '@midmarkrtls/common/services/http'

const initialState: OperationsUsersState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

const key = 'id'
const operationsUsersReducer = (
  state: OperationsUsersState = initialState,
  action: OperationsUserAction
): OperationsUsersState => {
  switch (action.type) {
    case getType(acknowledgeHttpError):
      if (state.error !== undefined) {
        state.error.acknowledged = action.payload
        return {
          ...state,
        }
      }
      return {
        ...state,
      }
    case getType(getAllOperationsUsersAction.success):
    case getType(getOperationsUsersByEmailAction.success):
      return {
        data: mergeRecords<OperationsUsers, OperationsUser, typeof key>(
          state.data,
          action.payload,
          key
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getOperationsUserByNameAction.success):
    case getType(getOperationsUserByIdAction.success):
    case getType(postOperationsUserAction.success):
    case getType(putOperationsUserAction.success):
      return {
        data: mergeRecord<OperationsUsers, OperationsUser, typeof key>(
          state.data,
          action.payload,
          key
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(deleteOperationsUserAction.success):
      return {
        data: deleteRecord<OperationsUsers, OperationsUser>(
          state.data,
          action.payload
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getOperationsUserByNameAction.request):
    case getType(getAllOperationsUsersAction.request):
    case getType(getOperationsUserByIdAction.request):
    case getType(getOperationsUsersByEmailAction.request):
    case getType(postOperationsUserAction.request):
    case getType(putOperationsUserAction.request):
    case getType(deleteOperationsUserAction.request):
      return {
        ...state,
        status: FetchingStatus.Request,
      }

    case getType(getOperationsUserByNameAction.failure):
    case getType(getAllOperationsUsersAction.failure):
    case getType(getOperationsUserByIdAction.failure):
    case getType(getOperationsUsersByEmailAction.failure):
    case getType(postOperationsUserAction.failure):
    case getType(putOperationsUserAction.failure):
    case getType(deleteOperationsUserAction.failure):
      return {
        ...state,
        status: FetchingStatus.Failure,
        error: action.payload as HttpErrorResponse,
      }

    default:
      return state
  }
}

export default operationsUsersReducer
