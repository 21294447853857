import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { OwnerAction } from '../actions'
import { getAllOwnersAction } from '../actions/owners'
import { OwnersApi } from '../middleware'
import { OwnersState } from '../models'

type OwnerEpic = Epic<
  OwnerAction,
  OwnerAction,
  OwnersState,
  { ownersApi: OwnersApi }
>

export const getAllOwnersEpic: OwnerEpic = (action$, _state, { ownersApi }) => {
  return action$.pipe(
    filter(isActionOf(getAllOwnersAction.request)),
    mergeMap((action) =>
      from(ownersApi.getAllOwners()).pipe(
        map((owners) => getAllOwnersAction.success(owners)),
        catchError((error) => of(getAllOwnersAction.failure(error)))
      )
    )
  )
}
