import {
  BaseBadgeTelemetryMessage,
  BaseBadgeTelemetryState,
} from '../models/telemetry'

export const selectBaseBadgeTelemetry = ({
  baseBadgeTelemetry,
}: {
  baseBadgeTelemetry: BaseBadgeTelemetryState
}): BaseBadgeTelemetryMessage[] => baseBadgeTelemetry.badgeTelemetry
