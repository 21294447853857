import React from 'react'
import { RightDrawerLayout } from '../../../../components/Common/RightDrawer'
import AlertDetailsPaneHeader from '../../../../components/AlertList/AlertDetailsPaneHeader'
import { AlertActiveConfig } from '../../../../models/alert'
import AlertDetailsPane from '../../../../components/AlertList/AlertDetailsPane'

interface Props {
  activeConfig: AlertActiveConfig
  activeConfigLoaded: boolean
  canEdit: boolean
}

const AlertConfigRightDrawer = (props: Props): JSX.Element => {
  const { activeConfig, activeConfigLoaded, canEdit } = props

  return (
    <RightDrawerLayout
      header={
        <AlertDetailsPaneHeader
          alert={activeConfig}
          isReviewed={true}
          canEdit={canEdit}
        />
      }
      content={
        <AlertDetailsPane
          alert={activeConfig}
          isLoading={!activeConfigLoaded}
        />
      }
    />
  )
}

export default AlertConfigRightDrawer
