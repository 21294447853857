import { getType } from 'typesafe-actions'
import { StaffDuressAction } from '../actions'
import { StaffDuressState } from '../models/staffDuress'
import {
  setActiveDuressEvents,
  setAllDuressFilteredByUser,
  setShowNowAndShowNextDuress,
} from '../actions/staffDuress'

const initialState: StaffDuressState = {
  allDuressFilteredByUser: [],
  activeDuressEvents: [],
  showNowStaffDuressEvent: undefined,
  showNextStaffDuressEvent: undefined,
  filteredDuressLoaded: false,
}

const staffDuressReducer = (
  state: StaffDuressState = initialState,
  action: StaffDuressAction
): StaffDuressState => {
  switch (action.type) {
    case getType(setAllDuressFilteredByUser):
      return {
        ...state,
        allDuressFilteredByUser: action.payload,
        filteredDuressLoaded: true,
      }
    case getType(setActiveDuressEvents):
      return {
        ...state,
        activeDuressEvents: action.payload,
      }
    case getType(setShowNowAndShowNextDuress):
      return {
        ...state,
        showNowStaffDuressEvent: action.payload.showNow,
        showNextStaffDuressEvent: action.payload.showNext,
      }
    default:
      return state
  }
}

export default staffDuressReducer
