import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { ManufacturerAction } from '../actions'
import { getAllManufacturersAction } from '../actions/manufacturers'
import { ManufacturersApi } from '../middleware/manufacturers'
import { ManufacturersState } from '../models'

type ManufacturerEpic = Epic<
  ManufacturerAction,
  ManufacturerAction,
  ManufacturersState,
  { manufacturersApi: ManufacturersApi }
>

export const getAllManufacturersEpic: ManufacturerEpic = (
  action$,
  state,
  { manufacturersApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllManufacturersAction.request)),
    mergeMap((action) =>
      from(manufacturersApi.getAllManufacturers()).pipe(
        map((manufacturers) =>
          getAllManufacturersAction.success(manufacturers)
        ),
        catchError((error) => of(getAllManufacturersAction.failure(error)))
      )
    )
  )
}
