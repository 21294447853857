import { generateAssetConfig, getCoordinates } from '.'
import { getGeoFenceInstancesCenterByPoint } from '../../hooks/inpixon'
import {
  InpixonAssetConfig,
  Assets,
  BadgeSignalStatus,
  IconType,
  InpixonAssetRef,
  MapGroups,
  MapUpdate,
  MapUpdateType,
  StaffMembers,
  FeatureFlags,
  StaffEvents,
  MapOutline,
  AgentTypePlural,
  Staff,
  Asset,
  MapOutlineState,
  InpixonAssetVisibilityUpdate,
  InpixonAssetPositionUpdate,
} from '../../models'
import { getAccuracyConfig } from '../accuracy'
import {
  badgeSignalStatus,
  staffDuressEnabled,
  staffDuressResolved,
  staffAssistEnabled,
  staffAssistResolved,
} from '..'
import { getIconScale } from './assetCreate'
import { isLowBattery } from '../battery'
import { colors } from '../../styles/MidmarkTheme'
import { removeStaffMembersFromMapAction } from '../../actions/staff'
import { Dispatch } from 'react'
import { removeAssetsFromMapAction } from '../../actions/assets'
import { getBadgeStateSingle } from './badgeState'
import { removeFilteredTrackingIds } from './mapProcess'
import { addAgentReferenceAction } from '../../actions/agentReference'

export interface agentUpdates {
  agentsToCreate: InpixonAssetConfig[]
  agentsToUpdate: InpixonAssetConfig[]
  agentPositionsToUpdate: InpixonAssetPositionUpdate[]
  agentsToHideShow: InpixonAssetVisibilityUpdate[]
  agentsToHandleDesiredGeofenceUpdate: MapOutline[]
  agentsToHandleDesiredGeofenceDelete: string[]
  agentsToDelete: number[]
}

export function processAgentUpdates(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jibestream: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  assetKit: any,
  privateGeofenceIds: number[],
  mapId: number,
  dispatch: Dispatch<any>,
  currentGeofenceMapState: MapOutlineState,
  desiredGeofenceMapState: MapOutlineState,
  handleClearDesiredGeofenceMapState: () => void,
  selectedBadgeId: string,
  existingMapGroups: MapGroups,
  mapUpdates?: MapUpdate[] | null,
  inpixonReference?: InpixonAssetRef[],
  currentAgentReference?: number,
  assets?: Assets,
  staff?: StaffMembers,
  featureFlags?: FeatureFlags,
  duressNotifications?: StaffEvents,
  assistNotifications?: StaffEvents,
  agentTypes?: string[]
): agentUpdates | undefined {
  let visibleTrackingIds: string[] = []
  let currentAgentReferenceTemp = currentAgentReference

  const duressStaffShow =
    featureFlags === undefined
      ? false
      : Object.values(featureFlags).filter(
          (x) => x.name.includes('/duress/staff') == true && x.enabled === true
        ).length > 0

  const useGeofenceCentroid =
    featureFlags === undefined
      ? false
      : Object.values(featureFlags).filter(
          (x) =>
            x.name.includes('GeofenceCentroid') == true && x.enabled === true
        ).length > 0

  if (inpixonReference && (assets || staff)) {
    const agentTrackingIds: string[] = []
    if (
      assets &&
      agentTypes?.includes(AgentTypePlural[AgentTypePlural.Assets])
    ) {
      const mapLeaversBadges = mapUpdates?.filter(
        (x) => x.mapId && x.mapId !== mapId
      )
      const mapLeavers: Asset[] = []
      Object.values(assets).map((x) => {
        if (mapLeaversBadges?.some((y) => y.trackingId === x.badgeIds[0])) {
          mapLeavers.push(x)
        } else if (
          (x.badgeTelemetry?.mapId && x.badgeTelemetry.mapId === mapId) ||
          mapUpdates?.some(
            (s) => s.trackingId === x.badgeIds[0] && s.mapId === mapId
          )
        ) {
          agentTrackingIds.push(x.badgeIds[0])
        }
      })
      if (mapLeavers.length) {
        dispatch(removeAssetsFromMapAction(mapLeavers.map((x) => x.agentGuid)))
      }
    }

    if (staff && agentTypes?.includes(AgentTypePlural[AgentTypePlural.Staff])) {
      const mapLeaversBadges = mapUpdates?.filter(
        (x) => x.mapId && x.mapId !== mapId
      )
      const mapLeavers: Staff[] = []
      Object.values(staff).map((x) => {
        if (mapLeaversBadges?.some((y) => y.trackingId === x.badgeIds[0])) {
          mapLeavers.push(x)
        } else if (
          (x.badgeTelemetry?.mapId && x.badgeTelemetry.mapId === mapId) ||
          mapUpdates?.some(
            (s) => s.trackingId === x.badgeIds[0] && s.mapId === mapId
          )
        ) {
          agentTrackingIds.push(x.badgeIds[0])
        }
      })
      if (mapLeavers.length) {
        dispatch(
          removeStaffMembersFromMapAction(mapLeavers.map((x) => x.agentGuid))
        )
      }
    }

    // filter out tracking ids that are moving into a private location
    visibleTrackingIds = agentTrackingIds

    removeFilteredTrackingIds(
      visibleTrackingIds,
      assetKit,
      inpixonReference,
      agentTypes
    )
  }

  if (mapUpdates && (assets || staff) && inpixonReference) {
    const agentsToCreate: InpixonAssetConfig[] = []
    const agentsToUpdate: InpixonAssetConfig[] = []
    const agentPositionsToUpdate: InpixonAssetPositionUpdate[] = []
    const agentsToHideShow: InpixonAssetVisibilityUpdate[] = []
    const agentsToHandleDesiredGeofenceUpdate: MapOutline[] = []
    const agentsToHandleDesiredGeofenceDelete: string[] = []
    const agentsToDelete: number[] = []

    if (
      mapUpdates.filter((x) => x.isRefresh).length &&
      !agentTypes?.includes(AgentTypePlural[AgentTypePlural.Staff])
    ) {
      jibestream?.control?.currentMapView?.mapLayers
        ?.find((x: any) => x.name === 'Geofences')
        ?.clearShapes()

      handleClearDesiredGeofenceMapState()
    }

    if (duressNotifications && Object.values(duressNotifications).length) {
      const mapUpdatesForDuress: MapUpdate[] = []
      Object.values(duressNotifications).map((x) => {
        const mapUpdate = mapUpdates?.find((y) => y.trackingId === x.badgeId)
        if (!mapUpdate) {
          const badgeState = getBadgeStateSingle(x.badgeId)
          if (badgeState) {
            const groupState = existingMapGroups[badgeState.geoFenceId]

            if (
              !groupState ||
              !groupState.trackingIds.includes(badgeState.trackingId)
            ) {
              mapUpdatesForDuress.push({
                trackingId: badgeState.trackingId,
                signalTypeId: badgeState.signalTypeId,
                lon: badgeState.lon,
                lat: badgeState.lat,
                locationUncertainty: badgeState.locationUncertainty,
                mapId: badgeState.mapId,
                geoFenceId: badgeState.geoFenceId,
                timestamp: badgeState.timestamp,
                updateType: MapUpdateType.Agent,
                agents: [],
                isRefresh: false,
              })
            }
          }
        }
      })
      mapUpdates = [...mapUpdates, ...mapUpdatesForDuress]
    }

    if (assistNotifications && Object.values(assistNotifications).length) {
      const mapUpdatesForAssist: MapUpdate[] = []
      Object.values(assistNotifications).map((x) => {
        const mapUpdate = mapUpdates?.find((y) => y.trackingId === x.badgeId)
        if (!mapUpdate) {
          const badgeState = getBadgeStateSingle(x.badgeId)
          if (badgeState) {
            const groupState = existingMapGroups[badgeState.geoFenceId]

            if (
              !groupState ||
              !groupState.trackingIds.includes(badgeState.trackingId)
            ) {
              mapUpdatesForAssist.push({
                trackingId: badgeState.trackingId,
                signalTypeId: badgeState.signalTypeId,
                lon: badgeState.lon,
                lat: badgeState.lat,
                locationUncertainty: badgeState.locationUncertainty,
                mapId: badgeState.mapId,
                geoFenceId: badgeState.geoFenceId,
                timestamp: badgeState.timestamp,
                updateType: MapUpdateType.Agent,
                agents: [],
                isRefresh: false,
              })
            }
          }
        }
      })

      mapUpdates = [...mapUpdates, ...mapUpdatesForAssist]
    }

    mapUpdates
      .filter((x) => x.mapId && x.mapId === mapId)
      .map(({ trackingId, lat, lon, signalTypeId, geoFenceId }) => {
        if (trackingId && visibleTrackingIds.includes(trackingId)) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          let assetKitId = inpixonReference.find((x: any) =>
            x.trackingIds?.includes(trackingId)
          )?.assetKitId

          if (!assetKitId) {
            // try creating the reference after load
            const assetRecord = Object.values(assets ?? []).find(
              (asset: Asset) => asset.badgeIds.includes(trackingId)
            )
            const staffRecord = Object.values(staff ?? []).find(
              (staff: Staff) => staff.badgeIds.includes(trackingId)
            )
            if (assetRecord && currentAgentReferenceTemp) {
              dispatch(
                addAgentReferenceAction({
                  agentReferences: [
                    {
                      agentGuid: assetRecord.agentGuid,
                      trackingIds: assetRecord.badgeIds,
                      assetKitId: currentAgentReferenceTemp,
                    },
                  ],
                  currentReference: currentAgentReferenceTemp++,
                })
              )
            }
            if (staffRecord && currentAgentReferenceTemp) {
              dispatch(
                addAgentReferenceAction({
                  agentReferences: [
                    {
                      agentGuid: staffRecord.agentGuid,
                      trackingIds: staffRecord.badgeIds,
                      assetKitId: currentAgentReferenceTemp,
                    },
                  ],
                  currentReference: currentAgentReferenceTemp++,
                })
              )
            }
            assetKitId = currentAgentReferenceTemp

            if (!assetKitId) {
              return
            }
          }

          const accuracyConfig = getAccuracyConfig([signalTypeId ?? 0])
          const matchingAsset = Object.values(assets ?? []).find((a) =>
            a.badgeIds.includes(trackingId)
          )
          const matchingStaff = Object.values(staff ?? []).find((a) =>
            a.badgeIds.includes(trackingId)
          )

          //Check if Agent is in a group, update accuracy config to reduce group click issue
          if (
            (existingMapGroups[geoFenceId]?.trackingIds.includes(trackingId) &&
              existingMapGroups[geoFenceId]?.trackingIds.length > 1) ??
            false
          ) {
            accuracyConfig.confidenceAlpha = 0.0000001
            accuracyConfig.confidencePercent = 0.1
            accuracyConfig.confidenceMax = 0.1
          } else {
            accuracyConfig.confidenceAlpha = 0
            accuracyConfig.confidencePercent = 1
            accuracyConfig.confidenceMax = 9
          }

          const inCreateArray = agentsToCreate.find((x) => x.id === assetKitId)
          const inpixonAsset = assetKit.getAssetById(assetKitId)

          let telemetryCoords
          let coords
          let positionCoords: [number, number]
          if (lat && lon) {
            telemetryCoords = getCoordinates(lat, lon, jibestream)
            coords = getGeoFenceInstancesCenterByPoint(telemetryCoords, mapId)

            if (!coords.length) {
              return
            }
            positionCoords = useGeofenceCentroid
              ? [coords[0], coords[1]]
              : telemetryCoords
          } else {
            return
          }

          const iconScale = getIconScale(
            jibestream.control.currentMap.mmPerPixel
          )

          //Assets
          if (
            (matchingAsset || inCreateArray) &&
            agentTypes?.includes(AgentTypePlural[AgentTypePlural.Assets])
          ) {
            // move existing badge
            if (inpixonAsset) {
              agentPositionsToUpdate.push({
                id: assetKitId,
                position: positionCoords,
              })
              agentsToHideShow.push({
                id: inpixonAsset.id,
                confidencePercent: accuracyConfig.confidencePercent,
                pulseVisible: false,
                confidenceMax: accuracyConfig.confidenceMax,
              })
            } else {
              agentsToCreate.push(
                generateAssetConfig(
                  {
                    assetID: assetKitId,
                    name: matchingAsset?.displayName ?? trackingId,
                    coordinates: positionCoords,
                    type: IconType.Asset,
                    height: 4,
                    isLowBattery: isLowBattery(matchingAsset?.isLowBattery),
                    showIcon: true,
                    image: matchingAsset?.iconUrl,
                    iconScale: iconScale,
                    map: jibestream.control?.map,
                    isConfigured: false,
                    hasIssues: false,
                    ...accuracyConfig,
                  },
                  badgeSignalStatus(matchingAsset?.badgeTelemetry.timestamp)
                )
              )
            }
          }
          //Staff
          if (
            (matchingStaff || inCreateArray) &&
            agentTypes?.includes(AgentTypePlural[AgentTypePlural.Staff])
          ) {
            let isDuress = false
            let isDuressResolved = false
            let isAssist = false
            let isAssistResolved = false
            const isPrivate = privateGeofenceIds.includes(geoFenceId)

            if (duressStaffShow) {
              if (matchingStaff) {
                isDuress = staffDuressEnabled(
                  matchingStaff.badgeIds[0],
                  duressNotifications
                )
                isDuressResolved = staffDuressResolved(
                  matchingStaff.badgeIds[0],
                  duressNotifications
                )

                if (!isDuress) {
                  isAssist = staffAssistEnabled(
                    matchingStaff.badgeIds[0],
                    assistNotifications
                  )
                  isAssistResolved = staffAssistResolved(
                    matchingStaff.badgeIds[0],
                    assistNotifications
                  )
                }
              }
            }
            if (!isPrivate || isDuress || isAssist) {
              if (coords?.length) {
                if (inpixonAsset) {
                  agentPositionsToUpdate.push({
                    id: assetKitId,
                    position: positionCoords,
                    ...accuracyConfig,
                  })
                  agentsToHideShow.push({
                    id: inpixonAsset.id,
                    confidencePercent: accuracyConfig.confidencePercent,
                    pulseVisible: false,
                    confidenceMax: accuracyConfig.confidenceMax,
                  })

                  let duressEnabled = false
                  let assistEnabled = false
                  if (isDuress) {
                    duressEnabled = true
                    accuracyConfig.confidenceColor = colors.duress
                    if (isDuressResolved) {
                      accuracyConfig.confidenceColor = colors.staff
                      accuracyConfig.pulseVisible = false
                      duressEnabled = false
                      if (selectedBadgeId !== trackingId) {
                        accuracyConfig.confidenceAlpha = 0.000001
                      }
                      const updatedDuressAssetConfig = generateAssetConfig(
                        {
                          assetID: assetKitId,
                          name: matchingStaff?.displayName ?? trackingId,
                          coordinates: positionCoords,
                          type: IconType.Staff,
                          height: 4,
                          isLowBattery: isLowBattery(
                            matchingStaff?.isLowBattery
                          ),
                          showIcon: true,
                          image: matchingStaff?.iconUrl,
                          iconScale: iconScale,
                          map: jibestream.control?.map,
                          isConfigured: false,
                          isDuress: duressEnabled,
                          hasIssues:
                            badgeSignalStatus(
                              matchingStaff?.badgeTelemetry.timestamp
                            ) !== BadgeSignalStatus.Current,
                          ...accuracyConfig,
                        },
                        badgeSignalStatus(
                          matchingStaff?.badgeTelemetry.timestamp
                        )
                      )
                      if (
                        inpixonAsset.pulse._pulseVisible !==
                        updatedDuressAssetConfig.pulseVisible
                      ) {
                        agentsToDelete.push(assetKitId)
                        agentsToCreate.push(updatedDuressAssetConfig)
                      } else {
                        updatedDuressAssetConfig.url =
                          updatedDuressAssetConfig.url
                        agentsToUpdate.push(updatedDuressAssetConfig)
                      }
                      agentsToHandleDesiredGeofenceDelete.push(trackingId)
                    } else {
                      const currentGeofenceInstance =
                        currentGeofenceMapState[trackingId]
                      const desiredGeofenceInstance =
                        desiredGeofenceMapState[trackingId]

                      if (currentGeofenceInstance) {
                        if (desiredGeofenceInstance) {
                          if (
                            currentGeofenceInstance.geoLocationId !== geoFenceId
                          ) {
                            agentsToHandleDesiredGeofenceUpdate.push({
                              badgeId: trackingId,
                              geoLocationId: geoFenceId,
                            })
                          }
                        } else {
                          agentsToHandleDesiredGeofenceUpdate.push({
                            badgeId: trackingId,
                            geoLocationId: geoFenceId,
                          })
                        }
                      } else {
                        if (!desiredGeofenceInstance) {
                          agentsToHandleDesiredGeofenceUpdate.push({
                            badgeId: trackingId,
                            geoLocationId: geoFenceId,
                          })
                        }
                      }

                      const updatedDuressAssetConfig = generateAssetConfig({
                        assetID: assetKitId,
                        name: matchingStaff?.displayName ?? trackingId,
                        coordinates: positionCoords,
                        type: IconType.Staff,
                        height: 4,
                        isLowBattery: isLowBattery(matchingStaff?.isLowBattery),
                        showIcon: true,
                        image: matchingStaff?.iconUrl,
                        iconScale: iconScale,
                        map: jibestream.control?.map,
                        isConfigured: false,
                        isDuress: duressEnabled,
                        hasIssues:
                          badgeSignalStatus(
                            matchingStaff?.badgeTelemetry.timestamp
                          ) !== BadgeSignalStatus.Current,
                        ...accuracyConfig,
                      })

                      if (
                        inpixonAsset.pulse._pulseVisible !==
                        updatedDuressAssetConfig.pulseVisible
                      ) {
                        agentsToDelete.push(assetKitId)
                        agentsToCreate.push(updatedDuressAssetConfig)
                      } else {
                        updatedDuressAssetConfig.url =
                          updatedDuressAssetConfig.url
                        agentsToUpdate.push(updatedDuressAssetConfig)
                      }
                    }
                  } else if (isAssist) {
                    assistEnabled = true
                    accuracyConfig.confidenceColor =
                      colors.assistConfidenceBubble
                    if (isAssistResolved) {
                      accuracyConfig.confidenceColor = colors.staff
                      accuracyConfig.pulseVisible = false
                      assistEnabled = false
                      if (selectedBadgeId !== trackingId) {
                        accuracyConfig.confidenceAlpha = 0.000001
                      }
                      const updatedAssistAssetConfig = generateAssetConfig({
                        assetID: assetKitId,
                        name: matchingStaff?.displayName ?? trackingId,
                        coordinates: positionCoords,
                        type: IconType.Staff,
                        height: 4,
                        isLowBattery: isLowBattery(matchingStaff?.isLowBattery),
                        showIcon: true,
                        image: matchingStaff?.iconUrl,
                        iconScale: iconScale,
                        map: jibestream.control?.map,
                        isConfigured: false,
                        isAssist: assistEnabled,
                        hasIssues:
                          badgeSignalStatus(
                            matchingStaff?.badgeTelemetry.timestamp
                          ) !== BadgeSignalStatus.Current,
                        ...accuracyConfig,
                      })
                      if (
                        inpixonAsset.pulse._pulseVisible !==
                        updatedAssistAssetConfig.pulseVisible
                      ) {
                        agentsToDelete.push(assetKitId)
                        agentsToCreate.push(updatedAssistAssetConfig)
                      } else {
                        updatedAssistAssetConfig.url =
                          updatedAssistAssetConfig.url
                        agentsToUpdate.push(updatedAssistAssetConfig)
                      }
                      agentsToHandleDesiredGeofenceDelete.push(trackingId)
                    } else {
                      if (selectedBadgeId !== trackingId) {
                        accuracyConfig.confidenceAlpha = 0.000001
                      }
                      const updatedAssistAssetConfig = generateAssetConfig({
                        assetID: assetKitId,
                        name: matchingStaff?.displayName ?? trackingId,
                        coordinates: positionCoords,
                        type: IconType.Staff,
                        height: 4,
                        isLowBattery: isLowBattery(matchingStaff?.isLowBattery),
                        showIcon: true,
                        image: matchingStaff?.iconUrl,
                        iconScale: iconScale,
                        map: jibestream.control?.map,
                        isConfigured: false,
                        isAssist: assistEnabled,
                        hasIssues:
                          badgeSignalStatus(
                            matchingStaff?.badgeTelemetry.timestamp
                          ) !== BadgeSignalStatus.Current,
                        ...accuracyConfig,
                      })

                      if (
                        inpixonAsset.pulse._pulseVisible !==
                        updatedAssistAssetConfig.pulseVisible
                      ) {
                        agentsToDelete.push(assetKitId)
                        agentsToCreate.push(updatedAssistAssetConfig)
                      } else {
                        updatedAssistAssetConfig.url =
                          updatedAssistAssetConfig.url
                        agentsToUpdate.push(updatedAssistAssetConfig)
                      }
                    }
                  }
                } else {
                  //new agent
                  let duressEnabled = false
                  let assistEnabled = false
                  accuracyConfig.confidenceColor = colors.staff
                  accuracyConfig.confidenceAlpha = 0
                  if (isDuress) {
                    duressEnabled = true
                    if (isDuressResolved) {
                      duressEnabled = false
                      accuracyConfig.confidenceColor = colors.staff
                      accuracyConfig.confidenceAlpha = 0
                      agentsToHandleDesiredGeofenceDelete.push(trackingId)
                    } else {
                      agentsToHandleDesiredGeofenceUpdate.push({
                        badgeId: trackingId,
                        geoLocationId: geoFenceId,
                      })
                    }
                  } else if (isAssist) {
                    assistEnabled = true
                    if (isAssistResolved) {
                      assistEnabled = false
                      accuracyConfig.confidenceColor = colors.staff
                      accuracyConfig.confidenceAlpha = 0
                    } else if (trackingId === selectedBadgeId) {
                      accuracyConfig.confidenceColor =
                        colors.assistConfidenceBubble
                    }
                  }
                  agentsToCreate.push(
                    generateAssetConfig(
                      {
                        assetID: assetKitId,
                        name: matchingStaff?.displayName ?? trackingId,
                        coordinates: positionCoords,
                        type: IconType.Staff,
                        height: 4,
                        isLowBattery: isLowBattery(matchingStaff?.isLowBattery),
                        showIcon: true,
                        image: matchingStaff?.iconUrl,
                        iconScale: iconScale,
                        map: jibestream.control?.map,
                        isConfigured: false,
                        isDuress: duressEnabled,
                        isAssist: assistEnabled,
                        hasIssues: false,
                        ...accuracyConfig,
                      },
                      badgeSignalStatus(matchingStaff?.badgeTelemetry.timestamp)
                    )
                  )
                }
              }
            }
          }
        }
      })

    return {
      agentPositionsToUpdate: agentPositionsToUpdate,
      agentsToCreate: agentsToCreate,
      agentsToDelete: agentsToDelete,
      agentsToHandleDesiredGeofenceDelete: agentsToHandleDesiredGeofenceDelete,
      agentsToHandleDesiredGeofenceUpdate: agentsToHandleDesiredGeofenceUpdate,
      agentsToHideShow: agentsToHideShow,
      agentsToUpdate: agentsToUpdate,
    }
  }
}
