import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AssetsState, FilterRequest } from '../../models'
import { getPaginatedAssetsAction } from '../../actions/assets'
import { buildPaginatedParams } from '../../helpers'
import { useAssetFilters, useSearchFilter } from '..'
import { assetFilterMap } from '../../helpers/filterMap/assetFilterMap'
import { shouldDispatch } from '../../helpers/shouldDispatch'
import { batteryStatusSearch } from '../../filters'

const useFetchPaginatedAssets = (
  filterRequest: FilterRequest,
  resetPageIndex: () => void,
  shouldRefresh = false
) => {
  const dispatch = useDispatch()
  const [cachedPage, setCachedPage] = useState(0)
  const [cachedSize, setCachedSize] = useState(0)
  const [assetState, setAssetState] = useState<unknown>({})
  const [searchState, setSearchState] = useState<string | undefined>(undefined)

  const {
    status,
    assetsByLocationLastRefreshTime,
    data: assets,
    recordCount,
    page,
    prevLink,
    nextLink,
  } = useSelector(({ assets }: { assets: AssetsState }) => assets)

  const { filter: searchFilter } = useSearchFilter(
    [
      'agentGuid',
      'badgeIds',
      'assetTypeId',
      'assetSubTypeId',
      'hasWorkOrder',
      'manufacturerGuid',
      'hasRecall',
      'iconUrl',
      'badgeTelemetry',
      'icon',
      'badgeLocation',
      'venueId',
      'actions',
      'signalTypeIds',
    ],
    {
      preventativeMaintenanceDate: 'datetime',
      tagBatteryChangeDate: 'datetime',
      isLowBattery: batteryStatusSearch,
    }
  )

  const { filterState: assetFilters } = useAssetFilters()

  useEffect(() => {
    let requestNewData = false
    //Check for page change
    if (filterRequest.page !== cachedPage) {
      setCachedPage(filterRequest.page)
      requestNewData = true
    }
    //check for page size change
    if (filterRequest.size !== cachedSize) {
      setCachedSize(filterRequest.size)
      requestNewData = true
    }
    //check for filter change
    if (JSON.stringify(assetState) !== JSON.stringify(assetFilters)) {
      setAssetState(assetFilters)
      requestNewData = true
      filterRequest.page = 1 //Reset Page if filter changes
      resetPageIndex()
    }
    //Check for search change
    if (searchState !== searchFilter.value) {
      setSearchState(searchFilter.value)
      requestNewData = true
      filterRequest.page = 1 //Reset Page if search changes
      resetPageIndex()
    }
    //If change detected, Dispatch for requested data
    if (
      requestNewData ||
      shouldDispatch(status, assetsByLocationLastRefreshTime) ||
      shouldRefresh
    ) {
      filterRequest.search = searchFilter.value
      filterRequest.lowRTLSBattery = assetFilters?.isLowBattery

      if (assetFilters?.tagBatteryChangeDate) {
        filterRequest.rtlsBatteryChangeDate = new Date(
          assetFilters?.tagBatteryChangeDate
        ).toDateString()
      }

      filterRequest.hasBadge = assetFilters?.hasBadge
      filterRequest.rtlsSignalType = assetFilters?.signalType?.map(
        (x: any) => x.id
      )
      filterRequest.onWatchlist = assetFilters?.isWatchlist
      filterRequest.assetFilterRequest = assetFilterMap(assetFilters)
      if (assetFilters.columnSort?.length) {
        // We use tagBatteryChangeDateString in the Asset Management List, so have to catch
        // it and change it to the field name that Ops API can sort by
        if (assetFilters.columnSort[0].id === 'tagBatteryChangeDateString') {
          filterRequest.sortBy = 'tagBatteryChangeDate'
        } else if (assetFilters.columnSort[0].id === 'cmmsManagedDisplay') {
          filterRequest.sortBy = 'cmmsManaged'
        } else {
          filterRequest.sortBy = assetFilters.columnSort[0].id
        }

        filterRequest.sortOrderDesc = assetFilters.columnSort[0].desc
      }
      dispatch(
        getPaginatedAssetsAction.request({
          filters: buildPaginatedParams(filterRequest),
        })
      )
    }
  }, [
    assetsByLocationLastRefreshTime,
    dispatch,
    status,
    shouldRefresh,
    filterRequest,
    assetFilters,
    searchFilter,
    cachedPage,
    cachedSize,
    assetState,
    searchState,
  ])

  return {
    assets,
    status,
    recordCount,
    page,
    prevLink,
    nextLink,
  }
}

export { useFetchPaginatedAssets }
