import { useEffect, useState } from 'react'
import useSnackbar from '../components/Common/Snackbar/useSnackbar'
import { SnackbarType } from '../models/snackbarType'

export enum RequestType {
  SAVING,
  DELETING,
}

const useDeleteSuccessFailureUtility = (
  isSuccessful: boolean,
  isFailed: boolean,
  successMessage: string,
  failureMessage: string,
  handleCloseModal?: () => void,
  handleCloseDetailDrawer?: () => void
) => {
  const { setSnackbarData } = useSnackbar()
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)

  useEffect(() => {
    if (isDeleting) {
      if (isSuccessful) {
        setSnackbarData({
          message: successMessage,
          type: SnackbarType.Success,
          autoClose: 10000,
          link: null,
          linkText: null,
        })
        setIsDeleting(false)
        if (handleCloseModal) {
          handleCloseModal()
        }
        if (handleCloseDetailDrawer) {
          handleCloseDetailDrawer()
        }
      } else if (isFailed) {
        setShowError(true)
        setSnackbarData({
          message: failureMessage,
          type: SnackbarType.Error,
          autoClose: 10000,
          link: null,
          linkText: null,
        })
        setIsDeleting(false)
      }
    }
  }, [
    isSuccessful,
    isFailed,
    successMessage,
    failureMessage,
    handleCloseModal,
    handleCloseDetailDrawer,
  ])

  return {
    isDeleting,
    setIsDeleting,
    showError,
    setShowError,
  }
}

export { useDeleteSuccessFailureUtility }
