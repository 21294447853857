import { Authentication } from '../authentication/AuthenticationManager'

export const useFetchAccessToken = () => {
  const loginRequest = Authentication.authConfig.loginRequest
  const account = Authentication.account

  Authentication.clientApplication
    .acquireTokenSilent({
      ...loginRequest,
      account: account,
    })
    .then((resp) => {
      return resp.accessToken
    })
    .catch((err) => {
      Authentication.logout()
      return err
    })
}

export const fetchAccessTokenAsync = async (): Promise<string> => {
  const loginRequest = Authentication.authConfig.loginRequest
  const account = Authentication.account
  return Authentication.clientApplication
    .acquireTokenSilent({
      ...loginRequest,
      account: account,
    })
    .then((resp) => {
      return resp.accessToken
    })
    .catch((err) => {
      Authentication.logout()
      return err
    })
}
