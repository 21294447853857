import React from 'react'
import DialogBox, { DialogWidth } from '../../Dialogs/DialogBox'
import DialogHeaderClose from '../../Dialogs/DialogHeaderClose'

export interface CreateOrEditModalProps<T> {
  handleClose: () => void
  data: T
  modalHeaderTitle: string
  handleCloseDetailDrawer?: () => void
  dataTestId?: string
}

const makeCreateOrEditModal =
  <T extends object | null>(
    FormComponent: React.ComponentType<CreateOrEditModalProps<T>>,
    maxWidth?: DialogWidth
  ) =>
  (props: CreateOrEditModalProps<T>) => {
    const { handleClose, modalHeaderTitle } = props

    return (
      <DialogBox
        maxWidth={maxWidth}
        open
        handleClose={handleClose}
        customHeader={
          <DialogHeaderClose title={modalHeaderTitle} onClose={handleClose} />
        }
      >
        <FormComponent {...props} />
      </DialogBox>
    )
  }

export default makeCreateOrEditModal
