import { ColumnSort } from '@tanstack/react-table'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { removeAssetsFromMapAction } from '../../../actions/assets'
import {
  setTelemetryCurrentMapIdAction,
  setTelemetryEnabledAction,
} from '../../../actions/telemetrySubscription'
import AssetStaffFilterDrawer from '../../../components/Common/AssetStaff/FilterDrawer/AssetStaffFilterDrawer'
import { PageContent } from '../../../components/Layout'
import { DrawerType } from '../../../components/Layout/PageContent'
import RealTimeAssetList from '../../../components/RealTimeAssetList/RealTimeAssetList'
import { useReactTableRightDrawer } from '../../../components/Tables/useReactTableRightDrawer'
import { RealTimeTableDataLimit } from '../../../constants'
import {
  RealTimeFilterStates,
  useAuth,
  useBadgeLocationMonitor,
  useCurrentFloor,
  useCurrentLocation,
  useFetchWatchlist,
  useTelemetrySubscription,
} from '../../../hooks'
import { useFetchGeofencesByLocation } from '../../../hooks/entities/useFetchGeofencesByLocation'
import useCurrentMap from '../../../hooks/useCurrentMap'
import useRealTimeAssetTableData from '../../../hooks/useRealTimeAssetTableData'
import {
  Asset,
  AssetFilterConfigs,
  BadgeCurrentLocation,
  FilterType,
  LocationType,
  Locations,
  Unit,
} from '../../../models'
import { AssetRow } from '../../../models/assetRow'
import { FetchingStatus } from '../../../models/fetchingStatus'
import { PageLayoutState } from '../../../models/pageLayout'
import DeprecatedAssetDetailDrawer from '../../../components/Common/AssetStaff/DetailDrawer/DeprecatedAssetDetailDrawer'

interface Props {
  assets: Asset[] | undefined
  assetsStatus: FetchingStatus | undefined
  venueId?: number
  locations: Locations
  locationStatus: FetchingStatus | undefined
  assetsByLocation: Asset[]
  liveTelemetryEnabled: boolean
  refreshTable: boolean
  assetFilterOptions: AssetFilterConfigs
  filterStates: RealTimeFilterStates
  filterButtonToggled: boolean | undefined
  units: Unit[]
  setFilterButtonToggled: (filterButtonStates: any) => void
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const RealTimeAssetListPage = (props: Props): JSX.Element => {
  const {
    assets,
    assetsStatus,
    venueId,
    locations,
    locationStatus,
    assetsByLocation,
    liveTelemetryEnabled,
    refreshTable,
    assetFilterOptions,
    filterButtonToggled,
    filterStates,
    units,
    setFilterButtonToggled,
  } = props
  const { manufacturers } = assetFilterOptions
  const {
    mergeAssetFilters,
    clearAssetFilters,
    clearStaffFilters,
    assetFilterState,
  } = filterStates
  const maxHeight = useSelector(
    ({ pageLayout }: { pageLayout: PageLayoutState }) => pageLayout.maxHeight
  )

  const dispatch = useDispatch()

  const [savedFilterSortBy, setSavedFilterSortBy] = useState<
    ColumnSort | undefined
  >()
  const {
    rightDrawerState,
    selectRow,
    closeDetailDrawer,
    closeFilterDrawer,
    clearSelectedItem,
    resetSelectedRowIndex,
    toggleFilterDrawer,
    updateSelectedRow,
  } = useReactTableRightDrawer<AssetRow>()
  const {
    currentDrawerType,
    item: selectedRow,
    tableSelectedRowIndex,
  } = rightDrawerState
  const mapId = useCurrentMap(venueId, useCurrentFloor())
  const [filteredBadgeLocations, setFilteredBadgeLocations] =
    useState<BadgeCurrentLocation[]>()
  const { enabled: telemetryEnabled } = useTelemetrySubscription()
  const currentLocation = useCurrentLocation()

  const { data: geofences, status: geofenceStatus } =
    useFetchGeofencesByLocation(
      Object.values(locations).find((x) => x.level === LocationType.Root)?.id ??
        ''
    )

  const { user } = useAuth()
  const { data: watchlist } = useFetchWatchlist(user.id)

  useEffect(() => {
    dispatch(setTelemetryCurrentMapIdAction(mapId))
  }, [mapId])

  const {
    assetTableData: tableData,
    badgeLocationsChangedAt,
    badgeLocations,
  } = useRealTimeAssetTableData(
    liveTelemetryEnabled,
    refreshTable,
    assetsByLocation,
    assets,
    assetsStatus
  )

  // set signalr filters for given location+map reference views

  const allBadges = useMemo(() => {
    return assets
      ? assets
          .filter((asset) => !!asset.badgeIds.length)
          .map((asset) => asset.badgeIds[0])
      : []
  }, [assets])

  const shouldMonitorBadgeLocation = useCallback(
    (badgeLocation: BadgeCurrentLocation): boolean => {
      return allBadges.includes(badgeLocation.badgeId)
    },
    [allBadges]
  )

  const {
    state: badgeLocationsState, // use to send custom location updates
  } = useBadgeLocationMonitor(
    liveTelemetryEnabled ? badgeLocations : [],
    shouldMonitorBadgeLocation
  )

  useEffect(() => {
    const mapLeaversBadges = badgeLocations?.filter((x) => x.mapId !== mapId)
    if (mapLeaversBadges.length && mapId) {
      const mapLeavers: Asset[] = []
      if (assets) {
        Object.values(assets).forEach((x) => {
          if (mapLeaversBadges?.some((y) => y.badgeId === x.badgeIds[0])) {
            mapLeavers.push(x)
          }
        })
        if (mapLeavers.length) {
          dispatch(
            removeAssetsFromMapAction(mapLeavers.map((x) => x.agentGuid))
          )
        }
      }
    }
  }, [badgeLocations, badgeLocationsChangedAt, mapId])

  useEffect(() => {
    if (assets && selectedRow) {
      const matchedAsset = assets.find(
        (x) => x.agentGuid === selectedRow.agentGuid
      )
      if (
        badgeLocations &&
        matchedAsset &&
        badgeLocations.some((x) => matchedAsset.badgeIds.includes(x.badgeId))
      ) {
        setFilteredBadgeLocations(
          badgeLocations.filter((x) =>
            matchedAsset.badgeIds.includes(x.badgeId)
          )
        )
      }
    }
  }, [assets, badgeLocations, selectedRow, badgeLocationsChangedAt])

  const isFetching = useMemo(() => {
    return (
      assetsStatus !== FetchingStatus.Success ||
      geofenceStatus !== FetchingStatus.Success ||
      locationStatus !== FetchingStatus.Success
    )
  }, [assetsStatus, geofenceStatus, locationStatus])

  useEffect(() => {
    if (
      !isFetching &&
      tableData &&
      tableData?.length < RealTimeTableDataLimit &&
      telemetryEnabled !== liveTelemetryEnabled
    ) {
      dispatch(setTelemetryEnabledAction(liveTelemetryEnabled))
    }
  }, [
    currentLocation,
    dispatch,
    isFetching,
    liveTelemetryEnabled,
    tableData,
    telemetryEnabled,
  ])

  const clearFilters = useCallback(() => {
    clearAssetFilters()
    clearStaffFilters()
  }, [clearAssetFilters, clearStaffFilters])

  const handleSort = useCallback(
    (columnSorts: ColumnSort[], isSavedFilter = false) => {
      if (isSavedFilter) {
        const columnSort = columnSorts.length > 0 ? columnSorts[0] : undefined
        setSavedFilterSortBy((prevState) => {
          if (columnSort) {
            // Use JS spread operation to force state update even when the value has not been change
            // This is because we don't reset this state on sort change
            return { ...columnSort }
          } else {
            return undefined
          }
        })
      } else {
        const sortFilters = {
          columnSort: columnSorts,
          filterGuid: undefined,
        }
        mergeAssetFilters(sortFilters)
      }
    },
    [mergeAssetFilters]
  )

  useEffect(() => {
    if (filterButtonToggled !== undefined) {
      toggleFilterDrawer()
    }
  }, [filterButtonToggled, toggleFilterDrawer])

  const commonFilterOptions = useMemo(
    () => ({
      hideLowBatterFilter: true,
    }),
    []
  )

  const listFilterOptions = useMemo(
    () => ({
      isManagementPage: false,
      onSavedFilterChanged: handleSort,
    }),
    [handleSort]
  )

  const tableRowSelectionOptions = useMemo(
    () => ({
      selectedRow,
      selectedRowIndex: tableSelectedRowIndex,
      onSelectRow: selectRow,
      clearSelectedRow: clearSelectedItem,
      resetSelectedRowIndex,
      updateSelectedRow,
    }),
    [
      clearSelectedItem,
      resetSelectedRowIndex,
      selectRow,
      selectedRow,
      tableSelectedRowIndex,
      updateSelectedRow,
    ]
  )

  const realTimeListPageOptions = useMemo(
    () => ({
      badgeCurrentLocation: filteredBadgeLocations,
    }),
    [filteredBadgeLocations]
  )

  return (
    <PageContent
      maxHeight={maxHeight}
      currentRightDrawerType={currentDrawerType}
      content={
        <RealTimeAssetList
          isFetching={isFetching}
          tableData={tableData ?? []}
          savedFilterSortBy={savedFilterSortBy}
          onSortingChange={handleSort}
          tableRowSelectionOptions={tableRowSelectionOptions}
        />
      }
      rightDrawer={
        currentDrawerType === DrawerType.DetailDrawer && selectedRow ? (
          <DeprecatedAssetDetailDrawer
            selectedAsset={selectedRow}
            watchList={watchlist}
            manufacturers={manufacturers}
            onCloseDetailDrawer={closeDetailDrawer}
            isRTMapPage={false}
            realTimeListPageOptions={realTimeListPageOptions}
            assetFilterState={assetFilterState}
            setFilterButtonToggled={setFilterButtonToggled}
          />
        ) : currentDrawerType === DrawerType.FilterDrawer ? (
          <AssetStaffFilterDrawer
            onCloseDrawer={closeFilterDrawer}
            clearFilters={clearFilters}
            assetFilterOptions={assetFilterOptions}
            commonFilterOptions={commonFilterOptions}
            units={units}
            listFilterOptions={listFilterOptions}
            filterStates={filterStates}
            filterType={FilterType.RealTime}
          />
        ) : null
      }
    />
  )
}

export default RealTimeAssetListPage
