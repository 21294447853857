import { BaseService } from './baseService'
import { Location } from '../models'

export class LocationsApi extends BaseService {
  constructor() {
    super({ entityType: 'locations' })
  }
  route = 'locations'

  async getAllLocations(): Promise<Location[]> {
    return await this.get<Location[]>(this.route)
  }
  async getLocation(id: string): Promise<Location> {
    return await this.get<Location>(`${this.route}/${id}`)
  }
  async getLocationWithAncestors(id: string): Promise<Location> {
    return await this.get<Location>(`${this.route}/${id}/ancestors`)
  }
  async getLocationWithDescendants(id: string): Promise<Location> {
    return await this.get<Location>(`${this.route}/${id}/descendants`)
  }
  async postLocation(location: Location): Promise<Location> {
    return await this.post<Location>(this.route, location)
  }
  async putLocation(location: Location): Promise<Location> {
    return await this.put<Location>(`${this.route}/${location.id}`, location)
  }
  async putLocations(locations: Location[]): Promise<Location[]> {
    return await this.put<Location[]>(this.route, locations)
  }
  async deleteLocation(id: string): Promise<string> {
    return await this.delete(`${this.route}/${id}`)
  }
}
