import { useSelector } from 'react-redux'
import { AuthState } from '../models'

const useAuth = (): AuthState => {
  const authState = useSelector(({ auth }: { auth: AuthState }) => auth)

  return {
    ...authState,
  }
}

export { useAuth }
