import React, { useMemo } from 'react'
import { AlertActiveConfig } from '../../models/alert'
import { DetailedItem } from '../../models/DetailPaneItems'
import DetailsList from '../DetailsList/DetailsList'

interface Props {
  alert: AlertActiveConfig
  isLoading?: boolean
  hideHeader?: boolean
  headerStylesOverrides?: any
  listItemStylesOverrides?: any
}

const AlertIdentificationDetail = (props: Props) => {
  const {
    alert,
    isLoading = false,
    hideHeader,
    headerStylesOverrides,
    listItemStylesOverrides,
  } = props

  const identificationItems = useMemo(() => {
    const newAlertGuid = '00000000-0000-0000-0000-000000000000'
    return !!alert
      ? [
          {
            field: 'Name',
            value: alert.name,
          },
          {
            field: 'Description',
            value: alert.description,
          },
          {
            field: 'Type',
            value: alert.alertType?.name,
          },
          {
            field: 'Alert ID',
            value: alert.id === newAlertGuid ? '' : alert.id,
          },
        ]
      : []
  }, [alert])

  const alertIdentificationDetailItems: DetailedItem[] = []
  alertIdentificationDetailItems.push({
    header: 'ALERT',
    items: identificationItems,
  })

  return (
    <DetailsList
      detailedItems={alertIdentificationDetailItems}
      narrowRows={true}
      headerStylesOverrides={headerStylesOverrides}
      listItemStylesOverrides={listItemStylesOverrides}
      hideHeader={hideHeader}
      isLoading={isLoading}
    />
  )
}

export default AlertIdentificationDetail
