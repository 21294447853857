import React from 'react'
import { RightDrawerLayout } from '../../../components/Common/RightDrawer'
import UnitManagementDetailsPaneHeader from './UnitManagementDetailsPaneHeader'
import UnitManagementDetailsPane from './UnitManagementDetailsPane'
import { Unit } from '../../../models'

interface Props {
  selectedUnit: Unit
  onEditUnit: () => void
  onClose: () => void
}

const UnitDetailDrawer = (props: Props): JSX.Element => {
  const { selectedUnit, onEditUnit, onClose } = props
  return (
    <RightDrawerLayout
      header={
        <UnitManagementDetailsPaneHeader
          unit={selectedUnit}
          onEditUnit={onEditUnit}
          onClose={onClose}
        />
      }
      content={<UnitManagementDetailsPane unit={selectedUnit} />}
    />
  )
}

export default UnitDetailDrawer
