import { useMemo } from 'react'
import { useGlobalFilterState } from '.'
import { FilterProvider } from '../../filters'
import { setGlobalDeviceFiltersAction } from '../../actions/globalFilters'
import { createQuery, whereValue } from '../../filters'
import { isDateMatch, isInList } from '../../helpers/filters'
import {
  DeviceAndIssue,
  DeviceFilters,
  Locations,
  LocationsState,
} from '../../models'
import { useSelector } from 'react-redux'

type DeviceFiltersProvider<T extends DeviceAndIssue> = FilterProvider<
  T,
  DeviceFilters
>
const useDeviceFilters = <
  T extends DeviceAndIssue
>(): DeviceFiltersProvider<T> => {
  const {
    filterState: filters,
    setFilterState: setFilters,
    updateFilterState: mergeFilters,
    clearFilterState: clearFilters,
  } = useGlobalFilterState(
    (globalFilters) => globalFilters.devices,
    setGlobalDeviceFiltersAction
  )

  const locations: Locations = useSelector(
    ({ locations }: { locations: LocationsState }) => locations.data
  )

  const filter = useMemo(() => {
    const filter = createQuery<T>([
      whereValue(
        (deviceOrIssue) => !deviceOrIssue.resolveDate,
        filters.hideResolved ?? false
      ),
      whereValue(
        (deviceOrIssue) =>
          isDateMatch(filters?.createdDate, deviceOrIssue?.createdDate) ||
          deviceOrIssue?.issueCreatedDates?.some(
            (d) => d !== undefined && isDateMatch(filters?.createdDate, d)
          ) ||
          false,
        filters?.createdDate
      ),
      whereValue(
        (deviceOrIssue) =>
          isDateMatch(filters?.resolveDate, deviceOrIssue?.resolveDate) ||
          deviceOrIssue?.issueResolveDates?.some(
            (d) => d !== undefined && isDateMatch(filters?.resolveDate, d)
          ) ||
          false,
        filters?.resolveDate
      ),
      whereValue(
        (deviceOrIssue) =>
          isInList(
            deviceOrIssue.configurationStatus,
            filters?.deviceStatus,
            (item) => item.toString()
          ),
        filters?.deviceStatus
      ),
      whereValue(
        (deviceOrIssue) =>
          isInList(deviceOrIssue.issueTypeId, filters?.issueTypes, (item) =>
            Number(item.id)
          ),
        filters?.issueTypes
      ),
      whereValue(
        (deviceOrIssue) =>
          isInList(
            deviceOrIssue ? locations[deviceOrIssue.locationId]?.name : null,
            filters?.locations,
            (item) => item.name
          ),
        filters?.locations
      ),
    ])
    return filter
  }, [filters, locations])

  return {
    filterState: filters,
    setFilterState: setFilters,
    updateFilterState: mergeFilters,
    clearFilterState: clearFilters,
    filter,
    filterCount: filter.filterCount,
  }
}

export default useDeviceFilters
