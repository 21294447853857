import { getType } from 'typesafe-actions'
import { LocationAction } from '../actions'
import {
  getAllLocationsAction,
  getLocationAction,
  postLocationAction,
  putLocationAction,
  deleteLocationAction,
  putLocationsAction,
} from '../actions/locations'
import { Location, Locations, LocationsState } from '../models'
import { FetchingStatus } from '../models/fetchingStatus'
import {
  mergeRecords,
  mergeRecord,
  deleteRecord,
  setFetchingStatus,
} from '../utils'

const initialState: LocationsState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

const key = 'id'
const locationReducer = (
  state: LocationsState = initialState,
  action: LocationAction
): LocationsState => {
  switch (action.type) {
    case getType(getAllLocationsAction.success):
      return {
        data: mergeRecords<Locations, Location, typeof key>(
          state.data,
          action.payload,
          key
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getLocationAction.success):
    case getType(postLocationAction.success):
      return {
        data: mergeRecord<Locations, Location, typeof key>(
          state.data,
          action.payload,
          key
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(putLocationAction.success):
      return {
        data: mergeRecord<Locations, Location, typeof key>(
          state.data,
          action.payload,
          key
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(putLocationsAction.success):
      return {
        data: mergeRecords<Locations, Location, typeof key>(
          state.data,
          action.payload,
          key
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(deleteLocationAction.success):
      return {
        data: deleteRecord<Locations, Location>(state.data, action.payload),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }

    case getType(putLocationAction.request):
    case getType(putLocationsAction.request):
      return {
        ...state,
        status: FetchingStatus.Request,
      }
    case getType(getAllLocationsAction.request):
    case getType(getLocationAction.request):
    case getType(postLocationAction.request):
    case getType(deleteLocationAction.request):
      return setFetchingStatus<Locations>(state, FetchingStatus.Request)
    case getType(getAllLocationsAction.failure):
    case getType(getLocationAction.failure):
    case getType(postLocationAction.failure):
    case getType(putLocationAction.failure):
    case getType(putLocationsAction.failure):
    case getType(deleteLocationAction.failure):
      return setFetchingStatus<Locations>(state, FetchingStatus.Failure)

    default:
      return state
  }
}

export default locationReducer
