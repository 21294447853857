import GeofenceKit from '@jibestream-dev/jmap-geofence-kit'
import { useEffect, useState } from 'react'
import { GeofenceInstance, GeofenceKitInstance } from '../../models/jibestream'
import { getAllGeofenceInstances } from '../../helpers/inpixon'

export function useJibestreamGeofenceKit(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  jibestreamControl: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  jibestreamCore: any,
  mapId: number | undefined
): {
  geofenceKit: GeofenceKitInstance | undefined
  geofenceInstances: GeofenceInstance[]
} {
  const [geofenceKit, setGeofenceKit] = useState<GeofenceKitInstance>()
  const [geofenceInstances, setGeofenceInstances] = useState<
    GeofenceInstance[]
  >([])

  useEffect(() => {
    if (jibestreamControl && jibestreamCore && mapId !== undefined) {
      if (jibestreamControl.currentMapView.id !== mapId) {
        return
      }
      const geofenceKitInstance = new GeofenceKit(
        jibestreamCore,
        jibestreamControl
      )
      geofenceKitInstance.getGeofences((error: any, geofencesByMapId: any) => {
        const geofenceInstancesByMapId =
          getAllGeofenceInstances(geofencesByMapId)
        setGeofenceInstances(geofenceInstancesByMapId)
        setGeofenceKit(geofenceKitInstance)
      })
    }
  }, [jibestreamControl, jibestreamCore, mapId])

  useEffect(() => {
    return () => {
      setGeofenceKit(undefined)
      setGeofenceInstances([])
    }
  }, [])

  return {
    geofenceKit,
    geofenceInstances,
  }
}
