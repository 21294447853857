import { getType } from 'typesafe-actions'
import { OperationsRoleAction } from '../actions'
import {
  getOperationsRolesAction,
  getOperationsRolesByUserIdAction,
  getOperationsRoleByIdAction,
  postOperationsRoleAction,
  putOperationsRoleAction,
  deleteOperationsRoleAction,
} from '../actions/operationsRoles'
import { Role, Roles } from '@midmarkrtls/common/models/roles'
import {
  mergeRecord,
  mergeRecords,
  deleteRecord,
} from '@midmarkrtls/common/utils'
import { FetchingStatus } from '@midmarkrtls/common/models/fetchingStatus'
import { OperationsRolesState } from 'v2/models/operationsRoles'

const initialState: OperationsRolesState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

const key = 'id'
const operationsRolesReducer = (
  state: OperationsRolesState = initialState,
  action: OperationsRoleAction
): OperationsRolesState => {
  switch (action.type) {
    case getType(getOperationsRolesAction.success):
    case getType(getOperationsRolesByUserIdAction.success):
      return {
        data: mergeRecords<Roles, Role, typeof key>(
          state.data,
          action.payload,
          key
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getOperationsRoleByIdAction.success):
    case getType(postOperationsRoleAction.success):
    case getType(putOperationsRoleAction.success):
      return {
        data: mergeRecord<Roles, Role, typeof key>(
          state.data,
          action.payload,
          key
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(deleteOperationsRoleAction.success):
      return {
        data: deleteRecord<Roles, Role>(state.data, action.payload),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }

    case getType(getOperationsRolesAction.request):
    case getType(getOperationsRolesByUserIdAction.request):
    case getType(getOperationsRoleByIdAction.request):
    case getType(postOperationsRoleAction.request):
    case getType(putOperationsRoleAction.request):
    case getType(deleteOperationsRoleAction.request):
      return {
        ...state,
        status: FetchingStatus.Request,
      }

    case getType(getOperationsRolesAction.failure):
    case getType(getOperationsRolesByUserIdAction.failure):
    case getType(getOperationsRoleByIdAction.failure):
    case getType(postOperationsRoleAction.failure):
    case getType(putOperationsRoleAction.failure):
    case getType(deleteOperationsRoleAction.failure):
      return {
        ...state,
        status: FetchingStatus.Failure,
      }

    default:
      return state
  }
}

export default operationsRolesReducer
