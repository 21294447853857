import {
  BaseBadgeTelemetryMessage,
  Asset,
  Staff,
  AttemptedSilentUpdates,
  AgentType,
} from '../models'
import {
  findItemInArrayAButNotInArrayB,
  findDuplicateItemsInArray,
} from './array'

export default function calculateValidTelemetryNotInState(
  telemetry: BaseBadgeTelemetryMessage[],
  agentState: Asset[] | Staff[],
  attemptedSilentUpdates: AttemptedSilentUpdates | undefined,
  agentType: AgentType
): BaseBadgeTelemetryMessage[] {
  let newAgents: BaseBadgeTelemetryMessage[] = []
  if (agentState.length > 0) {
    const badgeIdsInTelemetry = [...telemetry.map((t) => t.trackingId)]

    let currentBadgeIdsInState: string[] = []

    agentState.forEach((agent) => {
      currentBadgeIdsInState = currentBadgeIdsInState.concat([
        ...agent.badgeIds,
      ])
    })

    const telemetryNotInAgentState = findItemInArrayAButNotInArrayB(
      badgeIdsInTelemetry,
      currentBadgeIdsInState,
      badgeIdsInTelemetry.length,
      currentBadgeIdsInState.length
    )
    if (telemetryNotInAgentState.length > 0) {
      newAgents = Object.values(telemetry).filter(
        (b) =>
          b.agent != undefined &&
          b.agent.id !== undefined &&
          b.agent.id !== '' &&
          (attemptedSilentUpdates === undefined ||
            attemptedSilentUpdates[b.agent.id] === undefined) &&
          b.agent.agentType === AgentType[agentType] &&
          telemetryNotInAgentState.includes(b.trackingId)
      )

      const newAgentGuids: string[] = newAgents
        .map((agent) => agent.agent?.id || '')
        .filter((agent) => agent !== '')
      const duplicateTelemetryAgentGuids =
        findDuplicateItemsInArray(newAgentGuids)
      if (duplicateTelemetryAgentGuids.length > 0) {
        let duplicateAgentsToRemove: BaseBadgeTelemetryMessage[] = []
        duplicateTelemetryAgentGuids.forEach((agentGuid) => {
          const telemetry = newAgents.filter(
            (agent) => agent.agent?.id === agentGuid
          )
          const sortedTelemetry = telemetry.sort((t1, t2) => {
            if (t1.timestamp && t2.timestamp) {
              return (
                new Date(t2.timestamp).getTime() -
                new Date(t1.timestamp).getTime()
              )
            }
            return -1
          })
          const mostRecentTelemetry = sortedTelemetry.shift()
          duplicateAgentsToRemove = duplicateAgentsToRemove.concat(
            sortedTelemetry.filter(
              (telemetry) => telemetry !== mostRecentTelemetry
            )
          )
          newAgents = newAgents.filter(
            (agent) => !duplicateAgentsToRemove.includes(agent)
          )
        })
      }
    }
  }

  return newAgents
}
