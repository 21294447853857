import { isString, isArray, isFunction } from 'lodash'
import {
  Filterer,
  Criteria,
  FilterPredicate,
  QueryFilter,
  Filterable,
  compare,
  ComparableValueCheck,
} from '.'
import { isNullOrUndefined } from '../helpers'

export const filterByCriteria = <D extends Filterable = {}>(
  data: D[] | undefined,
  criteria: Criteria<D>[]
): D[] | undefined => {
  if (data === undefined) {
    return undefined
  }
  const enabledCriteria = criteria.filter((filter) => filter.enabled ?? true)
  return data.filter(
    (item, index, items) =>
      !enabledCriteria.length ||
      enabledCriteria.every((criteria) => criteria.matches(item, index, items))
  )
}

export const isCriteriaEnabled = <D extends Filterable = {}>(
  criteria: Criteria<D>
): boolean => criteria.enabled ?? true

export const calculateCountByValue = <V extends unknown = string>(
  value: V | null | undefined
): number => {
  if (isArray(value)) {
    return (value as [])?.length ?? 0
  } else if (isString(value)) {
    return Boolean((value as string)?.length) ? 1 : 0
  } else {
    return Boolean(value) ? 1 : 0
  }
}

export const getFilterCountByState = <S extends object = {}>(
  filterState: S,
  ignore: Array<keyof S> = []
): number => {
  const sum = Object.entries(filterState ?? {})
    .map((entry) => ({ key: entry[0] as keyof S, value: entry[1] }))
    .filter(
      ({ key, value }) =>
        !(isFunction(value) || isNullOrUndefined(value) || ignore.includes(key))
    )
    .reduce(
      (count: number, prop: { value: unknown }) =>
        count + calculateCountByValue(prop?.value),
      0
    )

  return sum
}

export const getFilterCount = <D extends Filterable = {}>(
  criteria: Criteria<D>[]
): number => {
  const filterCount = criteria
    .filter(isCriteriaEnabled)
    .reduce(
      (count: number, filter: Criteria<D>) =>
        count + calculateCountByValue(filter.value),
      0
    )
  return filterCount
}

export const createFilterer =
  <D extends Filterable = {}>(predicate: FilterPredicate<D>): Filterer<D> =>
  (data: D[]): D[] =>
    data.filter(predicate)

export const createQueryFilterer =
  <D extends Filterable>(query: Criteria<D>[]): Filterer<D> =>
  (items: D[]): D[] => {
    const filteredItems = query
      .filter((criteria) => criteria.enabled ?? true)
      .reduce(
        (nextItems, criteria) => nextItems.filter(criteria.matches),
        items
      )

    return filteredItems
  }

export const createQuery = <D extends Filterable>(
  criteria: Array<Criteria<D>>
): QueryFilter<D> => {
  const filter = createQueryFilterer(criteria)
  const filterCount = getFilterCount(criteria)
  return { criteria, filter, filterCount }
}

export const where = <D extends Filterable = {}>(
  predicate: FilterPredicate<D>,
  enabled?: boolean
): Criteria<D> => ({
  matches: predicate,
  enabled: enabled ?? true,
})

export const whereValue = <
  D extends Filterable = {},
  V extends unknown = string
>(
  predicate: FilterPredicate<D>,
  value: V,
  enabledOrValueCheck?: boolean | ComparableValueCheck<V>
): Criteria<D> => compare(value, predicate, enabledOrValueCheck)
