import { DialogActions, IconButton, Link, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Close } from '@mui/icons-material'
import { Alert, AlertTitle } from '@mui/material'
import moment from 'moment'
import React, { useState } from 'react'
import { fromNow, localMoment } from '../../../helpers/date'
import { UpcomingReleaseNotification, useInterval } from '../../../hooks'
import { colors, tableColors } from '../../../styles'
import DialogActionButtons from '../../Dialogs/DialogActionButtons'
import { DialogActionButtonType } from '../../Dialogs/DialogActionButtonType'
import { FlexBox } from '../../Layout'
import { useFetchFeatureFlags } from '../../../hooks/entities/useFetchFeatureFlags'
import { FeatureFlagLabels } from '../../../constants'

interface Props {
  notification: UpcomingReleaseNotification
  dismiss: (notification: UpcomingReleaseNotification) => void
  ignore: (notification: UpcomingReleaseNotification) => void
}

const SystemMaintenanceAlert = (props: Props): JSX.Element | null => {
  const { notification, dismiss, ignore } = props

  const { data: FeatureFlags } = useFetchFeatureFlags(
    FeatureFlagLabels.Navigation
  )

  const systemDown =
    Object.values(FeatureFlags).filter(
      (x) => x.name === 'DownForMaintenanceScreen' && x.enabled === true
    ).length > 0

  const [systemMaintenanceNotification, setSystemMaintenanceNotification] =
    useState({
      title: 'System Maintenance',
      modalMessage: '',
      friendlyAgoText: '',
    })

  const createSystemMaintenanceMessage = () => {
    if (!notification) return

    const title = 'System Maintenance'
    const now = localMoment(new Date())
    const updateTime = localMoment(notification.plannedReleasedDate)
    const receivedOn = localMoment(notification.receivedOn)
    const minutesToUpdate = moment.duration(updateTime.diff(now)).asMinutes()

    const elapsedMilliseconds = moment
      .duration(now.diff(receivedOn))
      .asMilliseconds()

    const modalMessage = `
    Midmark RTLS Cloud System maintenance will begin in ${Math.ceil(
      minutesToUpdate
    )} minutes.
    The system may become unresponsive for 15 minutes during the update.
    `
    const friendlyAgoText = notification.receivedOn
      ? fromNow(elapsedMilliseconds)
      : ''

    setSystemMaintenanceNotification({
      title,
      modalMessage,
      friendlyAgoText,
    })
  }

  useInterval(createSystemMaintenanceMessage, 2000)

  const actionButtons: DialogActionButtonType[] = [
    {
      label: 'IGNORE',
      action: (): void => ignore(notification),
    },
    {
      label: (
        <Link
          target='blank'
          href={process.env.REACT_APP_ZENDESK_RELEASE_DETAILS}
          underline='hover'
        >
          Details
        </Link>
      ),
      action: (): void => {},
      variant: 'outlined',
    },
  ]

  const useAlertStyles = makeStyles((theme) => ({
    root: () => ({
      width: 380,
      backgroundColor: theme.palette.background.paper,
      borderRadius: '4px',
      border: `1px solid ${tableColors.borderColor}`,
      padding: '0px 16px',
    }),
  }))

  const alertClasses = useAlertStyles()

  const customHeader = (
    <FlexBox
      flex='basis'
      layout='row'
      layoutAlign={{ item: 'center', content: 'center', main: 'flex-start' }}
    >
      <FlexBox
        flex='grow'
        layout='row'
        layoutAlign={{
          item: 'center',
          content: 'center',
          main: 'flex-start',
        }}
      >
        <Typography
          variant='body1'
          style={{
            fontWeight: 'bold',
            color: colors.darkSurface,
            paddingTop: 8,
          }}
        >
          {systemMaintenanceNotification.title}
        </Typography>
      </FlexBox>
      <FlexBox
        flex='grow'
        layout='row-reverse'
        layoutAlign={{
          item: 'center',
          content: 'center',
          main: 'flex-start',
        }}
      >
        <IconButton
          aria-label='Close'
          onClick={() => dismiss(notification)}
          style={{ padding: 0 }}
          disableRipple
          disableFocusRipple
          disableTouchRipple
          size='large'
        >
          <Close />
        </IconButton>
        <Typography
          variant='body2'
          style={{
            fontWeight: 'normal',
            color: '#979797',
            marginRight: '8px',
          }}
        >
          {systemMaintenanceNotification.friendlyAgoText}
        </Typography>
      </FlexBox>
    </FlexBox>
  )

  return notification.isVisible &&
    systemMaintenanceNotification.modalMessage &&
    !systemDown ? (
    <Alert
      severity='info'
      icon={false}
      variant='outlined'
      classes={{
        root: alertClasses.root,
      }}
    >
      <>
        <AlertTitle>{customHeader}</AlertTitle>
        <Typography
          variant='body1'
          style={{
            fontSize: '16px',
            color: '#000',
          }}
        >
          {systemMaintenanceNotification.modalMessage}
        </Typography>
        <FlexBox
          flex='grow'
          layout='row-reverse'
          layoutAlign={{
            item: 'center',
            content: 'center',
            main: 'flex-start',
          }}
        >
          {actionButtons && (
            <DialogActions>
              <DialogActionButtons actionButtons={actionButtons} />
            </DialogActions>
          )}
        </FlexBox>
      </>
    </Alert>
  ) : null
}

export { SystemMaintenanceAlert }
