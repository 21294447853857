/* eslint-disable @typescript-eslint/no-explicit-any */

export type JsonParser = (key: string, value: any) => any
export interface ValueTransform {
  canTransform: (value: any) => boolean
  transform: (value: any) => any
}

export const matchDate =
  /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/
const parseDate = (value: string): string | Date => {
  const isDate = matchDate.test(value)
  return isDate ? new Date(value) : value
}

export const dateTransform: ValueTransform = {
  canTransform: (value: any) =>
    typeof value === 'string' && matchDate.test(value as string),
  transform: (value: any) => parseDate(value as string),
}

export const jsonParser = (key: string, value: any): any => {
  try {
    if (dateTransform.canTransform(value)) {
      return dateTransform.transform(value)
    }
    return value
  } catch (ex) {
    return value
  }
}
