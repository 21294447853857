import { useDispatch, useSelector } from 'react-redux'
import { shouldDispatch } from '../../helpers/shouldDispatch'
import { useEffect } from 'react'
import { ChannelTypes, ChannelTypesState } from '../../models'
import { getAllChannelTypesAction } from '../../actions/channelTypes'

const useFetchChannelTypes = (shouldRefresh = false) => {
  const dispatch = useDispatch()
  const status = useSelector(
    ({ channelTypes }: { channelTypes: ChannelTypesState }) =>
      channelTypes.status
  )
  const lastRefreshTime = useSelector(
    ({ channelTypes }: { channelTypes: ChannelTypesState }) =>
      channelTypes.lastRefreshTime
  )

  useEffect(() => {
    if (shouldDispatch(status, lastRefreshTime) || shouldRefresh) {
      dispatch(getAllChannelTypesAction.request())
    }
  }, [status, lastRefreshTime, shouldRefresh, dispatch])

  const channelTypes: ChannelTypes = useSelector(
    ({ channelTypes }: { channelTypes: ChannelTypesState }) => channelTypes.data
  )

  return {
    channelTypes,
    status,
  }
}

export { useFetchChannelTypes }
