import { FetchingState } from '../interfaces/fetchingState'
import { BadgeEventTelemetryMessage } from './badgeTelemetry'
import { StaffEvent } from './staffEvent'

export interface TelemetryEventLocation {
  id: string
  eventActionType: number
  agentEventType: string
  agentEventCreatedDate: Date
  agentEventActive: boolean
  data: {
    customerId: string
    agentId: string
    alertId: string
    agentEventId: string
    agentEventCreatedDate: Date
    agentEventClosedDate: Date
    agentEventClosedBy: string
    agentEventActive: boolean
    agentEventTypeId: number
    agentEventType: string
    staffTypeId: number
    staffType: string
    staffSubTypeId: number
    staffSubType: string
    displayName: string
    locationId: string
    locationTypeId: number
    locationName: string
    parentLocationId: string
    parentLocationName: string
    trackingId: string
    signalTypeId: number
    timestamp: Date
    mapId: number
    geoFenceId: number
    isPrivate: boolean
    pointOfCare: boolean
    patientCareArea: boolean
    lon: number
    lat: number
    locUncertainty: number
    sensorId: string
  }
}

export interface AgentEventLocation {
  id: string
  agentEventTypeId: number
  agentId: string
  alertId: string
  closedBy?: string
  closedDate?: Date
  isActive: boolean
  agentType: string
  displayName: string
  badgeIds: string[]
  agentEventCreatedDate: Date
  badgeEventTelemetry?: BadgeEventTelemetryMessage
}

export interface AgentEventStaffEventEpicMapper
  extends AgentEventLocation,
    StaffEvent {}

export interface AgentEventsLocation {
  [id: string]: AgentEventLocation
}

export interface AgentEventLocationUpdate {
  Id: string
  ClosedBy: string
  ClosedDate: Date
  AgentEventTypeId: number
  IsActive: boolean
  Accidental?: boolean
}

export type AgentEventsLocationState = Readonly<
  FetchingState<AgentEventsLocation>
>

export interface BadgeEventTelemetryStore {
  badgeEventTelemetry: TelemetryEventLocation[]
}

export interface AgentEventUpdate {
  guid: string
  agentEventType: number
  agentId: string
  closedBy: string
  dateTime: Date
}

export enum EventActionType {
  Create,
  Update,
  Resolve,
  AutomaticResolve,
  Silence,
}
