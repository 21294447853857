import { Alert, Checkbox, Grid, TextField, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { Autocomplete } from '@mui/material'
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { PayloadAction } from 'typesafe-actions'
import { postBadgeAction } from '../../actions/badges'
import {
  acknowledgeHttpError,
  deleteUserAction,
  postStaffUserAction,
  postUserAction,
  putStaffUserAction,
  putUserAction,
} from '../../actions/users'
import { FeatureFlagLabels, FeatureFlagNames } from '../../constants'
import { filterRtlsId, GetManagerDisplayOption } from '../../helpers'
import { resolveBadge } from '../../helpers/cygnusHardwareUtility'
import { handleDateValidation } from '../../helpers/forms/date'
import {
  getVisibleRolesByCurrentUserRole,
  roleIdsToNames,
  roleNamesToId,
} from '../../helpers/roles'
import {
  useDeleteSuccessFailureUtility,
  useFetchRoles,
  useFetchStaffByLocation,
  useSaveSuccessFailureUtility,
} from '../../hooks'
import { useFetchFeatureFlags } from '../../hooks/entities/useFetchFeatureFlags'
import { useBadgeValidation } from '../../hooks/forms/useBadgeValidation'
import {
  AppTypeEnum,
  AuthState,
  BadgesState,
  CreateBadgeRequest,
  FetchingStatus,
  SignalStatus,
  Staff,
  StaffCreateOrUpdate,
  StaffState,
  StaffSubType,
  StaffSubTypes,
  StaffSubTypesState,
  StaffType,
  StaffTypes,
  StaffTypesState,
  Unit,
  Units,
  UnitsState,
} from '../../models'
import { AccountType } from '../../models/accountType'
import {
  LinkedUserModel,
  StaffUserCreateUpdateModel,
  User,
  UsersState,
} from '../../models/users'
import { colors, useFormStyle } from '../../styles/index'
import { DataTestIds } from '../../utils/test-utils/dataTestIds'
import { ActionConfirmationButton, SaveButton } from '../Buttons/Button'
import { CreateOrEditModalProps } from '../Common/HOC'
import { requiredMessage } from '../Forms'
import DebouncedTextField from '../Forms/DebouncedTextField'
import MaterialDatePicker from '../Forms/MaterialDatePicker'
import MaterialMultiselect from '../Forms/MaterialMultiselect'
import UserCloudReportingForm from './UserCloudReportingForm'
import { selectCurrentUser } from '../../selectors'

const UserManagementForm: React.ComponentType<
  CreateOrEditModalProps<User | null>
> = (props: CreateOrEditModalProps<User | null>) => {
  const { data: selectedUser, handleClose, handleCloseDetailDrawer } = props

  //Use States
  const [selectedEmailAddress, setSelectedEmailAddress] = useState<string>()

  //Selectors
  const { currentCustomerId } = useSelector(
    ({ auth }: { auth: AuthState }) => auth
  )
  const {
    data: users,
    status: usersStatus,
    error: usersHttpError,
  } = useSelector(({ users }: { users: UsersState }) => users)
  const { status: staffStatus } = useSelector(
    ({ staff }: { staff: StaffState }) => staff
  )
  const { data: badges, status: badgeStatus } = useSelector(
    ({ badgeState }: { badgeState: BadgesState }) => badgeState
  )
  const { data: roles } = useFetchRoles()
  const staffTypes: StaffTypes = useSelector(
    ({ staffTypes }: { staffTypes: StaffTypesState }) => staffTypes.data
  )
  const staffSubTypes: StaffSubTypes = useSelector(
    ({ staffSubTypes }: { staffSubTypes: StaffSubTypesState }) =>
      staffSubTypes.data
  )
  const units: Units = useSelector(
    ({ units }: { units: UnitsState }) => units.data
  )
  const currentUser = useSelector(selectCurrentUser)

  //Hooks
  const { staffs } = useFetchStaffByLocation(SignalStatus.TimedOut)
  const dispatch = useDispatch()
  const classes = useFormStyle()
  const useCheckboxStyles = makeStyles(() =>
    createStyles({
      root: {
        padding: '0px',
        height: '19px',
        width: '19px',
      },
    })
  )
  const checkboxClasses = useCheckboxStyles()
  //Feature Flags
  const { data: featureFlags } = useFetchFeatureFlags(
    FeatureFlagLabels.Navigation
  )

  //Memo
  const user: User = useMemo(() => {
    if (selectedUser) {
      return selectedUser
    }
    if (selectedEmailAddress) {
      const recentlyAddedUser = Object.values(users).find(
        (x) =>
          x.email !== null &&
          x.email.toLowerCase() === selectedEmailAddress.toLowerCase()
      )
      if (recentlyAddedUser) {
        return recentlyAddedUser
      }
    }
    return {
      id: undefined,
      email: '',
      firstName: '',
      lastName: '',
      displayName: '',
      isAccountEnabled: true,
      customerId: currentCustomerId,
      authenticationType: 1,
      accountType: AccountType.Unknown,
      roleIds: [],
      isCloudReportingEnabled: false,
    }
  }, [JSON.stringify(users), selectedEmailAddress, selectedUser])

  const matchingStaff = useMemo(() => {
    if (
      !selectedUser &&
      Object.values(users).find(
        (x) =>
          x.email !== null &&
          x.email.toLowerCase() === selectedEmailAddress?.toLowerCase()
      ) !== undefined
    ) {
      return undefined
    }
    if (staffs) {
      if (selectedUser?.email) {
        return Object.values(staffs).find(
          (x) =>
            x.email !== null &&
            x.email.toLowerCase() === selectedUser.email.toLowerCase()
        )
      } else if (user && user.email.length > 0 && selectedEmailAddress === '') {
        return undefined
      } else {
        const results = Object.values(staffs).find(
          (x) =>
            x.email !== null &&
            x.email.toLowerCase() === user.email.toLowerCase()
        )
        return results
      }
    }
  }, [selectedUser, users, staffs, selectedEmailAddress, user])

  const sortedRoles = useMemo(() => {
    if (!currentUser) {
      return []
    }
    const isStaffVisibilityEnabled =
      Object.values(featureFlags).filter(
        (x) => x.name === 'StaffVisibility' && x.enabled === true
      ).length > 0
    if (!isStaffVisibilityEnabled) {
      return Object.values(roles).map((role) => role.name)
    }
    const visibleRoles = getVisibleRolesByCurrentUserRole(
      currentUser.roleIds,
      Object.values(roles)
    )
    return visibleRoles
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map((role) => role.name)
  }, [currentUser, featureFlags, roles])

  //Roles Use State
  const [selectedRoles, setSelectedRoles] = useState<string[]>(
    roleIdsToNames(user.roleIds, roles)
  )

  //Staff Use States
  const [roleIdsUpdated, setRoleIdsUpdated] = useState(false)
  const [badgeId, setBadgeId] = useState<string>('')
  const [staffAssignCheckboxState, setStaffAssignCheckboxState] =
    useState<boolean>(!!(matchingStaff !== undefined))
  const [staffSubTypesFiltered, setStaffSubTypesFiltered] = useState<
    StaffSubType[]
  >([])
  const [unitState, setUnitState] = useState<Unit | null>(null)
  const [manager, setManager] = useState<Staff | null>(
    Object.values(staffs).find(
      (x) => x.agentGuid === matchingStaff?.managerId
    ) ?? null
  )
  const [staffType, setStaffType] = useState<StaffType | null>(
    Object.values(staffTypes).find(
      (x) => x.staffTypeId === matchingStaff?.staffTypeId
    ) ?? null
  )
  const [staffSubType, setStaffSubType] = useState<StaffSubType | null>(
    Object.values(staffSubTypes).find(
      (x) => x.staffSubTypeId === matchingStaff?.staffSubTypeId
    ) ?? null
  )

  const isFederated = user.accountType === AccountType.Federated

  //Actions
  const postUser = (user: User): PayloadAction<'POST_USER_REQUEST', User> =>
    dispatch(postUserAction.request(user))
  const putUser = (user: User): PayloadAction<'PUT_USER_REQUEST', User> =>
    dispatch(putUserAction.request(user))
  const postStaffUser = (
    staffUser: StaffUserCreateUpdateModel
  ): PayloadAction<'POST_STAFF_USER_REQUEST', StaffUserCreateUpdateModel> =>
    dispatch(postStaffUserAction.request(staffUser))
  const putStaffUser = (
    staffUser: StaffUserCreateUpdateModel
  ): PayloadAction<'PUT_STAFF_USER_REQUEST', StaffUserCreateUpdateModel> =>
    dispatch(putStaffUserAction.request(staffUser))
  const deleteUser = (
    userId: string
  ): PayloadAction<'DELETE_USER_REQUEST', string> =>
    dispatch(deleteUserAction.request(userId))
  const handleAckHttpError = () => {
    dispatch(acknowledgeHttpError(true))
  }

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    trigger,
  } = useForm<LinkedUserModel>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      email: user.email ?? '',
      firstName: user.firstName ?? '',
      lastName: user.lastName ?? '',
      displayName: user.displayName ?? '',
      roleIds: [],
      staffType: staffType,
      staffSubType: staffSubType,
      unit: unitState,
      manager: manager,
      badgeId:
        matchingStaff?.badgeIds?.length ?? -1 > 0
          ? matchingStaff?.badgeIds[0]
          : '',
      tagBatteryChangeDate: matchingStaff?.tagBatteryChangeDate
        ? matchingStaff.tagBatteryChangeDate
        : null,
      isCloudReportingEnabled: user.isCloudReportingEnabled ?? false,
    },
  })

  useEffect(() => {
    if (matchingStaff !== undefined) {
      setStaffAssignCheckboxState(true)

      const staffType = matchingStaff?.staffTypeId
        ? Object.values(staffTypes).find(
            (s) => s.staffTypeId === matchingStaff.staffTypeId
          )
        : null

      const staffSubType = matchingStaff?.staffSubTypeId
        ? Object.values(staffSubTypes).find(
            (s) => s.staffSubTypeId === matchingStaff.staffSubTypeId
          )
        : null

      const unit = matchingStaff?.unitId
        ? Object.values(units).find((u) => u.id === matchingStaff.unitId)
        : null

      const manager = matchingStaff?.managerId
        ? Object.values(staffs).find(
            (s) => s.agentGuid === matchingStaff.managerId
          )
        : null

      const badgeId =
        matchingStaff?.badgeIds?.length ?? -1 > 0
          ? matchingStaff?.badgeIds[0]
          : ''

      const tagBatteryChangeDate = matchingStaff?.tagBatteryChangeDate
        ? matchingStaff.tagBatteryChangeDate
        : null

      setValue('firstName', matchingStaff.firstName)
      setValue('lastName', matchingStaff.lastName)
      setValue('displayName', matchingStaff.displayName)

      if (staffType !== undefined) {
        setValue('staffType', staffType)
      }

      if (staffSubType !== undefined) {
        setValue('staffSubType', staffSubType)
      }

      if (unit !== undefined) {
        setUnitState(unit)
        setValue('unit', unit)
      }

      if (manager !== undefined) {
        setValue('manager', manager)
      }

      setValue('badgeId', badgeId)
      setBadgeId(badgeId)
      setValue('tagBatteryChangeDate', tagBatteryChangeDate)
    }
  }, [
    matchingStaff,
    setValue,
    staffSubTypes,
    staffTypes,
    staffs,
    units,
    user.id,
  ])

  const validateEmail = (input: string): boolean => {
    if (
      !selectedUser &&
      input.length > 0 &&
      Object.values(users)?.find(
        (x) => x.email && x.email.toLowerCase() === input.toLowerCase()
      ) !== undefined
    ) {
      return false
    } else {
      return true
    }
  }

  const { validating, validatedBadgeId, setValidating } = useBadgeValidation(
    badgeId,
    badges
  )

  const {
    isSaving,
    setIsSaving,
    showError: showSaveError,
    setShowError: setShowSaveError,
  } = useSaveSuccessFailureUtility(
    (usersStatus === FetchingStatus.Success &&
      staffStatus === FetchingStatus.Success &&
      !staffAssignCheckboxState) ||
      (usersStatus === FetchingStatus.Success &&
        badgeStatus === FetchingStatus.Success &&
        staffStatus === FetchingStatus.Success),
    usersStatus === FetchingStatus.Failure ||
      staffStatus === FetchingStatus.Failure,
    `User was successfully ${selectedUser ? 'updated.' : 'created.'}`,
    `There was an error saving User information, please try again. If the problem persists, please contact support for assistance.`,
    handleClose
  )

  const {
    isDeleting,
    setIsDeleting,
    showError: showDeleteError,
    setShowError: setShowDeleteError,
  } = useDeleteSuccessFailureUtility(
    usersStatus === FetchingStatus.Success &&
      staffStatus === FetchingStatus.Success,
    usersStatus === FetchingStatus.Failure ||
      staffStatus === FetchingStatus.Failure,
    `User was successfully deleted.`,
    `There was an error deleting User information, please try again. If the problem persists, please contact support for assistance.`,
    handleClose,
    handleCloseDetailDrawer
  )

  useEffect(() => {
    if (badgeStatus === FetchingStatus.Failure) {
      setValidating(false)
    }
  }, [badgeStatus, setValidating])

  const buildStaffData = useCallback(
    (formData: LinkedUserModel): StaffCreateOrUpdate => {
      if (matchingStaff) {
        return {
          ...matchingStaff,
          displayName: formData.displayName,
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          badgeId: formData.badgeId ?? '',
          tagBatteryChangeDate: formData.tagBatteryChangeDate,
          staffTypeId: formData.staffType?.staffTypeId ?? 0,
          staffSubTypeId: formData.staffSubType?.staffSubTypeId ?? null,
          managerId: formData?.manager?.agentGuid,
          userId: user.id,
          unitId: formData.unit?.id,
        }
      } else {
        return {
          displayName: formData.displayName,
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          staffTypeId: formData.staffType?.staffTypeId ?? 0,
          staffSubTypeId: formData.staffSubType?.staffSubTypeId ?? null,
          managerId: formData?.manager?.agentGuid,
          unitId: formData.unit?.id,
          badgeId: formData.badgeId ?? '',
          tagBatteryChangeDate: formData.tagBatteryChangeDate,
        }
      }
    },
    [matchingStaff, user.id]
  )

  const onSubmit = useCallback(
    (formData: LinkedUserModel): void => {
      if (selectedRoles.length === 0) {
        setError('roleIds', { type: 'validate' })
        setRoleIdsUpdated(true)
        return
      }

      if (Object.keys(errors).length) {
        return
      }

      setIsSaving(true)
      if (showSaveError) {
        setShowSaveError(false)
      }
      if (showDeleteError) {
        setShowDeleteError(false)
      }
      if (unitState !== null) {
        setValue('unit', unitState)
      }

      if (user.id && user.id !== '') {
        const updatedUser: User = {
          ...user,
          ...formData,
          roleIds: roleNamesToId(selectedRoles, roles),
          isCloudReportingEnabled: formData.isCloudReportingEnabled,
        }

        if (staffAssignCheckboxState) {
          if (!validatedBadgeId && badgeId !== undefined && badgeId !== null) {
            if (!Object.keys(errors).length) {
              const postResponseInput: CreateBadgeRequest = {
                badge: {
                  badgeId: badgeId,
                  badgeType: 'unknown',
                  signalTypes: [],
                },
              }
              dispatch(postBadgeAction.request(postResponseInput))
            }
          }
          const staffData = buildStaffData(formData)
          putStaffUser({ user: updatedUser, staff: staffData })
        } else {
          putUser(updatedUser)
        }
      } else {
        const createUser: User = {
          ...formData,
          isAccountEnabled: true,
          customerId: currentCustomerId,
          authenticationType: 1,
          roleIds: roleNamesToId(selectedRoles, roles),
          isCloudReportingEnabled: formData.isCloudReportingEnabled,
        }

        if (staffAssignCheckboxState) {
          if (
            !validatedBadgeId &&
            badgeId !== undefined &&
            badgeId !== null &&
            badgeId !== ''
          ) {
            if (!Object.keys(errors).length) {
              const postResponseInput: CreateBadgeRequest = {
                badge: {
                  badgeId: badgeId,
                  badgeType: 'unknown',
                  signalTypes: [],
                },
              }
              dispatch(postBadgeAction.request(postResponseInput))
            }
          }
          const staffData = buildStaffData(formData)
          postStaffUser({ user: createUser, staff: staffData })
        } else {
          postUser(createUser)
        }
      }
    },
    [
      selectedRoles,
      errors,
      setIsSaving,
      showSaveError,
      showDeleteError,
      unitState,
      user,
      setError,
      setShowSaveError,
      setShowDeleteError,
      setValue,
      roles,
      staffAssignCheckboxState,
      validatedBadgeId,
      badgeId,
      buildStaffData,
      putStaffUser,
      dispatch,
      putUser,
      currentCustomerId,
      postStaffUser,
      postUser,
    ]
  )

  //Sorted Data
  const sortedStaffTypes = useMemo(() => {
    return Object.values(staffTypes ?? {}).sort((a, b) =>
      String(a.name).toLowerCase().localeCompare(String(b.name).toLowerCase())
    )
  }, [staffTypes])

  const sortedManagers = useMemo(() => {
    return Object.values(staffs).sort((a, b) =>
      GetManagerDisplayOption(a)
        .toLowerCase()
        .localeCompare(GetManagerDisplayOption(b).toLowerCase())
    )
  }, [staffs])

  const handleDelete = (): void => {
    if (user.id) {
      setIsDeleting(true)
      deleteUser(user.id)
    }
  }

  // this is gross, but i was unable to figure out a way to properly validate the multi select that we are using
  // this just manually sets and clears the errors on the multiselect whenever selectedRoles is updated
  useEffect(() => {
    if (roleIdsUpdated) {
      if (selectedRoles?.length > 0) {
        clearErrors('roleIds')
      } else {
        setError('roleIds', { type: 'validate' })
      }
    }
    if (
      usersHttpError?.acknowledged === false &&
      usersHttpError?.status === 409
    ) {
      setError('email', { type: 'validate', message: 'Email already in use' })
    }
  }, [
    clearErrors,
    control._formState.submitCount,
    roleIdsUpdated,
    selectedRoles,
    setError,
    usersHttpError,
  ])

  const updateSelectedRoles = useCallback((data: string[]) => {
    setRoleIdsUpdated(true)
    setSelectedRoles(data)
  }, [])

  const isCloudReportingFeatureEnabled =
    Object.values(featureFlags).filter(
      (x) => x.name === FeatureFlagNames.cloudReporting && x.enabled
    ).length > 0

  const showCloudReportingFeature =
    isCloudReportingFeatureEnabled &&
    process.env.REACT_APP_APP_TYPE === AppTypeEnum.Customer

  const handleRTLSBadgeIdChange = useCallback(
    (fieldValue: string): void => {
      const filteredValue = filterRtlsId(fieldValue)
      const resolvedBadge = resolveBadge(filteredValue)
      setBadgeId(resolvedBadge.badgeId)
      setValue('badgeId', resolvedBadge.badgeId, { shouldValidate: true })
    },
    [setValue]
  )

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        data-testid={DataTestIds.userManagementForm}
      >
        <Typography
          style={{
            fontSize: '14px',
            textAlign: 'left',
            fontWeight: 'bold',
            color: colors.darkGrey,
          }}
        >
          USER INFORMATION
        </Typography>
        {isFederated && (
          <Alert severity='warning'>
            This user is governed by an external identity provider. Changes to
            certain fields cannot be performed here.
          </Alert>
        )}
        <Controller
          name='firstName'
          control={control}
          rules={{
            required: {
              value: true,
              message: 'First Name is required',
            },
          }}
          render={({ field: { onChange, ...field } }) => {
            return (
              <TextField
                {...field}
                label='First Name*'
                id='firstName'
                InputLabelProps={{ htmlFor: 'firstName' }}
                variant='filled'
                margin='normal'
                fullWidth
                className={classes.inputStyle}
                error={errors.hasOwnProperty('firstName')}
                disabled={isFederated}
                helperText={
                  errors?.firstName?.message
                    ? errors?.firstName?.message
                    : requiredMessage
                }
                onChange={onChange}
              />
            )
          }}
        />
        <Controller
          name='lastName'
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Last Name is required',
            },
          }}
          render={({ field: { onChange, ...field } }) => {
            return (
              <TextField
                {...field}
                data-testid={DataTestIds.userManagementFormLastName}
                label='Last Name*'
                variant='filled'
                margin='normal'
                fullWidth
                className={classes.inputStyle}
                error={errors.hasOwnProperty('lastName')}
                disabled={isFederated}
                helperText={
                  errors?.lastName?.message
                    ? errors.lastName?.message
                    : requiredMessage
                }
                onChange={onChange}
              />
            )
          }}
        />
        <Controller
          name='email'
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Email is required',
            },
            pattern: {
              value: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
              message: 'Enter a valid email address',
            },
            validate: {
              checkEmail: (v) => validateEmail(v) || 'Email already in use',
            },
          }}
          render={({ field }) => {
            return (
              <TextField
                {...field}
                data-testid={DataTestIds.userManagementFormEmail}
                label='Email*'
                variant='filled'
                margin='normal'
                fullWidth
                className={classes.inputStyle}
                autoFocus
                disabled={isFederated}
                error={errors.hasOwnProperty('email')}
                helperText={
                  errors?.email?.message
                    ? errors.email?.message
                    : requiredMessage
                }
                onChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) => {
                  if (
                    usersHttpError?.acknowledged === false &&
                    handleAckHttpError
                  ) {
                    handleAckHttpError()
                  }
                  if (!event.target.value.trim().length) {
                    setStaffSubTypesFiltered([])
                    setBadgeId('')
                    setValue('badgeId', '')
                    setUnitState(null)
                    setManager(null)
                    setValue('tagBatteryChangeDate', undefined)
                  }
                  setSelectedEmailAddress(event.target.value)
                  field.onChange(event)
                  return event.target.value
                }}
              />
            )
          }}
        />
        {staffAssignCheckboxState &&
          matchingStaff &&
          matchingStaff.userId === null && (
            <Typography
              style={{
                lineHeight: '1.2',
                fontSize: '14px',
                padding: '4px 18px 0px 18px',
              }}
            >
              An existing staff member has the email address{' '}
              {matchingStaff.email}. We will link this user with that staff
              member.
            </Typography>
          )}
        <Controller
          name='displayName'
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Display Name is required',
            },
          }}
          render={({ field: { onChange, ...field } }) => {
            return (
              <TextField
                {...field}
                data-testid={DataTestIds.userManagementFormDisplayName}
                label='Display Name*'
                variant='filled'
                margin='normal'
                fullWidth
                className={classes.inputStyle}
                error={errors.hasOwnProperty('displayName')}
                helperText={
                  errors?.displayName?.message
                    ? errors.displayName?.message
                    : requiredMessage
                }
                onChange={onChange}
              />
            )
          }}
        />
        <Controller
          name='roleIds'
          control={control}
          render={({ field }) => {
            return (
              <MaterialMultiselect
                {...field}
                id='select-user-roles'
                label='Roles*'
                classes={classes}
                items={sortedRoles}
                selections={selectedRoles}
                handleChange={(event: ChangeEvent<{ value: unknown }>) => {
                  updateSelectedRoles(event.target.value as string[])
                }}
                error={errors.hasOwnProperty('roleIds')}
                errorText='At least one role assignment is required'
                helperText={requiredMessage}
              />
            )
          }}
        />

        {showCloudReportingFeature && (
          <UserCloudReportingForm
            user={user}
            currentCustomerId={currentCustomerId}
            control={control}
            error={errors.isCloudReportingEnabled}
            register={register}
            trigger={trigger}
            getValues={getValues}
          ></UserCloudReportingForm>
        )}

        {staffStatus !== FetchingStatus.Request ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Checkbox
              data-testid={DataTestIds.userManagementFormStaffCheckBox}
              color='primary'
              icon={<CheckBoxOutlineBlankIcon color='primary' />}
              classes={checkboxClasses}
              disabled={matchingStaff !== undefined}
              style={{
                boxShadow: 'none',
              }}
              checked={staffAssignCheckboxState}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.checked !== undefined) {
                  setStaffAssignCheckboxState(event.target.checked)
                }
                return event
              }}
            />
            <Typography
              style={{
                fontSize: '16px',
                padding: '0px 0px 0px 9px',
              }}
            >
              {matchingStaff ? 'Assign Staff Member' : 'Create Staff Member'}
            </Typography>
          </div>
        ) : (
          <img
            src={process.env.REACT_APP_STATIC_CONTENT + 'images/progress.gif'}
            alt='loading'
            width={100}
            style={{ marginTop: '-40px', marginBottom: '-40px' }}
          ></img>
        )}

        {staffAssignCheckboxState && (
          <div>
            <Typography
              style={{
                fontSize: '14px',
                textAlign: 'left',
                fontWeight: 'bold',
                padding: '12px 0px 0px 0px',
                color: colors.darkGrey,
              }}
            >
              STAFF INFORMATION
            </Typography>
            <Controller
              control={control}
              name='staffType'
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => {
                return (
                  <Autocomplete
                    {...field}
                    style={{ width: '100%' }}
                    value={staffType}
                    isOptionEqualToValue={(option, value) =>
                      option.name === value?.name
                    }
                    options={sortedStaffTypes}
                    getOptionLabel={(option) => {
                      return option.name ?? ''
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>{option.name}</li>
                    )}
                    onChange={(event, newValue) => {
                      setStaffType(newValue)
                      // clear out staff sub type field
                      setValue('staffSubType', null)
                      setStaffSubType(null)

                      // set the staff sub type options
                      setStaffSubTypesFiltered(
                        Object.values(staffSubTypes)
                          .filter(
                            (s) => s.staffTypeId === newValue?.staffTypeId
                          )
                          .sort((a, b) =>
                            String(a.name)
                              .toLowerCase()
                              .localeCompare(String(b.name).toLowerCase())
                          )
                      )

                      // set the staff type field with the selected value
                      field.onChange(newValue)
                      return newValue
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...field}
                        {...params}
                        margin='normal'
                        label='Staff Type'
                        variant='filled'
                        required
                        error={!!errors.staffType}
                        className={classes.inputStyle}
                        helperText={requiredMessage}
                        inputRef={ref}
                      />
                    )}
                  />
                )
              }}
            />
            <Controller
              control={control}
              name='staffSubType'
              rules={{ required: false }}
              render={({ field: { ref, ...field } }) => {
                return (
                  <Autocomplete
                    {...field}
                    options={staffSubTypesFiltered}
                    getOptionLabel={(option) => {
                      return option.name ?? ''
                    }}
                    value={staffSubType}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>{option.name}</li>
                    )}
                    style={{ width: '100%' }}
                    onChange={(event, newValue) => {
                      setStaffSubType(newValue)
                      field.onChange(newValue)
                      return newValue
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...field}
                        {...params}
                        margin='normal'
                        label='Staff Sub-Type'
                        variant='filled'
                        error={!!errors.staffSubTypeId}
                        className={classes.inputStyle}
                        helperText={errors.staffSubTypeId?.message}
                        inputRef={ref}
                      />
                    )}
                  />
                )
              }}
            />
            <Controller
              control={control}
              name='unit'
              rules={{ required: false }}
              render={({ field: { ref, ...field } }) => {
                return (
                  <Autocomplete
                    {...field}
                    options={Object.values(units ?? {})}
                    getOptionLabel={(option) => {
                      return option.name
                    }}
                    value={unitState}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value?.id
                    }
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>{option.name}</li>
                    )}
                    style={{ width: '100%' }}
                    onChange={(event, newValue) => {
                      field.onChange(newValue)
                      if (newValue === null) {
                        setUnitState(null)
                        return newValue
                      }

                      const unit = newValue as Unit
                      setUnitState((prevState) => ({
                        ...prevState,
                        id: unit.id,
                        name: unit.name,
                      }))
                      return newValue
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...field}
                        {...params}
                        margin='normal'
                        label='Assigned To'
                        variant='filled'
                        error={!!errors.unit}
                        className={classes.inputStyle}
                        helperText={errors.unit?.message}
                        inputRef={ref}
                      />
                    )}
                  />
                )
              }}
            />
            <Controller
              control={control}
              name='manager'
              rules={{ required: false }}
              render={({ field: { ref, ...field } }) => {
                return (
                  <Autocomplete
                    {...field}
                    options={sortedManagers}
                    getOptionLabel={(option) => {
                      if (option !== null || option !== undefined) {
                        return GetManagerDisplayOption(option, true) ?? ''
                      }

                      return ''
                    }}
                    value={manager}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        {GetManagerDisplayOption(option, true)}
                      </li>
                    )}
                    style={{ width: '100%' }}
                    onChange={(event, newValue) => {
                      const newManager = newValue as Staff
                      setManager(newManager)
                      field.onChange(newManager)
                      return newValue
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...field}
                        {...params}
                        margin='normal'
                        label='Reports To'
                        variant='filled'
                        error={!!errors.agentGuid}
                        className={classes.inputStyle}
                        helperText={errors.agentGuid?.message}
                      />
                    )}
                  />
                )
              }}
            />
            <Typography
              style={{
                fontSize: '14px',
                textAlign: 'left',
                fontWeight: 'bold',
                padding: '12px 0px 0px 0px',
                color: colors.darkGrey,
              }}
            >
              RTLS BADGE INFORMATION
            </Typography>
            <Controller
              name='badgeId'
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <DebouncedTextField
                    {...field}
                    margin='normal'
                    label='RTLS ID'
                    variant='filled'
                    fullWidth
                    error={!!errors.badgeId}
                    data-testid={DataTestIds.userManagementFormRTLSID}
                    className={classes.inputStyle}
                    inputProps={{
                      maxLength: 50,
                    }}
                    helperText={
                      errors.badgeId?.message
                        ? errors.badgeId?.message
                        : requiredMessage
                    }
                    isWaiting={setValidating}
                    handleChange={handleRTLSBadgeIdChange}
                  />
                )
              }}
            />
            {badgeId !== undefined &&
              badgeId !== '' &&
              !validatedBadgeId &&
              !validating &&
              badgeStatus !== FetchingStatus.Request && (
                <Typography>
                  This Badge ID {badgeId} does not exist, we will add this ID to
                  your application
                </Typography>
              )}
            {validating && (
              <img
                src={
                  process.env.REACT_APP_STATIC_CONTENT + 'images/progress.gif'
                }
                alt='loading'
                width={100}
                style={{ marginTop: '-40px', marginBottom: '-40px' }}
              ></img>
            )}
            <Controller
              name='tagBatteryChangeDate'
              control={control}
              rules={{
                validate: (v) =>
                  handleDateValidation(getValues('tagBatteryChangeDate')),
              }}
              render={({ field }) => {
                return (
                  <MaterialDatePicker
                    {...field}
                    label='RTLS Battery Change'
                    field='tagBatteryChangeDate'
                    setDate={(field: string, event: string) =>
                      setValue('tagBatteryChangeDate', new Date(event))
                    }
                    required={false}
                    date={getValues('tagBatteryChangeDate') ?? null}
                    error={!!errors.tagBatteryChangeDate}
                    helperText={
                      errors.tagBatteryChangeDate?.message
                        ? errors.tagBatteryChangeDate?.message
                        : requiredMessage
                    }
                  />
                )
              }}
            />
          </div>
        )}

        {showSaveError && (
          <Typography
            style={{
              color: colors.error,
              paddingTop: 10,
            }}
          >
            There was an error saving User information, please try again. If the
            problem persists, contact support.
          </Typography>
        )}

        {showDeleteError && (
          <Typography
            style={{
              color: colors.error,
              paddingTop: 10,
            }}
          >
            There was an error deleting User information, please try again. If
            the problem persists, contact support.
          </Typography>
        )}

        <Grid container justifyContent='space-between'>
          <Grid item>
            {user && user.id !== '' && selectedUser !== null && (
              <ActionConfirmationButton
                openConfirmationButtonLabel='DELETE'
                disableConfirmation={false}
                loading={isSaving || isDeleting}
                confirmationModalTitle='Delete User?'
                confirmationModalContent={
                  <>
                    Are you sure you wish to delete {selectedUser?.displayName}?
                  </>
                }
                onContinue={handleDelete}
                bgColor={colors.transparent}
                textColor={colors.error}
              />
            )}
          </Grid>
          <Grid item>
            <SaveButton
              loading={isSaving || validating || isDeleting}
              disabled={Object.keys(errors).length > 0}
            />
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export default UserManagementForm
