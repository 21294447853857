import React, { useMemo } from 'react'
import {
  badgeSignalAccuracy,
  badgeSignalStatus,
  displayRoomName,
  getRoomDisplayName,
} from '../../../../helpers'
import { getLocationBasedBatteryStatus } from '../../../../helpers/battery'
import {
  formatLocalDateTimeByUserPreference,
  localMomentFormatted,
} from '../../../../helpers/date'
import { useFetchUserPreferences } from '../../../../hooks'
import { useAgentLocation } from '../../../../hooks/useAgentLocation'
import { SignalType, Staff } from '../../../../models'
import { DetailedItem } from '../../../../models/DetailPaneItems'
import DetailsList from '../../../DetailsList/DetailsList'

interface Props {
  staff: Staff
  isDuress?: boolean
  isAssist?: boolean
}

function DeprecatedStaffDetailPane(props: Props): React.ReactElement {
  const { staff, isDuress, isAssist } = props

  const { data: userPreferences } = useFetchUserPreferences()

  const duress = isDuress || false
  const assist = isAssist || false

  const identificationItems = useMemo(() => {
    return !!staff
      ? [
          {
            field: 'Display Name',
            value: staff.displayName,
          },
          {
            field: 'First Name',
            value: staff.firstName,
          },
          {
            field: 'Last Name',
            value: staff.lastName,
          },
          {
            field: 'Email',
            value: staff.email,
          },
          {
            field: 'Staff Type',
            value: staff.staffTypeName,
          },
          {
            field: 'Staff Sub-Type',
            value: staff.staffSubTypeName,
          },
          {
            field: 'Assigned To',
            value: staff.unitName,
          },
          {
            field: 'Reports To',
            value: staff.managerName,
          },
          {
            field: 'RTLS ID',
            value: staff.badgeIds.join(','),
          },
        ]
      : []
  }, [staff])

  const staffLocation = useAgentLocation(staff)

  const locationItems = useMemo(() => {
    let locationAccuracy = 'Unknown'
    let signalStatus = 'Unknown'
    let lastDetected = staffLocation?.timestamp
    if (staff.badgeTelemetry?.signalTypeId) {
      locationAccuracy = badgeSignalAccuracy(staff.badgeTelemetry?.signalTypeId)
      signalStatus = badgeSignalStatus(staff?.badgeTelemetry?.timestamp)
      lastDetected = staff?.badgeTelemetry?.timestamp
    }
    return !!staff
      ? [
          {
            field: 'Building Group',
            value: staffLocation?.buildingGroupLocation?.name,
          },
          {
            field: 'Building',
            value: staffLocation?.buildingLocation?.name ?? '',
          },
          {
            field: 'Floor',
            value: staffLocation?.floorLocation?.name ?? '',
          },
          {
            field: 'Room',
            value: getRoomDisplayName(
              staffLocation?.roomLocation?.name ?? '',
              displayRoomName(duress || assist, staffLocation?.isPrivate)
            ),
          },
          {
            field: 'Unit',
            value: staffLocation?.locationUnitName,
          },
          {
            field: 'Sub-Unit',
            value: staffLocation?.locationSubUnitName,
          },
          {
            field: 'Location Accuracy',
            value: locationAccuracy ?? '',
          },
          {
            field: 'Last Detected',
            value: formatLocalDateTimeByUserPreference(
              lastDetected,
              userPreferences.timeDisplayId
            ),
          },
          {
            field: 'Signal Status',
            value: signalStatus ?? '',
          },
          {
            field: 'Signal Type',
            value: SignalType[staff.badgeTelemetry?.signalTypeId] ?? '',
          },
          {
            field: 'Battery Status',
            value: getLocationBasedBatteryStatus(
              staffLocation?.roomLocation,
              staff?.isLowBattery
            ),
          },
          {
            field: 'RTLS Battery Change Date',
            value: staff?.tagBatteryChangeDate
              ? localMomentFormatted(staff.tagBatteryChangeDate, 'MM/DD/YYYY')
              : 'None',
          },
        ]
      : []
  }, [assist, duress, staff, staffLocation, userPreferences.timeDisplayId])

  const detailItems: DetailedItem[] = []
  detailItems.push({
    header: 'STAFF IDENTIFICATION',
    items: identificationItems,
  })
  detailItems.push({
    header: 'RTLS LOCATION DATA',
    items: locationItems,
  })

  return (
    <div style={{ paddingTop: 24 }}>
      <DetailsList detailedItems={detailItems} narrowRows={true} />
    </div>
  )
}

export default DeprecatedStaffDetailPane
