import { Typography } from '@mui/material'
import moment from 'moment'
import React, { useMemo } from 'react'
import {
  RealTimePageViews,
  TelemetryStaff,
  Watchlist,
} from '../../../../models'
import { DetailDrawerAgentTelemetry } from '../../../../models/telemetry'
import { detailDrawerStyles } from '../../../../styles'
import DetailsPaneHeader from '../../../Drawer/DetailsPaneHeader'
import AgentDetailDrawerActionButtons from './AgentDetailDrawerActionButtons'
import { StaffFilters } from '../../../../models/filters'

interface Props {
  data: DetailDrawerAgentTelemetry<TelemetryStaff>
  onClose: () => void
  watchlist: Watchlist
  pageView: RealTimePageViews
  staffAssistReportedTime: Date | undefined
  staffFilterState: StaffFilters
  onEditStaff?: () => void
  setFilterButtonToggled: (filterButtonStates: any) => void
}

function StaffDetailPaneHeader(props: Props): React.ReactElement {
  const {
    data,
    onClose,
    watchlist,
    pageView,
    staffAssistReportedTime,
    staffFilterState,
    onEditStaff,
    setFilterButtonToggled,
  } = props

  const { agent, telemetry, resolvedLocationHierachy } = data
  const classes = detailDrawerStyles()

  const assistMessage = useMemo(() => {
    if (telemetry && telemetry.hasActiveAssist) {
      return `Staff Assist requested on ${
        resolvedLocationHierachy
          ? resolvedLocationHierachy.floorLocation.name
          : 'Unknown Floor'
      } in ${
        resolvedLocationHierachy
          ? resolvedLocationHierachy.roomLocation.name
          : 'Unknown Room'
      } at ${moment(staffAssistReportedTime).format('LT')}.`
    }
  }, [resolvedLocationHierachy, staffAssistReportedTime, telemetry])

  return (
    <div>
      <DetailsPaneHeader handleClose={onClose} headerText={agent.displayName} />
      {telemetry && telemetry.hasActiveAssist && (
        <Typography className={classes.assistHeaderAlert}>
          {assistMessage}
        </Typography>
      )}
      <AgentDetailDrawerActionButtons
        data={data}
        watchlist={watchlist}
        pageView={pageView}
        onRemovedFromWatchList={onClose}
        onEditAgent={onEditStaff}
        setFilterButtonToggled={setFilterButtonToggled}
        hasWatchListFilter={staffFilterState.isWatchlist ?? false}
      />
    </div>
  )
}

export default StaffDetailPaneHeader
