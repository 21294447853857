import { getType } from 'typesafe-actions'
import { StaffTypeAction } from '../actions'
import { getAllStaffTypesAction } from '../actions/staffTypes'
import { StaffType, StaffTypes, StaffTypesState } from '../models'
import { FetchingStatus } from '../models/fetchingStatus'
import { mergeRecords, setFetchingStatus } from '../utils'

const initialState: StaffTypesState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

export default (
  state: StaffTypesState = initialState,
  action: StaffTypeAction
): StaffTypesState => {
  switch (action.type) {
    case getType(getAllStaffTypesAction.success):
      return {
        data: mergeRecords<StaffTypes, StaffType, 'staffTypeId'>(
          state.data,
          action.payload,
          'staffTypeId'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getAllStaffTypesAction.request):
      return setFetchingStatus<StaffTypes>(state, FetchingStatus.Request)
    case getType(getAllStaffTypesAction.failure):
      return setFetchingStatus<StaffTypes>(state, FetchingStatus.Failure)
    default:
      return state
  }
}
