import AgentEventsLocationReducer from './agentEventsLocation'
import AgentEventViewsReducer from './agentEventViews'
import AgentSilentUpdateReducer from './agentSilentUpdates'
import AlertsReducer from './alerts'
import AlertSubscriptionKeysReducer from './alertSubscriptionKeys'
import AlertTypesReducer from './alertTypes'
import AltitudeMapReducer from './altitudeMaps'
import AssetsReducer from './assets'
import AssetSubTypesReducer from './assetSubTypes'
import AssetTypesReducer from './assetTypes'
import AuthReducer from './auth'
import BadgeAssignedCountReducer from './badgeAssignedCount'
import BadgeReducer from './badges'
import BadgeSensorStatesReducer from './badgeSensorStates'
import BaseBadgeTelemetryReducer from './baseBadgeTelemetry'
import BuildingReducer from './buildings'
import ChannelSoundsReducer from './channelSounds'
import ChannelTypeReducer from './channelTypes'
import CurrentLocationsReducer from './currentLocation'
import CurrentUserReducer from './currentUser'
import CurrentUserLocationReducer from './currentUserLocation'
import CurrentUserTelemetrySubscriptionReducer from './currentUserTelemetrySubscription'
import DevicesReducer from './devices'
import ExternalLinksReducer from './externalLinks'
import FeatureFlagReducer from './featureFlags'
import FiltersReducer from './filters'
import FormsReducer from './forms'
import GeofenceReducer from './geofence'
import GlobalFiltersReducer from './globalFilters'
import HardwareReducer from './hardware'
import IssuesReducer from './issues'
import IssueTypesReducer from './issueTypes'
import LocationGeofencesReducer from './locationGeofence'
import LocationMetricsReducer from './locationMetrics'
import LocationRoutersReducer from './locationRouters'
import LocationsReducer from './locations'
import LocationSelectionReducer from './locationSelection'
import LogLevelTypesReducer from './logLevelTypes'
import ManufacturersReducer from './manufacturers'
import MapGroupsReducer from './mapGroups'
import MapPresetsReducer from './mapPresets'
import MapReferenceViewsReducer from './mapReferenceViews'
import MapsReducer from './maps'
import ModelsReducer from './models'
import OwnersReducer from './owners'
import PageLayoutReducer from './pageLayout'
import PermissionsReducer from './permissions'
import QualifierReducer from './qualifiers'
import RealTimeMapControlReducer from './realTimeMapControl'
import ReleaseReducer from './releases'
import RolesReducer from './roles'
import RouterPromptReducer from './routerPrompt'
import SecurityRoutersReducer from './securityRouters'
import SelectableLocationsReducer from './selectableLocations'
import SelectedSavedFilter from './selectedSavedFilter'
import SensorsReducer from './sensors'
import SensoryReferenceReducer from './sensoryReference'
import SideNavReducer from './sidenav'
import StaffDuressReducer from './staffDuress'
import StaffAssistReducer from './staffAssist'
import StaffReducer from './staff'
import StaffNoLocationReducer from './staffNoLocation'
import StaffEventsReducer from './staffEvents'
import StaffSubTypesReducer from './staffSubTypes'
import StaffTypesReducer from './staffTypes'
import SubUnitsReducer from './subUnits'
import SyncVenueReducer from './syncVenue'
import TelemetrySubscriptionReducer from './telemetrySubscription'
import TenantConfigurationReducer from './tenantConfiguration'
import TriggersReducer from './triggers'
import UnitsReducer from './units'
import UserPreferencesReducer from './userPreferences'
import UserRoutersReducer from './userRouters'
import UsersReducer from './users'
import UserSettingsReducer from './userSettings'
import VenuesReducer from './venues'
import WatchlistReducer from './watchlist'
import CloudReportingReducer from './cloudReporting'
import badgeTelemetryReducer from './badgeTelemetry'
import badgeEventTelemetryReducer from './badgeEventTelemetry'
import mapUpdateReducer from './mapUpdate'
import agentReferenceReducer from './agentReference'
import currentUnitsReducer from './currentUnits'

const commonReducersMap = {
  agentEventsLocation: AgentEventsLocationReducer,
  agentEventViews: AgentEventViewsReducer,
  agentSilentUpdate: AgentSilentUpdateReducer,
  auth: AuthReducer,
  mapReferenceViews: MapReferenceViewsReducer,
  // v2
  altitudeMaps: AltitudeMapReducer,
  assets: AssetsReducer,
  assetSubTypes: AssetSubTypesReducer,
  assetTypes: AssetTypesReducer,
  baseBadgeTelemetry: BaseBadgeTelemetryReducer,
  buildings: BuildingReducer,
  badgeAssignedCount: BadgeAssignedCountReducer,
  badgeSensorStates: BadgeSensorStatesReducer,
  badgeState: BadgeReducer,
  channelTypes: ChannelTypeReducer,
  cloudReporting: CloudReportingReducer,
  currentLocation: CurrentLocationsReducer,
  currentUser: CurrentUserReducer,
  devices: DevicesReducer,
  externalLinks: ExternalLinksReducer,
  featureFlags: FeatureFlagReducer,
  forms: FormsReducer,
  filters: FiltersReducer,
  geofences: GeofenceReducer,
  globalFilters: GlobalFiltersReducer,
  hardware: HardwareReducer,
  issues: IssuesReducer,
  issueTypes: IssueTypesReducer,
  locations: LocationsReducer,
  locationGeofences: LocationGeofencesReducer,
  locationMetrics: LocationMetricsReducer,
  logLevelTypes: LogLevelTypesReducer,
  manufacturers: ManufacturersReducer,
  maps: MapsReducer,
  mapGroups: MapGroupsReducer,
  mapPresets: MapPresetsReducer,
  models: ModelsReducer,
  owners: OwnersReducer,
  permissions: PermissionsReducer,
  realTimeMapControl: RealTimeMapControlReducer,
  releases: ReleaseReducer,
  roles: RolesReducer,
  selectableLocations: SelectableLocationsReducer,
  selectedSavedFilter: SelectedSavedFilter,
  sensors: SensorsReducer,
  sensoryReferences: SensoryReferenceReducer,
  sideNav: SideNavReducer,
  staff: StaffReducer,
  staffDuress: StaffDuressReducer,
  staffAssist: StaffAssistReducer,
  staffNoLocation: StaffNoLocationReducer,
  staffTypes: StaffTypesReducer,
  staffSubTypes: StaffSubTypesReducer,
  subUnits: SubUnitsReducer,
  syncVenueResults: SyncVenueReducer,
  tenantConfiguration: TenantConfigurationReducer,
  telemetrySubscription: TelemetrySubscriptionReducer,
  users: UsersReducer,
  userPreferences: UserPreferencesReducer,
  userSettings: UserSettingsReducer,
  units: UnitsReducer,
  venues: VenuesReducer,
  watchlist: WatchlistReducer,
  staffEvents: StaffEventsReducer,
  alerts: AlertsReducer,
  alertTypes: AlertTypesReducer,
  qualifiers: QualifierReducer,
  triggers: TriggersReducer,
  currentUserLocation: CurrentUserLocationReducer,
  currentUserTelemetrySubscription: CurrentUserTelemetrySubscriptionReducer,
  locationRouters: LocationRoutersReducer,
  securityRouters: SecurityRoutersReducer,
  userRouters: UserRoutersReducer,
  locationSelection: LocationSelectionReducer,
  routerPrompt: RouterPromptReducer,
  pageLayout: PageLayoutReducer,
  channelSounds: ChannelSoundsReducer,
  alertSubscriptionKeys: AlertSubscriptionKeysReducer,
  badgeTelemetry: badgeTelemetryReducer,
  badgeEventTelemetry: badgeEventTelemetryReducer,
  mapUpdates: mapUpdateReducer,
  agentReferences: agentReferenceReducer,
  currentUnits: currentUnitsReducer,
}

export default commonReducersMap
