import {
  Asset,
  AssetCreateOrUpdate,
  AssetTrafficByIdsRequest,
  AssetPaginatedTrafficRequest,
  AssetTrafficRequest,
} from '../models'
import { PaginationResponse } from '../models/paginationResponse'
import { HttpParams, HttpParamsOptions } from '../services/http'
import { BaseService } from './baseService'

export class AssetsApi extends BaseService {
  constructor() {
    super({ entityType: 'assets' })
  }
  assetRoute = 'assets'
  assetsByIdsRoute = 'assetsByIds'
  locRoute = 'locations'

  async getAllAssets(): Promise<Asset[]> {
    return await this.get<Asset[]>(this.assetRoute)
  }

  async getAssetsByLocationId(
    assetTrafficRequest: AssetTrafficRequest
  ): Promise<Asset[]> {
    return await this.get<Asset[]>(
      `${this.locRoute}/${assetTrafficRequest.locationGuid}/assets/traffic`,
      { params: { signalStatusId: assetTrafficRequest.signalStatus } }
    )
  }

  async getAssetsByIds(
    assetTrafficRequest: AssetTrafficByIdsRequest
  ): Promise<Asset[]> {
    const paramsOptions: HttpParamsOptions = {
      fromObject: {
        assetGuids: assetTrafficRequest.agentGuids as string[],
      },
    }
    return await this.get<Asset[]>(
      `${this.assetRoute}/${this.assetsByIdsRoute}`,
      { params: new HttpParams(paramsOptions) }
    )
  }

  async getPaginatedAssets(
    assetPaginatedTrafficRequest: AssetPaginatedTrafficRequest
  ): Promise<PaginationResponse<Asset[]>> {
    return await this.get<PaginationResponse<Asset[]>>(
      `${this.locRoute}/assets/traffic`,
      {
        params: assetPaginatedTrafficRequest.filters,
      }
    )
  }

  async getAssetById(assetId: string): Promise<Asset> {
    return await this.get<Asset>(`${this.assetRoute}/${assetId}`)
  }

  async putAsset(asset: AssetCreateOrUpdate): Promise<Asset> {
    return await this.put<Asset>(`${this.assetRoute}/${asset.agentGuid}`, asset)
  }

  async postAsset(asset: AssetCreateOrUpdate): Promise<Asset> {
    return await this.post<Asset>(`${this.assetRoute}`, asset)
  }

  async deleteAsset(assetGuid: string): Promise<boolean> {
    return await this.deleteNoResult(`${this.assetRoute}/${assetGuid}`)
  }

  async getFullAssetsDownload(
    assetPaginatedTrafficRequest: AssetPaginatedTrafficRequest
  ): Promise<string> {
    return await this.get<string>(`${this.locRoute}/assets/traffic/export`, {
      params: assetPaginatedTrafficRequest.filters,
    })
  }
}
