import {
  Locations,
  Location,
  LocationType,
  Venues,
  LocationGeofence,
  Geofence,
  SimpleLocation,
} from '../models'
import { LocationConstants } from '../constants'

export function isLocationPrivate(
  locations: Locations,
  locationName: string
): boolean {
  return (
    Object.values(locations).find(
      (p) => p.name === locationName && p.locationType === LocationType.Room
    )?.isPrivate ?? false
  )
}

export function getRoomDisplayName(
  roomName: string,
  isLocationPrivate: boolean
): string {
  return isLocationPrivate ? LocationConstants.PrivateDescription : roomName
}

export function getVenueIdFromLocation(
  location: Location,
  venues: Venues
): number | undefined {
  if (location) {
    if (location.locationType === LocationType.BuildingGroup) {
      return Object.values(venues).find((x) => x.locationGuid === location.id)
        ?.venueId
    }

    if (location.locationType === LocationType.Building) {
      return Object.values(venues).find((x) =>
        x.buildingGuids?.includes(location.id)
      )?.venueId
    }

    if (location.locationType === LocationType.Floor) {
      return Object.values(venues).find((x) =>
        x.buildingGuids?.includes(location?.parentId ?? '')
      )?.venueId
    }
  }
}

export function mapGeofencesToLocations(
  allGeofences: LocationGeofence[],
  selectedGeofences: Geofence[],
  locations: Location[]
): Location[] | [] {
  const locGeos = allGeofences.filter((geofence) =>
    selectedGeofences?.find((s) => s.coreReferenceId === geofence.geofenceId)
  )
  return locations.filter(
    (x) => locGeos.findIndex((y) => y.locationId === x.id) > -1
  )
}

export function isCurrentLocationOrChild(
  floorLocationId: string,
  currentLocation: Location | null,
  locations: Locations
): boolean {
  const floor = locations[floorLocationId]
  switch (currentLocation?.locationType) {
    case LocationType.Building:
      if (floor && floor?.parentId)
        return floor.parentId === currentLocation?.id
      return false
    case LocationType.IDN:
      return true
    case LocationType.BuildingGroup:
      if (locations && floor?.parentId) {
        const buildingId = floor.parentId
        const campusId = locations[buildingId]?.parentId
        return campusId === currentLocation?.id
      }
      return false
    default:
      return floorLocationId === currentLocation?.id
  }
}

export interface LocationAncestors {
  floor: Location | undefined
  building: Location | undefined
  buildingGroup: Location | undefined
}

export function getLocationAncestors(
  roomLocation: Location | undefined,
  locations: Locations
): LocationAncestors {
  let floor: Location | undefined
  let building: Location | undefined
  let buildingGroup: Location | undefined

  if (!roomLocation) {
    return {
      floor,
      building,
      buildingGroup,
    }
  }

  const floorLocationId = roomLocation.parentId
  if (!!floorLocationId) {
    floor = locations[floorLocationId]

    if (floor) {
      const buildingLocationId = locations[floor.id].parentId

      if (!!buildingLocationId) {
        building = locations[buildingLocationId]
        if (building) {
          const buildingGroupLocationId = building.parentId
          if (!!buildingGroupLocationId) {
            buildingGroup = locations[buildingGroupLocationId]
          }
        }
      }
    }
  }

  return {
    floor,
    building,
    buildingGroup,
  }
}

export function sortSimpleLocationsByDisplayOrderOrName(
  locations: SimpleLocation[]
): SimpleLocation[] {
  let displayOrderItems = locations.filter(
    (x) => x.displayOrder !== null && x.displayOrder !== undefined
  )
  let nameOrderItems = locations.filter(
    (x) => x.displayOrder === null || x.displayOrder === undefined
  )

  displayOrderItems = displayOrderItems.sort((a, b) => {
    if (a.displayOrder !== undefined && b.displayOrder !== undefined) {
      return a.displayOrder > b.displayOrder ? 1 : -1
    }

    return 1
  })

  nameOrderItems = nameOrderItems.sort((a, b) => {
    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
  })

  return [...displayOrderItems, ...nameOrderItems]
}

export function sortLocationsByDisplayOrderOrName(
  locations: Location[]
): Location[] {
  let displayOrderItems = locations.filter(
    (x) => x.displayOrder !== null && x.displayOrder !== undefined
  )
  let nameOrderItems = locations.filter(
    (x) => x.displayOrder === null || x.displayOrder === undefined
  )

  displayOrderItems = displayOrderItems.sort((a, b) => {
    if (a.displayOrder !== undefined && b.displayOrder !== undefined) {
      return a.displayOrder > b.displayOrder ? 1 : -1
    }

    return 1
  })

  nameOrderItems = nameOrderItems.sort((a, b) => {
    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
  })

  return [...displayOrderItems, ...nameOrderItems]
}
