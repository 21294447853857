import { Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { useAgentLocation } from '../../../../hooks/useAgentLocation'
import { Staff, StaffEvent, Watchlist } from '../../../../models'
import { detailDrawerStyles } from '../../../../styles'
import DetailsPaneHeader from '../../../Drawer/DetailsPaneHeader'
import DeprecatedAgentDetailDrawerActionButtons from './DeprecatedAgentDetailDrawerActionButtons'
import { StaffRow } from '../../../../models/staffRow'
import { StaffFilters } from '../../../../models/filters'

interface Props<T = StaffRow | Staff> {
  staff: T
  onClose: () => void
  isMap: boolean
  isDuress: boolean
  isAssist: boolean
  staffEvent: StaffEvent | undefined
  watchlist: Watchlist
  staffFilterState: StaffFilters
  onEditStaff?: () => void
  setFilterButtonToggled: (filterButtonStates: any) => void
  forceRefresh: () => void
}

function DeprecatedStaffDetailPaneHeader(props: Props): React.ReactElement {
  const {
    staff,
    onClose,
    isMap,
    isDuress,
    isAssist,
    staffEvent,
    watchlist,
    staffFilterState,
    onEditStaff,
    setFilterButtonToggled,
    forceRefresh,
  } = props

  const staffLocation = useAgentLocation(staff)
  const classes = detailDrawerStyles()

  let assistMessage = ''

  if (isAssist && staffEvent) {
    assistMessage = `Staff Assist requested on ${staffEvent.floorName} in ${
      staffEvent.roomName
    } at ${moment(staffEvent.reportedTime).format('LT')}.`
  }

  return (
    <div>
      <DetailsPaneHeader handleClose={onClose} headerText={staff.displayName} />
      {isAssist && staffEvent && (
        <Typography className={classes.assistHeaderAlert}>
          {assistMessage}
        </Typography>
      )}
      <DeprecatedAgentDetailDrawerActionButtons
        isMap={isMap}
        agent={staff}
        watchlist={watchlist}
        floorLocationId={staffLocation?.floorLocation?.id ?? ''}
        isPrivate={
          (staffLocation?.isPrivate ?? false) &&
          isDuress === false &&
          isAssist === false
        }
        isDuress={isDuress}
        hasWatchListFilter={staffFilterState.isWatchlist ?? false}
        onRemovedFromWatchList={onClose}
        onEditAgent={onEditStaff}
        setFilterButtonToggled={setFilterButtonToggled}
        forceRefresh={forceRefresh}
      />
    </div>
  )
}

export default DeprecatedStaffDetailPaneHeader
