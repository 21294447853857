import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { AssetAction, BadgeAction, FormAction } from '../actions'
import {
  getAssetByIdAction,
  postAssetAction,
  putAssetAction,
} from '../actions/assets'
import { postStaffAction, putStaffAction } from '../actions/staff'
import {
  getAllBadgesAction,
  getBadgeAction,
  putBadgeAction,
  postBadgeAction,
} from '../actions/badges'
import { clearFormErrorsAction, setFormErrorsAction } from '../actions/forms'
import { BadgesApi } from '../middleware/badges'
import { BadgesState } from '../models'

type BadgeEpic = Epic<
  BadgeAction | AssetAction | FormAction,
  BadgeAction | AssetAction | FormAction,
  BadgesState,
  { badgesApi: BadgesApi }
>

export const getAllBadgesEpic: BadgeEpic = (action$, state$, { badgesApi }) => {
  return action$.pipe(
    filter(isActionOf(getAllBadgesAction.request)),
    mergeMap((action) =>
      from(badgesApi.getAllBadges()).pipe(
        mergeMap((badges) => [getAllBadgesAction.success(badges)]),
        catchError((error) => of(getAllBadgesAction.failure(error)))
      )
    )
  )
}

export const getBadgeEpic: BadgeEpic = (action$, state$, { badgesApi }) => {
  return action$.pipe(
    filter(isActionOf(getBadgeAction.request)),
    mergeMap((action) =>
      from(badgesApi.getBadge(action.payload)).pipe(
        map((badge) => getBadgeAction.success(badge)),
        catchError((error) => of(getBadgeAction.failure(error)))
      )
    )
  )
}

export const putBadgeEpic: BadgeEpic = (action$, state$, { badgesApi }) => {
  return action$.pipe(
    filter(isActionOf(putBadgeAction.request)),
    mergeMap((action) =>
      from(badgesApi.putBadge(action.payload)).pipe(
        mergeMap(() => [
          clearFormErrorsAction({ formName: 'badgeUpdate' }),
          getAssetByIdAction.request(action.payload.agentID),
          putBadgeAction.success(),
        ]),
        catchError((error) =>
          of(
            setFormErrorsAction({ formName: 'badgeUpdate', error }),
            putBadgeAction.failure(error)
          )
        )
      )
    )
  )
}

export const postBadgeEpic: BadgeEpic = (action$, state$, { badgesApi }) => {
  return action$.pipe(
    filter(isActionOf(postBadgeAction.request)),
    mergeMap((action) =>
      from(badgesApi.postBadge(action.payload)).pipe(
        mergeMap((badge) => {
          const arr: any[] = []
          if (action.payload.asset) {
            if (action.payload.isPost) {
              arr.splice(0, 0, postAssetAction.request(action.payload.asset))
            } else {
              arr.splice(0, 0, putAssetAction.request(action.payload.asset))
            }
          } else if (action.payload.staff) {
            if (action.payload.isPost) {
              arr.splice(0, 0, postStaffAction.request(action.payload.staff))
            } else {
              arr.splice(0, 0, putStaffAction.request(action.payload.staff))
            }
          }
          arr.splice(1, 0, postBadgeAction.success(badge))
          return arr
        }),
        catchError((error) => of(postBadgeAction.failure(error)))
      )
    )
  )
}
