import React from 'react'
import { Typography } from '@mui/material'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() =>
  createStyles({
    cardContainer: {
      padding: '12px 16px 16px 16px',
      borderRadius: '4px',
      boxShadow:
        '0 0 4px 1px rgba(0, 0, 0, 0.15), 0 0 2px 0 rgba(0, 0, 0, 0.15), 0 0 1px 0 rgba(0, 0, 0, 0.15)',
      backgroundColor: 'white',
      minWidth: '430px',
      minHeight: '250px',
      height: 'fit-content',
    },
    cardHeading: {
      margin: '0 0 24px 0',
      fontFamily: 'Ubuntu',
      fontSize: '20px',
      fontWeight: 'bold',
      textAlign: 'left',
      color: '#000',
    },
    cardContents: {
      display: 'flex',
      flexDirection: 'row',
      gap: '40px',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      minWidth: 'fit-content',
    },
  })
)

interface Props {
  style?: React.CSSProperties
  children: React.ReactNode
  title?: string
}

const UserProfileCard: React.FC<Props> = (props: Props) => {
  const { style, children, title } = props
  const classes = useStyles()

  return (
    <div style={style} className={classes.cardContainer}>
      {title && (
        <Typography className={classes.cardHeading}>{title}</Typography>
      )}
      <div className={classes.cardContents}>{children}</div>
    </div>
  )
}

export default UserProfileCard
