export interface AlertConfigStepper {
  name: string
  path: string
  stepComplete: boolean
}

export interface AlertConfigSteppers {
  [name: string]: AlertConfigStepper
}

export enum AlertConfigSteps {
  CONDITIONS,
  CHANNELS,
  ROUTING,
  RESOLUTION,
  REVIEW,
}

export const defaultAlertConfigSteps: AlertConfigSteppers = {
  [AlertConfigSteps[AlertConfigSteps.CONDITIONS]]: {
    name: AlertConfigSteps[AlertConfigSteps.CONDITIONS],
    path: 'conditions',
    stepComplete: false,
  },
  [AlertConfigSteps[AlertConfigSteps.CHANNELS]]: {
    name: AlertConfigSteps[AlertConfigSteps.CHANNELS],
    path: 'channels',
    stepComplete: false,
  },
  [AlertConfigSteps[AlertConfigSteps.ROUTING]]: {
    name: AlertConfigSteps[AlertConfigSteps.ROUTING],
    path: 'routing',
    stepComplete: false,
  },
  [AlertConfigSteps[AlertConfigSteps.RESOLUTION]]: {
    name: AlertConfigSteps[AlertConfigSteps.RESOLUTION],
    path: 'resolution',
    stepComplete: false,
  },
  [AlertConfigSteps[AlertConfigSteps.REVIEW]]: {
    name: AlertConfigSteps[AlertConfigSteps.REVIEW],
    path: 'review',
    stepComplete: false,
  },
}

export interface AlertConfigRouteParams {
  id: string
  step: string
}
