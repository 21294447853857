import React from 'react'
import { getStyling, StyledComponent, StyledComponentProps } from '../'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { colors } from '../../styles/MidmarkTheme'
import { fixedBannerHeight } from './constants'
import { DataTestIds } from '../../utils/test-utils/dataTestIds'

const styles = createStyles({
  banner: {
    display: 'flex',
    minHeight: fixedBannerHeight,
    maxHeight: fixedBannerHeight,
  },
})

const getBannerBackground = (type: BannerType): string => {
  switch (type) {
    case BannerType.Error:
      return colors.error
    default:
      return colors.primary
  }
}

export enum BannerType {
  Error,
}

interface Props {
  bannerType: BannerType
}

const ApplicationBanner: StyledComponent<Props, typeof styles> = (
  props: StyledComponentProps<Props, typeof styles>
) => {
  const { bannerType, classes, children } = props

  return (
    <div
      {...getStyling(props, classes.banner)}
      style={{ backgroundColor: getBannerBackground(bannerType) }}
      data-testid={DataTestIds.appBanner}
    >
      {children}
    </div>
  )
}

export default withStyles(styles, { name: 'ActionBar' })(ApplicationBanner)
