import { createAsyncAction } from 'typesafe-actions'
import { Alert, AlertCreateOrUpdate } from '../models/alert'

export const getAllAlertsAction = createAsyncAction(
  'GET_ALL_ALERTS_REQUEST',
  'GET_ALL_ALERTS_SUCCESS',
  'GET_ALL_ALERTS_FAILURE'
)<void, Alert[], Error>()

export const getAlertAction = createAsyncAction(
  'GET_ALERT_REQUEST',
  'GET_ALERT_SUCCESS',
  'GET_ALERT_FAILURE'
)<string, Alert, Error>()

export const postAlertAction = createAsyncAction(
  'POST_ALERT_REQUEST',
  'POST_ALERT_SUCCESS',
  'POST_ALERT_FAILURE'
)<AlertCreateOrUpdate, Alert, Error>()

export const putAlertAction = createAsyncAction(
  'PUT_ALERT_REQUEST',
  'PUT_ALERT_SUCCESS',
  'PUT_ALERT_FAILURE'
)<AlertCreateOrUpdate, Alert, Error>()

export const deleteAlertAction = createAsyncAction(
  'DELETE_ALERT_REQUEST',
  'DELETE_ALERT_SUCCESS',
  'DELETE_ALERT_FAILURE'
)<string, string, Error>()
