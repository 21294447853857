import { getType } from 'typesafe-actions'
import { RealTimeMapControlAction } from '../actions'
import {
  setShouldShowRealTimeMapAction as setShouldShowAction,
  setRectangleAction,
  setRealTimeMapControlStateAction,
  resetRealTimeMapControlStateAction,
  setJibestreamControlAction,
} from '../actions/realTimeMapControlAction'
import { RealTimeMapControlStore, RealTimeMapControlState } from '../models'

const initialState: RealTimeMapControlStore = {
  shouldShow: false,
  rectangle: {
    x: 0,
    y: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    height: 0,
    width: 0,
  },
  jibestreamControl: {},
}
export default (
  state: RealTimeMapControlStore = initialState,
  action: RealTimeMapControlAction
): RealTimeMapControlState => {
  switch (action.type) {
    case getType(resetRealTimeMapControlStateAction):
      return { ...initialState }
    case getType(setRealTimeMapControlStateAction):
      return { ...action.payload }
    case getType(setShouldShowAction):
      return {
        ...state,
        shouldShow: action.payload,
      }
    case getType(setRectangleAction):
      return {
        ...state,
        rectangle: action.payload,
      }
    case getType(setJibestreamControlAction):
      return {
        ...state,
        jibestreamControl: action.payload,
      }
    default:
      return state
  }
}
