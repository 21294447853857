export function convertLatLonToJibestreamCoordinates(
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any,
  lat: number,
  lon: number
): [number, number] {
  return jibestreamController.jungle.util.convertCoordinate(
    [lon, lat],
    'EPSG:4326',
    'jmap:local',
    jibestreamController.activeVenue
  )
}
