import DeviceIcon from '../../models/deviceIcons'
import {
  buildSVG,
  buildTextElement,
  svgUrlHeader,
  buildAdditionalElement,
  buildGenericTextElement,
} from '../inpixonIcons/buildSVG'
import {
  BadgeSignalStatus,
  DeviceType,
  deviceTypeIdToEnum,
  IconType,
} from '../../models'
import { replaceLowBatterySvg } from '..'
import icons from '../../models/icons'

export const defaultGroupColor: string = '1C1B1B'
export const highlightedGroupColor: string = '41abc7'

/**
 * @deprecated
 */
function getPersonIcon(): string {
  return icons.personIcon
}

/**
 * @deprecated
 */
// fallback option incase an icon record doesnt have the default set
export function getDefaultAssetIcon(): string {
  return icons.defaultAssetIcon
}

/**
 * @deprecated
 */
// fallback option incase an icon record doesnt have the default set
export function getDefaultStaffIcon(): string {
  return icons.defaultAssetIcon
}

/**
 * @deprecated
 */
export function getAssetIconStatus(
  signalStatus: BadgeSignalStatus,
  lowBattery: boolean,
  icon: string
): string {
  let iconUrl = icon
  iconUrl =
    signalStatus !== BadgeSignalStatus.Current
      ? iconUrl.replaceAll(
          icon,
          icon.replace('.svg', `${signalStatus.replace('-', '')}.svg`)
        )
      : iconUrl
  const assetIconBody = iconUrl
  return assetIconBody
}

/**
 * @deprecated
 */
export function getIconStatusList(
  signalStatus: BadgeSignalStatus,
  lowBattery: boolean,
  icon: string,
  defaultIcon: () => string,
  isDuress = false,
  isAssist = false
): string {
  if (isDuress) {
    icon = process.env.REACT_APP_STATIC_CONTENT + 'icons/StaffDuress.svg'
  } else if (isAssist) {
    icon = process.env.REACT_APP_STATIC_CONTENT + 'icons/StaffAssist.svg'
  }
  let iconUrl = icon
  iconUrl =
    signalStatus !== BadgeSignalStatus.Current
      ? iconUrl.replaceAll(
          icon,
          icon.replace('.svg', `${signalStatus.replace('-', '')}.svg`)
        )
      : iconUrl

  const iconBody = `<svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg"><image width="100%" height="100%" href="${iconUrl}" /></svg>`
  const iconResult = iconBody ?? defaultIcon()
  const iconWithBattery =
    lowBattery === true ? replaceLowBatterySvg(iconResult) : iconResult
  return iconWithBattery ?? defaultIcon()
}

/**
 * @deprecated
 */
export function getDefaultLowBatteryAssetIcon(): string {
  return replaceLowBatterySvg(getDefaultAssetIcon()) as string
}

/**
 * @deprecated
 */
export function getGroupIcon(groupCount: number, color: string): string {
  switch (groupCount) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
      return icons.assetGroupIcon(groupCount, false, color)
    default:
      return icons.assetGroupIconOver9(false, color)
  }
}

/**
 * @deprecated
 */
function getSensorIcon(
  deviceTypeId: number,
  isConfigured = true,
  hasIssues = false
): string {
  if (deviceTypeId) {
    switch (deviceTypeIdToEnum(deviceTypeId)) {
      case DeviceType.IR:
        if (isConfigured) {
          return DeviceIcon.Ir.Wired.Configured(hasIssues)
        } else {
          return DeviceIcon.Ir.Wired.Unconfigured(hasIssues)
        }
      case DeviceType.RF:
        if (isConfigured) {
          return DeviceIcon.Rf.Wired.Configured(hasIssues)
        } else {
          return DeviceIcon.Rf.Wired.Unconfigured(hasIssues)
        }
      case DeviceType['Badge Cabinet']:
        if (isConfigured) {
          return DeviceIcon.BadgeCabinet.Wired.Configured(hasIssues)
        } else {
          return DeviceIcon.BadgeCabinet.Wired.Unconfigured(hasIssues)
        }
      case DeviceType['Drop Box']:
        if (isConfigured) {
          return DeviceIcon.DropBox.Wired.Configured(hasIssues)
        } else {
          return DeviceIcon.DropBox.Wired.Unconfigured(hasIssues)
        }
      case DeviceType.BleSensor:
        if (isConfigured) {
          return DeviceIcon.BleSensor.Wireless.Configured(hasIssues)
        } else {
          return DeviceIcon.BleSensor.Wireless.Unconfigured(hasIssues)
        }
      default:
        return DeviceIcon.BadgeCabinet.Wired.Unconfigured(false)
    }
  } else {
    return ''
  }
}

/**
 * @deprecated
 */
function getIconForAsset(
  imageSvg: string | undefined,
  isLowBattery: boolean,
  name: string
) {
  const iconImage = imageSvg ?? getDefaultAssetIcon()
  const icon = isLowBattery ? replaceLowBatterySvg(iconImage) : iconImage

  const rtn = `${
    svgUrlHeader() +
    buildSVG({
      body:
        buildAdditionalElement({
          body: String(icon),
          x: 875,
          y: 0,
          height: 128,
          width: 128,
        }) +
        buildTextElement({
          text: name,
          x: 1025,
          y: 20,
          height: 128,
          width: 1300,
          fitText: false,
          fontSize: 80,
          textX: 10,
          textY: 70,
          includeFont: false,
        }),
      height: 128,
      width: 1900,
    })
  }`
  return rtn
}

/**
 * @deprecated
 */
function getIconForLocation(name: string) {
  const rtn = `${
    svgUrlHeader() +
    buildSVG({
      body: buildGenericTextElement({
        text: name,
        x: 300,
        y: 20,
        height: 90,
        width: 700,
        fitText: false,
        fontSize: 20,
        textX: 30,
        textY: 40,
        includeFont: false,
      }),
      height: 90,
      width: 700,
    })
  }`
  return rtn
}

/**
 * @deprecated
 */
function getIconForPerson(name: string) {
  return `${
    svgUrlHeader() +
    buildSVG({
      body:
        buildAdditionalElement({
          body: getPersonIcon(),
          x: 490,
          y: 0,
          height: 128,
          width: 128,
        }) +
        buildTextElement({
          text: name,
          x: 620,
          y: 40,
          height: 128,
          width: 800,
          fitText: false,
          fontSize: 80,
          textX: 0,
          textY: 56,
          includeFont: false,
        }),
      height: 128,
      width: 1513,
    })
  }`
}

/**
 * @deprecated
 */
function getIconForSensor(
  deviceTypeId: number | undefined,
  isConfigured: boolean,
  hasIssues: boolean,
  name: string
) {
  return `${
    svgUrlHeader() +
    buildSVG({
      body:
        buildAdditionalElement({
          body: getSensorIcon(deviceTypeId || 0, isConfigured, hasIssues),
          x:
            deviceTypeIdToEnum(deviceTypeId) !== DeviceType.BleSensor
              ? 25
              : -17,
          y: 0,
          height: 150,
          width: 175,
        }) +
        buildTextElement({
          text: name,
          x: 0,
          y: 125,
          height: 42,
          width: 175,
          fitText: false,
          fontSize: 55,
          textX: 0,
          textY: 40,
          includeFont: false,
        }),
      height: 175,
      width: 175,
    })
  }`
}

/**
 * @deprecated
 */
export function getIconForGroup(name: string, color?: string) {
  return `${
    svgUrlHeader() +
    buildSVG({
      body: buildAdditionalElement({
        body: getGroupIcon(Number(name), color ?? defaultGroupColor),
        x: 5,
        y: 0,
        height: 128,
        width: 128,
      }),
      height: 128,
      width: 128,
    })
  }`
}

/**
 * @deprecated
 */
function getDefaultIcon(name: string) {
  return `${
    svgUrlHeader() +
    buildSVG({
      body: buildTextElement({
        text: name,
        x: 620,
        y: 40,
        height: 128,
        width: 800,
        fitText: false,
        fontSize: 80,
        textX: 0,
        textY: 56,
        includeFont: false,
      }),
      height: 128,
      width: 1513,
    })
  }`
}

/**
 * @deprecated
 */
export function getIcon(
  iconType: IconType,
  name: string,
  isLowBattery: boolean,
  deviceTypeId?: number,
  isConfigured = true,
  hasIssues = false,
  imageSvg?: string,
  colorHexColor?: string
): string {
  switch (iconType) {
    case IconType.Asset:
      return getIconForAsset(imageSvg, isLowBattery, name)
    case IconType.Location:
      return getIconForLocation(name)
    case IconType.Person:
      return getIconForPerson(name)
    case IconType.Sensor:
      return getIconForSensor(deviceTypeId, isConfigured, hasIssues, name)
    case IconType.Group:
      return getIconForGroup(name, colorHexColor)
    default:
      return getDefaultIcon(name)
  }
}
