import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllAlertTypesAction } from '../actions/alertTypes'
import { shouldDispatch } from '../helpers'
import { AlertTypes, AlertTypesState } from '../models'

const useFetchAlertTypes = (shouldRefresh: boolean = false) => {
  const dispatch = useDispatch()
  const status = useSelector(
    ({ alertTypes }: { alertTypes: AlertTypesState }) => alertTypes.status
  )
  const lastRefreshTime = useSelector(
    ({ alertTypes }: { alertTypes: AlertTypesState }) =>
      alertTypes.lastRefreshTime
  )

  useEffect(() => {
    if (shouldDispatch(status, lastRefreshTime) || shouldRefresh) {
      dispatch(getAllAlertTypesAction.request())
    }
  }, [status, lastRefreshTime, shouldRefresh, dispatch])

  const alertTypes: AlertTypes = useSelector(
    ({ alertTypes }: { alertTypes: AlertTypesState }) => alertTypes.data
  )

  return {
    alertTypes,
    status,
  }
}

export { useFetchAlertTypes }
