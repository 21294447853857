import { BaseService } from '@midmarkrtls/common/middleware/baseService'
import { CreateTenant } from 'models/CreateTenent'
import { Tenant } from 'v2/models'

export class TenantsApi extends BaseService {
  constructor() {
    super({ entityType: 'tenants' })
  }
  route = 'tenants'

  async getAllTenants(): Promise<Tenant[]> {
    return await this.get(`${this.route}`)
  }

  async createOperationsCustomer(record: CreateTenant): Promise<Tenant[]> {
    return await this.post(this.route, record)
  }
}

export const tenantsApi = new TenantsApi()
