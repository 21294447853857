import { useDispatch, useSelector } from 'react-redux'
import { StaffTypes, StaffTypesState } from '../models'
import { getAllStaffTypesAction } from '../actions/staffTypes'
import { shouldDispatch } from '../helpers/shouldDispatch'
import { useEffect } from 'react'

const useFetchStaffTypes = (shouldRefresh: boolean = false) => {
  const dispatch = useDispatch()
  const status = useSelector(
    ({ staffTypes }: { staffTypes: StaffTypesState }) => staffTypes.status
  )
  const lastRefreshTime = useSelector(
    ({ staffTypes }: { staffTypes: StaffTypesState }) =>
      staffTypes.lastRefreshTime
  )

  useEffect(() => {
    if (shouldDispatch(status, lastRefreshTime) || shouldRefresh) {
      dispatch(getAllStaffTypesAction.request())
    }
  }, [status, lastRefreshTime, shouldRefresh, dispatch])

  const staffTypes: StaffTypes = useSelector(
    ({ staffTypes }: { staffTypes: StaffTypesState }) => staffTypes.data
  )

  return {
    staffTypes,
    status,
  }
}

export { useFetchStaffTypes }
