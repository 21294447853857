import { getType } from 'typesafe-actions'
import { TenantAction } from 'v2/actions'
import { Tenant, Tenants, TenantsState } from 'v2/models'
import { mergeRecords, setFetchingStatus } from '@midmarkrtls/common/utils'
import {
  getAllTenantsAction,
  getCurrentUserTenantsAction,
  postOperationsCustomerAction,
} from 'v2/actions/tenants'
import { FetchingStatus } from '@midmarkrtls/common/models'

const initialState: TenantsState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

const key = 'tenantId'
const tenantsReducer = (
  state: TenantsState = initialState,
  action: TenantAction
): TenantsState => {
  switch (action.type) {
    case getType(getAllTenantsAction.success):
    case getType(getCurrentUserTenantsAction.success):
    case getType(postOperationsCustomerAction.success):
      return {
        data: mergeRecords<Tenants, Tenant, typeof key>(
          state.data,
          action.payload,
          key
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getAllTenantsAction.request):
    case getType(getCurrentUserTenantsAction.request):
    case getType(postOperationsCustomerAction.request):
      return setFetchingStatus<Tenants>(state, FetchingStatus.Request)

    case getType(getAllTenantsAction.failure):
    case getType(getCurrentUserTenantsAction.failure):
    case getType(postOperationsCustomerAction.failure):
      return setFetchingStatus<Tenants>(
        state,
        FetchingStatus.Failure,
        action.payload
      )

    default:
      return state
  }
}

export default tenantsReducer
