import { getType } from 'typesafe-actions'
import { DeviceAction } from '../actions'
import {
  getAllDevicesAction,
  getDeviceAction,
  putDevicesAction,
} from '../actions/devices'
import { Devices, Device, DevicesState, FetchingStatus } from '../models'
import { mergeRecords, mergeRecord } from '../utils'

const initialState: DevicesState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

export default (state = initialState, action: DeviceAction): DevicesState => {
  switch (action.type) {
    case getType(getAllDevicesAction.request):
    case getType(putDevicesAction.request):
    case getType(getDeviceAction.request):
      return {
        ...state,
        status: FetchingStatus.Request,
      }

    case getType(getAllDevicesAction.failure):
    case getType(putDevicesAction.failure):
    case getType(getDeviceAction.failure):
      return {
        ...state,
        status: FetchingStatus.Failure,
      }

    case getType(getAllDevicesAction.success):
      return {
        data: mergeRecords<Devices, Device, 'deviceGuid'>(
          state.data,
          action.payload,
          'deviceGuid'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getDeviceAction.success):
      return {
        data: mergeRecord<Devices, Device, 'deviceGuid'>(
          state.data,
          action.payload,
          'deviceGuid'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(putDevicesAction.success):
      return {
        data: mergeRecords<Devices, Device, 'deviceGuid'>(
          state.data,
          action.payload,
          'deviceGuid'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    default:
      return state
  }
}
