import { BaseService } from './baseService'
import { UserPreferences } from '../models'

export class UserPreferencesApi extends BaseService {
  constructor() {
    super({ entityType: 'userPreferences' })
  }
  route = 'users/preference'

  async getUserPreferences(): Promise<UserPreferences> {
    return await this.get<UserPreferences>(this.route)
  }
  async putUserPreferences(
    userPreferences: UserPreferences
  ): Promise<UserPreferences> {
    return await this.put<UserPreferences>(this.route, userPreferences)
  }
}
