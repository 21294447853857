import React, { useMemo } from 'react'
import { AlertActiveConfig } from '../../models/alert'
import { DetailedItem } from '../../models/DetailPaneItems'
import DetailsList from '../DetailsList/DetailsList'

interface Props {
  alert: AlertActiveConfig
  isLoading?: boolean
  headerStylesOverrides?: any
  listItemStylesOverrides?: any
}

const AlertTriggerDetail = (props: Props) => {
  const {
    alert,
    isLoading = false,
    headerStylesOverrides,
    listItemStylesOverrides,
  } = props

  const triggerItems = useMemo(() => {
    return [
      {
        field: 'Trigger',
        value: alert?.trigger?.triggerDescription,
      },
    ]
  }, [alert])

  const alertTriggerDetailItems: DetailedItem[] = []
  alertTriggerDetailItems.push({
    header: 'TRIGGERS',
    items: triggerItems,
  })

  return (
    <DetailsList
      detailedItems={alertTriggerDetailItems}
      narrowRows={true}
      headerStylesOverrides={headerStylesOverrides}
      listItemStylesOverrides={listItemStylesOverrides}
      isLoading={isLoading}
    />
  )
}

export default AlertTriggerDetail
