import { getType } from 'typesafe-actions'
import { SensoryReferenceAction } from '../actions'
import { FetchingStatus } from '../models'
import { mergeRecords, setFetchingStatus } from '../utils'
import {
  SensoryReference,
  SensoryReferences,
  SensoryReferencesState,
} from '../models/SensoryReference'
import {
  getAllSensoryReferenceByTenantIdAction,
  postImportSensoryReferenceAction,
} from '../actions/sensoryReference'

const initialState: SensoryReferencesState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

export default (
  state = initialState,
  action: SensoryReferenceAction
): SensoryReferencesState => {
  switch (action.type) {
    case getType(postImportSensoryReferenceAction.request):
    case getType(getAllSensoryReferenceByTenantIdAction.request):
      return setFetchingStatus<SensoryReferences>(state, FetchingStatus.Request)

    case getType(postImportSensoryReferenceAction.failure):
    case getType(getAllSensoryReferenceByTenantIdAction.failure):
      return setFetchingStatus<SensoryReferences>(state, FetchingStatus.Failure)

    case getType(postImportSensoryReferenceAction.success):
    case getType(getAllSensoryReferenceByTenantIdAction.success):
      return {
        data: mergeRecords<
          SensoryReferences,
          SensoryReference,
          'enterpriseSensoryId'
        >(state.data, action.payload, 'enterpriseSensoryId'),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }

    default:
      return state
  }
}
