import { BaseService } from '../middleware/baseService'
import { Venue, SyncVenueResult } from '../models'

export class VenuesApi extends BaseService {
  constructor() {
    super({ entityType: 'venues' })
  }
  route = 'venues'

  async getAllVenues(): Promise<Venue[]> {
    return await this.get<Venue[]>(this.route)
  }

  async getSyncVenues(venueId: string): Promise<SyncVenueResult> {
    return await this.get<SyncVenueResult>(`${this.route}/${venueId}/import`)
  }

  async deleteVenues(venueId: number): Promise<void> {
    return await this.delete<void>(`${this.route}/${venueId}`)
  }

  async deleteAllVenues(): Promise<void> {
    return await this.delete<void>(`${this.route}/all`)
  }
}
