export enum DataTestIds {
  duressBannerItem = 'duressBannerItem',
  duressResolve = 'duressResolve',
  bannerDuressMessage = 'bannerDuressMessage',
  customSvgIcon = 'customSvgIcon',
  duressCount = 'duressCount',
  actionConfirmationModal = 'actionConfirmationModal',
  duressConfirmationMessage = 'duressConfirmationMessage',
  stackedModalFront = 'stackedModalFront',
  stackedModalBack = 'stackedModalBack',
  modalTimeElapsed = 'modalTimeElapsed',
  inRoomHeader = 'inRoomHeader',
  duressReportedBy = 'duressReportedBy',
  duressMessage = 'duressMessage',
  resolveDuressConfirmationModal = 'resolveDuressConfirmationModal',
  appBanner = 'appBanner',
  bannerTimeElapsed = 'bannerTimeElapsed',
  resolveDuressError = 'resolveDuressError',
  locationBreadcrumbs = 'locationBreadcrumbs',
  headerToolbarSpacer = 'headerToolbarSpacer',
  alertAdminPageAlertToolbar = 'alertAdminPageAlertToolbar',
  alertAdminPageAlertListContainer = 'alertAdminPageAlertListContainer',
  radioSavedFilterButtonChecked = 'radioSavedFilterButtonChecked',
  radioSavedFilterButtonUnChecked = 'radioSavedFilterButtonUnChecked',
  commonFilterOptionsAssignedTo = 'commonFilterOptionsAssignedTo',
  commonFilterOptionsBatteryChangeDate = 'commonFilterOptionsBatteryChangeDate',
  commonFilterOptionsBatteryStatus = 'commonFilterOptionsBatteryStatus',
  commonFilterOptionsSignalType = 'commonFilterOptionsSignalType',
  commonFilterOptionsSignalStatus = 'commonFilterOptionsSignalStatus',
  commonFilterOptionsLocationAccuracy = 'commonFilterOptionsLocationAccuracy',
  commonFilterOptionsHasBadge = 'commonFilterOptionsHasBadge',
  commonFilterOptionsOnMyList = 'commonFilterOptionsOnMyList',
  commonFilterOptionsLowBattery = 'commonFilterOptionsLowBattery',
  notificationBarMessageTypoghaphy = 'notificationBarMessageTypoghaphy',
  notificationBarRightTextTypoghaphy = 'notificationBarRightTextTypoghaphy',
  savedFiltersControl = 'savedFiltersControl',
  savedFiltersRadioButton = 'savedFiltersRadioButton',
  savedFiltersDeleteButton = 'savedFiltersDeleteButton',
  staffManagementModal = 'staffManagementModal',
  listFilterContentCurrentCommonFilters = 'listFilterContentCurrentCommonFilters',
  listFilterSavedFilters = 'listFilterSavedFilters',
  sideNavLinkIcon = 'sideNavLinkIcon',
  userManagementForm = 'userManagementForm',
  userManagementFormRTLSID = 'userManagementFormRTLSID',
  userManagementFormFirstName = 'userManagementFormFirstName',
  userManagementFormLastName = 'userManagementFormLastName',
  userManagementFormEmail = 'userManagementFormEmail',
  userManagementFormDisplayName = 'userManagementFormDisplayName',
  userManagementFormStaffCheckBox = 'userManagementFormStaffCheckBox',
  assetManForm = 'assetManForm',
  assetManFormAssetId = 'assetManFormAssetId',
  assetManFormName = 'assetManFormName',
  assetManFormDisplayName = 'assetManFormDisplayName',
  assetManFormOwner = 'assetManFormOwner',
  assetManFormRTLSID = 'assetManFormRTLSID',
  assetManFormAssetType = 'assetManFormAssetType',
  assetManFormAssetSubType = 'assetManFormAssetSubType',
  assetManFormAssignedTo = 'assetManFormAssignedTo',
  assetManFormManufacturer = 'assetManFormManufacturer',
  assetManFormSerialNumber = 'assetManFormSerialNumber',
  assetManFormModel = 'assetManFormModel',
  assetManFormNextPMDate = 'assetManFormNextPMDate',
  assetManFormRTLSBatteryChange = 'assetManFormRTLSBatteryChange',
  inpixonMap = 'inpixonMap',
  locationManagementMap = 'locationManagementMap',
  liveTelemetryIndicator = 'liveTelemetryIndicator',
  alertDetailPaneHeader = 'alerDetailPaneHeader',
  alertDetailPaneHeaderDelete = 'alerDetailPaneHeaderDelete',
  alertDetailPaneHeaderEnable = 'alerDetailPaneHeaderEnable',
  alertDetailPaneHeaderEdit = 'alerDetailPaneHeaderEdit',
  alertAdminPageAlertList = 'alertAdminPageAlertList',
  quickLinksHeader = 'quickLinksHeader',
  quickLinksCustomizeButton = 'quickLinksCustomizeButton',
  quickLinksWidgetRealTimeAssets = 'quickLinksWidgetRealTimeAssets',
  quickLinksWidgetOpenPMs = 'quickLinksWidgetOpenPMs',
  quickLinksWidgetMyAssetList = 'quickLinksWidgetMyAssetList',
  quickLinksWidgetLowBatteries = 'quickLinksWidgetLowBatteries',
  quickLinksWidgetRealTimeStaff = 'quickLinksWidgetRealTimeStaff',
  quickLinksWidgetMyStaffList = 'quickLinksWidgetMyStaffList',
  quickLinksWidgetAssetNotifications = 'quickLinksWidgetAssetNotifications',
  importDetailPane = 'importDetailPane',
  buildingCardContent = 'buildingCardContent,',
  notificationsPageToolbar = 'notificationsPageToolbar',
}
