import React from 'react'
import { SignalType } from '../../models'
import { CurrentUserLocation } from '../../models/currentUserLocation'
import { localMomentFormatted } from '../../helpers/date'
import { badgeSignalAccuracy, badgeSignalStatus } from '../../helpers'
import {
  getLocationBasedBatteryPercent,
  getLocationBasedBatteryStatus,
} from '../../helpers/battery'
import UserProfileColumnText from './UserProfileColumnText'
import UserProfileColumn from './UserProfileColumn'
import UserProfileCard from './UserProfileCard'

interface Props {
  currentLocation: CurrentUserLocation | undefined
  style?: React.CSSProperties
}

const UserCurrentLocationCard: React.FC<Props> = (props: Props) => {
  const { currentLocation, style } = props

  const title = 'Current Location Data'

  const leftContent = {
    'Building Group': currentLocation?.buildingGroup ?? '-',
    Building: currentLocation?.building ?? '-',
    Floor: currentLocation?.floor ?? '-',
    Room: currentLocation?.room ?? '-',
    Unit: currentLocation?.unitName ?? '-',
    SubUnit: currentLocation?.subUnitName ?? '-',
  }

  const rightContent = {
    'RTLS Signal Confidence': currentLocation?.badgeTelemetry?.signalTypeId
      ? badgeSignalAccuracy(currentLocation?.badgeTelemetry?.signalTypeId)
      : '-',
    'RTLS Signal Status': currentLocation?.badgeTelemetry?.timestamp
      ? badgeSignalStatus(currentLocation?.badgeTelemetry?.timestamp)
      : '-',
    'RTLS Signal Type': currentLocation?.badgeTelemetry?.signalTypeId
      ? SignalType[currentLocation?.badgeTelemetry?.signalTypeId]
      : '-',
    'RTLS Battery Status': currentLocation?.batteryPercent
      ? getLocationBasedBatteryStatus(
          currentLocation.roomLocation,
          currentLocation.isLowBattery
        ) ?? '-'
      : '-',
    'RTLS Battery Change Date': currentLocation?.tagBatteryChangeDate
      ? localMomentFormatted(
          currentLocation?.tagBatteryChangeDate,
          'MM/DD/YYYY'
        )
      : '-',
  }

  return (
    <div style={style}>
      <UserProfileCard title={title}>
        <UserProfileColumn>
          <UserProfileColumnText
            keyValDictionary={leftContent}
          ></UserProfileColumnText>
        </UserProfileColumn>
        <UserProfileColumn>
          <UserProfileColumnText
            keyValDictionary={rightContent}
          ></UserProfileColumnText>
        </UserProfileColumn>
      </UserProfileCard>
    </div>
  )
}

export default UserCurrentLocationCard
