import {
  IconButton as MatIconButton,
  Button as MatButton,
  TextField as MatTextField,
  Select as MatSelect,
  Badge as MatBadge,
  SelectProps,
} from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import { ToggleButton as MatToggleButton } from '@mui/material'
import React, { ReactNode } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import { StyledComponentProps } from '..'
import { colors } from '../../styles/MidmarkTheme'

const theme = {
  borderRadius: 4,
  controlHeight: 36,
}

export const TextField = withStyles(
  {
    root: {
      background: 'rgba(255, 255, 255, 0.05)',
      margin: '4px 0',
      borderRadius: theme.borderRadius,
      height: theme.controlHeight,
      minWidth: '270px',
      maxWidth: '100%',
      '& .MuiInputBase-input': {
        color: colors.darkOlive,
      },
      '& .MuiInputBase-root': {
        color: 'rgba(255, 255, 255, 0.75)',
        '&.Mui-focused': {
          color: colors.black,
          backgroundColor: colors.whiteSmoke,
        },
      },
      '& .MuiInputAdornment-root': {
        color: 'inherit',
      },
      '& .MuiOutlinedInput-inputSizeSmall': {
        height: 20,
        lineHeight: 20,
        paddingTop: 8,
        paddingBottom: 8,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: colors.background,
        },
        '&:hover fieldset': {
          borderColor: colors.background,
        },
        '&.Mui-focused fieldset': {
          borderColor: colors.background,
        },
        '& input::placeholder': {
          color: colors.whiteGrey,
          opacity: 1,
        },
        '& input:focus::placeholder': {
          color: colors.offWhite,
          opacity: 1,
        },
      },
    },
  },
  { name: 'AbTextField' }
)(MatTextField)

export const Select = withStyles(
  {
    select: {
      background: colors.white,
      margin: 0,
      borderRadius: theme.borderRadius,
      height: 20,
      lineHeight: '20px',
      paddingTop: 8,
      paddingBottom: 8,
      '&, &:focus, &:active': {
        background: colors.white,
        color: colors.assetTimedOut,
        borderRadius: theme.borderRadius,
      },
      '&.MuiInputBase-input': {
        color: colors.assetTimedOut,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: colors.background,
        },
        '&:hover fieldset': {
          borderColor: colors.background,
        },
        '&.Mui-focused fieldset': {
          borderColor: colors.background,
        },
      },
    },
  },
  { name: 'AbSelect' }
)((props: SelectProps) => {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    variant: overriddenVariant,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    margin: overriddenMargin,
    ...other
  } = props

  return <MatSelect variant='outlined' margin='dense' {...other}></MatSelect>
})

export const Badge = withStyles(
  {
    badge: {
      backgroundColor: colors.lightTeal,
      color: colors.white,
      top: -4,
      right: -4,
    },
  },
  { name: 'AbBadge' }
)(MatBadge)

export const IconButton = withStyles(
  {
    root: {
      background: colors.white,
      borderColor: `1px solid ${colors.background}`,
      borderRadius: 4,
      padding: 5,
      color: colors.primary,
      '&:hover': {
        background: colors.white,
      },
    },
  },
  { name: 'AbIconButton' }
)(MatIconButton)

const buttonStyles = createStyles({
  root: {
    background: colors.white,
    borderColor: `1px solid ${colors.background}`,
    borderRadius: 4,
    padding: 5,
    color: colors.primary,
    '&:hover': {
      background: colors.white,
    },
  },
})

export const Button = withStyles(buttonStyles, { name: 'AbButton' })(MatButton)

// eslint-disable-next-line react/display-name
export const LinkBehavior = React.forwardRef<any, LinkProps>((props, ref) => (
  <Link ref={ref} {...props} />
))
type LinkButtonProps = Pick<LinkProps, 'to' | 'replace'> &
  StyledComponentProps<{ disabled?: boolean; active?: boolean }>

export const LinkButton = withStyles(buttonStyles, { name: 'AbLinkButton' })(
  (props: LinkButtonProps) => {
    return (
      <MatButton
        variant='outlined'
        color='inherit'
        component={LinkBehavior}
        {...props}
      ></MatButton>
    )
  }
)

export const ToggleButton = withStyles(
  {
    root: {
      background: 'rgba(0, 0, 0, 0)',
      color: colors.white,
      borderColor: colors.midGrey2,
      '&.Mui-selected, &.Mui-selected:hover': {
        background: colors.darkBlue,
        color: colors.white,
      },
      '&.Mui-disabled': {
        color: colors.white,
        borderColor: colors.midGrey2,
      },
      padding: '6px 12px',
      minWidth: 90,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '20px',
      textTransform: 'none',
    },
    selected: {},
    disabled: {},
  },
  { name: 'AbToggleButton' }
)(MatToggleButton)
