import { getType } from 'typesafe-actions'
import { UserAction } from '../actions'
import {
  acknowledgeHttpError,
  getAllUsersAction,
  getUserByIdAction,
  getUserByEmailAction,
  postUserAction,
  putUserAction,
  deleteUserAction,
} from '../actions/users'
import { User, Users, UsersState } from '../models/users'
import { deleteRecord, mergeRecord, mergeRecords } from '../utils'
import { FetchingStatus } from '../models/fetchingStatus'
import { HttpErrorResponse } from '../services/http'

const initialState: UsersState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

const key = 'id'
export default (
  state: UsersState = initialState,
  action: UserAction
): UsersState => {
  switch (action.type) {
    case getType(acknowledgeHttpError):
      if (state.error !== undefined) {
        state.error.acknowledged = action.payload
        return {
          ...state,
        }
      }
      return {
        ...state,
      }
    case getType(getAllUsersAction.success):
      return {
        data: mergeRecords<Users, User, typeof key>(
          state.data,
          action.payload,
          key
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getUserByIdAction.success):
    case getType(getUserByEmailAction.success):
    case getType(postUserAction.success):
    case getType(putUserAction.success):
      return {
        data: mergeRecord<Users, User, typeof key>(
          state.data,
          action.payload,
          key
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(deleteUserAction.success):
      return {
        data: deleteRecord<Users, User>(state.data, action.payload),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }

    case getType(getAllUsersAction.request):
    case getType(getUserByIdAction.request):
    case getType(getUserByEmailAction.request):
    case getType(postUserAction.request):
    case getType(putUserAction.request):
    case getType(deleteUserAction.request):
      return {
        ...state,
        status: FetchingStatus.Request,
      }

    case getType(getAllUsersAction.failure):
    case getType(getUserByIdAction.failure):
    case getType(getUserByEmailAction.failure):
    case getType(postUserAction.failure):
    case getType(putUserAction.failure):
    case getType(deleteUserAction.failure):
      return {
        ...state,
        status: FetchingStatus.Failure,
        error: action.payload as HttpErrorResponse,
        errorAcknowledged: false,
      }

    default:
      return state
  }
}
