import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Location, Locations, LocationsState, LocationType } from '../models'

export default function useFloorSelection() {
  const currentLocation: Location = useSelector(
    ({ currentLocation }: { currentLocation: Location }) => currentLocation
  )

  const locations: Locations = useSelector(
    ({ locations }: { locations: LocationsState }) => locations.data
  )

  const { floors, building } = useMemo(() => {
    let floorsTemp: Location[] = []
    let buildingTemp: Location | null = null

    if (currentLocation?.locationType === LocationType.Building) {
      buildingTemp = currentLocation
      floorsTemp = Object.values(locations).filter(
        (x) => x.parentId === currentLocation.id
      )
    } else if (currentLocation?.locationType === LocationType.Floor) {
      floorsTemp = Object.values(locations).filter(
        (x) => x.parentId === currentLocation.parentId
      )
      buildingTemp =
        Object.values(locations).find((x) => x.id === floorsTemp[0].parentId) ??
        null
    } else {
      buildingTemp = null
      floorsTemp = []
    }

    return { floors: floorsTemp, building: buildingTemp }
  }, [currentLocation, locations])

  return { building, floors }
}
