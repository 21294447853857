import { Box, TablePagination } from '@mui/material'
import {
  Column,
  ColumnOrderState,
  ColumnPinningState,
  ColumnSort,
  Header,
  HeaderGroup,
  InitialTableState,
  Row,
  RowData,
  SortDirection,
  SortingFn,
  SortingState,
  TableOptions,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  EpicTableFooter,
  Table,
  TableBody,
  TableCell,
  TableEmptyMessage,
  TableHead,
  TableRow,
  TableScroll,
  TableToolbar,
  TableToolbarOptions,
  TableView,
} from '.'
import {
  customSortByAdvancedString,
  customSortByDate,
  mapTableColumnSettingsToColumnState,
  renderCellContent,
  shouldResetPageIndex,
} from '../../helpers/reactTable'
import { useElementSize } from '../../hooks'
import { TableColumnSetting, TableColumnSettings } from '../../models'
import TableColumnCustomizationModal from './TableColumnCustomizationModal'
import TableSkeleton from './TableSkeleton'
import { TableSortLabel } from './Theme'
import { SelectRowActionPayload } from './useReactTableRightDrawer'
import { isEqual } from 'lodash'

declare module '@tanstack/table-core' {
  interface SortingFns {
    rtlsDateTime: SortingFn<unknown>
    rtlsAdvSort: SortingFn<unknown>
  }
  interface ColumnMeta<TData extends RowData, TValue> {
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
    headerStyle?: React.CSSProperties
    dataCellStyle?: React.CSSProperties
  }
}

interface ReactTableOptions<T>
  extends Pick<TableOptions<T>, 'data' | 'columns'> {
  stickyHeader?: boolean
  showLoading?: boolean
  skeletonRowCount?: number
  emptyMessage?: ReactNode
}

interface TablePaginationOptions {
  pageSizeOptions?: number[]
  initialPageSize?: number
}

export interface TableRowSelectionOptions<T> {
  selectedRow: T | undefined
  selectedRowIndex: number | undefined
  onSelectRow: (data: SelectRowActionPayload<T>) => void
  dataMatchPredicate: (valueA: T, valueB: T) => boolean
  clearSelectedRow: () => void
  resetSelectedRowIndex: (index: number) => void
  updateSelectedRow: (updatedRow: T) => void
}

interface TableRowOptions<T> {
  rowSelectionOptions?: TableRowSelectionOptions<T>
  getDynamicRowStyle?: (row: Row<T>) => React.CSSProperties
}

interface TableSortingOptions {
  defaultSortBy?: ColumnSort
  savedFilterSortBy?: ColumnSort
  onSortingChange?: (columnSort: ColumnSort[]) => void
}

export interface ServerSidePaginationOptions {
  manualSortHandler: (columnSort: ColumnSort[]) => void
  recordCount: number
  paginationNextLink: string | undefined
  paginationPrevLink: string | undefined
  onPageChanged: (pageIndex: number) => void
  onPageSizeChanged: (pageSize: number) => void
  pageIndex: number
}

interface TableColumnUserCustomizationOptions {
  userSettings: TableColumnSetting[]
  onSaveColumnCustomization: (
    settings: TableColumnSettings,
    saveDefault: boolean
  ) => void
}

interface UrlSearchParamsOptions<T> {
  rowToSelectFromUrlSearchParams: T | undefined
  rowMatchPredicate: (a: T, b: T) => boolean
}

interface Props<T> {
  tableToolbarOptions: Pick<
    TableToolbarOptions,
    'title' | 'subTitle' | 'search' | 'actions'
  >
  tableOptions: ReactTableOptions<T>
  tablePaginationOptions?: TablePaginationOptions
  tableColumnUserCustomizationOptions?: TableColumnUserCustomizationOptions
  tableRowOptions?: TableRowOptions<T>
  tableSortingOptions?: TableSortingOptions
  preSelectedItemFromUrlSearchParams?: UrlSearchParamsOptions<T>
  serverSidePaginationOptions?: ServerSidePaginationOptions
}

const defaultPageSizes = [50, 100, 200]

// eslint-disable-next-line @typescript-eslint/ban-types
const ReactTable = <T extends object>(props: Props<T>): ReactElement => {
  const {
    tableToolbarOptions,
    tableOptions,
    tablePaginationOptions,
    tableColumnUserCustomizationOptions,
    tableRowOptions,
    tableSortingOptions,
    preSelectedItemFromUrlSearchParams,
    serverSidePaginationOptions,
  } = props

  const { title, subTitle, actions } = tableToolbarOptions
  const {
    stickyHeader = true,
    showLoading = false,
    skeletonRowCount,
    data,
    columns,
    emptyMessage,
  } = tableOptions
  const { userSettings, onSaveColumnCustomization } =
    tableColumnUserCustomizationOptions ?? {}
  const { initialPageSize, pageSizeOptions } = tablePaginationOptions ?? {}
  const { defaultSortBy, savedFilterSortBy, onSortingChange } =
    tableSortingOptions ?? {}
  const { rowSelectionOptions, getDynamicRowStyle } = tableRowOptions ?? {}
  const {
    onSelectRow,
    dataMatchPredicate,
    clearSelectedRow,
    resetSelectedRowIndex,
    updateSelectedRow,
    selectedRow,
    selectedRowIndex,
  } = rowSelectionOptions ?? {}
  const { rowToSelectFromUrlSearchParams, rowMatchPredicate } =
    preSelectedItemFromUrlSearchParams ?? {}
  const {
    manualSortHandler,
    onPageChanged,
    onPageSizeChanged,
    recordCount,
    paginationNextLink,
    paginationPrevLink,
    pageIndex,
  } = serverSidePaginationOptions ?? {}

  const tableScrollRef = useRef<HTMLDivElement>(null)
  const tableScrollSize = useElementSize(tableScrollRef)
  const preSelectedItemFromSearchUrlParamsRef = useRef<HTMLDivElement>(null)

  const [skeletonRows, setSkeletonRows] = useState(skeletonRowCount ?? 25)
  const [selectRowFromUrlSearchParams, setSelectRowFromUrlSearchParams] =
    useState<boolean>(false)
  const [sorting, setSorting] = useState<SortingState>(() => {
    return defaultSortBy
      ? [defaultSortBy]
      : savedFilterSortBy
      ? [savedFilterSortBy]
      : []
  })
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({})
  const [columnPinning, setColumnPinning] = React.useState<ColumnPinningState>(
    {}
  )
  const [columnOrder, setColumnOrder] = React.useState<ColumnOrderState>([])
  const [customizeFormOpen, setCustomizeFormOpen] = useState(false)

  const initialState: InitialTableState = {
    pagination: {
      pageSize: initialPageSize ?? defaultPageSizes[0],
    },
    sorting: defaultSortBy
      ? [defaultSortBy]
      : savedFilterSortBy
      ? [savedFilterSortBy]
      : [],
  }

  const table = useReactTable<T>({
    data,
    columns,
    initialState,
    state: {
      sorting,
      columnVisibility,
      columnPinning,
      columnOrder,
    },
    enableSortingRemoval: false,
    enableMultiRowSelection: false,
    enableMultiSort: false,
    manualSorting: !!serverSidePaginationOptions,
    manualPagination: !!serverSidePaginationOptions,
    autoResetPageIndex: false,
    sortingFns: {
      rtlsDateTime: customSortByDate,
      rtlsAdvSort: customSortByAdvancedString,
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
  })

  const {
    getHeaderGroups,
    getRowModel,
    getState,
    setPageIndex,
    setPageSize,
    getCanNextPage,
    getCanPreviousPage,
    getPrePaginationRowModel,
    getAllColumns,
    getVisibleFlatColumns,
    resetSorting,
  } = table

  const pageSizes = useMemo(() => {
    const rows = getRowModel().rows
    const pageSizes = pageSizeOptions ?? defaultPageSizes
    return rows.length > Math.max(...pageSizes)
      ? [...pageSizes, { label: 'All', value: rows.length }]
      : pageSizes
  }, [getRowModel, pageSizeOptions])

  const showEmptyTable = useMemo(() => {
    return !showLoading && data && data.length === 0
  }, [data, showLoading])

  const handleRowClick = (row: Row<T>) => (): void => {
    if (onSelectRow && row.original) {
      onSelectRow({
        selectedItem: row.original,
        selectedRowIndex: row.index,
      })
    }
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ): void => {
    if (clearSelectedRow) {
      clearSelectedRow()
    }
    if (onPageChanged) {
      onPageChanged(page + 1)
    } else {
      setPageIndex(page)
    }
    tableScrollRef.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const pageSize = Number(event.target.value)
    setPageSize(pageSize)
    if (clearSelectedRow) {
      clearSelectedRow()
    }
    if (onPageSizeChanged) {
      onPageSizeChanged(pageSize)
    }
  }

  const handleCloseCustomizeTableModal = () => {
    setCustomizeFormOpen(false)
  }

  const handleOpenCustomizeTableModal = () => {
    setCustomizeFormOpen(true)
  }

  // Set the skeleton rows based on view size
  useEffect(() => {
    const tableScrollHeight = tableScrollSize.height

    if (tableScrollHeight > 100) {
      const numRows = Math.floor(tableScrollHeight / 50)
      setSkeletonRows(Math.min(numRows, skeletonRows ?? 15))
    }
  }, [tableScrollSize, skeletonRows])

  useEffect(() => {
    if (savedFilterSortBy) {
      setSorting([savedFilterSortBy])
    } else {
      resetSorting()
    }
  }, [resetSorting, savedFilterSortBy])

  useEffect(() => {
    if (columns.length === 0) {
      return
    }
    if (userSettings && userSettings?.length > 0) {
      const columnCustomizationState = mapTableColumnSettingsToColumnState(
        userSettings,
        getAllColumns()
      )
      setColumnOrder(columnCustomizationState.columnOrdering)
      setColumnVisibility(columnCustomizationState.columnVisibility)
      setColumnPinning(columnCustomizationState.columnPinning)
    } else {
      const columnVisibility: VisibilityState = {}
      const leftPinnnedColumns: string[] = []
      const rightPinnedColumns: string[] = []
      let isLeftPinned = true
      getAllColumns().forEach((column) => {
        if (isLeftPinned) {
          if (column.columnDef.enablePinning) {
            leftPinnnedColumns.push(column.id)
          } else {
            isLeftPinned = false
          }
        } else {
          if (column.columnDef.enablePinning) {
            rightPinnedColumns.push(column.id)
          }
        }

        if (column.columnDef.enableHiding) {
          columnVisibility[column.id] = false
        }
      })
      setColumnPinning({
        left: leftPinnnedColumns,
        right: rightPinnedColumns,
      })
      // Tim Groven - 3 Jan 2024 - https://versuscarina.visualstudio.com/Carina/_workitems/edit/35007
      // Need to always call setColumnVisibility in case things got reset to default columns and
      // columnVisibility is empty, it still needs to be set as empty
      setColumnVisibility(columnVisibility)
      setColumnOrder(getAllColumns().map((column) => column.id))
    }
  }, [getAllColumns, columns, userSettings])

  useEffect(() => {
    if (
      !selectedRow ||
      !dataMatchPredicate ||
      !clearSelectedRow ||
      !resetSelectedRowIndex
    ) {
      return
    }
    if (
      data.length === 0 ||
      data.findIndex((d) => dataMatchPredicate(d, selectedRow)) < 0
    ) {
      clearSelectedRow()
    } else {
      const updatedSelectedRowIndex = getRowModel().rows.find((d) =>
        dataMatchPredicate(d.original, selectedRow)
      )?.index
      if (updatedSelectedRowIndex === undefined) {
        clearSelectedRow()
      } else {
        if (updatedSelectedRowIndex !== selectedRowIndex) {
          resetSelectedRowIndex(updatedSelectedRowIndex)
        }
      }
    }
  }, [
    clearSelectedRow,
    data,
    dataMatchPredicate,
    getRowModel,
    getState,
    resetSelectedRowIndex,
    selectedRow,
    selectedRowIndex,
  ])

  useEffect(() => {
    if (data.length === 0 || selectRowFromUrlSearchParams || !onSelectRow) {
      return
    }
    if (rowToSelectFromUrlSearchParams && rowMatchPredicate) {
      const itemIndex = data.findIndex((x) =>
        rowMatchPredicate(x, rowToSelectFromUrlSearchParams)
      )
      const pageIndex = Math.floor(itemIndex / getState().pagination.pageSize)
      setPageIndex(pageIndex)
      if (
        tableScrollRef.current &&
        preSelectedItemFromSearchUrlParamsRef.current
      ) {
        tableScrollRef.current.scrollTo({
          behavior: 'smooth',
          top:
            preSelectedItemFromSearchUrlParamsRef.current?.offsetTop -
            (preSelectedItemFromSearchUrlParamsRef.current?.offsetHeight + 4),
        })
      }
      onSelectRow({
        selectedItem: rowToSelectFromUrlSearchParams,
        selectedRowIndex: itemIndex,
      })
      setSelectRowFromUrlSearchParams(true)
    }
  }, [
    data,
    getState,
    rowMatchPredicate,
    selectRowFromUrlSearchParams,
    rowToSelectFromUrlSearchParams,
    setPageIndex,
    onSelectRow,
  ])

  useEffect(() => {
    const totalItems =
      !!serverSidePaginationOptions && !!recordCount ? recordCount : data.length
    if (
      shouldResetPageIndex(
        totalItems,
        getState().pagination.pageIndex,
        getState().pagination.pageSize
      )
    ) {
      setPageIndex(0)
    }
  }, [data, getState, recordCount, serverSidePaginationOptions, setPageIndex])

  const disabledPrevPage = useCallback(() => {
    if (showLoading || data.length === 0) {
      return true
    }
    if (!!serverSidePaginationOptions) {
      return !paginationPrevLink
    }
    return !getCanPreviousPage()
  }, [
    getCanPreviousPage,
    paginationPrevLink,
    serverSidePaginationOptions,
    showLoading,
    data,
  ])

  const disabledNextPage = useCallback(() => {
    if (showLoading || data.length === 0) {
      return true
    }
    if (!!serverSidePaginationOptions) {
      return !paginationNextLink
    }
    return !getCanNextPage()
  }, [
    getCanNextPage,
    paginationNextLink,
    serverSidePaginationOptions,
    showLoading,
    data,
  ])

  useEffect(() => {
    if (pageIndex !== undefined) {
      setPageIndex(pageIndex)
    }
  }, [pageIndex, setPageIndex])

  useEffect(() => {
    if (selectedRow && dataMatchPredicate && updateSelectedRow) {
      const updatedSelectedRow = data.find((x) =>
        dataMatchPredicate(x, selectedRow)
      )
      if (updatedSelectedRow && !isEqual(updatedSelectedRow, selectedRow)) {
        updateSelectedRow(updatedSelectedRow)
      }
    }
  }, [data, dataMatchPredicate, selectedRow, updateSelectedRow])

  const handleSort = useCallback(
    (column: Column<T>) => {
      if (!column.getCanSort()) return // short circuit, column is not sortable

      const columnSort: ColumnSort[] = [
        {
          id: column.id,
          desc: !column.getIsSorted()
            ? false
            : (column.getIsSorted() as SortDirection) === 'desc'
            ? false
            : true,
        },
      ]
      if (manualSortHandler) {
        manualSortHandler(columnSort)
      }
      if (onSortingChange) {
        onSortingChange(columnSort)
      }
      setSorting(columnSort)
      setPageIndex(0)
      if (clearSelectedRow) {
        clearSelectedRow()
      }
    },
    [clearSelectedRow, manualSortHandler, onSortingChange, setPageIndex]
  )

  return (
    <>
      <TableView fullHeight={true}>
        <TableToolbar
          title={title}
          subTitle={subTitle}
          actions={actions}
          tableSettingsCustomizeFormOpen={
            tableColumnUserCustomizationOptions
              ? handleOpenCustomizeTableModal
              : undefined
          }
        />
        <TableScroll ref={tableScrollRef}>
          <Table component='div' stickyHeader={stickyHeader}>
            <TableHead component='div'>
              {getHeaderGroups().map(
                (headerGroup: HeaderGroup<T>, index: number) => (
                  <TableRow
                    key={`TableHeaderGroupRow-${index}`}
                    component='div'
                  >
                    {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                    {headerGroup.headers.map((header: Header<T, any>) => {
                      return (
                        <TableCell
                          key={`TableHeaderCell-${header.id}`}
                          component='div'
                          variant='head'
                          align={header.column.columnDef.meta?.align ?? 'left'}
                          onClick={() => handleSort(header.column)}
                          style={{
                            ...header.column.columnDef.meta?.headerStyle,
                            paddingBottom: 4,
                          }}
                        >
                          {header.column.getCanSort() ? (
                            <TableSortLabel
                              active={
                                header.column.getIsSorted() === false
                                  ? false
                                  : true
                              }
                              style={{ verticalAlign: 'bottom' }}
                              direction={
                                header.column.getIsSorted() !== false
                                  ? (header.column.getIsSorted() as SortDirection)
                                  : undefined
                              }
                              hideSortIcon={
                                header.column.getIsSorted() === false
                              }
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </TableSortLabel>
                          ) : header.isPlaceholder ? null : (
                            flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )
                          )}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              )}
            </TableHead>

            <TableBody component='div'>
              {showLoading ? (
                <TableSkeleton
                  rowCount={skeletonRows}
                  columns={getVisibleFlatColumns()}
                />
              ) : showEmptyTable ? (
                <></>
              ) : (
                getRowModel().rows.map((row) => (
                  <TableRow
                    key={`TableBodyRow-${row.id}`}
                    component='div'
                    hover={!!rowSelectionOptions}
                    onClick={handleRowClick(row)}
                    selected={selectedRowIndex === row.index}
                    style={getDynamicRowStyle ? getDynamicRowStyle(row) : {}}
                    ref={
                      rowMatchPredicate &&
                      rowToSelectFromUrlSearchParams &&
                      rowMatchPredicate(
                        row.original,
                        rowToSelectFromUrlSearchParams
                      )
                        ? preSelectedItemFromSearchUrlParamsRef
                        : undefined
                    }
                  >
                    {row.getVisibleCells().map((cell, index) => {
                      return (
                        <TableCell
                          key={`TableBodyCell-${row.id}-${index}`}
                          variant='body'
                          style={{
                            ...cell.column.columnDef.meta?.dataCellStyle,
                          }}
                          component='div'
                          align={cell.column.columnDef.meta?.align ?? 'left'}
                        >
                          {renderCellContent(cell)}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
          {!showLoading && data && data.length === 0 && (
            <TableEmptyMessage emptyMessage={emptyMessage} />
          )}
        </TableScroll>

        <EpicTableFooter>
          <Box display='block' displayPrint='none'>
            <TablePagination
              component='div'
              rowsPerPageOptions={pageSizes}
              colSpan={3}
              count={recordCount ?? getPrePaginationRowModel().rows.length}
              rowsPerPage={getState().pagination.pageSize}
              page={getState().pagination.pageIndex}
              labelRowsPerPage='Show'
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
                disabled: disabledPrevPage(),
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
                disabled: disabledNextPage(),
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </EpicTableFooter>
      </TableView>
      {onSaveColumnCustomization && customizeFormOpen && (
        <TableColumnCustomizationModal
          handleClose={handleCloseCustomizeTableModal}
          handleSave={onSaveColumnCustomization}
          getCurrentColumns={getAllColumns}
          columnOrderingState={columnOrder}
        />
      )}
    </>
  )
}

export { ReactTable }
