import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { AdUserAction } from '../actions'
import { getAllAdUsersAction, getAllB2CUsersAction } from '../actions/adUsers'
import { AdUsersApi } from '../middleware/adUsers'
import { UsersState } from '@midmarkrtls/common/models/users'

type UsersEpic = Epic<
  AdUserAction,
  AdUserAction,
  UsersState,
  { adUsersApi: AdUsersApi }
>

export const getAllAdUsersActionEpic: UsersEpic = (
  action$,
  state$,
  { adUsersApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllAdUsersAction.request)),
    mergeMap(() =>
      from(adUsersApi.getAllAdUsers()).pipe(
        map((payload) => getAllAdUsersAction.success(payload)),
        catchError((error) => of(getAllAdUsersAction.failure(error)))
      )
    )
  )
}

export const getAllUsersActionEpic: UsersEpic = (
  action$,
  state$,
  { adUsersApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllB2CUsersAction.request)),
    mergeMap(() =>
      from(adUsersApi.getAllUsersAction()).pipe(
        map((payload) => getAllB2CUsersAction.success(payload)),
        catchError((error) => of(getAllB2CUsersAction.failure(error)))
      )
    )
  )
}
