import { useSelector } from 'react-redux'
import { TelemetrySubscriptionState } from '../models'

const useTelemetrySubscription = (): TelemetrySubscriptionState => {
  const telemetrySubscriptionState = useSelector(
    ({
      telemetrySubscription,
    }: {
      telemetrySubscription: TelemetrySubscriptionState
    }) => telemetrySubscription
  )

  return {
    ...telemetrySubscriptionState,
  }
}

export { useTelemetrySubscription }
