import clsx, { ClassValue } from 'clsx'
import { CSSProperties } from 'react'
import { CommonProps } from '.'
import { isA } from '../helpers'

interface StylizeProps {
  classes?: ClassValue[]
  css?: CSSProperties
}

export const getClassName = (...classes: ClassValue[]): string =>
  clsx(classes.filter(Boolean))

export const getStyling = (
  props: CommonProps,
  ...classes: ClassValue[]
): { style?: React.CSSProperties; className?: string } => ({
  style: props.style,
  className: getClassName(...[props.className, ...classes]),
})

export const stylize = <P extends CommonProps>(
  componentProps: P,
  classesOrProps: ClassValue[] | StylizeProps = []
): { style?: CSSProperties; className?: string } => {
  const stylizeProps = classesOrProps as StylizeProps
  const directClasses = isA.array(classesOrProps)
    ? (classesOrProps as ClassValue[])
    : []
  const propsClasses = stylizeProps?.classes ?? []

  const style = {
    ...componentProps.style,
    ...stylizeProps.css,
  }

  const classes = [...directClasses, ...propsClasses]
  const className = getClassName(componentProps.className, ...classes)

  return {
    className,
    style,
  }
}
