import { useEffect } from 'react'
import { v1 as uuidv1 } from 'uuid'
import useSignalRSubscription from './useSignalRSubscription'
import { BaseBadgeTelemetryMessage } from '../models'
import { Authentication } from '../authentication/AuthenticationManager'
import { fetchAccessTokenAsync } from './useFetchAccessToken'

const url = process.env.REACT_APP_SIGNALR_CONNECTION_MANAGER
const id = uuidv1()

export function useLiveTenantTelemetry(
  enabled?: boolean
): BaseBadgeTelemetryMessage[] | null {
  const handleSubscription = async (token: string, endpoint: string) => {
    await fetch(
      `${process.env.REACT_APP_SIGNALR_CONNECTION_MANAGER}telemetry/${endpoint}`,
      {
        method: 'POST',
        headers: {
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          userId: id,
          mapId: undefined,
          tenantId: Authentication.currentCustomerId,
        }),
      }
    )
  }

  // update filters
  useEffect(() => {
    fetchAccessTokenAsync().then((token) => {
      if (enabled) {
        handleSubscription(token, 'subscribe')
      } else {
        handleSubscription(token, 'unsubscribe')
      }
    })
  }, [enabled])

  // signalr feed
  const telemetry = useSignalRSubscription<BaseBadgeTelemetryMessage>({
    url,
    method: 'RCVTLMY',
    id,
    enabled,
  })

  return telemetry ?? []
}
