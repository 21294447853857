import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { AssetTypeAction } from '../actions'
import { getAllAssetTypesAction } from '../actions/assetTypes'
import { AssetTypesApi } from '../middleware'
import { AssetTypesState } from '../models'

type AssetTypeEpic = Epic<
  AssetTypeAction,
  AssetTypeAction,
  AssetTypesState,
  { assetTypesApi: AssetTypesApi }
>

export const getAllAssetTypesEpic: AssetTypeEpic = (
  action$,
  state,
  { assetTypesApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllAssetTypesAction.request)),
    mergeMap((action) =>
      from(assetTypesApi.getAllAssetTypes()).pipe(
        map((assetTypes) => getAllAssetTypesAction.success(assetTypes)),
        catchError((error) => of(getAllAssetTypesAction.failure(error)))
      )
    )
  )
}
