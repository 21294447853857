import { isArray, isString, isFunction } from 'lodash'
import { Filterable, FilterPredicate, createFilterer, CriteriaFilter } from '.'

export type Comparer<V, F = V> = (value: V, filterValue: F) => boolean

const isComparableValue = <V extends unknown = string>(value: V): boolean => {
  if (isArray(value)) {
    return (value as [])?.length > 0
  } else if (isString(value)) {
    return (value as string)?.length > 0
  } else {
    return Boolean(value)
  }
}

export type ComparableValueCheck<V extends unknown = string> = (
  value: V
) => boolean
export const compare = <D extends Filterable = {}, V extends unknown = string>(
  value: V,
  predicate: FilterPredicate<D>,
  enabledOrValueCheck?: boolean | ComparableValueCheck<V>
): CriteriaFilter<D, V> => {
  const enabledCheck = enabledOrValueCheck ?? isComparableValue
  const enabled = isFunction(enabledCheck)
    ? (enabledCheck as ComparableValueCheck<V>)(value)
    : Boolean(enabledCheck)
  const filterer = createFilterer(predicate)

  return {
    matches: predicate,
    filter: filterer,
    value,
    enabled,
  }
}
