import { BaseService } from '@midmarkrtls/common/middleware/baseService'
import { User } from '@midmarkrtls/common/models/users'
export class AdUsersApi extends BaseService {
  constructor() {
    super({ entityType: 'operationsUsers' })
  }
  route = 'users'

  async getAllAdUsers(): Promise<User[]> {
    return await this.get(this.route)
  }
  async getAllUsersAction(): Promise<User[]> {
    return await this.get(this.route)
  }
}
export const adUsersApi = new AdUsersApi()
