import { createAction } from 'typesafe-actions'
import { StaffEvent } from '../models'

export const setAllDuressFilteredByUser = createAction(
  'SET_ALL_DURESS_FILTERED_BY_USER'
)<StaffEvent[]>()

export const setActiveDuressEvents = createAction('SET_ACTIVE_DURESS_EVENTS')<
  StaffEvent[]
>()

export const setShowNowAndShowNextDuress = createAction(
  'SET_SHOW_NOW_AND_SHOW_NEXT_DURESS'
)<{
  showNow: StaffEvent | undefined
  showNext: StaffEvent | undefined
}>()
