import { timeBetween } from '.'
import { Asset, SignalType, Staff } from '../models'
import { BadgeSignalStatus, BadgeSignalAccuracy } from '../models/assetSignal'

/**
 * @deprecated
 * @param dateFrom
 * @returns
 */
export const badgeSignalStatus = (dateFrom?: Date): BadgeSignalStatus => {
  const from = dateFrom ?? new Date('1970-1-1T00:00:00')
  const lastSeen = timeBetween('minute', from)
  if (lastSeen >= -45) {
    return BadgeSignalStatus.Current
  } else if (lastSeen < -45 && lastSeen >= -1440) {
    return BadgeSignalStatus.Recent
  } else {
    return BadgeSignalStatus.TimedOut
  }
}

export const badgeSignalAccuracy = (signalTypeId?: number): string => {
  if (signalTypeId === undefined) {
    signalTypeId = 0
  }
  const defaultSignalAccuracy = BadgeSignalAccuracy.InRoom

  // this matches closely to SignalTypePriority dictionary
  switch (signalTypeId) {
    case 1:
      return BadgeSignalAccuracy.InRoom
    case 3:
      return BadgeSignalAccuracy.NearRoom
    case 0:
    case 2:
    case 4:
    default:
      return defaultSignalAccuracy
  }
}

export function calculateAgentSignalType<T extends Asset | Staff>(
  agent: T
): number {
  if (agent.badgeTelemetry.sensorId) {
    return SignalType.IR
  } else if (agent.badgeIds[0].includes(':')) {
    return SignalType.BLE
  } else if (agent.badgeTelemetry.geoFenceId) {
    return SignalType.BLE
  } else {
    return SignalType.IR
  }
}

export const calculateBadgeSignalStatus = (
  dateFrom: Date | undefined
): BadgeSignalStatus => {
  const from = dateFrom ?? '1970-1-1T00:00:00'
  const lastSeen = timeBetween('minute', new Date(from))
  if (lastSeen >= -45) {
    return BadgeSignalStatus.Current
  } else if (lastSeen < -45 && lastSeen >= -1440) {
    return BadgeSignalStatus.Recent
  } else {
    return BadgeSignalStatus.TimedOut
  }
}
