import { differenceInSeconds } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  BadgeEventTelemetryStore,
  BaseBadgeTelemetryMessage,
  BadgeTelemetryState,
  TelemetryEventLocation,
} from '../models'

/**
 *
 * @returns @deprecated
 */
const useCombinedTelemetry = (): {
  badgeTelemetry: BaseBadgeTelemetryMessage[]
  lastLiveTelemetry: Date
  lastLatency: number
} => {
  const [buffer, setBuffer] = useState<BaseBadgeTelemetryMessage[]>()
  const [eventBuffer, setEventBuffer] = useState<TelemetryEventLocation[]>()
  const [lastLiveTelemetry, setLastLiveTelemetry] = useState<Date>(new Date())
  const [lastLatency, setLastLatency] = useState<number>(0)
  const [baseTelemetry, setBaseTelemetry] = useState<
    BaseBadgeTelemetryMessage[]
  >([])
  const badgeTelemetryState = useSelector(
    ({ badgeTelemetry }: { badgeTelemetry: BadgeTelemetryState }) =>
      badgeTelemetry
  )
  const badgeEventTelemetryStore = useSelector(
    ({
      badgeEventTelemetry,
    }: {
      badgeEventTelemetry: BadgeEventTelemetryStore
    }) => badgeEventTelemetry
  )
  useEffect(() => {
    let telemetry: BaseBadgeTelemetryMessage[] = []
    const badgeTelemetry = badgeTelemetryState.badgeTelemetry
    const badgeEventTelemetry = badgeEventTelemetryStore.badgeEventTelemetry
    if (
      badgeTelemetry?.length &&
      JSON.stringify(badgeTelemetry) !== JSON.stringify(buffer)
    ) {
      setBuffer(badgeTelemetry)
      telemetry = [...telemetry, ...badgeTelemetry]
    }
    if (
      badgeEventTelemetry?.length &&
      JSON.stringify(badgeEventTelemetry) !== JSON.stringify(eventBuffer)
    ) {
      setEventBuffer(badgeEventTelemetry)
      const temp: BaseBadgeTelemetryMessage[] = badgeEventTelemetry.map((x) => {
        return {
          customerId: x.data.customerId,
          trackingId: x.data.trackingId,
          signalTypeId: x.data.signalTypeId,
          lon: x.data.lon,
          lat: x.data.lat,
          locationUncertainty: x.data.locUncertainty,
          sensorId: x.data.sensorId,
          mapId: x.data.mapId,
          geoFenceId: x.data.geoFenceId,
          timestamp: x.data.timestamp,
          agent: {
            id: x.data.agentId,
            agentType: 'Staff',
            agentTypeId: 0,
            badgeID: x.data.trackingId,
          },
        }
      })
      telemetry = [...telemetry, ...temp]
    }
    if (
      telemetry.length > 0 &&
      JSON.stringify(baseTelemetry) !== JSON.stringify(telemetry)
    ) {
      setBaseTelemetry(telemetry)
      if (
        (badgeTelemetry?.length || badgeEventTelemetry?.length) &&
        differenceInSeconds(new Date(), lastLiveTelemetry) > 3
      ) {
        setLastLiveTelemetry(new Date())
        setLastLatency(
          differenceInSeconds(
            new Date(),
            new Date(telemetry[0].timestamp.toString())
          )
        )
      }
    }
  }, [
    badgeTelemetryState,
    buffer,
    badgeEventTelemetryStore,
    eventBuffer,
    baseTelemetry,
    lastLiveTelemetry,
  ])
  return {
    badgeTelemetry: baseTelemetry,
    lastLiveTelemetry: lastLiveTelemetry,
    lastLatency: lastLatency,
  }
}
export { useCombinedTelemetry }
