import { FormControl, FormGroup } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import React from 'react'
import { useSelector } from 'react-redux'
import { defaultAutocompleteStyles } from '../../helpers/filters'
import { StaffType, StringObj } from '../../models'
import { GlobalFiltersState, StaffFilters } from '../../models/filters'
import { useFormStyle } from '../../styles/index'
import SortedAutocomplete from '../Filters/SortedAutoComplete'

interface Props {
  handleStaffFilterChange: (
    changes: Partial<StaffFilters>,
    savedFilter?: boolean
  ) => void
  staffTypes: StaffType[]
  managers: StringObj[]
}

const StaffFilterOptions: React.FC<
  Props & WithStyles<typeof defaultAutocompleteStyles>
> = (props: Props & WithStyles<typeof defaultAutocompleteStyles>) => {
  const { staffTypes, handleStaffFilterChange, managers } = props

  const staffFilters: StaffFilters = useSelector(
    ({ globalFilters }: { globalFilters: GlobalFiltersState }) =>
      globalFilters?.staff
  )

  const formClasses = useFormStyle()

  return (
    <>
      <FormGroup>
        <FormControl variant='standard' className={formClasses.inputStyle}>
          <SortedAutocomplete
            value={staffFilters.staffTypes}
            items={staffTypes}
            itemKey='name'
            itemValueCompareKey='staffTypeId'
            label='Role'
            handleChange={(event, selected: StaffType[]): void =>
              selected &&
              handleStaffFilterChange({
                staffTypes: selected,
              })
            }
          />
        </FormControl>
        <FormControl variant='standard' className={formClasses.inputStyle}>
          <SortedAutocomplete
            value={staffFilters.reportsTo}
            items={managers}
            itemKey='name'
            itemValueCompareKey='id'
            label='Reports To'
            handleChange={(event, selected: StringObj[]): void =>
              selected &&
              handleStaffFilterChange({
                reportsTo: selected,
              })
            }
          />
        </FormControl>
      </FormGroup>
    </>
  )
}

export default withStyles(defaultAutocompleteStyles)(StaffFilterOptions)
