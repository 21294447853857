import makeStyles from '@mui/styles/makeStyles'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { dismissStaffEvent } from '../../actions/staffEvents'
import { StaffEvent } from '../../models'
import { colors } from '../../styles'
import { StackingModals } from '../Modals/StackingModals'
import StaffDuressModalContent from './StaffDuressModalContent'

interface Props {
  staffDuressEventToShowNow: StaffEvent | null
  staffDuressEventToShowNext?: StaffEvent | null
  showDuressModal: boolean
  onResolve: (duressToResolve: StaffEvent) => void
  isSecurityUser: boolean
  canViewOnMap: boolean
}

const StaffDuressModal = (props: Props): JSX.Element | null => {
  const {
    staffDuressEventToShowNow,
    staffDuressEventToShowNext,
    showDuressModal,
    onResolve,
    isSecurityUser,
    canViewOnMap,
  } = props

  const [openFrontModal, setOpenFrontModal] = useState<boolean>(true)
  const [dismissShowNow, setDismissShowNow] = useState<boolean>()

  useEffect(() => {
    setOpenFrontModal(true)
  }, [staffDuressEventToShowNow])

  const dispatch = useDispatch()

  const useDialogStyles = makeStyles(() => ({
    paper: {
      borderLeft: `8px solid ${colors.error}`,
      padding: '16px',
    },
  }))

  const dialogClasses = useDialogStyles()

  const handleClose = () => {
    setOpenFrontModal(false)
    setDismissShowNow(true)
  }

  const handleViewOnMap = () => {
    setOpenFrontModal(false)
    setDismissShowNow(true)
  }

  const dismissDuressEvent = () => {
    if (dismissShowNow && staffDuressEventToShowNow) {
      dispatch(dismissStaffEvent(staffDuressEventToShowNow))
    }
  }

  return staffDuressEventToShowNow ? (
    <>
      <StackingModals
        showStackedModal={showDuressModal}
        openFrontModal={openFrontModal}
        onClose={handleClose}
        onModalExitedTransition={dismissDuressEvent}
        dialogContainerClasses={dialogClasses}
        backModalContent={
          staffDuressEventToShowNext ? (
            <>
              <StaffDuressModalContent
                hideButtons={true}
                duressEvent={staffDuressEventToShowNext}
                onCloseDuressModal={handleClose}
                onViewOnMap={handleViewOnMap}
                onResolve={onResolve}
                isSecurityUser={isSecurityUser}
                canViewOnMap={canViewOnMap}
              />
            </>
          ) : null
        }
        frontModalContent={
          <>
            <StaffDuressModalContent
              hideButtons={false}
              duressEvent={staffDuressEventToShowNow}
              onCloseDuressModal={handleClose}
              onViewOnMap={handleViewOnMap}
              onResolve={onResolve}
              isSecurityUser={isSecurityUser}
              canViewOnMap={canViewOnMap}
            />
          </>
        }
      />
    </>
  ) : (
    <></>
  )
}

export { StaffDuressModal }
