export enum FetchingStatus {
  Request,
  Success,
  Failure,
}

export enum SilentRefreshStatus {
  Request,
  Success,
  Failure,
  Complete,
}
