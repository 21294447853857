import { FetchingState } from '../interfaces/fetchingState'

export interface LocationRouter {
  id: string
  name: string
  routeLocationTypeId: number
}

export interface LocationRouters {
  [id: string]: LocationRouter
}

export type LocationRouterState = Readonly<FetchingState<LocationRouters>>

export enum RouteLocationType {
  Unit,
  StaffsInUnit,
  SelectedLocation,
}
