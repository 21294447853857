import { calculateLeavingGeofence, calculateLeavingGroup } from '.'
import { AgentType, IconType } from '../../models'
import {
  InpixonAssetAgentCreateOptions,
  InpixonAssetAgentPositionUpdate,
  InpixonAssetGroupUpdate,
} from '../../models/inpixonMap'
import { MapRenderByGeofence } from '../../models/realtimeMap'
import { BadgeTelemetryMessageWithAgentEvent } from '../../models/telemetry'

/**
 * For the MoveAgentFromGroup MapUpdateType, use this function to calculate all
 * the config values that can be used to later rerender agents on the map including:
 *  - all agents that need to be rerendered as either Asset or Staff icon,
 *  - config to update the existing group icon in the leaving geofence,
 *  - the config
 *  to rerender the agent leaving the geofence in order to show agent movement on the
 *  map
 * @param newAgentTelemetry
 * @param currentMapRender
 * @param iconScale
 * @param selectedTrackingId
 * @param selectedGroupId
 * @returns all agents that need to be rerendered as either Asset or Staff icon,
 * config to update the existing group icon in the leaving geofence, and the config
 * to rerender the agent leaving the geofence in order to show agent movement on the
 * map
 */
export function calculateLeavingGeofenceMapUpdatesToMoveAgentFromGroup(
  newAgentTelemetry: BadgeTelemetryMessageWithAgentEvent,
  currentMapRender: MapRenderByGeofence,
  iconScale: number,
  selectedTrackingId: string | undefined,
  selectedGroupId: number | undefined
):
  | {
      agentsToDegroup: InpixonAssetAgentCreateOptions[]
      groupToUpdateIcon: InpixonAssetGroupUpdate
      degroupedAgentToUpdatePosition: InpixonAssetAgentPositionUpdate
    }
  | undefined {
  const leavingGeofenceData = calculateLeavingGeofence(
    newAgentTelemetry.trackingId,
    currentMapRender
  )
  if (!leavingGeofenceData) {
    return
  }
  const { prevAgentTelemetry, agentTelemetriesInLeavingGeofence } =
    leavingGeofenceData
  const leavingGroupData = calculateLeavingGroup(
    newAgentTelemetry,
    prevAgentTelemetry,
    agentTelemetriesInLeavingGeofence,
    iconScale,
    selectedTrackingId,
    selectedGroupId
  )
  if (!leavingGroupData) {
    return
  }
  const {
    remainingAgentsHasActiveAssist,
    remainingAgentsHasActiveDuress,
    isSelected: isCurrentGroupInLeavingGeofenceSelected,
    updatedGroupCount,
    leavingAgentToDegroup,
  } = leavingGroupData
  const agentToUpdatePosition: InpixonAssetAgentPositionUpdate = {
    lat: newAgentTelemetry.lat,
    lon: newAgentTelemetry.lon,
    trackingId: newAgentTelemetry.trackingId,
    geoFenceId: newAgentTelemetry.geoFenceId,
    leavingGeofenceId: prevAgentTelemetry.geoFenceId,
    hasActiveDuress: newAgentTelemetry.hasActiveDuress,
    iconType:
      newAgentTelemetry.agent.agentType === AgentType.Asset
        ? IconType.Asset
        : IconType.Staff,
    mapId: newAgentTelemetry.mapId,
  }
  const groupToUpdateIcon: InpixonAssetGroupUpdate = {
    geoFenceId: prevAgentTelemetry.geoFenceId,
    hasActiveDuress: remainingAgentsHasActiveDuress,
    hasActiveAssist: remainingAgentsHasActiveAssist,
    isSelected: isCurrentGroupInLeavingGeofenceSelected,
    groupCount: updatedGroupCount,
  }
  return {
    agentsToDegroup: [leavingAgentToDegroup],
    degroupedAgentToUpdatePosition: agentToUpdatePosition,
    groupToUpdateIcon,
  }
}
