import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { HardwareAction } from '../actions'
import { getAllHardwareAction } from '../actions/hardware'
import { HardwareApi } from '../middleware'
import { HardwareState } from '../models'

type HardwareEpic = Epic<
  HardwareAction,
  HardwareAction,
  HardwareState,
  { hardwareApi: HardwareApi }
>

export const getAllHardwareEpic: HardwareEpic = (
  action$,
  state,
  { hardwareApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllHardwareAction.request)),
    mergeMap((action) =>
      from(hardwareApi.getAllHardware()).pipe(
        map((hardware) => getAllHardwareAction.success(hardware)),
        catchError((error) => of(getAllHardwareAction.failure(error)))
      )
    )
  )
}
