import { useSelector } from 'react-redux'
import { useFetchLocations } from './entities/useFetchLocations'
import { useMemo } from 'react'

const useCurrentUnitLocations = () => {
  const currentUnits = useSelector(
    ({ currentUnits }: { currentUnits: string[] }) => currentUnits
  )
  const { data: locations } = useFetchLocations()

  //Get all locationIds for the current Units Selected
  const currentUnitLocations = useMemo(() => {
    if (currentUnits?.length) {
      return Object.values(locations)
        .filter((x) => x.unitId && currentUnits.includes(x.unitId))
        .map((x) => x.id)
    } else {
      return []
    }
  }, [currentUnits, locations])

  //A simple bool to detect if the All Units entry has been set
  const allUnits = useMemo(() => {
    return (
      (currentUnits == null ||
        currentUnits.length === 0 ||
        currentUnits?.includes('-1')) ??
      false
    )
  }, [currentUnits])

  return {
    currentUnits,
    currentUnitLocations,
    allUnits,
  }
}

export default useCurrentUnitLocations
