import { BaseService } from './baseService'
import { Location } from '../models'

export class SelectableLocationsApi extends BaseService {
  constructor() {
    super({ entityType: 'selectableLocations' })
  }
  route = 'selectable-locations'

  async getSelectableLocations(): Promise<Location> {
    return await this.get<Location>(this.route)
  }
}
