import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React, { useMemo } from 'react'
import { Location, Locations, SubUnits, Units } from '../../../models'
import { DetailedItem, Item } from '../../../models/DetailPaneItems'
import { UtilityStatusType } from '../../../models/utilityStatusType'
import DetailsList from '../../DetailsList/DetailsList'

interface Props {
  selectedRoom: Location
  units: Units
  subUnits: SubUnits
  locations: Locations
}

const useStyles = makeStyles(() =>
  createStyles({
    detailPanelBody: {
      zIndex: 999,
      width: '100%',
    },
    quickActionButtonBody: {
      display: 'flex',
      justifyContent: 'center',
      margin: 12,
    },
    quickActionButtonLeft: {
      paddingRight: '20px',
    },
    detailListBody: {
      paddingTop: '28px',
    },
  })
)

function LocationDetailPane(props: Props): React.ReactElement {
  const { selectedRoom, subUnits, units, locations } = props
  const classes = useStyles()

  const detailItems: DetailedItem[] = useMemo(() => {
    const detailItems: Item[] = [
      { field: 'Name', value: selectedRoom.name },
      {
        field: 'Floor',
        value: selectedRoom.parentId
          ? locations[selectedRoom.parentId]?.name
          : '',
      },
      {
        field: 'Unit',
        value:
          selectedRoom.unitId && units && Object.values(units).length
            ? units[selectedRoom.unitId]?.name
            : '',
      },
      {
        field: 'Sub-Unit',
        value:
          selectedRoom.subUnitId && subUnits && Object.values(subUnits).length
            ? subUnits[selectedRoom.subUnitId]?.subUnitName
            : '',
      },
      {
        field: 'Utility Status',
        value:
          selectedRoom.utilityStatus !== null
            ? UtilityStatusType[Number(selectedRoom.utilityStatus)]
            : '',
      },
      {
        field: 'Point of Care Location',
        value: selectedRoom.isPointOfCareArea ? 'Yes' : 'No',
      },
      {
        field: 'Patient Care Area',
        value: selectedRoom.isPatientCareArea ? 'Yes' : 'No',
      },
      { field: 'Private Room', value: selectedRoom.isPrivate ? 'Yes' : 'No' },
    ]

    return [{ header: 'ROOM INFORMATION', items: detailItems }]
  }, [locations, selectedRoom, units, subUnits])

  return (
    <div className={classes.detailListBody}>
      <DetailsList detailedItems={detailItems} narrowRows={true} />
    </div>
  )
}

export default LocationDetailPane
