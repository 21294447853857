import { List } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React, { useCallback, useRef } from 'react'
import LoadingIndicator from '../LoadingIndicator'

const useStyles = makeStyles(() =>
  createStyles({
    list: {
      width: '100%',
    },
  })
)

interface InfiniteScrollListProps<T> {
  items: T[]
  renderItem: (item: T) => React.ReactNode
  fetchMoreItems: () => void
  isLoading: boolean
  hasMore: boolean
}

type LastElementRefCallbackType = (node: HTMLDivElement) => void

// eslint-disable-next-line @typescript-eslint/ban-types
const InfiniteScrollList = <T,>(
  props: InfiniteScrollListProps<T>
): JSX.Element => {
  const { items, renderItem, fetchMoreItems, isLoading, hasMore } = { ...props }
  const classes = useStyles()
  const observer = useRef<IntersectionObserver | null>(null)

  const scrollTriggerRef = useCallback<LastElementRefCallbackType>(
    (node: HTMLDivElement) => {
      if (isLoading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver(
        (entries: IntersectionObserverEntry[]) => {
          if (entries[0].isIntersecting && hasMore) {
            fetchMoreItems()
          }
        }
      )
      if (node) observer.current.observe(node)
    },
    [fetchMoreItems, hasMore, isLoading]
  )

  return (
    <>
      {items && items.length > 0 && (
        <List className={classes.list}>
          {items.map((item, i) => {
            if (items.length - 2 === i) {
              // this is the second to last item
              // add the ref to trigger scrolling
              return (
                <div ref={scrollTriggerRef} key={i}>
                  {renderItem(item)}
                </div>
              )
            } else {
              return <div key={i}>{renderItem(item)}</div>
            }
          })}
        </List>
      )}
      {isLoading && <LoadingIndicator />}
    </>
  )
}

export default InfiniteScrollList
