import React from 'react'
import jmap from 'jmap.js'
import { IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material'
import { colors } from '../../styles'
import { onZoomComplete } from '../../helpers/inpixon'

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      backgroundColor: colors.white,
      boxShadow:
        '0 1px 10px 0 rgba(0, 0, 0, 0.18), 0 4px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 4px 0 rgba(0, 0, 0, 0.14);',
      display: 'flex',
      flexFlow: 'column',
      width: 52,
      position: 'absolute',
      bottom: 24,
      left: 36,
      borderRadius: 4,
      zIndex: 1000,
    },
    zoomIn: {
      // borderBottom: `1px solid ${colors.midGrey}`,
      borderRadius: 0,
    },
    zoomOut: {
      borderTop: `1px solid ${colors.midGrey}`,
      borderRadius: 0,
    },
    search: {
      borderRadius: 0,
    },
  })
)

const ZoomControl: React.FC<Props> = (props: Props) => {
  const { control } = props
  const classes = useStyles()
  const scaleModifierConstant = 1.35

  const handleZoomChange = (scale: number): void => {
    const animation = new jmap.Animation({
      duration: 0.25,
      onComplete: () => onZoomComplete(control),
    })
    control.setMapTransform(
      new jmap.Transform({
        scale,
        animation,
      })
    )
  }

  const zoomInClick = (): void =>
    handleZoomChange(control.stage.currentMapView.scale * scaleModifierConstant)

  const zoomOutClick = (): void =>
    handleZoomChange(control.stage.currentMapView.scale / scaleModifierConstant)

  return (
    <div className={classes.container}>
      <IconButton className={classes.zoomIn} onClick={zoomInClick} size='large'>
        <AddCircleOutline color='primary' />
      </IconButton>
      <IconButton
        className={classes.zoomOut}
        onClick={zoomOutClick}
        size='large'
      >
        <RemoveCircleOutline color='primary' />
      </IconButton>
    </div>
  )
}

export { ZoomControl }
