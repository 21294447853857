import { getType } from 'typesafe-actions'
import { ReleaseAction } from '../actions'
import { FetchingStatus, Release, ReleasesState, PastReleases } from '../models'
import {
  createReleaseAction,
  getAllReleasesAction,
  getPastReleasesAction,
  getUpcomingReleaseAction,
  updateReleaseAction,
} from '../actions/release'
import { mergeRecords } from '../utils'

const initialState: ReleasesState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
  upcomingRelease: undefined,
  allReleases: {},
}

export default (state = initialState, action: ReleaseAction): ReleasesState => {
  switch (action.type) {
    case getType(getUpcomingReleaseAction.success):
      return {
        data: state.data,
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
        upcomingRelease: action.payload,
        allReleases: state.allReleases,
      }
    case getType(getAllReleasesAction.success):
      const dict = Object.fromEntries(
        action.payload.map((x) => [x.releaseId, x])
      )
      return {
        data: state.data,
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
        upcomingRelease: state.upcomingRelease,
        allReleases: dict,
      }
    case getType(getPastReleasesAction.success):
      const pastReleases = mergeRecords<PastReleases, Release, 'releaseId'>(
        state.data,
        action.payload,
        'releaseId'
      )
      return {
        data: pastReleases,
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
        upcomingRelease: state.upcomingRelease,
        allReleases: state.allReleases,
      }
    case getType(updateReleaseAction.success):
      const updateReleases = mergeRecords<PastReleases, Release, 'releaseId'>(
        state.allReleases,
        [action.payload],
        'releaseId'
      )
      return {
        data: state.data,
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
        upcomingRelease: state.upcomingRelease,
        allReleases: updateReleases,
      }
    case getType(createReleaseAction.success):
      const createReleases = mergeRecords<PastReleases, Release, 'releaseId'>(
        state.allReleases,
        [action.payload],
        'releaseId'
      )
      return {
        data: state.data,
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
        upcomingRelease: state.upcomingRelease,
        allReleases: createReleases,
      }
    case getType(getAllReleasesAction.request):
    case getType(createReleaseAction.request):
    case getType(getUpcomingReleaseAction.request):
    case getType(getPastReleasesAction.request):
    case getType(updateReleaseAction.request):
      return {
        ...state,
        status: FetchingStatus.Request,
      }
    case getType(getAllReleasesAction.failure):
    case getType(createReleaseAction.failure):
    case getType(getUpcomingReleaseAction.failure):
    case getType(getPastReleasesAction.failure):
    case getType(updateReleaseAction.failure):
      return {
        ...state,
        status: FetchingStatus.Failure,
      }
    default:
      return state
  }
}
