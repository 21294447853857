import { renderCurrentMapViewControl } from '.'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function roomLabels(control: any, jmap: any, rotation: number): void {
  const unitData = [] as Array<any> // eslint-disable-line @typescript-eslint/no-explicit-any

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control.currentMapView.mapLayers
    .find((layer: any) => layer.name === 'Units')
    .shapes.forEach((shape: any) => {
      unitData.push({
        waypoints: shape.meta.waypointIds,
        X: shape.localPosition[0],
        Y: shape.localPosition[1],
        points: shape.localPoints,
      })
    })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control.activeVenue.destinations._items
    .filter((destination: any) => destination.displayMode > 0) // allow only non-hidden destinations
    .forEach((destination: any) => {
      let X = 0
      let Y = 0
      let wordWrap = false

      const unit = unitData.filter(
        (unit) =>
          unit.waypoints.filter(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (waypoint: any) => waypoint === destination.waypoints[0].id
          ) > 0
      )

      if (unit.length > 0) {
        const { width, height } = getUnitDimensions(unit)

        X = unit[0].X
        Y = unit[0].Y - height / 2 + 2.5 //Y is center, take half height, subtract then add 2.5 to adjust for header

        const nameWidth = 1.5 * destination.name.length

        if (nameWidth > width) {
          wordWrap = true
        }

        control.currentMapView.guaranteeMapLayer('Text-Layer').addText(
          new control.jungle.Text({
            name: destination.id,
            text: destination.name,
            point: [X, Y],
            width: width,
            height: height,
            rotation: rotation * -1, // invert direction of rotation for text
            visible: true,
            center: true,
            scaleWithMap: false,
            hideOnOverflow: true,
            style: new jmap.Font({
              wordWrap: wordWrap,
              wordWrapWidth: nameWidth,
              align: 'left',
              fontSize: '12px',
              fontFamily: 'Ubuntu',
              fill: '#000000',
            }),
          })
        )
      }
    })

  // force re-render
  renderCurrentMapViewControl(control)
}

const getUnitDimensions = (unit: any) => {
  let defaultHeight = 2
  let defaultWidth = 10

  const { width, height } = unit?.[0]?.points?.reduce(
    (acc: { width: number; height: number }, point: any) => {
      const pointX = point[0]
      const pointY = point[1]
      return {
        width: acc.width > pointX ? acc.width : pointX,
        height: acc.height > pointY ? acc.height : pointY,
      }
    },
    {}
  )

  return {
    height: height || defaultHeight,
    width: width || defaultWidth,
  }
}
