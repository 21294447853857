import { StaffEvent, User, UserRoles } from '../models'
import { Alert } from '../models/alert'
import { AlertLocationRouter, AlertUserRouter } from '../models/alertRouter'
import { CurrentUserLocation } from '../models/currentUserLocation'
import { RouteLocationType } from '../models/locationRouter'

/**
 * This function calculates if there is any matches between the locationId
 * specified in the Alert Router config and the current user locationId, which
 * can be a room, floor, building, or building group
 * @param userLocation the current user location
 * @param alertRouterLocationId the locationId in the Alert Router config
 * @returns true if there is any match and false if there is no match
 */
export const checkLocationRouter = (
  userLocation: CurrentUserLocation,
  alertRouterLocationId: string
): boolean => {
  if (alertRouterLocationId === '') {
    return false
  }
  if (
    alertRouterLocationId === userLocation.roomId ||
    alertRouterLocationId === userLocation.floorId ||
    alertRouterLocationId === userLocation.buildingId ||
    alertRouterLocationId === userLocation.buildingGroupId
  ) {
    return true
  } else {
    return false
  }
}

/**
 * This function calculates if there is any userRoles matches alertRouterRoles
 * @param userRoleIds the list of user role Ids
 * @param alertRouterRoleIds the list of role Ids in alert router config
 * @returns true if there is any match and false if there is no match
 */
export const checkUserRouters = (
  userRoleIds: number[],
  alertRouterRoleIds: number[]
): boolean => {
  // Return true if user router is not configured, i.e., All Roles
  if (alertRouterRoleIds.length === 0) {
    return true
  }
  if (userRoleIds.filter((x) => alertRouterRoleIds.includes(x)).length > 0) {
    return true
  }
  return false
}

/**
 * This function takes an alert config and determine whether an alert notification
 * should be displayed to the user or not
 * @param userLocation the current user location to match up against the location router
 * in the alert config
 * @param userRoleIds the current user's role Ids
 * @param alert the alert config
 * @returns true if the alert notification should be displayed to the user and false if it
 * should NOT be displayed to the user
 */
export const shouldShowAlertNotification = (
  staffEvent: StaffEvent,
  userLocation: CurrentUserLocation | undefined,
  userRoleIds: number[],
  alert: Alert | undefined,
  alreadyShownEventIds: string[]
): boolean => {
  if (!alert) {
    if (alreadyShownEventIds.includes(staffEvent.id)) {
      return true
    }
    return false
  }
  //If user has security role short circuit and return true
  if (
    userRoleIds.length &&
    (userRoleIds.includes(UserRoles.SecurityAdmin) ||
      userRoleIds.includes(UserRoles.SecurityUser))
  ) {
    return true
  }

  const locationRouters = alert.locationRouters.filter(
    (x) => x.locationRouterTypeId === RouteLocationType.SelectedLocation
  )

  //If no Location Router (i.e., All Locations) return true
  if (locationRouters.length === 0) {
    return true
  }

  // If userLocation is unknown, return false
  if (!userLocation) {
    return false
  }

  // Check location routers matches current user location
  if (
    locationRouters.find((x) => checkLocationRouter(userLocation, x.ruleValue))
  ) {
    const unitRouters = alert.locationRouters?.filter(
      (x) => x.locationRouterTypeId === RouteLocationType.Unit
    )
    const userRouters = alert.userRouters

    // unitRouters is not configured (i.e., All Units)
    if (unitRouters.length === 0 && userRouters !== undefined) {
      return checkUserRouters(
        userRoleIds,
        userRouters.map((s) => Number(s.ruleValue))
      )
    } else {
      // Current userLocation unit matches location router unit
      if (unitRouters.find((x) => x.ruleValue === userLocation.unitId)) {
        return checkUserRouters(
          userRoleIds,
          userRouters.map((s) => Number(s.ruleValue))
        )
      }
      // Return false if userLocation unit does NOT match location router unit
      return false
    }
  }

  return false
}
