import { useMemo } from 'react'
import { useGlobalFilterState } from '.'
import { CustomSearchTypes, FilterStateManager } from '../../filters'
import { setGlobalSearchFilterAction } from '../../actions/globalFilters'
import { Filterable, Criteria, searchBy } from '../../filters'
import { SearchFilter } from '../../models/filters'

interface SearchFilterProvider<T extends Filterable = {}>
  extends FilterStateManager<SearchFilter> {
  filter: Criteria<T, string>
}

const useSearchFilter = <T extends object = {}>(
  ignorePropNames?: (keyof T)[],
  customTypes?: CustomSearchTypes
): SearchFilterProvider<T> => {
  const {
    filterState: filters,
    setFilterState: setFilters,
    updateFilterState: mergeFilters,
    clearFilterState: clearFilters,
  } = useGlobalFilterState(
    (globalFilters) => globalFilters.search,
    setGlobalSearchFilterAction
  )

  const filtersSearchBy = filters.searchBy

  const filter = useMemo(() => {
    const searchFilter = searchBy<T>(
      filtersSearchBy ?? '',
      ignorePropNames,
      customTypes
    )
    return searchFilter
  }, [customTypes, filtersSearchBy, ignorePropNames])

  return {
    filterState: filters,
    setFilterState: setFilters,
    updateFilterState: mergeFilters,
    clearFilterState: clearFilters,
    filter,
  }
}

export default useSearchFilter
