import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { MapPresetAction } from '../actions'
import { getAllMapPresets } from '../actions/mapPresets'
import { MapPresetApi } from '../middleware'
import { MapPresetsState } from '../models'

type MapPresetEpic = Epic<
  MapPresetAction,
  MapPresetAction,
  MapPresetsState,
  { mapPresetApi: MapPresetApi }
>

export const getAllMapPresetEpic: MapPresetEpic = (
  action$,
  _state,
  { mapPresetApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllMapPresets.request)),
    mergeMap((action) =>
      from(mapPresetApi.getAllMapPresets()).pipe(
        map((mapPresets) => getAllMapPresets.success(mapPresets)),
        catchError((error) => of(getAllMapPresets.failure(error)))
      )
    )
  )
}
