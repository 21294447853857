import { getType } from 'typesafe-actions'
import { UserAction } from '../actions'
import { CurrentUserState } from '../models/currentUser'
import { FetchingStatus } from '../models/fetchingStatus'
import { HttpErrorResponse } from '../services/http'
import {
  acknowledgeHttpError,
  getCurrentUserByIdAction,
} from '../actions/currentUser'

const initialState: CurrentUserState = {
  data: undefined,
  status: undefined,
  lastRefreshTime: undefined,
}

export default (
  state: CurrentUserState = initialState,
  action: UserAction
): CurrentUserState => {
  switch (action.type) {
    case getType(acknowledgeHttpError):
      if (state.error !== undefined) {
        state.error.acknowledged = action.payload
        return {
          ...state,
        }
      }
      return {
        ...state,
      }
    case getType(getCurrentUserByIdAction.success):
      return {
        data: action.payload,
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getCurrentUserByIdAction.request):
      return {
        ...state,
        status: FetchingStatus.Request,
      }

    case getType(getCurrentUserByIdAction.failure):
      return {
        ...state,
        status: FetchingStatus.Failure,
        error: action.payload as HttpErrorResponse,
        errorAcknowledged: false,
      }

    default:
      return state
  }
}
