import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Location, Locations, LocationsState, LocationType } from '../models'

const useCurrentFloor = (): Location | null => {
  const currentLocation: Location = useSelector(
    ({ currentLocation }: { currentLocation: Location }) => currentLocation
  )

  const locations: Locations = useSelector(
    ({ locations }: { locations: LocationsState }) => locations.data
  )

  const currentFloor = useMemo(() => {
    const currentFloor =
      currentLocation?.locationType === LocationType.Floor
        ? currentLocation
        : null
    return currentFloor
  }, [currentLocation])

  return currentFloor
}

export default useCurrentFloor
