import { useAssetFilters, useStaffFilters } from '..'
import {
  AssetFilters,
  AssetFiltersProvider,
  FilterBase,
  StaffFilters,
  StaffFiltersProvider,
} from '../../models/filters'

export interface RealTimeFilterStates {
  assetFilterCount: number
  assetFilterCountExclusive: number
  staffFilterCount: number
  staffFilterCountExclusive: number
  commonFilterCount: number
  setAssetFilters: (state: AssetFilters) => void
  setStaffFilters: (state: StaffFilters) => void
  mergeAssetFilters: (state: Partial<AssetFilters>) => void
  mergeStaffFilters: (state: Partial<StaffFilters>) => void
  clearAssetFilters: () => void
  clearStaffFilters: () => void
  assetFilterProvider: AssetFiltersProvider
  staffFilterProvider: StaffFiltersProvider
  assetFilterState: AssetFilters
  staffFilterState: StaffFilters
  commonFilterState: CommonFilters
}

// set of props that are in common between AssetFilters and StaffFilters
export type CommonFilters = Omit<
  {
    [P in keyof AssetFilters & keyof StaffFilters]:
      | AssetFilters[P]
      | StaffFilters[P]
  },
  keyof FilterBase | 'columnSort'
>

// set of props that exist on AssetFilters but not StaffFilters
type AssetFiltersExclusive = Omit<
  AssetFilters,
  keyof CommonFilters | keyof FilterBase | 'columnSort'
>

// set of props that exist on StaffFilters but not AssetFilters
type StaffFiltersExclusive = Omit<
  StaffFilters,
  keyof CommonFilters | keyof FilterBase | 'columnSort'
>

// used to check if a property exists on the CommonFilters type, properties should not be modified in any of the code below
const commonFiltersProps: CommonFilters = {
  assignedTo: undefined,
  batteryStatus: undefined,
  hasBadge: undefined,
  isInUse: undefined,
  isWatchlist: undefined,
  signalAccuracy: undefined,
  signalStatus: undefined,
  signalType: undefined,
  tagBatteryChangeDate: undefined,
}

// used to check if a property exists on the AssetFiltersExclusive type, properties should not be modified in any of the code below
const assetFiltersExclusiveProps: AssetFiltersExclusive = {
  assetBatteryChangeDate: undefined,
  assetSubTypes: undefined,
  assetTypes: undefined,
  badgeNumber: undefined,
  cmmsManaged: undefined,
  displayNames: undefined,
  hasRecall: undefined,
  hasWorkOrder: undefined,
  isPmThisMonth: undefined,
  manufacturers: undefined,
  models: undefined,
  owners: undefined,
  preventativeMaintenanceDate: undefined,
  serialNumber: undefined,
}

// used to check if a property exists on the StaffFiltersExclusive type, properties should not be modified in any of the code below
const staffFiltersExclusiveProps: StaffFiltersExclusive = {
  reportsTo: undefined,
  staffTypes: undefined,
}

// get the common filter values based on the AssetFilters and Staff filters
function getCommonFilterState(
  assetFilterState: StaffFilters,
  staffFilterState: AssetFilters
): CommonFilters {
  const commonFilterState: CommonFilters = {
    assignedTo: undefined,
    batteryStatus: undefined,
    hasBadge: undefined,
    isInUse: undefined,
    isWatchlist: undefined,
    signalAccuracy: undefined,
    signalStatus: undefined,
    signalType: undefined,
    tagBatteryChangeDate: undefined,
  }

  // iterate over the asset filters and set the corresponding common filter
  Object.entries(assetFilterState).forEach(([k, v]) => {
    if (!!v) {
      if (k in commonFiltersProps) {
        const commonKey = k as keyof CommonFilters
        commonFilterState[commonKey] = commonFilterState[commonKey] ?? v
      }
    }
  })

  // iterate over the staff filters and set the corresponding common filter
  Object.entries(staffFilterState).forEach(([k, v]) => {
    if (!!v) {
      if (k in commonFiltersProps) {
        const commonKey = k as keyof CommonFilters
        commonFilterState[commonKey] = commonFilterState[commonKey] ?? v
      }
    }
  })

  return commonFilterState
}

// gets the counts of asset, staff, and common filters with no overlapping of shared filter properties
function getExclusiveCounts(
  assetFilterState: AssetFilters,
  staffFilterState: StaffFilters,
  commonFilterState: CommonFilters
): {
  assetFilterCountExclusive: number
  staffFilterCountExclusive: number
  commonFilterCount: number
} {
  let assetFilterCountExclusive = 0
  let staffFilterCountExclusive = 0
  let commonFilterCount = 0

  // iterate over asset filters and increment the count if the filter is set
  Object.entries(assetFilterState).forEach(([k, v]) => {
    if (!!v) {
      if (k in assetFiltersExclusiveProps) {
        assetFilterCountExclusive += Array.isArray(v) ? v.length : 1
      }
    }
  })

  // iterate over staff filters and increment the count if the filter is set
  Object.entries(staffFilterState).forEach(([k, v]) => {
    if (!!v) {
      if (k in staffFiltersExclusiveProps) {
        staffFilterCountExclusive += Array.isArray(v) ? v.length : 1
      }
    }
  })

  // iterate over common filters and increment the count if the filter is set
  Object.values(commonFilterState).forEach((v) => {
    if (!!v) {
      commonFilterCount += Array.isArray(v) ? v.length : 1
    }
  })

  return {
    assetFilterCountExclusive,
    staffFilterCountExclusive,
    commonFilterCount,
  }
}

const useRealTimeFilters = (): RealTimeFilterStates => {
  const assetFilters = useAssetFilters()
  const staffFilters = useStaffFilters()
  const commonFilterState = getCommonFilterState(
    assetFilters.filterState,
    staffFilters.filterState
  )

  return {
    ...getExclusiveCounts(
      assetFilters.filterState,
      staffFilters.filterState,
      commonFilterState
    ),
    assetFilterCount: assetFilters.filterCount ?? 0, // this is the raw count of asset filters without excluding common filters
    staffFilterCount: staffFilters.filterCount ?? 0, // this is the raw count of staff filters without excluding common filters
    setAssetFilters: assetFilters.setFilterState,
    setStaffFilters: staffFilters.setFilterState,
    mergeAssetFilters: assetFilters.updateFilterState,
    mergeStaffFilters: staffFilters.updateFilterState,
    clearAssetFilters: assetFilters.clearFilterState,
    clearStaffFilters: staffFilters.clearFilterState,
    assetFilterProvider: assetFilters,
    staffFilterProvider: staffFilters,
    assetFilterState: assetFilters.filterState,
    staffFilterState: staffFilters.filterState,
    commonFilterState,
  }
}

export default useRealTimeFilters
