import { FilterChipInfo } from '../models/filterChip'

export const createFilterChipInfo = (
  key: string | number,
  label: string,
  onDelete: () => void,
  color: string
): FilterChipInfo => {
  return {
    key,
    label,
    onDelete,
    color,
  }
}
