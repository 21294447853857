import React, { useEffect, useState } from 'react'
import { ListItemText, MenuItem, Typography } from '@mui/material'
import { Select } from '../ActionBar'
import { useFetchFeatureFlags } from '../../hooks/entities/useFetchFeatureFlags'
import { FeatureFlagLabels } from '../../constants'
import { AgentTypePlural } from '../../models'

interface Props {
  currentView: string
  onSelectChange: (value?: string) => void
}

const RealtimeSelect: React.FC<Props> = (props: Props) => {
  const { onSelectChange: handleChange, currentView } = props

  const [selectedValue, setSelectedValue] = useState<string>(
    AgentTypePlural[AgentTypePlural.Assets]
  )

  const { data: FeatureFlags } = useFetchFeatureFlags(
    FeatureFlagLabels.toString()
  )

  const onSelectChange = (value?: string): void => {
    setSelectedValue(value ?? '')
    handleChange(value ?? AgentTypePlural[AgentTypePlural.Assets])
  }

  const types = [
    AgentTypePlural[AgentTypePlural.Assets],
    AgentTypePlural[AgentTypePlural.Staff],
  ]

  useEffect(() => {
    if (currentView && selectedValue !== currentView) {
      setSelectedValue(currentView)
    }
  }, [currentView, selectedValue])

  const realTimeSelectionHide =
    Object.values(FeatureFlags).filter(
      (x) => x.name.includes('/realtimeselection') == true && x.enabled === true
    ).length > 0

  return (
    <>
      {realTimeSelectionHide === true ? (
        <Select
          margin='dense'
          value={selectedValue}
          onChange={(ev): void => onSelectChange(ev.target.value as string)}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          }}
          style={{ minWidth: '204px' }}
          renderValue={(selected: any) => selected}
        >
          {types.map((type) => (
            <MenuItem
              key={type}
              value={type}
              style={{
                height: '36px',
                margin: '1px 0 0 0',
                padding: '8px 37px 4px 10px',
              }}
            >
              <ListItemText>
                <Typography
                  style={{
                    fontSize: '16px',
                    fontWeight: 500,
                  }}
                >
                  {type}
                </Typography>
              </ListItemText>
            </MenuItem>
          ))}
        </Select>
      ) : (
        <></>
      )}
    </>
  )
}

export default RealtimeSelect
