import { FetchingState } from '../interfaces/fetchingState'

export interface Permission {
  id: number
  name: string
  description: string
}

export interface Permissions {
  [id: string]: Permission
}

export enum PermissionsEnum {
  // customer permissions
  MapRead = 'map.read',
  MapWrite = 'map.write',
  ListRead = 'list.read',
  ListWrite = 'list.write',
  LocationsRead = 'locations.read',
  LocationsWrite = 'locations.write',
  HardwareRead = 'hardware.read',
  HardwareWrite = 'hardware.write',
  AssetsRead = 'assets.read',
  AssetsWrite = 'assets.write',
  UsersRead = 'users.read',
  UsersWrite = 'users.write',
  StaffRead = 'staff.read',
  StaffWrite = 'staff.write',
  AssetsAdminWrite = 'assetsadmin.read',
  AssetsAdminRead = 'assetsadmin.write',
  StaffAdminRead = 'staffadmin.read',
  StaffAdminWrite = 'staffadmin.write',
  BadgesRead = 'badges.read',
  BadgesWrite = 'badges.write',
  DuressRead = 'duress.read',
  DuressLimitedWrite = 'duress.limited_write',
  DuressWrite = 'duress.write',
  AlertAdminRead = 'alertadmin.read',
  AlertAdminWrite = 'alertadmin.write',
  CloudReportingTableauViewer = 'CloudReporting_Tableau_Viewer',

  // ops permissions
  OperationsUsersRead = 'operationsUsers.read',
  OperationsUsersWrite = 'operationsUsers.write',
  OperationsUsersTechnical = 'operationsUsers.technical',
  BadgeTrafficRead = 'badgeTraffic.read',
  BadgeTrafficWrite = 'badgeTraffic.write',
  ImportVenuesRead = 'importVenues.read',
  ImportVenuesWrite = 'importVenues.write',
  HardwareTechnical = 'hardware.technical',
  CanViewAllCustomers = 'canViewAllCustomers',
  CustomerRead = 'customers.read',
  CustomerWrite = 'customers.write',
  PrivateRead = 'private.read',
  PrivateWrite = 'private.write',
  DevToolsRead = 'devTools.read',
  DevToolsWrite = 'devTools.write',
}

export type PermissionsState = Readonly<FetchingState<Permissions>>
