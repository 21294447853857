import moment from 'moment'

export const handleDateValidation = (
  currentValue?: Date,
  required: boolean = false
) => {
  if (!required && !currentValue) {
    return true
  }

  if (moment(currentValue).isValid()) {
    return true
  }

  return 'Invalid Date'
}
