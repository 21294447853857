import React, { ReactElement } from 'react'
import { ToggleIconButton, ToggleIconButtonProps } from './ToggleIconButton'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import PlaylistAddCheck from '@mui/icons-material/PlaylistAddCheck'
import { Tooltip } from '@mui/material'

const render = (isSelected: boolean): ReactElement => {
  return (
    <Tooltip title={isSelected ? 'Remove from My List' : 'Add to My List'}>
      {isSelected ? <PlaylistAddCheck /> : <PlaylistAddIcon />}
    </Tooltip>
  )
}

const PlaylistToggleIconButton = (
  props: ToggleIconButtonProps
): ReactElement => {
  return <ToggleIconButton {...props} render={render} />
}

export { PlaylistToggleIconButton }
