import React from 'react'
import { Button, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { colors } from '../../styles/MidmarkTheme'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { FlexBox } from '../Layout'
import './Banner.css'
import { DataTestIds } from '../../utils/test-utils/dataTestIds'

const useStyles = makeStyles(() =>
  createStyles({
    bannerControl: {
      marginLeft: 6,
    },
    bannerControlButton: {
      color: colors.white,
      padding: '2px 8px',
    },
    bannerControlTextNormal: {
      color: colors.white,
      fontWeight: 'bold',
      fontSize: 18,
    },
    bannerControlTextSmall: {
      color: colors.white,
      fontWeight: 'bold',
      fontSize: 16,
    },
  })
)

interface Props {
  currentBannerValue: number
  maxBannerValues: number
  handleUpEvent: () => void
  handleDownEvent: () => void
}

const BannerControl = (props: Props): JSX.Element => {
  const {
    currentBannerValue,
    maxBannerValues,
    handleDownEvent,
    handleUpEvent,
  } = props
  const classes = useStyles()

  const setSelectedButtonPress = (direction: 'up' | 'down') => {
    if (direction === 'up') {
      handleUpEvent()
    } else {
      handleDownEvent()
    }
  }

  return (
    <FlexBox
      className={classes.bannerControl}
      layout='column'
      layoutAlign={{ item: 'center', main: 'center' }}
    >
      <Button
        aria-label='up'
        type='button'
        className={classes.bannerControlButton}
        onClick={() => setSelectedButtonPress('up')}
      >
        <KeyboardArrowUp />
      </Button>
      <Typography
        className={
          maxBannerValues > 9
            ? classes.bannerControlTextSmall
            : classes.bannerControlTextNormal
        }
        data-testid={DataTestIds.duressCount}
      >
        {currentBannerValue} OF {maxBannerValues}
      </Typography>
      <Button
        aria-label='down'
        type='button'
        className={classes.bannerControlButton}
        onClick={() => setSelectedButtonPress('down')}
      >
        <KeyboardArrowDown />
      </Button>
    </FlexBox>
  )
}

export default BannerControl
