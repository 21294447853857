import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setPageLayoutMaxHeight } from '../actions/pageLayout'
import { fixedBannerHeight } from '../components/Banners'
import { headerMaxHeight } from '../components/Common'
import { selectStaffAssistState } from '../selectors'

export const usePageLayoutMaxHeight = () => {
  const { activeAssistEvents } = useSelector(selectStaffAssistState)
  const dispatch = useDispatch()

  useEffect(() => {
    if (Object.values(activeAssistEvents).length > 0) {
      dispatch(
        setPageLayoutMaxHeight(
          window.innerHeight - headerMaxHeight - fixedBannerHeight
        )
      )
    }
  }, [activeAssistEvents, dispatch])
}
