import {
  AgentType,
  BadgeState,
  BadgeStates,
  BaseBadgeTelemetryMessage,
  GeoFence,
  GeoFenceUpdate,
  StaffEvent,
} from '../../models'
import { hasStaffAssist, hasStaffDuress } from '../staffEvents'

let badgeState: BadgeStates = {}

function processGeoFence(geoFences: number[]): GeoFence[] {
  const output: GeoFence[] = []

  geoFences.forEach((geoFenceId) => {
    const geoFenceAssets =
      Object.values(badgeState).filter(
        (x: BaseBadgeTelemetryMessage) => x.geoFenceId === geoFenceId
      ) ?? []
    output.push({
      geoFenceId: geoFenceId,
      assets: geoFenceAssets,
    })
  })

  return output
}

export default function getGeofenceMapping(
  messages: BaseBadgeTelemetryMessage[],
  agentType: AgentType,
  activeDuressEvents: StaffEvent[],
  activeAssistEvents: StaffEvent[],
  enabled?: boolean
): GeoFenceUpdate {
  const geoFenceToUpdate: number[] = []
  const output: GeoFenceUpdate = {
    telemetry: {},
    geoFences: [],
  }
  if (messages.length && enabled) {
    messages
      .filter((x) => x.geoFenceId)
      .forEach((message: BaseBadgeTelemetryMessage) => {
        //If Message doesn't exist in map, add to map and add to output
        if (!badgeState[message.trackingId]) {
          badgeState = {
            ...badgeState,
            [message.trackingId]: { ...message, agentType },
          }
          output.telemetry = {
            ...output.telemetry,
            [message.trackingId]: message,
          }
          if (!geoFenceToUpdate.some((x) => x === message.geoFenceId)) {
            geoFenceToUpdate.push(message.geoFenceId)
          }
        } else {
          const existing = badgeState[message.trackingId]
          const hasDuress = hasStaffDuress(
            existing.trackingId,
            activeDuressEvents
          )
          const hasAssist = hasStaffAssist(
            existing.trackingId,
            activeAssistEvents
          )
          if (
            existing &&
            (message.geoFenceId !== existing.geoFenceId ||
              hasDuress ||
              hasAssist)
          ) {
            badgeState = {
              ...badgeState,
              [message.trackingId]: { ...message, agentType },
            }
            output.telemetry = {
              ...output.telemetry,
              [message.trackingId]: message,
            }

            if (!geoFenceToUpdate.some((x) => x === existing.geoFenceId)) {
              geoFenceToUpdate.push(existing.geoFenceId)
            }
            if (!geoFenceToUpdate.some((x) => x === message.geoFenceId)) {
              geoFenceToUpdate.push(message.geoFenceId)
            }
          }
        }
      })
  }

  if (geoFenceToUpdate.length) {
    output.geoFences = processGeoFence(geoFenceToUpdate)
  }

  return output
}

export function getMapState(): GeoFenceUpdate {
  const output: GeoFenceUpdate = {
    telemetry: {},
    geoFences: [],
  }
  const geoFenceToUpdate: number[] = []

  Object.values(badgeState).forEach((message: BaseBadgeTelemetryMessage) => {
    output.telemetry = {
      ...output.telemetry,
      [message.trackingId]: message,
    }
    if (!output.geoFences.find((x) => x.geoFenceId === message.geoFenceId)) {
      if (!geoFenceToUpdate.some((x) => x === message.geoFenceId)) {
        geoFenceToUpdate.push(message.geoFenceId)
      }
    }
  })

  if (geoFenceToUpdate.length) {
    output.geoFences = processGeoFence(geoFenceToUpdate)
  }

  return output
}

export function getBadgeState(): BadgeStates {
  return badgeState
}

export function getBadgeStateSingle(trackingId: string): BadgeState {
  return badgeState[trackingId]
}

export function clearBadgeState(): BadgeStates {
  badgeState = {}
  return badgeState
}
