import { getType } from 'typesafe-actions'
import { PermissionAction } from '../actions'
import { getPermissionsAction } from '../actions/permissions'
import {
  Permission,
  Permissions,
  PermissionsState,
} from '../models/permissions'
import { mergeRecords, setFetchingStatus } from '../utils'
import { FetchingStatus } from '../models/fetchingStatus'

const initialState: PermissionsState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

const key = 'id'
export default (
  state: PermissionsState = initialState,
  action: PermissionAction
): PermissionsState => {
  switch (action.type) {
    case getType(getPermissionsAction.success):
      return {
        data: mergeRecords<Permissions, Permission, typeof key>(
          state.data,
          action.payload,
          key
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }

    case getType(getPermissionsAction.request):
      return setFetchingStatus<Permissions>(state, FetchingStatus.Request)
    case getType(getPermissionsAction.failure):
      return setFetchingStatus<Permissions>(state, FetchingStatus.Failure)

    default:
      return state
  }
}
