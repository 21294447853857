import React from 'react'
import {
  CreateOrEditModalProps,
  makeCreateOrEditModal,
} from '../../../components/Common/HOC'
import { SubUnit } from '../../../models'
import SubUnitManagementForm from './SubUnitManagementForm'

const SubUnitManagementModal = (
  props: CreateOrEditModalProps<SubUnit | null>
) => <SubUnitManagementForm {...props}></SubUnitManagementForm>

export default makeCreateOrEditModal<SubUnit | null>(SubUnitManagementModal)
