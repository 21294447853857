import { getType } from 'typesafe-actions'
import { WatchlistAction } from '../actions'
import {
  getWatchlistAction,
  getAssetWatchlistAction,
  deleteAssetWatchlistItemAction,
  postAssetWatchlistItemAction,
  getStaffWatchlistAction,
  deleteStaffWatchlistItemAction,
  postStaffWatchlistItemAction,
} from '../actions/watchlists'
import { Watchlist, WatchlistItem, WatchlistState } from '../models'
import { FetchingStatus } from '../models/fetchingStatus'
import {
  deleteRecord,
  mergeRecord,
  mergeRecords,
  setFetchingStatus,
} from '../utils'

const initialState: WatchlistState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

const key = 'id'
export default (
  state: WatchlistState = initialState,
  action: WatchlistAction
): WatchlistState => {
  switch (action.type) {
    case getType(getWatchlistAction.request):
      return setFetchingStatus<Watchlist>(state, FetchingStatus.Request)

    case getType(getWatchlistAction.success):
      return {
        data: mergeRecords<Watchlist, WatchlistItem, typeof key>(
          state.data,
          action.payload,
          key
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }

    case getType(getAssetWatchlistAction.request):
    case getType(getStaffWatchlistAction.request):
      return setFetchingStatus<Watchlist>(state, FetchingStatus.Request)

    case getType(getAssetWatchlistAction.success):
    case getType(getStaffWatchlistAction.success):
      return {
        data: mergeRecords<Watchlist, WatchlistItem, typeof key>(
          state.data,
          action.payload,
          key
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }

    // optimistic update
    case getType(postAssetWatchlistItemAction.request):
    case getType(postStaffWatchlistItemAction.request):
      return {
        ...state,
        status: FetchingStatus.Request,
        lastRefreshTime: new Date(),
      }

    case getType(postAssetWatchlistItemAction.success):
    case getType(postStaffWatchlistItemAction.success):
      return {
        data: mergeRecord<Watchlist, WatchlistItem, typeof key>(
          state.data,
          action.payload,
          key
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }

    // optimistic update
    case getType(deleteAssetWatchlistItemAction.request):
    case getType(deleteStaffWatchlistItemAction.request):
      return {
        data: deleteRecord<Watchlist, WatchlistItem>(
          state.data,
          action.payload.id ? action.payload.id : action.payload.agentId
        ),
        status: FetchingStatus.Request,
        lastRefreshTime: new Date(),
      }

    case getType(deleteAssetWatchlistItemAction.success):
    case getType(deleteStaffWatchlistItemAction.success):
      return {
        data: deleteRecord<Watchlist, WatchlistItem>(
          state.data,
          action.payload.id ? action.payload.id : action.payload.agentId
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }

    case getType(getWatchlistAction.failure):
    case getType(getAssetWatchlistAction.failure):
    case getType(postAssetWatchlistItemAction.failure):
    case getType(deleteAssetWatchlistItemAction.failure):
    case getType(getStaffWatchlistAction.failure):
    case getType(postStaffWatchlistItemAction.failure):
    case getType(deleteStaffWatchlistItemAction.failure):
      return setFetchingStatus<Watchlist>(state, FetchingStatus.Failure)

    default:
      return state
  }
}
