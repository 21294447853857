import { List } from '@mui/material'
import React from 'react'
import { DetailDrawerGroupTelemetry } from '../../../models/telemetry'
import AgentGroupDetailListItem, {
  AgentGroupDetailListItemProps,
} from './AgentGroupDetailsListItem'

interface Props extends Pick<AgentGroupDetailListItemProps, 'onSelectAgent'> {
  data: DetailDrawerGroupTelemetry
}

function AgentGroupDetailsPane(props: Props): JSX.Element {
  const { data, onSelectAgent } = props
  const { agentsInGroup } = data

  return (
    <List style={{ paddingTop: 0 }}>
      {agentsInGroup.map((agentInGroup) => {
        return (
          <AgentGroupDetailListItem
            key={agentInGroup.agent.agentGuid}
            data={agentInGroup}
            onSelectAgent={onSelectAgent}
          />
        )
      })}
    </List>
  )
}

export default AgentGroupDetailsPane
