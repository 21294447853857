import React from 'react'
import { CSVLink } from 'react-csv'
import { PrimaryButton } from '../Buttons'

export interface Header {
  label: string
  key: string
}

interface CSVLinkOptions {
  csvFileName?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[]
  headers?: Header[] // https://github.com/react-csv/react-csv#custom-header-labels
  label?: string
}

interface ServersidePaginationOptions {
  onClick: () => void
}

interface Props {
  isLoading: boolean
  /**
   * Use this option if all data is already fetched and ready for export
   */
  csvLinkExportOptions?: CSVLinkOptions
  /**
   * Use this option if the remaining data needs to be fetched prior to exporting due to serverside pagination
   */
  serversidePaginationOptions?: ServersidePaginationOptions
}
const TableCsvExport: React.FC<Props> = (props: Props) => {
  const { isLoading, csvLinkExportOptions, serversidePaginationOptions } = props

  return csvLinkExportOptions ? (
    <CSVLink
      style={{ textDecoration: 'none' }}
      data={csvLinkExportOptions.data}
      headers={csvLinkExportOptions.headers}
      filename={
        csvLinkExportOptions.csvFileName ?? `${new Date().toString()}.csv`
      }
      target='_blank'
      onClick={() => !isLoading} // if this click event returns false the link is 'disabled'
    >
      <PrimaryButton variant='contained' type='button' loading={isLoading}>
        {csvLinkExportOptions.label
          ? `Export ${csvLinkExportOptions.label}`
          : 'Export'}
      </PrimaryButton>
    </CSVLink>
  ) : (
    <PrimaryButton
      variant='contained'
      type='button'
      loading={isLoading}
      onClick={serversidePaginationOptions?.onClick}
    >
      Export
    </PrimaryButton>
  )
}
export default TableCsvExport
