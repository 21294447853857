import React, { useEffect, useMemo, useState } from 'react'
import { UserManagementToolbar } from '.'
import { PageLayout } from '../../components/Layout'
import RolesManagementListPage from '../../components/RolesManagement/RolesManagementListPage'
import UserManagementListPage from '../../components/UserManagement/UserManagementListPage'
import {
  useCurrentView,
  useFetchBadges,
  useFetchPermissions,
  useFetchRoles,
  useFetchStaffSubTypes,
  useFetchStaffTypes,
  useFetchUnits,
  useUserFiltersState,
} from '../../hooks'
import { UserManagementPageViews } from '../../models'

const UserManagementPage: React.FC = () => {
  const [filterButtonToggled, setFilterButtonToggled] = useState<
    boolean | undefined
  >(undefined)

  const { data: permissions } = useFetchPermissions()
  const { data: roles, status: rolesStatus } = useFetchRoles()
  useFetchStaffTypes()
  useFetchStaffSubTypes()
  useFetchUnits()
  useFetchBadges()

  const { currentView, setCurrentView } =
    useCurrentView<UserManagementPageViews>('list')
  const {
    filterCount,
    filter: userFilterQuery,
    filterState: userFilterState,
    setFilterState: setUserFilters,
  } = useUserFiltersState()

  useEffect(() => {
    setFilterButtonToggled(undefined)
  }, [currentView])

  const sortedRoles = useMemo(() => {
    return Object.values(roles).sort((a, b) => a.sortOrder - b.sortOrder)
  }, [roles])

  return (
    <PageLayout>
      <UserManagementToolbar
        onViewChange={(view: UserManagementPageViews): void =>
          setCurrentView(view)
        }
        activeView={currentView}
        filterCount={filterCount}
        onFilterButtonClicked={() =>
          setFilterButtonToggled((prevState) => !prevState)
        }
      />
      {currentView === 'list' && (
        <UserManagementListPage
          filterButtonToggled={filterButtonToggled}
          userFilterQuery={userFilterQuery}
          userFilterState={userFilterState}
          setUserFilters={setUserFilters}
        />
      )}
      {currentView === 'roles' && (
        <RolesManagementListPage
          roles={sortedRoles}
          rolesStatus={rolesStatus}
          permissions={permissions}
        />
      )}
    </PageLayout>
  )
}

export default UserManagementPage
