import { useCallback, useEffect, useReducer } from 'react'
import { DrawerType } from '../Layout/PageContent'

interface DrawerState<T> {
  currentDrawerType: DrawerType
  item: T | undefined
  tableSelectedRowIndex: number | undefined
}

enum Actions {
  SelectRow,
  CloseDetailDrawer,
  ClearSelectedRow,
  ResetSelectedRowIndex,
  ToggleFilterDrawer,
  CloseFilterDrawer,
  ResetDrawer,
  UpdateSelectedRow,
}

export type SelectRowActionPayload<T> = {
  selectedItem: T
  selectedRowIndex: number
}

type SelectRowAction<T> = {
  type: Actions.SelectRow
  payload: SelectRowActionPayload<T>
}

type ResetSelectedRowIndexAction = {
  type: Actions.ResetSelectedRowIndex
  payload: number
}

type UpdateSelectedRowAction<T> = {
  type: Actions.UpdateSelectedRow
  payload: T
}

type ActionsWithNoPayload = {
  type:
    | Actions.CloseDetailDrawer
    | Actions.ClearSelectedRow
    | Actions.ToggleFilterDrawer
    | Actions.CloseFilterDrawer
    | Actions.ResetDrawer
}

type RightDrawerActions<T> =
  | SelectRowAction<T>
  | ResetSelectedRowIndexAction
  | UpdateSelectedRowAction<T>
  | ActionsWithNoPayload

function rightDrawerReducer<T>(
  state: DrawerState<T>,
  action: RightDrawerActions<T>
): DrawerState<T> {
  switch (action.type) {
    case Actions.ToggleFilterDrawer:
      if (state.currentDrawerType === DrawerType.FilterDrawer) {
        return {
          ...state,
          currentDrawerType: DrawerType.None,
        }
      } else {
        return {
          currentDrawerType: DrawerType.FilterDrawer,
          item: undefined,
          tableSelectedRowIndex: undefined,
        }
      }
    case Actions.CloseFilterDrawer:
      return {
        ...state,
        currentDrawerType: DrawerType.None,
      }
    case Actions.CloseDetailDrawer:
      return {
        currentDrawerType: DrawerType.None,
        item: undefined,
        tableSelectedRowIndex: undefined,
      }
    case Actions.SelectRow:
      if (state.currentDrawerType === DrawerType.DetailDrawer) {
        return {
          ...state,
          item: action.payload?.selectedItem,
          tableSelectedRowIndex: action.payload.selectedRowIndex,
        }
      } else {
        return {
          currentDrawerType: DrawerType.DetailDrawer,
          item: action.payload?.selectedItem,
          tableSelectedRowIndex: action.payload.selectedRowIndex,
        }
      }
    case Actions.ClearSelectedRow:
      if (state.currentDrawerType === DrawerType.DetailDrawer) {
        return {
          currentDrawerType: DrawerType.None,
          item: undefined,
          tableSelectedRowIndex: undefined,
        }
      }
      return state
    case Actions.ResetSelectedRowIndex:
      return {
        ...state,
        tableSelectedRowIndex: action.payload,
      }
    case Actions.ResetDrawer:
      return {
        currentDrawerType: DrawerType.None,
        item: undefined,
        tableSelectedRowIndex: undefined,
      }
    case Actions.UpdateSelectedRow:
      if (state.currentDrawerType === DrawerType.DetailDrawer) {
        return {
          ...state,
          item: action.payload,
        }
      }
      return state
    default:
      return state
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/ban-types
export const useReactTableRightDrawer = <T extends object>() => {
  const [rightDrawerState, dispatch] = useReducer(rightDrawerReducer<T>, {
    currentDrawerType: DrawerType.None,
    item: undefined,
    tableSelectedRowIndex: undefined,
  })

  const selectRow = useCallback((payload: SelectRowActionPayload<T>) => {
    dispatch({ type: Actions.SelectRow, payload: { ...payload } })
  }, [])

  const closeDetailDrawer = useCallback(() => {
    dispatch({ type: Actions.CloseDetailDrawer })
  }, [])

  const clearSelectedItem = useCallback(() => {
    dispatch({ type: Actions.ClearSelectedRow })
  }, [])

  const resetSelectedRowIndex = useCallback((index: number) => {
    dispatch({ type: Actions.ResetSelectedRowIndex, payload: index })
  }, [])

  const toggleFilterDrawer = useCallback(() => {
    dispatch({ type: Actions.ToggleFilterDrawer })
  }, [])

  const closeFilterDrawer = useCallback(() => {
    dispatch({ type: Actions.CloseFilterDrawer })
  }, [])

  const updateSelectedRow = useCallback((updatedItem: T) => {
    dispatch({ type: Actions.UpdateSelectedRow, payload: updatedItem })
  }, [])

  // Clean up on navigating away
  useEffect(() => {
    return () => {
      dispatch({ type: Actions.ResetDrawer })
    }
  }, [])

  return {
    rightDrawerState,
    selectRow,
    closeDetailDrawer,
    clearSelectedItem,
    resetSelectedRowIndex,
    toggleFilterDrawer,
    closeFilterDrawer,
    updateSelectedRow,
  }
}
