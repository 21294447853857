import { ListItemText, MenuItem, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { AgentTypePlural } from '../../models'
import { Select } from '../ActionBar'

interface Props {
  currentView: string
  units: string[]
  onSelectChange: (value: string) => void
}

const AlertManagementSelect: React.FC<Props> = (props: Props) => {
  const { onSelectChange: handleChange, currentView, units } = props

  const [selectedValue, setSelectedValue] = useState<string>(units[0])

  const onSelectChange = (value?: string): void => {
    setSelectedValue(value ?? '')
    handleChange(value ?? AgentTypePlural[AgentTypePlural.Assets])
  }

  useEffect(() => {
    if (currentView && selectedValue !== currentView) {
      setSelectedValue(currentView)
    }
  }, [currentView, selectedValue])

  return (
    <Select
      margin='dense'
      value={selectedValue}
      onChange={(ev): void => onSelectChange(ev.target.value as string)}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      }}
      style={{ minWidth: '204px' }}
      renderValue={(selected: any) => selected}
    >
      {units.map((unit) => (
        <MenuItem
          key={unit}
          value={unit}
          style={{
            height: '36px',
            margin: '1px 0 0 0',
            padding: '8px 37px 4px 10px',
          }}
        >
          <ListItemText>
            <Typography
              style={{
                fontSize: '16px',
                fontWeight: 500,
              }}
            >
              {unit}
            </Typography>
          </ListItemText>
        </MenuItem>
      ))}
    </Select>
  )
}

export default AlertManagementSelect
