import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import QuickActionCard, {
  IconType,
} from '../../../components/Cards/QuickActionCard'
import DetailsPaneHeader from '../../../components/Drawer/DetailsPaneHeader'
import { SubUnit } from '../../../models'

interface Props {
  subUnit: SubUnit
  onClose: () => void
  onEditSubUnit: () => void
}

const useStyles = makeStyles(() =>
  createStyles({
    quickActionButtonBody: {
      display: 'flex',
      justifyContent: 'center',
      margin: 12,
    },
    quickActionButtonLeft: {
      paddingRight: '20px',
    },
  })
)

function SubUnitDetailPaneHeader(props: Props): React.ReactElement {
  const { subUnit, onClose, onEditSubUnit } = props
  const classes = useStyles()

  return (
    <>
      <div>
        <DetailsPaneHeader
          handleClose={onClose}
          headerText={`${subUnit?.subUnitName}`}
        />
      </div>
      <div className={classes.quickActionButtonBody}>
        <div className={classes.quickActionButtonLeft}>
          <QuickActionCard
            cardText='Edit Sub-Unit Information'
            cardIconType={IconType.EditOutlined}
            onActionAreaClick={onEditSubUnit}
          />
        </div>
      </div>
    </>
  )
}

export default SubUnitDetailPaneHeader
