import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { IssueTypesAction } from '../actions'
import { getIssueTypesAction } from '../actions/issueTypes'
import { IssueTypesApi } from '../middleware'
import { IssueTypesState } from '../models'

type IssueTypesEpic = Epic<
  IssueTypesAction,
  IssueTypesAction,
  IssueTypesState,
  { issueTypesApi: IssueTypesApi }
>

export const getAllIssuesEpic: IssueTypesEpic = (
  action$,
  state,
  { issueTypesApi }
) => {
  return action$.pipe(
    filter(isActionOf(getIssueTypesAction.request)),
    mergeMap(() =>
      from(issueTypesApi.getAllIssueTypes()).pipe(
        map((issues) => getIssueTypesAction.success(issues)),
        catchError((error) => of(getIssueTypesAction.failure(error)))
      )
    )
  )
}
