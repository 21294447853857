import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAssetsByLocationIdAction } from '../../actions/assets'
import { filterAgentsByLocation } from '../../helpers'
import { shouldDispatch } from '../../helpers/shouldDispatch'
import { useCurrentLocation } from '../../hooks'
import {
  Asset,
  Assets,
  AssetsState,
  FetchingStatus,
  Location,
  SignalStatus,
} from '../../models'

// used to detect location changes and prevent unnecessary calls out to fetch assets
let cachedLocation: Location | null = null
let cachedSignalStatus: SignalStatus | null = null

const useFetchAssetsByLocation = (
  signalStatusId: SignalStatus,
  shouldRefresh = false
): {
  assetsByLocation: Asset[]
  assets: Assets
  assetsFetchingStatus: FetchingStatus | undefined
  isFetched: boolean
} => {
  const dispatch = useDispatch()
  const { currentLocation } = useCurrentLocation()

  const {
    status: assetsFetchingStatus,
    assetsByLocationLastRefreshTime,
    data: assets,
    assetsByLocation,
  } = useSelector(({ assets }: { assets: AssetsState }) => assets)

  const assetsByLocationFetched =
    assetsFetchingStatus !== FetchingStatus.Request && !!currentLocation

  useEffect(() => {
    if (currentLocation) {
      if (currentLocation !== cachedLocation) {
        cachedSignalStatus = signalStatusId
        cachedLocation = currentLocation
        dispatch(
          getAssetsByLocationIdAction.request({
            locationGuid: cachedLocation.id,
            signalStatus: signalStatusId,
          })
        )
      } else if (cachedSignalStatus !== signalStatusId) {
        cachedSignalStatus = signalStatusId
        dispatch(
          getAssetsByLocationIdAction.request({
            locationGuid: currentLocation.id,
            signalStatus: cachedSignalStatus ?? null,
          })
        )
      } else if (
        shouldDispatch(assetsFetchingStatus, assetsByLocationLastRefreshTime) ||
        shouldRefresh
      ) {
        cachedSignalStatus = signalStatusId
        dispatch(
          getAssetsByLocationIdAction.request({
            locationGuid: cachedLocation.id,
            signalStatus: signalStatusId,
          })
        )
      }
    }
  }, [
    assetsByLocationLastRefreshTime,
    currentLocation,
    dispatch,
    assetsFetchingStatus,
    shouldRefresh,
    signalStatusId,
  ])

  const filteredAssets = useMemo(() => {
    return filterAgentsByLocation(
      assetsFetchingStatus,
      currentLocation,
      assetsByLocation
    )
  }, [assetsFetchingStatus, currentLocation, assetsByLocation])

  return {
    assetsByLocation: filteredAssets,
    assets,
    assetsFetchingStatus,
    isFetched: assetsByLocationFetched,
  }
}

export { useFetchAssetsByLocation }
