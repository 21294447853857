import { getType } from 'typesafe-actions'
import { RouterPromptAction } from '../actions'
import {
  setChangeTenantRouterPromptStatusAction,
  setLogoutRouterPromptStatusAction,
  setRouterPromptIsEnabledStatusAction,
} from '../actions/routerPrompt'
import { RouterPromptState } from '../models/routerPrompt'

const initialState: RouterPromptState = {
  isEnabled: false,
  logoutPromptStatus: undefined,
  changeTenantPromptStatus: undefined,
}
export default (
  state: RouterPromptState = initialState,
  action: RouterPromptAction
): RouterPromptState => {
  switch (action.type) {
    case getType(setRouterPromptIsEnabledStatusAction):
      return {
        ...state,
        isEnabled: action.payload,
      }
    case getType(setLogoutRouterPromptStatusAction):
      return {
        ...state,
        logoutPromptStatus: action.payload,
      }
    case getType(setChangeTenantRouterPromptStatusAction):
      return {
        ...state,
        changeTenantPromptStatus: action.payload,
      }
    default:
      return state
  }
}
