import { IconType } from '../../models'

// Tim Groven - 6/16/2021 - 11725 - "#" and "&" characters in the Display Name cut off the remaining text for non grouped assets on the map page.
// Make sure we encode special characters for the URL being called to create the Asset Icon
export function uriEncode(text: string): string {
  return encodeURIComponent(text)
}

// Tim Groven - 6/16/2021 - 11725 - "#" and "&" characters in the Display Name cut off the remaining text for non grouped assets on the map page.
// Created as a wrapper for all the changes that need to be made
// to the Asset Name before passing to the Map Icon Generator
export function prepIconName(text: string, type: IconType): string {
  let formattedText = text
  if (type === IconType.Asset || type === IconType.Staff) {
    formattedText = padName(formattedText)
    formattedText = uriEncode(formattedText)
  } else if (type === IconType.Sensor) {
    formattedText = padSensorName(formattedText)
    formattedText = uriEncode(formattedText)
  }
  return formattedText
}

// Pad Assets image text for consistent width
export function padName(text: string): string {
  const maxStringLength = 13

  if (text.length === maxStringLength) {
    return text
  } else if (text.length > maxStringLength) {
    return text.substring(0, 12) + '..'
  } else {
    const padLength = maxStringLength - text.length
    return text.padEnd(padLength, ' ')
  }
}

export function padSensorName(text: string): string {
  const maxStringLength = 5
  if (text.length === maxStringLength) {
    return text
  } else {
    return text.padStart(maxStringLength, ' ')
  }
}
