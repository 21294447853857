import { getPermissionsAction } from '../../actions/permissions'
import { Permissions, PermissionsState } from '../../models'
import { EntityResult, useEntities } from '../useEntities'

const useFetchPermissions = (): EntityResult<Permissions> =>
  useEntities<PermissionsState, Permissions>(
    () => getPermissionsAction.request(),
    (state: any) => state.permissions // eslint-disable-line @typescript-eslint/no-explicit-any
  )

export { useFetchPermissions }
