import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFetchDevices } from '..'
import { putDevicesAction } from '../../actions/devices'
import { Device, MapReferenceViewState } from '../../models'

export function useDeviceLocationUpdate(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jibestream: any,
  venueId: number,
  setUpdateDeviceLatLon?: (loaded: boolean) => void,
  updateDeviceLatLon?: boolean
): void {
  const { data: devices } = useFetchDevices()
  const dispatch = useDispatch()
  const mapReferenceViews = useSelector(
    ({ mapReferenceViews }: { mapReferenceViews: MapReferenceViewState }) =>
      mapReferenceViews
  )

  useEffect(() => {
    if (updateDeviceLatLon && setUpdateDeviceLatLon && devices && jibestream) {
      Object.values(devices)
        ?.filter((device) =>
          Object.values(mapReferenceViews.data).some(
            (mapRef) =>
              mapRef.venueId === venueId &&
              mapRef.deviceId === device.deviceGuid
          )
        )
        .forEach((x) => {
          const coords = jibestream.control.jungle.util.convertCoordinate(
            [x.x, x.y],
            'jmap:local',
            'EPSG:4326',
            jibestream.control.activeVenue
          )

          x.latitude = coords[1]
          x.longitude = coords[0]
        })

      const deviceUpdates = Object.values(devices).filter(
        (device: Device) => device.latitude && device.longitude
      )

      dispatch(putDevicesAction.request(deviceUpdates))
      setUpdateDeviceLatLon(false)
    }
  }, [
    updateDeviceLatLon,
    devices,
    jibestream,
    dispatch,
    mapReferenceViews.data,
    venueId,
    setUpdateDeviceLatLon,
  ])
}
