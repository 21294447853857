import { getType } from 'typesafe-actions'
import {
  TelemetrySubscriptionState,
  TelemetrySubscriptionStore,
} from '../models'
import {
  setTelemetryCurrentMapIdAction,
  setTelemetryEnabledAction,
} from '../actions/telemetrySubscription'
import { TelemetrySubscriptionAction } from '../actions'

const initialState: TelemetrySubscriptionStore = {
  enabled: false,
  mapId: undefined,
  previousMapId: undefined,
}

export default (
  state: TelemetrySubscriptionStore = initialState,
  action: TelemetrySubscriptionAction
): TelemetrySubscriptionState => {
  switch (action.type) {
    case getType(setTelemetryCurrentMapIdAction):
      return {
        ...state,
        mapId: action.payload,
        previousMapId:
          state.mapId === action.payload ? state.previousMapId : state.mapId,
      }
    case getType(setTelemetryEnabledAction):
      return {
        ...state,
        enabled: action.payload,
      }
    default:
      return state
  }
}
