import { createAsyncAction } from 'typesafe-actions'
import { Import, ImportCreate } from 'v2/models'
import { HttpErrorResponse } from '@midmarkrtls/common/services/http'

export const getAllAssetsImportsAction = createAsyncAction(
  'GET_ALL_IMPORTS_ASSETS_REQUEST',
  'GET_ALL_IMPORTS_ASSETS_SUCCESS',
  'GET_ALL_IMPORTS_ASSETS_FAILURE'
)<void, Import[], HttpErrorResponse>()

export const postImportAssetsAction = createAsyncAction(
  'POST_IMPORT_ASSETS_REQUEST',
  'POST_IMPORT_ASSETS_SUCCESS',
  'POST_IMPORT_ASSETS_FAILURE'
)<ImportCreate, Import, HttpErrorResponse>()
