import { AssetInstance, AssetKitInstance, IconType } from '../../models'
import jmap from 'jmap.js'
import {
  InpixonAssetAgentDeleteOptions,
  InpixonAssetGroupDeleteOptions,
} from '../../models/inpixonMap'

/**
 * @deprecated
 */
export function deleteAsset(
  assetKit: AssetKitInstance,
  assetId: number
): boolean {
  const asset = assetKit.getAssetById(assetId)

  if (asset) {
    assetKit.removeAsset(asset)
    return true
  }
  return false
}

/**
 * @deprecated
 */
export function deleteAssets(
  assetKit: AssetKitInstance,
  assetIds: number[]
): void {
  assetIds.map((x) => {
    const asset = assetKit.getAssetById(x)

    if (asset) {
      assetKit.removeAsset(asset)
    }
  })
}

/**
 * @deprecated
 */
export function hideAsset(
  assetKit: AssetKitInstance,
  assetKitId: number
): void {
  const asset = assetKit.getAssetById(assetKitId)

  if (asset && asset._visible) {
    // this is a hack until they prevent hidden assets from being clicked
    assetKit.updateAsset(
      asset,
      {
        confidenceAlpha: 0.0000001,
        confidencePercent: 0.1,
        confidenceMax: 0.1,
      },
      new jmap.Animation({ duration: 0 })
    )
    assetKit.hideAssetsOfType(`Asset_${assetKitId}`)
    assetKit.hideAssetsOfType(`Staff_${assetKitId}`)
  }
}

/**
 * @deprecated
 */
export function hideAssets(
  assetKit: AssetKitInstance,
  assetKitIds: number[]
): void {
  assetKitIds.map((x) => {
    const asset = assetKit.getAssetById(x)

    if (asset && asset._visible) {
      // this is a hack until they prevent hidden assets from being clicked
      assetKit.updateAsset(
        asset,
        {
          confidenceAlpha: 0.0000001,
          confidencePercent: 0.1,
          confidenceMax: 0.1,
        },
        new jmap.Animation({ duration: 0 })
      )
      assetKit.hideAssetsOfType(`Asset_${x}`)
      assetKit.hideAssetsOfType(`Staff_${x}`)
    }
  })
}

export function deleteGroupInpixonAsset(
  assetKit: AssetKitInstance,
  option: InpixonAssetGroupDeleteOptions
): void {
  const asset: AssetInstance[] = assetKit.getAssetsByType(
    `${IconType.Group.toString()}_${option.geofenceId.toString()}`
  )
  if (asset.length === 0) {
    console.warn('Asset not found in asset kit. Unable to delete!')
    return
  }
  assetKit.removeAsset(asset[0])
}

export function deleteAgentInpixonAsset(
  assetKit: AssetKitInstance,
  option: InpixonAssetAgentDeleteOptions
): void {
  const asset: AssetInstance[] = assetKit.getAssetsByType(
    `${option.iconType.toString()}_${option.trackingId}`
  )

  if (asset.length > 0) {
    assetKit.removeAsset(asset[0])
  }
}

export function deleteAgentInpixonAssets(
  assetKit: AssetKitInstance,
  options: InpixonAssetAgentDeleteOptions[]
): void {
  options.forEach((option) => {
    const asset: AssetInstance[] = assetKit.getAssetsByType(
      `${option.iconType.toString()}_${option.trackingId}`
    )

    if (asset.length > 0) {
      assetKit.removeAsset(asset[0])
    }
  })
}

export function deleteAllInpixonAssets(assetKit: AssetKitInstance): void {
  const assetKitAssets: AssetInstance[] = assetKit._getAllAssets()
  assetKitAssets.forEach((asset) => {
    assetKit.removeAsset(asset)
  })
}
