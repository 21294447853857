import { getType } from 'typesafe-actions'
import { LocationSelectionAction } from '../actions'
import { setLocationSelectionAction } from '../actions/locationSelection'
import { LocationSelection } from '../models'

const initialState: LocationSelection = {
  isChanged: false,
}

export default (
  state: LocationSelection = initialState,
  action: LocationSelectionAction
): LocationSelection => {
  switch (action.type) {
    case getType(setLocationSelectionAction):
      return action.payload
    default:
      return state
  }
}
