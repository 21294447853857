import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { LocationGeofencesAction } from '../actions'
import { LocationGeofencesState } from '../models/locationGeofence'
import {
  getAllGeofencesByLocation,
  postAssociateGeofencesAction,
} from '../actions/locationGeofences'
import { LocationGeofenceApi } from '../middleware/locationGeofences'

type LocationGeofenceEpic = Epic<
  LocationGeofencesAction,
  LocationGeofencesAction,
  LocationGeofencesState,
  { locationGeofencesApi: LocationGeofenceApi }
>

export const getAllLocationGeofencesEpic: LocationGeofenceEpic = (
  action$,
  _state,
  { locationGeofencesApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllGeofencesByLocation.request)),
    mergeMap((action) =>
      from(locationGeofencesApi.getAllLocationGeofences(action.payload)).pipe(
        map((locationGeofences) =>
          getAllGeofencesByLocation.success(locationGeofences)
        ),
        catchError((error) => of(getAllGeofencesByLocation.failure(error)))
      )
    )
  )
}

export const postAssociateGeofences: LocationGeofenceEpic = (
  action$,
  state,
  { locationGeofencesApi }
) => {
  return action$.pipe(
    filter(isActionOf(postAssociateGeofencesAction.request)),
    mergeMap((action) =>
      from(locationGeofencesApi.postAssociateGeofences(action.payload)).pipe(
        map((locationGeofence) =>
          postAssociateGeofencesAction.success(locationGeofence)
        ),
        catchError((error) => of(postAssociateGeofencesAction.failure(error)))
      )
    )
  )
}
