import { PageGroup } from '../interfaces/modules'
import { FeatureFlags } from '../models'
import featureFlags from '../reducers/featureFlags'

export const routeEnabled = (
  pathName: string,
  featureFlags: FeatureFlags
): boolean => {
  const featureFlagByRoute = Object.values(featureFlags).filter(
    (x) => x.name === pathName
  )[0]

  // if route isnt guarded by feature flag
  if (!featureFlagByRoute) {
    return true
  }

  return featureFlagByRoute.enabled
}

export const navagationFilter = (
  state: FeatureFlags,
  pageGroup: PageGroup[]
) => {
  const pageGroupsFiltered: PageGroup[] = []

  pageGroup.filter((group) => {
    const pg: PageGroup = group
    const pages: any[] = []

    if (routeEnabled(group.associatedRoute?.path ?? '', state)) {
      group.pages.filter((page) => {
        if (routeEnabled(page.associatedRoute?.path ?? '', state)) {
          pages.push(page)
        }
        return page
      })
      pg.pages = pages
      pageGroupsFiltered.push(pg)
    }

    return pageGroupsFiltered
  })

  return pageGroupsFiltered
}
