import { BaseService } from '@midmarkrtls/common/middleware/baseService'
import { Permission } from '@midmarkrtls/common/models'

export class OperationsPermissionsApi extends BaseService {
  constructor() {
    super({ entityType: 'operationsPermissions' })
  }

  public async getAllOperationsPermissions(): Promise<Permission[]> {
    return await this.get<Permission[]>(`operationsPermissions`)
  }
}
