import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllReleasesAction } from '../../actions/release'
import { PastReleases, ReleasesState } from '../../models/release'

const useFetchAllReleases = () => {
  const dispatch = useDispatch()
  const status = useSelector(
    ({ releases }: { releases: ReleasesState }) => releases.status
  )

  useEffect(() => {
    dispatch(getAllReleasesAction.request())
  }, [])

  const releases: PastReleases = useSelector(
    ({ releases }: { releases: ReleasesState }) => releases.allReleases
  )

  return {
    releases,
    status,
  }
}

export { useFetchAllReleases }
