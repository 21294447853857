import React, { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  useNavigate,
  unstable_useBlocker as useBlocker,
  unstable_BlockerFunction as BlockerFunction,
} from 'react-router-dom'
import {
  setChangeTenantRouterPromptStatusAction,
  setLogoutRouterPromptStatusAction,
  setRouterPromptIsEnabledStatusAction,
} from '../../actions/routerPrompt'
import { alertConfigPathMatches } from '../../helpers/alert'
import {
  RouterPromptState,
  RouterPromptStatus,
} from '../../models/routerPrompt'
import { DialogActionButtonType } from '../Dialogs/DialogActionButtonType'
import ConfirmationModal from '../Modals/ConfirmationModal'

const RouterPrompt = (): ReactElement => {
  const [showPrompt, setShowPrompt] = useState(false)
  const [currentPath, setCurrentPath] = useState('')
  const routerPromptState = useSelector(
    ({ routerPrompt }: { routerPrompt: RouterPromptState }) => routerPrompt
  )

  const history = useNavigate()
  const dispatch = useDispatch()

  const shouldBlock = React.useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => {
      if (
        alertConfigPathMatches(nextLocation.pathname) ||
        !routerPromptState.isEnabled
      ) {
        setShowPrompt(false)
        return false
      }
      setCurrentPath(nextLocation.pathname)
      setShowPrompt(true)
      return true
    },
    [routerPromptState.isEnabled]
  )

  const blocker = useBlocker(shouldBlock)

  const handleLeave = () => {
    if (
      routerPromptState.changeTenantPromptStatus === RouterPromptStatus.PROMPTED
    ) {
      dispatch(
        setChangeTenantRouterPromptStatusAction(RouterPromptStatus.CONFIRMED)
      )
    } else if (
      routerPromptState.logoutPromptStatus === RouterPromptStatus.PROMPTED
    ) {
      dispatch(setLogoutRouterPromptStatusAction(RouterPromptStatus.CONFIRMED))
    } else {
      if (blocker && blocker.proceed) {
        blocker.proceed()
      }
      history(currentPath)
    }
    dispatch(setRouterPromptIsEnabledStatusAction(false))
  }

  const handleCancel = () => {
    setShowPrompt(false)
    if (routerPromptState.changeTenantPromptStatus !== undefined) {
      dispatch(setChangeTenantRouterPromptStatusAction(undefined))
    }
    if (routerPromptState.logoutPromptStatus !== undefined) {
      dispatch(setLogoutRouterPromptStatusAction(undefined))
    }
  }

  const leftActionButtons: DialogActionButtonType[] = [
    {
      label: 'Cancel',
      action: handleCancel,
      variant: 'outlined',
    },
  ]

  const rightActionButtons: DialogActionButtonType[] = [
    {
      label: 'Leave',
      action: handleLeave,
      variant: 'contained',
      color: '#ffffff',
      backgroundColor: '#165788',
    },
  ]

  return routerPromptState.isEnabled ? (
    <ConfirmationModal
      isOpen={
        showPrompt ||
        routerPromptState.changeTenantPromptStatus ===
          RouterPromptStatus.PROMPTED ||
        routerPromptState.logoutPromptStatus === RouterPromptStatus.PROMPTED
      }
      handleClose={handleCancel}
      title={'Leaving This Page?'}
      leftActionButtons={leftActionButtons}
      rightActionButtons={rightActionButtons}
      content={<>Leaving this page will discard any changes you have made.</>}
    />
  ) : (
    <></>
  )
}

export default RouterPrompt
