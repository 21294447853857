import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import RootAction from '../../actions'
import { RootState } from '../../reducers'
import {
  getAllUsersImportsAction,
  postImportUsersAction,
} from 'v2/actions/importsUsers'
import { ImportsUsersApi } from 'v2/middleware'

type ImportsUsersEpic = Epic<
  RootAction,
  RootAction,
  RootState,
  { importsUsersApi: ImportsUsersApi }
>

export const getAllUsersImportsEpic: ImportsUsersEpic = (
  action$,
  state,
  { importsUsersApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllUsersImportsAction.request)),
    mergeMap((action) =>
      from(importsUsersApi.getAllUsersImports()).pipe(
        map((imports) => getAllUsersImportsAction.success(imports)),
        catchError((error) => of(getAllUsersImportsAction.failure(error)))
      )
    )
  )
}

export const postImport: ImportsUsersEpic = (
  action$,
  state,
  { importsUsersApi }
) => {
  return action$.pipe(
    filter(isActionOf(postImportUsersAction.request)),
    mergeMap((action) =>
      from(importsUsersApi.postImportUsers(action.payload)).pipe(
        map((importResponse) => postImportUsersAction.success(importResponse)),
        catchError((error) => of(postImportUsersAction.failure(error)))
      )
    )
  )
}
