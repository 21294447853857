import { createAsyncAction } from 'typesafe-actions'
import { User } from '@midmarkrtls/common/models/users'

export const getAllB2CUsersAction = createAsyncAction(
  'GET_ALL_B2C_USERS_REQUEST',
  'GET_ALL_B2C_USERS_SUCCESS',
  'GET_ALL_B2C_USERS_FAILURE'
)<void, User[], Error>()

export const getAllAdUsersAction = createAsyncAction(
  'GET_ALL_AD_USERS_REQUEST',
  'GET_ALL_AD_USERS_SUCCESS',
  'GET_ALL_AD_USERS_FAILURE'
)<void, User[], Error>()
