import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React, { useEffect, useState } from 'react'
import { createFilterChipInfo } from '../../helpers/filterChips'
import { buildChipByColor } from '../../helpers/filters'
import { AlertFilters } from '../../models'
import { FilterChipInfo } from '../../models/filterChip'
import { colors } from '../../styles/MidmarkTheme'

interface Props {
  alertFilterState: AlertFilters
  onAlertFilterChanged: (changes: AlertFilters) => void
}

const useStyles = makeStyles(() =>
  createStyles({
    chipContainer: {
      marginBottom: 12,
    },
  })
)

const AlertFilterChips = (props: Props): JSX.Element => {
  const { alertFilterState, onAlertFilterChanged } = props

  const classes = useStyles()

  const [filterChipInfos, setFilterChipInfos] = useState<
    FilterChipInfo[] | undefined
  >(undefined)

  useEffect(() => {
    let filterChipInfos: FilterChipInfo[] = []

    if (alertFilterState.alertTypes && alertFilterState.alertTypes.length > 0) {
      filterChipInfos = filterChipInfos.concat(
        alertFilterState.alertTypes.map((alertType) =>
          createFilterChipInfo(
            `alertType-${alertType.id}`,
            `Type: ${alertType.name}`,
            () => {
              const newTypes = alertFilterState.alertTypes
                ? [...alertFilterState.alertTypes]
                : []

              newTypes.splice(
                newTypes.findIndex((x) => x.id === alertType.id),
                1
              )

              return onAlertFilterChanged({
                ...alertFilterState,
                alertTypes: newTypes,
              })
            },
            colors.primary
          )
        )
      )
    }
    setFilterChipInfos(filterChipInfos)
  }, [alertFilterState, onAlertFilterChanged])

  return (
    <div className={classes.chipContainer}>
      {filterChipInfos &&
        filterChipInfos.map((filterChipInfo) =>
          buildChipByColor(
            filterChipInfo.key,
            filterChipInfo.label,
            filterChipInfo.onDelete,
            filterChipInfo.color
          )
        )}
    </div>
  )
}

export default AlertFilterChips
