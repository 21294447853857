import React, { useMemo } from 'react'
import { StaffEvent } from '../../models'
import ApplicationBanner, { BannerType } from '../Banners/ApplicationBanner'
import StaffDuressBannerCarousel from './StaffDuressBannerCarousel'

interface Props {
  staffDuressEvents: StaffEvent[]
  onResolve: (duressToResolve: StaffEvent) => void
  duressResolved: boolean
  isSecurityUser: boolean
  canViewOnMap: boolean
}

const StaffDuressBanner = (props: Props): JSX.Element => {
  const {
    staffDuressEvents,
    onResolve,
    duressResolved,
    isSecurityUser,
    canViewOnMap,
  } = props

  const sortedEvents = useMemo(() => {
    const sortedDuressEvents = staffDuressEvents.sort((e1, e2) => {
      if (e1.reportedTime && e2.reportedTime) {
        return e1.reportedTime.getTime() - e2.reportedTime.getTime()
      } else {
        return -1
      }
    })
    return sortedDuressEvents
  }, [staffDuressEvents])

  return sortedEvents.length > 0 ? (
    <ApplicationBanner bannerType={BannerType.Error}>
      <StaffDuressBannerCarousel
        items={sortedEvents}
        onResolve={onResolve}
        duressResolved={duressResolved}
        isSecurityUser={isSecurityUser}
        canViewOnMap={canViewOnMap}
      />
    </ApplicationBanner>
  ) : (
    <></>
  )
}

export default StaffDuressBanner
