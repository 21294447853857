import { AssetKitInstance, IconType } from '../../models'
import {
  MapRenderByGeofence,
  StaffDuressAssistAgentIconUpdate,
  StaffDuressAssistGroupIconUpdate,
} from '../../models/realtimeMap'
import { BadgeTelemetryMessageWithAgentEvent } from '../../models/telemetry'
import { getConfidenceBubbleConfig } from '../inpixon'
import {
  updateAgentOnMapWithActiveDuress,
  updateGroupOnMapWithActiveDuress,
} from '../processRTMapUpdates'
import { calculateMapRenderToUpdateForAgentOnMap } from '../realtimeMap'

/**
 * Once the MapUpdateType has been determined to be NewActiveDuress, use this function to
 * process the corresponding map rerendering for the received telemetry.
 * Calculate the required configs followed by updating the currently rendered icon on the map
 * (whether Group or Asset/Staff icon) to add highlighting and add geofence highlighting
 * If agent is currently selected on the map or is an agent of a currently selected group icon
 * on the map, update the RightDrawerState accordingly in order to update relevant duress/assist
 * details in the Detail Drawer (icons, Assist text, etc.)
 * Finally, calculate and return the updated map render state
 * @param newAgentTelemetry
 * @param currentMapRender
 * @param assetKit
 * @param jibestreamController
 * @param selectedTrackingId
 * @param selectedGroupId
 * @param updateAgentInSelectedGroup
 * @param updateSelectedAgent
 * @param addGeofenceHighlight
 * @returns updated map render state after map rerendering
 */
export function processMapUpdatesForNewActiveDuress(
  newAgentTelemetry: BadgeTelemetryMessageWithAgentEvent,
  currentMapRender: MapRenderByGeofence,
  assetKit: AssetKitInstance,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any,
  selectedTrackingId: string | undefined,
  selectedGroupId: number | undefined,
  updateAgentInSelectedGroup: (
    agentTelemetry: BadgeTelemetryMessageWithAgentEvent
  ) => void,
  updateSelectedAgent: (
    updatedAgent: BadgeTelemetryMessageWithAgentEvent
  ) => void,
  addGeofenceHighlight: (geofenceId: number) => void
): MapRenderByGeofence {
  const mapRender = currentMapRender[newAgentTelemetry.geoFenceId]
  if (mapRender) {
    const agentIsInGroup =
      mapRender.renderedAgents.length >= mapRender.maxAgentsAllowedInGeofence
    if (agentIsInGroup) {
      const activeDuressGroupIconToUpdate: StaffDuressAssistGroupIconUpdate = {
        groupByType: {
          geofenceId: newAgentTelemetry.geoFenceId,
          iconType: IconType.Group,
        },
        hasActiveDuress: newAgentTelemetry.hasActiveDuress,
        hasActiveAssist: newAgentTelemetry.hasActiveAssist,
        isSelected: newAgentTelemetry.geoFenceId === selectedGroupId,
      }
      updateGroupOnMapWithActiveDuress(
        activeDuressGroupIconToUpdate,
        assetKit,
        jibestreamController,
        addGeofenceHighlight
      )
      if (newAgentTelemetry.geoFenceId === selectedGroupId) {
        updateAgentInSelectedGroup(newAgentTelemetry)
      }
      if (newAgentTelemetry.trackingId === selectedTrackingId) {
        updateSelectedAgent(newAgentTelemetry)
      }
    } else {
      const confidenceBubbleConfig = getConfidenceBubbleConfig(
        newAgentTelemetry.signalTypeId,
        IconType.Staff,
        {
          hasActiveDuress: newAgentTelemetry.hasActiveDuress,
          hasActiveAssist: newAgentTelemetry.hasActiveAssist,
          isSelected: newAgentTelemetry.trackingId === selectedTrackingId,
        }
      )
      const activeDuressAgentIconToUpdate: StaffDuressAssistAgentIconUpdate = {
        agentByType: {
          trackingId: newAgentTelemetry.trackingId,
          iconType: IconType.Staff,
        },
        geofenceId: newAgentTelemetry.geoFenceId,
        confidenceBubbleConfig,
      }
      updateAgentOnMapWithActiveDuress(
        activeDuressAgentIconToUpdate,
        assetKit,
        jibestreamController,
        addGeofenceHighlight
      )
      if (newAgentTelemetry.trackingId === selectedTrackingId) {
        updateSelectedAgent(newAgentTelemetry)
      }
    }
    const updatedMapRender = calculateMapRenderToUpdateForAgentOnMap(
      currentMapRender,
      newAgentTelemetry
    )
    return updatedMapRender
  } else {
    return currentMapRender
  }
}
