import React, { FunctionComponent, ReactNode } from 'react'
import { Grid, Typography } from '@mui/material'

interface Props {
  pageTitle: string
  pageSubtitle?: string
  actionItems?: ReactNode[]
}

const PageHeader: FunctionComponent<Props> = (props: Props) => {
  const { pageTitle, pageSubtitle, actionItems } = props
  return (
    <Grid
      container
      direction='row'
      justifyContent='space-between'
      alignItems='flex-start'
      style={{ padding: 24 }}
    >
      <Grid item container>
        <Typography variant='h5'>{pageTitle}&nbsp;&nbsp;&nbsp;</Typography>
        <Typography style={{ marginTop: 6 }}>{pageSubtitle}</Typography>
      </Grid>
      <Grid item>
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='flex-end'
          spacing={3}
        >
          {actionItems &&
            actionItems.map((actionItem, i) => (
              <Grid item key={`PageHeaderActions-${i}`}>
                {actionItem}
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PageHeader
