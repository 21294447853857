import { BaseService } from './baseService'
import { Permission } from '../models/permissions'

export class PermissionsApi extends BaseService {
  constructor() {
    super({ entityType: 'permissions' })
  }

  public async getAll(): Promise<Permission[]> {
    return await this.get<Permission[]>(`permissions/`)
  }

  public async getUserPermissions(userGuid: string): Promise<Permission[]> {
    return await this.get<Permission[]>(`users/${userGuid}/permissions/`)
  }

  public async createUserPermissions(
    permissions: Permission,
    userGuid: string
  ): Promise<Permission> {
    return await this.post<Permission>(
      `users/${userGuid}/permissions/`,
      permissions
    )
  }
}
