import { InpixonAssetAgentCreateOptions } from '../../models/inpixonMap'
import { BadgeTelemetryMessageWithAgentEvent } from '../../models/telemetry'
import { buildInpixonAssetAgentCreateOptions } from '../inpixon'
import { groupHasActiveAssist, groupHasActiveDuress } from '../telemetry'

/**
 * Calculate the new (decreased) group count, whether the updated group icon has an active duress/assist and/or
 * selected (highlighted), and the config to subsequently render the leaving agent as an Asset/Staff icon in order
 * to show movement to the new geofence.
 * @param newAgentTelemetry
 * @param prevAgentTelemetry
 * @param agentTelemetriesInLeavingGeofence
 * @param iconScale
 * @param selectedTrackingId
 * @param selectedGroupId
 * @returns data that can be later used to update the current group icon in the leaving geofence after an agent
 * left the geofence
 */
export function calculateLeavingGroup(
  newAgentTelemetry: BadgeTelemetryMessageWithAgentEvent,
  prevAgentTelemetry: BadgeTelemetryMessageWithAgentEvent,
  agentTelemetriesInLeavingGeofence: BadgeTelemetryMessageWithAgentEvent[],
  iconScale: number,
  selectedTrackingId: string | undefined,
  selectedGroupId: number | undefined
):
  | {
      remainingAgentsHasActiveDuress: boolean
      remainingAgentsHasActiveAssist: boolean
      isSelected: boolean
      leavingAgentToDegroup: InpixonAssetAgentCreateOptions
      updatedGroupCount: number
    }
  | undefined {
  const remainingAgentsInGroup = agentTelemetriesInLeavingGeofence.filter(
    (telemetry) => telemetry.trackingId !== newAgentTelemetry.trackingId
  )
  const remainingAgentsHasActiveDuress = groupHasActiveDuress(
    remainingAgentsInGroup
  )
  const remainingAgentsHasActiveAssist = groupHasActiveAssist(
    remainingAgentsInGroup
  )
  const leavingAgentToDegroup = buildInpixonAssetAgentCreateOptions(
    prevAgentTelemetry,
    iconScale,
    selectedTrackingId
  )
  const isCurrentGroupSelected =
    prevAgentTelemetry.geoFenceId === selectedGroupId

  if (leavingAgentToDegroup) {
    return {
      remainingAgentsHasActiveDuress,
      remainingAgentsHasActiveAssist,
      isSelected: isCurrentGroupSelected,
      updatedGroupCount: remainingAgentsInGroup.length,
      leavingAgentToDegroup,
    }
  }
}
