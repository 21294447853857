import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllUserRoutersAction } from '../actions/userRouters'
import { shouldDispatch } from '../helpers'
import { UserRouters, UserRouterState } from '../models/userRouter'

const useFetchUserRouters = (shouldRefresh: boolean = false) => {
  const dispatch = useDispatch()
  const status = useSelector(
    ({ userRouters }: { userRouters: UserRouterState }) => userRouters.status
  )
  const lastRefreshTime = useSelector(
    ({ userRouters }: { userRouters: UserRouterState }) =>
      userRouters.lastRefreshTime
  )

  useEffect(() => {
    if (shouldDispatch(status, lastRefreshTime) || shouldRefresh) {
      dispatch(getAllUserRoutersAction.request())
    }
  }, [status, lastRefreshTime, shouldRefresh, dispatch])

  const userRouters: UserRouters = useSelector(
    ({ userRouters }: { userRouters: UserRouterState }) => userRouters.data
  )

  return {
    userRouters,
    status,
  }
}

export { useFetchUserRouters }
