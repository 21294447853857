import {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  rest,
  RestContext,
  RestRequest,
} from "msw";
import { AgentEventLocation, AgentEventLocationUpdate } from "../Common/models";

export const mswApiUrl = "http://mockserviceworker/";

/**
 * Request handler is a function that determines whether
 * an outgoing request should be mocked, and specifies its mocked response.
 * Add all common REST handler methods that can be shared between tests here.
 * @example
 * Match a GET request to a third-party server.
 * rest.get('https://api.github.com/user/:login', (req, res, ctx) => {
 *  return res(ctx.json({ login: req.params.login }))
 * }
 *
 * Match a POST request issued against the same origin
 * rest.post('/author/:authorId/:postId', responseResolver)
 */
export const commonHandlers = [
  // PUT Agent events
  rest.put(`${mswApiUrl}v1/agents/events/:id`, async (req, res, ctx) => {
    const reqBody = await req.json();
    const response = {
      id: reqBody.Id,
      agentEventTypeId: reqBody.AgentEventTypeId,
      closedBy: "cb5ef613-ad8e-49e6-aa22-440e35c9688a",
      closedDate: reqBody.ClosedDate,
      isActive: false,
      accidental: reqBody.Accidental,
      badgeIds: ["43644"],
      agentId: "56daad39-b7c6-eb11-a7ad-501ac51cb116",
    };
    return res(ctx.status(200), ctx.json(response));
  }),
  // GET Embed Url from Cloud Reporting
  rest.get(`${mswApiUrl}v1/cloudreporting/embed`, async (req, res, ctx) => {
    const response = "https://www.google.com";
    return res(ctx.status(200), ctx.json(response));
  }),
];
