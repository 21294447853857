import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { IssuesApi } from '../middleware'
import { Issue, IssuesState } from '../models'
import {
  getAllIssuesAction,
  putIssueAction,
  postIssuesAction,
  putIssuesAction,
} from '../actions/issues'
import { refreshMapReferenceViewByDeviceGuid } from '../actions/mapReferenceViews'
import { IssuesAction, MapReferenceViewAction } from '../actions'

type IssuesEpic = Epic<
  IssuesAction | MapReferenceViewAction,
  IssuesAction | MapReferenceViewAction,
  IssuesState,
  { issuesApi: IssuesApi }
>

export const getAllIssuesEpic: IssuesEpic = (action$, state, { issuesApi }) => {
  return action$.pipe(
    filter(isActionOf(getAllIssuesAction.request)),
    mergeMap(() =>
      from(issuesApi.getAllIssues()).pipe(
        map((issues) => getAllIssuesAction.success(issues)),
        catchError((error) => of(getAllIssuesAction.failure(error)))
      )
    )
  )
}

export const postIssuesEpic: IssuesEpic = (action$, state$, { issuesApi }) => {
  return action$.pipe(
    filter(isActionOf(postIssuesAction.request)),
    mergeMap((action) =>
      from(issuesApi.postIssues(action.payload)).pipe(
        mergeMap((payload: Issue[]) => [
          postIssuesAction.success(payload),
          refreshMapReferenceViewByDeviceGuid(action.payload[0]?.deviceGuid),
        ]),
        catchError((error) => of(postIssuesAction.failure(error)))
      )
    )
  )
}

export const putIssueEpic: IssuesEpic = (action$, state$, { issuesApi }) => {
  return action$.pipe(
    filter(isActionOf(putIssueAction.request)),
    mergeMap((action) =>
      from(issuesApi.putIssue(action.payload)).pipe(
        mergeMap((payload: Issue) => [
          putIssueAction.success(payload),
          refreshMapReferenceViewByDeviceGuid(payload.deviceGuid),
        ]),
        catchError((error) => of(putIssueAction.failure(error)))
      )
    )
  )
}

export const putIssuesEpic: IssuesEpic = (action$, state$, { issuesApi }) => {
  return action$.pipe(
    filter(isActionOf(putIssuesAction.request)),
    mergeMap((action) =>
      from(issuesApi.putIssues(action.payload)).pipe(
        mergeMap((payload: Issue[]) => [
          putIssuesAction.success(payload),
          refreshMapReferenceViewByDeviceGuid(action.payload[0]?.deviceGuid),
        ]),
        catchError((error) => of(putIssuesAction.failure(error)))
      )
    )
  )
}
