import { compare, Comparer, Filterable, Criteria, FilterPredicate } from '.'
import { localMomentFormatted } from '../helpers/date'

interface TextFilterValues {
  value: string
  filterValue: string
}

export type CustomSearchType =
  | 'datetime'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | ((value: any, searchValue: string) => boolean)

export interface CustomSearchTypes {
  [key: string]: CustomSearchType
}

const transformTextFilter = (
  value: string,
  filterValue = '',
  ignoreCase = true
): TextFilterValues => ({
  value: String(ignoreCase ? value.toLowerCase() : value ?? ''),
  filterValue: String(
    ignoreCase ? filterValue.toLowerCase() : filterValue ?? ''
  ),
})

const equalsComparer = (value: string, filterValue: string): boolean =>
  value === filterValue

const includesComparer = (value: string, filterValue: string): boolean =>
  value.includes(filterValue)

export const textMatches = (
  text: string,
  filterValue: string,
  comparer: Comparer<string> = equalsComparer,
  ignoreCase = true
): boolean => {
  const textFilter = transformTextFilter(text, filterValue, ignoreCase)

  return comparer(textFilter.value, textFilter.filterValue)
}

export const textEquals = (
  text: string,
  value = '',
  ignoreCase = true
): boolean => textMatches(text, value, equalsComparer, ignoreCase)

export const textContains = (
  text: string,
  value = '',
  ignoreCase = true
): boolean => textMatches(text, value, includesComparer, ignoreCase)

export const textSearch = (
  value: any,
  searchValue: string,
  customType?: CustomSearchType
): boolean => {
  if (!customType) {
    return String(value).toLowerCase().includes(searchValue)
  }

  if (customType === 'datetime') {
    const valueAsDate = localMomentFormatted(value, 'MM/DD/YYYY')
    return valueAsDate.includes(searchValue)
  }

  return typeof customType === 'function' && customType(value, searchValue)
}

export const textSearchMatches = <T extends {}>(
  item: T,
  text: string,
  ignorePropNames?: (keyof T)[],
  customTypes?: CustomSearchTypes
): boolean => {
  const searchValue = String(text).toLowerCase()
  const validTypes = ['string', 'number', 'object']
  const isMatch = Object.entries(item).some(([key, value]) => {
    const validProp =
      !ignorePropNames || !ignorePropNames?.includes(key as keyof T)
    const validType = value && validTypes.includes(typeof value)

    return (
      validProp &&
      validType &&
      textSearch(value, searchValue, customTypes && customTypes[key])
    )
  })
  return isMatch
}

export const withSearch = <T extends Filterable = {}>(
  searchText = '',
  ignorePropNames?: (keyof T)[],
  customTypes?: CustomSearchTypes
): FilterPredicate<T> => {
  return (item: T): boolean =>
    textSearchMatches(item, searchText, ignorePropNames, customTypes)
}

export const searchBy = <T extends Filterable = {}>(
  searchText = '',
  ignorePropNames?: (keyof T)[],
  customTypes?: CustomSearchTypes
): Criteria<T, string> =>
  compare(searchText, withSearch(searchText, ignorePropNames, customTypes))
