import { useDispatch, useSelector } from 'react-redux'
import { OwnersState, StringObj } from '../models'
import { shouldDispatch } from '../helpers/shouldDispatch'
import { useEffect } from 'react'
import { getAllOwnersAction } from '../actions/owners'

const useFetchOwners = (shouldRefresh = false) => {
  const dispatch = useDispatch()
  const status = useSelector(
    ({ owners }: { owners: OwnersState }) => owners.status
  )
  const lastRefreshTime = useSelector(
    ({ owners }: { owners: OwnersState }) => owners.lastRefreshTime
  )

  useEffect(() => {
    if (shouldDispatch(status, lastRefreshTime) || shouldRefresh) {
      dispatch(getAllOwnersAction.request())
    }
  }, [status, lastRefreshTime, shouldRefresh, dispatch])

  const owners: StringObj[] = useSelector(
    ({ owners }: { owners: OwnersState }) => owners.data
  )

  return {
    owners,
    status,
  }
}

export { useFetchOwners }
