import { getAllBadgesAction } from '../../actions/badges'
import { Badges, BadgesState, FetchingStatus } from '../../models'
import { EntityResult, useEntities } from '../useEntities'
const useFetchBadges = (): EntityResult<Badges> =>
  useEntities<BadgesState, Badges>(
    () => getAllBadgesAction.request(),
    (state: any) => state.badgeState,
    (status: FetchingStatus | undefined, lastRefreshTime: Date | undefined) =>
      !lastRefreshTime
  )

export { useFetchBadges }
