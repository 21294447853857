import { getType } from 'typesafe-actions'
import {
  fetchMapReferenceViewsByVenueIdAction,
  fetchMapReferenceViewByDeviceGuidAction,
  fetchMapReferenceViewsByFloorLocationIdAction,
  removeMapReferenceViewByDeviceGuid,
  refreshMapReferenceViewByDeviceGuid,
  fetchAllMapReferenceViewsAction,
} from '../actions/mapReferenceViews'
import {
  MapReferenceView,
  MapReferenceViews,
  MapReferenceViewState,
} from '../models/mapReferenceViews'
import { MapReferenceViewAction } from '../actions'
import { FetchingStatus } from '../models'

const initialState: MapReferenceViewState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

export default (
  state = initialState,
  action: MapReferenceViewAction
): MapReferenceViewState => {
  if (!action.payload) {
    return state
  }
  switch (action.type) {
    case getType(fetchMapReferenceViewsByVenueIdAction.request):
    case getType(fetchMapReferenceViewsByFloorLocationIdAction.request):
    case getType(fetchMapReferenceViewByDeviceGuidAction.request):
    case getType(fetchAllMapReferenceViewsAction.request):
      return {
        ...state,
        status: FetchingStatus.Request,
      }

    case getType(fetchMapReferenceViewsByVenueIdAction.failure):
    case getType(fetchMapReferenceViewsByFloorLocationIdAction.failure):
    case getType(fetchMapReferenceViewByDeviceGuidAction.failure):
    case getType(fetchAllMapReferenceViewsAction.failure):
      return {
        ...state,
        status: FetchingStatus.Request,
      }

    case getType(fetchMapReferenceViewsByVenueIdAction.success):
    case getType(fetchMapReferenceViewsByFloorLocationIdAction.success):
    case getType(fetchAllMapReferenceViewsAction.success):
      return {
        data: action.payload.reduce(
          (
            mapReferenceViews: MapReferenceViews,
            mapReferenceView: MapReferenceView
          ) => ({
            ...mapReferenceViews,
            [mapReferenceView.deviceId]: {
              ...mapReferenceView,
              lastUpdated: new Date(),
            },
          }),
          { ...state.data }
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(fetchMapReferenceViewByDeviceGuidAction.success):
      return {
        data: {
          ...state.data,
          [action.payload.deviceId]: {
            ...action.payload,
            lastUpdated: new Date(),
          },
        },
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(refreshMapReferenceViewByDeviceGuid):
      return {
        data: Object.values(state.data)
          .map((mapReferenceView) =>
            mapReferenceView.deviceId === action.payload
              ? {
                  ...mapReferenceView,
                  lastUpdated: new Date(),
                }
              : mapReferenceView
          )
          .reduce(
            (mapReferenceViews, mapReferenceView) => ({
              ...mapReferenceViews,
              [mapReferenceView.deviceId]: mapReferenceView,
            }),
            {}
          ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(removeMapReferenceViewByDeviceGuid):
      return {
        data: Object.values(state.data)
          .filter(
            (mapReferenceView) => mapReferenceView.deviceId !== action.payload
          )
          .reduce(
            (mapReferenceViews, mapReferenceView) => ({
              ...mapReferenceViews,
              [mapReferenceView.deviceId]: mapReferenceView,
            }),
            {}
          ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    default:
      return state
  }
}
