import { getType } from 'typesafe-actions'
import { AssetTypeAction } from '../actions'
import { getAllAssetTypesAction } from '../actions/assetTypes'
import { AssetType, AssetTypes, AssetTypesState } from '../models'
import { FetchingStatus } from '../models/fetchingStatus'
import { mergeRecords, setFetchingStatus } from '../utils'

const initialState: AssetTypesState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

export default (
  state: AssetTypesState = initialState,
  action: AssetTypeAction
): AssetTypesState => {
  switch (action.type) {
    case getType(getAllAssetTypesAction.success):
      return {
        data: mergeRecords<AssetTypes, AssetType, 'assetTypeId'>(
          state.data,
          action.payload,
          'assetTypeId'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getAllAssetTypesAction.request):
      return setFetchingStatus<AssetTypes>(state, FetchingStatus.Request)
    case getType(getAllAssetTypesAction.failure):
      return setFetchingStatus<AssetTypes>(state, FetchingStatus.Failure)
    default:
      return state
  }
}
