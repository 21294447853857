import { useCallback, useReducer } from 'react'
import { filterAgentTelemetriesByAgentType } from '../../helpers/telemetry'
import { AgentType, TelemetryAsset, TelemetryStaff } from '../../models'
import {
  AgentTelemetriesGroupedByTrackingId,
  BadgeTelemetryMessageWithAgentEvent,
  TelemetryByAgentType,
} from '../../models/telemetry'
import { deleteRecords } from '../../utils'

const inititalState: TelemetryByAgentType = {
  assetAgentTelemetries: {},
  staffAgentTelemetries: {},
}

enum Actions {
  SetInitialAgentTelemetries,
  UpdateAgentTelemetries,
  AddAssetTelemetries,
  AddStaffTelemetries,
}

type SetInitialAgentTelemetriesAction = {
  type: Actions.SetInitialAgentTelemetries
  payload: TelemetryByAgentType
}

export type UpdateAgentTelemetriesPayload = {
  newTelemetriesForExistingAgents: AgentTelemetriesGroupedByTrackingId<
    TelemetryAsset | TelemetryStaff
  >
  agentsLeavingMap: TelemetryByAgentType
}

type UpdateAgentTelemetriesAction = {
  type: Actions.UpdateAgentTelemetries
  payload: UpdateAgentTelemetriesPayload
}

type AddAssetTelemetriesAction = {
  type: Actions.AddAssetTelemetries
  payload: AgentTelemetriesGroupedByTrackingId<TelemetryAsset>
}

type AddStaffTelemetriesAction = {
  type: Actions.AddStaffTelemetries
  payload: AgentTelemetriesGroupedByTrackingId<TelemetryStaff>
}

type AgentTelemetryActions =
  | SetInitialAgentTelemetriesAction
  | UpdateAgentTelemetriesAction
  | AddAssetTelemetriesAction
  | AddStaffTelemetriesAction

function RTMapAgentTelemetryReducer(
  state: TelemetryByAgentType,
  action: AgentTelemetryActions
): TelemetryByAgentType {
  switch (action.type) {
    case Actions.SetInitialAgentTelemetries:
      return action.payload
    case Actions.UpdateAgentTelemetries:
      const {
        assetAgentTelemetries: assetsLeavingMap,
        staffAgentTelemetries: staffsLeavingMap,
      } = action.payload.agentsLeavingMap
      const assetAgentTelemetries = filterAgentTelemetriesByAgentType(
        action.payload.newTelemetriesForExistingAgents,
        AgentType.Asset
      ) as AgentTelemetriesGroupedByTrackingId<TelemetryAsset>
      const staffAgentTelemetries = filterAgentTelemetriesByAgentType(
        action.payload.newTelemetriesForExistingAgents,
        AgentType.Staff
      ) as AgentTelemetriesGroupedByTrackingId<TelemetryStaff>
      let updatedAssetTelemetries = {
        ...state.assetAgentTelemetries,
        ...assetAgentTelemetries,
      }
      updatedAssetTelemetries = deleteRecords<
        AgentTelemetriesGroupedByTrackingId<TelemetryAsset>,
        BadgeTelemetryMessageWithAgentEvent<TelemetryAsset>
      >(
        updatedAssetTelemetries,
        Object.values(assetsLeavingMap).map((telemetry) => telemetry.trackingId)
      )
      let updatedStaffTelemetries = {
        ...state.staffAgentTelemetries,
        ...staffAgentTelemetries,
      }
      updatedStaffTelemetries = deleteRecords<
        AgentTelemetriesGroupedByTrackingId<TelemetryStaff>,
        BadgeTelemetryMessageWithAgentEvent<TelemetryStaff>
      >(
        updatedStaffTelemetries,
        Object.values(staffsLeavingMap).map((telemetry) => telemetry.trackingId)
      )
      return {
        ...state,
        assetAgentTelemetries: updatedAssetTelemetries,
        staffAgentTelemetries: updatedStaffTelemetries,
      }
    case Actions.AddAssetTelemetries:
      return {
        ...state,
        assetAgentTelemetries: {
          ...state.assetAgentTelemetries,
          ...action.payload,
        },
      }
    case Actions.AddStaffTelemetries:
      return {
        ...state,
        staffAgentTelemetries: {
          ...state.staffAgentTelemetries,
          ...action.payload,
        },
      }
    default:
      return state
  }
}

/**
 * Hook with reducer function to manage state updates for agentTelemetryState
 * This state is initial set to badge telemetries mapped from fetched agents and is updated
 * with live telemtry as received via SignalR. The purpose of this state is:
 *    - store the agent object from the fetched agent which is needed for the Detail Drawer data because the agent
 * object included in the telemetry data does not have all the required asset/staff props.
 *    - store the latest badge telemetry from both the fetched agent data and telemetry received
 * via the websocket so that it can later be used to rerender the map on agentTypes changed and/or
 * on filtering changed
 * @returns current agentTelemetryState and setter methods to update state
 */
export function useRTMapAgentTelemetryState(): {
  agentTelemetryState: TelemetryByAgentType
  setInitialAgentTelemetryState: (
    agentTelemetries: TelemetryByAgentType
  ) => void
  updateAgentTelemetries: (payload: UpdateAgentTelemetriesPayload) => void
  addAssetTelemetries: (
    telemetries: AgentTelemetriesGroupedByTrackingId<TelemetryAsset>
  ) => void
  addStaffTelemetries: (
    telemetries: AgentTelemetriesGroupedByTrackingId<TelemetryStaff>
  ) => void
} {
  const [agentTelemetryState, dispatch] = useReducer(
    RTMapAgentTelemetryReducer,
    inititalState
  )

  const setInitialAgentTelemetryState = useCallback(
    (agentTelemetries: TelemetryByAgentType) => {
      dispatch({
        type: Actions.SetInitialAgentTelemetries,
        payload: agentTelemetries,
      })
    },
    []
  )

  const updateAgentTelemetries = useCallback(
    (payload: UpdateAgentTelemetriesPayload) => {
      dispatch({
        type: Actions.UpdateAgentTelemetries,
        payload,
      })
    },
    []
  )

  const addAssetTelemetries = useCallback(
    (telemetries: AgentTelemetriesGroupedByTrackingId<TelemetryAsset>) => {
      dispatch({
        type: Actions.AddAssetTelemetries,
        payload: telemetries,
      })
    },
    []
  )

  const addStaffTelemetries = useCallback(
    (telemetries: AgentTelemetriesGroupedByTrackingId<TelemetryStaff>) => {
      dispatch({
        type: Actions.AddStaffTelemetries,
        payload: telemetries,
      })
    },
    []
  )

  return {
    agentTelemetryState,
    setInitialAgentTelemetryState,
    updateAgentTelemetries,
    addAssetTelemetries,
    addStaffTelemetries,
  }
}
