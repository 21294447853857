import { getType } from 'typesafe-actions'
import { GlobalFiltersAction } from '../actions'
import {
  setGlobalUserFiltersAction,
  setGlobalAssetFiltersAction,
  setGlobalSearchFilterAction,
  setGlobalDeviceFiltersAction,
  setGlobalBadgeTrafficFiltersAction,
  setGlobalStaffFiltersAction,
  setGlobalAlertFiltersAction,
} from '../actions/globalFilters'
import { GlobalFiltersState } from '../models/filters'

const initialState: GlobalFiltersState = {
  users: {
    selectedRoles: [],
  },
  assets: {
    isInUse: undefined,
    hasBadge: undefined,
    hasRecall: undefined,
    hasWorkOrder: undefined,
    assetBatteryChangeDate: undefined,
    assetTypes: [],
    assetSubTypes: [],
    manufacturers: [],
    models: undefined,
    tagBatteryChangeDate: undefined,
    displayNames: undefined,
    serialNumber: undefined,
    owners: undefined,
    badgeNumber: undefined,
    columnSort: [],
    isLowBattery: undefined,
  },
  devices: {
    hideResolved: false,
  },
  badgeTraffic: {
    sensorId: undefined,
    trackingId: undefined,
    roomName: undefined,
    floorName: undefined,
    buildingName: undefined,
  },
  staff: {
    isInUse: undefined,
    hasBadge: undefined,
    tagBatteryChangeDate: undefined,
    columnSort: [],
    isLowBattery: undefined,
  },
  customers: {},
  search: {},
  alerts: {},
}

export default (
  state = initialState,
  action: GlobalFiltersAction
): GlobalFiltersState => {
  switch (action.type) {
    case getType(setGlobalUserFiltersAction):
      return {
        ...state,
        users: { ...action.payload },
      }
    case getType(setGlobalAssetFiltersAction):
      return {
        ...state,
        assets: { ...action.payload },
      }
    case getType(setGlobalSearchFilterAction):
      return {
        ...state,
        search: { ...action.payload },
      }
    case getType(setGlobalBadgeTrafficFiltersAction):
      return {
        ...state,
        badgeTraffic: { ...action.payload },
      }
    case getType(setGlobalDeviceFiltersAction):
      return {
        ...state,
        devices: { ...action.payload },
      }
    case getType(setGlobalStaffFiltersAction):
      return {
        ...state,
        staff: { ...action.payload },
      }
    case getType(setGlobalAlertFiltersAction):
      return {
        ...state,
        alerts: { ...action.payload },
      }
    default:
      return state
  }
}
