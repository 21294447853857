import makeStyles from '@mui/styles/makeStyles'
import { Skeleton } from '@mui/material'
import React from 'react'

interface Props {
  width: number
}

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 4,
    margin: '20px 0',
  },
}))

const FormSkeleton = (props: Props) => {
  const { width } = props

  const classes = useStyles()

  return (
    <Skeleton
      className={classes.root}
      variant='rectangular'
      width={width}
      height={56}
    />
  )
}

export default FormSkeleton
