import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAuth, useFetchLocations, useFetchUserPreferences } from '.'
import { setCurrentLocationAction } from '../actions/currentLocation'
import { storageStatePath } from '../authentication/auth.config'
import { FetchingStatus, LocationSelection } from '../models'
import { Location } from '../models/locations'
import { useCurrentIDN } from './'

function useSetInitialCurrentLocation() {
  const dispatch = useDispatch()
  const { currentCustomerId } = useAuth()
  const IDN = useCurrentIDN()
  const { data: locations, status: locationsFetchingStatus } =
    useFetchLocations(!!currentCustomerId)
  const { data: userPreferences, status: userPreferencesStatus } =
    useFetchUserPreferences()

  const [initialLoad, setInitialLoad] = useState<boolean>(false)

  useEffect(() => {
    if (
      locationsFetchingStatus === FetchingStatus.Success &&
      userPreferencesStatus === FetchingStatus.Success
    ) {
      if (initialLoad) {
        return
      }

      const storageConfigString = localStorage.getItem(storageStatePath)
      if (!!storageConfigString) {
        const rootJSON = JSON.parse(storageConfigString)
        if (!!rootJSON) {
          const locationSelection = JSON.parse(
            rootJSON.locationSelection
          ) as LocationSelection
          if (locationSelection.isChanged) {
            const locationConfig = JSON.parse(
              rootJSON.currentLocation
            ) as Location
            if (locationConfig) {
              dispatch(setCurrentLocationAction(locationConfig))
              return
            }
          }
        }
      }

      if (Object.values(locations).length > 0 && userPreferences.locationId) {
        const location = locations[userPreferences.locationId]
        dispatch(setCurrentLocationAction(location))
        setInitialLoad(true)
      } else if (IDN && locations[IDN.id]) {
        dispatch(setCurrentLocationAction(IDN))
        setInitialLoad(true)
      } else {
        dispatch(setCurrentLocationAction(null))
        setInitialLoad(true)
      }
    } else if (
      locationsFetchingStatus === FetchingStatus.Failure ||
      userPreferencesStatus === FetchingStatus.Failure
    ) {
      dispatch(setCurrentLocationAction(null))
    }
  }, [
    IDN,
    locations,
    locationsFetchingStatus,
    dispatch,
    userPreferences,
    userPreferencesStatus,
  ])
}

export { useSetInitialCurrentLocation }
