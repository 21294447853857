import {
  getAllAgentEventsAction,
  resolveStaffEvent,
} from '@midmarkrtls/common/actions/agentEventsLocation'
import { fetchLiveStaffEventsTelemetrySuccess } from '@midmarkrtls/common/actions/staffEvents'
import Alerts from '@midmarkrtls/common/components/Alerts/Alerts'
import {
  staffAssistPopperAnchorTopOffset,
  staffAssistPopperPadding,
} from '@midmarkrtls/common/components/Alerts/StaffAssist/constants'
import Header from '@midmarkrtls/common/components/Common/Header'
import SideNav from '@midmarkrtls/common/components/Common/SideNav'
import { Snackbar } from '@midmarkrtls/common/components/Common/Snackbar'
import useSnackbar from '@midmarkrtls/common/components/Common/Snackbar/useSnackbar'
import { InpixonMapPlaceholder } from '@midmarkrtls/common/components/Inpixon'
import ScrollableModal from '@midmarkrtls/common/components/Modals/ScrollableModal'
import { StaffDuressBannerAndModal } from '@midmarkrtls/common/components/StaffDuress'
import {
  CommonRouteKeys,
  FeatureFlagLabels,
} from '@midmarkrtls/common/constants'
import { getAssociatedRoute } from '@midmarkrtls/common/helpers'
import { localMomentFormatted } from '@midmarkrtls/common/helpers/date'
import { navagationFilter } from '@midmarkrtls/common/helpers/navigation'
import {
  useAgentEventSound,
  useCurrentIDN,
  useCurrentLocation,
  useEventListener,
  useFetchCurrentUser,
  useLiveEventTelemetry,
  useLiveTelemetry,
  usePageLayoutMaxHeight,
  usePermissionCheck,
  useRouteRedirect,
  useSetInitialCurrentLocation,
  useStaffAssistAlertModal,
  useStaffAssistEvents,
} from '@midmarkrtls/common/hooks'
import { useFetchFeatureFlags } from '@midmarkrtls/common/hooks/entities/useFetchFeatureFlags'
import { useCurrentUserLocation } from '@midmarkrtls/common/hooks/useCurrentUserLocation'
import useStaffDuressEvents from '@midmarkrtls/common/hooks/useStaffDuressEvents'
import useUpcomingReleaseNotification from '@midmarkrtls/common/hooks/useUpcomingReleaseNotifications'
import { PageGroup } from '@midmarkrtls/common/interfaces/modules'
import {
  AgentEventType,
  EventActionType,
  PermissionsEnum,
  TelemetryEventLocation,
} from '@midmarkrtls/common/models'
import { AppTypeEnum } from '@midmarkrtls/common/models/appType'
import { AuthState } from '@midmarkrtls/common/models/auth'
import { SnackbarType } from '@midmarkrtls/common/models/snackbarType'
import CircularProgress from '@mui/material/CircularProgress'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { useFetchCurrentUserTenants } from 'hooks/fetch/useFetchCurrentUserTenants'
import { useTenantChange } from 'hooks/useTenantChange'
import { operationsPageGroups, pageGroups } from 'modules'
import React, { ReactNode, useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import pageRoutes, { RouteKeys } from 'routes'
import { clearStatusAction } from '../actions/ui'
import { CustomerSelect } from '../components/Operations/CustomerSelect'
import Notifications from '../components/common/Notifications/Notifications'
import { RootState } from '../reducers'

function isIOS(): boolean {
  return (
    /iPad|iPhone|iPod/.test(navigator.platform) ||
    (navigator.platform === 'MacIntel' &&
      navigator.maxTouchPoints > 1 &&
      // eslint-disable-next-line
      !(window as any).MSStream)
  )
}

function height(): string {
  let height = '100vh'
  if (isIOS()) {
    height = window.innerHeight + 'px'
  }
  return height
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: height(),
      width: '100vw',
      overflow: 'hidden',
    },
    rows: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      overflowY: 'hidden',
    },
    columns: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    content: {
      width: '100%',
      height: '100%',
      minHeight: 0,
      position: 'relative',
    },
    nav: {
      '@media print': {
        display: 'none',
      },
    },
    staffAssistModalGap: {
      height: 10,
    },
  })
)

interface Props {
  currentCustomerId: string
  status: readonly string[]
  clearStatus: () => void
  children: ReactNode
}

const RootLayout = (props: Props): JSX.Element => {
  const { data: featureFlags } = useFetchFeatureFlags(
    FeatureFlagLabels.Navigation
  )

  const systemDown = featureFlags['DownForMaintenanceScreen']?.enabled ?? false
  const assetAlertEnabled = featureFlags['/assets/alerts']?.enabled ?? false

  const pageGroupsFiltered: PageGroup[] = navagationFilter(
    featureFlags,
    pageGroups
  )

  const { currentCustomerId, status, clearStatus, children } = props

  const [windowHeight, setWindownHeight] = useState<number>(
    document.documentElement.clientHeight
  )
  const [newModelLiveEventTelemetry, setNewModelLiveEventTelemetry] = useState<
    TelemetryEventLocation[]
  >([])
  const [activeAlertIds, setActiveAlertIds] = useState<string[]>([])
  const { activeStaffAssists } = useStaffAssistEvents()

  const { activeDuressEvents } = useStaffDuressEvents()

  useAgentEventSound(activeDuressEvents, activeStaffAssists)

  const headerRef =
    React.useRef<HTMLDivElement>() as React.RefObject<HTMLDivElement>

  const classes = useStyles()
  const location = useLocation()
  const dispatch = useDispatch()
  const history = useNavigate()

  const handleTenantChange = useTenantChange()
  const { data: tenants } = useFetchCurrentUserTenants()
  const { user } = useSelector(({ auth }: { auth: AuthState }) => auth)
  useFetchCurrentUser(user.id)
  useSetInitialCurrentLocation()
  const { setCurrentLocation } = useCurrentLocation()
  const canViewAllCustomers = usePermissionCheck([
    PermissionsEnum.CanViewAllCustomers,
  ])

  const { setSnackbarData } = useSnackbar()
  const liveEventTelemetry = useLiveEventTelemetry(
    currentCustomerId !== '',
    assetAlertEnabled
  )
  useLiveTelemetry()
  const authState = useSelector(({ auth }: { auth: AuthState }) => auth)

  const definedAppType = process.env.REACT_APP_APP_TYPE
  const applicationTypeId = definedAppType === AppTypeEnum.Operations ? 1 : 2

  const {
    notification: upcomingRelease,
    dismiss: dismissUpcomingReleaseNotification,
    ignore: ignoreUpcomingReleaseNotification,
  } = useUpcomingReleaseNotification(applicationTypeId)

  useCurrentUserLocation()
  usePageLayoutMaxHeight()
  const currentIDN = useCurrentIDN()

  const {
    anchorEl,
    staffAssistHeightOffset,
    staffAssistModalTopOffset,
    staffAssistModalRightOffset,
    mostRecentStaffAssistModalHeight,
    systemMaintenanceModalHeight,
    showAllStaffAssistNotifications,
    setMostRecentStaffAssistModalHeight,
    setSystemMaintenanceModalHeight,
    setShowAllStaffAssistNotifications,
  } = useStaffAssistAlertModal(headerRef, activeDuressEvents)

  useEffect(() => {
    if (liveEventTelemetry && liveEventTelemetry.length > 0) {
      setNewModelLiveEventTelemetry(
        liveEventTelemetry?.filter((telemetry) => telemetry.id)
      )
    }
  }, [liveEventTelemetry])

  useEventListener('resize', () => {
    setWindownHeight(document.documentElement.clientHeight)
  })

  useEffect(() => {
    dispatch(getAllAgentEventsAction.request())
  }, [dispatch])

  // resolve staff events in local state that come in over signalr
  useEffect(() => {
    if (newModelLiveEventTelemetry && newModelLiveEventTelemetry.length > 0) {
      newModelLiveEventTelemetry.forEach((element) => {
        if (
          element.data.agentEventTypeId === AgentEventType.Duress ||
          element.data.agentEventTypeId === AgentEventType.Assist
        ) {
          if (
            element.eventActionType === EventActionType.Resolve ||
            element.eventActionType === EventActionType.AutomaticResolve
          ) {
            dispatch(
              resolveStaffEvent({
                agentId: element.data.agentId,
                badgeId: element.data.trackingId,
              })
            )
          }
        }
      })
    }
  }, [dispatch, newModelLiveEventTelemetry])

  useEffect(() => {
    //This useEffect handles building and comparing the incoming arrays that the current user should see.
    //This array is used in the next useEffect to compare the incoming telemetry
    //This logic is broken out to reduce the issue with loops forming
    const newIds = activeDuressEvents.map((x) => x.id)
    const idsToAdd: string[] = []
    let shouldUpdate = false

    newIds.forEach((x) => {
      if (!activeAlertIds.includes(x)) {
        shouldUpdate = true
        idsToAdd.push(x)
      }
    })

    if (shouldUpdate) {
      setActiveAlertIds([...activeAlertIds, ...idsToAdd])
    }
  }, [activeAlertIds, activeDuressEvents])

  useEffect(() => {
    if (newModelLiveEventTelemetry && newModelLiveEventTelemetry.length > 0) {
      newModelLiveEventTelemetry.forEach((element) => {
        if (
          ((element.eventActionType === EventActionType.Resolve &&
            element.data.agentEventClosedBy !== authState.user.id) ||
            element.eventActionType === EventActionType.AutomaticResolve) &&
          element.data.agentEventTypeId === 0 &&
          activeAlertIds.includes(element.data.agentEventId)
        ) {
          if (
            element.data?.parentLocationName &&
            element.data?.locationName &&
            element.data?.displayName &&
            element.data?.agentEventCreatedDate
          ) {
            setSnackbarData({
              message: `Staff Duress on ${element.data.parentLocationName} in ${
                element.data.locationName
              } by ${element.data.displayName} at ${localMomentFormatted(
                element.data.agentEventCreatedDate,
                'LT'
              )} has been resolved.`,
              type: SnackbarType.Generic,
              autoClose: 8000,
            })
          }
        }
      })
      dispatch(fetchLiveStaffEventsTelemetrySuccess(newModelLiveEventTelemetry))
    }
  }, [
    newModelLiveEventTelemetry,
    dispatch,
    setSnackbarData,
    authState.user.id,
    activeAlertIds,
  ])

  useEffect(
    () => {
      if (
        systemDown === true &&
        !location?.pathname.includes('/downformaintenance')
      ) {
        history('/downformaintenance')
      } else if (
        systemDown === false &&
        location?.pathname.includes('/downformaintenance')
      ) {
        history('/')
      } else if (
        systemDown === false &&
        !currentCustomerId &&
        !location?.pathname.includes('/operations')
      ) {
        history('/operations')
      } else if (
        systemDown === false &&
        currentCustomerId &&
        location?.pathname.includes('/operations')
      ) {
        history('/')
      } else if (
        !assetAlertEnabled &&
        location?.pathname.includes('/notifications')
      ) {
        history('/')
      }
    },
    // eslint-disable-next-line
    [
      canViewAllCustomers,
      location?.pathname,
      systemDown,
      featureFlags,
      assetAlertEnabled,
    ]
  )

  useRouteRedirect(
    location.pathname,
    getAssociatedRoute(CommonRouteKeys.ErrorPage.key, pageRoutes)?.path ?? '',
    pageRoutes
  )

  const headerItems = [
    <CustomerSelect
      key={'headerItemCustomerSelect'}
      tenants={Object.values(tenants)}
      canUserViewAllTenants={canViewAllCustomers}
      selectedTenantId={currentCustomerId}
      handleChange={handleTenantChange}
    />,
  ]

  const handleLogoClick = () => {
    setCurrentLocation(currentIDN)
    history(
      getAssociatedRoute(CommonRouteKeys.DashboardPage.key, pageRoutes)?.path ??
        ''
    )
  }

  return (
    <div className={classes.root}>
      <StaffDuressBannerAndModal />
      {currentCustomerId &&
        (activeStaffAssists.length > 0 ||
          (upcomingRelease && upcomingRelease.isVisible)) && (
          <ScrollableModal
            isOpen={true}
            anchorEl={anchorEl}
            verticalOffset={staffAssistModalTopOffset}
            horizontalOffset={staffAssistModalRightOffset}
            maxHeight={
              showAllStaffAssistNotifications
                ? windowHeight -
                  (staffAssistHeightOffset.duressBanner +
                    staffAssistHeightOffset.primaryHeader +
                    staffAssistHeightOffset.secondaryHeader +
                    staffAssistHeightOffset.epicToolbarFooter +
                    staffAssistPopperAnchorTopOffset +
                    staffAssistPopperPadding)
                : mostRecentStaffAssistModalHeight +
                  systemMaintenanceModalHeight
            }
            width={436}
          >
            <Alerts
              staffAssistEvents={activeStaffAssists}
              showAllStaffAssistNotifications={showAllStaffAssistNotifications}
              upcomingRelease={upcomingRelease}
              dismissUpcomingReleaseNotification={
                dismissUpcomingReleaseNotification
              }
              ignoreUpcomingReleaseNotification={
                ignoreUpcomingReleaseNotification
              }
              setMostRecentStaffAssistModalHeight={
                setMostRecentStaffAssistModalHeight
              }
              setSystemMaintenanceModalHeight={setSystemMaintenanceModalHeight}
              setShowAllStaffAssistNotifications={
                setShowAllStaffAssistNotifications
              }
            />
          </ScrollableModal>
        )}
      <div ref={headerRef}>
        <Header
          currentCustomerId={currentCustomerId}
          headerItems={headerItems}
          handleLogoClick={handleLogoClick}
          pageGroups={
            currentCustomerId ? pageGroupsFiltered : operationsPageGroups
          }
          userProfilePage={
            getAssociatedRoute(
              systemDown
                ? CommonRouteKeys.DownForMaintenance.key
                : RouteKeys.UserProfilePage,
              pageRoutes
            )?.path ?? ''
          }
        />
      </div>
      <div className={classes.rows}>
        {!systemDown && (
          <SideNav
            pageGroups={
              currentCustomerId ? pageGroupsFiltered : operationsPageGroups
            }
          />
        )}
        <div className={classes.columns}>
          <div className={classes.rows}>
            <div className={classes.content}>
              <InpixonMapPlaceholder />
              <React.Suspense
                fallback={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <CircularProgress />
                  </div>
                }
              >
                {children}
                {/* Outlet is used by the react-router-dom as the output for the routes */}
                <Outlet />
              </React.Suspense>
            </div>
          </div>
        </div>
      </div>
      <Notifications status={status} clearStatus={clearStatus} />
      <Snackbar />
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    status: state.status,
    currentCustomerId: (state.auth as AuthState).currentCustomerId,
  }
}

export default connect(mapStateToProps, {
  clearStatus: () => clearStatusAction(),
})(RootLayout)
