import { PeopleOutlined, Security } from '@mui/icons-material'
import React, { useMemo } from 'react'
import { CommonComponent, ComponentProps } from '../../components'
import { FilterToggleButton, PageViewNav } from '../../components/ActionBar'
import { PageToolbar } from '../../components/Layout'
import { UserManagementPageViews } from '../../models'

interface Props {
  activeView?: UserManagementPageViews
  filterCount: number | undefined
  onViewChange: (view: UserManagementPageViews) => void
  onFilterButtonClicked: () => void
}

const UserManagementToolbar: CommonComponent<Props> = (
  props: ComponentProps<Props>
) => {
  const { onViewChange, activeView, filterCount, onFilterButtonClicked } = props

  const showFilters = useMemo(() => activeView === 'list', [activeView])

  const handleViewChange = (view: UserManagementPageViews): void => {
    onViewChange && onViewChange(view)
  }

  return (
    <PageToolbar searchStorageName={UserManagementToolbar.name}>
      {{
        navigation: (
          <PageViewNav
            defaultView='list'
            view={activeView}
            onViewChange={(view): void => handleViewChange(view)}
            items={[
              {
                view: 'list',
                icon: <PeopleOutlined />,
                label: 'Users',
              },
              {
                view: 'roles',
                icon: <Security />,
                label: 'Roles',
              },
            ]}
          ></PageViewNav>
        ),
        filterButton: showFilters && (
          <FilterToggleButton
            filterCount={filterCount}
            onClick={onFilterButtonClicked}
          ></FilterToggleButton>
        ),
      }}
    </PageToolbar>
  )
}

export default UserManagementToolbar
