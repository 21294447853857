import { differenceInHours } from 'date-fns'
import { FetchingStatus } from '../models/fetchingStatus'

const shouldDispatch = (
  status: FetchingStatus | undefined,
  lastRefreshTime: Date | undefined,
  additionalCondition = false
): boolean | undefined =>
  additionalCondition ||
  status === undefined ||
  (lastRefreshTime && differenceInHours(lastRefreshTime, new Date()) > 1)

export { shouldDispatch }
