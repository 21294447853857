import { getType } from 'typesafe-actions'
import { StaffAction } from '../actions'
import {
  removeStaffFromMapAction,
  removeStaffMembersFromMapAction,
  deleteStaffAction,
  getStaffByLocationIdAction,
  postStaffAction,
  putStaffAction,
  silentUpdateStaffAction,
  getPaginatedStaffAction,
} from '../actions/staff'
import {
  Staff,
  StaffMembers,
  StaffState,
  SignalStatus,
  BadgeState,
} from '../models'
import { FetchingStatus, SilentRefreshStatus } from '../models/fetchingStatus'
import {
  deleteRecord,
  deleteRecords,
  mergeRecord,
  mergeRecords,
  setFetchingStatus,
} from '../utils'

const initialState: StaffState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
  staffByLocation: {},
  silentRefreshStatus: undefined,
  recordCount: undefined,
  page: undefined,
  prevLink: undefined,
  nextLink: undefined,
}

const staffReducer = (
  state: StaffState = initialState,
  action: StaffAction
): StaffState => {
  switch (action.type) {
    case getType(removeStaffFromMapAction):
      return {
        ...state,
        data: deleteRecord<StaffMembers, Staff>(state.data, action.payload),
      }
    case getType(removeStaffMembersFromMapAction):
      const newState = { ...state.staffByLocation }

      action.payload.forEach((staff) => {
        Object.entries(newState).forEach((x) => {
          if (x[1].some((s) => s.agentGuid === staff)) {
            const index = x[1].findIndex((s) => s.agentGuid === staff)
            if (index > -1) {
              x[1].splice(index, 1)
            }
          }
        })
      })

      return {
        ...state,
        data: deleteRecords<StaffMembers, Staff>(state.data, action.payload),
        staffByLocation: newState,
      }
    case getType(postStaffAction.success):
      return {
        ...state,
        data: mergeRecord<StaffMembers, Staff, 'agentGuid'>(
          state.data,
          action.payload,
          'agentGuid'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
        recordCount: state.recordCount ? state.recordCount + 1 : undefined,
      }
    case getType(putStaffAction.success):
      return {
        ...state,
        data: mergeRecord<StaffMembers, Staff, 'agentGuid'>(
          state.data,
          action.payload,
          'agentGuid'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getPaginatedStaffAction.success):
      return {
        ...state,
        data: mergeRecords<StaffMembers, Staff, 'agentGuid'>(
          {},
          action.payload.values,
          'agentGuid'
        ),
        recordCount: action?.payload?.totalCount ?? 0,
        page: action?.payload?.page ?? 1,
        prevLink: action.payload.previousLink,
        nextLink: action.payload.nextLink,
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getStaffByLocationIdAction.success):
      const result = {
        ...state,
        data: mergeRecords<StaffMembers, Staff, 'agentGuid'>(
          state.data,
          action.payload.staff,
          'agentGuid'
        ),
        staffByLocation:
          action.payload.signalStatus !== SignalStatus.TimedOut
            ? {
                ...state.staffByLocation,
                [action.payload.locationId]: action.payload.staff,
              }
            : { ...state.staffByLocation },
        staffByLocationLastRefreshTime: new Date(),
        currentSignalStatus: action.payload.signalStatus,
        status: FetchingStatus.Success,
      }

      return result

    case getType(deleteStaffAction.success):
      return {
        ...state,
        data: deleteRecord<StaffMembers, Staff>(state.data, action.payload),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
        recordCount:
          state.recordCount && state.recordCount >= 1
            ? state.recordCount - 1
            : undefined,
      }

    case getType(putStaffAction.request):
    case getType(postStaffAction.request):
    case getType(deleteStaffAction.request):
    case getType(getPaginatedStaffAction.request):
      return setFetchingStatus<StaffMembers>(state, FetchingStatus.Request)

    case getType(getStaffByLocationIdAction.request):
      return {
        ...state,
        status: FetchingStatus.Request,
        staffByLocation: {},
        staffByLocationLastRefreshTime: new Date(),
      }

    case getType(getStaffByLocationIdAction.failure):
    case getType(putStaffAction.failure):
    case getType(postStaffAction.failure):
    case getType(deleteStaffAction.failure):
    case getType(getPaginatedStaffAction.failure):
      return setFetchingStatus<StaffMembers>(state, FetchingStatus.Failure)
    case getType(silentUpdateStaffAction.request):
      return {
        ...state,
        silentRefreshStatus: SilentRefreshStatus.Request,
      }
    case getType(silentUpdateStaffAction.cancel):
      return {
        ...state,
        silentRefreshStatus: SilentRefreshStatus.Complete,
      }
    case getType(silentUpdateStaffAction.success): {
      action.payload.staff.forEach((staff) => {
        staff.badgeTelemetry = action.payload.badgeTelemetry?.find(
          (telemetry) =>
            telemetry !== undefined &&
            staff.badgeIds.includes(telemetry.trackingId)
        ) as BadgeState
      })
      const currentStaffByLocation = state.staffByLocation
        ? state.staffByLocation
        : {}
      const currentStaffByCurrentLocation: Staff[] =
        state.staffByLocation &&
        state.staffByLocation[action.payload.locationId]
          ? state.staffByLocation[action.payload.locationId]
          : []
      if (action?.payload && action.payload.staff.length > 0) {
        const result = {
          ...state,
          silentRefreshStatus: SilentRefreshStatus.Success,
          data: mergeRecords<StaffMembers, Staff, 'agentGuid'>(
            state.data,
            action.payload.staff,
            'agentGuid'
          ),
          staffByLocation: {
            ...currentStaffByLocation,
            [action.payload.locationId]: [
              ...currentStaffByCurrentLocation,
              ...action.payload.staff,
            ],
          },
          staffByLocationLastRefreshTime: new Date(),
        }

        return result
      } else {
        //If the response is empty from the server, return current state to reduce excessive re-renders from state mutation
        return state
      }
    }

    default:
      return state
  }
}

export default staffReducer
