import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators'
import { Epic } from 'redux-observable'
import { WatchlistAction } from '../actions'
import { WatchlistApi } from '../middleware/watchlist'
import { WatchlistState } from '../models'
import { isActionOf } from 'typesafe-actions'
import {
  getAssetWatchlistAction,
  deleteAssetWatchlistItemAction,
  postAssetWatchlistItemAction,
  getStaffWatchlistAction,
  deleteStaffWatchlistItemAction,
  postStaffWatchlistItemAction,
  getWatchlistAction,
} from '../actions/watchlists'

type WatchlistEpic = Epic<
  WatchlistAction,
  WatchlistAction,
  WatchlistState,
  { watchlistApi: WatchlistApi }
>

export const getWatchlistEpic: WatchlistEpic = (
  action$,
  state$,
  { watchlistApi }
) => {
  return action$.pipe(
    filter(isActionOf(getWatchlistAction.request)),
    mergeMap((action) =>
      from(watchlistApi.getWatchlist(action.payload)).pipe(
        map((watchlistItems) => getWatchlistAction.success(watchlistItems)),
        catchError((error) => of(getWatchlistAction.failure(error)))
      )
    )
  )
}

export const getAssetWatchlistEpic: WatchlistEpic = (
  action$,
  state$,
  { watchlistApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAssetWatchlistAction.request)),
    mergeMap((action) =>
      from(watchlistApi.getAssetWatchlist(action.payload)).pipe(
        map((watchlistItems) =>
          getAssetWatchlistAction.success(watchlistItems)
        ),
        catchError((error) => of(getAssetWatchlistAction.failure(error)))
      )
    )
  )
}

export const postAssetWatchlistItemEpic: WatchlistEpic = (
  action$,
  state$,
  { watchlistApi }
) => {
  return action$.pipe(
    filter(isActionOf(postAssetWatchlistItemAction.request)),
    mergeMap((action) =>
      from(watchlistApi.postAssetWatchlistItem(action.payload)).pipe(
        map((watchlistItem) =>
          postAssetWatchlistItemAction.success(watchlistItem)
        ),
        catchError((error) => of(postAssetWatchlistItemAction.failure(error)))
      )
    )
  )
}

export const deleteAssetWatchlistItemEpic: WatchlistEpic = (
  action$,
  state$,
  { watchlistApi }
) => {
  return action$.pipe(
    filter(isActionOf(deleteAssetWatchlistItemAction.request)),
    mergeMap((action) =>
      from(watchlistApi.deleteAssetWatchlistItem(action.payload)).pipe(
        map(() => deleteAssetWatchlistItemAction.success(action.payload)),
        catchError((error) => of(deleteAssetWatchlistItemAction.failure(error)))
      )
    )
  )
}

export const getStaffWatchlistEpic: WatchlistEpic = (
  action$,
  state$,
  { watchlistApi }
) => {
  return action$.pipe(
    filter(isActionOf(getStaffWatchlistAction.request)),
    mergeMap((action) =>
      from(watchlistApi.getStaffWatchlist(action.payload)).pipe(
        map((watchlistItems) =>
          getStaffWatchlistAction.success(watchlistItems)
        ),
        catchError((error) => of(getStaffWatchlistAction.failure(error)))
      )
    )
  )
}

export const postStaffWatchlistItemEpic: WatchlistEpic = (
  action$,
  state$,
  { watchlistApi }
) => {
  return action$.pipe(
    filter(isActionOf(postStaffWatchlistItemAction.request)),
    mergeMap((action) =>
      from(watchlistApi.postStaffWatchlistItem(action.payload)).pipe(
        map((watchlistItem) =>
          postStaffWatchlistItemAction.success(watchlistItem)
        ),
        catchError((error) => of(postStaffWatchlistItemAction.failure(error)))
      )
    )
  )
}

export const deleteStaffWatchlistItemEpic: WatchlistEpic = (
  action$,
  state$,
  { watchlistApi }
) => {
  return action$.pipe(
    filter(isActionOf(deleteStaffWatchlistItemAction.request)),
    mergeMap((action) =>
      from(watchlistApi.deleteStaffWatchlistItem(action.payload)).pipe(
        map(() => deleteStaffWatchlistItemAction.success(action.payload)),
        catchError((error) => of(deleteStaffWatchlistItemAction.failure(error)))
      )
    )
  )
}
