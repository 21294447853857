import { TenantConfiguration } from '../models'
import { BaseService } from './baseService'

export class TenantConfigurationApi extends BaseService {
  constructor() {
    super({ entityType: 'configuration' })
  }
  route = 'configuration'

  async getCurrentTenantConfiguration(): Promise<TenantConfiguration> {
    return await this.get<TenantConfiguration>(this.route)
  }
}
