import { FilterRequest } from '../models'
import { HttpParams } from '../services/http'

export const buildPaginatedParams = (
  filterRequest?: FilterRequest
): HttpParams => {
  //These params are required
  let httpParams = new HttpParams()
    .set('page', filterRequest?.page ?? 1)
    .set('size', filterRequest?.size ?? 50)

  if (filterRequest) {
    if (filterRequest.userId) {
      httpParams = httpParams.append('userId', filterRequest.userId)
    }
    if (filterRequest.onWatchlist) {
      httpParams = httpParams.append(
        'OnWatchlist',
        filterRequest.onWatchlist ?? false
      )
    }
    if (filterRequest.search) {
      httpParams = httpParams.append('Search', filterRequest.search)
    }
    if (filterRequest.lowRTLSBattery) {
      httpParams = httpParams.append(
        'LowRTLSBattery',
        filterRequest.lowRTLSBattery ?? false
      )
    }
    if (filterRequest.hasBadge) {
      httpParams = httpParams.append(
        'hasBadge',
        filterRequest.hasBadge ?? false
      )
    }
    if (filterRequest.rtlsBatteryChangeDate) {
      httpParams = httpParams.append(
        'RTLSBatteryChangeDate',
        filterRequest.rtlsBatteryChangeDate
      )
    }
    if (filterRequest.rtlsSignalType) {
      httpParams = httpParams.append(
        'RTLSSignalType',
        filterRequest.rtlsSignalType.join(',')
      )
    }
    if (filterRequest.assetFilterRequest) {
      if (filterRequest.assetFilterRequest.hasRecall) {
        httpParams = httpParams.append(
          'HasRecall',
          filterRequest.assetFilterRequest.hasRecall
        )
      }
      if (filterRequest.assetFilterRequest.hasCurrentMonthPMs) {
        httpParams = httpParams.append(
          'HasCurrentMonthPMs',
          filterRequest.assetFilterRequest.hasCurrentMonthPMs
        )
      }
      if (filterRequest.assetFilterRequest.cmmsManaged) {
        httpParams = httpParams.append(
          'CmmsManaged',
          filterRequest.assetFilterRequest.cmmsManaged ?? false
        )
      }
      if (filterRequest.assetFilterRequest.assetTypes) {
        httpParams = httpParams.append(
          'AssetTypes',
          filterRequest.assetFilterRequest.assetTypes.join(',')
        )
      }
      if (filterRequest.assetFilterRequest.assetSubTypes) {
        httpParams = httpParams.append(
          'AssetSubTypes',
          filterRequest.assetFilterRequest.assetSubTypes.join(',')
        )
      }
      if (filterRequest.assetFilterRequest.manufacturers) {
        httpParams = httpParams = httpParams.append(
          'Manufacturers',
          filterRequest.assetFilterRequest.manufacturers.join(',')
        )
      }
      if (filterRequest.assetFilterRequest.models) {
        httpParams = httpParams.append(
          'Models',
          filterRequest.assetFilterRequest.models.join(',')
        )
      }
      if (filterRequest.assetFilterRequest.owners) {
        httpParams = httpParams.append(
          'Owners',
          filterRequest.assetFilterRequest.owners.join(',')
        )
      }
      if (filterRequest.assetFilterRequest.nextPMDate) {
        httpParams = httpParams.append(
          'NextPMDate',
          filterRequest.assetFilterRequest.nextPMDate
        )
      }
      if (filterRequest.assetFilterRequest.assetAssignedTo) {
        httpParams = httpParams.append(
          'assetAssignedTo',
          filterRequest.assetFilterRequest.assetAssignedTo.join(',')
        )
      }
    }

    if (filterRequest.staffFilterRequest) {
      if (filterRequest.staffFilterRequest.staffTypes) {
        httpParams = httpParams.append(
          'StaffTypes',
          filterRequest.staffFilterRequest.staffTypes.join(',')
        )
      }
      if (filterRequest.staffFilterRequest.staffAssignedTo) {
        httpParams = httpParams.append(
          'StaffAssignedTo',
          filterRequest.staffFilterRequest.staffAssignedTo.join(',')
        )
      }
      if (filterRequest.staffFilterRequest.reportsTo) {
        httpParams = httpParams.append(
          'ReportsTo',
          filterRequest.staffFilterRequest.reportsTo.join(',')
        )
      }
    }

    if (filterRequest.sortBy) {
      httpParams = httpParams.append('sortBy', filterRequest.sortBy)
      if (filterRequest.sortOrderDesc === true) {
        httpParams = httpParams.append(
          'sortOrderDesc',
          filterRequest.sortOrderDesc
        )
      }
    }
  }

  return httpParams
}
