import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { QualifierAction } from '../actions'
import { getAllQualifiersAction } from '../actions/qualifiers'
import { QualifiersApi } from '../middleware'
import { QualifierState } from '../models/qualifier'

type QualifierEpic = Epic<
  QualifierAction,
  QualifierAction,
  QualifierState,
  { qualifiersApi: QualifiersApi }
>

export const getAllQualifiersEpic: QualifierEpic = (
  action$,
  state,
  { qualifiersApi: qualifierApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllQualifiersAction.request)),
    mergeMap((action) =>
      from(qualifierApi.getAllQualifiers()).pipe(
        map((qualifiers) => getAllQualifiersAction.success(qualifiers)),
        catchError((error) => of(getAllQualifiersAction.failure(error)))
      )
    )
  )
}
