import React from 'react'
import { noop } from '../../utils'
import TelemetryVisibilityNotificationBar from './TelemetryVisibilityNotificationBar'

interface Props {
  showLiveTelemetryTurnedOff: boolean
  isLoading: boolean
  notificationMessage: string
  refreshText?: string
  refreshButtonAction: (...args: any[]) => void
  disconnectEventAction?: (...args: any[]) => void
  reconnectEventAction?: (...args: any[]) => void
}

const TelemetryNotificationBarContainer: React.FC<Props> = (props: Props) => {
  const { disconnectEventAction = noop, reconnectEventAction = noop } = props

  return (
    <TelemetryVisibilityNotificationBar
      {...props}
      disconnectEventAction={disconnectEventAction}
      reconnectEventAction={reconnectEventAction}
      notificationMessage={'While you were away, automatic updates stopped.'}
    />
  )
}

export default TelemetryNotificationBarContainer
