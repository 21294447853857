import { getType } from 'typesafe-actions'
import { QualifierAction } from '../actions'
import { getAllQualifiersAction } from '../actions/qualifiers'
import { FetchingStatus } from '../models/fetchingStatus'
import { Qualifier, Qualifiers, QualifierState } from '../models/qualifier'
import { mergeRecords, setFetchingStatus } from '../utils'

const initialState: QualifierState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

export default (
  state: QualifierState = initialState,
  action: QualifierAction
): QualifierState => {
  switch (action.type) {
    case getType(getAllQualifiersAction.success):
      return {
        data: mergeRecords<Qualifiers, Qualifier, 'id'>(
          state.data,
          action.payload,
          'id'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getAllQualifiersAction.request):
      return setFetchingStatus<Qualifiers>(state, FetchingStatus.Request)
    case getType(getAllQualifiersAction.failure):
      return setFetchingStatus<Qualifiers>(state, FetchingStatus.Failure)
    default:
      return state
  }
}
