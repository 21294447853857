import { getAssociatedRoute } from '@midmarkrtls/common/helpers'
import AppStore, { getPersistor } from 'store/configureStore'
import pageRoutes, { RouteKeys } from 'routes'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setCurrentCustomerIdAction } from '@midmarkrtls/common/actions/auth'
import { resetStoreAction } from 'actions/resetStore'
import { LocalStorageKeys } from '@midmarkrtls/common/constants'

export const useTenantChange = (): ((id: string) => void) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useNavigate()

  return (id: string) => {
    localStorage.setItem(LocalStorageKeys.IsTenantChanged, '1')
    dispatch(resetStoreAction())
    dispatch(setCurrentCustomerIdAction(id))

    const persistor = getPersistor(AppStore)
    persistor.flush()
    const rootUrl = process.env.REACT_APP_SITE_URL

    const currentLocation = location?.pathname

    const operationsDashboardRoute = getAssociatedRoute(
      RouteKeys.OperationsDashboardPage,
      pageRoutes
    )

    const operationsCustomerManagementPageRoute = getAssociatedRoute(
      RouteKeys.OperationsCustomerManagementPage,
      pageRoutes
    )

    const operationsUserManagementPageRoute = getAssociatedRoute(
      RouteKeys.OperationsUserManagementPage,
      pageRoutes
    )

    if (rootUrl) {
      if (!id) {
        if (operationsDashboardRoute) {
          history(operationsDashboardRoute.path)
        }
      } else {
        if (
          currentLocation === operationsDashboardRoute?.path ||
          currentLocation === operationsCustomerManagementPageRoute?.path ||
          currentLocation === operationsUserManagementPageRoute?.path
        ) {
          history('/')
        } else {
          window.location.href = rootUrl
        }
      }
    }
  }
}
