import { LogLevel } from '@microsoft/signalr'
import { useEffect, useState } from 'react'
import { Subscription } from 'rxjs'
import { bufferTime, filter, map } from 'rxjs/operators'
import SignalRHttpClient from '../helpers/signalRHttpClient'
import { useSignalr } from './useSignalR'
import { fetchAccessTokenAsync } from './useFetchAccessToken'

interface Props {
  url?: string
  method: string
  id: string
  enabled?: boolean
}

let subscription: Subscription | null = null

export default function useSignalRSubscription<T>({
  url,
  method,
  id,
  enabled = true,
}: Props): T[] | undefined {
  const [message, setMessage] = useState<T[]>()

  const { on } = useSignalr(
    `${
      url || process.env.REACT_APP_SIGNALR_CONNECTION_MANAGER
    }telemetry/?id=${id}`,
    {
      accessTokenFactory: () => fetchAccessTokenAsync(),
      httpClient: enabled ? new SignalRHttpClient() : undefined,
      logger: LogLevel.Critical,
    },
    undefined,
    enabled
  )

  useEffect(() => {
    if (!enabled && subscription) {
      subscription.unsubscribe()
      subscription = null
      return
    }

    if (enabled && !subscription) {
      subscription = on<T>(method)
        .pipe(
          bufferTime(3000),
          filter((bufferedArray) => bufferedArray.length > 0),
          map((filteredArray) => filteredArray.flat() as T[])
        )
        .subscribe(setMessage)
    }

    return (): void => {
      if (subscription) {
        subscription.unsubscribe()
        subscription = null
      }
    }
  }, [on, method, enabled, id])

  return message
}
