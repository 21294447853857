import { IconButton, PropTypes } from '@mui/material'
import React, { ReactElement, useEffect, useState } from 'react'
import { ReactNode } from 'react'

export interface ToggleIconButtonProps {
  isSelected: boolean
  size?: 'small' | 'medium'
  shouldPreventDefault?: boolean
  onChange?(isSelected: boolean): void
  color?: PropTypes.Color
}

interface Props extends ToggleIconButtonProps {
  render(isSelected: boolean): ReactNode
}

const ToggleIconButton = (props: Props): ReactElement => {
  const {
    isSelected,
    size = 'medium',
    shouldPreventDefault,
    onChange,
    render,
    color,
  } = props

  const [isToggleSelected, setIsToggleSelected] = useState(isSelected)

  useEffect(() => {
    setIsToggleSelected(isSelected)
  }, [isSelected, props])

  const handleClick = (event: React.MouseEvent): void => {
    if (shouldPreventDefault) {
      event.preventDefault()
      event.stopPropagation()
    }

    if (onChange) {
      onChange(!isToggleSelected)
    }

    setIsToggleSelected(!isToggleSelected)
  }
  return (
    <IconButton onClick={handleClick} color={color} size={size}>
      {render(isToggleSelected)}
    </IconButton>
  )
}

export { ToggleIconButton }
