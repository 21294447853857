/* eslint-disable @typescript-eslint/no-explicit-any */

export const isFunction = (fn: any): boolean => typeof fn === 'function'
export const isArray = (obj: any): boolean => Array.isArray(obj)
export const isNumber = (obj: any): boolean => typeof obj === 'number'
export const isString = (obj: any): boolean => typeof obj === 'string'
export const isNullOrUndefined = (obj: any): boolean =>
  obj === undefined || obj === null
export const isA = {
  function: isFunction,
  array: isArray,
  number: isNumber,
  string: isString,
}
export const isEmpty = (obj: any): boolean => {
  return isNullOrUndefined(obj) || obj === ''
}
