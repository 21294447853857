import { getType } from 'typesafe-actions'
import { MapUpdateState, MapUpdateStore } from '../models'
import { MapUpdateAction } from '../actions'
import { setMapUpdateAction } from '../actions/mapUpdate'
const initialState: MapUpdateStore = {
  mapUpdates: [],
  lastLiveTelemetry: new Date(),
}
export default (
  state: MapUpdateStore = initialState,
  action: MapUpdateAction
): MapUpdateState => {
  switch (action.type) {
    case getType(setMapUpdateAction):
      return {
        ...state,
        mapUpdates: action.payload,
        lastLiveTelemetry: new Date(),
      }
    default:
      return state
  }
}
