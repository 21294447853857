import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addAgentReferenceAction } from '../../actions/agentReference'
import {
  Asset,
  AssetsState,
  InpixonAssetRef,
  InpixonAssetRefStore,
  Staff,
  StaffState,
} from '../../models'

export function useMapAgentReference(): void {
  const { data: assets } = useSelector(
    ({ assets }: { assets: AssetsState }) => assets
  )
  const { data: staff } = useSelector(
    ({ staff }: { staff: StaffState }) => staff
  )
  const { agentReferences, currentReference } = useSelector(
    ({ agentReferences }: { agentReferences: InpixonAssetRefStore }) =>
      agentReferences
  )
  const dispatch = useDispatch()

  useEffect(() => {
    const toBeAdded: InpixonAssetRef[] = []
    let currentRef = currentReference
    if (assets) {
      Object.values(assets)
        .filter((x) => x.badgeIds.length > 0)
        .forEach((asset: Asset) => {
          const existing = agentReferences.some((x) =>
            x.trackingIds.includes(asset.badgeIds[0])
          )
          if (!existing) {
            toBeAdded.push({
              assetKitId: currentRef,
              trackingIds: asset.badgeIds,
              agentGuid: asset.agentGuid,
            })
            currentRef++
          }
        })
    }
    if (staff) {
      Object.values(staff)
        .filter((x) => x.badgeIds.length > 0)
        .forEach((staff: Staff) => {
          const existing = agentReferences.some((x) =>
            x.trackingIds.includes(staff.badgeIds[0])
          )
          if (!existing) {
            toBeAdded.push({
              assetKitId: currentRef,
              trackingIds: staff.badgeIds,
              agentGuid: staff.agentGuid,
            })
            currentRef++
          }
        })
    }
    if (toBeAdded.length) {
      dispatch(
        addAgentReferenceAction({
          agentReferences: toBeAdded,
          currentReference: currentRef,
        })
      )
    }
  }, [agentReferences, assets, currentReference, dispatch, staff])
}
