import { createAsyncAction } from 'typesafe-actions'
import { SubUnit } from '../models'

export const getAllSubUnitsAction = createAsyncAction(
  'GET_ALL_SUBUNITS_REQUEST',
  'GET_ALL_SUBUNITS_SUCCESS',
  'GET_ALL_SUBUNITS_FAILURE'
)<void, SubUnit[], Error>()

export const getSubUnitAction = createAsyncAction(
  'GET_SUBUNIT_REQUEST',
  'GET_SUBUNIT_SUCCESS',
  'GET_SUBUNIT_FAILURE'
)<string, SubUnit, Error>()

export const postSubUnitAction = createAsyncAction(
  'POST_SUBUNIT_REQUEST',
  'POST_SUBUNIT_SUCCESS',
  'POST_SUBUNIT_FAILURE'
)<SubUnit, SubUnit, Error>()

export const putSubUnitAction = createAsyncAction(
  'PUT_SUBUNIT_REQUEST',
  'PUT_SUBUNIT_SUCCESS',
  'PUT_SUBUNIT_FAILURE'
)<SubUnit, SubUnit, Error>()

export const deleteSubUnitAction = createAsyncAction(
  'DELETE_SUBUNIT_REQUEST',
  'DELETE_SUBUNIT_SUCCESS',
  'DELETE_SUBUNIT_FAILURE'
)<string, string, Error>()
