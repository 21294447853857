import { createAsyncAction, createAction } from 'typesafe-actions'
import {
  Asset,
  AssetCreateOrUpdate,
  AssetTrafficByIdsRequest,
  AssetPaginatedTrafficRequest,
  AssetTrafficRequest,
  GetAssetByLocationSuccessModel,
  GetAssetsByIdsSuccessModel,
} from '../models'
import { PaginationResponse } from '../models/paginationResponse'
import { HttpErrorResponse } from '../services/http'

export const removeAssetFromMapAction = createAction(
  'REMOVE_ASSET_FROM_MAP'
)<string>()
export const removeAssetsFromMapAction = createAction('REMOVE_ASSETS_FROM_MAP')<
  string[]
>()

export const deleteAssetAction = createAsyncAction(
  'DELETE_ASSET_REQUEST',
  'DELETE_ASSET_SUCCESS',
  'DELETE_ASSET_FAILURE'
)<string, string, HttpErrorResponse>()

export const getAllAssetsAction = createAsyncAction(
  'GET_ALL_ASSETS_REQUEST',
  'GET_ALL_ASSETS_SUCCESS',
  'GET_ALL_ASSETS_FAILURE'
)<void, Asset[], HttpErrorResponse>()

export const getAssetByIdAction = createAsyncAction(
  'GET_ASSET_BY_ID_REQUEST',
  'GET_ASSET_BY_ID_SUCCESS',
  'GET_ASSET_BY_ID_FAILURE'
)<string, Asset, HttpErrorResponse>()

export const getAssetsByLocationIdAction = createAsyncAction(
  'GET_ASSETS_BY_LOC_REQUEST',
  'GET_ASSETS_BY_LOC_SUCCESS',
  'GET_ASSETS_BY_LOC_FAILURE'
)<AssetTrafficRequest, GetAssetByLocationSuccessModel, HttpErrorResponse>()

export const getPaginatedAssetsAction = createAsyncAction(
  'GET_PAGE_ASSETS_REQUEST',
  'GET_PAGE_ASSETS_SUCCESS',
  'GET_PAGE_ASSETS_FAILURE'
)<
  AssetPaginatedTrafficRequest,
  PaginationResponse<Asset[]>,
  HttpErrorResponse
>()

export const putAssetAction = createAsyncAction(
  'PUT_ASSET_REQUEST',
  'PUT_ASSET_SUCCESS',
  'PUT_ASSET_FAILURE'
)<AssetCreateOrUpdate, Asset, HttpErrorResponse>()

export const postAssetAction = createAsyncAction(
  'POST_ASSET_REQUEST',
  'POST_ASSET_SUCCESS',
  'POST_ASSET_FAILURE'
)<AssetCreateOrUpdate, Asset, HttpErrorResponse>()

// Use cancelArg as SILENT_UPDATE_ASSET_COMPLETE action type to take advantages of the createAsyncAction method
export const silentUpdateAssetAction = createAsyncAction(
  'SILENT_UPDATE_ASSET_REQUEST',
  'SILENT_UPDATE_ASSET_SUCCESS',
  'SILENT_UPDATE_ASSET_FAILURE',
  'SILENT_UPDATE_ASSET_COMPLETE'
)<AssetTrafficByIdsRequest, GetAssetsByIdsSuccessModel, HttpErrorResponse>()

export const getFullAssetsDownloadAction = createAsyncAction(
  'GET_FULL_DOWNLOAD_ASSETS_REQUEST',
  'GET_FULL_DOWNLOAD_ASSETS_SUCCESS',
  'GET_FULL_DOWNLOAD_ASSETS_FAILURE'
)<AssetPaginatedTrafficRequest, string, HttpErrorResponse>()
