import {
  JibestreamVenues,
  JibestreamVenue,
  JibestreamVenueState,
} from '../models/venue'
import { JibestreamVenueAction } from '../actions'
import { getType } from 'typesafe-actions'
import {
  getAllJibestreamVenuesAction,
  importVenueAction,
} from '../actions/venue'
import { FetchingStatus } from '@midmarkrtls/common/models'
import { setFetchingStatus } from '@midmarkrtls/common/utils'

const initialState: JibestreamVenueState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

const mergeJibestreamVenue = (
  state: JibestreamVenueState,
  venues: JibestreamVenues
) => {
  return Object.values(venues).reduce(
    (acc: Record<number, JibestreamVenue>, venue: JibestreamVenue) => {
      return {
        ...acc,
        [venue.id]: venue,
      }
    },
    { ...state.data }
  )
}

const venueReducer = (
  state: JibestreamVenueState = initialState,
  action: JibestreamVenueAction
): JibestreamVenueState => {
  switch (action.type) {
    case getType(getAllJibestreamVenuesAction.success):
      return {
        data: mergeJibestreamVenue(state, action.payload),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(importVenueAction.success):
      return setFetchingStatus<JibestreamVenues>(state, FetchingStatus.Success)
    case getType(getAllJibestreamVenuesAction.request):
    case getType(importVenueAction.request):
      return setFetchingStatus<JibestreamVenues>(state, FetchingStatus.Request)
    case getType(getAllJibestreamVenuesAction.failure):
    case getType(importVenueAction.failure):
      try {
        const t = action.payload
        if (t && t.error && t.error.errorMessages.length > 0) {
          t.error.errorMessages.forEach((x: string) => {
            // eslint-disable-next-line
            console.log(x)
          })
        }
      } catch (e) {
        // eslint-disable-next-line
        console.log('Unable to parse error message')
      }
      return setFetchingStatus<JibestreamVenues>(state, FetchingStatus.Failure)
    default:
      return state
  }
}

export default venueReducer
