import { Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { acknowledgeHttpError } from '../../actions/users'
import { QueryFilter } from '../../filters'
import {
  useFetchStaffByLocation,
  useFetchUsers,
  usePermissionCheck,
} from '../../hooks'
import useUserFilters from '../../hooks/useUserFilters'
import {
  FetchingStatus,
  PermissionsEnum,
  Roles,
  RolesState,
  SignalStatus,
  User,
  UserFilters,
} from '../../models'
import { PageLayoutState } from '../../models/pageLayout'
import { PrimaryButton } from '../Buttons'
import { PageContent } from '../Layout'
import { useReactTableRightDrawer } from '../Tables/useReactTableRightDrawer'
import UserDetailDrawer from './UserDetailDrawer'
import UserListFilterDrawer from './UserListFilterDrawer'
import UserManagementList, { UserRow } from './UserManagementList'
import UserManagementModal from './UserManagementModal'
import { DrawerType } from '../Layout/PageContent'

interface Props {
  userFilterQuery: QueryFilter<User>
  userFilterState: UserFilters
  setUserFilters: (filter: UserFilters) => void
  filterButtonToggled: boolean | undefined
}

const UserManagementListPage = (props: Props): JSX.Element => {
  const {
    filterButtonToggled,
    userFilterQuery,
    userFilterState,
    setUserFilters,
  } = props

  const roles: Roles = useSelector(
    ({ roles }: { roles: RolesState }) => roles.data
  )

  const {
    data: users,
    status: usersStatus,
    error: usersHttpError,
  } = useFetchUsers()
  const hasWritePermission = usePermissionCheck([PermissionsEnum.UsersWrite])

  const dispatch = useDispatch()

  const filteredUsers = useUserFilters(Object.values(users))

  const [openUserFormModal, setOpenUserFormModal] = useState<boolean>(false)
  const {
    rightDrawerState,
    selectRow,
    closeDetailDrawer,
    closeFilterDrawer,
    clearSelectedItem,
    resetSelectedRowIndex,
    toggleFilterDrawer,
    updateSelectedRow,
  } = useReactTableRightDrawer<UserRow<User>>()
  const {
    currentDrawerType,
    item: selectedRow,
    tableSelectedRowIndex,
  } = rightDrawerState
  const { staffs } = useFetchStaffByLocation(SignalStatus.TimedOut)

  const maxHeight = useSelector(
    ({ pageLayout }: { pageLayout: PageLayoutState }) => pageLayout.maxHeight
  )

  const handleClose = useCallback((): void => {
    if (
      usersHttpError?.status === 409 &&
      usersHttpError?.acknowledged === false
    ) {
      dispatch(acknowledgeHttpError(true))
    }
    setOpenUserFormModal(false)
  }, [dispatch, usersHttpError?.acknowledged, usersHttpError?.status])

  const handleCreateUser = useCallback(() => {
    closeDetailDrawer()
    setOpenUserFormModal(true)
  }, [closeDetailDrawer])

  const actions = hasWritePermission
    ? [
        <PrimaryButton
          key='create'
          styleOverride={{
            wrapper: {
              marginRight: '12px',
            },
          }}
          style={{ margin: 0 }}
          variant='contained'
          type={'button'}
          onClick={handleCreateUser}
          loading={usersStatus === FetchingStatus.Request}
        >
          <Typography noWrap style={{ fontWeight: 'bold' }}>
            Create New User
          </Typography>
        </PrimaryButton>,
      ]
    : []

  useEffect(() => {
    if (filterButtonToggled !== undefined) {
      toggleFilterDrawer()
    }
  }, [filterButtonToggled, toggleFilterDrawer])

  return (
    <PageContent
      maxHeight={maxHeight}
      currentRightDrawerType={currentDrawerType}
      content={
        <>
          <UserManagementList
            canEdit={hasWritePermission}
            userFilters={userFilterQuery}
            users={filteredUsers}
            roles={roles}
            isOpsUserList={false}
            showLoading={usersStatus === FetchingStatus.Request}
            tableToolbarActions={actions}
            onUserEdit={() => setOpenUserFormModal(true)}
            tableRowSelectionOptions={{
              selectedRow,
              selectedRowIndex: tableSelectedRowIndex,
              onSelectRow: selectRow,
              clearSelectedRow: clearSelectedItem,
              resetSelectedRowIndex,
              updateSelectedRow,
            }}
          />
          {openUserFormModal && (
            <UserManagementModal
              data={selectedRow ?? null}
              handleClose={handleClose}
              handleCloseDetailDrawer={closeDetailDrawer}
              modalHeaderTitle={selectedRow ? 'Edit User' : 'Create User'}
            />
          )}
        </>
      }
      rightDrawer={
        currentDrawerType === DrawerType.DetailDrawer && selectedRow ? (
          <UserDetailDrawer
            selectedUser={selectedRow}
            roles={roles}
            staff={staffs}
            isOpsUser={false}
            onClose={closeDetailDrawer}
            onEditUser={() => setOpenUserFormModal(true)}
          />
        ) : currentDrawerType === DrawerType.FilterDrawer ? (
          <UserListFilterDrawer
            roles={roles}
            userFilters={userFilterState}
            handleFilterChange={setUserFilters}
            headerProps={{
              onCloseDrawer: closeFilterDrawer,
              clearFilters: () => setUserFilters({} as UserFilters),
            }}
          />
        ) : null
      }
    />
  )
}

export default UserManagementListPage
