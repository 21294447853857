import { getType } from 'typesafe-actions'
import { UserPreferencesAction } from '../actions'
import { setFetchingStatus } from '../utils'
import { FetchingStatus } from '../models/fetchingStatus'
import { UserPreferences, UserPreferencesState } from '../models'
import {
  getUserPreferencesAction,
  putUserPreferencesAction,
} from '../actions/userPreferences'

const initialState: UserPreferencesState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

export default (
  state: UserPreferencesState = initialState,
  action: UserPreferencesAction
): UserPreferencesState => {
  switch (action.type) {
    case getType(getUserPreferencesAction.success):
    case getType(putUserPreferencesAction.success):
      return {
        data: action.payload,
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getUserPreferencesAction.request):
    case getType(putUserPreferencesAction.request):
      return setFetchingStatus<UserPreferences>(state, FetchingStatus.Request)
    case getType(getUserPreferencesAction.failure):
    case getType(putUserPreferencesAction.failure):
      return setFetchingStatus<UserPreferences>(state, FetchingStatus.Failure)

    default:
      return state
  }
}
