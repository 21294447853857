import { useDispatch, useSelector } from 'react-redux'
import { shouldDispatch } from '../../helpers/shouldDispatch'
import { useEffect } from 'react'
import { ChannelSounds, ChannelSoundsState } from '../../models'
import { getAllChannelSoundsAction } from '../../actions/channelSounds'

const useFetchChannelSounds = (shouldRefresh = false) => {
  const dispatch = useDispatch()
  const status = useSelector(
    ({ channelSounds }: { channelSounds: ChannelSoundsState }) =>
      channelSounds.status
  )
  const lastRefreshTime = useSelector(
    ({ channelSounds }: { channelSounds: ChannelSoundsState }) =>
      channelSounds.lastRefreshTime
  )

  useEffect(() => {
    if (shouldDispatch(status, lastRefreshTime) || shouldRefresh) {
      dispatch(getAllChannelSoundsAction.request())
    }
  }, [status, lastRefreshTime, shouldRefresh, dispatch])

  const channelSounds: ChannelSounds = useSelector(
    ({ channelSounds }: { channelSounds: ChannelSoundsState }) =>
      channelSounds.data
  )

  return {
    channelSounds,
    status,
  }
}

export { useFetchChannelSounds }
