import { AgentType, IconType } from '../../models'
import {
  AgentInpixonAssetByType,
  InpixonAssetAgentDeleteOptions,
  InpixonAssetGroupCreateOptions,
} from '../../models/inpixonMap'
import {
  EnteringGeofenceUpdates,
  MapRenderByGeofence,
} from '../../models/realtimeMap'
import { BadgeTelemetryMessageWithAgentEvent } from '../../models/telemetry'
import { groupHasActiveAssist, groupHasActiveDuress } from '../telemetry'

/**
 * Calculate config values to create a new group icon in the entering geofence, and
 * config values to hide the new agent after entering the geofence and rendered as a
 * group icon
 * @param newAgentTelemetry
 * @param currentMapRender
 * @param iconScale
 * @param selectedTrackingId
 * @param selectedGroupId
 * @returns config values to create a new group icon in the entering geofence, and
 * config values to hide the new agent after entering the geofence and rendered as a
 * group icon
 */
export function calculateCreateNewGroupForEnteringGeofence(
  newAgentTelemetry: BadgeTelemetryMessageWithAgentEvent,
  currentMapRender: MapRenderByGeofence,
  iconScale: number,
  selectedTrackingId: string | undefined,
  selectedGroupId: number | undefined
):
  | Pick<EnteringGeofenceUpdates, 'enteringAgentsToHide' | 'groupToCreate'>
  | undefined {
  const enteringAgentsToHide: AgentInpixonAssetByType[] = []
  let groupToCreate: InpixonAssetGroupCreateOptions | undefined
  const currentAgentsToHide: InpixonAssetAgentDeleteOptions[] = []
  const enteringGeofenceMapRender =
    currentMapRender[newAgentTelemetry.geoFenceId]
  if (enteringGeofenceMapRender) {
    enteringGeofenceMapRender.renderedAgents.forEach((renderedAgent) => {
      currentAgentsToHide.push({
        trackingId: renderedAgent.trackingId,
        iconType:
          renderedAgent.agent.agentType === AgentType.Asset
            ? IconType.Asset
            : IconType.Staff,
      })
    })
    const allAgentTelemetriesInNewGroup = [
      ...enteringGeofenceMapRender.renderedAgents,
      newAgentTelemetry,
    ]
    enteringAgentsToHide.push(...currentAgentsToHide, {
      trackingId: newAgentTelemetry.trackingId,
      iconType:
        newAgentTelemetry.agent.agentType === AgentType.Asset
          ? IconType.Asset
          : IconType.Staff,
    })
    const newGroupHasActiveDuress = groupHasActiveDuress(
      allAgentTelemetriesInNewGroup
    )
    const newGroupHasActiveAssist = groupHasActiveAssist(
      allAgentTelemetriesInNewGroup
    )
    groupToCreate = {
      iconType: IconType.Group,
      iconScale,
      geoFenceId: newAgentTelemetry.geoFenceId,
      lat: newAgentTelemetry.lat,
      lon: newAgentTelemetry.lon,
      mapId: newAgentTelemetry.mapId,
      hasActiveDuress: newGroupHasActiveDuress,
      hasActiveAssist: newGroupHasActiveAssist,
      isSelected:
        newAgentTelemetry.geoFenceId === selectedGroupId ||
        newAgentTelemetry.trackingId === selectedTrackingId,
      groupCount: allAgentTelemetriesInNewGroup.length,
    }

    return {
      enteringAgentsToHide,
      groupToCreate,
    }
  } else {
    console.warn('Geofence not found in current map rendering state!')
  }
}
