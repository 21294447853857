import { isEqual } from 'lodash'
import React, { useEffect, useReducer } from 'react'
import { BadgeCurrentLocation, State } from '../models'
import { mapToState } from '../utils'

export type BadgeLocationState = State<BadgeCurrentLocation>

type UseBadgeLocationsState = {
  state: BadgeLocationState
  dispatch: React.Dispatch<BadgeCurrentLocation[]>
}

const useBadgeLocationMonitor = (
  badgeLocationUpdates?: BadgeCurrentLocation[],
  changeFilter?: (
    change: BadgeCurrentLocation,
    state: BadgeLocationState
  ) => boolean
): UseBadgeLocationsState => {
  const isStateChange = (
    badgeLocationUpdate: BadgeCurrentLocation,
    badgeLocationState: BadgeLocationState
  ): boolean => {
    const currentBadgeLocation =
      badgeLocationState[badgeLocationUpdate.badgeId] ?? {}
    return !isEqual(currentBadgeLocation, badgeLocationUpdate)
  }

  const pluckChangesFrom = (
    badgeLocations: BadgeCurrentLocation[],
    currentState: BadgeLocationState
  ): BadgeCurrentLocation[] => {
    const changes = badgeLocations.filter(
      (badgeLocation: BadgeCurrentLocation) =>
        isStateChange(badgeLocation, currentState)
    )

    return changes
  }

  const [state, dispatch] = useReducer(
    (
      state: BadgeLocationState,
      badgeLocations: BadgeCurrentLocation[]
    ): BadgeLocationState => {
      const filteredBadgeLocations = changeFilter
        ? badgeLocations.filter((change) => changeFilter(change, state))
        : badgeLocations

      const locationChanges = pluckChangesFrom(filteredBadgeLocations, state)
      // logChanges(locationChanges, state)
      if (locationChanges.length) {
        const stateChanges = mapToState(locationChanges, 'badgeId')
        const newState = {
          ...state,
          ...stateChanges,
        }

        return newState
      } else {
        return state
      }
    },
    {}
  )

  // watch updates and dispatch to reducer
  useEffect((): void => {
    if (badgeLocationUpdates?.length) {
      dispatch(badgeLocationUpdates)
    }
  }, [badgeLocationUpdates])

  return { state, dispatch }
}
export default useBadgeLocationMonitor
