import { AssetKitInstance } from '../../models'
import {
  StaffDuressAssistAgentIconUpdate,
  StaffDuressAssistGroupIconUpdate,
} from '../../models/realtimeMap'
import {
  updateAgentIconWithActiveStaffEvent,
  updateAgentIconWithResolvedEvent,
  updateGroupIconWithActiveStaffEvent,
  updateGroupIconWithResolvedEvent,
} from '../inpixon'

/**
 * Update agent icon color for active duress and enable pulse. Add geofence highlighting
 * @param agent
 * @param assetKit
 * @param jibestreamController
 * @param addGeofenceHighlight
 */
export function updateAgentOnMapWithActiveDuress(
  agent: StaffDuressAssistAgentIconUpdate,
  assetKit: AssetKitInstance,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any,
  addGeofenceHighlight: (geofenceId: number) => void
): void {
  updateAgentIconWithActiveStaffEvent(
    assetKit,
    `${agent.agentByType.iconType}_${agent.agentByType.trackingId}`,
    agent.confidenceBubbleConfig,
    true,
    false,
    jibestreamController
  )
  addGeofenceHighlight(agent.geofenceId)
}

/**
 * Update agent icon color for active assist
 * @param agent
 * @param assetKit
 * @param jibestreamController
 */
export function updateAgentOnMapWithActiveAssist(
  agent: StaffDuressAssistAgentIconUpdate,
  assetKit: AssetKitInstance,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any
): void {
  updateAgentIconWithActiveStaffEvent(
    assetKit,
    `${agent.agentByType.iconType}_${agent.agentByType.trackingId}`,
    agent.confidenceBubbleConfig,
    false,
    true,
    jibestreamController
  )
}

/**
 * Update group icon color with active duress and add geofence highlighting
 * @param group
 * @param assetKit
 * @param jibestreamController
 * @param addGeofenceHighlight
 */
export function updateGroupOnMapWithActiveDuress(
  group: StaffDuressAssistGroupIconUpdate,
  assetKit: AssetKitInstance,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any,
  addGeofenceHighlight: (geofenceId: number) => void
): void {
  updateGroupIconWithActiveStaffEvent(
    assetKit,
    `${group.groupByType.iconType}_${group.groupByType.geofenceId}`,
    true,
    false,
    jibestreamController
  )
  addGeofenceHighlight(group.groupByType.geofenceId)
}

/**
 * Update group icon color with active assist
 * @param group
 * @param assetKit
 * @param jibestreamController
 */
export function updateGroupOnMapWithActiveAssist(
  group: StaffDuressAssistGroupIconUpdate,
  assetKit: AssetKitInstance,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any
): void {
  updateGroupIconWithActiveStaffEvent(
    assetKit,
    `${group.groupByType.iconType}_${group.groupByType.geofenceId}`,
    false,
    true,
    jibestreamController
  )
}

/**
 * Update agent icon color to remove duress/assist color, disable pulse and remove geofence highlighting
 * @param agent
 * @param assetKit
 * @param jibestreamController
 * @param removeGeofenceHighlight
 */
export function updateAgentOnMapWithResolvedAgentEvent(
  agent: StaffDuressAssistAgentIconUpdate,
  assetKit: AssetKitInstance,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any,
  removeGeofenceHighlight: (geofenceId: number) => void
): void {
  updateAgentIconWithResolvedEvent(
    assetKit,
    `${agent.agentByType.iconType}_${agent.agentByType.trackingId}`,
    agent.confidenceBubbleConfig,
    jibestreamController
  )
  removeGeofenceHighlight(agent.geofenceId)
}

/**
 * Update group icon color to remove duress/assist color and remove geofence highlighting
 * @param group
 * @param assetKit
 * @param jibestreamController
 * @param removeGeofenceHighlight
 */
export function updateGroupOnMapWithResolvedAgentEvent(
  group: StaffDuressAssistGroupIconUpdate,
  assetKit: AssetKitInstance,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any,
  removeGeofenceHighlight: (geofenceId: number) => void
): void {
  updateGroupIconWithResolvedEvent(
    assetKit,
    `${group.groupByType.iconType}_${group.groupByType.geofenceId}`,
    jibestreamController
  )
  removeGeofenceHighlight(group.groupByType.geofenceId)
}
