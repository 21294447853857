import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllStaffAction } from '../../actions/staff'
import { shouldDispatch } from '../../helpers'
import {
  FetchingStatus,
  StaffMembers,
  StaffNoLocationState,
} from '../../models'

interface UseFetchStaff {
  staff: StaffMembers
  status: FetchingStatus | undefined
}

const useFetchStaff = (shouldRefresh = false): UseFetchStaff => {
  const dispatch = useDispatch()
  const status = useSelector(
    ({ staffNoLocation }: { staffNoLocation: StaffNoLocationState }) =>
      staffNoLocation.status
  )
  const lastRefreshTime = useSelector(
    ({ staffNoLocation }: { staffNoLocation: StaffNoLocationState }) =>
      staffNoLocation.lastRefreshTime
  )

  useEffect(() => {
    if (shouldDispatch(status, lastRefreshTime) || shouldRefresh) {
      dispatch(getAllStaffAction.request())
    }
  }, [status, lastRefreshTime, shouldRefresh, dispatch])

  const staff: StaffMembers = useSelector(
    ({ staffNoLocation }: { staffNoLocation: StaffNoLocationState }) =>
      staffNoLocation.data
  )

  return {
    staff,
    status,
  }
}

export { useFetchStaff }
