import { getType } from 'typesafe-actions'
import { UserRouterAction } from '../actions'
import { getAllUserRoutersAction } from '../actions/userRouters'
import { FetchingStatus } from '../models/fetchingStatus'
import { UserRouter, UserRouters, UserRouterState } from '../models/userRouter'
import { mergeRecords, setFetchingStatus } from '../utils'

const initialState: UserRouterState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

export default (
  state: UserRouterState = initialState,
  action: UserRouterAction
): UserRouterState => {
  switch (action.type) {
    case getType(getAllUserRoutersAction.success):
      return {
        data: mergeRecords<UserRouters, UserRouter, 'id'>(
          state.data,
          action.payload,
          'id'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getAllUserRoutersAction.request):
      return setFetchingStatus<UserRouters>(state, FetchingStatus.Request)
    case getType(getAllUserRoutersAction.failure):
      return setFetchingStatus<UserRouters>(state, FetchingStatus.Failure)
    default:
      return state
  }
}
