import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { ChannelTypeAction } from '../actions'
import { getAllChannelTypesAction } from '../actions/channelTypes'
import { ChannelTypesApi } from '../middleware'
import { ChannelTypesState } from '../models'

type ChannelTypeEpic = Epic<
  ChannelTypeAction,
  ChannelTypeAction,
  ChannelTypesState,
  { channelTypesApi: ChannelTypesApi }
>

export const getAllChannelTypesEpic: ChannelTypeEpic = (
  action$,
  state,
  { channelTypesApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllChannelTypesAction.request)),
    mergeMap((action) =>
      from(channelTypesApi.getAllChannelTypes()).pipe(
        map((channelTypes) => getAllChannelTypesAction.success(channelTypes)),
        catchError((error) => of(getAllChannelTypesAction.failure(error)))
      )
    )
  )
}
