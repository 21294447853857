import { Unit } from './units'
import { Location } from './locations'

export interface AlertQualifier {
  id?: string
  alertId?: string
  qualifierId: string
  qualifierTypeId: number
  joinTypeId?: number
  ruleValue: string
}

export interface LocationQualifier {
  campus: Location | undefined
  building: Location | undefined
  floor: Location | undefined
}

export enum QualifierType {
  RoomLocation,
  Unit,
  FloorLocation,
  BuildingLocation,
  BuildingGroupLocation,
  AgentType,
  BadgeList,
  Location,
  AssetType,
}

export enum LocationChangeType {
  'Enters Location',
  'Exits Location',
}
