import { AssetKitInstance } from '../../models'
import {
  InpixonAssetGroupDeleteOptions,
  InpixonAssetAgentCreateOptions,
} from '../../models/inpixonMap'
import { deleteGroupInpixonAsset, showDegroupingAgents } from '../inpixon'

/**
 * Delete the group icon in the leaving geofence and replace it with degrouped agents
 * @returns the nextInpixonAssetId to use for subsequent Inpixon asset creation
 */
export function degroupAgentsInLeavingGeofence(
  groupToRemove: InpixonAssetGroupDeleteOptions,
  agentsToDegroup: InpixonAssetAgentCreateOptions[],
  assetKit: AssetKitInstance,
  assetKitId: number,
  useGeofenceCentroid: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  geofenceInstances: any[],
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any
): number | undefined {
  let nextInpixonAssetId: number | undefined
  deleteGroupInpixonAsset(assetKit, groupToRemove)
  if (agentsToDegroup.length > 0) {
    nextInpixonAssetId = showDegroupingAgents(
      assetKit,
      agentsToDegroup,
      assetKitId,
      useGeofenceCentroid,
      geofenceInstances,
      jibestreamController
    )
  }
  return nextInpixonAssetId
}
