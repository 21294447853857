import memoize from 'lodash/memoize'
import { createSelector } from 'reselect'
import { Devices } from '../models'

export const deviceSelector = (state: Devices): Devices => state

export const getDeviceByDeviceIdSelector = createSelector(
  deviceSelector,
  (devices) =>
    memoize((deviceId: number) =>
      Object.values(devices).find((device) => device.deviceId === deviceId)
    )
)
