import { getType } from 'typesafe-actions'
import { ImportAssetsAction } from 'v2/actions'
import { Imports, Import, ImportsAssetsState } from 'v2/models'
import { mergeRecords, mergeRecord } from '@midmarkrtls/common/utils'
import {
  getAllAssetsImportsAction,
  postImportAssetsAction,
} from 'v2/actions/importsAssets'
import { FetchingStatus } from '@midmarkrtls/common/models'

const initialState: ImportsAssetsState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

const importAssetsReducer = (
  state: ImportsAssetsState = initialState,
  action: ImportAssetsAction
): ImportsAssetsState => {
  switch (action.type) {
    case getType(getAllAssetsImportsAction.request):
    case getType(postImportAssetsAction.request):
      return {
        ...state,
        status: FetchingStatus.Request,
      }

    case getType(postImportAssetsAction.success):
      return {
        data: mergeRecord<Imports, Import, 'importGuid'>(
          state.data,
          action.payload,
          'importGuid'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getAllAssetsImportsAction.success):
      return {
        data: mergeRecords<Imports, Import, 'importGuid'>(
          state.data,
          action.payload,
          'importGuid'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getAllAssetsImportsAction.failure):
    case getType(postImportAssetsAction.failure):
      return {
        ...state,
        status: FetchingStatus.Failure,
      }
    default:
      return state
  }
}

export default importAssetsReducer
