import React, { ReactNode } from 'react'
import { CommonComponent, ComponentProps } from '../../components'
import { PageToolbar } from '../../components/Layout'
import { DataTestIds } from '../../utils/test-utils/dataTestIds'

interface Props {
  unitSelector?: ReactNode
}

const NotificationsToolbar: CommonComponent<Props> = (
  props: ComponentProps<Props>
) => {
  const { unitSelector } = props

  return (
    <PageToolbar
      searchStorageName={NotificationsToolbar.name}
      data-testid={`${DataTestIds.notificationsPageToolbar}`}
    >
      {{
        actions: <>{unitSelector}</>,
      }}
    </PageToolbar>
  )
}

export default NotificationsToolbar
