import { QuickLinkSetting } from "./userSettings"

export enum QuickLinkTypes {
  'Real-Time Assets' = 1,
  'Open PMs' = 2,
  'My Asset List' = 3, // Was Asset Watchlist
  'RTLS Low Batteries' = 4,
  'Real-Time Staff' = 5,
  'My Staff List' = 6, // Was Staff Watchlist
  'Asset Notifications' = 7,
}

export interface QuickLinkBase {
  id: QuickLinkTypes
  enabled: boolean
}

export interface QuickLinkCard extends Pick<QuickLinkBase, 'id'> {
  count: number
  title: string
  subTitle: string
  linkText: string
  handleLinkClick: () => void
  loading: boolean
  borderDecorationColor?: string
}

export interface FilteredQuickLinkSetting
  extends QuickLinkSetting,
    QuickLinkBase {}

export interface FilteredQuickLinkSettings {
  [id: number]: FilteredQuickLinkSetting
}
