import { Avatar, Divider, IconButton, MenuItem } from '@mui/material/'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import Menu, { MenuProps } from '@mui/material/Menu'
import withStyles from '@mui/styles/withStyles'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import React, { MouseEvent, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { setLogoutRouterPromptStatusAction } from '../../actions/routerPrompt'
import { Authentication } from '../../authentication/AuthenticationManager'
import { alertConfigPathMatches } from '../../helpers'
import { useAuth } from '../../hooks'
import {
  RouterPromptState,
  RouterPromptStatus,
} from '../../models/routerPrompt'
import { colors } from '../../styles'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #8d8d8d',
    height: 115,
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))

const useStyles = makeStyles(() =>
  createStyles({
    profileIcon: {
      color: colors.background,
    },
    avatar: {
      height: 26,
      width: 26,
      fontSize: 12,
      fontWeight: 'bold',
    },
    menuHeader: {
      width: 200,
      height: 32,
      overflow: 'hidden',
      fontSize: '16px',
      boxSizing: 'border-box',
      fontFamily: 'Ubuntu',
      whiteSpace: 'nowrap',
      paddingLeft: 12,
      paddingTop: '1px',
      paddingBottom: 'unset',
    },
    menuItem: {
      width: 200,
      height: 28,
      paddingLeft: 12,
      marginTop: 4,
      marginBottom: 0,
      paddingTop: 7,
    },
    divider: {
      backgroundColor: '#8d8d8d',
    },
    background1: {
      backgroundColor: '#8D1B60',
    },
    background2: {
      backgroundColor: '#D9597C',
    },
    background3: {
      backgroundColor: '#78BE20',
    },
    background4: {
      backgroundColor: '#00A19B',
    },
    background5: {
      backgroundColor: '#285F68',
    },
    background6: {
      backgroundColor: '#165788',
    },
  })
)

interface Props {
  userProfilePage: string
  profileDisabled: boolean
}

const UserMenu = (props: Props) => {
  const { userProfilePage, profileDisabled } = props
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const [avatarBackground, setAvatarBackground] = useState<string>()
  const { user } = useAuth()
  const location = useLocation()
  const history = useNavigate()
  const dispatch = useDispatch()
  const routerPromptState = useSelector(
    ({ routerPrompt }: { routerPrompt: RouterPromptState }) => routerPrompt
  )

  const handleMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setMenuOpen(!menuOpen)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setMenuOpen(false)
  }

  const handleLogout = () => {
    const isAlertConfigPage = alertConfigPathMatches(location.pathname)
    if (isAlertConfigPage && routerPromptState.isEnabled) {
      dispatch(setLogoutRouterPromptStatusAction(RouterPromptStatus.PROMPTED))
    } else {
      Authentication.logout()
      handleClose()
    }
  }

  const handleUserProfile = useCallback(() => {
    handleClose()
    history(userProfilePage)
  }, [history, userProfilePage])

  const classes = useStyles()

  const formatAvatar = useCallback(
    () =>
      `${user.firstName.slice(0, 1).toUpperCase()}${user.lastName
        .slice(0, 1)
        .toUpperCase()}`,
    [user]
  )

  if (!avatarBackground) {
    const firstLetter = user.firstName.slice(0, 1).toUpperCase()
    switch (firstLetter) {
      case 'A':
      case 'B':
      case 'C':
      case 'D':
      case 'E':
        setAvatarBackground(classes.background1)
        break
      case 'F':
      case 'G':
      case 'H':
      case 'I':
      case 'J':
        setAvatarBackground(classes.background2)
        break
      case 'K':
      case 'L':
      case 'M':
      case 'N':
      case 'O':
        setAvatarBackground(classes.background3)
        break
      case 'P':
      case 'Q':
      case 'R':
      case 'S':
        setAvatarBackground(classes.background4)
        break
      case 'T':
      case 'U':
      case 'V':
      case 'W':
        setAvatarBackground(classes.background5)
        break
      case 'X':
      case 'Y':
      case 'Z':
        setAvatarBackground(classes.background6)
        break
      default:
        setAvatarBackground(classes.background1)
        break
    }
  }

  useEffect(() => {
    if (routerPromptState.logoutPromptStatus === RouterPromptStatus.CONFIRMED) {
      Authentication.logout()
      handleClose()
    }
  }, [routerPromptState.logoutPromptStatus])

  return (
    <div>
      <IconButton
        id={'open-user-menu'}
        onClick={handleMenu}
        classes={{ root: classes.profileIcon }}
        size='large'
      >
        <Avatar className={`${classes.avatar} ${avatarBackground}`}>
          {formatAvatar()}
        </Avatar>
        {menuOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </IconButton>
      <StyledMenu
        id='menu-appbar'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{
          top: 25,
        }}
      >
        <div
          className={classes.menuHeader}
        >{`${user.firstName} ${user.lastName}`}</div>
        <Divider className={classes.divider} />
        <MenuItem
          className={classes.menuItem}
          onClick={handleUserProfile}
          disabled={profileDisabled}
        >
          Profile
        </MenuItem>
        <MenuItem
          id={'logout-menu-item'}
          className={classes.menuItem}
          onClick={handleLogout}
        >
          Logout
        </MenuItem>
      </StyledMenu>
    </div>
  )
}

export default UserMenu
