import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'

export const overrides = {
  noVerticalPadding: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}

export const useOverrides = makeStyles(() =>
  createStyles({
    ...overrides,
  })
)
