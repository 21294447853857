import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import {
  Table as MatTable,
  TableRow as MatTableRow,
  TableCell as MatTableCell,
  TableBody as MatTableBody,
  TableHead as MatTableHead,
  TableFooter as MatTableFooter,
} from '@mui/material'
import { useTableStyles } from './TableStyles'

// eslint-disable-next-line react-hooks/rules-of-hooks
const styles = useTableStyles()

const tableRowStyles = createStyles({
  root: {
    ...styles.tableRow,
  },
  hover: {},
  selected: {},
})

const tableCellStyles = createStyles({
  root: {
    ...styles.tableCell,
  },
  head: {
    ...styles.tableHeadCell,
  },
})

const tableStyles = createStyles({
  root: {
    ...styles.table,
  },
})

export const Table = withStyles(tableStyles, { name: 'MidmarkTable' })(
  MatTable
) as typeof MatTable
export const TableHead = MatTableHead
export const TableBody = MatTableBody
export const TableFooter = MatTableFooter
export const TableRow = withStyles(tableRowStyles, { name: 'MidmarkTableRow' })(
  MatTableRow
) as typeof MatTableRow
export const TableCell = withStyles(tableCellStyles, {
  name: 'MidmarkTableCell',
})(MatTableCell) as typeof MatTableCell
