import React from 'react'
import { PageContent, PageLayout } from '../../components/Layout'
import { useSelector } from 'react-redux'
import { PageLayoutState } from '../../models/pageLayout'
import { NotificationsList } from './NotificationsList'
import PageHeader from '../../components/ListViews/PageHeader'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { tableColors } from '../../styles'
import NotificationsToolbar from './NotificationsToolbar'
import { useFetchFeatureFlags } from '../../hooks/entities/useFetchFeatureFlags'
import { FeatureFlagLabels, FeatureFlagNames } from '../../constants'
import UnitMultiSelect from '../../components/Selects/UnitMultiSelect'

const useStyles = makeStyles(() =>
  createStyles({
    pageLayout: {
      backgroundColor: tableColors.background,
    },
    pageContent: {
      backgroundColor: tableColors.background,
    },
  })
)

const NotificationsPage = (): JSX.Element => {
  const classes = useStyles()
  const maxHeight = useSelector(
    ({ pageLayout }: { pageLayout: PageLayoutState }) => pageLayout.maxHeight
  )
  const { data: featureFlags } = useFetchFeatureFlags(
    FeatureFlagLabels.Navigation
  )
  const isUnitSelectorEnabled =
    Object.values(featureFlags).filter(
      (x) => x.name === FeatureFlagNames.unitSelector && x.enabled
    ).length > 0

  return (
    <PageLayout className={classes.pageLayout}>
      <NotificationsToolbar
        unitSelector={
          isUnitSelectorEnabled ? (
            <UnitMultiSelect multiSelect={true}></UnitMultiSelect>
          ) : (
            <></>
          )
        }
      />
      <PageHeader
        pageTitle='Asset Notification History'
        pageSubtitle='Shows notifications from the last 48 hours. (Refresh browser to get the latest updates.)'
      ></PageHeader>
      <PageContent
        maxHeight={maxHeight}
        className={classes.pageContent}
        content={<NotificationsList />}
      />
    </PageLayout>
  )
}

export { NotificationsPage }
