import { getAllUnitsAction } from '../../actions/units'
import { FetchingStatus, Units, UnitsState } from '../../models'
import { EntityResult, useEntities } from '../useEntities'

const useFetchUnits = (): EntityResult<Units> =>
  useEntities<UnitsState, Units>(
    () => getAllUnitsAction.request(),
    (state: any) => state.units, // eslint-disable-line @typescript-eslint/no-explicit-any
    (status: FetchingStatus | undefined, lastRefreshTime: Date | undefined) =>
      !lastRefreshTime
  )

export { useFetchUnits }
