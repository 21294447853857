import { useDispatch, useSelector } from 'react-redux'
import { StaffSubTypes, StaffSubTypesState } from '../models'
import { getAllStaffSubTypesAction } from '../actions/staffSubTypes'
import { shouldDispatch } from '../helpers/shouldDispatch'
import { useEffect } from 'react'

const useFetchStaffSubTypes = (shouldRefresh: boolean = false) => {
  const dispatch = useDispatch()
  const status = useSelector(
    ({ staffSubTypes }: { staffSubTypes: StaffSubTypesState }) =>
      staffSubTypes.status
  )
  const lastRefreshTime = useSelector(
    ({ staffSubTypes }: { staffSubTypes: StaffSubTypesState }) =>
      staffSubTypes.lastRefreshTime
  )

  useEffect(() => {
    if (shouldDispatch(status, lastRefreshTime) || shouldRefresh) {
      dispatch(getAllStaffSubTypesAction.request())
    }
  }, [status, lastRefreshTime, shouldRefresh, dispatch])

  const staffSubTypes: StaffSubTypes = useSelector(
    ({ staffSubTypes }: { staffSubTypes: StaffSubTypesState }) =>
      staffSubTypes.data
  )

  return {
    staffSubTypes,
    status,
  }
}

export { useFetchStaffSubTypes }
