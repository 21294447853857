import { GeofenceAction } from '../../actions'
import { getAllGeofencesAction } from '../../actions/geofence'
import { Geofences, GeofencesState } from '../../models'
import { EntityResult, useEntities } from '../useEntities'

const useFetchAllGeofences = (): EntityResult<Geofences> =>
  useEntities<GeofencesState, Geofences>(
    () => getAllGeofencesAction.request(),
    (state: any) => state.geofences
  )

export { useFetchAllGeofences }
