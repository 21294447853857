import { AlertType } from '../models'
import { BaseService } from './baseService'

export class AlertTypesApi extends BaseService {
  constructor() {
    super({ entityType: 'alertTypes' })
  }
  route = 'alert/types'

  async getAllAlertTypes(): Promise<AlertType[]> {
    return await this.get<AlertType[]>(this.route)
  }
}
