import React, { PropsWithChildren, ReactElement, useEffect } from 'react'
import { useSecondaryHeader } from '../components/Common/SecondaryHeader'

const PageWrapper = (props: PropsWithChildren<{}>): ReactElement => {
  const { children } = props
  const { clearSecondaryHeader } = useSecondaryHeader()

  // automatically hide secondary header when page container umounts
  useEffect(() => {
    return (): void => {
      clearSecondaryHeader()
    }
  }, [clearSecondaryHeader])

  return <>{children}</>
}

export default PageWrapper
