export function getEnumKeyByEnumValue<T extends { [index: string]: string }>(
  myEnum: T,
  enumValue: string
): keyof T | null {
  const keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue)
  return keys.length > 0 ? keys[0] : null
}

export function enumToArray<TEnumValue extends number | string>(
  enumVariable: Record<string, TEnumValue>
): string[] {
  return Object.keys(enumVariable).filter(
    (key) => !isNaN(Number(enumVariable[key]))
  )
}

export function addSpaceToEnum(enumVariable: string): string {
  const splitString = enumVariable.split(/(?=[A-Z])/)
  let newString = ''

  splitString.forEach((element) => {
    newString = newString + element + ' '
  })

  return newString.trim()
}
