import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { colors } from './MidmarkTheme'

export const detailDrawerStyles = makeStyles(() =>
  createStyles({
    assistHeaderAlert: {
      fontWeight: 'bold',
      fontSize: '14px',
      backgroundColor: colors.assistDetailDrawerColor,
      marginTop: '20px',
      marginBottom: '16px',
      padding: '4px 4px 2px 8px',
      borderRadius: '4px',
    },
  })
)
