import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { useSelector } from 'react-redux'
import { PageContent, PageLayout } from '../../components/Layout'
import UserProfileView from '../../components/UserProfile/UserProfileView'
import { PageLayoutState } from '../../models/pageLayout'

const useStyles = makeStyles(() =>
  createStyles({
    page: {
      backgroundColor: '#f2f2f2',
    },
    pageContent: {
      maxHeight: '100%',
    },
  })
)

function UserProfilePage(): JSX.Element {
  const classes = useStyles()
  const maxHeight = useSelector(
    ({ pageLayout }: { pageLayout: PageLayoutState }) => pageLayout.maxHeight
  )

  return (
    <PageLayout className={classes.page}>
      <PageContent
        maxHeight={maxHeight}
        className={classes.pageContent}
        content={<UserProfileView />}
      />
    </PageLayout>
  )
}

export default UserProfilePage
