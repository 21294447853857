import React, { useState, useCallback } from 'react'
import { Link } from '@mui/material'
import { FilterCreateModel, FilterType } from '../../models'
import { useDispatch } from 'react-redux'
import { postFiltersAction } from '../../actions/filters'
import FilterNameModal from './FilterNameModal'
import { useAuth } from '../../hooks'

interface Props<T> {
  currentFilter: T
  filterType: FilterType
}

interface SavedFilter {
  filterName?: string
}

const SaveFilterButton = <T extends SavedFilter>(
  props: Props<T>
): JSX.Element => {
  const { currentFilter, filterType } = props
  const { user } = useAuth()

  const dispatch = useDispatch()
  const [filterNameModalOpen, setFilterNameModalOpen] = useState(false)

  const handleFilterCreate = useCallback(
    (input: string): void => {
      const newFilter: FilterCreateModel = {
        name: input,
        filterTypeId: filterType,
        filterOptions: JSON.stringify(currentFilter),
        userGuid: user.id,
      }
      dispatch(postFiltersAction.request(newFilter))
      setFilterNameModalOpen(false)
    },
    [currentFilter, user, filterType, dispatch, setFilterNameModalOpen]
  )

  return (
    <>
      <Link
        style={{ cursor: 'pointer', fontSize: '12px' }}
        variant='body2'
        onClick={(): void => setFilterNameModalOpen(true)}
        underline='none'
      >
        {'Save Filters'}
      </Link>
      <FilterNameModal
        modalOpen={filterNameModalOpen}
        setModalOpen={setFilterNameModalOpen}
        handleCreate={handleFilterCreate}
      />
    </>
  )
}

export default SaveFilterButton
