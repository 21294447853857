import React from 'react'
import { Typography, Divider } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { FlexBox } from '../Layout'

const useStyles = makeStyles(() =>
  createStyles({
    errorContainer: {
      height: '88%',
      textAlign: 'center',
    },
    img: {
      marginBottom: 6,
      height: 65,
    },
    divider: {
      width: 300,
      marginTop: 12,
      marginBottom: 12,
      height: 2,
    },
    errorText: {
      maxWidth: 600,
    },
  })
)

interface Props {
  emptyMessage?: React.ReactNode
}

const TableEmptyMessage = (props: Props): React.ReactElement => {
  const { emptyMessage } = props
  const classes = useStyles()

  return emptyMessage ? (
    <>{emptyMessage}</>
  ) : (
    <FlexBox
      className={classes.errorContainer}
      flex='grow'
      layout='column'
      layoutAlign={{ item: 'center', main: 'center' }}
    >
      <Typography variant='h5'>No Results Found</Typography>
      {/* <Divider className={classes.divider} /> */}
      <Typography className={classes.errorText}>
        Reduce your filters to expand your results.
      </Typography>
    </FlexBox>
  )
}

export { TableEmptyMessage }
