import { createAsyncAction } from 'typesafe-actions'
import { Unit } from '../models'

export const getAllUnitsAction = createAsyncAction(
  'GET_ALL_UNITS_REQUEST',
  'GET_ALL_UNITS_SUCCESS',
  'GET_ALL_UNITS_FAILURE'
)<void, Unit[], Error>()

export const getUnitAction = createAsyncAction(
  'GET_UNIT_REQUEST',
  'GET_UNIT_SUCCESS',
  'GET_UNIT_FAILURE'
)<string, Unit, Error>()

export const postUnitAction = createAsyncAction(
  'POST_UNIT_REQUEST',
  'POST_UNIT_SUCCESS',
  'POST_UNIT_FAILURE'
)<Unit, Unit, Error>()

export const putUnitAction = createAsyncAction(
  'PUT_UNIT_REQUEST',
  'PUT_UNIT_SUCCESS',
  'PUT_UNIT_FAILURE'
)<Unit, Unit, Error>()

export const deleteUnitAction = createAsyncAction(
  'DELETE_UNIT_REQUEST',
  'DELETE_UNIT_SUCCESS',
  'DELETE_UNIT_FAILURE'
)<string, string, Error>()
