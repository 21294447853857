import { Header } from '../components/Tables/TableCsvExport'

export const assetExportHeaders: Header[] = [
  { label: 'Name', key: 'name' },
  { label: 'Display Name', key: 'displayName' },
  { label: 'Asset Type', key: 'assetTypeName' },
  { label: 'Asset Sub-Type', key: 'assetSubTypeName' },
  { label: 'Asset ID', key: 'externalUniqueId' },
  { label: 'Manufacturer', key: 'manufacturerName' },
  { label: 'Model', key: 'model' },
  { label: 'Serial Number', key: 'serialNumber' },
  { label: 'Owner', key: 'owner' },
  { label: 'Next PM Date', key: 'preventativeMaintenanceDateString' },
  { label: 'RTLS ID', key: 'badgeNumber' },
  { label: 'Building Group', key: 'campus' },
  { label: 'Building', key: 'building' },
  { label: 'Floor', key: 'floor' },
  { label: 'Room', key: 'locationName' },
  { label: 'Unit', key: 'locationUnitName' },
  { label: 'Sub-Unit', key: 'locationSubUnitName' },
  { label: 'Location Accuracy', key: 'accuracy' },
  { label: 'RTLS Signal Status', key: 'signalStatus' },
  { label: 'Signal Type', key: 'signalType' },
  { label: 'RTLS Battery Status', key: 'batteryStatus' },
  { label: 'RTLS Battery Percent', key: 'batteryPercent' },
  { label: 'RTLS Battery Change Date', key: 'tagBatteryChangeDateString' },
  { label: 'Last Detected', key: 'lastSensedDateString' },
  { label: 'Assigned To', key: 'unitName' },
]
