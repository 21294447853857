import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { AgentSilentUpdateAction, FormAction, StaffAction } from '../actions'
import {
  deleteStaffAction,
  getPaginatedStaffAction,
  getAllStaffAction,
  getStaffByLocationIdAction,
  postStaffAction,
  putStaffAction,
  silentUpdateStaffAction,
} from '../actions/staff'
import { StaffState } from '../models'
import { StaffApi } from '../middleware/staff'
import { clearFormErrorsAction, setFormErrorsAction } from '../actions/forms'
import { trackSilentUpdateSuccess } from '../actions/agentSilentUpdate'

type StaffEpic = Epic<
  StaffAction | FormAction | AgentSilentUpdateAction,
  StaffAction | FormAction | AgentSilentUpdateAction,
  StaffState,
  { staffApi: StaffApi }
>

export const getStaffByLocationIdEpic: StaffEpic = (
  action$,
  state$,
  { staffApi }
) => {
  return action$.pipe(
    filter(isActionOf(getStaffByLocationIdAction.request)),
    switchMap((action) =>
      from(staffApi.getStaffByLocationId(action.payload)).pipe(
        map((staffMembers) =>
          getStaffByLocationIdAction.success({
            staff: staffMembers,
            locationId: action.payload.locationGuid,
            signalStatus: action.payload.signalStatus,
          })
        ),
        catchError((error) => of(getStaffByLocationIdAction.failure(error)))
      )
    )
  )
}

export const getPaginatedStaffsIdEpic: StaffEpic = (
  action$,
  state$,
  { staffApi }
) => {
  return action$.pipe(
    filter(isActionOf(getPaginatedStaffAction.request)),
    mergeMap((action) =>
      from(staffApi.getPaginatedStaff(action.payload)).pipe(
        map((staff) => getPaginatedStaffAction.success(staff)),
        catchError((error) => of(getPaginatedStaffAction.failure(error)))
      )
    )
  )
}

export const getAllStaffEpic: StaffEpic = (action$, state$, { staffApi }) => {
  return action$.pipe(
    filter(isActionOf(getAllStaffAction.request)),
    mergeMap(() =>
      from(staffApi.getAllStaff()).pipe(
        map((staff) => getAllStaffAction.success(staff)),
        catchError((error) => of(getAllStaffAction.failure(error)))
      )
    )
  )
}

export const putStaffEpic: StaffEpic = (action$, state$, { staffApi }) => {
  return action$.pipe(
    filter(isActionOf(putStaffAction.request)),
    mergeMap((action) =>
      from(staffApi.putStaff(action.payload)).pipe(
        mergeMap((staff) => [
          clearFormErrorsAction({ formName: 'staffEdit' }),
          putStaffAction.success(staff),
        ]),
        catchError((error) =>
          of(
            setFormErrorsAction({ formName: 'staffEdit', error }),
            putStaffAction.failure(error)
          )
        )
      )
    )
  )
}

export const postStaffEpic: StaffEpic = (action$, state$, { staffApi }) => {
  return action$.pipe(
    filter(isActionOf(postStaffAction.request)),
    mergeMap((action) =>
      from(staffApi.postStaff(action.payload)).pipe(
        mergeMap((staff) => [
          clearFormErrorsAction({ formName: 'staffEdit' }),
          postStaffAction.success(staff),
        ]),
        catchError((error) =>
          of(
            setFormErrorsAction({ formName: 'staffEdit', error }),
            postStaffAction.failure(error)
          )
        )
      )
    )
  )
}

export const deleteStaffEpic: StaffEpic = (action$, state$, { staffApi }) => {
  return action$.pipe(
    filter(isActionOf(deleteStaffAction.request)),
    mergeMap((action) =>
      from(staffApi.deleteStaff(action.payload)).pipe(
        map(() => deleteStaffAction.success(action.payload)),
        catchError((error) => of(deleteStaffAction.failure(error)))
      )
    )
  )
}

export const silentUpdateStaffEpic: StaffEpic = (
  action$,
  state$,
  { staffApi }
) => {
  return action$.pipe(
    filter(isActionOf(silentUpdateStaffAction.request)),
    mergeMap((action) =>
      from(staffApi.getStaffsByIds(action.payload)).pipe(
        mergeMap((staffMembers) => [
          silentUpdateStaffAction.success({
            staff: staffMembers,
            locationId: action.payload.locationGuid,
            badgeTelemetry: action.payload.badgeTelemetry,
          }),
          trackSilentUpdateSuccess(staffMembers.map((x) => x.agentGuid)),
        ]),
        catchError((error) => of(silentUpdateStaffAction.failure(error)))
      )
    )
  )
}
