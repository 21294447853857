import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { getAllGeofencesAction } from '../actions/geofence'
import { GeofenceApi } from '../middleware'
import { GeofenceState } from '../models'
import { GeofenceAction } from '../actions'

type GeofenceEpic = Epic<
  GeofenceAction,
  GeofenceAction,
  GeofenceState,
  { geofenceApi: GeofenceApi }
>

export const getGeofences: GeofenceEpic = (action$, state, { geofenceApi }) => {
  return action$.pipe(
    filter(isActionOf(getAllGeofencesAction.request)),
    mergeMap((action) =>
      from(geofenceApi.getAllGeofences()).pipe(
        map((geofence) => getAllGeofencesAction.success(geofence)),
        catchError((error) => of(getAllGeofencesAction.failure(error)))
      )
    )
  )
}
