import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { ModelAction } from '../actions'
import { getAllModelsAction } from '../actions/models'
import { ModelsApi } from '../middleware'
import { ModelsState } from '../models'

type ModelEpic = Epic<
  ModelAction,
  ModelAction,
  ModelsState,
  { modelsApi: ModelsApi }
>

export const getAllModelsEpic: ModelEpic = (action$, _state, { modelsApi }) => {
  return action$.pipe(
    filter(isActionOf(getAllModelsAction.request)),
    mergeMap((action) =>
      from(modelsApi.getAllModels()).pipe(
        map((models) => getAllModelsAction.success(models)),
        catchError((error) => of(getAllModelsAction.failure(error)))
      )
    )
  )
}
