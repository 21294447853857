import { useDispatch, useSelector } from 'react-redux'
import { AssetSubTypes, AssetSubTypesState } from '../models'
import { getAllAssetSubTypesAction } from '../actions/assetSubTypes'
import { shouldDispatch } from '../helpers/shouldDispatch'
import { useEffect } from 'react'

const useFetchAssetSubTypes = (shouldRefresh: boolean = false) => {
  const dispatch = useDispatch()
  const status = useSelector(
    ({ assetSubTypes }: { assetSubTypes: AssetSubTypesState }) =>
      assetSubTypes.status
  )
  const lastRefreshTime = useSelector(
    ({ assetSubTypes }: { assetSubTypes: AssetSubTypesState }) =>
      assetSubTypes.lastRefreshTime
  )

  useEffect(() => {
    if (shouldDispatch(status, lastRefreshTime) || shouldRefresh) {
      dispatch(getAllAssetSubTypesAction.request())
    }
  }, [status, lastRefreshTime, shouldRefresh, dispatch])

  const assetSubTypes: AssetSubTypes = useSelector(
    ({ assetSubTypes }: { assetSubTypes: AssetSubTypesState }) =>
      assetSubTypes.data
  )

  return {
    assetSubTypes,
    status,
  }
}

export { useFetchAssetSubTypes }
