import {
  AgentInpixonAssetByType,
  ConfidenceConfig,
  GroupInpixonAssetByType,
  InpixonAssetAgentCreateOptions,
  InpixonAssetAgentDeleteOptions,
  InpixonAssetAgentPositionUpdate,
  InpixonAssetGroupCreateOptions,
  InpixonAssetGroupDeleteOptions,
  InpixonAssetGroupUpdate,
} from '../inpixonMap'

/**
 * MoveAgent:
 *    Agent is currently NOT rendered a Group icon
 * MoveAgentFromGroup:
 *    Agent is currently rendered as a group icon. After agent left this geofence,
 *  the remaning agents in this geofence is still to be rendered as a Group icon with an updated group count.
 * Degroup:
 *    Agent is currently rendered as a group icon. After agent left this geofence,
 *  the remaning agents in this geofence is no longer rendered as a Group icon and is to be replaced with either
 *  an Asset or Staff icon.
 */
export enum LeavingGeofenceMapUpdateTypes {
  MoveAgent,
  MoveAgentFromGroup,
  Degroup,
}

/**
 * Ungrouped:
 *    Agent is entering a geofence that currently does not have any other agent. Therefore, entering agent is to be
 *  rendered as either an Asset or Staff icon
 * UpdateGroup:
 *    Agent is entering a geofence that already has an existing group icon rendered. Therefore, entering agent is to
 *  be rendered as a Group icon with an updated group count
 * CreateNewGroup:
 *    Agent is entering a geofence that already as an existing Asset or Staff icon. Therefore, both existing agent and
 *  the entering agent are to be rendered as a Group icon
 */
export enum EnteringGeofenceMapUpdateTypes {
  Ungrouped,
  UpdateGroup,
  CreateNewGroup,
}

export enum AgentEventMapUpdateTypes {
  NewActiveDuress,
  NewActiveAssist,
  ResolvedDuressOrAssist,
}

export type LeavingGeofenceUpdates = {
  groupToRemove: InpixonAssetGroupDeleteOptions
  agentsToDegroup: InpixonAssetAgentCreateOptions[]
  degroupedAgentToUpdatePosition: InpixonAssetAgentPositionUpdate
  leavingAgentToUpdatePosition: InpixonAssetAgentPositionUpdate
  groupToUpdateIcon: InpixonAssetGroupUpdate
}

export type EnteringGeofenceUpdates = {
  groupToUpdateIcon: InpixonAssetGroupUpdate
  enteringAgentsToHide: AgentInpixonAssetByType[]
  groupToCreate: InpixonAssetGroupCreateOptions
}

export type MoveAgent = Pick<
  LeavingGeofenceUpdates,
  'leavingAgentToUpdatePosition'
>
export type Degroup = Pick<
  LeavingGeofenceUpdates,
  'groupToRemove' | 'agentsToDegroup' | 'degroupedAgentToUpdatePosition'
>
export type MoveAgentFromGroup = Pick<
  LeavingGeofenceUpdates,
  'groupToUpdateIcon' | 'agentsToDegroup' | 'degroupedAgentToUpdatePosition'
>

export type CreateNewGroup = Pick<
  EnteringGeofenceUpdates,
  'enteringAgentsToHide' | 'groupToCreate'
>
export type UpdateGroup = Pick<
  EnteringGeofenceUpdates,
  'groupToUpdateIcon' | 'enteringAgentsToHide'
>

export interface RemoveAgentLeavingMap
  extends Pick<
    LeavingGeofenceUpdates,
    'groupToRemove' | 'groupToUpdateIcon' | 'agentsToDegroup'
  > {
  agentsToRemove: InpixonAssetAgentDeleteOptions
}

export type MoveAgentAndCreateNewGroupUpdates = {
  [trackingId: string]: {
    leavingGeofence: MoveAgent
    enteringGeofence: CreateNewGroup
  }
}

export type MoveAgentAndUpdateGroupUpdates = {
  [trackingId: string]: {
    leavingGeofence: MoveAgent
    enteringGeofence: UpdateGroup
  }
}

export type MoveAgentAndUngroupedUpdates = {
  [trackingId: string]: MoveAgent
}

export type DegroupAndUngroupedUpdates = {
  [trackingId: string]: Degroup
}

export type DegroupAndUpdateGroupUpdates = {
  [trackingId: string]: {
    leavingGeofence: Degroup
    enteringGeofence: UpdateGroup
  }
}

export type DegroupAndCreateNewGroupUpdates = {
  [trackingId: string]: {
    leavingGeofence: Degroup
    enteringGeofence: CreateNewGroup
  }
}

export type MoveAgentFromGroupAndUpdateGroupUpdates = {
  [trackingId: string]: {
    leavingGeofence: MoveAgentFromGroup
    enteringGeofence: UpdateGroup
  }
}

export type MoveAgentFromGroupAndUngroupUpdates = {
  [assetTrackingId: string]: MoveAgentFromGroup
}

export type MoveAgentFromGroupCreateNewGroupUpdates = {
  [trackingId: string]: {
    leavingGeofence: MoveAgentFromGroup
    enteringGeofence: CreateNewGroup
  }
}

export type StaffDuressAssistAgentIconUpdate = {
  agentByType: AgentInpixonAssetByType
  geofenceId: number
  confidenceBubbleConfig: ConfidenceConfig
}

export type StaffDuressAssistGroupIconUpdate = {
  groupByType: GroupInpixonAssetByType
  hasActiveDuress: boolean
  hasActiveAssist: boolean
  isSelected: boolean
}

export type StaffDuressAssistIconUpdate = {
  agentIconUpdates: {
    activeDuressAgents: StaffDuressAssistAgentIconUpdate[]
    activeAssistAgents: StaffDuressAssistAgentIconUpdate[]
    resolvedEventAgents: StaffDuressAssistAgentIconUpdate[]
  }
  groupIconUpdates: {
    activeDuressGroups: StaffDuressAssistGroupIconUpdate[]
    activeAssistGroups: StaffDuressAssistGroupIconUpdate[]
    resolvedEventGroups: StaffDuressAssistGroupIconUpdate[]
  }
}

export type AgentsAlreadyOnMapUpdates = {
  moveAgentAndCreateNewGroupUpdates: MoveAgentAndCreateNewGroupUpdates
  moveAgentAndUpdateGroupUpdates: MoveAgentAndUpdateGroupUpdates
  moveAgentAndUngroupedUpdates: MoveAgentAndUngroupedUpdates
  degroupAndUngroupedUpdates: DegroupAndUngroupedUpdates
  degroupAndUpdateGroupUpdates: DegroupAndUpdateGroupUpdates
  degroupAndCreateNewGroupUpdates: DegroupAndCreateNewGroupUpdates
  moveAgentFromGroupAndUpdateGroupUpdates: MoveAgentFromGroupAndUpdateGroupUpdates
  moveAgentFromGroupAndUngroupUpdates: MoveAgentFromGroupAndUngroupUpdates
  moveAgentFromGroupAndCreateNewGroupUpdates: MoveAgentFromGroupCreateNewGroupUpdates
  staffDuressAssistIconUpdates: StaffDuressAssistIconUpdate
}

export type GroupIconUpdate = {
  geofenceId: number
  url: string
}

export type EnteringGeofenceGroupUpdates = {
  enteringAgentsToHide: AgentInpixonAssetByType[]
  groupIconToUpdate: GroupIconUpdate
}
