import { FormControl, TextField } from '@mui/material'
import { Autocomplete } from '@mui/material'
import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { defaultAutocompleteProps } from '../../../../helpers/filters'
import { Unit } from '../../../../models'
import { useFormStyle } from '../../../../styles'

interface Props {
  value: Unit | undefined
  loading: boolean
  error: boolean
  className: string | undefined
  handleChange: (event: any, value: Unit | null) => void
  data: Unit[]
  fieldName: string
  helperText?: string
  disabled?: boolean
  unselectedValue?: string
}

const AlertUnitDropDown: React.FC<Props> = (props: Props) => {
  const {
    value,
    loading,
    error,
    className,
    handleChange,
    data,
    fieldName,
    helperText,
    disabled,
    unselectedValue,
  } = props

  const [managedValue, setManagedValue] = useState<Unit>()
  const [open, setOpen] = useState(false)

  const sortedData = useMemo(() => {
    const temp = data.sort((a, b) =>
      String(a.id).toLowerCase().localeCompare(String(b.id).toLowerCase())
    )
    const index = temp.findIndex((x: Unit) => x.id === '-1')
    const item = temp.splice(index, 1)[0]
    temp.splice(0, 0, item)
    return temp
  }, [data])

  const classes = useFormStyle()

  useEffect(() => {
    if (data.length && value === undefined && unselectedValue) {
      setManagedValue(data.find((x) => x.id === unselectedValue))
      setOpen(false)
    } else {
      setManagedValue(value)
    }
  }, [data, unselectedValue, value])

  const { props: autoCompleteProps, renderTags } = defaultAutocompleteProps()
  const valueChange = (event: any, value: Unit | null) => {
    setOpen(false)
    if (handleChange !== null && handleChange != undefined) {
      handleChange(event, value)
    }
  }

  return (
    <FormControl
      variant='standard'
      className={classes.inputStyle}
      style={{ width: '100%' }}
    >
      <Autocomplete
        {...autoCompleteProps}
        style={{ width: '100%' }}
        value={managedValue ?? null}
        getOptionLabel={(unit: Unit): string => unit.name}
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id
        }}
        options={sortedData}
        selectOnFocus
        clearOnBlur
        open={open}
        onOpen={() => {
          setOpen(true)
        }}
        onClose={() => {
          setOpen(false)
        }}
        handleHomeEndKeys
        loading={loading}
        onChange={valueChange}
        disabled={disabled ?? false}
        renderOption={(props, option, { selected }) => (
          <li {...props}>{option.name}</li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            margin='normal'
            label={fieldName}
            variant='filled'
            error={error}
            required
            className={className}
            helperText={helperText}
          />
        )}
        renderTags={renderTags((option) => option.name)}
      />
    </FormControl>
  )
}

export { AlertUnitDropDown }
