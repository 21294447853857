import { useMemo } from 'react'
import { Criteria, filterByCriteria } from '../../filters'

export const useFilters = <D extends object = {}>(
  data: D[] | undefined,
  criteria: Criteria<D>[]
): D[] | undefined => {
  const filteredData = useMemo(() => {
    return filterByCriteria(data, criteria)
  }, [data, criteria])

  return filteredData
}

export default useFilters
