import { Epic } from 'redux-observable'
import { AgentEventViewsAction } from '../actions'
import { getAgentEventViewsByUnitsAndLocationAction } from '../actions/agentEventViews'
import { catchError, filter, map, switchMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { AgentEventViewsApi } from '../middleware'
import { from, of } from 'rxjs'
import { HttpParams } from '../services/http'
import { AlertTypes } from '../models/alert'

type AgentEventViewsEpic = Epic<
  AgentEventViewsAction,
  AgentEventViewsAction,
  any,
  { agentEventViewsApi: AgentEventViewsApi }
>

export const getAgentEventViewsEpic: AgentEventViewsEpic = (
  action$,
  state$,
  { agentEventViewsApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAgentEventViewsByUnitsAndLocationAction.request)),
    switchMap((action) =>
      from(
        agentEventViewsApi.getAgentEventViews({
          filters: new HttpParams()
            .set('page', state$.value.agentEventViews.data.currentPage + 1)
            .set('size', state$.value.agentEventViews.data.pageSize)
            .set('alertTypes', AlertTypes.AssetAlert)
            .set(
              'lastTimePeriod',
              state$.value.agentEventViews.data.lastTimePeriod
            )
            .set('unitIds', action.payload.unitIds ?? [])
            .set('floorIds', action.payload.floorIds),
        })
      ).pipe(
        map((paginationResponse) =>
          getAgentEventViewsByUnitsAndLocationAction.success(paginationResponse)
        ),
        catchError((error) =>
          of(getAgentEventViewsByUnitsAndLocationAction.failure(error))
        )
      )
    )
  )
}
