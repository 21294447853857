import { createSelector } from 'reselect'
import { StaffEventsState } from '../models'

export const selectStaffEventState = ({
  staffEvents,
}: {
  staffEvents: StaffEventsState
}): StaffEventsState => staffEvents

export const selectStaffEventData = createSelector(
  selectStaffEventState,
  (staffEventState) => staffEventState.data
)

export const selectAllStaffEvents = createSelector(
  selectStaffEventData,
  (staffEvents) => Object.values(staffEvents)
)

export const selectStaffEventFetchingStatus = createSelector(
  selectStaffEventState,
  (staffEventState) => staffEventState.status
)
