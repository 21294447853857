import React, { ReactNode, useCallback, useEffect } from 'react'
import { CommonProps } from '..'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { ActionBar, SearchField } from '../ActionBar'
import { useSearchFilter } from '../../hooks/filters'

export interface PageToolbarItems {
  navigation?: ReactNode
  actions?: ReactNode
  filterButton?: ReactNode
}

interface Props extends CommonProps {
  disableSearch?: boolean
  searchStorageName?: string
  children?: PageToolbarItems
}

const PageToolbar: React.FC<Props> = (props: Props) => {
  const { disableSearch = false, searchStorageName, children = {} } = props

  const { filterState: searchFilter, setFilterState: setSearchFilter } =
    useSearchFilter()

  const onSearch = useCallback(
    (value: string) => {
      setSearchFilter({ searchBy: value })
    },
    [setSearchFilter]
  )

  const useStyles = makeStyles(() =>
    createStyles({
      root: {
        paddingRight: '12px',
      },
    })
  )
  useEffect(() => {
    return (): void => {
      setSearchFilter({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const classes = useStyles()
  return (
    <ActionBar
      containerClasses={classes}
      left={children.navigation}
      middle={
        !disableSearch && (
          <SearchField
            value={searchFilter.searchBy}
            localStorageName={searchStorageName}
            onSearch={onSearch}
          />
        )
      }
      right={
        <>
          {children.actions}
          {children.filterButton}
        </>
      }
    ></ActionBar>
  )
}

export default PageToolbar
