import {
  AnyAction,
  applyMiddleware,
  createStore,
  PreloadedState,
  Reducer,
  Store,
} from 'redux'
import { Epic } from 'redux-observable'
import { Action } from 'typesafe-actions'
import { MiddlewarePieces } from './middleware'

const configureStore = <S, T extends Action<string>, U>(
  reducers: Reducer,
  middlewares: MiddlewarePieces<T, U>,
  epic: Epic,
  preloadedState?: PreloadedState<S>
): Store<S, AnyAction> => {
  const store =
    process.env.NODE_ENV === 'production' || !middlewares.enhancer
      ? createStore(reducers, preloadedState, applyMiddleware(middlewares.epic))
      : createStore(reducers, preloadedState, middlewares.enhancer)
  middlewares.epic.run(epic)
  return store
}

export default configureStore
