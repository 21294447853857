import { createAsyncAction } from 'typesafe-actions'
import { Badge, BadgeUpdateModel, CreateBadgeRequest } from '../models'
import { HttpErrorResponse } from '../services/http'

export const getAllBadgesAction = createAsyncAction(
  'GET_ALL_BADGES_REQUEST',
  'GET_ALL_BADGES_SUCCESS',
  'GET_ALL_BADGES_FAILURE'
)<void, Badge[], HttpErrorResponse>()

export const getBadgeAction = createAsyncAction(
  'GET_BADGE_REQUEST',
  'GET_BADGE_SUCCESS',
  'GET_BADGE_FAILURE'
)<string, Badge, HttpErrorResponse>()

export const putBadgeAction = createAsyncAction(
  'PUT_BADGES_REQUEST',
  'PUT_BADGES_SUCCESS',
  'PUT_BADGES_FAILURE'
)<BadgeUpdateModel, void, HttpErrorResponse>()

export const postBadgeAction = createAsyncAction(
  'POST_BADGES_REQUEST',
  'POST_BADGES_SUCCESS',
  'POST_BADGES_FAILURE'
)<CreateBadgeRequest, Badge, HttpErrorResponse>()
