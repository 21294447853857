import React, { ReactNode } from 'react'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { flexLayout } from '../../../styles'

const styles = createStyles({
  root: {
    ...flexLayout({ direction: 'column' }),
  },
})

interface Props extends WithStyles<typeof styles> {
  children: ReactNode
}

const DrawerContent: React.FC<Props> = (props: Props) => {
  const { classes, children } = props

  return <div className={classes.root}>{children}</div>
}

export default withStyles(styles, { name: 'RightDrawerContent' })(DrawerContent)
