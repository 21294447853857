import React from 'react'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { range } from '../../helpers/misc'
import clsx from 'clsx'
import { CommonPropsWithStyles, TableRow, TableCell } from '.'
import { Skeleton } from '@mui/material'

interface Props {
  rowCount?: number
  columns?: any[] // eslint-disable-line @typescript-eslint/no-explicit-any
  columnCount?: number
}

const styles = createStyles({
  row: {
    // Support custom styles here
  },
  cell: {
    // Support custom styles here
  },
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const generateColumns = (count: number): any[] => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Array.from(Array(count).keys()).map(() => ({} as any))
}

const TableSkeleton = (
  props: Props & CommonPropsWithStyles<typeof styles>
): JSX.Element => {
  const {
    classes,
    rowCount = 3,
    columnCount = 3,
    columns = generateColumns(columnCount),
  } = props
  const rows = range(0, rowCount - 1, 1)
  // needed in order to allow overridden mui component to recognize `component` attribute
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const componentProps: any = { component: 'div' }

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      {rows.map((rowIndex: any) => (
        <TableRow
          className={clsx(classes.row)}
          key={`TableSkeletonRow-${rowIndex}`}
          {...componentProps}
        >
          {columns.map((column, i) => (
            <TableCell
              className={clsx(classes.cell)}
              component='div'
              variant='body'
              key={`TableSkeletonCell-${rowIndex}-${i}`}
            >
              <Skeleton
                variant={column.type === 'icon' ? 'circle' : 'text'}
                width={column.width ?? 100}
              />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  )
}

export default withStyles(styles, { name: 'MidmarkTableSkeleton' })(
  TableSkeleton
)
