import { ColumnSort } from '@tanstack/react-table'
import {
  AlertType,
  AssetSubType,
  AssetType,
  Device,
  Issue,
  IssueType,
  Location,
  Manufacturer,
  Role,
  StaffType,
  Unit,
} from '.'
import { FetchingState } from '../interfaces/fetchingState'
import { DeviceStatus } from './devices'

export interface GlobalFilters {
  users: UserFilters
  assets: AssetFilters
  staff: StaffFilters
  devices: DeviceFilters
  badgeTraffic: BadgeTrafficFilters
  search: SearchFilter
  customers: CustomerFilters
  alerts: AlertFilters
}

export interface Filters {
  userFilters: UserFilters[]
  assetFilters: AssetFilters[]
  staffFilters: StaffFilters[]
  deviceFilters: DeviceFilters[]
  customerFilters: CustomerFilters[]
  realTimeFilters: RealTimeFilters[]
}

export interface FilterModel {
  id: string
  filterTypeId: number
  name: string
  filterOptions: string
}

export enum FilterType {
  Asset,
  User,
  Staff,
  RealTime,
  Device,
}

export interface FilterCreateModel {
  filterTypeId: FilterType
  name: string
  filterOptions: string
  userGuid: string
}

export interface FilterDeleteModel {
  id: string
  userGuid: string
}

export type SearchFilter = {
  searchBy?: string
}

export interface FilterBase {
  filterName?: string
  filterGuid?: string
  filterType?: FilterType
}

export interface UserFilters extends FilterBase {
  selectedRoles: Role[]
}

export interface StaffFilters extends FilterBase {
  tagBatteryChangeDate?: Date
  isInUse?: boolean
  hasBadge?: boolean
  isWatchlist?: boolean
  columnSort?: ColumnSort[]
  batteryStatus?: StringObj[] // enum of battery status options
  signalAccuracy?: StringObj[] //signal confidence
  signalStatus?: StringObj[]
  signalType?: StringObj[]
  staffTypes?: StaffType[]
  assignedTo?: Unit[]
  reportsTo?: StringObj[]
}

export interface CustomerFilters {
  filterName?: string
  filterGuid?: string
  filterType?: FilterType
}

export interface BadgeTrafficFilters extends FilterBase {
  trackingId?: string
  sensorId?: string
  roomName?: string
  floorName?: string
  buildingName?: string
  columnSort?: ColumnSort[]
}

export interface StringObj {
  id: number
  name: string
  key?: string
}

export interface AssetFilters extends FilterBase {
  isInUse?: boolean
  hasBadge?: boolean
  hasRecall?: boolean
  hasWorkOrder?: boolean
  assetBatteryChangeDate?: Date
  assetTypes?: AssetType[]
  assetSubTypes?: AssetSubType[]
  manufacturers?: Manufacturer[]
  tagBatteryChangeDate?: Date
  models?: StringObj[]
  displayNames?: StringObj[]
  serialNumber?: string
  owners?: StringObj[]
  badgeNumber?: string
  columnSort?: ColumnSort[]
  isWatchlist?: boolean
  preventativeMaintenanceDate?: Date
  isPmThisMonth?: boolean | false
  assignedTo?: Unit[]
  signalAccuracy?: StringObj[] //signal confidence
  signalStatus?: StringObj[]
  signalType?: StringObj[]
  batteryStatus?: StringObj[] // enum of battery status options
  cmmsManaged?: boolean
  locationUnits?: string[]
}

export interface RealTimeFilters extends FilterBase {
  assetFilters: AssetFilters
  staffFilters: StaffFilters
}

export interface DeviceFilters extends FilterBase {
  locations?: Location[]
  deviceStatus?: DeviceStatus[]
  issueTypes?: IssueType[]
  createdDate?: Date
  resolveDate?: Date
  hideResolved?: boolean
}

export interface DeviceAndIssue extends Device, Issue {
  configurationStatus: string
  issueTypeName?: string
  issueCreatedDates?: Date[]
  issueResolveDates?: (Date | undefined)[]
  locationName: string
  view?: string | JSX.Element
  details?: string | JSX.Element
  actions?: string | JSX.Element
}

export interface AlertFilters extends FilterBase {
  columnSort?: ColumnSort[]
  alertTypes?: AlertType[]
}

export type UserFiltersOptions = keyof UserFilters
export type AssetFiltersOptions = keyof AssetFilters
export type StaffFiltersOptions = keyof StaffFilters

export type GlobalFiltersState = Readonly<GlobalFilters>
export type FiltersState = Readonly<FetchingState<Filters>>
