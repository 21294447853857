import { AlertSubscriptionKey } from '../models'
import { BaseService } from './baseService'

export class AlertSubscriptionKeysApi extends BaseService {
  constructor() {
    super({ entityType: 'alertSubscriptionKey' })
  }
  route = 'alert/SubscriptionKey'

  async getAlertSubscriptionKey(): Promise<AlertSubscriptionKey[]> {
    return await this.get<AlertSubscriptionKey[]>(this.route)
  }
}
