import { getType } from 'typesafe-actions'
import { TenantConfigurationAction } from '../actions'
import { setFetchingStatus } from '../utils'
import { FetchingStatus } from '../models/fetchingStatus'
import { TenantConfiguration, TenantConfigurationState } from '../models'
import { getCurrentTenantConfigurationAction } from '../actions/tenantConfiguration'

const initialState: TenantConfigurationState = {
  data: {} as any,
  status: undefined,
  lastRefreshTime: undefined,
}

export default (
  state: TenantConfigurationState = initialState,
  action: TenantConfigurationAction
): TenantConfigurationState => {
  switch (action.type) {
    case getType(getCurrentTenantConfigurationAction.success):
      return {
        data: action.payload,
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }

    case getType(getCurrentTenantConfigurationAction.request):
      return setFetchingStatus<TenantConfiguration>(
        state,
        FetchingStatus.Request
      )
    case getType(getCurrentTenantConfigurationAction.failure):
      return setFetchingStatus<TenantConfiguration>(
        state,
        FetchingStatus.Failure
      )

    default:
      return state
  }
}
