import { getType } from 'typesafe-actions'
import { MapGroupAction } from '../actions'
import {
  setSelectedGroupIdAction,
  setSelectedTrackingIdAction,
  updateGroupsAction,
} from '../actions/mapGroups'
import { MapGroupState } from '../models'

const initialState: MapGroupState = {
  groups: {},
  selectedTrackingId: undefined,
  selectedGroupId: undefined,
}

export default (
  state: MapGroupState = initialState,
  action: MapGroupAction
): MapGroupState => {
  switch (action.type) {
    case getType(setSelectedGroupIdAction):
      return {
        ...state,
        selectedGroupId: action.payload,
        selectedTrackingId: undefined, // only one entity can be selected at a time
      }
    case getType(setSelectedTrackingIdAction):
      return {
        ...state,
        selectedTrackingId: action.payload,
        selectedGroupId: undefined, // only one entity can be selected at a time
      }
    case getType(updateGroupsAction):
      state.groups = {
        ...state.groups,
        ...action.payload,
      }
      return { ...state }
    default:
      return state
  }
}
