import { getType } from 'typesafe-actions'
import { SubUnitAction } from '../actions'
import {
  getAllSubUnitsAction,
  getSubUnitAction,
  postSubUnitAction,
  deleteSubUnitAction,
  putSubUnitAction,
} from '../actions/subUnits'
import { SubUnit, SubUnits, SubUnitsState } from '../models'
import { FetchingStatus } from '../models/fetchingStatus'
import {
  mergeRecords,
  mergeRecord,
  deleteRecord,
  setFetchingStatus,
} from '../utils'

const initialState: SubUnitsState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

const key = 'id'
export default (
  state: SubUnitsState = initialState,
  action: SubUnitAction
): SubUnitsState => {
  switch (action.type) {
    case getType(getAllSubUnitsAction.success):
      return {
        data: mergeRecords<SubUnits, SubUnit, typeof key>(
          state.data,
          action.payload,
          key
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getSubUnitAction.success):
    case getType(postSubUnitAction.success):
    case getType(putSubUnitAction.success):
      return {
        data: mergeRecord<SubUnits, SubUnit, typeof key>(
          state.data,
          action.payload,
          key
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(deleteSubUnitAction.success):
      return {
        data: deleteRecord<SubUnits, SubUnit>(state.data, action.payload),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }

    case getType(getAllSubUnitsAction.request):
    case getType(getSubUnitAction.request):
    case getType(postSubUnitAction.request):
    case getType(putSubUnitAction.request):
    case getType(deleteSubUnitAction.request):
      return setFetchingStatus<SubUnits>(state, FetchingStatus.Request)
    case getType(getAllSubUnitsAction.failure):
    case getType(getSubUnitAction.failure):
    case getType(postSubUnitAction.failure):
    case getType(putSubUnitAction.failure):
    case getType(deleteSubUnitAction.failure):
      return setFetchingStatus<SubUnits>(state, FetchingStatus.Failure)

    default:
      return state
  }
}
