import { createAsyncAction } from 'typesafe-actions'
import {
  AgentEventView,
  AgentEventViewsByUnitsAndLocationRequest,
} from '../models'
import { HttpErrorResponse } from '../services/http'
import { PaginationResponse } from '../models/paginationResponse'

export const getAgentEventViewsByUnitsAndLocationAction = createAsyncAction(
  'GET_AGENT_EVENT_VIEWS_BY_UNIT_AND_LOCATION_REQUEST',
  'GET_AGENT_EVENT_VIEWS_BY_UNIT_AND_LOCATION_SUCCESS',
  'GET_AGENT_EVENT_VIEWS_BY_UNIT_AND_LOCATION_FAILURE'
)<
  AgentEventViewsByUnitsAndLocationRequest,
  PaginationResponse<AgentEventView[]>,
  HttpErrorResponse
>()
