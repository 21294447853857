import { AssetInstance, AssetKitInstance } from '../../models'
import {
  MapRender,
  SelectedAgent,
  SelectedGroup,
} from '../../models/realtimeMap'
import { calculateMapIconUpdatesForSelectingGroup } from '../calculateRTMapUpdates'
import { removeAgentConfidenceBubble, updateInpixonGroupIcon } from '../inpixon'

/**
 * After selecting a group on the map,
 * - open the Group Detail Drawer
 * - update the group icon for the selecting group to add highlighting
 * - remove confidence bubble from currently selected agent on the map (if any)
 * - remove highlighting from currently selected group on the map (if any)
 * @param inpixonAsset
 * @param group
 * @param assetKit
 * @param jibestreamController
 * @param openGroupDetailDrawer
 */
export function selectGroupOnMap(
  inpixonAsset: AssetInstance,
  group: MapRender,
  currentlySelectedAgentInpixonAsset: SelectedAgent | undefined,
  currentlySelectedGroupInpixonAsset: AssetInstance | undefined,
  assetKit: AssetKitInstance | undefined,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any,
  openGroupDetailDrawer: (group: SelectedGroup) => void
): void {
  if (!assetKit || !jibestreamController) {
    return
  }
  const prevSelectedIconUpdates = calculateMapIconUpdatesForSelectingGroup(
    inpixonAsset,
    currentlySelectedAgentInpixonAsset,
    currentlySelectedGroupInpixonAsset
  )
  openGroupDetailDrawer({
    groupTelemetries: group,
    inpixonAsset,
  })
  const {
    groupsIconToAddHighlight,
    groupsIconToRemoveHighlight,
    prevSelectedAgentTypeToRemoveConfidenceBubble,
  } = prevSelectedIconUpdates
  if (groupsIconToRemoveHighlight) {
    updateInpixonGroupIcon(
      assetKit,
      jibestreamController,
      groupsIconToRemoveHighlight.inpixonAssetType,
      groupsIconToRemoveHighlight.url
    )
  }
  if (prevSelectedAgentTypeToRemoveConfidenceBubble) {
    removeAgentConfidenceBubble(
      assetKit,
      prevSelectedAgentTypeToRemoveConfidenceBubble,
      jibestreamController
    )
  }
  if (groupsIconToAddHighlight) {
    updateInpixonGroupIcon(
      assetKit,
      jibestreamController,
      groupsIconToAddHighlight.inpixonAssetType,
      groupsIconToAddHighlight.url
    )
  }
}
