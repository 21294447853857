import { createAsyncAction } from 'typesafe-actions'
import { GeofenceExport } from '../models/geofenceExport'
import { LocationGeofence } from '../models/locationGeofence'

export const getAllGeofencesByLocation = createAsyncAction(
  'GET_ALL_GEOFENCES_BY_LOCATION_REQUEST',
  'GET_ALL_GEOFENCES_BY_LOCATION_SUCCESS',
  'GET_ALL_GEOFENCES_BY_LOCATION_FAILURE'
)<string, LocationGeofence[], Error>()

export const postAssociateGeofencesAction = createAsyncAction(
  'POST_ASSOCIATE_GEOFENCES_REQUEST',
  'POST_ASSOCIATE_GEOFENCES_SUCCESS',
  'POST_ASSOCIATE_GEOFENCES_FAILURE'
)<GeofenceExport, LocationGeofence[], Error>()
