import { createAsyncAction } from 'typesafe-actions'
import { Import, ImportCreate } from 'v2/models'
import { HttpErrorResponse } from '@midmarkrtls/common/services/http'

export const getAllBadgesImportsAction = createAsyncAction(
  'GET_ALL_IMPORTS_BADGES_REQUEST',
  'GET_ALL_IMPORTS_BADGES_SUCCESS',
  'GET_ALL_IMPORTS_BADGES_FAILURE'
)<void, Import[], HttpErrorResponse>()

export const postImportBadgesAction = createAsyncAction(
  'POST_IMPORT_BADGES_REQUEST',
  'POST_IMPORT_BADGES_SUCCESS',
  'POST_IMPORT_BADGES_FAILURE'
)<ImportCreate, Import, HttpErrorResponse>()
