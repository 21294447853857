import { createAsyncAction } from 'typesafe-actions'
import { MapReferenceView } from '../models'
import { SensoryReference } from '../models/SensoryReference'

export const getAllSensoryReferenceByTenantIdAction = createAsyncAction(
  'GET_ALL_SENSORYREFERENCE_BY_TENANT_REQUEST',
  'GET_ALL_SENSORYREFERENCE_BY_TENANT_SUCCESS',
  'GET_ALL_SENSORYREFERENCE_BY_TENANT_FAILURE'
)<void, SensoryReference[], Error>()

export const postImportSensoryReferenceAction = createAsyncAction(
  'POST_IMPORT_SENSORYREFERENCE_REQUEST',
  'POST_IMPORT_SENSORYREFERENCE_SUCCESS',
  'POST_IMPORT_SENSORYREFERENCE_FAILURE'
)<MapReferenceView[], SensoryReference[], Error>()
