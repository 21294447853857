import { TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import {
  deleteUnitAction,
  postUnitAction,
  putUnitAction,
} from '../../../actions/units'
import {
  ActionConfirmationButton,
  Button,
  SaveButton,
} from '../../../components/Buttons'
import ActionConfirmationMessage from '../../../components/Common/ActionConfirmationMessage'
import { CreateOrEditModalProps } from '../../../components/Common/HOC'
import { requiredMessage } from '../../../components/Forms'
import { ConfirmationFormInput } from '../../../components/Forms/ActionConfirmationForm'
import { FlexBox } from '../../../components/Layout'
import {
  useDeleteSuccessFailureUtility,
  useSaveSuccessFailureUtility,
} from '../../../hooks'
import { FetchingStatus, Unit, UnitsState } from '../../../models'
import { colors, useFormStyle } from '../../../styles'

const UnitManagementForm: React.ComponentType<
  CreateOrEditModalProps<Unit | null>
> = (props: CreateOrEditModalProps<Unit | null>) => {
  const { data: selectedUnit, handleClose } = props

  const { data: units, status: unitStatus } = useSelector(
    ({ units }: { units: UnitsState }) => units
  )

  const dispatch = useDispatch()

  const classes = useFormStyle()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Unit>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: selectedUnit ? selectedUnit.name : '',
    } as Unit,
  })

  const onSubmit = (formData: Unit) => {
    const updatedUnit: Unit = {
      ...selectedUnit,
      ...formData,
    }
    setIsSaving(true)
    if (showSaveError) {
      setShowSaveError(false)
    }
    if (showDeleteError) {
      setShowDeleteError(false)
    }
    if (updatedUnit.id) {
      dispatch(putUnitAction.request(updatedUnit))
    } else {
      dispatch(postUnitAction.request(updatedUnit))
    }
  }

  const {
    isSaving,
    setIsSaving,
    showError: showSaveError,
    setShowError: setShowSaveError,
  } = useSaveSuccessFailureUtility(
    unitStatus === FetchingStatus.Success,
    unitStatus === FetchingStatus.Failure,
    `Unit was successfully ${selectedUnit ? 'updated.' : 'created.'}`,
    `There was an error saving Unit information, please try again. If the problem persists, please contact support for assistance.`,
    handleClose
  )

  const {
    isDeleting,
    setIsDeleting,
    showError: showDeleteError,
    setShowError: setShowDeleteError,
  } = useDeleteSuccessFailureUtility(
    unitStatus === FetchingStatus.Success,
    unitStatus === FetchingStatus.Failure,
    `Unit was successfully deleted.`,
    `There was an error deleting Unit information, please try again. If the problem persists, please contact support for assistance.`,
    handleClose
  )

  const confirmationInput: ConfirmationFormInput = {
    inputLabel: 'Confirm Unit Deletion',
    keyword: 'delete',
  }

  const deleteConfirmationModalContent = (
    <ActionConfirmationMessage
      primaryMessage={
        <Typography variant='body1'>
          Are you sure you want to delete <b>{selectedUnit?.name}</b>?
        </Typography>
      }
      additionalMessage={
        <Typography variant='body1'>
          This will delete <b>{selectedUnit?.name}</b> and unassign this unit
          from all locations, staff, and assets. This action cannot be undone.
        </Typography>
      }
    />
  )

  const handleDelete = (): void => {
    if (selectedUnit && selectedUnit.id) {
      setIsDeleting(true)
      dispatch(deleteUnitAction.request(selectedUnit.id))
    }
  }

  const validateUniqueUnitName = (name: string) => {
    return Object.values(units).some(
      (x) =>
        x.name.toLowerCase().trim() === name.toLowerCase().trim() &&
        (!selectedUnit || selectedUnit.id !== x.id)
    )
      ? 'Name must be unique'
      : true
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography style={{ fontWeight: 'bold' }}>UNIT INFORMATION</Typography>
      <Controller
        name='name'
        control={control}
        rules={{
          required: true,
          maxLength: {
            value: 50,
            message: 'Unit name must be between 1 and 50 characters',
          },
          validate: validateUniqueUnitName,
        }}
        render={({ field }) => {
          return (
            <TextField
              {...field}
              margin='normal'
              id='unitEditName'
              label='Unit Name'
              variant='filled'
              fullWidth
              required
              error={!!errors.name}
              helperText={
                errors.name?.message ? errors.name?.message : requiredMessage
              }
              className={classes.inputStyle}
            />
          )
        }}
      />
      {selectedUnit && (
        <Typography variant='body2' style={{ paddingBottom: 10 }}>
          *This change will affect all assigned locations, assets, and staff.
        </Typography>
      )}

      {showSaveError && (
        <Typography
          style={{
            color: colors.error,
            paddingBottom: 10,
          }}
        >
          There was an error saving Unit information, please try again. If the
          problem persists, contact support.
        </Typography>
      )}

      {showDeleteError && (
        <Typography
          style={{
            color: colors.error,
            paddingBottom: 10,
          }}
        >
          There was an error deleting Unit information, please try again. If the
          problem persists, contact support.
        </Typography>
      )}

      <FlexBox
        layout='row'
        layoutAlign={{
          main: 'space-between',
        }}
      >
        {selectedUnit && selectedUnit.id ? (
          <ActionConfirmationButton
            openConfirmationButtonLabel='DELETE'
            disableConfirmation={false}
            loading={isSaving || isDeleting}
            confirmationModalTitle={`Delete Unit: ${selectedUnit?.name}`}
            confirmationModalContent={deleteConfirmationModalContent}
            confirmationInput={confirmationInput}
            onContinue={handleDelete}
            bgColor={colors.error}
            textColor={colors.white}
            disableEnterButtonFormSubmission={true}
          />
        ) : (
          <></>
        )}
        <FlexBox
          flex={{
            grow: 1,
          }}
        />
        <Button
          id='unit-edit-cancel-button'
          onClick={handleClose}
          styleOverride={{ wrapper: { margin: 0 } }}
          bgColor={colors.transparent}
          textColor={colors.error}
          loading={isSaving || isDeleting}
        >
          Cancel
        </Button>
        <SaveButton
          loading={isSaving || isDeleting}
          id='unit-edit-save-button'
          disabled={Object.values(errors).length > 0}
        >
          Save
        </SaveButton>
      </FlexBox>
    </form>
  )
}

export default UnitManagementForm
