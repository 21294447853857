import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { FiltersAction } from '../actions'
import {
  deleteFiltersAction,
  getFiltersAction,
  postFiltersAction,
} from '../actions/filters'
import { UsersApi } from '../middleware'
import { FiltersState } from '../models/filters'

type FiltersEpic = Epic<
  FiltersAction,
  FiltersAction,
  FiltersState,
  { usersApi: UsersApi }
>

export const getFiltersEpic: FiltersEpic = (action$, state$, { usersApi }) => {
  return action$.pipe(
    filter(isActionOf(getFiltersAction.request)),
    mergeMap((action) =>
      from(usersApi.getUserFiltersById(action.payload)).pipe(
        map((payload) => getFiltersAction.success(payload)),
        catchError((error) => of(getFiltersAction.failure(error)))
      )
    )
  )
}

export const deleteFiltersEpic: FiltersEpic = (
  action$,
  state$,
  { usersApi }
) => {
  return action$.pipe(
    filter(isActionOf(deleteFiltersAction.request)),
    mergeMap((action) =>
      from(usersApi.deleteUserFilterById(action.payload)).pipe(
        map((payload) => deleteFiltersAction.success(action.payload)),
        catchError((error) => of(deleteFiltersAction.failure(error)))
      )
    )
  )
}

export const postFiltersEpic: FiltersEpic = (action$, state$, { usersApi }) => {
  return action$.pipe(
    filter(isActionOf(postFiltersAction.request)),
    mergeMap((action) =>
      from(usersApi.postUserFiltersById(action.payload)).pipe(
        map((payload) => postFiltersAction.success(payload)),
        catchError((error) => of(postFiltersAction.failure(error)))
      )
    )
  )
}
