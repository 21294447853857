import { BaseService } from './baseService'
import { SubUnit } from '../models'

export class SubUnitsApi extends BaseService {
  constructor() {
    super({ entityType: 'subunits' })
  }
  route = 'subunits'

  async getAllSubUnits(): Promise<SubUnit[]> {
    return await this.get<SubUnit[]>(this.route)
  }
  async getSubUnit(id: string): Promise<SubUnit> {
    return await this.get<SubUnit>(`${this.route}/${id}`)
  }
  async postSubUnit(subUnit: SubUnit): Promise<SubUnit> {
    return await this.post<SubUnit>(this.route, subUnit)
  }
  async putSubUnit(subUnit: SubUnit): Promise<SubUnit> {
    return await this.put<SubUnit>(this.route, subUnit)
  }
  async deleteSubUnit(id: string): Promise<string> {
    return await this.delete(`${this.route}/${id}`)
  }
}
