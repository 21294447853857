import {
  AgentEventStaffEventEpicMapper,
  AgentEventLocation,
  BaseBadgeTelemetryMessage,
  EventStatus,
  Staff,
  StaffEvent,
  StaffEvents,
  StaffMembers,
  TelemetryEventLocation,
  EventActionType,
} from '../models'
import { getBadgeState } from './inpixon/badgeState'

export const staffDuressEnabled = (
  badgeId: string,
  duressNotifications?: StaffEvents
): boolean => {
  return (
    Object.values(duressNotifications || []).filter(
      (x) => x.badgeId === badgeId
    ).length > 0
  )
}

export const staffDuressResolved = (
  badgeId: string,
  duressNotifications?: StaffEvents
) => {
  return (
    Object.values(duressNotifications || []).filter(
      (x) => x.badgeId === badgeId && x.resolved === true
    ).length > 0
  )
}

export const staffAssistEnabled = (
  badgeId: string,
  assistNotifications?: StaffEvents
) => {
  return (
    Object.values(assistNotifications || []).filter(
      (x) => x.badgeId === badgeId
    ).length > 0
  )
}

export const staffAssistResolved = (
  badgeId: string,
  assistNotifications?: StaffEvents
) => {
  return (
    Object.values(assistNotifications || []).filter(
      (x) => x.badgeId === badgeId && x.resolved === true
    ).length > 0
  )
}

export function getDuressInGeoFence(
  geoFenceId: number,
  staff?: StaffMembers,
  duressNotifications?: StaffEvents
): boolean {
  const badgeState = getBadgeState()
  let filteredStaffWithBadge: Staff[] = []

  const geoFenceAgents = Object.values(badgeState)?.filter(
    (x: BaseBadgeTelemetryMessage) => x.geoFenceId === geoFenceId
  )
  if (staff) {
    filteredStaffWithBadge = Object.values(staff).filter((staff) =>
      Boolean(staff.badgeIds?.length)
    )

    return (
      filteredStaffWithBadge.filter(
        (staff) =>
          staffDuressEnabled(staff.badgeIds[0], duressNotifications) === true &&
          !staffDuressResolved(staff.badgeIds[0], duressNotifications) &&
          geoFenceAgents.filter((item) => item.trackingId === staff.badgeIds[0])
            .length > 0
      ).length > 0
    )
  } else {
    return false
  }
}

export function getAssistInGeoFence(
  geoFenceId: number,
  staff?: StaffMembers,
  assistNotifications?: StaffEvents
): boolean {
  const badgeState = getBadgeState()
  let filteredStaffWithBadge: Staff[] = []

  const geoFenceAgents = Object.values(badgeState)?.filter(
    (x: BaseBadgeTelemetryMessage) => x.geoFenceId === geoFenceId
  )
  if (staff) {
    filteredStaffWithBadge = Object.values(staff).filter((staff) =>
      Boolean(staff.badgeIds?.length)
    )

    return (
      filteredStaffWithBadge.filter(
        (staff) =>
          staffAssistEnabled(staff.badgeIds[0], assistNotifications) === true &&
          !staffAssistResolved(staff.badgeIds[0], assistNotifications) &&
          geoFenceAgents.filter((item) => item.trackingId === staff.badgeIds[0])
            .length > 0
      ).length > 0
    )
  } else {
    return false
  }
}

export function displayRoomName(duress = false, isPrivate = false): boolean {
  if (duress) {
    return false
  } else if (isPrivate) {
    return true
  }
  return false
}

export function mapAgentEventLocationToStaffEvent(
  agentEvent: AgentEventLocation
): AgentEventStaffEventEpicMapper {
  return {
    ...agentEvent,
    status: EventStatus.ACTIVE,
    resolved: false,
    floorName: agentEvent.badgeEventTelemetry?.floorName ?? 'Unknown Floor',
    floorLocationId: agentEvent.badgeEventTelemetry?.floorId ?? '',
    roomName: agentEvent.badgeEventTelemetry?.roomName ?? 'Unknown Room',
    reportedTime: agentEvent.agentEventCreatedDate,
    reportedBy: agentEvent.displayName,
    badgeId: agentEvent.badgeIds[0],
    id: agentEvent.id,
    agentId: agentEvent.agentId,
    agentEventTypeId: agentEvent.agentEventTypeId,
    alertId: agentEvent.alertId,
  }
}

export function mapAgentEventsLocationToStaffEvents(
  agentEvents: AgentEventLocation[]
): AgentEventStaffEventEpicMapper[] {
  if (agentEvents.length > 0) {
    let lastKnownEvents: AgentEventStaffEventEpicMapper[] = []

    agentEvents.map((x) => {
      lastKnownEvents.push(mapAgentEventLocationToStaffEvent(x))
    })
    return lastKnownEvents
  }

  return []
}

export function mapLiveEventsTelemetryToStaffEvents(
  liveEventTelemetry: TelemetryEventLocation
): StaffEvent {
  if (
    liveEventTelemetry.eventActionType === EventActionType.Resolve ||
    liveEventTelemetry.eventActionType === EventActionType.AutomaticResolve
  ) {
    return {
      status: EventStatus.RESOLVED,
      resolved: true,
      floorName: liveEventTelemetry.data.parentLocationName ?? 'Unknown Floor',
      floorLocationId: liveEventTelemetry.data.parentLocationId ?? '',
      roomName: liveEventTelemetry.data.locationName ?? 'Unknown Room',
      reportedTime: new Date(liveEventTelemetry.data.agentEventCreatedDate),
      reportedBy: liveEventTelemetry.data.displayName,
      badgeId: liveEventTelemetry.data.trackingId,
      id: liveEventTelemetry.data.agentEventId,
      agentId: liveEventTelemetry.data.agentId,
      agentEventTypeId: liveEventTelemetry.data.agentEventTypeId,
      alertId: liveEventTelemetry.data.alertId,
    }
  }

  return {
    status: EventStatus.ACTIVE,
    resolved: false,
    floorName: liveEventTelemetry.data.parentLocationName ?? 'Unknown Floor',
    floorLocationId: liveEventTelemetry.data.parentLocationId ?? '',
    roomName: liveEventTelemetry.data.locationName ?? 'Unknown Room',
    reportedTime: new Date(liveEventTelemetry.data.agentEventCreatedDate),
    reportedBy: liveEventTelemetry.data.displayName,
    badgeId: liveEventTelemetry.data.trackingId,
    id: liveEventTelemetry.data.agentEventId,
    agentId: liveEventTelemetry.data.agentId,
    agentEventTypeId: liveEventTelemetry.data.agentEventTypeId,
    alertId: liveEventTelemetry.data.alertId,
  }
}

export const sortEventsByOldestFirst = (
  staffEvents: AgentEventStaffEventEpicMapper[] | StaffEvent[]
) => {
  let sortedEvents = staffEvents.sort((e1, e2) => {
    if (e1.reportedTime && e2.reportedTime) {
      return e1.reportedTime.getTime() - e2.reportedTime.getTime()
    } else {
      return -1
    }
  })

  return sortedEvents
}

export const sortEventsByMostRecent = (
  staffEvents: AgentEventStaffEventEpicMapper[] | StaffEvent[]
) => {
  let sortedEvents = staffEvents.sort((e1, e2) => {
    if (e1.reportedTime && e2.reportedTime) {
      return e2.reportedTime.getTime() - e1.reportedTime.getTime()
    } else {
      return -1
    }
  })

  return sortedEvents
}

export const hasActiveAgentEvent = (
  badgeId: string,
  agentEvents: StaffEvent[]
): boolean => {
  return (
    agentEvents.filter((x) => x.badgeId === badgeId && !x.resolved).length > 0
  )
}

export const hasResolvedDuressOrAssistEvent = (
  badgeId: string,
  duressNotifications: StaffEvent[]
): boolean => {
  return (
    duressNotifications.filter((x) => x.badgeId === badgeId && x.resolved)
      .length > 0
  )
}

export function hasStaffDuress(
  badgeId: string,
  activeStaffDuressEvents: StaffEvent[]
): boolean {
  return activeStaffDuressEvents.findIndex((e) => e.badgeId === badgeId) > -1
}

export function hasStaffAssist(
  badgeId: string,
  activeStaffAssistEvents: StaffEvent[]
): boolean {
  return activeStaffAssistEvents.findIndex((e) => e.badgeId === badgeId) > -1
}
