import React from 'react'
import { User } from '../../models'
import DetailsPaneHeader from '../Drawer/DetailsPaneHeader'
import UserQuickActionButtons from './UserQuickActionButtons'

interface Props {
  user: User
  onClose: () => void
  onEditUser: () => void
}

function UserDetailsPaneHeader(props: Props): React.ReactElement {
  const { user, onEditUser, onClose } = props

  return (
    <div>
      <DetailsPaneHeader handleClose={onClose} headerText={user.displayName} />
      <UserQuickActionButtons onEditUser={onEditUser} />
    </div>
  )
}

export default UserDetailsPaneHeader
