import { getType } from 'typesafe-actions'
import { TenantTagNumbersAction } from '../actions'
import { FetchingStatus } from '@midmarkrtls/common/models/fetchingStatus'
import { TenantTagNumbersState } from 'v2/models'
import { getTenantTagNumbersAction } from 'v2/actions/tenantTagNumbers'

const initialState: TenantTagNumbersState = {
  data: {
    tenants: 0,
    tags: 0,
    edgeConnections: 0,
  },
  status: undefined,
  lastRefreshTime: undefined,
}

const tenantTagNumbersReducer = (
  state: TenantTagNumbersState = initialState,
  action: TenantTagNumbersAction
): TenantTagNumbersState => {
  switch (action.type) {
    case getType(getTenantTagNumbersAction.success):
      return {
        data: action.payload,
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getTenantTagNumbersAction.request):
      return {
        ...state,
        status: FetchingStatus.Request,
      }

    case getType(getTenantTagNumbersAction.failure):
      return {
        ...state,
        status: FetchingStatus.Failure,
      }

    default:
      return state
  }
}

export default tenantTagNumbersReducer
