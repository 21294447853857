import { User } from '../models'
import { useSearchAndFilters, useUserFiltersState } from './filters'

export default function useUserFilters(users: User[]): User[] {
  const { filter } = useUserFiltersState()

  const filteredUsers = useSearchAndFilters(users, filter.criteria)

  return filteredUsers || []
}
