import { renderCurrentMapViewControl } from '.'
import {
  AssetKitInstance,
  BadgeSignalStatus,
  IconType,
  InpixonAssetConfig as DeprecatedInpixonAssetConfig,
  DeviceType,
  deviceTypeIdToEnum,
  AssetInstance,
} from '../../models'
import { InpixonAssetConfig } from '../../models/inpixonMap'
import { colors } from '../../styles/MidmarkTheme'
import { getIcon } from './icons'
import jmap from 'jmap.js'

const duressGroupColor = colors.duressConfidenceBubble

export interface AssetConfigOptions {
  assetID: number
  name: string
  deviceTypeId?: number
  coordinates: [number, number]
  type: IconType
  height: number
  showIcon: boolean
  isConfigured: boolean
  isLowBattery: boolean
  hasIssues: boolean
  width?: number //Set to override dynamic width
  image?: string
  iconScale: number
  map?: unknown
  pulseColor?: string
  pulseVisible?: boolean
  confidenceMax?: number
  confidencePercent?: number
  confidenceColor?: string
  confidenceAlpha?: number
  snapToGrid?: boolean
  isDuress?: boolean
  isAssist?: boolean
  isSelected?: boolean
}

/**
 * @deprecated
 * @param text
 * @returns
 */
const preserveWhiteSpace = (text: string): string => {
  return text ? text.replace(/ /g, '\u00a0') : text
}

export const deviceToIcon = (deviceTypeId: number): string => {
  const deviceType = deviceTypeIdToEnum(deviceTypeId)
  switch (deviceType) {
    case DeviceType.IR:
      return 'icons/DeviceIrWiredUnconfigured.svg'
    case DeviceType.RF:
      return 'icons/DeviceRfWiredUnconfigured.svg'
    case DeviceType['Badge Cabinet']:
      return 'icons/DeviceIrWiredUnconfigured.svg'
    case DeviceType['Drop Box']:
      return 'icons/DeviceDropBoxWiredUnconfigured.svg'
    case DeviceType.BleSensor:
      return 'icons/DeviceBleSensorWirelessUnconfigured.svg'
    case DeviceType.Gateway:
      return 'icons/DeviceGatewayWirelessUnconfigured.svg'
    default:
      return 'icons/DeviceIrWiredUnconfigured.svg'
  }
}

export const getSvgLength = (text: string, assetType: IconType): number => {
  switch (assetType) {
    case IconType.Group:
      return 10
    default:
      return 56
  }
}

/**
 *
 * @param text @deprecated
 * @returns
 */
//Pad Assets image text for consistent width
export const padName = (text: string): string => {
  const maxStringLength = 13

  if (text.length === maxStringLength) {
    return text
  } else if (text.length > maxStringLength) {
    return text.substring(0, 12) + '..'
  } else {
    const padLength = maxStringLength - text.length
    return text.padEnd(padLength, ' ')
  }
}

/**
 * @deprecated
 * @param text
 * @returns
 */
export const padSensorName = (text: string): string => {
  const maxStringLength = 5
  if (text.length === maxStringLength) {
    return text
  } else {
    return text.padStart(maxStringLength, ' ')
  }
}

/**
 *
 * @param text @deprecated
 * @returns
 */
// Tim Groven - 6/16/2021 - 11725 - "#" and "&" characters in the Display Name cut off the remaining text for non grouped assets on the map page.
// Make sure we encode special characters for the URL being called to create the Asset Icon
export const uriEncode = (text: string): string => {
  return encodeURIComponent(text)
}

/**
 * @deprecated
 * @param text
 * @param type
 * @returns
 */
// Tim Groven - 6/16/2021 - 11725 - "#" and "&" characters in the Display Name cut off the remaining text for non grouped assets on the map page.
// Created as a wrapper for all the changes that need to be made
// to the Asset Name before passing to the Map Icon Generator
export const prepIconName = (text: string, type: IconType): string => {
  if (type === IconType.Asset || type === IconType.Staff) {
    text = padName(text)
    text = uriEncode(text)
  } else if (type === IconType.Sensor) {
    text = padSensorName(text)
    text = uriEncode(text)
  }
  return text
}

/**
 * @deprecated
 * @param assetKit @deprecated
 * @param options
 * @param signalStatus
 */
export const createAsset = (
  assetKit: AssetKitInstance,
  options: AssetConfigOptions,
  signalStatus?: BadgeSignalStatus
): void => {
  const config: DeprecatedInpixonAssetConfig = {
    position: options.coordinates,
    width: options.width ?? getSvgLength(padName(options.name), options.type), // options.width,
    height: options.height,
    id: options.assetID,
    name: options.assetID.toString(),
    url: buildIconURL(
      options,
      signalStatus,
      options.isDuress,
      options.isAssist
    ),
    scaleWithMap: true,
    rotateWithMap: false,
    confidenceAlpha: 0,
    type: `${options.type.toString()}_${options.assetID}`,
    iconColor: '#ffffff',
    confidenceColor: options.confidenceColor,
    confidenceMax: options.confidenceMax ?? 3,
    confidencePercent: options.confidencePercent,
    snapToGrid: options.snapToGrid ?? true,
    isDuress: options.isDuress ?? false,
    isAssist: options.isAssist ?? false,
    pulseColor: options.pulseColor ?? '#ffffff',
  }
  assetKit.createAsset(config)
}

/**
 * @deprecated
 */
export function buildCDNPath(
  image: string | undefined,
  name: string,
  scale: number,
  isLowBattery: boolean,
  hasIssues: boolean,
  type: IconType,
  signalStatus?: BadgeSignalStatus,
  isDuress = false,
  isAssist = false,
  selected = false
) {
  return `${
    process.env.REACT_APP_MAPICON_GENERATOR
  }?path=${image}&name=${prepIconName(
    name,
    type
  )}&lowBattery=${isLowBattery}&hasIssues=${hasIssues}&iconScale=${scale}&agentType=${type}${
    signalStatus ? `&signalStatus=${signalStatus.replace('-', '')}` : ''
  }&code=${
    process.env.REACT_APP_MAPICON_KEY
  }&isDuress=${isDuress}&isAssist=${isAssist}&selected=${selected}`
}

/**
 * @deprecated
 */
export function buildIconURL(
  options: AssetConfigOptions,
  signalStatus?: BadgeSignalStatus,
  isDuress = false,
  isAssist = false
) {
  switch (options.type) {
    case IconType.Location:
      return getIcon(
        options.type,
        padName(options.name),
        options.isLowBattery,
        options.deviceTypeId,
        options.isConfigured,
        options.hasIssues,
        options.image,
        isDuress
          ? duressGroupColor
          : isAssist
          ? colors.assistGroupColor
          : undefined
      )
    case IconType.Sensor:
      return buildCDNPath(
        deviceToIcon(options.deviceTypeId ?? 0),
        options.name,
        options.iconScale,
        false,
        options.hasIssues,
        options.type,
        signalStatus,
        isDuress,
        isAssist,
        options.isSelected
      )
    case IconType.Group:
      return buildCDNPath(
        Number.parseInt(options.name) > 8
          ? 'icons/GroupOver9.svg'
          : 'icons/Group.svg',
        options.name,
        options.iconScale,
        false,
        false,
        options.type,
        signalStatus,
        isDuress,
        isAssist,
        options.isSelected
      )
    case IconType.Staff:
    case IconType.Asset:
    default:
      return buildCDNPath(
        options.image,
        options.name,
        options.iconScale,
        options.isLowBattery,
        options.hasIssues,
        options.type,
        signalStatus,
        isDuress,
        isAssist
      )
  }
}

/**
 * @deprecated
 */
export const generateAssetConfig = (
  options: AssetConfigOptions,
  signalStatus?: BadgeSignalStatus
): DeprecatedInpixonAssetConfig => {
  options.name = preserveWhiteSpace(options.name)

  const config = {
    position: options.coordinates,
    width: options.width ?? getSvgLength(padName(options.name), options.type),
    height: options.height,
    id: options.assetID,
    name: options.name,
    url: buildIconURL(
      options,
      signalStatus,
      options.isDuress,
      options.isAssist
    ),
    scaleWithMap: true,
    rotateWithMap: false,
    confidenceAlpha: options.confidenceAlpha,
    type: `${options.type.toString()}_${options.assetID}`,
    iconColor: '#ffffff',
    pulseColor: '#ffffff',
    confidenceColor: options.confidenceColor,
    confidenceMax: options.confidenceMax ?? 3,
    confidencePercent: options.confidencePercent,
    snapToGrid: options.snapToGrid ?? true,
    isDuress: options.isDuress ?? false,
    isAssist: options.isAssist ?? false,
  }

  //Used to change devices to configured if Applicable
  if (options.isConfigured === true) {
    config.url = config.url.replace('Unconfigured', 'Configured')
  } else {
  }

  if (options.map) {
    return {
      map: options.map,
      ...config,
    }
  }

  return config
}

/**
 * @deprecated
 */
export function createAssets(
  assetKit: AssetKitInstance,
  assetConfigs: DeprecatedInpixonAssetConfig[]
) {
  try {
    assetConfigs.forEach(function (item) {
      if (item.isDuress) {
        item.confidenceColor = colors.duressBubble
        item.confidenceAlpha = 0.3
        item.pulseVisible = true
      } else if (item.isAssist) {
        item.confidenceColor = colors.assistConfidenceBubble
        item.confidenceAlpha = 0.3
        item.pulseVisible = true
      }
      return item
    })
    assetKit.createAsset(assetConfigs)
  } catch (error) {
    console.log('Error Creating Assets')
    console.error(error)
  }
}

/**
 * @deprecated
 */
export function getIconScale(mmPerPixel: number): number {
  // scaleValue: The baseline value for 100% scale is 183.6952515 pixels.
  // As defined by Nathan Loding in PBI 11264 (https://versuscarina.visualstudio.com/Carina/_workitems/edit/11264)
  const scaleValue = 183.6952515
  const iconScale = (100.0 * mmPerPixel) / scaleValue

  return iconScale
}

export function createInpixonAssets(
  assetKit: AssetKitInstance,
  assetConfigs: InpixonAssetConfig[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  jibestreamController: any
): void {
  try {
    const assets = assetKit.createAsset(assetConfigs)
    // This is hacky workaround to address the issue with delay render
    // on the map when a new asset is created. Ticket #18366 opened with Inpixon for this issue
    assets.forEach((asset: AssetInstance) => {
      const fullMapPoint = {
        x: asset.position[0],
        y: asset.position[1],
        mapId: assetKit._control.currentMapView.id,
      }
      const animation = new jmap.Animation({
        duration: 1,
      })
      assetKit.updateAssetWithPosition(asset, fullMapPoint, animation)
    })
    renderCurrentMapViewControl(jibestreamController)
  } catch (error) {
    console.log('Error Creating Assets')
    console.error(error)
  }
}
