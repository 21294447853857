import { AssetKitInstance } from '../../models'
import { InpixonAssetAgentPositionUpdate } from '../../models/inpixonMap'
import { BadgeTelemetryMessageWithAgentEvent } from '../../models/telemetry'
import {
  calculateAssetPositionCoords,
  updateInpixonAssetPosition,
} from '../inpixon'
import { groupHasActiveDuress } from '../telemetry'

/**
 * Move agent to the new geofence
 */
export function moveAgentToNewGeofence(
  leavingAgentToUpdatePosition: InpixonAssetAgentPositionUpdate,
  assetKit: AssetKitInstance,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  geofenceInstances: any[],
  useGeofenceCentroid: boolean,
  addGeofenceHighlight: (geofenceId: number) => void,
  removeGeofenceHighlight: (geofenceId: number) => void,
  leavingGroupHasActiveDuress: boolean
): void {
  const positionCoords = calculateAssetPositionCoords(
    jibestreamController,
    geofenceInstances,
    leavingAgentToUpdatePosition.lat,
    leavingAgentToUpdatePosition.lon,
    leavingAgentToUpdatePosition.mapId,
    useGeofenceCentroid
  )
  if (
    leavingAgentToUpdatePosition.hasActiveDuress &&
    !leavingGroupHasActiveDuress
  ) {
    removeGeofenceHighlight(leavingAgentToUpdatePosition.leavingGeofenceId)
  }
  updateInpixonAssetPosition(
    {
      trackingId: leavingAgentToUpdatePosition.trackingId,
      position: positionCoords,
      iconType: leavingAgentToUpdatePosition.iconType,
    },
    assetKit,
    jibestreamController
  )
  if (leavingAgentToUpdatePosition.hasActiveDuress) {
    addGeofenceHighlight(leavingAgentToUpdatePosition.geoFenceId)
  }
}
