import { BaseService } from '@midmarkrtls/common/middleware/baseService'
import { TenantTagNumbers } from 'v2/models'

export class TenantTagNumbersApi extends BaseService {
  constructor() {
    super({ entityType: 'tenantTagNumbers' })
  }
  route = 'operationsdevtools/tenantnumbers'

  async getTenantTagNumbers(): Promise<TenantTagNumbers> {
    return await this.get<TenantTagNumbers>(this.route)
  }
}

export const tenantTagNumbersApi = new TenantTagNumbersApi()
