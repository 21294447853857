import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PayloadActionCreator } from 'typesafe-actions'
import { FilterStateManager } from '../../filters'

const useFilterState = <TState, TAction extends string, TStateContainer>(
  selector: (state: TStateContainer) => TState,
  actionCreator: PayloadActionCreator<TAction, TState>
): FilterStateManager<TState> => {
  const dispatch = useDispatch()
  const filters = useSelector(selector)

  const setFilters = useCallback(
    (filters: TState) => {
      dispatch(actionCreator(filters))
    },
    [actionCreator, dispatch]
  )

  const mergeFilters = useCallback(
    (updates: Partial<TState>) => {
      setFilters({
        ...filters,
        ...updates,
      })
    },
    [filters, setFilters]
  )

  const clearFilters = useCallback(
    (defaults?: Partial<TState>) => {
      setFilters({
        ...defaults,
      } as TState)
    },
    [setFilters]
  )

  return {
    filterState: filters,
    updateFilterState: mergeFilters,
    setFilterState: setFilters,
    clearFilterState: clearFilters,
  }
}

export default useFilterState
