import { getType } from 'typesafe-actions'
import { JibestreamDestinationAction } from '../actions'
import {
  getDestinationsByVenueAction,
  updateDestinationAction,
} from '../actions/destination'
import {
  JibestreamDestination,
  JibestreamDestinations,
} from '../models/destination'

export type JibestreamDestinationState = Readonly<JibestreamDestinations>

const jibestreamDestinationReducer = (
  state: JibestreamDestinationState = {},
  action: JibestreamDestinationAction
): JibestreamDestinationState => {
  switch (action.type) {
    case getType(getDestinationsByVenueAction.success):
      return mergeJibestreamDestination(state, action.payload)

    case getType(updateDestinationAction.success):
      return mergeJibestreamDestination(state, {
        [action.payload.id]: action.payload,
      })
    default:
      return state
  }
}

const mergeJibestreamDestination = (
  state: JibestreamDestinationState,
  destinations: JibestreamDestinations
): JibestreamDestinationState => {
  return Object.values(destinations).reduce(
    (
      acc: Record<number, JibestreamDestination>,
      destination: JibestreamDestination
    ) => {
      return {
        ...acc,
        [destination.id]: destination,
      }
    },
    { ...state }
  )
}

export default jibestreamDestinationReducer
