import { Epic } from 'redux-observable'
import { from } from 'rxjs'
import { filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { StaffEventsAction } from '../actions'
import {
  fetchLiveStaffEventsTelemetrySuccess,
  updateTelemetryStaffEvent,
} from '../actions/staffEvents'
import { mapLiveEventsTelemetryToStaffEvents } from '../helpers'
import { StaffEventsState } from '../models'

type LiveEventsTelemetryEpic = Epic<
  StaffEventsAction,
  StaffEventsAction,
  StaffEventsState
>

export const fetchLiveEventsTelemetrySuccessEpic: LiveEventsTelemetryEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(fetchLiveStaffEventsTelemetrySuccess)),
    map((action) => action.payload),
    mergeMap((eventTelemetry) =>
      from(eventTelemetry).pipe(
        map((eventTelemetry) => {
          const staffEvents =
            mapLiveEventsTelemetryToStaffEvents(eventTelemetry)
          return updateTelemetryStaffEvent(staffEvents)
        })
      )
    )
  )
}
