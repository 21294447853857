import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getBadgeAction } from '../../actions/badges'
import { Badges } from '../../models'

const useBadgeValidation = (badgeId: string, badges: Badges) => {
  const [validating, setValidating] = useState(false)
  const [validatedBadgeId, setValidatedBadgeId] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (badgeId) {
      if (!badges[badgeId]) {
        setValidatedBadgeId(false)
        setValidating(true)
        dispatch(getBadgeAction.request(badgeId))
      } else {
        setValidatedBadgeId(true)
        setValidating(false)
      }
    }
  }, [dispatch, badgeId, badges])

  return {
    validating,
    validatedBadgeId,
    setValidating,
  }
}

export { useBadgeValidation }
