import React from 'react'
import { IconButton } from '../../../components/ActionBar'
import { EditOutlined } from '@mui/icons-material'
import { Location } from '../../../models'
import { Tooltip } from '@mui/material'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

interface Props {
  canEdit: boolean
  selectedFloor: Location
  onEdit: (id: string) => void
}

const useStyles = makeStyles(() =>
  createStyles({
    bannerBody: {
      zIndex: 700,
      position: 'absolute',
      width: '100%',
      height: '84px',
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      WebkitBackdropFilter: 'blur(6px)',
      backdropFilter: 'blur(6px)',
      boxShadow: '0 0 0px 1px rgba(196, 196, 196, 0.6)',
    },
    bannerText: {
      fontFamily: 'Ubuntu',
      fontSize: '24px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.17',
      letterSpacing: 'normal',
      textAlign: 'left',
      color: '#333',
      paddingTop: '26px',
      paddingLeft: '24px',
    },
    iconButton: {
      backgroundColor: 'unset',
    },
  })
)

const LocationFloorEditBanner: React.FC<Props> = (props: Props) => {
  const { canEdit, selectedFloor, onEdit } = props
  const classes = useStyles()

  const handleEditClick = () => {
    onEdit(selectedFloor.id)
  }

  return (
    <div className={classes.bannerBody}>
      <div className={classes.bannerText}>
        Edit Floor: {selectedFloor.name}
        {canEdit ? (
          <Tooltip title='Edit Floor'>
            <IconButton
              size='small'
              color='primary'
              onClick={handleEditClick}
              className={classes.iconButton}
              style={{ paddingBottom: '10px' }}
            >
              <EditOutlined />
            </IconButton>
          </Tooltip>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export { LocationFloorEditBanner }
