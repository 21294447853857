import { Epic } from 'redux-observable'
import { JibestreamDestinationAction } from '../actions'
import { JibestreamDestinationState } from '../reducers/destination'
import { JibestreamApi } from '../middleware/jibestream'
import { isActionOf } from 'typesafe-actions'
import {
  getDestinationsByVenueAction,
  updateDestinationAction,
} from '../actions/destination'
import { filter, mergeMap, catchError, map } from 'rxjs/operators'
import { from, of } from 'rxjs'

type JibestreamDestinationEpic = Epic<
  JibestreamDestinationAction,
  JibestreamDestinationAction,
  JibestreamDestinationState,
  { jibestreamApi: JibestreamApi }
>

export const getDestinationsByVenueEpic: JibestreamDestinationEpic = (
  action$,
  state,
  { jibestreamApi }
) => {
  return action$.pipe(
    filter(isActionOf(getDestinationsByVenueAction.request)),
    mergeMap((action) =>
      from(jibestreamApi.getDestinationsByVenue(action.payload)).pipe(
        map((destination) => getDestinationsByVenueAction.success(destination)),
        catchError((error) => of(getDestinationsByVenueAction.failure(error)))
      )
    )
  )
}

export const updateDestinationEpic: JibestreamDestinationEpic = (
  action$,
  state,
  { jibestreamApi }
) => {
  return action$.pipe(
    filter(isActionOf(updateDestinationAction.request)),
    mergeMap((action) =>
      from(jibestreamApi.updateDestination(action.payload)).pipe(
        map((destination) => updateDestinationAction.success(destination)),
        catchError((error) => of(updateDestinationAction.failure(error)))
      )
    )
  )
}
