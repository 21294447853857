import React from 'react'
import withStyles from '@mui/styles/withStyles'
import MuiDialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'

interface Props {
  title: string
  classes: any // todo: interface/object for this?
  onClose: () => void
}

const DialogHeaderClose = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  titleText: {
    lineHeight: 1,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))((props: Props) => {
  const { title, classes, onClose } = props
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant='h6' component='span' className={classes.titleText}>
        {title}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label='Close'
          className={classes.closeButton}
          onClick={onClose}
          size='large'
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

export default DialogHeaderClose
