import { Epic } from 'redux-observable'
import { AlertSubscriptionKeyAction } from '../actions'
import { AlertSubscriptionKeyState } from '../models'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { getAlertSubscriptionKeysAction } from '../actions/alertSubscriptionKeys'
import { AlertSubscriptionKeysApi } from '../middleware'
import { from, of } from 'rxjs'

type AlertSubscriptionKeysEpic = Epic<
  AlertSubscriptionKeyAction,
  AlertSubscriptionKeyAction,
  AlertSubscriptionKeyState,
  { alertSubscriptionKeysApi: AlertSubscriptionKeysApi }
>

export const getAlertSubscriptionKeyEpic: AlertSubscriptionKeysEpic = (
  action$,
  state,
  { alertSubscriptionKeysApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAlertSubscriptionKeysAction.request)),
    mergeMap((action) =>
      from(alertSubscriptionKeysApi.getAlertSubscriptionKey()).pipe(
        map((alertSubscriptionKey) =>
          getAlertSubscriptionKeysAction.success(alertSubscriptionKey)
        ),
        catchError((error) => of(getAlertSubscriptionKeysAction.failure(error)))
      )
    )
  )
}
