import { createAsyncAction } from 'typesafe-actions'
import { Venue, SyncVenueResult } from '../models'
import { HttpErrorResponse } from '../services/http'

export const getAllVenuesAction = createAsyncAction(
  'GET_ALL_VENUES_REQUEST',
  'GET_ALL_VENUES_SUCCESS',
  'GET_ALL_VENUES_FAILURE'
)<void, Venue[], HttpErrorResponse>()

export const getSyncVenueAction = createAsyncAction(
  'GET_SYNC_VENUE_REQUEST',
  'GET_SYNC_VENUE_SUCCESS',
  'GET_SYNC_VENUE_FAILURE'
)<string, SyncVenueResult, HttpErrorResponse>()

export const deleteVenueAction = createAsyncAction(
  'DELETE_VENUE_REQUEST',
  'DELETE_VENUE_SUCCESS',
  'DELETE_VENUE_FAILURE'
)<number, Venue[], HttpErrorResponse>()

export const deleteAllVenueAction = createAsyncAction(
  'DELETE_ALL_VENUE_REQUEST',
  'DELETE_ALL_VENUE_SUCCESS',
  'DELETE_ALL_VENUE_FAILURE'
)<void, Venue[], HttpErrorResponse>()
