import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { AgentEventsLocationAction } from '../actions'
import {
  getAllAgentEventsAction,
  getAgentEventAction,
  putAgentEventAction,
  postAgentEventAction,
  setStaffEvents,
  resolveStaffEvent,
} from '../actions/agentEventsLocation'
import { mapAgentEventsLocationToStaffEvents } from '../helpers'
import { AgentEventLocationApi } from '../middleware/agentEventsLocation'
import {
  AgentEventLocation,
  AgentEventsLocationState,
  StaffEventsState,
} from '../models'

type AgentEventsEpic = Epic<
  AgentEventsLocationAction,
  AgentEventsLocationAction,
  AgentEventsLocationState,
  { agentEventLocationApi: AgentEventLocationApi }
>

export const getAllAgentEventsEpic: AgentEventsEpic = (
  action$,
  state$,
  { agentEventLocationApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllAgentEventsAction.request)),
    mergeMap(() =>
      from(agentEventLocationApi.getAllAgentEvents()).pipe(
        map((agentEvents) => getAllAgentEventsAction.success(agentEvents)),
        catchError((error) => of(getAllAgentEventsAction.failure(error)))
      )
    )
  )
}

type AgentEventsToDuressEventsEpic = Epic<
  AgentEventsLocationAction,
  AgentEventsLocationAction,
  StaffEventsState
>

export const getAllAgentEventsSuccessEpic: AgentEventsToDuressEventsEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(getAllAgentEventsAction.success)),
    map((action) => action.payload),
    mergeMap((agentEvents) =>
      of(agentEvents).pipe(
        map((agentEvents) => {
          const staffEvents = mapAgentEventsLocationToStaffEvents(agentEvents)
          return setStaffEvents(staffEvents)
        })
      )
    )
  )
}

export const getAgentEventsEpic: AgentEventsEpic = (
  action$,
  state$,
  { agentEventLocationApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAgentEventAction.request)),
    mergeMap((action) =>
      from(agentEventLocationApi.getAgentEvent(action.payload)).pipe(
        map((agentEvents: AgentEventLocation) =>
          getAgentEventAction.success(agentEvents)
        ),
        catchError((error) => of(getAgentEventAction.failure(error)))
      )
    )
  )
}

export const putAgentEventsEpic: AgentEventsEpic = (
  action$,
  state$,
  { agentEventLocationApi }
) => {
  return action$.pipe(
    filter(isActionOf(putAgentEventAction.request)),
    mergeMap((action) =>
      from(agentEventLocationApi.putAgentEvent(action.payload)).pipe(
        map((payload: AgentEventLocation) =>
          putAgentEventAction.success(payload)
        ),
        catchError((error) => of(putAgentEventAction.failure(error)))
      )
    )
  )
}

export const putAgentEventSuccessEpic: AgentEventsToDuressEventsEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(putAgentEventAction.success)),
    map((action) => action.payload),
    mergeMap((agentEvent) =>
      of(agentEvent).pipe(
        map((agentEvent) => {
          return resolveStaffEvent({
            agentId: agentEvent.agentId,
            badgeId: agentEvent.badgeIds[0],
          })
        })
      )
    )
  )
}

export const postAgentEventsEpic: AgentEventsEpic = (
  action$,
  state$,
  { agentEventLocationApi }
) => {
  return action$.pipe(
    filter(isActionOf(postAgentEventAction.request)),
    mergeMap((action) =>
      from(agentEventLocationApi.postAgentEvent(action.payload)).pipe(
        map((payload) => postAgentEventAction.success(payload)),
        catchError((error) => of(postAgentEventAction.failure(error)))
      )
    )
  )
}
