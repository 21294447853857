import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { StateType } from 'typesafe-actions'
import badgeDestinationReducer from './badgeDestination'
import badgeMovementQueueReducer from './badgeMovementQueue'
import badgesAtDestinationReducer from './badgesAtDestination'
import destinationPositionReducer from './destinationPositions'
import statusReducer from './status'
import trafficSensorsReducer from './traffic/trafficSensors'
import uiReducer from './ui'

import commonReducersMap from '@midmarkrtls/common/reducers'
import {
  AdUsersReducer,
  ImportsAssetsReducer,
  ImportsBadgesReducer,
  ImportsStaffReducer,
  ImportsUsersReducer,
  JibestreamRootReducer,
  OperationsFiltersReducer,
  OperationsPermissionsReducer,
  OperationsRolesReducer,
  OperationsUsersReducer,
  TenantsReducer,
  TenantTagNumbersReducer,
} from 'v2/reducers'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'currentLocation', 'locationSelection'],
}

// eslint-disable-next-line
const allReducers = {
  ...commonReducersMap,
  badgesAtDestination: badgesAtDestinationReducer,
  badgeDestinations: badgeDestinationReducer,
  badgeMovementQueue: badgeMovementQueueReducer,
  status: statusReducer,
  ui: uiReducer,
  trafficSensors: trafficSensorsReducer,
  destinationPositions: destinationPositionReducer,
  // v2
  adUsers: AdUsersReducer,
  importsAssets: ImportsAssetsReducer,
  importsBadges: ImportsBadgesReducer,
  importsStaff: ImportsStaffReducer,
  importsUsers: ImportsUsersReducer,
  jibestream: JibestreamRootReducer,
  operationsPermissions: OperationsPermissionsReducer,
  operationsFilters: OperationsFiltersReducer,
  operationsRoles: OperationsRolesReducer,
  operationsUsers: OperationsUsersReducer,
  tenants: TenantsReducer,
  tenantTagNumbers: TenantTagNumbersReducer,
}

const appReducer = combineReducers(allReducers)

const rootReducer = (
  // eslint-disable-next-line
  state: any,
  // eslint-disable-next-line
  action: any
) => {
  if (action.type === 'RESET_STORE') {
    storage.removeItem('persist:root')
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

export type RootState = StateType<typeof rootReducer>

export default persistReducer<RootState>(persistConfig, rootReducer)
