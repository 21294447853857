import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import {
  getAllAssetsImportsAction,
  postImportAssetsAction,
} from 'v2/actions/importsAssets'
import { ImportsAssetsApi } from 'v2/middleware'
import RootAction from '../../actions'
import { RootState } from '../../reducers'

type ImportsAssetsEpic = Epic<
  RootAction,
  RootAction,
  RootState,
  { importsAssetsApi: ImportsAssetsApi }
>

export const getAllAssetsImportsEpic: ImportsAssetsEpic = (
  action$,
  state,
  { importsAssetsApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllAssetsImportsAction.request)),
    mergeMap((action) =>
      from(importsAssetsApi.getAllAssetsImports()).pipe(
        map((imports) => getAllAssetsImportsAction.success(imports)),
        catchError((error) => of(getAllAssetsImportsAction.failure(error)))
      )
    )
  )
}

export const postImport: ImportsAssetsEpic = (
  action$,
  state,
  { importsAssetsApi }
) => {
  return action$.pipe(
    filter(isActionOf(postImportAssetsAction.request)),
    mergeMap((action) =>
      from(importsAssetsApi.postImportAssets(action.payload)).pipe(
        map((importResponse) => postImportAssetsAction.success(importResponse)),
        catchError((error) => of(postImportAssetsAction.failure(error)))
      )
    )
  )
}
