import { buildCDNPath } from '.'
import { IconType, StaffDuressAndAssistOptions } from '../../models'
import { IconUrlBadgeOptions, IconUrlOptions } from '../../models/inpixonMap'

export const defaultGroupColor = '1C1B1B'
export const highlightedGroupColor = '41abc7'

export function buildGroupIconUrl(
  agentCount: number,
  iconScale: number,
  staffDuressAndAssistOptions: Omit<
    StaffDuressAndAssistOptions,
    'staffDuressAndAssistFeatureFlagEnabled' | 'hasResolvedDuressOrAssist'
  >
): string {
  const iconUrlOptions: IconUrlOptions = {
    image: agentCount > 8 ? 'icons/GroupOver9.svg' : 'icons/Group.svg',
    name: agentCount.toString(),
    scale: iconScale,
    type: IconType.Group,
  }
  const iconUrlBadgeOptions: IconUrlBadgeOptions = {
    isLowBattery: false,
    hasIssues: false,
  }
  return buildCDNPath(
    iconUrlOptions,
    iconUrlBadgeOptions,
    staffDuressAndAssistOptions
  )
}

export function updateStaffDuressAssistIconUrl(
  iconUrl: string,
  hasActiveDuress: boolean,
  hasActiveAssist: boolean,
  hasResolvedDuressOrAssist: boolean
): string {
  if (hasActiveDuress) {
    return iconUrl.replace('isDuress=false', 'isDuress=true')
  } else if (hasActiveAssist) {
    return iconUrl.replace('isAssist=false', 'isAssist=true')
  } else if (hasResolvedDuressOrAssist) {
    let updatedIconUrl = iconUrl
    if (iconUrl.includes('isDuress=true')) {
      updatedIconUrl = updatedIconUrl.replace('isDuress=true', 'isDuress=false')
    }
    if (iconUrl.includes('isAssist=true')) {
      updatedIconUrl = updatedIconUrl.replace('isAssist=true', 'isAssist=false')
    }
    return updatedIconUrl
  }
  return iconUrl
}

export function updateSelectedGroupIconUrl(
  iconUrl: string,
  isSelected: boolean
): string {
  return isSelected
    ? iconUrl.replace('selected=false', 'selected=true')
    : iconUrl.replace('selected=true', 'selected=false')
}
