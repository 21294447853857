import { Badge, BadgeUpdateModel, CreateBadgeRequest } from '../models'
import { BaseService } from './baseService'

export class BadgesApi extends BaseService {
  constructor() {
    super({ entityType: 'badges' })
  }
  route = 'badges'

  async getAllBadges(): Promise<Badge[]> {
    return await this.get<Badge[]>(this.route)
  }

  async getBadge(badgeID: string): Promise<Badge> {
    return await this.get<Badge>(`${this.route}/${badgeID}`)
  }

  async putBadge(badgeUpdateModel: BadgeUpdateModel): Promise<Badge> {
    return await this.put<Badge>(
      `${this.route}/${badgeUpdateModel.badgeID}`,
      badgeUpdateModel
    )
  }
  async postBadge(badgeRequest: CreateBadgeRequest): Promise<Badge> {
    return await this.post<Badge>(`${this.route}`, badgeRequest.badge)
  }
}

export const badgeApi = new BadgesApi()
