import { BadgeMovementQueue } from '../models/badgeMovementQueue'
import { BadgeMovementQueueAction } from '../actions'
import { getType } from 'typesafe-actions'
import {
  addToQueueAction,
  removeFromQueueAction,
} from '../actions/badgeMovementQueue'

export type BadgeMovementQueueState = Readonly<BadgeMovementQueue>

const badgeMovementQueueReducer = (
  state: BadgeMovementQueueState = {},
  action: BadgeMovementQueueAction
): BadgeMovementQueueState => {
  switch (action.type) {
    case getType(addToQueueAction):
      return {
        ...state,
        [action.payload.badgeID]: action.payload,
      }
    case getType(removeFromQueueAction):
      const newState = { ...state }
      delete newState[action.payload]
      return newState
    default:
      return state
  }
}

export default badgeMovementQueueReducer
