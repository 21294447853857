import { colors } from '../styles/MidmarkTheme'

export function GetAvatarColor(singleLetter: string): string {
  let avatarColor: string = colors.unitColor1

  switch (singleLetter) {
    case 'A':
    case 'B':
    case 'C':
    case 'D':
    case 'E':
      avatarColor = colors.unitColor1
      break
    case 'F':
    case 'G':
    case 'H':
    case 'I':
    case 'J':
      avatarColor = colors.unitColor3
      break
    case 'K':
    case 'L':
    case 'M':
    case 'N':
    case 'O':
      avatarColor = colors.unitColor2
      break
    case 'P':
    case 'Q':
    case 'R':
    case 'S':
      avatarColor = colors.avatarBackground4
      break
    case 'T':
    case 'U':
    case 'V':
    case 'W':
      avatarColor = colors.unitColor4
      break
    case 'X':
    case 'Y':
    case 'Z':
      avatarColor = colors.darkBlue
      break
    default:
      avatarColor = colors.unitColor1
      break
  }

  return avatarColor
}
