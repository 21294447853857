import { Epic } from 'redux-observable'
import { isActionOf } from 'typesafe-actions'
import { mergeMap, catchError, filter, map } from 'rxjs/operators'
import { from, of } from 'rxjs'
import { OperationsRoleAction } from '../actions'
import {
  getOperationsRolesAction,
  getOperationsRolesByUserIdAction,
  getOperationsRoleByIdAction,
  postOperationsRoleAction,
  deleteOperationsRoleAction,
  putOperationsRoleAction,
} from '../actions/operationsRoles'
import { OperationsRolesApi } from 'v2/middleware/operationsRoles'
import { Role } from '@midmarkrtls/common/models'
import { OperationsRolesState } from 'v2/models/operationsRoles'

type OperationsRolesEpic = Epic<
  OperationsRoleAction,
  OperationsRoleAction,
  OperationsRolesState,
  { operationsRolesApi: OperationsRolesApi }
>

export const getAllOperationsRolesEpic: OperationsRolesEpic = (
  action$,
  state$,
  { operationsRolesApi }
) => {
  return action$.pipe(
    filter(isActionOf(getOperationsRolesAction.request)),
    mergeMap(() =>
      from(operationsRolesApi.getAllOperationsRoles()).pipe(
        map((payload: Role[]) => getOperationsRolesAction.success(payload)),
        catchError((error) => of(getOperationsRolesAction.failure(error)))
      )
    )
  )
}

export const getOperationsRolesByUserIdEpic: OperationsRolesEpic = (
  action$,
  state$,
  { operationsRolesApi }
) => {
  return action$.pipe(
    filter(isActionOf(getOperationsRolesByUserIdAction.request)),
    mergeMap((action) =>
      from(operationsRolesApi.getOperationsRolesByUserId(action.payload)).pipe(
        map((payload: Role[]) =>
          getOperationsRolesByUserIdAction.success(payload)
        ),
        catchError((error) =>
          of(getOperationsRolesByUserIdAction.failure(error))
        )
      )
    )
  )
}

export const getOperationsRoleByIdEpic: OperationsRolesEpic = (
  action$,
  state$,
  { operationsRolesApi }
) => {
  return action$.pipe(
    filter(isActionOf(getOperationsRoleByIdAction.request)),
    mergeMap((action) =>
      from(operationsRolesApi.getOperationsRoleById(action.payload)).pipe(
        map((payload: Role) => getOperationsRoleByIdAction.success(payload)),
        catchError((error) => of(getOperationsRoleByIdAction.failure(error)))
      )
    )
  )
}

export const postOperationsRoleByIdEpic: OperationsRolesEpic = (
  action$,
  state$,
  { operationsRolesApi }
) => {
  return action$.pipe(
    filter(isActionOf(postOperationsRoleAction.request)),
    mergeMap((action) =>
      from(operationsRolesApi.postOperationsRole(action.payload)).pipe(
        map((payload: Role) => postOperationsRoleAction.success(payload)),
        catchError((error) => of(postOperationsRoleAction.failure(error)))
      )
    )
  )
}

export const putOperationsRoleByIdEpic: OperationsRolesEpic = (
  action$,
  state$,
  { operationsRolesApi }
) => {
  return action$.pipe(
    filter(isActionOf(putOperationsRoleAction.request)),
    mergeMap((action) =>
      from(operationsRolesApi.putOperationsRole(action.payload)).pipe(
        map((payload: Role) => putOperationsRoleAction.success(payload)),
        catchError((error) => of(putOperationsRoleAction.failure(error)))
      )
    )
  )
}

export const deleteOperationsRoleEpic: OperationsRolesEpic = (
  action$,
  state$,
  { operationsRolesApi }
) => {
  return action$.pipe(
    filter(isActionOf(deleteOperationsRoleAction.request)),
    mergeMap((action) =>
      from(operationsRolesApi.deleteOperationsRole(action.payload)).pipe(
        map(() => deleteOperationsRoleAction.success(action.payload)),
        catchError((error) => of(deleteOperationsRoleAction.failure(error)))
      )
    )
  )
}
