import { createAction } from 'typesafe-actions'
import { MapGroups } from '../models'

export const updateGroupsAction = createAction('UPDATE_GROUPS')<MapGroups>()
export const setSelectedGroupIdAction = createAction('SET_SELECTED_GROUP_ID')<
  number | undefined
>()
export const setSelectedTrackingIdAction = createAction(
  'SET_SELECTED_TRACKING_ID'
)<string | undefined>()
