import { createAsyncAction } from 'typesafe-actions'
import { Device } from '../models'
import { HttpErrorResponse } from '../services/http'

export const getAllDevicesAction = createAsyncAction(
  'GET_ALL_DEVICES_REQUEST',
  'GET_ALL_DEVICES_SUCCESS',
  'GET_ALL_DEVICES_FAILURE'
)<void, Device[], HttpErrorResponse>()

export const getDeviceAction = createAsyncAction(
  'GET_DEVICE_REQUEST',
  'GET_DEVICE_SUCCESS',
  'GET_DEVICE_FAILURE'
)<string, Device, HttpErrorResponse>()

export const putDeviceAction = createAsyncAction(
  'PUT_DEVICE_REQUEST',
  'PUT_DEVICE_SUCCESS',
  'PUT_DEVICE_FAILURE'
)<Device, Device, HttpErrorResponse>()

export const putDevicesAction = createAsyncAction(
  'PUT_DEVICES_REQUEST',
  'PUT_DEVICES_SUCCESS',
  'PUT_DEVICES_FAILURE'
)<Device[], Device[], HttpErrorResponse>()
