import React from 'react'
import {
  Asset,
  BadgeCurrentLocation,
  Manufacturer,
  Watchlist,
} from '../../../../models'
import { AssetRow } from '../../../../models/assetRow'
import { RightDrawerLayout } from '../../RightDrawer'
import DeprecatedAssetDetailPane from './DeprecatedAssetDetailPane'
import DeprecatedAssetDetailPaneHeader from './DeprecatedAssetDetailPaneHeader'
import { AssetFilters } from '../../../../models/filters'
import { ColumnSort } from '@tanstack/react-table'

interface RealTimeListPageOptions {
  badgeCurrentLocation: BadgeCurrentLocation[] | undefined
}

interface Props<T = AssetRow | Asset> {
  selectedAsset: T
  watchList: Watchlist
  manufacturers: Manufacturer[]
  realTimeListPageOptions?: RealTimeListPageOptions
  isRTMapPage: boolean
  assetFilterState: AssetFilters
  onCloseDetailDrawer: () => void
  onEditAsset?: () => void
  setFilterButtonToggled: (filterButtonStates: any) => void
  forceRefresh: () => void
}

const DeprecatedAssetDetailDrawer = (props: Props): JSX.Element => {
  const {
    selectedAsset,
    watchList,
    realTimeListPageOptions,
    manufacturers,
    isRTMapPage,
    assetFilterState,
    onCloseDetailDrawer,
    onEditAsset,
    setFilterButtonToggled,
    forceRefresh,
  } = props
  const { badgeCurrentLocation } = realTimeListPageOptions ?? {}
  return (
    <RightDrawerLayout
      header={
        <DeprecatedAssetDetailPaneHeader
          asset={selectedAsset}
          isMap={isRTMapPage}
          watchlist={watchList}
          liveTelemetry={badgeCurrentLocation}
          assetFilterState={assetFilterState}
          onClose={onCloseDetailDrawer}
          onEditAsset={onEditAsset}
          setFilterButtonToggled={setFilterButtonToggled}
          forceRefresh={forceRefresh}
        />
      }
      content={
        <DeprecatedAssetDetailPane
          asset={selectedAsset}
          manufacturers={manufacturers}
          liveTelemetry={badgeCurrentLocation}
        />
      }
    />
  )
}

export default DeprecatedAssetDetailDrawer
