import { calculateMapUpdateType } from '../calculateRTMapUpdates'
import {
  EnteringGeofenceMapUpdateTypes,
  LeavingGeofenceMapUpdateTypes,
  MapRenderByGeofence,
  SelectedAgent,
} from '../../models/realtimeMap'
import {
  AgentTelemetriesGroupedByTrackingId,
  BadgeTelemetryMessageWithAgentEvent,
} from '../../models/telemetry'
import { findGeofenceAgentIsIn } from '../realtimeMap'
import { AgentType, AssetKitInstance, IconType } from '../../models'

/**
 * If an agent or group icon is currently selected on the map (i.e. the Detail Drawer is open),
 * the Detail Drawer needs to be updated accordingly as the agent moves around.
 * If agent is currently rendered as an Asset/Staff icon, agent telemetry in the Detail Drawer state is
 * updated as agent moves around
 * If agent is currently rendered as a Group icon,
 *  - If the Group icon is currently selected and agent is selected from the Detail Drawer list, reset the
 *  Group icon to unselected and keep the agent selected
 *  - If the Group icon is currently selected (Group Detail Drawer is open), remove the agent from the list
 *  in the Detail Drawer
 * If agents enters a geofence with a selected group, agent is then added to the selected group in order
 * for it to show up in the Detail Drawer agent list
 * @param agentTelemetries
 * @param currentMapRender
 * @param selectedTrackingId
 * @param selectedGroupId
 * @param moveSelectedAgentOutOfGroup
 * @param removeAgentFromSelectedGroup
 * @param addAgentToSelectedGroup
 */
export function updateDetailDrawerOnMapOnAgentMoved(
  agentTelemetries: AgentTelemetriesGroupedByTrackingId,
  currentMapRender: MapRenderByGeofence,
  selectedTrackingId: string | undefined,
  selectedGroupId: number | undefined,
  assetKit: AssetKitInstance,
  updateSelectedAgentTelemetry: (
    telemetry: BadgeTelemetryMessageWithAgentEvent
  ) => void,
  moveSelectedAgentOutOfGroup: (updatedAgent: SelectedAgent) => void,
  removeAgentFromSelectedGroup: (
    agentTelemtry: BadgeTelemetryMessageWithAgentEvent
  ) => void,
  addAgentToSelectedGroup: (
    agentTelemtry: BadgeTelemetryMessageWithAgentEvent
  ) => void
): void {
  Object.values(agentTelemetries).forEach((movingAgentTelemetry) => {
    const updateType = calculateMapUpdateType(
      movingAgentTelemetry,
      currentMapRender
    )
    if (!updateType || updateType.agentEventUpdateType) {
      return
    }
    let isAgentInCurrentlySelectedGroup = false
    let isAgentMovingToASelectedGroup = false
    const isAgentCurrentlySelected =
      movingAgentTelemetry.trackingId === selectedTrackingId
    const prevGeofenceAgentWasIn = findGeofenceAgentIsIn(
      movingAgentTelemetry.trackingId,
      currentMapRender
    )
    if (prevGeofenceAgentWasIn) {
      isAgentInCurrentlySelectedGroup =
        prevGeofenceAgentWasIn === selectedGroupId
    }
    isAgentMovingToASelectedGroup =
      movingAgentTelemetry.geoFenceId === selectedGroupId
    if (updateType.agentMovingBetweenGeofencesUpdate) {
      const { leavingGeofenceUpdateType, enteringGeofenceUpdateType } =
        updateType.agentMovingBetweenGeofencesUpdate
      switch (leavingGeofenceUpdateType) {
        case LeavingGeofenceMapUpdateTypes.MoveAgent:
          if (isAgentCurrentlySelected) {
            updateSelectedAgentTelemetry(movingAgentTelemetry)
          }
          break
        case LeavingGeofenceMapUpdateTypes.Degroup:
        case LeavingGeofenceMapUpdateTypes.MoveAgentFromGroup:
          if (isAgentCurrentlySelected && isAgentInCurrentlySelectedGroup) {
            if (movingAgentTelemetry.agent.agentType === undefined) {
              return
            }
            const iconType =
              movingAgentTelemetry.agent.agentType === AgentType.Asset
                ? IconType.Asset
                : IconType.Staff
            const inpixonAsset = assetKit.getAssetsByType(
              `${iconType}_${movingAgentTelemetry.trackingId}`
            )
            moveSelectedAgentOutOfGroup({
              inpixonAsset:
                inpixonAsset.length > 0 ? inpixonAsset[0] : undefined,
              telemetry: movingAgentTelemetry,
            })
          } else if (isAgentInCurrentlySelectedGroup) {
            removeAgentFromSelectedGroup(movingAgentTelemetry)
          } else if (isAgentCurrentlySelected) {
            updateSelectedAgentTelemetry(movingAgentTelemetry)
          }
          break
      }
      if (isAgentMovingToASelectedGroup) {
        switch (enteringGeofenceUpdateType) {
          case EnteringGeofenceMapUpdateTypes.CreateNewGroup:
          case EnteringGeofenceMapUpdateTypes.Ungrouped:
            break
          case EnteringGeofenceMapUpdateTypes.UpdateGroup:
            if (isAgentMovingToASelectedGroup) {
              addAgentToSelectedGroup(movingAgentTelemetry)
            }
        }
      }
    }
  })
}
