import { Row } from '@tanstack/react-table'
import moment from 'moment'
import { localMoment } from '../date'

// eslint-disable-next-line @typescript-eslint/ban-types
export const customSortByAdvancedString = <T extends object = {}>(
  rowA: Row<T>,
  rowB: Row<T>,
  columnId: string
): number => {
  const aRow = rowA.getValue(columnId)
  const bRow = rowB.getValue(columnId)

  if (!aRow || typeof aRow !== 'string') {
    return -1
  }
  if (!bRow || typeof bRow !== 'string') {
    return 1
  }

  return aRow.localeCompare(bRow, undefined, {
    numeric: true,
    sensitivity: 'base',
  })
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const customSortByDate = <T extends object = {}>(
  rowA: Row<T>,
  rowB: Row<T>,
  columnId: string
): number => {
  const a = rowA.getValue<Date | string | moment.Moment | undefined>(columnId)
  const b = rowB.getValue<Date | string | moment.Moment | undefined>(columnId)

  // use 1900 for null dates to fix react-table null/undefined handling
  const nullDate = moment('1900-01-01')
  const aDate = a ? localMoment(a) : nullDate
  const bDate = b ? localMoment(b) : nullDate
  return aDate > bDate ? 1 : -1
}
