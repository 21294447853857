import { ButtonBase, Grid, Popover, Theme, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { ChevronRight, ExpandMore } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import useWidthBreakpoints from '../../hooks/useWidthBreakpoints'
import { Location } from '../../models'
import { WidthBreakpoints } from '../../models/widthBreakpoints'
import { DataTestIds } from '../../utils/test-utils/dataTestIds'
import LocationSelectMenu from './LocationSelectMenu'

export interface SelectProps {
  isIDNRestrictedHierarchy: boolean
  idn: Location | null
  selectedCampus?: Location
  selectedBuilding?: Location
  selectedFloor?: Location
  campuses?: Location[]
  onCampusChange: (value: Location | undefined) => void
  onBuildingChange: (value: Location | undefined) => void
  onFloorChange: (value: Location | undefined) => void
  isDeselectable?: boolean
}

interface ButtonBaseStylesProps {
  menuOpen: boolean
}

interface SelectionStylesProps {
  defaultSelection?: boolean
  menuOpen?: boolean
  first: boolean
  last: boolean
}

const useButtonStyles = makeStyles<Theme, ButtonBaseStylesProps>((theme) =>
  createStyles({
    root: {
      height: '39px',
      justifyContent: 'flex-start',
    },
  })
)
const useContainerStyles = makeStyles<Theme, SelectionStylesProps>(() =>
  createStyles({
    root: {
      width: 'fit-content',
      minWidth: ({ first }) => (first ? 'initial' : '64px'),
    },
  })
)

const useTextStyles = makeStyles<Theme, SelectionStylesProps>(() =>
  createStyles({
    root: {
      color: ({ defaultSelection }) =>
        defaultSelection ? '#8d8d8d' : '#1c1b1b',
      margin: '0px 7px',
    },
  })
)
const useIconStyles = makeStyles<Theme, SelectionStylesProps>(() =>
  createStyles({
    root: {
      height: ({ menuOpen }) => (menuOpen ? 'initial' : '16px'),
      color: ({ first, last }) => (first || last ? '#666666' : '#c4c4c4'),
      marginLeft: ({ last }) => (last ? '8px' : '0px'),
      marginRight: ({ last }) => (last ? '8px' : '0px'),
    },
  })
)

const usePopoverStyles = makeStyles(() =>
  createStyles({
    root: {},
    paper: {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: '#e4e4e4',
      borderRadius: '4px 4px 4px 4px',
      boxShadow:
        '0 0 3px 1px rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.16)',
    },
  })
)

const LocationSelectBreadcrumbs = (props: SelectProps): JSX.Element => {
  const {
    isIDNRestrictedHierarchy,
    idn,
    campuses,
    selectedCampus,
    selectedBuilding,
    selectedFloor,
    onCampusChange,
    onBuildingChange,
    onFloorChange,
    isDeselectable,
  } = props

  const [maxBreadcrumbLength, setMaxBreadcrumbLength] = useState<number>(30)
  const [isNarrowWidth, setIsNarrowWidth] = useState<boolean>(false)

  const widthBreakpoint = useWidthBreakpoints()

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const buttonRef =
    React.useRef<HTMLButtonElement>() as React.RefObject<HTMLButtonElement>

  useEffect(() => {
    switch (widthBreakpoint) {
      case WidthBreakpoints.Medium:
        setMaxBreadcrumbLength(23)
        setIsNarrowWidth(true)
        break
      case WidthBreakpoints.Large:
        setMaxBreadcrumbLength(25)
        setIsNarrowWidth(true)
        break
      case WidthBreakpoints.ExtraLarge:
        setMaxBreadcrumbLength(28)
        setIsNarrowWidth(true)
        break
      case WidthBreakpoints.ExtraExtraLarge:
        setMaxBreadcrumbLength(30)
        setIsNarrowWidth(false)
        break
      case WidthBreakpoints.ExtraSmall:
      case WidthBreakpoints.Small:
      default:
        setMaxBreadcrumbLength(19)
        setIsNarrowWidth(true)
    }
  }, [widthBreakpoint])
  const handleClick = () => {
    setAnchorEl(buttonRef.current ?? null)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const truncate = (str: string, count: number): string => {
    if (count < 4) count = 4
    return str.length > count ? str.slice(0, count - 4) + '...' : str
  }

  const open = Boolean(anchorEl)
  const id = open ? 'location-select-popover' : undefined
  const buttonClasses = useButtonStyles({ menuOpen: open })
  const lastIconClasses = useIconStyles({
    defaultSelection: !selectedFloor,
    menuOpen: true,
    first: false,
    last: true,
  })
  const popoverClasses = usePopoverStyles({})

  return (
    <>
      <Grid
        container
        item
        justifyContent='space-between'
        alignItems='center'
        wrap='nowrap'
        classes={useContainerStyles({
          defaultSelection: false,
          menuOpen: false,
          first: true,
          last: false,
        })}
      >
        <Typography
          variant='body2'
          classes={useTextStyles({
            defaultSelection: false,
            menuOpen: open,
            first: false,
            last: false,
          })}
          noWrap
        >
          {isNarrowWidth
            ? truncate(idn?.name ?? 'IDN', maxBreadcrumbLength)
            : idn?.name}
        </Typography>
        <ChevronRight
          classes={useIconStyles({
            defaultSelection: false,
            menuOpen: false,
            first: false,
            last: false,
          })}
        />
      </Grid>
      <ButtonBase
        disabled={isIDNRestrictedHierarchy}
        onClick={handleClick}
        classes={buttonClasses}
        disableRipple
        ref={buttonRef ?? null}
        data-testid={`${DataTestIds.locationBreadcrumbs}`}
      >
        <Grid container wrap='nowrap'>
          <Grid
            container
            item
            justifyContent='space-between'
            alignItems='center'
            wrap='nowrap'
            classes={useContainerStyles({
              defaultSelection: !selectedCampus,
              menuOpen: open,
              first: false,
              last: false,
            })}
          >
            <Typography
              variant='body2'
              classes={useTextStyles({
                defaultSelection: !selectedCampus,
                menuOpen: open,
                first: false,
                last: false,
              })}
              noWrap
            >
              {truncate(
                selectedCampus?.name ?? 'All Building Groups',
                maxBreadcrumbLength
              )}
            </Typography>
            <ChevronRight
              classes={useIconStyles({
                defaultSelection: !selectedCampus,
                menuOpen: false,
                first: false,
                last: false,
              })}
            />
          </Grid>
          <Grid
            container
            item
            justifyContent='space-between'
            alignItems='center'
            wrap='nowrap'
            classes={useContainerStyles({
              defaultSelection: !selectedBuilding,
              menuOpen: open,
              first: false,
              last: false,
            })}
          >
            <Typography
              variant='body2'
              classes={useTextStyles({
                defaultSelection: !selectedBuilding,
                menuOpen: open,
                first: false,
                last: false,
              })}
              noWrap
            >
              {truncate(
                selectedBuilding?.name ?? 'All Buildings',
                maxBreadcrumbLength
              )}
            </Typography>
            <ChevronRight
              classes={useIconStyles({
                defaultSelection: !selectedBuilding,
                menuOpen: false,
                first: false,
                last: false,
              })}
            />
          </Grid>
          <Grid
            container
            item
            justifyContent='space-between'
            alignItems='center'
            wrap='nowrap'
            classes={useContainerStyles({
              defaultSelection: !selectedFloor,
              menuOpen: open,
              first: false,
              last: false,
            })}
          >
            <Typography
              variant='body2'
              classes={useTextStyles({
                defaultSelection: !selectedFloor,
                menuOpen: open,
                first: false,
                last: false,
              })}
              noWrap
            >
              {truncate(
                selectedFloor?.name ?? 'All Floors',
                maxBreadcrumbLength
              )}
            </Typography>
            {!isIDNRestrictedHierarchy && (
              <ExpandMore classes={lastIconClasses} />
            )}
          </Grid>
        </Grid>
      </ButtonBase>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={0}
        classes={popoverClasses}
        marginThreshold={0}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <LocationSelectMenu
          isIDNRestrictedHierarchy={isIDNRestrictedHierarchy}
          open={open}
          selectedCampus={selectedCampus}
          selectedBuilding={selectedBuilding}
          selectedFloor={selectedFloor}
          campuses={campuses}
          onCampusChange={onCampusChange}
          onBuildingChange={onBuildingChange}
          onFloorChange={onFloorChange}
          handleClose={handleClose}
          isDeselectable={isDeselectable}
        />
      </Popover>
    </>
  )
}

export default LocationSelectBreadcrumbs
