import { useCallback, useEffect, useMemo } from 'react'
import { getAgentEventViewsByUnitsAndLocationAction } from '../../actions/agentEventViews'
import { AgentEventView, FetchingStatus } from '../../models'
import { useDispatch, useSelector } from 'react-redux'
import { useCurrentLocation } from '../useCurrentLocation'
import useCurrentUnitLocations from '../useCurrentUnitLocations'
import useLocationDescendants from '../useLocationDescendants'
import { selectAgentEventViewsState } from '../../selectors'
import { useGlobalSearch } from '..'
import { useFetchFeatureFlags } from './useFetchFeatureFlags'
import { FeatureFlagLabels, FeatureFlagNames } from '../../constants'

export interface UseFetchAgentEventViews {
  fetchNextPage: () => void
  fetchingStatus?: FetchingStatus
  agentEventViews: AgentEventView[]
  hasMore: boolean
  totalCount: number
}

const useFetchAgentEventViews = (): UseFetchAgentEventViews => {
  const dispatch = useDispatch()
  const { currentLocation } = useCurrentLocation()
  const { currentUnits: unitIds } = useCurrentUnitLocations()
  const { floors } = useLocationDescendants(currentLocation, true)
  const agentEventViewsState = useSelector(selectAgentEventViewsState)

  const floorIds = useMemo(
    () => floors.map((f) => f.id),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(floors)]
  )

  const locationId = useMemo(
    () => currentLocation?.id,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentLocation?.id]
  )

  const { data: featureFlags } = useFetchFeatureFlags(
    FeatureFlagLabels.Navigation,
    true
  )

  const isEnabled =
    Object.values(featureFlags).filter(
      (x) => x.name === FeatureFlagNames.assetAlerts && x.enabled === true
    ).length > 0

  useEffect(() => {
    if (locationId && isEnabled) {
      dispatch(
        getAgentEventViewsByUnitsAndLocationAction.request({
          floorIds,
          locationId,
          unitIds,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId, JSON.stringify(unitIds)])

  const fetchNextPage = useCallback(() => {
    if (locationId && isEnabled) {
      dispatch(
        getAgentEventViewsByUnitsAndLocationAction.request({
          floorIds,
          locationId,
          unitIds,
        })
      )
    }
  }, [locationId, JSON.stringify(unitIds)])

  const filteredItems = useGlobalSearch(
    agentEventViewsState.data.agentEventViews,
    [
      'eventId',
      'eventTypeId',
      'eventType',
      'alertConfigurationId',
      'alertConfiguration',
      'alertTypeId',
      'alertType',
      'createdDate',
      'typeId',
      'battery',
      'isLowBattery',
      'batteryTimestamp',
      'latestLocationId',
      'latestLocation',
      'latestUnitId',
      'latestUnit',
      'latestFloorId',
      'latestFloor',
      'latestBuildingId',
      'latestBuilding',
      'latestTimestamp',
      'previousLocationId',
      'previousLocation',
      'previousUnitId',
      'previousUnit',
      'previousFloorId',
      'previousFloor',
      'previousBuildingId',
      'previousBuilding',
      'previousTimestamp',
    ]
  )

  return {
    fetchNextPage,
    agentEventViews: filteredItems ?? [],
    hasMore: agentEventViewsState.data.hasMore,
    fetchingStatus: agentEventViewsState.status,
    totalCount: agentEventViewsState.data.totalCount,
  }
}

export { useFetchAgentEventViews }
