import { Grid, TextField, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { putLocationAction } from '../../actions/locations'
import { cleanUpNullGuid } from '../../helpers/misc'
import { useSaveSuccessFailureUtility } from '../../hooks'
import {
  FetchingStatus,
  Location,
  LocationsState,
  LocationType,
} from '../../models'
import { colors, useFormStyle } from '../../styles'
import { Button, SaveButton } from '../Buttons'
import { requiredMessage } from '../Forms'
import { addSpaceToEnum } from '../../helpers/enums'

interface Props {
  canEdit: boolean
  selectedLocation: Location
}

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      display: 'block',
      maxWidth: 420,
    },
    buttonContainer: {
      marginTop: 10,
      maxWidth: 420,
    },
    editRoomLabel: {
      marginBottom: 25,
      maxWidth: 420,
    },
  })
)

const CampusBuildingEditForm: React.FC<Props> = (props: Props) => {
  const { canEdit, selectedLocation } = props

  const locationStatus = useSelector(
    ({ locations }: { locations: LocationsState }) => locations.status
  )
  const dispatch = useDispatch()

  const {
    control,
    handleSubmit,
    formState: { isDirty, errors },
    reset,
    setValue,
  } = useForm<Location>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: selectedLocation.name,
    } as Location,
  })

  const onSubmit = (formData: Location): void => {
    const updatedLocation: Location = {
      ...selectedLocation,
      ...formData,
    }
    cleanUpNullGuid(updatedLocation, ['unitId', 'subUnitId'])
    setIsSaving(true)
    dispatch(putLocationAction.request(updatedLocation))
  }

  const { isSaving, setIsSaving, showError, setShowError } =
    useSaveSuccessFailureUtility(
      locationStatus === FetchingStatus.Success,
      locationStatus === FetchingStatus.Failure,
      `${LocationType[selectedLocation.locationType]} was successfully updated.`
    )

  useEffect(() => {
    if (selectedLocation) {
      setValue('name', selectedLocation.name)
    }

    if (showError) {
      setShowError(false)
    }
  }, [selectedLocation, setShowError, setValue, showError])

  const handleCancel = () => {
    reset({ name: selectedLocation.name })
  }

  const classes = useStyles()
  const formClasses = useFormStyle()

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography
        id='location-edit-label'
        variant='h5'
        className={classes.editRoomLabel}
      >
        Edit {addSpaceToEnum(LocationType[selectedLocation.locationType])}:{' '}
        {selectedLocation.name}
      </Typography>
      <Grid item xs>
        <Controller
          name='name'
          control={control}
          rules={{
            required: true,
            maxLength: {
              value: 50,
              message: 'Floor name must be between 1 and 50 characters',
            },
          }}
          render={({ field }) => {
            return (
              <TextField
                {...field}
                id='location-edit-textbox'
                label={
                  addSpaceToEnum(LocationType[selectedLocation.locationType]) +
                  ' Name'
                }
                variant='filled'
                fullWidth
                required
                className={formClasses.inputStyle}
                autoFocus
                disabled={!canEdit || locationStatus === FetchingStatus.Request}
                error={!!errors.name}
                helperText={
                  errors.name?.message ? errors.name?.message : requiredMessage
                }
              />
            )
          }}
        />
      </Grid>
      {showError && (
        <Grid item xs>
          <Typography
            style={{
              color: colors.error,
              paddingTop: 10,
            }}
            className={classes.textField}
          >
            There was an error saving{' '}
            {addSpaceToEnum(LocationType[selectedLocation.locationType])}{' '}
            information, please try again. If the problem persists, contact
            support.
          </Typography>
        </Grid>
      )}
      <Grid
        container
        justifyContent={'flex-end'}
        className={classes.buttonContainer}
        spacing={2}
      >
        <Grid item>
          <Button
            id='location-edit-cancel-button'
            onClick={handleCancel}
            disabled={!isDirty || isSaving}
            styleOverride={{
              wrapper: { margin: 0 },
            }}
            bgColor={colors.transparent}
            textColor={colors.error}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          {canEdit && (
            <SaveButton
              loading={isSaving}
              id='location-edit-save-button'
              disabled={Object.values(errors).length > 0}
            >
              Save
            </SaveButton>
          )}
        </Grid>
      </Grid>
    </form>
  )
}

export { CampusBuildingEditForm }
