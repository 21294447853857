import { ColumnDef } from '@tanstack/react-table'
import React, { ReactNode, useMemo } from 'react'
import { FeatureFlagLabels, FeatureFlagNames } from '../../constants'
import { QueryFilter } from '../../filters'
import { useSearchAndFilters } from '../../hooks'
import { useFetchFeatureFlags } from '../../hooks/entities/useFetchFeatureFlags'
import { Roles, User } from '../../models'
import { EditButton, ReactTable, TableRowSelectionOptions } from '../Tables'

interface Props<T extends User> {
  canEdit: boolean
  users: T[]
  showLoading: boolean
  roles: Roles
  isOpsUserList: boolean
  tableToolbarActions: ReactNode[]
  userFilters: QueryFilter<T>
  onUserEdit: (id: string | undefined) => void
  tableRowSelectionOptions: Omit<
    TableRowSelectionOptions<UserRow<T>>,
    'dataMatchPredicate'
  >
}

export type UserRow<T> = T & {
  roles: string
  formattedCloudReportingEnabled: string
}

// eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/explicit-module-boundary-types
const UserManagementList = <T extends User>(props: Props<T>) => {
  const {
    canEdit,
    users,
    showLoading,
    roles,
    isOpsUserList,
    tableToolbarActions,
    userFilters,
    onUserEdit,
    tableRowSelectionOptions,
  } = props

  const { data: featureFlags } = useFetchFeatureFlags(
    FeatureFlagLabels.Navigation
  )

  const isCloudReportingFeatureEnabled =
    Object.values(featureFlags).filter(
      (x) => x.name === FeatureFlagNames.cloudReporting && x.enabled
    ).length > 0

  const columns = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const columnDefinitions: ColumnDef<UserRow<T>, any>[] = [
      {
        accessorKey: 'firstName',
        header: 'First Name',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'lastName',
        header: 'Last Name',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'displayName',
        header: 'Display Name',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'email',
        header: 'Email',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'roles',
        header: 'Roles',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'formattedCloudReportingEnabled',
        header: 'Analytics Access',
        sortingFn: 'alphanumeric',
        enableHiding: !isCloudReportingFeatureEnabled || isOpsUserList,
      },
      {
        id: 'edit',
        enableSorting: false,
        accessorFn: (row) => (
          <EditButton item={row.id} onEditClick={onUserEdit} />
        ),
        cell: (cell) => cell.getValue(),
        header: 'Edit',
        enablePinning: true,
        enableHiding: !canEdit,
        meta: {
          align: 'right',
          headerStyle: {
            paddingRight: 19,
          },
        },
      },
    ]
    return columnDefinitions
  }, [canEdit, isCloudReportingFeatureEnabled, isOpsUserList, onUserEdit])

  const userRows: UserRow<T>[] = useMemo((): UserRow<T>[] => {
    return users.map((user) => {
      const userRow: UserRow<T> = {
        ...user,
        roles: user.roleIds?.map((role) => roles[role]?.name).join(', ') ?? '',
        formattedCloudReportingEnabled:
          user.isCloudReportingEnabled === null
            ? 'Unavailable'
            : user.isCloudReportingEnabled
            ? 'Yes'
            : 'No',
      }
      return userRow
    })
  }, [users, roles])
  const tableData = useSearchAndFilters(userRows, userFilters.criteria, [
    'id',
    'customerId',
    'roleIds',
  ])

  return (
    <>
      <ReactTable
        tableToolbarOptions={{
          title: 'User Administration',
          actions: tableToolbarActions,
        }}
        tableOptions={{
          data: tableData ?? [],
          columns: columns,
          showLoading: showLoading,
        }}
        tableRowOptions={{
          rowSelectionOptions: {
            ...tableRowSelectionOptions,
            dataMatchPredicate: (userA: UserRow<T>, userB: UserRow<T>) =>
              userA.id === userB.id,
          },
        }}
      />
    </>
  )
}

export default UserManagementList
