import { MapRenderByGeofence } from '../../models/realtimeMap'
import { BadgeTelemetryMessageWithAgentEvent } from '../../models/telemetry'
import { groupHasActiveDuress } from '../telemetry'

export function findAgentInCurrentMapRender(
  trackingId: string,
  currentMapRender: MapRenderByGeofence
): BadgeTelemetryMessageWithAgentEvent | undefined {
  let prevTelemetry: BadgeTelemetryMessageWithAgentEvent | undefined
  Object.values(currentMapRender).forEach((mapRenderByGeofence) => {
    const telemetry = mapRenderByGeofence.renderedAgents.find(
      (telemetry) => telemetry.trackingId === trackingId
    )
    if (telemetry) {
      prevTelemetry = telemetry
    }
  })
  return prevTelemetry
}

export function findGeofenceAgentIsIn(
  trackingId: string,
  currentMapRender: MapRenderByGeofence
): number | undefined {
  let geofenceId: number | undefined
  Object.values(currentMapRender).forEach((mapRender) => {
    const trackingIdsInGeofence = mapRender.renderedAgents.map(
      (x) => x.trackingId
    )
    if (trackingIdsInGeofence.includes(trackingId)) {
      geofenceId = mapRender.geoFenceId
    }
  })
  return geofenceId
}

export function calculateMapRenderToUpdateForAgentOnMap(
  currentMapRender: MapRenderByGeofence,
  telemetry: BadgeTelemetryMessageWithAgentEvent
): MapRenderByGeofence {
  let updatedMapRender = currentMapRender
  const currentGeofenceAgentIsIn = findGeofenceAgentIsIn(
    telemetry.trackingId,
    currentMapRender
  )
  if (currentGeofenceAgentIsIn) {
    if (currentGeofenceAgentIsIn !== telemetry.geoFenceId) {
      const enteringGeofence = currentMapRender[telemetry.geoFenceId]
      if (enteringGeofence) {
        const updatedEnteringGeofenceRender = [
          ...enteringGeofence.renderedAgents,
          telemetry,
        ]
        updatedMapRender = {
          ...updatedMapRender,
          [telemetry.geoFenceId]: {
            ...enteringGeofence,
            renderedAgents: updatedEnteringGeofenceRender,
          },
        }
      } else {
        console.error(
          'Entering geofence not found for previously rendered agent!'
        )
      }
      const updatedLeavingGeofenceRender = currentMapRender[
        currentGeofenceAgentIsIn
      ].renderedAgents.filter(
        (mapRender) => mapRender.trackingId !== telemetry.trackingId
      )
      updatedMapRender = {
        ...updatedMapRender,
        [currentGeofenceAgentIsIn]: {
          ...updatedMapRender[currentGeofenceAgentIsIn],
          renderedAgents: updatedLeavingGeofenceRender,
        },
      }
    } else {
      // Replace telemetry with new one
      const filteredCurrentAgentsInGeofence = updatedMapRender[
        telemetry.geoFenceId
      ].renderedAgents.filter((x) => x.trackingId !== telemetry.trackingId)
      updatedMapRender = {
        ...updatedMapRender,
        [telemetry.geoFenceId]: {
          ...updatedMapRender[telemetry.geoFenceId],
          renderedAgents: [...filteredCurrentAgentsInGeofence, telemetry],
        },
      }
    }
  } else {
    console.error('Unable to find geofenceId for previously rendered agent!')
  }
  return updatedMapRender
}

export function calculateMapRenderToUpdateForAgentLeavingMap(
  currentMapRender: MapRenderByGeofence,
  agentLeavingMap: BadgeTelemetryMessageWithAgentEvent
): MapRenderByGeofence {
  let updatedMapRender = currentMapRender
  const currentGeofenceAgentIsIn = findGeofenceAgentIsIn(
    agentLeavingMap.trackingId,
    currentMapRender
  )
  if (currentGeofenceAgentIsIn) {
    const updatedLeavingGeofenceRender = currentMapRender[
      currentGeofenceAgentIsIn
    ].renderedAgents.filter(
      (mapRender) => mapRender.trackingId !== agentLeavingMap.trackingId
    )
    updatedMapRender = {
      ...updatedMapRender,
      [currentGeofenceAgentIsIn]: {
        ...updatedMapRender[currentGeofenceAgentIsIn],
        renderedAgents: updatedLeavingGeofenceRender,
      },
    }
  } else {
    console.info(
      'Received telemetry for agent leaving current floor, but agent is not currently rendered on map!'
    )
  }
  return updatedMapRender
}

export function calculateMapRenderToUpdateForAgentsEnteringMap(
  currentMapRender: MapRenderByGeofence,
  agentEnteringMap: BadgeTelemetryMessageWithAgentEvent
): MapRenderByGeofence {
  let updatedMapRender = currentMapRender
  const enteringGeofenceMapRender =
    currentMapRender[agentEnteringMap.geoFenceId]
  if (enteringGeofenceMapRender) {
    const updatedAgentsInEnteringGeofence = [
      ...enteringGeofenceMapRender.renderedAgents,
      agentEnteringMap,
    ]
    updatedMapRender = {
      ...updatedMapRender,
      [agentEnteringMap.geoFenceId]: {
        ...updatedMapRender[agentEnteringMap.geoFenceId],
        renderedAgents: updatedAgentsInEnteringGeofence,
      },
    }
  } else {
    console.error(
      'Unable to find entering geofence for new agent. Unable to add agent to map!'
    )
  }
  return updatedMapRender
}

export function checkRemainingAgentsHaveDuress(
  currentMapRender: MapRenderByGeofence,
  leavingTrackingId: string,
  previousGeofenceId: number
): boolean {
  const leavingGeofenceMapRender = currentMapRender[previousGeofenceId]
  const remainingAgentsInLeavingGeofence: BadgeTelemetryMessageWithAgentEvent[] =
    leavingGeofenceMapRender.renderedAgents.filter(
      (telemetry) => telemetry.trackingId !== leavingTrackingId
    ) ?? []
  return groupHasActiveDuress(remainingAgentsInLeavingGeofence)
}
