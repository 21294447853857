import { BaseService } from './baseService'
import { HttpRequestInit, MediaType } from '../services/http'
import {
  ExternalLink,
  ExternalLinksOperationsRequest,
  ExternalLinkUpdate,
} from '../models/externalLinks'

export class ExternalLinksApi extends BaseService {
  constructor() {
    super({ entityType: 'externalLink' })
  }
  route = 'externallinks'

  async getAllExternalLinks(customerId: string): Promise<ExternalLink[]> {
    const myHeaders: Record<string, string> = { 'X-Tenant-ID': `${customerId}` }
    const options: HttpRequestInit = {
      headers: myHeaders,
    }
    return await this.get<ExternalLink[]>(this.route, options)
  }

  async putExternalLink(
    externalLink: ExternalLinkUpdate
  ): Promise<ExternalLink> {
    const myHeaders: Record<string, string> = {
      'X-Tenant-ID': `${externalLink.customerId}`,
    }
    const options: HttpRequestInit = {
      headers: myHeaders,
    }
    return await this.put<ExternalLink>(
      `${this.route}`,
      externalLink.externalLink,
      options
    )
  }

  async postExternalLink(
    externalLink: ExternalLinkUpdate
  ): Promise<ExternalLink> {
    const myHeaders: Record<string, string> = {
      //Passing Tenant Customer ID to allow pull to that Tenant
      'X-Tenant-ID': `${externalLink.customerId}`,
    }
    const options: HttpRequestInit = {
      headers: myHeaders,
    }

    return await this.post<ExternalLink>(
      `${this.route}`,
      externalLink.externalLink,
      options
    )
  }

  async postExternalLinks(
    request: ExternalLinksOperationsRequest
  ): Promise<ExternalLink[]> {
    const myHeaders: Record<string, string> = {
      //Passing Tenant Customer ID to allow pull to that Tenant
      'X-Tenant-ID': `${request.customerId}`,
    }
    const options: HttpRequestInit = {
      headers: myHeaders,
      responseType: MediaType.Json,
    }

    return await this.post<ExternalLink[]>(
      `${this.route}`,
      request.operations,
      options
    )
  }

  async deleteExternalLink(
    externalLink: ExternalLinkUpdate
  ): Promise<ExternalLink> {
    const myHeaders: Record<string, string> = {
      //Passing Tenant Customer ID to allow update to that Tenant
      'X-Tenant-ID': `${externalLink.customerId}`,
    }
    const options: HttpRequestInit = {
      headers: myHeaders,
    }

    return await this.delete<ExternalLink>(
      `${this.route}/${externalLink.externalLink.id}`,
      options
    )
  }
}
