import { Epic } from 'redux-observable'
import RootAction from 'actions'
import { JibestreamVenueAction } from '../actions'
import { isActionOf } from 'typesafe-actions'
import {
  getAllJibestreamVenuesAction,
  importVenueAction,
} from '../actions/venue'
import { getAllVenuesAction } from '@midmarkrtls/common/actions/venues'
import { getAllLocationsAction } from '@midmarkrtls/common/actions/locations'
import { filter, mergeMap, catchError, map } from 'rxjs/operators'
import { from, of } from 'rxjs'
import { JibestreamApi } from '../middleware/jibestream'
import { JibestreamVenueState } from 'v2/models'
import { HttpErrorResponse } from '@midmarkrtls/common/services/http'

type JibestreamVenueEpic = Epic<
  JibestreamVenueAction | RootAction,
  JibestreamVenueAction | RootAction,
  JibestreamVenueState,
  { jibestreamApi: JibestreamApi }
>

export const getAllVenuesEpic: JibestreamVenueEpic = (
  action$,
  state,
  { jibestreamApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllJibestreamVenuesAction.request)),
    mergeMap(() =>
      from(jibestreamApi.getAllVenues()).pipe(
        map((venue) => getAllJibestreamVenuesAction.success(venue)),
        catchError((error) => of(getAllJibestreamVenuesAction.failure(error)))
      )
    )
  )
}

export const importVenueActionEpic: JibestreamVenueEpic = (
  action$,
  state,
  { jibestreamApi }
) => {
  return action$.pipe(
    filter(isActionOf(importVenueAction.request)),
    mergeMap((action) =>
      from(jibestreamApi.importVenue(action.payload)).pipe(
        mergeMap((venue) => [
          importVenueAction.success(venue),
          getAllVenuesAction.request(),
          getAllLocationsAction.request(),
        ]),
        catchError((error: HttpErrorResponse) =>
          of(importVenueAction.failure(error))
        )
      )
    )
  )
}
