import { useEffect, useState } from 'react'
import { useUrlParams } from '.'

interface CurrentViewState<View extends string> {
  currentView?: View
  setCurrentView: (view: View) => void
  disableSearch: boolean
}

const useCurrentView = <View extends string>(
  defaultView?: View,
  isHardwareOrMapsPage?: boolean
): CurrentViewState<View> => {
  const { queryParams, routeParams } = useUrlParams()
  const [currentView, setCurrentView] = useState<View>()
  const [disableSearch, setDisableSearch] = useState(false)

  // watch url to see if view changes
  useEffect(() => {
    const viewFromParams = (queryParams?.view ?? routeParams?.view) as View
    const view = !!viewFromParams?.length ? viewFromParams : defaultView
    if (view) {
      setCurrentView(view)
    }
    if (
      (view === 'list' || view === 'map' || view === 'altitude') &&
      isHardwareOrMapsPage
    ) {
      setDisableSearch(true)
    } else if (isHardwareOrMapsPage) {
      setDisableSearch(false)
    }
  }, [defaultView, queryParams, routeParams])

  return {
    currentView,
    setCurrentView,
    disableSearch,
  }
}

export default useCurrentView
