import { FeatureFlag } from '../models'
import { BaseService } from './baseService'

export class FeatureFlagApi extends BaseService {
  constructor() {
    super({
      apiRoot: process.env.REACT_APP_FEATURE_FLAGS,
      entityType: 'FeatureFlag',
    })
  }
  route = 'appconfig'

  async getFeatureFlagByLabel(label: string): Promise<FeatureFlag[]> {
    return await this.get<FeatureFlag[]>(`${this.route}`, {
      params: { label: label },
    })
  }
}
