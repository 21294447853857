import { differenceInSeconds } from 'date-fns'
import { useState, useEffect } from 'react'
import { ChannelTypeEnum, StaffEvent } from '../models'
import { useFetchAlerts } from './entities/useFetchAlerts'
import { useFetchChannelSounds } from './entities/useFetchChannelSounds'

const useAgentEventSound = (
  staffDuress: StaffEvent[],
  staffAssist: StaffEvent[]
): void => {
  const [alertSoundsPlayed, setAlertSoundsPlayed] = useState<string[]>([])
  const { channelSounds } = useFetchChannelSounds()
  const { alerts } = useFetchAlerts()

  useEffect(() => {
    if (
      Object.values(channelSounds).length > 0 &&
      Object.values(alerts).length > 0 &&
      (staffDuress.length > 0 || staffAssist.length > 0)
    ) {
      //Combine notification
      const notifications = [...staffDuress, ...staffAssist]
      //Loop through all events
      notifications.forEach((event) => {
        const alert = alerts[event.alertId]
        if (
          alert &&
          alert.channels.some(
            (x) => x.channelTypeId === ChannelTypeEnum.Sound
          ) &&
          !alertSoundsPlayed.includes(event.id) &&
          differenceInSeconds(
            new Date(),
            event.reportedTime ?? new Date(2020, 1, 1)
          ) < 10 //This stops alerts from playing on page refresh
        ) {
          const alertSoundChannel = alert.channels.find(
            (x) => x.channelTypeId === ChannelTypeEnum.Sound
          )?.channelSoundId
          if (alertSoundChannel) {
            setAlertSoundsPlayed([event.id, ...alertSoundsPlayed])
            const soundChannel = channelSounds[alertSoundChannel]
            if (soundChannel) {
              const audioRef = new Audio(
                process.env.REACT_APP_STATIC_CONTENT + soundChannel.cdnPath
              )
              audioRef.play()
            }
          }
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(channelSounds),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(alerts),
    staffDuress,
    staffAssist,
    alertSoundsPlayed,
  ])
}

export { useAgentEventSound }
