import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { LocationRouterAction } from '../actions'
import { getAllLocationRoutersAction } from '../actions/locationRouters'
import { LocationRoutersApi } from '../middleware'
import { LocationRouterState } from '../models/locationRouter'

type LocationRouterEpic = Epic<
  LocationRouterAction,
  LocationRouterAction,
  LocationRouterState,
  { locationRoutersApi: LocationRoutersApi }
>

export const getAllLocationRoutersEpic: LocationRouterEpic = (
  action$,
  state,
  { locationRoutersApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllLocationRoutersAction.request)),
    mergeMap((action) =>
      from(locationRoutersApi.getAllLocationRouters()).pipe(
        map((locationRouters) =>
          getAllLocationRoutersAction.success(locationRouters)
        ),
        catchError((error) => of(getAllLocationRoutersAction.failure(error)))
      )
    )
  )
}
