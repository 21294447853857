import { EntityResult, useFetchUsers } from '..'
import { User } from '../../models'

const useFetchUserById = (userId: string): EntityResult<User | undefined> => {
  const fetchUsersResult = useFetchUsers()

  return { ...fetchUsersResult, data: fetchUsersResult.data[userId] }
}

export { useFetchUserById }
