import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { action, isActionOf } from 'typesafe-actions'
import { CloudReportingAction } from '../actions'
import {
  getCloudReportingEmbedUrlAction,
  getCloudReportingPartnerAction,
  getCloudReportingViewsAction,
} from '../actions/cloudReporting'
import { CloudReportingApi } from '../middleware'
import { CloudReportingState } from '../models/cloudReporting'

type CloudReportingEpic = Epic<
  CloudReportingAction,
  CloudReportingAction,
  CloudReportingState,
  { cloudReportingApi: CloudReportingApi }
>

export const getCloudReportingEmbedUrlEpic: CloudReportingEpic = (
  action$,
  state$,
  { cloudReportingApi }
) => {
  return action$.pipe(
    filter(isActionOf(getCloudReportingEmbedUrlAction.request)),
    mergeMap((action) =>
      from(cloudReportingApi.getEmbedUrl(action.payload)).pipe(
        map((embedUrl) => getCloudReportingEmbedUrlAction.success(embedUrl)),
        catchError((error) =>
          of(getCloudReportingEmbedUrlAction.failure(error))
        )
      )
    )
  )
}

export const getCloudReportingPartnerEpic: CloudReportingEpic = (
  action$,
  state$,
  { cloudReportingApi }
) => {
  return action$.pipe(
    filter(isActionOf(getCloudReportingPartnerAction.request)),
    mergeMap((action) =>
      from(cloudReportingApi.getPartner(action.payload)).pipe(
        map((partner) => getCloudReportingPartnerAction.success(partner)),
        catchError((error) => of(getCloudReportingPartnerAction.failure(error)))
      )
    )
  )
}

export const getCloudReportingViewsEpic: CloudReportingEpic = (
  action$,
  state$,
  { cloudReportingApi }
) => {
  return action$.pipe(
    filter(isActionOf(getCloudReportingViewsAction.request)),
    mergeMap(() =>
      from(cloudReportingApi.getViews()).pipe(
        map((views) => getCloudReportingViewsAction.success(views)),
        catchError((error) => of(getCloudReportingViewsAction.failure(error)))
      )
    )
  )
}
