import { BaseService } from '@midmarkrtls/common/middleware/baseService'
import { Import, ImportCreate, ImportLog } from 'v2/models'

export class ImportsStaffApi extends BaseService {
  constructor() {
    super({ entityType: 'imports/Staff' })
  }
  route = 'imports/Staff'

  async getAllStaffImports(): Promise<Import[]> {
    return await this.get<Import[]>(`${this.route}`)
  }

  async getImportStaffTemplate(): Promise<string> {
    return await this.get<string>(`${this.route}/template`)
  }

  async getImportStaffLogs(
    importGuid: string,
    selectedLogLevelIds: string[]
  ): Promise<ImportLog[]> {
    return await this.get<ImportLog[]>(`${this.route}/${importGuid}/logs`, {
      params: { logLevels: selectedLogLevelIds },
    })
  }

  async downloadImportStaffLogsCsv(
    importGuid: string,
    selectedLogLevelIds: string[]
  ): Promise<File> {
    return await this.get<File>(`${this.route}/${importGuid}/logs/download`, {
      params: { logLevels: selectedLogLevelIds },
    })
  }

  async postImportStaff(importCreate: ImportCreate): Promise<Import> {
    const formData = new FormData()
    formData.append('file', importCreate.file, importCreate.file.name)

    return await this.post(`${this.route}}`, formData)
  }
}

export const importsStaffApi = new ImportsStaffApi()
