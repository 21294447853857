import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { ExternalLinksAction } from '../actions'
import {
  deleteExternalLinkAction,
  getAllExternalLinksAction,
  postExternalLinkAction,
  postExternalLinksAction,
  putExternalLinkAction,
} from '../actions/externalLinks'
import { ExternalLinksApi } from '../middleware'
import { ExternalLinksState } from '../models'

type ExternalLinksEpic = Epic<
  ExternalLinksAction,
  ExternalLinksAction,
  ExternalLinksState,
  { externalLinksApi: ExternalLinksApi }
>

export const getAllExternalLinksEpic: ExternalLinksEpic = (
  action$,
  state,
  { externalLinksApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllExternalLinksAction.request)),
    mergeMap((action) =>
      from(externalLinksApi.getAllExternalLinks(action.payload)).pipe(
        map((external) => getAllExternalLinksAction.success(external)),
        catchError((error) => of(getAllExternalLinksAction.failure(error)))
      )
    )
  )
}

export const putExternalLinkEpic: ExternalLinksEpic = (
  action$,
  state,
  { externalLinksApi }
) => {
  return action$.pipe(
    filter(isActionOf(putExternalLinkAction.request)),
    mergeMap((action) =>
      from(externalLinksApi.putExternalLink(action.payload)).pipe(
        map((external) => putExternalLinkAction.success(external)),
        catchError((error) => of(putExternalLinkAction.failure(error)))
      )
    )
  )
}

export const postExternalLinkEpic: ExternalLinksEpic = (
  action$,
  state,
  { externalLinksApi }
) => {
  return action$.pipe(
    filter(isActionOf(postExternalLinkAction.request)),
    mergeMap((action) =>
      from(externalLinksApi.postExternalLink(action.payload)).pipe(
        map((external) => postExternalLinkAction.success(external)),
        catchError((error) => of(postExternalLinkAction.failure(error)))
      )
    )
  )
}

export const postExternalLinksEpic: ExternalLinksEpic = (
  action$,
  state,
  { externalLinksApi }
) => {
  return action$.pipe(
    filter(isActionOf(postExternalLinksAction.request)),
    mergeMap((action) =>
      from(externalLinksApi.postExternalLinks(action.payload)).pipe(
        map((externalLinks) => postExternalLinksAction.success(externalLinks)),
        catchError((error) => of(postExternalLinkAction.failure(error)))
      )
    )
  )
}

export const deleteExternalLinkEpic: ExternalLinksEpic = (
  action$,
  state,
  { externalLinksApi }
) => {
  return action$.pipe(
    filter(isActionOf(deleteExternalLinkAction.request)),
    mergeMap((action) =>
      from(externalLinksApi.deleteExternalLink(action.payload)).pipe(
        map((external) => deleteExternalLinkAction.success(external)),
        catchError((error) => of(deleteExternalLinkAction.failure(error)))
      )
    )
  )
}
