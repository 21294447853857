import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllLocationRoutersAction } from '../actions/locationRouters'
import { shouldDispatch } from '../helpers'
import { LocationRouters, LocationRouterState } from '../models/locationRouter'

const useFetchLocationRouters = (shouldRefresh: boolean = false) => {
  const dispatch = useDispatch()
  const status = useSelector(
    ({ locationRouters }: { locationRouters: LocationRouterState }) =>
      locationRouters.status
  )
  const lastRefreshTime = useSelector(
    ({ locationRouters }: { locationRouters: LocationRouterState }) =>
      locationRouters.lastRefreshTime
  )

  useEffect(() => {
    if (shouldDispatch(status, lastRefreshTime) || shouldRefresh) {
      dispatch(getAllLocationRoutersAction.request())
    }
  }, [status, lastRefreshTime, shouldRefresh, dispatch])

  const locationRouters: LocationRouters = useSelector(
    ({ locationRouters }: { locationRouters: LocationRouterState }) =>
      locationRouters.data
  )

  return {
    locationRouters,
    status,
  }
}

export { useFetchLocationRouters }
