import { useEffect, useState } from 'react'
import { FilterRequest } from '../../models'
import { useAssetFilters, useSearchFilter } from '..'
import { assetFilterMap } from '../../helpers/filterMap/assetFilterMap'
import { batteryStatusSearch } from '../../filters'

const useFetchFullAssets = () => {
  const [assetState, setAssetState] = useState<unknown>({})
  const [searchState, setSearchState] = useState<string | undefined>(undefined)
  const [filterRequest, setFilterRequest] = useState<FilterRequest>(
    {} as FilterRequest
  )

  const { filter: searchFilter } = useSearchFilter(
    [
      'agentGuid',
      'badgeIds',
      'assetTypeId',
      'assetSubTypeId',
      'hasWorkOrder',
      'manufacturerGuid',
      'hasRecall',
      'iconUrl',
      'badgeTelemetry',
      'icon',
      'badgeLocation',
      'venueId',
      'actions',
      'signalTypeIds',
    ],
    {
      preventativeMaintenanceDate: 'datetime',
      tagBatteryChangeDate: 'datetime',
      isLowBattery: batteryStatusSearch,
    }
  )

  const { filterState: assetFilters } = useAssetFilters()

  useEffect(() => {
    let newFilterRequestNeeded = false
    //check for filter change
    if (JSON.stringify(assetState) !== JSON.stringify(assetFilters)) {
      setAssetState(assetFilters)
      newFilterRequestNeeded = true
    }
    //Check for search change
    if (searchState !== searchFilter.value) {
      setSearchState(searchFilter.value)
      newFilterRequestNeeded = true
    }
    //If change detected, Dispatch for requested data
    if (newFilterRequestNeeded) {
      const newFilterRequest = {} as FilterRequest
      newFilterRequest.search = searchFilter.value
      newFilterRequest.lowRTLSBattery = assetFilters?.isLowBattery

      if (assetFilters?.tagBatteryChangeDate) {
        newFilterRequest.rtlsBatteryChangeDate = new Date(
          assetFilters?.tagBatteryChangeDate
        ).toDateString()
      }

      newFilterRequest.hasBadge = assetFilters?.hasBadge
      newFilterRequest.rtlsSignalType = assetFilters?.signalType?.map(
        (x: any) => x.id
      )
      newFilterRequest.onWatchlist = assetFilters?.isWatchlist
      newFilterRequest.assetFilterRequest = assetFilterMap(assetFilters)
      if (assetFilters.columnSort?.length) {
        newFilterRequest.sortBy = assetFilters.columnSort[0].id
        newFilterRequest.sortOrderDesc = assetFilters.columnSort[0].desc
      }
      setFilterRequest(newFilterRequest)
    }
  }, [assetFilters, searchFilter])

  return {
    filterRequest,
  }
}

export { useFetchFullAssets }
