import { getType } from 'typesafe-actions'
import { BadgeEventTelemetryStore } from '../models'
import { setBadgeEventTelemetryAction } from '../actions/badgeEventTelemetry'
import { BadgeEventTelemetryAction } from '../actions'
const initialState: BadgeEventTelemetryStore = {
  badgeEventTelemetry: [],
}
export default (
  state: BadgeEventTelemetryStore = initialState,
  action: BadgeEventTelemetryAction
): BadgeEventTelemetryStore => {
  switch (action.type) {
    case getType(setBadgeEventTelemetryAction):
      return {
        ...state,
        badgeEventTelemetry: action.payload,
      }
    default:
      return state
  }
}
