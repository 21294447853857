import React, { ReactNode } from 'react'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { flexLayout } from '../../styles'
import clsx from 'clsx'
import { CommonPropsWithStyles } from '.'
import { defaultTheme } from './TableStyles'

const styles = createStyles({
  root: {
    background: defaultTheme.backgroundColor,
    ...flexLayout({ direction: 'column' }),
  },
  stretch: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },
})

interface Props extends CommonPropsWithStyles<typeof styles> {
  fullHeight?: boolean
  children: ReactNode
}

const TableView: React.FC<Props> = (props: Props) => {
  const { classes, fullHeight } = props

  return (
    <div className={clsx(classes.root, fullHeight && classes.stretch)}>
      {props.children}
    </div>
  )
}

export default withStyles(styles, { name: 'MidmarkTableView' })(TableView)
