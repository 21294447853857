import { getIssueTypesAction } from '../../actions/issueTypes'
import { IssueTypes, IssueTypesState } from '../../models'
import { EntityResult, useEntities } from '../useEntities'

const useFetchIssueTypes = (): EntityResult<IssueTypes> =>
  useEntities<IssueTypesState, IssueTypes>(
    () => getIssueTypesAction.request(),
    (state: any) => state.issueTypes // eslint-disable-line @typescript-eslint/no-explicit-any
  )

export { useFetchIssueTypes }
