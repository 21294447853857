import { useCallback, useRef, useState } from 'react'
import {
  addInpixonGeofenceHighlight,
  removeInpixonGeofenceHighlight,
  removeInpixonGeofenceHighlights,
} from '../../helpers/inpixon'
import { Geofences } from '../../models'
import { HighlightedGeofences } from '../../models/inpixonMap'
import { GeofenceInstance, GeofenceKitInstance } from '../../models/jibestream'
import { deleteRecord } from '../../utils'

export function useGeofenceHighlighting(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  jibestreamControl: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  jibestreamCore: any,
  geofenceKit: GeofenceKitInstance | undefined,
  geofenceInstances: GeofenceInstance[],
  fetchedGeofences: Geofences,
  mapId: number | undefined
): {
  highlightedGeofences: HighlightedGeofences // Return value for unit test assertion only
  addGeofenceHighlight: (geofenceId: number) => void
  addGeofenceHighlights: (geofenceIds: number[]) => void
  removeGeofenceHighlight: (geofenceId: number) => void
  removeAllGeofenceHighlights: () => void
} {
  const highlightedGeofencesRef = useRef<HighlightedGeofences>({})

  const addGeofenceHighlight = useCallback(
    (geofenceId: number) => {
      if (!geofenceKit) {
        return
      }
      const geofenceAlreadyHighlighted =
        highlightedGeofencesRef.current[geofenceId]
      if (geofenceAlreadyHighlighted) {
        return
      }
      const inpixonGeofence = addInpixonGeofenceHighlight(
        geofenceKit,
        jibestreamControl,
        geofenceId,
        fetchedGeofences
      )
      if (inpixonGeofence) {
        highlightedGeofencesRef.current = {
          ...highlightedGeofencesRef.current,
          [geofenceId]: inpixonGeofence,
        }
      }
    },
    [fetchedGeofences, geofenceKit, jibestreamControl]
  )

  const addGeofenceHighlights = useCallback(
    (geofenceIds: number[]) => {
      if (!geofenceKit) {
        return
      }
      geofenceIds.forEach((geofenceId) => {
        const geofenceAlreadyHighlighted =
          highlightedGeofencesRef.current[geofenceId]
        if (geofenceAlreadyHighlighted) {
          return
        }
        const inpixonGeofence = addInpixonGeofenceHighlight(
          geofenceKit,
          jibestreamControl,
          geofenceId,
          fetchedGeofences
        )
        if (inpixonGeofence) {
          highlightedGeofencesRef.current = {
            ...highlightedGeofencesRef.current,
            [geofenceId]: inpixonGeofence,
          }
        }
      })
    },
    [fetchedGeofences, geofenceKit, jibestreamControl]
  )

  const removeGeofenceHighlight = useCallback(
    (geofenceId: number) => {
      if (!geofenceKit) {
        return
      }
      if (highlightedGeofencesRef.current[geofenceId]) {
        removeInpixonGeofenceHighlight(
          geofenceKit,
          jibestreamControl,
          highlightedGeofencesRef.current[geofenceId]
        )
        const updatedHighlightedGeofences = deleteRecord<
          HighlightedGeofences,
          GeofenceInstance
        >(highlightedGeofencesRef.current, geofenceId)
        highlightedGeofencesRef.current = updatedHighlightedGeofences
      }
    },
    [geofenceKit, jibestreamControl]
  )

  const removeAllGeofenceHighlights = useCallback(() => {
    if (!geofenceKit || !jibestreamControl || !jibestreamCore || !mapId) {
      return
    }
    removeInpixonGeofenceHighlights(
      geofenceKit,
      jibestreamControl,
      geofenceInstances,
      mapId
    )
    highlightedGeofencesRef.current = {}
  }, [geofenceInstances, geofenceKit, jibestreamControl, jibestreamCore, mapId])

  return {
    highlightedGeofences: highlightedGeofencesRef.current,
    addGeofenceHighlight,
    addGeofenceHighlights,
    removeGeofenceHighlight,
    removeAllGeofenceHighlights,
  }
}
