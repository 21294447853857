import { AssetKitInstance, IconType } from '../../models'
import {
  InpixonAssetGroupUpdate,
  InpixonAssetAgentCreateOptions,
} from '../../models/inpixonMap'
import { updateInpixonGroupIconByType, showDegroupingAgents } from '../inpixon'
import { buildGroupIconUrl } from '../inpixonIcons'

/**
 * Update group icon to decrease group count in leaving geofence followed by
 * degrouping and showing leaving agent
 * @returns the nextInpixonAssetId for subsequent Inpixon asset creation
 */
export function updateGroupIconAndShowLeavingAgent(
  leavingGeofenceGroupToUpdateIcon: InpixonAssetGroupUpdate,
  agentsToDegroup: InpixonAssetAgentCreateOptions[],
  assetKit: AssetKitInstance,
  assetKitId: number,
  iconScale: number,
  useGeofenceCentroid: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  geofenceInstances: any[],
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any
): number | undefined {
  let nextInpixonAssetId: number | undefined
  const url = buildGroupIconUrl(
    leavingGeofenceGroupToUpdateIcon.groupCount,
    iconScale,
    {
      hasActiveDuress: leavingGeofenceGroupToUpdateIcon.hasActiveDuress,
      hasActiveAssist: leavingGeofenceGroupToUpdateIcon.hasActiveAssist,
      isSelected: leavingGeofenceGroupToUpdateIcon.isSelected,
    }
  )
  updateInpixonGroupIconByType(
    assetKit,
    jibestreamController,
    {
      iconType: IconType.Group,
      geofenceId: leavingGeofenceGroupToUpdateIcon.geoFenceId,
    },
    url
  )
  if (agentsToDegroup.length > 0) {
    nextInpixonAssetId = showDegroupingAgents(
      assetKit,
      agentsToDegroup,
      assetKitId,
      useGeofenceCentroid,
      geofenceInstances,
      jibestreamController
    )
  }
  return nextInpixonAssetId
}
