import { BaseService } from './baseService'
import { Unit } from '../models'

export class UnitsApi extends BaseService {
  constructor() {
    super({ entityType: 'units' })
  }
  route = 'units'

  async getAllUnits(): Promise<Unit[]> {
    return await this.get<Unit[]>(this.route)
  }
  async getUnit(id: string): Promise<Unit> {
    return await this.get<Unit>(`${this.route}/${id}`)
  }
  async postUnit(unit: Unit): Promise<Unit> {
    return await this.post<Unit>(this.route, unit)
  }
  async putUnit(unit: Unit): Promise<Unit> {
    return await this.put<Unit>(this.route, unit)
  }
  async deleteUnit(id: string): Promise<string> {
    return await this.delete(`${this.route}/${id}`)
  }
}
