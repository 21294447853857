import { createAsyncAction } from 'typesafe-actions'
import { Import, ImportCreate } from 'v2/models'
import { HttpErrorResponse } from '@midmarkrtls/common/services/http'

export const getAllStaffImportsAction = createAsyncAction(
  'GET_ALL_IMPORTS_STAFF_REQUEST',
  'GET_ALL_IMPORTS_STAFF_SUCCESS',
  'GET_ALL_IMPORTS_STAFF_FAILURE'
)<void, Import[], HttpErrorResponse>()

export const postImportStaffAction = createAsyncAction(
  'POST_IMPORT_STAFF_REQUEST',
  'POST_IMPORT_STAFF_SUCCESS',
  'POST_IMPORT_STAFF_FAILURE'
)<ImportCreate, Import, HttpErrorResponse>()
