import React, { useState, Fragment } from 'react'
import clsx from 'clsx'
import { Drawer, Typography, Button } from '@mui/material'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { colors } from '../../styles/MidmarkTheme'
import { PageGroup } from '../../interfaces/modules'
import SideNavLinks from './SideNav/SideNavLinks'
import { connect, useDispatch, useSelector } from 'react-redux'
import { AuthState, PermissionsEnum, SideNavState } from '../../models'
//import { SupportButton } from '../Support'
import { setSideNavIsOpenAction } from '../../actions/sidenav'

const drawerClosedWidth = 56
const drawerOpenedWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      marginLeft: 4,
      marginRight: 4,
      color: '#58c9e7',
    },
    drawerButton: {
      color: 'white',
      height: drawerClosedWidth + 'px',
      minHeight: drawerClosedWidth + 'px',
      minWidth: drawerClosedWidth + 'px',
      textTransform: 'none',
      borderTop: '1px solid ' + colors.background,
      borderRadius: '0px',
      padding: 0,
    },
    drawerIcon: {
      color: colors.indigo600,
      minWidth: drawerClosedWidth + 'px',
      justifyContent: 'center',
    },
    linkBody: {
      overflowX: 'hidden',
      overflowY: 'auto',
      height: '100%',
    },
    drawerPaper: {
      overflowX: 'hidden',
      overflowY: 'auto',
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerOpenedWidth,
      border: 0,
      backgroundColor: colors.darkSurface,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxShadow:
        '0 1px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.16)',
      '@media print': {
        display: 'none',
      },
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      overflowY: 'auto',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: '56px',
      '@media print': {
        display: 'none',
      },
    },
  })
)

interface Props {
  pageGroups: PageGroup[]
  permissions: PermissionsEnum[]
}

const SideNav: React.FC<Props> = (props: Props) => {
  const { pageGroups, permissions } = props
  const classes = useStyles()
  const [open, setOpen] = useState(true)
  const dispatch = useDispatch()

  return (
    <Drawer
      variant='permanent'
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
      onAnimationEnd={(): unknown => dispatch(setSideNavIsOpenAction(open))}
    >
      <div className={classes.linkBody}>
        {permissions && permissions.length && (
          <SideNavLinks
            isCollapsed={!open}
            pageGroups={pageGroups}
            permissions={permissions}
          />
        )}
      </div>
      <Button
        aria-label='open drawer'
        onClick={() => setOpen((prevOpen) => !prevOpen)}
        classes={{ root: classes.drawerButton }}
      >
        <div style={{ display: 'flex', width: '100%' }}>
          {open ? (
            <Fragment>
              <ChevronLeft classes={{ root: classes.drawerIcon }} />
              <Typography color='inherit'>Collapse</Typography>
            </Fragment>
          ) : (
            <ChevronRight
              classes={{ root: classes.drawerIcon }}
              style={{ marginRight: 'auto' }}
            />
          )}
        </div>
      </Button>
      {/* <SupportButton
        horizontalOffsetPixels={
          open ? drawerOpenedWidth.toString() : drawerClosedWidth.toString()
        }
      /> */}
    </Drawer>
  )
}

export default connect(({ auth }: { auth: AuthState }) => ({
  permissions: auth.permissions,
}))(SideNav)
