import { getType } from 'typesafe-actions'
import { ImportStaffAction } from 'v2/actions'
import { Imports, Import, ImportsStaffState } from 'v2/models'
import { mergeRecords, mergeRecord } from '@midmarkrtls/common/utils'
import {
  getAllStaffImportsAction,
  postImportStaffAction,
} from 'v2/actions/importsStaff'
import { FetchingStatus } from '@midmarkrtls/common/models'

const initialState: ImportsStaffState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

const importStaffReducer = (
  state: ImportsStaffState = initialState,
  action: ImportStaffAction
): ImportsStaffState => {
  switch (action.type) {
    case getType(getAllStaffImportsAction.request):
    case getType(postImportStaffAction.request):
      return {
        ...state,
        status: FetchingStatus.Request,
      }

    case getType(postImportStaffAction.success):
      return {
        data: mergeRecord<Imports, Import, 'importGuid'>(
          state.data,
          action.payload,
          'importGuid'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getAllStaffImportsAction.success):
      return {
        data: mergeRecords<Imports, Import, 'importGuid'>(
          state.data,
          action.payload,
          'importGuid'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getAllStaffImportsAction.failure):
    case getType(postImportStaffAction.failure):
      return {
        ...state,
        status: FetchingStatus.Failure,
      }
    default:
      return state
  }
}

export default importStaffReducer
