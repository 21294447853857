import { AgentType, IconType } from '../../models'
import { AgentInpixonAssetByType } from '../../models/inpixonMap'
import {
  EnteringGeofenceUpdates,
  MapRenderByGeofence,
} from '../../models/realtimeMap'
import { BadgeTelemetryMessageWithAgentEvent } from '../../models/telemetry'
import { groupHasActiveAssist, groupHasActiveDuress } from '../telemetry'

/**
 * Calculate config values to update group icon for entering geofence and config to
 * hide entering agent after moving into the geofence that has a group icon
 * @param newAgentTelemetry
 * @param currentMapRender
 * @param selectedTrackingId
 * @param selectedGroupId
 * @returns config to update group icon for entering geofence and config to
 * hide entering agent after moving into the geofence that has a group icon
 */
export function calculateGroupUpdateForEnteringGeofence(
  newAgentTelemetry: BadgeTelemetryMessageWithAgentEvent,
  currentMapRender: MapRenderByGeofence,
  selectedTrackingId: string | undefined,
  selectedGroupId: number | undefined
):
  | Pick<EnteringGeofenceUpdates, 'groupToUpdateIcon' | 'enteringAgentsToHide'>
  | undefined {
  const enteringGeofenceMapRender =
    currentMapRender[newAgentTelemetry.geoFenceId]
  if (enteringGeofenceMapRender) {
    const updatedAgentsInEnteringGeofence = [
      ...enteringGeofenceMapRender.renderedAgents,
      newAgentTelemetry,
    ]
    const updatedGroupHasActiveDuress = groupHasActiveDuress(
      updatedAgentsInEnteringGeofence
    )
    const updatedGroupHasActiveAssist = groupHasActiveAssist(
      updatedAgentsInEnteringGeofence
    )
    const groupToUpdateIcon = {
      geoFenceId: newAgentTelemetry.geoFenceId,
      hasActiveDuress: updatedGroupHasActiveDuress,
      hasActiveAssist: updatedGroupHasActiveAssist,
      isSelected:
        newAgentTelemetry.geoFenceId === selectedGroupId ||
        newAgentTelemetry.trackingId === selectedTrackingId,
      groupCount: updatedAgentsInEnteringGeofence.length,
    }
    const enteringAgentsToHide: AgentInpixonAssetByType[] = [
      {
        trackingId: newAgentTelemetry.trackingId,
        iconType:
          newAgentTelemetry.agent.agentType === AgentType.Asset
            ? IconType.Asset
            : IconType.Staff,
      },
    ]
    return {
      groupToUpdateIcon,
      enteringAgentsToHide,
    }
  }
}
