import React, { ReactNode, useMemo } from 'react'
import { Navigate } from 'react-router-dom'
import { permittedRoute } from '../../helpers'
import { PageRoute, PageRouteComponent } from '../../interfaces/modules'
import { useAuth } from '../../hooks'

export const withRedirectTo = (path: string) => (): ReactNode =>
  (<Navigate to={path} />) as ReactNode

const BuildRoutes = (routes: PageRoute[]): PageRouteComponent[] => {
  const { permissions } = useAuth()

  const userRoutes: PageRouteComponent[] | null = useMemo(() => {
    if (!permissions) {
      return null
    }

    if (routes?.length) {
      return routes?.reduce((acc: PageRouteComponent[], route) => {
        const Component = route.redirectTo
          ? withRedirectTo(route.redirectTo)
          : route.pageComponent
        const isPermitted =
          route.permissions.length === 0 ||
          permittedRoute(route.permissions, permissions)
        if (isPermitted) {
          acc.push({ route: route, component: Component })
        }
        return acc
      }, [])
    } else {
      return null
    }
  }, [routes, permissions])

  return userRoutes ?? []
}

export default BuildRoutes
