import { ColumnSort } from '@tanstack/react-table'
import { AlertType, Device, Issue, IssueType, Location, Role } from '.'
import { FetchingState } from '../interfaces/fetchingState'
import { DeviceStatus } from './devices'
import { AssetFilters, StaffFilters } from './filters'

/**
 * @deprecated
 */
export interface FilterModel {
  id: string
  filterTypeId: number
  name: string
  filterOptions: string
}

/**
 * @deprecated
 */
export enum FilterType {
  Asset,
  User,
  Staff,
  RealTime,
  Device,
}

/**
 * @deprecated
 */
export interface FilterCreateModel {
  filterTypeId: FilterType
  name: string
  filterOptions: string
  userGuid: string
}

/**
 * @deprecated
 */
export interface FilterDeleteModel {
  id: string
  userGuid: string
}

/**
 * @deprecated
 */
export type SearchFilter = {
  searchBy?: string
}

/**
 * @deprecated
 */
export interface FilterBase {
  filterName?: string
  filterGuid?: string
  filterType?: FilterType
}

export interface UserFilters extends FilterBase {
  selectedRoles: Role[]
}

export interface CustomerFilters {
  filterName?: string
  filterGuid?: string
  filterType?: FilterType
}

export interface BadgeTrafficFilters extends FilterBase {
  trackingId?: string
  sensorId?: string
  roomName?: string
  floorName?: string
  buildingName?: string
  columnSort?: ColumnSort[]
}

export interface DeviceFilters extends FilterBase {
  locations?: Location[]
  deviceStatus?: DeviceStatus[]
  issueTypes?: IssueType[]
  createdDate?: Date
  resolveDate?: Date
  hideResolved?: boolean
}

export interface DeviceAndIssue extends Device, Issue {
  configurationStatus: string
  issueTypeName?: string
  issueCreatedDates?: Date[]
  issueResolveDates?: (Date | undefined)[]
  locationName: string
  view?: string | JSX.Element
  details?: string | JSX.Element
  actions?: string | JSX.Element
}

export interface AlertFilters extends FilterBase {
  columnSort?: ColumnSort[]
  alertTypes?: AlertType[]
}

export type UserFiltersOptions = keyof UserFilters
