import { BaseService } from './baseService'
import { LogLevelType } from '../models/logLevelTypes'

export class LogLevelTypesApi extends BaseService {
  constructor() {
    super({ entityType: 'logLevelTypes' })
  }
  route = 'logLevelTypes'

  async getAllLogLevelTypes(): Promise<LogLevelType[]> {
    return await this.get<LogLevelType[]>(`${this.route}`)
  }
}
