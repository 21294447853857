import { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  useCalculateLiveMapTelemetryUpdates,
  useRTMapSearchAndFilters,
} from '.'
import { Criteria } from '../../filters'
import {
  filterTelemetries,
  sortInitialFetchedTelemetries,
} from '../../helpers/telemetry'
import {
  Asset,
  FetchingStatus,
  Geofences,
  Staff,
  StaffEvent,
} from '../../models'
import {
  MapRenderByGeofence,
  SortedLiveMapTelemetry,
} from '../../models/realtimeMap'
import { AgentTelemetriesGroupedByTrackingId } from '../../models/telemetry'
import { TelemetryByAgentType } from '../../models/telemetry/baseBadgeTelemetry'
import {
  selectAllFeatureFlags,
  selectCurrentLocation,
  selectFeatureFlagFetchingStatus,
  selectTelemetrySubscriptionState,
} from '../../selectors'
import { useFetchInitialMapTelemetry } from './useFetchInitialMapTelemetry'
import { useRTMapAgentTelemetryState } from './useRTMapAgentTelemetryState'

/**
 * This hook calculate the initial map render to render on the map at initial page load,
 * reloaded map render to rerender icons on the map as user selects agent types, and on filter changed.
 * The calculated data is then passed to subsequent hook for processing of map rendering.
 * @param assetData
 * @param staffData
 * @param geofenceData
 * @param agentTypes
 * @returns initial map render to render on the map at initial page load
 */
export function useRTMapTelemetry(
  assetData: {
    fetchedAssetsByLocation: Asset[]
    isAssetsByLocationFetched: boolean
    assetStatus: FetchingStatus | undefined
    assetFilterCriteria: Array<Criteria<Asset>>
  },
  staffData: {
    fetchedStaffsByLocation: Staff[]
    isStaffsByLocationFetched: boolean
    staffStatus: FetchingStatus | undefined
    allDuressEvents: StaffEvent[]
    allAssistEvents: StaffEvent[]
    staffEventFetchingStatus: FetchingStatus | undefined
    staffFilterCriteria: Array<Criteria<Staff>>
  },
  geofenceData: {
    geofences: Geofences
    geofencesFetchingStatus: FetchingStatus | undefined
  },
  agentTypes: string[],
  filteredDuressLoaded: boolean,
  onSelectAgentTypes: (
    agentTypes: string[],
    updatedMapRender: MapRenderByGeofence
  ) => void
): {
  initialMapRender: MapRenderByGeofence | undefined
  liveMapTelemetriesToUpdate: SortedLiveMapTelemetry | undefined
  reloadedMapRender: MapRenderByGeofence | undefined
  agentTelemetryState: TelemetryByAgentType
  newAgentsEnteringMapTelemetries: AgentTelemetriesGroupedByTrackingId
  isInitialLoaded: boolean
} {
  const {
    fetchedAssetsByLocation,
    isAssetsByLocationFetched,
    assetStatus,
    assetFilterCriteria,
  } = assetData
  const {
    fetchedStaffsByLocation,
    isStaffsByLocationFetched,
    staffStatus,
    allAssistEvents,
    allDuressEvents,
    staffEventFetchingStatus,
    staffFilterCriteria,
  } = staffData
  const { geofences, geofencesFetchingStatus } = geofenceData

  const [reloadedMapRender, setReloadedMapRender] = useState<
    MapRenderByGeofence | undefined
  >(undefined)

  const isInitialLoadedRef = useRef<boolean>(false)
  const agentTelemetriesRef = useRef<TelemetryByAgentType>({
    assetAgentTelemetries: {},
    staffAgentTelemetries: {},
  })
  const allDuressEventsRef = useRef<StaffEvent[]>([])
  const allAssistEventsRef = useRef<StaffEvent[]>([])
  const agentTypesRef = useRef<string[]>(agentTypes)
  const staffDuressAndAssistFeatureFlagEnabledRef = useRef<boolean>(false)
  const geofencesRef = useRef<Geofences>({})
  const currentLocationIdRef = useRef<string | undefined>()
  const assetSearchAndFilterCriteriaRef =
    useRef<Array<Criteria<Asset>>>(assetFilterCriteria)
  const staffSearchAndFilterCriteriaRef =
    useRef<Array<Criteria<Staff>>>(staffFilterCriteria)
  const fetchedAssetsByLocationRef = useRef<Asset[]>(fetchedAssetsByLocation)
  const fetchedStaffsByLocationRef = useRef<Staff[]>(fetchedStaffsByLocation)
  const {
    agentTelemetryState,
    setInitialAgentTelemetryState,
    updateAgentTelemetries,
    addAssetTelemetries,
    addStaffTelemetries,
  } = useRTMapAgentTelemetryState()
  const { assetSearchAndFilterCriteria, staffSearchAndFilterCriteria } =
    useRTMapSearchAndFilters(assetFilterCriteria, staffFilterCriteria)

  const { enabled, mapId } = useSelector(selectTelemetrySubscriptionState)
  const currentLocation = useSelector(selectCurrentLocation)

  const allFeatureFlags = useSelector(selectAllFeatureFlags)
  const featureFlagFetchingStatus = useSelector(selectFeatureFlagFetchingStatus)

  const staffDuressAndAssistFeatureFlagEnabled = useMemo(() => {
    return (
      Object.values(allFeatureFlags).filter(
        (x) => x.name.includes('/duress/staff') && x.enabled
      ).length > 0
    )
  }, [allFeatureFlags])

  allDuressEventsRef.current = allDuressEvents
  allAssistEventsRef.current = Object.values(allAssistEvents)
  agentTypesRef.current = agentTypes
  staffDuressAndAssistFeatureFlagEnabledRef.current =
    staffDuressAndAssistFeatureFlagEnabled
  geofencesRef.current = geofences
  currentLocationIdRef.current = currentLocation.id
  assetSearchAndFilterCriteriaRef.current = assetSearchAndFilterCriteria
  fetchedAssetsByLocationRef.current = fetchedAssetsByLocation
  staffSearchAndFilterCriteriaRef.current = staffSearchAndFilterCriteria
  fetchedStaffsByLocationRef.current = fetchedStaffsByLocation

  // Recalculate telemetries to render on agentTypes changed
  // This useEffect needs to be called before isInitialLoaded is reset to true
  useEffect(() => {
    if (!isInitialLoadedRef.current) {
      return
    }
    const telemetriesToRenderOnMap = filterTelemetries(
      agentTelemetriesRef.current,
      fetchedAssetsByLocationRef.current,
      fetchedStaffsByLocationRef.current,
      agentTypesRef.current,
      assetSearchAndFilterCriteriaRef.current,
      staffSearchAndFilterCriteriaRef.current
    )
    const mapRenderByGeofence = sortInitialFetchedTelemetries(
      telemetriesToRenderOnMap,
      geofencesRef.current
    )
    setReloadedMapRender(mapRenderByGeofence)
    onSelectAgentTypes(agentTypes, mapRenderByGeofence)
  }, [agentTypes, onSelectAgentTypes])

  // Recalculate telemetries to render on search and filter changed
  useEffect(() => {
    if (!isInitialLoadedRef.current) {
      return
    }
    const telemetriesToRenderOnMap = filterTelemetries(
      agentTelemetriesRef.current,
      fetchedAssetsByLocationRef.current,
      fetchedStaffsByLocationRef.current,
      agentTypesRef.current,
      assetSearchAndFilterCriteria,
      staffSearchAndFilterCriteria
    )
    const mapRenderByGeofence = sortInitialFetchedTelemetries(
      telemetriesToRenderOnMap,
      geofencesRef.current
    )
    setReloadedMapRender(mapRenderByGeofence)
  }, [assetSearchAndFilterCriteria, staffSearchAndFilterCriteria])

  const { initialMapRender, isInitialLoaded } = useFetchInitialMapTelemetry(
    {
      fetchedAssetsByLocation,
      isAssetsByLocationFetched,
    },
    {
      fetchedStaffsByLocation,
      isStaffsByLocationFetched,
      allAssistEvents,
      allDuressEvents,
      staffEventFetchingStatus,
    },
    {
      featureFlagFetchingStatus,
      staffDuressAndAssistFeatureFlagEnabled,
    },
    {
      geofences,
      geofencesFetchingStatus,
    },
    agentTypes,
    assetSearchAndFilterCriteria,
    staffSearchAndFilterCriteria,
    mapId,
    filteredDuressLoaded,
    setInitialAgentTelemetryState
  )

  isInitialLoadedRef.current = isInitialLoaded

  const { liveMapTelemetriesToUpdate, newAgentsEnteringMapTelemetries } =
    useCalculateLiveMapTelemetryUpdates(
      enabled,
      mapId,
      isInitialLoaded,
      {
        fetchedAssetsByLocation,
        assetStatus,
      },
      {
        fetchedStaffsByLocation,
        staffStatus,
      },
      {
        allDuressEvents,
        allAssistEvents,
        staffDuressAndAssistFeatureFlagEnabled,
      },
      geofences,
      agentTypes,
      {
        agentTelemetries: agentTelemetryState,
        updateAgentTelemetries,
        addAssetTelemetries,
        addStaffTelemetries,
      }
    )
  agentTelemetriesRef.current = agentTelemetryState

  return {
    initialMapRender,
    reloadedMapRender,
    liveMapTelemetriesToUpdate,
    agentTelemetryState,
    newAgentsEnteringMapTelemetries,
    isInitialLoaded,
  }
}
