import _ from 'lodash'
import { getType } from 'typesafe-actions'
import { OwnerAction } from '../actions'
import { getAllOwnersAction } from '../actions/owners'
import { OwnersState, StringObj } from '../models'
import { FetchingStatus } from '../models/fetchingStatus'
import { setFetchingStatus } from '../utils'

const initialState: OwnersState = {
  data: [],
  status: undefined,
  lastRefreshTime: undefined,
}

export default (
  state: OwnersState = initialState,
  action: OwnerAction
): OwnersState => {
  switch (action.type) {
    case getType(getAllOwnersAction.success):
      return {
        data: action.payload.flatMap((x, index) => ({ id: index, name: x })),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }

    case getType(getAllOwnersAction.request):
      return setFetchingStatus<StringObj[]>(state, FetchingStatus.Request)
    case getType(getAllOwnersAction.failure):
      return setFetchingStatus<StringObj[]>(state, FetchingStatus.Failure)

    default:
      return state
  }
}
