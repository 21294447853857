import React from 'react'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { colors } from '../../styles/MidmarkTheme'
import { Divider, Grid, IconButton, Typography } from '@mui/material'
import { LocationType, SimpleLocation } from '../../models'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import { sortSimpleLocationsByDisplayOrderOrName } from '../../helpers'

const drawerOpenedWidth = 310

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textContainer: {
      height: 48,
      display: 'flex',
      alignItems: 'center',
    },
    campusText: {
      fontSize: 14,
      textDecoration: 'underline',
      color: colors.primary,
      marginLeft: 24,
      marginTop: 12,
      marginBottom: 12,
      overflowWrap: 'break-word',
      width: 226,
      fontWeight: 'bold',
    },
    buildingText: {
      fontSize: 14,
      color: colors.primary,
      marginLeft: 48,
      marginTop: 12,
      marginBottom: 12,
      overflowWrap: 'break-word',
      width: 202,
      fontWeight: 'bold',
    },
    floorText: {
      fontSize: 14,
      color: colors.primary,
      marginLeft: 72,
      overflowWrap: 'break-word',
      width: 238, // No drop down button, so can be wider
    },
    iconButton: {
      fontSize: 19,
      marginRight: 12,
    },
    dividerCampus: {
      width: drawerOpenedWidth,
      marginLeft: 0,
    },
    dividerBuilding: {
      width: drawerOpenedWidth - 24,
      marginLeft: 24,
    },
    dividerFloor: {
      width: drawerOpenedWidth - 48,
      marginLeft: 48,
    },
    highlighted: {
      backgroundColor: 'rgba(84, 200, 232, 0.2)',
    },
  })
)

interface Props {
  location: SimpleLocation
  locationSelected: (id: string) => void
  id: string
  selectedLocationId?: string
  listIndex?: number
}

const LocationListItem: React.FC<Props> = (props: Props) => {
  const { location, locationSelected, id, selectedLocationId, listIndex } =
    props
  const classes = useStyles()
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  return (
    <>
      {location.locationType != LocationType.Floor && listIndex !== 0 && (
        <div
          className={
            location.locationType == LocationType.BuildingGroup
              ? classes.dividerCampus
              : location.locationType == LocationType.Building
              ? classes.dividerBuilding
              : classes.dividerFloor
          }
        >
          <Divider />
        </div>
      )}
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        className={selectedLocationId == location.id ? classes.highlighted : ''}
      >
        <Grid item className={classes.textContainer}>
          <div
            id={id}
            onClick={(): void => {
              locationSelected(location.id)
            }}
            style={{ cursor: 'pointer' }}
          >
            <Typography
              className={
                location.locationType == LocationType.BuildingGroup
                  ? classes.campusText
                  : location.locationType == LocationType.Building
                  ? classes.buildingText
                  : classes.floorText
              }
            >
              {location.name}
            </Typography>
          </div>
        </Grid>
        {location.locationType != LocationType.Floor && (
          <Grid item>
            <IconButton
              id={id + '-expand-collapse'}
              className={classes.iconButton}
              onClick={(): void => setIsOpen(!isOpen)}
              size='large'
            >
              {isOpen ? (
                <KeyboardArrowUpOutlinedIcon color='primary' />
              ) : (
                <KeyboardArrowDownOutlinedIcon color='primary' />
              )}
            </IconButton>
          </Grid>
        )}
      </Grid>
      {location.locationType === LocationType.Building && isOpen && (
        <div className={classes.dividerFloor}>
          <Divider />
        </div>
      )}
      {isOpen && (
        <>
          {sortSimpleLocationsByDisplayOrderOrName(location.subLocations).map(
            (subLocation) => {
              return (
                <LocationListItem
                  key={subLocation.id}
                  location={subLocation}
                  locationSelected={locationSelected}
                  id={subLocation.id}
                  selectedLocationId={selectedLocationId}
                />
              )
            }
          )}
        </>
      )}
    </>
  )
}

export { LocationListItem }
