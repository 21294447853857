import { getCurrentTenantConfigurationAction } from '../../actions/tenantConfiguration'
import { TenantConfiguration, TenantConfigurationState } from '../../models'
import { EntityResult, useEntities } from '../useEntities'

const useFetchCurrentTenantConfiguration =
  (): EntityResult<TenantConfiguration> =>
    useEntities<TenantConfigurationState, TenantConfiguration>(
      () => getCurrentTenantConfigurationAction.request(),
      (state: any) => state.tenantConfiguration
    )

export { useFetchCurrentTenantConfiguration }
