import React, { MouseEvent, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { SaveButton } from '@midmarkrtls/common/components/Buttons/Button'
import { deleteAllVenueAction } from '@midmarkrtls/common/actions/venues'
import {
  useFetchVenues,
  usePermissionCheck,
  useSaveSuccessFailureUtility,
} from '@midmarkrtls/common/hooks'
import { FetchingStatus, PermissionsEnum } from '@midmarkrtls/common/models'
import MapDeleteAllConfirmationModal from './MapDeleteAllConfirmationModal'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      display: 'block',
      minWidth: 30,
      marginLeft: 10,
    },
    container: {
      marginTop: 10,
      marginLeft: 10,
      marginRight: 10,
      marginBottom: 10,
      minWidth: 400,
      borderWidth: 2,
      borderStyle: 'solid',
      paddingBottom: 10,
      paddingTop: 10,
    },
    containerLabel: {
      marginTop: 10,
      marginLeft: 10,
      marginBottom: 10,
    },
  })
)

const RemoveAllVenueComponent: React.FC = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { status: venueStatus } = useFetchVenues()
  const [showDialog, setShowDialog] = useState(false)

  const { isSaving, setIsSaving } = useSaveSuccessFailureUtility(
    venueStatus === FetchingStatus.Success,
    venueStatus === FetchingStatus.Failure,
    `All venues have been removed, please refresh page to see changes.`,
    `There was an error removing Venue information, please try again. If the problem persists, please contact support for assistance.`
  )

  const deleteAllVenue = (event: MouseEvent<HTMLButtonElement>) => {
    setShowDialog(true)
    event.preventDefault()
  }

  const handleContinue = () => {
    setIsSaving(true)
    dispatch(deleteAllVenueAction.request())
    setShowDialog(false)
  }

  const handleClose = () => {
    setShowDialog(false)
  }

  const hasTechnicalPermissions = usePermissionCheck([
    PermissionsEnum.HardwareTechnical,
  ])

  const isProdEnvironment = process.env.NODE_ENV === 'production'

  return (
    <div className={classes.container}>
      <Grid container direction='row'>
        <Grid item>
          <SaveButton
            id='create-duress-save-button'
            disabled={!hasTechnicalPermissions || isProdEnvironment}
            style={{ fontWeight: 'bold', marginRight: 12, marginLeft: 10 }}
            onClick={deleteAllVenue}
            loading={isSaving}
          >
            Delete All Venues
          </SaveButton>
        </Grid>
        <Grid item>
          <Typography
            style={{ color: 'red', fontWeight: 'bold', fontSize: '24px' }}
          >
            PLEASE USE WITH CAUTION!!!!
          </Typography>
        </Grid>
      </Grid>
      <div style={{ marginRight: 12, marginLeft: 10 }}>
        This process will remove all inpixon data, location data, device data,
        and remove the geofences from the SA reference DB
        <br />
        <a
          href={
            'https://dev.azure.com/VersusCarina/Carina/_wiki/wikis/Carina.wiki/473/Venue-Removal-Script'
          }
        >
          Wiki Reference
        </a>
      </div>
      <MapDeleteAllConfirmationModal
        showDialog={showDialog}
        handleClose={handleClose}
        handleContinue={handleContinue}
      />
    </div>
  )
}

export { RemoveAllVenueComponent }
