import { useDispatch, useSelector } from 'react-redux'
import { shouldDispatch } from '../../helpers/shouldDispatch'
import { useEffect } from 'react'
import { AlertSubscriptionKeys, AlertSubscriptionKeyState } from '../../models'
import { getAlertSubscriptionKeysAction } from '../../actions/alertSubscriptionKeys'

const useFetchAlertSubscriptionKeys = (shouldRefresh = false) => {
  const dispatch = useDispatch()
  const status = useSelector(
    ({
      alertSubscriptionKeys,
    }: {
      alertSubscriptionKeys: AlertSubscriptionKeyState
    }) => alertSubscriptionKeys.status
  )
  const lastRefreshTime = useSelector(
    ({
      alertSubscriptionKeys,
    }: {
      alertSubscriptionKeys: AlertSubscriptionKeyState
    }) => alertSubscriptionKeys.lastRefreshTime
  )

  useEffect(() => {
    if (shouldDispatch(status, lastRefreshTime) || shouldRefresh) {
      dispatch(getAlertSubscriptionKeysAction.request())
    }
  }, [status, lastRefreshTime, shouldRefresh, dispatch])

  const alertSubscriptionKeys: AlertSubscriptionKeys = useSelector(
    ({
      alertSubscriptionKeys,
    }: {
      alertSubscriptionKeys: AlertSubscriptionKeyState
    }) => alertSubscriptionKeys.data
  )

  return {
    alertSubscriptionKeys,
    status,
  }
}

export { useFetchAlertSubscriptionKeys }
