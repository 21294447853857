import { calculateLeavingGeofence } from '.'
import { MapRenderByGeofence } from '../../models/realtimeMap'
import {
  InpixonAssetAgentCreateOptions,
  InpixonAssetAgentPositionUpdate,
  InpixonAssetGroupDeleteOptions,
} from '../../models/inpixonMap'
import { BadgeTelemetryMessageWithAgentEvent } from '../../models/telemetry'
import { calculateDegroupingAgents } from '.'

/**
 * For Degroup MapUpdateType, use this function to calculate config values
 * that can be used to later rerender agents on the map including:
 *  - group icon to remove from leaving geofence,
 *  - the config to rerender the leaving
 *  agent as either an Asset or Staff icon followed by showing agent movement on the map,
 *  - the configs to rerender all remaining agents in leaving geofence as either Asset or Staff icon
 * @param newAgentTelemetry
 * @param currentMapRender
 * @param iconScale
 * @param selectedTrackingId
 * @returns group icon to remove from leaving geofence, the config to rerender the leaving
 * agent as either an Asset or Staff icon followed by showing agent movement on the map, and the configs
 * to rerender all remaining agents in leaving geofence as either Asset or Staff icon
 */
export function calculateLeavingGeofenceMapUpdatesToDegroupAgents(
  newAgentTelemetry: BadgeTelemetryMessageWithAgentEvent,
  currentMapRender: MapRenderByGeofence,
  iconScale: number,
  selectedTrackingId: string | undefined
):
  | {
      groupToRemove: InpixonAssetGroupDeleteOptions
      degroupedAgentToUpdatePosition: InpixonAssetAgentPositionUpdate
      agentsToDegroup: InpixonAssetAgentCreateOptions[]
    }
  | undefined {
  const leavingGeofenceData = calculateLeavingGeofence(
    newAgentTelemetry.trackingId,
    currentMapRender
  )
  if (!leavingGeofenceData) {
    return
  }
  const { prevAgentTelemetry, agentTelemetriesInLeavingGeofence } =
    leavingGeofenceData
  const { groupToRemove, degroupedAgentToUpdatePosition, agentsToDegroup } =
    calculateDegroupingAgents(
      prevAgentTelemetry,
      newAgentTelemetry,
      agentTelemetriesInLeavingGeofence,
      iconScale,
      selectedTrackingId
    )
  return {
    groupToRemove,
    degroupedAgentToUpdatePosition,
    agentsToDegroup,
  }
}
