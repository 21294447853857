import { createAsyncAction } from 'typesafe-actions'
import { JibestreamUsers } from '../models/user'

export const getAllUsersAction = createAsyncAction(
  'FETCH_ALL_USERS_REQUEST',
  'FETCH_ALL_USERS_SUCCESS',
  'FETCH_ALL_USERS_FAILURE'
)<void, JibestreamUsers, TypeError>()

/*
  {
    "id": 826,
    "username": "psmidmark@jibestream.com",
    "firstName": "Jibestream",
    "lastName": "Support",
    "email": "psmidmark@jibestream.com",
    "active": 1,
    "role": 1,
    "allowedVenues": [],
    "recordHistory": {
      "createdBy": "admin@jibestream.com",
      "createdAt": 1564690310980,
      "updatedBy": "admin@jibestream.com",
      "updatedAt": 1564690310980
    },
    "lastLogin": 1565961917565
  },
*/

/*
 GET
 /customer/{cid}/user
 Retrieve all users based on the customer ID
 
 POST
 /customer/{cid}/user
 Create a new user based on customer ID
 
 GET
 /customer/{cid}/user/{username}
 Retrieve a user by username
 
 PUT
 /customer/{cid}/user/{username}
 Update an existing user
 
 DELETE
 /customer/{cid}/user/{username}
 Delete a user by username
 
 GET
 /customer/{cid}/user/{username}/venue
 Retrieve all venues that a user is permitted to access
 
 GET
 /customer/{cid}/user/role
 Retrieve all permission roles
 
 GET
 /customer/{cid}/user/role/{rid}
 Retrieve a permission role by ID
*/
