import React, { useState, useEffect } from 'react'
import moment from 'moment'

const buildDateGreaterThan = (
  latestDate: string | Date,
  currentDate: string | Date
) => {
  const momLatestDateTime = moment(latestDate)
  const momCurrentDateTime = moment(currentDate)

  return momLatestDateTime.isAfter(momCurrentDateTime)
}

const isDevelopment = process.env.NODE_ENV === 'development'

const withClearCache = <P extends object>(
  Component: React.ComponentType<P>,
  packageJSON: any
) => {
  function ClearCacheComponent(props: any) {
    const [isLatestBuildDate, setIsLatestBuildDate] = useState(false)

    useEffect(() => {
      if (!isDevelopment) {
        fetch('/meta.json')
          .then((response) => response.json())
          .then((meta) => {
            const latestVersionDate = meta.buildDate
            const currentVersionDate = packageJSON.buildDate

            localStorage.setItem('currentVersionDate', currentVersionDate)

            console.log('Latest version date: ' + currentVersionDate)
            console.log('Current version date: ' + currentVersionDate)

            const shouldForceRefresh = buildDateGreaterThan(
              latestVersionDate,
              currentVersionDate
            )
            if (shouldForceRefresh) {
              setIsLatestBuildDate(false)
              refreshCacheAndReload()
            } else {
              setIsLatestBuildDate(true)
            }
          })
          .catch((err) => console.log('Error reading build date ' + err))
      }
    }, [])

    const refreshCacheAndReload = () => {
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {
          for (const name of names) {
            console.log('Clearing cache: ' + name)
            caches.delete(name)
          }
        })
      }
      // delete browser cache and hard reload
      console.log('Build out of date. Reloading...')
      window.location.reload()
    }

    return (
      <React.Fragment>
        {isDevelopment || isLatestBuildDate ? <Component {...props} /> : null}
      </React.Fragment>
    )
  }

  return ClearCacheComponent
}

export default withClearCache
