import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchMapReferenceViewsByVenueIdAction } from '../../actions/mapReferenceViews'
import { MapReferenceViews, MapReferenceViewState } from '../../models'
import { EntityResult } from '../useEntities'

let cachedVenueId: number | undefined

const useMapReferenceViews = (
  venueId: number | undefined
): EntityResult<MapReferenceViews> => {
  const dispatch = useDispatch()
  const { data, status } = useSelector(
    ({ mapReferenceViews }: { mapReferenceViews: MapReferenceViewState }) =>
      mapReferenceViews
  )

  useEffect(() => {
    if (venueId && cachedVenueId !== venueId) {
      cachedVenueId = venueId
      dispatch(fetchMapReferenceViewsByVenueIdAction.request(venueId))
    }
  }, [cachedVenueId, venueId])

  return { data, status }
}

export { useMapReferenceViews }
