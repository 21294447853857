import { BaseService } from './baseService'
import { SensoryReference } from '../models/SensoryReference'
import { MapReferenceView } from '../models'

export class SensoryReferenceApi extends BaseService {
  constructor() {
    super({ entityType: 'sensoryReference' })
  }
  route = `/sensoryreference`

  async getAllSensoryReferences(): Promise<SensoryReference[]> {
    return await this.get<SensoryReference[]>(`${this.route}`)
  }

  async postImportSensoryReferences(
    mapReferences: MapReferenceView[]
  ): Promise<SensoryReference[]> {
    return await this.post<SensoryReference[]>(
      `${this.route}/Import`,
      mapReferences
    )
  }
}
