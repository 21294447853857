import { createAsyncAction, createAction } from 'typesafe-actions'
import { MapReferenceView } from '../models/mapReferenceViews'

export const fetchAllMapReferenceViewsAction = createAsyncAction(
  'FETCH_ALL_MAP_REFERENCE_VIEWS_REQUEST',
  'FETCH_ALL_MAP_REFERENCE_VIEWS_SUCCESS',
  'FETCH_ALL_MAP_REFERENCE_VIEWS_FAILURE'
)<void, MapReferenceView[], { error: Error }>()

export const fetchMapReferenceViewsByVenueIdAction = createAsyncAction(
  'FETCH_MAP_REFERENCE_VIEWS_BY_VENUE_ID_REQUEST',
  'FETCH_MAP_REFERENCE_VIEWS_BY_VENUE_ID_SUCCESS',
  'FETCH_MAP_REFERENCE_VIEWS_BY_VENUE_ID_FAILURE'
)<number, MapReferenceView[], { error: Error }>()

export const fetchMapReferenceViewsByFloorLocationIdAction = createAsyncAction(
  'FETCH_MAP_REFERENCE_VIEWS_BY_FLOOR_LOCATION_ID_REQUEST',
  'FETCH_MAP_REFERENCE_VIEWS_BY_FLOOR_LOCATION_ID_SUCCESS',
  'FETCH_MAP_REFERENCE_VIEWS_BY_FLOOR_LOCATION_ID_FAILURE'
)<string, MapReferenceView[], { error: Error }>()

export const fetchMapReferenceViewByDeviceGuidAction = createAsyncAction(
  'FETCH_MAP_REFERENCE_VIEW_BY_DEVICE_GUID_REQUEST',
  'FETCH_MAP_REFERENCE_VIEW_BY_DEVICE_GUID_SUCCESS',
  'FETCH_MAP_REFERENCE_VIEW_BY_DEVICE_GUID_FAILURE'
)<string, MapReferenceView, { error: Error }>()

export const removeMapReferenceViewByDeviceGuid = createAction(
  'REMOVE_MAP_REFERENCE_VIEW_BY_DEVICE_GUID'
)<string>()

export const refreshMapReferenceViewByDeviceGuid = createAction(
  'REFRESH_MAP_REFERENCE_VIEW_BY_DEVICE_GUID'
)<string>()
