import { Collapse } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { useEffect } from 'react'
import { StaffEvent } from '../../../models'
import { alertModalGap } from '../constants'
import { StaffAssistModal } from './StaffAssistModal'

interface Props {
  staffAssistEvent: StaffEvent
  totalStaffAssistEvents: number
  showStaffAssistExpansionPanelFooter: boolean
  isMostRecentStaffAssistAlert: boolean
  showAllStaffAssistNotifications: boolean
  setMostRecentStaffAssistModalHeight: (height: number) => void
  setShowAllStaffAssistNotifications: (showAll: boolean) => void
}

const useStyles = makeStyles(() => ({
  root: {
    height: alertModalGap,
  },
}))

const StaffAssistAlert = (props: Props): JSX.Element | null => {
  const {
    staffAssistEvent,
    totalStaffAssistEvents,
    showStaffAssistExpansionPanelFooter,
    isMostRecentStaffAssistAlert,
    showAllStaffAssistNotifications,
    setMostRecentStaffAssistModalHeight,
    setShowAllStaffAssistNotifications,
  } = props

  const staffAssistModalRef =
    React.useRef<HTMLDivElement>() as React.RefObject<HTMLDivElement>

  const classes = useStyles()

  useEffect(() => {
    if (staffAssistModalRef.current?.clientHeight) {
      setMostRecentStaffAssistModalHeight(
        staffAssistModalRef.current.clientHeight
      )
    }
    // eslint-disable-next-line
  }, [staffAssistModalRef.current?.clientHeight])

  return isMostRecentStaffAssistAlert ? (
    <div key={staffAssistEvent.id} ref={staffAssistModalRef}>
      <StaffAssistModal
        staffAssistEvent={staffAssistEvent}
        totalStaffAssistEvents={totalStaffAssistEvents}
        showExpansionPanelFooter={showStaffAssistExpansionPanelFooter}
        isShowMoreExpanded={showAllStaffAssistNotifications}
        setIsShowMoreExpanded={setShowAllStaffAssistNotifications}
      />
      {showStaffAssistExpansionPanelFooter && (
        <div className={classes.root}></div>
      )}
    </div>
  ) : (
    <div key={staffAssistEvent.id}>
      <Collapse in={showAllStaffAssistNotifications}>
        <StaffAssistModal
          staffAssistEvent={staffAssistEvent}
          showExpansionPanelFooter={false}
          isShowMoreExpanded={showAllStaffAssistNotifications}
          setIsShowMoreExpanded={setShowAllStaffAssistNotifications}
        />
      </Collapse>
      {showAllStaffAssistNotifications && <div className={classes.root}></div>}
    </div>
  )
}

export { StaffAssistAlert }
