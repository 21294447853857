import { Link } from '@mui/material'
import React from 'react'
import { Button } from '../Buttons'
import { DialogActionButtonType } from './DialogActionButtonType'

interface Props {
  actionButton: DialogActionButtonType
}

const DialogButton = (props: Props) => {
  const {
    label,
    action,
    type = 'button',
    color = '#236092',
    backgroundColor,
    disabled = false,
    variant = 'text',
    loading = false,
  } = props.actionButton
  return variant === 'link' ? (
    <Link
      component='button'
      variant='body1'
      onClick={action}
      underline='none'
      style={{
        fontWeight: 'bold',
        fontSize: 14,
        color: color,
      }}
    >
      {label}
    </Link>
  ) : (
    <Button
      type={type}
      onClick={action}
      style={{ margin: '0 4px', fontWeight: 'bold', color, backgroundColor }}
      disabled={disabled}
      variant={variant}
      bgColor={backgroundColor ?? ''}
      textColor={color}
      loading={loading}
    >
      {label}
    </Button>
  )
}

export default DialogButton
