import { createAsyncAction } from 'typesafe-actions'
import { Import, ImportCreate } from 'v2/models'
import { HttpErrorResponse } from '@midmarkrtls/common/services/http'

export const getAllUsersImportsAction = createAsyncAction(
  'GET_ALL_IMPORTS_USERS_REQUEST',
  'GET_ALL_IMPORTS_USERS_SUCCESS',
  'GET_ALL_IMPORTS_USERS_FAILURE'
)<void, Import[], HttpErrorResponse>()

export const postImportUsersAction = createAsyncAction(
  'POST_IMPORT_USERS_REQUEST',
  'POST_IMPORT_USERS_SUCCESS',
  'POST_IMPORT_USERS_FAILURE'
)<ImportCreate, Import, HttpErrorResponse>()
