import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { BadgeAssignedCountAction } from '../actions'
import { BadgeAssignedCountState } from '../models/badgeAssignedCount'
import { BadgeAssignedCountApi } from '../middleware'
import { getBadgeAssignedCountAction } from '../actions/badgeAssignedCount'

type BadgeAssignedCountEpic = Epic<
  BadgeAssignedCountAction,
  BadgeAssignedCountAction,
  BadgeAssignedCountState,
  { badgeAssignedCountApi: BadgeAssignedCountApi }
>

export const getBadgeAssignedCountEpic: BadgeAssignedCountEpic = (
  action$,
  state$,
  { badgeAssignedCountApi }
) => {
  return action$.pipe(
    filter(isActionOf(getBadgeAssignedCountAction.request)),
    mergeMap((action) =>
      from(badgeAssignedCountApi.getBadgeAssignedCount()).pipe(
        map((count) => getBadgeAssignedCountAction.success(count)),
        catchError((error) => of(getBadgeAssignedCountAction.failure(error)))
      )
    )
  )
}
