import { StaffSubType } from '../models'
import { BaseService } from './baseService'

export class StaffSubTypesApi extends BaseService {
  constructor() {
    super({ entityType: 'staffSubTypes' })
  }
  route = 'staff/subTypes'

  async getAllStaffSubTypes(): Promise<StaffSubType[]> {
    return await this.get<StaffSubType[]>(this.route)
  }
}
