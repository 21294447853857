import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import React, { ReactNode } from 'react'
import { FlexBox } from '.'
import { StyledComponent, StyledComponentProps, stylize } from '..'
import { useRightDrawerController } from './useRightDrawerController'

const styles = createStyles({
  root: {
    background: '#fafafa',
    position: 'relative',
    maxHeight: (props: Props) => `${props.maxHeight}px)`,
    overflowY: 'hidden',
    height: '100%',
    overflow: 'auto',
  },
  footerless: {
    background: '#fafafa',
    position: 'relative',
  },
  content: {
    width: '100%',
    height: '100%',
    position: 'relative',
    minHeight: 0,
    maxHeight: (props: Props) => `${props.maxHeight}px)`,
    overflowY: (props: Props) => (props.hideScrollbar ? 'hidden' : 'auto'),
  },
})

interface Props {
  fillContainer?: boolean
  withoutFooter?: boolean
  maxHeight: number
  hideScrollbar?: boolean
  content: ReactNode
  rightDrawer?: ReactNode
  currentRightDrawerType?: DrawerType
  openRightDrawer?: boolean
}

export enum DrawerType {
  None,
  DetailDrawer,
  FilterDrawer,
  GeofencesDetailDrawer,
  RealTimeAssetDetailDrawer,
  RealTimeStaffDetailDrawer,
  RealTimeGroupDetailDrawer,
}

const PageContent: StyledComponent<Props> = (
  props: StyledComponentProps<Props, typeof styles>
) => {
  const {
    classes,
    content,
    rightDrawer,
    withoutFooter,
    currentRightDrawerType,
    openRightDrawer,
  } = props

  useRightDrawerController(currentRightDrawerType, openRightDrawer)
  return (
    <FlexBox
      layout='row'
      flex='grow'
      {...stylize(
        props,
        withoutFooter === true ? [classes.footerless] : [classes.root]
      )}
    >
      <div className={classes.content}>{content}</div>
      {rightDrawer}
    </FlexBox>
  )
}

export default withStyles(styles, { name: 'PageContent' })(PageContent)
