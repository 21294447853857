import { Epic } from 'redux-observable'
import { AlertTypeAction } from '../actions'
import { AlertTypesState } from '../models'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { getAllAlertTypesAction } from '../actions/alertTypes'
import { AlertTypesApi } from '../middleware'
import { from, of } from 'rxjs'

type AlertTypeEpic = Epic<
  AlertTypeAction,
  AlertTypeAction,
  AlertTypesState,
  { alertTypesApi: AlertTypesApi }
>

export const getAllAlertTypesEpic: AlertTypeEpic = (
  action$,
  state,
  { alertTypesApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllAlertTypesAction.request)),
    mergeMap((action) =>
      from(alertTypesApi.getAllAlertTypes()).pipe(
        map((alertTypes) => getAllAlertTypesAction.success(alertTypes)),
        catchError((error) => of(getAllAlertTypesAction.failure(error)))
      )
    )
  )
}
