import { FetchingState } from '../interfaces/fetchingState'

export interface UserPreferences {
  locationId?: string
  unitId?: string
  timeDisplayId: number
}

export interface UserLocationPreferenceEdit {
  campus?: string
  building?: string
  floor?: string
}

export enum TimeDisplayPreferenceIds {
  TwelveHour = 0,
  TwentyFourHour = 1,
}

export interface TimeDisplayPreference {
  id: number
  name: string
}

export const TimeDisplayPreferences: TimeDisplayPreference[] = [
  {
    id: 0,
    name: '12 Hour',
  },
  {
    id: 1,
    name: '24 Hour',
  },
]

export type UserPreferencesState = Readonly<FetchingState<UserPreferences>>
