import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import QuickActionCard, {
  IconType,
} from '../../../components/Cards/QuickActionCard'
import DetailsPaneHeader from '../../../components/Drawer/DetailsPaneHeader'
import { Unit } from '../../../models'

interface Props {
  unit: Unit
  onClose: () => void
  onEditUnit: () => void
}

const useStyles = makeStyles(() =>
  createStyles({
    quickActionButtonBody: {
      display: 'flex',
      justifyContent: 'center',
      margin: 12,
    },
    quickActionButtonLeft: {
      paddingRight: '20px',
    },
  })
)

function UnitManagementDetailsPaneHeader(props: Props): React.ReactElement {
  const { unit, onClose, onEditUnit } = props
  const classes = useStyles()

  return (
    <>
      <div>
        <DetailsPaneHeader handleClose={onClose} headerText={`${unit?.name}`} />
      </div>
      <div className={classes.quickActionButtonBody}>
        <div className={classes.quickActionButtonLeft}>
          <QuickActionCard
            cardText='Edit Unit Information'
            cardIconType={IconType.EditOutlined}
            onActionAreaClick={onEditUnit}
          />
        </div>
      </div>
    </>
  )
}

export default UnitManagementDetailsPaneHeader
