import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { StaffAction, UserAction } from '../actions'
import { postStaffAction, putStaffAction } from '../actions/staff'
import {
  getAllUsersAction,
  getUserByIdAction,
  getUserByEmailAction,
  postUserAction,
  putUserAction,
  deleteUserAction,
  postStaffUserAction,
  putStaffUserAction,
} from '../actions/users'
import { UsersApi } from '../middleware/users'
import { UsersState } from '../models/users'

type UsersEpic = Epic<
  UserAction | StaffAction,
  UserAction | StaffAction,
  UsersState | StaffAction,
  { usersApi: UsersApi }
>

export const getAllUsersActionEpic: UsersEpic = (
  action$,
  state$,
  { usersApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllUsersAction.request)),
    mergeMap(() =>
      from(usersApi.getAllUsers()).pipe(
        map((payload) => getAllUsersAction.success(payload)),
        catchError((error) => of(getAllUsersAction.failure(error)))
      )
    )
  )
}

export const getUserByIdActionEpic: UsersEpic = (
  action$,
  state$,
  { usersApi }
) => {
  return action$.pipe(
    filter(isActionOf(getUserByIdAction.request)),
    mergeMap((action) =>
      from(usersApi.getUserById(action.payload)).pipe(
        map((payload) => getUserByIdAction.success(payload)),
        catchError((error) => of(getUserByIdAction.failure(error)))
      )
    )
  )
}

export const getUserByEmailActionEpic: UsersEpic = (
  action$,
  state$,
  { usersApi }
) => {
  return action$.pipe(
    filter(isActionOf(getUserByEmailAction.request)),
    mergeMap((action) =>
      from(usersApi.getUserByEmail(action.payload)).pipe(
        map((payload) => getUserByEmailAction.success(payload)),
        catchError((error) => of(getUserByEmailAction.failure(error)))
      )
    )
  )
}

export const postUsersActionEpic: UsersEpic = (
  action$,
  state$,
  { usersApi }
) => {
  return action$.pipe(
    filter(isActionOf(postUserAction.request)),
    mergeMap((action) =>
      from(usersApi.createUser(action.payload)).pipe(
        map((payload) => postUserAction.success(payload)),
        catchError((error) => of(postUserAction.failure(error)))
      )
    )
  )
}

export const postUsersStaffActionEpic: UsersEpic = (
  action$,
  state$,
  { usersApi }
) => {
  return action$.pipe(
    filter(isActionOf(postStaffUserAction.request)),
    mergeMap((action) =>
      from(usersApi.createUser(action.payload.user)).pipe(
        mergeMap((payload) => [
          postUserAction.success(payload),
          action.payload.staff.agentGuid
            ? putStaffAction.request({
                ...action.payload.staff,
                userId: payload.id,
              })
            : postStaffAction.request({
                ...action.payload.staff,
                userId: payload.id,
              }),
        ]),
        catchError((error) => of(postUserAction.failure(error)))
      )
    )
  )
}

export const putUsersActionEpic: UsersEpic = (
  action$,
  state$,
  { usersApi }
) => {
  return action$.pipe(
    filter(isActionOf(putUserAction.request)),
    mergeMap((action) =>
      from(usersApi.updateUser(action.payload)).pipe(
        map((payload) => putUserAction.success(payload)),
        catchError((error) => of(putUserAction.failure(error)))
      )
    )
  )
}

export const putUsersStaffActionEpic: UsersEpic = (
  action$,
  state$,
  { usersApi }
) => {
  return action$.pipe(
    filter(isActionOf(putStaffUserAction.request)),
    mergeMap((action) =>
      from(usersApi.updateUser(action.payload.user)).pipe(
        mergeMap((payload) => [
          putUserAction.success(payload),
          action.payload.staff.agentGuid
            ? putStaffAction.request({
                ...action.payload.staff,
                userId: payload.id,
              })
            : postStaffAction.request({
                ...action.payload.staff,
                userId: payload.id,
              }),
        ]),
        catchError((error) => of(putUserAction.failure(error)))
      )
    )
  )
}

export const deleteUsersActionEpic: UsersEpic = (
  action$,
  state$,
  { usersApi }
) => {
  return action$.pipe(
    filter(isActionOf(deleteUserAction.request)),
    mergeMap((action) =>
      from(usersApi.deleteUser(action.payload)).pipe(
        map((payload) => deleteUserAction.success(payload)),
        catchError((error) => of(deleteUserAction.failure(error)))
      )
    )
  )
}
