import React from 'react'
import { Typography } from '@mui/material'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() =>
  createStyles({
    sectionList: {
      display: 'table',
      width: '100%',
      height: 'fit-content',
      margin: 0,
      flexGrow: 1,

      '& > div': {
        display: 'table-row',
        fontFamily: 'Ubuntu',
        fontSize: '16px',

        '& > dt': {
          display: 'table-cell',
          paddingRight: '20px',
          minWidth: '130px',
          color: '#333',
          whiteSpace: 'nowrap',
        },

        '& > dd': {
          display: 'table-cell',
          paddingBottom: '8px',
          textAlign: 'right',
          color: '#979797',
          wordBreak: 'break-word',
        },
      },
    },
  })
)

interface Props {
  style?: React.CSSProperties
  keyValDictionary: object
}

const UserProfileColumnText: React.FC<Props> = (props: Props) => {
  const { style, keyValDictionary } = props
  const classes = useStyles()

  return (
    <dl style={style} className={classes.sectionList}>
      {Object.keys(keyValDictionary).map((x, i) => {
        return (
          <div key={i}>
            <dt>
              <Typography>{x}</Typography>
            </dt>
            <dd>
              <Typography>
                {keyValDictionary[x as keyof typeof keyValDictionary]}
              </Typography>
            </dd>
          </div>
        )
      })}
    </dl>
  )
}

export default UserProfileColumnText
