import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v1 as uuidv1 } from 'uuid'
import { setCurrentUserTelemetryMapIdAction } from '../actions/currentUserTelemetrySubscription'
import { Authentication } from '../authentication/AuthenticationManager'
import { BadgeTelemetryMessageExtended } from '../models'
import { selectCurrentUserLocation } from '../selectors'
import { useCurrentUserTelemetrySubscription } from './useCurrentUserTelemetrySubscription'
import { fetchAccessTokenAsync } from './useFetchAccessToken'
import useSignalRSubscription from './useSignalRSubscription'

const url = process.env.REACT_APP_SIGNALR_CONNECTION_MANAGER
const id = uuidv1()

export function useCurrentUserTelemetry():
  | BadgeTelemetryMessageExtended
  | undefined {
  const dispatch = useDispatch()
  const [currentMapIdSubscription, setCurrentMapIdSubscription] =
    useState<number>()
  const [tenantId, setTenantId] = useState<string>(
    Authentication.currentCustomerId
  )
  const { enabled, mapId, previousMapId } =
    useCurrentUserTelemetrySubscription()

  const currentUserLocation = useSelector(selectCurrentUserLocation)

  const handleSubscription = async (
    token: string,
    currentMapId: number | undefined,
    endpoint: string
  ) => {
    if (endpoint === 'subscribe') {
      setTenantId(Authentication.currentCustomerId)
    }
    await fetch(
      `${process.env.REACT_APP_SIGNALR_CONNECTION_MANAGER}telemetry/${endpoint}`,
      {
        method: 'POST',
        headers: {
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          userId: id,
          mapId: currentMapId,
          tenantId: tenantId,
        }),
      }
    )
  }

  // update filters
  useEffect(() => {
    fetchAccessTokenAsync().then((token) => {
      if (enabled) {
        if (previousMapId !== undefined && previousMapId != mapId) {
          handleSubscription(token, previousMapId, 'unsubscribe')
        }
        if (mapId !== undefined && mapId !== currentMapIdSubscription) {
          setCurrentMapIdSubscription(mapId)
          handleSubscription(token, mapId, 'subscribe')
        }
      } else {
        if (previousMapId !== undefined) {
          handleSubscription(token, previousMapId, 'unsubscribe')
        }
        if (mapId !== undefined) {
          handleSubscription(token, mapId, 'unsubscribe')
        }
      }
    })
  }, [mapId, enabled])

  // signalr feed
  const telemetry = useSignalRSubscription<BadgeTelemetryMessageExtended>({
    url,
    method: 'RCVTLMY',
    id,
  })

  const currentUserTelemetry = telemetry?.find(
    (x) => x.agent?.id === currentUserLocation?.agentGuid
  )

  useEffect(() => {
    if (
      currentUserTelemetry !== undefined &&
      currentUserTelemetry.mapId !== mapId
    ) {
      dispatch(setCurrentUserTelemetryMapIdAction(currentUserTelemetry?.mapId))
    }
  }, [currentUserTelemetry?.mapId])

  return currentUserTelemetry
}
