import { useMemo } from 'react'
import { useAuth } from '.'
import { PermissionsEnum } from '../models'

export const usePermissionCheck = (permissions: PermissionsEnum[]): boolean => {
  const { permissions: currentUserPermissions } = useAuth()
  return useMemo(() => {
    return permissions.every((permission) =>
      currentUserPermissions.includes(permission)
    )
  }, [permissions, currentUserPermissions])
}
