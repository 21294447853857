import { Epic } from 'redux-observable'
import { isActionOf } from 'typesafe-actions'
import { from, of } from 'rxjs'
import { filter, mergeMap, map, catchError } from 'rxjs/operators'
import { MapReferenceViewAction } from '../actions'
import {
  fetchMapReferenceViewsByVenueIdAction,
  fetchMapReferenceViewByDeviceGuidAction,
  fetchMapReferenceViewsByFloorLocationIdAction,
  fetchAllMapReferenceViewsAction,
} from '../actions/mapReferenceViews'
import { MapReferenceViewApi } from '../middleware/mapReferenceViews'
import { MapReferenceViewState } from '../models'

type MapReferenceViewEpic = Epic<
  MapReferenceViewAction,
  MapReferenceViewAction,
  MapReferenceViewState,
  { mapReferenceViewApi: MapReferenceViewApi }
>

export const fetchMapReferenceViewsByVenueIdEpic: MapReferenceViewEpic = (
  action$,
  state$,
  { mapReferenceViewApi }
) => {
  return action$.pipe(
    filter(isActionOf(fetchMapReferenceViewsByVenueIdAction.request)),
    mergeMap((action) =>
      from(
        mapReferenceViewApi.fetchMapReferenceViewsByVenueId(action.payload)
      ).pipe(
        map((mapReferenceViews) =>
          fetchMapReferenceViewsByVenueIdAction.success(mapReferenceViews)
        ),
        catchError((error) =>
          of(fetchMapReferenceViewsByVenueIdAction.failure({ error }))
        )
      )
    )
  )
}
export const fetchMapReferenceViewsByLocationIdEpic: MapReferenceViewEpic = (
  action$,
  state$,
  { mapReferenceViewApi }
) => {
  return action$.pipe(
    filter(isActionOf(fetchMapReferenceViewsByFloorLocationIdAction.request)),
    mergeMap((action) =>
      from(
        mapReferenceViewApi.fetchMapReferenceViewsByFloorLocationId(
          action.payload
        )
      ).pipe(
        map((mapReferenceViews) =>
          fetchMapReferenceViewsByFloorLocationIdAction.success(
            mapReferenceViews
          )
        ),
        catchError((error) =>
          of(fetchMapReferenceViewsByFloorLocationIdAction.failure({ error }))
        )
      )
    )
  )
}

export const fetchMapReferenceViewByDeviceGuidEpic: MapReferenceViewEpic = (
  action$,
  state$,
  { mapReferenceViewApi }
) => {
  return action$.pipe(
    filter(isActionOf(fetchMapReferenceViewByDeviceGuidAction.request)),
    mergeMap((action) =>
      from(
        mapReferenceViewApi.fetchMapReferenceViewByDeviceGuid(action.payload)
      ).pipe(
        map((mapReferenceView) =>
          fetchMapReferenceViewByDeviceGuidAction.success(mapReferenceView)
        ),
        catchError((error) =>
          of(fetchMapReferenceViewByDeviceGuidAction.failure({ error }))
        )
      )
    )
  )
}

export const fetchAllMapReferenceViewsByVenueIdEpic: MapReferenceViewEpic = (
  action$,
  state$,
  { mapReferenceViewApi }
) => {
  return action$.pipe(
    filter(isActionOf(fetchAllMapReferenceViewsAction.request)),
    mergeMap(() =>
      from(mapReferenceViewApi.fetchAllMapReferenceViews()).pipe(
        map((mapReferenceViews) =>
          fetchAllMapReferenceViewsAction.success(mapReferenceViews)
        ),
        catchError((error) =>
          of(fetchAllMapReferenceViewsAction.failure({ error }))
        )
      )
    )
  )
}
