import { AgentType, Location } from '../models'
import { SignalType } from '../models/signalType'

export enum BatteryStatus {
  Low = 'Low',
  Good = 'Good',
}

export const batteryStatus = (isLowBattery?: boolean): string | undefined => {
  if (isLowBattery !== undefined && isLowBattery !== null) {
    return isLowBattery ? BatteryStatus.Low : BatteryStatus.Good
  }
  return undefined
}

export const getLocationBasedBatteryStatus = (
  location: Location | undefined,
  isLowBattery?: boolean
): string | undefined => {
  return location ? batteryStatus(isLowBattery) : undefined
}

export const batteryPercent = (
  currentSignalType: number,
  batteryPercent?: number,
  isLowBattery?: boolean
): string | undefined => {
  if (batteryStatus(isLowBattery)) {
    if (batteryPercent) {
      if (currentSignalType === SignalType.BLE) {
        return `${batteryPercent}%`
      }
      return 'N/A'
    }
  }

  return undefined
}

export const getLocationBasedBatteryPercent = (
  location: Location | undefined,
  currentSignalType: number,
  percent?: number,
  isLowBattery?: boolean
): string | undefined => {
  return location
    ? batteryPercent(currentSignalType, percent, isLowBattery)
    : undefined
}

export const isLowBattery = (isLowBattery?: boolean): boolean =>
  isLowBattery ?? false

export const getSignalType = (signalTypes: number[]): string => {
  return [
    ...new Set(
      signalTypes?.map((item) => {
        switch (item) {
          case SignalType['IR/RF']:
            return 'RF'
          case SignalType.Unknown:
            return '-'
          default:
            return SignalType[item]
        }
      })
    ),
  ].join('/')
}

export const getBatteryPercent = (
  signalTypes: number[] | undefined,
  batteryPercentage?: number | undefined
): string => {
  if (!signalTypes || !batteryPercentage) {
    return '-'
  }

  if (getSignalType(signalTypes) === '-') {
    return '-'
  }

  return batteryPercent(signalTypes[0], batteryPercentage) ?? '-'
}

export const getBatteryStatus = (
  signalTypes: number[] | undefined,
  isLowBattery?: boolean
): string => {
  if (!signalTypes || isLowBattery === undefined || isLowBattery === null) {
    return '-'
  }

  if (getSignalType(signalTypes) === '-') {
    return '-'
  }

  return batteryStatus(isLowBattery) ?? '-'
}

export const getAgentType = (agentType?: number | undefined): string => {
  if (agentType !== null && agentType !== undefined) {
    return AgentType[agentType]
  }
  return '-'
}
