import { UserRouter } from '../models/userRouter'
import { BaseService } from './baseService'

export class UserRoutersApi extends BaseService {
  constructor() {
    super({ entityType: 'userRouters' })
  }
  route = 'alert/userRouters'

  async getAllUserRouters(): Promise<UserRouter[]> {
    return await this.get<UserRouter[]>(this.route)
  }
}
