import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { UserRouterAction } from '../actions'
import { getAllUserRoutersAction } from '../actions/userRouters'
import { UserRoutersApi } from '../middleware'
import { UserRouterState } from '../models/userRouter'

type UserRouterEpic = Epic<
  UserRouterAction,
  UserRouterAction,
  UserRouterState,
  { userRoutersApi: UserRoutersApi }
>

export const getAllUserRoutersEpic: UserRouterEpic = (
  action$,
  state,
  { userRoutersApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllUserRoutersAction.request)),
    mergeMap((action) =>
      from(userRoutersApi.getAllUserRouters()).pipe(
        map((userRouters) => getAllUserRoutersAction.success(userRouters)),
        catchError((error) => of(getAllUserRoutersAction.failure(error)))
      )
    )
  )
}
