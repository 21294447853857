import commonMiddlewares from '@midmarkrtls/common/middleware'
import { FeatureFlagApi } from '@midmarkrtls/common/middleware/featureFlags'
import { MiddlewarePieces } from '@midmarkrtls/common/store/middleware'
import { applyMiddleware, compose, Middleware } from 'redux'
import { createLogger } from 'redux-logger'
import { createEpicMiddleware, EpicMiddleware } from 'redux-observable'
import {
  AdUsersApi,
  CreateEventApi,
  ImportsAssetsApi,
  ImportsBadgesApi,
  ImportsStaffApi,
  ImportsUsersApi,
  JibestreamApi,
  OperationsPermissionsApi,
  OperationsRolesApi,
  OperationsUsersApi,
  TenantsApi,
  TenantTagNumbersApi,
} from 'v2/middleware'
import RootAction from '../actions'
import { RootState } from '../reducers'

function setupEpicMiddleware(): EpicMiddleware<
  RootAction,
  RootAction,
  RootState
> {
  return createEpicMiddleware<RootAction, RootAction, RootState>({
    dependencies: {
      ...commonMiddlewares,
      adUsersApi: new AdUsersApi(),
      importsAssetsApi: new ImportsAssetsApi(),
      importsBadgesApi: new ImportsBadgesApi(),
      importsStaffApi: new ImportsStaffApi(),
      importsUsersApi: new ImportsUsersApi(),
      createEventApi: new CreateEventApi(),
      featureFlagApi: new FeatureFlagApi(),
      jibestreamApi: new JibestreamApi(),
      operationsPermissionsApi: new OperationsPermissionsApi(),
      operationsRolesApi: new OperationsRolesApi(),
      operationsUsersApi: new OperationsUsersApi(),
      tenantsApi: new TenantsApi(),
      tenantTagNumbersApi: new TenantTagNumbersApi(),
    },
  })
}

function setupEnhancer(middlewares: Middleware[]) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const extensionCompose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  const enhancer = extensionCompose
    ? extensionCompose(applyMiddleware(...middlewares))
    : compose(applyMiddleware(...middlewares))
  return enhancer
}

const setupMiddleware = (): MiddlewarePieces<RootAction, RootState> => {
  const epicMiddleware = setupEpicMiddleware()
  const logger = createLogger({
    collapsed: true,
    predicate: () => process.env.REACT_APP_ENABLE_REDUX_LOGGER === 'true',
  })

  const middlewares = [epicMiddleware, logger]

  const enhancer = setupEnhancer(middlewares)

  return {
    epic: epicMiddleware,
    logger,
    enhancer,
  }
}

export default setupMiddleware
