import { createAction, createAsyncAction } from 'typesafe-actions'
import {
  AgentEventStaffEventEpicMapper,
  AgentEventLocation,
  AgentEventLocationUpdate,
  StaffEvent,
} from '../models'
import { HttpErrorResponse } from '../services/http'

export const getAllAgentEventsAction = createAsyncAction(
  'GET_ALL_AGENT_EVENTS_REQUEST',
  'GET_ALL_AGENT_EVENTS_SUCCESS',
  'GET_ALL_AGENT_EVENTS_FAILURE'
)<void, AgentEventLocation[], HttpErrorResponse>()

export const setStaffEvents =
  createAction('SET_STAFF_EVENTS')<AgentEventStaffEventEpicMapper[]>()

export const resolveStaffEvent = createAction('RESOLVE_STAFF_EVENT')<
  Pick<StaffEvent, 'agentId' | 'badgeId'>
>()

export const getAgentEventAction = createAsyncAction(
  'GET_AGENT_EVENTS_REQUEST',
  'GET_AGENT_EVENTS_SUCCESS',
  'GET_AGENT_EVENTS_FAILURE'
)<string, AgentEventLocation, HttpErrorResponse>()

export const putAgentEventAction = createAsyncAction(
  'PUT_AGENT_EVENTS_REQUEST',
  'PUT_AGENT_EVENTS_SUCCESS',
  'PUT_AGENT_EVENTS_FAILURE'
)<AgentEventLocationUpdate, AgentEventLocation, HttpErrorResponse>()

export const postAgentEventAction = createAsyncAction(
  'POST_AGENT_EVENTS_REQUEST',
  'POST_AGENT_EVENTS_SUCCESS',
  'POST_AGENT_EVENTS_FAILURE'
)<AgentEventLocation, AgentEventLocation, HttpErrorResponse>()
