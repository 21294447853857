import { getType } from 'typesafe-actions'
import { CurrentUnitsAction } from '../actions'
import { setCurrentUnitsAction } from '../actions/currentUnits'

export default (
  state: string[] | null = null,
  action: CurrentUnitsAction
): string[] | null => {
  switch (action.type) {
    case getType(setCurrentUnitsAction):
      return action.payload
    default:
      return state
  }
}
