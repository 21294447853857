import React, { useCallback, useEffect, useState } from 'react'
import TelemetryNotificationBar from './TelemetryNotificationBar'
import useBrowserVisibilityChange from '../../hooks/useBrowserVisibilityChange'
import { useInterval } from '../../hooks'
import { fromNow } from '../../helpers/date'
import TelemetryNotificationWorker from './TelemetryNotificationWorker.js'
import WebWorker from '../../models/webWorker'

const ReconnectIntentionalDelay = 1000
const NotificationWorker = new WebWorker(TelemetryNotificationWorker).build()

interface Props {
  isLoading: boolean
  notificationMessage: string
  refreshText?: string
  refreshButtonAction: (...args: any[]) => void
  disconnectEventAction: (...args: any[]) => void
  reconnectEventAction: (...args: any[]) => void
}

const TelemetryVisibilityNotificationBar: React.FC<Props> = (props: Props) => {
  const {
    isLoading,
    refreshText = 'Refresh',
    refreshButtonAction,
    disconnectEventAction,
    reconnectEventAction,
  } = props

  const [friendlyAgoText, setFriendlyAgoText] = useState<string>('')
  const [disconnectedAt, setDisconnectedAt] = useState<Date | null>(null)

  const [
    reconnectIntentionalDelayLoading,
    setReconnectIntentionalDelayLoading,
  ] = useState<boolean>(false)

  const [showNotificationbar, setShowNotificationBar] =
    React.useState<boolean>(false)
  const handleConnectedSinceIntervalChange = useCallback(
    (millisecondsElapsed) => {
      const timeElapsedWhileHidden = disconnectedAt
        ? new Date().getTime() - disconnectedAt.getTime()
        : 0
      setFriendlyAgoText(fromNow(timeElapsedWhileHidden + millisecondsElapsed))
    },
    [disconnectedAt]
  )

  const onReconnect = useCallback(() => {
    setReconnectIntentionalDelayLoading(true)
    reconnectEventAction()
    refreshButtonAction()

    const timeout = setTimeout(() => {
      clearConnectedInterval()
      setShowNotificationBar(false)
      setDisconnectedAt(null)
      setReconnectIntentionalDelayLoading(false)
    }, ReconnectIntentionalDelay)

    return () => clearTimeout(timeout)
  }, [])

  const { visible } = useBrowserVisibilityChange({ enabled: true })

  const { clearInterval: clearConnectedInterval } = useInterval(
    handleConnectedSinceIntervalChange,
    1000,
    showNotificationbar
  )

  useEffect(() => {
    if (!visible) {
      if (!showNotificationbar) {
        NotificationWorker?.postMessage('start')
        setDisconnectedAt(new Date())
      }
      disconnectEventAction()
    } else {
      NotificationWorker?.postMessage('stop')
      if (!showNotificationbar) {
        reconnectEventAction()
      }
    }
  }, [visible, showNotificationbar])

  useEffect(() => {
    NotificationWorker?.addEventListener('message', (event: MessageEvent) => {
      setShowNotificationBar(true)
    })
  }, [])

  useEffect(() => {
    // cleanup intervals on unmount & reconnect
    return () => {
      clearConnectedInterval()
      reconnectEventAction()
    }
  }, [])

  if (!showNotificationbar) {
    return <></>
  }

  return (
    <TelemetryNotificationBar
      {...props}
      isLoading={isLoading || reconnectIntentionalDelayLoading}
      rightText={`Last connected ${friendlyAgoText}`}
      buttonText={refreshText}
      onClick={onReconnect}
    />
  )
}

export default TelemetryVisibilityNotificationBar
