import jmap from 'jmap.js'

const host = process.env.REACT_APP_JIBESTREAM_URL
const clientId = process.env.REACT_APP_JIBESTREAM_CLIENT_ID
const clientSecret = process.env.REACT_APP_JIBESTREAM_CLIENT_SECRET
const customerId = process.env.REACT_APP_JIBESTREAM_CUSTOMER_ID

export const config = {
  auth: new jmap.core.Auth(clientId, clientSecret),
  host,
  customerId,
  showAllPathTypes: true,
  showAllAmenities: true,
  applyDisplayMode: true,
  // userLocationOptions: {
  //   position: [2632, 2682],
  //   confidenceAlpha: 0.3,
  //   width: 12,
  //   pulseVisible: true,
  // },
}
