import { Asset, BadgeState, Location, SilentRefreshStatus, Staff } from '.'

export enum AgentType {
  Staff,
  Asset,
  Patient,
}

export enum AgentTypePlural {
  Staff,
  Assets,
  Patients,
}

export interface Agent {
  id?: string // Agent GUID, needs to be optional to account for new Agents
  agentType: string
  agentTypeId: number
  badgeID?: string
  [key: string]: any // eslint-disable-line @typescript-eslint/no-explicit-any
  // badgeAssigned?: Date
  // badgeUnassigned?: Date
  // CreateDate: Date
}
export interface AttemptedSilentUpdates {
  [agentId: string]: string
}

export interface AgentSilentUpdatesState {
  silentRefreshStatus?: SilentRefreshStatus | undefined
  attemptedSilentUpdates?: AttemptedSilentUpdates | undefined
}
export interface AgentLocationModel {
  agentGuid: string
  displayName: string
  iconUrl: string
  badgeIds: string[]
  signalTypeIds: number[]
  isLowBattery?: boolean
  tagBatteryChangeDate?: Date
  badgeTelemetry: BadgeState
  agentType?: AgentType
  isPrivate?: boolean
  roomLocation?: Location
  floorLocation?: Location
  buildingLocation?: Location
  buildingGroupLocation?: Location
}

export type AgentLocation<T> = T & {
  venueId?: number
  timestamp: Date
  accuracy?: number
  locationUnitName?: string
  locationSubUnitName?: string
}

export interface TelemetryAgent {
  id: string
  agentTypeId: number
  agentType: string
  displayName: string
  asset: Asset | null
  staff: Staff | null
}
