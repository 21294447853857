import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { defaultAutocompleteStyles } from '../../helpers/filters'
import { AssetSubType, AssetType, Manufacturer, StringObj } from '../../models'
import { useFormStyle } from '../../styles/index'
import SortedAutocomplete from '../Filters/SortedAutoComplete'
import { AssetFilters, GlobalFiltersState } from '../../models/filters'

interface Props {
  assetSubTypes: AssetSubType[]
  assetTypes: AssetType[]
  manufacturers: Manufacturer[]
  displayNames: StringObj[]
  models: StringObj[]
  owners: StringObj[]
  handleAssetFilterChange: (
    changes: Partial<AssetFilters>,
    savedFilter?: boolean
  ) => void
}

const AssetFilterOptions: React.FC<
  Props & WithStyles<typeof defaultAutocompleteStyles>
> = (props: Props & WithStyles<typeof defaultAutocompleteStyles>) => {
  const {
    assetTypes,
    assetSubTypes,
    manufacturers,
    models,
    owners,
    handleAssetFilterChange,
  } = props

  const assetFilters: AssetFilters = useSelector(
    ({ globalFilters }: { globalFilters: GlobalFiltersState }) =>
      globalFilters?.assets
  )

  const [selectedDate, setSelectedDate] = useState<Date | string | null>()
  const [errorMsg, setErrorMsg] = useState<string>()
  const [errorValidation, setErrorValidation] = useState<boolean>()

  const handleChange = (event: any) => {
    if (event === null) {
      setSelectedDate(event)
      return
    }
    const momentObj = moment(event._d).utc()

    if (momentObj !== undefined && event._d !== null) {
      const validDate = momentObj.format('MM/DD/YYYY')
      setSelectedDate(validDate)
      if (validDate !== 'Invalid date') {
        const newDate = new Date(moment(validDate).format('MM/DD/YYYY'))
        handleAssetFilterChange({
          preventativeMaintenanceDate: newDate,
        })
        setErrorValidation(false)
        setErrorMsg('')
      }
    }
  }

  const handleBlur = (event: any) => {
    if (selectedDate !== undefined) {
      const momentObj = moment(selectedDate)
      if (momentObj !== undefined && selectedDate !== null) {
        const validDate = momentObj.format('MM/DD/YYYY')
        if (validDate !== 'Invalid date') {
          handleAssetFilterChange({
            preventativeMaintenanceDate: new Date(
              moment(validDate).format('MM/DD/YYYY')
            ),
          })
          setErrorValidation(false)
          setErrorMsg('')
        } else {
          handleAssetFilterChange({
            preventativeMaintenanceDate: undefined,
          })
          setErrorMsg(validDate)
          setErrorValidation(true)
        }
      } else {
        handleAssetFilterChange({
          preventativeMaintenanceDate: undefined,
        })
        setErrorValidation(false)
        setErrorMsg('')
      }
    }
  }

  const formClasses = useFormStyle()

  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              color='primary'
              icon={<CheckBoxOutlineBlankIcon color='primary' />}
              checked={assetFilters.hasRecall ?? false}
              onChange={(event, checked: boolean): void =>
                handleAssetFilterChange({ hasRecall: checked })
              }
              name='hasRecall'
            />
          }
          label='Has Recall'
        />
        <FormControlLabel
          control={
            <Checkbox
              color='primary'
              icon={<CheckBoxOutlineBlankIcon color='primary' />}
              checked={assetFilters.cmmsManaged ?? false}
              onChange={(event, checked: boolean): void =>
                handleAssetFilterChange({ cmmsManaged: checked })
              }
              name='isCmmsManaged'
            />
          }
          label='Managed by CMMS'
        />
        <FormControlLabel
          control={
            <Checkbox
              color='primary'
              icon={<CheckBoxOutlineBlankIcon color='primary' />}
              checked={assetFilters.isPmThisMonth ?? false}
              onChange={(event, checked: boolean): void =>
                handleAssetFilterChange({ isPmThisMonth: checked })
              }
              name='isPmThisMonth'
            />
          }
          label='Open PMs'
        />
        <FormControl variant='standard' className={formClasses.inputStyle}>
          <SortedAutocomplete
            value={assetFilters.assetTypes}
            items={assetTypes}
            itemKey='name'
            itemValueCompareKey='assetTypeId'
            label='Asset Type'
            handleChange={(event, selected: AssetType[]): void =>
              selected &&
              handleAssetFilterChange({
                assetTypes: selected,
              })
            }
          />
        </FormControl>
        <FormControl variant='standard' className={formClasses.inputStyle}>
          <SortedAutocomplete
            value={assetFilters.assetSubTypes}
            items={assetSubTypes}
            itemKey='name'
            itemValueCompareKey='assetSubTypeId'
            label='Asset Sub-Type'
            handleChange={(event, selected: AssetSubType[]): void =>
              selected &&
              handleAssetFilterChange({
                assetSubTypes: selected,
              })
            }
          />
        </FormControl>
        <FormControl variant='standard' className={formClasses.inputStyle}>
          <SortedAutocomplete
            value={assetFilters.manufacturers}
            items={manufacturers}
            itemKey='name'
            itemValueCompareKey='manufacturerGuid'
            label='Manufacturer'
            handleChange={(event, selected: Manufacturer[]): void =>
              selected &&
              handleAssetFilterChange({
                manufacturers: selected,
              })
            }
          />
        </FormControl>
        <FormControl variant='standard' className={formClasses.inputStyle}>
          <SortedAutocomplete
            value={assetFilters.models}
            items={models}
            itemKey='name'
            itemValueCompareKey='id'
            label='Model'
            handleChange={(event, selected: StringObj[]) =>
              selected &&
              handleAssetFilterChange({
                models: selected,
              })
            }
          />
        </FormControl>
        <FormControl variant='standard' className={formClasses.inputStyle}>
          <SortedAutocomplete
            value={assetFilters.owners}
            items={owners}
            itemKey='name'
            itemValueCompareKey='id'
            label='Owner'
            handleChange={(event, selected: StringObj[]) =>
              selected &&
              handleAssetFilterChange({
                owners: selected,
              })
            }
          />
        </FormControl>
        <FormControl variant='standard' className={formClasses.inputStyle}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              format='MM/DD/YYYY'
              label='Next PM Date'
              value={
                assetFilters.preventativeMaintenanceDate
                  ? moment(assetFilters.preventativeMaintenanceDate)
                  : null
              }
              defaultValue={null}
              onChange={handleChange}
              slotProps={{
                textField: {
                  variant: 'filled',
                  margin: 'normal',
                  name: 'nextPMDate',
                  color: 'primary',
                  error: errorValidation,
                  helperText: errorMsg,
                  onBlur: handleBlur,
                },
              }}
            />
          </LocalizationProvider>
        </FormControl>
      </FormGroup>
    </>
  )
}

export default withStyles(defaultAutocompleteStyles)(AssetFilterOptions)
