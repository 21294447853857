import { Manufacturer } from '../models'
import { BaseService } from './baseService'

export class ManufacturersApi extends BaseService {
  constructor() {
    super({ entityType: 'manufacturers' })
  }
  route = 'manufacturers'

  async getAllManufacturers(): Promise<Manufacturer[]> {
    return await this.get<Manufacturer[]>(this.route)
  }
}
