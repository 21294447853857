import React, { ReactNode } from 'react'
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
} from '@mui/material'

export interface Props {
  tableHead: Array<string>
  tableBody: Array<Array<string | ReactNode>>
}

const DataTable = (props: Props) => {
  const { tableHead, tableBody } = props
  return (
    <React.Fragment>
      <Table size='small' stickyHeader>
        <TableHead>
          <TableRow>
            {tableHead.map((col, i) => (
              <TableCell key={`tableHead-${i}`}>
                {typeof col === 'string' ? <Typography>{col}</Typography> : col}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableBody.map((row, i) => (
            <TableRow key={`tableRow-${i}`} hover style={{ height: 36 }}>
              {row.map((col, j) => (
                <TableCell key={`tableCell-${i}-${j}`}>
                  {typeof col === 'string' ? (
                    <Typography>{col}</Typography>
                  ) : (
                    col
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  )
}

export default DataTable
