import { AlertConfigSteps, allUnits } from '../models'
import { Alert, AlertActiveConfig, AlertTypes } from '../models/alert'
import { LocationQualifier } from '../models/alertQualifier'
import { Location } from '../models/locations'

export const mapLocationAncestors = (
  floorLocationId: string,
  locations: Location[]
) => {
  let campus: Location | undefined
  let building: Location | undefined
  let floor: Location | undefined

  floor = locations.find(
    (location) => location.id.toLowerCase() === floorLocationId.toLowerCase()
  )
  if (floor?.parentId !== '') {
    building = locations.find(
      (location) => location.id.toLowerCase() === floor?.parentId?.toLowerCase()
    )
  }
  if (building?.parentId !== '') {
    campus = Object.values(locations).find(
      (location) =>
        location.id.toLowerCase() === building?.parentId?.toLowerCase()
    )
  }

  return {
    campus,
    building,
    floor,
  }
}

export const mapAlertTypeName = (id: number) => {
  switch (id) {
    case AlertTypes.StaffDuress:
      return 'Staff Duress'
      break
    case AlertTypes.StaffAssist:
      return 'Staff Assist'
      break
    case AlertTypes.AssetAlert:
      return 'Asset Alert'
      break

    default:
      return 'Uknown Type'
      break
  }
}

export const mapAlertToAlertActiveConfig = (
  alert: Alert
): AlertActiveConfig => ({
  id: alert.id,
  name: alert.name,
  description: alert.description,
  alertType: {
    id: alert.alertTypeId,
    name: alert.alertTypeName,
  },
  trigger: alert.triggers.shift(),
  locationQualifier: {
    campus: alert.locationQualifier?.campus,
    building: alert.locationQualifier?.building,
    floor: alert.locationQualifier?.floor,
  },
  unitQualifier: alert.unitQualifier ?? allUnits,
  badgeQualifier: !alert.badgeQualifier ? 'All Badges' : alert.badgeQualifier,
  channels: alert.channels,
  alertResolution: alert.alertResolution,
  locationRouter: alert.locationRouter,
  unitRouter: alert.unitRouter ?? allUnits,
  securityRouters: alert.securityRouters,
  userRouters: alert.userRouters,
  assetDetail: alert.assetDetail,
  assetType: alert.assetType,
  alertStatus: alert.alertStatus,
  locationChangeQualifier: alert.locationChangeQualifier,
})

export const mapLocationsWithChildren = (
  idn: Location,
  locationQualifier: LocationQualifier
) => {
  let selectedCampusWithChildren: Location | undefined
  let selectedBuildingWithChildren: Location | undefined
  if (!idn.children || idn.children.length === 0) {
    return {
      selectedCampusWithChildren,
      selectedBuildingWithChildren,
    }
  }
  selectedCampusWithChildren = idn.children.find(
    (location: Location) =>
      locationQualifier.campus && location.id === locationQualifier.campus.id
  )
  if (
    selectedCampusWithChildren &&
    selectedCampusWithChildren.children &&
    selectedCampusWithChildren.children.length > 0
  ) {
    selectedBuildingWithChildren = selectedCampusWithChildren.children.find(
      (location: Location) =>
        locationQualifier.building &&
        location.id === locationQualifier.building.id
    )
  }
  return {
    selectedCampusWithChildren,
    selectedBuildingWithChildren,
  }
}

export const validateActiveConfig = (
  activeConfig: AlertActiveConfig
): boolean => {
  const isAlertTypeValid =
    activeConfig.alertType?.id === AlertTypes.AssetAlert
      ? !!activeConfig.locationChangeQualifier && !!activeConfig.assetType
      : activeConfig.channels.length > 0 &&
        !!activeConfig.locationRouter?.floor &&
        !!activeConfig.alertResolution.automaticResolveTimeoutInMinutes

  return (
    !!activeConfig.name &&
    activeConfig.name !== '' &&
    !!activeConfig.description &&
    activeConfig.description !== '' &&
    !!activeConfig.alertType &&
    !!activeConfig.locationQualifier.floor &&
    isAlertTypeValid
  )
}

export const alertConfigPathMatches = (pathname: string): boolean => {
  return (
    pathname.includes(
      AlertConfigSteps[AlertConfigSteps.CONDITIONS].toLowerCase()
    ) ||
    pathname.includes(
      AlertConfigSteps[AlertConfigSteps.CHANNELS].toLowerCase()
    ) ||
    pathname.includes(
      AlertConfigSteps[AlertConfigSteps.ROUTING].toLowerCase()
    ) ||
    pathname.includes(
      AlertConfigSteps[AlertConfigSteps.RESOLUTION].toLowerCase()
    ) ||
    pathname.includes(AlertConfigSteps[AlertConfigSteps.REVIEW].toLowerCase())
  )
}
