import { BLERange } from '../models/bleRange'
import { BLEType } from '../models/bleType'
import { ResolvedBadge } from '../models/resolvedBadge'
import { TagType } from '../models/tagType'

//Midmark BLE/IR
//78:C9:5E:00:00:00 (IR:       0) thru 78:C9:5E:0C:34:FF (IR: 799,999) = reserve : Rules will need to change to implement
//78:C9:5E:0C:35:00 (IR: 800,000) thru 78:C9:5E:0F:42:3F (IR: 999,999) = use range/production(Qty: 200,000)
//78:C9:5E:0F:42:40 (IR: 1,000,000) thru 78:C9:5E:0F:FF:FF (IR: 1,048,575) = reserve
//Minew BLE
//78:C9:5E:10:00:00 thru 78:C9:5E:1F:47:CF = use range/production (Qty: 1,001,424)
//Midmark BLE
//78:C9:5E:1F:47:D0 thru 78:C9:5E:2E:8A:0F = use range/production (Qty: 1,000,000)
//Reserve BLE
//78:C9:5E:2E:8A:10 thru 78:C9:5E:A8:9C:0F = reserve (Qty: 8,000,000)

const midmarkManufacturerId = '78:C9:5E'
const midmarkBLEIR: BLERange = { start: 800000, end: 1048575 }
const minewBLE: BLERange = { start: 1048576, end: 2049999 }
const midmarkBLE: BLERange = { start: 2050000, end: 3049999 }
const reserveBLE: BLERange = { start: 3050000, end: 11049999 }

export const getBLETypeFromNumber = (badgeId: number): BLEType => {
  if (badgeId >= midmarkBLEIR.start && badgeId <= midmarkBLEIR.end) {
    return BLEType.Blended
  } else if (badgeId >= minewBLE.start && badgeId <= minewBLE.end) {
    return BLEType.Minew
  } else if (badgeId >= midmarkBLE.start && badgeId <= midmarkBLE.end) {
    return BLEType.Midmark
  } else if (badgeId >= reserveBLE.start && badgeId <= reserveBLE.end) {
    return BLEType.Reserve
  } else {
    return BLEType.NonMidmark
  }
}

export const removeSpecialCharacters = (input: string): string => {
  return input.replace(/:/g, '').replace(/-/g, '')
}

export const sanitizeInput = (macAddress: string): string => {
  const cleanedInput = removeSpecialCharacters(macAddress)

  if (/^[a-fA-F0-9]{6}$/.test(cleanedInput)) {
    //10:00:00
    return `${removeSpecialCharacters(midmarkManufacturerId)}${cleanedInput}`
  } else if (/^[a-fA-F0-9]{5}$/.test(cleanedInput)) {
    //0:00:00
    return `${removeSpecialCharacters(midmarkManufacturerId)}0${cleanedInput}`
  } else if (/^[a-fA-F0-9]{12}$/.test(cleanedInput)) {
    //78C95E100000
    return cleanedInput
  } else if (/[0-9]$/.test(cleanedInput)) {
    //100000
    return cleanedInput
  } else {
    return macAddress
  }
}

export const appendColon = (input: string, index: number): string => {
  return `${input.slice(0, index)}:${input.slice(index)}`
}

export const appendColons = (macAddress: string): string => {
  let cleanedInput = removeSpecialCharacters(macAddress).toUpperCase()

  if (cleanedInput.length === 6) {
    cleanedInput = `${removeSpecialCharacters(
      midmarkManufacturerId
    )}${cleanedInput}`
  }

  return appendColon(
    appendColon(
      appendColon(appendColon(appendColon(cleanedInput, 2), 5), 8),
      11
    ),
    14
  )
}

export const parse = (macAddress: string): string => {
  const sanitizedInput = sanitizeInput(macAddress)

  return sanitizedInput.toUpperCase()
}

export const convertMacAddresstoNumber = (macAddress: string): number => {
  const parsedInput = parse(macAddress)
  return Number.parseInt(parsedInput.substring(6), 16)
}

export const getBLETypeFromString = (macAddress: string): BLEType => {
  const parsedInput = parse(macAddress)

  //Check for Midmark Manufacturer Id
  if (
    !removeSpecialCharacters(parsedInput).toLocaleLowerCase().includes('78c95e')
  ) {
    return BLEType.NonMidmark
  }

  return getBLETypeFromNumber(convertMacAddresstoNumber(parsedInput))
}

export const convertBadgeIdtoMacAddress = (badgeId: number): string => {
  const hexValue = badgeId.toString(16).toUpperCase()

  const bleBadgeType = getBLETypeFromString(sanitizeInput(hexValue))

  if (bleBadgeType != BLEType.NonMidmark) {
    return appendColons(parse(hexValue).toString())
  }

  return badgeId.toString()
}

export const isMacAddress = (macAddress: string): boolean => {
  try {
    return (
      /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/.test(macAddress) || //78:C9:5E:10:00:00
      /^([0-9A-Fa-f]{2}[:-]){2}([0-9A-Fa-f]{2})$/.test(macAddress) || //10:00:00
      (macAddress.length === 12 &&
        /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/.test(
          appendColons(macAddress)
        )) || //78C95E100000
      (macAddress.length === 6 &&
        /^([0-9A-Fa-f]{2}[:-]){2}([0-9A-Fa-f]{2})$/.test(
          appendColon(appendColon(macAddress, 2), 5)
        ) &&
        /[A-Fa-f]/.test(macAddress))
    )
  } catch {
    return false
  }
}

export const resolveBadge = (badgeId: string): ResolvedBadge => {
  if (isMacAddress(badgeId)) {
    //MacAddress
    //Check if Mac Address is a Bleneded BLE Tag
    if (getBLETypeFromString(badgeId) === BLEType.Blended) {
      return {
        badgeId: convertMacAddresstoNumber(badgeId).toString(),
        vendorHardwareId: appendColons(parse(badgeId).toString()),
        isBlended: true,
        tagType: TagType.Blended,
      }
    } else {
      return {
        badgeId: appendColons(parse(badgeId).toString()),
        vendorHardwareId: appendColons(parse(badgeId).toString()),
        isBlended: false,
        tagType: TagType.BLE,
      }
    }
  } else if (/^\d+$/.test(badgeId)) {
    //Numeric
    //Check if Numeric value is a Bleneded BLE Tag
    if (getBLETypeFromNumber(Number.parseInt(badgeId)) === BLEType.Blended) {
      return {
        badgeId: badgeId,
        vendorHardwareId: appendColons(
          convertBadgeIdtoMacAddress(Number.parseInt(badgeId))
        ),
        isBlended: true,
        tagType: TagType.Blended,
      }
    } else {
      return {
        badgeId: badgeId,
        isBlended: false,
        tagType: TagType.IRRF,
      }
    }
  } else {
    return {
      badgeId: badgeId,
      isBlended: false,
      tagType: TagType.IRRF,
    }
  }
}
