import { useEffect, useState } from 'react'
import useSnackbar from '../components/Common/Snackbar/useSnackbar'
import { SnackbarType } from '../models/snackbarType'

const useSaveSuccessFailureUtility = (
  isSuccessful: boolean,
  isFailed: boolean,
  successMessage: string,
  failureMessage?: string,
  handleCloseModalOnSuccess?: () => void,
  handleCloseModalOnDelete?: () => void
) => {
  const { setSnackbarData } = useSnackbar()
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)

  useEffect(() => {
    if (isSaving) {
      if (isSuccessful) {
        setSnackbarData({
          message: successMessage,
          type: SnackbarType.Success,
          autoClose: 10000,
          link: null,
          linkText: null,
        })
        setIsSaving(false)
        if (handleCloseModalOnSuccess) {
          handleCloseModalOnSuccess()
        }
      } else if (isFailed) {
        setShowError(true)
        if (failureMessage) {
          setSnackbarData({
            message: failureMessage,
            type: SnackbarType.Error,
            autoClose: 10000,
            link: null,
            linkText: null,
          })
        }
        setIsSaving(false)
        if (handleCloseModalOnDelete) {
          handleCloseModalOnDelete()
        }
      }
    }
  }, [
    isSuccessful,
    isFailed,
    successMessage,
    failureMessage,
    handleCloseModalOnSuccess,
  ])

  return {
    isSaving,
    setIsSaving,
    showError,
    setShowError,
  }
}

export { useSaveSuccessFailureUtility }
