import { BaseService } from './baseService'
import { BadgeSensorState, BadgeTrafficRequest } from '../models'

export class BadgeSensorStatesApi extends BaseService {
  constructor() {
    super({ entityType: 'badge' })
  }
  route = `locations`

  public async fetchBadgeSensorStatesByLocationId(
    locationId: string
  ): Promise<BadgeSensorState[]> {
    return await this.get<BadgeSensorState[]>(
      `${this.route}/${locationId}/badges/sensors/state`
    )
  }

  public async fetchBadgeSensorStates(
    BadgeTrafficRequest: BadgeTrafficRequest
  ): Promise<BadgeSensorState[]> {
    return await this.post<BadgeSensorState[]>(
      `badges/sensors/state`,
      BadgeTrafficRequest
    )
  }
}
