import { TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import {
  deleteSubUnitAction,
  postSubUnitAction,
  putSubUnitAction,
} from '../../../actions/subUnits'
import {
  ActionConfirmationButton,
  Button,
  SaveButton,
} from '../../../components/Buttons'
import ActionConfirmationMessage from '../../../components/Common/ActionConfirmationMessage'
import { CreateOrEditModalProps } from '../../../components/Common/HOC'
import { requiredMessage } from '../../../components/Forms'
import { ConfirmationFormInput } from '../../../components/Forms/ActionConfirmationForm'
import { FlexBox } from '../../../components/Layout'
import {
  useDeleteSuccessFailureUtility,
  useSaveSuccessFailureUtility,
} from '../../../hooks'
import { FetchingStatus, SubUnit, SubUnitsState } from '../../../models'
import { colors, useFormStyle } from '../../../styles'

const SubUnitManagementForm: React.ComponentType<
  CreateOrEditModalProps<SubUnit | null>
> = (props: CreateOrEditModalProps<SubUnit | null>) => {
  const { data: selectedSubUnit, handleClose } = props

  const { status: subUnitStatus, data: subUnits } = useSelector(
    ({ subUnits }: { subUnits: SubUnitsState }) => subUnits
  )

  const dispatch = useDispatch()

  const classes = useFormStyle()

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<SubUnit>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      subUnitName: selectedSubUnit ? selectedSubUnit.subUnitName : '',
    } as SubUnit,
  })

  const onSubmit = (formData: SubUnit) => {
    const updatedSubUnit: SubUnit = {
      ...selectedSubUnit,
      ...formData,
    }
    setIsSaving(true)
    if (showSaveError) {
      setShowSaveError(false)
    }
    if (showDeleteError) {
      setShowDeleteError(false)
    }
    if (updatedSubUnit.id) {
      dispatch(putSubUnitAction.request(updatedSubUnit))
    } else {
      dispatch(postSubUnitAction.request(updatedSubUnit))
    }
  }

  const {
    isSaving,
    setIsSaving,
    showError: showSaveError,
    setShowError: setShowSaveError,
  } = useSaveSuccessFailureUtility(
    subUnitStatus === FetchingStatus.Success,
    subUnitStatus === FetchingStatus.Failure,
    `Sub-Unit was successfully ${selectedSubUnit ? 'updated.' : 'created.'}`,
    `There was an error saving Sub-Unit information, please try again. If the problem persists, please contact support for assistance.`,
    handleClose
  )

  const {
    isDeleting,
    setIsDeleting,
    showError: showDeleteError,
    setShowError: setShowDeleteError,
  } = useDeleteSuccessFailureUtility(
    subUnitStatus === FetchingStatus.Success,
    subUnitStatus === FetchingStatus.Failure,
    `Sub-Unit was successfully deleted.`,
    `There was an error deleting Sub-Unit information, please try again. If the problem persists, please contact support for assistance.`,
    handleClose
  )

  const confirmationInput: ConfirmationFormInput = {
    inputLabel: 'Confirm Sub-Unit Deletion',
    keyword: 'delete',
  }

  const deleteConfirmationModalContent = (
    <ActionConfirmationMessage
      primaryMessage={
        <Typography variant='body1'>
          Are you sure you want to delete <b>{selectedSubUnit?.subUnitName}</b>?
        </Typography>
      }
      additionalMessage={
        <Typography variant='body1'>
          This will delete <b>{selectedSubUnit?.subUnitName}</b> and unassign
          this sub-unit from all locations, staff, and assets. This action
          cannot be undone.
        </Typography>
      }
    />
  )

  const handleDelete = (): void => {
    if (selectedSubUnit && selectedSubUnit.id) {
      setIsDeleting(true)
      dispatch(deleteSubUnitAction.request(selectedSubUnit.id))
    }
  }

  const validateUniqueSubUnitName = (name: string) => {
    return Object.values(subUnits).some(
      (x) =>
        x.subUnitName.toLowerCase().trim() === name.toLowerCase().trim() &&
        (!selectedSubUnit || selectedSubUnit.id !== x.id)
    )
      ? 'Name must be unique'
      : true
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography style={{ fontWeight: 'bold' }}>
        SUB-UNIT INFORMATION
      </Typography>
      <Controller
        name='subUnitName'
        control={control}
        rules={{
          required: true,
          maxLength: {
            value: 50,
            message: 'Sub-Unit name must be between 1 and 50 characters',
          },
          validate: validateUniqueSubUnitName,
        }}
        render={({ field }) => {
          return (
            <TextField
              {...field}
              margin='normal'
              id='subUnitEditName'
              label='Sub-Unit Name'
              variant='filled'
              fullWidth
              required
              error={!!errors.subUnitName}
              helperText={
                errors.subUnitName?.message
                  ? errors.subUnitName?.message
                  : requiredMessage
              }
              className={classes.inputStyle}
            />
          )
        }}
      />
      {selectedSubUnit && (
        <Typography variant='body2' style={{ paddingBottom: 10 }}>
          *This change will affect all assigned locations, assets, and staff.
        </Typography>
      )}

      {showSaveError && (
        <Typography
          style={{
            color: colors.error,
            paddingBottom: 10,
          }}
        >
          There was an error saving Sub-Unit information, please try again. If
          the problem persists, contact support.
        </Typography>
      )}

      {showDeleteError && (
        <Typography
          style={{
            color: colors.error,
            paddingBottom: 10,
          }}
        >
          There was an error deleting Sub-Unit information, please try again. If
          the problem persists, contact support.
        </Typography>
      )}

      <FlexBox
        layout='row'
        layoutAlign={{
          main: 'space-between',
        }}
      >
        {selectedSubUnit && selectedSubUnit.id ? (
          <ActionConfirmationButton
            openConfirmationButtonLabel='DELETE'
            disableConfirmation={false}
            loading={isSaving || isDeleting}
            confirmationModalTitle={`Delete Sub-Unit: ${selectedSubUnit?.subUnitName}`}
            confirmationModalContent={deleteConfirmationModalContent}
            confirmationInput={confirmationInput}
            onContinue={handleDelete}
            bgColor={colors.error}
            textColor={colors.white}
            disableEnterButtonFormSubmission={true}
          />
        ) : (
          <></>
        )}
        <FlexBox
          flex={{
            grow: 1,
          }}
        />
        <Button
          id='sub-unit-edit-cancel-button'
          onClick={handleClose}
          styleOverride={{ wrapper: { margin: 0 } }}
          bgColor={colors.transparent}
          textColor={colors.error}
          loading={isSaving || isDeleting}
        >
          Cancel
        </Button>
        <SaveButton
          loading={isSaving || isDeleting}
          id='sub-unit-edit-save-button'
          disabled={Object.values(errors).length > 0}
        >
          Save
        </SaveButton>
      </FlexBox>
    </form>
  )
}

export default SubUnitManagementForm
