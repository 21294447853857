import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllSecurityRoutersAction } from '../actions/securityRouters'
import { shouldDispatch } from '../helpers'
import { SecurityRouters, SecurityRouterState } from '../models/securityRouter'

const useFetchSecurityRouters = (shouldRefresh: boolean = false) => {
  const dispatch = useDispatch()
  const status = useSelector(
    ({ securityRouters }: { securityRouters: SecurityRouterState }) =>
      securityRouters.status
  )
  const lastRefreshTime = useSelector(
    ({ securityRouters }: { securityRouters: SecurityRouterState }) =>
      securityRouters.lastRefreshTime
  )

  useEffect(() => {
    if (shouldDispatch(status, lastRefreshTime) || shouldRefresh) {
      dispatch(getAllSecurityRoutersAction.request())
    }
  }, [status, lastRefreshTime, shouldRefresh, dispatch])

  const securityRouters: SecurityRouters = useSelector(
    ({ securityRouters }: { securityRouters: SecurityRouterState }) =>
      securityRouters.data
  )

  return {
    securityRouters,
    status,
  }
}

export { useFetchSecurityRouters }
