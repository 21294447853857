import { BaseService } from './baseService'
import { MapPreset } from '../models'

export class MapPresetApi extends BaseService {
  constructor() {
    super({ entityType: 'mappresets' })
  }
  route = 'mappresets'

  async getAllMapPresets(): Promise<MapPreset[]> {
    return await this.get<MapPreset[]>(this.route)
  }
}
