import { Tenants, TenantsState } from '../../v2/models'
import { EntityResult, useAuth, useEntities } from '@midmarkrtls/common/hooks'
import { getCurrentUserTenantsAction } from 'v2/actions/tenants'

const useFetchCurrentUserTenants = (): EntityResult<Tenants> => {
  const auth = useAuth()
  const currentUserId = auth.user?.id || ''
  return useEntities<TenantsState, Tenants>(
    () => getCurrentUserTenantsAction.request(currentUserId),
    (state: any) => state.tenants // eslint-disable-line @typescript-eslint/no-explicit-any
  )
}

export { useFetchCurrentUserTenants }
