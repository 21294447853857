import { Epic } from 'redux-observable'
import { isActionOf } from 'typesafe-actions'
import { mergeMap, catchError, filter, map } from 'rxjs/operators'
import { from, of } from 'rxjs'
import { TenantTagNumbersAction } from '../actions'
import { getTenantTagNumbersAction } from '../actions/tenantTagNumbers'
import { TenantTagNumbersApi } from 'v2/middleware'
import { TenantTagNumbers, TenantTagNumbersState } from 'v2/models'

type TenantTagNumbersEpic = Epic<
  TenantTagNumbersAction,
  TenantTagNumbersAction,
  TenantTagNumbersState,
  { tenantTagNumbersApi: TenantTagNumbersApi }
>

export const getTenantTagNumbersEpic: TenantTagNumbersEpic = (
  action$,
  state$,
  { tenantTagNumbersApi }
) => {
  return action$.pipe(
    filter(isActionOf(getTenantTagNumbersAction.request)),
    mergeMap(() =>
      from(tenantTagNumbersApi.getTenantTagNumbers()).pipe(
        map((payload: TenantTagNumbers) =>
          getTenantTagNumbersAction.success(payload)
        ),
        catchError((error) => of(getTenantTagNumbersAction.failure(error)))
      )
    )
  )
}
