import { useSelector } from 'react-redux'
import { Locations, LocationsState, Location, LocationType } from '../models'
import { useCallback, useMemo } from 'react'

export interface LocationsDescendants {
  idn?: Location
  buildingGroups: Location[]
  buildings: Location[]
  floors: Location[]
  rooms: Location[]
}

export default function useLocationDescendants(
  rootLocation: Location | null,
  includeSelf = false
): LocationsDescendants {
  const locations: Locations = useSelector(
    ({ locations }: { locations: LocationsState }) => locations.data
  )

  const processDescendants = useCallback(
    (descendants: Location[], allLocations: Location[]): Location[] => {
      if (descendants.length === 0) {
        // termination condition
        return descendants
      } else {
        const newDescendants = allLocations.filter((x) =>
          descendants.map((d) => d.id).includes(x.parentId ?? '')
        )

        return [
          ...descendants,
          ...processDescendants(newDescendants, allLocations),
        ]
      }
    },
    []
  )

  const result = useMemo(() => {
    const allLocations = Object.values(locations)

    let descendants: Location[] = allLocations.filter(
      (x) => x.parentId === rootLocation?.id
    )

    if (rootLocation) {
      descendants = processDescendants(descendants, allLocations)
    }

    if (includeSelf && rootLocation) {
      descendants.push(rootLocation)
    }

    return {
      idn: descendants.find((x) => x.locationType === LocationType.IDN),
      buildingGroups: descendants.filter(
        (x) => x.locationType === LocationType.BuildingGroup
      ),
      buildings: descendants.filter(
        (x) => x.locationType === LocationType.Building
      ),
      floors: descendants.filter((x) => x.locationType === LocationType.Floor),
      rooms: descendants.filter((x) => x.locationType === LocationType.Room),
    }
  }, [rootLocation])

  return result
}
