import React from 'react'
import ListItem from '@mui/material/ListItem'
import { Typography } from '@mui/material'
import { Skeleton } from '@mui/material'

interface Props {
  field?: string | number | React.ReactNode
  value?: string | number | React.ReactNode
  divider: boolean
  classes: Record<
    | 'list'
    | 'field'
    | 'row'
    | 'chip'
    | 'detail'
    | 'item'
    | 'text'
    | 'multiLineItem',
    string
  >
  narrowRows?: boolean
  multiLine?: boolean
  isLoading?: boolean
}

const DetailsListItem = (props: Props): React.ReactElement => {
  const {
    field,
    value,
    classes,
    divider,
    narrowRows,
    multiLine,
    isLoading = false,
  } = props

  return (
    <>
      {!divider ? (
        <ListItem
          className={
            multiLine && multiLine === true && field === ''
              ? classes.multiLineItem
              : classes.item
          }
          key={`DetailsListItemContainer-${value}`}
        >
          <div className={classes.row}>
            <div
              className={classes.field}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                noWrap={false}
                align='left'
                className={
                  narrowRows && narrowRows === true ? classes.text : ''
                }
              >
                {field}
              </Typography>
            </div>
            <div className={classes.detail} style={{}}>
              {isLoading ? (
                <Skeleton variant='text' />
              ) : typeof value === 'string' || typeof value === 'number' ? (
                <Typography
                  align='right'
                  noWrap={false}
                  className={
                    narrowRows && narrowRows === true ? classes.text : ''
                  }
                  display='block'
                  style={{ whiteSpace: 'pre-line' }}
                >
                  {value}
                </Typography>
              ) : (
                value
              )}
            </div>
          </div>
        </ListItem>
      ) : (
        <ListItem
          divider
          className={
            multiLine && multiLine === true && field === ''
              ? classes.multiLineItem
              : classes.item
          }
          key={`DetailsListItemContainer-${value}`}
        >
          <div className={classes.row}>
            <div className={classes.field}>
              <Typography
                className={
                  narrowRows && narrowRows === true ? classes.text : ''
                }
              >
                {field}
              </Typography>
            </div>
            <div className={classes.detail}>
              {typeof value === 'string' || typeof value === 'number' ? (
                <Typography
                  className={
                    narrowRows && narrowRows === true ? classes.text : ''
                  }
                >
                  {value}
                </Typography>
              ) : (
                value
              )}
            </div>
          </div>
        </ListItem>
      )}
    </>
  )
}

export default DetailsListItem
