import { Typography, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Close } from '@mui/icons-material'
import React from 'react'
import icons from '../../models/icons'
import CustomSVGIcon from '../Icons/CustomSVGIcon'
import { FlexBox } from '../Layout'
import MuiDialogTitle from '@mui/material/DialogTitle'
import { colors } from '../../styles'
import { useTimeAgo } from '../../hooks'
import { fixedDuressTimerInterval } from './constants'
import { DataTestIds } from '../../utils/test-utils/dataTestIds'

interface Props {
  onCloseDuressModal: () => void
  reportedTime: Date | undefined
}

const StaffDuressModalTitle = (props: Props): JSX.Element => {
  const { onCloseDuressModal, reportedTime } = props

  const { timeAgoText } = useTimeAgo(reportedTime, fixedDuressTimerInterval)

  const useSVGIconStyles = makeStyles(() =>
    createStyles({
      icon: {
        margin: 5,
        width: 30,
        height: 30,
        position: 'relative',

        '& svg': {
          position: 'absolute',
          top: 0,
          left: 0,
        },
      },
    })
  )

  const customSVGIconClasses = useSVGIconStyles()

  return (
    <MuiDialogTitle style={{ padding: 0 }}>
      <FlexBox
        flex='basis'
        layout='row'
        layoutAlign={{
          item: 'center',
          content: 'center',
          main: 'flex-start',
        }}
      >
        <FlexBox
          flex='grow'
          layout='row'
          layoutAlign={{
            item: 'center',
            content: 'center',
            main: 'flex-start',
          }}
        >
          <CustomSVGIcon
            svgText={icons.staffDuressIcon}
            classes={{ ...customSVGIconClasses }}
          />
          <Typography
            variant='body1'
            style={{
              fontWeight: 'bold',
              color: colors.error,
              paddingLeft: '8px',
              paddingBottom: '10px',
            }}
          >
            Staff Duress Reported
          </Typography>
        </FlexBox>
        <FlexBox
          flex='grow'
          layout='row-reverse'
          layoutAlign={{
            item: 'center',
            content: 'center',
            main: 'flex-start',
          }}
        >
          <IconButton
            aria-label='Close'
            onClick={onCloseDuressModal}
            style={{ padding: 0 }}
            disableRipple
            disableFocusRipple
            disableTouchRipple
            size='large'
          >
            <Close />
          </IconButton>
          <Typography
            variant='body2'
            style={{
              fontWeight: 'normal',
              color: '#979797',
              marginRight: '8px',
            }}
            data-testid={DataTestIds.modalTimeElapsed}
          >
            {timeAgoText ? `Reported ${timeAgoText}` : 'Unknown Time'}
          </Typography>
        </FlexBox>
      </FlexBox>
    </MuiDialogTitle>
  )
}

export default StaffDuressModalTitle
