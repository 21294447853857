import { AssetKitInstance, AgentType, IconType } from '../../models'
import {
  AgentInpixonAssetByType,
  InpixonAssetGroupCreateOptions,
} from '../../models/inpixonMap'
import { MapRenderByGeofence, MapRender } from '../../models/realtimeMap'
import { BadgeTelemetryMessageWithAgentEvent } from '../../models/telemetry'
import {
  buildInpixonAssetConfigsForNewGroup,
  createInpixonAssets,
  deleteAgentInpixonAssets,
} from '../inpixon'
import { calculateMapRenderToUpdateForAgentsEnteringMap } from '../realtimeMap'

/**
 * Once it's determined that the agent is entering a geofence that already has another agent icon and
 * the icon needs to be rendered as a Group icon, then
 *    - Hide the existing Asset/Staff icon
 *    - Replace it with a Group icon
 *    - Add geofence highlighting if any of the agents has an active duress
 * @param agentTelemetry
 * @param currentMapRender
 * @param enteringGeofenceMapRender
 * @param assetKit
 * @param useGeofenceCentroid
 * @param iconScale
 * @param geofenceInstances
 * @param jibestreamController
 * @param selectedTrackingId
 * @param addGeofenceHighlight
 * @returns updated map render state after map rerendering
 */
export function createGroupForNewAgentEnteringMap(
  agentTelemetry: BadgeTelemetryMessageWithAgentEvent,
  currentMapRender: MapRenderByGeofence,
  enteringGeofenceMapRender: MapRender,
  assetKit: AssetKitInstance,
  useGeofenceCentroid: boolean,
  iconScale: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  geofenceInstances: any[],
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any,
  selectedTrackingId: string | undefined,
  addGeofenceHighlight: (geofenceId: number) => void
): MapRenderByGeofence {
  const existingAgentsToHide: AgentInpixonAssetByType[] = []
  enteringGeofenceMapRender.renderedAgents.forEach((telemetry) => {
    existingAgentsToHide.push({
      trackingId: telemetry.trackingId,
      iconType:
        telemetry.agent.agentType === AgentType.Asset
          ? IconType.Asset
          : IconType.Staff,
    })
  })
  const updatedAgentsInEnteringGeofence = [
    ...enteringGeofenceMapRender.renderedAgents,
    agentTelemetry,
  ]
  const arbitraryAssetWithinGeofence =
    enteringGeofenceMapRender.renderedAgents[0]
  const hasActiveDuress = updatedAgentsInEnteringGeofence.some(
    (telemetry) => telemetry.hasActiveDuress
  )
  const hasActiveAssist = updatedAgentsInEnteringGeofence.some(
    (telemetry) => telemetry.hasActiveAssist
  )
  const isAnyExistingAgentSelected = existingAgentsToHide.some(
    (agent) => agent.trackingId === selectedTrackingId
  )
  const groupToCreate: InpixonAssetGroupCreateOptions = {
    iconType: IconType.Group,
    iconScale,
    geoFenceId: agentTelemetry.geoFenceId,
    lat: arbitraryAssetWithinGeofence.lat,
    lon: arbitraryAssetWithinGeofence.lon,
    mapId: arbitraryAssetWithinGeofence.mapId,
    hasActiveDuress,
    hasActiveAssist,
    isSelected: isAnyExistingAgentSelected,
    groupCount: updatedAgentsInEnteringGeofence.length,
  }
  if (existingAgentsToHide.length > 0) {
    deleteAgentInpixonAssets(assetKit, existingAgentsToHide)
  }
  const groupInpixonAssetConfig = buildInpixonAssetConfigsForNewGroup(
    groupToCreate,
    useGeofenceCentroid,
    iconScale,
    geofenceInstances,
    jibestreamController
  )
  createInpixonAssets(assetKit, [groupInpixonAssetConfig], jibestreamController)
  if (hasActiveDuress) {
    addGeofenceHighlight(agentTelemetry.geoFenceId)
  }
  const updatedMapRender = calculateMapRenderToUpdateForAgentsEnteringMap(
    currentMapRender,
    agentTelemetry
  )
  return updatedMapRender
}
