import {
  Divider,
  FormControl,
  FormGroup,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { defaultAutocompleteStyles } from '../../helpers/filters'
import { useFetchAlertTypes } from '../../hooks/useFetchAlertTypes'
import { AlertFilters, AlertType } from '../../models'
import { useFormStyle } from '../../styles'
import { DrawerContent } from '../Common/RightDrawer'
import SortedAutocomplete from './SortedAutoComplete'
import { GlobalFiltersState } from '../../models/filters'

interface Props {
  onAlertFilterChange: (changes: Partial<AlertFilters>) => void
}

const AlertFilterOptions: React.FC<
  Props & WithStyles<typeof defaultAutocompleteStyles>
> = (props: Props & WithStyles<typeof defaultAutocompleteStyles>) => {
  const { classes, onAlertFilterChange } = props

  const [showAlert, setShowAlert] = useState(false)

  const alertFilters: AlertFilters = useSelector(
    ({ globalFilters }: { globalFilters: GlobalFiltersState }) =>
      globalFilters?.alerts
  )

  const formClasses = useFormStyle()
  const { alertTypes } = useFetchAlertTypes()

  const alertTypeValues = useMemo(() => {
    return Object.values(alertTypes)
  }, [alertTypes])

  return (
    <>
      <Divider />
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Grid item>
          <Typography variant='body2' className={classes.commonFilter}>
            {'ALERT FILTERS'}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton
            style={{ fontSize: 19 }}
            onClick={() => setShowAlert(!showAlert)}
            size='large'
          >
            {showAlert ? (
              <KeyboardArrowUpOutlinedIcon color='primary' />
            ) : (
              <KeyboardArrowDownOutlinedIcon color='primary' />
            )}
          </IconButton>
        </Grid>
      </Grid>
      <DrawerContent>
        {showAlert && (
          <FormGroup style={{ width: '312px' }}>
            <FormControl variant='standard' className={formClasses.inputStyle}>
              <SortedAutocomplete
                value={alertFilters.alertTypes}
                items={alertTypeValues}
                itemKey='name'
                itemValueCompareKey='id'
                label='Type'
                handleChange={(event, selected: AlertType[]): void =>
                  selected &&
                  onAlertFilterChange({
                    alertTypes: selected,
                  })
                }
              />
            </FormControl>
          </FormGroup>
        )}
      </DrawerContent>
    </>
  )
}

export default withStyles(defaultAutocompleteStyles)(AlertFilterOptions)
