import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, switchMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { PermissionsApi } from '../middleware/permissions'
import { PermissionAction } from '../actions'
import { getPermissionsAction } from '../actions/permissions'

type PermissionsEpics = Epic<
  PermissionAction,
  PermissionAction,
  PermissionState,
  { permissionsApi: PermissionsApi }
>

export const getPermissionsEpic: PermissionsEpics = (
  action$,
  state,
  { permissionsApi }
) => {
  return action$.pipe(
    filter(isActionOf(getPermissionsAction.request)),
    switchMap(() =>
      from(permissionsApi.getAll()).pipe(
        map((permissions) => getPermissionsAction.success(permissions)),
        catchError((error) => of(getPermissionsAction.failure(error)))
      )
    )
  )
}

// export const createPermissionEpic: PermissionsEpics = (
//     action$,
//     state$,
//     { permissionsApi }
// ) => {
//     return action$.pipe(
//      filter(isActionOf(createPermissionsEpics.request)),
//      mergeMap((action) =>
//        from(permissionsApi.createUserPermissions(action.payload)).pipe(
//            map((permission) => createPermissionsEpics.success(permission)),
//            catchError((error) => of(createPermissionsEpics.failure(error)))
//        )
//      )
//     )
//   }
