import {
  Button,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemProps,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React, { useCallback } from 'react'
import { AgentEventTypeId, AgentEventView } from '../../models'
import { ChevronRight } from '@mui/icons-material'
import { formatLocalDateTimeByUserPreference } from '../../helpers/date'
import { colors, tableColors } from '../../styles/MidmarkTheme'
import icons from '../../models/icons'

export interface AgentEventListItemProps extends ListItemProps {
  agentEvent: AgentEventView
  userPreferencesTimeDisplayId?: number
  handleButtonClick?: (event: React.SyntheticEvent) => void
}

const useStyles = makeStyles(() =>
  createStyles({
    listItem: {
      minHeight: 100,
      backgroundColor: 'white',
    },
    chevron: {
      height: '16px',
      color: tableColors.borderColor,
      margin: '0 2px',
    },
    headlineText: {
      fontWeight: 'bold',
      marginBottom: 10,
    },
    locationText: {
      color: colors.darkSurface,
      fontSize: '14px',
    },
    dateText: {
      color: colors.midGrey2,
      fontSize: '12px',
      marginBottom: 10,
      textAlign: 'end',
    },
    actionButton: {
      fontWeight: 'bold',
    },
    listItemAvatar: {
      position: 'relative',
      top: -12,
      left: -7,
      minWidth: 24,
    },
  })
)

const AgentEventListItem = (props: AgentEventListItemProps): JSX.Element => {
  const {
    agentEvent,
    userPreferencesTimeDisplayId,
    handleButtonClick,
    ...genericProps
  } = {
    ...props,
  }
  const classes = useStyles()

  const locationBreadcrumb = useCallback(
    (item: AgentEventView): React.ReactNode => {
      const moveText =
        item.eventTypeId === AgentEventTypeId.EntersLocation
          ? `Moved to ${item.latestBuilding}`
          : `Moved from ${item.latestBuilding}`

      const locationText =
        item.eventTypeId === AgentEventTypeId.EntersLocation
          ? item.latestLocation
          : item.previousLocation

      return (
        <Grid
          container
          item
          justifyContent='start'
          alignItems='center'
          wrap='nowrap'
        >
          <Typography className={classes.locationText}>{moveText}</Typography>
          <ChevronRight fontSize='small' className={classes.chevron} />
          <Typography className={classes.locationText}>
            {item.latestFloor}
          </Typography>
          <ChevronRight fontSize='small' className={classes.chevron} />
          <Typography className={classes.locationText}>
            {locationText}
          </Typography>
        </Grid>
      )
    },
    [classes.chevron, classes.locationText]
  )

  const headline = useCallback(
    (item: AgentEventView): React.ReactNode => {
      const text =
        item.eventTypeId === AgentEventTypeId.EntersLocation
          ? `${item.displayName} moved to ${item.latestLocation}`
          : `${item.displayName} moved from ${item.previousLocation}`

      return <Typography className={classes.headlineText}>{text}</Typography>
    },
    [classes.headlineText]
  )

  return (
    <ListItem {...genericProps} className={classes.listItem}>
      <ListItemAvatar className={classes.listItemAvatar}>
        <span dangerouslySetInnerHTML={{ __html: icons.defaultAssetIcon }} />
      </ListItemAvatar>
      <ListItemText
        primary={headline(agentEvent)}
        secondary={locationBreadcrumb(agentEvent)}
        disableTypography
      />
      <ListItemSecondaryAction>
        <Typography className={classes.dateText}>
          {formatLocalDateTimeByUserPreference(
            agentEvent.createdDate,
            userPreferencesTimeDisplayId
          )}
        </Typography>
        <Button
          onClick={handleButtonClick}
          color={'primary'}
          variant='contained'
          data-agent-event-id={agentEvent.eventId}
          className={classes.actionButton}
        >
          VIEW CURRENT LOCATION
        </Button>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export default AgentEventListItem
