import { matchPath } from 'react-router-dom'
import { PageRoute } from '../interfaces/modules'

export const pathMatches = (route: PageRoute, pathname: string): boolean => {
  // Tim Groven - 08/22/2023 - Moved to startsWith for path matching due to
  // Alerts having sub pages that weren't matching, so Alerts menu item wouldn't
  // be highlighted.
  const match =
    route.path === '/'
      ? matchPath(route.path, pathname)
      : pathname.startsWith(route.path)
  if (!match && route.subroutes.length > 0) {
    const subRouteMatch = subRoutePathMatches(route, pathname)
    return !!subRouteMatch
  }
  return !!match
}

export const subRoutePathMatches = (
  route: PageRoute,
  pathname: string
): boolean => {
  const matchedSubRoute = route.subroutes.find(
    (x) => !!matchPath(route.path, pathname)
  )
  return !!matchedSubRoute
}

export const getAssociatedRoute = (
  routeKey: string,
  availableRoutes: PageRoute[]
): PageRoute | undefined => {
  return availableRoutes.find((x) => x.key === routeKey)
}
