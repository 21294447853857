import { useSelector } from 'react-redux'
import { RealTimeMapControlState } from '../models'

const useRealTimeMapControl = (): RealTimeMapControlState => {
  const interfaceOptions: RealTimeMapControlState = useSelector(
    ({ realTimeMapControl }: { realTimeMapControl: RealTimeMapControlState }) =>
      realTimeMapControl
  )
  return interfaceOptions
}

export default useRealTimeMapControl
