import { useDispatch, useSelector } from 'react-redux'
import { shouldDispatch } from '../helpers/shouldDispatch'
import { useEffect } from 'react'
import { getBadgeAssignedCountAction } from '../actions/badgeAssignedCount'
import { BadgeAssignedCountState } from '../models/badgeAssignedCount'

const useFetchBadgeAssignedCount = (shouldRefresh = false) => {
  const dispatch = useDispatch()
  const badgeAssignedCount = useSelector(
    ({ badgeAssignedCount }: { badgeAssignedCount: BadgeAssignedCountState }) =>
      badgeAssignedCount
  )

  useEffect(() => {
    if (
      shouldDispatch(
        badgeAssignedCount.status,
        badgeAssignedCount.lastRefreshTime
      ) ||
      shouldRefresh
    ) {
      dispatch(getBadgeAssignedCountAction.request())
    }
  }, [badgeAssignedCount, shouldRefresh, dispatch])

  return {
    count: badgeAssignedCount.data,
    status: badgeAssignedCount.status,
  }
}

export { useFetchBadgeAssignedCount }
