import {
  Column,
  ColumnOrderState,
  ColumnPinningState,
  VisibilityState,
} from '@tanstack/react-table'
import { TableColumnSetting, TableColumnSettings } from '../../models'

interface ReactTableColumnCustomizationState {
  columnPinning: ColumnPinningState
  columnVisibility: VisibilityState
  columnOrdering: ColumnOrderState
}

export const mapTableColumnSettingsToColumnState = <T>(
  userTableColumnSettings: TableColumnSetting[],
  columns: Column<T>[]
): ReactTableColumnCustomizationState => {
  const columnCustomizationState: ReactTableColumnCustomizationState = {
    columnPinning: {
      left: [],
      right: [],
    },
    columnVisibility: {},
    columnOrdering: [],
  }
  const newColumnsNotSavedInUserSettings = columns.filter(
    (column) =>
      !column.columnDef.enablePinning &&
      !userTableColumnSettings.map((x) => x.columnName).includes(column.id)
  )

  // Set left and right column pinning and column ordering for left pinned columns
  const lastLeftPinnedColumnIndex =
    columns
      .map((column) => column.columnDef.enablePinning)
      .indexOf(undefined, 0) - 1
  const firstRightPinnedColumnIndex = columns
    .map((column) => column.columnDef.enablePinning)
    .indexOf(true, lastLeftPinnedColumnIndex + 1)
  for (let i = 0; i < columns.length; i++) {
    if (i <= lastLeftPinnedColumnIndex) {
      columnCustomizationState.columnPinning.left?.push(columns[i].id)
      columnCustomizationState.columnOrdering.push(columns[i].id)
    } else if (i >= firstRightPinnedColumnIndex) {
      columnCustomizationState.columnPinning.right?.push(columns[i].id)
    }
  }

  // Set column visibility and column ordering for unpinned columns
  userTableColumnSettings
    // Filter out pinned columns and columns that have been removed from column definitions
    .filter(
      (x) =>
        x.canModify && columns.map((column) => column.id).includes(x.columnName)
    )
    .sort((a, b) => a.order - b.order)
    .forEach((setting) => {
      if (!setting.show) {
        columnCustomizationState.columnVisibility[setting.columnName] = false
      }
      columnCustomizationState.columnOrdering.push(setting.columnName)
    })

  // Set collumn visibility and column ordering for new columns added to column definitions.
  // These columns are ordered/pushed to the end
  newColumnsNotSavedInUserSettings.forEach((column) => {
    if (column.columnDef.enableHiding) {
      columnCustomizationState.columnVisibility[column.id] = false
    }
    columnCustomizationState.columnOrdering.push(column.id)
  })

  // Finally, set column ordering for right pinned columns
  if (
    columnCustomizationState.columnPinning.right &&
    columnCustomizationState.columnPinning.right.length > 0
  ) {
    columnCustomizationState.columnOrdering =
      columnCustomizationState.columnOrdering.concat(
        columnCustomizationState.columnPinning.right
      )
  }
  return columnCustomizationState
}

export const mapColumnStateToTableColumnSettings = <T>(
  columns: Column<T>[],
  columnOrderingState: string[]
): TableColumnSetting[] => {
  const columnSettings: TableColumnSetting[] = []
  columns.forEach((column) => {
    const setting: TableColumnSetting = {
      columnName: column.id,
      columnDisplayName:
        typeof column.columnDef.header === 'string'
          ? column.columnDef.header
          : '',
      order: columnOrderingState.findIndex((id) => id === column.id),
      show: column.getIsVisible(),
      canModify: !column.columnDef.enablePinning ?? true,
    }
    columnSettings.push(setting)
  })
  return columnSettings.sort((a, b) => a.order - b.order)
}

export const getDefaultTableColumnSettings = <T>(
  columns: Column<T>[]
): TableColumnSettings => {
  const columnSettings: TableColumnSettings = {}
  columns.forEach((column, index) => {
    const setting: TableColumnSetting = {
      columnName: column.id,
      columnDisplayName:
        typeof column.columnDef.header === 'string'
          ? column.columnDef.header
          : '',
      order: index,
      show: !column.columnDef.enableHiding ?? true,
      canModify: !column.columnDef.enablePinning ?? true,
    }
    columnSettings[column.id] = setting
  })
  return columnSettings
}
