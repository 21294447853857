import { getType } from 'typesafe-actions'
import { SideNavAction } from '../actions'
import { setSideNavIsOpenAction, toggleSideNavAction } from '../actions/sidenav'
import { SideNavState } from '../models/sidenav'

const initialState: SideNavState = {
  isOpen: true,
}

export default (
  state: SideNavState = initialState,
  action: SideNavAction
): SideNavState => {
  switch (action.type) {
    case getType(setSideNavIsOpenAction):
      return {
        ...state,
        isOpen: action.payload,
      }
    case getType(toggleSideNavAction):
      return {
        ...state,
        isOpen: !state.isOpen,
      }
    default:
      return {
        ...state,
      }
  }
}
