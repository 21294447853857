import { AssetKitInstance, IconType } from '../../models'
import { InpixonAssetGroupUpdate } from '../../models/inpixonMap'
import { MapRenderByGeofence } from '../../models/realtimeMap'
import { BadgeTelemetryMessageWithAgentEvent } from '../../models/telemetry'
import { updateInpixonGroupIconByType } from '../inpixon'
import { buildGroupIconUrl } from '../inpixonIcons'
import { calculateMapRenderToUpdateForAgentLeavingMap } from '../realtimeMap'
import { groupHasActiveDuress, groupHasActiveAssist } from '../telemetry'

/**
 * If agent is currently rendered as a group icon, after the agent left the floor,
 * update the current group icon to decrease the group count.
 * If the agent has an active duress and the remaining agents in the group does not have
 * any active duress, remove the geofence highlighting
 * If the leaving agent is currently selected on the map, then close the Detail Drawer.
 * Otherwise, if the leaving agent is in a selected group, remove that agent from the
 * Detail Drawer agent list
 * @param agentTelemetry
 * @param currentMapRender
 * @param remainingAgentsInLeavingGeofence
 * @param leavingGeofenceId
 * @param assetKit
 * @param jibestreamController
 * @param iconScale
 * @param selectedGroupId
 * @param selectedTrackingId
 * @param removeGeofenceHighlight
 * @param removeSelectedAgent
 * @param removeAgentFromSelectedGroup
 * @returns updated map render state after map rerendering
 */
export function updateGroupIconAfterAgentLeft(
  agentTelemetry: BadgeTelemetryMessageWithAgentEvent,
  currentMapRender: MapRenderByGeofence,
  remainingAgentsInLeavingGeofence: BadgeTelemetryMessageWithAgentEvent[],
  leavingGeofenceId: number,
  assetKit: AssetKitInstance,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any,
  iconScale: number,
  selectedGroupId: number | undefined,
  selectedTrackingId: string | undefined,
  removeGeofenceHighlight: (geofenceId: number) => void,
  removeSelectedAgent: (
    agentTelemtry: BadgeTelemetryMessageWithAgentEvent
  ) => void,
  removeAgentFromSelectedGroup: (
    agentTelemtry: BadgeTelemetryMessageWithAgentEvent
  ) => void
): MapRenderByGeofence {
  const updatedGroupHasActiveDuress = groupHasActiveDuress(
    remainingAgentsInLeavingGeofence
  )
  const updatedGroupHasActiveAssist = groupHasActiveAssist(
    remainingAgentsInLeavingGeofence
  )
  const groupToUpdateIcon: InpixonAssetGroupUpdate = {
    geoFenceId: leavingGeofenceId,
    hasActiveDuress: updatedGroupHasActiveDuress,
    hasActiveAssist: updatedGroupHasActiveAssist,
    isSelected: leavingGeofenceId === selectedGroupId,
    groupCount: remainingAgentsInLeavingGeofence.length,
  }
  const url = buildGroupIconUrl(groupToUpdateIcon.groupCount, iconScale, {
    hasActiveDuress: groupToUpdateIcon.hasActiveDuress,
    hasActiveAssist: groupToUpdateIcon.hasActiveAssist,
    isSelected: groupToUpdateIcon.isSelected,
  })
  updateInpixonGroupIconByType(
    assetKit,
    jibestreamController,
    {
      iconType: IconType.Group,
      geofenceId: groupToUpdateIcon.geoFenceId,
    },
    url
  )
  const updatedMapRender = calculateMapRenderToUpdateForAgentLeavingMap(
    currentMapRender,
    agentTelemetry
  )
  if (agentTelemetry.hasActiveDuress && !updatedGroupHasActiveDuress) {
    removeGeofenceHighlight(leavingGeofenceId)
  }
  if (agentTelemetry.trackingId === selectedTrackingId) {
    removeSelectedAgent(agentTelemetry)
  } else if (leavingGeofenceId === selectedGroupId) {
    removeAgentFromSelectedGroup(agentTelemetry)
  }
  return updatedMapRender
}
