import { LocationRouter } from '../models/locationRouter'
import { BaseService } from './baseService'

export class LocationRoutersApi extends BaseService {
  constructor() {
    super({ entityType: 'locationRouters' })
  }
  route = 'alert/locationRouters'

  async getAllLocationRouters(): Promise<LocationRouter[]> {
    return await this.get<LocationRouter[]>(this.route)
  }
}
