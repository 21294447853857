import { Epic } from 'redux-observable'
import { filter, mergeMap, map, catchError } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { from, of } from 'rxjs'
import { getSelectableLocationsAction } from '../actions/selectableLocations'
import { SelectableLocationsApi } from '../middleware/selectableLocations'
import { SelectableLocationsAction } from '../actions'
import { SelectableLocationsState } from '../models'

type SelectableLocationsEpic = Epic<
  SelectableLocationsAction,
  SelectableLocationsAction,
  SelectableLocationsState,
  { selectableLocationsApi: SelectableLocationsApi }
>
export const getSelectableLocationsActionEpic: SelectableLocationsEpic = (
  action$,
  state,
  { selectableLocationsApi }
) => {
  return action$.pipe(
    filter(isActionOf(getSelectableLocationsAction.request)),
    mergeMap(() =>
      from(selectableLocationsApi.getSelectableLocations()).pipe(
        map((payload) => getSelectableLocationsAction.success(payload)),
        catchError((error) => of(getSelectableLocationsAction.failure(error)))
      )
    )
  )
}
