import { useEffect, useState } from 'react'
import {
  useCurrentLocation,
  useFetchGeofencesByLocation,
  useFetchLocations,
} from '.'
import { getLocationAncestors } from '../helpers'
import { getBadgeStateSingle } from '../helpers/inpixon/badgeState'
import {
  AgentLocation,
  AgentLocationModel,
  BadgeCurrentLocation,
  FetchingStatus,
  Location,
  MapUpdate,
} from '../models'

const useAgentLocation = <T extends AgentLocationModel>(
  agent: T,
  liveBadgeLocations?: BadgeCurrentLocation[],
  mapUpdates?: MapUpdate[]
): AgentLocation<T> | null => {
  const [assetLocation, setAssetLocation] = useState<AgentLocation<T> | null>(
    null
  )
  const currentLocation = useCurrentLocation()
  const { data: locations, status: locationStatus } = useFetchLocations()
  const { data: geofences, status: geofenceStatus } =
    useFetchGeofencesByLocation(
      currentLocation?.currentLocation?.parentId ??
        currentLocation?.currentLocation?.id ??
        ''
    )

  const badgeState = getBadgeStateSingle(agent.badgeIds[0])

  useEffect(() => {
    if (
      geofenceStatus === FetchingStatus.Success &&
      locationStatus === FetchingStatus.Success &&
      locations &&
      geofences &&
      agent
    ) {
      let timestamp = agent.badgeTelemetry?.timestamp ?? ''
      let isPrivate = false
      let locationUnitName = ''
      let locationSubUnitName = ''
      let room: Location | undefined

      //Use Live Telemetry if changes are present - List View
      if (
        liveBadgeLocations &&
        liveBadgeLocations.some((x) => agent.badgeIds.includes(x.badgeId))
      ) {
        const location = liveBadgeLocations.find((x) =>
          agent.badgeIds.includes(x.badgeId)
        )
        if (location) {
          room = location.roomLocation
          timestamp = location.timestamp
        }
      }
      //Use Map Updates if changes are present - Map View
      else if (
        mapUpdates &&
        mapUpdates.some((x) => agent.badgeIds.includes(x.trackingId ?? ''))
      ) {
        const update = mapUpdates.find((x) =>
          agent.badgeIds.includes(x.trackingId ?? '')
        )
        if (update) {
          const roomLocationId = geofences[update.geoFenceId]?.locationId
          room = locations[roomLocationId]
        }
      }
      // pull from badge state
      else if (badgeState && geofences[badgeState.geoFenceId]?.locationId) {
        const roomLocationId = geofences[badgeState.geoFenceId]?.locationId
        room = locations[roomLocationId]
        timestamp = badgeState.timestamp ?? ''
      }
      //Fallback to asset geofenceId from state load
      else if (agent.badgeIds[0]) {
        if (agent.badgeTelemetry?.geoFenceId) {
          const roomLocationId =
            geofences[agent.badgeTelemetry.geoFenceId]?.locationId

          if (roomLocationId) {
            room = locations[roomLocationId]
            timestamp = agent.badgeTelemetry.timestamp ?? ''
          }
        }
      }

      const locationAncestors = getLocationAncestors(room, locations)
      if (room) {
        isPrivate = room.isPrivate ?? false
        locationUnitName = room.unitName ?? ''
        locationSubUnitName = room.subUnitName ?? ''
      }

      setAssetLocation({
        ...agent,
        roomLocation: room,
        floorLocation: locationAncestors.floor,
        building: locationAncestors.building,
        buildingGroup: locationAncestors.buildingGroup,
        timestamp: timestamp,
        isPrivate: isPrivate,
        locationUnitName,
        locationSubUnitName,
      })
    }
  }, [
    agent,
    geofenceStatus,
    geofences,
    locationStatus,
    locations,
    liveBadgeLocations,
    mapUpdates,
    badgeState,
  ])

  return assetLocation
}

export { useAgentLocation }
