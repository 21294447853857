import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getRolesAction } from '../../actions/roles'
import { FetchingStatus } from '../../models'
import {
  SortedRole,
  SortedRoles,
  TenantUserRolesState,
  UserRoles,
} from '../../models/roles'
import {
  selectAllFeatureFlags,
  selectFeatureFlagFetchingStatus,
} from '../../selectors'
import { mergeRecords } from '../../utils'
import { EntityResult, useEntities } from '../useEntities'

const useFetchRoles = (): EntityResult<SortedRoles> => {
  const fetchedRoles = useEntities<TenantUserRolesState, SortedRoles>(
    () => getRolesAction.request(),
    (state: any) => state.roles, // eslint-disable-line @typescript-eslint/no-explicit-any
    (status: FetchingStatus | undefined, lastRefreshTime: Date | undefined) =>
      !lastRefreshTime
  )

  const featureFlags = useSelector(selectAllFeatureFlags)
  const featureFlagFetchingStatus = useSelector(selectFeatureFlagFetchingStatus)
  const filteredRoles = useMemo(() => {
    let roles: SortedRoles = {}
    if (featureFlagFetchingStatus !== FetchingStatus.Request) {
      const isStaffVisibilityEnabled =
        Object.values(featureFlags).filter(
          (x) => x.name === 'StaffVisibility' && x.enabled === true
        ).length > 0
      const filteredRolesByFeatureFlags = isStaffVisibilityEnabled
        ? Object.values(fetchedRoles.data)
        : Object.values(fetchedRoles.data).filter(
            (role) =>
              role.id !== UserRoles.BioMedAdmin &&
              role.id !== UserRoles.ClinicalAssetAdmin &&
              role.id !== UserRoles.ClinicalAssetUser
          )
      roles = mergeRecords<SortedRoles, SortedRole, 'id'>(
        roles,
        filteredRolesByFeatureFlags,
        'id'
      )
    }
    return roles
  }, [featureFlags, featureFlagFetchingStatus, fetchedRoles.data])
  return {
    data: filteredRoles,
    status: fetchedRoles.status,
    error: fetchedRoles.error,
  }
}

export { useFetchRoles }
