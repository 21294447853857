import { createAction } from 'typesafe-actions'
import { BadgeDestination } from '@midmarkrtls/common/models'

export const setBadgeDestinationAction = createAction('SET_BADGE_DESTINATION')<{
  badgeID: string
  destinationId: string
}>()

export const setBadgesDestinationsAction = createAction(
  'SET_BADGES_DESTINATIONS'
)<BadgeDestination>()
