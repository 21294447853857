import React from 'react'
import {
  CreateOrEditModalProps,
  makeCreateOrEditModal,
} from '../../../components/Common/HOC'
import { Unit } from '../../../models'
import UnitManagementForm from './UnitManagementForm'

const UnitManagementModal = (props: CreateOrEditModalProps<Unit | null>) => (
  <UnitManagementForm {...props}></UnitManagementForm>
)

export default makeCreateOrEditModal<Unit | null>(UnitManagementModal)
