import React from 'react'
import { RightDrawerLayout } from '../../../components/Common/RightDrawer'
import { SubUnit } from '../../../models'
import SubUnitDetailPane from './SubUnitDetailPane'
import SubUnitDetailPaneHeader from './SubUnitDetailPaneHeader'

interface Props {
  selectedSubUnit: SubUnit
  onEditSubUnit: () => void
  onClose: () => void
}

const SubUnitDetailDrawer = (props: Props): JSX.Element => {
  const { selectedSubUnit, onEditSubUnit, onClose } = props
  return (
    <RightDrawerLayout
      header={
        <SubUnitDetailPaneHeader
          subUnit={selectedSubUnit}
          onEditSubUnit={onEditSubUnit}
          onClose={onClose}
        />
      }
      content={<SubUnitDetailPane subUnit={selectedSubUnit} />}
    />
  )
}

export default SubUnitDetailDrawer
