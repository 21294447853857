import React, { useEffect, useState } from 'react'
import { deletePropWithoutMutation, stripManagerEmail } from '../../helpers'
import { buildChipByColor } from '../../helpers/filters'
import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { localMomentFormatted } from '../../helpers/date'
import { colors } from '../../styles/MidmarkTheme'
import { CommonFilters } from '../../hooks/filters/useRealTimeFilters'
import { AssetFilters, StaffFilters } from '../../models/filters'

const useStyles = makeStyles(() =>
  createStyles({
    chipContainer: {
      marginBottom: 12,
    },
  })
)

interface Props {
  assetFilterState: AssetFilters
  staffFilterState: StaffFilters
  commonFilterState: CommonFilters
  handleAssetChange?: (changes: AssetFilters) => void
  handleStaffChange?: (changes: StaffFilters) => void
  disableAssetChips: boolean
  disableStaffChips: boolean
}

type FilterChipInfo = {
  key: string | number
  label: string
  onDelete: () => void
  color: string
}

const ListFilterChips = (props: Props): JSX.Element => {
  const {
    assetFilterState,
    staffFilterState,
    commonFilterState,
    handleAssetChange,
    handleStaffChange,
    disableAssetChips,
    disableStaffChips,
  } = props
  const classes = useStyles()

  const [assetsFilterChipInfos, setAssetsFilterChipInfos] = useState<
    FilterChipInfo[] | undefined
  >(undefined)

  const [commonFilterChipInfos, setCommonFilterChipInfos] = useState<
    FilterChipInfo[] | undefined
  >(undefined)

  const [staffFilterChipInfos, setStaffFilterChipInfos] = useState<
    FilterChipInfo[] | undefined
  >(undefined)

  const createFilterChipInfo = (
    key: string | number,
    label: string,
    onDelete: () => void,
    color: string
  ): FilterChipInfo => {
    return {
      key,
      label,
      onDelete,
      color,
    }
  }

  const handleCommonUpdate = (updateProp: string, value: any): void => {
    if (handleAssetChange) {
      handleAssetChange({
        ...assetFilterState,
        [updateProp as keyof AssetFilters]: value,
      })
    }
    if (handleStaffChange) {
      handleStaffChange({
        ...staffFilterState,
        [updateProp as keyof StaffFilters]: value,
      })
    }
  }

  const handleCommonDelete = (filterToRemove: string): void => {
    if (handleAssetChange) {
      handleAssetChange(
        deletePropWithoutMutation(
          assetFilterState,
          filterToRemove as keyof AssetFilters
        )
      )
    }
    if (handleStaffChange) {
      handleStaffChange(
        deletePropWithoutMutation(
          staffFilterState,
          filterToRemove as keyof StaffFilters
        )
      )
    }
  }

  useEffect(() => {
    let assetsFilterChipInfos: FilterChipInfo[] = []
    let commonFilterChipInfos: FilterChipInfo[] = []
    let staffFilterChipInfos: FilterChipInfo[] = []

    // Handle Common Filter Changes
    if (commonFilterState.hasBadge) {
      commonFilterChipInfos.push(
        createFilterChipInfo(
          'hasBadge',
          'Has Badge',
          () => handleCommonDelete('hasBadge'),
          colors.background
        )
      )
    }

    if (commonFilterState.isWatchlist) {
      commonFilterChipInfos.push(
        createFilterChipInfo(
          'isWatchlist',
          'On My List',
          () => handleCommonDelete('isWatchlist'),
          colors.background
        )
      )
    }

    if (handleAssetChange) {
      if (assetFilterState.hasRecall) {
        assetsFilterChipInfos.push(
          createFilterChipInfo(
            'hasRecall',
            'Has Recall',
            () =>
              handleAssetChange(
                deletePropWithoutMutation(assetFilterState, 'hasRecall')
              ),
            colors.primary
          )
        )
      }

      if (assetFilterState.isPmThisMonth) {
        assetsFilterChipInfos.push(
          createFilterChipInfo(
            'isPmThisMonth',
            'Open PMs',
            () =>
              handleAssetChange(
                deletePropWithoutMutation(assetFilterState, 'isPmThisMonth')
              ),
            colors.primary
          )
        )
      }
    }

    if (handleAssetChange) {
      if (commonFilterState.signalAccuracy) {
        commonFilterChipInfos = commonFilterChipInfos.concat(
          commonFilterState.signalAccuracy.map((signalAccuracy) =>
            createFilterChipInfo(
              `signalAccuracy-${signalAccuracy.id}`,
              `Location Accuracy: ${signalAccuracy.name}`,
              () => {
                const newSignalAccuracy = commonFilterState.signalAccuracy
                  ? [...commonFilterState.signalAccuracy]
                  : []

                newSignalAccuracy.splice(
                  newSignalAccuracy.findIndex(
                    (x) => x.id === signalAccuracy.id
                  ),
                  1
                )

                handleCommonUpdate('signalAccuracy', newSignalAccuracy)
              },
              colors.background
            )
          )
        )
      }
    }

    if (handleAssetChange) {
      if (commonFilterState.signalStatus) {
        commonFilterChipInfos = commonFilterChipInfos.concat(
          commonFilterState.signalStatus.map((signalStatus) =>
            createFilterChipInfo(
              `signalStatus-${signalStatus.id}`,
              `Signal Status: ${signalStatus.name}`,
              () => {
                const newSignalStatus = commonFilterState.signalStatus
                  ? [...commonFilterState.signalStatus]
                  : []

                newSignalStatus.splice(
                  newSignalStatus.findIndex((x) => x.id === signalStatus.id),
                  1
                )

                handleCommonUpdate('signalStatus', newSignalStatus)
              },
              colors.background
            )
          )
        )
      }
    }

    if (handleAssetChange) {
      if (commonFilterState.signalType) {
        commonFilterChipInfos = commonFilterChipInfos.concat(
          commonFilterState.signalType.map((signalType) =>
            createFilterChipInfo(
              `signalType-${signalType.id}`,
              `Signal Type: ${signalType.name}`,
              () => {
                const newSignalType = commonFilterState.signalType
                  ? [...commonFilterState.signalType]
                  : []

                newSignalType.splice(
                  newSignalType.findIndex((x) => x.id === signalType.id),
                  1
                )

                handleCommonUpdate('signalType', newSignalType)
              },
              colors.background
            )
          )
        )
      }
    }

    if (handleAssetChange) {
      if (commonFilterState.batteryStatus) {
        commonFilterChipInfos = commonFilterChipInfos.concat(
          commonFilterState.batteryStatus.map((batteryStatus) =>
            createFilterChipInfo(
              `batteryStatus-${batteryStatus.id}`,
              `Battery Status: ${batteryStatus.name}`,
              () => {
                const newBatteryStatus = commonFilterState.batteryStatus
                  ? [...commonFilterState.batteryStatus]
                  : []

                newBatteryStatus.splice(
                  newBatteryStatus.findIndex((x) => x.id === batteryStatus.id),
                  1
                )

                handleCommonUpdate('batteryStatus', newBatteryStatus)
              },
              colors.background
            )
          )
        )
      }
    }

    if (commonFilterState.tagBatteryChangeDate) {
      commonFilterChipInfos.push(
        createFilterChipInfo(
          'tagBatteryChangeDate',
          `RTLS Battery Change Date: ${localMomentFormatted(
            commonFilterState.tagBatteryChangeDate,
            'MM/DD/YYYY'
          )}`,
          () => handleCommonDelete('tagBatteryChangeDate'),
          colors.background
        )
      )
    }

    // Handle Asset Filter Changes
    if (handleAssetChange) {
      if (assetFilterState.cmmsManaged) {
        assetsFilterChipInfos.push(
          createFilterChipInfo(
            'cmmsManaged-asset',
            'Managed by CMMS',
            () =>
              handleAssetChange(
                deletePropWithoutMutation(assetFilterState, 'cmmsManaged')
              ),
            disableAssetChips ? colors.background : colors.primary
          )
        )
      }
      if (assetFilterState.displayNames) {
        assetsFilterChipInfos = assetsFilterChipInfos.concat(
          assetFilterState.displayNames.map((dispName) =>
            createFilterChipInfo(
              `name-${dispName.id}-asset`,
              `Name: ${dispName.name}`,
              () => {
                const newDisplayNames = assetFilterState.displayNames
                  ? [...assetFilterState.displayNames]
                  : []

                newDisplayNames.splice(
                  newDisplayNames.findIndex((x) => x.id === dispName.id),
                  1
                )

                return handleAssetChange({
                  ...assetFilterState,
                  displayNames: newDisplayNames,
                })
              },
              colors.primary
            )
          )
        )
      }

      if (
        assetFilterState.assetTypes &&
        assetFilterState.assetTypes.length > 0
      ) {
        assetsFilterChipInfos = assetsFilterChipInfos.concat(
          assetFilterState.assetTypes.map((type) =>
            createFilterChipInfo(
              `assetType-${type.assetTypeId}-asset`,
              `Type: ${type.name}`,
              () => {
                const newTypes = assetFilterState.assetTypes
                  ? [...assetFilterState.assetTypes]
                  : []

                newTypes.splice(
                  newTypes.findIndex((x) => x.assetTypeId === type.assetTypeId),
                  1
                )

                return handleAssetChange({
                  ...assetFilterState,
                  assetTypes: newTypes,
                })
              },
              colors.primary
            )
          )
        )
      }

      if (
        assetFilterState.assetSubTypes &&
        assetFilterState.assetSubTypes.length > 0
      ) {
        assetsFilterChipInfos = assetsFilterChipInfos.concat(
          assetFilterState.assetSubTypes.map((subType) =>
            createFilterChipInfo(
              `assetSubType-${subType.assetSubTypeId}-asset`,
              `Sub-Type: ${subType.name}`,
              () => {
                const newSubTypes = assetFilterState.assetSubTypes
                  ? [...assetFilterState.assetSubTypes]
                  : []

                newSubTypes.splice(
                  newSubTypes.findIndex(
                    (x) => x.assetSubTypeId === subType.assetSubTypeId
                  ),
                  1
                )

                return handleAssetChange({
                  ...assetFilterState,
                  assetSubTypes: newSubTypes,
                })
              },
              colors.primary
            )
          )
        )
      }

      if (
        assetFilterState.manufacturers &&
        assetFilterState.manufacturers.length > 0
      ) {
        assetsFilterChipInfos = assetsFilterChipInfos.concat(
          assetFilterState.manufacturers.map((man) =>
            createFilterChipInfo(
              `manufacturer-${man.manufacturerGuid}-asset`,
              `Manufacturer: ${man.name}`,
              () => {
                const newManufacturers = assetFilterState.manufacturers
                  ? [...assetFilterState.manufacturers]
                  : []

                newManufacturers.splice(
                  newManufacturers.findIndex(
                    (x) => x.manufacturerGuid === man.manufacturerGuid
                  ),
                  1
                )

                return handleAssetChange({
                  ...assetFilterState,
                  manufacturers: newManufacturers,
                })
              },
              colors.primary
            )
          )
        )
      }

      if (assetFilterState.models) {
        assetsFilterChipInfos = assetsFilterChipInfos.concat(
          assetFilterState.models.map((model) =>
            createFilterChipInfo(
              `model-${model.id}-asset`,
              `Model: ${model.name}`,
              () => {
                const newModels = assetFilterState.models
                  ? [...assetFilterState.models]
                  : []

                newModels.splice(
                  newModels.findIndex((x) => x.id === model.id),
                  1
                )

                return handleAssetChange({
                  ...assetFilterState,
                  models: newModels,
                })
              },
              colors.primary
            )
          )
        )
      }

      if (assetFilterState.owners) {
        assetsFilterChipInfos = assetsFilterChipInfos.concat(
          assetFilterState.owners.map((owner) =>
            createFilterChipInfo(
              `owner-${owner.id}-asset`,
              `Owner: ${owner.name}`,
              () => {
                const newOwners = assetFilterState.owners
                  ? [...assetFilterState.owners]
                  : []

                newOwners.splice(
                  newOwners.findIndex((x) => x.id === owner.id),
                  1
                )

                return handleAssetChange({
                  ...assetFilterState,
                  owners: newOwners,
                })
              },
              colors.primary
            )
          )
        )
      }

      if (assetFilterState.preventativeMaintenanceDate) {
        assetsFilterChipInfos.push(
          createFilterChipInfo(
            'preventativeMaintenanceDate-asset',
            `Next PM Date: ${localMomentFormatted(
              assetFilterState.preventativeMaintenanceDate,
              'MM/DD/YYYY'
            )}`,
            () =>
              handleAssetChange(
                deletePropWithoutMutation(
                  assetFilterState,
                  'preventativeMaintenanceDate'
                )
              ),
            colors.primary
          )
        )
      }

      if (
        commonFilterState.assignedTo &&
        commonFilterState.assignedTo.length > 0
      ) {
        commonFilterChipInfos = commonFilterChipInfos.concat(
          commonFilterState.assignedTo.map((assignedTo) =>
            createFilterChipInfo(
              `assignedTo-${assignedTo.id}`,
              `Assigned To: ${assignedTo.name}`,
              () => {
                const newAssignedTo = commonFilterState.assignedTo
                  ? [...commonFilterState.assignedTo]
                  : []

                newAssignedTo.splice(
                  newAssignedTo.findIndex((x) => x.id === assignedTo.id),
                  1
                )

                return handleCommonUpdate('assignedTo', newAssignedTo)
              },
              colors.background
            )
          )
        )
      }

      if (commonFilterState.isInUse) {
        commonFilterChipInfos.push(
          createFilterChipInfo(
            'isInUse',
            'Is In Use',
            () => handleCommonDelete('isInUse'),
            colors.background
          )
        )
      }

      if (assetFilterState.hasWorkOrder) {
        assetsFilterChipInfos.push(
          createFilterChipInfo(
            'hasWorkOrder-asset',
            'Has Work Order',
            () =>
              handleAssetChange(
                deletePropWithoutMutation(assetFilterState, 'hasWorkOrder')
              ),
            colors.primary
          )
        )
      }

      if (assetFilterState.serialNumber) {
        assetsFilterChipInfos.push(
          createFilterChipInfo(
            'serialNumber-asset',
            assetFilterState.serialNumber,
            () =>
              handleAssetChange(
                deletePropWithoutMutation(assetFilterState, 'serialNumber')
              ),
            colors.primary
          )
        )
      }

      if (assetFilterState.badgeNumber) {
        assetsFilterChipInfos.push(
          createFilterChipInfo(
            'badgeNumber-asset',
            assetFilterState.badgeNumber,
            () =>
              handleAssetChange(
                deletePropWithoutMutation(assetFilterState, 'badgeNumber')
              ),
            colors.primary
          )
        )
      }
    }

    // Handle Staff Filter Changes
    if (handleStaffChange) {
      if (staffFilterState.staffTypes) {
        staffFilterChipInfos = staffFilterChipInfos.concat(
          staffFilterState.staffTypes.map((staffType) =>
            createFilterChipInfo(
              `staffRole-${staffType.staffTypeId}-staff`,
              `Role: ${staffType.name}`,
              () => {
                const newStaffTypes = staffFilterState.staffTypes
                  ? [...staffFilterState.staffTypes]
                  : []

                newStaffTypes.splice(
                  newStaffTypes.findIndex(
                    (x) => x.staffTypeId === staffType.staffTypeId
                  ),
                  1
                )

                return handleStaffChange({
                  ...staffFilterState,
                  staffTypes: newStaffTypes,
                })
              },
              colors.staff
            )
          )
        )
      }

      if (staffFilterState.reportsTo && staffFilterState.reportsTo.length > 0) {
        staffFilterChipInfos = staffFilterChipInfos.concat(
          staffFilterState.reportsTo.map((report) =>
            createFilterChipInfo(
              `staffReportsTo-${report.id}-staff`,
              `Reports To: ${stripManagerEmail(report.name)}`,
              () => {
                const newTypes = staffFilterState.reportsTo
                  ? [...staffFilterState.reportsTo]
                  : []

                newTypes.splice(
                  newTypes.findIndex((x) => x.id === report.id),
                  1
                )

                return handleStaffChange({
                  ...staffFilterState,
                  reportsTo: newTypes,
                })
              },
              colors.staff
            )
          )
        )
      }
    }

    setCommonFilterChipInfos(commonFilterChipInfos)
    setAssetsFilterChipInfos(assetsFilterChipInfos)
    setStaffFilterChipInfos(staffFilterChipInfos)
  }, [
    assetFilterState,
    commonFilterState,
    disableAssetChips,
    disableStaffChips,
    handleAssetChange,
    handleStaffChange,
    staffFilterState,
  ])

  return (
    <>
      {!disableAssetChips && !!assetsFilterChipInfos?.length && (
        <div className={classes.chipContainer}>
          <Typography variant='body2' style={{ fontWeight: 'bold' }}>
            {'CURRENT ASSET FILTERS'}
          </Typography>
          {assetsFilterChipInfos &&
            assetsFilterChipInfos.map((filterChipInfo) =>
              buildChipByColor(
                filterChipInfo.key,
                filterChipInfo.label,
                filterChipInfo.onDelete,
                filterChipInfo.color
              )
            )}
        </div>
      )}

      {!!commonFilterChipInfos?.length && (
        <div className={classes.chipContainer}>
          <Typography variant='body2' style={{ fontWeight: 'bold' }}>
            {'CURRENT COMMON FILTERS'}
          </Typography>
          {commonFilterChipInfos &&
            commonFilterChipInfos.map((filterChipInfo) =>
              buildChipByColor(
                filterChipInfo.key,
                filterChipInfo.label,
                filterChipInfo.onDelete,
                filterChipInfo.color
              )
            )}
        </div>
      )}

      {!disableStaffChips && !!staffFilterChipInfos?.length && (
        <div className={classes.chipContainer}>
          <Typography variant='body2' style={{ fontWeight: 'bold' }}>
            {'CURRENT STAFF FILTERS'}
          </Typography>
          {staffFilterChipInfos &&
            staffFilterChipInfos.map((filterChipInfo) =>
              buildChipByColor(
                filterChipInfo.key,
                filterChipInfo.label,
                filterChipInfo.onDelete,
                filterChipInfo.color
              )
            )}
        </div>
      )}
    </>
  )
}

export default ListFilterChips
