import React, { ReactNode, ReactNodeArray } from 'react'
import { AppBar, Toolbar } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { getStyling, StyledComponent, StyledComponentProps } from '../'
import { FlexBox } from '../Layout'
import { ClassNameMap } from '@mui/styles'
import { actionBarContainerMinHeight } from './constants'

interface Props {
  children?: ReactNodeArray | ReactNode
}

const styles = createStyles({
  root: {
    backgroundColor: '#313131',
  },
  container: {
    minHeight: actionBarContainerMinHeight,
  },
  gutters: {
    paddingLeft: 24,
    paddingRight: 16,
  },
})

export type ActionBarContainerStyles = Partial<
  ClassNameMap<keyof typeof styles>
>

const ActionBarContainer: StyledComponent<Props, typeof styles> = (
  props: StyledComponentProps<Props, typeof styles>
) => {
  const { classes, children } = props

  return (
    <AppBar elevation={0} position='relative' className={classes.root}>
      <Toolbar variant='dense' {...getStyling(props, classes.gutters)}>
        <FlexBox
          className={classes.container}
          flex='grow'
          layout='row'
          layoutAlign={{ item: 'center' }}
        >
          {children}
        </FlexBox>
      </Toolbar>
    </AppBar>
  )
}

export default withStyles(styles, { name: 'ActionBarContainer' })(
  ActionBarContainer
)
