import React, { useMemo } from 'react'
import { useFetchRoles, useFetchUnits } from '../../hooks'
import { allRoles, UserRoles } from '../../models'
import { AlertActiveConfig, AlertTypes } from '../../models/alert'
import { DetailedItem } from '../../models/DetailPaneItems'
import DetailsList from '../DetailsList/DetailsList'

interface Props {
  alert: AlertActiveConfig
  isLoading?: boolean
  headerStylesOverrides?: any
  listItemStylesOverrides?: any
}

const AlertRouteDetail = (props: Props) => {
  const {
    alert,
    isLoading = false,
    headerStylesOverrides,
    listItemStylesOverrides,
  } = props

  const { data: roles } = useFetchRoles()
  const { data: units } = useFetchUnits()

  const fixedRouteItems = useMemo(() => {
    const results: any[] = []
    if (
      alert?.locationRouter &&
      alert.alertType?.id !== AlertTypes.AssetAlert
    ) {
      results.push({
        field: 'Building Group',
        value: alert.locationRouter?.campus?.name ?? '',
      })

      results.push({
        field: 'Building',
        value: alert.locationRouter?.building?.name ?? '',
      })

      results.push({
        field: 'Floor',
        value: alert.locationRouter?.floor?.name ?? '',
      })
    }

    if (alert.unitRouter && alert.alertType?.id !== AlertTypes.AssetAlert) {
      results.push({
        field: 'Unit',
        value: alert.unitRouter?.name,
      })
    }

    if (
      alert.userRouters?.length > 0 &&
      alert.alertType?.id !== AlertTypes.AssetAlert
    ) {
      alert.userRouters.map((x, index) => {
        const role = Object.values(roles).find((y) => y.id == +x.ruleValue)
        results.push({
          field: index === 0 ? 'Routed To RTLS Role' : '',
          value: role?.name,
        })
      })
    } else if (alert.alertType?.id !== AlertTypes.AssetAlert) {
      results.push({
        field: 'Routed To RTLS Role',
        value: allRoles.name,
      })
    } else if (alert.alertType?.id === AlertTypes.AssetAlert) {
      results.push({
        field: 'Routing',
        value: 'CareFlow Desktop App Subscribers',
      })
    }

    return results
  }, [alert, roles, units])

  const routeItems = useMemo(() => {
    const results: any[] = []
    if (alert.securityRouters?.length > 0) {
      alert.securityRouters.map((x, index) => {
        const role = Object.values(roles).find((y) => y.id == +x.ruleValue)
        results.push({
          field: index === 0 ? 'Routed To RTLS Role' : '',
          value: role?.name,
        })
      })
    } else {
      results.push({
        field: 'Routed To RTLS Role',
        value: Object.values(roles)
          .filter(
            (x) =>
              x.id === UserRoles.SecurityAdmin ||
              x.id === UserRoles.SecurityUser
          )
          .map((x) => x.name)
          .join(', '),
      })
    }

    return results
  }, [alert, roles])

  const alertRouteDetailItems: DetailedItem[] = []
  if (alert.alertType?.id === AlertTypes.StaffDuress) {
    alertRouteDetailItems.push({
      header: 'PEOPLE REGARDLESS OF LOCATION',
      items: routeItems,
    })
  } else if (alert.alertType?.id === AlertTypes.AssetAlert) {
    alertRouteDetailItems.push({
      header: '',
      items: fixedRouteItems,
    })
  }
  const alertRouteFixedDetailItems: DetailedItem[] = []
  if (alert.alertType?.id !== AlertTypes.AssetAlert) {
    alertRouteFixedDetailItems.push({
      header: 'PEOPLE IN SPECIFIC LOCATION',
      items: fixedRouteItems,
    })
  }

  return (
    <>
      <DetailsList
        detailedItems={alertRouteFixedDetailItems}
        narrowRows={true}
        multiLine={true}
        headerStylesOverrides={headerStylesOverrides}
        listItemStylesOverrides={listItemStylesOverrides}
        isLoading={isLoading}
      />
      <DetailsList
        detailedItems={alertRouteDetailItems}
        narrowRows={true}
        multiLine={true}
        headerStylesOverrides={headerStylesOverrides}
        listItemStylesOverrides={listItemStylesOverrides}
        isLoading={isLoading}
      />
    </>
  )
}

export default AlertRouteDetail
