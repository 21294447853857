import { createAsyncAction } from 'typesafe-actions'
import { UserSettings, UserSettingsType } from '../models/userSettings'
import { HttpErrorResponse } from '../services/http'

export const getUserSettingsAction = createAsyncAction(
  'GET_USER_SETTINGS_REQUEST',
  'GET_USER_SETTINGS_SUCCESS',
  'GET_USER_SETTINGS_FAILURE'
)<void, UserSettings, HttpErrorResponse>()

export const patchUserSettingsAction = createAsyncAction(
  'PATCH_USER_SETTINGS_REQUEST',
  'PATCH_USER_SETTINGS_SUCCESS',
  'PATCH_USER_SETTINGS_FAILURE'
)<UserSettings, UserSettings, HttpErrorResponse>()

export const deleteUserSettingsAction = createAsyncAction(
  'DELETE_USER_SETTINGS_REQUEST',
  'DELETE_USER_SETTINGS_SUCCESS',
  'DELETE_USER_SETTINGS_FAILURE'
)<UserSettingsType, UserSettings, HttpErrorResponse>()
