import {
  buildConfidenceBubbleAgentEventOptionsForFetchedAgents,
  buildConfidenceBubbleAgentEventOptionsForLiveTelemetry,
  getTelemetriesByAgentTypesPlural,
  replaceAgentInNewTelemetryWithFetchedAgentInState,
} from '.'
import { findItemsInArrayAButNotInArrayB } from '..'
import { Criteria, filterByCriteria } from '../../filters'
import {
  AgentType,
  AgentTypePlural,
  Asset,
  Geofences,
  Staff,
  StaffEvent,
  TelemetryAsset,
  TelemetryStaff,
} from '../../models'
import { MapRender, MapRenderByGeofence } from '../../models/realtimeMap'
import {
  AgentTelemetriesGroupedByTrackingId,
  BadgeTelemetryMessageWithAgentEvent,
  BaseBadgeTelemetryMessage,
  NewAgentsEnteringMapTelemetry,
  TelemetryByAgentType,
} from '../../models/telemetry'
import { mergeRecord, mergeRecords } from '../../utils'
import { calculateAgentSignalType } from '../assetSignal'
import { getDefaultGeofenceGroupCount } from '../inpixon'

/**
 * Take badge telemetry data in the fetched assets and staffs and map it to the new telemetry data model
 * with calculated duress/assist data used for map rendering
 * @param fetchedAssets fetched assets by location
 * @param fetchedStaffs fetched staff by location
 * @param mapId
 * @param allDuressEvents
 * @param allAssistEvents
 * @param staffDuressAndAssistFeatureFlagEnabled
 * @returns telemetry data with calculated duress/assist grouped by agent type (Asset/Staff)
 */
export function combineFetchedBadgeTelemetriesByAgentTypes(
  fetchedAssets: Asset[],
  fetchedStaffs: Staff[],
  mapId: number | undefined,
  allDuressEvents: StaffEvent[],
  allAssistEvents: StaffEvent[],
  staffDuressAndAssistFeatureFlagEnabled: boolean
): TelemetryByAgentType {
  let assetAgentTelemetries: AgentTelemetriesGroupedByTrackingId<TelemetryAsset> =
    {}
  let staffAgentTelemetries: AgentTelemetriesGroupedByTrackingId<TelemetryStaff> =
    {}
  if (fetchedAssets.length > 0) {
    const assetTelemetry = getBadgeTelemetriesFromFetchedAgents(
      fetchedAssets,
      AgentType.Asset,
      mapId,
      allDuressEvents,
      allAssistEvents,
      staffDuressAndAssistFeatureFlagEnabled
    )
    assetAgentTelemetries = mergeRecords<
      AgentTelemetriesGroupedByTrackingId<TelemetryAsset>,
      BadgeTelemetryMessageWithAgentEvent<TelemetryAsset>,
      'trackingId'
    >(
      assetAgentTelemetries,
      assetTelemetry as BadgeTelemetryMessageWithAgentEvent<TelemetryAsset>[],
      'trackingId'
    )
  }
  if (fetchedStaffs.length > 0) {
    const staffTelemetry = getBadgeTelemetriesFromFetchedAgents(
      fetchedStaffs,
      AgentType.Staff,
      mapId,
      allDuressEvents,
      allAssistEvents,
      staffDuressAndAssistFeatureFlagEnabled
    )
    staffAgentTelemetries = mergeRecords<
      AgentTelemetriesGroupedByTrackingId<TelemetryStaff>,
      BadgeTelemetryMessageWithAgentEvent<TelemetryStaff>,
      'trackingId'
    >(
      staffAgentTelemetries,
      staffTelemetry as BadgeTelemetryMessageWithAgentEvent<TelemetryStaff>[],
      'trackingId'
    )
  }
  return {
    assetAgentTelemetries,
    staffAgentTelemetries,
  }
}

/**
 * Take badge telemetry data in the fetched agents and map it to the new telemetry data model
 * with calculated duress/assist data used for map rendering
 * @param fetchedAgents fetched agents (assets/staffs) by location
 * @param agentType Asset/Staff
 * @param mapId
 * @param allDuressEvents
 * @param allAssistEvents
 * @param staffDuressAndAssistFeatureFlagEnabled
 * @returns telemetry data with calculated duress/assist
 */
function getBadgeTelemetriesFromFetchedAgents(
  fetchedAgents: Asset[] | Staff[],
  agentType: AgentType,
  mapId: number | undefined,
  allDuressEvents: StaffEvent[],
  allAssistEvents: StaffEvent[],
  staffDuressAndAssistFeatureFlagEnabled: boolean
): BadgeTelemetryMessageWithAgentEvent<TelemetryAsset | TelemetryStaff>[] {
  if (!mapId || fetchedAgents.length === 0) {
    return []
  }

  const badgeTelemetries: BadgeTelemetryMessageWithAgentEvent[] = fetchedAgents
    .filter(
      (fetchedAgent) =>
        !!fetchedAgent.agentGuid &&
        fetchedAgent.badgeTelemetry &&
        fetchedAgent.badgeTelemetry.lat &&
        fetchedAgent.badgeTelemetry.lon &&
        fetchedAgent.badgeTelemetry.geoFenceId
    )
    .map((fetchedAgent) => {
      const signalTypeId = calculateAgentSignalType(fetchedAgent)
      let asset: TelemetryAsset | undefined
      let staff: TelemetryStaff | undefined
      if (agentType === AgentType.Asset) {
        const castedFetchedAgent = fetchedAgent as Asset
        const {
          badgeIds,
          badgeTelemetry,
          roomLocation,
          floorLocation,
          buildingLocation,
          buildingGroupLocation,
          ...rest
        } = castedFetchedAgent
        asset = rest
      } else {
        const {
          badgeIds,
          badgeTelemetry,
          roomLocation,
          floorLocation,
          buildingLocation,
          buildingGroupLocation,
          ...rest
        } = fetchedAgent as Staff
        staff = rest
      }
      const { hasActiveDuress, hasActiveAssist, hasResolvedDuressOrAssist } =
        buildConfidenceBubbleAgentEventOptionsForFetchedAgents(
          fetchedAgent.badgeIds[0],
          allDuressEvents,
          allAssistEvents,
          staffDuressAndAssistFeatureFlagEnabled
        )
      return {
        agent:
          agentType === AgentType.Asset
            ? (asset as TelemetryAsset)
            : (staff as TelemetryStaff),
        customerId: fetchedAgent.badgeTelemetry.customerId ?? '',
        trackingId: fetchedAgent.badgeIds[0],
        signalTypeId,
        lon: fetchedAgent.badgeTelemetry.lon,
        lat: fetchedAgent.badgeTelemetry.lat,
        locationUncertainty:
          fetchedAgent.badgeTelemetry.locationUncertainty ?? 1,
        sensorId: '',
        mapId: fetchedAgent.badgeTelemetry.mapId,
        geoFenceId: fetchedAgent.badgeTelemetry.geoFenceId,
        isLowBattery: fetchedAgent.isLowBattery,
        prevMapId: undefined,
        timestamp: fetchedAgent.badgeTelemetry.timestamp ?? new Date(),
        hasActiveDuress,
        hasActiveAssist,
        hasResolvedDuressOrAssist,
        isAgentEventTelemetry: false,
      }
    })
  return badgeTelemetries
}

/**
 * Group telemetries by geofenceId
 * @param badgeTelemetries
 * @param geofences
 * @returns telemetries grouped by geofenceIds
 */
export function sortInitialFetchedTelemetries(
  badgeTelemetries: BadgeTelemetryMessageWithAgentEvent[],
  geofences: Geofences
): MapRenderByGeofence {
  let initialMapRendering: MapRenderByGeofence = {}
  Object.values(geofences).forEach((geofence) => {
    if (!geofence.coreReferenceId) {
      return
    }
    const maxAgentsAllowedInGeofence = getDefaultGeofenceGroupCount(
      geofences,
      geofence.coreReferenceId
    )
    const agentsInGeofence = badgeTelemetries.filter(
      (telemetry) => telemetry.geoFenceId === geofence.coreReferenceId
    )
    initialMapRendering = mergeRecord<
      MapRenderByGeofence,
      MapRender,
      'geoFenceId'
    >(
      initialMapRendering,
      {
        geoFenceId: geofence.coreReferenceId,
        maxAgentsAllowedInGeofence,
        renderedAgents: agentsInGeofence,
      },
      'geoFenceId'
    )
  })
  return initialMapRendering
}

/**
 * Filter telemetries by agent types and current filter query and search criteria.
 * Only telemetries with trackingIds found in fetched assets/staffs are included
 * @param agentTelemetries
 * @param fetchedAssetsByLocation
 * @param fetchedStaffsByLocation
 * @param agentTypes
 * @param assetFilterCriteria asset search and filter criteria
 * @param staffFilterCriteria staff search and filter criteria
 * @returns telemetries fitlered by search and filter criteria and agent types. Only telemetries with trackingIds
 * found in fetched assets/staffs are included
 */
export function filterTelemetries(
  agentTelemetries: TelemetryByAgentType,
  fetchedAssetsByLocation: Asset[],
  fetchedStaffsByLocation: Staff[],
  agentTypes: string[],
  assetFilterCriteria: Array<Criteria<Asset>>,
  staffFilterCriteria: Array<Criteria<Staff>>
): BadgeTelemetryMessageWithAgentEvent[] {
  const filteredTelemetriesByAgentTypes = Object.values(
    getTelemetriesByAgentTypesPlural(agentTelemetries, agentTypes)
  )
  const filteredAssets = filterByCriteria(
    fetchedAssetsByLocation,
    assetFilterCriteria
  )
  const filteredStaffs = filterByCriteria(
    fetchedStaffsByLocation,
    staffFilterCriteria
  )
  let filteredAssetTelemetries: BadgeTelemetryMessageWithAgentEvent[] = []
  let filteredStaffTelemetries: BadgeTelemetryMessageWithAgentEvent[] = []
  if (
    agentTypes.includes(AgentTypePlural[AgentTypePlural.Assets]) &&
    agentTypes.includes(AgentTypePlural[AgentTypePlural.Staff])
  ) {
    if (filteredAssets) {
      filteredAssetTelemetries = filteredTelemetriesByAgentTypes.filter(
        (telemetry) =>
          filteredAssets
            .map((asset) => asset.agentGuid)
            .includes(telemetry.agent.agentGuid)
      )
    }
    if (filteredStaffs) {
      filteredStaffTelemetries = filteredTelemetriesByAgentTypes.filter(
        (telemetry) =>
          filteredStaffs
            .map((staff) => staff.agentGuid)
            .includes(telemetry.agent.agentGuid)
      )
    }
    return [...filteredAssetTelemetries, ...filteredStaffTelemetries]
  } else if (agentTypes.includes(AgentTypePlural[AgentTypePlural.Assets])) {
    if (!filteredAssets) {
      return []
    }
    filteredAssetTelemetries = filteredTelemetriesByAgentTypes.filter(
      (telemetry) =>
        filteredAssets
          .map((asset) => asset.agentGuid)
          .includes(telemetry.agent.agentGuid)
    )
    return filteredAssetTelemetries
  } else if (agentTypes.includes(AgentTypePlural[AgentTypePlural.Staff])) {
    if (!filteredStaffs) {
      return []
    }
    filteredStaffTelemetries = filteredTelemetriesByAgentTypes.filter(
      (telemetry) =>
        filteredStaffs
          .map((staff) => staff.agentGuid)
          .includes(telemetry.agent.agentGuid)
    )
    return filteredStaffTelemetries
  }
  return []
}

/**
 * Filter telemetry by the current agent type (Asset/Staff)
 * @param agentTelemetries
 * @param agentType
 * @returns telemetries filtered by agent type
 */
export function filterAgentTelemetriesByAgentType<T = Asset | Staff>(
  agentTelemetries: AgentTelemetriesGroupedByTrackingId<T>,
  agentType: AgentType
): AgentTelemetriesGroupedByTrackingId<T> {
  let filteredAgentTelemetriesByTrackingId: AgentTelemetriesGroupedByTrackingId<T> =
    {}
  const filteredAgentTelemetries = Object.values(agentTelemetries).filter(
    (telemetry) =>
      agentType === AgentType.Asset
        ? (telemetry.agent as Asset).agentType === agentType
        : (telemetry.agent as Staff).agentType === agentType
  )
  filteredAgentTelemetriesByTrackingId = mergeRecords<
    AgentTelemetriesGroupedByTrackingId<T>,
    BadgeTelemetryMessageWithAgentEvent<T>,
    'trackingId'
  >(
    filteredAgentTelemetriesByTrackingId,
    filteredAgentTelemetries,
    'trackingId'
  )
  return filteredAgentTelemetriesByTrackingId
}

/**
 * Filter telemetry received via SignalR to only include those with an asset or staff in the agent prop.
 * Transform telemetry to include agent event data (whether telemetry has an active or resolved duress/assist).
 * Additionally, the asset/staff object in the telemetry data is replaced with that retrieved from the original
 * fetched assets/staffs, which has all the props needed for the Detail Drawer.
 * @param telemetries
 * @param previousAgentTelemetries previous telemetries received from initial fetched agents which is overwritten each time
 * the new telemetry is received via SignalR.
 * @param allDuressEvents
 * @param allAssistEvents
 * @param staffDuressAndAssistFeatureFlagEnabled
 * @returns transformed telemetry
 */
export function transformTelemetriesToIncludeStaffEvents(
  telemetries: BaseBadgeTelemetryMessage[],
  previousAgentTelemetries: TelemetryByAgentType,
  allDuressEvents: StaffEvent[],
  allAssistEvents: StaffEvent[],
  staffDuressAndAssistFeatureFlagEnabled: boolean
): BadgeTelemetryMessageWithAgentEvent[] {
  const transformedTelemetries: BadgeTelemetryMessageWithAgentEvent[] = []
  telemetries.forEach((telemetry) => {
    if (
      !telemetry.agent ||
      (telemetry.agent && !telemetry.agent.asset && !telemetry.agent.staff)
    ) {
      return
    }
    const { hasActiveDuress, hasActiveAssist, hasResolvedDuressOrAssist } =
      buildConfidenceBubbleAgentEventOptionsForLiveTelemetry(
        telemetry,
        allDuressEvents,
        allAssistEvents,
        staffDuressAndAssistFeatureFlagEnabled
      )
    let asset: TelemetryAsset | undefined
    let staff: TelemetryStaff | undefined
    if (telemetry.agent.asset) {
      const {
        badgeIds,
        badgeTelemetry,
        roomLocation,
        floorLocation,
        buildingLocation,
        buildingGroupLocation,
        ...rest
      } = telemetry.agent.asset
      asset = rest
    } else if (telemetry.agent.staff) {
      const {
        badgeIds,
        badgeTelemetry,
        roomLocation,
        floorLocation,
        buildingLocation,
        buildingGroupLocation,
        ...rest
      } = telemetry.agent.staff
      staff = rest
    }
    const agent = asset ?? staff
    if (agent) {
      const transformedTelemetry: BadgeTelemetryMessageWithAgentEvent = {
        ...telemetry,
        hasActiveDuress,
        hasActiveAssist,
        hasResolvedDuressOrAssist,
        agent: {
          ...agent,
          agentType: telemetry.agent.agentTypeId,
          agentGuid: telemetry.agent.id,
          displayName: telemetry.agent.displayName,
        },
      }
      replaceAgentInNewTelemetryWithFetchedAgentInState(
        transformedTelemetry,
        previousAgentTelemetries
      )
      transformedTelemetries.push(transformedTelemetry)
    }
  })
  return transformedTelemetries
}

/**
 * Sort telemetries based on previously received telemetries to separate telemetries into 3 groups:
 * 1. New telemetries for agents already rendered on the map
 * 2. Telemetries for new agents entering the map (telemetries that are not included in previous agent telemetries)
 * 3. Telemetries for agents leaving the map (telemetries with mapId that does not match the current mapId)
 * @param badgeTelemetries
 * @param previousAgentTelemetries previous telemetries received from initial fetched agents which is overwritten each time
 * the new telemetry is received via SignalR.
 * @param mapId
 * @returns telemetries grouped by new telemetries for agents already rendered on map, telemetries for new agents entering map, and
 * telemetries for agents leaving map
 */
export function filterNewAgentsAndMapLeavers(
  badgeTelemetries: BadgeTelemetryMessageWithAgentEvent[],
  previousAgentTelemetries: TelemetryByAgentType,
  mapId: number
): {
  newAgentsByAgentType: TelemetryByAgentType
  newTelemetriesForExistingAgents: AgentTelemetriesGroupedByTrackingId<
    TelemetryAsset | TelemetryStaff
  >
  agentsLeavingMap: TelemetryByAgentType
} {
  const newAgentsByAgentType: TelemetryByAgentType = {
    assetAgentTelemetries: {},
    staffAgentTelemetries: {},
  }
  let newTelemetriesForExistingAgents: AgentTelemetriesGroupedByTrackingId = {}
  const agentsLeavingMap: TelemetryByAgentType = {
    assetAgentTelemetries: {},
    staffAgentTelemetries: {},
  }

  const assetTelemetries = badgeTelemetries.filter(
    (telemetry) =>
      telemetry.agent.agentType === AgentType.Asset && telemetry.mapId === mapId
  )
  const staffTelemetries = badgeTelemetries.filter(
    (telemetry) =>
      telemetry.agent.agentType === AgentType.Staff && telemetry.mapId === mapId
  )
  const newAssetsEnteringFloor = findItemsInArrayAButNotInArrayB(
    assetTelemetries as BadgeTelemetryMessageWithAgentEvent<TelemetryAsset>[],
    Object.values(previousAgentTelemetries.assetAgentTelemetries),
    'trackingId'
  )
  const newStaffsEnteringFloor = findItemsInArrayAButNotInArrayB(
    staffTelemetries as BadgeTelemetryMessageWithAgentEvent<TelemetryStaff>[],
    Object.values(previousAgentTelemetries.staffAgentTelemetries),
    'trackingId'
  )
  newAgentsByAgentType.assetAgentTelemetries = mergeRecords<
    AgentTelemetriesGroupedByTrackingId<TelemetryAsset>,
    BadgeTelemetryMessageWithAgentEvent<TelemetryAsset>,
    'trackingId'
  >(
    newAgentsByAgentType.assetAgentTelemetries,
    newAssetsEnteringFloor,
    'trackingId'
  )
  newAgentsByAgentType.staffAgentTelemetries = mergeRecords<
    AgentTelemetriesGroupedByTrackingId<TelemetryStaff>,
    BadgeTelemetryMessageWithAgentEvent<TelemetryStaff>,
    'trackingId'
  >(
    newAgentsByAgentType.staffAgentTelemetries,
    newStaffsEnteringFloor,
    'trackingId'
  )

  const allAgentsLeavingMap = badgeTelemetries.filter(
    (telemetry) => telemetry.mapId !== mapId
  )
  const assetsLeavingMap = allAgentsLeavingMap.filter(
    (telemetry) =>
      telemetry.agent.agentType === AgentType.Asset &&
      Object.keys(previousAgentTelemetries.assetAgentTelemetries).includes(
        telemetry.trackingId
      )
  )
  const staffsLeavingMap = allAgentsLeavingMap.filter(
    (telemetry) =>
      telemetry.agent.agentType === AgentType.Staff &&
      Object.keys(previousAgentTelemetries.staffAgentTelemetries).includes(
        telemetry.trackingId
      )
  )
  agentsLeavingMap.assetAgentTelemetries = mergeRecords<
    AgentTelemetriesGroupedByTrackingId<TelemetryAsset>,
    BadgeTelemetryMessageWithAgentEvent<TelemetryAsset>,
    'trackingId'
  >(
    agentsLeavingMap.assetAgentTelemetries,
    assetsLeavingMap as BadgeTelemetryMessageWithAgentEvent<TelemetryAsset>[],
    'trackingId'
  )
  agentsLeavingMap.staffAgentTelemetries = mergeRecords<
    AgentTelemetriesGroupedByTrackingId<TelemetryStaff>,
    BadgeTelemetryMessageWithAgentEvent<TelemetryStaff>,
    'trackingId'
  >(
    agentsLeavingMap.staffAgentTelemetries,
    staffsLeavingMap as BadgeTelemetryMessageWithAgentEvent<TelemetryStaff>[],
    'trackingId'
  )

  const telemetriesForAgentsAlreadyOnMap = badgeTelemetries.filter(
    (telemetry) => {
      return (
        !newAgentsByAgentType.assetAgentTelemetries[telemetry.trackingId] &&
        !newAgentsByAgentType.staffAgentTelemetries[telemetry.trackingId] &&
        !allAgentsLeavingMap
          .map((telemetry) => telemetry.trackingId)
          .includes(telemetry.trackingId)
      )
    }
  )
  newTelemetriesForExistingAgents = mergeRecords<
    AgentTelemetriesGroupedByTrackingId,
    BadgeTelemetryMessageWithAgentEvent,
    'trackingId'
  >(
    newTelemetriesForExistingAgents,
    telemetriesForAgentsAlreadyOnMap,
    'trackingId'
  )

  return {
    newAgentsByAgentType,
    newTelemetriesForExistingAgents,
    agentsLeavingMap,
  }
}

/**
 * Filter telemetries by the AgentTypePlural enum.
 * The AgentType enum stored in the agentType prop is Asset or Staff
 * while the AgentTypePlural enum for the agent type dropdown values
 * are Assets or Staff. Therefore, we need to map one enum to the other before
 * filtering.
 * @param agentTelemetries
 * @param agentTypesPlural
 * @returns filtered telemetries by current agent types which can be Assets or
 * Staff or Assets and Staff
 */
export function filterAgentTelemetriesByAgentTypesPlural(
  agentTelemetries: AgentTelemetriesGroupedByTrackingId,
  agentTypesPlural: string[]
): AgentTelemetriesGroupedByTrackingId {
  let filteredAgentTelemetriesByTrackingId: AgentTelemetriesGroupedByTrackingId =
    {}
  const filteredAgentTelemetries =
    filterBadgeTelemetryMessagesByAgentTypesPlural(
      Object.values(agentTelemetries),
      agentTypesPlural
    )
  filteredAgentTelemetriesByTrackingId = mergeRecords<
    AgentTelemetriesGroupedByTrackingId,
    BadgeTelemetryMessageWithAgentEvent,
    'trackingId'
  >(
    filteredAgentTelemetriesByTrackingId,
    filteredAgentTelemetries,
    'trackingId'
  )
  return filteredAgentTelemetriesByTrackingId
}

export function filterBadgeTelemetryMessagesByAgentTypesPlural(
  telemetries: BadgeTelemetryMessageWithAgentEvent[],
  agentTypesPlural: string[]
): BadgeTelemetryMessageWithAgentEvent[] {
  return telemetries.filter((agentTelemetry) => {
    const agentTypePlural: string =
      agentTelemetry.agent.agentType === AgentType.Asset
        ? AgentTypePlural[AgentTypePlural.Assets]
        : agentTelemetry.agent.agentType === AgentType.Staff
        ? AgentTypePlural[AgentTypePlural.Staff]
        : ''
    return agentTypesPlural.includes(agentTypePlural)
  })
}

export function transformFetchedNewAgentsEnteringMap<
  T = TelemetryAsset | TelemetryStaff
>(
  fetchedAgents: NewAgentsEnteringMapTelemetry<T>[]
): AgentTelemetriesGroupedByTrackingId<T> {
  let agentTelemetries: AgentTelemetriesGroupedByTrackingId<T> = {}
  if (fetchedAgents.length > 0) {
    const transformedTelemetries: BadgeTelemetryMessageWithAgentEvent<T>[] =
      fetchedAgents.map((agentTelemetry) => ({
        ...agentTelemetry.telemetry,
        agent: agentTelemetry.agent,
      }))
    agentTelemetries = mergeRecords<
      AgentTelemetriesGroupedByTrackingId<T>,
      BadgeTelemetryMessageWithAgentEvent<T>,
      'trackingId'
    >(agentTelemetries, transformedTelemetries, 'trackingId')
  }
  return agentTelemetries
}
