import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { AssetSubTypeAction } from '../actions'
import { getAllAssetSubTypesAction } from '../actions/assetSubTypes'
import { AssetSubTypesApi } from '../middleware'
import { AssetSubTypesState } from '../models'

type AssetSubTypeEpic = Epic<
  AssetSubTypeAction,
  AssetSubTypeAction,
  AssetSubTypesState,
  { assetSubTypesApi: AssetSubTypesApi }
>

export const getAllAssetSubTypesEpic: AssetSubTypeEpic = (
  action$,
  state,
  { assetSubTypesApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllAssetSubTypesAction.request)),
    mergeMap(() =>
      from(assetSubTypesApi.getAllAssetSubTypes()).pipe(
        map((assetSubTypes) =>
          getAllAssetSubTypesAction.success(assetSubTypes)
        ),
        catchError((error) => of(getAllAssetSubTypesAction.failure(error)))
      )
    )
  )
}
