import { getType } from 'typesafe-actions'
import { AlertTypeAction } from '../actions'
import { getAllAlertTypesAction } from '../actions/alertTypes'
import {
  AlertType,
  AlertTypes,
  AlertTypesState,
  FetchingStatus,
} from '../models'
import { mergeRecords, setFetchingStatus } from '../utils'

const initialState: AlertTypesState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

export default (
  state: AlertTypesState = initialState,
  action: AlertTypeAction
): AlertTypesState => {
  switch (action.type) {
    case getType(getAllAlertTypesAction.success):
      return {
        data: mergeRecords<AlertTypes, AlertType, 'id'>(
          state.data,
          action.payload,
          'id'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getAllAlertTypesAction.request):
      return setFetchingStatus<AlertTypes>(state, FetchingStatus.Request)
    case getType(getAllAlertTypesAction.failure):
      return setFetchingStatus<AlertTypes>(state, FetchingStatus.Failure)
    default:
      return state
  }
}
