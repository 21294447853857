import { getType } from 'typesafe-actions'
import { CurrentUserTelemetrySubscriptionAction } from '../actions'
import {
  setCurrentUserTelemetryEnabledAction,
  setCurrentUserTelemetryMapIdAction,
} from '../actions/currentUserTelemetrySubscription'
import {
  CurrentUserTelemetrySubscription,
  CurrentUserTelemetrySubscriptionState,
} from '../models'

const initialState: CurrentUserTelemetrySubscription = {
  enabled: false,
  mapId: undefined,
  previousMapId: undefined,
}

export default (
  state: CurrentUserTelemetrySubscription = initialState,
  action: CurrentUserTelemetrySubscriptionAction
): CurrentUserTelemetrySubscriptionState => {
  switch (action.type) {
    case getType(setCurrentUserTelemetryMapIdAction):
      return {
        ...state,
        mapId: action.payload,
        previousMapId:
          state.mapId === action.payload ? state.previousMapId : state.mapId,
      }
    case getType(setCurrentUserTelemetryEnabledAction):
      return {
        ...state,
        enabled: action.payload,
      }
    default:
      return state
  }
}
