import { nullGuid } from '../helpers'
import { FetchingState } from '../interfaces/fetchingState'
import { AlertChannel } from './alertChannel'
import { AlertQualifier, LocationQualifier } from './alertQualifier'
import { AlertResolution } from './alertResolution'
import {
  AlertLocationRouter,
  AlertSecurityRouter,
  AlertUserRouter,
} from './alertRouter'
import { AlertTrigger } from './alertTrigger'
import { AlertType } from './alertTypes'
import { AssetType } from './assetTypes'
import { Unit } from './units'

export enum AlertStatus {
  Enabled,
  Disabled,
  Failed,
}

export enum AlertTypes {
  StaffDuress,
  StaffAssist,
  AssetAlert,
}

export interface AlertActiveConfig {
  id: string
  name: string
  alertType: AlertType | undefined
  description: string
  alertStatus: AlertStatus
  trigger: AlertTrigger | undefined
  locationChangeQualifier: string | undefined
  locationQualifier: LocationQualifier
  unitQualifier: Unit | undefined
  badgeQualifier: string
  channels: AlertChannel[]
  locationRouter: LocationQualifier
  unitRouter?: Unit | undefined
  securityRouters: AlertSecurityRouter[]
  userRouters: AlertUserRouter[]
  alertResolution: AlertResolution
  assetDetail: string | undefined
  assetType: AssetType | undefined
}

export interface Alert extends AlertActiveConfig {
  alertTypeName: string
  alertTypeId: number
  lastModified?: Date
  lastRun?: Date
  alertStatus: AlertStatus
  triggers: AlertTrigger[]
  qualifiers: AlertQualifier[]
  locationRouters: AlertLocationRouter[]
}

export const defaultAlertActiveConfig: AlertActiveConfig = {
  id: nullGuid,
  name: '',
  description: '',
  alertType: undefined,
  trigger: undefined,
  alertStatus: AlertStatus.Disabled,
  locationQualifier: {
    campus: undefined,
    building: undefined,
    floor: undefined,
  },
  unitQualifier: undefined,
  badgeQualifier: 'All Badges',
  channels: [],
  locationRouter: {
    campus: undefined,
    building: undefined,
    floor: undefined,
  },
  unitRouter: undefined,
  securityRouters: [],
  userRouters: [],
  alertResolution: {
    id: nullGuid,
    automaticResolveTimeoutInMinutes: 60,
    resolverRoles: [],
    resolverUsers: [],
    resolverStaffTypes: [],
  },
  locationChangeQualifier: undefined,
  assetDetail: undefined,
  assetType: undefined,
}

export interface Alerts {
  [id: string]: Alert
}

export type AlertsState = Readonly<FetchingState<Alerts>>

export interface AlertCreateOrUpdate {
  id: string
  name: string
  description: string
  alertTypeId: number
  alertStatus: AlertStatus
  triggers: AlertTrigger[]
  qualifiers: AlertQualifier[]
  channels: AlertChannel[]
  locationRouters: AlertLocationRouter[]
  securityRouters: AlertSecurityRouter[]
  userRouters: AlertUserRouter[]
  alertResolutionId: string
  alertResolution: AlertResolution
  locationChangeQualifier: string | undefined
  assetDetail: string | undefined
  assetType: AssetType | undefined
}

export interface AlertConfigRouteParams {
  id: string
  step: string
}
