import { useCallback, useEffect, useReducer } from 'react'
import { Location } from '../../../models'
import { DrawerType } from '../../../components/Layout/PageContent'

export interface RightDrawerState {
  currentDrawerType: DrawerType
  selectedRoom: Location | undefined
}

enum Actions {
  CloseDetailDrawer,
  ResetDrawer,
  SelectRoom,
  UpdateSelectedRoom,
}

type SelectRoomAction = {
  type: Actions.SelectRoom
  payload: Location
}

type ActionsWithNoPayload = {
  type: Actions.CloseDetailDrawer | Actions.ResetDrawer
}

type UpdateSelectedRoomAction = {
  type: Actions.UpdateSelectedRoom
  payload: Location
}

type RightDrawerActions =
  | ActionsWithNoPayload
  | SelectRoomAction
  | UpdateSelectedRoomAction

function rightDrawerReducer(
  state: RightDrawerState,
  action: RightDrawerActions
): RightDrawerState {
  switch (action.type) {
    case Actions.SelectRoom:
      if (state.currentDrawerType === DrawerType.DetailDrawer) {
        return {
          ...state,
          selectedRoom: action.payload,
        }
      } else {
        return {
          ...state,
          currentDrawerType: DrawerType.DetailDrawer,
          selectedRoom: action.payload,
        }
      }
    case Actions.CloseDetailDrawer:
      if (state.currentDrawerType === DrawerType.DetailDrawer) {
        return {
          ...state,
          currentDrawerType: DrawerType.None,
          selectedRoom: undefined,
        }
      }
      return state
    case Actions.ResetDrawer:
      return {
        currentDrawerType: DrawerType.None,
        selectedRoom: undefined,
      }
    case Actions.UpdateSelectedRoom:
      return {
        ...state,
        selectedRoom: action.payload,
      }
    default:
      return state
  }
}

export const useLocationManagementMapRightDrawer = (): {
  rightDrawerState: RightDrawerState
  selectRoom: (room: Location) => void
  closeDetailDrawer: () => void
  updateSelectedRoom: (room: Location) => void
} => {
  const initialState: RightDrawerState = {
    currentDrawerType: DrawerType.None,
    selectedRoom: undefined,
  }
  const [rightDrawerState, dispatch] = useReducer(
    rightDrawerReducer,
    initialState
  )

  const selectRoom = useCallback((room: Location) => {
    dispatch({ type: Actions.SelectRoom, payload: room })
  }, [])

  const closeDetailDrawer = useCallback(() => {
    dispatch({ type: Actions.CloseDetailDrawer })
  }, [])

  const updateSelectedRoom = useCallback((item: Location) => {
    dispatch({ type: Actions.UpdateSelectedRoom, payload: item })
  }, [])

  // Clean up on navigating away
  useEffect(() => {
    return () => {
      dispatch({ type: Actions.ResetDrawer })
    }
  }, [])

  return {
    rightDrawerState,
    selectRoom,
    closeDetailDrawer,
    updateSelectedRoom,
  }
}
