import { StaffFilterRequest } from '../../models'
import { StaffFilters } from '../../models/filters'

export const staffFilterMap = (
  filterRequest?: StaffFilters
): StaffFilterRequest => {
  const filter: StaffFilterRequest = {
    staffTypes: filterRequest?.staffTypes?.map((x) => x.staffTypeId) ?? [],
    staffAssignedTo:
      filterRequest?.assignedTo?.map((x) => x.id?.toString() ?? '') ?? [],
    reportsTo: filterRequest?.reportsTo?.map((x) => x.key ?? '') ?? [],
  }

  return filter
}
