import { BaseService } from '@midmarkrtls/common/middleware/baseService'
import { Role } from '@midmarkrtls/common/models'

export class OperationsRolesApi extends BaseService {
  constructor() {
    super({ entityType: 'operationsRoles' })
  }
  route = 'operationsRoles'

  async getAllOperationsRoles(): Promise<Role[]> {
    return await this.get<Role[]>(this.route)
  }
  async getOperationsRolesByUserId(userId: string): Promise<Role[]> {
    return await this.get<Role[]>(`${this.route}/${userId}`)
  }
  async getOperationsRoleById(id: number): Promise<Role> {
    return await this.get<Role>(`${this.route}/${id}`)
  }
  async postOperationsRole(role: Role): Promise<Role> {
    return await this.post<Role>(this.route, role)
  }
  async putOperationsRole(role: Role): Promise<Role> {
    return await this.put<Role>(this.route, role)
  }
  async deleteOperationsRole(id: number): Promise<boolean> {
    return await this.deleteNoResult(`${this.route}/${id}`)
  }
}

export const operationsRolesApi = new OperationsRolesApi()
