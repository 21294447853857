import { LocationMetric } from '../models'
import { BaseService } from './baseService'

export class LocationMetricsApi extends BaseService {
  constructor() {
    super({ entityType: 'locationMetrics' })
  }
  locationRoute = 'locations'
  metricsRoute = 'agents/metrics/realtime'

  async getLocationMetrics(locationId: string): Promise<LocationMetric> {
    return await this.get<LocationMetric>(
      `${this.locationRoute}/${locationId}/${this.metricsRoute}`
    )
  }
}
