import { getType } from 'typesafe-actions'
import { MapAction } from '../actions'
import { getAllMapsAction } from '../actions/maps'
import { Map, Maps, MapsState } from '../models'
import { FetchingStatus } from '../models/fetchingStatus'
import { mergeRecords, setFetchingStatus } from '../utils'

const initialState: MapsState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

export default (
  state: MapsState = initialState,
  action: MapAction
): MapsState => {
  switch (action.type) {
    case getType(getAllMapsAction.success):
      return {
        data: mergeRecords<Maps, Map, 'id'>(state.data, action.payload, 'id'),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getAllMapsAction.request):
      return setFetchingStatus<Maps>(state, FetchingStatus.Request)
    case getType(getAllMapsAction.failure):
      return setFetchingStatus<Maps>(state, FetchingStatus.Failure)
    default:
      return state
  }
}
