import { LocationConstants } from '../../constants'
import { Location } from '../../models'

export function getRoomDisplayNameForStaff(
  roomLocation: Location,
  isPrivate: boolean,
  hasActiveDuressOrAssist: boolean | undefined
): string {
  if (hasActiveDuressOrAssist) {
    return roomLocation.name
  }
  if (isPrivate) {
    return LocationConstants.PrivateDescription
  }
  return roomLocation.name
}
