import { createAsyncAction } from 'typesafe-actions'
import { CloudReportingPartner } from '../models'
import { HttpErrorResponse } from '../services/http'
import { CloudReportingViews } from '../models/cloudReportingViews'

export const getCloudReportingEmbedUrlAction = createAsyncAction(
  'GET_CLOUD_REPORTING_EMBED_URL_REQUEST',
  'GET_CLOUD_REPORTING_EMBED_URL_SUCCESS',
  'GET_CLOUD_REPORTING_EMBED_URL_FAILURE'
)<string, string, HttpErrorResponse>()

export const getCloudReportingPartnerAction = createAsyncAction(
  'GET_CLOUD_REPORTING_PARTNER_REQUEST',
  'GET_CLOUD_REPORTING_PARTNER_SUCCESS',
  'GET_CLOUD_REPORTING_PARTNER_FAILURE'
)<string, CloudReportingPartner, HttpErrorResponse>()

export const getCloudReportingViewsAction = createAsyncAction(
  'GET_CLOUD_REPORTING_VIEWS_REQUEST',
  'GET_CLOUD_REPORTING_VIEWS_SUCCESS',
  'GET_CLOUD_REPORTING_VIEWS_FAILURE'
)<void, CloudReportingViews, HttpErrorResponse>()
