import { getType } from 'typesafe-actions'
import { SensorAction } from '../actions'
import { Sensors, Sensor, SensorsState, FetchingStatus } from '../models'
import { mergeRecords } from '../utils'
import {
  getAllSensorsAction,
  getAllSensorsByLocationIdAction,
  getAllSensorsBySensorIdAction,
} from '../actions/sensors'

const initialState: SensorsState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

export default (state = initialState, action: SensorAction): SensorsState => {
  switch (action.type) {
    case getType(getAllSensorsBySensorIdAction.request):
    case getType(getAllSensorsAction.request):
    case getType(getAllSensorsByLocationIdAction.request):
      return {
        ...state,
        status: FetchingStatus.Request,
      }

    case getType(getAllSensorsBySensorIdAction.failure):
    case getType(getAllSensorsAction.failure):
    case getType(getAllSensorsByLocationIdAction.failure):
      return {
        ...state,
        status: FetchingStatus.Failure,
      }

    case getType(getAllSensorsBySensorIdAction.success):
    case getType(getAllSensorsAction.success):
    case getType(getAllSensorsByLocationIdAction.success):
      return {
        data: mergeRecords<Sensors, Sensor, 'sensorId'>(
          state.data,
          action.payload,
          'sensorId'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }

    default:
      return state
  }
}
