import { BaseService } from './baseService'

export class OwnersApi extends BaseService {
  constructor() {
    super({ entityType: 'owners' })
  }
  route = 'owners'

  async getAllOwners(): Promise<string[]> {
    return await this.get<string[]>(this.route)
  }
}
