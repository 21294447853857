import jmap from 'jmap.js'
import { onZoomComplete } from '.'

export function focusView(
  control: any, // eslint-disable-line @typescript-eslint/no-explicit-any
  x: number,
  y: number,
  duration: number,
  scale?: number,
  rotation?: number
): void {
  if (control) {
    // use current control values if values are not supplied
    scale = scale ?? control.stage.currentMapView.scale
    rotation = rotation ?? control.stage.currentMapView.rotation

    control.setMapTransform(
      new jmap.Transform({
        x,
        y,
        scale,
        rotation,
        animation: new jmap.Animation({
          duration,
          onComplete: () => onZoomComplete(control),
        }),
      })
    )
  }
}
