import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTelemetrySubscription } from '.'
import { trackSilentUpdateRequest } from '../actions/agentSilentUpdate'
import { silentUpdateAssetAction } from '../actions/assets'
import { setMapUpdateAction } from '../actions/mapUpdate'
import { silentUpdateStaffAction } from '../actions/staff'
import getGeofenceMapping, {
  clearBadgeState,
  getMapState,
} from '../helpers/inpixon/badgeState'
import calculateValidTelemetryNotInState from '../helpers/telemetryNotInState'
import {
  AgentSilentUpdatesState,
  AttemptedSilentUpdates,
  AgentType,
  AgentTypePlural,
  Asset,
  BaseBadgeTelemetryMessage,
  FetchingStatus,
  GeoFence,
  GeoFenceUpdate,
  Location,
  MapUpdate,
  MapUpdateType,
  SignalType,
  SilentRefreshStatus,
  Staff,
  StaffEvents,
} from '../models'
import { useCombinedTelemetry } from './useCombinedTelemetry'

function processMapUpdates(
  data: GeoFenceUpdate,
  mapId: number,
  isRefresh: boolean
): MapUpdate[] {
  const mapUpdates: MapUpdate[] = []
  const { telemetry, geoFences } = data
  if (Object.values(telemetry).length) {
    Object.values(telemetry)?.map((message) => {
      mapUpdates.push({
        trackingId: message.trackingId,
        signalTypeId: message.signalTypeId,
        lon: message.lon,
        lat: message.lat,
        locationUncertainty: message.locationUncertainty,
        mapId: message.mapId,
        previousMapId: message.prevMapId,
        geoFenceId: message.geoFenceId,
        timestamp: message.timestamp,
        updateType: MapUpdateType.Agent,
        agents: [],
        isRefresh: isRefresh,
      })
    })
  }
  if (geoFences) {
    geoFences.map((geoFence: GeoFence) => {
      mapUpdates.push({
        geoFenceId: geoFence.geoFenceId,
        agents: geoFence.assets,
        mapId,
        updateType: MapUpdateType.GeoFence,
        isRefresh: isRefresh,
      })
    })
  }

  return mapUpdates
}

export function getMapTelemetryState(mapId: number): MapUpdate[] | null {
  const state = getMapState()
  return processMapUpdates(state, mapId, true)
}

function getAssetSignalType(asset: Asset): number {
  if (asset.badgeTelemetry.sensorId) {
    return SignalType.IR
  } else if (asset.badgeIds.includes(':')) {
    return SignalType.BLE
  } else if (asset.badgeTelemetry.geoFenceId) {
    return SignalType.BLE
  } else {
    return SignalType.IR
  }
}

function getStaffSignalType(staff: Staff): number {
  if (staff.badgeTelemetry.sensorId) {
    return SignalType.IR
  } else if (staff.badgeIds.includes(':')) {
    return SignalType.BLE
  } else if (staff.badgeTelemetry.geoFenceId) {
    return SignalType.BLE
  } else {
    return SignalType.IR
  }
}

function processAssetState(
  assetState: Asset[],
  mapId?: number
): BaseBadgeTelemetryMessage[] | null {
  const badgeTelemetry: BaseBadgeTelemetryMessage[] = []

  if (mapId && assetState) {
    const data = assetState.filter((x) => x.agentGuid)

    data.map((state: Asset) => {
      if (
        state?.badgeTelemetry?.lon &&
        state?.badgeTelemetry?.lat &&
        state?.badgeTelemetry?.geoFenceId
      ) {
        badgeTelemetry.push({
          customerId: state.badgeTelemetry.customerId ?? '',
          trackingId: state.badgeIds[0],
          signalTypeId: getAssetSignalType(state),
          lon: state.badgeTelemetry.lon,
          lat: state.badgeTelemetry.lat,
          locationUncertainty: state.badgeTelemetry.locationUncertainty ?? 1,
          sensorId: '',
          mapId: state.badgeTelemetry.mapId,
          geoFenceId: state.badgeTelemetry.geoFenceId,
          timestamp: state.badgeTelemetry.timestamp
            ? new Date(state.badgeTelemetry.timestamp)
            : new Date(),
        })
      }
    })
  }

  return badgeTelemetry
}

function processStaffState(
  staffState: Staff[],
  mapId?: number
): BaseBadgeTelemetryMessage[] | null {
  const badgeTelemetry: BaseBadgeTelemetryMessage[] = []

  if (mapId && staffState) {
    const data = staffState.filter((x) => x.agentGuid)

    data.map((state: Staff) => {
      if (
        state?.badgeTelemetry?.lon &&
        state?.badgeTelemetry?.lat &&
        state?.badgeTelemetry?.geoFenceId
      ) {
        badgeTelemetry.push({
          customerId: state.badgeTelemetry.customerId ?? '',
          trackingId: state.badgeIds[0],
          signalTypeId: getStaffSignalType(state),
          lon: state.badgeTelemetry.lon,
          lat: state.badgeTelemetry.lat,
          locationUncertainty: state.badgeTelemetry.locationUncertainty ?? 1,
          sensorId: '',
          mapId: state.badgeTelemetry.mapId,
          geoFenceId: state.badgeTelemetry.geoFenceId,
          timestamp: state.badgeTelemetry.timestamp
            ? new Date(state.badgeTelemetry.timestamp)
            : new Date(),
        })
      }
    })
  }

  return badgeTelemetry
}

export function useMapTelemetry(
  assetState: Asset[],
  staffState: Staff[],
  agentTypes: string[],
  assetStatus: FetchingStatus | undefined,
  staffStatus: FetchingStatus | undefined,
  allDuressEvents: StaffEvents,
  allAssistEvents: StaffEvents
): void {
  const [stateLoaded, setStateLoaded] = useState(false)
  const [agentTypeState, setAgentTypeState] = useState<string[]>([])
  const [silentUpdateAssetDispatched, setSilentUpdateAssetDispatched] =
    useState<boolean>(false)
  const [silentUpdateStaffDispatched, setSilentUpdateStaffDispatched] =
    useState<boolean>(false)
  const { enabled: enabled, mapId } = useTelemetrySubscription()
  const [telemetryBuffer, setTelemetryBuffer] = useState<
    BaseBadgeTelemetryMessage[]
  >([])
  const { badgeTelemetry: baseTelemetry } = useCombinedTelemetry()

  const currentLocation = useSelector(
    ({ currentLocation }: { currentLocation: Location }) => currentLocation
  )

  const silentRefreshStatus = useSelector(
    ({ agentSilentUpdate }: { agentSilentUpdate: AgentSilentUpdatesState }) =>
      agentSilentUpdate.silentRefreshStatus
  )

  const attemptedSilentUpdates: AttemptedSilentUpdates | undefined =
    useSelector(
      ({ agentSilentUpdate }: { agentSilentUpdate: AgentSilentUpdatesState }) =>
        agentSilentUpdate.attemptedSilentUpdates
    )

  const dispatch = useDispatch()

  const mapUpdates = useMemo(() => {
    if (
      enabled &&
      mapId &&
      baseTelemetry //&&
      // (!stateLoaded ||
      //   JSON.stringify(baseTelemetry) !== JSON.stringify(telemetryBuffer))
    ) {
      setTelemetryBuffer(baseTelemetry)
      let newAgents = false
      const combinedAgentTelemetry: BaseBadgeTelemetryMessage[] = []
      const staffTelemetry = Object.values(baseTelemetry).filter(
        (t) => t.agent?.agentType === AgentType[AgentType.Staff]
      )
      const assetTelemetry = Object.values(baseTelemetry).filter(
        (t) => t.agent?.agentType === AgentType[AgentType.Asset]
      )

      if (
        assetState.length > 0 &&
        stateLoaded &&
        assetStatus !== undefined &&
        assetStatus !== FetchingStatus.Request
      ) {
        const newAssets = calculateValidTelemetryNotInState(
          assetTelemetry,
          assetState,
          attemptedSilentUpdates,
          AgentType.Asset
        )

        if (newAssets.length > 0) {
          newAgents = true
          dispatch(
            trackSilentUpdateRequest(newAssets.map((x) => x.agent?.id ?? ''))
          )
          dispatch(
            silentUpdateAssetAction.request({
              locationGuid: currentLocation.id,
              agentGuids: newAssets.map((x) => x.agent?.id ?? ''),
              badgeTelemetry: newAssets,
            })
          )
        }
      }

      if (
        staffState.length > 0 &&
        stateLoaded &&
        silentRefreshStatus !== SilentRefreshStatus.Request &&
        staffStatus !== undefined &&
        staffStatus !== FetchingStatus.Request
      ) {
        const newStaffs = calculateValidTelemetryNotInState(
          staffTelemetry,
          staffState,
          attemptedSilentUpdates,
          AgentType.Staff
        )

        if (newStaffs.length > 0) {
          newAgents = true
          dispatch(
            trackSilentUpdateRequest(newStaffs.map((x) => x.agent?.id ?? ''))
          )
          dispatch(
            silentUpdateStaffAction.request({
              locationGuid: currentLocation.id,
              agentGuids: newStaffs.map((x) => x.agent?.id ?? ''),
              badgeTelemetry: newStaffs,
            })
          )
        }
      }

      const isRefresh =
        JSON.stringify(agentTypes) !== JSON.stringify(agentTypeState)
          ? true
          : false

      //CAUTION: Items added to this if can cause excessive re-renders of the map and SensoryNetworkMap
      if (!stateLoaded || newAgents || isRefresh) {
        clearBadgeState()
        if (
          agentTypes?.includes(AgentTypePlural[AgentTypePlural.Assets]) &&
          assetState.length
        ) {
          const assetTelemetry = processAssetState(assetState, mapId)
          assetTelemetry?.map((x) => combinedAgentTelemetry.push(x))
        }
        if (
          agentTypes?.includes(AgentTypePlural[AgentTypePlural.Staff]) &&
          staffState.length
        ) {
          const staffTelemetry = processStaffState(staffState, mapId)
          staffTelemetry?.map((x) => combinedAgentTelemetry.push(x))
        }
        setStateLoaded(true)
        setAgentTypeState(agentTypes)

        // Use cancelArg as SILENT_UPDATE_STAFF_COMPLETE action type to take advantages of the createAsyncAction method
        if (silentUpdateStaffDispatched) {
          dispatch(silentUpdateStaffAction.cancel(null, null))
          setSilentUpdateStaffDispatched(false)
        }
        if (silentUpdateAssetDispatched) {
          dispatch(silentUpdateAssetAction.cancel(null, null))
          setSilentUpdateAssetDispatched(false)
        }
      }

      if (baseTelemetry?.length) {
        baseTelemetry?.map((x) => combinedAgentTelemetry.push(x))
      }
      if (combinedAgentTelemetry.length) {
        const data = getGeofenceMapping(
          combinedAgentTelemetry,
          AgentType.Staff,
          allDuressEvents,
          allAssistEvents,
          enabled
        )
        return processMapUpdates(data, mapId, isRefresh)
      }
    }

    return []
  }, [
    enabled,
    mapId,
    stateLoaded,
    agentTypes,
    agentTypeState,
    baseTelemetry,
    assetState,
    staffState,
    currentLocation,
    dispatch,
    allDuressEvents,
    allAssistEvents,
  ])

  useEffect(() => {
    if (mapUpdates?.length) {
      dispatch(setMapUpdateAction(mapUpdates))
    }
  }, [dispatch, mapUpdates])
}
