import { CellContext, ColumnDef } from '@tanstack/react-table'
import React, { useCallback, useMemo } from 'react'
import {
  camelCaseToArray,
  capitalizeFirstChar,
  extractPermissionGroups,
  transformPermissionGroups,
} from '../../helpers'
import { useGlobalSearch } from '../../hooks/filters'
import { Permissions, PermissionsEnum, Role, SortedRole } from '../../models'
import { FetchingStatus } from '../../models/fetchingStatus'
import { ReactTable } from '../Tables'

interface Props {
  roles: Role[] | SortedRole[]
  permissions: Permissions
  rolesStatus: FetchingStatus | undefined
}

interface EntityAccess {
  read: boolean
  limitedWrite: boolean
  write: boolean
  technical: boolean
  canViewAllCustomers: boolean
}

interface PermissionGroup {
  access: EntityAccess
  name: string
}
type RolePermissionsRow = Record<string, PermissionGroup> & {
  role: string
}

const canViewAllCustomersProp = 'canViewAllCustomers'

const RolesManagementList: React.FC<Props> = (props: Props) => {
  const { roles, permissions, rolesStatus } = props

  const permissionGroups = extractPermissionGroups(permissions).filter(
    (x) => x !== canViewAllCustomersProp
  )
  const transformedPermissionGroups = transformPermissionGroups(permissions)
  const getAllowedActions = (group: EntityAccess): string[] =>
    [
      group.read && 'Read',
      group.limitedWrite && !group.write && 'Limited Write',
      group.write && 'Write',
      group.technical && 'Technical',
      group.canViewAllCustomers && capitalizeFirstChar(canViewAllCustomersProp),
    ].filter(Boolean) as string[]

  const columns = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const columnDefinitions: ColumnDef<RolePermissionsRow, any>[] = [
      {
        accessorKey: 'role',
        header: 'Role Name',
        sortingFn: 'alphanumeric',
      },
      ...permissionGroups.map((groupName) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const column: ColumnDef<RolePermissionsRow, any> = {
          accessorKey: groupName,
          header: camelCaseToArray(capitalizeFirstChar(groupName))
            ?.join(' ')
            .toUpperCase()
            .replace('ADMIN', ' ADMIN'),
          enableSorting: false,
          cell: (cell: CellContext<RolePermissionsRow, PermissionGroup>) => {
            const allowedActions = getAllowedActions(cell.getValue().access)
            return (
              <div style={{ whiteSpace: 'nowrap' }}>
                {!allowedActions.length ? '-' : allowedActions.join(' & ')}
              </div>
            )
          },
        }
        return column
      }),
    ]

    if (
      Object.values(permissions).some(
        (x) => x.name === PermissionsEnum.CanViewAllCustomers
      )
    ) {
      columnDefinitions.push({
        id: canViewAllCustomersProp,
        accessorKey: canViewAllCustomersProp,
        header: 'Can View All Customers',
        cell: (cell: CellContext<RolePermissionsRow, PermissionGroup>) => {
          const allowedActions = getAllowedActions(cell.getValue().access)
          return capitalizeFirstChar(
            (
              allowedActions[0] === capitalizeFirstChar(canViewAllCustomersProp)
            ).toString()
          )
        },
      })
    }

    return columnDefinitions
  }, [permissionGroups, permissions])

  const getRolePermissions = useCallback(
    (role: Role) => {
      const rolePermissions = Object.entries(
        transformedPermissionGroups
      ).reduce(
        (
          prev,
          [name, { read, limitedWrite, write, technical, canViewAllCustomers }]
        ) => {
          const hasRead = role.permissionIds.some(
            (permissionId) => permissionId === read
          )
          const hasLimitedWrite = role.permissionIds.some(
            (permissionId) => permissionId === limitedWrite
          )
          const hasWrite = role.permissionIds.some(
            (permissionId) => permissionId === write
          )
          const hasTechnical = role.permissionIds.some(
            (permissionId) => permissionId === technical
          )
          const canViewAllCustomersPerm = role.permissionIds.some(
            (permissionId) => permissionId === canViewAllCustomers
          )

          return {
            ...prev,
            [name]: {
              name,
              access: {
                read: hasRead,
                limitedWrite: hasLimitedWrite,
                write: hasWrite,
                technical: hasTechnical,
                canViewAllCustomers: canViewAllCustomersPerm,
              },
            },
          }
        },
        {}
      )
      return rolePermissions
    },
    [transformedPermissionGroups]
  )

  const rows = useMemo(() => {
    const rows = roles.map((role) => ({
      role: role.name,
      ...getRolePermissions(role),
    })) as RolePermissionsRow[]

    return rows
  }, [getRolePermissions, roles])

  const tableData = useGlobalSearch(rows)

  return (
    <>
      <ReactTable
        tableToolbarOptions={{
          title: 'Roles Administration',
        }}
        tableOptions={{
          data: tableData ?? [],
          columns: columns,
          showLoading: rolesStatus === FetchingStatus.Request,
        }}
      />
    </>
  )
}

export default RolesManagementList
