import { prepIconName } from '.'
import { StaffDuressAndAssistOptions } from '../../models'
import { IconUrlBadgeOptions, IconUrlOptions } from '../../models/inpixonMap'

export function buildCDNPath(
  iconUrlOptions: IconUrlOptions,
  iconUrlBadgeOptions: IconUrlBadgeOptions,
  iconUrlStaffDuressAndAssistOptions: Omit<
    StaffDuressAndAssistOptions,
    'staffDuressAndAssistFeatureFlagEnabled' | 'hasResolvedDuressOrAssist'
  >
): string {
  const { image, name, type, scale } = iconUrlOptions
  const { isLowBattery, hasIssues, signalStatus } = iconUrlBadgeOptions
  const { hasActiveDuress, hasActiveAssist, isSelected } =
    iconUrlStaffDuressAndAssistOptions

  return `${
    process.env.REACT_APP_MAPICON_GENERATOR
  }?path=${image}&name=${prepIconName(
    name,
    type
  )}&lowBattery=${isLowBattery}&hasIssues=${hasIssues}&iconScale=${scale}&agentType=${type}${
    signalStatus ? `&signalStatus=${signalStatus.replace('-', '')}` : ''
  }&code=${
    process.env.REACT_APP_MAPICON_KEY
  }&isDuress=${hasActiveDuress}&isAssist=${hasActiveAssist}&selected=${isSelected}`
}
