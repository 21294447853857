import { Link } from '@mui/material'
import React from 'react'
import { colors } from '../../styles'
import { GenericButtonProps } from './Button'

export const PrimaryTextButton = (props: GenericButtonProps): JSX.Element => {
  const { onClick, loading, disabled, children } = props

  return (
    <Link
      component='button'
      variant='body1'
      onClick={onClick}
      disabled={disabled || loading}
      underline='hover'
      style={{
        fontWeight: 'bold',
        fontSize: 14,
        color: disabled || loading ? colors.darkGrey : colors.primary,
      }}
    >
      {children}
    </Link>
  )
}

export default PrimaryTextButton
