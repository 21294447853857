import { AgentEventLocation, AgentEventLocationUpdate } from '../models'
import { HttpClient } from '../services/http'
import { BaseService, MiddlewareServiceInit } from './baseService'

export class AgentEventLocationApi extends BaseService {
  constructor(init?: MiddlewareServiceInit, httpClient?: HttpClient) {
    super(
      init
        ? { ...init, entityType: 'agentEventLocation' }
        : { entityType: 'agentEventLocation' },
      httpClient
    )
  }
  route = 'agents/events'

  async getAllAgentEvents(): Promise<AgentEventLocation[]> {
    return await this.get<AgentEventLocation[]>(this.route)
  }

  async getAgentEvent(Id: string): Promise<AgentEventLocation> {
    return await this.get<AgentEventLocation>(`${this.route}/${Id}`)
  }

  async putAgentEvent(
    agentEventLocationUpdateModel: AgentEventLocationUpdate
  ): Promise<AgentEventLocation> {
    return await this.put<AgentEventLocation>(
      `${this.route}/${agentEventLocationUpdateModel.Id}`,
      agentEventLocationUpdateModel
    )
  }
  async postAgentEvent(
    agentEventLocationRequest: AgentEventLocation
  ): Promise<AgentEventLocation> {
    return await this.post<AgentEventLocation>(
      `${this.route}`,
      agentEventLocationRequest
    )
  }
}

export const agentEventLocationApi = new AgentEventLocationApi()
