import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { OperationsUserAction } from '../actions'
import {
  getAllOperationsUsersAction,
  getOperationsUserByIdAction,
  getOperationsUsersByEmailAction,
  postOperationsUserAction,
  putOperationsUserAction,
  getOperationsUserByNameAction,
  deleteOperationsUserAction,
} from '../actions/operationsUsers'
import { OperationsUsersApi } from '../middleware/operationsUsers'
import { OperationsUsersState } from '../models/operationsUsers'

type UsersEpic = Epic<
  OperationsUserAction,
  OperationsUserAction,
  OperationsUsersState,
  { operationsUsersApi: OperationsUsersApi }
>

export const getAllOperationsUsersActionEpic: UsersEpic = (
  action$,
  state$,
  { operationsUsersApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllOperationsUsersAction.request)),
    mergeMap(() =>
      from(operationsUsersApi.getAllOperationsUsers()).pipe(
        map((payload) => getAllOperationsUsersAction.success(payload)),
        catchError((error) => of(getAllOperationsUsersAction.failure(error)))
      )
    )
  )
}

export const getOperationsUserByIdActionEpic: UsersEpic = (
  action$,
  state$,
  { operationsUsersApi }
) => {
  return action$.pipe(
    filter(isActionOf(getOperationsUserByIdAction.request)),
    mergeMap((action) =>
      from(operationsUsersApi.getOperationsUserById(action.payload)).pipe(
        map((payload) => getOperationsUserByIdAction.success(payload)),
        catchError((error) => of(getOperationsUserByIdAction.failure(error)))
      )
    )
  )
}

export const getOperationsUserByEmailActionEpic: UsersEpic = (
  action$,
  state$,
  { operationsUsersApi }
) => {
  return action$.pipe(
    filter(isActionOf(getOperationsUsersByEmailAction.request)),
    mergeMap((action) =>
      from(operationsUsersApi.getOperationsUsersByEmail(action.payload)).pipe(
        map((payload) => getOperationsUsersByEmailAction.success(payload)),
        catchError((error) =>
          of(getOperationsUsersByEmailAction.failure(error))
        )
      )
    )
  )
}

export const postOperationsUsersActionEpic: UsersEpic = (
  action$,
  state$,
  { operationsUsersApi }
) => {
  return action$.pipe(
    filter(isActionOf(postOperationsUserAction.request)),
    mergeMap((action) =>
      from(operationsUsersApi.createOperationsUser(action.payload)).pipe(
        map((payload) => postOperationsUserAction.success(payload)),
        catchError((error) => of(postOperationsUserAction.failure(error)))
      )
    )
  )
}

export const putOperationsUsersActionEpic: UsersEpic = (
  action$,
  state$,
  { operationsUsersApi }
) => {
  return action$.pipe(
    filter(isActionOf(putOperationsUserAction.request)),
    mergeMap((action) =>
      from(operationsUsersApi.updateOperationsUser(action.payload)).pipe(
        map((payload) => putOperationsUserAction.success(payload)),
        catchError((error) => of(putOperationsUserAction.failure(error)))
      )
    )
  )
}

export const getOperationsUserByNameActionEpic: UsersEpic = (
  action$,
  state$,
  { operationsUsersApi }
) => {
  return action$.pipe(
    filter(isActionOf(getOperationsUserByNameAction.request)),
    mergeMap((action) =>
      from(operationsUsersApi.getOperationsUserByName(action.payload)).pipe(
        map((payload) => getOperationsUserByNameAction.success(payload)),
        catchError((error) => of(getOperationsUserByNameAction.failure(error)))
      )
    )
  )
}

export const deleteOperationsUserEpic: UsersEpic = (
  action$,
  state$,
  { operationsUsersApi }
) => {
  return action$.pipe(
    filter(isActionOf(deleteOperationsUserAction.request)),
    mergeMap((action) =>
      from(operationsUsersApi.deleteOperationsUser(action.payload)).pipe(
        map(() => deleteOperationsUserAction.success(action.payload)),
        catchError((error) => of(deleteOperationsUserAction.failure(error)))
      )
    )
  )
}
