export interface GeometricBadgeTraffic {
  id: number
  customerId: string
  hardwareId: string
  telemetryType: TelemetryType
  mapId: number
  latitude: number
  longitude: number
  uncertainty: number
  timestamp: Date
  geofenceId: number
}

export enum TelemetryType {
  ble = 'Ble',
  wifi = 'Wifi',
}

export interface GeometricBadgeTrafficList {
  [hardwareID: string]: GeometricBadgeTraffic
}
