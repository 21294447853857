import React, { Fragment, useMemo } from 'react'
import {
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Typography,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { colors } from '../../styles'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import PlaylistAddCheck from '@mui/icons-material/PlaylistAddCheck'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

import List from '@mui/icons-material/List'
import {
  CloudDoneOutlined,
  CloudQueueOutlined,
  EditOutlined,
  CloudDownloadOutlined,
  Memory,
  CancelOutlined,
  LoopOutlined,
  DeleteOutlined,
} from '@mui/icons-material'
import icons from '../../models/icons'
import CustomSVGIcon from '../Icons/CustomSVGIcon'
import { DefaultButtonStyles } from '../Buttons'

const useStyles = makeStyles((styleOverride?: DefaultButtonStyles) =>
  createStyles({
    card: {
      display: 'flex',
      alignItems: 'center',
      height: 68,
      width: 100,
      flexDirection: 'column',
      boxShadow: '0 0 3px 1px rgba(0, 0, 0, 0.2)',
    },
    cardContent: {
      height: 68,
      width: 100,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'column',
      padding: 5,
    },
    avatar: {
      height: 35,
      width: 35,
      marginRight: 30,
      backgroundColor: colors.primary,
      fontSize: 12,
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      width: 100,
      paddingLeft: 10,
      paddingRight: 10,
      textAlign: 'center',
    },
    text: {
      fontSize: 12,
      color: '#165788',
      fontFamily: 'Ubuntu',
      lineHeight: 'normal',
    },
    textGrey: {
      fontSize: 12,
      color: '#979797',
      fontFamily: 'Ubuntu',
      lineHeight: 'normal',
    },
    iconContainer: {
      height: 24,
      width: 100,
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
    },
    icon: {
      marginTop: 4,
      color: '#165788',
      width: 24,
      height: 24,
      position: 'relative',

      '& svg': {
        position: 'absolute',
        top: 0,
        left: 0,
      },
    },
    iconGrey: {
      marginTop: 4,
      color: '#979797',
      width: 24,
      height: 24,
    },
    blueProgress: {
      color: '#236092',
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
      ...(styleOverride ? styleOverride.whiteProgress : {}),
    },
  })
)

export enum IconType {
  PinDropIcon,
  PlayListAddIcon,
  PlayListAddCheck,
  List,
  EditOutlined,
  CloudQueue,
  CloudDone,
  Download,
  ArrowForward,
  Memory,
  DisabledOutlined,
  PreviewOutlined,
  DeleteOutlined,
}

interface Props {
  cardText: string
  cardIconType: IconType
  onActionAreaClick: () => void
  isLoading?: boolean
  isDisabled?: boolean
  titleText?: string
  id?: string
}

const QuickActionCard = (props: Props) => {
  const {
    cardText,
    cardIconType,
    onActionAreaClick,
    isLoading,
    isDisabled,
    titleText,
    id,
  } = props
  const classes = useStyles()

  const greyText = useMemo(
    () => isLoading || isDisabled,
    [isLoading, isDisabled]
  )

  return (
    <Card className={classes.card} title={titleText ?? ''}>
      <CardActionArea
        id={id}
        onClick={onActionAreaClick}
        disabled={isLoading || isDisabled}
      >
        <CardContent className={classes.cardContent}>
          <>
            <div className={classes.iconContainer}>
              <Fragment>
                {cardIconType === IconType.PinDropIcon && (
                  <CustomSVGIcon
                    svgText={
                      !greyText ? icons.viewAsset : icons.viewAssetDisabled
                    }
                    classes={{ ...classes }}
                  />
                )}
                {cardIconType === IconType.PlayListAddIcon && (
                  <PlaylistAddIcon
                    className={!greyText ? classes.icon : classes.iconGrey}
                  />
                )}
                {cardIconType === IconType.PlayListAddCheck && (
                  <PlaylistAddCheck
                    className={!greyText ? classes.icon : classes.iconGrey}
                  />
                )}
                {cardIconType === IconType.List && (
                  <List
                    className={!greyText ? classes.icon : classes.iconGrey}
                  />
                )}
                {cardIconType === IconType.EditOutlined && (
                  <EditOutlined
                    className={!greyText ? classes.icon : classes.iconGrey}
                  />
                )}
                {cardIconType === IconType.CloudDone && (
                  <CloudDoneOutlined
                    className={!greyText ? classes.icon : classes.iconGrey}
                  />
                )}
                {cardIconType === IconType.CloudQueue && (
                  <CloudQueueOutlined
                    className={!greyText ? classes.icon : classes.iconGrey}
                  />
                )}
                {cardIconType === IconType.Download && (
                  <CloudDownloadOutlined
                    className={!greyText ? classes.icon : classes.iconGrey}
                  />
                )}
                {cardIconType === IconType.ArrowForward && (
                  <ArrowForwardIcon
                    className={!greyText ? classes.icon : classes.iconGrey}
                  />
                )}
                {cardIconType === IconType.Memory && (
                  <Memory
                    className={!greyText ? classes.icon : classes.iconGrey}
                  />
                )}
                {cardIconType === IconType.DisabledOutlined && (
                  <CancelOutlined
                    className={!greyText ? classes.icon : classes.iconGrey}
                  />
                )}
                {cardIconType === IconType.PreviewOutlined && (
                  <LoopOutlined
                    className={!greyText ? classes.icon : classes.iconGrey}
                  />
                )}
                {cardIconType === IconType.DeleteOutlined && (
                  <DeleteOutlined
                    className={!greyText ? classes.icon : classes.iconGrey}
                  />
                )}
              </Fragment>
            </div>
            <div className={classes.textContainer}>
              <Typography
                className={!greyText ? classes.text : classes.textGrey}
              >
                {cardText}
              </Typography>
            </div>
          </>
          {isLoading ? (
            <div style={{ width: 100, height: 100, zIndex: 100 }}>
              <Fragment>
                <CircularProgress
                  size={24.5}
                  className={classes.blueProgress}
                />
              </Fragment>
            </div>
          ) : (
            <div></div>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default QuickActionCard
