import { BaseService } from './baseService'

export class ModelsApi extends BaseService {
  constructor() {
    super({ entityType: 'models' })
  }
  route = 'models'

  async getAllModels(): Promise<string[]> {
    return await this.get<string[]>(this.route)
  }
}
