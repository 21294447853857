import { FetchingState } from '../interfaces/fetchingState'
import { FetchingStatus } from '../models/fetchingStatus'
import { HttpErrorResponse } from '../services/http'

interface State<U> {
  [key: string]: U
}

export const mapToState = <T, K extends keyof T>(
  items: T[],
  key: K
): State<T> =>
  items.reduce<State<T>>(
    (state, item) => ({
      ...state,
      [String(item[key])]: item,
    }),
    {}
  )

// Immutable merge. Only updates slice
// DO NOT USE inside any loops, this should be used for
// single entity updates only
export const mergeRecord = <T extends State<U>, U, K extends keyof U>(
  state: T,
  record: U,
  key: K
): T => {
  return record[key]
    ? {
        ...state,
        [String(record[key])]: {
          ...state[String(record[key])],
          ...record,
        },
      }
    : state
}

// Immutable delete
export const deleteRecords = <T extends State<U>, U>(
  state: T,
  ids: string[] | number[]
): T => {
  const nextState = { ...state }
  let stateUpdated = false
  ids.forEach((id) => {
    if (id in state) {
      stateUpdated = true
      delete nextState[id]
    }
  })
  return stateUpdated ? nextState : state
}

// Reduce array payload to dictionary of key `key`
export const mergeRecords = <T extends State<U>, U, K extends keyof U>(
  state: T,
  payload: U[],
  key: K
): T => {
  if (!payload) {
    return state
  }

  const merged = {} as State<U>

  payload.forEach((record) => {
    if (record[key] !== undefined) {
      merged[String(record[key])] = state[String(record[key])]
        ? {
            ...state[String(record[key])],
            ...record,
          }
        : record
    }
  })

  return {
    ...state,
    ...merged,
  }
}

export const mergeRecordsByValue = <T extends State<string | number>>(
  state: T,
  payload: string[] | number[]
): T => {
  if (!payload) {
    return state
  }

  const merged = {} as State<string | number>

  payload.forEach((record) => {
    merged[record] = record
  })

  return {
    ...state,
    ...merged,
  }
}

// Immutable delete
export const deleteRecord = <T extends State<U>, U>(
  state: T,
  id: string | number
): T => {
  if (id in state) {
    const nextState = { ...state }
    delete nextState[id]
    return nextState
  }
  return state
}

export const deleteLoadRecords = <T extends State<U>, U, K extends keyof U>(
  state: T,
  payload: U[],
  key: K
): any => {
  if (!payload) {
    return state
  }

  const merged = {} as State<U>

  payload.forEach((record) => {
    if (record[key] !== undefined) {
      merged[String(record[key])] = {
        ...record,
      }
    }
  })
  return {
    ...merged,
  }
}

export const setFetchingStatus = <T>(
  state: FetchingState<T>,
  status: FetchingStatus,
  error?: HttpErrorResponse
): FetchingState<T> => ({
  ...state,
  status,
  lastRefreshTime: new Date(),
  error,
  errorAcknowledged: false,
})

export const noop = (): void => {}
