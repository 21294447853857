import React, { PropsWithChildren } from 'react'
import { Typography } from '@mui/material'

import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'

import { defaultTheme } from '.'
import { padding } from '../../styles'
import { Variant } from '@mui/material/styles'

const styles = createStyles({
  root: {
    ...padding(12, defaultTheme.spacing),
  },
})

interface Props extends WithStyles<typeof styles> {
  variant?: Variant
  noWrap?: boolean
}

const TableMessage: React.FC<Props & WithStyles<typeof styles>> = (
  props: PropsWithChildren<Props>
) => {
  const { children, classes, noWrap, variant = 'body1' } = props
  return (
    <Typography noWrap={noWrap} variant={variant} className={classes.root}>
      {children}
    </Typography>
  )
}

export default withStyles(styles, { name: 'TableMessage' })(TableMessage)
