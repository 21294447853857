import { createAsyncAction } from 'typesafe-actions'
import {
  BadgeSensorState,
  BadgeTrafficRequest,
} from '../models/badgeSensorState'

export const fetchBadgeSensorStatesByLocationIdAction = createAsyncAction(
  'FETCH_BADGE_SENSOR_STATES_BY_LOCATION_ID_REQUEST',
  'FETCH_BADGE_SENSOR_STATES_BY_LOCATION_ID_SUCCESS',
  'FETCH_BADGE_SENSOR_STATES_BY_LOCATION_ID_FAILURE'
)<string, BadgeSensorState[], { locationId: string; error: Error }>()

export const fetchBadgeSensorStatesByBadgeIdsAction = createAsyncAction(
  'FETCH_BADGE_SENSOR_STATES_BY_BADGE_IDS_REQUEST',
  'FETCH_BADGE_SENSOR_STATES_BY_BADGE_IDS_SUCCESS',
  'FETCH_BADGE_SENSOR_STATES_BY_BADGE_IDS_FAILURE'
)<
  BadgeTrafficRequest,
  BadgeSensorState[],
  { badgeTrafficRequest: BadgeTrafficRequest; error: Error }
>()
