import { getType } from 'typesafe-actions'
import { ChannelSoundsAction } from '../actions'
import { getAllChannelSoundsAction } from '../actions/channelSounds'
import {
  ChannelSound,
  ChannelSounds,
  ChannelSoundsState,
  FetchingStatus,
} from '../models'
import { mergeRecords, setFetchingStatus } from '../utils'

const initialState: ChannelSoundsState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

export default (
  state: ChannelSoundsState = initialState,
  action: ChannelSoundsAction
): ChannelSoundsState => {
  switch (action.type) {
    case getType(getAllChannelSoundsAction.success):
      return {
        data: mergeRecords<ChannelSounds, ChannelSound, 'id'>(
          state.data,
          action.payload,
          'id'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getAllChannelSoundsAction.request):
      return setFetchingStatus<ChannelSounds>(state, FetchingStatus.Request)
    case getType(getAllChannelSoundsAction.failure):
      return setFetchingStatus<ChannelSounds>(state, FetchingStatus.Failure)
    default:
      return state
  }
}
