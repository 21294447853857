import { getType } from 'typesafe-actions'
import { AuthAction } from '../actions'
import {
  setAuthenticatedUserAction,
  setChangeTenantConfirmedAction,
  setCurrentCustomerIdAction,
  setCurrentPermissionsAction,
  setIsCustomerSelectedAction,
} from '../actions/auth'
import { AuthState, AuthStore } from '../models/auth'

const initialState: AuthStore = {
  authenticated: false,
  currentCustomerId: '',
  user: {
    customerId: '',
    firstName: '',
    id: '',
    lastName: '',
    name: '',
    permissions: [],
  },
  isImpersonating: false,
  permissions: [],
}
export default (
  state: AuthStore = initialState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case getType(setAuthenticatedUserAction):
      return {
        ...state,
        authenticated: !!action.payload?.id?.length,
        user: action.payload,
        permissions: action.payload.permissions,
      }
    case getType(setCurrentCustomerIdAction):
      return {
        ...state,
        currentCustomerId: action.payload,
        isImpersonating: action.payload !== state.user?.customerId,
      }
    case getType(setCurrentPermissionsAction):
      return { ...state, permissions: action.payload }
    default:
      return state
  }
}
