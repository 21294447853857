import { createAction, createAsyncAction } from 'typesafe-actions'
import { CurrentUserLocation } from '../models/currentUserLocation'
import { HttpErrorResponse } from '../services/http'

export const getCurrentUserLocationAction = createAsyncAction(
  'GET_CURRENT_USER_LOCATION_REQUEST',
  'GET_CURRENT_USER_LOCATION_SUCCESS',
  'GET_CURRENT_USER_LOCATION_FAILURE'
)<string, CurrentUserLocation | null, HttpErrorResponse>()

export const updateCurrentUserLocation = createAction(
  'UPDATE_CURRENT_USER_LOCATION'
)<CurrentUserLocation>()
