import { TextFieldVariants } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useFormStyle } from '../../styles/index'

interface Props {
  date: Date | string | null
  error?: boolean
  field: string
  helperText?: React.ReactNode
  label: string
  required: boolean
  variant?: TextFieldVariants
  setDate: (field: string, event: string) => any
}

const MaterialDatePicker = (props: Props) => {
  const { date, field, label, required, setDate, variant = 'filled' } = props
  const format = 'MM/DD/YYYY'
  const [selectedDate, setSelectedDate] = useState<Date | string | null>(date)
  const [errorMsg, setErrorMsg] = useState<string>()
  const [errorValidation, setErrorValidation] = useState<boolean>()
  useEffect(() => {
    setSelectedDate(date)
  }, [date])

  const handleChange = (event: any) => {
    setSelectedDate(event)
    setDate(field, event)
  }

  const handleBlur = (event: any) => {
    if (selectedDate !== undefined) {
      const momentObj = moment(selectedDate)
      if (momentObj !== undefined && selectedDate !== null) {
        const location = momentObj.format('MM/DD/YYYY')
        if (location === 'Invalid date') {
          setErrorValidation(true)
          setErrorMsg(location)
        } else {
          setErrorValidation(false)
          setErrorMsg('')
        }
      } else {
        setErrorValidation(false)
        setErrorMsg('')
      }
    }
  }

  const classes = useFormStyle()

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        className={classes.inputStyle}
        label={label}
        value={selectedDate ? moment(selectedDate) : null}
        defaultValue={null}
        onChange={handleChange}
        format={format}
        slotProps={{
          textField: {
            variant: variant,
            margin: 'normal',
            name: field,
            color: 'primary',
            error: errorValidation,
            helperText: errorMsg,
            onBlur: handleBlur,
            style: { width: '100%' },
          },
        }}
      />
    </LocalizationProvider>
  )
}

export default React.forwardRef(MaterialDatePicker)
