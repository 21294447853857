import { BaseService } from './baseService'
import { MapReferenceView } from '../models/mapReferenceViews'

export class MapReferenceViewApi extends BaseService {
  constructor() {
    super({ entityType: 'mapReferenceView' })
  }

  public async fetchMapReferenceViewsByVenueId(
    venueID: number
  ): Promise<MapReferenceView[]> {
    return await this.get<MapReferenceView[]>(`mapReference?venueId=${venueID}`)
  }

  public async fetchMapReferenceViewsByFloorLocationId(
    floorLocationId: string
  ): Promise<MapReferenceView[]> {
    return await this.get<MapReferenceView[]>(
      `mapReference?floorLocationId=${floorLocationId}`
    )
  }

  public async fetchMapReferenceViewByDeviceGuid(
    deviceGuid: string
  ): Promise<MapReferenceView> {
    return await this.get<MapReferenceView>(
      `mapReference?deviceGuid=${deviceGuid}`
    )
  }

  public async fetchMapReferenceViewBySensorGuid(
    sensorGuid: string
  ): Promise<MapReferenceView> {
    return await this.get<MapReferenceView>(
      `mapReference?sensorGuid=${sensorGuid}`
    )
  }

  public async fetchMapReferenceViewByLocationId(
    locationId: string
  ): Promise<MapReferenceView> {
    return await this.get<MapReferenceView>(
      `mapReference?locationId=${locationId}`
    )
  }

  public async fetchAllMapReferenceViews(): Promise<MapReferenceView[]> {
    return await this.get<MapReferenceView[]>(`mapReference`)
  }
}
