import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import RootAction from '../../actions'
import { RootState } from '../../reducers'
import {
  getAllStaffImportsAction,
  postImportStaffAction,
} from 'v2/actions/importsStaff'
import { ImportsStaffApi } from 'v2/middleware/importsStaff'

type ImportsStaffEpic = Epic<
  RootAction,
  RootAction,
  RootState,
  { importsStaffApi: ImportsStaffApi }
>

export const getAllStaffImportsEpic: ImportsStaffEpic = (
  action$,
  state,
  { importsStaffApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllStaffImportsAction.request)),
    mergeMap((action) =>
      from(importsStaffApi.getAllStaffImports()).pipe(
        map((imports) => getAllStaffImportsAction.success(imports)),
        catchError((error) => of(getAllStaffImportsAction.failure(error)))
      )
    )
  )
}

export const postImport: ImportsStaffEpic = (
  action$,
  state,
  { importsStaffApi }
) => {
  return action$.pipe(
    filter(isActionOf(postImportStaffAction.request)),
    mergeMap((action) =>
      from(importsStaffApi.postImportStaff(action.payload)).pipe(
        map((importResponse) => postImportStaffAction.success(importResponse)),
        catchError((error) => of(postImportStaffAction.failure(error)))
      )
    )
  )
}
