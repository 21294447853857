import { getType } from 'typesafe-actions'
import { AgentSilentUpdateAction } from '../actions'
import {
  trackSilentUpdateRequest,
  trackSilentUpdateSuccess,
  setSilentRefreshStatus,
} from '../actions/agentSilentUpdate'
import { AgentSilentUpdatesState, AttemptedSilentUpdates } from '../models'
import { SilentRefreshStatus } from '../models/fetchingStatus'
import { mergeRecordsByValue } from '../utils'

const initialState: AgentSilentUpdatesState = {
  silentRefreshStatus: SilentRefreshStatus.Complete,
  attemptedSilentUpdates: {},
}

export default (
  state: AgentSilentUpdatesState = initialState,
  action: AgentSilentUpdateAction
): AgentSilentUpdatesState => {
  switch (action.type) {
    case getType(trackSilentUpdateRequest):
      const recordsNotInState = action.payload.filter(
        (x) =>
          state.attemptedSilentUpdates &&
          state.attemptedSilentUpdates[x] === undefined
      )
      return {
        ...state,
        attemptedSilentUpdates: mergeRecordsByValue<AttemptedSilentUpdates>(
          state.attemptedSilentUpdates ?? {},
          recordsNotInState
        ),
        silentRefreshStatus: SilentRefreshStatus.Request,
      }
    case getType(trackSilentUpdateSuccess):
      const newState = { ...state?.attemptedSilentUpdates }

      action.payload.forEach((x: string) => {
        if (state.attemptedSilentUpdates) {
          delete newState[x]
        }
      })

      return {
        ...state,
        attemptedSilentUpdates: newState,
        silentRefreshStatus: SilentRefreshStatus.Success,
      }
    case getType(setSilentRefreshStatus):
      return {
        ...state,
        silentRefreshStatus: action.payload,
      }
    default:
      return state
  }
}
