import { BadgeLocationState } from '../hooks/useBadgeLocationMonitor'
import {
  Asset,
  BadgeSignalStatus,
  Location,
  LocationGeofences,
  Locations,
  SignalType,
  UserPreferences,
  WatchlistItem,
} from '../models'
import { AssetRow } from '../models/assetRow'
import { badgeSignalAccuracy, badgeSignalStatus } from './assetSignal'
import { getLocationBasedBatteryStatus, isLowBattery } from './battery'
import { getCmmsManagedDisplayText } from './cmmsManaged'
import { formatDate, formatLocalDateTimeByUserPreference } from './date'
import { getDefaultAssetIcon, getIconStatusList } from './inpixon/icons'
import { getLocationAncestors } from './location'

export const createAssetRow = (
  asset: Asset,
  currentBadgeLocations: BadgeLocationState,
  currentGeofences: LocationGeofences,
  watchlist: WatchlistItem[],
  allLocations: Locations,
  userPreferences: UserPreferences
): AssetRow => {
  const badgeId = asset.badgeIds[0]
  const currentBadgeLocation = currentBadgeLocations[badgeId]
  const isWatchListed = watchlist.some(
    (item) => item.agentId === asset.agentGuid
  )

  let signalStatus: BadgeSignalStatus | undefined
  let updatedRoomLocation: Location | undefined
  let accuracy = ''
  let signalType = ''
  let lastSensedDate: Date | undefined
  const cmmsManagedDisplay = getCmmsManagedDisplayText(asset.cmmsManaged)

  if (currentBadgeLocation && currentBadgeLocation.roomLocation) {
    if (currentBadgeLocation.timestamp) {
      signalStatus = badgeSignalStatus(currentBadgeLocation.timestamp)
      lastSensedDate = currentBadgeLocation.timestamp
    }
    updatedRoomLocation = currentBadgeLocation.roomLocation
    accuracy = badgeSignalAccuracy(currentBadgeLocation.currentSignalType)
    signalType = SignalType[currentBadgeLocation.currentSignalType]
  } else if (asset.badgeIds[0]) {
    if (asset.badgeTelemetry) {
      if (asset.badgeTelemetry.timestamp) {
        signalStatus = badgeSignalStatus(asset.badgeTelemetry.timestamp)

        lastSensedDate = asset.badgeTelemetry.timestamp
      }
      if (asset.badgeTelemetry?.geoFenceId) {
        const roomLocationId =
          currentGeofences[asset.badgeTelemetry.geoFenceId]?.locationId

        if (roomLocationId) {
          updatedRoomLocation = allLocations[roomLocationId]
        }
      }
      accuracy = badgeSignalAccuracy(asset.badgeTelemetry.signalTypeId)
      signalType = asset.badgeTelemetry.signalTypeId
        ? SignalType[asset.badgeTelemetry.signalTypeId]
        : ''
    }
  }

  const updatedLocationAncestors = getLocationAncestors(
    updatedRoomLocation,
    allLocations
  )

  const image = getIconStatusList(
    signalStatus ?? BadgeSignalStatus.Current,
    isLowBattery(asset?.isLowBattery),
    process.env.REACT_APP_STATIC_CONTENT + asset.iconUrl,
    getDefaultAssetIcon
  )

  return {
    ...asset,
    roomLocation: updatedRoomLocation,
    floorLocation: updatedLocationAncestors?.floor,
    badgeNumber: badgeId,
    icon: image,
    batteryStatus: getLocationBasedBatteryStatus(
      updatedRoomLocation,
      asset?.isLowBattery
    ),
    locationName: updatedRoomLocation?.name ?? '',
    floor: updatedLocationAncestors?.floor?.name ?? '',
    building: updatedLocationAncestors?.building?.name ?? '',
    buildingGroup: updatedLocationAncestors?.buildingGroup?.name ?? '',
    signalStatus: signalStatus ?? '',
    accuracy,
    signalType: signalType,
    preventativeMaintenanceDateString: formatDate(
      asset.preventativeMaintenanceDate,
      'MM/DD/YYYY'
    ),
    lastSensedDateString: formatLocalDateTimeByUserPreference(
      lastSensedDate,
      userPreferences.timeDisplayId
    ),
    tagBatteryChangeDateString: formatDate(asset.tagBatteryChangeDate),
    locationUnitName: updatedRoomLocation?.unitName,
    locationSubUnitName: updatedRoomLocation?.subUnitName,
    cmmsManagedDisplay,
    isWatchListed,
  }
}
