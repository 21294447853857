import { createAsyncAction } from 'typesafe-actions'
import { WatchlistItem } from '../models'
import { HttpErrorResponse } from '../services/http'

export const getWatchlistAction = createAsyncAction(
  'GET_WATCHLIST_REQUEST',
  'GET_WATCHLIST_SUCCESS',
  'GET_WATCHLIST_FAILURE'
)<string, WatchlistItem[], HttpErrorResponse>()

export const getAssetWatchlistAction = createAsyncAction(
  'GET_ASSET_WATCHLIST_REQUEST',
  'GET_ASSET_WATCHLIST_SUCCESS',
  'GET_ASSET_WATCHLIST_FAILURE'
)<string, WatchlistItem[], HttpErrorResponse>()

export const postAssetWatchlistItemAction = createAsyncAction(
  'POST_ASSET_WATCHLIST_ITEM_REQUEST',
  'POST_ASSET_WATCHLIST_ITEM_SUCCESS',
  'POST_ASSET_WATCHLIST_ITEM_FAILURE'
)<WatchlistItem, WatchlistItem, HttpErrorResponse>()

export const deleteAssetWatchlistItemAction = createAsyncAction(
  'DELETE_ASSET_WATCHLIST_ITEM_REQUEST',
  'DELETE_ASSET_WATCHLIST_ITEM_SUCCESS',
  'DELETE_ASSET_WATCHLIST_ITEM_FAILURE'
)<WatchlistItem, WatchlistItem, HttpErrorResponse>()

export const getStaffWatchlistAction = createAsyncAction(
  'GET_STAFF_WATCHLIST_REQUEST',
  'GET_STAFF_WATCHLIST_SUCCESS',
  'GET_STAFF_WATCHLIST_FAILURE'
)<string, WatchlistItem[], HttpErrorResponse>()

export const postStaffWatchlistItemAction = createAsyncAction(
  'POST_STAFF_WATCHLIST_ITEM_REQUEST',
  'POST_STAFF_WATCHLIST_ITEM_SUCCESS',
  'POST_STAFF_WATCHLIST_ITEM_FAILURE'
)<WatchlistItem, WatchlistItem, HttpErrorResponse>()

export const deleteStaffWatchlistItemAction = createAsyncAction(
  'DELETE_STAFF_WATCHLIST_ITEM_REQUEST',
  'DELETE_STAFF_WATCHLIST_ITEM_SUCCESS',
  'DELETE_STAFF_WATCHLIST_ITEM_FAILURE'
)<WatchlistItem, WatchlistItem, HttpErrorResponse>()
