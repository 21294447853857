import { Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { postAlertAction, putAlertAction } from '../../../../actions/alerts'
import AlertChannelDetail from '../../../../components/AlertList/AlertChannelDetail'
import AlertIdentificationDetail from '../../../../components/AlertList/AlertIdentificationDetail'
import AlertQualifierDetail from '../../../../components/AlertList/AlertQualifierDetail'
import AlertResolutionDetail from '../../../../components/AlertList/AlertResolutionDetail'
import AlertRouteDetail from '../../../../components/AlertList/AlertRouteDetail'
import AlertTriggerDetail from '../../../../components/AlertList/AlertTriggerDetail'
import {
  CancelButton,
  SaveConfirmationButton,
  ViewButton,
} from '../../../../components/Buttons'
import RouterPrompt from '../../../../components/Common/RouterPrompt'
import { DialogActionButtonType } from '../../../../components/Dialogs/DialogActionButtonType'
import { PageContent } from '../../../../components/Layout'
import ConfirmationModal from '../../../../components/Modals/ConfirmationModal'
import { nullGuid } from '../../../../helpers'
import {
  useFetchAlerts,
  useFetchLocationRouters,
  useSaveSuccessFailureUtility,
} from '../../../../hooks'
import { useAlertConfigPageReload } from '../../../../hooks/useAlertConfigPageReload'
import { useFetchQualifiers } from '../../../../hooks/useFetchQualiers'
import { AlertConfigSteps, allUnits, FetchingStatus } from '../../../../models'
import {
  Alert,
  AlertCreateOrUpdate,
  AlertsState,
  AlertStatus,
  AlertTypes,
} from '../../../../models/alert'
import {
  AlertQualifier,
  QualifierType,
  LocationChangeType,
} from '../../../../models/alertQualifier'
import { AlertLocationRouter } from '../../../../models/alertRouter'
import { RouteLocationType } from '../../../../models/locationRouter'
import { PageLayoutState } from '../../../../models/pageLayout'
import { AlertConfigStepperHeaderHeight } from '../../constants'
import useAlertActiveConfig from '../../useAlertActiveConfig'
import AlertConfigStepperHeader from '../AlertConfigStepperHeader'
import { setRouterPromptIsEnabledStatusAction } from '../../../../actions/routerPrompt'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      margin: '12px 12px 0px 24px',
    },
    columnContainer: {
      flexBasis: '30%',
      display: 'flex',
      flexDirection: 'column',
    },
    sectionHeader: {
      display: 'flex',
      flexDirection: 'row',
    },
    listItem: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    header: {
      fontFamily: 'Ubuntu',
      fontSize: 20,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'left',
      color: '#000000',
    },
  })
)

export const AlertConfigReviewPage = () => {
  const location = useLocation()
  const history = useNavigate()
  const maxHeight = useSelector(
    ({ pageLayout }: { pageLayout: PageLayoutState }) => pageLayout.maxHeight
  )
  const alertStatus = useSelector(
    ({ alerts }: { alerts: AlertsState }) => alerts.status
  )
  const { activeConfig, setActiveConfig } = useAlertActiveConfig()
  const { formattedAlerts } = useFetchAlerts()
  const { locationRouters } = useFetchLocationRouters()
  const { qualifiers } = useFetchQualifiers()
  const activeConfigLoaded = useAlertConfigPageReload(
    activeConfig,
    setActiveConfig
  )
  const dispatch = useDispatch()

  const [openSaveConfirmationModal, setOpenSaveConfirmationModal] =
    useState<boolean>(false)

  const handleCloseSaveConfirmationModal = () => {
    setOpenSaveConfirmationModal(false)
  }

  const handleOpenSaveConfirmationModal = () => {
    setOpenSaveConfirmationModal(true)
  }

  const handlePrevious = () => {
    const path = `${location.pathname.slice(
      0,
      location.pathname.lastIndexOf('/')
    )}`
    history(
      `${path}/${AlertConfigSteps[AlertConfigSteps.RESOLUTION].toLowerCase()}`
    )
  }

  const saveAlert = (alertStatus: AlertStatus) => {
    const alertQualifiers: AlertQualifier[] = []
    let alert: Alert | undefined
    if (activeConfig.id !== nullGuid) {
      alert = formattedAlerts[activeConfig.id]
    }
    const isAssetAlert = activeConfig.alertType?.id === AlertTypes.AssetAlert

    if (activeConfig.locationChangeQualifier) {
      let qualifierId: string
      let ruleValue: string
      switch (activeConfig.locationChangeQualifier) {
        case LocationChangeType[0]:
          qualifierId = '8c38056e-a559-430f-9686-96e06add9e10'
          ruleValue = LocationChangeType[0]
          break
        case LocationChangeType[1]:
          qualifierId = 'd07f42c4-f9e1-4df1-a39c-42d8a68a7dd3'
          ruleValue = LocationChangeType[1]
          break
        default:
          throw new Error('Location change qualifer not recognized')
      }
      if (
        alert &&
        alert.qualifiers
          ?.map((q) => q.qualifierTypeId)
          .includes(QualifierType.Location)
      ) {
        const existingQualifier = alert.qualifiers?.find(
          (q) => q.qualifierTypeId === QualifierType.Location
        )
        const allQualifiers = Object.values(qualifiers).find(
          (q) => q.id === qualifierId
        )
        if (existingQualifier && allQualifiers?.id) {
          alertQualifiers.push({
            id: existingQualifier.id,
            alertId: existingQualifier.alertId,
            qualifierId: allQualifiers.id,
            qualifierTypeId: existingQualifier.qualifierTypeId,
            ruleValue: ruleValue,
            joinTypeId: existingQualifier.joinTypeId,
          })
        }
      } else {
        const newQualifer = Object.values(qualifiers).find(
          (q) => q.id === qualifierId
        )
        if (newQualifer) {
          alertQualifiers.push({
            id: nullGuid,
            alertId: activeConfig.id,
            qualifierId: newQualifer.id,
            qualifierTypeId: newQualifer.qualifierTypeId,
            ruleValue: ruleValue,
            joinTypeId: 1,
          })
        }
      }
    }

    if (activeConfig.locationQualifier.floor) {
      let qualifierId: string
      switch (activeConfig.locationChangeQualifier) {
        case LocationChangeType[0]:
          qualifierId = 'b5574045-be44-48fb-980b-81c8d4182d3c'
          break
        case LocationChangeType[1]:
          qualifierId = '6266b159-e30b-4633-aac6-d07bb26feed5'
          break
        default:
          qualifierId = 'e498d20f-4c1f-43bd-a09e-c94d6a5fe127'
      }
      if (
        alert &&
        alert.qualifiers
          ?.map((q) => q.qualifierTypeId)
          .includes(QualifierType.FloorLocation)
      ) {
        const existingQualifier = alert.qualifiers?.find(
          (q) => q.qualifierTypeId === QualifierType.FloorLocation
        )
        const allQualifiers = Object.values(qualifiers).find(
          (q) => q.id === qualifierId
        )
        if (existingQualifier && allQualifiers?.id) {
          alertQualifiers.push({
            id: existingQualifier.id,
            alertId: existingQualifier.alertId,
            qualifierId: allQualifiers.id,
            qualifierTypeId: existingQualifier.qualifierTypeId,
            ruleValue: activeConfig.locationQualifier.floor.id,
            joinTypeId: existingQualifier.joinTypeId,
          })
        }
      } else {
        const roomLocationQualifierType = Object.values(qualifiers).find(
          (q) => q.id === qualifierId
        )
        if (roomLocationQualifierType) {
          alertQualifiers.push({
            id: nullGuid,
            alertId: activeConfig.id,
            qualifierId: roomLocationQualifierType.id,
            qualifierTypeId: roomLocationQualifierType.qualifierTypeId,
            ruleValue: activeConfig.locationQualifier.floor.id,
            joinTypeId: 1,
          })
        }
      }
    }

    if (
      activeConfig.unitQualifier &&
      activeConfig.unitQualifier?.id !== allUnits.id
    ) {
      let qualifierId: string
      switch (activeConfig.locationChangeQualifier) {
        case LocationChangeType[0]:
          qualifierId = 'ad670846-4b2c-48da-b332-dadefc0f2144'
          break
        case LocationChangeType[1]:
          qualifierId = '7acbecac-b63d-4470-b831-4f31dbd038e8'
          break
        default:
          qualifierId = 'e9cd76eb-5737-4ed2-88e7-d6b19517458f'
      }
      if (
        alert &&
        alert.qualifiers
          ?.map((q) => q.qualifierTypeId)
          .includes(QualifierType.Unit)
      ) {
        const unitQualifer = alert.qualifiers?.find(
          (q) => q.qualifierId === qualifierId
        )
        if (unitQualifer) {
          alertQualifiers.push({
            id: unitQualifer.id,
            alertId: unitQualifer.alertId,
            qualifierId: unitQualifer.qualifierId,
            qualifierTypeId: unitQualifer.qualifierTypeId,
            ruleValue:
              activeConfig.unitQualifier.id !== undefined
                ? activeConfig.unitQualifier.id
                : '',
            joinTypeId: unitQualifer.joinTypeId,
          })
        }
      } else {
        const unitQualifierType = Object.values(qualifiers).find(
          (q) => q.id === qualifierId
        )
        if (unitQualifierType) {
          alertQualifiers.push({
            id: nullGuid,
            alertId: activeConfig.id,
            qualifierId: unitQualifierType.id,
            qualifierTypeId: unitQualifierType.qualifierTypeId,
            ruleValue:
              activeConfig.unitQualifier.id === allUnits.id
                ? allUnits.name
                : activeConfig.unitQualifier.id !== undefined
                ? activeConfig.unitQualifier.id
                : '',
            joinTypeId: 1,
          })
        }
      }
    }

    if (activeConfig.badgeQualifier && !isAssetAlert) {
      if (
        alert &&
        alert.qualifiers
          ?.map((q) => q.qualifierTypeId)
          .includes(QualifierType.BadgeList)
      ) {
        const badgeQualifier = alert.qualifiers?.find(
          (q) => q.qualifierTypeId === QualifierType.BadgeList
        )
        if (badgeQualifier) {
          alertQualifiers.push({
            id: badgeQualifier.id,
            alertId: badgeQualifier.alertId,
            qualifierId: badgeQualifier.qualifierId,
            qualifierTypeId: badgeQualifier.qualifierTypeId,
            ruleValue: activeConfig.badgeQualifier,
            joinTypeId: badgeQualifier.joinTypeId,
          })
        }
      } else {
        const badgeQualifierType = Object.values(qualifiers).find(
          (qualifier) => qualifier.qualifierTypeId === QualifierType.BadgeList
        )
        if (badgeQualifierType) {
          alertQualifiers.push({
            id: nullGuid,
            alertId: activeConfig.id,
            qualifierId: badgeQualifierType.id,
            qualifierTypeId: badgeQualifierType.qualifierTypeId,
            ruleValue: activeConfig.badgeQualifier,
            joinTypeId: 1,
          })
        }
      }
    }

    if (activeConfig.assetType) {
      const assetTypeQualifier = Object.values(qualifiers).find(
        (qualifier) => qualifier.qualifierTypeId === QualifierType.AssetType
      )
      alertQualifiers.push({
        id: nullGuid,
        alertId: activeConfig.id,
        qualifierId: assetTypeQualifier.id,
        qualifierTypeId: assetTypeQualifier?.qualifierTypeId,
        ruleValue: activeConfig.assetType.assetTypeId,
        joinTypeId: 1,
      })
    }

    const alertLocationRouters: AlertLocationRouter[] = []
    if (!isAssetAlert && activeConfig.locationRouter.floor) {
      if (
        alert &&
        alert.locationRouters
          .map((r) => r.locationRouterTypeId)
          .includes(RouteLocationType.SelectedLocation)
      ) {
        const locationRouter = alert.locationRouters?.find(
          (q) => q.locationRouterTypeId === RouteLocationType.SelectedLocation
        )
        if (locationRouter) {
          alertLocationRouters.push({
            id: locationRouter.id,
            alertId: locationRouter.alertId,
            locationRouterId: locationRouter.locationRouterId,
            locationRouterTypeId: locationRouter.locationRouterTypeId,
            ruleValue: activeConfig.locationRouter.floor.id,
            joinTypeId: locationRouter.joinTypeId,
          })
        }
      } else {
        const locationRouterType = Object.values(locationRouters).find(
          (q) => q.routeLocationTypeId === RouteLocationType.SelectedLocation
        )
        if (locationRouterType) {
          alertLocationRouters.push({
            id: nullGuid,
            alertId: activeConfig.id,
            locationRouterId: locationRouterType.id,
            locationRouterTypeId: locationRouterType.routeLocationTypeId,
            ruleValue: activeConfig.locationRouter.floor.id,
            joinTypeId: 1,
          })
        }
      }
    }

    if (
      activeConfig.unitRouter &&
      activeConfig.unitRouter?.id !== allUnits.id
    ) {
      if (
        alert &&
        alert.locationRouters
          .map((r) => r.locationRouterTypeId)
          .includes(RouteLocationType.Unit)
      ) {
        const unitRouter = alert.locationRouters?.find(
          (q) => q.locationRouterTypeId === RouteLocationType.Unit
        )
        if (unitRouter) {
          alertLocationRouters.push({
            id: unitRouter.id,
            alertId: unitRouter.alertId,
            locationRouterId: unitRouter.locationRouterId,
            locationRouterTypeId: unitRouter.locationRouterTypeId,
            ruleValue:
              activeConfig.unitRouter.id === allUnits.id
                ? allUnits.name
                : activeConfig.unitRouter.id !== undefined
                ? activeConfig.unitRouter.id
                : '',
            joinTypeId: unitRouter.joinTypeId,
          })
        }
      } else {
        const unitRouterType = Object.values(locationRouters).find(
          (q) => q.routeLocationTypeId === RouteLocationType.Unit
        )
        if (unitRouterType) {
          alertLocationRouters.push({
            id: nullGuid,
            alertId: activeConfig.id,
            locationRouterId: unitRouterType.id,
            locationRouterTypeId: unitRouterType.routeLocationTypeId,
            ruleValue:
              activeConfig.unitRouter.id === allUnits.id
                ? allUnits.name
                : activeConfig.unitRouter.id !== undefined
                ? activeConfig.unitRouter.id
                : '',
            joinTypeId: 1,
          })
        }
      }
    }

    const alertCreateOrUpdate: AlertCreateOrUpdate = {
      id: activeConfig.id,
      name: activeConfig.name,
      description: activeConfig.description,
      alertTypeId: activeConfig.alertType?.id ?? 0,
      alertStatus: alertStatus,
      triggers: activeConfig.trigger ? [activeConfig.trigger] : [],
      qualifiers: alertQualifiers,
      channels: activeConfig.channels,
      locationRouters: alertLocationRouters,
      userRouters: activeConfig.userRouters,
      securityRouters: activeConfig.securityRouters,
      alertResolution: activeConfig.alertResolution,
      alertResolutionId: activeConfig.alertResolution.id,
    }
    setIsSaving(true)
    if (alertCreateOrUpdate.id === nullGuid) {
      dispatch(postAlertAction.request(alertCreateOrUpdate))
    } else {
      dispatch(putAlertAction.request(alertCreateOrUpdate))
    }
    dispatch(setRouterPromptIsEnabledStatusAction(false))
  }

  const handleCloseSaveConfirmationModalOnSuccess = () => {
    setOpenSaveConfirmationModal(false)
    history('/admin/alerts')
  }

  const handleCloseSaveConfirmationModalOnDelete = () => {
    dispatch(setRouterPromptIsEnabledStatusAction(true))
    setOpenSaveConfirmationModal(false)
  }

  const handleSaveAndDisable = () => {
    saveAlert(AlertStatus.Disabled)
  }

  const handleSaveAndEnable = () => {
    saveAlert(AlertStatus.Enabled)
  }

  const handleViewButton = (step: AlertConfigSteps) => {
    const path = `${location.pathname.slice(
      0,
      location.pathname.lastIndexOf('/')
    )}`
    history(`${path}/${AlertConfigSteps[step].toLowerCase()}`)
  }

  const { isSaving, setIsSaving } = useSaveSuccessFailureUtility(
    alertStatus === FetchingStatus.Success,
    alertStatus === FetchingStatus.Failure,
    `Alert has successfully been ${
      activeConfig.id === nullGuid ? 'created.' : 'updated.'
    }`,
    `There is an error saving Alert information, please try again. If the problem persists, please contact support for assistance.`,
    handleCloseSaveConfirmationModalOnSuccess,
    handleCloseSaveConfirmationModalOnDelete
  )

  const leftActionButtons: DialogActionButtonType[] = [
    {
      label: 'Save and Disable',
      action: handleSaveAndDisable,
      variant: 'outlined',
      loading: isSaving,
    },
  ]

  const rightActionButtons: DialogActionButtonType[] = [
    {
      label: 'Save and Enable',
      action: handleSaveAndEnable,
      variant: 'contained',
      color: '#ffffff',
      backgroundColor: '#165788',
      loading: isSaving,
    },
  ]

  const classes = useStyles()

  const headerStyles = {
    paddingLeft: 0,
    paddingTop: 14,
  }

  const listItemStyles = {
    paddingLeft: 0,
  }

  return (
    <PageContent
      maxHeight={maxHeight}
      style={{ maxHeight: '100%' }}
      hideScrollbar={true}
      content={
        <>
          <AlertConfigStepperHeader activeStep={AlertConfigSteps.REVIEW} />
          <div
            style={{
              overflow: 'auto',
              height: '100%',
              maxHeight: `calc(100% - ${AlertConfigStepperHeaderHeight})`,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                position: 'absolute',
                width: '100%',
                marginTop: '12px',
                paddingRight: '12px',
              }}
            >
              <CancelButton onClick={handlePrevious}>Previous</CancelButton>

              <SaveConfirmationButton
                onClick={handleOpenSaveConfirmationModal}
                disableConfirmation={true}
              >
                Save
              </SaveConfirmationButton>
            </div>
            <div
              style={{
                margin: '22px 0px 24px 24px',
              }}
            >
              <Typography variant='h5'>Alert Builder: Review</Typography>
            </div>
            <div className={classes.root}>
              <div className={classes.columnContainer}>
                <div className={classes.sectionHeader}>
                  <Typography className={classes.header}>Alert</Typography>
                  <ViewButton
                    onClick={() =>
                      handleViewButton(AlertConfigSteps.CONDITIONS)
                    }
                  />
                </div>
                <AlertIdentificationDetail
                  alert={activeConfig}
                  isLoading={!activeConfigLoaded}
                  headerStylesOverrides={headerStyles}
                  listItemStylesOverrides={listItemStyles}
                  hideHeader={true}
                />
                <div className={classes.sectionHeader}>
                  <Typography className={classes.header}>Conditions</Typography>
                  <ViewButton
                    onClick={() =>
                      handleViewButton(AlertConfigSteps.CONDITIONS)
                    }
                  />
                </div>
                <AlertTriggerDetail
                  alert={activeConfig}
                  isLoading={!activeConfigLoaded}
                  headerStylesOverrides={headerStyles}
                  listItemStylesOverrides={listItemStyles}
                />
                <AlertQualifierDetail
                  alert={activeConfig}
                  isLoading={!activeConfigLoaded}
                  headerStylesOverrides={headerStyles}
                  listItemStylesOverrides={listItemStyles}
                />
                <div className={classes.sectionHeader}>
                  <Typography className={classes.header}>
                    {activeConfig.alertType?.id === AlertTypes.AssetAlert
                      ? 'Display Method'
                      : 'Channelss'}
                  </Typography>
                  <ViewButton
                    onClick={() => handleViewButton(AlertConfigSteps.CHANNELS)}
                  />
                </div>
                <AlertChannelDetail
                  alert={activeConfig}
                  isLoading={!activeConfigLoaded}
                  headerStylesOverrides={headerStyles}
                  listItemStylesOverrides={listItemStyles}
                />
              </div>
              <div
                className={classes.columnContainer}
                style={{
                  flexBasis: '8%',
                }}
              ></div>
              <div className={classes.columnContainer}>
                <div className={classes.sectionHeader}>
                  <Typography className={classes.header}>Routing</Typography>
                  <ViewButton
                    onClick={() => handleViewButton(AlertConfigSteps.ROUTING)}
                  />
                </div>
                <AlertRouteDetail
                  alert={activeConfig}
                  isLoading={!activeConfigLoaded}
                  headerStylesOverrides={headerStyles}
                  listItemStylesOverrides={listItemStyles}
                />
                <div className={classes.sectionHeader}>
                  <Typography className={classes.header}>Resolution</Typography>
                  <ViewButton
                    onClick={() =>
                      handleViewButton(AlertConfigSteps.RESOLUTION)
                    }
                  />
                </div>
                <AlertResolutionDetail
                  alert={activeConfig}
                  isLoading={!activeConfigLoaded}
                  headerStylesOverrides={headerStyles}
                  listItemStylesOverrides={listItemStyles}
                />
              </div>
            </div>
          </div>
          <ConfirmationModal
            isOpen={openSaveConfirmationModal}
            handleClose={handleCloseSaveConfirmationModal}
            title={`Save ${activeConfig?.name} Alert`}
            leftActionButtons={leftActionButtons}
            rightActionButtons={rightActionButtons}
            content={
              <>
                Would you like to enable this alert after the changes are saved?
              </>
            }
          />
          <RouterPrompt />
        </>
      }
    />
  )
}

export default AlertConfigReviewPage
