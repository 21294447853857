import { moveAgentToNewGeofence } from '.'
import { AssetKitInstance } from '../../models'
import { MapRenderByGeofence } from '../../models/realtimeMap'
import { BadgeTelemetryMessageWithAgentEvent } from '../../models/telemetry'
import { calculateLeavingGeofenceMapUpdatesToMoveAgent } from '../calculateRTMapUpdates'
import {
  calculateMapRenderToUpdateForAgentOnMap,
  checkRemainingAgentsHaveDuress,
} from '../realtimeMap'

/**
 * Once the MapUpdateType has been determined to be MoveAgent from leaving geofence,
 * use this function to process the corresponding map rerendering for the received telemetry.
 * Calculate the required configs followed by moving agent to the new geofence
 * Finally, calculate and return the updated map render state
 * @param newAgentTelemetry
 * @param currentMapRender
 * @param assetKit
 * @param jibestreamController
 * @param geofenceInstances
 * @param useGeofenceCentroid
 * @param iconScale
 * @param selectedTrackingId
 * @param selectedGroupId
 * @param addGeofenceHighlight
 * @param removeGeofenceHighlight
 * @returns updated map render state after map rerendering
 */
export function moveAgent(
  newAgentTelemetry: BadgeTelemetryMessageWithAgentEvent,
  currentMapRender: MapRenderByGeofence,
  assetKit: AssetKitInstance,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  geofenceInstances: any[],
  useGeofenceCentroid: boolean,
  addGeofenceHighlight: (geofenceId: number) => void,
  removeGeofenceHighlight: (geofenceId: number) => void
): MapRenderByGeofence {
  const leavingAgentToUpdatePosition =
    calculateLeavingGeofenceMapUpdatesToMoveAgent(
      newAgentTelemetry,
      currentMapRender
    )
  if (!leavingAgentToUpdatePosition) {
    return currentMapRender
  }
  const remainingAgentsHaveDuress = checkRemainingAgentsHaveDuress(
    currentMapRender,
    newAgentTelemetry.trackingId,
    leavingAgentToUpdatePosition.geoFenceId
  )
  moveAgentToNewGeofence(
    leavingAgentToUpdatePosition,
    assetKit,
    jibestreamController,
    geofenceInstances,
    useGeofenceCentroid,
    addGeofenceHighlight,
    removeGeofenceHighlight,
    remainingAgentsHaveDuress
  )
  const updatedMapRender = calculateMapRenderToUpdateForAgentOnMap(
    currentMapRender,
    newAgentTelemetry
  )
  return updatedMapRender
}
