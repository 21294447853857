import { Release } from '../models/release'
import { BaseService } from './baseService'

export class ReleaseApi extends BaseService {
  constructor() {
    super({ entityType: 'release' })
  }
  route = 'releases'

  async getNextRelease(applicationId: number): Promise<Release> {
    return await this.get<Release>(`${this.route}/latest/${applicationId}`)
  }

  async getNewReleases(applicationId: number): Promise<Release[]> {
    return await this.get<Release[]>(`${this.route}/new/${applicationId}`)
  }

  async getAllReleases(): Promise<Release[]> {
    return await this.get<Release[]>(`${this.route}`)
  }

  async createRelease(release: Release): Promise<Release> {
    return await this.post<Release>(`${this.route}`, release)
  }

  async updateRelease(release: Release): Promise<Release> {
    return await this.put<Release>(`${this.route}`, release)
  }
}
