import { BaseService } from './baseService'
import { Hardware } from '../models'

export class HardwareApi extends BaseService {
  constructor() {
    super({ entityType: 'hardware' })
  }
  route = 'hardware'

  async getAllHardware(): Promise<Hardware[]> {
    return await this.get<Hardware[]>(this.route)
  }
}
