import {
  Button as MaterialButton,
  CircularProgress,
  DialogContentText,
  Theme,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { CSSProperties } from '@mui/styles'
import React, { ReactNode, useMemo, useState } from 'react'
import { colors } from '../../styles'
import { DialogActionButtonType } from '../Dialogs/DialogActionButtonType'
import DialogBox from '../Dialogs/DialogBox'
import { ConfirmationFormInput } from '../Forms/ActionConfirmationForm'
import ConfirmationModal from '../Modals/ConfirmationModal'

export interface GenericButtonProps {
  type?: 'submit' | 'button' | 'reset' | undefined
  variant?: 'text' | 'contained' | 'outlined' | undefined
  style?: Record<string, unknown>
  loading?: boolean
  disabled?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
  children?: React.ReactNode
  styleOverride?: DefaultButtonStyles
  endIcon?: React.ReactNode
  startIcon?: React.ReactNode
  id?: string
}

export interface ButtonProps extends GenericButtonProps {
  bgColor: string
  textColor: string
  id?: string
}

export interface DefaultButtonStyles {
  root?: CSSProperties
  wrapper?: CSSProperties
  whiteProgress?: CSSProperties
}

const useStyles = (styleOverride?: DefaultButtonStyles) => {
  return makeStyles((theme: Theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      ...(styleOverride ? styleOverride.root : {}),
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
      ...(styleOverride ? styleOverride.wrapper : {}),
    },
    whiteProgress: {
      color: 'white',
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
      ...(styleOverride ? styleOverride.whiteProgress : {}),
    },
  }))
}

const Button = (props: ButtonProps): JSX.Element => {
  const {
    type = 'button',
    variant,
    style = { margin: '0 4px' },
    loading = false,
    disabled = false,
    onClick,
    children,
    styleOverride,
    id,
  } = props

  let { bgColor = colors.primary, textColor = colors.white } = props

  if (loading) {
    if (bgColor !== colors.white) {
      bgColor = colors.disabledButtonBackground
    }
    textColor = colors.disabledButtonText
  }

  const classes = useStyles(styleOverride)()

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <MaterialButton
          id={id}
          type={type}
          style={{
            ...style,
            backgroundColor: bgColor,
            color: textColor,
            fontWeight: 'bold',
          }}
          variant={variant}
          onClick={onClick}
          disabled={disabled || loading}
          endIcon={props.endIcon}
          startIcon={props.startIcon}
        >
          {children ? children : 'Save'}
        </MaterialButton>
        {!!loading && (
          <CircularProgress size={24.5} className={classes.whiteProgress} />
        )}
      </div>
    </div>
  )
}

const PrimaryButton = (props: GenericButtonProps): JSX.Element => (
  <Button
    {...props}
    bgColor={props.disabled ? colors.darkGrey : colors.primary}
    textColor={'white'}
    variant={props.variant}
  >
    {props.children}
  </Button>
)

const OutlinedButton = (props: GenericButtonProps): JSX.Element => (
  <Button
    {...props}
    bgColor={colors.white}
    textColor={colors.primary}
    style={{ border: '1px solid rgba(35, 96, 146, 0.5)' }}
  >
    {props.children}
  </Button>
)

const SaveButton = (props: GenericButtonProps): JSX.Element => (
  <PrimaryButton
    {...props}
    type={'submit'}
    styleOverride={{ wrapper: { margin: 0 } }}
  >
    {props.children ? props.children : 'Save'}
  </PrimaryButton>
)

const CreateButton = (props: GenericButtonProps): JSX.Element => (
  <PrimaryButton {...props} type={'submit'}>
    {props.children ? props.children : 'Create'}
  </PrimaryButton>
)

const ViewButton = (props: GenericButtonProps): JSX.Element => (
  <MaterialButton
    {...props}
    style={{
      backgroundColor: colors.transparent,
      color: colors.primary,
      fontWeight: 'normal',
      textTransform: 'none',
      minWidth: '44px',
      padding: 0,
    }}
  >
    View
  </MaterialButton>
)

export interface SaveConfirmationButtonProps extends GenericButtonProps {
  disableConfirmation?: boolean
  confirmationTitle?: string
  confirmationMessage?: string
}

const SaveConfirmationButton = (
  props: SaveConfirmationButtonProps
): JSX.Element => {
  const {
    disableConfirmation,
    confirmationTitle,
    confirmationMessage,
    onClick,
    loading,
    ...rest
  } = props

  const [showDialog, setShowDialog] = useState(false)

  const isLoading = useMemo(() => showDialog || loading, [showDialog, loading])

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    if (disableConfirmation) {
      onClick && onClick(e)
      return
    }

    setShowDialog(true)
  }

  return (
    <>
      <Button
        {...rest}
        loading={isLoading}
        onClick={handleClick}
        bgColor={props.disabled ? colors.darkGrey : colors.primary}
        textColor={colors.white}
        style={props.style}
      >
        {props.children ? props.children : 'Save'}
      </Button>

      <DialogBox
        open={showDialog}
        handleClose={(): void => setShowDialog(false)}
        title={confirmationTitle}
        actionButtons={[
          {
            label: 'Cancel',
            action: (): void => setShowDialog(false),
          },
          {
            label: props.children ? props.children : 'Save',
            action: (e): void => onClick && onClick(e),
            color: colors.primary,
            type: 'button',
          },
        ]}
      >
        <DialogContentText id='alert-dialog-description'>
          {confirmationMessage}
        </DialogContentText>
      </DialogBox>
    </>
  )
}

export interface DeleteButtonProps extends GenericButtonProps {
  disableConfirmation?: boolean
  confirmationTitle?: string
  confirmationMessage?: string
}

const DeleteButton = (props: DeleteButtonProps): JSX.Element => {
  const {
    disableConfirmation,
    confirmationTitle,
    confirmationMessage,
    onClick,
    loading,
    ...rest
  } = props

  const [showDialog, setShowDialog] = useState(false)

  const isLoading = useMemo(() => showDialog || loading, [showDialog, loading])

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    if (disableConfirmation) {
      onClick && onClick(e)
      return
    }

    setShowDialog(true)
  }

  const handleDelete = (e: any) => {
    if (onClick) {
      onClick(e)
    }
    setShowDialog(false)
  }

  return (
    <>
      <Button
        {...rest}
        loading={isLoading}
        onClick={handleClick}
        bgColor={colors.transparent}
        textColor={colors.error}
      >
        {props.children ? props.children : 'Delete'}
      </Button>

      <DialogBox
        open={showDialog}
        handleClose={(): void => setShowDialog(false)}
        title={confirmationTitle}
        actionButtons={[
          {
            label: 'Cancel',
            action: (): void => setShowDialog(false),
          },
          {
            label: props.children ? props.children : 'Delete',
            action: handleDelete,
            color: colors.primary,
            type: 'button',
          },
        ]}
      >
        <DialogContentText id='alert-dialog-description'>
          {confirmationMessage}
        </DialogContentText>
      </DialogBox>
    </>
  )
}

const CancelButton = (props: GenericButtonProps): JSX.Element => (
  <Button {...props} bgColor={colors.transparent} textColor={colors.primary}>
    {props.children ? props.children : 'Cancel'}
  </Button>
)

export interface ActionConfirmationButtonProps extends ButtonProps {
  openConfirmationButtonLabel: string
  confirmButtonLabel?: string
  disableConfirmation: boolean
  confirmationModalTitle: string
  confirmationModalContent: ReactNode
  confirmationInput?: ConfirmationFormInput
  leftActionButtonColor?: string
  rightActionButtonColor?: string
  disableRightActionButton?: boolean
  disableEnterButtonFormSubmission?: boolean
  onContinue: () => void
  onCancelExternalHandler?: () => void
}

const ActionConfirmationButton = (
  props: ActionConfirmationButtonProps
): JSX.Element => {
  const {
    openConfirmationButtonLabel,
    confirmButtonLabel,
    bgColor,
    disabled,
    textColor,
    disableConfirmation,
    confirmationModalTitle,
    confirmationModalContent,
    confirmationInput,
    leftActionButtonColor,
    rightActionButtonColor,
    disableRightActionButton,
    disableEnterButtonFormSubmission = false,
    onContinue,
    onCancelExternalHandler,
    loading,
    children,
    ...rest
  } = props

  const [showDialog, setShowDialog] = useState(false)
  const [isConfirmed, setIsConfirmed] = useState(!confirmationInput)

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    if (disableConfirmation) {
      onContinue()
      return
    }

    setShowDialog(true)
  }

  const handleClose = () => {
    setShowDialog(false)
    setIsConfirmed(false)
    if (onCancelExternalHandler) {
      onCancelExternalHandler()
    }
  }

  const handleContinue = () => {
    onContinue()
    setShowDialog(false)
  }

  const leftActionButtons: DialogActionButtonType[] = [
    {
      label: 'CANCEL',
      action: handleClose,
      color: leftActionButtonColor ?? colors.error,
      variant: 'link',
    },
  ]

  const rightActionButtons: DialogActionButtonType[] = [
    {
      label: confirmButtonLabel ?? openConfirmationButtonLabel,
      action: handleContinue,
      variant: 'contained',
      color: '#ffffff',
      backgroundColor:
        disableRightActionButton || (confirmationInput && !isConfirmed)
          ? colors.darkGrey
          : rightActionButtonColor
          ? rightActionButtonColor
          : colors.darkBlue,
      disabled: (confirmationInput && !isConfirmed) || disableRightActionButton,
    },
  ]

  return (
    <>
      <Button
        {...rest}
        disabled={disabled}
        loading={loading || showDialog}
        onClick={handleClick}
        bgColor={disabled ? colors.darkGrey : bgColor}
        textColor={textColor}
        styleOverride={{ wrapper: { margin: ' 8px 0' } }}
        style={props.style}
      >
        {openConfirmationButtonLabel}
      </Button>

      <ConfirmationModal
        isOpen={showDialog}
        handleClose={handleClose}
        title={confirmationModalTitle}
        leftActionButtons={leftActionButtons}
        rightActionButtons={rightActionButtons}
        content={confirmationModalContent}
        confirmationInput={
          confirmationInput
            ? {
                ...confirmationInput,
                setIsConfirmed,
              }
            : undefined
        }
        disableEnterButtonFormSubmission={disableEnterButtonFormSubmission}
      />
    </>
  )
}

export {
  Button,
  PrimaryButton,
  SaveButton,
  CreateButton,
  DeleteButton,
  CancelButton,
  SaveConfirmationButton,
  OutlinedButton,
  ViewButton,
  ActionConfirmationButton,
}
