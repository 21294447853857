import { createAsyncAction } from 'typesafe-actions'
import { Issue } from '../models'
import { HttpErrorResponse } from '../services/http'

export const getAllIssuesAction = createAsyncAction(
  'GET_ALL_ISSUES_REQUEST',
  'GET_ALL_ISSUES_SUCCESS',
  'GET_ALL_ISSUES_FAILURE'
)<void, Issue[], HttpErrorResponse>()

export const postIssuesAction = createAsyncAction(
  'POST_ISSUES_REQUEST',
  'POST_ISSUES_SUCCESS',
  'POST_ISSUES_FAILURE'
)<Issue[], Issue[], HttpErrorResponse>()

export const putIssueAction = createAsyncAction(
  'PUT_ISSUE_REQUEST',
  'PUT_ISSUE_SUCCESS',
  'PUT_ISSUE_FAILURE'
)<Issue, Issue, HttpErrorResponse>()

export const putIssuesAction = createAsyncAction(
  'PUT_ISSUES_REQUEST',
  'PUT_ISSUES_SUCCESS',
  'PUT_ISSUES_FAILURE'
)<Issue[], Issue[], HttpErrorResponse>()
