import { FeatureFlagState, FeatureFlags, FetchingStatus } from '../models'

export const selectAllFeatureFlags = ({
  featureFlags,
}: {
  featureFlags: FeatureFlagState
}): FeatureFlags => featureFlags.data

export const selectFeatureFlagFetchingStatus = ({
  featureFlags,
}: {
  featureFlags: FeatureFlagState
}): FetchingStatus | undefined => featureFlags.status
