import { focusView, hideLayers } from '.'
import { MapPreset } from '../../models'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getX = (preset: MapPreset | null, asset?: any, x?: number): number => {
  if (x) return x
  if (asset && asset.position[0]) return asset.position[0]
  return preset ? preset.x : 0
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getY = (preset: MapPreset | null, asset?: any, y?: number): number => {
  if (y) return y
  if (asset && asset.position[1]) return asset.position[1]
  return preset ? preset.y : 0
}

export function applyMapPreset(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jibestreamControl: any,
  preset: MapPreset | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  asset?: any,
  x?: number,
  y?: number,
  scale?: number
): void {
  if (preset || (x && y)) {
    focusView(
      jibestreamControl,
      getX(preset, asset, x),
      getY(preset, asset, y),
      preset ? preset.duration : 1,
      scale ? scale : preset ? preset.scale : 1,
      preset ? preset.rotation : 0
    )
  }

  hideLayers(jibestreamControl, preset ? preset.layers : [])
}
