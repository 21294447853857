import { useSelector } from 'react-redux'
import { CurrentUserTelemetrySubscriptionState } from '../models'

const useCurrentUserTelemetrySubscription =
  (): CurrentUserTelemetrySubscriptionState => {
    const currentUserTelemetrySubscriptionState = useSelector(
      ({
        currentUserTelemetrySubscription,
      }: {
        currentUserTelemetrySubscription: CurrentUserTelemetrySubscriptionState
      }) => currentUserTelemetrySubscription
    )

    return {
      ...currentUserTelemetrySubscriptionState,
    }
  }

export { useCurrentUserTelemetrySubscription }
