import { createAsyncAction } from 'typesafe-actions'
import { UserPreferences } from '../models'
import { HttpErrorResponse } from '../services/http'

export const getUserPreferencesAction = createAsyncAction(
  'GET_USER_PREFERENCES_REQUEST',
  'GET_USER_PREFERENCES_SUCCESS',
  'GET_USER_PREFERENCES_FAILURE'
)<void, UserPreferences, HttpErrorResponse>()

export const putUserPreferencesAction = createAsyncAction(
  'PUT_USER_PREFERENCES_REQUEST',
  'PUT_USER_PREFERENCES_SUCCESS',
  'PUT_USER_PREFERENCES_FAILURE'
)<UserPreferences, UserPreferences, HttpErrorResponse>()
