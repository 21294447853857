export default () => {
  const respondInMs = 120000
  let timeOut = null

  self.addEventListener(
    'message',
    function (e) {
      switch (e.data) {
        case 'start':
          if (timeOut) {
            clearTimeout(timeOut)
          }

          timeOut = setTimeout(function () {
            self.postMessage('tick')
          }, respondInMs)
          break
        case 'stop':
          clearTimeout(timeOut)
          break
      }
    },
    false
  )
}
