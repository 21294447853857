import { Typography } from '@mui/material'
import React, { ReactNode } from 'react'
import { Badge, IconButton } from '.'
import { CommonComponent, ComponentProps } from '..'
import { CdnIcon } from '../../models'
import icons from '../../models/icons'

interface Props {
  filterCount?: number
  icon?: ReactNode
  onClick: () => void
}

const FilterToggleButton: CommonComponent<Props> = (
  props: ComponentProps<Props>
) => {
  const {
    icon = <CdnIcon icon={icons.cdnFilterIcon} />,
    filterCount,
    onClick,
  } = props

  return (
    <IconButton
      id='toggle-filter'
      color='inherit'
      onClick={onClick}
      size='large'
    >
      <Typography
        noWrap
        style={{ fontWeight: 'bold', paddingLeft: '4px', paddingRight: '8px' }}
      >
        FILTERS
      </Typography>
      <Badge badgeContent={filterCount}>{icon}</Badge>
    </IconButton>
  )
}

export default FilterToggleButton
