import { getType } from 'typesafe-actions'
import { StaffAssistAction } from '../actions'
import {
  setActiveAssistEvents,
  setAllAssistFilteredByUser,
} from '../actions/staffAssist'
import { StaffAssistState } from '../models/staffAssist'

const initialState: StaffAssistState = {
  allAssistsFilteredByUser: [],
  activeAssistEvents: [],
}

const staffAssistReducer = (
  state: StaffAssistState = initialState,
  action: StaffAssistAction
): StaffAssistState => {
  switch (action.type) {
    case getType(setAllAssistFilteredByUser):
      return {
        ...state,
        allAssistsFilteredByUser: action.payload,
      }
    case getType(setActiveAssistEvents):
      return {
        ...state,
        activeAssistEvents: action.payload,
      }
    default:
      return state
  }
}

export default staffAssistReducer
