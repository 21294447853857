import { Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { AlertActiveConfig, AlertTypes } from '../../models/alert'
import AlertChannelDetail from './AlertChannelDetail'
import AlertIdentificationDetail from './AlertIdentificationDetail'
import AlertQualifierDetail from './AlertQualifierDetail'
import AlertResolutionDetail from './AlertResolutionDetail'
import AlertRouteDetail from './AlertRouteDetail'
import AlertTriggerDetail from './AlertTriggerDetail'

interface Props {
  alert: AlertActiveConfig
  isLoading?: boolean
}

const useStyles = makeStyles((theme) =>
  createStyles({
    sectionHeader: {
      fontFamily: 'Ubuntu',
      fontSize: 20,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'left',
      color: '#000000',
      paddingBottom: 12,
    },
  })
)

function AlertDetailsPane(props: Props): React.ReactElement {
  const { alert, isLoading = false } = props

  const classes = useStyles()

  return (
    <div style={{ paddingTop: 24 }}>
      <AlertIdentificationDetail alert={alert} isLoading={isLoading} />
      <Typography className={classes.sectionHeader}>Conditions</Typography>
      <AlertTriggerDetail alert={alert} isLoading={isLoading} />
      <AlertQualifierDetail alert={alert} isLoading={isLoading} />
      <>
        <Typography className={classes.sectionHeader}>
          {alert.alertType?.id === AlertTypes.AssetAlert
            ? 'Display Method'
            : 'Channels'}
        </Typography>
        <AlertChannelDetail alert={alert} isLoading={isLoading} />
      </>
      <>
        <Typography className={classes.sectionHeader}>Routing</Typography>
        <AlertRouteDetail alert={alert} isLoading={isLoading} />
      </>
      <>
        <Typography className={classes.sectionHeader}>Resolution</Typography>
        <AlertResolutionDetail alert={alert} isLoading={isLoading} />
      </>
    </div>
  )
}

export default AlertDetailsPane
