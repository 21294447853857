import { useSelector } from 'react-redux'
import { Location, LocationsState, LocationType } from '../models'

export function useCurrentIDN(): Location | null {
  const locations = useSelector(
    ({ locations }: { locations: LocationsState }) => locations.data
  )

  return (
    Object.values(locations).find(
      (loc) => loc.locationType === LocationType.IDN
    ) ?? null
  )
}
