import React from 'react'
import {
  RealTimePageViews,
  TelemetryAsset,
  Watchlist,
} from '../../../../models'
import { DetailDrawerAgentTelemetry } from '../../../../models/telemetry'
import DetailsPaneHeader from '../../../Drawer/DetailsPaneHeader'
import AgentDetailDrawerActionButtons from './AgentDetailDrawerActionButtons'
import { AssetFilters } from '../../../../models/filters'

interface Props {
  data: DetailDrawerAgentTelemetry<TelemetryAsset>
  onClose: () => void
  watchlist: Watchlist
  pageView: RealTimePageViews
  assetFilterState: AssetFilters
  onEditAsset?: () => void
  setFilterButtonToggled: (filterButtonStates: any) => void
}

function AssetDetailPaneHeader(props: Props): React.ReactElement {
  const {
    data,
    onClose,
    onEditAsset,
    setFilterButtonToggled,
    watchlist,
    pageView,
    assetFilterState,
  } = props

  return (
    <>
      <DetailsPaneHeader
        handleClose={onClose}
        headerText={data.agent.displayName}
      />
      <AgentDetailDrawerActionButtons
        data={data}
        watchlist={watchlist}
        pageView={pageView}
        onRemovedFromWatchList={onClose}
        onEditAgent={onEditAsset}
        setFilterButtonToggled={setFilterButtonToggled}
        hasWatchListFilter={assetFilterState.isWatchlist ?? false}
      />
    </>
  )
}

export default AssetDetailPaneHeader
