import { ColumnSort } from '@tanstack/react-table'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { removeStaffMembersFromMapAction } from '../../../actions/staff'
import {
  setTelemetryCurrentMapIdAction,
  setTelemetryEnabledAction,
} from '../../../actions/telemetrySubscription'
import { getAllVenuesAction } from '../../../actions/venues'
import DeprecatedStaffDetailDrawer from '../../../components/Common/AssetStaff/DetailDrawer/DeprecatedStaffDetailDrawer'
import AssetStaffFilterDrawer from '../../../components/Common/AssetStaff/FilterDrawer/AssetStaffFilterDrawer'
import { PageContent } from '../../../components/Layout'
import { DrawerType } from '../../../components/Layout/PageContent'
import RealTimeStaffList from '../../../components/RealTimeStaffList/RealTimeStaffList'
import { useReactTableRightDrawer } from '../../../components/Tables/useReactTableRightDrawer'
import { RealTimeTableDataLimit } from '../../../constants'
import {
  RealTimeFilterStates,
  useAuth,
  useBadgeLocationMonitor,
  useCurrentFloor,
  useCurrentLocation,
  useFetchWatchlist,
  useTelemetrySubscription,
} from '../../../hooks'
import { useFetchGeofencesByLocation } from '../../../hooks/entities/useFetchGeofencesByLocation'
import useCurrentMap from '../../../hooks/useCurrentMap'
import useRealTimeStaffTableData from '../../../hooks/useRealTimeStaffTableData'
import {
  BadgeCurrentLocation,
  FetchingStatus,
  FilterType,
  LocationType,
  Locations,
  StaffFilterConfigs,
  Unit,
} from '../../../models'
import { PageLayoutState } from '../../../models/pageLayout'
import { Staff } from '../../../models/staff'
import { StaffRow } from '../../../models/staffRow'
import {
  selectStaffAssistState,
  selectStaffDuressState,
} from '../../../selectors'

interface Props {
  staffMembers: Staff[] | undefined
  staffStatus?: FetchingStatus
  locations: Locations
  locationStatus: FetchingStatus | undefined
  venueId?: number
  staffByLocation: Staff[]
  liveTelemetryEnabled: boolean
  refreshTable: boolean
  staffFilterOptions: StaffFilterConfigs
  filterStates: RealTimeFilterStates
  filterButtonToggled: boolean | undefined
  units: Unit[]
  setFilterButtonToggled: (filterButtonStates: any) => void
}

const RealTimeStaffListPage: React.FC<Props> = (props: Props) => {
  const {
    staffMembers,
    staffStatus,
    locations,
    venueId,
    staffByLocation,
    locationStatus,
    liveTelemetryEnabled,
    refreshTable,
    staffFilterOptions,
    filterButtonToggled,
    filterStates,
    units,
    setFilterButtonToggled,
  } = props
  const {
    mergeStaffFilters,
    clearAssetFilters,
    clearStaffFilters,
    staffFilterState,
  } = filterStates

  const maxHeight = useSelector(
    ({ pageLayout }: { pageLayout: PageLayoutState }) => pageLayout.maxHeight
  )

  const dispatch = useDispatch()
  const {
    rightDrawerState,
    selectRow,
    closeDetailDrawer,
    closeFilterDrawer,
    clearSelectedItem,
    resetSelectedRowIndex,
    toggleFilterDrawer,
    updateSelectedRow,
  } = useReactTableRightDrawer<StaffRow>()
  const {
    currentDrawerType,
    item: selectedRow,
    tableSelectedRowIndex,
  } = rightDrawerState
  const { user } = useAuth()
  const { data: watchlist } = useFetchWatchlist(user.id)
  const [filteredBadgeLocations, setFilteredBadgeLocations] =
    useState<BadgeCurrentLocation[]>()
  const [savedFilterSortBy, setSavedFilterSortBy] = useState<
    ColumnSort | undefined
  >()

  const { activeDuressEvents } = useSelector(selectStaffDuressState)
  const { activeAssistEvents } = useSelector(selectStaffAssistState)

  const { enabled: telemetryEnabled } = useTelemetrySubscription()
  const mapId = useCurrentMap(venueId, useCurrentFloor())

  useEffect(() => {
    dispatch(setTelemetryCurrentMapIdAction(mapId))
  }, [mapId])

  useEffect(() => {
    dispatch(getAllVenuesAction.request())
  }, [dispatch])

  const currentLocation = useCurrentLocation()
  const { data: geofences, status: geofenceStatus } =
    useFetchGeofencesByLocation(
      Object.values(locations).find((x) => x.level === LocationType.Root)?.id ??
        ''
    )

  const {
    staffTableData: tableData,
    badgeLocationsChangedAt,
    badgeLocations,
  } = useRealTimeStaffTableData(
    refreshTable,
    activeDuressEvents,
    activeAssistEvents
  )

  const allBadges = useMemo(() => {
    return staffMembers
      ? staffMembers
          .filter((staff) => !!staff.badgeIds.length)
          .map((staff) => staff.badgeIds[0])
      : []
  }, [staffMembers])

  const shouldMonitorBadgeLocation = useCallback(
    (badgeLocation: BadgeCurrentLocation): boolean => {
      return allBadges.includes(badgeLocation.badgeId)
    },
    [allBadges]
  )

  const { state: badgeLocationsState } = useBadgeLocationMonitor(
    liveTelemetryEnabled ? badgeLocations : [],
    shouldMonitorBadgeLocation
  )

  useEffect(() => {
    const mapLeaversBadges = badgeLocations?.filter((x) => x.mapId !== mapId)
    const matchedAsset = staffByLocation.find(
      (x) => x.agentGuid === selectedRow?.agentGuid
    )
    if (
      badgeLocations &&
      matchedAsset &&
      badgeLocations.some((x) => matchedAsset.badgeIds.includes(x.badgeId)) &&
      mapId
    ) {
      setFilteredBadgeLocations(
        badgeLocations.filter((x) => matchedAsset.badgeIds.includes(x.badgeId))
      )
    }
    if (mapLeaversBadges.length && mapId) {
      const mapLeavers: Staff[] = []
      if (staffMembers) {
        Object.values(staffMembers).forEach((x) => {
          if (mapLeaversBadges?.some((y) => y.badgeId === x.badgeIds[0])) {
            mapLeavers.push(x)
          }
        })
        if (mapLeavers.length) {
          dispatch(
            removeStaffMembersFromMapAction(mapLeavers.map((x) => x.agentGuid))
          )
        }
      }
    }
  }, [badgeLocations, badgeLocationsChangedAt, staffByLocation, selectedRow])

  const isFetching = useMemo(() => {
    return (
      staffStatus !== FetchingStatus.Success ||
      geofenceStatus !== FetchingStatus.Success ||
      locationStatus !== FetchingStatus.Success
    )
  }, [staffStatus, geofenceStatus, locationStatus])

  useEffect(() => {
    if (
      !isFetching &&
      tableData &&
      tableData?.length < RealTimeTableDataLimit &&
      telemetryEnabled !== liveTelemetryEnabled
    ) {
      dispatch(setTelemetryEnabledAction(liveTelemetryEnabled))
    }
  }, [
    currentLocation,
    dispatch,
    isFetching,
    liveTelemetryEnabled,
    tableData,
    telemetryEnabled,
  ])

  const clearFilters = useCallback(() => {
    clearAssetFilters()
    clearStaffFilters()
  }, [clearAssetFilters, clearStaffFilters])

  useEffect(() => {
    if (filterButtonToggled !== undefined) {
      toggleFilterDrawer()
    }
  }, [filterButtonToggled, toggleFilterDrawer])

  const handleSort = useCallback(
    (columnSorts: ColumnSort[], isSavedFilter = false) => {
      if (isSavedFilter) {
        const columnSort = columnSorts.length > 0 ? columnSorts[0] : undefined
        setSavedFilterSortBy((prevState) => {
          if (columnSort) {
            // Use JS spread operation to force state update even when the value has not been change
            // This is because we don't reset this state on sort change
            return { ...columnSort }
          } else {
            return undefined
          }
        })
      } else {
        const sortFilters = {
          columnSort: columnSorts,
          filterGuid: undefined,
        }
        mergeStaffFilters(sortFilters)
      }
    },
    [mergeStaffFilters]
  )

  const commonFilterOptions = useMemo(
    () => ({
      hideLowBatterFilter: true,
    }),
    []
  )

  const listFilterOptions = useMemo(
    () => ({
      isManagementPage: false,
      onSavedFilterChanged: handleSort,
    }),
    [handleSort]
  )

  const tableRowSelectionOptions = useMemo(
    () => ({
      selectedRow,
      selectedRowIndex: tableSelectedRowIndex,
      onSelectRow: selectRow,
      clearSelectedRow: clearSelectedItem,
      resetSelectedRowIndex,
      updateSelectedRow,
    }),
    [
      clearSelectedItem,
      resetSelectedRowIndex,
      selectRow,
      selectedRow,
      tableSelectedRowIndex,
      updateSelectedRow,
    ]
  )

  const realTimeListPageOptions = useMemo(
    () => ({
      badgeCurrentLocation: filteredBadgeLocations,
    }),
    [filteredBadgeLocations]
  )

  return (
    <PageContent
      maxHeight={maxHeight}
      currentRightDrawerType={currentDrawerType}
      content={
        <RealTimeStaffList
          isFetching={isFetching}
          activeDuressEvents={activeDuressEvents}
          activeAssistEvents={activeAssistEvents}
          tableData={tableData ?? []}
          savedFilterSortBy={savedFilterSortBy}
          onSortingChange={handleSort}
          tableRowSelectionOptions={tableRowSelectionOptions}
        />
      }
      rightDrawer={
        currentDrawerType === DrawerType.DetailDrawer && selectedRow ? (
          <DeprecatedStaffDetailDrawer
            selectedStaff={selectedRow}
            watchList={watchlist}
            activeAssistEvents={activeAssistEvents}
            activeDuressEvents={activeDuressEvents}
            isRTMapPage={false}
            staffFilterState={staffFilterState}
            onCloseDetailDrawer={closeDetailDrawer}
            realTimeListPageOptions={realTimeListPageOptions}
            setFilterButtonToggled={setFilterButtonToggled}
          />
        ) : currentDrawerType === DrawerType.FilterDrawer ? (
          <AssetStaffFilterDrawer
            onCloseDrawer={closeFilterDrawer}
            clearFilters={clearFilters}
            staffFilterOptions={staffFilterOptions}
            commonFilterOptions={commonFilterOptions}
            units={units}
            listFilterOptions={listFilterOptions}
            filterStates={filterStates}
            filterType={FilterType.RealTime}
          />
        ) : null
      }
    />
  )
}

export default RealTimeStaffListPage
