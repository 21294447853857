import { AssetCreateOrUpdate, StaffCreateOrUpdate } from '.'
import { FetchingState } from '../interfaces/fetchingState'

export interface Badge {
  badgeId: string
  assignedAgentId?: string
  batteryChangeDate?: Date
  id?: string
  badgeType: string
  badgeTypeId?: number
  vendorHardwareId?: string
  lowBattery?: boolean
  signalTypes: number[]
  assignment?: string
  agentType?: number
  createdDate?: Date
}

export interface BadgeUpdateModel {
  badgeID: string
  agentID: string
  batteryChangeDate: Date
}

export interface BadgeError {
  badgeID: string
  error: string
}

export interface Badges {
  [badgeID: string]: Badge
}

export interface CreateBadgeRequest {
  badge: Badge
  asset?: AssetCreateOrUpdate
  staff?: StaffCreateOrUpdate
  isPost?: boolean
}

export enum BadgeType {
  'Unknown',
  'Clearview 195x',
  'Asset 1979',
  'Asset Mini 1974 (XL)',
  'Remote Station 4080',
  'Remote Station 4085',
  'Remote Station 4090',
  'Asset Net 5134',
  'Test Badge - IR Only',
  'Test Badge - RF Only',
}

export type BadgesState = Readonly<FetchingState<Badges>>
