import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { SecurityRouterAction } from '../actions'
import { getAllSecurityRoutersAction } from '../actions/securityRouters'
import { SecurityRoutersApi } from '../middleware'
import { SecurityRouterState } from '../models/securityRouter'

type SecurityRouterEpic = Epic<
  SecurityRouterAction,
  SecurityRouterAction,
  SecurityRouterState,
  { securityRoutersApi: SecurityRoutersApi }
>

export const getAllSecurityRoutersEpic: SecurityRouterEpic = (
  action$,
  state,
  { securityRoutersApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllSecurityRoutersAction.request)),
    mergeMap((action) =>
      from(securityRoutersApi.getAllSecurityRouters()).pipe(
        map((securityRouters) =>
          getAllSecurityRoutersAction.success(securityRouters)
        ),
        catchError((error) => of(getAllSecurityRoutersAction.failure(error)))
      )
    )
  )
}
