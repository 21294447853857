import { IconType } from '../../models'

export function getIconWidth(iconType: IconType): number {
  switch (iconType) {
    case IconType.Group:
      return 10
    default:
      return 56
  }
}
