import { filter, mergeMap, map, catchError } from 'rxjs/operators'
import { from, of } from 'rxjs'
import { Epic } from 'redux-observable'
import { isActionOf } from 'typesafe-actions'
import { AlertAction } from '../actions'
import {
  getAllAlertsAction,
  getAlertAction,
  postAlertAction,
  putAlertAction,
  deleteAlertAction,
} from '../actions/alerts'
import { AlertsApi } from '../middleware/alerts'
import { AlertsState } from '../models/alert'

type AlertsEpic = Epic<
  AlertAction,
  AlertAction,
  AlertsState,
  { alertsApi: AlertsApi }
>
export const getAllAlertsEpic: AlertsEpic = (action$, state, { alertsApi }) => {
  return action$.pipe(
    filter(isActionOf(getAllAlertsAction.request)),
    mergeMap(() =>
      from(alertsApi.getAllAlerts()).pipe(
        map((payload) => getAllAlertsAction.success(payload)),
        catchError((error) => of(getAllAlertsAction.failure(error)))
      )
    )
  )
}

export const getAlertEpic: AlertsEpic = (action$, state, { alertsApi }) => {
  return action$.pipe(
    filter(isActionOf(getAlertAction.request)),
    mergeMap((action) =>
      from(alertsApi.getAlert(action.payload)).pipe(
        map((payload) => getAlertAction.success(payload)),
        catchError((error) => of(getAlertAction.failure(error)))
      )
    )
  )
}

export const postAlertEpic: AlertsEpic = (action$, state, { alertsApi }) => {
  return action$.pipe(
    filter(isActionOf(postAlertAction.request)),
    mergeMap((action) =>
      from(alertsApi.postAlert(action.payload)).pipe(
        map((payload) => postAlertAction.success(payload)),
        catchError((error) => of(postAlertAction.failure(error)))
      )
    )
  )
}

export const putAlertEpic: AlertsEpic = (action$, state, { alertsApi }) => {
  return action$.pipe(
    filter(isActionOf(putAlertAction.request)),
    mergeMap((action) =>
      from(alertsApi.putAlert(action.payload)).pipe(
        map((payload) => putAlertAction.success(payload)),
        catchError((error) => of(putAlertAction.failure(error)))
      )
    )
  )
}

export const deleteAlertEpic: AlertsEpic = (action$, state, { alertsApi }) => {
  return action$.pipe(
    filter(isActionOf(deleteAlertAction.request)),
    mergeMap((action) =>
      from(alertsApi.deleteAlert(action.payload)).pipe(
        map((payload) => deleteAlertAction.success(payload)),
        catchError((error) => of(deleteAlertAction.failure(error)))
      )
    )
  )
}
