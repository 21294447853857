import { createAsyncAction } from 'typesafe-actions'
import { createAction } from 'typesafe-actions'
import { User } from '../models/users'

export const acknowledgeHttpError = createAction(
  'ACK_USERS_HTTP_ERROR'
)<boolean>()

export const getCurrentUserByIdAction = createAsyncAction(
  'GET_USER_BY_ID_REQUEST',
  'GET_USER_BY_ID_SUCCESS',
  'GET_USER_BY_ID_FAILURE'
)<string, User, Error>()
