import { getAllSubUnitsAction } from '../../actions/subUnits'
import { FetchingStatus, SubUnits, SubUnitsState } from '../../models'
import { EntityResult, useEntities } from '../useEntities'

const useFetchSubUnits = (): EntityResult<SubUnits> =>
  useEntities<SubUnitsState, SubUnits>(
    () => getAllSubUnitsAction.request(),
    (state: any) => state.subUnits, // eslint-disable-line @typescript-eslint/no-explicit-any
    (status: FetchingStatus | undefined, lastRefreshTime: Date | undefined) =>
      !lastRefreshTime
  )

export { useFetchSubUnits }
