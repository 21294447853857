import { getType } from 'typesafe-actions'
import { SelectableLocationsAction } from '../actions'
import { getSelectableLocationsAction } from '../actions/selectableLocations'
import { SelectableLocations, SelectableLocationsState } from '../models'
import { FetchingStatus } from '../models/fetchingStatus'
import { setFetchingStatus } from '../utils'

const initialState: SelectableLocationsState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

export default (
  state: SelectableLocationsState = initialState,
  action: SelectableLocationsAction
): SelectableLocationsState => {
  switch (action.type) {
    case getType(getSelectableLocationsAction.success):
      return {
        data: { idn: action.payload },
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getSelectableLocationsAction.request):
      return setFetchingStatus<SelectableLocations>(
        state,
        FetchingStatus.Request
      )
    case getType(getSelectableLocationsAction.failure):
      if (action.payload.status === 404) {
        return {
          data: { idn: undefined },
          status: FetchingStatus.Success,
        }
      }
      return setFetchingStatus<SelectableLocations>(
        state,
        FetchingStatus.Failure
      )

    default:
      return state
  }
}
