import React from 'react'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ChevronRight from '@mui/icons-material/ChevronRight'
import Typography from '@mui/material/Typography'
import { Chip } from '@mui/material'

interface Props {
  field?: string | number | React.ReactNode
  value?: string | number | React.ReactNode
  action?: () => void
  chips?: string[]
  classes: Record<
    | 'list'
    | 'field'
    | 'row'
    | 'chip'
    | 'text'
    | 'gridItemRight'
    | 'chipChevron'
    | 'chevronContainer',
    string
  >
}

// This might/will need some more tweaks
const ActionListItem = (props: Props): JSX.Element => {
  const { field, value, action, chips, classes } = props

  if (chips && chips.length > 0) {
    return (
      <ListItem disableGutters divider button onClick={action}>
        <Grid container>
          <Grid item xs={11}>
            <List className={classes.list} disablePadding>
              <ListItem>
                <ListItemText primary={field} />
                <ListItemSecondaryAction>
                  <div className={classes.row}>
                    <Typography className={classes.text}>{value}</Typography>
                  </div>
                </ListItemSecondaryAction>
              </ListItem>
              {chips && chips.length > 0 && (
                <ListItem>
                  <div>
                    {/*This div is here to make the chips wrap rather than overflow to the right */}
                    {chips.map((chip, i) => (
                      <Chip
                        key={`chip-${i}`}
                        className={classes.chip}
                        label={chip}
                      />
                    ))}
                  </div>
                </ListItem>
              )}
            </List>
          </Grid>
          <Grid
            item
            xs={1}
            className={classes.gridItemRight}
            direction='row-reverse'
          >
            <div
              className={`${classes.chipChevron} ${classes.chevronContainer}`}
            >
              <ChevronRight />
            </div>
          </Grid>
        </Grid>
      </ListItem>
    )
  }

  return (
    <ListItem divider button onClick={action}>
      <div className={classes.row}>
        <div className={classes.field}>
          <Typography className={classes.text}>{field}</Typography>
        </div>
        <div>
          {typeof value === 'string' || typeof value === 'number' ? (
            <div className={classes.chevronContainer}>
              <Typography className={classes.text}>{value}</Typography>
              <ChevronRight />
            </div>
          ) : (
            value
          )}
        </div>
      </div>
    </ListItem>
  )
}

export default ActionListItem
