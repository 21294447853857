import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { Location } from '../../../models'
import QuickActionCard, { IconType } from '../../Cards/QuickActionCard'
import DetailsPaneHeader from '../../Drawer/DetailsPaneHeader'

interface Props {
  selectedRoom: Location
  onClose: () => void
  onEditRoom: () => void
  onViewRoomSensors: () => void
  canEdit: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    detailPanelBody: {
      zIndex: 999,
      width: '100%',
    },
    quickActionButtonBody: {
      display: 'flex',
      justifyContent: 'center',
      margin: 12,
    },
    quickActionButtonLeft: {
      paddingRight: '20px',
    },
    detailListBody: {
      paddingTop: '28px',
    },
  })
)

function LocationDetailPaneHeader(props: Props): React.ReactElement {
  const { selectedRoom, onClose, onEditRoom, onViewRoomSensors, canEdit } =
    props

  const classes = useStyles()

  return (
    <>
      <DetailsPaneHeader
        handleClose={onClose}
        headerText={`${selectedRoom.name}`}
      />
      <div className={classes.quickActionButtonBody}>
        <div className={classes.quickActionButtonLeft}>
          <QuickActionCard
            cardText='Edit Room Information'
            cardIconType={IconType.EditOutlined}
            onActionAreaClick={onEditRoom}
            isDisabled={!canEdit}
          />
        </div>
        <div>
          <QuickActionCard
            cardText='View Room Sensors'
            cardIconType={IconType.Memory}
            onActionAreaClick={onViewRoomSensors}
          />
        </div>
      </div>
    </>
  )
}

export default LocationDetailPaneHeader
