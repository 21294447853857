import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FilterRequest, StaffState } from '../../models'
import { getPaginatedStaffAction } from '../../actions/staff'
import { buildPaginatedParams } from '../../helpers'
import { useSearchFilter, useStaffFilters } from '..'
import { shouldDispatch } from '../../helpers/shouldDispatch'
import { batteryStatusSearch } from '../../filters'
import { staffFilterMap } from '../../helpers/filterMap/staffFilterMap'

const useFetchPaginatedStaff = (
  filterRequest: FilterRequest,
  resetPageIndex: () => void,
  shouldRefresh = false
) => {
  const dispatch = useDispatch()
  const [cachedPage, setCachedPage] = useState(0)
  const [cachedSize, setCachedSize] = useState(0)
  const [staffState, setStaffState] = useState<unknown>({})
  const [searchState, setSearchState] = useState<string | undefined>(undefined)

  const {
    status,
    staffByLocationLastRefreshTime,
    data: staff,
    recordCount,
    page,
    nextLink,
    prevLink,
  } = useSelector(({ staff }: { staff: StaffState }) => staff)

  const { filter: searchFilter } = useSearchFilter(
    [
      'agentGuid',
      'badgeIds',
      'staffTypeId',
      'iconUrl',
      'badgeTelemetry',
      'icon',
      'badgeLocation',
      'venueId',
      'actions',
      'signalTypeIds',
    ],
    {
      tagBatteryChangeDate: 'datetime',
      isLowBattery: batteryStatusSearch,
    }
  )

  const { filterState: staffFilters } = useStaffFilters()

  useEffect(() => {
    let requestNewData = false
    //Check for page change
    if (filterRequest.page !== cachedPage) {
      setCachedPage(filterRequest.page)
      requestNewData = true
    }
    //check for page size change
    if (filterRequest.size !== cachedSize) {
      setCachedSize(filterRequest.size)
      requestNewData = true
    }
    //check for filter change
    if (JSON.stringify(staffState) !== JSON.stringify(staffFilters)) {
      setStaffState(staffFilters)
      requestNewData = true
      filterRequest.page = 1 //Reset Page if filter changes
      resetPageIndex()
    }
    //Check for search change
    if (searchState !== searchFilter.value) {
      setSearchState(searchFilter.value)
      requestNewData = true
      filterRequest.page = 1 //Reset Page if search changes
      resetPageIndex()
    }
    //If change detected, Dispatch for requested data
    if (
      requestNewData ||
      shouldDispatch(status, staffByLocationLastRefreshTime) ||
      shouldRefresh
    ) {
      filterRequest.search = searchFilter.value
      filterRequest.lowRTLSBattery = staffFilters?.isLowBattery

      if (staffFilters?.tagBatteryChangeDate) {
        filterRequest.rtlsBatteryChangeDate = new Date(
          staffFilters?.tagBatteryChangeDate
        ).toDateString()
      }

      filterRequest.hasBadge = staffFilters?.hasBadge
      filterRequest.onWatchlist = staffFilters?.isWatchlist
      filterRequest.rtlsSignalType = staffFilters?.signalType?.map(
        (x: any) => x.id
      )
      filterRequest.staffFilterRequest = staffFilterMap(staffFilters)
      if (staffFilters.columnSort?.length) {
        if (staffFilters.columnSort[0].id === 'badgeNumber') {
          filterRequest.sortBy = 'badgeIds'
        } else {
          filterRequest.sortBy = staffFilters.columnSort[0].id
        }
        filterRequest.sortOrderDesc = staffFilters.columnSort[0].desc
      }
      dispatch(
        getPaginatedStaffAction.request({
          filters: buildPaginatedParams(filterRequest),
        })
      )
    }
  }, [
    staffByLocationLastRefreshTime,
    dispatch,
    status,
    shouldRefresh,
    filterRequest,
    staffFilters,
    searchFilter,
    cachedPage,
    cachedSize,
    staffState,
    searchState,
  ])

  return {
    staff,
    status,
    recordCount,
    page,
    nextLink,
    prevLink,
  }
}

export { useFetchPaginatedStaff }
