import { Trigger } from '../models/trigger'
import { BaseService } from './baseService'

export class TriggersApi extends BaseService {
  constructor() {
    super({ entityType: 'triggers' })
  }
  route = 'alert/triggers'

  async getAllTriggers(): Promise<Trigger[]> {
    return await this.get<Trigger[]>(this.route)
  }
}
