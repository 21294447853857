export type CssSize = number | string

export type SpacingStyles = { [key: string]: CssSize | undefined }

const createSpacingStyles = (
  styleName: string,
  top?: CssSize,
  right?: CssSize,
  bottom?: CssSize,
  left?: CssSize
): SpacingStyles => {
  return {
    [`${styleName}Top`]: top,
    [`${styleName}Right`]: right,
    [`${styleName}Bottom`]: bottom,
    [`${styleName}Left`]: left,
  }
}

const spacingShorthand = (
  styleName: string,
  ...styles: CssSize[]
): SpacingStyles => {
  switch (styles.length) {
    case 2:
      return createSpacingStyles(
        styleName,
        styles[0],
        styles[1],
        styles[0],
        styles[1]
      )
    case 3:
      return createSpacingStyles(
        styleName,
        styles[0],
        styles[1],
        styles[2],
        styles[1]
      )
    case 4:
      return createSpacingStyles(
        styleName,
        styles[0],
        styles[1],
        styles[2],
        styles[3]
      )
  }
  return { [styleName]: styles[0] }
}

export const padding = (...styles: CssSize[]): SpacingStyles =>
  spacingShorthand('padding', ...styles)
export const margin = (...styles: CssSize[]): SpacingStyles =>
  spacingShorthand('margin', ...styles)
