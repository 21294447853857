import _ from 'lodash'
import { getType } from 'typesafe-actions'
import { ModelAction } from '../actions'
import { getAllModelsAction } from '../actions/models'
import { ModelsState, StringObj } from '../models'
import { FetchingStatus } from '../models/fetchingStatus'
import { setFetchingStatus } from '../utils'

const initialState: ModelsState = {
  data: [],
  status: undefined,
  lastRefreshTime: undefined,
}

export default (
  state: ModelsState = initialState,
  action: ModelAction
): ModelsState => {
  switch (action.type) {
    case getType(getAllModelsAction.success):
      return {
        data: action.payload.flatMap((x, index) => ({ id: index, name: x })),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }

    case getType(getAllModelsAction.request):
      return setFetchingStatus<StringObj[]>(state, FetchingStatus.Request)
    case getType(getAllModelsAction.failure):
      return setFetchingStatus<StringObj[]>(state, FetchingStatus.Failure)

    default:
      return state
  }
}
