import { Grid, Typography } from '@mui/material'
import { StyleRules, WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import React, { ReactNode } from 'react'
import { colors, tableColors, withChildStyles } from '../../styles'

const styles = createStyles({
  root: {
    paddingRight: 24,
    paddingLeft: 24,
    paddingTop: 24,
    paddingBottom: 8,
    background: tableColors.background,
    // ...flexStyles.flexNone
  },
  actions: {
    marginLeft: 16,
    paddingRight: 8,
    ...withChildStyles({
      marginLeft: 0,
      marginRight: 0,
    }),
  },
} as StyleRules)

export interface TableToolbarOptions {
  title?: string
  subTitle?: string
  search?: boolean
  // IDEA: Change node to a FilterAction interface that has toolbar content, filter content and filter functionality
  actions?: ReactNode[]
  tableSettingsCustomizeFormOpen?: () => void
}

interface Props extends TableToolbarOptions, WithStyles<typeof styles> {}

const TableToolbar: React.FC<Props> = (props: Props) => {
  const { title, subTitle, classes, actions, tableSettingsCustomizeFormOpen } =
    props

  return (
    <Grid
      container
      direction='row'
      alignContent='center'
      alignItems='center'
      justifyContent='space-between'
      className={classes.root}
    >
      <Grid item>
        <Grid
          container
          direction='row'
          alignContent='center'
          alignItems='center'
          justifyContent='space-between'
        >
          <Grid item>
            <Grid container flex='column' spacing={2} alignItems='end'>
              <Grid item>
                <Typography variant='h5'>{title}</Typography>
              </Grid>
              <Grid item>
                <Typography>{subTitle}</Typography>
              </Grid>
            </Grid>
          </Grid>
          {tableSettingsCustomizeFormOpen && (
            <Grid item>
              <Typography
                onClick={tableSettingsCustomizeFormOpen}
                style={{
                  color: colors.primary,
                  cursor: 'pointer',
                  fontFamily: 'Ubuntu',
                  fontSize: '15px',
                  fontWeight: 'normal',
                  textTransform: 'none',
                  padding: '6px 8px 0px 8px',
                }}
              >
                Customize
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <Grid
          container
          direction='row'
          alignItems='center'
          justifyContent='flex-end'
          className={classes.actions}
        >
          <>
            {actions &&
              actions.map((action, i) => (
                <React.Fragment key={`TableAction-${i}`}>
                  {action}
                </React.Fragment>
              ))}
          </>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles, { name: 'MidmarkTableToolbar' })(TableToolbar)
