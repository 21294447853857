import React, { useCallback, useEffect, useState } from 'react'
import { TextField } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import {
  Location,
  LocationType,
  TimeDisplayPreference,
  TimeDisplayPreferences,
  Unit,
  UserPreferences,
} from '../../models'
import { Autocomplete } from '@mui/material'
import { useFormStyle } from '../../styles'
import UserProfileCard from './UserProfileCard'

const useStyles = makeStyles(() =>
  createStyles({
    formContent: {
      display: 'flex',
      flexDirection: 'column',
      width: '397px',
    },
    autocomplete: {
      flex: 1,
    },
  })
)

interface Props {
  userPreferences: UserPreferences
  locations: Location[]
  units: Unit[]
  handleUpdateLocationPreference: (locationId: string | undefined) => void
  handleUpdateUnitPreference: (unitId: string | undefined) => void
  handleUpdateTimeDisplayPreference: (timeDisplayId: number) => void
  style?: React.CSSProperties
}

const UserPreferencesCard: React.FC<Props> = (props: Props) => {
  const {
    userPreferences,
    locations,
    units,
    handleUpdateLocationPreference,
    handleUpdateUnitPreference,
    handleUpdateTimeDisplayPreference,
    style,
  } = props
  const classes = useStyles()
  const formClasses = useFormStyle()

  const [buildingGroups, setBuildingGroups] = useState<Location[]>([])
  const [selectedBuildingGroup, setSelectedBuildingGroup] =
    useState<Location | null>()
  const [buildings, setBuildings] = useState<Location[]>([])
  const [selectedBuilding, setSelectedBuilding] = useState<Location | null>()
  const [floors, setFloors] = useState<Location[]>([])
  const [selectedFloor, setSelectedFloor] = useState<Location | null>()
  const [unitsToSelect, setUnitsToSelect] = useState<Unit[]>([])
  const [selectedUnit, setSelectedUnit] = useState<Unit>()
  const [selectedTimeDisplay, setSelectedTimeDisplay] =
    useState<TimeDisplayPreference>()

  const title = 'Preference Defaults'

  const createBuildingsList = useCallback(
    (buildingGroupId: string | undefined): void => {
      if (buildingGroupId) {
        const buildingList = locations
          .filter(
            (x) =>
              x.locationType == LocationType.Building &&
              x.parentId == buildingGroupId
          )
          .sort((a, b) => (a.name > b.name ? 1 : -1))
        setBuildings(buildingList)
        setSelectedFloor(null)
      }
    },
    [locations]
  )

  const createFloorsList = useCallback(
    (buildingId: string | undefined): void => {
      if (buildingId) {
        const floorList = locations
          .filter(
            (x) =>
              x.locationType == LocationType.Floor && x.parentId == buildingId
          )
          .sort((a, b) => (a.name > b.name ? 1 : -1))
        const allFloors = { name: 'All Floors', id: '' } as Location
        floorList.unshift(allFloors)
        setFloors(floorList)
        setSelectedFloor(allFloors)
      }
    },
    [locations]
  )

  const createUnitsList = useCallback(
    (unitId: string | undefined): void => {
      if (units) {
        const unitList = units.sort((a, b) => (a.name > b.name ? 1 : -1))
        const allUnits = { name: 'All Units', id: '' } as Unit
        unitList.unshift(allUnits)
        setUnitsToSelect(unitList)

        if (unitId) {
          setSelectedUnit(unitList.find((x) => x.id == unitId))
        } else {
          setSelectedUnit(allUnits)
        }
      }
    },
    [units]
  )

  useEffect(() => {
    if (locations) {
      const buildingGroupList = locations
        .filter((x) => x.locationType == LocationType.BuildingGroup)
        .sort((a, b) => (a.name > b.name ? 1 : -1))
      setBuildingGroups(buildingGroupList)

      const preferredLocation = locations.find(
        (x) => x.id == userPreferences.locationId
      )

      if (preferredLocation) {
        let floor: Location | undefined
        let building: Location | undefined

        if (preferredLocation.locationType == LocationType.Floor) {
          floor = preferredLocation
          building = locations.find((x) => x.id == floor?.parentId)
        } else if (preferredLocation.locationType == LocationType.Building) {
          building = preferredLocation
        }

        const buildingGroupId = building
          ? building?.parentId
          : preferredLocation.id
        const buildingGroup = locations.find((x) => x.id == buildingGroupId)

        setSelectedBuildingGroup(buildingGroup)
        createBuildingsList(buildingGroup?.id)
        setSelectedBuilding(building)
        createFloorsList(building?.id)

        if (floor) {
          setSelectedFloor(floor)
        }
      } else {
        setSelectedBuildingGroup(null)
        setSelectedBuilding(null)
        setSelectedFloor(null)
        setBuildings([])
        setFloors([])
      }
    }
  }, [userPreferences, locations, createBuildingsList, createFloorsList])

  useEffect(() => {
    if (units) {
      createUnitsList(userPreferences.unitId)
    }
  }, [units])

  useEffect(() => {
    setSelectedTimeDisplay(
      TimeDisplayPreferences[userPreferences.timeDisplayId]
    )
  }, [userPreferences])

  return (
    <div style={style}>
      <UserProfileCard title={title}>
        <div className={classes.formContent}>
          <Autocomplete
            className={classes.autocomplete}
            value={selectedBuildingGroup ?? null}
            onChange={(event, newValue) => {
              setSelectedFloor(null)
              setFloors([])
              setSelectedBuilding(null)
              setBuildings([])

              if (newValue == null) {
                setSelectedBuildingGroup(null)
                handleUpdateLocationPreference(undefined)
                return
              }

              const buildingGroup = newValue as Location
              setSelectedBuildingGroup(buildingGroup)
              createBuildingsList(buildingGroup.id)
              handleUpdateLocationPreference(buildingGroup.id)
            }}
            options={buildingGroups}
            getOptionLabel={(option) => {
              return option.name ?? ''
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            renderOption={(props, option, { selected }) => (
              <li {...props}>{option.name}</li>
            )}
            style={{ width: '100%' }}
            renderInput={(params) => (
              <TextField
                {...params}
                margin='normal'
                label='Building Group'
                variant='filled'
                className={formClasses.inputStyle}
              />
            )}
          />
          <Autocomplete
            className={classes.autocomplete}
            value={selectedBuilding ?? null}
            disabled={selectedBuildingGroup == null}
            onChange={(event, newValue) => {
              if (newValue == null) {
                setSelectedFloor(null)
                setFloors([])
                const emptyBuilding = {} as Location
                setSelectedBuilding(emptyBuilding)
                handleUpdateLocationPreference(selectedBuildingGroup!.id)
                return
              }

              const building = newValue as Location
              setSelectedBuilding(building)
              handleUpdateLocationPreference(building.id)
            }}
            options={buildings}
            getOptionLabel={(option) => {
              return option.name ?? ''
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            renderOption={(props, option, { selected }) => (
              <li {...props}>{option.name}</li>
            )}
            style={{ width: '100%' }}
            renderInput={(params) => (
              <TextField
                {...params}
                margin='normal'
                label='Building'
                variant='filled'
                className={formClasses.inputStyle}
              />
            )}
          />
          <Autocomplete
            className={classes.autocomplete}
            value={selectedFloor ?? null}
            disabled={selectedBuilding == null}
            onChange={(event, newValue) => {
              if (newValue == null) {
                const emptyFloor = {} as Location
                setSelectedFloor(emptyFloor)
                handleUpdateLocationPreference(selectedBuilding!.id)
                return
              }

              const floor = newValue as Location
              if (floor.id == '') {
                floor.id = selectedBuilding?.id ?? ''
              }
              handleUpdateLocationPreference(floor.id)
            }}
            options={floors}
            getOptionLabel={(option) => {
              return option.name ?? ''
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            renderOption={(props, option, { selected }) => (
              <li {...props}>{option.name}</li>
            )}
            style={{ width: '100%' }}
            renderInput={(params) => (
              <TextField
                {...params}
                margin='normal'
                label='Floor'
                variant='filled'
                className={formClasses.inputStyle}
              />
            )}
          />
          <Autocomplete
            className={classes.autocomplete}
            value={selectedUnit ?? null}
            onChange={(event, newValue) => {
              if (newValue === null) {
                const allUnits = unitsToSelect.find(function (unit) {
                  return unit.id == ''
                })
                setSelectedUnit(allUnits)
                handleUpdateUnitPreference(undefined)
                return
              }

              const unit = newValue as Unit
              handleUpdateUnitPreference(unit.id ?? undefined)
            }}
            options={unitsToSelect}
            getOptionLabel={(option) => {
              return option.name ?? ''
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            renderOption={(props, option, { selected }) => (
              <li {...props}>{option.name}</li>
            )}
            style={{ width: '100%' }}
            renderInput={(params) => (
              <TextField
                {...params}
                margin='normal'
                label='Unit'
                variant='filled'
                className={formClasses.inputStyle}
              />
            )}
          />
          <Autocomplete
            className={classes.autocomplete}
            value={selectedTimeDisplay ?? null}
            onChange={(event, newValue) => {
              const timeDisplay = newValue as TimeDisplayPreference
              setSelectedTimeDisplay(timeDisplay)
              handleUpdateTimeDisplayPreference(timeDisplay.id ?? undefined)
            }}
            options={TimeDisplayPreferences}
            getOptionLabel={(option) => {
              return option.name ?? ''
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            renderOption={(props, option, { selected }) => (
              <li {...props}>{option.name}</li>
            )}
            style={{ width: '100%' }}
            renderInput={(params) => (
              <TextField
                {...params}
                margin='normal'
                label='Time'
                variant='filled'
                className={formClasses.inputStyle}
              />
            )}
          />
        </div>
      </UserProfileCard>
    </div>
  )
}

export default UserPreferencesCard
