import { getWatchlistAction } from '../../actions/watchlists'
import { Watchlist, WatchlistState, FetchingStatus } from '../../models'
import { EntityResult, useEntities } from '../useEntities'

const useFetchWatchlist = (userId: string): EntityResult<Watchlist> =>
  useEntities<WatchlistState, Watchlist>(
    () => getWatchlistAction.request(userId),
    (state: any) => state.watchlist, // eslint-disable-line @typescript-eslint/no-explicit-any
    (status: FetchingStatus | undefined, lastRefreshTime: Date | undefined) =>
      !lastRefreshTime
  )

export { useFetchWatchlist }
