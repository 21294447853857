export function getCoordinates(
  lat: number,
  lon: number,
  jibestream: any
): [number, number] {
  return jibestream.control.jungle.util.convertCoordinate(
    [lon, lat],
    'EPSG:4326',
    'jmap:local',
    jibestream.control.activeVenue
  )
}
