import CloseIcon from '@mui/icons-material/Close'
import ReorderIcon from '@mui/icons-material/Reorder'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Column } from '@tanstack/react-table'
import React, { useRef, useState } from 'react'
import { TableColumnSetting, TableColumnSettings } from '../../models'
import { colors } from '../../styles/MidmarkTheme'
import { CancelButton, SaveButton } from '../Buttons/Button'
import {
  getDefaultTableColumnSettings,
  mapColumnStateToTableColumnSettings,
} from '../../helpers/reactTable'

interface Props<T> {
  handleClose: () => void
  getCurrentColumns: () => Column<T>[]
  columnOrderingState: string[]
  handleSave: (settings: TableColumnSettings, saveDefault: boolean) => void
}

const useStyles = makeStyles(() =>
  createStyles({
    dialogTitle: {
      padding: '0 0 0 16px',
    },
    dialogContent: {
      padding: 0,
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      color: 'black',
      fontFamily: 'Ubuntu',
      fontSize: '12px',
      fontWeight: 'bold',
      width: '380px',
      verticalAlign: 'center',
    },
    headerLeftContainer: {
      display: 'flex',
    },
    headerLeftContainerText: {
      margin: 'auto',
    },
    listItem: {
      paddingLeft: '14px',
    },
    listItemText: {
      color: 'black',
      fontFamily: 'Ubuntu',
      fontSize: '16px',
      cursor: 'move',
    },
    listItemIcon: {
      padding: 0,
      minWidth: '32px',
    },
    listItemSecondaryAction: {
      cursor: 'move',
    },
    footerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    link: {
      color: colors.primary,
      fontFamily: 'Ubuntu',
      fontSize: '12px',
    },
    checkbox: {
      outlineColor: colors.primary,
      borderColor: colors.primary,
      alignContent: 'left',
      fontFamily: 'Ubuntu',
      fontSize: '16px',
      padding: 0,
    },
    closeButton: {
      verticalAlign: 'bottom',
      height: '45px',
      border: '0',
      paddingLeft: '16px',
      paddingRight: '16px',
    },
    paper: {
      margin: 'auto',
    },
  })
)

const TableColumnCustomizationModal = <T extends unknown>(
  props: Props<T>
): JSX.Element => {
  const { handleClose, getCurrentColumns, columnOrderingState, handleSave } =
    props

  const [currentColumnSettings, setCurrentColumnSettings] = useState<
    TableColumnSetting[]
  >(() => {
    const columns = getCurrentColumns()
    const columnSettings = mapColumnStateToTableColumnSettings(
      columns,
      columnOrderingState
    )
    return Object.values(columnSettings)
  })
  const [saveDefault, setSaveDefault] = useState<boolean>(false)
  const classes = useStyles()

  const onSave = () => {
    const newListSettings = currentColumnSettings.reduce(
      (acc: TableColumnSettings, item, index: number): TableColumnSettings => {
        return {
          ...acc,
          [item.columnName]: {
            ...item,
            order: index,
            show: item.show,
          },
        }
      },
      {} as TableColumnSettings
    )

    handleSave(newListSettings, saveDefault)
    handleClose()
  }

  const handleCheckboxToggled = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const t = currentColumnSettings.findIndex(
      (x) => (x.columnName ?? -1).toString() === event.target.value
    )
    currentColumnSettings[t].show = event.target.checked
    setCurrentColumnSettings(currentColumnSettings.slice())
    setSaveDefault(false)
    return true
  }

  const resetToDefault = () => {
    const columns = getCurrentColumns()
    const defaultColumnSettings = getDefaultTableColumnSettings(columns)
    setCurrentColumnSettings(Object.values(defaultColumnSettings))
    setSaveDefault(true)
  }

  const draggingItem = useRef<number>()
  const dragOverItem = useRef<number>()

  const handleDragStart = (
    e: React.DragEvent<HTMLDivElement>,
    position: number
  ) => {
    if (
      position < currentColumnSettings.length &&
      currentColumnSettings[position].canModify
    ) {
      draggingItem.current = position
    } else {
      draggingItem.current = undefined
    }
  }

  const handleDragEnter = (
    e: React.DragEvent<HTMLDivElement>,
    position: number
  ) => {
    dragOverItem.current = position
    if (
      draggingItem.current !== undefined &&
      dragOverItem.current !== undefined
    ) {
      const columnSettingsClone = [...currentColumnSettings]
      const dragOverItemContent = columnSettingsClone[dragOverItem.current]
      if (dragOverItemContent.canModify) {
        const draggingItemContent = columnSettingsClone[draggingItem.current]

        columnSettingsClone.splice(draggingItem.current, 1)
        columnSettingsClone.splice(dragOverItem.current, 0, draggingItemContent)

        draggingItem.current = dragOverItem.current
        dragOverItem.current = undefined
        setCurrentColumnSettings(columnSettingsClone)
        setSaveDefault(false)
      }
    }
  }

  return (
    <Dialog open className={classes.paper} onClose={handleClose}>
      <DialogTitle className={classes.dialogTitle}>
        <div className={classes.headerContainer}>
          <div className={classes.headerLeftContainer}>
            <div className={classes.headerLeftContainerText}>
              CUSTOMIZE COLUMNS
            </div>
            <Button
              onClick={(): void => resetToDefault()}
              className={classes.link}
              style={{ textTransform: 'none' }}
            >
              Reset to Default
            </Button>
          </div>
          <IconButton
            aria-label='Close'
            className={classes.closeButton}
            onClick={handleClose}
            size='large'
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <List dense>
          {currentColumnSettings.map((column, index) => {
            return (
              <div
                key={index}
                draggable={column.canModify}
                onDragStart={(e) => handleDragStart(e, index)}
                onDragOver={(e) => e.preventDefault()}
                onDragEnter={(e) => handleDragEnter(e, index)}
              >
                <ListItem data-position={index} className={classes.listItem}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <Checkbox
                      value={column.columnName ?? -1}
                      onChange={(e) => handleCheckboxToggled(e)}
                      checked={column.show}
                      color='primary'
                      className={classes.checkbox}
                      disabled={!column.canModify}
                    />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    className={classes.listItemText}
                  >
                    {column.columnDisplayName}
                  </ListItemText>
                  <ListItemSecondaryAction
                    className={classes.listItemSecondaryAction}
                  >
                    <ReorderIcon />
                  </ListItemSecondaryAction>
                </ListItem>
              </div>
            )
          })}
        </List>
      </DialogContent>
      <DialogActions className={classes.footerContainer}>
        <CancelButton onClick={(): void => handleClose()} />
        <SaveButton onClick={(): void => onSave()} />
      </DialogActions>
    </Dialog>
  )
}

export default TableColumnCustomizationModal
