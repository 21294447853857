import React, { useCallback, useEffect, useState } from 'react'
import { PageLayout } from '../../components/Layout'
import AlertManagementSelect from '../../components/Selects/AlertManagementSelect'
import { isCurrentLocationOrChild } from '../../helpers'
import {
  useCurrentLocation,
  useFetchLocations,
  useFetchUnits,
  useFetchUserPreferences,
} from '../../hooks'
import { useFetchAlerts } from '../../hooks/entities/useFetchAlerts'
import useAlertFilters from '../../hooks/filters/useAlertFilters'
import { FetchingStatus } from '../../models'
import { Alert, Alerts, defaultAlertActiveConfig } from '../../models/alert'
import { QualifierType } from '../../models/alertQualifier'
import { mergeRecords } from '../../utils'
import AlertAdminToolbar from './AlertAdminToolbar'
import AlertListPage from './AlertListPage'
import useAlertActiveConfig from './useAlertActiveConfig'

const AlertManagementPage = (): JSX.Element => {
  const { formattedAlerts, alertsFetchingStatus } = useFetchAlerts()
  const {
    filterCount,
    filter: alertFilterQuery,
    filterState: alertFilterState,
    setFilterState: setAlertFilters,
  } = useAlertFilters()

  const { data: units, status: unitsStatus } = useFetchUnits()

  const [unitSelectOptions, setUnitSelectOptions] = useState<string[]>([
    'All Units',
    ...Object.values(units).map((unit) => unit.name),
  ])
  const [selectedUnit, setSelectedUnit] = useState<string>(unitSelectOptions[0])
  const [alertsByUnit, setAlertsByUnit] = useState<Alerts>(formattedAlerts)
  const [activeStep, setActiveStep] = useState<number>(() => {
    const saved = localStorage.getItem('alertStep') || '0'
    const initialValue = JSON.parse(saved)
    return initialValue || 0
  })
  const [filterButtonToggled, setFilterButtonToggled] = useState<
    boolean | undefined
  >(undefined)

  const { currentLocation } = useCurrentLocation()
  const { data: locations } = useFetchLocations()

  const { setActiveConfig } = useAlertActiveConfig()

  // Set unit to user's preferred unit
  const { data: userPreferences, status: userPreferencesStatus } =
    useFetchUserPreferences()
  const [unitInitialized, setUnitInitialized] = useState(false)

  useEffect(() => {
    if (
      userPreferencesStatus === FetchingStatus.Success &&
      unitsStatus === FetchingStatus.Success &&
      userPreferences.unitId &&
      !unitInitialized
    ) {
      const userPreferenceUnitName = Object.values(units).find(
        (unit) => unit.id === userPreferences.unitId
      )?.name

      if (userPreferenceUnitName) {
        setSelectedUnit(userPreferenceUnitName)
      }
      setUnitInitialized(true)
    }
  }, [userPreferencesStatus, unitsStatus])
  // End of setting unit to user's preferred unit

  useEffect(() => {
    setActiveConfig(defaultAlertActiveConfig)
  }, [])

  useEffect(() => {
    localStorage.setItem('alertStep', JSON.stringify(activeStep))
  }, [activeStep])

  const filterAlertsByLocation = (alerts: Alerts): Alerts => {
    let filteredAlerts: Alerts = {}

    const locationFilteredAlerts = Object.values(alerts).filter((alert) => {
      const locationIdToPass =
        alert.locationQualifier?.floor?.id ??
        alert.locationQualifier?.building?.id ??
        alert.locationQualifier?.campus?.id ??
        ''

      return isCurrentLocationOrChild(
        locationIdToPass,
        currentLocation,
        locations
      )
    })

    filteredAlerts = mergeRecords<Alerts, Alert, 'id'>(
      filteredAlerts,
      locationFilteredAlerts,
      'id'
    )

    return filteredAlerts
  }

  const filterAlertsByUnit = (alerts: Alerts): Alerts => {
    let filteredAlerts: Alerts = {}

    const filteredAlertList = Object.values(alerts).filter((alert) => {
      const selectedUnitId = Object.values(units).find(
        (unit) => unit.name === selectedUnit
      )?.id
      if (selectedUnitId) {
        return alert.qualifiers?.find(
          (qualifier) =>
            qualifier.qualifierTypeId === QualifierType.Unit &&
            qualifier.ruleValue.toLowerCase() === selectedUnitId.toLowerCase()
        )
      }
      return []
    })

    filteredAlerts = mergeRecords<Alerts, Alert, 'id'>(
      filteredAlerts,
      filteredAlertList,
      'id'
    )

    return filteredAlerts
  }

  useEffect(() => {
    if (!selectedUnit) {
      return
    }

    if (selectedUnit === 'All Units') {
      const locationFilteredAlerts = filterAlertsByLocation(formattedAlerts)
      setAlertsByUnit(locationFilteredAlerts)
      return
    }

    const unitFilteredAlerts = filterAlertsByUnit(formattedAlerts)
    const locationFilteredAlerts = filterAlertsByLocation(unitFilteredAlerts)
    setAlertsByUnit(locationFilteredAlerts)
  }, [formattedAlerts, selectedUnit, currentLocation])

  useEffect(() => {
    setUnitSelectOptions([
      'All Units',
      ...Object.values(units).map((unit) => unit.name),
    ])
  }, [units])

  const handleListChange = useCallback((value: string) => {
    setSelectedUnit(value)
  }, [])

  return (
    <PageLayout>
      <AlertAdminToolbar
        filterCount={filterCount}
        onFilterButtonClicked={() =>
          setFilterButtonToggled((prevState) => !prevState)
        }
        unitSelector={
          <AlertManagementSelect
            onSelectChange={handleListChange}
            currentView={selectedUnit}
            units={unitSelectOptions}
          ></AlertManagementSelect>
        }
      ></AlertAdminToolbar>
      <AlertListPage
        alerts={alertsByUnit}
        alertsStatus={alertsFetchingStatus}
        setActiveStep={setActiveStep}
        alertFilterQuery={alertFilterQuery}
        alertFilterState={alertFilterState}
        setAlertFilters={setAlertFilters}
        filterButtonToggled={filterButtonToggled}
      />
    </PageLayout>
  )
}

export default AlertManagementPage
