import React from 'react'
import midmarkLogo from '../assets/Midmark-logo-48.png'
import { LinearProgress } from '@mui/material'

const LoadingPageFull: React.FC = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      flexDirection: 'column',
    }}
  >
    <div>
      <img src={midmarkLogo} width='72' alt='logo' />
    </div>
    <div>
      <LinearProgress style={{ marginTop: 10, width: 100 }} />
    </div>
  </div>
)

export { LoadingPageFull }
