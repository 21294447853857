import { Autocomplete, TextField, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { setRouterPromptIsEnabledStatusAction } from '../../../../actions/routerPrompt'
import { CancelButton, PrimaryButton } from '../../../../components/Buttons'
import RouterPrompt from '../../../../components/Common/RouterPrompt'
import { requiredMessage } from '../../../../components/Forms'
import { PageContent } from '../../../../components/Layout'
import FormSkeleton from '../../../../components/Skeleton/FormSkeleton'
import { nullGuid } from '../../../../helpers'
import { useFetchRoles } from '../../../../hooks'
import { useAlertConfigPageReload } from '../../../../hooks/useAlertConfigPageReload'
import {
  AlertConfigSteps,
  AlertResolutionFormFields,
  FetchingStatus,
  Role,
  UserRoles,
  allRoles,
} from '../../../../models'
import { AlertTypes } from '../../../../models/alert'
import { PageLayoutState } from '../../../../models/pageLayout'
import { RouterPromptState } from '../../../../models/routerPrompt'
import { useFormStyle } from '../../../../styles'
import { AlertConfigStepperHeaderHeight } from '../../constants'
import useAlertActiveConfig from '../../useAlertActiveConfig'
import AlertConfigStepperHeader from '../AlertConfigStepperHeader'
import { AlertRoleDropDown } from '../Inputs/AlertRoleDropDown'
import AlertConfigRightDrawer from './AlertConfigRightDrawer'
import { formWidth } from './constants'

const useFlexLayoutStyles = makeStyles(() =>
  createStyles({
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      margin: '0px 12px 0px 24px',
    },
    detail: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      margin: '0px 12px 0px 24px',
    },
    leftItem: {
      width: 508,
    },
    rightItem: {
      width: 300,
      margin: '15px 0',
      color: '#6f6e6e',
      fontSize: 12,
    },
  })
)

export const AlertConfigResolutionPage = () => {
  const maxHeight = useSelector(
    ({ pageLayout }: { pageLayout: PageLayoutState }) => pageLayout.maxHeight
  )
  const location = useLocation()
  const history = useNavigate()
  const automaticExpirationOptions = [30, 60, 90, 120]

  const { data: roles, status: rolesStatus } = useFetchRoles()
  const { activeConfig, setActiveConfig } = useAlertActiveConfig()
  const {
    control,
    formState: { isDirty, errors },
    setValue,
  } = useForm<AlertResolutionFormFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      automaticExpiration:
        activeConfig?.alertResolution?.automaticResolveTimeoutInMinutes ?? 60,
      roleIds: activeConfig?.alertResolution?.resolverRoles?.map(
        (x) => x.roleId
      ),
    },
  })
  const activeConfigLoaded = useAlertConfigPageReload(
    activeConfig,
    setActiveConfig
  )

  const routerPromptState = useSelector(
    ({ routerPrompt }: { routerPrompt: RouterPromptState }) => routerPrompt
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (!routerPromptState.isEnabled && isDirty) {
      dispatch(setRouterPromptIsEnabledStatusAction(isDirty))
    }
  }, [isDirty])

  useEffect(() => {
    if (activeConfigLoaded) {
      if (activeConfig.alertResolution.automaticResolveTimeoutInMinutes) {
        setValue(
          'automaticExpiration',
          activeConfig?.alertResolution.automaticResolveTimeoutInMinutes
        )
      }
      if (
        activeConfig.alertResolution?.resolverRoles?.length === 0 &&
        activeConfig.alertType?.id !== AlertTypes.StaffAssist
      ) {
        const updatedResolution = { ...activeConfig.alertResolution }
        const securityRole = Object.values(roles).find(
          (r) => r.id === UserRoles.SecurityAdmin
        )
        updatedResolution.resolverRoles = securityRole
          ? [
              {
                alertResolutionId: activeConfig.alertResolution.id,
                id: nullGuid,
                roleId: securityRole.id,
              },
            ]
          : []
        setActiveConfig({
          ...activeConfig,
          alertResolution: updatedResolution,
        })
      }
    }
  }, [
    activeConfig?.alertResolution.automaticResolveTimeoutInMinutes,
    activeConfigLoaded,
  ])

  const handleNext = () => {
    const path = `${location.pathname.slice(
      0,
      location.pathname.lastIndexOf('/')
    )}`
    history(
      `${path}/${AlertConfigSteps[AlertConfigSteps.REVIEW].toLowerCase()}`
    )
  }

  const handlePrevious = () => {
    const path = `${location.pathname.slice(
      0,
      location.pathname.lastIndexOf('/')
    )}`
    history(
      `${path}/${AlertConfigSteps[AlertConfigSteps.ROUTING].toLowerCase()}`
    )
  }

  const onSubmit = () => {
    handleNext()
  }

  const handleAddResolverRole = (newRoles: Role[]) => {
    let newData = newRoles.map((x: Role) => x.id).filter((x) => x !== -1)
    if (
      newRoles.some((x: Role) => x.id === -1) &&
      (activeConfig?.alertResolution?.resolverRoles?.length ?? 0) > 0
    ) {
      newData = []
    }

    let updatedResolverRoles = activeConfig?.alertResolution?.resolverRoles
      ? [...activeConfig.alertResolution.resolverRoles]
      : []

    const deselectedRoleId = updatedResolverRoles
      .filter((x) => !newData.includes(+x.roleId))
      .map((x) => x.roleId)

    if (deselectedRoleId?.length > 0) {
      updatedResolverRoles = updatedResolverRoles.filter(
        (x) => !deselectedRoleId.includes(x.roleId)
      )
    }
    const selectedRoleId = newData
      .filter((x) => !updatedResolverRoles.some((r) => +r.roleId === x))
      .shift()

    if (selectedRoleId) {
      updatedResolverRoles.push({
        roleId: selectedRoleId,
        alertResolutionId: activeConfig.alertResolution.id,
        id: nullGuid,
      })
    }

    setActiveConfig({
      ...activeConfig,
      alertResolution: {
        ...activeConfig.alertResolution,
        resolverRoles: updatedResolverRoles,
      },
    })
  }

  const formClasses = useFormStyle()
  const classes = useFlexLayoutStyles()

  return (
    <PageContent
      maxHeight={maxHeight}
      openRightDrawer={true}
      style={{ maxHeight: '100%' }}
      hideScrollbar={true}
      content={
        <>
          <AlertConfigStepperHeader activeStep={AlertConfigSteps.RESOLUTION} />
          <div
            style={{
              overflow: 'auto',
              height: '100%',
              maxHeight: `calc(100% - ${AlertConfigStepperHeaderHeight})`,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                position: 'absolute',
                width: '100%',
                marginTop: '12px',
                paddingRight: '12px',
              }}
            >
              <CancelButton onClick={handlePrevious}>Previous</CancelButton>

              <PrimaryButton onClick={onSubmit}>Next</PrimaryButton>
            </div>
            <form>
              <div
                style={{
                  margin: '22px 0px 10px 24px',
                }}
              >
                <Typography variant='h5'>Alert Builder: Resolution</Typography>
              </div>
              {activeConfig.alertType?.id === AlertTypes.AssetAlert && (
                <div
                  style={{
                    margin: '22px 0px 10px 24px',
                  }}
                >
                  <Typography>
                    Asset alerts do not require Resolution. Click Next to
                    continue.
                  </Typography>
                </div>
              )}
              {activeConfig.alertType?.id !== AlertTypes.AssetAlert && (
                <div>
                  <div className={classes.detail}>
                    <div className={classes.leftItem}>
                      <Typography
                        variant='h6'
                        style={{
                          fontWeight: 'bold',
                          fontSize: '20px',
                        }}
                      >
                        Alert Resolve Permissions
                      </Typography>
                    </div>
                  </div>

                  <div className={classes.detail}>
                    <div className={classes.leftItem}>
                      {activeConfigLoaded ? (
                        <AlertRoleDropDown
                          value={
                            Object.values(roles).filter((x: Role) => {
                              if (
                                activeConfig?.alertResolution.resolverRoles
                                  .length > 0
                              ) {
                                return activeConfig?.alertResolution?.resolverRoles
                                  ?.map((r) => r.roleId)
                                  .includes(x.id)
                              }
                            }) ?? []
                          }
                          error={false}
                          loading={rolesStatus === FetchingStatus.Request}
                          className={formClasses.inputStyle}
                          unselectedValue={-1}
                          data={[allRoles, ...Object.values(roles)] ?? []}
                          fieldName='RTLS Role'
                          disabled={
                            activeConfig?.alertType?.id !==
                            AlertTypes.StaffAssist
                          }
                          onChange={(event, newValue: Role[]) => {
                            handleAddResolverRole(newValue)
                          }}
                          helperText={requiredMessage}
                        />
                      ) : (
                        <FormSkeleton width={formWidth} />
                      )}
                    </div>
                  </div>

                  <div className={classes.detail}>
                    <div className={classes.leftItem}>
                      <Typography
                        variant='h6'
                        style={{
                          fontWeight: 'bold',
                          fontSize: '20px',
                        }}
                      >
                        Automatic Expiration
                      </Typography>
                    </div>
                  </div>

                  <div className={classes.detail}>
                    <div className={classes.leftItem}>
                      {activeConfigLoaded ? (
                        <Controller
                          control={control}
                          name='automaticExpiration'
                          rules={{ required: true }}
                          render={({ field: { ref, ...field } }) => {
                            return (
                              <Autocomplete
                                {...field}
                                style={{ width: '100%' }}
                                value={
                                  activeConfig?.alertResolution
                                    ?.automaticResolveTimeoutInMinutes
                                }
                                isOptionEqualToValue={(option, value) => {
                                  if (!value) {
                                    return true
                                  }
                                  return option === value
                                }}
                                options={automaticExpirationOptions}
                                getOptionLabel={(option) => `${option} Minutes`}
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                renderOption={(props, option, { selected }) => (
                                  <li {...props}>{`${option} Minutes`}</li>
                                )}
                                onChange={(event, newValue) => {
                                  const updatedResolution = {
                                    ...activeConfig.alertResolution,
                                  }
                                  updatedResolution.automaticResolveTimeoutInMinutes =
                                    newValue ?? 60
                                  setActiveConfig({
                                    ...activeConfig,
                                    alertResolution: updatedResolution,
                                  })

                                  if (newValue === null) {
                                    return 60
                                  }

                                  field.onChange(newValue)
                                  return newValue
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...field}
                                    {...params}
                                    margin='normal'
                                    label='Expiration'
                                    variant='filled'
                                    className={formClasses.inputStyle}
                                    error={!!errors.automaticExpiration}
                                    required
                                    helperText={requiredMessage}
                                    inputRef={ref}
                                  />
                                )}
                              />
                            )
                          }}
                        />
                      ) : (
                        <FormSkeleton width={formWidth} />
                      )}
                    </div>
                    <div className={classes.rightItem}>
                      <p>
                        After the selected time has elapsed, the alert will
                        automatically resolve.
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </div>
          <RouterPrompt />
        </>
      }
      rightDrawer={
        <AlertConfigRightDrawer
          activeConfig={activeConfig}
          activeConfigLoaded={activeConfigLoaded}
          canEdit={false}
        />
      }
    />
  )
}

export default AlertConfigResolutionPage
