import { createAsyncAction } from 'typesafe-actions'
import {
  ExternalLink,
  ExternalLinksOperationsRequest,
  ExternalLinkUpdate,
} from '../models'
import { HttpErrorResponse } from '../services/http'

export const getAllExternalLinksAction = createAsyncAction(
  'GET_ALL_EXTERNAL_LINKS_REQUEST',
  'GET_ALL_EXTERNAL_LINKS_SUCCESS',
  'GET_ALL_EXTERNAL_LINKS_FAILURE'
)<string, ExternalLink[], HttpErrorResponse>()

export const putExternalLinkAction = createAsyncAction(
  'PUT_EXTERNAL_LINK_REQUEST',
  'PUT_EXTERNAL_LINK_SUCCESS',
  'PUT_EXTERNAL_LINK_FAILURE'
)<ExternalLinkUpdate, ExternalLink, HttpErrorResponse>()

export const postExternalLinksAction = createAsyncAction(
  'POST_EXTERNAL_LINKS_REQUEST',
  'POST_EXTERNAL_LINKS_SUCCESS',
  'POST_EXTERNAL_LINKS_FAILURE'
)<ExternalLinksOperationsRequest, ExternalLink[], HttpErrorResponse>()

export const postExternalLinkAction = createAsyncAction(
  'POST_EXTERNAL_LINK_REQUEST',
  'POST_EXTERNAL_LINK_SUCCESS',
  'POST_EXTERNAL_LINK_FAILURE'
)<ExternalLinkUpdate, ExternalLink, HttpErrorResponse>()

export const deleteExternalLinkAction = createAsyncAction(
  'DELETE_EXTERNAL_LINK_REQUEST',
  'DELETE_EXTERNAL_LINK_SUCCESS',
  'DELETE_EXTERNAL_LINK_FAILURE'
)<ExternalLinkUpdate, ExternalLink, HttpErrorResponse>()
