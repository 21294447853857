import React, { useMemo } from 'react'
import { badgeSignalAccuracy, badgeSignalStatus } from '../../../../helpers'
import { getLocationBasedBatteryStatus } from '../../../../helpers/battery'
import {
  formatLocalDateTimeByUserPreference,
  localMomentFormatted,
} from '../../../../helpers/date'
import { getRoomDisplayNameForStaff } from '../../../../helpers/detailDrawer'
import { useFetchUserPreferences } from '../../../../hooks'
import { SignalType, TelemetryStaff } from '../../../../models'
import { DetailedItem } from '../../../../models/DetailPaneItems'
import { DetailDrawerAgentTelemetry } from '../../../../models/telemetry'
import DetailsList from '../../../DetailsList/DetailsList'

interface Props {
  data: DetailDrawerAgentTelemetry<TelemetryStaff>
}

function StaffDetailPane(props: Props): React.ReactElement {
  const { data } = props
  const { agent, telemetry, resolvedLocationHierachy } = data

  const { data: userPreferences } = useFetchUserPreferences()

  const identificationItems = useMemo(() => {
    return !!agent
      ? [
          {
            field: 'Display Name',
            value: agent.displayName,
          },
          {
            field: 'First Name',
            value: agent.firstName,
          },
          {
            field: 'Last Name',
            value: agent.lastName,
          },
          {
            field: 'Email',
            value: agent.email,
          },
          {
            field: 'Staff Type',
            value: agent.staffTypeName,
          },
          {
            field: 'Staff Sub-Type',
            value: agent.staffSubTypeName,
          },
          {
            field: 'Assigned To',
            value: agent.unitName,
          },
          {
            field: 'Reports To',
            value: agent.managerName,
          },
          {
            field: 'RTLS ID',
            value: telemetry?.trackingId,
          },
        ]
      : []
  }, [agent, telemetry])

  const locationItems = useMemo(() => {
    let locationAccuracy = 'Unknown'
    let signalStatus = 'Unknown'
    let lastDetected = telemetry?.timestamp
    if (telemetry && telemetry.signalTypeId) {
      locationAccuracy = badgeSignalAccuracy(telemetry.signalTypeId)
      signalStatus = badgeSignalStatus(telemetry.timestamp)
      lastDetected = telemetry.timestamp
    }
    return [
      {
        field: 'Building Group',
        value: resolvedLocationHierachy?.buildingGroupLocation.name,
      },
      {
        field: 'Building',
        value: resolvedLocationHierachy?.buildingLocation.name ?? '',
      },
      {
        field: 'Floor',
        value: resolvedLocationHierachy?.floorLocation.name ?? '',
      },
      {
        field: 'Room',
        value: resolvedLocationHierachy
          ? getRoomDisplayNameForStaff(
              resolvedLocationHierachy.roomLocation,
              resolvedLocationHierachy.isPrivate,
              (telemetry?.hasActiveDuress || telemetry?.hasActiveAssist) ??
                false
            )
          : '',
      },
      {
        field: 'Unit',
        value: resolvedLocationHierachy?.locationUnitName,
      },
      {
        field: 'Sub-Unit',
        value: resolvedLocationHierachy?.locationSubUnitName,
      },
      {
        field: 'Location Accuracy',
        value: locationAccuracy ?? '',
      },
      {
        field: 'Last Detected',
        value: formatLocalDateTimeByUserPreference(
          lastDetected,
          userPreferences.timeDisplayId
        ),
      },
      {
        field: 'Signal Status',
        value: signalStatus ?? '',
      },
      {
        field: 'Signal Type',
        value: telemetry ? SignalType[telemetry.signalTypeId] : '',
      },
      {
        field: 'Battery Status',
        value: getLocationBasedBatteryStatus(
          resolvedLocationHierachy?.roomLocation,
          telemetry?.isLowBattery
        ),
      },
      {
        field: 'RTLS Battery Change Date',
        value: agent.tagBatteryChangeDate
          ? localMomentFormatted(agent.tagBatteryChangeDate, 'MM/DD/YYYY')
          : 'None',
      },
    ]
  }, [
    agent,
    resolvedLocationHierachy,
    telemetry,
    userPreferences.timeDisplayId,
  ])

  const detailItems: DetailedItem[] = []
  detailItems.push({
    header: 'STAFF IDENTIFICATION',
    items: identificationItems,
  })
  detailItems.push({
    header: 'RTLS LOCATION DATA',
    items: locationItems,
  })

  return (
    <div style={{ paddingTop: 24 }}>
      <DetailsList detailedItems={detailItems} narrowRows={true} />
    </div>
  )
}

export default StaffDetailPane
