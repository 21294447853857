import React, { useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Link,
  Typography,
  Divider,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { DialogActionButtonType } from '../../Dialogs/DialogActionButtonType'
import DialogActionButtons from '../../Dialogs/DialogActionButtons'
import { Release, PastReleases } from '../../../models/release'
import { releaseUserNoticesApi } from '../../../middleware'
import { localMoment } from '../../../helpers/date'
import UpdateModalHeader from './UpdateModalHeader'
import { useAuth } from '../../../hooks'

const useStyles = makeStyles({
  bodyText: {
    fontSize: '16px',
  },
  releaseTimeText: {
    fontSize: '12px',
    color: 'grey',
  },
  dialogActions: {
    paddingTop: '20px',
    paddingRight: '24px',
    paddingBottom: '24px',
  },
})

const useDialogStyles = makeStyles({
  paper: {
    width: '500px',
    maxHeight: '530px',
    height: 'auto',
  },
})

const useDialogContentStyles = makeStyles({
  root: {
    color: 'black',
    padding: '16px 20px',
    overflowY: 'auto',
    maxHeight: '300px',
  },
})

interface Props {
  pastReleases: PastReleases
  applicationTypeId: number
}

const RecentUpdateModal: React.FC<Props> = (props: Props) => {
  const { pastReleases, applicationTypeId } = props
  const classes = useStyles()
  const dialogClasses = useDialogStyles()
  const dialogContentClasses = useDialogContentStyles()
  const { user } = useAuth()

  const [modalOpen, setModalOpen] = useState(true)

  const handleDismissClick = () => {
    releaseUserNoticesApi
      .dismissAllReleaseNotices({
        releaseApplicationId: applicationTypeId,
        userId: user.id,
        userNoticeDate: new Date(),
      })
      .finally(() => {
        setModalOpen(false)
      })
  }

  const actionButtons: DialogActionButtonType[] = [
    {
      label: (
        <Link
          target='blank'
          href={process.env.REACT_APP_ZENDESK_RELEASE_DETAILS}
          underline='hover'
        >
          LEARN MORE
        </Link>
      ),
      action: (): void => {},
    },
    {
      label: 'Dismiss',
      action: (): void => handleDismissClick(),
      variant: 'outlined',
    },
  ]

  return (
    <Dialog
      classes={dialogClasses}
      open={modalOpen}
      onClose={handleDismissClick}
    >
      <UpdateModalHeader
        title={`What's New in Midmark RTLS Cloud`}
        onClose={handleDismissClick}
      />
      <Divider />
      <DialogContent classes={dialogContentClasses}>
        <Typography
          variant='h4'
          style={{ fontSize: '30px', paddingBottom: '10px' }}
        >{`RTLS Cloud Has Updated`}</Typography>
        {pastReleases &&
          Object.values(pastReleases)
            .sort(
              (a, b) =>
                new Date(b.plannedReleasedDate).getTime() -
                new Date(a.plannedReleasedDate).getTime()
            )
            .map((release: Release, index) => {
              return (
                <div key={release.releaseId}>
                  <div style={{ padding: '10px 0px' }}>
                    <Typography className={classes.releaseTimeText}>
                      {`${localMoment(release.plannedReleasedDate).format(
                        'MMM D[,] YYYY'
                      )}`}
                    </Typography>
                    <Typography className={classes.bodyText}>
                      {`${release.releaseNotes}`}
                    </Typography>
                    <Typography className={classes.bodyText}>
                      Click{' '}
                      {
                        <Link
                          target='blank'
                          href={release.learnMoreLink}
                          underline='hover'
                        >
                          here
                        </Link>
                      }{' '}
                      to learn more
                    </Typography>
                  </div>
                  {index < Object.values(pastReleases).length - 1 && (
                    <Divider />
                  )}
                </div>
              )
            })}
      </DialogContent>
      <DialogContent style={{ padding: '0px' }}>
        <Divider />
        <Typography
          className={classes.bodyText}
          style={{ paddingLeft: '20px' }}
        >
          <span style={{ display: 'block', marginBottom: '12px' }} />
          {`Click Learn More to read the full release notes.`}
        </Typography>
      </DialogContent>
      {actionButtons && (
        <DialogActions className={classes.dialogActions}>
          <DialogActionButtons actionButtons={actionButtons} />
        </DialogActions>
      )}
    </Dialog>
  )
}

export default RecentUpdateModal
