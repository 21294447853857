import { TextField, TextFieldProps } from '@mui/material'
import React, { useEffect, useState, useRef } from 'react'

type DebouncedTextFieldProps = TextFieldProps & {
  handleChange: (value: any) => void
  isWaiting: (value: boolean) => void
}

const DebouncedTextField = (props: DebouncedTextFieldProps) => {
  const {
    value: valueToDebounce,
    handleChange,
    isWaiting,
    ...textFieldProps
  } = props
  const [managedValue, setManagedValue] = useState<unknown>(valueToDebounce)
  const [typing, setTyping] = useState(false)
  const firstUpdate = useRef(true)

  //This useEffect should assist with handling the value prop changing after initial render
  //Originally added for Blended Badge conversion after typing
  useEffect(() => {
    if (managedValue !== valueToDebounce && !typing) {
      setManagedValue(valueToDebounce)
    }
  }, [typing, managedValue, valueToDebounce])

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    const timeout = setTimeout(() => {
      handleChange(managedValue)
      isWaiting(false)
      setTyping(false)
    }, 2000)
    return () => clearTimeout(timeout)
  }, [handleChange, isWaiting, managedValue])

  return (
    <TextField
      variant='standard'
      {...textFieldProps}
      onChange={(event) => {
        isWaiting(true)
        setTyping(true)
        setManagedValue(event.target.value)
      }}
      value={managedValue}
    />
  )
}

export default React.forwardRef(DebouncedTextField)
