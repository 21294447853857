import { AgentType, IconType } from '../../models'
import { InpixonAssetAgentPositionUpdate } from '../../models/inpixonMap'
import { MapRenderByGeofence } from '../../models/realtimeMap'
import { BadgeTelemetryMessageWithAgentEvent } from '../../models/telemetry'
import { calculateLeavingGeofence } from './calculateLeavingGeofence'

/**
 * For MoveAgent MapUpdateType, use this function to calculate the config values
 * needed to rerender agent on the map and show agent movement
 * @param newAgentTelemetry
 * @param currentMapRender
 * @returns config to update agent position on the map
 */
export function calculateLeavingGeofenceMapUpdatesToMoveAgent(
  newAgentTelemetry: BadgeTelemetryMessageWithAgentEvent,
  currentMapRender: MapRenderByGeofence
): InpixonAssetAgentPositionUpdate | undefined {
  const leavingGeofenceData = calculateLeavingGeofence(
    newAgentTelemetry.trackingId,
    currentMapRender
  )
  if (!leavingGeofenceData) {
    return
  }
  const { prevAgentTelemetry } = leavingGeofenceData
  const leavingAgentToUpdatePosition: InpixonAssetAgentPositionUpdate = {
    trackingId: newAgentTelemetry.trackingId,
    lat: newAgentTelemetry.lat,
    lon: newAgentTelemetry.lon,
    mapId: newAgentTelemetry.mapId,
    geoFenceId: newAgentTelemetry.geoFenceId,
    leavingGeofenceId: prevAgentTelemetry.geoFenceId,
    hasActiveDuress: newAgentTelemetry.hasActiveDuress,
    iconType:
      newAgentTelemetry.agent.agentType === AgentType.Asset
        ? IconType.Asset
        : IconType.Staff,
  }
  return leavingAgentToUpdatePosition
}
