import React from 'react'
import { Switch, FormControlLabel, Typography, Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { colors } from '../../styles'
import { UnitPlusColor } from '../../models/units'
import { Geofence } from '../../models'

interface Props {
  unitsWithColor: UnitPlusColor[]
  showUnits: boolean
  setShowUnits: (setShowUnits: boolean) => void
  setMultiSelectRooms: (setMultiSelectRooms: boolean) => void
  mutliSelectRooms: boolean
  setSelectAllRooms: (setSelectAllRooms: boolean) => void
  setOpenMultipleRoomsEditModal?: (open: boolean) => void
  selectedGeofences?: Geofence[]
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      backgroundColor: colors.white,
      boxShadow:
        '0 1px 10px 0 rgba(0, 0, 0, 0.18), 0 4px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 4px 0 rgba(0, 0, 0, 0.14);',
      display: 'flex',
      flexFlow: 'column',
      width: '225px',
      position: 'absolute',
      top: 101,
      left: 36,
      borderRadius: 4,
      zIndex: 1000,
      leftPadding: 16,
      paddingLeft: '10px',
      paddingRight: '10px',
      borderColor: '#8d8d8d',
      maxHeight: '85%',
      overflow: 'auto',
    },
    zoomIn: {
      borderRadius: 0,
    },
    zoomOut: {
      borderTop: `1px solid ${colors.midGrey}`,
      borderRadius: 0,
    },
    search: {
      borderRadius: 0,
    },
  })
)

const FloorUnitsControl: React.FC<Props> = (props: Props) => {
  const {
    unitsWithColor,
    showUnits,
    setShowUnits,
    setMultiSelectRooms,
    mutliSelectRooms,
    setSelectAllRooms,
    setOpenMultipleRoomsEditModal,
    selectedGeofences,
  } = props
  const classes = useStyles()

  const unitsShow = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowUnits(event.target.checked)
  }

  const multiRoomSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMultiSelectRooms(event.target.checked)
  }

  const selectAllRooms = () => {
    setSelectAllRooms(true)
  }

  const handleMultipleRoomsEdit = () => {
    if (setOpenMultipleRoomsEditModal) {
      setOpenMultipleRoomsEditModal(true)
    }
  }

  return (
    <div className={classes.container}>
      <div>
        <FormControlLabel
          control={
            <Switch color='primary' checked={showUnits} onChange={unitsShow} />
          }
          labelPlacement='end'
          color={colors.primary}
          label={
            <Typography
              style={{
                color: colors.primary,
                fontWeight: 'bold',
                fontSize: '15px',
              }}
            >
              Show Units
            </Typography>
          }
        />
        {showUnits === true && (
          <div>
            {unitsWithColor?.length > 0 ? (
              unitsWithColor.map((unit) => (
                <div key={unit.id}>
                  <div style={{ display: 'flex' }}>
                    <div
                      style={{
                        backgroundColor: unit.unitColor,
                        width: '20px',
                        height: '20px',
                        display: 'flex',
                        borderRadius: '4px',
                        opacity: '0.30',
                        minWidth: '20px',
                      }}
                    ></div>
                    <div style={{ width: '10px' }}></div>
                    <div style={{ display: 'flex' }}>
                      <Typography
                        key={unit.id}
                        style={{
                          fontSize: '16px',
                          color: '#333',
                        }}
                      >
                        {unit.name}
                      </Typography>
                    </div>
                  </div>
                  <div style={{ height: '10px' }}></div>
                </div>
              ))
            ) : (
              <div>No Units</div>
            )}
          </div>
        )}
        <FormControlLabel
          value='multiselect'
          control={
            <Switch
              color='primary'
              checked={mutliSelectRooms}
              onChange={multiRoomSelect}
            />
          }
          labelPlacement='end'
          label={
            <Typography
              style={{
                color: colors.primary,
                fontWeight: 'bold',
                fontSize: '15px',
              }}
            >
              Multi Select Rooms
            </Typography>
          }
        />
        {mutliSelectRooms === true && (
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: colors.primary,
                  fontWeight: 'bold',
                  fontSize: '14px',
                  justifyContent: 'flex-start',
                }}
              >
                <Button
                  type='submit'
                  onClick={() => selectAllRooms()}
                  style={{
                    fontWeight: 'bold',
                    color: colors.primary,
                    cursor: 'pointer',
                  }}
                >
                  {'SELECT ALL'}
                </Button>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant='outlined'
                  onClick={() => handleMultipleRoomsEdit()}
                  type='submit'
                  disabled={(selectedGeofences || []).length > 0 ? false : true}
                  style={
                    (selectedGeofences || []).length > 0
                      ? {
                          fontWeight: 'bold',
                          backgroundColor: colors.primary,
                          color: colors.white,
                          cursor: 'pointer',
                        }
                      : {
                          fontWeight: 'bold',
                          backgroundColor: '#c4c4c4',
                          color: '#8d8d8d',
                          cursor: 'pointer',
                        }
                  }
                >
                  {'EDIT'}
                </Button>
              </div>
            </div>
            <div style={{ height: '10px' }} />
          </>
        )}
      </div>
    </div>
  )
}

export { FloorUnitsControl }
