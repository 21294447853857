export interface Rectangle {
  x: number
  y: number
  top: number
  right: number
  bottom: number
  left: number
  height: number
  width: number
}

export const domRectToRectangle = (rect: DOMRect): Rectangle => {
  return {
    x: rect.x,
    y: rect.y,
    top: rect.top,
    right: rect.right,
    bottom: rect.bottom,
    left: rect.left,
    height: rect.height,
    width: rect.width,
  }
}

export interface RealTimeMapControlStore {
  shouldShow: boolean
  rectangle: Rectangle
  jibestreamControl: any
}

export type RealTimeMapControlState = Readonly<RealTimeMapControlStore>
