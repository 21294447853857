import React, { useCallback, useState, useEffect } from 'react'
import { useLocation } from 'react-router'
import { config } from './inpixonConfig'
import LoadingIndicator from '../LoadingIndicator'
import { focusView, hideLayers } from '../../helpers/inpixon'
import { useQueryParams } from '../../hooks'
import {
  useAssetKit,
  useDevices,
  useJibestream,
  useMapPresets,
  useGeoFenceKit,
  useMapUpdates,
} from '../../hooks/inpixon'
import {
  MapPresets,
  AssetInstance,
  MapReferenceView,
  Geofences,
  Assets,
  SideNavState,
  StaffMembers,
  Locations,
  LocationGeofences,
  StaffEvents,
} from '../../models'
import { useMapAgentReference } from '../../hooks/inpixon/useMapAgentReference'
import { useFocusView } from '../../hooks/inpixon/useFocusView'
import { stringToNumber } from '../../helpers'
import { useInpixonAssetHighlighting } from '../../hooks/inpixon/useInpixonAssetHighlighting'
import { useDeviceLocationUpdate } from '../../hooks/inpixon/useDeviceLocationUpdate'
import { useGeofenceExport } from '../../hooks/inpixon/useGeofenceExport'
import { useSelector } from 'react-redux'
import { useFetchFeatureFlags } from '../../hooks/entities/useFetchFeatureFlags'
import { FeatureFlagLabels } from '../../constants'
import { DrawerType } from '../Layout/PageContent'

interface AgentEventOptions {
  allDuressEvents: StaffEvents
  allAssistEvents: StaffEvents
}

interface Props {
  venueId: number
  deviceMapReferences?: MapReferenceView[]
  assets?: Assets
  staff?: StaffMembers
  mapPresets: MapPresets
  mapId: number | undefined
  mapIsLoaded: boolean
  updateDeviceLatLon?: boolean
  exportGeofence?: boolean
  redirect?: string
  geofences?: Geofences
  locations?: Locations
  locationGeofences?: LocationGeofences
  setSelectedBadgeId?: (trackingId: string) => void
  setSelectedGroupId?: (groupId: number | undefined) => void
  setMapLoaded: (loaded: boolean) => void
  onMapChanged: (mapId: number) => void
  onMapShowDelegate?: (jibestream: any) => void
  handleAssetClickDelegate: (
    trackingId: number,
    asset: AssetInstance,
    jibestream: any
  ) => void
  setUpdateDeviceLatLon?: (loaded: boolean) => void
  setExportGeofence?: (loaded: boolean) => void
  handleMapRef?: (jibestream: any) => void
  handleGeofenceKitRef?: (geofenceKit: any) => void
  handleGeofenceKitLoaded?: (loaded: any) => void
  handleAssetKitRef?: (assetKit: any) => void
  agentTypes?: string[]
  currentRightDrawerType?: DrawerType
  focusOnAgent?: (agentTrackingId: string) => void
  agentEventOptions?: AgentEventOptions
}

const InpixonMap: React.FC<Props> = ({
  venueId,
  deviceMapReferences,
  assets,
  staff,
  mapPresets,
  mapId,
  mapIsLoaded,
  updateDeviceLatLon,
  exportGeofence,
  redirect,
  geofences,
  locations,
  locationGeofences,
  setMapLoaded,
  onMapShowDelegate,
  onMapChanged,
  handleAssetClickDelegate,
  setSelectedBadgeId,
  setUpdateDeviceLatLon,
  setExportGeofence,
  setSelectedGroupId,
  handleGeofenceKitRef,
  handleGeofenceKitLoaded,
  handleAssetKitRef,
  handleMapRef,
  agentTypes,
  currentRightDrawerType,
  focusOnAgent,
  agentEventOptions,
}: Props) => {
  const { allDuressEvents, allAssistEvents } = agentEventOptions ?? {}

  const [mapInitialized, setMapInitialized] = useState(false)
  const { badgeId, x, y, zoom } = useQueryParams()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const urlBadgeId = searchParams.get('badgeId')
  const [assetsCreated, setAssetsCreated] = useState(false)
  const [geoFenceKitLoaded, setGeoFenceKitLoaded] = useState(false)

  const { data: featureFlags } = useFetchFeatureFlags(
    FeatureFlagLabels.Navigation,
    true
  )

  const onMapShow = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (jibestream: any): void => {
      if (!mapInitialized) {
        const hiddenLayers = [
          'CustomArtLayer-1',
          'CustomArtLayer-2',
          'CustomArtLayer-3',
          'CustomArtLayer-4',
          'lBox',
        ]
        jibestream.control.setMaxScale(30)
        jibestream.control.currentMapView?.guaranteeMapLayer('Geofences')
        jibestream.control.currentMapView?.guaranteeMapLayer('Moving-Objects')

        hideLayers(jibestream.control, hiddenLayers)
        focusView(jibestream.control, 745, 940, 2, 4.8, 0)
        setMapInitialized(true)

        if (onMapShowDelegate) {
          onMapShowDelegate(jibestream)
        }
      }

      if (mapId !== jibestream.control.currentMap.id) {
        setMapLoaded(false)
        onMapChanged(jibestream.control.currentMap.id)
        setMapLoaded(true)
      } else if (!mapIsLoaded) {
        setMapLoaded(true)
      }
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const jibestream = useJibestream({
    config,
    venueId,
    mapId,
    onMapShow,
  })

  const assetKit = useAssetKit(jibestream?.control, mapIsLoaded)

  const geoFenceKit = useGeoFenceKit(
    jibestream?.control,
    jibestream?.core,
    setGeoFenceKitLoaded
  )

  useInpixonAssetHighlighting(jibestream.control, assetKit, assets, staff)

  useEffect(() => {
    if (
      handleGeofenceKitRef &&
      geoFenceKit?._control &&
      geoFenceKitLoaded &&
      mapInitialized
    ) {
      handleGeofenceKitRef(geoFenceKit)
    }
  }, [geoFenceKit, handleGeofenceKitRef, geoFenceKitLoaded, mapInitialized])

  useEffect(() => {
    if (handleAssetKitRef && assetKit && mapIsLoaded && mapInitialized) {
      handleAssetKitRef(assetKit)
    }
  }, [assetKit, handleAssetKitRef, mapIsLoaded, mapInitialized])

  useEffect(() => {
    if (handleMapRef && jibestream && mapInitialized) {
      handleMapRef(jibestream)
    }
  }, [jibestream, handleMapRef, mapInitialized])

  useMapAgentReference()

  useEffect(() => {
    //Make sure the GeoFence Kit has instances
    if (!geoFenceKitLoaded && geoFenceKit?.geofences?._items?.length > 0) {
      setGeoFenceKitLoaded(true)
      if (handleGeofenceKitLoaded) {
        handleGeofenceKitLoaded(true)
      }
    } else if (geoFenceKitLoaded && handleGeofenceKitLoaded) {
      handleGeofenceKitLoaded(true)
    }
  }, [
    geoFenceKit,
    geoFenceKit?.geofences?._items?.length,
    geoFenceKitLoaded,
    handleGeofenceKitLoaded,
    setGeoFenceKitLoaded,
  ])

  useMapPresets(
    jibestream?.control,
    assetKit,
    mapPresets,
    badgeId,
    stringToNumber(x),
    stringToNumber(y),
    stringToNumber(zoom)
  )

  useDeviceLocationUpdate(
    jibestream,
    venueId,
    setUpdateDeviceLatLon,
    updateDeviceLatLon
  )

  useGeofenceExport(geoFenceKit, setExportGeofence, exportGeofence)

  //Handles focusing view to asset from url
  useFocusView(
    assetKit,
    jibestream.control,
    urlBadgeId ?? undefined,
    mapIsLoaded,
    assetsCreated,
    focusOnAgent
  )

  useEffect(() => {
    if (assetKit) {
      const handler = (item: any) => {
        const asset = assetKit?.getAssetById(item.id)
        if (asset) {
          handleAssetClickDelegate(item.id, asset, jibestream)
        }
      }

      // enableAssetTap registers a new callback in the tapHandlers array
      // by default, the assetKit comes with two tapHandlers already enabled
      // any tapHandlers > 2 are callbacks we added
      const tapEventsAdded = (): boolean =>
        Boolean(assetKit?._control.stage.events.tapHandlers.length > 2)

      // only register our click handler once, remove old one, then add new one
      if (tapEventsAdded()) {
        assetKit.disableAssetTap(handler)
      }

      assetKit.enableAssetTap(handler)
    }
  }, [assetKit, handleAssetClickDelegate])

  // depending on map usage, only one of these hooks should fire on any given page
  useMapUpdates(
    jibestream,
    assetKit,
    mapId,
    setAssetsCreated,
    allDuressEvents,
    allAssistEvents,
    agentTypes,
    setSelectedGroupId,
    assets,
    staff,
    geofences,
    locations,
    locationGeofences,
    geoFenceKitLoaded,
    featureFlags,
    geoFenceKit
  )

  // useDevices: handles displaying sensor hardware
  useDevices(deviceMapReferences, jibestream, assetKit)

  const isSideNavOpen = useSelector(
    ({ sideNav }: { sideNav: SideNavState }) => sideNav.isOpen
  )

  useEffect(() => {
    jibestream?.control?.stage?.resize()
  }, [isSideNavOpen, currentRightDrawerType])

  const cleanup = () => {
    if (assetKit !== undefined) {
      if (assetKit) {
        assetKit?._getAllAssets()?.forEach((asset) => {
          assetKit?.removeAsset(asset)
        })
      }
    }
  }

  useEffect(() => {
    cleanup()
  }, [])

  return (
    <div>
      {!mapIsLoaded && (
        <div
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            height: '100%',
            width: '100%',
          }}
        >
          <LoadingIndicator color='primary' />
        </div>
      )}
    </div>
  )
}

export { InpixonMap }
