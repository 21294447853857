import { getType } from 'typesafe-actions'
import { UnitAction } from '../actions'
import {
  getAllUnitsAction,
  getUnitAction,
  postUnitAction,
  deleteUnitAction,
  putUnitAction,
} from '../actions/units'
import { Unit, Units, UnitsState } from '../models'
import { FetchingStatus } from '../models/fetchingStatus'
import {
  mergeRecords,
  mergeRecord,
  deleteRecord,
  setFetchingStatus,
} from '../utils'

const initialState: UnitsState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

const key = 'id'
export default (
  state: UnitsState = initialState,
  action: UnitAction
): UnitsState => {
  switch (action.type) {
    case getType(getAllUnitsAction.success):
      return {
        data: mergeRecords<Units, Unit, typeof key>(
          state.data,
          action.payload,
          key
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getUnitAction.success):
    case getType(postUnitAction.success):
    case getType(putUnitAction.success):
      return {
        data: mergeRecord<Units, Unit, typeof key>(
          state.data,
          action.payload,
          key
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(deleteUnitAction.success):
      return {
        data: deleteRecord<Units, Unit>(state.data, action.payload),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }

    case getType(getAllUnitsAction.request):
    case getType(getUnitAction.request):
    case getType(postUnitAction.request):
    case getType(putUnitAction.request):
    case getType(deleteUnitAction.request):
      return setFetchingStatus<Units>(state, FetchingStatus.Request)
    case getType(getAllUnitsAction.failure):
    case getType(getUnitAction.failure):
    case getType(postUnitAction.failure):
    case getType(putUnitAction.failure):
    case getType(deleteUnitAction.failure):
      return setFetchingStatus<Units>(state, FetchingStatus.Failure)

    default:
      return state
  }
}
