import { Coordinates } from '../models/destinationPositions'
import {
  DeviceType,
  deviceTypeIdToEnum,
  Sensor,
  Sensors,
  SignalType,
} from '../models'

const getBadgeCoordinates = (
  badges: string[],
  destinationCoordinates: Coordinates[],
  sensors: Sensors,
  destinationId: string
): Coordinates | null => {
  let coordinates: Coordinates | null = null

  badges.forEach((badgeID, index) => {
    const destCoordinates = destinationCoordinates[index]
    if (destCoordinates && destCoordinates.x && destCoordinates.y) {
      if (
        Object.values(sensors).some(
          (sensor: Sensor) =>
            sensor.destinationId &&
            sensor.destinationId.toString() === destinationId &&
            sensor.signalTypeId === SignalType.IR
        ) &&
        Object.values(sensors).some(
          (sensor: Sensor) =>
            sensor.destinationId &&
            sensor.destinationId.toString() === destinationId &&
            deviceTypeIdToEnum(sensor.deviceTypeId) === DeviceType.IR
        )
      ) {
        coordinates = destCoordinates
      }
    }

    return
  })

  return coordinates
}

export { getBadgeCoordinates }
