import React from 'react'
import {
  RealTimePageViews,
  TelemetryStaff,
  Watchlist,
} from '../../../../models'
import { DetailDrawerAgentTelemetry } from '../../../../models/telemetry'
import { RightDrawerLayout } from '../../RightDrawer'
import StaffDetailPane from './StaffDetailPane'
import StaffDetailPaneHeader from './StaffDetailPaneHeader'
import { StaffFilters } from '../../../../models/filters'

interface Props {
  selectedStaff: DetailDrawerAgentTelemetry<TelemetryStaff>
  watchList: Watchlist
  onCloseDetailDrawer: () => void
  onEditStaff?: () => void
  currentPageView: RealTimePageViews
  staffAssistReportedTime: Date | undefined
  staffFilterState: StaffFilters
  setFilterButtonToggled: (filterButtonStates: any) => void
}

const StaffDetailDrawer = (props: Props): JSX.Element => {
  const {
    selectedStaff,
    watchList,
    currentPageView,
    staffAssistReportedTime,
    staffFilterState,
    onCloseDetailDrawer,
    onEditStaff,
    setFilterButtonToggled,
  } = props

  return (
    <RightDrawerLayout
      header={
        <StaffDetailPaneHeader
          data={selectedStaff}
          onClose={onCloseDetailDrawer}
          watchlist={watchList}
          onEditStaff={onEditStaff}
          staffAssistReportedTime={staffAssistReportedTime}
          pageView={currentPageView === 'list' ? 'map' : 'list'}
          setFilterButtonToggled={setFilterButtonToggled}
          staffFilterState={staffFilterState}
        />
      }
      content={<StaffDetailPane data={selectedStaff} />}
    />
  )
}

export default StaffDetailDrawer
