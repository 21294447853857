import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setCurrentLocationAction } from '../../../../actions/currentLocation'
import {
  deleteAssetWatchlistItemAction,
  deleteStaffWatchlistItemAction,
  postAssetWatchlistItemAction,
  postStaffWatchlistItemAction,
} from '../../../../actions/watchlists'
import {
  buildNavigationUrlToFocusOnAgent,
  shouldDisableNavigationButton,
} from '../../../../helpers/detailDrawer'
import { useAuth } from '../../../../hooks'
import {
  AgentType,
  LocationsState,
  RealTimePageViews,
  TelemetryAsset,
  TelemetryStaff,
  Watchlist,
  WatchlistItem,
} from '../../../../models'
import { DetailDrawerAgentTelemetry } from '../../../../models/telemetry'
import QuickActionAvatarCard, { IconType } from '../../../Cards/QuickActionCard'

const useStyles = makeStyles(() =>
  createStyles({
    buildingTextContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      height: 68,
      marginTop: 13,
    },
    actionName: {
      fontSize: 12,
    },
    centerButton: {
      marginLeft: 12,
      marginRight: 12,
    },
    leftButton: {
      marginLeft: 12,
    },
    rightButton: {
      marginRight: 12,
    },
  })
)

interface Props<T extends TelemetryAsset | TelemetryStaff> {
  data: DetailDrawerAgentTelemetry<T>
  watchlist: Watchlist
  onEditAgent?: () => void
  setFilterButtonToggled: (filterButtonStates: any) => void
  onRemovedFromWatchList: () => void
  pageView: RealTimePageViews
  hasWatchListFilter: boolean
}

function AgentDetailDrawerActionButtons<
  T extends TelemetryAsset | TelemetryStaff
>(props: Props<T>): React.ReactElement {
  const {
    data,
    pageView,
    watchlist,
    hasWatchListFilter,
    onEditAgent,
    setFilterButtonToggled,
    onRemovedFromWatchList,
  } = props
  const { agent, telemetry, resolvedLocationHierachy } = data
  const classes = useStyles()
  const dispatch = useDispatch()
  const { user } = useAuth()
  const history = useNavigate()

  const isAsset = useMemo(() => {
    return agent.agentType === AgentType.Asset
  }, [agent])

  const watchlistItem: WatchlistItem | undefined = useMemo(() => {
    if (!agent) {
      return undefined
    }
    return Object.values(watchlist).find((x) => {
      return x.agentId === agent.agentGuid
    })
  }, [watchlist, agent])

  const locations = useSelector(
    ({ locations }: { locations: LocationsState }) => locations.data
  )

  const handleNavigationClick = (): void => {
    if (
      !telemetry ||
      !resolvedLocationHierachy ||
      telemetry.agent.agentType === undefined
    ) {
      return
    }
    dispatch(
      setCurrentLocationAction(
        locations[resolvedLocationHierachy.floorLocation.id]
      )
    )

    const url = buildNavigationUrlToFocusOnAgent(
      pageView,
      telemetry.agent.agentType,
      telemetry.trackingId
    )
    setFilterButtonToggled({
      assetListPage: undefined,
      mapPage: undefined,
      staffListPage: undefined,
    })
    if (url) {
      history(url)
    }
  }

  const handleWatchListClick = useCallback((): void => {
    if (!!watchlistItem) {
      if (isAsset) {
        dispatch(deleteAssetWatchlistItemAction.request(watchlistItem))
      } else {
        dispatch(deleteStaffWatchlistItemAction.request(watchlistItem))
      }
      if (hasWatchListFilter) {
        onRemovedFromWatchList()
      }
    } else {
      if (!agent) {
        return
      }
      const watchlistItem: WatchlistItem = {
        userId: user.id,
        agentId: agent.agentGuid,
      }
      if (isAsset) {
        dispatch(postAssetWatchlistItemAction.request(watchlistItem))
      } else {
        dispatch(postStaffWatchlistItemAction.request(watchlistItem))
      }
    }
  }, [
    agent,
    hasWatchListFilter,
    dispatch,
    isAsset,
    user.id,
    watchlistItem,
    onRemovedFromWatchList,
  ])

  const disabledViewOnMapOrListButton = useMemo(() => {
    return shouldDisableNavigationButton(
      telemetry,
      resolvedLocationHierachy?.isPrivate
    )
  }, [resolvedLocationHierachy?.isPrivate, telemetry])

  return (
    <div className={classes.buildingTextContainer}>
      {onEditAgent ? (
        <div className={classes.leftButton}>
          <QuickActionAvatarCard
            cardText={
              agent.agentType === AgentType.Asset ? 'Edit Asset' : 'Edit Staff'
            }
            isDisabled={false}
            cardIconType={IconType.EditOutlined}
            onActionAreaClick={onEditAgent}
          ></QuickActionAvatarCard>
        </div>
      ) : (
        <div />
      )}
      <div className={classes.centerButton}>
        <QuickActionAvatarCard
          cardText={pageView === 'list' ? 'View on List' : 'View on Map'}
          cardIconType={
            pageView === 'list' ? IconType.List : IconType.PinDropIcon
          }
          onActionAreaClick={(): void => handleNavigationClick()}
          isDisabled={disabledViewOnMapOrListButton}
        ></QuickActionAvatarCard>
      </div>
      <div className={classes.rightButton}>
        <QuickActionAvatarCard
          cardText={
            !!watchlistItem ? 'Remove from My List' : 'Add to My List' // Used to be called Watchlist
          }
          cardIconType={
            !!watchlistItem
              ? IconType.PlayListAddCheck
              : IconType.PlayListAddIcon
          }
          onActionAreaClick={(): void => handleWatchListClick()}
        ></QuickActionAvatarCard>
      </div>
    </div>
  )
}

export default AgentDetailDrawerActionButtons
