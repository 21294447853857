import { FetchingState } from '../interfaces/fetchingState'
import { HttpParams } from '../services/http'

export enum AgentEventTypeId {
  StaffDuress,
  StaffAssist,
  EntersLocation,
  ExitsLocation,
}

export interface AgentEventView {
  eventId: string
  eventTypeId: AgentEventTypeId
  eventType: string
  alertConfigurationId: string
  alertConfiguration: string
  alertTypeId: number
  alertType: string
  createdDate: string
  displayName: string
  typeId: number | null
  type: string
  model: string
  serialNumber: string
  trackingId: string
  battery: number | null
  isLowBattery: boolean | null
  batteryTimestamp: string | null
  latestLocationId: string | null
  latestLocation: string
  latestUnitId: string | null
  latestUnit: string
  latestFloorId: string | null
  latestFloor: string
  latestBuildingId: string | null
  latestBuilding: string
  latestTimestamp: string | null
  previousLocationId: string | null
  previousLocation: string
  previousUnitId: string | null
  previousUnit: string
  previousFloorId: string | null
  previousFloor: string
  previousBuildingId: string | null
  previousBuilding: string
  previousTimestamp: string | null
}

export interface AgentEventViewsPaginatedTrafficRequest {
  filters: HttpParams
}

export interface AgentEventViewsByUnitsAndLocationRequest {
  unitIds: string[]
  locationId: string
  floorIds: string[] // the floors contained within selected location
}

export interface AgentEventViewsByUnitsAndLocationPagination {
  currentPage: number
  pageSize: number
  hasMore: boolean
  totalCount: number
  agentEventViews: AgentEventView[]
  cacheKey: string
  lastTimePeriod: number
}

export type AgentEventViewsByUnitsAndLocationState = FetchingState<
  Readonly<AgentEventViewsByUnitsAndLocationPagination>
>
