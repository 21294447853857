import { getType } from 'typesafe-actions'
import { AlertSubscriptionKeyAction } from '../actions'
import { getAlertSubscriptionKeysAction } from '../actions/alertSubscriptionKeys'
import {
  AlertSubscriptionKey,
  AlertSubscriptionKeys,
  AlertSubscriptionKeyState,
  FetchingStatus,
} from '../models'
import { mergeRecords, setFetchingStatus } from '../utils'

const initialState: AlertSubscriptionKeyState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

export default (
  state: AlertSubscriptionKeyState = initialState,
  action: AlertSubscriptionKeyAction
): AlertSubscriptionKeyState => {
  switch (action.type) {
    case getType(getAlertSubscriptionKeysAction.success):
      return {
        data: mergeRecords<
          AlertSubscriptionKeys,
          AlertSubscriptionKey,
          'partnerLocationId'
        >(state.data, action.payload, 'partnerLocationId'),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getAlertSubscriptionKeysAction.request):
      return setFetchingStatus<AlertSubscriptionKeys>(
        state,
        FetchingStatus.Request
      )
    case getType(getAlertSubscriptionKeysAction.failure):
      return setFetchingStatus<AlertSubscriptionKeys>(
        state,
        FetchingStatus.Failure
      )
    default:
      return state
  }
}
