import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { actionBarContainerMinHeight } from '../components/ActionBar'
import {
  staffAssistPopperAnchorRightOffset,
  staffAssistPopperAnchorTopOffset,
} from '../components/Alerts/StaffAssist'
import { fixedBannerHeight } from '../components/Banners'
import { headerMaxHeight } from '../components/Common'
import { rightDrawerWidth } from '../components/Common/RightDrawer'
import { epicTableFooterHeight } from '../components/Tables'
import { CommonRouteKeys } from '../constants'
import { StaffEvent } from '../models'
import { PageLayoutState } from '../models/pageLayout'

export interface StaffAssistModalHeightOffset {
  duressBanner: number
  primaryHeader: number
  secondaryHeader: number
  epicToolbarFooter: number
}

export const useStaffAssistAlertModal = (
  headerRef: React.RefObject<HTMLDivElement>,
  duressNotifications: StaffEvent[]
) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const [staffAssistModalTopOffset, setStaffAssistModalTopOffset] = useState(
    actionBarContainerMinHeight + staffAssistPopperAnchorTopOffset
  )
  const [staffAssistModalRightOffset, setStaffAssistModalRightOffset] =
    useState(staffAssistPopperAnchorRightOffset)
  const [staffAssistHeightOffset, setStaffAssistHeightOffset] =
    useState<StaffAssistModalHeightOffset>({
      duressBanner: 0,
      primaryHeader: headerMaxHeight,
      secondaryHeader: 0,
      epicToolbarFooter: 0,
    })
  const [
    mostRecentStaffAssistModalHeight,
    setMostRecentStaffAssistModalHeight,
  ] = useState<number>(0)
  const [systemMaintenanceModalHeight, setSystemMaintenanceModalHeight] =
    useState<number>(0)
  const [showAllStaffAssistNotifications, setShowAllStaffAssistNotifications] =
    useState(false)
  const [showDuressBanner, setShowDuressBanner] = useState(false)

  const { pathname } = useLocation()
  const rightDrawerOpen = useSelector(
    ({ pageLayout }: { pageLayout: PageLayoutState }) =>
      pageLayout.rightDrawerOpen
  )

  useEffect(() => {
    setAnchorEl(headerRef.current ?? null)
  }, [headerRef, setAnchorEl])

  useEffect(() => {
    if (!rightDrawerOpen) {
      setStaffAssistModalRightOffset(staffAssistPopperAnchorRightOffset)
    } else {
      setStaffAssistModalRightOffset(-1 * rightDrawerWidth)
    }
  }, [rightDrawerOpen])

  useEffect(() => {
    if (
      pathname === CommonRouteKeys.DashboardPage.path ||
      pathname === CommonRouteKeys.DownForMaintenance.path
    ) {
      setStaffAssistModalTopOffset(staffAssistPopperAnchorTopOffset)
      setStaffAssistHeightOffset((offset) => ({
        ...offset,
        secondaryHeader: 0,
        epicToolbarFooter: 0,
      }))
    } else {
      setStaffAssistModalTopOffset(
        actionBarContainerMinHeight + staffAssistPopperAnchorTopOffset
      )
      setStaffAssistHeightOffset((offset) => ({
        ...offset,
        secondaryHeader: actionBarContainerMinHeight,
        epicToolbarFooter: epicTableFooterHeight,
      }))
    }
  }, [pathname, setStaffAssistHeightOffset, setStaffAssistModalTopOffset])

  useEffect(() => {
    if (showDuressBanner) {
      setStaffAssistHeightOffset((offset) => ({
        ...offset,
        duressBanner: fixedBannerHeight,
      }))
    } else if (!showDuressBanner) {
      setStaffAssistHeightOffset((offset) => ({
        ...offset,
        duressBanner: 0,
      }))
    }
  }, [showDuressBanner])

  useEffect(() => {
    if (duressNotifications.length > 0) {
      setShowDuressBanner((isShown) => {
        if (!isShown) {
          return true
        } else {
          return isShown
        }
      })
    }
    if (duressNotifications.length === 0) {
      setShowDuressBanner((isShown) => {
        if (isShown) {
          return false
        } else {
          return isShown
        }
      })
    }
  }, [duressNotifications])

  return {
    anchorEl,
    staffAssistHeightOffset,
    staffAssistModalTopOffset,
    staffAssistModalRightOffset,
    mostRecentStaffAssistModalHeight,
    systemMaintenanceModalHeight,
    showAllStaffAssistNotifications,
    setMostRecentStaffAssistModalHeight,
    setSystemMaintenanceModalHeight,
    setShowAllStaffAssistNotifications,
  }
}
