import React, { ForwardRefExoticComponent } from 'react'
import { LinkProps, Link } from 'react-router-dom'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RouterLink = (to: string): ForwardRefExoticComponent<any> =>
  // eslint-disable-next-line react/display-name, @typescript-eslint/no-explicit-any
  React.forwardRef<any, Omit<LinkProps, 'to'>>((itemProps, ref) => (
    <Link className='SubmoduleLink' to={to} ref={ref} {...itemProps} />
  ))

export default RouterLink
