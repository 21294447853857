import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Tooltip } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import icons from '../../models/icons'
import { useDispatch, useSelector } from 'react-redux'
import { LocationsState } from '../../models/locations'
import { setCurrentLocationAction } from '../../actions/currentLocation'
import { buildURL } from '../../helpers'
import { AgentType } from '../../models'
import { LocationConstants } from '../../constants'

interface Props {
  room: string | undefined
  badgeId: string | ''
  size?: 'small' | 'medium'
  signalStatus?: string | ''
  floorLocationId: string | ''
  isPrivateRoom?: boolean
}

const StaffListViewStaffButton = (props: Props) => {
  const {
    room,
    badgeId,
    signalStatus,
    floorLocationId,
    size = 'medium',
    isPrivateRoom,
  } = props
  const zoom = 10
  let iconValue = icons.viewAsset
  let disabled = false

  const dispatch = useDispatch()
  const history = useNavigate()
  const locations = useSelector(
    ({ locations }: { locations: LocationsState }) => locations.data
  )

  if (
    room === undefined ||
    signalStatus === 'Timed-Out' ||
    !floorLocationId ||
    room === LocationConstants.PrivateDescription
  ) {
    iconValue = icons.viewAssetDisabled
    disabled = true
  }

  let title = 'View on Map'

  if (isPrivateRoom) {
    title = 'Unavailable'
  }

  const handleClick = (event: any) => {
    event.preventDefault()
    event.stopPropagation()

    if (disabled === false) {
      dispatch(setCurrentLocationAction(locations[floorLocationId]))

      history(
        buildURL('/realtime', [
          ['view', 'map'],
          ['agentType', AgentType[AgentType.Staff]],
          ['badgeId', badgeId],
          ['zoom', zoom.toString()],
        ])
      )
    }
  }

  return (
    <Tooltip title={title}>
      <IconButton color='primary' onClick={handleClick} size={size}>
        <div
          style={{
            height: 24,
            width: 24,
            display: 'flex',
          }}
          dangerouslySetInnerHTML={{ __html: iconValue }}
        />
      </IconButton>
    </Tooltip>
  )
}

export default StaffListViewStaffButton
