import { createAction } from 'typesafe-actions'
import { SilentRefreshStatus } from '../models'

export const trackSilentUpdateRequest = createAction(
  'TRACK_SILENT_UPDATE_REQUEST'
)<string[]>()

export const trackSilentUpdateSuccess = createAction(
  'TRACK_SILENT_UPDATE_SUCCESS'
)<string[]>()

export const setSilentRefreshStatus = createAction(
  'SET_SILENT_UPDATE_STATUS'
)<SilentRefreshStatus>()
