import { useEffect, useState } from 'react'
import jmap from 'jmap.js'
import { InpixonEntity } from '../../models'

interface Props {
  config: object
  venueId: number
  mapId: number | undefined // inpixon map id

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onMapShow: (jibestream: any) => void
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useJibestream({
  config,
  venueId,
  mapId,
  onMapShow,
}: Props): any {
  const [jibestream, setJibestream] = useState<any>({}) // eslint-disable-line @typescript-eslint/no-explicit-any
  const [showMap, setShowMap] = useState(false)

  useEffect(() => {
    return (): void => {
      if (jibestream !== undefined && showMap) {
        if (jibestream.control !== undefined) {
          jibestream?.control?.destroyActiveVenue()
        }
        if (jibestream.core !== undefined) {
          jibestream.core.clearAllCache()
        }
        setShowMap(false)
      }
    }
  }, [jibestream, showMap])

  // initialize jibestream
  useEffect(() => {
    const core = new jmap.core.JCore({ ...config })

    // create an ActiveVenue & render map
    // https://inpixon.zendesk.com/hc/en-us/articles/360016404071-Installation-and-Setup-Web
    core.populateVenueByVenueId(venueId, (error: any, activeVenue: any) => {
      if (error) {
        console.error(error)
      }

      // populate the default Building
      const buildingId = activeVenue.buildings.getDefault().id
      if (
        activeVenue &&
        buildingId &&
        document.getElementsByClassName('map').length > 0
      ) {
        return core.populateBuildingInVenue(
          activeVenue,
          buildingId,
          (error2: any) => {
            if (error2) {
              console.error(error2)
            }

            try {
              const control = new jmap.JController({
                activeVenue,
              })

              // allow in-lay labels to appear if configured in CMS
              control.applyDisplayModeToAllUnits()

              setJibestream({ core, control })
            } catch (error) {
              console.log('Caught DOMElement Error')
            }
          }
        )
      } else if (document.getElementsByClassName('map').length === 0) {
        console.log('Waiting for map element to render...')
      }
    })

    return (): void => {
      setJibestream({})
    }
  }, [config, venueId])

  // handle map show
  useEffect(() => {
    if (
      mapId &&
      jibestream &&
      jibestream?.control &&
      jibestream?.control?.activeVenue?.maps
        ?.getAll()
        ?.find((x: InpixonEntity) => x.id === mapId)
    ) {
      try {
        const activeVenue = jibestream.control.activeVenue
        const map = activeVenue.maps
          .getAll()
          .find((x: InpixonEntity) => x.id === mapId)
        if (map) {
          jibestream.control.showMap(map)
        } else {
          console.log('An Error Occured Showing the map')
        }
      } catch (error) {
        console.error(error)
      }
    }
  }, [mapId, jibestream, onMapShow])

  useEffect(() => {
    if (
      jibestream?.control?.currentMapView?.visible &&
      jibestream?.control?.currentMapView?.container &&
      !showMap
    ) {
      setShowMap(true)
      onMapShow(jibestream)
    }
  }, [jibestream, mapId, onMapShow, showMap])

  return jibestream
}
