import { Asset, FetchingStatus, Location, Staff } from '../models'

interface AgentsByLocation<T extends Asset | Staff> {
  [locationId: string]: T[]
}

export function filterAgentsByLocation<T extends Asset | Staff>(
  status: FetchingStatus | undefined,
  currentLocation: Location | null,
  allAgentsByLocations: AgentsByLocation<T> | undefined
): T[] {
  if (
    status !== FetchingStatus.Success ||
    currentLocation === null ||
    !allAgentsByLocations ||
    !Object.keys(allAgentsByLocations).includes(currentLocation.id)
  ) {
    return []
  }

  return allAgentsByLocations[currentLocation.id]
}
