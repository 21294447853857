import { createAction } from 'typesafe-actions'
import { Coordinates } from '@midmarkrtls/common/models'

export const addToQueueAction = createAction('ADD_TO_QUEUE')<{
  badgeID: string
  coordinates: Coordinates
  destinationId: number
}>()

export const removeFromQueueAction = createAction('REMOVE_FROM_QUEUE')<string>()
