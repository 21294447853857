import { CurrentUserLocation } from '../models/currentUserLocation'
import { BaseService } from './baseService'

export class CurrentUserLocationApi extends BaseService {
  constructor() {
    super({ entityType: 'currentUserLocation' })
  }
  route = 'users'

  async getCurrentUserLocation(userId: string): Promise<CurrentUserLocation> {
    return await this.get<CurrentUserLocation>(
      `${this.route}/${userId}/location`
    )
  }
}
