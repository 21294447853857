import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCloudReportingPartnerAction } from '../actions/cloudReporting'
import { CloudReportingPartner, FetchingStatus } from '../models'
import {
  CloudReportingState,
  FetchCloudReportingErrorTypes,
} from '../models/cloudReporting'

export interface FetchCloudReportingPartnerInfoResult {
  status: FetchingStatus | undefined
  partner: CloudReportingPartner | undefined
  errorType: FetchCloudReportingErrorTypes
}

const useFetchCloudReportingPartner = (
  partnerId: string
): FetchCloudReportingPartnerInfoResult => {
  const dispatch = useDispatch()
  const cloudReporting = useSelector(
    ({ cloudReporting }: { cloudReporting: CloudReportingState }) =>
      cloudReporting
  )

  useEffect(() => {
    dispatch(getCloudReportingPartnerAction.request(partnerId))
  }, [dispatch, partnerId])

  const errorType = !cloudReporting.error
    ? FetchCloudReportingErrorTypes.None
    : cloudReporting.error.status === 404
    ? FetchCloudReportingErrorTypes.NotFound
    : FetchCloudReportingErrorTypes.Unknown

  return {
    status: cloudReporting.status,
    partner: cloudReporting.data.partner,
    errorType,
  }
}

export { useFetchCloudReportingPartner }
