import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { StaffTypeAction } from '../actions'
import { getAllStaffTypesAction } from '../actions/staffTypes'
import { StaffTypesApi } from '../middleware'
import { StaffTypesState } from '../models'

type StaffTypeEpic = Epic<
  StaffTypeAction,
  StaffTypeAction,
  StaffTypesState,
  { staffTypesApi: StaffTypesApi }
>

export const getAllStaffTypesEpic: StaffTypeEpic = (
  action$,
  state,
  { staffTypesApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllStaffTypesAction.request)),
    mergeMap((action) =>
      from(staffTypesApi.getAllStaffTypes()).pipe(
        map((staffTypes) => getAllStaffTypesAction.success(staffTypes)),
        catchError((error) => of(getAllStaffTypesAction.failure(error)))
      )
    )
  )
}
