import createStyles from '@mui/styles/createStyles'

export interface TableTheme {
  spacing: number
  cellHeight: number
  color: string
  headerColor: string
  backgroundColor: string
  borderColor: string
}

export const defaultTheme: TableTheme = {
  spacing: 24,
  cellHeight: 48,
  color: '#333333',
  headerColor: '#666666',
  backgroundColor: '#f2f2f2',
  borderColor: '#c4c4c4',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useTableStyles = (tableTheme: TableTheme = defaultTheme): any =>
  createStyles({
    table: {
      background: tableTheme.backgroundColor,
      color: tableTheme.color,
    },
    tableRow: {
      background: tableTheme.backgroundColor,
      '&:nth-of-type(odd)': {
        backgroundColor: '#ffffff',
      },
      '&:last-child': {
        borderBottom: `1px solid ${tableTheme.borderColor}`,
      },
      '&.MuiTableRow-hover:hover': {
        cursor: 'pointer',
        backgroundColor: '#54C8E826',
      },
      '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: '#54C8E84D',
      },
    },
    tableCell: {
      paddingTop: 0,
      paddingBottom: 0,
      borderBottomWidth: 0,
      height: tableTheme.cellHeight,
      fontSize: 14,
      padding: 10,
      whiteSpace: 'pre-wrap',
      // Uncomment to enforce no text wrapping
      // textOverflow: 'ellipsis',
      // overflow: 'hidden',
      // whiteSpace: 'nowrap',
      '&:first-child': {
        paddingLeft: tableTheme.spacing,
        whiteSpace: 'normal',
      },
      '&:last-child': {
        paddingRight: '7px',
        whiteSpace: 'normal',
      },
    },
    tableHeadCell: {
      textTransform: 'uppercase',
      fontSize: 12,
      color: tableTheme.headerColor,
      backgroundColor: tableTheme.backgroundColor,
      borderBottom: `1px solid ${tableTheme.borderColor}`,
      'user-select': 'none',
      fontWeight: 'bold',
      lineHeight: 'unset',
      paddingBottom: '10px',
      verticalAlign: 'bottom',
      // Uncomment to enforce no text wrapping
      //textOverflow: 'ellipsis',
      //overflow: 'hidden',
      whiteSpace: 'normal',
    },
    container: {
      color: tableTheme.color,
      backgroundColor: tableTheme.backgroundColor,
    },
    title: {
      fontSize: 24,
    },
  })
