import { CSSProperties } from '@mui/material/styles'
import { CssSize } from '.'

export const layout = {
  fillContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },
  container: {
    position: 'relative',
  },
}

export const sizeWithUnit = (size: CssSize, unit = 'px'): string => {
  let sizeWithUnit = size.toString()
  if (typeof size === 'number') {
    sizeWithUnit += unit
  }

  return sizeWithUnit
}

type cssSelectorTransformer = (selector: string) => string

const addPrefixToSelector = (
  selector: string,
  prefix: string,
  match?: RegExp
): string => {
  const prefixRegex = new RegExp(`^(${prefix})`)
  return `${prefix}${selector.replace(match || prefixRegex, '')}`
}

const addAmpersandToSelector = (selector: string): string => {
  return addPrefixToSelector(selector, '&')
}

const fixPseudoSelector = (selector: string): string => {
  //return `&:${selector.replace(/^(&*:*)/, '')}`
  return addPrefixToSelector(selector, '&:', /^(&*:*)/)
}

export const withAdditionalTransformedRules = <
  Selector extends string,
  Props extends {}
>(
  props: Props,
  selectors: Selector[],
  transformer: cssSelectorTransformer = addAmpersandToSelector
): CSSProperties => {
  const transformedSelectors = selectors
    .map((selector) => transformer(selector))
    .join(',')
  return {
    [transformedSelectors]: props,
  }
}

export const withAdditionalRules = <Selector extends string, Props extends {}>(
  props: Props,
  ...selectors: Selector[]
): CSSProperties => {
  return withAdditionalTransformedRules(
    props,
    selectors,
    addAmpersandToSelector
  )
}

export const withChildStyles = <Selector extends string, Props extends {}>(
  props: Props,
  ...selectors: Selector[]
): CSSProperties => {
  if (!selectors?.length) {
    selectors = ['*' as Selector]
  }
  return withAdditionalTransformedRules(
    props,
    selectors.map((selector) => addPrefixToSelector(selector, '>')),
    addAmpersandToSelector
  )
}

export const withPseudoSelectors = <Selector extends string, Props extends {}>(
  props: Props,
  ...selectors: Selector[]
): CSSProperties => {
  return withAdditionalTransformedRules(props, selectors, fixPseudoSelector)
}
