import { FetchingState } from '../interfaces/fetchingState'
import { BadgeEventTelemetryMessage } from './badgeTelemetry'

export interface StaffEvent {
  status: EventStatus
  resolved: boolean
  floorName: string
  floorLocationId: string
  roomName: string
  reportedBy: string
  reportedTime: Date | undefined
  badgeId: string
  id: string
  agentEventTypeId: number
  agentId: string
  alertId: string
  badgeEventTelemetry?: BadgeEventTelemetryMessage
}

export interface StaffEvents {
  [badgeId: string]: StaffEvent
}

export type StaffEventsState = Readonly<FetchingState<StaffEvents>>

export enum EventStatus {
  DISMISSED,
  RESOLVED,
  ACTIVE,
}

export type StaffDuressAndAssistOptions = {
  staffDuressAndAssistFeatureFlagEnabled: boolean
  hasActiveDuress: boolean
  hasActiveAssist: boolean
  hasResolvedDuressOrAssist: boolean
  isSelected: boolean
}
