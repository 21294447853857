import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { TriggerAction } from '../actions'
import { getAllTriggersAction } from '../actions/triggers'
import { TriggersApi } from '../middleware/triggers'
import { TriggerState } from '../models/trigger'

type TriggerEpic = Epic<
  TriggerAction,
  TriggerAction,
  TriggerState,
  { triggersApi: TriggersApi }
>

export const getAllTriggersEpic: TriggerEpic = (
  action$,
  state,
  { triggersApi: triggersApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllTriggersAction.request)),
    mergeMap((action) =>
      from(triggersApi.getAllTriggers()).pipe(
        map((triggers) => getAllTriggersAction.success(triggers)),
        catchError((error) => of(getAllTriggersAction.failure(error)))
      )
    )
  )
}
