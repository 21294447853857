import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { SensoryReferenceAction } from '../actions'
import { SensoryReferencesState } from '../models/SensoryReference'
import {
  getAllSensoryReferenceByTenantIdAction,
  postImportSensoryReferenceAction,
} from '../actions/sensoryReference'
import { SensoryReferenceApi } from '../middleware'

type SensoryReferenceEpic = Epic<
  SensoryReferenceAction,
  SensoryReferenceAction,
  SensoryReferencesState,
  { sensoryReferenceApi: SensoryReferenceApi }
>

export const getAllSensoryReferenceByTenantIdEpic: SensoryReferenceEpic = (
  action$,
  _state,
  { sensoryReferenceApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllSensoryReferenceByTenantIdAction.request)),
    mergeMap((action) =>
      from(sensoryReferenceApi.getAllSensoryReferences()).pipe(
        map((sensoryReferences) =>
          getAllSensoryReferenceByTenantIdAction.success(sensoryReferences)
        ),
        catchError((error) =>
          of(getAllSensoryReferenceByTenantIdAction.failure(error))
        )
      )
    )
  )
}

export const postImportSensoryReferenceEpic: SensoryReferenceEpic = (
  action$,
  state,
  { sensoryReferenceApi }
) => {
  return action$.pipe(
    filter(isActionOf(postImportSensoryReferenceAction.request)),
    mergeMap((action) =>
      from(
        sensoryReferenceApi.postImportSensoryReferences(action.payload)
      ).pipe(
        map((sensoryReferences) =>
          postImportSensoryReferenceAction.success(sensoryReferences)
        ),
        catchError((error) =>
          of(postImportSensoryReferenceAction.failure(error))
        )
      )
    )
  )
}
