import { getType } from 'typesafe-actions'
import { IssuesAction } from '../actions'
import { mergeRecords, mergeRecord } from '../utils'
import { Issues, Issue, IssuesState, FetchingStatus } from '../models'
import {
  getAllIssuesAction,
  putIssueAction,
  postIssuesAction,
  putIssuesAction,
} from '../actions/issues'

const initialState: IssuesState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

export default (state = initialState, action: IssuesAction): IssuesState => {
  switch (action.type) {
    case getType(postIssuesAction.success):
    case getType(putIssuesAction.success):
    case getType(getAllIssuesAction.success):
      return {
        data: mergeRecords<Issues, Issue, 'issueGuid'>(
          state.data,
          action.payload,
          'issueGuid'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(putIssueAction.success):
      return {
        data: mergeRecord<Issues, Issue, 'issueGuid'>(
          state.data,
          action.payload,
          'issueGuid'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(postIssuesAction.request):
    case getType(putIssuesAction.request):
    case getType(getAllIssuesAction.request):
    case getType(putIssueAction.request):
      return {
        ...state,
        status: FetchingStatus.Request,
      }
    case getType(postIssuesAction.failure):
    case getType(putIssuesAction.failure):
    case getType(getAllIssuesAction.failure):
    case getType(putIssueAction.failure):
      return {
        ...state,
        status: FetchingStatus.Failure,
      }
    default:
      return state
  }
}
