import { Users, UsersState } from '../../models'
import { getAllUsersAction } from '../../actions/users'
import { EntityResult, useEntities } from '../useEntities'

const useFetchUsers = (): EntityResult<Users> =>
  useEntities<UsersState, Users>(
    () => getAllUsersAction.request(),
    (state: any) => state.users // eslint-disable-line @typescript-eslint/no-explicit-any
  )

export { useFetchUsers }
