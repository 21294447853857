import React, { ReactNode, useCallback } from 'react'
import { List, Map } from '@mui/icons-material'
import { CommonComponent, ComponentProps } from '../../components'
import { FilterToggleButton, PageViewNav } from '../../components/ActionBar'
import { PageToolbar } from '../../components/Layout'
import { LiveTelemetryIndicator } from './LiveTelemetryIndicator'
import { AppTypeEnum, RealTimePageViews } from '../../models'
import { setSelectedTrackingIdAction } from '../../actions/mapGroups'
import { useDispatch } from 'react-redux'

interface Props {
  floorSelector?: ReactNode
  unitSelector?: ReactNode
  listSelector?: ReactNode
  filterCount?: number
  activeView?: RealTimePageViews
  onViewChange: (view: RealTimePageViews) => void
  onFilterButtonClicked: () => void
}

const RealTimeToolbar: CommonComponent<Props> = (
  props: ComponentProps<Props>
) => {
  const {
    onViewChange,
    activeView,
    filterCount,
    floorSelector,
    listSelector,
    unitSelector,
    onFilterButtonClicked,
  } = props
  const definedAppType = process.env.REACT_APP_APP_TYPE
  const handleViewChange = (view: RealTimePageViews): void => {
    onViewChange && onViewChange(view)
  }

  const dispatch = useDispatch()

  const filterToggleClickHandler = useCallback(() => {
    onFilterButtonClicked()
    dispatch(setSelectedTrackingIdAction(undefined))
  }, [dispatch, onFilterButtonClicked])

  return (
    <PageToolbar searchStorageName={RealTimeToolbar.name}>
      {{
        navigation: (
          <PageViewNav
            defaultView='list'
            view={activeView}
            onViewChange={(view): void => handleViewChange(view)}
            items={[
              {
                view: 'list',
                label: 'List',
                icon: <List />,
              },
              {
                view: 'map',
                label: 'Map',
                icon: <Map />,
              },
            ]}
          ></PageViewNav>
        ),
        filterButton: (
          <FilterToggleButton
            filterCount={filterCount}
            onClick={filterToggleClickHandler}
          ></FilterToggleButton>
        ),
        actions: (
          <>
            {activeView === 'list' && unitSelector}
            {definedAppType === AppTypeEnum.Operations && (
              <LiveTelemetryIndicator />
            )}
            {listSelector}
            {floorSelector}
          </>
        ),
      }}
    </PageToolbar>
  )
}

export default RealTimeToolbar
