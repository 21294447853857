import { createAsyncAction } from 'typesafe-actions'
import { FilterCreateModel, FilterDeleteModel, FilterModel } from '../models'
import { HttpErrorResponse } from '../services/http'

export const getFiltersAction = createAsyncAction(
  'GET_FILTERS_BY_USER_REQUEST',
  'GET_FILTERS_BY_USER_SUCCESS',
  'GET_FILTERS_BY_USER_FAILURE'
)<string, FilterModel[], HttpErrorResponse>()

export const deleteFiltersAction = createAsyncAction(
  'DELETE_FILTER_REQUEST',
  'DELETE_FILTER_SUCCESS',
  'DELETE_FILTER_FAILURE'
)<FilterDeleteModel, FilterDeleteModel, HttpErrorResponse>()

export const postFiltersAction = createAsyncAction(
  'POST_FILTER_REQUEST',
  'POST_FILTER_SUCCESS',
  'POST_FILTER_FAILURE'
)<FilterCreateModel, FilterModel, HttpErrorResponse>()
