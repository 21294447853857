import { getAllUsersAction } from '../actions/user'
import { getType } from 'typesafe-actions'
import { JibestreamUsers, JibestreamUser } from '../models/user'
import { JibestreamUserAction } from '../actions'

export type JibestreamUserState = Readonly<JibestreamUsers>

const jibestreamUserReducer = (
  state: JibestreamUserState = {},
  action: JibestreamUserAction
): JibestreamUserState => {
  switch (action.type) {
    case getType(getAllUsersAction.success):
      return mergeJibestreamUser(state, action.payload)
    default:
      return state
  }
}

const mergeJibestreamUser = (
  state: JibestreamUserState,
  users: JibestreamUsers
): JibestreamUserState => {
  return Object.values(users).reduce(
    (acc: Record<number, JibestreamUser>, user: JibestreamUser) => {
      return {
        ...acc,
        [user.id]: user,
      }
    },
    { ...state }
  )
}

export default jibestreamUserReducer
