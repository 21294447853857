import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import AssetDetailDrawer from '../../../components/Common/AssetStaff/DetailDrawer/AssetDetailDrawer'
import MapGroupDetailDrawer from '../../../components/Common/AssetStaff/DetailDrawer/MapGroupDetailDrawer'
import StaffDetailDrawer from '../../../components/Common/AssetStaff/DetailDrawer/StaffDetailDrawer'
import AssetStaffFilterDrawer from '../../../components/Common/AssetStaff/FilterDrawer/AssetStaffFilterDrawer'
import LocationSelectionError from '../../../components/Common/LocationSelectionError'
import ErrorBoundaryModal from '../../../components/ErrorBoundaryModal'
import { PageContent } from '../../../components/Layout'
import { DrawerType } from '../../../components/Layout/PageContent'
import LoadingIndicator from '../../../components/LoadingIndicator'
import { calculateUpdatesToRemoveIconHighlight } from '../../../helpers/calculateRTMapUpdates'
import {
  mapBadgeTelemetryToDetailDrawerAgentTelemetry,
  mapGroupedTelemetryToDetailDrawerGroupTelemetry,
} from '../../../helpers/detailDrawer'
import {
  removeAgentConfidenceBubble,
  updateInpixonGroupIcon,
} from '../../../helpers/inpixon'
import {
  selectAgentOnMap,
  selectGroupOnMap,
} from '../../../helpers/processRTMapUpdates'
import {
  RealTimeFilterStates,
  useAuth,
  useFetchGeofencesByLocation,
  useFetchWatchlist,
} from '../../../hooks'
import { useInpixonContext } from '../../../hooks/inpixon'
import {
  AssetFilterConfigs,
  AssetInstance,
  AssetKitInstance,
  FetchingStatus,
  IconType,
  Location,
  LocationGeofences,
  LocationType,
  Locations,
  StaffFilterConfigs,
  TelemetryAsset,
  TelemetryStaff,
  Unit,
} from '../../../models'
import { FilterType } from '../../../models/filters'
import { PageLayoutState } from '../../../models/pageLayout'
import { MapRender, MapRenderByGeofence } from '../../../models/realtimeMap'
import {
  BadgeTelemetryMessageWithAgentEvent,
  DetailDrawerAgentTelemetry,
} from '../../../models/telemetry'
import RealTimeMapView, { RealTimeMapViewProps } from '../RealTimeMapView'
import { useRealTimeMapRightDrawer } from './useRealTimeMapPageRightDrawer'
import { MapRenderProvider } from './useRealTimeMapRenderContext'
import { selectStaffAssistState } from '../../../selectors'

interface Props
  extends Omit<
    RealTimeMapViewProps,
    | 'rightDrawerState'
    | 'assetFilterProvider'
    | 'staffFilterProvider'
    | 'onSelectAgent'
    | 'onSelectGroupOnMap'
    | 'onSelectAgentTypes'
    | 'moveSelectedAgentOutOfGroup'
    | 'updateSelectedAgentTelemetry'
    | 'removeAgentFromSelectedGroup'
    | 'addAgentToSelectedGroup'
    | 'updateAgentInSelectedGroup'
    | 'removeSelectedAgent'
    | 'selectAgentInGroup'
    | 'resetRightDrawerState'
  > {
  initialized: boolean
  currentLocation: Location | null
  assetFilterOptions: AssetFilterConfigs
  staffFilterOptions: StaffFilterConfigs
  units: Unit[]
  filterStates: RealTimeFilterStates
  filterButtonToggled: boolean | undefined
  locations: Locations
  setFilterButtonToggled: (filterButtonStates: any) => void
}

const RealTimeMapPage = (props: Props): JSX.Element => {
  const {
    initialized,
    venueId,
    currentLocation,
    assetsByLocation,
    isAssetsByLocationFetched,
    assetsStatus,
    agentTypes,
    staffByLocation,
    isStaffsByLocationFetched,
    staffStatus,
    currentFloor,
    assetFilterOptions,
    staffFilterOptions,
    units,
    filterButtonToggled,
    filterStates,
    locations,
    setFilterButtonToggled,
  } = props
  const { manufacturers } = assetFilterOptions
  const {
    clearAssetFilters,
    clearStaffFilters,
    assetFilterProvider,
    staffFilterProvider,
    assetFilterState,
    staffFilterState,
  } = filterStates

  const maxHeight = useSelector(
    ({ pageLayout }: { pageLayout: PageLayoutState }) => pageLayout.maxHeight
  )

  const buildingSelected = currentLocation
    ? currentLocation?.locationType > LocationType.BuildingGroup
    : false

  const { user } = useAuth()
  const { data: watchlist } = useFetchWatchlist(user.id)
  const { activeAssistEvents } = useSelector(selectStaffAssistState)
  const { data: locationGeofences, status: locationGeofencesFetchingStatus } =
    useFetchGeofencesByLocation(
      currentLocation?.parentId ?? currentLocation?.id ?? ''
    )
  const {
    rightDrawerState,
    openAssetDetailDrawer,
    openStaffDetailDrawer,
    openGroupDetailDrawer,
    selectAgentFromGroupDetailDrawer,
    closeDetailDrawer,
    toggleFilterDrawer,
    closeFilterDrawer,
    updateDrawerByAgentTypes,
    updateSelectedAgentTelemetry,
    moveSelectedAgentOutOfGroup,
    removeAgentFromSelectedGroup,
    addAgentToSelectedGroup,
    updateAgentInSelectedGroup,
    removeSelectedAgent,
    selectAgentInGroup,
    resetRightDrawerState,
  } = useRealTimeMapRightDrawer()
  const {
    currentDrawerType,
    selectedAgent: currentlySelectedAgent,
    selectedGroup: currentlySelectedGroup,
  } = rightDrawerState
  const { inpixonState } = useInpixonContext()
  const { assetKit, jibestream } = inpixonState

  const assetKitRef = useRef<AssetKitInstance>()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const jibestreamControllerRef = useRef<any>(undefined)
  const locationsRef = useRef<Locations>(locations)
  const locationGeofencesRef = useRef<LocationGeofences>({})
  locationGeofencesRef.current = locationGeofences

  assetKitRef.current = assetKit
  jibestreamControllerRef.current = jibestream?.control
  locationsRef.current = locations

  const allDataFetched = useMemo(() => {
    return (
      initialized && locationGeofencesFetchingStatus !== FetchingStatus.Request
    )
  }, [initialized, locationGeofencesFetchingStatus])

  const selectedAgentTelemetry = useMemo(() => {
    if (!currentlySelectedAgent) {
      return
    }
    return mapBadgeTelemetryToDetailDrawerAgentTelemetry(
      currentlySelectedAgent.telemetry,
      locationGeofencesRef.current,
      locationsRef.current
    )
  }, [currentlySelectedAgent])

  const selectedGroupTelemetry = useMemo(() => {
    if (!currentlySelectedGroup) {
      return
    }
    return mapGroupedTelemetryToDetailDrawerGroupTelemetry(
      currentlySelectedGroup.groupTelemetries,
      locationGeofencesRef.current,
      locationsRef.current
    )
  }, [currentlySelectedGroup])

  const clearFilters = useCallback(() => {
    clearAssetFilters()
    clearStaffFilters()
  }, [clearAssetFilters, clearStaffFilters])

  const handleCloseDetailDrawer = useCallback(() => {
    const prevSelectedIconUpdates = calculateUpdatesToRemoveIconHighlight(
      currentlySelectedAgent?.inpixonAsset,
      currentlySelectedGroup?.inpixonAsset
    )
    closeDetailDrawer()
    if (assetKitRef.current && jibestreamControllerRef.current) {
      const {
        groupsIconToRemoveHighlight,
        prevSelectedAgentTypeToRemoveConfidenceBubble,
      } = prevSelectedIconUpdates
      if (groupsIconToRemoveHighlight) {
        updateInpixonGroupIcon(
          assetKitRef.current,
          jibestreamControllerRef.current,
          groupsIconToRemoveHighlight.inpixonAssetType,
          groupsIconToRemoveHighlight.url
        )
      }
      if (prevSelectedAgentTypeToRemoveConfidenceBubble) {
        removeAgentConfidenceBubble(
          assetKitRef.current,
          prevSelectedAgentTypeToRemoveConfidenceBubble,
          jibestreamControllerRef.current
        )
      }
    }
  }, [closeDetailDrawer, currentlySelectedAgent, currentlySelectedGroup])

  const handleSelectAgentInGroup = useCallback(
    (agentTelemetry: BadgeTelemetryMessageWithAgentEvent) => {
      if (!assetKitRef.current || !jibestreamControllerRef.current) {
        return
      }
      const groupInpixonAsset = assetKitRef.current.getAssetsByType(
        `${IconType.Group}_${agentTelemetry.geoFenceId.toString()}`
      )
      if (groupInpixonAsset.length === 0) {
        return
      }
      selectAgentFromGroupDetailDrawer(agentTelemetry)
    },
    [selectAgentFromGroupDetailDrawer]
  )

  useEffect(() => {
    if (filterButtonToggled !== undefined) {
      toggleFilterDrawer()

      // remove confidence bubble
      if (assetKitRef.current && jibestreamControllerRef.current) {
        const {
          groupsIconToRemoveHighlight,
          prevSelectedAgentTypeToRemoveConfidenceBubble,
        } = calculateUpdatesToRemoveIconHighlight(
          currentlySelectedAgent?.inpixonAsset,
          currentlySelectedGroup?.inpixonAsset
        )
        if (groupsIconToRemoveHighlight) {
          updateInpixonGroupIcon(
            assetKitRef.current,
            jibestreamControllerRef.current,
            groupsIconToRemoveHighlight.inpixonAssetType,
            groupsIconToRemoveHighlight.url
          )
        }
        if (prevSelectedAgentTypeToRemoveConfidenceBubble) {
          removeAgentConfidenceBubble(
            assetKitRef.current,
            prevSelectedAgentTypeToRemoveConfidenceBubble,
            jibestreamControllerRef.current
          )
        }
      }
    }
  }, [filterButtonToggled, toggleFilterDrawer])

  const mapFilterOptions = useMemo(
    () => ({
      agentTypes,
    }),
    [agentTypes]
  )

  const commonFilterOptions = useMemo(
    () => ({
      hideLowBatterFilter: true,
    }),
    []
  )

  const handleSelectAgentOnMap = useCallback(
    (
      inpixonAsset: AssetInstance,
      agentTelemetry: BadgeTelemetryMessageWithAgentEvent
    ) => {
      selectAgentOnMap(
        inpixonAsset,
        agentTelemetry,
        currentlySelectedAgent?.inpixonAsset,
        currentlySelectedGroup?.inpixonAsset,
        assetKitRef.current,
        jibestreamControllerRef.current,
        openAssetDetailDrawer,
        openStaffDetailDrawer
      )
    },
    [
      openAssetDetailDrawer,
      openStaffDetailDrawer,
      currentlySelectedAgent,
      currentlySelectedGroup,
    ]
  )

  const handleSelectGroupOnMap = useCallback(
    (inpixonAsset: AssetInstance, group: MapRender) => {
      selectGroupOnMap(
        inpixonAsset,
        group,
        currentlySelectedAgent,
        currentlySelectedGroup?.inpixonAsset,
        assetKitRef.current,
        jibestreamControllerRef.current,
        openGroupDetailDrawer
      )
    },
    [openGroupDetailDrawer, currentlySelectedAgent, currentlySelectedGroup]
  )

  const handleSelectAgentTypes = useCallback(
    (agentTypes: string[], updatedMapRender: MapRenderByGeofence) => {
      updateDrawerByAgentTypes({
        agentTypes,
        updatedMapRender,
      })
    },
    [updateDrawerByAgentTypes]
  )

  const staffAssistReportedTime = useMemo(() => {
    if (currentlySelectedAgent) {
      const staffAssist = activeAssistEvents.find(
        (e) => e.badgeId === currentlySelectedAgent.telemetry.trackingId
      )
      if (staffAssist) {
        return staffAssist.reportedTime
      }
    }
  }, [activeAssistEvents, currentlySelectedAgent])

  return (
    <MapRenderProvider>
      <PageContent
        maxHeight={maxHeight}
        currentRightDrawerType={currentDrawerType}
        content={
          !allDataFetched ? (
            <LoadingIndicator
              fetching={!allDataFetched}
              noResultsText='Venues not found'
            />
          ) : !venueId || !buildingSelected ? (
            <LocationSelectionError />
          ) : (
            <ErrorBoundaryModal
              title={
                staffStatus === FetchingStatus.Success ||
                assetsStatus === FetchingStatus.Success
                  ? 'Map Error'
                  : 'Map Loading Error'
              }
              message={
                staffStatus === FetchingStatus.Success ||
                assetsStatus === FetchingStatus.Success
                  ? 'An error occurred on the map. Please try again or contact your administrator'
                  : 'An error occurred while loading the map. Please try again or contact your administrator'
              }
            >
              <RealTimeMapView
                assetsByLocation={assetsByLocation}
                assetsStatus={assetsStatus}
                isAssetsByLocationFetched={isAssetsByLocationFetched}
                assetFilterProvider={assetFilterProvider}
                agentTypes={agentTypes}
                staffByLocation={staffByLocation}
                staffStatus={staffStatus}
                isStaffsByLocationFetched={isStaffsByLocationFetched}
                staffFilterProvider={staffFilterProvider}
                currentFloor={currentFloor}
                venueId={venueId}
                rightDrawerState={rightDrawerState}
                onSelectAgent={handleSelectAgentOnMap}
                onSelectGroupOnMap={handleSelectGroupOnMap}
                onSelectAgentTypes={handleSelectAgentTypes}
                updateSelectedAgentTelemetry={updateSelectedAgentTelemetry}
                moveSelectedAgentOutOfGroup={moveSelectedAgentOutOfGroup}
                removeAgentFromSelectedGroup={removeAgentFromSelectedGroup}
                addAgentToSelectedGroup={addAgentToSelectedGroup}
                updateAgentInSelectedGroup={updateAgentInSelectedGroup}
                removeSelectedAgent={removeSelectedAgent}
                selectAgentInGroup={selectAgentInGroup}
                resetRightDrawerState={resetRightDrawerState}
              />
            </ErrorBoundaryModal>
          )
        }
        rightDrawer={
          currentDrawerType === DrawerType.RealTimeAssetDetailDrawer &&
          selectedAgentTelemetry ? (
            <AssetDetailDrawer
              selectedAsset={
                selectedAgentTelemetry as DetailDrawerAgentTelemetry<TelemetryAsset>
              }
              watchList={watchlist}
              manufacturers={manufacturers}
              onCloseDetailDrawer={handleCloseDetailDrawer}
              currentPageView='map'
              setFilterButtonToggled={setFilterButtonToggled}
              assetFilterState={assetFilterState}
            />
          ) : currentDrawerType === DrawerType.RealTimeStaffDetailDrawer &&
            selectedAgentTelemetry ? (
            <StaffDetailDrawer
              selectedStaff={
                selectedAgentTelemetry as DetailDrawerAgentTelemetry<TelemetryStaff>
              }
              watchList={watchlist}
              onCloseDetailDrawer={handleCloseDetailDrawer}
              staffAssistReportedTime={staffAssistReportedTime}
              currentPageView='map'
              setFilterButtonToggled={setFilterButtonToggled}
              staffFilterState={staffFilterState}
            />
          ) : currentDrawerType === DrawerType.RealTimeGroupDetailDrawer &&
            selectedGroupTelemetry ? (
            <MapGroupDetailDrawer
              onCloseDetailDrawer={handleCloseDetailDrawer}
              onSelectAgent={handleSelectAgentInGroup}
              data={selectedGroupTelemetry}
            />
          ) : currentDrawerType === DrawerType.FilterDrawer ? (
            <AssetStaffFilterDrawer
              onCloseDrawer={closeFilterDrawer}
              clearFilters={clearFilters}
              mapFilterOptions={mapFilterOptions}
              assetFilterOptions={assetFilterOptions}
              staffFilterOptions={staffFilterOptions}
              commonFilterOptions={commonFilterOptions}
              units={units}
              filterStates={filterStates}
              filterType={FilterType.RealTime}
            />
          ) : null
        }
      />
    </MapRenderProvider>
  )
}

export default RealTimeMapPage
