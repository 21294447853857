import React, { useEffect, useMemo, useState } from 'react'
import { Avatar, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { GetAvatarColor, roleIdsToNames } from '../../helpers'
import { Roles, StaffMembers, User } from '../../models'
import UserProfileColumnText from './UserProfileColumnText'
import UserProfileColumn from './UserProfileColumn'
import UserProfileCard from './UserProfileCard'

const useStyles = makeStyles(() =>
  createStyles({
    avatar: {
      height: 140,
      width: 140,
      fontSize: 34,
      fontWeight: 'bold',
    },
    boldName: {
      fontFamily: 'Ubuntu',
      fontSize: '20px',
      fontWeight: 'bold',
      textAlign: 'left',
      color: '#000',
    },
    email: {
      fontFamily: 'Ubuntu',
      fontSize: '16px',
      textAlign: 'left',
      color: '#333',
    },
  })
)

interface Props {
  userInfo: User
  roles: Roles
  staff: StaffMembers
}

const UserProfilePersonalInfoCard: React.FC<Props> = (props: Props) => {
  const { userInfo, roles, staff } = props
  const classes = useStyles()

  const [avatarBackground, setAvatarBackground] = useState<string>()
  const [initials, setInitials] = useState<string>('')
  const [fullName, setFullName] = useState<string>('')
  const [displayName, setDisplayName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [userRoles, setUserRoles] = useState<string>('')

  const matchingStaff = useMemo(() => {
    if (staff && Object.values(staff).length && userInfo) {
      if (userInfo.email) {
        return Object.values(staff).find(
          (x) =>
            x.email !== null &&
            x.email.toLowerCase() === userInfo.email.toLowerCase()
        )
      } else {
        return undefined
      }
    }
  }, [staff, userInfo])

  const title = 'Personal Information'

  useEffect(() => {
    if (userInfo) {
      setInitials(
        userInfo.firstName.slice(0, 1).toUpperCase() +
          userInfo.lastName.slice(0, 1).toUpperCase()
      )
      setFullName(userInfo.firstName + ' ' + userInfo.lastName)
      setEmail(userInfo.email.toLowerCase())
      setDisplayName(userInfo.displayName)

      if (!avatarBackground) {
        const avatarColor = GetAvatarColor(
          userInfo.firstName.slice(0, 1).toUpperCase()
        )
        setAvatarBackground(avatarColor)
      }

      if (
        userInfo.roleIds.length > 0 &&
        roles &&
        Object.values(roles).length > 0
      ) {
        setUserRoles(roleIdsToNames(userInfo.roleIds, roles).join(', '))
      }
    }
  }, [userInfo, roles])

  const userRolesKeyVals = {
    'Staff Type': matchingStaff?.staffTypeName ?? '-',
    'Staff Sub-Type': matchingStaff?.staffSubTypeName ?? '-',
    Unit: matchingStaff?.unitName ?? '-',
    'RTLS ID': matchingStaff?.badgeIds[0] ?? '-',
    'RTLS Role': !!userRoles.length ? userRoles : '-',
  }

  const userNameKeyVals = {
    Name: fullName,
    'Display Name': displayName,
    Email: email,
  }

  return (
    <>
      <UserProfileCard title={title}>
        <UserProfileColumn style={{ minWidth: 'max-content' }}>
          <Avatar
            className={classes.avatar}
            style={{ backgroundColor: `${avatarBackground}` }}
          >
            {initials}
          </Avatar>
          <div>
            <Typography className={classes.boldName}>{fullName}</Typography>
            <Typography className={classes.email}>{email}</Typography>
          </div>
        </UserProfileColumn>
        <UserProfileColumn>
          <UserProfileColumnText keyValDictionary={userNameKeyVals} />
        </UserProfileColumn>
        <UserProfileColumn>
          <UserProfileColumnText keyValDictionary={userRolesKeyVals} />
        </UserProfileColumn>
      </UserProfileCard>
    </>
  )
}

export default UserProfilePersonalInfoCard
