import React, { useState, useEffect, useRef, ChangeEvent } from 'react'
import {
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
  FilledInput,
  FormHelperText,
} from '@mui/material'

interface Props {
  classes: {
    inputStyle: string
    multiselectForm?: string
  }
  id: string
  label: string
  items: string[]
  selections: string[]
  error?: boolean
  errorText?: string
  handleChange: (event: ChangeEvent<{ value: unknown }>) => void
  helperText?: string
}

const MaterialMultiselect = (props: Props) => {
  const {
    classes,
    id = 'material-multiselect',
    label,
    items,
    selections,
    error,
    errorText,
    handleChange,
    helperText,
  } = props

  // This is all to handle the width of the OutlinedInput
  const inputLabelRef = useRef<HTMLLabelElement>(null)
  const [labelWidth, setLabelWidth] = useState<number>(0)
  useEffect(() => {
    setLabelWidth(inputLabelRef.current!.offsetWidth)
  }, [])
  const renderHelperText = () => {
    if (error) {
      return <FormHelperText error>{errorText}</FormHelperText>
    } else {
      return <FormHelperText>{helperText}</FormHelperText>
    }
  }
  return (
    <FormControl
      variant='filled'
      classes={{ root: classes.multiselectForm ?? classes.inputStyle }}
      margin='normal'
      style={{ width: '100%' }}
    >
      <InputLabel error={error} ref={inputLabelRef} htmlFor={id}>
        {label}
      </InputLabel>
      <Select
        multiple
        // autoWidth
        variant='filled'
        value={selections}
        onChange={handleChange}
        input={<FilledInput id={id} />}
        renderValue={(selected) => (selected as string[]).join(', ')}
        error={error}
        className={classes.inputStyle}
      >
        {items.map((item) => (
          <MenuItem key={item} value={item}>
            <Checkbox checked={selections.includes(item)} color='primary' />
            <ListItemText primary={item} />
          </MenuItem>
        ))}
      </Select>
      {renderHelperText()}
    </FormControl>
  )
}

export default React.forwardRef(MaterialMultiselect)
