import { Permission, Permissions, PermissionsEnum } from '../models/permissions'
import { Page, PageGroup } from '../interfaces/modules'

// Convert array of permission.description to permission.id
export const permissionNamesToId = (
  permissionNames: string[],
  permissions: Permissions
): number[] => {
  return permissionNames.map(
    (permissionName) =>
      Object.values(permissions).find(
        (permission: Permission) => permission.description === permissionName
      )?.id || 0
  )
}

// Convert array of permission.id to permission.description
export const permissionIdsToNames = (
  permissionIds: number[],
  permissions: Permissions
): string[] => {
  return permissionIds.map(
    (permissionId) =>
      Object.values(permissions).find(
        (permission: Permission) => permission.id === permissionId
      )?.description || ''
  )
}

export const permissionStringsToEnums = (
  permissions: string[]
): PermissionsEnum[] => {
  return permissions.reduce((result: PermissionsEnum[], perm: string) => {
    const mappedEnum = Object.values(PermissionsEnum).find(
      (value) => value === perm
    ) as PermissionsEnum

    if (mappedEnum) {
      result.push(mappedEnum)
    }

    return result
  }, [])
}

export const filteredPages = (
  pageGroup: PageGroup,
  permissions: PermissionsEnum[]
): Page[] => {
  return pageGroup.pages.filter((page) =>
    page.permissions.find((permission) => permissions.includes(permission))
  )
}

export const filteredPageGroups = (
  pageGroups: PageGroup[],
  permissions: PermissionsEnum[]
): PageGroup[] => {
  return pageGroups
    .filter(
      (pageGroup) =>
        !pageGroup.permissions ||
        pageGroup.permissions.find((permission) =>
          permissions.includes(permission)
        )
    )
    .map((pageGroup) => {
      pageGroup.pages = filteredPages(pageGroup, permissions)
      return pageGroup
    })
}

export const permittedRoute = (
  routePermissions: PermissionsEnum[],
  userPermissions: PermissionsEnum[]
): boolean =>
  routePermissions.length === 0 ||
  routePermissions.some((permission) => userPermissions.includes(permission))

export function extractPermissionGroups(permissions: Permissions): string[] {
  return Object.values(permissions).reduce((acc: string[], permission) => {
    const permissionType = permission.name.split('.')[0]
    return acc.includes(permissionType) ? acc : [...acc, permissionType]
  }, [])
}

type PermissionMap = {
  [group: string]: {
    read: number
    limitedWrite: number
    write: number
    technical: number
    canViewAllCustomers: number
  }
}

export function transformPermissionGroups(
  permissions: Permissions
): PermissionMap {
  const transformed = Object.values(permissions).reduce(
    (permissionMap: PermissionMap, permission: Permission) => {
      const [group, access] = permission.name.split('.')
      return {
        ...permissionMap,
        [group]: {
          ...permissionMap[group],
          read: access === 'read' ? permission.id : permissionMap[group]?.read,
          limitedWrite:
            access === 'limited_write'
              ? permission.id
              : permissionMap[group]?.limitedWrite,
          write:
            access === 'write' ? permission.id : permissionMap[group]?.write,
          technical:
            access === 'technical'
              ? permission.id
              : permissionMap[group]?.technical,
          canViewAllCustomers:
            group === 'canViewAllCustomers'
              ? permission.id
              : permissionMap[group]?.canViewAllCustomers,
        },
      }
    },
    {}
  )
  return transformed as PermissionMap
}
