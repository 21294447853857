import { ColumnDef, ColumnSort, Row } from '@tanstack/react-table'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  deleteUserSettingsAction,
  patchUserSettingsAction,
} from '../../actions/userSettings'
import { renderSvgIcon, renderUTCDate } from '../../helpers/reactTable'
import { useFetchUserSettings } from '../../hooks'
import {
  AgentType,
  BadgeSignalStatus,
  FetchingStatus,
  TableColumnSettings,
  UserSettingsType,
} from '../../models'
import { assetExportHeaders } from '../../models/assetExportHeaders'
import { AssetRow } from '../../models/assetRow'
import { WatchAgentIconButton } from '../Buttons/WatchAgentIconButton'
import { ReactTable, TableRowSelectionOptions } from '../Tables'
import TableCsvExport from '../Tables/TableCsvExport'
import AssetListViewAssetButton from './AssetListViewAssetButton'

interface Props {
  isFetching: boolean
  tableData: AssetRow[]
  tableRowSelectionOptions: Omit<
    TableRowSelectionOptions<AssetRow>,
    'dataMatchPredicate'
  >
  savedFilterSortBy: ColumnSort | undefined
  onSortingChange: (columnSort: ColumnSort[]) => void
}

const RealTimeAssetList = (props: Props): JSX.Element => {
  const {
    isFetching,
    tableData,
    tableRowSelectionOptions,
    savedFilterSortBy,
    onSortingChange,
  } = props

  const [preSelectedRowFrolUrlBadgeId, setPreSelectedRowFrolUrlBadgeId] =
    useState<AssetRow | undefined>(undefined)

  // Tim Groven - 05/26/2023 - https://versuscarina.visualstudio.com/Carina/_workitems/edit/25991
  // Using URLSearchParams because pulling from useQueryParams()
  // Doesn't decode the HTML string, which is bad for MAC Addresses
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const urlBadgeId = searchParams.get('badgeId')

  const { data: userSettings, status: userSettingsStatus } =
    useFetchUserSettings()

  const dispatch = useDispatch()

  useEffect(() => {
    if (urlBadgeId && tableData.length > 0 && !preSelectedRowFrolUrlBadgeId) {
      const asset = tableData.find((x) =>
        x.badgeIds.some((s) => s === urlBadgeId)
      )
      if (asset) {
        setPreSelectedRowFrolUrlBadgeId(asset)
      }
    }
  }, [preSelectedRowFrolUrlBadgeId, urlBadgeId, tableData])

  const columns = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const columnDefinitions: ColumnDef<AssetRow, any>[] = [
      {
        size: 32,
        accessorKey: 'icon',
        header: 'Icon',
        cell: (cell) => renderSvgIcon(cell),
        enableSorting: false,
        enablePinning: true,
      },
      {
        accessorKey: 'displayName',
        header: 'Display Name',
        sortingFn: 'alphanumeric',
        enablePinning: true,
      },
      {
        accessorKey: 'externalUniqueId',
        header: 'Asset ID',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'manufacturerName',
        header: 'Manufacturer',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'model',
        header: 'Model',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'serialNumber',
        header: 'Serial Number',
        sortingFn: 'alphanumeric',
        size: 125,
      },
      {
        accessorKey: 'assetTypeName',
        header: 'Asset Type',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'preventativeMaintenanceDate',
        header: 'Next PM Date',
        // Render the date in UTC, or it will be changed by the local timezone
        cell: (cell) => renderUTCDate(cell.getValue(), 'MM/DD/YYYY'),
        sortingFn: 'rtlsDateTime',
        size: 125,
      },
      {
        accessorKey: 'unitName',
        header: 'Assigned To',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'building',
        header: 'Building',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'floor',
        header: 'Floor',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'campus',
        header: 'Building Group',
        sortingFn: 'alphanumeric',
        enableHiding: true,
      },
      {
        accessorKey: 'locationName',
        header: 'Room',
        sortingFn: 'rtlsAdvSort',
      },
      {
        accessorKey: 'locationUnitName',
        header: 'Unit',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'locationSubUnitName',
        header: 'Sub-Unit',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'accuracy',
        header: 'Location Accuracy',
        sortingFn: 'alphanumeric',
        size: 125,
      },
      {
        accessorKey: 'lastSensedDateString',
        header: 'Last Detected',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'signalStatus',
        header: 'Signal Status',
        sortingFn: 'alphanumeric',
        size: 125,
      },
      {
        accessorKey: 'batteryStatus',
        header: 'RTLS Battery Status',
        size: 220,
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'name',
        header: 'Name',
        sortingFn: 'alphanumeric',
        enableHiding: true,
      },
      {
        accessorKey: 'assetSubTypeName',
        header: 'Asset Sub-Type',
        sortingFn: 'alphanumeric',
        enableHiding: true,
      },
      {
        accessorKey: 'owner',
        header: 'Owner',
        sortingFn: 'alphanumeric',
        enableHiding: true,
      },
      {
        accessorKey: 'badgeNumber',
        header: 'RTLS ID',
        sortingFn: 'alphanumeric',
        enableHiding: true,
      },
      {
        id: 'signalType',
        accessorKey: 'signalType',
        header: 'RTLS Signal Type',
        sortingFn: 'alphanumeric',
        enableHiding: true,
      },
      {
        accessorKey: 'tagBatteryChangeDateString',
        header: 'RTLS Battery Change Date',
        sortingFn: 'alphanumeric',
        enableHiding: true,
      },
      {
        accessorKey: 'cmmsManagedDisplay',
        header: 'CMMS Managed',
        sortingFn: 'alphanumeric',
        enableHiding: true,
      },
      {
        id: 'map',
        enableSorting: false,
        accessorFn: (row) => (
          <AssetListViewAssetButton
            size='small'
            room={row.locationName}
            signalStatus={row.signalStatus}
            badgeId={row.badgeIds[0] ?? ''}
            floorLocationId={row.floorLocation?.id ?? ''}
          />
        ),
        cell: (cell) => cell.getValue(),
        header: 'Map',
        enablePinning: true,
        meta: {
          align: 'right',
          headerStyle: {
            paddingRight: 19,
          },
        },
      },
      {
        id: 'myList',
        enableSorting: false,
        accessorFn: (row) => (
          <WatchAgentIconButton
            size='small'
            agentId={row.agentGuid}
            agentType={AgentType.Asset}
            isWatchlisted={row.isWatchListed}
            color='primary'
          />
        ),
        cell: (cell) => cell.getValue(),
        header: 'My List',
        enablePinning: true,
        meta: {
          align: 'right',
          headerStyle: {
            paddingRight: 19,
          },
        },
      },
    ]
    return columnDefinitions
  }, [])

  const handleSave = useCallback(
    (settings: TableColumnSettings, saveDefault: boolean): void => {
      if (!saveDefault) {
        dispatch(
          patchUserSettingsAction.request({
            realtimeAsset: settings,
          })
        )
      } else {
        dispatch(deleteUserSettingsAction.request(UserSettingsType.AssetList))
      }
    },
    [dispatch]
  )

  const getRowStyles = (row: Row<AssetRow>): React.CSSProperties => {
    if (row.getValue('signalStatus') === BadgeSignalStatus.Recent) {
      return { fontStyle: 'italic' }
    }
    return {}
  }

  const showLoading = useMemo(() => {
    return isFetching || userSettingsStatus === FetchingStatus.Request
  }, [isFetching, userSettingsStatus])

  const actions = useMemo(() => {
    return [
      <TableCsvExport
        isLoading={showLoading}
        key='CSVExport'
        csvLinkExportOptions={{
          csvFileName: 'AssetList.csv',
          data: tableData,
          headers: assetExportHeaders,
        }}
      />,
    ]
  }, [showLoading, tableData])

  const tableToolbarConfigs = useMemo(
    () => ({
      title: 'Asset List (Real-Time)',
      actions: actions,
    }),
    [actions]
  )

  const tableOptions = useMemo(
    () => ({
      data: tableData,
      columns: columns,
      showLoading: showLoading,
    }),
    [columns, showLoading, tableData]
  )

  const tableRowOptions = useMemo(
    () => ({
      rowSelectionOptions: {
        ...tableRowSelectionOptions,
        dataMatchPredicate: (assetA: AssetRow, assetB: AssetRow) =>
          assetA.agentGuid === assetB.agentGuid,
      },
      getDynamicRowStyle: getRowStyles,
    }),
    [tableRowSelectionOptions]
  )

  const tableSortingOptions = useMemo(
    () => ({
      onSortingChange: onSortingChange,
      savedFilterSortBy: savedFilterSortBy,
    }),
    [onSortingChange, savedFilterSortBy]
  )

  const tableColumnUserCustomizationOptions = useMemo(
    () => ({
      onSaveColumnCustomization: handleSave,
      userSettings: Object.values(userSettings.realtimeAsset ?? {}),
    }),
    [handleSave, userSettings.realtimeAsset]
  )

  const preSelectedItemFromUrlSearchParams = useMemo(
    () => ({
      rowToSelectFromUrlSearchParams: preSelectedRowFrolUrlBadgeId,
      rowMatchPredicate: (assetA: AssetRow, assetB: AssetRow) =>
        assetA.agentGuid === assetB.agentGuid,
    }),
    [preSelectedRowFrolUrlBadgeId]
  )

  return (
    <>
      <ReactTable
        tableToolbarOptions={tableToolbarConfigs}
        tableOptions={tableOptions}
        tableRowOptions={tableRowOptions}
        tableSortingOptions={tableSortingOptions}
        tableColumnUserCustomizationOptions={
          tableColumnUserCustomizationOptions
        }
        preSelectedItemFromUrlSearchParams={preSelectedItemFromUrlSearchParams}
      />
    </>
  )
}

export default RealTimeAssetList
