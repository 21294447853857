import React from 'react'
import { Location } from '../../models'
import { CreateOrEditModalProps, makeCreateOrEditModal } from '../Common/HOC'
import FloorEditForm from './FloorEditForm'

const FloorEditModal = (props: CreateOrEditModalProps<Location>) => (
  <FloorEditForm {...props}></FloorEditForm>
)

export default makeCreateOrEditModal<Location>(FloorEditModal, 'xs')
