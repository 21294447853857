import { Epic } from 'redux-observable'
import { isActionOf } from 'typesafe-actions'
import { mergeMap, catchError, filter, map } from 'rxjs/operators'
import { from, of } from 'rxjs'
import { RoleAction } from '../actions'
import { Role, RolesState } from '../models/roles'
import { RolesApi } from '../middleware/roles'
import {
  getRolesAction,
  getRolesByUserIdAction,
  getRoleByIdAction,
  postRoleAction,
  deleteRoleAction,
  putRoleAction,
} from '../actions/roles'

type RolesEpic = Epic<
  RoleAction,
  RoleAction,
  RolesState,
  { rolesApi: RolesApi }
>

export const getAllRolesEpic: RolesEpic = (action$, state$, { rolesApi }) => {
  return action$.pipe(
    filter(isActionOf(getRolesAction.request)),
    mergeMap(() =>
      from(rolesApi.getAllRoles()).pipe(
        map((payload: Role[]) => getRolesAction.success(payload)),
        catchError((error) => of(getRolesAction.failure(error)))
      )
    )
  )
}

export const getRolesByUserIdEpic: RolesEpic = (
  action$,
  state$,
  { rolesApi }
) => {
  return action$.pipe(
    filter(isActionOf(getRolesByUserIdAction.request)),
    mergeMap((action) =>
      from(rolesApi.getRolesByUserId(action.payload)).pipe(
        map((payload: Role[]) => getRolesByUserIdAction.success(payload)),
        catchError((error) => of(getRolesByUserIdAction.failure(error)))
      )
    )
  )
}

export const getRoleByIdEpic: RolesEpic = (action$, state$, { rolesApi }) => {
  return action$.pipe(
    filter(isActionOf(getRoleByIdAction.request)),
    mergeMap((action) =>
      from(rolesApi.getRoleById(action.payload)).pipe(
        map((payload: Role) => getRoleByIdAction.success(payload)),
        catchError((error) => of(getRoleByIdAction.failure(error)))
      )
    )
  )
}

export const postRoleByIdEpic: RolesEpic = (action$, state$, { rolesApi }) => {
  return action$.pipe(
    filter(isActionOf(postRoleAction.request)),
    mergeMap((action) =>
      from(rolesApi.postRole(action.payload)).pipe(
        map((payload: Role) => postRoleAction.success(payload)),
        catchError((error) => of(postRoleAction.failure(error)))
      )
    )
  )
}

export const putRoleByIdEpic: RolesEpic = (action$, state$, { rolesApi }) => {
  return action$.pipe(
    filter(isActionOf(putRoleAction.request)),
    mergeMap((action) =>
      from(rolesApi.putRole(action.payload)).pipe(
        map((payload: Role) => putRoleAction.success(payload)),
        catchError((error) => of(putRoleAction.failure(error)))
      )
    )
  )
}

export const deleteRoleEpic: RolesEpic = (action$, state$, { rolesApi }) => {
  return action$.pipe(
    filter(isActionOf(deleteRoleAction.request)),
    mergeMap((action) =>
      from(rolesApi.deleteRole(action.payload)).pipe(
        map(() => deleteRoleAction.success(action.payload)),
        catchError((error) => of(deleteRoleAction.failure(error)))
      )
    )
  )
}
