import React from 'react'
import { RightDrawerLayout } from '../Common/RightDrawer'
import AlertDetailsPaneHeader from './AlertDetailsPaneHeader'
import AlertDetailsPane from './AlertDetailsPane'
import { AlertRow } from './AlertList'
import { AlertActiveConfig } from '../../models/alert'
import { allUnits } from '../../models'

interface Props {
  canEdit: boolean
  selectedAlert: AlertRow
  onClose: () => void
  onDeleteAlert: () => void
  onDisableAlert: () => void
}

const mapAlertRowToAlertActiveConfig = (alert: AlertRow): AlertActiveConfig => {
  return {
    id: alert.id,
    name: alert.name,
    description: alert.description,
    alertType: {
      id: alert.alertTypeId,
      name: alert.alertTypeName,
    },
    alertStatus: alert.alertStatus,
    trigger: alert.triggers[0],
    locationQualifier: {
      campus: alert.locationQualifier?.campus,
      building: alert.locationQualifier?.building,
      floor: alert.locationQualifier?.floor,
    },
    unitQualifier: alert.unitQualifier ?? allUnits,
    badgeQualifier: !alert.badgeQualifier ? 'All Badges' : alert.badgeQualifier,
    channels: alert.channels,
    locationRouter: alert.locationRouter,
    unitRouter: alert.unitRouter,
    userRouters: alert.userRouters,
    securityRouters: alert.securityRouters,
    alertResolution: alert.alertResolution,
  }
}

const AlertDetailDrawer = (props: Props): JSX.Element => {
  const { selectedAlert, canEdit, onClose, onDeleteAlert, onDisableAlert } =
    props
  const activeConfig = mapAlertRowToAlertActiveConfig(selectedAlert)
  return (
    <RightDrawerLayout
      header={
        <AlertDetailsPaneHeader
          alert={activeConfig}
          onClose={onClose}
          deleteAlert={onDeleteAlert}
          disableAlert={onDisableAlert}
          canEdit={canEdit}
        />
      }
      content={<AlertDetailsPane alert={activeConfig} />}
    />
  )
}

export default AlertDetailDrawer
