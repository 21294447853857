import { Epic } from 'redux-observable'
import { filter, mergeMap, map, catchError } from 'rxjs/operators'
import { action, isActionOf } from 'typesafe-actions'
import { from, of } from 'rxjs'
import {
  getAllUnitsAction,
  getUnitAction,
  postUnitAction,
  deleteUnitAction,
  putUnitAction,
} from '../actions/units'
import { UnitsApi } from '../middleware/units'
import { UnitAction } from '../actions'
import { UnitsState } from '../models'

type UnitsEpic = Epic<
  UnitAction,
  UnitAction,
  UnitsState,
  { unitsApi: UnitsApi }
>
export const getAllUnitsEpic: UnitsEpic = (action$, state, { unitsApi }) => {
  return action$.pipe(
    filter(isActionOf(getAllUnitsAction.request)),
    mergeMap(() =>
      from(unitsApi.getAllUnits()).pipe(
        map((payload) => getAllUnitsAction.success(payload)),
        catchError((error) => of(getAllUnitsAction.failure(error)))
      )
    )
  )
}

export const getUnitEpic: UnitsEpic = (action$, state, { unitsApi }) => {
  return action$.pipe(
    filter(isActionOf(getUnitAction.request)),
    mergeMap((action) =>
      from(unitsApi.getUnit(action.payload)).pipe(
        map((payload) => getUnitAction.success(payload)),
        catchError((error) => of(getUnitAction.failure(error)))
      )
    )
  )
}

export const postUnitEpic: UnitsEpic = (action$, state, { unitsApi }) => {
  return action$.pipe(
    filter(isActionOf(postUnitAction.request)),
    mergeMap((action) =>
      from(unitsApi.postUnit(action.payload)).pipe(
        map((payload) => postUnitAction.success(payload)),
        catchError((error) => of(postUnitAction.failure(error)))
      )
    )
  )
}

export const putUnitEpic: UnitsEpic = (action$, state, { unitsApi }) => {
  return action$.pipe(
    filter(isActionOf(putUnitAction.request)),
    mergeMap((action) =>
      from(unitsApi.putUnit(action.payload)).pipe(
        map((payload) => putUnitAction.success(payload)),
        catchError((error) => of(putUnitAction.failure(error)))
      )
    )
  )
}

export const deleteUnitEpic: UnitsEpic = (action$, state, { unitsApi }) => {
  return action$.pipe(
    filter(isActionOf(deleteUnitAction.request)),
    mergeMap((action) =>
      from(unitsApi.deleteUnit(action.payload)).pipe(
        map((payload) => deleteUnitAction.success(payload)),
        catchError((error) => of(deleteUnitAction.failure(error)))
      )
    )
  )
}
