import { isA } from '../helpers'
import { CssSize, sizeWithUnit } from './'

export type FlexDirection =
  | 'row'
  | 'row-reverse'
  | 'column'
  | 'column-reverse'
  | 'initial'
  | 'inherit'
export type FlexWrap =
  | 'nowrap'
  | 'wrap'
  | 'wrap-reverse'
  | 'initial'
  | 'inherit'
export type FlexAlignment = 'flex-start' | 'center' | 'flex-end' | 'stretch'
// export type FlexItemAlignment = FlexAlignment | 'baseline'
export type FlexContentAlignment =
  | FlexAlignment
  | 'space-around'
  | 'space-between'
  | 'space-evenly'

export type FlexLayoutAlignProps = [
  FlexContentAlignment,
  FlexAlignment?,
  FlexAlignment?
]

export interface FlexAlignmentProps {
  /**
   * justify-content: how to place content within each row or column
   */
  main?: FlexContentAlignment
  /**
   * align-items: how to align items within a row or column
   */
  item?: FlexAlignment
  /**
   * align-content: how to align content between rows or columns
   */
  content?: FlexContentAlignment
}

export interface FlexProps {
  grow?: CssSize
  shrink?: CssSize
  basis?: CssSize
}

export interface FlexLayoutProps {
  direction?: FlexDirection
  wrap?: FlexWrap | boolean
  align?: FlexLayoutAlignProps
}

export interface FlexLayoutAlignStyles {
  justifyContent: FlexContentAlignment
  alignContent: FlexContentAlignment
  alignItems: FlexAlignment
}

export interface FlexLayoutStyles extends FlexLayoutAlignStyles {
  display: 'flex'
  flexDirection?: FlexDirection
  flexWrap?: FlexWrap
  boxSizing: 'border-box'
}

export interface FlexBoxStyles {
  flex: string
  boxSizing?: 'border-box'
}

export const flexProps = (
  grow: CssSize = 1,
  shrink: CssSize = 1,
  basis: CssSize = 'auto'
): FlexProps => {
  return {
    grow,
    shrink,
    basis,
  }
}

export const flexLayoutProps = (
  direction: FlexDirection = 'row',
  align?: FlexLayoutAlignProps,
  wrap?: FlexWrap
): FlexLayoutProps => {
  return {
    direction,
    align,
    wrap,
  }
}

export const flexValue = (props: FlexProps): string => {
  const { grow = 1, shrink = 1, basis = 'auto' } = props
  return `${grow} ${shrink} ${sizeWithUnit(basis)}`
}

export const flexNone = '0 0 auto'
export const flexAuto = '1 1 auto'
export const flexGrow = '1 1 100%'
export const flexDefault = '1 1 0%'
export const flexNoGrow = '0 1 auto'
export const flexNoShrink = '1 0 auto'

export const flexValueMap: Record<string, string> = {
  default: flexDefault,
  none: flexNone,
  auto: flexAuto,
  grow: flexGrow,
  nogrow: flexNoGrow,
  noshrink: flexNoShrink,
}

const createFlex = (value: string): FlexBoxStyles => ({
  flex: value,
  boxSizing: 'border-box',
})

export const flex = (props?: FlexProps | string): FlexBoxStyles => {
  // use default
  if (!props || props === '') {
    return createFlex(flexDefault)
  }

  // use string
  if (isA.string(props)) {
    const valueOrName = props as string
    return createFlex(flexValueMap[valueOrName] ?? valueOrName)
  }

  // otherwise generate value
  const flexProps = (props as FlexProps) ?? {}
  const { grow = 1, shrink = 1, basis = '0%' } = flexProps

  return createFlex(flexValue({ grow, shrink, basis }))
}

export type FlexLayoutOptions = FlexAlignmentProps & {
  wrap?: FlexWrap | boolean
}

export const fxLayout = (
  direction: FlexDirection,
  options: FlexLayoutOptions = {}
): FlexLayoutStyles => {
  const {
    main: justifyContent = 'flex-start',
    item: alignItems = 'stretch',
    content: alignContent = alignItems,
    wrap = false,
  } = options
  const flexWrap = (wrap as FlexWrap) ?? ((wrap as boolean) ? 'wrap' : 'nowrap')

  const styles: FlexLayoutStyles = {
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: direction,
    flexWrap,
    justifyContent,
    alignContent,
    alignItems: alignItems ?? alignContent,
  }

  return styles
}

export const flexLayout = (
  props: FlexLayoutProps = {
    direction: 'row',
    align: ['flex-start', 'stretch'],
    wrap: false,
  }
): FlexLayoutStyles => {
  const { direction, align = ['flex-start', 'stretch'], wrap } = props

  const [justifyContent, alignContent = 'stretch', alignItems] = align
  const flexWrap = (wrap as FlexWrap) || ((wrap as boolean) ? 'wrap' : 'nowrap')

  const styles: FlexLayoutStyles = {
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: direction,
    flexWrap,
    justifyContent,
    alignContent,
    alignItems: alignItems ?? alignContent,
  }

  return styles
}

export const flexStyles = {
  flexNone: flex(flexNone),
  flexAuto: flex(flexAuto),
  flexGrow: flex(flexGrow),
}
