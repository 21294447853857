import { getType } from 'typesafe-actions'
import { VenueAction } from '../actions'
import {
  FetchingStatus,
  SyncVenueResultState,
  SyncVenueResults,
  SyncVenueResult,
} from '../models'
import { mergeRecord } from '../utils'
import { getSyncVenueAction } from '../actions/venues'

const initialState: SyncVenueResultState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

export default (
  state = initialState,
  action: VenueAction
): SyncVenueResultState => {
  switch (action.type) {
    case getType(getSyncVenueAction.success):
      return {
        data: mergeRecord<SyncVenueResults, SyncVenueResult, 'venueId'>(
          state.data,
          action.payload,
          'venueId'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getSyncVenueAction.request):
      return {
        ...state,
        status: FetchingStatus.Request,
      }
    case getType(getSyncVenueAction.failure):
      return {
        ...state,
        status: FetchingStatus.Failure,
      }
    default:
      return state
  }
}
