import React from 'react'
import { ClearAll, EditLocation, Sort } from '@mui/icons-material'
import { CommonComponent, ComponentProps } from '../../components'
import { PageViewNav } from '../../components/ActionBar'
import { PageToolbar } from '../../components/Layout'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { LocationManagementPageViews } from '../../models'

interface Props {
  activeView?: LocationManagementPageViews
  onViewChange: (view: LocationManagementPageViews) => void
}

const LocationManagementToolbar: CommonComponent<Props> = (
  props: ComponentProps<Props>
) => {
  const { onViewChange, activeView } = props

  const handleViewChange = (view: LocationManagementPageViews): void => {
    onViewChange && onViewChange(view)
  }

  const useStyles = makeStyles(() =>
    createStyles({
      mirror: {
        transform: 'rotateY(180deg)',
      },
    })
  )

  const classes = useStyles()

  const navItems = [
    {
      view: 'locations',
      label: 'Locations',
      icon: <EditLocation />,
    },
    {
      view: 'units',
      label: 'Units',
      icon: <ClearAll className={classes.mirror} />,
    },
    {
      view: 'subunits',
      label: 'Sub-Units',
      icon: <Sort className={classes.mirror} />,
    },
  ]

  return (
    <PageToolbar searchStorageName={LocationManagementToolbar.name}>
      {{
        navigation: (
          <PageViewNav
            defaultView='list'
            view={activeView}
            onViewChange={(view): void =>
              handleViewChange(view as LocationManagementPageViews)
            }
            items={navItems}
          ></PageViewNav>
        ),
      }}
    </PageToolbar>
  )
}

export { LocationManagementToolbar }
