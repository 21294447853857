import { DestinationPositionsAction } from '../actions'
import { DestinationPositions } from '@midmarkrtls/common/models'
import { setDestinationPositionsAction } from '../actions/destinationPositions'
import { getType } from 'typesafe-actions'

export type DestinationPositionState = Readonly<DestinationPositions>

const destinationPositionReducer = (
  state: DestinationPositionState = {},
  action: DestinationPositionsAction
): DestinationPositionState => {
  switch (action.type) {
    case getType(setDestinationPositionsAction):
      return action.payload
    default:
      return state
  }
}

export default destinationPositionReducer
