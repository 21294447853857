import { getUserPreferencesAction } from '../../actions/userPreferences'
import { UserPreferences, UserPreferencesState } from '../../models'
import { EntityResult, useEntities } from '../useEntities'

const useFetchUserPreferences = (): EntityResult<UserPreferences> =>
  useEntities<UserPreferencesState, UserPreferences>(
    () => getUserPreferencesAction.request(),
    (state: any) => state.userPreferences // eslint-disable-line @typescript-eslint/no-explicit-any
  )

export { useFetchUserPreferences }
