import { useDispatch, useSelector } from 'react-redux'
import { PayloadAction } from 'typesafe-actions'
import { setSelectedSavedFilterAction } from '../actions/selectedSavedFilter'

export interface UseSelectedSavedFilterResult {
  selectedSavedFilter: string | null
  setSelectedSavedFilter: (
    selectedFilter: string | null
  ) => PayloadAction<'SET_SELECTED_SAVED_FILTER', string | null>
}

export function useSelectedSavedFilter(): UseSelectedSavedFilterResult {
  const dispatch = useDispatch()

  const setSelectedSavedFilter = (
    newSavedFilter: string | null
  ): PayloadAction<'SET_SELECTED_SAVED_FILTER', string | null> =>
    dispatch(setSelectedSavedFilterAction(newSavedFilter))

  const selectedSavedFilter = useSelector(
    ({ selectedSavedFilter }: { selectedSavedFilter: string }) =>
      selectedSavedFilter
  )

  return {
    selectedSavedFilter,
    setSelectedSavedFilter,
  }
}
