import React, { useState, useEffect } from 'react'
import { TextField } from '@mui/material'
import { colors } from '../../styles'
import DialogBox from '../Dialogs/DialogBox'

interface Props {
  modalOpen: boolean
  setModalOpen: (show: boolean) => void
  handleCreate: (input: string) => void
}

const FilterNameModal = (props: Props) => {
  const { modalOpen, setModalOpen, handleCreate } = props
  const [userInput, setUserInput] = useState('')
  const [showError, setShowError] = useState(true)

  useEffect(() => {
    if (!modalOpen) {
      setUserInput('')
      setShowError(true)
    }
  }, [modalOpen])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput(event.target.value)
    if (event.target.value === '') {
      setShowError(true)
    } else if (showError) {
      setShowError(false)
    }
  }

  const createFilter = (event: React.FormEvent) => {
    event.preventDefault()
    event.stopPropagation()
    if (userInput !== '') {
      handleCreate(userInput)
    } else {
      setShowError(true)
    }
  }

  return (
    <DialogBox
      open={modalOpen}
      handleClose={() => setModalOpen(false)}
      title={`Filter Name`}
      actionButtons={[
        {
          label: 'Cancel',
          action: () => setModalOpen(false),
        },
        {
          label: 'Save',
          action: createFilter,
          color: colors.primary,
          disabled: showError,
          type: 'button',
        },
      ]}
    >
      <form onSubmit={createFilter}>
        <TextField
          variant='standard'
          autoFocus
          error={showError}
          label={'Name'}
          value={userInput}
          fullWidth
          onChange={handleChange}
        />
      </form>
    </DialogBox>
  )
}

export default FilterNameModal
