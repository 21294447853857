import { useEffect, useState } from 'react'
import {
  createAssets,
  deleteAsset,
  generateAssetConfig,
  getIcon,
  updateAssetIcon,
} from '../../helpers/inpixon'
import { InpixonAssetConfig, IconType, MapReferenceView } from '../../models'
import { useFetchIssues } from '..'
import { deviceHasOpenIssues } from '../../selectors'
import { getIconScale } from '../../helpers/inpixon/assetCreate'

export function useDevices(
  devices: MapReferenceView[] | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jibestream: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  assetKit: any
): void {
  // todo: do these belong elsewhere, or do they fit here?
  const [devicesOnMap, setDevicesOnMap] = useState<string[]>([])
  const [lastUpdated, setLastUpdated] = useState(new Date())

  const { data: issues } = useFetchIssues()

  useEffect(() => {
    if (!jibestream || !jibestream.control || !assetKit || !devices) return

    const hasDeviceBeenUpdated = (device: MapReferenceView): boolean =>
      lastUpdated &&
      device.lastUpdated !== undefined &&
      lastUpdated <= device.lastUpdated

    const existingDevices = devices.map((x) => x.deviceExternalId)

    const devicesToDelete = devicesOnMap.filter(
      (deviceID) => !existingDevices.includes(deviceID)
    )

    const devicesToUpdate = devices.filter((m) => hasDeviceBeenUpdated(m))

    const devicesToCreate = devices.filter(
      (m) => !devicesOnMap.includes(m.deviceExternalId)
    )

    // Calculate icon scale here
    // Get the mmPerPixel value to use when loading the asset icons
    const iconScale = getIconScale(jibestream.control.currentMap.mmPerPixel)

    const createConfigs = devicesToCreate.reduce(
      (acc, mapReferenceView) => ({
        ...acc,
        [mapReferenceView.deviceExternalId]: generateAssetConfig({
          assetID: Number(mapReferenceView.deviceExternalId),
          name: mapReferenceView.deviceExternalId,
          coordinates: [mapReferenceView.xCord, mapReferenceView.yCord],
          type: IconType.Sensor,
          height: 9,
          width: 12,
          isLowBattery: false,
          showIcon: true,
          iconScale: iconScale,
          map: jibestream.control.currentBuilding.floors.find(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (x: any) => x.id === mapReferenceView.floorId
          )?.map,
          deviceTypeId: mapReferenceView.deviceTypeId,
          isConfigured: Boolean(mapReferenceView.sensorEnterpriseId),
          hasIssues: deviceHasOpenIssues(issues)(mapReferenceView.deviceId),
          confidenceAlpha: 0,
        }),
      }),
      {}
    )

    const createList: InpixonAssetConfig[] = Object.values(createConfigs)
    if (!!createList.length) {
      setDevicesOnMap((prev) => [...prev, ...Object.keys(createConfigs)])
      createAssets(assetKit, createList)
    }

    if (!!devicesToUpdate.length) {
      devicesToUpdate.forEach((device) => {
        const config = generateAssetConfig({
          assetID: Number(device.deviceExternalId),
          name: device.deviceExternalId,
          coordinates: [device.xCord, device.yCord],
          type: IconType.Sensor,
          height: 9,
          width: 12,
          isLowBattery: false,
          showIcon: true,
          iconScale: iconScale,
          map: jibestream.control.currentBuilding.floors.find(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (x: any) => x.id === device.floorId
          )?.map,
          deviceTypeId: device.deviceTypeId,
          isConfigured: Boolean(device.sensorEnterpriseId),
          hasIssues: deviceHasOpenIssues(issues)(device.deviceId),
          confidenceAlpha: 0,
        })
        if (
          Boolean(device.sensorEnterpriseId) &&
          config.url.includes('Unconfigured')
        ) {
          config.url = config.url.replace('Unconfigured', 'Configured')
        } else {
        }
        updateAssetIcon(assetKit, Number(device.deviceExternalId), config.url)
      })

      setLastUpdated(new Date())
    }

    if (devicesToDelete.length) {
      setDevicesOnMap((prevDeviceIds) =>
        prevDeviceIds.filter((deviceId) => !devicesToDelete.includes(deviceId))
      )
      devicesToDelete.forEach((deviceId) => {
        deleteAsset(assetKit, Number(deviceId))
      })
    }

    // render map view to ensure icons are visible
    // delay is to ensure all changes have been processed
    setTimeout(() => jibestream.control.renderCurrentMapView(), 500)
  }, [jibestream, devices, devicesOnMap, lastUpdated, assetKit, issues])
}
