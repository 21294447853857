import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  AgentLocationModel,
  AgentType,
  BadgeSignalStatus,
  LocationsState,
  Watchlist,
  WatchlistItem,
} from '../../../../models'
import { useAuth, useQueryParams } from '../../../../hooks'
import { setCurrentLocationAction } from '../../../../actions/currentLocation'
import { CommonRouteKeys } from '../../../../constants'
import { badgeSignalStatus, buildURL } from '../../../../helpers'
import {
  deleteAssetWatchlistItemAction,
  deleteStaffWatchlistItemAction,
  postAssetWatchlistItemAction,
  postStaffWatchlistItemAction,
} from '../../../../actions/watchlists'
import QuickActionAvatarCard, { IconType } from '../../../Cards/QuickActionCard'
import { AssetFilters } from '../../../../models/filters/assetFilters'

const useStyles = makeStyles(() =>
  createStyles({
    buildingTextContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      height: 68,
      marginTop: 13,
    },
    actionName: {
      fontSize: 12,
    },
    centerButton: {
      marginLeft: 12,
      marginRight: 12,
    },
    leftButton: {
      marginLeft: 12,
    },
    rightButton: {
      marginRight: 12,
    },
  })
)

interface Props {
  agent: AgentLocationModel
  isMap: boolean
  watchlist: Watchlist
  onEditAgent?: () => void
  onRemovedFromWatchList: () => void
  floorLocationId: string | ''
  isPrivate: boolean
  isDuress?: boolean
  hasWatchListFilter: boolean
  setFilterButtonToggled: (filterButtonStates: any) => void
  forceRefresh: () => void
}

function DeprecatedAgentDetailDrawerActionButtons(
  props: Props
): React.ReactElement {
  const {
    agent,
    isMap,
    watchlist,
    onEditAgent,
    hasWatchListFilter,
    onRemovedFromWatchList,
    floorLocationId,
    isPrivate,
    isDuress,
    setFilterButtonToggled,
    forceRefresh,
  } = props
  const classes = useStyles()
  const { returnPath } = useQueryParams()
  const dispatch = useDispatch()
  const { user } = useAuth()
  const history = useNavigate()

  const watchlistItem: WatchlistItem | undefined = Object.values(
    watchlist
  ).find((x) => x.agentId === agent.agentGuid)
  const isWatchlisted = !!watchlistItem

  const locations = useSelector(
    ({ locations }: { locations: LocationsState }) => locations.data
  )

  const handleNavigationClick = (): void => {
    const urlParameters: [Array<string>] = [new Array<string>()]

    const agentTypeEnum: string | undefined =
      agent.agentType === AgentType.Staff
        ? AgentType[AgentType.Staff]
        : undefined

    const agentTypeString: string =
      agent.agentType === AgentType.Staff ? 'agentType' : ''

    dispatch(setCurrentLocationAction(locations[floorLocationId]))

    setFilterButtonToggled({
      assetListPage: undefined,
      mapPage: undefined,
      staffListPage: undefined,
    })

    if (isMap) {
      if (
        returnPath === undefined ||
        decodeURIComponent(returnPath) === CommonRouteKeys.RealTimePage.path
      ) {
        urlParameters.push(['view', 'list'])
      }
      urlParameters.push(
        !!agentTypeEnum ? [agentTypeString, agentTypeEnum] : []
      )

      urlParameters.push(['badgeId', agent.badgeIds[0]])
      history(
        buildURL(
          returnPath !== undefined
            ? decodeURIComponent(returnPath)
            : CommonRouteKeys.RealTimePage.path,
          urlParameters
        )
      )
    } else {
      history(
        buildURL(`/realtime`, [
          ['view', 'map'],
          !!agentTypeEnum ? [agentTypeString, agentTypeEnum] : [],
          ['badgeId', agent.badgeIds[0]],
          ['zoom', '10'],
        ])
      )
    }
  }

  const handleWatchListClick = (): void => {
    if (isWatchlisted) {
      const watchlistItem: WatchlistItem | undefined = Object.values(
        watchlist
      ).find((x) => x.agentId === agent.agentGuid)
      if (watchlistItem) {
        if (agent.agentType === AgentType.Asset) {
          dispatch(deleteAssetWatchlistItemAction.request(watchlistItem))
        } else if (agent.agentType === AgentType.Staff) {
          dispatch(deleteStaffWatchlistItemAction.request(watchlistItem))
        }
        if (hasWatchListFilter) {
          forceRefresh()
          onRemovedFromWatchList()
        }
      }
    } else {
      const watchlistItem: WatchlistItem = {
        userId: user.id,
        agentId: agent.agentGuid,
      }
      if (agent.agentType === AgentType.Asset) {
        dispatch(postAssetWatchlistItemAction.request(watchlistItem))
      } else if (agent.agentType === AgentType.Staff) {
        dispatch(postStaffWatchlistItemAction.request(watchlistItem))
      }
    }
  }

  return (
    <div className={classes.buildingTextContainer}>
      {onEditAgent ? (
        <div className={classes.leftButton}>
          <QuickActionAvatarCard
            cardText={
              agent.agentType === AgentType.Asset ? 'Edit Asset' : 'Edit Staff'
            }
            isDisabled={false}
            cardIconType={IconType.EditOutlined}
            onActionAreaClick={onEditAgent}
          ></QuickActionAvatarCard>
        </div>
      ) : (
        <div />
      )}
      <div className={classes.centerButton}>
        <QuickActionAvatarCard
          cardText={isMap ? 'View on List' : 'View on Map'}
          cardIconType={isMap ? IconType.List : IconType.PinDropIcon}
          onActionAreaClick={(): void => handleNavigationClick()}
          isDisabled={
            isDuress === true
              ? false
              : agent.badgeIds.length === 0 ||
                (isPrivate && !isMap) ||
                badgeSignalStatus(agent.badgeTelemetry?.timestamp) ===
                  BadgeSignalStatus.TimedOut
              ? true
              : false
          }
        ></QuickActionAvatarCard>
      </div>
      <div className={classes.rightButton}>
        <QuickActionAvatarCard
          cardText={
            isWatchlisted ? 'Remove from My List' : 'Add to My List' // Used to be called Watchlist
          }
          cardIconType={
            isWatchlisted ? IconType.PlayListAddCheck : IconType.PlayListAddIcon
          }
          onActionAreaClick={(): void => handleWatchListClick()}
        ></QuickActionAvatarCard>
      </div>
    </div>
  )
}

export default DeprecatedAgentDetailDrawerActionButtons
