import {
  DefaultHttpClient,
  HttpRequest,
  HttpResponse,
} from '@microsoft/signalr'
import { Authentication } from '../authentication/AuthenticationManager'

export default class CustomHttpClient extends DefaultHttpClient {
  constructor() {
    super(console)
  }

  public async send(request: HttpRequest): Promise<HttpResponse> {
    request.headers = {
      ...request.headers,
      'X-Tenant-ID': `${Authentication.tenantImpersonationId ?? ''}`,
    }
    return super.send(request)
  }
}
