import { isEmpty } from 'lodash'
import { useEffect, useRef } from 'react'
import { transformFetchedNewAgentsEnteringMap } from '../../helpers/telemetry'
import {
  AgentTypePlural,
  FetchingStatus,
  Geofences,
  Staff,
  TelemetryStaff,
} from '../../models'
import {
  AgentTelemetriesGroupedByTrackingId,
  NewAgentsEnteringMapTelemetry,
} from '../../models/telemetry'

/**
 * This hook listens to the Staff Fetching status state, determine if the fetched data includes any
 * of the awaiting fetched new staffs entering the map.
 * If data is received for an awaiting fetched new staff entering the map, transform the fetched data
 * to keep the fetched agent object and use the latest telemetry data from the list of awaiting fetched
 * staff state.
 * Add the transformed telemetry data to the current state of all badge telemetries.
 * If the currently selected agent type is Staff, add the transformed telemetry to the state so that it
 * can be rendered on the map. Otherwise, skip it.
 * Remove the fetched staff from the list of awaiting fetched staffs.
 * @param staffState
 * @param staffStatus
 * @param geofences
 * @param awaitingFetchedNewStaffsEnteringMap
 * @param agentTypes
 * @param addNewAgentsEnteringMapTelemetries state setter to store the transformed telemetry for new
 * asset that can be used for rendering on the map
 * @param addStaffTelemetriesToState add new telemetry to the current state of all badge telemetries
 * @param removeFetchedStaffs remove the fetched asset from the list of awaiting fetched new agent state
 * once data has been received, transformed and set via @param addNewAgentsEnteringMapTelemetries
 */
export function useCalculateNewStaffsEnteringMapUpdates(
  staffState: Staff[],
  staffStatus: FetchingStatus | undefined,
  geofences: Geofences,
  awaitingFetchedNewStaffsEnteringMap: AgentTelemetriesGroupedByTrackingId<TelemetryStaff>,
  agentTypes: string[],
  addNewAgentsEnteringMapTelemetries: (
    newStaffs: AgentTelemetriesGroupedByTrackingId
  ) => void,
  addStaffTelemetriesToState: (
    telemetries: AgentTelemetriesGroupedByTrackingId<TelemetryStaff>
  ) => void,
  removeFetchedStaffs: (fetchedStaffTrackingIds: string[]) => void
): void {
  const agentTypesRef = useRef<string[]>(agentTypes)
  const geofencesRef = useRef<Geofences>({})

  agentTypesRef.current = agentTypes
  geofencesRef.current = geofences

  useEffect(() => {
    let newStaffsEnteringMapTelemetry: NewAgentsEnteringMapTelemetry<TelemetryStaff>[] =
      []
    if (
      staffStatus === FetchingStatus.Success &&
      !isEmpty(awaitingFetchedNewStaffsEnteringMap)
    ) {
      const fetchedNewStaffsEnteringMap = staffState.filter((staff) => {
        return Object.keys(awaitingFetchedNewStaffsEnteringMap).includes(
          staff.badgeIds[0]
        )
      })
      if (fetchedNewStaffsEnteringMap.length > 0) {
        newStaffsEnteringMapTelemetry = fetchedNewStaffsEnteringMap.map(
          (staff) => {
            const {
              badgeIds,
              badgeTelemetry,
              roomLocation,
              floorLocation,
              buildingLocation,
              buildingGroupLocation,
              ...telemetryStaff
            } = staff
            return {
              agent: telemetryStaff,
              telemetry: awaitingFetchedNewStaffsEnteringMap[staff.badgeIds[0]],
            }
          }
        )
        const transformedStaffTelemetries =
          transformFetchedNewAgentsEnteringMap(newStaffsEnteringMapTelemetry)
        addStaffTelemetriesToState(transformedStaffTelemetries)
        if (
          agentTypesRef.current.includes(AgentTypePlural[AgentTypePlural.Staff])
        ) {
          addNewAgentsEnteringMapTelemetries(transformedStaffTelemetries)
        }
        removeFetchedStaffs(
          fetchedNewStaffsEnteringMap.map((staff) => staff.badgeIds[0])
        )
      }
    }
  }, [
    addStaffTelemetriesToState,
    awaitingFetchedNewStaffsEnteringMap,
    removeFetchedStaffs,
    addNewAgentsEnteringMapTelemetries,
    staffState,
    staffStatus,
  ])
}
