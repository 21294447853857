import {
  AgentEventMapUpdateTypes,
  EnteringGeofenceMapUpdateTypes,
  LeavingGeofenceMapUpdateTypes,
  MapRenderByGeofence,
} from '../../models/realtimeMap'
import { BadgeTelemetryMessageWithAgentEvent } from '../../models/telemetry'
import {
  findAgentInCurrentMapRender,
  findGeofenceAgentIsIn,
} from '../realtimeMap/currentMapRender'

type AgentMovingBetweenGeofencesUpdate = {
  leavingGeofenceUpdateType: LeavingGeofenceMapUpdateTypes
  enteringGeofenceUpdateType: EnteringGeofenceMapUpdateTypes
}

type MapUpdateType = {
  agentMovingBetweenGeofencesUpdate:
    | AgentMovingBetweenGeofencesUpdate
    | undefined
  agentEventUpdateType: AgentEventMapUpdateTypes | undefined
}

/**
 * Calculate the map update type for an agent/group currently rendered on the map based on
 * the new telemetry received via SignalR and the current state of icons being rendered on the map.
 * Calculation of map update type is split into calculation of updates for leaving geofence and entering geofence.
 *
 * Leaving Geofence: Calculate update based on whether icon is currently rendered as group or not
 *    1. NOT currently rendered as group -> MoveAgent
 *    2. Currently rendered as a group and to remain rendering as Group based on remaning agents count -> MoveAgentFromGroup
 *    3. Currently rendered as a group and remaning agents is to be rerendered as either an Asset or Staff icon -> Degroup
 *
 * Entering Geofence: Calculate update based on whether icon is to be rendered in the entering geofence as a group or not
 *    1. No other icon currently rendered in the entering geofence -> Ungrouped
 *    2. There is either an Asset or Staff already rendered in the entering geofence -> CreateNewGroup
 *    3. There is already a group icon rendered in the entering geofence -> UpdateGroup
 * @param newAgentTelemetry
 * @param currentMapRender current state of agent and group icons rendered on the map
 * @returns MapUpdateType enum which can be used to later process map rerendering
 */
export function calculateMapUpdateType(
  newAgentTelemetry: BadgeTelemetryMessageWithAgentEvent,
  currentMapRender: MapRenderByGeofence
): MapUpdateType | undefined {
  let leavingGeofenceUpdateType: LeavingGeofenceMapUpdateTypes
  let enteringGeofenceUpdateType: EnteringGeofenceMapUpdateTypes
  let agentEventUpdateType: AgentEventMapUpdateTypes | undefined

  const prevGeofenceAgentWasIn = findGeofenceAgentIsIn(
    newAgentTelemetry.trackingId,
    currentMapRender
  )
  const prevAgentTelemetry = findAgentInCurrentMapRender(
    newAgentTelemetry.trackingId,
    currentMapRender
  )
  if (!prevGeofenceAgentWasIn || !prevAgentTelemetry) {
    console.info(
      'New agent entering map is still being rendered. Skipping this telemetry update!'
    )
    return
  }
  const prevMapRenderForAgent = currentMapRender[prevGeofenceAgentWasIn]
  if (prevGeofenceAgentWasIn === newAgentTelemetry.geoFenceId) {
    if (
      newAgentTelemetry.hasActiveDuress &&
      !prevAgentTelemetry.hasActiveDuress
    ) {
      agentEventUpdateType = AgentEventMapUpdateTypes.NewActiveDuress
      return {
        agentMovingBetweenGeofencesUpdate: undefined,
        agentEventUpdateType,
      }
    } else if (
      newAgentTelemetry.hasActiveAssist &&
      !prevAgentTelemetry.hasActiveAssist
    ) {
      agentEventUpdateType = AgentEventMapUpdateTypes.NewActiveAssist
      return {
        agentMovingBetweenGeofencesUpdate: undefined,
        agentEventUpdateType,
      }
    } else if (
      newAgentTelemetry.hasResolvedDuressOrAssist &&
      !prevAgentTelemetry.hasResolvedDuressOrAssist &&
      (prevAgentTelemetry.hasActiveDuress || prevAgentTelemetry.hasActiveAssist)
    ) {
      agentEventUpdateType = AgentEventMapUpdateTypes.ResolvedDuressOrAssist
      return {
        agentMovingBetweenGeofencesUpdate: undefined,
        agentEventUpdateType,
      }
    } else {
      return
    }
  }
  // Leaving geofence
  if (prevMapRenderForAgent) {
    const leavingGeofenceHasGroupAgents =
      prevMapRenderForAgent.renderedAgents.length >=
      prevMapRenderForAgent.maxAgentsAllowedInGeofence
    if (leavingGeofenceHasGroupAgents) {
      const currentGroupCount = prevMapRenderForAgent.renderedAgents.length
      const newGroupCount = currentGroupCount - 1
      if (newGroupCount < prevMapRenderForAgent.maxAgentsAllowedInGeofence) {
        leavingGeofenceUpdateType = LeavingGeofenceMapUpdateTypes.Degroup
      } else {
        leavingGeofenceUpdateType =
          LeavingGeofenceMapUpdateTypes.MoveAgentFromGroup
      }
    } else {
      leavingGeofenceUpdateType = LeavingGeofenceMapUpdateTypes.MoveAgent
    }

    // Entering geofence
    const currentMapRenderForEnteringGeofence =
      currentMapRender[newAgentTelemetry.geoFenceId]
    if (currentMapRenderForEnteringGeofence) {
      const enteringGeofenceHasGroupAgents =
        currentMapRenderForEnteringGeofence.renderedAgents.length >=
        currentMapRenderForEnteringGeofence.maxAgentsAllowedInGeofence
      if (enteringGeofenceHasGroupAgents) {
        enteringGeofenceUpdateType = EnteringGeofenceMapUpdateTypes.UpdateGroup
      } else {
        const newGroupCount =
          currentMapRenderForEnteringGeofence.renderedAgents.length + 1
        if (
          newGroupCount >=
          currentMapRenderForEnteringGeofence.maxAgentsAllowedInGeofence
        ) {
          enteringGeofenceUpdateType =
            EnteringGeofenceMapUpdateTypes.CreateNewGroup
        } else {
          enteringGeofenceUpdateType = EnteringGeofenceMapUpdateTypes.Ungrouped
        }
      }
    } else {
      console.warn('Entering geofenceId not found in MapRenderState')
      return
    }
  } else {
    console.warn('Previous rendered agent not found in MapRenderState')
    return
  }
  return {
    agentMovingBetweenGeofencesUpdate: {
      leavingGeofenceUpdateType,
      enteringGeofenceUpdateType,
    },
    agentEventUpdateType,
  }
}
