import { AssetKitInstance } from '../../models'
import {
  InpixonAssetAgentCreateOptions,
  InpixonAssetGroupDeleteOptions,
} from '../../models/inpixonMap'
import { MapRenderByGeofence } from '../../models/realtimeMap'
import { BadgeTelemetryMessageWithAgentEvent } from '../../models/telemetry'
import {
  buildInpixonAssetAgentCreateOptions,
  deleteGroupInpixonAsset,
  showDegroupingAgents,
} from '../inpixon'
import { calculateMapRenderToUpdateForAgentLeavingMap } from '../realtimeMap'
import { groupHasActiveDuress } from '../telemetry'

/**
 * If the leaving agent is currently rendered as a group icon and the remaining agents are
 * to be degrouped after the agent left, remove the group icon from the map and rerender the
 * remaining agents as either Asset/Staff icon.
 * If the leaving agent has an active duress and the remaining agents do not have any active duress,
 * remove the geofence highlighting
 * If the leaving agent is currently selected on the map, close the Detail Drawer.
 * Otherwise, if the leaving agent is in a selected group, remove the agent from the Detail Drawer agent list.
 * @param agentTelemetry
 * @param currentMapRender
 * @param remainingAgentsInLeavingGeofence
 * @param leavingGeofenceId
 * @param assetKit
 * @param assetKitId
 * @param selectedTrackingId
 * @param selectedGroupId
 * @param useGeofenceCentroid
 * @param iconScale
 * @param geofenceInstances
 * @param jibestreamController
 * @param removeGeofenceHighlight
 * @param removeSelectedAgent
 * @param removeAgentFromSelectedGroup
 * @returns updated map render state after map rerendering
 */
export function showRemainingAgentsAfterAgentLeft(
  agentTelemetry: BadgeTelemetryMessageWithAgentEvent,
  currentMapRender: MapRenderByGeofence,
  remainingAgentsInLeavingGeofence: BadgeTelemetryMessageWithAgentEvent[],
  leavingGeofenceId: number,
  assetKit: AssetKitInstance,
  assetKitId: number,
  selectedTrackingId: string | undefined,
  selectedGroupId: number | undefined,
  useGeofenceCentroid: boolean,
  iconScale: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  geofenceInstances: any[],
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any,
  removeGeofenceHighlight: (geofenceId: number) => void,
  removeSelectedAgent: (
    agentTelemtry: BadgeTelemetryMessageWithAgentEvent
  ) => void,
  removeAgentFromSelectedGroup: (
    agentTelemtry: BadgeTelemetryMessageWithAgentEvent
  ) => void
): {
  nextInpixonAssetId: number | undefined
  updatedMapRender: MapRenderByGeofence
} {
  const agentsToDegroup: InpixonAssetAgentCreateOptions[] = []
  let nextInpixonAssetId: number | undefined
  // Updated group need to be degrouped after agent left
  remainingAgentsInLeavingGeofence.forEach((telemetry) => {
    const inpixonAssetAgentCreate = buildInpixonAssetAgentCreateOptions(
      telemetry,
      iconScale,
      selectedTrackingId
    )
    if (inpixonAssetAgentCreate) {
      agentsToDegroup.push(inpixonAssetAgentCreate)
    }
  })
  const groupToRemove: InpixonAssetGroupDeleteOptions = {
    geofenceId: leavingGeofenceId,
  }
  deleteGroupInpixonAsset(assetKit, groupToRemove)
  if (agentsToDegroup.length > 0) {
    nextInpixonAssetId = showDegroupingAgents(
      assetKit,
      agentsToDegroup,
      assetKitId,
      useGeofenceCentroid,
      geofenceInstances,
      jibestreamController
    )
  }
  const updatedMapRender = calculateMapRenderToUpdateForAgentLeavingMap(
    currentMapRender,
    agentTelemetry
  )
  if (agentTelemetry.hasActiveDuress) {
    const remainingAgentsHaveActiveDuress = groupHasActiveDuress(
      remainingAgentsInLeavingGeofence
    )
    if (!remainingAgentsHaveActiveDuress) {
      removeGeofenceHighlight(leavingGeofenceId)
    }
  }
  if (agentTelemetry.trackingId === selectedTrackingId) {
    removeSelectedAgent(agentTelemetry)
  } else if (leavingGeofenceId === selectedGroupId) {
    removeAgentFromSelectedGroup(agentTelemetry)
  }
  return {
    nextInpixonAssetId,
    updatedMapRender,
  }
}
