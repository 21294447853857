import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { ImportsBadgesApi } from 'v2/middleware'
import RootAction from '../../actions'
import { RootState } from '../../reducers'
import {
  getAllBadgesImportsAction,
  postImportBadgesAction,
} from 'v2/actions/importsBadges'

type ImportsBadgesEpic = Epic<
  RootAction,
  RootAction,
  RootState,
  { importsBadgesApi: ImportsBadgesApi }
>

export const getAllBadgesImportsEpic: ImportsBadgesEpic = (
  action$,
  state,
  { importsBadgesApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllBadgesImportsAction.request)),
    mergeMap((action) =>
      from(importsBadgesApi.getAllBadgesImports()).pipe(
        map((imports) => getAllBadgesImportsAction.success(imports)),
        catchError((error) => of(getAllBadgesImportsAction.failure(error)))
      )
    )
  )
}

export const postImport: ImportsBadgesEpic = (
  action$,
  state,
  { importsBadgesApi }
) => {
  return action$.pipe(
    filter(isActionOf(postImportBadgesAction.request)),
    mergeMap((action) =>
      from(importsBadgesApi.postImportBadges(action.payload)).pipe(
        map((importResponse) => postImportBadgesAction.success(importResponse)),
        catchError((error) => of(postImportBadgesAction.failure(error)))
      )
    )
  )
}
