import { HttpErrorResponse } from '@midmarkrtls/common/services/http'
import { CreateTenant } from 'models/CreateTenent'
import { createAsyncAction } from 'typesafe-actions'
import { Tenant } from 'v2/models'

export const getAllTenantsAction = createAsyncAction(
  'GET_ALL_TENANTS_REQUEST',
  'GET_ALL_TENANTS_SUCCESS',
  'GET_ALL_TENANTS_FAILURE'
)<void, Tenant[], HttpErrorResponse>()

export const getCurrentUserTenantsAction = createAsyncAction(
  'GET_CURRENT_USER_TENANTS_REQUEST',
  'GET_CURRENT_USER_TENANTS_SUCCESS',
  'GET_CURRENT_USER_TENANTS_FAILURE'
)<string, Tenant[], HttpErrorResponse>()

export const postOperationsCustomerAction = createAsyncAction(
  'POST_OPERATIONS_CUSTOMER_REQUEST',
  'POST_OPERATIONS_CUSTOMER_SUCCESS',
  'POST_OPERATIONS_CUSTOMER_FAILURE'
)<CreateTenant, Tenant[], HttpErrorResponse>()
