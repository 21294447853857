import { createSelector } from 'reselect'
import { AgentEventsLocationState } from '../models'

export const selectAgentEventLocationState = ({
  agentEventsLocation,
}: {
  agentEventsLocation: AgentEventsLocationState
}): AgentEventsLocationState => agentEventsLocation

export const selectAgentEventLocationFetchingStatus = createSelector(
  selectAgentEventLocationState,
  (agentEventLocation) => agentEventLocation.status
)
