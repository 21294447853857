import { WatchlistItem } from '../models'
import { BaseService } from './baseService'
import { AuthState, B2CUser } from '../models'
import { useSelector } from 'react-redux'

export class WatchlistApi extends BaseService {
  constructor() {
    super({ entityType: 'watchlist' })
  }

  route = 'users'
  genericRoute = 'watchlists/items'
  assetsRoute = 'assetwatchlists/items'
  staffRoute = 'staffwatchlists/items'

  async getWatchlist(userId: string): Promise<WatchlistItem[]> {
    return await this.get<WatchlistItem[]>(
      `${this.route}/${userId}/${this.genericRoute}`
    )
  }

  async getAssetWatchlist(userId: string): Promise<WatchlistItem[]> {
    return await this.get<WatchlistItem[]>(
      `${this.route}/${userId}/${this.assetsRoute}`
    )
  }

  async postAssetWatchlistItem(
    watchlistItem: WatchlistItem
  ): Promise<WatchlistItem> {
    return await this.post<WatchlistItem>(
      `${this.route}/${watchlistItem.userId}/${this.assetsRoute}`,
      watchlistItem
    )
  }

  async deleteAssetWatchlistItem(
    watchlistItem: WatchlistItem
  ): Promise<boolean> {
    return await this.delete(
      `${this.route}/${watchlistItem.userId}/${this.assetsRoute}/${watchlistItem.id}`
    )
  }

  async getStaffWatchlist(userId: string): Promise<WatchlistItem[]> {
    return await this.get<WatchlistItem[]>(
      `${this.route}/${userId}/${this.staffRoute}`
    )
  }

  async postStaffWatchlistItem(
    watchlistItem: WatchlistItem
  ): Promise<WatchlistItem> {
    return await this.post<WatchlistItem>(
      `${this.route}/${watchlistItem.userId}/${this.staffRoute}`,
      watchlistItem
    )
  }

  async deleteStaffWatchlistItem(
    watchlistItem: WatchlistItem
  ): Promise<boolean> {
    return await this.delete(
      `${this.route}/${watchlistItem.userId}/${this.staffRoute}/${watchlistItem.id}`
    )
  }
}
