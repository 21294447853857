import { useDispatch } from 'react-redux'
import { DrawerType } from './PageContent'
import { useEffect } from 'react'
import { setRightDrawerOpen } from '../../actions/pageLayout'

export const useRightDrawerController = (
  currentDrawerType: DrawerType | undefined,
  openRightDrawer: boolean | undefined
): void => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (openRightDrawer !== undefined) {
      if (openRightDrawer) {
        dispatch(setRightDrawerOpen(true))
      } else {
        dispatch(setRightDrawerOpen(false))
      }
      return
    }
    if (
      currentDrawerType !== undefined &&
      currentDrawerType !== DrawerType.None
    ) {
      dispatch(setRightDrawerOpen(true))
    } else {
      dispatch(setRightDrawerOpen(false))
    }
  }, [currentDrawerType, dispatch, openRightDrawer])
}
