import { TextField, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useFormStyle } from '../../styles'

interface Props {
  inputLabel: string
  confirmationKeyword: string
  setIsConfirmed: (isConfirmed: boolean) => void
  disableEnterButtonFormSubmission?: boolean
}

export interface ConfirmationFormInput {
  inputLabel: string
  keyword: string
}

interface ConfirmationForm {
  name: string
}

const ActionConfirmationForm = (props: Props) => {
  const {
    inputLabel,
    confirmationKeyword,
    setIsConfirmed,
    disableEnterButtonFormSubmission = false,
  } = props

  const {
    control,
    formState: { isDirty, errors },
  } = useForm<ConfirmationForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: '',
    } as ConfirmationForm,
  })

  const classes = useFormStyle()

  const validateConfirmationKeyword = (value: string) => {
    return value.toLowerCase() === confirmationKeyword.toLowerCase()
  }

  useEffect(() => {
    if (!isDirty) {
      return
    }
    setIsConfirmed(Object.values(errors).length === 0)
  }, [JSON.stringify(errors)])

  return (
    <form>
      {disableEnterButtonFormSubmission && (
        <button
          type='submit'
          disabled
          style={{ display: 'none' }}
          aria-hidden='true'
        />
      )}
      <Typography variant='body1'>
        Please type {confirmationKeyword.toUpperCase()} to confirm.
      </Typography>
      <Controller
        name='name'
        control={control}
        rules={{
          required: true,
          validate: validateConfirmationKeyword,
        }}
        render={({ field }) => {
          return (
            <TextField
              {...field}
              margin='normal'
              id='action-confirmation'
              label={inputLabel}
              variant='filled'
              fullWidth
              required
              error={!!errors.name}
              className={classes.inputStyle}
            />
          )
        }}
      />
    </form>
  )
}

export default ActionConfirmationForm
