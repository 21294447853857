import { useEffect, useRef, useState } from 'react'

interface IntervalOptions {
  millisecondsElapsed: number
  resetInterval: (resetMillisecondsElapsed?: number) => void
  clearInterval: () => void
}

const useInterval = (
  action: (millisecondsElapsed?: number) => void,
  interval: number,
  enabled = true
): IntervalOptions => {
  const actionRef = useRef(action)
  const idRef = useRef<NodeJS.Timeout>()
  const [millisecondsElapsed, setMillisecondsElapsed] = useState<number>(0)

  // Remember the latest callback.
  useEffect(() => {
    actionRef.current = action
  }, [action])

  const resetInterval = (resetMillisecondsElapsed = 0) => {
    if (idRef.current) {
      setMillisecondsElapsed(resetMillisecondsElapsed)
    }
  }

  const clearIntervalRef = () => {
    if (idRef.current) {
      clearInterval(idRef.current)
      setMillisecondsElapsed(0)
      idRef.current = undefined
    }
  }

  // Set up the interval.
  useEffect(() => {
    if (enabled) {
      if (!idRef.current) {
        idRef.current = setInterval(() => {
          setMillisecondsElapsed((prev) => prev + interval)
        }, interval)
      }
    } else {
      clearIntervalRef()
    }
    return () => {
      clearIntervalRef()
    }
  }, [enabled, idRef, interval, millisecondsElapsed])

  useEffect(() => {
    actionRef.current(millisecondsElapsed)
  }, [millisecondsElapsed])

  return { millisecondsElapsed, resetInterval, clearInterval: clearIntervalRef }
}

export default useInterval
