import { BadgeLocationState } from '../hooks/useBadgeLocationMonitor'
import {
  BadgeSignalStatus,
  Location,
  LocationGeofences,
  Locations,
  SignalType,
  Staff,
  StaffEvent,
  UserPreferences,
  WatchlistItem,
} from '../models'
import { StaffRow } from '../models/staffRow'
import { badgeSignalAccuracy, badgeSignalStatus } from './assetSignal'
import { getLocationBasedBatteryStatus, isLowBattery } from './battery'
import { formatDate, formatLocalDateTimeByUserPreference } from './date'
import { getDefaultStaffIcon, getIconStatusList } from './inpixon/icons'
import {
  getLocationAncestors,
  getRoomDisplayName,
  isLocationPrivate,
} from './location'
import { hasStaffAssist, hasStaffDuress } from './staffEvents'

export const createStaffRow = (
  staffMember: Staff,
  currentBadgeLocations: BadgeLocationState,
  currentGeofences: LocationGeofences,
  watchlist: WatchlistItem[],
  duressStaffShow: boolean,
  activeDuressEvents: StaffEvent[],
  activeAssistEvents: StaffEvent[],
  allLocations: Locations,
  userPreferences: UserPreferences
): StaffRow => {
  const badgeId = staffMember.badgeIds[0]
  const currentBadgeLocation = currentBadgeLocations[badgeId]
  const isWatchListed = watchlist.some(
    (item) => item.agentId === staffMember.agentGuid
  )

  let signalStatus: BadgeSignalStatus | undefined
  let updatedRoomLocation: Location | undefined
  let accuracy = ''
  let signalType = ''
  let lastSensedDate: Date | undefined
  let isDuress = false
  let isAssist = false

  if (duressStaffShow) {
    isDuress = hasStaffDuress(staffMember.badgeIds[0], activeDuressEvents)
    isAssist = hasStaffAssist(staffMember.badgeIds[0], activeAssistEvents)
  }
  if (currentBadgeLocation && currentBadgeLocation.roomLocation) {
    if (currentBadgeLocation.timestamp) {
      signalStatus = badgeSignalStatus(currentBadgeLocation.timestamp)
    }
    updatedRoomLocation = currentBadgeLocation.roomLocation
    lastSensedDate = currentBadgeLocation.timestamp
    accuracy = badgeSignalAccuracy(currentBadgeLocation.currentSignalType)
    signalType = SignalType[currentBadgeLocation.currentSignalType]
  } else if (staffMember.badgeIds[0]) {
    if (staffMember.badgeTelemetry) {
      if (staffMember.badgeTelemetry.timestamp) {
        signalStatus = badgeSignalStatus(staffMember.badgeTelemetry.timestamp)
        lastSensedDate = staffMember.badgeTelemetry.timestamp
      }
      if (staffMember.badgeTelemetry.geoFenceId) {
        const roomLocationId =
          currentGeofences[staffMember.badgeTelemetry.geoFenceId]?.locationId

        if (roomLocationId) {
          updatedRoomLocation = allLocations[roomLocationId]
        }
      }
      accuracy = badgeSignalAccuracy(staffMember.badgeTelemetry.signalTypeId)
      signalType = SignalType[staffMember.badgeTelemetry.signalTypeId]
    }
  }

  const isPrivate =
    isDuress && isAssist
      ? false
      : isLocationPrivate(allLocations, updatedRoomLocation?.name ?? '')

  const updatedLocationAncestors = getLocationAncestors(
    updatedRoomLocation,
    allLocations
  )

  const image = getIconStatusList(
    signalStatus ?? BadgeSignalStatus.Current,
    isLowBattery(staffMember?.isLowBattery),
    process.env.REACT_APP_STATIC_CONTENT + staffMember.iconUrl,
    getDefaultStaffIcon,
    isDuress,
    isAssist
  )

  return {
    ...staffMember,
    roomLocation: updatedRoomLocation,
    floorLocation: updatedLocationAncestors?.floor,
    badgeNumber: badgeId,
    icon: image,
    locationName: getRoomDisplayName(
      updatedRoomLocation?.name ?? '',
      isPrivate
    ),
    floor: updatedLocationAncestors.floor?.name ?? '',
    building: updatedLocationAncestors.building?.name ?? '',
    buildingGroup: updatedLocationAncestors.buildingGroup?.name ?? '',
    batteryStatus: getLocationBasedBatteryStatus(
      updatedRoomLocation,
      staffMember?.isLowBattery
    ),
    signalStatus: signalStatus ?? '',
    accuracy: accuracy ?? '',
    signalType: signalType,
    lastSensedDateString: formatLocalDateTimeByUserPreference(
      lastSensedDate,
      userPreferences.timeDisplayId
    ),
    tagBatteryChangeDateString: formatDate(staffMember.tagBatteryChangeDate),
    locationUnitName: updatedRoomLocation?.unitName,
    locationSubUnitName: updatedRoomLocation?.subUnitName,
    isWatchListed,
    isPrivate,
  }
}
