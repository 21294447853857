import { BaseService } from '@midmarkrtls/common/middleware'
import { CreateEvent } from 'v2/models'

export class CreateEventApi extends BaseService {
  constructor() {
    super({ entityType: 'createEvent' })
  }
  route = 'devtools/agentEvent'

  async createEvent(createEvent: CreateEvent): Promise<void> {
    return await this.post(this.route, createEvent)
  }
}

export const createEventApi = new CreateEventApi()
