import React from 'react'
import { CommonComponent, ComponentProps, stylize } from '..'
import {
  flex,
  FlexAlignmentProps,
  FlexDirection,
  FlexProps,
  FlexWrap,
  fxLayout,
} from '../../styles'

interface Props {
  container?: boolean
  flex?: FlexProps | string
  layout?: FlexDirection
  wrap?: FlexWrap
  layoutAlign?: FlexAlignmentProps
}

const FlexBox: CommonComponent<Props> = (props: ComponentProps<Props>) => {
  const {
    container = false,
    children,
    flex: flexOption = 'default',
    layout,
    wrap = false,
    layoutAlign = {},
  } = props
  const isContainer = container || !!layout
  const layoutStyles = isContainer
    ? fxLayout(layout ?? 'row', {
        wrap,
        ...layoutAlign,
      })
    : {}

  const styles = {
    ...flex(flexOption),
    ...layoutStyles,
  }
  return <div {...stylize(props, { css: styles })}>{children}</div>
}

export default FlexBox
