import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PayloadAction } from 'typesafe-actions'
import { useAuth } from '.'
import { setCurrentLocationAction } from '../actions/currentLocation'
import { getVenueIdFromLocation } from '../helpers'
import { Location } from '../models'
import { useFetchVenues } from './entities/useFetchVenues'

export interface UseCurrentLocationResult {
  currentLocation: Location | null
  venueId: number | undefined
  setCurrentLocation: (
    newLocation: Location | null
  ) => PayloadAction<'SET_CURRENT_LOCATION', Location | null>
}

export function useCurrentLocation(): UseCurrentLocationResult {
  const dispatch = useDispatch()
  const { currentCustomerId } = useAuth()
  const { data: venues } = useFetchVenues(!!currentCustomerId)

  const setCurrentLocation = (
    newLocation: Location | null
  ): PayloadAction<'SET_CURRENT_LOCATION', Location | null> =>
    dispatch(setCurrentLocationAction(newLocation))

  const currentLocation = useSelector(
    ({ currentLocation }: { currentLocation: Location }) => currentLocation
  )

  const venueId = useMemo(() => {
    return getVenueIdFromLocation(currentLocation, venues)
  }, [currentLocation, venues])

  return {
    currentLocation,
    venueId,
    setCurrentLocation,
  }
}
