import { isEmpty } from 'lodash'
import { useEffect, useRef } from 'react'
import { transformFetchedNewAgentsEnteringMap } from '../../helpers/telemetry'
import {
  AgentTypePlural,
  Asset,
  FetchingStatus,
  Geofences,
  TelemetryAsset,
} from '../../models'
import {
  AgentTelemetriesGroupedByTrackingId,
  NewAgentsEnteringMapTelemetry,
} from '../../models/telemetry'

/**
 * This hook listens to the Asset Fetching status state, determine if the fetched data includes any
 * of the awaiting fetched new assets entering the map.
 * If data is received for an awaiting fetched new asset entering the map, transform the fetched data
 * to keep the fetched agent object and use the latest telemetry data from the list of awaiting fetched
 * asset state.
 * Add the transformed telemetry data to the current state of all badge telemetries.
 * If the currently selected agent type is Asset, add the transformed telemetry to the state so that it
 * can be rendered on the map. Otherwise, skip it.
 * Remove the fetched asset from the list of awaiting fetched assets.
 * @param assetState
 * @param assetStatus
 * @param geofences
 * @param awaitingFetchedNewAssetsEnteringMap
 * @param agentTypes
 * @param addNewAgentsEnteringMapTelemetries state setter to store the transformed telemetry for new
 * asset that can be used for rendering on the map
 * @param addAssetTelemetriesToState add new telemetry to the current state of all badge telemetries
 * @param removeFetchedAssets remove the fetched asset from the list of awaiting fetched new agent state
 * once data has been received, transformed and set via @param addNewAgentsEnteringMapTelemetries
 */
export function useCalculateNewAssetsEnteringMapUpdates(
  assetState: Asset[],
  assetStatus: FetchingStatus | undefined,
  geofences: Geofences,
  awaitingFetchedNewAssetsEnteringMap: AgentTelemetriesGroupedByTrackingId<TelemetryAsset>,
  agentTypes: string[],
  addNewAgentsEnteringMapTelemetries: (
    newAssets: AgentTelemetriesGroupedByTrackingId
  ) => void,
  addAssetTelemetriesToState: (
    telemetries: AgentTelemetriesGroupedByTrackingId<TelemetryAsset>
  ) => void,
  removeFetchedAssets: (fetchedAssetTrackingIds: string[]) => void
): void {
  const agentTypesRef = useRef<string[]>(agentTypes)
  const geofencesRef = useRef<Geofences>({})

  agentTypesRef.current = agentTypes
  geofencesRef.current = geofences

  useEffect(() => {
    let newAssetsEnteringMapTelemetry: NewAgentsEnteringMapTelemetry<TelemetryAsset>[] =
      []
    if (
      assetStatus === FetchingStatus.Success &&
      !isEmpty(awaitingFetchedNewAssetsEnteringMap)
    ) {
      const fetchedNewAssetsEnteringMap = assetState.filter((asset) => {
        return Object.keys(awaitingFetchedNewAssetsEnteringMap).includes(
          asset.badgeIds[0]
        )
      })
      if (fetchedNewAssetsEnteringMap.length > 0) {
        newAssetsEnteringMapTelemetry = fetchedNewAssetsEnteringMap.map(
          (asset) => {
            const {
              badgeIds,
              badgeTelemetry,
              roomLocation,
              floorLocation,
              buildingLocation,
              buildingGroupLocation,
              ...telemetryAsset
            } = asset
            return {
              agent: telemetryAsset,
              telemetry: awaitingFetchedNewAssetsEnteringMap[asset.badgeIds[0]],
            }
          }
        )
        const transformedAssetTelemetries =
          transformFetchedNewAgentsEnteringMap(newAssetsEnteringMapTelemetry)
        addAssetTelemetriesToState(transformedAssetTelemetries)
        if (
          agentTypesRef.current.includes(
            AgentTypePlural[AgentTypePlural.Assets]
          )
        ) {
          addNewAgentsEnteringMapTelemetries(transformedAssetTelemetries)
        }
        removeFetchedAssets(
          fetchedNewAssetsEnteringMap.map((asset) => asset.badgeIds[0])
        )
      }
    }
  }, [
    addAssetTelemetriesToState,
    assetState,
    assetStatus,
    awaitingFetchedNewAssetsEnteringMap,
    removeFetchedAssets,
    addNewAgentsEnteringMapTelemetries,
  ])
}
