import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setActiveAssistEvents,
  setAllAssistFilteredByUser,
} from '../actions/staffAssist'
import { shouldShowAlertNotification } from '../helpers/shouldShowAlertNotification'
import {
  AgentEventType,
  EventStatus,
  FetchingStatus,
  StaffEvent,
} from '../models'
import {
  selectAuthState,
  selectCurrentUserLocationState,
  selectStaffEventData,
} from '../selectors'
import { useFetchAlerts } from './entities/useFetchAlerts'
import { useFetchUserById } from './entities/useFetchUserById'

export function useStaffAssistEvents(): {
  activeStaffAssists: StaffEvent[]
} {
  const [activeAssists, setActiveAssists] = useState<StaffEvent[]>([])
  const currentUserLocationState = useSelector(selectCurrentUserLocationState)
  const {
    data: currentUserLocation,
    status: currentUserLocationFetchingStatus,
  } = currentUserLocationState
  const { user } = useSelector(selectAuthState)
  const { data: userInfo, status: currentUserFetchingStatus } =
    useFetchUserById(user.id)
  const { alerts, alertsFetchingStatus } = useFetchAlerts()
  const dispatch = useDispatch()

  const allEvents = useSelector(selectStaffEventData)

  const alreadyShownEventIds = useRef<string[]>([])

  useEffect(() => {
    if (
      currentUserLocationFetchingStatus === FetchingStatus.Request ||
      currentUserFetchingStatus === FetchingStatus.Request ||
      alertsFetchingStatus === FetchingStatus.Request
    ) {
      return
    }
    const filteredStaffAssists: StaffEvent[] = []

    Object.values(allEvents)
      .filter(
        (staffEvent) => staffEvent.agentEventTypeId === AgentEventType.Assist
      )
      .forEach((staffEvent: StaffEvent) => {
        const alert = Object.values(alerts).find(
          (x) => x.id === staffEvent.alertId
        )

        const userRoleIds = userInfo?.roleIds ?? []

        if (
          shouldShowAlertNotification(
            staffEvent,
            currentUserLocation,
            userRoleIds,
            alert,
            alreadyShownEventIds.current
          )
        ) {
          filteredStaffAssists.push(staffEvent)
          if (!alreadyShownEventIds.current.includes(staffEvent.id)) {
            alreadyShownEventIds.current.push(staffEvent.id)
          }
        }
      })
    dispatch(setAllAssistFilteredByUser(filteredStaffAssists))

    const activeAssists = filteredStaffAssists.filter(
      (e) => e.status !== EventStatus.RESOLVED
    )
    setActiveAssists(activeAssists)
    dispatch(setActiveAssistEvents(activeAssists))
  }, [
    alerts,
    alertsFetchingStatus,
    allEvents,
    currentUserFetchingStatus,
    currentUserLocation,
    currentUserLocationFetchingStatus,
    dispatch,
    userInfo?.roleIds,
  ])

  return {
    activeStaffAssists: activeAssists,
  }
}
