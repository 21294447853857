export enum SignalType {
  Unknown,
  IR,
  'IR/RF',
  BLE,
}

// this may be completely useless in the future once we prove out multiple signal type badges
export const SignalTypePriority: Record<SignalType, number> = {
  [SignalType.Unknown]: 0,
  [SignalType.IR]: 1,
  [SignalType['IR/RF']]: 2, //UI Only implementation we still want the DB to map these as RF but in the UI we want to show IR/RF based on that we are showing the last IR location for RF sensed badges
  [SignalType.BLE]: 3,
}
