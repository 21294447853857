import { useMemo } from 'react'
import { usePermissionCheck } from '.'
import { FetchingStatus, PermissionsEnum } from '../models'
import { useSelector } from 'react-redux'
import {
  selectAllFeatureFlags,
  selectFeatureFlagFetchingStatus,
} from '../selectors'

export function useStaffVisibility(): boolean {
  const featureFlags = useSelector(selectAllFeatureFlags)
  const featureFlagFetchingStatus = useSelector(selectFeatureFlagFetchingStatus)
  const hasStaffReadPermission = usePermissionCheck([PermissionsEnum.StaffRead])
  const hasStaffVisibility = useMemo(() => {
    if (featureFlagFetchingStatus !== FetchingStatus.Request) {
      const isStaffVisibilityEnabled =
        Object.values(featureFlags).filter(
          (x) => x.name === 'StaffVisibility' && x.enabled === true
        ).length > 0
      return isStaffVisibilityEnabled ? hasStaffReadPermission : true
    }
    return false
  }, [featureFlags, hasStaffReadPermission, featureFlagFetchingStatus])

  return hasStaffVisibility
}
