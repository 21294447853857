import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React, { useMemo } from 'react'
import DetailsList from '../../../components/DetailsList/DetailsList'
import { SubUnit } from '../../../models'
import { DetailedItem, Item } from '../../../models/DetailPaneItems'

interface Props {
  subUnit: SubUnit
}

const useStyles = makeStyles(() =>
  createStyles({
    detailPanelBody: {
      zIndex: 999,
      position: 'absolute',
      width: '100%',
    },
    detailListBody: {
      paddingTop: '28px',
    },
  })
)

function SubUnitDetailPane(props: Props): React.ReactElement {
  const { subUnit } = props
  const classes = useStyles()

  const detailItems: DetailedItem[] = useMemo(() => {
    const detailItems: Item[] = [{ field: 'Name', value: subUnit?.subUnitName }]

    return [{ header: 'SUB-UNIT INFORMATION', items: detailItems }]
  }, [subUnit])

  return (
    <div className={classes.detailListBody}>
      <DetailsList detailedItems={detailItems} narrowRows={true} />
    </div>
  )
}

export default SubUnitDetailPane
