import { useMemo } from 'react'
import { useGlobalFilterState } from '.'
import { useAuth, useFetchWatchlist } from '..'
import { setGlobalAlertFiltersAction } from '../../actions/globalFilters'
import { createQuery, FilterProvider, whereValue } from '../../filters'
import { isInList } from '../../helpers/filters'
import { AlertFilters } from '../../models'
import { Alert } from '../../models/alert'

type AlertFiltersProvider<T extends Alert = Alert> = FilterProvider<
  T,
  AlertFilters
>
const useAlertFilters = <
  T extends Alert = Alert
>(): AlertFiltersProvider<T> => {
  const {
    filterState: filters,
    setFilterState: setFilters,
    updateFilterState: mergeFilters,
    clearFilterState: clearFilters,
  } = useGlobalFilterState(
    (globalFilters) => globalFilters.alerts,
    setGlobalAlertFiltersAction
  )

  const { user } = useAuth()
  const { data: watchlist } = useFetchWatchlist(user.id)

  const filter = useMemo(() => {
    const filter = createQuery<T>([
      whereValue(
        (alert) =>
          isInList(alert.alertTypeId, filters.alertTypes, (item) => item.id),
        filters.alertTypes
      ),
    ])
    return filter
  }, [filters, watchlist])

  return {
    filterState: filters,
    setFilterState: setFilters,
    updateFilterState: mergeFilters,
    clearFilterState: clearFilters,
    filter,
    filterCount: filter.filterCount,
  }
}
export default useAlertFilters
