import { RefObject, useCallback, useState, useEffect } from 'react'
import { useEventListener } from '.'

export interface ElementSize {
  width: number
  height: number
}

const useElementSize = <T extends HTMLElement = HTMLDivElement>(
  ref: RefObject<T>
): ElementSize => {
  const [size, setSize] = useState<ElementSize>({
    width: 0,
    height: 0,
  })

  const updateSize = useCallback(() => {
    const node = ref?.current
    if (node) {
      setSize({
        width: node.offsetWidth || 0,
        height: node.offsetHeight || 0,
      })
    }
  }, [ref])

  // Initial size on mount
  useEffect(() => {
    updateSize()
  }, [updateSize])
  useEventListener('resize', updateSize)

  return size
}

export { useElementSize }
