import { AgentType, BadgeSignalStatus, RealTimePageViews } from '../../models'
import { BadgeTelemetryMessageWithAgentEvent } from '../../models/telemetry'
import { calculateBadgeSignalStatus } from '../assetSignal'
import { buildURL } from '../urlParameters'

export function buildNavigationUrlToFocusOnAgent(
  view: RealTimePageViews,
  agentType: AgentType,
  trackingId: string
): string | undefined {
  if (agentType === AgentType.Asset) {
    return buildURL(`/realtime`, [
      ['view', view],
      ['agentType', AgentType[agentType]],
      ['badgeId', trackingId],
      ['zoom', '10'],
    ])
  } else if (agentType === AgentType.Staff) {
    return buildURL(`/realtime`, [
      ['view', view],
      ['agentType', AgentType[agentType]],
      ['badgeId', trackingId],
      ['zoom', '10'],
    ])
  }
}

export function shouldDisableNavigationButton(
  agentTelemetry: BadgeTelemetryMessageWithAgentEvent | undefined,
  isPrivate: boolean | undefined
): boolean {
  if (!agentTelemetry) {
    return true
  }
  if (agentTelemetry.hasActiveDuress || agentTelemetry.hasActiveAssist) {
    return false
  }
  const badgeSignalStatus = calculateBadgeSignalStatus(agentTelemetry.timestamp)
  if (
    (isPrivate && agentTelemetry.agent?.agentType !== AgentType.Asset) ||
    badgeSignalStatus === BadgeSignalStatus.TimedOut
  ) {
    return true
  }
  return false
}
