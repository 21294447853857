import { useMemo } from 'react'
import { setGlobalAssetFiltersAction } from '../../actions/globalFilters'
import { textContains, createQuery, whereValue } from '../../filters'
import { isDateMatch, isInList, isPmDateThisMonth } from '../../helpers/filters'
import { badgeSignalStatus } from '../../helpers'
import { badgeSignalAccuracy } from '../../helpers/assetSignal'
import { Asset, SignalType } from '../../models'
import { useAuth, useFetchWatchlist, useGlobalFilterState } from '..'
import { batteryStatus, isLowBattery } from '../../helpers/battery'
import { AssetFiltersProvider } from '../../models/filters'

export const useAssetFilters = (): AssetFiltersProvider => {
  const {
    filterState: filters,
    setFilterState: setFilters,
    updateFilterState: mergeFilters,
    clearFilterState: clearFilters,
  } = useGlobalFilterState(
    (globalFilters) => globalFilters.assets,
    setGlobalAssetFiltersAction
  )

  const { user } = useAuth()
  const { data: watchlist } = useFetchWatchlist(user.id)

  const filter = useMemo(() => {
    const filter = createQuery<Asset>([
      whereValue((asset) => Boolean(asset.badgeIds?.length), filters.hasBadge),
      whereValue((asset) => asset.hasWorkOrder ?? false, filters.hasWorkOrder),
      whereValue(
        (asset) => isLowBattery(asset.isLowBattery),
        filters.isLowBattery
      ),
      whereValue(
        (asset) =>
          Object.values(watchlist).some(
            (item) => item.agentId === asset.agentGuid
          ),
        filters.isWatchlist
      ),
      whereValue((asset) => asset.hasRecall ?? false, filters.hasRecall),
      whereValue(
        (asset) => isInList(asset.model, filters?.models, (item) => item.name),
        filters.models
      ),
      whereValue(
        (asset) =>
          isInList(
            asset.displayName,
            filters?.displayNames,
            (item) => item.name
          ),
        filters.displayNames
      ),
      whereValue(
        (asset) => isInList(asset.owner, filters?.owners, (item) => item.name),
        filters.owners
      ),
      whereValue(
        (asset) => textContains(asset.serialNumber, filters.serialNumber),
        filters.serialNumber
      ),
      whereValue(
        (asset) => textContains(asset.badgeIds.join(','), filters.badgeNumber),
        filters.badgeNumber
      ),
      whereValue(
        (asset) =>
          isDateMatch(
            filters?.tagBatteryChangeDate,
            asset?.tagBatteryChangeDate
          ),
        filters.tagBatteryChangeDate
      ),
      whereValue(
        (asset) =>
          isDateMatch(
            filters?.assetBatteryChangeDate,
            asset?.tagBatteryChangeDate
          ),
        filters.assetBatteryChangeDate
      ),
      whereValue(
        (asset) =>
          isPmDateThisMonth(
            asset?.preventativeMaintenanceDate,
            filters.isPmThisMonth
          ),
        filters.isPmThisMonth
      ),
      whereValue((asset) => {
        return isDateMatch(
          filters?.preventativeMaintenanceDate,
          asset?.preventativeMaintenanceDate,
          true
        )
      }, filters.preventativeMaintenanceDate),
      whereValue(
        (asset) =>
          isInList(
            asset.manufacturerGuid,
            filters?.manufacturers,
            (item) => item.manufacturerGuid
          ),
        filters.manufacturers
      ),
      whereValue(
        (asset) =>
          isInList(
            asset.assetTypeId,
            filters?.assetTypes,
            (item) => item.assetTypeId
          ),
        filters.assetTypes
      ),
      whereValue(
        (asset) =>
          isInList(
            asset.assetSubTypeId,
            filters?.assetSubTypes,
            (item) => item.assetSubTypeId
          ),
        filters.assetSubTypes
      ),
      whereValue(
        (asset) =>
          isInList(
            badgeSignalAccuracy(asset.badgeTelemetry?.signalTypeId),
            filters?.signalAccuracy,
            (item) => item.name
          ),
        filters.signalAccuracy
      ),
      whereValue(
        (asset) =>
          isInList(
            badgeSignalStatus(asset.badgeTelemetry?.timestamp),
            filters?.signalStatus,
            (item) => item.name
          ),
        filters.signalStatus
      ),
      whereValue(
        (asset) =>
          isInList(
            SignalType[asset.badgeTelemetry?.signalTypeId],
            filters?.signalType,
            (item) => item.name
          ),
        filters.signalType
      ),
      whereValue(
        (asset) =>
          isInList(
            batteryStatus(asset.isLowBattery),
            filters?.batteryStatus,
            (item) => item.name
          ),
        filters.batteryStatus
      ),
      whereValue(
        (asset) =>
          isInList(asset.unitId, filters?.assignedTo, (item) => item.id),
        filters.assignedTo
      ),
      whereValue((asset) => asset.cmmsManaged ?? false, filters.cmmsManaged),
      whereValue(
        (asset) =>
          isInList(
            asset?.roomLocation?.id,
            filters.locationUnits,
            (item) => item
          ),
        filters.assignedTo
      ),
    ])
    return filter
  }, [filters, watchlist])

  return {
    filterState: filters,
    setFilterState: setFilters,
    updateFilterState: mergeFilters,
    clearFilterState: clearFilters,
    filter,
    filterCount: filter.filterCount,
  }
}
