import { setStaffEvents } from '@midmarkrtls/common/actions/agentEventsLocation'
import { setChangeTenantRouterPromptStatusAction } from '@midmarkrtls/common/actions/routerPrompt'
import { alertConfigPathMatches } from '@midmarkrtls/common/helpers/alert'
import useWidthBreakpoints from '@midmarkrtls/common/hooks/useWidthBreakpoints'
import {
  RouterPromptState,
  RouterPromptStatus,
} from '@midmarkrtls/common/models/routerPrompt'
import { WidthBreakpoints } from '@midmarkrtls/common/models/widthBreakpoints'
import { colors } from '@midmarkrtls/common/styles'
import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Tenant, UserTenant } from 'v2/models'

interface Props {
  canUserViewAllTenants: boolean
  error?: boolean
  required?: boolean
  selectedTenantId: string
  tenants: Array<Tenant | UserTenant>
  handleChange: (id: string) => void
}

const CustomerSelect: React.FC<Props> = (props: Props) => {
  const {
    canUserViewAllTenants,
    error,
    required,
    selectedTenantId,
    tenants,
    handleChange,
  } = props
  const widthBreakpoint = useWidthBreakpoints()
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>('')
  const [maxBreadcrumbLength, setMaxBreadcrumbLength] = useState<number>(30)
  const dispatch = useDispatch()
  const location = useLocation()

  useEffect(() => {
    switch (widthBreakpoint) {
      case WidthBreakpoints.Medium:
        setMaxBreadcrumbLength(19)
        break
      case WidthBreakpoints.Large:
        setMaxBreadcrumbLength(21)
        break
      case WidthBreakpoints.ExtraLarge:
        setMaxBreadcrumbLength(25)
        break
      case WidthBreakpoints.ExtraExtraLarge:
        setMaxBreadcrumbLength(29)
        break
      case WidthBreakpoints.ExtraSmall:
      case WidthBreakpoints.Small:
      default:
        setMaxBreadcrumbLength(13)
    }
  }, [widthBreakpoint])

  const tenantMenuItems = useMemo(() => {
    const truncate = (str: string, count: number): string => {
      if (count < 4) count = 4
      return str.length > count ? str.slice(0, count - 4) + '...' : str
    }
    return tenants
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((tenant) => (
        <MenuItem key={tenant.tenantId} value={tenant.tenantId}>
          {truncate(tenant.name, maxBreadcrumbLength)}
        </MenuItem>
      ))
  }, [maxBreadcrumbLength, tenants])

  const routerPromptState = useSelector(
    ({ routerPrompt }: { routerPrompt: RouterPromptState }) => routerPrompt
  )

  const updateTenant = useCallback(
    async (id?: string) => {
      dispatch(setStaffEvents([]))
      handleChange(id ?? selectedCustomerId)
    },
    [dispatch, handleChange, selectedCustomerId]
  )

  const handleSelect = (event: SelectChangeEvent<string>) => {
    const isAlertConfigPage = alertConfigPathMatches(location.pathname)
    if (isAlertConfigPage && routerPromptState.isEnabled) {
      setSelectedCustomerId(event.target.value as string)
      dispatch(
        setChangeTenantRouterPromptStatusAction(RouterPromptStatus.PROMPTED)
      )
    } else {
      updateTenant(event.target.value as string)
    }
  }

  useEffect(() => {
    const isAlertConfigPage = alertConfigPathMatches(location.pathname)
    if (!isAlertConfigPage) {
      return
    }
    if (
      routerPromptState.changeTenantPromptStatus ===
      RouterPromptStatus.CONFIRMED
    ) {
      updateTenant()
    }
  }, [
    routerPromptState.changeTenantPromptStatus,
    location.pathname,
    updateTenant,
  ])

  return tenants && tenants.length ? (
    <Select
      required={required}
      error={error}
      displayEmpty
      value={selectedTenantId}
      onChange={handleSelect}
      style={{
        color: colors.background,
      }}
      variant='standard'
      disableUnderline
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      }}
    >
      {canUserViewAllTenants && <MenuItem value=''>All Customers</MenuItem>}
      {!canUserViewAllTenants && (
        <MenuItem value=''>Select a Customer</MenuItem>
      )}
      {tenantMenuItems}
    </Select>
  ) : (
    <div></div>
  )
}

export { CustomerSelect }
