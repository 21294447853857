import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { PageContent } from '../../../components/Layout'
import { useReactTableRightDrawer } from '../../../components/Tables/useReactTableRightDrawer'
import { useFetchSubUnits } from '../../../hooks'
import { SubUnit } from '../../../models'
import { PageLayoutState } from '../../../models/pageLayout'
import SubUnitDetailDrawer from './SubUnitDetailDrawer'
import { SubUnitManagementList } from './SubUnitManagmentList'
import SubUnitModal from './SubUnitModal'
import { DrawerType } from '../../../components/Layout/PageContent'

const SubUnitManagementPage = (): JSX.Element => {
  const [openSubUnitForm, setOpenSubUnitForm] = useState<boolean>(false)

  const maxHeight = useSelector(
    ({ pageLayout }: { pageLayout: PageLayoutState }) => pageLayout.maxHeight
  )

  const { data: subUnits, status: subUnitFetchingStatus } = useFetchSubUnits()

  const {
    rightDrawerState,
    selectRow,
    closeDetailDrawer,
    clearSelectedItem,
    resetSelectedRowIndex,
    updateSelectedRow,
  } = useReactTableRightDrawer<SubUnit>()
  const {
    currentDrawerType,
    item: selectedRow,
    tableSelectedRowIndex,
  } = rightDrawerState

  const handleCreateSubUnit = useCallback(() => {
    closeDetailDrawer()
    setOpenSubUnitForm(true)
  }, [closeDetailDrawer])

  return (
    <PageContent
      maxHeight={maxHeight}
      currentRightDrawerType={currentDrawerType}
      content={
        <>
          <SubUnitManagementList
            subUnitFetchingStatus={subUnitFetchingStatus}
            subUnits={Object.values(subUnits)}
            onEditSubUnit={() => setOpenSubUnitForm(true)}
            onCreateSubUnit={handleCreateSubUnit}
            tableRowSelectionOptions={{
              selectedRow,
              selectedRowIndex: tableSelectedRowIndex,
              onSelectRow: selectRow,
              clearSelectedRow: clearSelectedItem,
              resetSelectedRowIndex,
              updateSelectedRow,
            }}
          />

          {openSubUnitForm && (
            <SubUnitModal
              data={selectedRow ?? null}
              handleClose={() => setOpenSubUnitForm(false)}
              modalHeaderTitle={
                !!selectedRow
                  ? `Edit Sub-Unit ${selectedRow.subUnitName}`
                  : 'Create Sub-Unit'
              }
            />
          )}
        </>
      }
      rightDrawer={
        currentDrawerType === DrawerType.DetailDrawer && selectedRow ? (
          <SubUnitDetailDrawer
            selectedSubUnit={selectedRow}
            onClose={closeDetailDrawer}
            onEditSubUnit={() => setOpenSubUnitForm(true)}
          />
        ) : null
      }
    />
  )
}

export default SubUnitManagementPage
