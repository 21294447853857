import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { LocalStorageKeys } from '../constants'
import { mapAlertToAlertActiveConfig } from '../helpers/alert'
import { AlertConfigRouteParams, AlertConfigSteps, AuthState } from '../models'
import { AlertActiveConfig } from '../models/alert'
import { RouterPromptState } from '../models/routerPrompt'
import { AlertActiveConfigState } from '../pages/AlertManagement'
import { useFetchAlerts } from './entities/useFetchAlerts'

const useAlertConfigPageReload = (
  activeConfig: AlertActiveConfig,
  setAlertActiveConfig: React.Dispatch<AlertActiveConfigState | undefined>
) => {
  const location = useLocation()
  const history = useNavigate()
  const { id } = useParams<AlertConfigRouteParams>()
  const { formattedAlerts, formattedAlertsLoaded } = useFetchAlerts()

  const routerPromptState = useSelector(
    ({ routerPrompt }: { routerPrompt: RouterPromptState }) => routerPrompt
  )

  const onReload = (e: BeforeUnloadEvent) => {
    localStorage.setItem('isReloaded', '1')

    const isTenantChanged = localStorage.getItem(
      LocalStorageKeys.IsTenantChanged
    )
    if (isTenantChanged && +isTenantChanged) {
      // Bypass browser default navigation away dialog if route redirect is due to
      // user selecting customer from the dropdown
      localStorage.removeItem(LocalStorageKeys.IsTenantChanged)
      return
    }
    if (routerPromptState.isEnabled) {
      e.preventDefault()
      e.returnValue = true
    }
  }

  useEffect(() => {
    if (
      location.pathname.includes(AlertConfigSteps[AlertConfigSteps.CONDITIONS])
    ) {
      return
    }
    window.addEventListener('beforeunload', onReload)

    return () => {
      window.removeEventListener('beforeunload', onReload)
      localStorage.setItem('isReloaded', '0')
    }
  }, [onReload])

  useEffect(() => {
    if (
      location.pathname.includes(AlertConfigSteps[AlertConfigSteps.CONDITIONS])
    ) {
      return
    }
    const isReloaded = localStorage.getItem('isReloaded')
    if (isReloaded && +isReloaded) {
      // Reroute user back to Step 1 on page reload
      const path = `${location.pathname.slice(
        0,
        location.pathname.lastIndexOf('/')
      )}`
      history(
        `${path}/${AlertConfigSteps[AlertConfigSteps.CONDITIONS].toLowerCase()}`
      )
    }
  }, [])

  const [activeConfigLoaded, setActiveConfigLoaded] = useState<boolean>(false)

  // setAlertActiveConfig on page refresh
  useEffect(() => {
    if (!id) {
      return
    }
    if (activeConfig.id === id && !activeConfigLoaded) {
      setActiveConfigLoaded(true)
      return
    }

    if (
      activeConfig.id !== id &&
      Object.values(formattedAlerts).length > 0 &&
      formattedAlertsLoaded
    ) {
      const alert = formattedAlerts[id]
      const alertActiveConfig = mapAlertToAlertActiveConfig(alert)
      setActiveConfigLoaded(true)
      setAlertActiveConfig(alertActiveConfig)
    }
  }, [activeConfig, formattedAlerts])

  return activeConfigLoaded
}

export { useAlertConfigPageReload }
