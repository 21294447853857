import React from 'react'
import { Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { useFetchBadgeAssignedCount } from '@midmarkrtls/common/hooks/useFetchBadgeAssignedCount'
import { FetchingStatus } from '@midmarkrtls/common/models'

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      display: 'block',
      minWidth: 30,
      marginLeft: 10,
    },
    container: {
      marginTop: 10,
      marginLeft: 10,
      marginRight: 10,
      marginBottom: 10,
      minWidth: 400,
      borderWidth: 2,
      borderStyle: 'solid',
      paddingBottom: 10,
    },
    containerLabel: {
      marginTop: 10,
      marginLeft: 10,
      marginBottom: 10,
    },
  })
)

const BadgeAssignedCountComponent: React.FC = () => {
  const { count: badgeAssignedCount, status } = useFetchBadgeAssignedCount()
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <form>
        <Typography
          id='badge-assigned-count-container-label'
          variant='h5'
          className={classes.containerLabel}
        >
          Badges Assigned in this Tenant
        </Typography>
        <Grid
          container
          direction='row'
          spacing={2}
          justifyContent='flex-start'
          alignItems='center'
        >
          <Grid item>
            <Typography
              id='badge-assigned-count-label'
              align='center'
              style={{ marginLeft: 10 }}
            >
              Badges Assigned:
            </Typography>
          </Grid>
          <Grid item>
            {status === FetchingStatus.Success && (
              <Typography
                id='badge-assigned-count-value'
                align='center'
                style={{ marginLeft: 10 }}
              >
                {badgeAssignedCount}
              </Typography>
            )}
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

export { BadgeAssignedCountComponent }
