export enum RouterPromptStatus {
  PROMPTED,
  CONFIRMED,
}

export interface RouterPromptState {
  isEnabled: boolean
  logoutPromptStatus: RouterPromptStatus | undefined
  changeTenantPromptStatus: RouterPromptStatus | undefined
}
