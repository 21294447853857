import { findAgentInCurrentMapRender } from '../realtimeMap'
import { MapRenderByGeofence } from '../../models/realtimeMap'
import { BadgeTelemetryMessageWithAgentEvent } from '../../models/telemetry'

/**
 * Calculate previous agent telemetry received and remaining agents in leaving
 * geofence after the agent with received telemetry left
 * @param trackingId
 * @param currentMapRender
 * @returns previous agent telemetry received and remaining agents in leaving
 * geofence after the agent with received telemetry left
 */
export function calculateLeavingGeofence(
  trackingId: string,
  currentMapRender: MapRenderByGeofence
):
  | {
      prevAgentTelemetry: BadgeTelemetryMessageWithAgentEvent
      agentTelemetriesInLeavingGeofence: BadgeTelemetryMessageWithAgentEvent[]
    }
  | undefined {
  const prevAgentTelemetry = findAgentInCurrentMapRender(
    trackingId,
    currentMapRender
  )
  if (!prevAgentTelemetry) {
    console.warn(
      'Unable to find previous agent telemetry for agent already rendered on map!'
    )
    return
  }
  return {
    prevAgentTelemetry,
    agentTelemetriesInLeavingGeofence:
      currentMapRender[prevAgentTelemetry.geoFenceId].renderedAgents,
  }
}
