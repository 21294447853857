import { getType } from 'typesafe-actions'
import { FormAction } from '../actions'
import { clearFormErrorsAction, setFormErrorsAction } from '../actions/forms'
import { Forms, FormsState, ManualFieldError } from '../models'
import { HttpErrorResponse } from '../services/http'
import { lowercaseFirstChar } from '../helpers'

const mapHttpErrorToFormError = <T extends {}>(
  resp: HttpErrorResponse
): ManualFieldError<T>[] => {
  // if the API was unable to format the error
  if (!resp?.error?.details || resp.error instanceof Error) {
    return [
      { errorFieldName: 'unknown', message: resp.error?.message },
    ] as ManualFieldError<T>[]
  }

  return resp.error.details.map((obj: any) => {
    return {
      errorFieldName: lowercaseFirstChar(obj.target),
      message: obj.message,
    }
  })
}

export default (
  state: Forms = {
    assetEdit: { errors: [] },
    staffEdit: { errors: [] },
    badgeUpdate: { errors: [] },
    badgeTraffic: { errors: [] },
    unitEdit: { errors: [] },
    alertEdit: { errors: [] },
  },
  action: FormAction
): FormsState => {
  switch (action.type) {
    case getType(setFormErrorsAction):
      return {
        ...state,
        [action.payload.formName]: {
          errors: mapHttpErrorToFormError(action.payload.error),
        },
      }
    case getType(clearFormErrorsAction):
      return {
        ...state,
        [action.payload.formName]: {
          errors: [],
        },
      }
    default:
      return state
  }
}
