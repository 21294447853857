import React, { MouseEvent, useState } from 'react'
import { Grid, Typography, TextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { CreateEvent } from 'v2/models'
import { useDispatch } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { SaveButton } from '@midmarkrtls/common/components/Buttons/Button'
import { AlertTypes } from '@midmarkrtls/common/models/alert'
import { createEventAction } from 'v2/actions/createEvent'

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      display: 'block',
      minWidth: 30,
      marginLeft: 10,
    },
    container: {
      marginTop: 10,
      marginLeft: 10,
      marginRight: 10,
      marginBottom: 10,
      minWidth: 400,
      borderWidth: 2,
      borderStyle: 'solid',
      paddingBottom: 10,
    },
    containerLabel: {
      marginTop: 10,
      marginLeft: 10,
      marginBottom: 10,
    },
  })
)

const CreateEventComponent: React.FC = () => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [badgeId, setBadgeId] = useState('')

  const { control } = useForm<CreateEvent>({})

  const badgeIdChanged = (badgeId: string) => {
    setBadgeId(badgeId)
  }

  const createAssist = (event: MouseEvent<HTMLButtonElement>) => {
    const createEvent: CreateEvent = {
      badgeId: badgeId,
      agentEventTypeId: AlertTypes.StaffAssist,
    }
    dispatch(createEventAction.request(createEvent))
    event.preventDefault()
  }

  const createDuress = (event: MouseEvent<HTMLButtonElement>) => {
    const createEvent: CreateEvent = {
      badgeId: badgeId,
      agentEventTypeId: AlertTypes.StaffDuress,
    }
    dispatch(createEventAction.request(createEvent))
    event.preventDefault()
  }

  return (
    <div className={classes.container}>
      <form>
        <Typography
          id='create-event-label'
          variant='h5'
          className={classes.containerLabel}
        >
          Create Event
        </Typography>
        <Grid
          container
          direction='row'
          spacing={2}
          justifyContent='flex-start'
          alignItems='center'
        >
          <Grid item>
            <Typography
              id='create-event-label'
              align='center'
              style={{ marginLeft: 10 }}
            >
              Badge ID
            </Typography>
          </Grid>
          <Grid item>
            <Controller
              name='badgeId'
              control={control}
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    id='create-event-badge-id-textbox'
                    value={badgeId}
                    variant='outlined'
                    size='small'
                    fullWidth
                    required
                    className={classes.textField}
                    autoFocus
                    inputProps={{ maxLength: 50 }}
                    onChange={(
                      e: React.ChangeEvent<
                        HTMLTextAreaElement | HTMLInputElement
                      >
                    ): string => {
                      badgeIdChanged(e.target.value)
                      return e.target.value
                    }}
                  />
                )
              }}
            />
          </Grid>
          <Grid item>
            <SaveButton
              id='create-assist-save-button'
              style={{ fontWeight: 'bold', marginRight: 12, marginLeft: 25 }}
              onClick={createAssist}
            >
              Create Assist
            </SaveButton>
          </Grid>
          <Grid item>
            <SaveButton
              id='create-duress-save-button'
              style={{ fontWeight: 'bold', marginRight: 12, marginLeft: 10 }}
              onClick={createDuress}
            >
              Create Duress
            </SaveButton>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

export { CreateEventComponent }
