import { createSelector } from 'reselect'
import memoize from 'lodash/memoize'
import { HardwareRecords, Hardware, WiredHardwareDetails } from '../models'

// Extract Concentrators from state.hardware
const concentratorSelector = (state: HardwareRecords): HardwareRecords => {
  return Object.values(state)
    .filter((h) => h.description === 'Concentrator')
    .reduce((concentrators: HardwareRecords, concentrator: Hardware) => {
      return {
        ...concentrators,
        [concentrator.hardwareGuid]: concentrator,
      }
    }, {})
}

// Extract Collectors from state.hardware
const collectorSelector = (state: HardwareRecords): HardwareRecords => {
  return Object.values(state)
    .filter((h) => h.description === 'Collector')
    .reduce((collectors: HardwareRecords, collector: Hardware) => {
      return {
        ...collectors,
        [collector.hardwareGuid]: collector,
      }
    }, {})
}

// Get an array of all Concentrators
export const getConcentrators = createSelector(
  concentratorSelector,
  (state: HardwareRecords) => {
    return state ? Object.values(state) : []
  }
)

// Get an array of all Collectors
export const getCollectors = createSelector(
  collectorSelector,
  (state: HardwareRecords) => {
    return state ? Object.values(state) : []
  }
)

export const getWiredHardwareDetailsFromSensorIdSelector = createSelector(
  [concentratorSelector, collectorSelector],
  (concentrators: HardwareRecords, collectors: HardwareRecords) =>
    memoize((sensorID: string): WiredHardwareDetails | undefined => {
      const [collectorId, punchdown] = sensorID.split('_')
      if (!collectorId || !punchdown) {
        return undefined
      }

      const collector = Object.values(collectors).find(
        (c) => c.hardwareId === collectorId
      )

      if (!collector || !collector.parentGuid) {
        return undefined
      }

      const concentrator = concentrators[collector.parentGuid]
      if (!concentrator) {
        return undefined
      }
      const concentratorId = concentrator.hardwareId

      return {
        sensorID,
        concentratorId,
        collectorId,
        punchdown,
      }
    })
)
