import { IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { PlayCircleOutline, PauseCircleOutline } from '@mui/icons-material'

interface Props {
  cdnPath: string
}

const AudioPlayButton = (props: Props) => {
  const { cdnPath } = props
  const [isPlaying, setIsPlaying] = useState(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [audio, setAudio] = useState(
    new Audio(process.env.REACT_APP_STATIC_CONTENT + cdnPath)
  )

  const handleClick = () => {
    if (isPlaying) {
      stopAudio(audio)
    } else {
      setIsPlaying(true)
      audio.play()
      audio.onended = () => {
        stopAudio(audio)
      }
    }
  }

  const stopAudio = (audioRef: HTMLAudioElement) => {
    audioRef.pause()
    audioRef.currentTime = 0
    setIsPlaying(false)
  }

  useEffect(() => {
    setAudio(new Audio(process.env.REACT_APP_STATIC_CONTENT + cdnPath))
  }, [cdnPath])

  return (
    <>
      <IconButton onClick={handleClick} size='large'>
        {!isPlaying ? (
          <PlayCircleOutline color='primary' />
        ) : (
          <PauseCircleOutline color='primary' />
        )}
      </IconButton>
    </>
  )
}

export default AudioPlayButton
