import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Checkbox, ListItemText, MenuItem, Typography } from '@mui/material'
import { Select } from '../ActionBar'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { Unit, SimpleLocation } from '../../models'
import {
  useCurrentLocation,
  useFetchLocations,
  useFetchUnits,
} from '../../hooks'
import { getLocationAndChildren } from '../../helpers'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentUnitsAction } from '../../actions/currentUnits'
import { isEqual } from 'lodash'

interface Props {
  multiSelect: boolean
}

const UnitMultiSelect: React.FC<Props> = (props: Props) => {
  const { multiSelect } = props

  const [selectedValue, setSelectedValue] = useState<string[]>(['0'])
  const [initialized, setInitialized] = useState(false)

  const currentUnits = useSelector(
    ({ currentUnits }: { currentUnits: string[] }) => currentUnits
  )

  const { data: units, status: unitStatus } = useFetchUnits()
  const { currentLocation } = useCurrentLocation()
  const { data: locations } = useFetchLocations()
  const dispatch = useDispatch()

  const onChange = (event: any) => {
    const {
      target: { value },
    } = event
    if (value?.length) {
      let temp: string[] = value.filter((x: string) => x !== '0')
      const allUnitIds = allUnits
        .filter((x) => x.id !== '-1' && x.id !== '0')
        .map((x) => x.id ?? '')

      //All Units selected and not in currentSelected - return indidivual ids and -1
      if (value.includes('-1') && !selectedValue.includes('-1')) {
        temp = allUnitIds
        temp.push('-1')
      }
      //All Units is not selected and is in currentSelected = return empty
      else if (!value.includes('-1') && selectedValue.includes('-1')) {
        temp = ['0']
      }
      //All Units is selected, and there are n units selected but less than all - return unitIds without -1
      else if (
        value.includes('-1') &&
        allUnitIds.length > value.filter((x: string) => x !== '-1').length
      ) {
        temp = value.filter((x: string) => x !== '-1')
      }
      //All Units is not selected, and all individual units are selected - return all unitIds with -1
      else if (
        !value.includes('-1') &&
        !selectedValue.includes('-1') &&
        allUnitIds.filter((x: string) => x !== '-1' && x !== '0').length ===
          value.filter((x: string) => x !== '-1' && x !== '0').length
      ) {
        temp = value
        temp.push('-1')
      }

      //This makes sure if only the All Units is left in the array, or the array is empty we reset the control
      if (temp.length === 0 || isEqual(temp, ['-1'])) {
        temp = ['0']
      }

      setSelectedValue(temp)
      //Only update State if not in default state
      dispatch(
        setCurrentUnitsAction(temp.filter((x) => x !== '0' && x !== '-1'))
      )
    } else {
      setSelectedValue(['0'])
      //Only update State if not in default state
      dispatch(setCurrentUnitsAction([]))
    }
  }

  //Recursive function to get all unitIds from current Location Hierarchy
  const getLocationUnits = useCallback(
    (hierarchy: SimpleLocation): string[] => {
      const unitData: string[] = []
      if (hierarchy?.unitId) {
        unitData.push(hierarchy.unitId)
      }
      if (hierarchy?.subLocations?.length) {
        hierarchy.subLocations.forEach((subLocation: SimpleLocation) => {
          getLocationUnits(subLocation).map((x) => unitData.push(x))
        })
      }
      //Returns a new array with only unique values
      return [...new Set(unitData)]
    },
    []
  )

  const locationUnits: string[] = useMemo(() => {
    if (currentLocation && Object.values(locations).length) {
      const hierarchy = getLocationAndChildren(currentLocation, locations, true)

      const unitData: string[] = getLocationUnits(hierarchy)

      //Returns a new array with only unique values
      return [...new Set(unitData)]
    }

    return []
  }, [currentLocation, locations, getLocationUnits])

  const allUnits = useMemo(() => {
    const temp: Unit[] = []

    temp.push({
      id: '-1',
      name: 'All Units',
    })

    Object.values(units).map((x: Unit) => {
      if (x.id && locationUnits.includes(x.id)) {
        temp.push(x)
      }
    })

    return temp
  }, [units, locationUnits])

  useEffect(() => {
    if (!initialized) {
      const allUnitsMinusNegativeOne = allUnits.filter((x) => x.id !== '-1')
      if (
        currentUnits?.length > 0 &&
        currentUnits?.length === allUnitsMinusNegativeOne?.length
      ) {
        setSelectedValue([...currentUnits, '-1'])
        setInitialized(true)
      } else if (currentUnits?.length > 0) {
        setSelectedValue(currentUnits)
        setInitialized(true)
      }
    }
  }, [allUnits, currentUnits, initialized])

  const renderValue = (selected: any) => {
    if (selected.includes('-1')) {
      return 'All Units'
    } else if (selected.length > 1) {
      return `${selected.length} Units`
    } else if (selected.includes('0')) {
      return 'Unit Selector'
    } else {
      return allUnits
        .filter((x) => selected.includes(x.id))
        .map((x) => x.name)
        .join(', ')
    }
  }

  const checkboxIndeterminate = useMemo(() => {
    if (
      !selectedValue.includes('-1') &&
      !selectedValue.includes('0') &&
      selectedValue.length > 0 &&
      selectedValue.length !== allUnits.filter((x) => x.id !== '-1').length
    ) {
      return true
    } else {
      return false
    }
  }, [allUnits, selectedValue])

  return (
    <>
      <Select
        margin='dense'
        value={selectedValue}
        onChange={onChange}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        }}
        multiple
        style={{ minWidth: '204px' }}
        renderValue={(selected: any) => renderValue(selected)}
      >
        {allUnits.filter((x) => x.id !== '-1').length ? (
          allUnits.map((unit: any) => (
            <MenuItem
              key={unit.id}
              value={unit.id}
              style={{
                height: '36px',
                margin: '1px 0 0 0',
                padding: '8px 37px 4px 10px',
              }}
            >
              <Checkbox
                color='primary'
                icon={<CheckBoxOutlineBlankIcon color='primary' />}
                checked={selectedValue.includes(unit.id ?? '')}
                style={{ width: '19px', height: '19px', margin: '0 8px 0 0' }}
                indeterminate={unit.id === '-1' && checkboxIndeterminate}
              />
              <ListItemText>
                <Typography
                  style={{
                    fontSize: '16px',
                    fontWeight: 500,
                  }}
                >
                  {unit.name}
                </Typography>
              </ListItemText>
            </MenuItem>
          ))
        ) : (
          <MenuItem
            key={'-2'}
            value={'-2'}
            disabled
            style={{
              height: '36px',
              margin: '1px 0 0 0',
              padding: '8px 37px 4px 10px',
            }}
          >
            <ListItemText>
              <Typography
                style={{
                  fontSize: '16px',
                  fontWeight: 500,
                }}
              >
                No unit(s) for location
              </Typography>
            </ListItemText>
          </MenuItem>
        )}
      </Select>
    </>
  )
}

export default UnitMultiSelect
