import { BaseService } from './baseService'
import { Issue } from '../models'

export class IssuesApi extends BaseService {
  constructor() {
    super({ entityType: 'issues' })
  }
  route = 'issues'

  async getAllIssues(): Promise<Issue[]> {
    return await this.get<Issue[]>(this.route)
  }

  async postIssues(issues: Issue[]): Promise<Issue[]> {
    return await this.post<Issue[]>(this.route, issues)
  }

  async putIssue(issue: Issue): Promise<Issue> {
    return await this.put<Issue>(`${this.route}/${issue.issueGuid}`, issue)
  }

  async putIssues(issues: Issue[]): Promise<Issue[]> {
    return await this.put<Issue[]>(`${this.route}`, issues)
  }
}
