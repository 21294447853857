import { BaseBadgeTelemetryMessage } from './badgeTelemetry'

export enum MapUpdateType {
  Agent,
  GeoFence,
}

export interface MapUpdate {
  trackingId?: string
  signalTypeId?: number
  lon?: number
  lat?: number
  locationUncertainty?: number
  mapId?: number
  previousMapId?: number
  geoFenceId: number
  updateType: MapUpdateType
  agents: BaseBadgeTelemetryMessage[]
  timestamp?: Date
  isRefresh: boolean
}

export interface MapLocationUpdate {
  mapUpdates: MapUpdate[]
  lastLiveTelemetry: Date
}
export interface MapUpdateStore {
  mapUpdates: MapUpdate[]
  lastLiveTelemetry: Date
}
export type MapUpdateState = Readonly<MapUpdateStore>
