import React, { useMemo } from 'react'
import { useFetchChannelSounds, useFetchChannelTypes } from '../../hooks'
import { AlertActiveConfig, AlertTypes } from '../../models/alert'
import { DetailedItem } from '../../models/DetailPaneItems'
import DetailsList from '../DetailsList/DetailsList'

interface Props {
  alert: AlertActiveConfig
  isLoading?: boolean
  headerStylesOverrides?: any
  listItemStylesOverrides?: any
}

const AlertChannelDetail = (props: Props) => {
  const {
    alert,
    isLoading = false,
    headerStylesOverrides,
    listItemStylesOverrides,
  } = props

  const { channelTypes } = useFetchChannelTypes()
  const { channelSounds } = useFetchChannelSounds()

  const channelItems = useMemo(() => {
    return alert.channels.map((x) => {
      const channel = Object.values(channelTypes).find(
        (y) => y.id == x.channelTypeId
      )
      return {
        field:
          alert.alertType?.id === AlertTypes.AssetAlert ? 'Display' : 'Channel',
        value: x.channelSoundId
          ? channelSounds[x.channelSoundId ?? '']?.name
          : channel?.name,
      }
    })
  }, [alert.channels, channelSounds, channelTypes])

  const alertChannelDetailItems: DetailedItem[] = []
  alertChannelDetailItems.push({
    header:
      alert.alertType?.id === AlertTypes.AssetAlert
        ? 'DELIVERY DISPLAYS'
        : 'DELIVERY CHANNELS',
    items: channelItems,
  })

  return (
    <DetailsList
      detailedItems={alertChannelDetailItems}
      narrowRows={true}
      headerStylesOverrides={headerStylesOverrides}
      listItemStylesOverrides={listItemStylesOverrides}
      isLoading={isLoading}
    />
  )
}

export default AlertChannelDetail
