import {
  buildInpixonAssetConfigsForNewAgents,
  createInpixonAssets,
  showAgentInpixonAssets,
} from '.'
import { AssetInstance, AssetKitInstance } from '../../models'
import { InpixonAssetAgentCreateOptions } from '../../models/inpixonMap'

export function showDegroupingAgents(
  assetKit: AssetKitInstance,
  agentsToDegroup: InpixonAssetAgentCreateOptions[],
  assetKitId: number,
  useGeofenceCentroid: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  geofenceInstances: any[],
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any
): number | undefined {
  const agentsAlreadyRenderedOnMap: InpixonAssetAgentCreateOptions[] = []
  const newAgentsToCreate: InpixonAssetAgentCreateOptions[] = []
  agentsToDegroup.forEach((agent) => {
    const currentAssetInAssetKit: AssetInstance[] = assetKit.getAssetsByType(
      `${agent.iconType.toString()}_${agent.trackingId.toString()}`
    )
    if (currentAssetInAssetKit.length > 0) {
      agentsAlreadyRenderedOnMap.push(agent)
    } else {
      newAgentsToCreate.push(agent)
    }
  })
  if (agentsAlreadyRenderedOnMap.length > 0) {
    agentsAlreadyRenderedOnMap.forEach((agent) => {
      showAgentInpixonAssets(
        assetKit,
        jibestreamController,
        agent,
        agent.isSelected,
        agent.hasActiveDuress,
        agent.hasActiveAssist,
        agent.hasResolvedDuressOrAssist
      )
    })
  }
  if (newAgentsToCreate.length > 0) {
    const { inpixonAssetConfigs, nextInpixonAssetId } =
      buildInpixonAssetConfigsForNewAgents(
        newAgentsToCreate,
        assetKitId,
        useGeofenceCentroid,
        geofenceInstances,
        jibestreamController
      )
    if (inpixonAssetConfigs.length > 0) {
      createInpixonAssets(assetKit, inpixonAssetConfigs, jibestreamController)
    }
    return nextInpixonAssetId
  }
}
