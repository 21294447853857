import React, { Component, Fragment, PropsWithChildren } from 'react'
import { withAITracking } from '@microsoft/applicationinsights-react-js'
import { ai } from './TelemetryService'
//import { HistoryRouterProps } from 'react-router-dom'

interface Props extends PropsWithChildren {
  instrumentationKey: string
}

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
class TelemetryProvider extends Component<Props> {
  state = {
    initialized: false,
  }

  componentDidMount() {
    //const { history } = this.props
    const { initialized } = this.state
    const AppInsightsInstrumentationKey = this.props.instrumentationKey // PUT YOUR KEY HERE
    if (
      !Boolean(initialized) &&
      Boolean(AppInsightsInstrumentationKey) //&&
      //Boolean(history)
    ) {
      //ai.initialize(AppInsightsInstrumentationKey, history)
      this.setState({ initialized: true })
    }
  }

  render() {
    const { children } = this.props
    return <Fragment>{children}</Fragment>
  }
}

export default withAITracking(ai.reactPlugin, TelemetryProvider)
