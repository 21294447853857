import { IconButton, InputAdornment, OutlinedInput } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined'
import { Skeleton } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'

import React, { useCallback, useState } from 'react'
import { colors } from '../../styles'
import { useInterval } from '../../hooks'

interface Props {
  text: string
  loading: boolean
  leadingText?: string
}

const useStyles = makeStyles(() => ({
  textField: {
    height: '36px',
    width: '525px',
    fontWeight: 'bold',
    color: colors.primary,
    fontSize: '14px',
  },
  skeleton: {
    borderStyle: 'solid',
    borderRadius: '5px',
    borderWidth: 'thin',
  },
  tooltip: {
    color: 'lightblue',
    backgroundColor: 'green',
  },
}))

const AlertSubscriptionKeyTextBox = (props: Props) => {
  const { text, loading, leadingText } = props
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [intervalEnabled, setIntervalEnabled] = useState(false)

  const handleClick = () => {
    navigator.clipboard.writeText(text)
    setTooltipOpen(true)
    setIntervalEnabled(true)
  }

  const classes = useStyles(props)
  const StyledTooltip = withStyles({
    tooltip: {
      color: 'white',
      backgroundColor: 'black',
      fontSize: '12px',
      fontWeight: 'bold',
    },
    arrow: {
      '&:before': {
        border: '1px solid #E6E8ED',
      },
      color: 'black',
      left: 0,
    },
  })(Tooltip)

  const handleIntervalChange = useCallback(() => {
    setIntervalEnabled(false)
    setTooltipOpen(false)
  }, [])

  useInterval(handleIntervalChange, 2000, intervalEnabled)

  return (
    <div>
      {!loading ? (
        <OutlinedInput
          id='outlined-adornment-key'
          type={'text'}
          value={`${leadingText ? leadingText : ''}${text}`}
          className={classes.textField}
          endAdornment={
            <InputAdornment position='end'>
              <StyledTooltip
                title='COPIED TO CLIPBOARD'
                arrow
                placement='bottom-start'
                open={tooltipOpen}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onClick={() => {}}
              >
                <IconButton onClick={handleClick} edge='end' size='large'>
                  <FileCopyOutlined color='primary' />
                </IconButton>
              </StyledTooltip>
            </InputAdornment>
          }
        />
      ) : (
        <Skeleton
          className={classes.skeleton}
          variant='rectangular'
          width={500}
          height={36}
        />
      )}
    </div>
  )
}

export default AlertSubscriptionKeyTextBox
