import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllGeofencesByLocation } from '../../../actions/locationGeofences'
import {
  resetRealTimeMapControlStateAction,
  setJibestreamControlAction,
  setRectangleAction,
  setShouldShowRealTimeMapAction,
} from '../../../actions/realTimeMapControlAction'
import { getCenterOfBounds, mapGeofencesToLocations } from '../../../helpers'
import {
  createAssets,
  deleteAsset,
  generateAssetConfig,
} from '../../../helpers/inpixon'
import { getIconScale } from '../../../helpers/inpixon/assetCreate'
import { mapUnitOutLinesHighlighting } from '../../../helpers/inpixon/assetUpdate'
import { useFetchAllGeofences, useMapReferenceViews } from '../../../hooks'
import { useMapResize } from '../../../hooks/inpixon/useMapResize'
import useCurrentMap from '../../../hooks/useCurrentMap'
import {
  FetchingStatus,
  Geofence,
  Geofences,
  IconType,
  InpixonAssetConfig,
  Location,
  LocationGeofence,
  LocationGeofences,
  LocationGeofencesState,
  Locations,
  MapOutline,
  MapPresets,
  UnitGeoWithColor,
  UnitPlusColor,
  Units,
  domRectToRectangle,
} from '../../../models'
import { LocationFloorEditBanner } from '../../../pages/LocationManagement/Locations/LocationFloorEditBanner'
import { MapManagementRightDrawerType } from '../../../pages/LocationManagement/Locations/useLocationManagementMapRightDrawer'
import { colors } from '../../../styles/MidmarkTheme'
import LoadingOverlay from '../../Common/LoadingOverlay'
import { FloorUnitsControl } from '../../Inpixon'
import { InpixonMap } from '../../Inpixon/DeprecatedInpixonMap'
import FloorEditModal from '../../LocationManagement/FloorEditModal'
import RoomEditModal from '../../LocationManagement/RoomEditModal'

interface RightDrawerOptions {
  closeDetailDrawer: () => void
  selectRoom: (room: Location) => void
  rightDrawerType: MapManagementRightDrawerType
}

interface Props {
  canEdit: boolean
  venueId: number
  selectedFloor: Location
  mapPresets: MapPresets
  locations: Locations
  openRoomEditModal: boolean
  setOpenRoomEditModal: (open: boolean) => void
  units: Units
  rightDrawerOptions: RightDrawerOptions
}

const LocationManagementMap = (props: Props): JSX.Element | null => {
  const {
    canEdit,
    venueId,
    selectedFloor,
    mapPresets,
    locations,
    openRoomEditModal,
    units,
    setOpenRoomEditModal,
    rightDrawerOptions,
  } = props
  const { closeDetailDrawer, selectRoom, rightDrawerType } = rightDrawerOptions

  // Use State Props
  const [showUnits, setShowUnits] = useState(false)
  const [selectedGeofences, setSelectedGeofences] = useState<Geofence[]>([])
  const [selectedRooms, setSelectedRooms] = useState<Location[]>([])
  const [
    locationIdsWithMismatchRoomNames,
    setLocationIdsWithMismatchRoomNames,
  ] = useState<string[]>([])
  const [mapIsLoaded, setMapIsLoaded] = useState(false)
  const [updateDeviceLatLon, setUpdateDeviceLatLon] = useState(false)
  const [exportGeofence, setExportGeofence] = useState(false)
  const [selectedLocationId, setSelectedLocationId] = useState<
    string | undefined
  >(undefined)
  const [jibestreamRef, setJibestreamRef] = useState<any>(undefined)
  const [geofenceKitRef, setGeofenceKitRef] = useState<any>(undefined)
  const [assetKitRef, setAssetKitRef] = useState<any>(undefined)
  const [locationsLoaded, setLocationsLoaded] = useState(false)
  const [geoFenceKitLoaded, setGeoFenceKitLoaded] = useState(false)
  const [currentVenueId, setCurrentVenueId] = useState(0)
  const [currentFloorId, setCurrentFloorId] = useState('')
  const [multiSelectRoomsEnabled, setMultiSelectRoomsEnabled] = useState(false)
  const [selectAllRooms, setSelectAllRooms] = useState(false)
  const [editFloorModalOpen, setEditFloorModalOpen] = useState<boolean>(false)
  const dispatch = useDispatch()

  // Data Hooks
  const { data: geofences } = useFetchAllGeofences()
  const { data: locationGeofences, status: locationGeofenceStatus } =
    useSelector(
      ({ locationGeofences }: { locationGeofences: LocationGeofencesState }) =>
        locationGeofences
    )
  const { data: mapReferenceViews } = useMapReferenceViews(venueId)
  const mapId = useCurrentMap(venueId, selectedFloor)
  useMapResize(jibestreamRef?.control)

  useEffect(() => {
    dispatch(getAllGeofencesByLocation.request(selectedFloor.id))
  }, [selectedFloor])

  const backgroundColor = (index: number): string => {
    switch (index) {
      case 0:
      case 4:
      case 8:
        return colors.unitColor1
      case 1:
      case 5:
      case 9:
        return colors.unitColor2
      case 2:
      case 6:
      case 10:
        return colors.unitColor3
      case 3:
      case 7:
      case 11:
        return colors.unitColor4
      default:
        return colors.darkBlue
    }
  }

  const assignUnitColors = (): UnitPlusColor[] => {
    const unitColors: UnitPlusColor[] = []
    const rooms = Object.values(locations)
      .filter((x) => x.parentId === selectedFloor.id)
      .map((x) => x.unitId)
    Object.values(units)
      .filter((x) => x?.id && rooms.includes(x.id))
      .map((unit, index) => {
        unitColors.push({
          id: unit.id,
          name: unit.name,
          unitColor: backgroundColor(index) || '',
        })
      })

    return unitColors
  }

  const colorUnits = (
    unitLocations: Locations,
    locationGeofences: LocationGeofences,
    geofences: Geofences
  ): MapOutline[] => {
    const unitLocationGeofences = Object.values(locationGeofences).filter(
      (lg) =>
        Object.values(unitLocations).findIndex((f) => f.id === lg.locationId) >
        -1
    )
    const unitGeofences = Object.values(geofences).filter(
      (g) =>
        unitLocationGeofences.findIndex(
          (f) => Number(f.geofenceId) === Number(g.coreReferenceId)
        ) > -1
    )

    const unitColors = assignUnitColors()
    const unitGeofencesWithColors: UnitGeoWithColor[] = Object.values(
      unitLocations
    )
      .map((unitLocation) => {
        const unitWithColor = unitColors.find(
          (u) => u.id === unitLocation.unitId
        )
        const geoloc = unitLocationGeofences.find(
          (locGeo) => locGeo.locationId === unitLocation.id
        )

        if (geoloc) {
          const unitGeoWithColor: UnitGeoWithColor = {
            geoLocationId: geoloc.geofenceId.toString(),
            unitColor: unitWithColor?.unitColor,
          }
          return unitGeoWithColor
        }
      })
      .filter((x) => x !== undefined) as UnitGeoWithColor[]

    const unitHighLighting: MapOutline[] = Object.values(unitGeofences).map(
      (unitGeofence) => {
        const color = unitGeofencesWithColors.find(
          (u) =>
            Number(u.geoLocationId) === Number(unitGeofence.coreReferenceId)
        )
        const mapOutline: MapOutline = {
          badgeId: '',
          geoLocationId: Number(unitGeofence.coreReferenceId),
          outlineColor: color?.unitColor,
        }
        return mapOutline
      }
    )
    return unitHighLighting
  }

  useEffect(() => {
    setSelectedRooms(
      mapGeofencesToLocations(
        Object.values(locationGeofences),
        selectedGeofences,
        Object.values(locations)
      )
    )
  }, [selectedGeofences, locations])

  // Draw selected rooms
  useEffect(() => {
    if (
      geofenceKitRef &&
      Object.values(locationGeofences).length &&
      Object.values(geofences).length
    ) {
      if (selectedGeofences.length > 0) {
        let unitHighLighting: MapOutline[] = []
        if (showUnits) {
          // First hightlight all rooms according to the unit color scheme
          const unitsToColor = colorUnits(
            locations,
            locationGeofences,
            geofences
          )
          unitHighLighting = unitHighLighting.concat(unitsToColor)
        }

        selectedGeofences.forEach((geo) => {
          // If selected rooms are part of the highlighted units, replace the unit color code with outlineColor indicated selected rooms
          const index = unitHighLighting.findIndex(
            (x) => x.geoLocationId === geo.coreReferenceId
          )
          if (index > -1) {
            unitHighLighting[index].outlineColor = colors.outlineColor
          } else {
            unitHighLighting.push({
              badgeId: '',
              geoLocationId: Number(geo.coreReferenceId),
              outlineColor: '#54c8e8',
            })
          }
        })

        mapUnitOutLinesHighlighting(
          unitHighLighting,
          jibestreamRef,
          geofenceKitRef,
          geofences,
          false
        )
      } else {
        if (showUnits) {
          // If deselecting all rooms, keep the map highlighted per unit color scheme
          let unitHighLighting: MapOutline[] = []
          const unitsToColor = colorUnits(
            locations,
            locationGeofences,
            geofences
          )
          unitHighLighting = unitHighLighting.concat(unitsToColor)
          mapUnitOutLinesHighlighting(
            unitHighLighting,
            jibestreamRef,
            geofenceKitRef,
            geofences,
            false
          )
        } else {
          jibestreamRef?.control?.currentMapView?.mapLayers
            ?.find((x: any) => x.name === 'Geofences')
            ?.clearShapes()
        }
      }
    }
  }, [
    selectedGeofences,
    geofenceKitRef,
    locationGeofences,
    geofences,
    jibestreamRef?.control,
  ])

  // Handle showUnit toggling
  useEffect(() => {
    if (showUnits) {
      const unitsToColor = colorUnits(locations, locationGeofences, geofences)
      mapUnitOutLinesHighlighting(
        unitsToColor,
        jibestreamRef,
        geofenceKitRef,
        geofences,
        false
      )
      // For now, all selected rooms are cleard out when showUnits is toggled on. This may change in the future
      setSelectedGeofences([])
    } else {
      // On showUnits toggled off, keep selected rooms highlighted on the map
      const unitHighLighting: MapOutline[] = selectedGeofences.map((geo) => ({
        badgeId: '',
        geoLocationId: Number(geo.coreReferenceId),
        outlineColor: colors.outlineColor,
      }))

      mapUnitOutLinesHighlighting(
        unitHighLighting,
        jibestreamRef,
        geofenceKitRef,
        geofences,
        false
      )
    }
  }, [showUnits])

  // Handles resetting venue, floor, and if locations have been loaded on the map
  useEffect(() => {
    if (currentVenueId !== venueId || currentFloorId !== selectedFloor.id) {
      setLocationsLoaded(false)
      setCurrentVenueId(venueId)
      setCurrentFloorId(selectedFloor.id)
      setMultiSelectRoomsEnabled(false)
      setShowUnits(false)
      setSelectAllRooms(false)
      setSelectedGeofences([])
      setSelectedLocationId('')
      closeDetailDrawer()
    }
  }, [currentFloorId, currentVenueId, locationsLoaded, selectedFloor, venueId])

  useEffect(() => {
    if (
      jibestreamRef?.control &&
      assetKitRef &&
      geofenceKitRef &&
      Object.values(locationGeofences).length &&
      Object.values(geofences).length &&
      !locationsLoaded &&
      locationGeofenceStatus !== FetchingStatus.Request &&
      mapIsLoaded &&
      currentVenueId === venueId &&
      currentFloorId === selectedFloor.id &&
      geoFenceKitLoaded
    ) {
      //Create Empty Array
      const locationsToCreate: InpixonAssetConfig[] = []
      const roomsWithMismatchNames: string[] = []

      //Loop through locations associated with a geofence
      Object.values(locationGeofences)
        .filter((x) =>
          Object.values(locations).some(
            (s) => s.parentId === currentFloorId && s.id === x.locationId
          )
        )
        .forEach((geoRef: LocationGeofence) => {
          //Get geofence record
          const geoFence = Object.values(geofences).find(
            (x) => x.coreReferenceId === geoRef.geofenceId
          )

          //Get location record
          const location = locations[geoRef.locationId]

          const destinationId = Object.values(mapReferenceViews).find(
            (x) => x.destinationLocationId === geoRef.locationId
          )?.destinationId
          const shapes = jibestreamRef.control.currentMapView
            .getMapLayerByName('Units')
            ?.shapes.map((x: any) => x.meta.destinationIds)

          let unitsDestinationIds: number[]

          if (shapes === undefined) {
            console.log('Problem in Inpixon, shapes undefined.')
            unitsDestinationIds = []
          } else {
            unitsDestinationIds = shapes
              .filter((y: any) => y.length > 0)
              .reduce((a: number[], b: number[]) => a.concat(b))
          }

          const deduplicatedDestinationIds = [...new Set(unitsDestinationIds)]
          // Check to see if the room location has a matching destinationId in Inpixon.
          // Typically, hallways or rooms with no geofences do not exist in Units map layer in Inpixon,
          // which requires the use of handleAssetClick() event handler to handle click event
          const hasUnitLayerWithSameDestinationId =
            destinationId &&
            deduplicatedDestinationIds.findIndex((id) => id === destinationId) >
              -1

          //Check if Inpixon data has a unit layer element with same name as location
          const hasUnitLayerWithSameLocationName =
            jibestreamRef?.control?.currentMapView?.texts.some(
              (x: any) => x.text.replace('⦰ ', '') === location.name
            )

          // A room location that hasUnitLayerWithSameDestinationId in Inpixon but does NOT hasUnitLayerWithSameLocationName
          // indicates there is a mismatch in the room name between what is stored in the tenant DB and what is in Inpixon
          if (
            hasUnitLayerWithSameDestinationId &&
            !hasUnitLayerWithSameLocationName
          ) {
            roomsWithMismatchNames.push(location.id)
          }

          //Check if location is on current floor, and has a unit in Inpixon data
          if (
            location.parentId === currentFloorId &&
            !hasUnitLayerWithSameLocationName
          ) {
            //Setup a variable
            let geoFenceInstance: any

            //Verify geofenceKit has items and geoFence is not undefined
            if (geofenceKitRef?.geofences?._items && geoFence) {
              geofenceKitRef.geofences._items.forEach((item: any) => {
                if (item?.instances) {
                  item.instances.forEach((instance: any) => {
                    if (instance.id === geoFence.id) {
                      //Set geoFenceInstance variable to instance for geoFenceKit
                      geoFenceInstance = instance
                    }
                  })
                }
              })

              //Check if geoFenceInstance is undefined
              if (geoFenceInstance) {
                //Get Center of geofence for plotting on map
                const center = getCenterOfBounds(
                  geoFenceInstance.localCoordinates[0]
                )

                //Push asset into create array
                locationsToCreate.push(
                  generateAssetConfig({
                    assetID: geoRef.geofenceId,
                    name: location.name,
                    coordinates: [center[0], center[1]],
                    type: IconType.Location,
                    height: 10,
                    showIcon: true,
                    map: jibestreamRef?.control?.map,
                    isConfigured: false,
                    hasIssues: false,
                    isLowBattery: false,
                    confidenceAlpha: 0,
                    confidenceMax: 10,
                    snapToGrid: false,
                    iconScale: getIconScale(
                      jibestreamRef?.control?.currentMap?.mmPerPixel
                    ),
                    isDuress: false,
                    isAssist: false,
                  })
                )
              }
            }
          }
        })

      setLocationIdsWithMismatchRoomNames(roomsWithMismatchNames)

      //Check if array has any elements
      if (locationsToCreate) {
        //Create Locations on map and set Loaded
        createAssets(assetKitRef, locationsToCreate)
        setLocationsLoaded(true)
      }
    } else if (
      locationsLoaded &&
      (currentVenueId !== venueId || currentFloorId !== selectedFloor.id)
    ) {
      //Location Cleanup when Venue or Floor change
      assetKitRef
        ._getAllAssets()
        .map((x: any) => x.id)
        .forEach((assetKitId: number) => {
          deleteAsset(assetKitRef, assetKitId)
        })
      setLocationsLoaded(false)
    }
  }, [
    assetKitRef,
    selectedFloor,
    currentFloorId,
    currentVenueId,
    geoFenceKitLoaded,
    geofenceKitRef,
    geofences,
    jibestreamRef,
    locationGeofences,
    locations,
    locationsLoaded,
    mapIsLoaded,
    mapReferenceViews,
    venueId,
  ])

  useEffect(() => {
    // On multiSelectRooms toggled off, remove all currently selected rooms on the map
    if (!multiSelectRoomsEnabled) {
      setSelectedLocationId('')
      setSelectedGeofences([])
      setSelectAllRooms(false)
    } else {
      closeDetailDrawer()
    }
  }, [multiSelectRoomsEnabled, closeDetailDrawer])

  useEffect(() => {
    if (selectAllRooms) {
      setSelectedGeofences(Object.values(geofences))
    }
  }, [selectAllRooms])

  useEffect(() => {
    if (!selectedLocationId) {
      return
    }

    const locationGeofence = Object.values(locationGeofences).find(
      (x) => x.locationId === selectedLocationId
    )
    const geofence = Object.values(geofences).find(
      (x) => x.coreReferenceId === locationGeofence?.geofenceId
    )
    if (!geofence) {
      return
    }

    if (
      selectedGeofences.findIndex(
        (x) => x.coreReferenceId === geofence.coreReferenceId
      ) > -1
    ) {
      if (multiSelectRoomsEnabled) {
        // Deselect room
        setSelectedGeofences((prevState) =>
          prevState.filter(
            (x) => x.coreReferenceId !== geofence.coreReferenceId
          )
        )
      }
    } else {
      if (multiSelectRoomsEnabled) {
        setSelectedGeofences((prevState) => [...prevState, geofence])
      } else {
        setSelectedGeofences([geofence])
      }
    }

    if (!multiSelectRoomsEnabled) {
      selectRoom(locations[selectedLocationId])
    }
    setSelectedLocationId(undefined)
  }, [selectedLocationId, locationGeofences, geofences, locations, selectRoom])

  useEffect(() => {
    if (jibestreamRef?.control) {
      const control = jibestreamRef.control

      //Enable click event when clicking on a unit in map
      control.enableLayerInteractivity('units', (shape: any, event: any) => {
        // Reset all shape styles on map
        control.resetShapeStyles(
          control.getUnitsFromMap(jibestreamRef.control.currentMap)
        )

        // Get all destinations from unit using the units meta info
        const destinations = shape.meta.destinationIds.map((id: any) =>
          control.activeVenue.destinations.getById(id)
        )

        //Check if destinations are found
        if (destinations.length) {
          //Get location record by destination name
          const mapRefView = Object.values(mapReferenceViews).find(
            (x) => x.destinationId === destinations[0].id
          )

          //If location is defined, show location details
          if (mapRefView) {
            setSelectedLocationId(mapRefView?.destinationLocationId)
          }
        }
      })
    }
  }, [jibestreamRef, mapReferenceViews])

  const HandleJibestreamRef = useCallback((jibestream: any) => {
    if (jibestream) {
      setJibestreamRef(jibestream)
    }
  }, [])

  const HandleAssetKitRef = useCallback((assetKit: any) => {
    if (assetKit) {
      setAssetKitRef(assetKit)
    }
  }, [])

  const HandleGeofenceKitRef = useCallback((geofenceKit: any) => {
    if (geofenceKit) {
      setGeofenceKitRef(geofenceKit)
    }
  }, [])

  const handleGeofenceKitLoaded = useCallback((loaded: boolean) => {
    if (loaded) {
      setGeoFenceKitLoaded(loaded)
    }
  }, [])

  const handleAssetClick = useCallback(
    (trackingId: number) => {
      if (locationGeofences && locations) {
        const locationGeofence = Object.values(locationGeofences).find(
          (x) => x.geofenceId === trackingId
        )

        if (locationGeofence) {
          // If the room location has a mismatch in room name between the tenant DB and Inpixon, skip handleAssetClick
          // to prevent setSelectedLocationId twice since it's already set in jibestream enableLayerInteractivity callback
          if (
            locationIdsWithMismatchRoomNames.findIndex(
              (x) => x === locationGeofence.locationId
            ) > -1
          ) {
            return
          }
          setSelectedLocationId(locationGeofence.locationId)

          if (jibestreamRef?.control) {
            const control = jibestreamRef.control
            const location = Object.values(mapReferenceViews).find(
              (x) => x.destinationLocationId === locationGeofence.locationId
            )
          }
        }
      }
    },
    [
      jibestreamRef?.control,
      locationGeofences,
      locations,
      mapReferenceViews,
      locationIdsWithMismatchRoomNames,
      setSelectedLocationId,
    ]
  )

  const handleEditFloor = useCallback((): void => {
    setEditFloorModalOpen(true)
  }, [])

  const handleCloseFloorEditModal = useCallback((): void => {
    setEditFloorModalOpen(false)
  }, [])

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleViewSensors = useCallback((): void => {}, [])

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleMapChange = useCallback((): void => {}, [])

  const mapPlaceholderRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const rect = mapPlaceholderRef.current?.getBoundingClientRect()
    dispatch(setShouldShowRealTimeMapAction(true))
    if (rect) {
      dispatch(setRectangleAction(domRectToRectangle(rect)))
    }
    return () => {
      dispatch(resetRealTimeMapControlStateAction())
    }
  }, [JSON.stringify(mapPlaceholderRef?.current?.getBoundingClientRect())])

  useEffect(() => {
    if (!!jibestreamRef) {
      dispatch(setJibestreamControlAction(jibestreamRef.control))
    }
  }, [jibestreamRef?.control])

  return (
    <>
      <LocationFloorEditBanner
        canEdit={canEdit}
        selectedFloor={selectedFloor}
        onEdit={handleEditFloor}
      />
      <div style={{ height: '100%', width: '100%' }}>
        <div
          style={{ height: '100%', width: '100%' }}
          ref={mapPlaceholderRef}
        />
        <FloorUnitsControl
          data-testid='floorUnitsControl'
          unitsWithColor={assignUnitColors()}
          showUnits={showUnits}
          setShowUnits={setShowUnits}
          setMultiSelectRooms={setMultiSelectRoomsEnabled}
          mutliSelectRooms={multiSelectRoomsEnabled}
          setSelectAllRooms={setSelectAllRooms}
          setOpenMultipleRoomsEditModal={setOpenRoomEditModal}
          selectedGeofences={selectedGeofences}
        />
        <InpixonMap
          data-testid='inpixonMap'
          venueId={venueId}
          mapPresets={mapPresets}
          mapId={mapId}
          mapIsLoaded={mapIsLoaded}
          geofences={geofences}
          onMapChanged={handleMapChange}
          handleAssetClickDelegate={handleAssetClick}
          setMapLoaded={setMapIsLoaded}
          updateDeviceLatLon={updateDeviceLatLon}
          setExportGeofence={setExportGeofence}
          setUpdateDeviceLatLon={setUpdateDeviceLatLon}
          exportGeofence={exportGeofence}
          redirect={'admin/hardware'}
          handleGeofenceKitRef={HandleGeofenceKitRef}
          handleAssetKitRef={HandleAssetKitRef}
          handleMapRef={HandleJibestreamRef}
          currentRightDrawerType={rightDrawerType}
          handleGeofenceKitLoaded={handleGeofenceKitLoaded}
        />
        <div>
          {editFloorModalOpen && (
            <FloorEditModal
              data={selectedFloor}
              handleClose={handleCloseFloorEditModal}
              modalHeaderTitle={`Edit ${selectedFloor.name}`}
            />
          )}
          {openRoomEditModal && (
            <RoomEditModal
              data={{
                selectedRooms: selectedRooms,
                multiSelectEnabled: multiSelectRoomsEnabled,
              }}
              handleClose={() => setOpenRoomEditModal(false)}
              modalHeaderTitle={`Edit ${selectedFloor.name}`}
            />
          )}
        </div>
        <LoadingOverlay visible={!(mapIsLoaded && locationsLoaded)} />
      </div>
    </>
  )
}

export { LocationManagementMap }
