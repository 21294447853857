import { combineEpics, Epic } from 'redux-observable'
import * as JibestreamDestinationEpics from './destination'
import * as JibestreamUserEpics from './user'
import * as JibestreamVenueEpics from './venue'
import { JibestreamRootAction } from '../actions'
import { JibestreamRootState } from '../reducers'

export * from './destination'
export * from './user'
export * from './venue'

export type JibestreamRootEpic = Epic<
  JibestreamRootAction,
  JibestreamRootAction,
  JibestreamRootState,
  // eslint-disable-next-line
  any
>

export default combineEpics(
  ...Object.values(JibestreamDestinationEpics),
  ...Object.values(JibestreamUserEpics),
  ...Object.values(JibestreamVenueEpics)
)
