import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

const useStyles = makeStyles(() =>
  createStyles({
    basket: {
      transition: 'opacity 0.5s',
      opacity: 1,
    },
    basketHide: {
      transition: 'opacity 1s',
      opacity: 0,
      pointerEvents: 'none',
    },
  })
)

interface Props {
  visible: boolean
}

const LoadingOverlay = (props: Props): JSX.Element | null => {
  const { visible } = props
  const classes = useStyles()

  return (
    <div
      className={visible ? classes.basket : classes.basketHide}
      style={{
        zIndex: 1001,
        paddingLeft: 1,
        paddingTop: 1,
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: 'white',
        display: 'flex',
      }}
    >
      <img
        src={process.env.REACT_APP_STATIC_CONTENT + 'images/progress.gif'}
        alt='loading'
        style={{ margin: 'auto', height: '200px' }}
      ></img>
    </div>
  )
}

export default LoadingOverlay
