import { IconButton, InputAdornment } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { SearchOutlined, Close } from '@mui/icons-material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { TextField } from './Theme'
import Autocomplete from '@mui/material/Autocomplete'
import { colors } from '../../styles/MidmarkTheme'

interface SearchFieldProps {
  value?: string
  onSearch?: (value: string) => void
  placeholder?: string
  autoFocus?: boolean
  debounce?: number
  disabled?: boolean
  fullWidth?: boolean
  localStorageName?: string
  inputClasses?: any
}

const SearchField = (props: SearchFieldProps): JSX.Element => {
  const {
    inputClasses,
    value,
    onSearch,
    placeholder = 'Search',
    debounce = 500,
    localStorageName,
    ...other
  } = props
  const [inputValue, setInputValue] = useState(value)
  const searchHist = localStorageName
    ? localStorage.getItem(localStorageName)
    : null

  const parsedHistory = useMemo((): string[] => {
    return searchHist ? JSON.parse(searchHist) : []
  }, [searchHist])

  const handleClear = useCallback((): void => {
    setInputValue('')
  }, [])

  // watch for props value changes and update search value if necessary
  useEffect(() => {
    if (value !== inputValue) {
      setInputValue(value)
    }
    // disabled additional dependencies to stop triggering effect when input value is updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (inputValue !== undefined) {
        onSearch && onSearch(inputValue)

        if (localStorageName) {
          const currentLocalStorageValue =
            localStorage.getItem(localStorageName)
          const parsedHistory = currentLocalStorageValue
            ? JSON.parse(currentLocalStorageValue)
            : []
          const mergedOptions = JSON.stringify([
            ...new Set(
              [...parsedHistory, inputValue]
                .map((x) => x.trim())
                .filter(Boolean)
                .slice(-5)
            ),
          ])

          if (
            !!currentLocalStorageValue &&
            currentLocalStorageValue !== mergedOptions
          ) {
            localStorage.setItem(localStorageName, mergedOptions)
          }
        }
      }
    }, debounce)
    return () => clearTimeout(timeout)
  }, [inputValue, localStorageName, debounce, onSearch])

  const useStyles = makeStyles({
    searchBoxWithInput: {
      maxHeight: '36px',
      backgroundColor: colors.whiteSmoke,
    },
    searchBoxWithNoInput: {
      maxHeight: '36px',
    },
  })

  const classes = useStyles()

  return (
    <Autocomplete
      freeSolo
      disableClearable
      value={inputValue ?? ''}
      options={parsedHistory.slice().reverse()}
      getOptionLabel={(option) => option}
      onChange={(_event, value) => {
        setInputValue(value)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant='outlined'
          placeholder={placeholder}
          onChange={(ev): void => setInputValue(ev.target.value)}
          {...other}
          InputProps={{
            ...params.InputProps,
            className: inputValue
              ? classes.searchBoxWithInput
              : classes.searchBoxWithNoInput,
            endAdornment: (
              <InputAdornment position='end'>
                {(inputValue !== '' && inputValue !== undefined && (
                  <IconButton onClick={handleClear} size='small'>
                    <Close style={{ color: colors.darkBlue }} />
                  </IconButton>
                )) || <SearchOutlined color='inherit' />}
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  )
}

export default SearchField
