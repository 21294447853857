import { getAllExternalLinksAction } from '../../actions/externalLinks'
import { ExternalLinks, ExternalLinksState } from '../../models'
import { EntityResult, useEntities } from '../useEntities'

const useFetchExternalLinks = (
  customerId: string
): EntityResult<ExternalLinks> =>
  useEntities<ExternalLinksState, ExternalLinks>(
    () => getAllExternalLinksAction.request(customerId),
    (state: any) => state.externalLinks // eslint-disable-line @typescript-eslint/no-explicit-any
  )

export { useFetchExternalLinks }
