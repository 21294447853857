import { Epic } from 'redux-observable'
import { filter, mergeMap, map, catchError } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { from, of } from 'rxjs'
import { getFeatureFlagByLabelAction } from '../actions/featureFlags'
import { FeatureFlagApi } from '../middleware/featureFlags'
import { FeatureFlagByLabelAction } from '../actions'
import { FeatureFlagState } from '../models'

type FeatureFlagsEpic = Epic<
  FeatureFlagByLabelAction,
  FeatureFlagByLabelAction,
  FeatureFlagState,
  { featureFlagApi: FeatureFlagApi }
>
export const getFeatureFlagByLabelEpic: FeatureFlagsEpic = (
  action$,
  state,
  { featureFlagApi }
) => {
  return action$.pipe(
    filter(isActionOf(getFeatureFlagByLabelAction.request)),
    mergeMap((action) =>
      from(featureFlagApi.getFeatureFlagByLabel(action.payload)).pipe(
        map((payload) => getFeatureFlagByLabelAction.success(payload)),
        catchError((error) => of(getFeatureFlagByLabelAction.failure(error)))
      )
    )
  )
}
