import React, { useRef, useState } from 'react'
import ReorderIcon from '@mui/icons-material/Reorder'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { CancelButton, SaveButton } from '../Buttons/Button'
import { TableColumnSetting, TableColumnSettings } from '../../models'
import CloseIcon from '@mui/icons-material/Close'
import { colors } from '../../styles/MidmarkTheme'

interface Props {
  handleClose: () => void
  listSettings: TableColumnSettings
  defaultListSettings: TableColumnSettings
  handleSave: (settings: TableColumnSettings, saveDefault: boolean) => void
}

const useStyles = makeStyles(() =>
  createStyles({
    dialogTitle: {
      padding: '0 0 0 16px',
    },
    dialogContent: {
      padding: 0,
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      color: 'black',
      fontFamily: 'Ubuntu',
      fontSize: '12px',
      fontWeight: 'bold',
      width: '380px',
      verticalAlign: 'center',
    },
    headerLeftContainer: {
      display: 'flex',
    },
    headerLeftContainerText: {
      margin: 'auto',
    },
    listItem: {
      paddingLeft: '14px',
    },
    listItemText: {
      color: 'black',
      fontFamily: 'Ubuntu',
      fontSize: '16px',
      cursor: 'move',
    },
    listItemIcon: {
      padding: 0,
      minWidth: '32px',
    },
    listItemSecondaryAction: {
      cursor: 'move',
    },
    footerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    link: {
      color: colors.primary,
      fontFamily: 'Ubuntu',
      fontSize: '12px',
    },
    checkbox: {
      outlineColor: colors.primary,
      borderColor: colors.primary,
      alignContent: 'left',
      fontFamily: 'Ubuntu',
      fontSize: '16px',
      padding: 0,
    },
    closeButton: {
      verticalAlign: 'bottom',
      height: '45px',
      border: '0',
      paddingLeft: '16px',
      paddingRight: '16px',
    },
    paper: {
      margin: 'auto',
    },
  })
)

const CustomizeTableModal = (props: Props) => {
  const { handleClose, listSettings, defaultListSettings, handleSave } = props

  const convertToScreenList = (
    settings: TableColumnSettings
  ): TableColumnSetting[] => {
    return Object.entries(settings)
      .map(([key, value]) => {
        return {
          ...value,
        }
      })
      .sort((a, b) => a.order - b.order)
  }

  const screenlist = convertToScreenList(listSettings)

  const [list, setList] = useState<TableColumnSetting[]>(screenlist)
  const [saveDefault, setSaveDefault] = useState<boolean>(false)
  const classes = useStyles()

  const onSave = () => {
    const newListSettings = list.reduce(
      (acc: TableColumnSettings, item, index: number): TableColumnSettings => {
        return {
          ...acc,
          [item.columnName]: {
            ...item,
            order: index,
            show: item.show,
          },
        }
      },
      {} as TableColumnSettings
    )

    handleSave(newListSettings, saveDefault)
    handleClose()
  }

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const t = list.findIndex(
      (x) => (x.columnName ?? -1).toString() === event.target.value
    )
    list[t].show = event.target.checked
    setList(list.slice())
    setSaveDefault(false)
    return true
  }

  const resetToDefault = () => {
    const defaultList = convertToScreenList(defaultListSettings)
    setList(defaultList)
    setSaveDefault(true)
  }

  const draggingItem = useRef<number>()
  const dragOverItem = useRef<number>()

  const handleDragStart = (e: any, position: any) => {
    if (position < list.length && list[position].canModify) {
      draggingItem.current = position
    } else {
      draggingItem.current = undefined
    }
  }

  const handleDragEnter = (e: any, position: any) => {
    dragOverItem.current = position
    if (
      draggingItem.current !== undefined &&
      dragOverItem.current !== undefined
    ) {
      const listCopy = [...list]
      const dragOverItemContent = listCopy[dragOverItem.current]
      if (dragOverItemContent.canModify) {
        const draggingItemContent = listCopy[draggingItem.current]

        listCopy.splice(draggingItem.current, 1)
        listCopy.splice(dragOverItem.current, 0, draggingItemContent)

        draggingItem.current = dragOverItem.current
        dragOverItem.current = undefined
        setList(listCopy)
        setSaveDefault(false)
      }
    }
  }

  return (
    <Dialog open className={classes.paper} onClose={handleClose}>
      <DialogTitle className={classes.dialogTitle}>
        <div className={classes.headerContainer}>
          <div className={classes.headerLeftContainer}>
            <div className={classes.headerLeftContainerText}>
              CUSTOMIZE COLUMNS
            </div>
            <Button
              onClick={(): void => resetToDefault()}
              className={classes.link}
              style={{ textTransform: 'none' }}
            >
              Reset to Default
            </Button>
          </div>
          <IconButton
            aria-label='Close'
            className={classes.closeButton}
            onClick={handleClose}
            size='large'
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <List dense>
          {list.map((item, index) => {
            return (
              <div
                key={index}
                draggable={item.canModify}
                onDragStart={(e) => handleDragStart(e, index)}
                onDragOver={(e) => e.preventDefault()}
                onDragEnter={(e) => handleDragEnter(e, index)}
              >
                <ListItem data-position={index} className={classes.listItem}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <Checkbox
                      value={item.columnName ?? -1}
                      onChange={(e) => handleOnChange(e)}
                      checked={item.show}
                      color='primary'
                      className={classes.checkbox}
                      disabled={!item.canModify}
                    />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    className={classes.listItemText}
                  >
                    {item.columnDisplayName}
                  </ListItemText>
                  <ListItemSecondaryAction
                    className={classes.listItemSecondaryAction}
                  >
                    <ReorderIcon />
                  </ListItemSecondaryAction>
                </ListItem>
              </div>
            )
          })}
        </List>
      </DialogContent>
      <DialogActions className={classes.footerContainer}>
        <CancelButton onClick={(): void => handleClose()} />
        <SaveButton onClick={(): void => onSave()} />
      </DialogActions>
    </Dialog>
  )
}

export default CustomizeTableModal
