import { BaseService } from './baseService'
import {
  Sensor,
  SensorUpdate,
  SensorBadgePayload,
  SensorUnconfigure,
  SensorConfigure,
} from '../models'

export class SensorsApi extends BaseService {
  constructor() {
    super({ entityType: 'sensors' })
  }
  route = 'sensors'

  //TODO: Find how nodeString is utilized for getAllSensors
  async getAllSensors(nodeString = ''): Promise<Sensor[]> {
    return await this.get<Sensor[]>(this.route, {
      params: { nodeString },
    })
  }

  async getAllSensorBySensorId(sensorId: string): Promise<Sensor[]> {
    return await this.get<Sensor[]>(`${this.route}/${sensorId}`)
  }

  async updateSensor(sensor: SensorUpdate): Promise<Sensor> {
    return await this.put<Sensor>(this.route, sensor)
  }

  async getSensorBadges(sensorIds: string[]): Promise<SensorBadgePayload> {
    return await this.get<SensorBadgePayload>(`${this.route}/badges`, {
      params: { sensorIds },
    })
  }

  async getSensorBadgesBySensor(sensorId: string): Promise<SensorBadgePayload> {
    return await this.get<SensorBadgePayload>(
      `${this.route}/${sensorId}/badges`
    )
  }

  async getLastSeenBadges(sensorIds: string[]): Promise<SensorBadgePayload> {
    return await this.get<SensorBadgePayload>(`${this.route}/badges/lastSeen`, {
      params: { sensorIds },
    })
  }

  async configureSensor(sensorConfigure: SensorConfigure): Promise<string> {
    return await this.put<string>(`${this.route}/configure`, sensorConfigure)
  }

  async unconfigureSensor(
    sensorUnconfigure: SensorUnconfigure
  ): Promise<string> {
    return await this.put<string>(
      `${this.route}/unconfigure`,
      sensorUnconfigure
    )
  }
}
