import { TabContext, TabPanel } from '@mui/lab'
import { Badge, Divider, Link, Tab, Tabs, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ColumnSort } from '@tanstack/react-table'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { RealTimeFilterStates, useFetchFilters } from '../../../../hooks'
import {
  AssetFilterConfigs,
  CommonFilterConfigs,
  StaffFilterConfigs,
  Unit,
} from '../../../../models'
import { colors } from '../../../../styles'
import { DataTestIds } from '../../../../utils/test-utils/dataTestIds'
import AssetFilterOptions from '../../../Filters/AssetFilterOptions'
import CommonFilterOptions from '../../../Filters/CommonFilterOptions'
import ListFilterChips from '../../../Filters/ListFilterChips'
import SaveFilterButton from '../../../Filters/SaveFilterButton'
import SavedFiltersList from '../../../Filters/SavedFiltersList'
import StaffFilterOptions from '../../../Filters/StaffFilterOptions'
import { DrawerContent } from '../../RightDrawer'
import {
  AssetFilters,
  StaffFilters,
  FilterBase,
  FilterType,
  RealTimeFilters,
} from '../../../../models/filters'

interface ListFilterOptions {
  onSavedFilterChanged: (
    columnSort: ColumnSort[],
    isSavedFilter: boolean
  ) => void
  isManagementPage: boolean
}

interface MapFilterOptions {
  agentTypes?: string[]
}

export interface AssetStaffFilterContentProps {
  assetFilterOptions?: AssetFilterConfigs
  staffFilterOptions?: StaffFilterConfigs
  commonFilterOptions: CommonFilterConfigs
  filterStates: RealTimeFilterStates
  units: Unit[]
  filterType: FilterType
  // Used for lists only
  listFilterOptions?: ListFilterOptions
  mapFilterOptions?: MapFilterOptions
}

const AssetStaffFilterContent: React.FC<AssetStaffFilterContentProps> = (
  props: AssetStaffFilterContentProps
) => {
  const {
    units,
    filterType,
    assetFilterOptions,
    staffFilterOptions,
    commonFilterOptions,
    filterStates,
    listFilterOptions,
    mapFilterOptions,
  } = props
  const {
    assetTypes,
    assetSubTypes,
    manufacturers,
    displayNames,
    models,
    owners,
  } = assetFilterOptions ?? {}

  const { staffTypes, managers } = staffFilterOptions ?? {}

  const { hideLowBatterFilter } = commonFilterOptions

  const {
    assetFilterCountExclusive: assetFilterCount,
    assetFilterState,
    setAssetFilters,
    staffFilterCountExclusive: staffFilterCount,
    staffFilterState,
    setStaffFilters,
    commonFilterCount,
    commonFilterState,
  } = filterStates ?? {}
  const { isManagementPage, onSavedFilterChanged } = listFilterOptions ?? {}
  const { agentTypes } = mapFilterOptions ?? {}

  const showAssetFilters: boolean = useMemo(() => {
    if (filterType === FilterType.Asset) {
      return true
    } else if (filterType === FilterType.RealTime) {
      if (!agentTypes) {
        return !!assetFilterOptions
      } else {
        return agentTypes.length > 0 && agentTypes.includes('Assets')
      }
    }
    return false
  }, [filterType, assetFilterOptions, agentTypes])

  const showStaffFilters = useMemo(() => {
    if (filterType === FilterType.Staff) {
      return true
    } else if (filterType === FilterType.RealTime) {
      if (!agentTypes) {
        return !!staffFilterOptions
      } else {
        return agentTypes.length > 0 && agentTypes.includes('Staff')
      }
    }
    return false
  }, [agentTypes, filterType, staffFilterOptions])

  const useStyles = makeStyles(() => ({
    tabs: {
      '& .MuiTab-textColorInherit': {
        opacity: 1,
      },
      '& .MuiBadge-anchorOriginTopRightRectangular': {
        right: -10,
      },
    },
    tabLabel: {
      display: 'flex',
      fontSize: 14,
      fontWeight: 'bold',
    },
    tabPanel: {
      '&.MuiTabPanel-root': {
        padding: 0,
      },
    },
    assetsBadge: {
      color: colors.white,
      backgroundColor: colors.primary,
    },
    commonBadge: {
      color: colors.white,
      backgroundColor: colors.background,
    },
    staffBadge: {
      color: colors.white,
      backgroundColor: colors.staff,
    },
    assetsTab: {
      minWidth: 98,
      display: showAssetFilters ? 'inherit' : 'none',
    },
    commonTab: {
      minWidth: 113,
    },
    staffTab: {
      minWidth: 88,
      display: showStaffFilters ? 'inherit' : 'none',
    },
    chipContainer: {
      position: 'relative',
    },
    saveButton: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
  }))

  const localClasses = useStyles()

  const [isEditable, setIsEditable] = useState(false)

  const {
    filters: {
      assetFilters: userAssetFilters,
      staffFilters: userStaffFilters,
      realTimeFilters: userRealTimeFilters,
    },
  } = useFetchFilters()

  const getFilterAndType = useCallback((): {
    filterToSave: AssetFilters | StaffFilters | RealTimeFilters
    savedFilters: AssetFilters[] | StaffFilters[] | RealTimeFilters[]
  } => {
    const savedFilters = (userRealTimeFilters as FilterBase[]).concat(
      userAssetFilters as FilterBase[],
      userStaffFilters as FilterBase[]
    )

    if (filterType === FilterType.RealTime) {
      return {
        filterToSave: {
          assetFilters: assetFilterState,
          staffFilters: staffFilterState,
        },
        savedFilters: savedFilters,
      }
    }

    if (filterType === FilterType.Asset) {
      return {
        filterToSave: assetFilterState,
        savedFilters: savedFilters,
      }
    }

    return {
      filterToSave: staffFilterState,
      savedFilters: savedFilters,
    }
  }, [
    assetFilterState,
    filterType,
    staffFilterState,
    userAssetFilters,
    userRealTimeFilters,
    userStaffFilters,
  ])

  const { filterToSave, savedFilters } = getFilterAndType()

  const handleAssetFilterChange = useCallback(
    (changes: Partial<AssetFilters>, savedFilter?: boolean): void => {
      let newState
      if (savedFilter) {
        newState = { ...changes }
      } else {
        newState = { ...assetFilterState, ...changes }
      }
      setAssetFilters(newState)
    },
    [assetFilterState, setAssetFilters]
  )

  const handleStaffFilterChange = useCallback(
    (changes: Partial<StaffFilters>, savedFilter?: boolean): void => {
      let newState
      if (savedFilter) {
        newState = { ...changes }
      } else {
        newState = { ...staffFilterState, ...changes }
      }
      setStaffFilters(newState)
    },
    [setStaffFilters, staffFilterState]
  )

  const handleSavedFilterChange = useCallback(
    (
      changes:
        | Partial<AssetFilters>
        | Partial<StaffFilters>
        | Partial<RealTimeFilters>,
      savedFilter?: boolean
    ): void => {
      let columnSort: ColumnSort[] = []
      switch (changes.filterType) {
        case FilterType.RealTime:
          columnSort =
            (changes as RealTimeFilters).assetFilters.columnSort ??
            (changes as RealTimeFilters).staffFilters.columnSort ??
            []
          if (onSavedFilterChanged) {
            onSavedFilterChanged(columnSort, true)
          }
          handleAssetFilterChange(
            (changes as RealTimeFilters).assetFilters,
            savedFilter
          )
          handleStaffFilterChange(
            (changes as RealTimeFilters).staffFilters,
            savedFilter
          )
          break
        case FilterType.Asset:
          columnSort = (changes as AssetFilters).columnSort ?? []
          if (onSavedFilterChanged) {
            onSavedFilterChanged(columnSort, true)
          }
          setStaffFilters({})
          handleAssetFilterChange(changes, savedFilter)
          break
        case FilterType.Staff:
          columnSort = (changes as StaffFilters).columnSort ?? []
          if (onSavedFilterChanged) {
            onSavedFilterChanged(columnSort, true)
          }
          setAssetFilters({})
          handleStaffFilterChange(changes, savedFilter)
          break
        default:
          // If it doesn't meet above matches, then it's probably empty due to
          // un-checking the saved filter
          setAssetFilters({})
          setStaffFilters({})
          break
      }
    },
    [
      handleAssetFilterChange,
      handleStaffFilterChange,
      onSavedFilterChanged,
      setAssetFilters,
      setStaffFilters,
    ]
  )

  const handleFilterChange = useCallback(
    (
      changes:
        | Partial<AssetFilters>
        | Partial<StaffFilters>
        | Partial<RealTimeFilters>,
      savedFilter?: boolean
    ): void => {
      if (filterType === FilterType.RealTime) {
        handleAssetFilterChange(changes, savedFilter)
        handleStaffFilterChange(changes, savedFilter)
      }

      if (filterType === FilterType.Asset) {
        handleAssetFilterChange(changes, savedFilter)
      }

      if (filterType === FilterType.Staff) {
        handleStaffFilterChange(changes, savedFilter)
      }
    },
    [filterType, handleAssetFilterChange, handleStaffFilterChange]
  )

  const resetFilters = useCallback((): void => {
    setAssetFilters({})
    setStaffFilters({})
  }, [setAssetFilters, setStaffFilters])

  enum TabPanelTypes {
    assets = 'Assets',
    common = 'Common',
    staff = 'Staff',
  }

  const filterCount = useMemo((): number => {
    let total = 0

    if (assetFilterCount) {
      total += assetFilterCount
    }

    if (staffFilterCount) {
      total += staffFilterCount
    }

    if (commonFilterCount) {
      total += commonFilterCount
    }

    return total
  }, [assetFilterCount, staffFilterCount, commonFilterCount])

  const [tabValue, setTabValue] = React.useState(
    showAssetFilters ? TabPanelTypes.assets : TabPanelTypes.staff
  )

  // ensure a valid tab is selected
  useEffect(() => {
    if (!showAssetFilters && tabValue === TabPanelTypes.assets) {
      setTabValue(TabPanelTypes.staff)
      return
    }

    if (!showStaffFilters && tabValue === TabPanelTypes.staff) {
      setTabValue(TabPanelTypes.assets)
      return
    }
  }, [
    TabPanelTypes.assets,
    TabPanelTypes.common,
    TabPanelTypes.staff,
    showAssetFilters,
    showStaffFilters,
    tabValue,
  ])

  const handleTabChange = (
    // eslint-disable-next-line @typescript-eslint/ban-types
    event: React.ChangeEvent<{}>,
    newValue: TabPanelTypes
  ): void => {
    setTabValue(newValue)
  }

  return (
    <DrawerContent>
      {!!filterCount && (
        <div
          className={localClasses.chipContainer}
          data-testid={DataTestIds.listFilterContentCurrentCommonFilters}
        >
          <div className={localClasses.saveButton}>
            <SaveFilterButton
              currentFilter={filterToSave}
              filterType={filterType}
            />
          </div>
          <div>
            <ListFilterChips
              assetFilterState={assetFilterState}
              staffFilterState={staffFilterState}
              commonFilterState={commonFilterState}
              handleAssetChange={setAssetFilters}
              handleStaffChange={setStaffFilters}
              disableAssetChips={!showAssetFilters}
              disableStaffChips={!showStaffFilters}
            />
          </div>
        </div>
      )}

      {!!savedFilters.length && (
        <div>
          <Typography variant='body2' style={{ fontWeight: 'bold' }}>
            {'SAVED FILTERS'}
            <Link
              style={{
                fontSize: '12px',
                marginLeft: 8,
                cursor: 'pointer',
              }}
              variant='body2'
              onClick={() => setIsEditable(!isEditable)}
              underline='hover'
            >
              {isEditable ? 'Done' : 'Edit'}
            </Link>
          </Typography>
          <SavedFiltersList
            savedFilters={savedFilters}
            isEditable={isEditable}
            handleChange={(
              change: AssetFilters | StaffFilters | RealTimeFilters
            ): void => handleSavedFilterChange(change, true)}
            handleDelete={resetFilters}
            filterCount={filterCount}
          />
        </div>
      )}
      <TabContext value={tabValue}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          centered
          className={localClasses.tabs}
          classes={{ root: localClasses.tabs }}
          indicatorColor='primary'
        >
          {showAssetFilters && (
            <Tab
              label={
                <>
                  <span className={localClasses.tabLabel}>
                    {TabPanelTypes.assets}
                    <Badge
                      badgeContent={assetFilterCount}
                      classes={{ badge: localClasses.assetsBadge }}
                    ></Badge>
                  </span>
                </>
              }
              value={TabPanelTypes.assets}
              className={localClasses.assetsTab}
            ></Tab>
          )}
          <Tab
            label={
              <>
                <span className={localClasses.tabLabel}>
                  {TabPanelTypes.common}
                  <Badge
                    badgeContent={commonFilterCount}
                    classes={{ badge: localClasses.commonBadge }}
                  ></Badge>
                </span>
              </>
            }
            value={TabPanelTypes.common}
            className={localClasses.commonTab}
          ></Tab>
          {showStaffFilters && (
            <Tab
              label={
                <>
                  <span className={localClasses.tabLabel}>
                    {TabPanelTypes.staff}
                    <Badge
                      badgeContent={staffFilterCount}
                      classes={{ badge: localClasses.staffBadge }}
                    ></Badge>
                  </span>
                </>
              }
              value={TabPanelTypes.staff}
              className={localClasses.staffTab}
            ></Tab>
          )}
        </Tabs>
        <Divider />
        {showAssetFilters && (
          <TabPanel
            value={TabPanelTypes.assets}
            className={localClasses.tabPanel}
            classes={{ root: localClasses.tabPanel }}
          >
            {assetTypes &&
              assetSubTypes &&
              manufacturers &&
              displayNames &&
              models &&
              owners &&
              units && (
                <AssetFilterOptions
                  handleAssetFilterChange={handleAssetFilterChange}
                  assetTypes={assetTypes}
                  assetSubTypes={assetSubTypes}
                  manufacturers={manufacturers}
                  displayNames={displayNames}
                  models={models}
                  owners={owners}
                />
              )}
          </TabPanel>
        )}
        <TabPanel
          value={TabPanelTypes.common}
          className={localClasses.tabPanel}
          classes={{ root: localClasses.tabPanel }}
        >
          <CommonFilterOptions
            isManagement={isManagementPage}
            hideLowBatteryFilter={hideLowBatterFilter}
            showAssetsSection={showAssetFilters}
            units={units ?? []}
            handleFilterChange={handleFilterChange}
          />
        </TabPanel>
        {showStaffFilters && (
          <TabPanel
            value={TabPanelTypes.staff}
            className={localClasses.tabPanel}
            classes={{ root: localClasses.tabPanel }}
          >
            {staffTypes && units && managers && (
              <StaffFilterOptions
                handleStaffFilterChange={handleStaffFilterChange}
                staffTypes={staffTypes}
                managers={managers}
              />
            )}
          </TabPanel>
        )}
      </TabContext>
    </DrawerContent>
  )
}

export default AssetStaffFilterContent
