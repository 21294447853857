import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { LogLevelTypesApi } from '../middleware'
import { getAllLogLevelTypesAction } from '../actions/logLevelTypes'
import { LogLevelTypesState } from '../models'
import { LogLevelTypeAction } from '../actions'

type LogLevelTypesEpic = Epic<
  LogLevelTypeAction,
  LogLevelTypeAction,
  LogLevelTypesState,
  { logLevelTypesApi: LogLevelTypesApi }
>

export const getAllLogLevelTypesEpic: LogLevelTypesEpic = (
  action$,
  state,
  { logLevelTypesApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllLogLevelTypesAction.request)),
    mergeMap(() =>
      from(logLevelTypesApi.getAllLogLevelTypes()).pipe(
        map((assetImports) => getAllLogLevelTypesAction.success(assetImports)),
        catchError((error) => of(getAllLogLevelTypesAction.failure(error)))
      )
    )
  )
}
