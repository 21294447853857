import React, { ReactNode } from 'react'
import { StyledComponent, StyledComponentProps } from '../'
import { FlexBox } from '../Layout'
import clsx from 'clsx'
import ActionBarContainer, {
  ActionBarContainerStyles,
} from './ActionBarContainer'
import { createStyles, withStyles } from '@mui/styles'

interface Props {
  left?: ReactNode
  middle?: ReactNode
  right?: ReactNode
  containerClasses?: ActionBarContainerStyles
}

const styles = createStyles({
  column: {
    '& > *:not(:first-child)': {
      marginLeft: 12,
    },
  },
  left: {},
  right: {},
  middle: {},
})

const ActionBar: StyledComponent<Props, typeof styles> = (
  props: StyledComponentProps<Props, typeof styles>
) => {
  const { classes, left, right, middle, containerClasses } = props

  return (
    <ActionBarContainer classes={containerClasses}>
      <FlexBox
        flex='grow'
        layout='row'
        layoutAlign={{ item: 'center', main: 'flex-start' }}
        className={clsx(classes.left, classes.column)}
      >
        {left}
      </FlexBox>
      {middle && (
        <FlexBox
          flex='grow'
          layout='row'
          layoutAlign={{ item: 'center', main: 'center' }}
          className={clsx(classes.middle, classes.column)}
        >
          {middle}
        </FlexBox>
      )}
      <FlexBox
        flex='grow'
        layout='row'
        layoutAlign={{ item: 'center', main: 'flex-end' }}
        className={clsx(classes.right, classes.column)}
      >
        {right}
      </FlexBox>
    </ActionBarContainer>
  )
}

export default withStyles(styles, { name: 'ActionBar' })(ActionBar)
