import { createSelector } from 'reselect'
import { CurrentUserLocationState } from '../models/currentUserLocation'

export const selectCurrentUserLocationState = ({
  currentUserLocation,
}: {
  currentUserLocation: CurrentUserLocationState
}): CurrentUserLocationState => currentUserLocation

export const selectCurrentUserLocation = createSelector(
  [selectCurrentUserLocationState],
  (currentUserLocationState) => currentUserLocationState.data
)

export const selectCurrentUserLocationFetchingStatus = createSelector(
  [selectCurrentUserLocationState],
  (currentUserLocationState) => currentUserLocationState.status
)
