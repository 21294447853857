import React, { useMemo } from 'react'
import LoadingIndicator from '../../components/LoadingIndicator'
import {
  SensoryNetworkMap,
  SensoryNetworkMapProps,
} from '../../components/Maps/SensoryNetworkMap/SensoryNetworkMap'
import {
  useFetchAllGeofences,
  useFetchDevices,
  useFetchGeofencesByLocation,
  useFetchLocations,
  useFetchMapPresets,
  useFetchSensors,
  useFetchVenues,
} from '../../hooks'
import { Asset, FetchingStatus, Location, Staff } from '../../models'
import {
  AssetFiltersProvider,
  StaffFiltersProvider,
} from '../../models/filters'

export interface RealTimeMapViewProps
  extends Pick<
    SensoryNetworkMapProps,
    | 'onSelectAgent'
    | 'onSelectGroupOnMap'
    | 'rightDrawerState'
    | 'onSelectAgentTypes'
    | 'updateSelectedAgentTelemetry'
    | 'removeAgentFromSelectedGroup'
    | 'addAgentToSelectedGroup'
    | 'updateAgentInSelectedGroup'
    | 'removeSelectedAgent'
    | 'moveSelectedAgentOutOfGroup'
    | 'selectAgentInGroup'
    | 'resetRightDrawerState'
  > {
  assetsByLocation: Asset[]
  isAssetsByLocationFetched: boolean
  assetFilterProvider: AssetFiltersProvider
  assetsStatus: FetchingStatus | undefined
  agentTypes: string[]
  venueId: number | undefined
  staffByLocation: Staff[]
  isStaffsByLocationFetched: boolean
  staffFilterProvider: StaffFiltersProvider
  staffStatus?: FetchingStatus | undefined
  currentFloor: Location | null
}
const RealTimeMapView: React.FC<RealTimeMapViewProps> = (
  props: RealTimeMapViewProps
) => {
  const {
    assetsByLocation,
    isAssetsByLocationFetched,
    assetFilterProvider,
    assetsStatus,
    venueId,
    staffByLocation,
    isStaffsByLocationFetched,
    staffFilterProvider,
    staffStatus,
    agentTypes,
    currentFloor,
    rightDrawerState,
    onSelectAgent,
    onSelectGroupOnMap,
    onSelectAgentTypes,
    updateSelectedAgentTelemetry,
    moveSelectedAgentOutOfGroup,
    removeAgentFromSelectedGroup,
    addAgentToSelectedGroup,
    updateAgentInSelectedGroup,
    removeSelectedAgent,
    selectAgentInGroup,
    resetRightDrawerState,
  } = props

  const { status: locationStatus } = useFetchLocations()
  const { status: locationGeofencesStatus } = useFetchGeofencesByLocation(
    currentFloor?.id ?? ''
  )
  const { status: venuesStatus } = useFetchVenues()
  const { status: sensorsStatus } = useFetchSensors()
  const { data: geofences, status: geofenceStatus } = useFetchAllGeofences()
  const { data: mapPresets, status: presetStatus } = useFetchMapPresets()
  const { data: devices, status: deviceStatus } = useFetchDevices()

  const initialized = useMemo(() => {
    return (
      assetsStatus !== FetchingStatus.Request &&
      sensorsStatus !== FetchingStatus.Request &&
      venuesStatus !== FetchingStatus.Request &&
      presetStatus !== FetchingStatus.Request &&
      deviceStatus !== FetchingStatus.Request &&
      geofenceStatus !== FetchingStatus.Request &&
      locationStatus !== FetchingStatus.Request &&
      staffStatus !== FetchingStatus.Request &&
      locationGeofencesStatus !== FetchingStatus.Request
    )
  }, [
    assetsStatus,
    sensorsStatus,
    venuesStatus,
    presetStatus,
    deviceStatus,
    geofenceStatus,
    locationStatus,
    staffStatus,
    locationGeofencesStatus,
  ])

  return (
    <>
      {initialized && venueId ? (
        <SensoryNetworkMap
          venueId={venueId}
          devices={devices}
          geofences={geofences}
          geofencesFetchingStatus={geofenceStatus}
          assetsByLocation={assetsByLocation}
          isAssetsByLocationFetched={isAssetsByLocationFetched}
          assetFilterProvider={assetFilterProvider}
          mapPresets={mapPresets}
          staffByLocaton={staffByLocation}
          isStaffsByLocationFetched={isStaffsByLocationFetched}
          staffFilterProvider={staffFilterProvider}
          agentTypes={agentTypes}
          assetStatus={assetsStatus}
          staffStatus={staffStatus}
          initialized={initialized}
          currentFloor={currentFloor}
          rightDrawerState={rightDrawerState}
          onSelectAgent={onSelectAgent}
          onSelectGroupOnMap={onSelectGroupOnMap}
          onSelectAgentTypes={onSelectAgentTypes}
          updateSelectedAgentTelemetry={updateSelectedAgentTelemetry}
          moveSelectedAgentOutOfGroup={moveSelectedAgentOutOfGroup}
          removeAgentFromSelectedGroup={removeAgentFromSelectedGroup}
          removeSelectedAgent={removeSelectedAgent}
          addAgentToSelectedGroup={addAgentToSelectedGroup}
          updateAgentInSelectedGroup={updateAgentInSelectedGroup}
          selectAgentInGroup={selectAgentInGroup}
          resetRightDrawerState={resetRightDrawerState}
        />
      ) : (
        <LoadingIndicator
          fetching={!initialized}
          noResultsText='Venues not found'
        />
      )}
    </>
  )
}

export default RealTimeMapView
