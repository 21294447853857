export function findItemInArrayAButNotInArrayB(
  arrayA: string[],
  arrayB: string[],
  arrayALength: number,
  arrayBLength: number
) {
  // Store all elements of
  // second array in a hash table
  let s = new Set()
  let itemsInAButNotInB: string[] = []
  for (let i = 0; i < arrayBLength; i++) {
    s.add(arrayB[i])
  }

  // Print all elements of first array
  // that are not present in hash table
  for (let i = 0; i < arrayALength; i++) {
    if (!s.has(arrayA[i])) {
      itemsInAButNotInB.push(arrayA[i])
    }
  }
  return itemsInAButNotInB
}

export function findDuplicateItemsInArray(array: string[]) {
  return array.filter((item, index) => array.indexOf(item) !== index)
}

export function findItemsInArrayAButNotInArrayB<T>(
  arrayA: T[],
  arrayB: T[],
  key?: keyof T
): T[] {
  if (key) {
    const arrayBValuesByKey = arrayB.map((x) => x[key])
    return arrayA.filter((x) => !arrayBValuesByKey.includes(x[key]))
  } else {
    if (!isArrayOfType(arrayA, 'string') && !isArrayOfType(arrayA, 'number')) {
      throw new Error('Missing key for object type!')
    }
    return arrayA.filter((x) => !arrayB.includes(x))
  }
}

function isArrayOfType<T>(array: T[], type: 'string' | 'number'): boolean {
  return array.every((item) => typeof item === type)
}
