import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { VenuesApi } from '../middleware'
import {
  getAllVenuesAction,
  getSyncVenueAction,
  deleteAllVenueAction,
  deleteVenueAction,
} from '../actions/venues'
import { VenueAction } from '../actions'
import { VenuesState } from '../models/venues'

type VenuesEpic = Epic<
  VenueAction,
  VenueAction,
  VenuesState,
  { venuesApi: VenuesApi }
>

export const getAllVenuesEpic: VenuesEpic = (action$, state, { venuesApi }) => {
  return action$.pipe(
    filter(isActionOf(getAllVenuesAction.request)),
    mergeMap(() =>
      from(venuesApi.getAllVenues()).pipe(
        map((venues) => getAllVenuesAction.success(venues)),
        catchError((error) => of(getAllVenuesAction.failure(error)))
      )
    )
  )
}

export const getSyncVenuesEpic: VenuesEpic = (
  action$,
  state,
  { venuesApi }
) => {
  return action$.pipe(
    filter(isActionOf(getSyncVenueAction.request)),
    mergeMap((action) =>
      from(venuesApi.getSyncVenues(action.payload)).pipe(
        map((syncVenueResults) => getSyncVenueAction.success(syncVenueResults)),
        catchError((error) => of(getSyncVenueAction.failure(error)))
      )
    )
  )
}

export const deleteVenuesEpic: VenuesEpic = (action$, state, { venuesApi }) => {
  return action$.pipe(
    filter(isActionOf(deleteVenueAction.request)),
    mergeMap((action) =>
      from(venuesApi.deleteVenues(action.payload)).pipe(
        map((x) => deleteVenueAction.success(x)),
        catchError((error) => of(deleteVenueAction.failure(error)))
      )
    )
  )
}

export const deleteAllVenuesEpic: VenuesEpic = (
  action$,
  state,
  { venuesApi }
) => {
  return action$.pipe(
    filter(isActionOf(deleteAllVenueAction.request)),
    mergeMap(() =>
      from(venuesApi.deleteAllVenues()).pipe(
        map((x) => deleteAllVenueAction.success(x)),
        catchError((error) => of(deleteAllVenueAction.failure(error)))
      )
    )
  )
}
