import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import midmarkLogo from '../../assets/Midmark-logo-48.png'

const useStyles = makeStyles({
  root: {
    maxWidth: 500,
    minHeight: '25vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
})

const PageNotFound = () => {
  const classes = useStyles()
  return (
    <Grid
      container
      spacing={10}
      direction='column'
      alignItems='center'
      justifyContent='center'
      style={{ minHeight: '100vh' }}
    >
      <Card className={classes.root}>
        <CardActionArea>
          <CardContent>
            <img src={midmarkLogo} width='72' alt='logo' />
          </CardContent>
          <CardContent>
            <Typography gutterBottom variant='h5' component='h2'>
              Hmmmm...You seem to have fallen off the map. Press the BACK button
              on your browser
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  )
}
export default PageNotFound
