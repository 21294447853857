import {
  buildBaseInpixonAssetConfigsForNewAgent,
  calculateAssetPositionCoords,
  getDefaultConfidenceBubble,
} from '.'
import { IconType } from '../../models'
import {
  BaseInpixonAssetConfigOptions,
  InpixonAssetAgentCreateOptions,
  InpixonAssetConfig,
  InpixonAssetGroupCreateOptions,
} from '../../models/inpixonMap'
import { colors } from '../../styles'
import { buildGroupIconUrl, getIconWidth } from '../inpixonIcons'

export const defaultAgentIconHeight = 4
export const defaultGroupIconHeight = 10

export function preserveWhiteSpace(text: string): string {
  return text ? text.replace(/ /g, '\u00a0') : text
}

/**
 * Append default values to base configs and added Inpixon Asset Type based on IconType
 * The Inpixon Asset `type` prop is used to retrieve create asset/icon on the map. That way,
 * we don't need to keep track of the asset's ids.
 * @param configOptions
 * @param iconType Asset/Staff/Group
 * @param typeId trackingId for Asset/Staff and geofenceId for Group IconType
 * @returns inpixon asset configs ready for asset creation
 */
export function appendDefaultInpixonAssetConfigs(
  configOptions: BaseInpixonAssetConfigOptions,
  iconType: IconType,
  typeId: string
): InpixonAssetConfig {
  const config: InpixonAssetConfig = {
    ...configOptions,
    name: preserveWhiteSpace(configOptions.name),
    width: configOptions.width ?? getIconWidth(iconType),
    snapToGrid:
      configOptions.snapToGrid !== undefined ? configOptions.snapToGrid : true,
    scaleWithMap: true,
    rotateWithMap: false,
    type: `${iconType.toString()}_${typeId}`,
    iconColor: colors.white,
  }
  return config
}

/**
 * Build Inpixon Asset config values for a new group.
 * The generated config can then be used to create an Inpixon asset(icon) on the map
 * @param groupToCreate options used to build config values
 * @param useGeofenceCentroid
 * @param iconScale
 * @param geofenceInstances
 * @param jibestreamController
 * @returns Inpixon Asset configs that can be used for creating Inpixon asset(icon) on the map
 */
export function buildInpixonAssetConfigsForNewGroup(
  groupToCreate: InpixonAssetGroupCreateOptions,
  useGeofenceCentroid: boolean,
  iconScale: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  geofenceInstances: any[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  jibestreamController: any
): InpixonAssetConfig {
  const confidenceBubbleConfig = getDefaultConfidenceBubble()
  const positionCoords = calculateAssetPositionCoords(
    jibestreamController,
    geofenceInstances,
    groupToCreate.lat,
    groupToCreate.lon,
    groupToCreate.mapId,
    useGeofenceCentroid
  )
  const url = buildGroupIconUrl(groupToCreate.groupCount, iconScale, {
    hasActiveDuress: groupToCreate.hasActiveDuress,
    hasActiveAssist: groupToCreate.hasActiveAssist,
    isSelected: groupToCreate.isSelected,
  })
  const config: BaseInpixonAssetConfigOptions = {
    id: groupToCreate.geoFenceId,
    position: [positionCoords[0], positionCoords[1]],
    height: defaultGroupIconHeight,
    name: groupToCreate.groupCount.toString(),
    url,
    snapToGrid: false,
    ...confidenceBubbleConfig,
  }

  const inpixonGroupedAssetConfig = appendDefaultInpixonAssetConfigs(
    config,
    IconType.Group,
    groupToCreate.geoFenceId.toString()
  )
  return inpixonGroupedAssetConfig
}

export function buildInpixonAssetConfigsForNewGroups(
  groupsToCreate: InpixonAssetGroupCreateOptions[],
  useGeofenceCentroid: boolean,
  iconScale: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  geofenceInstances: any[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  jibestreamController: any
): InpixonAssetConfig[] {
  const inpixonAssetConfigs: InpixonAssetConfig[] = []
  groupsToCreate.forEach((inpixonAssetGroup) => {
    const inpixonGroupedAssetConfig = buildInpixonAssetConfigsForNewGroup(
      inpixonAssetGroup,
      useGeofenceCentroid,
      iconScale,
      geofenceInstances,
      jibestreamController
    )
    inpixonAssetConfigs.push(inpixonGroupedAssetConfig)
  })
  return inpixonAssetConfigs
}

export function buildInpixonAssetConfigsForNewAgents(
  agentsToCreate: InpixonAssetAgentCreateOptions[],
  inpixonAssetId: number,
  useGeofenceCentroid: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  geofenceInstances: any[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  jibestreamController: any
): {
  inpixonAssetConfigs: InpixonAssetConfig[]
  nextInpixonAssetId: number
} {
  const inpixonAssetConfigs: InpixonAssetConfig[] = []
  let nextInpixonAssetId = inpixonAssetId
  agentsToCreate.forEach((inpixonAssetAgent) => {
    const { nextInpixonAssetId: updatedInpixonAssetId, inpixonAssetConfig } =
      buildInpixonAssetConfigForNewAgent(
        inpixonAssetAgent,
        nextInpixonAssetId,
        useGeofenceCentroid,
        geofenceInstances,
        jibestreamController
      )
    nextInpixonAssetId = updatedInpixonAssetId
    inpixonAssetConfigs.push(inpixonAssetConfig)
  })
  return {
    inpixonAssetConfigs,
    nextInpixonAssetId,
  }
}

/**
 * Build Inpixon Asset config values for new asset/staff.
 * The generated config can then be used to create an Inpixon asset(icon) on the map
 * @param agentToCreate options used to build config values
 * @param nextInpixonAssetId unique Inpixon Asset Id for each asset(icon) on the map
 * @param useGeofenceCentroid
 * @param geofenceInstances
 * @param jibestreamController
 * @returns Inpixon Asset configs that can be used for creating Inpixon asset(icon) on the map and
 * the next Inpixon Asset Id used for subsequent asset creation
 */
export function buildInpixonAssetConfigForNewAgent(
  agentToCreate: InpixonAssetAgentCreateOptions,
  nextInpixonAssetId: number,
  useGeofenceCentroid: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  geofenceInstances: any[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  jibestreamController: any
): {
  inpixonAssetConfig: InpixonAssetConfig
  nextInpixonAssetId: number
} {
  const baseConfigs = buildBaseInpixonAssetConfigsForNewAgent(
    agentToCreate,
    nextInpixonAssetId,
    geofenceInstances,
    jibestreamController,
    useGeofenceCentroid
  )
  const inpixonAssetConfig = appendDefaultInpixonAssetConfigs(
    baseConfigs,
    agentToCreate.iconType,
    agentToCreate.trackingId
  )
  return {
    inpixonAssetConfig,
    nextInpixonAssetId: nextInpixonAssetId + 1,
  }
}
