import { useEffect, useRef, useState } from 'react'
import { Criteria } from '../../filters'
import {
  combineFetchedBadgeTelemetriesByAgentTypes,
  filterTelemetries,
  shouldCalculateInitialMapRender,
  sortInitialFetchedTelemetries,
} from '../../helpers/telemetry'
import {
  Asset,
  FetchingStatus,
  Geofences,
  Staff,
  StaffEvent,
} from '../../models'
import { MapRenderByGeofence } from '../../models/realtimeMap'
import { TelemetryByAgentType } from '../../models/telemetry'

/**
 * Calculate initial map render using badge telemetries from fetched assets/staffs.
 * Filter telemetries to render based on agent types, filter query, and search criteria
 * @param assetData
 * @param staffData
 * @param featureFlagData
 * @param geofenceData
 * @param agentTypes
 * @param assetFilterCriteria asset filter query and search criteria
 * @param staffFilterCriteria staff filter query and search criteria
 * @param mapId
 * @param setInitialAgentTelemetryState
 * @returns initialMapRender to render on the map and isInitialLoaded flag
 */
export function useFetchInitialMapTelemetry(
  assetData: {
    fetchedAssetsByLocation: Asset[]
    isAssetsByLocationFetched: boolean
  },
  staffData: {
    fetchedStaffsByLocation: Staff[]
    isStaffsByLocationFetched: boolean
    allDuressEvents: StaffEvent[]
    allAssistEvents: StaffEvent[]
    staffEventFetchingStatus: FetchingStatus | undefined
  },
  featureFlagData: {
    staffDuressAndAssistFeatureFlagEnabled: boolean
    featureFlagFetchingStatus: FetchingStatus | undefined
  },
  geofenceData: {
    geofencesFetchingStatus: FetchingStatus | undefined
    geofences: Geofences
  },
  agentTypes: string[],
  assetFilterCriteria: Array<Criteria<Asset>>,
  staffFilterCriteria: Array<Criteria<Staff>>,
  mapId: number | undefined,
  filteredDuressLoaded: boolean,
  setInitialAgentTelemetryState: (
    agentTelemetries: TelemetryByAgentType
  ) => void
): {
  initialMapRender: MapRenderByGeofence | undefined
  isInitialLoaded: boolean
} {
  const { fetchedAssetsByLocation, isAssetsByLocationFetched } = assetData
  const {
    fetchedStaffsByLocation,
    isStaffsByLocationFetched,
    allDuressEvents,
    allAssistEvents,
    staffEventFetchingStatus,
  } = staffData
  const { staffDuressAndAssistFeatureFlagEnabled, featureFlagFetchingStatus } =
    featureFlagData
  const { geofences, geofencesFetchingStatus } = geofenceData

  const [initialMapRender, setInitialMapRender] = useState<
    MapRenderByGeofence | undefined
  >(undefined)

  const allDuressEventsRef = useRef<StaffEvent[]>([])
  const allAssistEventsRef = useRef<StaffEvent[]>([])
  const agentTypesRef = useRef<string[]>(agentTypes)
  const staffDuressAndAssistFeatureFlagEnabledRef = useRef<boolean>(false)
  const geofencesRef = useRef<Geofences>({})
  const isInitialLoadedRef = useRef<boolean>(false)

  allDuressEventsRef.current = allDuressEvents
  allAssistEventsRef.current = allAssistEvents
  agentTypesRef.current = agentTypes
  staffDuressAndAssistFeatureFlagEnabledRef.current =
    staffDuressAndAssistFeatureFlagEnabled
  geofencesRef.current = geofences

  useEffect(() => {
    if (
      !shouldCalculateInitialMapRender(
        isAssetsByLocationFetched,
        isStaffsByLocationFetched,
        featureFlagFetchingStatus,
        geofencesFetchingStatus,
        staffEventFetchingStatus,
        mapId,
        filteredDuressLoaded,
        isInitialLoadedRef.current
      )
    ) {
      return
    }
    isInitialLoadedRef.current = true
    const agentTelemetries = combineFetchedBadgeTelemetriesByAgentTypes(
      fetchedAssetsByLocation,
      fetchedStaffsByLocation,
      mapId,
      allDuressEventsRef.current,
      allAssistEventsRef.current,
      staffDuressAndAssistFeatureFlagEnabledRef.current
    )
    setInitialAgentTelemetryState(agentTelemetries)
    const telemetriesToRenderOnMap = filterTelemetries(
      agentTelemetries,
      fetchedAssetsByLocation,
      fetchedStaffsByLocation,
      agentTypesRef.current,
      assetFilterCriteria,
      staffFilterCriteria
    )
    const initialMapRendering = sortInitialFetchedTelemetries(
      telemetriesToRenderOnMap,
      geofencesRef.current
    )
    setInitialMapRender(initialMapRendering)
  }, [
    fetchedAssetsByLocation,
    isAssetsByLocationFetched,
    mapId,
    fetchedStaffsByLocation,
    isStaffsByLocationFetched,
    filteredDuressLoaded,
    featureFlagFetchingStatus,
    staffEventFetchingStatus,
    geofencesFetchingStatus,
    assetFilterCriteria,
    staffFilterCriteria,
    setInitialAgentTelemetryState,
  ])

  return {
    initialMapRender,
    isInitialLoaded: isInitialLoadedRef.current,
  }
}
