import { combineReducers } from 'redux'
import jibestreamDestinationReducer from './destination'
import jibestreamUserReducer from './user'
import jibestreamVenueReducer from './venue'
import { StateType } from 'typesafe-actions'

const jibestreamRootReducer = combineReducers({
  destinations: jibestreamDestinationReducer,
  users: jibestreamUserReducer,
  venues: jibestreamVenueReducer,
})

export type JibestreamRootState = StateType<typeof jibestreamRootReducer>

export default jibestreamRootReducer
