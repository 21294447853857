import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import QuickActionAvatarCard, { IconType } from '../Cards/QuickActionCard'
import { usePermissionCheck } from '../../hooks'
import { PermissionsEnum } from '../../models'

const useStyles = makeStyles(() =>
  createStyles({
    buildingTextContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      height: 68,
      marginTop: 13,
    },
    actionName: {
      fontSize: 12,
    },
    centerButton: {
      marginLeft: 12,
      marginRight: 12,
    },
    leftButton: {
      marginLeft: 12,
    },
    rightButton: {
      marginRight: 12,
    },
  })
)

interface Props {
  onEditUser: () => void
}

function UserQuickActionButtons(props: Props): React.ReactElement {
  const { onEditUser } = props
  const classes = useStyles()
  const hasUserWritePermission = usePermissionCheck([
    PermissionsEnum.UsersWrite,
  ])

  return (
    <div className={classes.buildingTextContainer}>
      <div className={classes.leftButton}>
        <QuickActionAvatarCard
          cardText={'Edit User'}
          isDisabled={!hasUserWritePermission}
          cardIconType={IconType.EditOutlined}
          onActionAreaClick={onEditUser}
        ></QuickActionAvatarCard>
      </div>
    </div>
  )
}

export default UserQuickActionButtons
