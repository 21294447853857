import jmap from 'jmap.js'
import { renderCurrentMapViewControl } from '.'

export function onZoomComplete(control: any) {
  {
    // this forces point labels to re-check their rendering conditions
    // setVisiblePointLabelLimit = (displayModeFont: jmap.Font, maxLabels?: number): void
    control.setVisiblePointLabelLimit(
      new jmap.Font({
        align: 'left',
        fontSize: '12px',
        fontFamily: 'Ubuntu',
        fill: '#000000',
      })
    )

    renderCurrentMapViewControl(control)
  }
}
