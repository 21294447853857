import { useMemo } from 'react'
import { useLocation } from 'react-router'
import { useParams } from 'react-router-dom'
export type ParamsMap = { [key: string]: string }
export interface RouteAndSearchParams {
  urlSearchParams: URLSearchParams
  queryParams: ParamsMap
  routeParams: ParamsMap
}

export const parseSearchParams = (searchParams: URLSearchParams): ParamsMap =>
  [...searchParams.entries()].reduce(
    (map: Record<string, string>, [key, value]) => ({
      ...map,
      [key]: value,
    }),
    {}
  )

const useUrlParams = (): RouteAndSearchParams => {
  const { search } = useLocation()
  const routeParams = useParams()

  const urlSearchParams = useMemo((): URLSearchParams => {
    const searchParams = new URLSearchParams(search)
    return searchParams
  }, [search])

  const queryParams = useMemo(
    () => parseSearchParams(urlSearchParams),
    [urlSearchParams]
  )

  return {
    urlSearchParams,
    routeParams,
    queryParams,
  }
}

export default useUrlParams
