import { getType } from 'typesafe-actions'
import { CurrentLocationAction } from '../actions'
import { setCurrentLocationAction } from '../actions/currentLocation'
import { Location } from '../models'

export default (
  state: Location | null = null,
  action: CurrentLocationAction
): Location | null => {
  switch (action.type) {
    case getType(setCurrentLocationAction):
      return action.payload
    default:
      return state
  }
}
