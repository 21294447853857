import {
  parseSettings,
  stringifySettings,
  UserSettings,
  UserSettingsType,
} from '../models'
import { BaseService } from './baseService'
import { StringifiedUserSettings } from '../models'

export class UserSettingsApi extends BaseService {
  constructor() {
    super({ entityType: 'userSetting' })
  }

  route = 'users'
  settingsRoute = 'settings'

  async getUserSettings(): Promise<UserSettings> {
    const stringifiedSettings = await this.get<StringifiedUserSettings>(
      `${this.route}/${this.settingsRoute}`
    )
    return parseSettings(stringifiedSettings)
  }

  async postUserSettings(userSettings: UserSettings): Promise<UserSettings> {
    const stringifiedSettings = await this.post<StringifiedUserSettings>(
      `${this.route}/${this.settingsRoute}`,
      stringifySettings(userSettings)
    )
    return parseSettings(stringifiedSettings)
  }

  async patchUserSettings(userSettings: UserSettings): Promise<UserSettings> {
    const stringifiedSettings = await this.patch<StringifiedUserSettings>(
      `${this.route}/${this.settingsRoute}`,
      stringifySettings(userSettings)
    )
    return parseSettings(stringifiedSettings)
  }

  async deleteUserSetting(
    settingType: UserSettingsType
  ): Promise<UserSettings> {
    const stringifiedSettings = await this.delete<StringifiedUserSettings>(
      `${this.route}/${this.settingsRoute}/${settingType}`
    )
    return parseSettings(stringifiedSettings)
  }
}
