import { getType } from 'typesafe-actions'
import { BuildingAction } from '../actions'
import { getAllBuildingsAction } from '../actions/buildings'
import { Building, Buildings, BuildingsState } from '../models'
import { FetchingStatus } from '../models/fetchingStatus'
import { mergeRecords, setFetchingStatus } from '../utils'

const initialState: BuildingsState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

export default (
  state: BuildingsState = initialState,
  action: BuildingAction
): BuildingsState => {
  switch (action.type) {
    case getType(getAllBuildingsAction.success):
      return {
        data: mergeRecords<Buildings, Building, 'id'>(
          state.data,
          action.payload,
          'id'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getAllBuildingsAction.request):
      return setFetchingStatus<Buildings>(state, FetchingStatus.Request)
    case getType(getAllBuildingsAction.failure):
      return setFetchingStatus<Buildings>(state, FetchingStatus.Failure)
    default:
      return state
  }
}
