import { useEffect, useState } from 'react'
import AssetKit from '@jibestream-dev/jmap-asset-kit'
import { AssetKitInstance } from '../../models'

export function useAssetKit(
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamControl: any,
  loaded: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): AssetKitInstance | undefined {
  const [assetKit, setAssetKit] = useState<AssetKitInstance>()

  useEffect(() => {
    if (loaded && jibestreamControl) {
      const newAssetKit = new AssetKit(jibestreamControl) as AssetKitInstance
      setAssetKit(newAssetKit)
    }
  }, [loaded, jibestreamControl])

  useEffect(() => {
    return () => {
      setAssetKit(undefined)
    }
  }, [])

  return assetKit
}
