import React, { ReactNode, useEffect, useState } from 'react'
import { ToggleButton } from '.'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { useNavigate } from 'react-router-dom'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { PermissionsEnum } from '../../models'
export interface NavItem<TView extends string = string> {
  view: TView
  icon?: ReactNode
  label?: string
  route?: string
  visible?: boolean
  permissions?: PermissionsEnum[]
}

interface Props<TView extends string = string> {
  onViewChange?: (view: TView, item: NavItem<TView>) => void
  view?: TView
  defaultView?: TView
  items: NavItem<TView>[]
}

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      '&:not(:only-child)': {
        paddingLeft: 8,
      },
    },
  })
)
const PageViewNav = <TView extends string = string>(
  props: Props<TView>
): React.ReactElement => {
  const { items, view, defaultView, onViewChange } = props
  const [activeView, setActiveView] = useState(defaultView)
  const history = useNavigate()

  const findItem = (view: TView): NavItem<TView> | undefined => {
    return items.find((e) => {
      return e.view === view
    })
  }

  const navigateToItem = (navItem: NavItem<TView>): void => {
    if (navItem.route) {
      history(navItem.route)
    } else {
      const searchParams = new URLSearchParams({
        view: navItem.view,
      })
      history({
        search: searchParams.toString(),
      })
    }
  }

  const handleViewChange = (view: TView): void => {
    if (activeView === view) {
      return
    }

    const navItem = findItem(view)
    if (navItem) {
      setActiveView(view)
      onViewChange && onViewChange(view, navItem)
      navigateToItem(navItem)
    }
  }

  useEffect(() => {
    if (view && view !== activeView) {
      setActiveView(view)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view])
  const classes = useStyles()

  return (
    <ToggleButtonGroup
      aria-label='Toggle View'
      defaultValue={activeView ?? defaultView ?? ''}
      value={activeView ?? defaultView ?? ''}
      exclusive
      onChange={(ev, nextView): void => handleViewChange(nextView)}
      size='small'
    >
      {items
        .filter((item) => item.visible ?? true)
        .map((item) => {
          const pageSelected = item.view === activeView ? true : false
          return (
            <ToggleButton
              aria-label={item.view}
              key={item.view}
              value={item.view}
              selected={pageSelected}
              disabled={pageSelected}
            >
              {item.icon && item.icon}
              {item.label && <div className={classes.label}>{item.label}</div>}
            </ToggleButton>
          )
        })}
    </ToggleButtonGroup>
  )
}

export default PageViewNav
