import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { UserPreferencesAction } from '../actions'
import {
  getUserPreferencesAction,
  putUserPreferencesAction,
} from '../actions/userPreferences'
import { UserPreferencesApi } from '../middleware'
import { UserPreferences, UserPreferencesState } from '../models'

type UserPreferencesEpic = Epic<
  UserPreferencesAction,
  UserPreferencesAction,
  UserPreferencesState,
  { userPreferencesApi: UserPreferencesApi }
>

export const getUserPreferencesEpic: UserPreferencesEpic = (
  action$,
  state$,
  { userPreferencesApi }
) => {
  return action$.pipe(
    filter(isActionOf(getUserPreferencesAction.request)),
    mergeMap(() =>
      from(userPreferencesApi.getUserPreferences()).pipe(
        map((userPreferences) =>
          getUserPreferencesAction.success(userPreferences)
        ),
        catchError((error) => of(getUserPreferencesAction.failure(error)))
      )
    )
  )
}

export const putUserPreferencesEpic: UserPreferencesEpic = (
  action$,
  state$,
  { userPreferencesApi }
) => {
  return action$.pipe(
    filter(isActionOf(putUserPreferencesAction.request)),
    mergeMap((action) =>
      from(userPreferencesApi.putUserPreferences(action.payload)).pipe(
        map((payload: UserPreferences) =>
          putUserPreferencesAction.success(payload)
        ),
        catchError((error) => of(putUserPreferencesAction.failure(error)))
      )
    )
  )
}
