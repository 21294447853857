import React, { ReactNode } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { Typography } from '@mui/material'

export interface Props {
  color?: 'inherit' | 'primary' | 'secondary'
  size?: number
  margin?: number
  fetching?: boolean
  noResultsText?: ReactNode | string
}

const LoadingIndicator = (props: Props) => {
  const {
    color = 'primary',
    size = 40,
    margin = 40,
    fetching = true,
    noResultsText = 'No Results',
  } = props

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {fetching ? (
        <CircularProgress style={{ margin }} size={size} color={color} />
      ) : typeof noResultsText === 'string' ? (
        <Typography>{noResultsText}</Typography>
      ) : (
        noResultsText
      )}
    </div>
  )
}

export default LoadingIndicator
