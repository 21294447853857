import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { ReleaseApi } from '../middleware'
import { ReleaseAction } from '../actions'
import { ReleasesState } from '../models/release'
import {
  createReleaseAction,
  getAllReleasesAction,
  getPastReleasesAction,
  getUpcomingReleaseAction,
  updateReleaseAction,
} from '../actions/release'

type ReleasesEpic = Epic<
  ReleaseAction,
  ReleaseAction,
  ReleasesState,
  { releaseApi: ReleaseApi }
>

export const getUpcomingReleaseEpic: ReleasesEpic = (
  action$,
  state,
  { releaseApi }
) => {
  return action$.pipe(
    filter(isActionOf(getUpcomingReleaseAction.request)),
    mergeMap((action) =>
      from(releaseApi.getNextRelease(action.payload)).pipe(
        map((release) => getUpcomingReleaseAction.success(release)),
        catchError((error) => of(getUpcomingReleaseAction.failure(error)))
      )
    )
  )
}

export const getPastReleasesEpic: ReleasesEpic = (
  action$,
  state,
  { releaseApi }
) => {
  return action$.pipe(
    filter(isActionOf(getPastReleasesAction.request)),
    mergeMap((action) =>
      from(releaseApi.getNewReleases(action.payload)).pipe(
        map((releases) => getPastReleasesAction.success(releases)),
        catchError((error) => of(getPastReleasesAction.failure(error)))
      )
    )
  )
}

export const getAllReleasesEpic: ReleasesEpic = (
  action$,
  state,
  { releaseApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllReleasesAction.request)),
    mergeMap((action) =>
      from(releaseApi.getAllReleases()).pipe(
        map((releases) => getAllReleasesAction.success(releases)),
        catchError((error) => of(getAllReleasesAction.failure(error)))
      )
    )
  )
}

export const createReleasesEpic: ReleasesEpic = (
  action$,
  state,
  { releaseApi }
) => {
  return action$.pipe(
    filter(isActionOf(createReleaseAction.request)),
    mergeMap((action) =>
      from(releaseApi.createRelease(action.payload)).pipe(
        map((releases) => createReleaseAction.success(releases)),
        catchError((error) => of(createReleaseAction.failure(error)))
      )
    )
  )
}

export const updateReleasesEpic: ReleasesEpic = (
  action$,
  state,
  { releaseApi }
) => {
  return action$.pipe(
    filter(isActionOf(updateReleaseAction.request)),
    mergeMap((action) =>
      from(releaseApi.updateRelease(action.payload)).pipe(
        map((releases) => updateReleaseAction.success(releases)),
        catchError((error) => of(updateReleaseAction.failure(error)))
      )
    )
  )
}
