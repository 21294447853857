import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFeatureFlagByLabelAction } from '../../actions/featureFlags'
import { shouldDispatch } from '../../helpers'
import { FeatureFlags, FeatureFlagState } from '../../models'
import { EntityResult } from '../useEntities'

const useFetchFeatureFlags = (
  label: string,
  enabled = true
): EntityResult<FeatureFlags> => {
  const dispatch = useDispatch()

  const { data, status, lastRefreshTime } = useSelector(
    ({ featureFlags }: { featureFlags: FeatureFlagState }) => featureFlags
  )

  useEffect(() => {
    if (enabled && label && shouldDispatch(status, lastRefreshTime)) {
      dispatch(getFeatureFlagByLabelAction.request(label))
    }
  }, [enabled, label, status, lastRefreshTime])

  return { data, status }
}

export { useFetchFeatureFlags }
