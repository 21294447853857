import { Close } from '@mui/icons-material'
import { IconButton, Link, Typography } from '@mui/material'
import { WithStyles, createStyles, withStyles } from '@mui/styles'
import React, { ReactElement } from 'react'
import { flexLayout } from '../../../styles'

export interface FilterDrawerHeaderProps {
  onCloseDrawer: () => void
  clearFilters: () => void
}

const styles = createStyles({
  root: {
    ...flexLayout({
      direction: 'row',
      align: ['flex-start', 'center'],
      wrap: false,
    }),
  },
  title: {
    flex: '1 1 auto',
    fontWeight: 'bold',
  },
  actionLink: {
    marginLeft: 8,
    cursor: 'pointer',
    fontSize: '12px',
  },
})

const FilterDrawerHeader = (
  props: FilterDrawerHeaderProps & WithStyles<typeof styles>
): ReactElement => {
  const { onCloseDrawer, clearFilters, classes } = props

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant='h6'>
        Filters
        <Link
          className={classes.actionLink}
          variant='body2'
          onClick={clearFilters}
          underline='none'
        >
          Clear All
        </Link>
      </Typography>

      <IconButton onClick={onCloseDrawer} size='large'>
        <Close />
      </IconButton>
    </div>
  )
}

export default withStyles(styles, { name: 'FilterHeader' })(FilterDrawerHeader)
