import React, { ReactNode, forwardRef } from 'react'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { CommonPropsWithStyles } from '.'

const styles = createStyles({
  root: {
    height: '100%',
    flex: '1 1 100%',
    overflow: 'auto',
    position: 'relative',
  },
})

interface Props extends CommonPropsWithStyles<typeof styles> {
  children: ReactNode
}

// eslint-disable-next-line react/display-name
const TableScroll = forwardRef<
  HTMLDivElement,
  Props
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
>((props: Props, ref: any) => {
  const { classes, children, ...other } = props
  return (
    <div ref={ref} className={classes.root} {...other}>
      {children}
    </div>
  )
})

export default withStyles(styles, { name: 'EpicTableScroll' })(TableScroll)
