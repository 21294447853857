import { useEffect, useState } from 'react'
import { getAllAlertsAction } from '../../actions/alerts'
import { mapLocationAncestors } from '../../helpers/alert'
import { allUnits, FetchingStatus } from '../../models'
import { Alerts, AlertsState } from '../../models/alert'
import { QualifierType, LocationChangeType } from '../../models/alertQualifier'
import { RouteLocationType } from '../../models/locationRouter'
import { useEntities } from '../useEntities'
import { useFetchLocations } from './useFetchLocations'
import { useFetchUnits } from './useFetchUnits'
import { useFetchAssetTypes } from '../useFetchAssetTypes'

const useFetchAlerts = () => {
  const {
    data: alerts,
    status: alertsFetchingStatus,
    error,
  } = useEntities<AlertsState, Alerts>(
    () => getAllAlertsAction.request(),
    (state: any) => state.alerts, // eslint-disable-line @typescript-eslint/no-explicit-any
    (status: FetchingStatus | undefined, lastRefreshTime: Date | undefined) =>
      !lastRefreshTime
  )
  const { data: locations } = useFetchLocations()
  const { data: units } = useFetchUnits()
  const { assetTypes } = useFetchAssetTypes()

  const [formattedAlerts, setFormattedAlerts] = useState<Alerts>(alerts)
  const [locationLoaded, setLocationLoaded] = useState<boolean>(false)
  const [unitLoaded, setUnitLoaded] = useState<boolean>(false)
  const [locationChangeLoaded, setLocationChangeLoaded] =
    useState<boolean>(false)
  const [assetTypeLoaded, setAssetTypeLoaded] = useState<boolean>(false)

  useEffect(() => {
    if (alertsFetchingStatus === FetchingStatus.Success) {
      setFormattedAlerts(JSON.parse(JSON.stringify(alerts)))
    }
  }, [alerts, alertsFetchingStatus, setFormattedAlerts])

  useEffect(() => {
    if (
      Object.values(locations).length > 0 &&
      alertsFetchingStatus === FetchingStatus.Success &&
      formattedAlerts &&
      Object.keys(formattedAlerts).length > 0
    ) {
      setFormattedAlerts((prevState) => {
        const alertsWithLocations = { ...prevState }
        Object.values(alertsWithLocations).forEach((alert) => {
          const floorLocationQualifier = alert.qualifiers?.find(
            (q) => q.qualifierTypeId === QualifierType.FloorLocation
          )

          if (floorLocationQualifier) {
            const { campus, building, floor } = mapLocationAncestors(
              floorLocationQualifier.ruleValue,
              Object.values(locations)
            )
            alert.locationQualifier = {
              campus: campus,
              building: building,
              floor: floor,
            }
          }

          const locationRouter = alert.locationRouters?.find(
            (x) => x.locationRouterTypeId === RouteLocationType.SelectedLocation
          )
          if (locationRouter) {
            const { campus, building, floor } = mapLocationAncestors(
              locationRouter.ruleValue,
              Object.values(locations)
            )
            alert.locationRouter = {
              campus: campus,
              building: building,
              floor: floor,
            }
          } else {
            alert.locationRouter = {
              campus: undefined,
              building: undefined,
              floor: undefined,
            }
          }
        })
        return alertsWithLocations
      })
      setLocationLoaded(true)
    }
  }, [formattedAlerts, locations, alertsFetchingStatus])

  useEffect(() => {
    if (
      Object.values(units).length > 0 &&
      alertsFetchingStatus === FetchingStatus.Success &&
      formattedAlerts &&
      Object.keys(formattedAlerts).length > 0
    ) {
      setFormattedAlerts((prevState) => {
        const alertsWithUnits = { ...prevState }
        Object.values(alertsWithUnits).forEach((alert) => {
          const unitQualifier = alert.qualifiers?.find(
            (q) => q.qualifierTypeId === QualifierType.Unit
          )
          if (unitQualifier && unitQualifier?.ruleValue !== '') {
            alert.unitQualifier = Object.values(units).find(
              (unit) =>
                unit.id?.toLowerCase() ===
                unitQualifier?.ruleValue.toLowerCase()
            )
          } else {
            alert.unitQualifier = allUnits
          }

          const unitRouter = alert.locationRouters?.find(
            (q) => q.locationRouterTypeId === RouteLocationType.Unit
          )
          if (unitRouter && unitRouter?.ruleValue !== '') {
            alert.unitRouter = Object.values(units).find(
              (unit) =>
                unit.id?.toLowerCase() === unitRouter?.ruleValue.toLowerCase()
            )
          } else {
            alert.unitRouter = allUnits
          }
        })
        setUnitLoaded(true)
        return alertsWithUnits
      })
    }
  }, [formattedAlerts, units, alertsFetchingStatus])

  useEffect(() => {
    if (alertsFetchingStatus === FetchingStatus.Success) {
      setFormattedAlerts((prevState) => {
        const alertsWithLocationChanges = { ...prevState }
        Object.values(alertsWithLocationChanges).forEach((alert) => {
          const alertIdToLocationChange: { [qualifierId: string]: string } = {
            'b5574045-be44-48fb-980b-81c8d4182d3c': LocationChangeType[0],
            'ad670846-4b2c-48da-b332-dadefc0f2144': LocationChangeType[0],
            '6266b159-e30b-4633-aac6-d07bb26feed5': LocationChangeType[1],
            '7acbecac-b63d-4470-b831-4f31dbd038e8': LocationChangeType[1],
          }
          const qualifier = alert.qualifiers?.find(
            (q) => !!alertIdToLocationChange[q.qualifierId]
          )
          if (qualifier) {
            alert.locationChangeQualifier =
              alertIdToLocationChange[qualifier.qualifierId]
          }
        })
        setLocationChangeLoaded(true)
        return alertsWithLocationChanges
      })
    }
  }, [alertsFetchingStatus])

  useEffect(() => {
    if (
      Object.values(assetTypes).length > 0 &&
      alertsFetchingStatus === FetchingStatus.Success
    ) {
      setFormattedAlerts((prevState) => {
        const alertsWithAssetTypes = { ...prevState }
        Object.values(alertsWithAssetTypes).forEach((alert) => {
          const assetTypeQualifier = alert.qualifiers?.find(
            (q) => q.qualifierTypeId === QualifierType.AssetType
          )
          if (assetTypeQualifier && assetTypeQualifier?.ruleValue !== '') {
            alert.assetType = Object.values(assetTypes).find(
              (assetType) =>
                assetType.assetTypeId === +assetTypeQualifier?.ruleValue
            )
          }
        })
        setAssetTypeLoaded(true)
        return alertsWithAssetTypes
      })
    }
  }, [assetTypes, alertsFetchingStatus])

  return {
    alerts,
    alertsFetchingStatus,
    formattedAlerts,
    formattedAlertsLoaded:
      locationLoaded && unitLoaded && locationChangeLoaded && assetTypeLoaded,
    error,
  }
}

export { useFetchAlerts }
