export function getCenterOfBounds(arr: [number[]]): [number, number] {
  const x = arr.map(function (a) {
    return a[0]
  })
  const y = arr.map(function (a) {
    return a[1]
  })
  const minX = Math.min.apply(null, x)
  const maxX = Math.max.apply(null, x)
  const minY = Math.min.apply(null, y)
  const maxY = Math.max.apply(null, y)
  return [(minX + maxX) / 2, (minY + maxY) / 2]
}

/**
 * Calculates whether two line segments, defined by their endpoints (xi, yi) and (xj, yj),
 * intersect with a horizontal line at a specific y coordinate.
 * The calculation is based on the orientation of the line segments relative to the horizontal line.
 * This calculation checks if a line segment intersects a horizontal line at a specific y coordinate
 * by analyzing the orientation of the line segment relative to the horizontal line and
 * calculating the x-coordinate of the intersection point.
 * If the calculated intersection point is to the left of the given x, it's considered an intersection.
 * @param point
 * @param vs
 * @returns
 */
export function isPointInBounds(point: number[], vs: Array<number[]>): boolean {
  // ray-casting algorithm based on
  // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html/pnpoly.html

  const x = point[0]
  const y = point[1]

  let inside = false
  for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    const xi = vs[i][0]
    const yi = vs[i][1]
    const xj = vs[j][0]
    const yj = vs[j][1]

    const intersect =
      yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi
    if (intersect) inside = !inside
  }

  return inside
}
