import { AgentType, TelemetryEventLocation } from '../../models'
import { BaseBadgeTelemetryMessage } from '../../models/telemetry/baseBadgeTelemetry'

export function mapBadgeEventTelemetryToBaseBadgeTelemetry(
  badgeEventTelemetry: TelemetryEventLocation[]
): BaseBadgeTelemetryMessage[] {
  return badgeEventTelemetry.map((telemetry) => {
    const transformedTelemetry: BaseBadgeTelemetryMessage = {
      customerId: telemetry.data.customerId,
      trackingId: telemetry.data.trackingId,
      signalTypeId: telemetry.data.signalTypeId,
      lon: telemetry.data.lon,
      lat: telemetry.data.lat,
      locationUncertainty: telemetry.data.locUncertainty,
      sensorId: telemetry.data.sensorId,
      mapId: telemetry.data.mapId,
      prevMapId: undefined,
      geoFenceId: telemetry.data.geoFenceId,
      timestamp: telemetry.data.timestamp,
      battery: undefined,
      isAgentEventTelemetry: true,
      isLowBattery: undefined,
      agentEvent: {
        agentEventTypeId: telemetry.data.agentEventTypeId,
        agentEventClosedDate: telemetry.data.agentEventClosedDate,
      },
      agent: {
        id: telemetry.data.agentId,
        agentType: AgentType[AgentType.Staff],
        agentTypeId: 0,
        displayName: telemetry.data.displayName,
        asset: null,
        staff: {
          staffTypeId: telemetry.data.staffTypeId,
          staffTypeName: telemetry.data.staffType,
          agentGuid: telemetry.data.agentId,
          displayName: telemetry.data.displayName,
          staffSubTypeId: telemetry.data.staffSubTypeId,
          staffSubTypeName: telemetry.data.staffSubType,
          firstName: '',
          lastName: '',
          email: '',
          userId: '',
          managerId: '',
          iconUrl: '',
          badgeIds: [telemetry.data.trackingId],
          signalTypeIds: [telemetry.data.signalTypeId],
          badgeTelemetry: {
            trackingId: telemetry.data.trackingId,
            customerId: telemetry.data.customerId,
            lat: telemetry.data.lat,
            lon: telemetry.data.lon,
            signalTypeId: telemetry.data.signalTypeId,
            mapId: telemetry.data.mapId,
            locationUncertainty: telemetry.data.locUncertainty,
            sensorId: telemetry.data.sensorId,
            geoFenceId: telemetry.data.geoFenceId,
            timestamp: telemetry.data.timestamp,
            agentType: AgentType.Staff,
          },
        },
      },
    }
    return transformedTelemetry
  })
}
