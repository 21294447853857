import {
  AgentType,
  BadgeCurrentLocation,
  BadgeCurrentLocationUpdate,
  Locations,
  Venues,
  BadgeState,
  AgentSilentUpdatesState,
  AttemptedSilentUpdates,
  Asset,
  Staff,
  FetchingStatus,
  Location,
  SilentRefreshStatus,
  StaffEvent,
} from '../models'
import getGeofenceMapping from '../helpers/inpixon/badgeState'
import { useTelemetrySubscription } from '.'
import { LocationGeofences } from '../models/locationGeofence'
import { getVenueLocationFor } from '../helpers'
import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { silentUpdateAssetAction } from '../actions/assets'
import { silentUpdateStaffAction } from '../actions/staff'
import calculateValidTelemetryNotInState from '../helpers/telemetryNotInState'
import { differenceInSeconds } from 'date-fns'
import { trackSilentUpdateRequest } from '../actions/agentSilentUpdate'
import { useCombinedTelemetry } from './useCombinedTelemetry'

export function useListTelemetry(
  locations: Locations,
  geofences: LocationGeofences,
  venues: Venues,
  assetState: Asset[],
  staffState: Staff[],
  assetStatus: FetchingStatus,
  staffStatus: FetchingStatus,
  activeDuressEvents: StaffEvent[],
  activeAssistEvents: StaffEvent[]
): BadgeCurrentLocationUpdate {
  const [badgeLocationsChangedAt, setBadgeLocationsChangedAt] = useState<Date>(
    new Date()
  )
  const currentLocation = useSelector(
    ({ currentLocation }: { currentLocation: Location }) => currentLocation
  )
  const { badgeTelemetry: baseTelemetry, lastLiveTelemetry } =
    useCombinedTelemetry()

  const dispatch = useDispatch()
  const { enabled: enabled, mapId } = useTelemetrySubscription()

  const silentRefreshStatus = useSelector(
    ({ agentSilentUpdate }: { agentSilentUpdate: AgentSilentUpdatesState }) =>
      agentSilentUpdate.silentRefreshStatus
  )

  const attemptedSilentUpdates: AttemptedSilentUpdates | undefined =
    useSelector(
      ({ agentSilentUpdate }: { agentSilentUpdate: AgentSilentUpdatesState }) =>
        agentSilentUpdate.attemptedSilentUpdates
    )

  const listUpdates = useMemo(() => {
    if (baseTelemetry?.length) {
      const lastKnownLocations: BadgeCurrentLocation[] = []
      const { telemetry } = getGeofenceMapping(
        baseTelemetry,
        AgentType.Asset,
        activeDuressEvents,
        activeAssistEvents,
        enabled
      )
      if (Object.values(telemetry).length) {
        Object.values(telemetry).forEach((message) => {
          const {
            geoFenceId,
            trackingId: badgeId,
            timestamp,
            locationUncertainty,
            customerId,
            lat,
            lon,
            mapId: messageMapId,
            sensorId,
            signalTypeId,
            agent,
          } = message

          const geofence = geofences[geoFenceId]

          // if no location found then move on
          if (!geofence?.locationId) {
            return
          }

          const agentType = agent?.agentTypeId
            ? (AgentType[agent.agentTypeId] as unknown as AgentType)
            : undefined
          const badgeTelemetry: BadgeState = {
            customerId: customerId,
            geoFenceId: geoFenceId,
            lat: lat,
            lon: lon,
            locationUncertainty: locationUncertainty,
            mapId: messageMapId,
            sensorId: sensorId,
            signalTypeId: signalTypeId,
            timestamp: timestamp,
            trackingId: badgeId,
            agentType: agentType,
          }
          const roomLocation = locations[geofence.locationId.toLowerCase()]
          const venueLocation = getVenueLocationFor(roomLocation.id, locations)
          const venue =
            venueLocation &&
            Object.values(venues ?? {}).find(
              (venue) => venue.locationGuid === venueLocation.id
            )
          lastKnownLocations.push({
            badgeId: badgeId?.toString(),
            venueId: venue?.venueId,
            mapId: message.mapId,
            previousMapId: message.prevMapId,
            timestamp,
            accuracy: locationUncertainty,
            currentSignalType: message.signalTypeId,
            agent: message.agent,
            badgeTelemetry: badgeTelemetry,
            roomLocation,
          })
        })
      }

      if (
        lastKnownLocations.length &&
        differenceInSeconds(new Date(), badgeLocationsChangedAt) > 1
      ) {
        return lastKnownLocations
        setBadgeLocationsChangedAt(new Date())
      }

      const staffTelemetry = Object.values(baseTelemetry).filter(
        (t) => t.agent?.agentType === AgentType[AgentType.Staff]
      )
      const assetTelemetry = Object.values(baseTelemetry).filter(
        (t) => t.agent?.agentType === AgentType[AgentType.Asset]
      )

      if (
        assetTelemetry.length > 0 &&
        assetState.length > 0 &&
        mapId &&
        assetStatus !== FetchingStatus.Request
      ) {
        const newAssets = calculateValidTelemetryNotInState(
          assetTelemetry,
          assetState,
          attemptedSilentUpdates,
          AgentType.Asset
        )

        if (newAssets.length > 0) {
          dispatch(
            trackSilentUpdateRequest(newAssets.map((x) => x.agent?.id ?? ''))
          )
          dispatch(
            silentUpdateAssetAction.request({
              locationGuid: currentLocation.id,
              agentGuids: newAssets.map((x) => x.agent?.id ?? ''),
              badgeTelemetry: newAssets,
            })
          )
        }
      }

      if (
        staffTelemetry.length > 0 &&
        staffState.length > 0 &&
        silentRefreshStatus !== SilentRefreshStatus.Request &&
        staffStatus !== FetchingStatus.Request &&
        mapId
      ) {
        const newStaffs = calculateValidTelemetryNotInState(
          staffTelemetry,
          staffState,
          attemptedSilentUpdates,
          AgentType.Staff
        )

        if (newStaffs.length > 0) {
          dispatch(
            trackSilentUpdateRequest(newStaffs.map((x) => x.agent?.id ?? ''))
          )
          dispatch(
            silentUpdateStaffAction.request({
              locationGuid: currentLocation.id,
              agentGuids: newStaffs.map((x) => x.agent?.id ?? ''),
              badgeTelemetry: newStaffs,
            })
          )
        }
      }
    }
  }, [
    assetState,
    assetStatus,
    attemptedSilentUpdates,
    baseTelemetry,
    currentLocation,
    dispatch,
    enabled,
    geofences,
    locations,
    mapId,
    silentRefreshStatus,
    staffState,
    staffStatus,
    venues,
  ])

  return {
    badgeLocations: listUpdates ?? [],
    badgeLocationsChangedAt: badgeLocationsChangedAt,
    lastLiveTelemetry: lastLiveTelemetry,
  }
}
