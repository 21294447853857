import {
  degroupAndCreateNewGroup,
  degroupAndMoveAgent,
  degroupAndUpdateGroup,
  moveAgent,
  moveAgentAndCreateNewGroup,
  moveAgentAndUpdateGroup,
  moveAgentFromGroup,
  moveAgentFromGroupAndCreateNewGroup,
  moveAgentFromGroupAndUpdateGroup,
  processMapUpdatesForNewActiveAssist,
  processMapUpdatesForNewActiveDuress,
  processMapUpdatesForResolvedAgentEvent,
} from '../processRTMapUpdates'
import { AssetKitInstance } from '../../models'
import {
  AgentEventMapUpdateTypes,
  EnteringGeofenceMapUpdateTypes,
  LeavingGeofenceMapUpdateTypes,
  MapRenderByGeofence,
} from '../../models/realtimeMap'
import { BadgeTelemetryMessageWithAgentEvent } from '../../models/telemetry'
import { calculateMapUpdateType } from '../calculateRTMapUpdates'

/**
 * For each telemetries that were previously filtered by agents already rendered on the map,
 * calculate the MapUpdateType and call the corresponding helper method to render the update
 * on the map
 * @param agentTelemetries
 * @param currentMapRender
 * @param assetKit
 * @param jibestreamController
 * @param geofenceInstances
 * @param useGeofenceCentroid
 * @param iconScale
 * @param selectedTrackingId
 * @param selectedGroupId
 * @param assetKitId
 * @param updateSelectedAgent
 * @param updateAgentInSelectedGroup
 * @param addGeofenceHighlight
 * @param removeGeofenceHighlight
 * @returns updated map render state after map rerendering and the next inpixonAssetId to use
 * for subsequent Inpixon Asset creation
 */
export async function processMapUpdatesForAgentsAlreadyOnMap(
  agentTelemetries: BadgeTelemetryMessageWithAgentEvent[],
  currentMapRender: MapRenderByGeofence,
  assetKit: AssetKitInstance,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  geofenceInstances: any[],
  useGeofenceCentroid: boolean,
  iconScale: number,
  selectedTrackingId: string | undefined,
  selectedGroupId: number | undefined,
  assetKitId: number,
  updateSelectedAgent: (
    updatedAgent: BadgeTelemetryMessageWithAgentEvent
  ) => void,
  updateAgentInSelectedGroup: (
    agentTelemetry: BadgeTelemetryMessageWithAgentEvent
  ) => void,
  addGeofenceHighlight: (geofenceId: number) => void,
  removeGeofenceHighlight: (geofenceId: number) => void
): Promise<{
  nextInpixonAssetId: number | undefined
  updatedMapRender: MapRenderByGeofence
}> {
  let nextInpixonAssetId = assetKitId
  let updatedMapRender = currentMapRender
  for (const newAgentTelemetry of agentTelemetries) {
    const updateType = calculateMapUpdateType(
      newAgentTelemetry,
      updatedMapRender
    )
    if (!updateType) {
      if (process.env.DEBUG) {
        console.info(
          'Unable to determine update type. Skip map update for',
          newAgentTelemetry
        )
      }
    } else {
      if (updateType.agentEventUpdateType !== undefined) {
        switch (updateType.agentEventUpdateType) {
          case AgentEventMapUpdateTypes.NewActiveDuress:
            updatedMapRender = processMapUpdatesForNewActiveDuress(
              newAgentTelemetry,
              updatedMapRender,
              assetKit,
              jibestreamController,
              selectedTrackingId,
              selectedGroupId,
              updateAgentInSelectedGroup,
              updateSelectedAgent,
              addGeofenceHighlight
            )
            break
          case AgentEventMapUpdateTypes.NewActiveAssist:
            updatedMapRender = processMapUpdatesForNewActiveAssist(
              newAgentTelemetry,
              updatedMapRender,
              assetKit,
              jibestreamController,
              selectedTrackingId,
              selectedGroupId,
              updateAgentInSelectedGroup,
              updateSelectedAgent
            )
            break
          case AgentEventMapUpdateTypes.ResolvedDuressOrAssist:
            updatedMapRender = processMapUpdatesForResolvedAgentEvent(
              newAgentTelemetry,
              updatedMapRender,
              assetKit,
              jibestreamController,
              selectedTrackingId,
              selectedGroupId,
              updateAgentInSelectedGroup,
              updateSelectedAgent,
              removeGeofenceHighlight
            )
            break
        }
      } else {
        if (updateType.agentMovingBetweenGeofencesUpdate) {
          const { leavingGeofenceUpdateType, enteringGeofenceUpdateType } =
            updateType.agentMovingBetweenGeofencesUpdate
          switch (leavingGeofenceUpdateType) {
            case LeavingGeofenceMapUpdateTypes.MoveAgent:
              switch (enteringGeofenceUpdateType) {
                case EnteringGeofenceMapUpdateTypes.CreateNewGroup:
                  updatedMapRender = await moveAgentAndCreateNewGroup(
                    newAgentTelemetry,
                    updatedMapRender,
                    assetKit,
                    jibestreamController,
                    geofenceInstances,
                    useGeofenceCentroid,
                    iconScale,
                    selectedTrackingId,
                    selectedGroupId,
                    addGeofenceHighlight,
                    removeGeofenceHighlight
                  )
                  break
                case EnteringGeofenceMapUpdateTypes.UpdateGroup:
                  updatedMapRender = await moveAgentAndUpdateGroup(
                    newAgentTelemetry,
                    updatedMapRender,
                    assetKit,
                    jibestreamController,
                    geofenceInstances,
                    useGeofenceCentroid,
                    iconScale,
                    selectedTrackingId,
                    selectedGroupId,
                    addGeofenceHighlight,
                    removeGeofenceHighlight
                  )
                  break
                case EnteringGeofenceMapUpdateTypes.Ungrouped:
                  updatedMapRender = moveAgent(
                    newAgentTelemetry,
                    updatedMapRender,
                    assetKit,
                    jibestreamController,
                    geofenceInstances,
                    useGeofenceCentroid,
                    addGeofenceHighlight,
                    removeGeofenceHighlight
                  )
                  break
              }
              break
            case LeavingGeofenceMapUpdateTypes.Degroup:
              switch (enteringGeofenceUpdateType) {
                case EnteringGeofenceMapUpdateTypes.CreateNewGroup:
                  const degroupAndNewGroupResult =
                    await degroupAndCreateNewGroup(
                      newAgentTelemetry,
                      updatedMapRender,
                      assetKit,
                      jibestreamController,
                      geofenceInstances,
                      useGeofenceCentroid,
                      iconScale,
                      selectedTrackingId,
                      selectedGroupId,
                      nextInpixonAssetId,
                      addGeofenceHighlight,
                      removeGeofenceHighlight
                    )
                  updatedMapRender = degroupAndNewGroupResult.updatedMapRender
                  if (degroupAndNewGroupResult.nextInpixonAssetId) {
                    nextInpixonAssetId =
                      degroupAndNewGroupResult.nextInpixonAssetId
                  }
                  break
                case EnteringGeofenceMapUpdateTypes.UpdateGroup:
                  const degroupAndUpdateGroupResult =
                    await degroupAndUpdateGroup(
                      newAgentTelemetry,
                      updatedMapRender,
                      assetKit,
                      jibestreamController,
                      geofenceInstances,
                      useGeofenceCentroid,
                      iconScale,
                      selectedTrackingId,
                      selectedGroupId,
                      nextInpixonAssetId,
                      addGeofenceHighlight,
                      removeGeofenceHighlight
                    )
                  updatedMapRender =
                    degroupAndUpdateGroupResult.updatedMapRender
                  if (degroupAndUpdateGroupResult.nextInpixonAssetId) {
                    nextInpixonAssetId =
                      degroupAndUpdateGroupResult.nextInpixonAssetId
                  }
                  break
                case EnteringGeofenceMapUpdateTypes.Ungrouped:
                  const degroupAndMoveAgentResult = degroupAndMoveAgent(
                    newAgentTelemetry,
                    updatedMapRender,
                    assetKit,
                    jibestreamController,
                    geofenceInstances,
                    useGeofenceCentroid,
                    iconScale,
                    selectedTrackingId,
                    nextInpixonAssetId,
                    addGeofenceHighlight,
                    removeGeofenceHighlight
                  )
                  updatedMapRender = degroupAndMoveAgentResult.updatedMapRender
                  if (degroupAndMoveAgentResult.nextInpixonAssetId) {
                    nextInpixonAssetId =
                      degroupAndMoveAgentResult.nextInpixonAssetId
                  }
                  break
              }
              break
            case LeavingGeofenceMapUpdateTypes.MoveAgentFromGroup:
              switch (enteringGeofenceUpdateType) {
                case EnteringGeofenceMapUpdateTypes.CreateNewGroup:
                  const moveAgentFromGroupAndNewGroupResult =
                    await moveAgentFromGroupAndCreateNewGroup(
                      newAgentTelemetry,
                      updatedMapRender,
                      assetKit,
                      jibestreamController,
                      geofenceInstances,
                      useGeofenceCentroid,
                      iconScale,
                      selectedTrackingId,
                      selectedGroupId,
                      nextInpixonAssetId,
                      addGeofenceHighlight,
                      removeGeofenceHighlight
                    )
                  updatedMapRender =
                    moveAgentFromGroupAndNewGroupResult.updatedMapRender
                  if (moveAgentFromGroupAndNewGroupResult.nextInpixonAssetId) {
                    nextInpixonAssetId =
                      moveAgentFromGroupAndNewGroupResult.nextInpixonAssetId
                  }
                  break
                case EnteringGeofenceMapUpdateTypes.UpdateGroup:
                  const moveAgentFromGroupAndUpdateGroupResult =
                    await moveAgentFromGroupAndUpdateGroup(
                      newAgentTelemetry,
                      updatedMapRender,
                      assetKit,
                      jibestreamController,
                      geofenceInstances,
                      useGeofenceCentroid,
                      iconScale,
                      selectedTrackingId,
                      selectedGroupId,
                      nextInpixonAssetId,
                      addGeofenceHighlight,
                      removeGeofenceHighlight
                    )
                  updatedMapRender =
                    moveAgentFromGroupAndUpdateGroupResult.updatedMapRender
                  if (
                    moveAgentFromGroupAndUpdateGroupResult.nextInpixonAssetId
                  ) {
                    nextInpixonAssetId =
                      moveAgentFromGroupAndUpdateGroupResult.nextInpixonAssetId
                  }
                  break
                case EnteringGeofenceMapUpdateTypes.Ungrouped:
                  const moveAgentFromGroupResult = moveAgentFromGroup(
                    newAgentTelemetry,
                    updatedMapRender,
                    assetKit,
                    jibestreamController,
                    geofenceInstances,
                    useGeofenceCentroid,
                    iconScale,
                    selectedTrackingId,
                    selectedGroupId,
                    nextInpixonAssetId,
                    addGeofenceHighlight,
                    removeGeofenceHighlight
                  )
                  updatedMapRender = moveAgentFromGroupResult.updatedMapRender
                  if (moveAgentFromGroupResult.nextInpixonAssetId) {
                    nextInpixonAssetId =
                      moveAgentFromGroupResult.nextInpixonAssetId
                  }
                  break
              }
              break
          }
        }
      }
    }
  }
  return {
    nextInpixonAssetId,
    updatedMapRender,
  }
}
