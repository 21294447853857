import { NavigateNext } from '@mui/icons-material'
import {
  Autocomplete,
  Breadcrumbs,
  Fade,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { setRouterPromptIsEnabledStatusAction } from '../../../../actions/routerPrompt'
import { CancelButton, PrimaryButton } from '../../../../components/Buttons'
import RouterPrompt from '../../../../components/Common/RouterPrompt'
import { requiredMessage } from '../../../../components/Forms'
import { PageContent } from '../../../../components/Layout'
import LocationSelectMenu from '../../../../components/LocationSelectMenu/LocationSelectMenu'
import FormSkeleton from '../../../../components/Skeleton/FormSkeleton'
import { mapLocationsWithChildren } from '../../../../helpers/alert'
import {
  useFetchAssetTypes,
  useFetchChannelTypes,
  useFetchSelectableLocations,
  useFetchUnits,
} from '../../../../hooks'
import { useAlertConfigPageReload } from '../../../../hooks/useAlertConfigPageReload'
import { useFetchAlertTypes } from '../../../../hooks/useFetchAlertTypes'
import { useFetchTriggers } from '../../../../hooks/useFetchTriggers'
import {
  AlertChannel,
  AlertConditionFormFields,
  AlertConfigSteps,
  AlertType,
  FetchingStatus,
  FormsState,
  Location,
  ManualFieldError,
  Unit,
  allUnits,
} from '../../../../models'
import { AlertTypes } from '../../../../models/alert'
import {
  LocationChangeType,
  LocationQualifier,
} from '../../../../models/alertQualifier'
import { AlertTrigger } from '../../../../models/alertTrigger'
import { PageLayoutState } from '../../../../models/pageLayout'
import { RouterPromptState } from '../../../../models/routerPrompt'
import { Trigger } from '../../../../models/trigger'
import { inputLabelStyles, useFormStyle } from '../../../../styles'
import { AlertConfigStepperHeaderHeight } from '../../constants'
import useAlertActiveConfig from '../../useAlertActiveConfig'
import AlertConfigStepperHeader from '../AlertConfigStepperHeader'
import { AlertUnitDropDown } from '../Inputs/AlertUnitDropDown'
import AlertConfigRightDrawer from './AlertConfigRightDrawer'
import { formWidth } from './constants'

const useSelectStyles = makeStyles(() =>
  createStyles({
    icon: {
      right: '9px',
    },
    select: {
      minHeight: '19px',
    },
  })
)

const useInputLabelStyles = makeStyles(() => inputLabelStyles)

const useBreadcrumbsStyles = makeStyles(() =>
  createStyles({
    root: {
      paddingRight: '24px',
    },
  })
)

export const AlertConfigConditionPage = () => {
  const { alertTypes, status: alertTypesgStatus } = useFetchAlertTypes()
  const { assetTypes, status: assetTypesStatus } = useFetchAssetTypes()
  const { data: units, status: unitsStatus } = useFetchUnits()
  const { triggers, status: triggersStatus } = useFetchTriggers()
  const { activeConfig, setActiveConfig } = useAlertActiveConfig()
  const [buttonPressedTrigger, setButtonPressedTrigger] =
    useState<Trigger | null>(null)
  const {
    control,
    formState: { isDirty, errors },
    register,
    setError,
    reset,
    clearErrors,
    trigger,
  } = useForm<AlertConditionFormFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: activeConfig.name ?? '',
      description: activeConfig.description ?? '',
      locationQualifier: activeConfig.locationQualifier?.floor?.id ?? '',
      unitQualifer: activeConfig.unitQualifier ?? allUnits,
      badgeQualifier: 'All Badges',
      alertType: activeConfig.alertType ?? 0,
      locationChangeQualifier:
        activeConfig.locationChangeQualifier ?? undefined,
      assetType: activeConfig.assetType ?? undefined,
      trigger: activeConfig.trigger
        ? {
            id: activeConfig.trigger.triggerId,
            name: activeConfig.trigger.triggerName,
            description: activeConfig.trigger.triggerDescription,
            joinTypeId: activeConfig.trigger.joinTypeId,
            ruleValue: activeConfig.trigger.ruleValue,
          }
        : buttonPressedTrigger,
    } as AlertConditionFormFields,
  })

  const activeConfigLoaded = useAlertConfigPageReload(
    activeConfig,
    setActiveConfig
  )
  const { data: selectableLocations } = useFetchSelectableLocations()
  const idn: Location | null = selectableLocations.idn ?? null
  const location = useLocation()
  const history = useNavigate()
  const { channelTypes, status: channelTypesStatus } = useFetchChannelTypes()
  const dispatch = useDispatch()

  const [alertType, setAlertType] = useState<AlertType | null>(
    activeConfig?.alertType ?? null
  )

  const [selectedCampus, setSelectedCampus] = useState<Location | undefined>()
  const [selectedBuilding, setSelectedBuilding] = useState<
    Location | undefined
  >()
  const [selectedFloor, setSelectedFloor] = useState<Location | undefined>()
  const [openLocationSelect, setOpenLocationSelect] = useState<boolean>(false)
  const [locationQualifier, setLocationQualifier] = useState<Location[]>([])
  const [
    isInitialLocationQualifierLoaded,
    setIsInitialLocationQualifierLoaded,
  ] = useState<boolean>(false)
  const [isAssetAlert, setIsAssetAlert] = useState(false)

  const routerPromptState = useSelector(
    ({ routerPrompt }: { routerPrompt: RouterPromptState }) => routerPrompt
  )

  const handleClose = () => {
    setOpenLocationSelect(false)
  }

  const handleOpen = () => {
    setOpenLocationSelect(true)
  }

  const handleNext = () => {
    const path = `${location.pathname.slice(
      0,
      location.pathname.lastIndexOf('/')
    )}`
    history(
      `${path}/${AlertConfigSteps[AlertConfigSteps.CHANNELS].toLowerCase()}`
    )
  }

  const handleCancel = () => {
    history('/admin/alerts')
  }

  useEffect(() => {
    if (!routerPromptState.isEnabled && isDirty) {
      dispatch(setRouterPromptIsEnabledStatusAction(isDirty))
    }
  }, [isDirty])

  const alertFormErrors: ManualFieldError<AlertConditionFormFields>[] =
    useSelector(({ forms }: { forms: FormsState }) => forms.alertEdit.errors)

  const onSubmit = async () => {
    await trigger()
    if (activeConfig.alertType === undefined) {
      setError('alertType', {
        type: 'validate',
        message: 'A type must be selected',
      })
    }

    if (selectedFloor === undefined) {
      setError('locationQualifier', {
        type: 'validate',
        message: 'A floor must be selected',
      })
    }

    if (isAssetAlert && activeConfig.locationChangeQualifier === undefined) {
      setError('locationChangeQualifier', {
        type: 'validate',
        message: 'A type must be selected',
      })
    }

    if (isAssetAlert && activeConfig.assetType === undefined) {
      setError('assetType', {
        type: 'validate',
        message: 'A type must be selected',
      })
    }

    if (Object.values(errors).length > 0) {
      return
    } else {
      handleNext()
    }
  }

  useEffect(() => {
    if (activeConfigLoaded && !isInitialLocationQualifierLoaded) {
      if (!idn || !activeConfig.locationQualifier) {
        return
      }

      const { selectedCampusWithChildren, selectedBuildingWithChildren } =
        mapLocationsWithChildren(idn, activeConfig.locationQualifier)

      if (selectedCampusWithChildren !== undefined) {
        setSelectedCampus(selectedCampusWithChildren)
      }

      if (selectedBuildingWithChildren !== undefined) {
        setSelectedBuilding(selectedBuildingWithChildren)
      }

      setSelectedFloor(activeConfig.locationQualifier?.floor)
      setIsInitialLocationQualifierLoaded(true)
    }
  }, [activeConfig.locationQualifier, idn, activeConfigLoaded])

  useEffect(() => {
    if (activeConfigLoaded) {
      const defaultTrigger = Object.values(triggers).find(
        (x) => x.name === 'ButtonPressed'
      )
      if (defaultTrigger) {
        setButtonPressedTrigger(defaultTrigger)
        const alertTrigger: AlertTrigger = {
          alertId: activeConfig.id,
          triggerId: defaultTrigger.id,
          triggerDescription: defaultTrigger.description,
          triggerName: defaultTrigger.name,
          joinTypeId: 1,
          ruleValue: '',
        }
        setActiveConfig({
          ...activeConfig,
          trigger: alertTrigger,
        })
        reset({
          name: activeConfig.name,
          description: activeConfig.description,
          locationChangeQualifier: activeConfig.locationChangeQualifier,
          unitQualifer: activeConfig.unitQualifier,
          alertType: activeConfig.alertType,
          badgeQualifier: 'All Badges',
          locationQualifier: activeConfig.locationQualifier?.floor?.id,
          trigger: defaultTrigger ?? undefined,
        })
      }
    }
  }, [triggers, activeConfigLoaded])

  useEffect(() => {
    alertFormErrors.forEach(({ errorFieldName, type, message }) =>
      setError(errorFieldName as keyof AlertConditionFormFields, {
        type,
        message,
      })
    )
  }, [alertFormErrors, setError])

  const classes = useFormStyle()
  const selectClasses = useSelectStyles()
  const inputLabelClasses = useInputLabelStyles()
  const breadcrumbsClasses = useBreadcrumbsStyles()

  useEffect(() => {
    register('locationQualifier')
  }, [register])

  useEffect(() => {
    if (!isAssetAlert && activeConfig.alertType?.id === AlertTypes.AssetAlert) {
      setIsAssetAlert(true)
    }
  }, [activeConfig, isAssetAlert])

  useEffect(() => {
    if (triggers && Object.values(triggers)?.length) {
      const locationTrigger = Object.values(triggers).find(
        (x) => x.name === 'LocationChange'
      )
      const buttonTrigger = Object.values(triggers).find(
        (x) => x.name === 'ButtonPressed'
      )
      if (
        locationTrigger &&
        activeConfig.alertType?.id === AlertTypes.AssetAlert &&
        activeConfig.trigger?.triggerId !== locationTrigger?.id
      ) {
        setActiveConfig({
          ...activeConfig,
          trigger: {
            alertId: activeConfig.id,
            triggerId: locationTrigger.id,
            triggerDescription: locationTrigger.description,
            triggerName: locationTrigger.name,
            joinTypeId: 1,
            ruleValue: '',
          },
        })
      } else if (
        buttonTrigger &&
        activeConfig.alertType?.id !== AlertTypes.AssetAlert &&
        activeConfig.trigger?.triggerId !== buttonTrigger?.id
      ) {
        setActiveConfig({
          ...activeConfig,
          trigger: {
            alertId: activeConfig.id,
            triggerId: buttonTrigger.id,
            triggerDescription: buttonTrigger.description,
            triggerName: buttonTrigger.name,
            joinTypeId: 1,
            ruleValue: '',
          },
        })
      }
    }
  }, [activeConfig, setActiveConfig, triggers])

  useEffect(() => {
    if (activeConfigLoaded && isInitialLocationQualifierLoaded) {
      const location = [selectedCampus, selectedBuilding, selectedFloor].filter(
        (x) => x !== undefined
      ) as Location[]
      setLocationQualifier(location)

      if (selectedFloor) {
        clearErrors('locationQualifier')
      }

      if (
        selectedCampus?.id === activeConfig.locationQualifier.campus?.id &&
        selectedBuilding?.id === activeConfig.locationQualifier.building?.id &&
        selectedFloor?.id === activeConfig.locationQualifier.floor?.id
      ) {
        return
      }
      const locationQualifier: LocationQualifier = {
        campus: selectedCampus,
        building: selectedBuilding,
        floor: selectedFloor,
      }
      setActiveConfig({
        ...activeConfig,
        locationQualifier: locationQualifier,
      })
    }
  }, [
    selectedCampus,
    selectedBuilding,
    selectedFloor,
    activeConfigLoaded,
    isInitialLocationQualifierLoaded,
  ])

  useEffect(() => {
    const isReloaded = localStorage.getItem('isReloaded')
    if (isReloaded && +isReloaded) {
      // Reset isReloaded after redirecting user to the Condition page on page reload
      localStorage.setItem('isReloaded', '0')
    }
  }, [])
  const maxHeight = useSelector(
    ({ pageLayout }: { pageLayout: PageLayoutState }) => pageLayout.maxHeight
  )

  return (
    <PageContent
      maxHeight={maxHeight}
      openRightDrawer={true}
      style={{ maxHeight: '100%' }}
      hideScrollbar={true}
      content={
        <>
          <AlertConfigStepperHeader activeStep={AlertConfigSteps.CONDITIONS} />
          <div
            style={{
              overflow: 'auto',
              height: '100%',
              maxHeight: `calc(100% - ${AlertConfigStepperHeaderHeight})`,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                position: 'absolute',
                width: '100%',
                marginTop: '12px',
                paddingRight: '12px',
              }}
            >
              <CancelButton onClick={handleCancel}>Cancel</CancelButton>
              <PrimaryButton onClick={onSubmit}>Next</PrimaryButton>
            </div>

            <form>
              <div
                style={{
                  margin: '22px 0px 10px 24px',
                }}
              >
                <Typography variant='h5'>Alert Builder: Conditions</Typography>
              </div>
              <div
                style={{
                  width: formWidth,
                  margin: '0 24px',
                }}
              >
                {activeConfigLoaded ? (
                  <Controller
                    name='name'
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => {
                      return (
                        <TextField
                          {...field}
                          margin='normal'
                          label='Alert Name'
                          variant='filled'
                          fullWidth
                          required
                          error={!!errors.name}
                          helperText={requiredMessage}
                          inputProps={{
                            maxLength: 50,
                          }}
                          className={classes.inputStyle}
                          onChange={(
                            event: React.ChangeEvent<
                              HTMLTextAreaElement | HTMLInputElement
                            >
                          ) => {
                            const newValue = event.target.value
                            setActiveConfig({
                              ...activeConfig,
                              name: newValue,
                            })
                            field.onChange(newValue)
                            return event.target.value
                          }}
                        />
                      )
                    }}
                  />
                ) : (
                  <FormSkeleton width={formWidth} />
                )}

                {activeConfigLoaded ? (
                  <Controller
                    name='description'
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => {
                      return (
                        <TextField
                          {...field}
                          margin='normal'
                          label='Description'
                          variant='filled'
                          fullWidth
                          required
                          error={!!errors.description}
                          helperText={requiredMessage}
                          className={classes.inputStyle}
                          onChange={(
                            event: React.ChangeEvent<
                              HTMLTextAreaElement | HTMLInputElement
                            >
                          ) => {
                            const newValue = event.target.value
                            setActiveConfig({
                              ...activeConfig,
                              description: newValue,
                            })
                            field.onChange(newValue)
                            return event.target.value
                          }}
                        />
                      )
                    }}
                  />
                ) : (
                  <FormSkeleton width={formWidth} />
                )}
                {activeConfigLoaded ? (
                  <Controller
                    control={control}
                    name='alertType'
                    rules={{ required: true }}
                    render={({ field: { ref, ...field } }) => {
                      return (
                        <Autocomplete
                          {...field}
                          style={{ width: '100%' }}
                          value={activeConfig?.alertType}
                          options={Object.values(alertTypes)}
                          getOptionLabel={(option): string =>
                            option.name ?? option
                          }
                          isOptionEqualToValue={(option, value) => {
                            if (!value) {
                              return true
                            }
                            return option.id === value.id
                          }}
                          clearOnBlur
                          selectOnFocus
                          handleHomeEndKeys
                          loading={alertTypesgStatus === FetchingStatus.Request}
                          onChange={(event, newValue) => {
                            const channels: AlertChannel[] = []
                            if (newValue !== undefined) {
                              setAlertType(newValue)
                            }
                            field.onChange(newValue ?? undefined)
                            if (
                              newValue &&
                              Object.values(channelTypes).length > 0
                            ) {
                              if (newValue.id === AlertTypes.StaffDuress) {
                                const duressBanner: AlertChannel = {
                                  channelTypeId: channelTypes[0].id,
                                }
                                const duressModal: AlertChannel = {
                                  channelTypeId: channelTypes[1].id,
                                }
                                channels.push(duressModal)
                                channels.push(duressBanner)
                                setIsAssetAlert(false)
                              } else if (
                                newValue.id === AlertTypes.StaffAssist
                              ) {
                                const assistModal: AlertChannel = {
                                  channelTypeId: channelTypes[2].id,
                                }
                                channels.push(assistModal)
                                setIsAssetAlert(false)
                              } else {
                                const desktopApp: AlertChannel = {
                                  channelTypeId: channelTypes[4].id,
                                }
                                channels.push(desktopApp)
                                setIsAssetAlert(true)
                              }
                            } else {
                              setIsAssetAlert(false)
                            }
                            setActiveConfig({
                              ...activeConfig,
                              alertType: newValue ?? undefined,
                              channels: channels,
                            })

                            return newValue
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...field}
                              {...params}
                              margin='normal'
                              label='Type'
                              variant='filled'
                              error={!!errors.alertType}
                              required
                              className={classes.inputStyle}
                              helperText={requiredMessage}
                              inputRef={ref}
                            />
                          )}
                        />
                      )
                    }}
                  />
                ) : (
                  <FormSkeleton width={formWidth} />
                )}

                {activeConfig.alertType && (
                  <div>
                    <Typography
                      variant='h6'
                      style={{
                        fontWeight: 'bold',
                        fontSize: '20px',
                      }}
                    >
                      Triggers
                    </Typography>
                    {activeConfigLoaded &&
                    triggersStatus === FetchingStatus.Success ? (
                      <Controller
                        control={control}
                        name='trigger'
                        rules={{ required: true }}
                        render={({ field: { ref, ...field } }) => {
                          return (
                            <Autocomplete
                              {...field}
                              value={{
                                id: activeConfig?.trigger?.triggerId,
                                description:
                                  activeConfig?.trigger?.triggerDescription,
                              }}
                              style={{ width: '100%' }}
                              options={Object.values(triggers)}
                              isOptionEqualToValue={(option, value) => {
                                if (!value) {
                                  return true
                                }
                                return option.id === value.id
                              }}
                              getOptionLabel={(option) =>
                                option?.description ?? ''
                              }
                              selectOnFocus
                              clearOnBlur
                              disabled
                              handleHomeEndKeys
                              onChange={(event, newValue) => {
                                field.onChange(newValue ?? undefined)
                                const trigger = Object.values(triggers).find(
                                  (x) => x.id === newValue?.id
                                )
                                if (trigger) {
                                  const alertTrigger: AlertTrigger = {
                                    alertId: activeConfig.id,
                                    triggerId: trigger?.id,
                                    triggerDescription: trigger?.description,
                                    triggerName: trigger?.name,
                                    joinTypeId: 1,
                                    ruleValue: '',
                                  }

                                  setActiveConfig({
                                    ...activeConfig,
                                    trigger: alertTrigger,
                                  })
                                }

                                return newValue
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...field}
                                  {...params}
                                  margin='normal'
                                  label='Trigger'
                                  variant='filled'
                                  error={!!errors.trigger}
                                  required
                                  className={classes.inputStyle}
                                  helperText={requiredMessage}
                                />
                              )}
                            />
                          )
                        }}
                      />
                    ) : (
                      <FormSkeleton width={formWidth} />
                    )}
                    <Typography
                      variant='h6'
                      style={{
                        fontWeight: 'bold',
                        fontSize: '20px',
                      }}
                    >
                      Qualifiers
                    </Typography>
                    {isAssetAlert && (
                      <div>
                        {activeConfigLoaded ? (
                          <Controller
                            control={control}
                            name='locationChangeQualifier'
                            rules={{ required: true }}
                            render={({ field: { ref, ...field } }) => {
                              return (
                                <Autocomplete
                                  {...field}
                                  style={{ width: '100%' }}
                                  value={activeConfig?.locationChangeQualifier}
                                  isOptionEqualToValue={(option, value) => {
                                    if (value === '') {
                                      return true
                                    }
                                    return option === value
                                  }}
                                  options={Object.values(LocationChangeType)
                                    .map((x) => x.toString())
                                    .filter((v) => isNaN(Number(v)))}
                                  selectOnFocus
                                  clearOnBlur
                                  handleHomeEndKeys
                                  onChange={(event, newValue) => {
                                    field.onChange(newValue ?? undefined)

                                    if (newValue) {
                                      setActiveConfig({
                                        ...activeConfig,
                                        locationChangeQualifier: newValue,
                                      })
                                    }

                                    return newValue
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...field}
                                      {...params}
                                      margin='normal'
                                      label='Enters / Exits'
                                      variant='filled'
                                      error={!!errors.locationChangeQualifier}
                                      required
                                      className={classes.inputStyle}
                                      helperText={requiredMessage}
                                    />
                                  )}
                                />
                              )
                            }}
                          />
                        ) : (
                          <FormSkeleton width={formWidth} />
                        )}
                      </div>
                    )}
                    {!isAssetAlert && (
                      <>
                        {activeConfigLoaded ? (
                          <Controller
                            control={control}
                            name='badgeQualifier'
                            rules={{ required: true }}
                            render={({ field: { ref, ...field } }) => {
                              return (
                                <Autocomplete
                                  {...field}
                                  defaultValue={'All Badges'}
                                  style={{ width: '100%' }}
                                  value={'All Badges'}
                                  isOptionEqualToValue={(option, value) => {
                                    if (value === '') {
                                      return true
                                    }
                                    return option === value
                                  }}
                                  options={['All Badges']}
                                  selectOnFocus
                                  clearOnBlur
                                  handleHomeEndKeys
                                  disabled={true}
                                  onChange={(event, newValue) => {
                                    field.onChange(newValue ?? undefined)
                                    return newValue
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...field}
                                      {...params}
                                      margin='normal'
                                      label='Badges'
                                      variant='filled'
                                      error={!!errors.badgeQualifier}
                                      required
                                      className={classes.inputStyle}
                                      helperText={requiredMessage}
                                    />
                                  )}
                                />
                              )
                            }}
                          />
                        ) : (
                          <FormSkeleton width={formWidth} />
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>

              {activeConfig.alertType && (
                <div>
                  <div
                    style={{
                      margin: '0 24px',
                    }}
                  >
                    {activeConfigLoaded ? (
                      <FormControl
                        variant='standard'
                        style={{
                          minWidth: formWidth,
                        }}
                        required
                      >
                        <InputLabel
                          classes={inputLabelClasses}
                          id='location-qualifier-label'
                        >
                          Location Qualifier
                        </InputLabel>
                        <Select
                          variant='standard'
                          labelId='location-qualifier-label'
                          id='location-qualifier'
                          multiple
                          autoWidth
                          required
                          name='locationQualifier'
                          value={locationQualifier}
                          input={<OutlinedInput required />}
                          classes={selectClasses}
                          open={openLocationSelect}
                          onOpen={handleOpen}
                          onClose={handleClose}
                          error={!!errors.locationQualifier}
                          renderValue={(selected: any) => {
                            return (
                              <Breadcrumbs
                                separator={<NavigateNext fontSize='small' />}
                                classes={breadcrumbsClasses}
                              >
                                {locationQualifier.map((x) => (
                                  <div key={x.id}>{x.name}</div>
                                ))}
                              </Breadcrumbs>
                            )
                          }}
                        >
                          <LocationSelectMenu
                            isIDNRestrictedHierarchy={false}
                            open={openLocationSelect}
                            selectedCampus={selectedCampus}
                            selectedBuilding={selectedBuilding}
                            selectedFloor={selectedFloor}
                            campuses={idn?.children}
                            onCampusChange={setSelectedCampus}
                            onBuildingChange={setSelectedBuilding}
                            onFloorChange={setSelectedFloor}
                            handleClose={handleClose}
                            isDeselectable={true}
                          />
                        </Select>
                        {errors.locationQualifier ? (
                          <FormHelperText
                            error
                            variant='outlined'
                          >{`*${errors.locationQualifier.message}`}</FormHelperText>
                        ) : (
                          <FormHelperText variant='outlined'>
                            {requiredMessage}
                          </FormHelperText>
                        )}
                      </FormControl>
                    ) : (
                      <FormSkeleton width={formWidth} />
                    )}
                  </div>
                  <div
                    style={{
                      width: formWidth,
                      margin: '0 24px 30px 24px',
                    }}
                  >
                    {activeConfigLoaded ? (
                      <AlertUnitDropDown
                        value={
                          Object.values(units).find(
                            (x) => x.id === activeConfig?.unitQualifier?.id
                          ) ?? undefined
                        }
                        loading={unitsStatus === FetchingStatus.Request}
                        error={!!errors.unitQualifer}
                        className={classes.inputStyle}
                        data={[allUnits, ...Object.values(units)] ?? []}
                        fieldName='Unit Qualifier'
                        unselectedValue={'-1'}
                        helperText={requiredMessage}
                        handleChange={(event: any, value: Unit | null) => {
                          setActiveConfig({
                            ...activeConfig,
                            unitQualifier: value ?? allUnits,
                          })
                          return value
                        }}
                      />
                    ) : (
                      <FormSkeleton width={formWidth} />
                    )}
                    {isAssetAlert && (
                      <div>
                        {activeConfigLoaded ? (
                          <Controller
                            control={control}
                            name='assetDetail'
                            render={({ field: { ref, ...field } }) => {
                              return (
                                <Autocomplete
                                  {...field}
                                  value={'Asset Type'}
                                  style={{ width: '100%' }}
                                  options={['Asset Type']}
                                  isOptionEqualToValue={(option, value) => {
                                    if (!value) {
                                      return true
                                    }
                                    return option === value
                                  }}
                                  getOptionLabel={(option) => option ?? ''}
                                  selectOnFocus
                                  clearOnBlur
                                  handleHomeEndKeys
                                  disabled
                                  onChange={(event, newValue) => {
                                    field.onChange(newValue ?? undefined)

                                    return newValue
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...field}
                                      {...params}
                                      margin='normal'
                                      label='Asset Detail'
                                      variant='filled'
                                      error={!!errors.alertType}
                                      required
                                      className={classes.inputStyle}
                                      helperText={requiredMessage}
                                      inputRef={ref}
                                    />
                                  )}
                                />
                              )
                            }}
                          />
                        ) : (
                          <FormSkeleton width={formWidth} />
                        )}
                      </div>
                    )}
                    {isAssetAlert && (
                      <div>
                        {activeConfigLoaded ? (
                          <Controller
                            control={control}
                            name='assetType'
                            render={({ field: { ref, ...field } }) => {
                              return (
                                <Autocomplete
                                  {...field}
                                  value={activeConfig?.assetType}
                                  style={{ width: '100%' }}
                                  options={Object.values(assetTypes)}
                                  isOptionEqualToValue={(option, value) => {
                                    if (!value) {
                                      return true
                                    }
                                    return (
                                      option.assetTypeId === value.assetTypeId
                                    )
                                  }}
                                  getOptionLabel={(option) => option.name ?? ''}
                                  selectOnFocus
                                  clearOnBlur
                                  handleHomeEndKeys
                                  loading={
                                    assetTypesStatus === FetchingStatus.Request
                                  }
                                  onChange={(event, newValue) => {
                                    field.onChange(newValue ?? undefined)

                                    if (newValue) {
                                      setActiveConfig({
                                        ...activeConfig,
                                        assetType: newValue,
                                      })
                                    }

                                    return newValue
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...field}
                                      {...params}
                                      margin='normal'
                                      label='Asset Type'
                                      variant='filled'
                                      error={!!errors.assetType}
                                      className={classes.inputStyle}
                                      helperText={requiredMessage}
                                      inputRef={ref}
                                    />
                                  )}
                                />
                              )
                            }}
                          />
                        ) : (
                          <FormSkeleton width={formWidth} />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </form>
          </div>
          <RouterPrompt />
        </>
      }
      rightDrawer={
        <AlertConfigRightDrawer
          activeConfig={activeConfig}
          activeConfigLoaded={activeConfigLoaded}
          canEdit={false}
        />
      }
    />
  )
}

export default AlertConfigConditionPage
