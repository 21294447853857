import { BaseService } from './baseService'
import { Role } from '../models/roles'

export class RolesApi extends BaseService {
  constructor() {
    super({ entityType: 'roles' })
  }
  route = 'roles'

  async getAllRoles(): Promise<Role[]> {
    return await this.get<Role[]>(this.route)
  }
  async getRolesByUserId(userId: string): Promise<Role[]> {
    return await this.get<Role[]>(`${this.route}/${userId}`)
  }
  async getRoleById(id: number): Promise<Role> {
    return await this.get<Role>(`${this.route}/${id}`)
  }
  async postRole(role: Role): Promise<Role> {
    return await this.post<Role>(this.route, role)
  }
  async putRole(role: Role): Promise<Role> {
    return await this.put<Role>(this.route, role)
  }
  async deleteRole(id: number): Promise<boolean> {
    return await this.deleteNoResult(`${this.route}/${id}`)
  }
}
