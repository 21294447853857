import React from 'react'
import { DataTestIds } from '../../utils/test-utils/dataTestIds'

interface Props {
  svgText: string
  classes?: Record<'icon', string>
}

const CustomSVGIcon = (props: Props): React.ReactElement => {
  const { svgText, classes } = props

  return (
    <div className={classes?.icon} data-testid={DataTestIds.customSvgIcon}>
      <div dangerouslySetInnerHTML={{ __html: svgText }} />
    </div>
  )
}

export default CustomSVGIcon
