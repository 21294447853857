import { createNewGroup, moveAgentToAGroup } from '.'
import { AssetKitInstance } from '../../models'
import { MapRenderByGeofence } from '../../models/realtimeMap'
import { BadgeTelemetryMessageWithAgentEvent } from '../../models/telemetry'
import {
  calculateCreateNewGroupForEnteringGeofence,
  calculateLeavingGeofence,
  calculateLeavingGeofenceMapUpdatesToMoveAgent,
} from '../calculateRTMapUpdates'
import {
  calculateMapRenderToUpdateForAgentOnMap,
  checkRemainingAgentsHaveDuress,
} from '../realtimeMap'

/**
 * Once the MapUpdateType has been determined to be MoveAgent from leaving geofence and
 * CreateNewGroup for entering geofence, use this function to process the corresponding
 * map rerendering for the received telemetry. Calculate the required configs followed by:
 * 1. Move agent to the new geofence
 * 2. Hide all existing agent icons at the entering geofence including the arrived agent icon
 * 3. Create a new group icon at the entering geofence
 * Finally, calculate and return the updated map render state
 * @param newAgentTelemetry
 * @param currentMapRender
 * @param assetKit
 * @param jibestreamController
 * @param geofenceInstances
 * @param useGeofenceCentroid
 * @param iconScale
 * @param selectedTrackingId
 * @param selectedGroupId
 * @param addGeofenceHighlight
 * @param removeGeofenceHighlight
 * @returns updated map render state after map rerendering
 */
export async function moveAgentAndCreateNewGroup(
  newAgentTelemetry: BadgeTelemetryMessageWithAgentEvent,
  currentMapRender: MapRenderByGeofence,
  assetKit: AssetKitInstance,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  geofenceInstances: any[],
  useGeofenceCentroid: boolean,
  iconScale: number,
  selectedTrackingId: string | undefined,
  selectedGroupId: number | undefined,
  addGeofenceHighlight: (geofenceId: number) => void,
  removeGeofenceHighlight: (geofenceId: number) => void
): Promise<MapRenderByGeofence> {
  const leavingAgentToUpdatePosition =
    calculateLeavingGeofenceMapUpdatesToMoveAgent(
      newAgentTelemetry,
      currentMapRender
    )
  if (!leavingAgentToUpdatePosition) {
    return currentMapRender
  }
  const enteringGeofenceNewGroupUpdate =
    calculateCreateNewGroupForEnteringGeofence(
      newAgentTelemetry,
      currentMapRender,
      iconScale,
      selectedTrackingId,
      selectedGroupId
    )
  if (enteringGeofenceNewGroupUpdate) {
    const { groupToCreate, enteringAgentsToHide } =
      enteringGeofenceNewGroupUpdate
    const groupIconToUpdate = createNewGroup(
      groupToCreate,
      assetKit,
      jibestreamController,
      geofenceInstances,
      useGeofenceCentroid,
      iconScale
    )

    const leavingGeofenceData = calculateLeavingGeofence(
      newAgentTelemetry.trackingId,
      currentMapRender
    )
    let remainingAgentsHaveDuress = false
    if (leavingGeofenceData) {
      remainingAgentsHaveDuress = checkRemainingAgentsHaveDuress(
        currentMapRender,
        newAgentTelemetry.trackingId,
        leavingGeofenceData.prevAgentTelemetry.geoFenceId
      )
    }

    await moveAgentToAGroup(
      leavingAgentToUpdatePosition,
      {
        enteringAgentsToHide,
        groupIconToUpdate,
      },
      assetKit,
      jibestreamController,
      geofenceInstances,
      useGeofenceCentroid,
      addGeofenceHighlight,
      removeGeofenceHighlight,
      remainingAgentsHaveDuress
    )
  }
  const updatedMapRender = calculateMapRenderToUpdateForAgentOnMap(
    currentMapRender,
    newAgentTelemetry
  )
  return updatedMapRender
}
