import { isEmpty } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  NewAgentByType,
  useAwaitingFetchedNewAgentsEnteringMap,
  useCalculateNewAssetsEnteringMapUpdates,
  useCalculateNewStaffsEnteringMapUpdates,
} from '.'
import { useCombinedLiveTelemetry } from '..'
import {
  removeAssetsFromMapAction,
  silentUpdateAssetAction,
} from '../../actions/assets'
import {
  removeStaffMembersFromMapAction,
  silentUpdateStaffAction,
} from '../../actions/staff'
import {
  filterAgentTelemetriesByAgentTypesPlural,
  filterNewAgentsAndMapLeavers,
  getTelemetriesByAgentTypesPlural,
  shouldCalculateLiveTelemetryUpdates,
  transformTelemetriesToIncludeStaffEvents,
} from '../../helpers/telemetry'
import {
  Asset,
  FetchingStatus,
  Geofences,
  Staff,
  StaffEvent,
  TelemetryAsset,
  TelemetryStaff,
} from '../../models'
import { SortedLiveMapTelemetry } from '../../models/realtimeMap'
import {
  AgentTelemetriesGroupedByTrackingId,
  TelemetryByAgentType,
} from '../../models/telemetry'
import {
  selectBadgeEventTelemetry,
  selectBaseBadgeTelemetry,
  selectCurrentLocation,
} from '../../selectors'
import { UpdateAgentTelemetriesPayload } from './useRTMapAgentTelemetryState'

export function useCalculateLiveMapTelemetryUpdates(
  enabled: boolean,
  mapId: number | undefined,
  isInitialLoaded: boolean,
  assetData: {
    fetchedAssetsByLocation: Asset[]
    assetStatus: FetchingStatus | undefined
  },
  staffData: {
    fetchedStaffsByLocation: Staff[]
    staffStatus: FetchingStatus | undefined
  },
  agentEvents: {
    allDuressEvents: StaffEvent[]
    allAssistEvents: StaffEvent[]
    staffDuressAndAssistFeatureFlagEnabled: boolean
  },
  geofences: Geofences,
  agentTypes: string[],
  agentTelemetryState: {
    agentTelemetries: TelemetryByAgentType
    updateAgentTelemetries: (payload: UpdateAgentTelemetriesPayload) => void
    addAssetTelemetries: (
      telemetries: AgentTelemetriesGroupedByTrackingId<TelemetryAsset>
    ) => void
    addStaffTelemetries: (
      telemetries: AgentTelemetriesGroupedByTrackingId<TelemetryStaff>
    ) => void
  }
): {
  liveMapTelemetriesToUpdate: SortedLiveMapTelemetry | undefined
  newAgentsEnteringMapTelemetries: AgentTelemetriesGroupedByTrackingId
  awaitingFetchedAgents: NewAgentByType
} {
  const {
    allDuressEvents,
    allAssistEvents,
    staffDuressAndAssistFeatureFlagEnabled,
  } = agentEvents

  const {
    agentTelemetries,
    updateAgentTelemetries,
    addAssetTelemetries,
    addStaffTelemetries,
  } = agentTelemetryState
  const { fetchedAssetsByLocation, assetStatus } = assetData
  const { fetchedStaffsByLocation, staffStatus } = staffData

  const [liveMapTelemetriesToUpdate, setLiveMapTelemetriesToUpdate] = useState<
    SortedLiveMapTelemetry | undefined
  >()
  const [newAgentsEnteringMapTelemetries, setNewAgentsEnteringMapTelemetries] =
    useState<AgentTelemetriesGroupedByTrackingId>({})

  const baseBadgeTelemetryState = useSelector(selectBaseBadgeTelemetry)
  const badgeEventTelemetryState = useSelector(selectBadgeEventTelemetry)
  const currentLocation = useSelector(selectCurrentLocation)

  const { badgeTelemetry: baseTelemetry, clearBaseTelemetry } =
    useCombinedLiveTelemetry(baseBadgeTelemetryState, badgeEventTelemetryState)
  const {
    awaitingFetchedAgents,
    addAwaitingFetchedAssets,
    addAwaitingFetchedStaffs,
    removeFetchedAssets,
    removeFetchedStaffs,
  } = useAwaitingFetchedNewAgentsEnteringMap()

  const allDuressEventsRef = useRef<StaffEvent[]>([])
  const allAssistEventsRef = useRef<StaffEvent[]>([])
  const agentTypesRef = useRef<string[]>(agentTypes)
  const staffDuressAndAssistFeatureFlagEnabledRef = useRef<boolean>(false)
  const agentTelemetriesRef = useRef<TelemetryByAgentType>({
    assetAgentTelemetries: {},
    staffAgentTelemetries: {},
  })
  const awaitingFetchedAgentsRef = useRef<NewAgentByType>(awaitingFetchedAgents)
  const geofencesRef = useRef<Geofences>({})
  const currentLocationIdRef = useRef<string | undefined>()

  geofencesRef.current = geofences
  allDuressEventsRef.current = allDuressEvents
  allAssistEventsRef.current = Object.values(allAssistEvents)
  agentTypesRef.current = agentTypes
  awaitingFetchedAgentsRef.current = awaitingFetchedAgents
  staffDuressAndAssistFeatureFlagEnabledRef.current =
    staffDuressAndAssistFeatureFlagEnabled
  currentLocationIdRef.current = currentLocation.id
  agentTelemetriesRef.current = agentTelemetries

  const dispatch = useDispatch()

  useEffect(() => {
    if (
      !shouldCalculateLiveTelemetryUpdates(
        baseTelemetry,
        enabled,
        mapId,
        isInitialLoaded
      ) ||
      !mapId
    ) {
      return
    }
    const transformedBaseTelemetries = transformTelemetriesToIncludeStaffEvents(
      baseTelemetry,
      agentTelemetriesRef.current,
      allDuressEventsRef.current,
      allAssistEventsRef.current,
      staffDuressAndAssistFeatureFlagEnabledRef.current
    )
    const {
      newAgentsByAgentType,
      newTelemetriesForExistingAgents,
      agentsLeavingMap,
    } = filterNewAgentsAndMapLeavers(
      transformedBaseTelemetries,
      agentTelemetriesRef.current,
      mapId
    )

    // Agents leaving map
    const {
      assetAgentTelemetries: assetsLeavingMap,
      staffAgentTelemetries: staffsLeavingMap,
    } = agentsLeavingMap
    if (!isEmpty(assetsLeavingMap)) {
      dispatch(
        removeAssetsFromMapAction(
          Object.values(assetsLeavingMap).map((x) => x.agent.agentGuid)
        )
      )
    }
    if (!isEmpty(staffsLeavingMap)) {
      dispatch(
        removeStaffMembersFromMapAction(
          Object.values(staffsLeavingMap).map((x) => x.agent.agentGuid)
        )
      )
    }

    updateAgentTelemetries({
      newTelemetriesForExistingAgents,
      agentsLeavingMap,
    })

    const filteredLiveTelemetriesByAgentTypes =
      filterAgentTelemetriesByAgentTypesPlural(
        newTelemetriesForExistingAgents,
        agentTypesRef.current
      )
    const filteredAgentsLeavingMapByAgentTypes =
      getTelemetriesByAgentTypesPlural(agentsLeavingMap, agentTypesRef.current)

    // New agents entering map
    const {
      assetAgentTelemetries: newAssetsEnteringMap,
      staffAgentTelemetries: newStaffsEnteringMap,
    } = newAgentsByAgentType
    const {
      assetTelemetries: requestedAssets,
      staffTelemetries: requestedStaffs,
    } = awaitingFetchedAgentsRef.current
    if (!isEmpty(newAssetsEnteringMap) && currentLocationIdRef.current) {
      const newAssetsToFetch = Object.values(newAssetsEnteringMap).filter(
        (telemetry) =>
          !Object.keys(requestedAssets).includes(telemetry.trackingId)
      )
      if (newAssetsToFetch.length > 0) {
        dispatch(
          silentUpdateAssetAction.request({
            locationGuid: currentLocationIdRef.current,
            agentGuids: newAssetsToFetch.map((x) => x.agent.agentGuid),
            badgeTelemetry: newAssetsToFetch,
          })
        )
      }
      addAwaitingFetchedAssets(Object.values(newAssetsEnteringMap))
    }
    if (!isEmpty(newStaffsEnteringMap) && currentLocationIdRef.current) {
      const newStaffsToFetch = Object.values(newStaffsEnteringMap).filter(
        (telemetry) =>
          !Object.keys(requestedStaffs).includes(telemetry.trackingId)
      )
      if (newStaffsToFetch.length > 0) {
        dispatch(
          silentUpdateStaffAction.request({
            locationGuid: currentLocationIdRef.current,
            agentGuids: newStaffsToFetch.map((x) => x.agent.agentGuid),
            badgeTelemetry: newStaffsToFetch,
          })
        )
      }
      addAwaitingFetchedStaffs(Object.values(newStaffsEnteringMap))
    }
    setLiveMapTelemetriesToUpdate({
      agentTelemetriesForAgentsAlreadyOnMap:
        filteredLiveTelemetriesByAgentTypes,
      agentsLeavingMap: filteredAgentsLeavingMapByAgentTypes,
    })
    clearBaseTelemetry()
  }, [
    baseTelemetry,
    clearBaseTelemetry,
    enabled,
    mapId,
    isInitialLoaded,
    dispatch,
    updateAgentTelemetries,
    addAwaitingFetchedAssets,
    addAwaitingFetchedStaffs,
  ])

  useCalculateNewAssetsEnteringMapUpdates(
    fetchedAssetsByLocation,
    assetStatus,
    geofences,
    awaitingFetchedAgents.assetTelemetries,
    agentTypes,
    setNewAgentsEnteringMapTelemetries,
    addAssetTelemetries,
    removeFetchedAssets
  )

  useCalculateNewStaffsEnteringMapUpdates(
    fetchedStaffsByLocation,
    staffStatus,
    geofences,
    awaitingFetchedAgents.staffTelemetries,
    agentTypes,
    setNewAgentsEnteringMapTelemetries,
    addStaffTelemetries,
    removeFetchedStaffs
  )

  return {
    liveMapTelemetriesToUpdate,
    newAgentsEnteringMapTelemetries,
    awaitingFetchedAgents, // Return this value for unit testing assertion only
  }
}
