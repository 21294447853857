import { getCenterOfBounds, isPointInBounds } from '..'
import { Geofences } from '../../models'
import { GeofenceInstance } from '../../models/jibestream'

export function getDefaultGeofenceGroupCount(
  geofences: Geofences,
  geofenceId: number
): number {
  return (
    Object.values(geofences).find((x) => x.coreReferenceId === geofenceId)
      ?.groupCount ?? 2
  )
}

export function getGeofenceInstancesCenterByPoint(
  point: number[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  geofenceInstances: any[],
  mapId: number | undefined
): number[] {
  if (geofenceInstances.length === 0) {
    return []
  }
  const geofence = geofenceInstances
    .filter((geofenceInstance) => geofenceInstance.floor.map.id === mapId)
    .find(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (geofenceInstance: any) => {
        if (geofenceInstance.localCoordinates.length === 0) {
          return false
        }
        return isPointInBounds(point, geofenceInstance.localCoordinates[0])
      }
    )
  if (geofence) {
    return getCenterOfBounds(geofence.localCoordinates[0])
  }
  return []
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function getAllGeofenceInstances(geofences: any): GeofenceInstance[] {
  if (!geofences) {
    return []
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const geofenceInstancesByMapId: any[] = []
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  geofences.getAll().forEach((geofence: any) => {
    geofence.instances.forEach((geofenceInstance: GeofenceInstance) =>
      geofenceInstancesByMapId.push(geofenceInstance)
    )
  })
  return geofenceInstancesByMapId
}
