import React from 'react'
import DialogButton from './DialogButton'
import { DialogActionButtonType } from './DialogActionButtonType'

interface Props {
  actionButtons: DialogActionButtonType[]
}

const DialogActionButtons = (props: Props) => {
  const { actionButtons } = props
  return (
    <>
      {actionButtons.map((actionButton, i) => (
        <DialogButton key={`actionButton-${i}`} actionButton={actionButton} />
      ))}
    </>
  )
}

export default DialogActionButtons
