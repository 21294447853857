import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { MapAction } from '../actions'
import { getAllMapsAction } from '../actions/maps'
import { MapsApi } from '../middleware'
import { MapsState } from '../models/maps'

type MapsEpic = Epic<MapAction, MapAction, MapsState, { mapsApi: MapsApi }>

export const getAllMapsEpic: MapsEpic = (action$, state, { mapsApi }) => {
  return action$.pipe(
    filter(isActionOf(getAllMapsAction.request)),
    mergeMap(() =>
      from(mapsApi.getAllMaps()).pipe(
        map((maps) => getAllMapsAction.success(maps)),
        catchError((error) => of(getAllMapsAction.failure(error)))
      )
    )
  )
}
