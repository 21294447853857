import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { DeviceAction } from '../actions'
import {
  getAllDevicesAction,
  getDeviceAction,
  putDeviceAction,
  putDevicesAction,
} from '../actions/devices'
import { DevicesApi } from '../middleware'
import { DevicesState } from '../models'

type DevicesEpic = Epic<
  DeviceAction,
  DeviceAction,
  DevicesState,
  { devicesApi: DevicesApi }
>

export const getAllDevicesEpic: DevicesEpic = (
  action$,
  state,
  { devicesApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllDevicesAction.request)),
    mergeMap((action) =>
      from(devicesApi.getAllDevices()).pipe(
        map((devices) => getAllDevicesAction.success(devices)),
        catchError((error) => of(getAllDevicesAction.failure(error)))
      )
    )
  )
}

export const getDeviceEpic: DevicesEpic = (action$, state, { devicesApi }) => {
  return action$.pipe(
    filter(isActionOf(getDeviceAction.request)),
    mergeMap((action) =>
      from(devicesApi.getDevice(action.payload)).pipe(
        map((device) => getDeviceAction.success(device)),
        catchError((error) => of(getDeviceAction.failure(error)))
      )
    )
  )
}

export const putDeviceEpic: DevicesEpic = (action$, state, { devicesApi }) => {
  return action$.pipe(
    filter(isActionOf(putDeviceAction.request)),
    mergeMap((action) =>
      from(devicesApi.putDevice(action.payload)).pipe(
        mergeMap((device) => [putDeviceAction.success(device)]),
        catchError((error) => of(putDeviceAction.failure(error)))
      )
    )
  )
}

export const putDevicesEpic: DevicesEpic = (action$, state, { devicesApi }) => {
  return action$.pipe(
    filter(isActionOf(putDevicesAction.request)),
    mergeMap((action) =>
      from(devicesApi.putDevices(action.payload)).pipe(
        mergeMap((devices) => [putDevicesAction.success(devices)]),
        catchError((error) => of(putDevicesAction.failure(error)))
      )
    )
  )
}
