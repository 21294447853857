import { FetchingState } from '../interfaces/fetchingState'

export interface Role {
  id: number
  name: string
  description: string
  permissionIds: number[]
}

export interface SortedRole extends Role {
  sortOrder: number
}

export interface SortedRoles {
  [id: string]: SortedRole
}

export interface Roles {
  [id: string]: Role
}

export type TenantUserRolesState = Readonly<FetchingState<SortedRoles>>

export enum UserRoles {
  SystemAdmin = 1,
  BioMed = 2,
  ClinicalUser = 3,
  ClinicalAdmin = 4,
  SecurityAdmin = 5,
  BioMedAdmin = 6,
  ClinicalAssetUser = 7,
  ClinicalAssetAdmin = 8,
  SecurityUser = 9,
}

export const allRoles: SortedRole = {
  id: -1,
  name: 'All Roles',
  description: '',
  permissionIds: [],
  sortOrder: 0,
}
