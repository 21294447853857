import { FetchingState } from '../interfaces/fetchingState'

export interface Device {
  deviceGuid: string
  deviceId: number
  deviceTypeId: number
  deviceType: DeviceType
  locationId: string
  sensorGuid?: string
  sensorId?: string
  floormapGuid: string
  status: DeviceStatus
  x: number
  y: number
  configuredDate?: Date
  unconfiguredDate?: Date
  latitude?: number
  longitude?: number
}

export interface Devices {
  [deviceGuid: string]: Device
}

export type DevicesState = Readonly<FetchingState<Devices>>

export enum DeviceStatus {
  NotInstalled = 'Not Installed',
  Configured = 'Configured',
  Unconfigured = 'Unconfigured',
  Connected = 'Connected',
  NotApplicable = 'N/A',
}

export enum DeviceType {
  Unknown = 'Unknown',
  IR = 'IR',
  RF = 'RF',
  'Badge Cabinet' = 'Badge Cabinet',
  'Drop Box' = 'Drop Box',
  BleSensor = 'BLE Sensor',
  Gateway = 'Gateway',
}

export const deviceTypeIdToEnum = (id: number | undefined): DeviceType => {
  switch (id) {
    case 1:
      return DeviceType.IR
    case 2:
      return DeviceType.RF
    case 3:
      return DeviceType['Badge Cabinet']
    case 4:
      return DeviceType['Drop Box']
    case 5:
      return DeviceType.BleSensor
    case 6:
      return DeviceType.Gateway
    case 0:
    default:
      return DeviceType.Unknown
  }
}
