import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import RootAction from '../../actions'
import {
  getAllSensorsBySensorIdAction,
  configureSensorAction,
  unconfigureSensorAction,
} from '@midmarkrtls/common/actions/sensors'
import { SensorsApi } from '@midmarkrtls/common/middleware/sensors'
import { RootState } from '../../reducers'
import {
  refreshMapReferenceViewByDeviceGuid,
  fetchMapReferenceViewsByFloorLocationIdAction,
} from '@midmarkrtls/common/actions/mapReferenceViews'
import { getDeviceAction } from '@midmarkrtls/common/actions/devices'

type sensorConfigurationEpic = Epic<
  RootAction,
  RootAction,
  RootState,
  { sensorsApi: SensorsApi }
>

export const ConfigureSensorEpic: sensorConfigurationEpic = (
  action$,
  state,
  { sensorsApi }
) => {
  return action$.pipe(
    filter(isActionOf(configureSensorAction.request)),
    mergeMap((action) =>
      from(sensorsApi.configureSensor(action.payload)).pipe(
        mergeMap((deviceGuid) => [
          configureSensorAction.success(deviceGuid),
          getDeviceAction.request(deviceGuid),
          getAllSensorsBySensorIdAction.request(action.payload.sensorId),
          refreshMapReferenceViewByDeviceGuid(deviceGuid),
          fetchMapReferenceViewsByFloorLocationIdAction.request(
            action.payload.floorLocationId
          ),
        ]),
        catchError((error) => of(configureSensorAction.failure(error)))
      )
    )
  )
}

export const UnconfigureSensorEpic: sensorConfigurationEpic = (
  action$,
  state,
  { sensorsApi }
) => {
  return action$.pipe(
    filter(isActionOf(unconfigureSensorAction.request)),
    mergeMap((action) =>
      from(sensorsApi.unconfigureSensor(action.payload)).pipe(
        mergeMap((deviceGuid) => [
          unconfigureSensorAction.success(deviceGuid),
          getDeviceAction.request(deviceGuid),
          getAllSensorsBySensorIdAction.request(action.payload.sensorId),
          refreshMapReferenceViewByDeviceGuid(deviceGuid),
          fetchMapReferenceViewsByFloorLocationIdAction.request(
            action.payload.floorLocationId
          ),
        ]),
        catchError((error) => of(unconfigureSensorAction.failure(error)))
      )
    )
  )
}
