import { AgentType, IconType } from '../../models'
import { InpixonAssetAgentCreateOptions } from '../../models/inpixonMap'
import { BadgeTelemetryMessageWithAgentEvent } from '../../models/telemetry'

/**
 * Build config options that can be used for subsequent calls to generate the final
 * Inpixon Asset config values used for Inpixon asset creation on the map.
 * NOTE: used for asset and staff icon only
 * @param telemetry
 * @param iconScale
 * @param selectedTrackingId
 * @returns config options used for subsequent Inpixon Asset config generation
 */
export function buildInpixonAssetAgentCreateOptions(
  telemetry: BadgeTelemetryMessageWithAgentEvent,
  iconScale: number,
  selectedTrackingId: string | undefined
): InpixonAssetAgentCreateOptions | undefined {
  if (telemetry.agent.agentType === AgentType.Asset) {
    return {
      iconType: IconType.Asset,
      iconUrl: telemetry.agent.iconUrl,
      displayName: telemetry.agent.displayName,
      trackingId: telemetry.trackingId,
      iconScale,
      signalTypeId: telemetry.signalTypeId,
      geoFenceId: telemetry.geoFenceId,
      lat: telemetry.lat,
      lon: telemetry.lon,
      mapId: telemetry.mapId,
      timestamp: telemetry.timestamp,
      isLowBattery: telemetry.isLowBattery,
      hasActiveDuress: false,
      hasActiveAssist: false,
      hasResolvedDuressOrAssist: false,
      isSelected: telemetry.trackingId === selectedTrackingId,
    }
  } else if (telemetry.agent.agentType === AgentType.Staff) {
    return {
      iconType: IconType.Staff,
      iconUrl: telemetry.agent.iconUrl,
      displayName: telemetry.agent.displayName,
      trackingId: telemetry.trackingId,
      iconScale,
      signalTypeId: telemetry.signalTypeId,
      geoFenceId: telemetry.geoFenceId,
      lat: telemetry.lat,
      lon: telemetry.lon,
      mapId: telemetry.mapId,
      timestamp: telemetry.timestamp,
      isLowBattery: telemetry.isLowBattery,
      hasActiveDuress: telemetry.hasActiveDuress,
      hasActiveAssist: telemetry.hasActiveAssist,
      hasResolvedDuressOrAssist: telemetry.hasResolvedDuressOrAssist,
      isSelected: telemetry.trackingId === selectedTrackingId,
    }
  }
}
