/* eslint-disable import/no-anonymous-default-export */
import { getType } from 'typesafe-actions'
import { UserSettingsAction } from '../actions'
import {
  deleteUserSettingsAction,
  getUserSettingsAction,
  patchUserSettingsAction,
} from '../actions/userSettings'
import { UserSettings, UserSettingsState } from '../models'
import { FetchingStatus } from '../models/fetchingStatus'
import { setFetchingStatus } from '../utils'

const initialState: UserSettingsState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

export default (
  state: UserSettingsState = initialState,
  action: UserSettingsAction
): UserSettingsState => {
  switch (action.type) {
    case getType(getUserSettingsAction.request):
    case getType(patchUserSettingsAction.request):
    case getType(deleteUserSettingsAction.request):
      return setFetchingStatus<UserSettings>(state, FetchingStatus.Request)

    case getType(getUserSettingsAction.success):
    case getType(patchUserSettingsAction.success):
    case getType(deleteUserSettingsAction.success):
      return {
        data: {
          ...action.payload,
        },
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }

    case getType(getUserSettingsAction.failure):
    case getType(patchUserSettingsAction.failure):
    case getType(deleteUserSettingsAction.failure):
      return setFetchingStatus<UserSettings>(state, FetchingStatus.Failure)

    default:
      return state
  }
}
