import { getAllIssuesAction } from '../../actions/issues'
import { Issues, IssuesState } from '../../models'
import { EntityResult, useEntities } from '../useEntities'

const useFetchIssues = (): EntityResult<Issues> =>
  useEntities<IssuesState, Issues>(
    () => getAllIssuesAction.request(),
    (state: any) => state.issues // eslint-disable-line @typescript-eslint/no-explicit-any
  )

export { useFetchIssues }
