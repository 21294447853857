import React, { ReactNode } from 'react'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { CommonPropsWithStyles } from '.'
import { flexNone } from '../../styles'
import { defaultTheme } from './TableStyles'
import { epicTableFooterHeight } from './constants'

const styles = createStyles({
  root: {
    flex: flexNone,
    paddingRight: 24,
    paddingLeft: 24,
    height: epicTableFooterHeight,
    borderTop: `1px solid ${defaultTheme.borderColor}`,
  },
})

interface Props extends CommonPropsWithStyles<typeof styles> {
  children: ReactNode
}

const EpicTableFooter: React.FC<Props> = (props: Props) => {
  const { classes, children, ...other } = props
  return (
    <footer className={classes.root} {...other}>
      {children}
    </footer>
  )
}

export default withStyles(styles, { name: 'EpicTableFooter' })(EpicTableFooter)
