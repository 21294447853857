import { HttpErrorResponse } from '@midmarkrtls/common/services/http'
import { createAsyncAction } from 'typesafe-actions'
import {
  JibestreamVenues,
  JibestreamVenueImportSuccessPayload,
} from '../models/venue'
import { VenueImport } from '../models/venueImport'

export const getAllJibestreamVenuesAction = createAsyncAction(
  'GET_ALL_JIBESTREAM_VENUES_REQUEST',
  'GET_ALL_JIBESTREAM_VENUES_SUCCESS',
  'GET_ALL_JIBESTREAM_VENUES_FAILURE'
)<void, JibestreamVenues, HttpErrorResponse>()

export const importVenueAction = createAsyncAction(
  'IMPORT_VENUE_REQUEST',
  'IMPORT_VENUE_SUCCESS',
  'IMPORT_VENUE_FAILURE'
)<VenueImport, JibestreamVenueImportSuccessPayload, HttpErrorResponse>()
