import React from 'react'
import { Tooltip } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { AssetFilters } from '../../models/filters'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'

interface Props {
  filter: AssetFilters
  handleDelete: (event: React.FormEvent, filterGiud: string) => void
  dataTestId?: string
}

const FilterDeleteButton = (props: Props) => {
  const { filter, handleDelete, dataTestId } = props

  const handleClick = (event: React.FormEvent) => {
    event.preventDefault()
    if (filter?.filterGuid) {
      handleDelete(event, filter.filterGuid)
    }
  }

  if (filter) {
    return (
      <Tooltip title={'Delete'}>
        <IconButton
          color='primary'
          onClick={(event: React.FormEvent) => handleClick(event)}
          data-testid={dataTestId ?? ''}
          size='large'
        >
          <DeleteOutlinedIcon />
        </IconButton>
      </Tooltip>
    )
  } else {
    return null
  }
}

export default FilterDeleteButton
