import {
  DialogActions,
  DialogContent,
  FormControlLabel,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Close } from '@mui/icons-material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { formatLocalDateTimeByUserPreference } from '../../helpers/date'
import {
  useFetchUserPreferences,
  useSaveSuccessFailureUtility,
} from '../../hooks'
import {
  AgentEventsLocationState,
  FetchingStatus,
  StaffEvent,
} from '../../models'
import { colors, tableColors } from '../../styles'
import { DataTestIds } from '../../utils/test-utils/dataTestIds'
import ErrorContainedButton from '../Buttons/ErrorContainedButton'
import PrimaryTextButton from '../Buttons/PrimaryTextButton'
import { FlexBox } from '../Layout'

interface Props {
  open: boolean
  duressToResolve: StaffEvent
  setIsAccidentalDuress: (isAccidental: boolean) => void
  onCancelResolve: () => void
  onResolve: () => void
  handleSuccessfulDuressResolution: () => void
}

interface ConfirmationForm {
  isAccidental: string
}

export interface ResolveDuressState {
  isAccidentalDuress: boolean | null
  openConfirmationModal: boolean
  duressToResolve: StaffEvent | null
  isConfirmed: boolean
  isResolved: boolean
}

const StaffDuressResolveConfirmationModal = (props: Props): JSX.Element => {
  const {
    open,
    duressToResolve,
    setIsAccidentalDuress,
    onCancelResolve,
    onResolve,
    handleSuccessfulDuressResolution,
  } = props

  const {
    control,
    formState: { isDirty },
  } = useForm<ConfirmationForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      isAccidental: '',
    },
  })

  const { data: userPreferences } = useFetchUserPreferences()

  const agentEventStatus = useSelector(
    ({
      agentEventsLocation,
    }: {
      agentEventsLocation: AgentEventsLocationState
    }) => agentEventsLocation.status
  )

  const handleResolve = () => {
    setIsSaving(true)
    if (showError) {
      setShowError(false)
    }
    onResolve()
  }

  const useDialogActionStyles = makeStyles(() => ({
    root: {
      padding: 0,
    },
  }))

  const useRadioButtonStyles = makeStyles({
    root: {
      padding: 0,
    },
  })

  const dialogActionClasses = useDialogActionStyles()
  const radioButtonClasses = useRadioButtonStyles()

  const useDialogContentStyles = makeStyles(() => ({
    root: {
      padding: '0 0 20px 0',
    },
  }))

  const useStyles = makeStyles((theme) => ({
    paper: () => ({
      position: 'absolute',
      width: 450,
      backgroundColor: theme.palette.background.paper,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '4px',
      outline: 'none',
      border: `1px solid ${tableColors.borderColor}`,
    }),
  }))

  const useDialogStyles = makeStyles(() => ({
    paper: {
      padding: '16px',
    },
  }))

  const classes = useStyles(props)
  const dialogContainerClasses = useDialogStyles(props)
  const dialogContentClasses = useDialogContentStyles()

  const { isSaving, setIsSaving, showError, setShowError } =
    useSaveSuccessFailureUtility(
      agentEventStatus === FetchingStatus.Success,
      agentEventStatus === FetchingStatus.Failure,
      `Staff Duress on ${duressToResolve.floorName} in ${
        duressToResolve.roomName
      } by ${
        duressToResolve.reportedBy
      } at ${formatLocalDateTimeByUserPreference(
        duressToResolve.reportedTime,
        userPreferences.timeDisplayId
      )} has been resolved.`,
      'There is an error resolving duress, please try again. If the problem persists, please contact support for assistance.',
      handleSuccessfulDuressResolution
    )

  return (
    <Modal // `disableBackdropClick` is removed by codemod.
      // You can find more details about this breaking change in [the migration guide](https://mui.com/material-ui/migration/v5-component-changes/#modal)
      disableRestoreFocus
      open={open}
    >
      <div
        className={`${classes?.paper} ${dialogContainerClasses?.paper}`}
        data-testid={DataTestIds.resolveDuressConfirmationModal}
      >
        <FlexBox
          style={{
            paddingBottom: 20,
          }}
          flex='basis'
          layout='row'
          layoutAlign={{
            item: 'center',
            content: 'center',
            main: 'space-between',
          }}
        >
          <Typography
            variant='h5'
            style={{
              fontWeight: 'normal',
              color: colors.darkSurface,
              fontSize: 24,
            }}
          >
            Resolve This Duress Event?
          </Typography>
          <IconButton
            aria-label='Close'
            onClick={onCancelResolve}
            style={{ padding: 0 }}
            disableRipple
            disableFocusRipple
            disableTouchRipple
            size='large'
          >
            <Close />
          </IconButton>
        </FlexBox>
        <DialogContent classes={dialogContentClasses}>
          <Typography
            variant='body1'
            data-testid={DataTestIds.duressConfirmationMessage}
          >
            Are you sure you want to resolve {duressToResolve.reportedBy}’s
            duress call? This action cannot be undone.
          </Typography>
          <form
            name='duressConfirmation'
            style={{
              padding: '10px 0',
            }}
          >
            <FlexBox
              layout='row'
              layoutAlign={{
                main: 'space-between',
                content: 'space-between',
              }}
            >
              <FlexBox
                flex={{
                  basis: 250,
                }}
                style={{
                  alignSelf: 'center',
                }}
              >
                <Typography variant='body1'>
                  Was this duress call an accidental button press?
                </Typography>
              </FlexBox>

              <Controller
                name='isAccidental'
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    row
                    aria-labelledby='isaccidental-radio-buttons-group-label'
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      event.target.value === 'true'
                        ? setIsAccidentalDuress(true)
                        : setIsAccidentalDuress(false)
                      field.onChange(event.target.value)
                    }}
                  >
                    <FormControlLabel
                      value='true'
                      labelPlacement='top'
                      control={
                        <Radio
                          size='small'
                          color='primary'
                          className={radioButtonClasses.root}
                        />
                      }
                      label={
                        <Typography
                          style={{
                            fontSize: 12,
                            fontWeight: 'bold',
                            color: colors.midGrey2,
                          }}
                        >
                          YES
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value='false'
                      labelPlacement='top'
                      control={
                        <Radio
                          size='small'
                          color='primary'
                          className={radioButtonClasses.root}
                        />
                      }
                      label={
                        <Typography
                          style={{
                            fontSize: 12,
                            fontWeight: 'bold',
                            color: colors.midGrey2,
                          }}
                        >
                          NO
                        </Typography>
                      }
                    />
                  </RadioGroup>
                )}
              />
            </FlexBox>
          </form>
        </DialogContent>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DialogActions classes={dialogActionClasses}>
            <PrimaryTextButton onClick={onCancelResolve} disabled={isSaving}>
              CANCEL
            </PrimaryTextButton>
          </DialogActions>

          <DialogActions classes={dialogActionClasses}>
            <ErrorContainedButton
              styleOverride={{ wrapper: { margin: 0 } }}
              onClick={handleResolve}
              loading={isSaving}
              disabled={!isDirty}
            >
              RESOLVE DURESS
            </ErrorContainedButton>
          </DialogActions>
        </div>
        {showError && (
          <Typography
            style={{
              color: colors.error,
              padding: '10px 0',
            }}
            data-testid={DataTestIds.resolveDuressError}
          >
            There was an error resolving duress, please try again. If the
            problem persists, contact support.
          </Typography>
        )}
      </div>
    </Modal>
  )
}

export { StaffDuressResolveConfirmationModal }
