import { getAllVenuesAction } from '../../actions/venues'
import { Venues, VenuesState } from '../../models'
import { EntityResult, useEntities } from '../useEntities'

const useFetchVenues = (canFetch: boolean = true): EntityResult<Venues> =>
  useEntities<VenuesState, Venues>(
    () => getAllVenuesAction.request(),
    (state: any) => state.venues, // eslint-disable-line @typescript-eslint/no-explicit-any
    () => false,
    () => canFetch
  )

export { useFetchVenues }
