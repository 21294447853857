import { Coordinates, DestinationPositions } from '../../models'
import { calculateCornerPoint, transformWithRotation } from '../math/points'

interface AssetSettings {
  unitBuffer: number
  unitHeight: number
  unitWidth: number
  headerBuffer: number
  defaultHeight: number
  defaultWidth: number
  defaultCoordinates: Array<number>
  defaultXOffset: number
  defaultYOffset: number
}

interface DestinationBounds {
  height: number
  width: number
  coordinates: Array<number>
  xOffSet: number
  yOffSet: number
}

export function assetSettings(): AssetSettings {
  return {
    unitBuffer: 1,
    unitHeight: 4 + 1,
    unitWidth: 12,
    headerBuffer: 4,
    defaultHeight: 25,
    defaultWidth: 25,
    defaultCoordinates: [0, 0],
    defaultXOffset: 7,
    defaultYOffset: 4,
  }
}

export function processUnitMaxAssets(
  height: number,
  width: number,
  defaultSetting: AssetSettings
): number {
  const xMax = Math.floor(width / defaultSetting.unitWidth)
  const yMax = Math.floor(height / defaultSetting.unitHeight)

  return xMax * yMax
}

export function processCoordinateOffset(
  x: number,
  y: number,
  height: number,
  unitCount: number,
  defaultSetting: AssetSettings,
  destinationBounds: any,
  rotation: number
): Array<number> {
  let headerBuffer = defaultSetting.headerBuffer

  if (height > 15) {
    headerBuffer = 3
  }

  const yMax = Math.floor(height / defaultSetting.unitHeight)
  const xOffsetCount = Math.floor(unitCount / yMax)
  const xOffset = xOffsetCount * defaultSetting.unitWidth

  // modding unit count against yMax will kick the asset back to
  // the top if its reached yMax
  const unitYOffset = (unitCount % yMax) * defaultSetting.unitHeight
  const yOffset = headerBuffer + unitYOffset

  const { x: transX, y: transY } = applyRotatedOffset(
    { x, y },
    xOffset + destinationBounds.xOffSet,
    yOffset,
    rotation
  )

  return [transX, transY]
}

export function getDestinationBounds(
  control: any, // eslint-disable-line @typescript-eslint/no-explicit-any
  destinationID: number,
  defaultSetting: AssetSettings
): DestinationBounds {
  const destinationBounds: DestinationBounds = {
    height: defaultSetting.defaultHeight,
    width: defaultSetting.defaultWidth,
    coordinates: defaultSetting.defaultCoordinates,
    xOffSet: defaultSetting.defaultXOffset,
    yOffSet: defaultSetting.defaultYOffset,
  }

  const destination = control.activeVenue.destinations.getById(destinationID)

  const units = control.getUnitsFromDestination(destination)
  const bounds = control.getBoundsFromShapes(units)

  if (
    bounds &&
    !isNaN(bounds.height) &&
    !isNaN(bounds.width) &&
    units &&
    units[0]
  ) {
    // flatten all points from all units into a single array of points
    const mergedPoints: number[][] = units.reduce(
      (acc: number[][], unit: any) => [...acc, ...unit._points],
      []
    )

    // find top left point
    const topLeftPoint = calculateCornerPoint(mergedPoints, 'topLeft')

    // TODO: future iterations/more complex maps might prove that using bounds for height calculation is a bad idea
    destinationBounds.height = Math.floor(bounds.height)
    destinationBounds.width = Math.floor(bounds.width)
    destinationBounds.coordinates[0] = Math.floor(topLeftPoint[0])
    destinationBounds.coordinates[1] = Math.floor(topLeftPoint[1])
  } else {
    const destination = control.activeVenue.destinations._items.filter(
      (x: any) => x.id === destinationID // eslint-disable-line @typescript-eslint/no-explicit-any
    )
    const coords = destination[0].waypoints[0].coordinates

    if (coords.length > 0 && !isNaN(coords[0]) && !isNaN(coords[1])) {
      destinationBounds.coordinates[0] = Math.floor(coords[0])
      destinationBounds.coordinates[1] = Math.floor(coords[1])
    }

    destinationBounds.xOffSet = 4
    destinationBounds.yOffSet = 0
  }

  return destinationBounds
}

export function destinationPositions(
  control: any, // eslint-disable-line @typescript-eslint/no-explicit-any
  destinationID: number,
  rotation: number
): Array<Coordinates> {
  const defaultSetting = assetSettings()
  const destinationBounds = getDestinationBounds(
    control,
    destinationID,
    defaultSetting
  )
  const maxAssets = processUnitMaxAssets(
    destinationBounds.height,
    destinationBounds.width,
    defaultSetting
  )

  const coordinateData = [] as Array<Coordinates>
  let i = 0

  do {
    const tempCoords = processCoordinateOffset(
      destinationBounds.coordinates[0],
      destinationBounds.coordinates[1],
      destinationBounds.height,
      i,
      defaultSetting,
      destinationBounds,
      rotation
    )

    if (tempCoords.length) {
      coordinateData.push({ x: tempCoords[0], y: tempCoords[1] })
    }

    i++
  } while (i <= maxAssets)

  return coordinateData
}

/**
 * @deprecated Removing destination repositioning for now
 */
export function buildDestinationData(
  control: any,
  rotation = 0
): DestinationPositions {
  const returnData = {} as DestinationPositions

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control.activeVenue.destinations._items.forEach((x: any) => {
    const coords = destinationPositions(control, x.id, rotation)
    returnData[x.id] = coords
  })

  return returnData
}

const applyRotatedOffset = (
  coordinate: Coordinates,
  xOffSet: number,
  yOffSet: number,
  rotation: number
): Coordinates => {
  if (rotation === 0) {
    return {
      x: coordinate.x + xOffSet,
      y: coordinate.y + yOffSet,
    }
  }

  return transformWithRotation(
    rotation,
    { x: coordinate.x, y: coordinate.y },
    { x: coordinate.x + xOffSet, y: coordinate.y + yOffSet }
  )
}
