import { SelectableLocationsState, SelectableLocations } from '../../models'
import { EntityResult, useEntities } from '../useEntities'
import { getSelectableLocationsAction } from '../../actions/selectableLocations'

const useFetchSelectableLocations = (): EntityResult<SelectableLocations> =>
  useEntities<SelectableLocationsState, SelectableLocations>(
    () => getSelectableLocationsAction.request(),
    (state: any) => state.selectableLocations // eslint-disable-line @typescript-eslint/no-explicit-any
  )

export { useFetchSelectableLocations }
