import { getType } from 'typesafe-actions'
import { OperationsPermissionAction } from '../actions'
import { getOperationsPermissionsAction } from '../actions/operationsPermissions'
import { Permission, Permissions } from '@midmarkrtls/common/models/permissions'
import { mergeRecords } from '@midmarkrtls/common/utils'
import { FetchingStatus } from '@midmarkrtls/common/models/fetchingStatus'
import { OperationsPermissionsState } from 'v2/models'

const initialState: OperationsPermissionsState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

const key = 'id'
const operationsPermissionsReducer = (
  state: OperationsPermissionsState = initialState,
  action: OperationsPermissionAction
): OperationsPermissionsState => {
  switch (action.type) {
    case getType(getOperationsPermissionsAction.success):
      return {
        data: mergeRecords<Permissions, Permission, typeof key>(
          state.data,
          action.payload,
          key
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }

    case getType(getOperationsPermissionsAction.request):
      return {
        ...state,
        status: FetchingStatus.Request,
      }

    case getType(getOperationsPermissionsAction.failure):
      return {
        ...state,
        status: FetchingStatus.Failure,
      }

    default:
      return state
  }
}

export default operationsPermissionsReducer
