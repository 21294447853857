import { ChannelType } from '../models'
import { BaseService } from './baseService'

export class ChannelTypesApi extends BaseService {
  constructor() {
    super({ entityType: 'channelTypes' })
  }
  route = 'alert/channels'

  async getAllChannelTypes(): Promise<ChannelType[]> {
    return await this.get<ChannelType[]>(this.route)
  }
}
