import { FetchingState } from '../interfaces/fetchingState'

export interface Unit {
  id?: string
  name: string
}

export interface Units {
  [id: string]: Unit
}

export type UnitsState = Readonly<FetchingState<Units>>

export interface UnitPlusColor extends Unit {
  unitColor: string
}

export interface UnitGeoWithColor {
  geoLocationId?: string
  unitColor?: string
}

export const allUnits: Unit = {
  id: '-1',
  name: 'All Units',
}
