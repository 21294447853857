export default class WebWorker {
  private code: string
  private blob: Blob

  constructor(worker: any) {
    this.code = worker.toString()

    this.blob = new Blob(['(' + this.code + ')()'])
  }

  public build(): Worker {
    return new Worker(URL.createObjectURL(this.blob))
  }
}
