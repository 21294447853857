import moment from 'moment'
import { useEffect, useRef, useState } from 'react'

export interface IntervalOptions {
  timeAgoText: string
  clearInterval: () => void
}

/**
 * This hook takes a fromDate as an argument, calculates the elapsed time at every specified
 * interval of time and output the updated time elapsed as a string
 * @param fromDate
 * @param interval
 * @returns time elapsed as a string, e.g.
 * 0 - 44 secs => "a few seconds ago"
 * 45 - 89 secs => "a minute ago"
 * 90 secs - 44 mins =>  "2 minutes ago" ... "44 minutes ago"
 * 45 mins - 89 mins => "an hour ago"
 * 90 mins - 21 hours => "2 hours ago" ... "21 hours ago"
 */
export const useTimeAgo = (
  fromDate: Date | undefined,
  interval: number
): IntervalOptions => {
  const idRef = useRef<NodeJS.Timeout>()
  const [timeAgoText, setTimeAgoText] = useState<string>('')

  const clearIntervalRef = () => {
    if (idRef.current) {
      clearInterval(idRef.current)
      setTimeAgoText('')
      idRef.current = undefined
    }
  }

  // Set up the interval.
  useEffect(() => {
    if (!idRef.current && fromDate) {
      setTimeAgoText(moment(fromDate).fromNow())
      idRef.current = setInterval(() => {
        setTimeAgoText(moment(fromDate).fromNow())
      }, interval)
    }
    return () => {
      clearIntervalRef()
    }
  }, [idRef, fromDate, interval])

  return { timeAgoText, clearInterval: clearIntervalRef }
}

export default useTimeAgo
