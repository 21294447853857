import { useMemo } from 'react'
import { useFilters, useSearchFilter } from '.'
import { Criteria, CustomSearchTypes } from '../../filters'

export const useSearchAndFilters = <D extends object = {}>(
  data: D[] | undefined,
  filters: Criteria<D>[],
  ignorePropNames?: (keyof D)[],
  customTypes?: CustomSearchTypes
): D[] | undefined => {
  const { filter: searchFilter } = useSearchFilter<D>(
    ignorePropNames,
    customTypes
  )

  const searchAndFilters = useMemo(
    () => [searchFilter, ...filters],
    [searchFilter, filters]
  )

  return useFilters<D>(data, searchAndFilters)
}

export default useSearchAndFilters
