import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Button from '@mui/material/Button'
import { Authentication } from '../../authentication/AuthenticationManager'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    width: '312px',
    height: '15px',
    margin: '0 165px 12px 166px',
    fontFamily: 'Ubuntu',
    fontSize: '24px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#000000',
  },
  body: {
    width: '643px',
    height: '16px',
    margin: '10px 0 0',
    fontFamily: 'Ubuntu',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#000000',
  },
  button: {
    width: '154px',
    height: '36px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '12px',
    margin: '24px 642px 0',
    padding: '10px 15px',
    objectFit: 'contain',
    borderRadius: '4px',
    backgroundColor: '#165788',
    color: '#FFFFFF',
  },
}))

const DownForMaintenance = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className='table'>
        <div className={classes.container}>
          <div className={classes.header}>
            We’re Making Some Updates
            <br />
          </div>
        </div>
        <div className={classes.container}>
          <div className={classes.body}>
            Midmark RTLS Cloud is currently undergoing system maintenance. Check
            back soon.
          </div>
        </div>
        <div className={classes.container}>
          <Button
            className={classes.button}
            onClick={(): void => Authentication.logout()}
          >
            RETURN TO LOGIN
          </Button>
        </div>
      </div>
    </div>
  )
}

export { DownForMaintenance }
