import { getType } from 'typesafe-actions'
import { ChannelTypeAction } from '../actions'
import { getAllChannelTypesAction } from '../actions/channelTypes'
import { ChannelType, ChannelTypes, ChannelTypesState } from '../models'
import { FetchingStatus } from '../models/fetchingStatus'
import { mergeRecords, setFetchingStatus } from '../utils'

const initialState: ChannelTypesState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

export default (
  state: ChannelTypesState = initialState,
  action: ChannelTypeAction
): ChannelTypesState => {
  switch (action.type) {
    case getType(getAllChannelTypesAction.success):
      return {
        data: mergeRecords<ChannelTypes, ChannelType, 'id'>(
          state.data,
          action.payload,
          'id'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getAllChannelTypesAction.request):
      return setFetchingStatus<ChannelTypes>(state, FetchingStatus.Request)
    case getType(getAllChannelTypesAction.failure):
      return setFetchingStatus<ChannelTypes>(state, FetchingStatus.Failure)
    default:
      return state
  }
}
