// eslint-disable-next-line @typescript-eslint/ban-types
export const shouldResetPageIndex = (
  recordCount: number,
  pageIndex: number,
  pageSize: number
): boolean => {
  const maxIndex =
    recordCount % pageSize > 0
      ? Math.floor(recordCount / pageSize)
      : Math.floor(recordCount / pageSize) - 1
  if (pageIndex > maxIndex) {
    console.info(
      `Invalid page index of ${pageIndex}.  Resetting page index automatically`
    )
    return true
  }
  return false
}
