import { AssetInstance, IconType } from '../../models'

export const inpixonAssetIsOfType = (asset: AssetInstance, type: string) =>
  asset?._type?.includes(type)

export function getGeofenceIdFromInpixonAssetType(
  asset: AssetInstance
): number | undefined {
  if (!asset._type) {
    return
  }
  const index = asset._type.indexOf('_')
  if (index > -1) {
    const isGroupIcon = asset._type.slice(0, index) === IconType.Group
    if (!isGroupIcon) {
      return
    }
    const geofenceId = asset._type.slice(index + 1, asset._type.length)
    if (!!geofenceId && /^-?\d+$/.test(geofenceId)) {
      return Number(geofenceId)
    }
  }
}

export function getTrackingIdFromInpixonAssetType(
  asset: AssetInstance
): string | undefined {
  if (!asset._type) {
    return
  }
  const index = asset._type.indexOf('_')
  if (index > -1) {
    const isGroupIcon = asset._type.slice(0, index) === IconType.Group
    if (isGroupIcon) {
      return
    }
    const trackingId = asset._type.slice(index + 1, asset._type.length)
    if (trackingId.trim().length > 0) {
      return trackingId
    }
  }
}
