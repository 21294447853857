import { moveAgentToAGroup } from '.'
import { AssetKitInstance } from '../../models'
import { MapRenderByGeofence } from '../../models/realtimeMap'
import { BadgeTelemetryMessageWithAgentEvent } from '../../models/telemetry'
import {
  calculateGroupUpdateForEnteringGeofence,
  calculateLeavingGeofence,
  calculateLeavingGeofenceMapUpdatesToMoveAgent,
} from '../calculateRTMapUpdates'
import { buildGroupIconUrl } from '../inpixonIcons'
import {
  calculateMapRenderToUpdateForAgentOnMap,
  checkRemainingAgentsHaveDuress,
} from '../realtimeMap'
/**
 * Once the MapUpdateType has been determined to be MoveAgent from leaving geofence and
 * UpdateGroup in entering geofence, use this function to process the corresponding
 * map rerendering for the received telemetry. Calculate the required configs followed by:
 * 1. Move agent to the new geofence
 * 2. Hide the arrived agent icon
 * 3. Update the current group icon at the entering geofence with new group count
 * Finally, calculate and return the updated map render state
 * @param newAgentTelemetry
 * @param currentMapRender
 * @param assetKit
 * @param jibestreamController
 * @param geofenceInstances
 * @param useGeofenceCentroid
 * @param iconScale
 * @param selectedTrackingId
 * @param selectedGroupId
 * @param addGeofenceHighlight
 * @param removeGeofenceHighlight
 * @returns updated map render state after map rerendering
 */
export async function moveAgentAndUpdateGroup(
  newAgentTelemetry: BadgeTelemetryMessageWithAgentEvent,
  currentMapRender: MapRenderByGeofence,
  assetKit: AssetKitInstance,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  geofenceInstances: any[],
  useGeofenceCentroid: boolean,
  iconScale: number,
  selectedTrackingId: string | undefined,
  selectedGroupId: number | undefined,
  addGeofenceHighlight: (geofenceId: number) => void,
  removeGeofenceHighlight: (geofenceId: number) => void
): Promise<MapRenderByGeofence> {
  const leavingAgentToUpdatePosition =
    calculateLeavingGeofenceMapUpdatesToMoveAgent(
      newAgentTelemetry,
      currentMapRender
    )
  const enteringGeofenceExistingGroupUpdate =
    calculateGroupUpdateForEnteringGeofence(
      newAgentTelemetry,
      currentMapRender,
      selectedTrackingId,
      selectedGroupId
    )
  if (!leavingAgentToUpdatePosition || !enteringGeofenceExistingGroupUpdate) {
    return currentMapRender
  }
  const { enteringAgentsToHide, groupToUpdateIcon } =
    enteringGeofenceExistingGroupUpdate
  const url = buildGroupIconUrl(groupToUpdateIcon.groupCount, iconScale, {
    hasActiveDuress: groupToUpdateIcon.hasActiveDuress,
    hasActiveAssist: groupToUpdateIcon.hasActiveAssist,
    isSelected: groupToUpdateIcon.isSelected,
  })

  const leavingGeofenceData = calculateLeavingGeofence(
    newAgentTelemetry.trackingId,
    currentMapRender
  )
  let remainingAgentsHaveDuress = false
  if (leavingGeofenceData) {
    remainingAgentsHaveDuress = checkRemainingAgentsHaveDuress(
      currentMapRender,
      newAgentTelemetry.trackingId,
      leavingGeofenceData.prevAgentTelemetry.geoFenceId
    )
  }

  await moveAgentToAGroup(
    leavingAgentToUpdatePosition,
    {
      enteringAgentsToHide,
      groupIconToUpdate: {
        geofenceId: groupToUpdateIcon.geoFenceId,
        url,
      },
    },
    assetKit,
    jibestreamController,
    geofenceInstances,
    useGeofenceCentroid,
    addGeofenceHighlight,
    removeGeofenceHighlight,
    remainingAgentsHaveDuress
  )
  const updatedMapRender = calculateMapRenderToUpdateForAgentOnMap(
    currentMapRender,
    newAgentTelemetry
  )
  return updatedMapRender
}
