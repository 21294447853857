import { getType } from 'typesafe-actions'
import { ManufacturerAction } from '../actions'
import { getAllManufacturersAction } from '../actions/manufacturers'
import { Manufacturer, Manufacturers, ManufacturersState } from '../models'
import { FetchingStatus } from '../models/fetchingStatus'
import { mergeRecords, setFetchingStatus } from '../utils'

const initialState: ManufacturersState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

export default (
  state: ManufacturersState = initialState,
  action: ManufacturerAction
): ManufacturersState => {
  switch (action.type) {
    case getType(getAllManufacturersAction.success):
      return {
        data: mergeRecords<Manufacturers, Manufacturer, 'manufacturerGuid'>(
          state.data,
          action.payload,
          'manufacturerGuid'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }

    case getType(getAllManufacturersAction.request):
      return setFetchingStatus<Manufacturers>(state, FetchingStatus.Request)
    case getType(getAllManufacturersAction.failure):
      return setFetchingStatus<Manufacturers>(state, FetchingStatus.Failure)

    default:
      return state
  }
}
