import { AssetKitInstance, IconType } from '../../models'
import {
  MapRenderByGeofence,
  StaffDuressAssistAgentIconUpdate,
  StaffDuressAssistGroupIconUpdate,
} from '../../models/realtimeMap'
import { BadgeTelemetryMessageWithAgentEvent } from '../../models/telemetry'
import {
  updateAgentOnMapWithResolvedAgentEvent,
  updateGroupOnMapWithResolvedAgentEvent,
} from '../processRTMapUpdates'
import { getConfidenceBubbleConfig } from '../inpixon'
import { calculateMapRenderToUpdateForAgentOnMap } from '../realtimeMap'

/**
 * Once the MapUpdateType has been determined to be ResolvedDuressOrAssist, use this function to
 * process the corresponding map rerendering for the received telemetry.
 * Calculate the required configs followed by updating the currently rendered icon on the map
 * (whether Group or Asset/Staff icon) to remove highlighting.
 * If agent is currently rendered as a group icon on the map, the updated group icon is evaluated
 * to determine if the remaining agents still have any active duress/assist or not before removing
 * the icon and geofence highlighting.
 * NOTE: Currently, it is assumed that a geofence will NEVER have both duress AND assist.
 * If agent is currently selected on the map or is an agent of a currently selected group icon
 * on the map, update the RightDrawerState accordingly in order to update relevant duress/assist
 * details in the Detail Drawer (icons, Assist text, etc.)
 * Finally, calculate and return the updated map render state
 * @param newAgentTelemetry
 * @param currentMapRender
 * @param assetKit
 * @param jibestreamController
 * @param selectedTrackingId
 * @param selectedGroupId
 * @param updateAgentInSelectedGroup
 * @param updateSelectedAgent
 * @param removeGeofenceHighlight
 * @returns updated map render state after map rerendering
 */
export function processMapUpdatesForResolvedAgentEvent(
  newAgentTelemetry: BadgeTelemetryMessageWithAgentEvent,
  currentMapRender: MapRenderByGeofence,
  assetKit: AssetKitInstance,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any,
  selectedTrackingId: string | undefined,
  selectedGroupId: number | undefined,
  updateAgentInSelectedGroup: (
    agentTelemetry: BadgeTelemetryMessageWithAgentEvent
  ) => void,
  updateSelectedAgent: (
    updatedAgent: BadgeTelemetryMessageWithAgentEvent
  ) => void,
  removeGeofenceHighlight: (geofenceId: number) => void
): MapRenderByGeofence {
  const mapRender = currentMapRender[newAgentTelemetry.geoFenceId]
  if (mapRender) {
    const agentIsInGroup =
      mapRender.renderedAgents.length >= mapRender.maxAgentsAllowedInGeofence
    if (agentIsInGroup) {
      const remainingAgentsInGroup = mapRender.renderedAgents.filter(
        (telemetry) => telemetry.trackingId !== newAgentTelemetry.trackingId
      )
      const remainingAgentsHaveActiveDuress = remainingAgentsInGroup.some(
        (telemetry) => telemetry.hasActiveDuress
      )
      const remainingAgentsHaveActiveAssist = remainingAgentsInGroup.some(
        (telemetry) => telemetry.hasActiveAssist
      )
      if (
        !remainingAgentsHaveActiveDuress &&
        !remainingAgentsHaveActiveAssist
      ) {
        const resolvedEventGroupIconToUpdate: StaffDuressAssistGroupIconUpdate =
          {
            groupByType: {
              geofenceId: newAgentTelemetry.geoFenceId,
              iconType: IconType.Group,
            },
            hasActiveDuress: false,
            hasActiveAssist: false,
            isSelected: newAgentTelemetry.geoFenceId === selectedGroupId,
          }
        updateGroupOnMapWithResolvedAgentEvent(
          resolvedEventGroupIconToUpdate,
          assetKit,
          jibestreamController,
          removeGeofenceHighlight
        )
      }
      if (newAgentTelemetry.geoFenceId === selectedGroupId) {
        updateAgentInSelectedGroup(newAgentTelemetry)
      }
      if (newAgentTelemetry.trackingId === selectedTrackingId) {
        updateSelectedAgent(newAgentTelemetry)
      }
    } else {
      const confidenceBubbleConfig = getConfidenceBubbleConfig(
        newAgentTelemetry.signalTypeId,
        IconType.Staff,
        {
          hasActiveDuress: false,
          hasActiveAssist: false,
          isSelected: newAgentTelemetry.trackingId === selectedTrackingId,
        }
      )
      const resolvedEventAgentIconToUpdate: StaffDuressAssistAgentIconUpdate = {
        agentByType: {
          trackingId: newAgentTelemetry.trackingId,
          iconType: IconType.Staff,
        },
        geofenceId: newAgentTelemetry.geoFenceId,
        confidenceBubbleConfig,
      }
      updateAgentOnMapWithResolvedAgentEvent(
        resolvedEventAgentIconToUpdate,
        assetKit,
        jibestreamController,
        removeGeofenceHighlight
      )
      if (newAgentTelemetry.trackingId === selectedTrackingId) {
        updateSelectedAgent(newAgentTelemetry)
      }
    }
    const updatedMapRender = calculateMapRenderToUpdateForAgentOnMap(
      currentMapRender,
      newAgentTelemetry
    )
    return updatedMapRender
  } else {
    return currentMapRender
  }
}
