import React, { useCallback, useEffect } from 'react'
import { Grid, FormControlLabel, RadioGroup } from '@mui/material'
import FilterDeleteButton from './FilterDeleteButton'
import RadioSavedFilterButton from '../Filters/RadioSavedFilterButton'
import { FilterDeleteModel } from '../../models'
import { useDispatch } from 'react-redux'
import { deleteFiltersAction } from '../../actions/filters'
import { useAuth, useSelectedSavedFilter } from '../../hooks'
import { DataTestIds } from '../../utils/test-utils/dataTestIds'

interface Props<T> {
  savedFilters: T[]
  isEditable: boolean
  handleChange: (changes: Partial<T>) => void
  handleDelete: () => void
  filterCount: number
}

interface SavedFilter {
  filterName?: string
  filterGuid?: string
}

const SavedFiltersList = <T extends SavedFilter>(
  props: Props<T>
): JSX.Element => {
  const { savedFilters, isEditable, handleChange, handleDelete, filterCount } =
    props

  const { user } = useAuth()
  const dispatch = useDispatch()

  const { selectedSavedFilter, setSelectedSavedFilter } =
    useSelectedSavedFilter()

  const handleFilterDelete = useCallback(
    (event: React.FormEvent, filterGuid: string) => {
      event?.stopPropagation()
      const deletedFilter: FilterDeleteModel = {
        id: filterGuid,
        userGuid: user.id,
      }
      dispatch(deleteFiltersAction.request(deletedFilter))
      handleDelete()
    },
    [deleteFiltersAction, dispatch, handleDelete]
  )

  const sortedFilters = savedFilters.sort((a, b) =>
    (a.filterName ?? '').localeCompare(b.filterName ?? '')
  )

  useEffect(() => {
    if (Object.keys(savedFilters).length === 0) {
      setSelectedSavedFilter('')
    }
  }, [savedFilters, setSelectedSavedFilter])

  useEffect(() => {
    // If no filters are applied, uncheck any saved filters that
    // might be checked
    if (filterCount === 0) {
      setSelectedSavedFilter('')
    }
  }, [filterCount, setSelectedSavedFilter])

  return (
    <>
      {sortedFilters.map((savedFilter) => {
        return (
          <Grid
            key={savedFilter.filterGuid}
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Grid item xs={isEditable ? 10 : 12}>
              <RadioGroup
                value={NaN}
                onChange={(): void => {
                  handleChange(savedFilter ?? {})
                }}
              >
                <FormControlLabel
                  control={
                    <RadioSavedFilterButton
                      key={savedFilter.filterGuid}
                      color='primary'
                      value={savedFilter.filterGuid}
                      checked={selectedSavedFilter == savedFilter.filterGuid}
                      onClick={(event): void => {
                        // handles the check and uncheck of the radio button
                        const target = event.target as HTMLInputElement
                        target.value === selectedSavedFilter
                          ? setSelectedSavedFilter('')
                          : setSelectedSavedFilter(target.value)
                        handleChange(
                          selectedSavedFilter === '' ? savedFilter : {}
                        )
                      }}
                      checkBoxDisabled={isEditable}
                      data-testid={`${DataTestIds.savedFiltersRadioButton}-${savedFilter.filterGuid}`}
                    />
                  }
                  label={savedFilter?.filterName ?? ''}
                  style={{
                    overflowWrap: 'anywhere',
                    // This overrides the text color when the field is disabled so the text is still readable
                    WebkitTextFillColor: '#000000',
                  }}
                  disabled={isEditable}
                  data-testid={`${DataTestIds.savedFiltersControl}-${savedFilter.filterGuid}`}
                />
              </RadioGroup>
            </Grid>
            {isEditable && (
              <Grid item xs={2}>
                <FilterDeleteButton
                  key={savedFilter.filterGuid}
                  filter={savedFilter}
                  handleDelete={handleFilterDelete}
                  dataTestId={`${DataTestIds.savedFiltersDeleteButton}-${savedFilter.filterGuid}`}
                />
              </Grid>
            )}
          </Grid>
        )
      })}
    </>
  )
}

export default SavedFiltersList
