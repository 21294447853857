/*
 *  Defines the styling used for form inputs as inputStyle.
 *  Additionally defines styling for buttons in our form layout
 */

import createStyles from '@mui/styles/createStyles'

import makeStyles from '@mui/styles/makeStyles'
import { colors } from './MidmarkTheme'

export const inputLabelStyles = createStyles({
  formControl: {
    top: 0,
    left: 0,
    position: 'absolute',
    transform: 'translate(12px, 20px) scale(1)',
  },
  shrink: {
    transform: 'translate(14px, 7px) scale(0.75)',
  },
})

export const useFormStyle = makeStyles(() =>
  createStyles({
    inputStyle: inputStyle,
  })
)

export const inputStyle = {
  '& .MuiFilledInput-root': {
    backgroundColor: 'rgba(0, 0, 0, 0)',
  },
  '& .MuiFilledInput-root.Mui-disabled': {
    backgroundColor: colors.offWhite,
  },
  '& .MuiFilledInput-underline:before': {
    border: '1px solid rgba(0, 0, 0, 0.42)',
    borderRadius: '4px',
    transition: 'transform 0ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    height: '100%',
  },
  '& .MuiFilledInput-underline:after': {
    border: '2px solid #236092',
    borderRadius: '4px',
    transition: 'transform 0ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    height: '100%',
  },
  '& .MuiFilledInput-underline.Mui-disabled:before': {
    borderBottomStyle: 'solid',
  },
  '& .MuiFilledInput-underline.Mui-error:after': {
    border: '1px solid #f44336',
  },
  '& .MuiFilledInput-input': {
    padding: '22px 15px 15px',
  },
  '& .MuiOutlinedInput-input': {
    padding: '16px 14px',
  },
  '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
    transform: 'translate(15px, 7px) scale(0.75)',
  },
  '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
    transform: 'translate(14px, -6px) scale(0.75)',
  },
  '& .MuiInputLabel-formControl': {
    top: 0,
    left: 0,
    position: 'absolute',
    transform: 'translate(12px, 20px) scale(1)',
  },
  '& .MuiFilledInput-adornedEnd': {
    paddingRight: 0,
  },
  '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]': {
    paddingTop: '13px',
    paddingLeft: '11px',
    paddingBottom: '6px',
  },
}

export const leftButton = {
  marginLeft: 0,
  right: '8px',
  marginTop: '10px',
}

export const cancelWithDelete = {
  marginRight: 0,
  left: '150px',
  marginTop: '10px',
}

export const cancelWithoutDelete = {
  marginRight: 0,
  left: '193px',
  marginTop: '10px',
}

export const rightButton = {
  marginRight: 0,
  left: '7px',
  marginTop: '10px',
}
