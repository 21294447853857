import React from 'react'
import { colors } from '../../styles'
import { Button, GenericButtonProps } from './Button'

const PrimaryContainedButton = (props: GenericButtonProps): JSX.Element => (
  <Button
    {...props}
    bgColor={props.disabled ? colors.darkGrey : colors.primary}
    textColor={colors.white}
    variant='contained'
  >
    {props.children}
  </Button>
)

export default PrimaryContainedButton
