import { useMemo } from 'react'
import { Criteria, CustomSearchTypes, batteryStatusSearch } from '../../filters'
import { Asset, Staff } from '../../models'
import { useSearchFilter } from '../filters'

const assetFilterIgnoredPropNames: (keyof Asset)[] = [
  'agentGuid',
  'assetTypeId',
  'assetSubTypeId',
  'hasWorkOrder',
  'hasRecall',
  'iconUrl',
  'badgeTelemetry',
  'tagBatteryChangeDate',
]

const customSearchTypes: CustomSearchTypes = {
  preventativeMaintenanceDate: 'datetime',
  isLowBattery: batteryStatusSearch,
}

const staffFilterIgnoredPropNames: (keyof Staff)[] = [
  'agentGuid',
  'badgeTelemetry',
]

export function useRTMapSearchAndFilters(
  assetFilterCriteria: Array<Criteria<Asset>>,
  staffFilterCriteria: Array<Criteria<Staff>>
): {
  assetSearchAndFilterCriteria: Array<Criteria<Asset>>
  staffSearchAndFilterCriteria: Array<Criteria<Staff>>
} {
  const { filter: assetSearchFilter } = useSearchFilter<Asset>(
    assetFilterIgnoredPropNames,
    customSearchTypes
  )
  const { filter: staffSearchFilter } = useSearchFilter<Staff>(
    staffFilterIgnoredPropNames,
    customSearchTypes
  )
  const assetSearchAndFilterCriteria = useMemo(() => {
    return [...assetFilterCriteria, assetSearchFilter]
  }, [assetSearchFilter, assetFilterCriteria])
  const staffSearchAndFilterCriteria = useMemo(() => {
    return [...staffFilterCriteria, staffSearchFilter]
  }, [staffFilterCriteria, staffSearchFilter])
  return {
    assetSearchAndFilterCriteria,
    staffSearchAndFilterCriteria,
  }
}
