import React, {
  Dispatch,
  ProviderProps,
  createContext,
  useCallback,
  useContext,
  useReducer,
} from 'react'
import { AssetKitInstance, JibestreamInstance } from '../../models'
import { GeofenceInstance, GeofenceKitInstance } from '../../models/jibestream'

export type InpixonState = {
  jibestream: JibestreamInstance | undefined
  assetKit: AssetKitInstance | undefined
  geofenceKit: GeofenceKitInstance | undefined
  geofenceInstances: GeofenceInstance[]
}

const initialState: InpixonState = {
  jibestream: undefined,
  assetKit: undefined,
  geofenceKit: undefined,
  geofenceInstances: [],
}

const InpixonStateContext = createContext<InpixonState>(initialState)
const InpixonDispatchContext = createContext<
  React.Dispatch<InpixonActions> | undefined
>(undefined)

function useInpixonState(): InpixonState {
  const context = useContext(InpixonStateContext)
  if (context === undefined) {
    throw new Error('Context missing for useInpixonState')
  }
  return context
}

function useInpixonDispatch(): Dispatch<InpixonActions> {
  const context = useContext(InpixonDispatchContext)
  if (context === undefined) {
    throw new Error('Context missing for useInpixonDispatch')
  }
  return context
}

export enum Actions {
  SetJibestream,
  SetAssetKit,
  SetGeofenceKit,
  SetGeofenceInstances,
}

type SetJibestreamAction = {
  type: Actions.SetJibestream
  payload: JibestreamInstance
}

type SetAssetKitAction = {
  type: Actions.SetAssetKit
  payload: AssetKitInstance | undefined
}

type SetGeofenceKitAction = {
  type: Actions.SetGeofenceKit
  payload: GeofenceKitInstance | undefined
}

type SetGeofenceInstancesAction = {
  type: Actions.SetGeofenceInstances
  payload: GeofenceInstance[]
}

type InpixonActions =
  | SetJibestreamAction
  | SetAssetKitAction
  | SetGeofenceKitAction
  | SetGeofenceInstancesAction

export function inpixonReducer(
  state: InpixonState,
  action: InpixonActions
): InpixonState {
  switch (action.type) {
    case Actions.SetJibestream:
      return {
        ...state,
        jibestream: action.payload,
      }
    case Actions.SetAssetKit:
      return {
        ...state,
        assetKit: action.payload,
      }
    case Actions.SetGeofenceKit:
      return {
        ...state,
        geofenceKit: action.payload,
      }
    case Actions.SetGeofenceInstances:
      return {
        ...state,
        geofenceInstances: action.payload,
      }
    default:
      return state
  }
}

export function InpixonProvider({
  children,
}: Pick<ProviderProps<Record<string, never>>, 'children'>): JSX.Element {
  const [inpixon, setInpixon] = useReducer(inpixonReducer, initialState)
  return (
    <InpixonStateContext.Provider value={inpixon}>
      <InpixonDispatchContext.Provider value={setInpixon}>
        {children}
      </InpixonDispatchContext.Provider>
    </InpixonStateContext.Provider>
  )
}

export default function useInpixonContext(): {
  inpixonState: InpixonState
  setJibestream: (jibestream: JibestreamInstance) => void
  setAssetKit: (assetKit: AssetKitInstance | undefined) => void
  setGeofenceKit: (geofenceKit: GeofenceKitInstance | undefined) => void
  setGeofenceInstances: (geofenceInstances: GeofenceInstance[]) => void
} {
  const dispatch = useInpixonDispatch()
  const setJibestream = useCallback(
    (jibestream: JibestreamInstance) => {
      dispatch({
        type: Actions.SetJibestream,
        payload: jibestream,
      })
    },
    [dispatch]
  )

  const setAssetKit = useCallback(
    (assetKit: AssetKitInstance | undefined) => {
      dispatch({
        type: Actions.SetAssetKit,
        payload: assetKit,
      })
    },
    [dispatch]
  )

  const setGeofenceKit = useCallback(
    (geofenceKit: GeofenceKitInstance | undefined) => {
      dispatch({
        type: Actions.SetGeofenceKit,
        payload: geofenceKit,
      })
    },
    [dispatch]
  )

  const setGeofenceInstances = useCallback(
    (geofenceInstances: GeofenceInstance[]) => {
      dispatch({
        type: Actions.SetGeofenceInstances,
        payload: geofenceInstances,
      })
    },
    [dispatch]
  )

  return {
    inpixonState: useInpixonState(),
    setJibestream,
    setAssetKit,
    setGeofenceKit,
    setGeofenceInstances,
  }
}
