import {
  Button,
  IconButton,
  Slide,
  Snackbar,
  SnackbarContent,
  Theme,
  useMediaQuery,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { TransitionProps } from '@mui/material/transitions'
import { Close } from '@mui/icons-material'
import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { SnackbarType } from '../../../models/snackbarType'
import { colors } from '../../../styles'
import useSnackbar from './useSnackbar'

const SnackbarCommon: React.FC = () => {
  const { snackbarData, setSnackbarData } = useSnackbar()
  const history = useNavigate()

  const useStyles = makeStyles(() => ({
    root: {
      flexWrap: 'nowrap',
    },
  }))

  const backgroundColorByType = useMemo(() => {
    switch (snackbarData.type) {
      case SnackbarType.Error:
        return colors.error
      case SnackbarType.Warning:
        return colors.warning
      case SnackbarType.Success:
      case SnackbarType.Info:
      case SnackbarType.Generic:
      default:
        return colors.primary
    }
  }, [snackbarData.type])

  const handleClose = (e: any, reason?: string): void => {
    if (reason !== 'clickaway') {
      setSnackbarData({
        message: null,
        type: SnackbarType.None,
      })
    }
  }

  const handleLinkClick = (): void => {
    if (snackbarData?.link) {
      history(snackbarData.link)
    }
  }

  const transitionUp = useCallback((props: TransitionProps) => {
    return <Slide {...props} direction='up' />
  }, [])

  const classes = useStyles()
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))

  return (
    <>
      <Snackbar
        open={
          snackbarData?.type !== SnackbarType.None && !!snackbarData.message
        }
        autoHideDuration={snackbarData.autoClose ?? 6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleClose}
        style={{
          bottom: '0px',
          width: matches ? '900px' : '750px',
          zIndex: 999999,
        }}
        TransitionComponent={transitionUp}
      >
        <SnackbarContent
          style={{
            backgroundColor: backgroundColorByType,
            width: '100%',
          }}
          className={classes.root}
          message={snackbarData?.message}
          action={
            <>
              {snackbarData.link &&
              snackbarData.linkText &&
              snackbarData.link.length > 0 ? (
                <Button
                  style={{ color: 'white', textDecoration: 'Underline' }}
                  onClick={handleLinkClick}
                >
                  {snackbarData.linkText}
                </Button>
              ) : null}
              <IconButton onClick={handleClose} size='large'>
                <Close style={{ color: 'white' }} />
              </IconButton>
            </>
          }
        />
      </Snackbar>
    </>
  )
}

export default SnackbarCommon
