export default {
  BadgeCabinet: {
    Wired: {
      Configured: (hasIssues: boolean) =>
        `<svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 30 30">
                    <defs>
                        <filter id="a" width="225.9%" height="221.4%" x="-63%" y="-60.7%" filterUnits="objectBoundingBox">
                            <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
                            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5"/>
                            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
                            <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter2"/>
                            <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="1"/>
                            <feComposite in="shadowBlurOuter2" in2="SourceAlpha" operator="out" result="shadowBlurOuter2"/>
                            <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feOffset in="SourceAlpha" result="shadowOffsetOuter3"/>
                            <feGaussianBlur in="shadowOffsetOuter3" result="shadowBlurOuter3" stdDeviation="1"/>
                            <feComposite in="shadowBlurOuter3" in2="SourceAlpha" operator="out" result="shadowBlurOuter3"/>
                            <feColorMatrix in="shadowBlurOuter3" result="shadowMatrixOuter3" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1"/>
                                <feMergeNode in="shadowMatrixOuter2"/>
                                <feMergeNode in="shadowMatrixOuter3"/>
                                <feMergeNode in="SourceGraphic"/>
                            </feMerge>
                        </filter>
                    </defs>
                    <g fill="none" fill-rule="evenodd">
                        <g filter="url(#a)" transform="translate(1.5 2)">
                            <path fill="#78BE20" stroke="#78BE20" stroke-width="2.4" d="M13.5 2.083L2.071 10.387l4.366 13.435h14.126l4.366-13.435L13.5 2.083z"/>
                            <path fill="#FFF" fill-rule="nonzero" d="M9.152 17.743c-.436 0-.87-.014-1.303-.04a9.209 9.209 0 0 1-1.349-.19V9.798c.367-.068.77-.12 1.205-.155.437-.034.842-.051 1.217-.051.505 0 .97.036 1.395.109.425.073.79.197 1.096.373.307.176.546.408.718.695.172.287.258.64.258 1.061 0 .636-.306 1.137-.918 1.504.505.192.85.452 1.033.78.184.33.276.701.276 1.114 0 .835-.305 1.462-.913 1.883-.609.421-1.514.632-2.715.632zm-.907-3.57v2.054c.13.016.272.027.425.035.153.007.321.011.505.011.536 0 .968-.076 1.297-.23.33-.152.494-.436.494-.849 0-.367-.138-.63-.414-.786-.275-.157-.67-.236-1.182-.236H8.245zm0-1.367h.872c.551 0 .946-.07 1.183-.212.237-.142.356-.37.356-.683 0-.322-.123-.547-.368-.678-.245-.13-.604-.195-1.079-.195-.153 0-.317.002-.493.006a5.36 5.36 0 0 0-.471.029v1.733zm9.409 4.994c-1.294 0-2.28-.36-2.956-1.08-.678-.719-1.016-1.74-1.016-3.064 0-.658.103-1.246.31-1.762.206-.517.49-.953.849-1.31.36-.355.788-.625 1.286-.808a4.638 4.638 0 0 1 1.618-.276c.337 0 .643.025.919.075.275.05.516.107.723.172a3.569 3.569 0 0 1 .815.361l-.516 1.447a4.081 4.081 0 0 0-1.963-.47c-.276 0-.546.045-.81.137a1.83 1.83 0 0 0-.7.442 2.202 2.202 0 0 0-.488.786c-.122.322-.184.712-.184 1.171 0 .368.04.71.12 1.028.081.317.211.591.391.82.18.23.415.412.706.546.291.134.643.2 1.056.2a4.813 4.813 0 0 0 1.252-.154c.16-.042.302-.09.424-.144.123-.053.234-.103.333-.149l.494 1.435c-.253.153-.609.29-1.068.413-.459.123-.99.184-1.595.184z"/>
                        </g>
                        ${
                          hasIssues
                            ? '<circle cx="24.375" cy="5.625" r="4.375" fill="#E54360"/>'
                            : ''
                        }
                    </g>
                </svg>`,
      Unconfigured: (hasIssues: boolean) =>
        `<svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 30 30">
                    <defs>
                        <filter id="a" width="225.9%" height="221.4%" x="-63%" y="-60.7%" filterUnits="objectBoundingBox">
                            <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
                            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5"/>
                            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
                            <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter2"/>
                            <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="1"/>
                            <feComposite in="shadowBlurOuter2" in2="SourceAlpha" operator="out" result="shadowBlurOuter2"/>
                            <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feOffset in="SourceAlpha" result="shadowOffsetOuter3"/>
                            <feGaussianBlur in="shadowOffsetOuter3" result="shadowBlurOuter3" stdDeviation="1"/>
                            <feComposite in="shadowBlurOuter3" in2="SourceAlpha" operator="out" result="shadowBlurOuter3"/>
                            <feColorMatrix in="shadowBlurOuter3" result="shadowMatrixOuter3" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1"/>
                                <feMergeNode in="shadowMatrixOuter2"/>
                                <feMergeNode in="shadowMatrixOuter3"/>
                                <feMergeNode in="SourceGraphic"/>
                            </feMerge>
                        </filter>
                    </defs>
                    <g fill="none" fill-rule="evenodd">
                        <g filter="url(#a)" transform="translate(1.5 2)">
                            <path fill="#FFF" stroke="#2A9AD1" stroke-width="2.4" d="M13.5 2.083L2.071 10.387l4.366 13.435h14.126l4.366-13.435L13.5 2.083z"/>
                            <path fill="#2A9AD1" fill-rule="nonzero" d="M9.152 17.743c-.436 0-.87-.014-1.303-.04a9.209 9.209 0 0 1-1.349-.19V9.798c.367-.068.77-.12 1.205-.155.437-.034.842-.051 1.217-.051.505 0 .97.036 1.395.109.425.073.79.197 1.096.373.307.176.546.408.718.695.172.287.258.64.258 1.061 0 .636-.306 1.137-.918 1.504.505.192.85.452 1.033.78.184.33.276.701.276 1.114 0 .835-.305 1.462-.913 1.883-.609.421-1.514.632-2.715.632zm-.907-3.57v2.054c.13.016.272.027.425.035.153.007.321.011.505.011.536 0 .968-.076 1.297-.23.33-.152.494-.436.494-.849 0-.367-.138-.63-.414-.786-.275-.157-.67-.236-1.182-.236H8.245zm0-1.367h.872c.551 0 .946-.07 1.183-.212.237-.142.356-.37.356-.683 0-.322-.123-.547-.368-.678-.245-.13-.604-.195-1.079-.195-.153 0-.317.002-.493.006a5.36 5.36 0 0 0-.471.029v1.733zm9.409 4.994c-1.294 0-2.28-.36-2.956-1.08-.678-.719-1.016-1.74-1.016-3.064 0-.658.103-1.246.31-1.762.206-.517.49-.953.849-1.31.36-.355.788-.625 1.286-.808a4.638 4.638 0 0 1 1.618-.276c.337 0 .643.025.919.075.275.05.516.107.723.172a3.569 3.569 0 0 1 .815.361l-.516 1.447a4.081 4.081 0 0 0-1.963-.47c-.276 0-.546.045-.81.137a1.83 1.83 0 0 0-.7.442 2.202 2.202 0 0 0-.488.786c-.122.322-.184.712-.184 1.171 0 .368.04.71.12 1.028.081.317.211.591.391.82.18.23.415.412.706.546.291.134.643.2 1.056.2a4.813 4.813 0 0 0 1.252-.154c.16-.042.302-.09.424-.144.123-.053.234-.103.333-.149l.494 1.435c-.253.153-.609.29-1.068.413-.459.123-.99.184-1.595.184z"/>
                        </g>
                        ${
                          hasIssues
                            ? '<circle cx="24.375" cy="5.625" r="4.375" fill="#E54360"/>'
                            : ''
                        }
                    </g>
                </svg>`,
    },
    Wireless: {
      Configured: (hasIssues: boolean) =>
        `<svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 30 30">
                    <defs>
                        <filter id="a" width="217.2%" height="312.5%" x="-58.6%" y="-106.2%" filterUnits="objectBoundingBox">
                            <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
                            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5"/>
                            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
                            <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter2"/>
                            <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="1"/>
                            <feComposite in="shadowBlurOuter2" in2="SourceAlpha" operator="out" result="shadowBlurOuter2"/>
                            <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feOffset in="SourceAlpha" result="shadowOffsetOuter3"/>
                            <feGaussianBlur in="shadowOffsetOuter3" result="shadowBlurOuter3" stdDeviation="1"/>
                            <feComposite in="shadowBlurOuter3" in2="SourceAlpha" operator="out" result="shadowBlurOuter3"/>
                            <feColorMatrix in="shadowBlurOuter3" result="shadowMatrixOuter3" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1"/>
                                <feMergeNode in="shadowMatrixOuter2"/>
                                <feMergeNode in="shadowMatrixOuter3"/>
                                <feMergeNode in="SourceGraphic"/>
                            </feMerge>
                        </filter>
                    </defs>
                    <g fill="none" fill-rule="evenodd">
                        <g filter="url(#a)" transform="translate(0 7)">
                            <path fill="#78BE20" stroke="#78BE20" stroke-width="2.4" d="M27.347 14.792L22.984 1.208H7.001L2.638 14.792h24.709z"/>
                            <path fill="#FFF" fill-rule="nonzero" d="M10.652 12.243c-.436 0-.87-.014-1.303-.04A9.209 9.209 0 0 1 8 12.012V4.298c.367-.068.77-.12 1.205-.155.437-.034.842-.051 1.217-.051.505 0 .97.036 1.395.109.425.073.79.197 1.096.373.307.176.546.408.718.695.172.287.258.64.258 1.061 0 .636-.306 1.137-.918 1.504.505.192.85.452 1.033.78.184.33.276.701.276 1.114 0 .835-.305 1.462-.913 1.883-.609.421-1.514.632-2.715.632zm-.907-3.57v2.054c.13.016.272.027.425.035.153.007.321.011.505.011.536 0 .968-.076 1.297-.23.33-.152.494-.436.494-.849 0-.367-.138-.63-.414-.786-.275-.157-.67-.236-1.182-.236H9.745zm0-1.367h.872c.551 0 .946-.07 1.183-.212.237-.142.356-.37.356-.683 0-.322-.123-.547-.368-.678-.245-.13-.604-.195-1.079-.195-.153 0-.317.002-.493.006a5.36 5.36 0 0 0-.471.029v1.733zm9.409 4.994c-1.294 0-2.28-.36-2.956-1.08-.678-.719-1.016-1.74-1.016-3.064 0-.658.103-1.246.31-1.762.206-.517.49-.953.849-1.31.36-.355.788-.625 1.286-.808A4.638 4.638 0 0 1 19.245 4c.337 0 .643.025.919.075.275.05.516.107.723.172a3.569 3.569 0 0 1 .815.361l-.516 1.447a4.081 4.081 0 0 0-1.963-.47c-.276 0-.546.045-.81.137a1.83 1.83 0 0 0-.7.442 2.202 2.202 0 0 0-.488.786c-.122.322-.184.712-.184 1.171 0 .368.04.71.12 1.028.081.317.211.591.391.82.18.23.415.412.706.546.291.134.643.2 1.056.2a4.813 4.813 0 0 0 1.252-.154c.16-.042.302-.09.424-.144.123-.053.234-.103.333-.149l.494 1.435c-.253.153-.609.29-1.068.413-.459.123-.99.184-1.595.184z"/>
                        </g>
                        ${
                          hasIssues
                            ? '<circle cx="24.375" cy="5.625" r="4.375" fill="#E54360"/>'
                            : ''
                        }
                    </g>
                </svg>`,
      Unconfigured: (hasIssues: boolean) =>
        `<svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 30 30">
                    <defs>
                        <filter id="a" width="217.2%" height="312.5%" x="-58.6%" y="-106.2%" filterUnits="objectBoundingBox">
                            <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
                            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5"/>
                            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
                            <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter2"/>
                            <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="1"/>
                            <feComposite in="shadowBlurOuter2" in2="SourceAlpha" operator="out" result="shadowBlurOuter2"/>
                            <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feOffset in="SourceAlpha" result="shadowOffsetOuter3"/>
                            <feGaussianBlur in="shadowOffsetOuter3" result="shadowBlurOuter3" stdDeviation="1"/>
                            <feComposite in="shadowBlurOuter3" in2="SourceAlpha" operator="out" result="shadowBlurOuter3"/>
                            <feColorMatrix in="shadowBlurOuter3" result="shadowMatrixOuter3" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1"/>
                                <feMergeNode in="shadowMatrixOuter2"/>
                                <feMergeNode in="shadowMatrixOuter3"/>
                                <feMergeNode in="SourceGraphic"/>
                            </feMerge>
                        </filter>
                    </defs>
                    <g fill="none" fill-rule="evenodd">
                        <g filter="url(#a)" transform="translate(0 7)">
                            <path fill="#FFF" stroke="#2A9AD1" stroke-width="2.4" d="M27.347 14.792L22.984 1.208H7.001L2.638 14.792h24.709z"/>
                            <path fill="#2A9AD1" fill-rule="nonzero" d="M10.652 12.243c-.436 0-.87-.014-1.303-.04A9.209 9.209 0 0 1 8 12.012V4.298c.367-.068.77-.12 1.205-.155.437-.034.842-.051 1.217-.051.505 0 .97.036 1.395.109.425.073.79.197 1.096.373.307.176.546.408.718.695.172.287.258.64.258 1.061 0 .636-.306 1.137-.918 1.504.505.192.85.452 1.033.78.184.33.276.701.276 1.114 0 .835-.305 1.462-.913 1.883-.609.421-1.514.632-2.715.632zm-.907-3.57v2.054c.13.016.272.027.425.035.153.007.321.011.505.011.536 0 .968-.076 1.297-.23.33-.152.494-.436.494-.849 0-.367-.138-.63-.414-.786-.275-.157-.67-.236-1.182-.236H9.745zm0-1.367h.872c.551 0 .946-.07 1.183-.212.237-.142.356-.37.356-.683 0-.322-.123-.547-.368-.678-.245-.13-.604-.195-1.079-.195-.153 0-.317.002-.493.006a5.36 5.36 0 0 0-.471.029v1.733zm9.409 4.994c-1.294 0-2.28-.36-2.956-1.08-.678-.719-1.016-1.74-1.016-3.064 0-.658.103-1.246.31-1.762.206-.517.49-.953.849-1.31.36-.355.788-.625 1.286-.808A4.638 4.638 0 0 1 19.245 4c.337 0 .643.025.919.075.275.05.516.107.723.172a3.569 3.569 0 0 1 .815.361l-.516 1.447a4.081 4.081 0 0 0-1.963-.47c-.276 0-.546.045-.81.137a1.83 1.83 0 0 0-.7.442 2.202 2.202 0 0 0-.488.786c-.122.322-.184.712-.184 1.171 0 .368.04.71.12 1.028.081.317.211.591.391.82.18.23.415.412.706.546.291.134.643.2 1.056.2a4.813 4.813 0 0 0 1.252-.154c.16-.042.302-.09.424-.144.123-.053.234-.103.333-.149l.494 1.435c-.253.153-.609.29-1.068.413-.459.123-.99.184-1.595.184z"/>
                        </g>
                        ${
                          hasIssues
                            ? '<circle cx="24.375" cy="5.625" r="4.375" fill="#E54360"/>'
                            : ''
                        }
                    </g>
                </svg>`,
    },
  },
  DropBox: {
    Wired: {
      Configured: (hasIssues: boolean) =>
        `<svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 30 30">
                    <defs>
                        <filter id="a" width="254.5%" height="254.5%" x="-77.3%" y="-77.3%" filterUnits="objectBoundingBox">
                            <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
                            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5"/>
                            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
                            <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter2"/>
                            <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="1"/>
                            <feComposite in="shadowBlurOuter2" in2="SourceAlpha" operator="out" result="shadowBlurOuter2"/>
                            <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feOffset in="SourceAlpha" result="shadowOffsetOuter3"/>
                            <feGaussianBlur in="shadowOffsetOuter3" result="shadowBlurOuter3" stdDeviation="1"/>
                            <feComposite in="shadowBlurOuter3" in2="SourceAlpha" operator="out" result="shadowBlurOuter3"/>
                            <feColorMatrix in="shadowBlurOuter3" result="shadowMatrixOuter3" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1"/>
                                <feMergeNode in="shadowMatrixOuter2"/>
                                <feMergeNode in="shadowMatrixOuter3"/>
                                <feMergeNode in="SourceGraphic"/>
                            </feMerge>
                        </filter>
                    </defs>
                    <g fill="none" fill-rule="evenodd">
                        <g fill-rule="nonzero" filter="url(#a)" transform="translate(4 4)">
                            <path fill="#78BE20" stroke="#78BE20" stroke-width="2.4" d="M1.2 1.2v19.6H11c5.412 0 9.8-4.388 9.8-9.8 0-5.412-4.388-9.8-9.8-9.8H1.2z"/>
                            <path fill="#FFF" d="M5.75 13.442c.083.007.178.013.287.017.108.003.237.005.387.005.875 0 1.524-.22 1.947-.662.423-.441.634-1.051.634-1.83 0-.815-.202-1.432-.606-1.85-.404-.42-1.043-.63-1.919-.63-.12 0-.243.002-.37.006a3.5 3.5 0 0 0-.36.028v4.916zm5.062-2.47c0 .674-.105 1.261-.314 1.763-.21.501-.507.916-.892 1.245-.386.33-.855.577-1.409.741a6.53 6.53 0 0 1-1.863.247c-.314 0-.68-.013-1.1-.04A8.985 8.985 0 0 1 4 14.767V7.191c.404-.075.825-.126 1.263-.152C5.7 7.013 6.076 7 6.39 7c.666 0 1.27.075 1.813.224.542.15 1.008.386 1.397.707.39.322.688.734.898 1.235.21.501.314 1.103.314 1.807zm3.867 3.996c-.426 0-.851-.013-1.274-.04a9.002 9.002 0 0 1-1.318-.184V7.202c.359-.067.752-.118 1.178-.151.426-.034.823-.051 1.19-.051.493 0 .948.036 1.363.107a3.23 3.23 0 0 1 1.072.364c.3.172.533.399.701.68.169.28.253.626.253 1.037 0 .621-.3 1.111-.898 1.47.494.188.83.442 1.01.764.18.321.27.684.27 1.088 0 .816-.298 1.43-.893 1.84-.595.412-1.48.618-2.654.618zm-.887-3.49v2.009c.128.015.266.026.416.033.15.008.314.012.493.012.524 0 .947-.075 1.269-.225.321-.15.482-.426.482-.83 0-.36-.134-.616-.404-.77-.27-.153-.654-.23-1.156-.23h-1.1zm0-1.336h.853c.54 0 .924-.069 1.156-.207.232-.139.348-.361.348-.668 0-.314-.12-.535-.359-.662-.24-.127-.591-.191-1.055-.191-.15 0-.31.002-.482.006a5.24 5.24 0 0 0-.46.028v1.694z"/>
                        </g>
                        ${
                          hasIssues
                            ? '<circle cx="24.375" cy="5.625" r="4.375" fill="#E54360"/>'
                            : ''
                        }
                    </g>
                </svg>`,
      Unconfigured: (hasIssues: boolean) =>
        `<svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 30 30">
                    <defs>
                        <filter id="a" width="254.5%" height="254.5%" x="-77.3%" y="-77.3%" filterUnits="objectBoundingBox">
                            <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
                            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5"/>
                            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
                            <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter2"/>
                            <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="1"/>
                            <feComposite in="shadowBlurOuter2" in2="SourceAlpha" operator="out" result="shadowBlurOuter2"/>
                            <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feOffset in="SourceAlpha" result="shadowOffsetOuter3"/>
                            <feGaussianBlur in="shadowOffsetOuter3" result="shadowBlurOuter3" stdDeviation="1"/>
                            <feComposite in="shadowBlurOuter3" in2="SourceAlpha" operator="out" result="shadowBlurOuter3"/>
                            <feColorMatrix in="shadowBlurOuter3" result="shadowMatrixOuter3" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1"/>
                                <feMergeNode in="shadowMatrixOuter2"/>
                                <feMergeNode in="shadowMatrixOuter3"/>
                                <feMergeNode in="SourceGraphic"/>
                            </feMerge>
                        </filter>
                    </defs>
                    <g fill="none" fill-rule="evenodd">
                        <g fill-rule="nonzero" filter="url(#a)" transform="translate(4 4)">
                            <path fill="#FFF" stroke="#2A9AD1" stroke-width="2.4" d="M1.2 1.2v19.6H11c5.412 0 9.8-4.388 9.8-9.8 0-5.412-4.388-9.8-9.8-9.8H1.2z"/>
                            <path fill="#2A9AD1" d="M5.75 13.442c.083.007.178.013.287.017.108.003.237.005.387.005.875 0 1.524-.22 1.947-.662.423-.441.634-1.051.634-1.83 0-.815-.202-1.432-.606-1.85-.404-.42-1.043-.63-1.919-.63-.12 0-.243.002-.37.006a3.5 3.5 0 0 0-.36.028v4.916zm5.062-2.47c0 .674-.105 1.261-.314 1.763-.21.501-.507.916-.892 1.245-.386.33-.855.577-1.409.741a6.53 6.53 0 0 1-1.863.247c-.314 0-.68-.013-1.1-.04A8.985 8.985 0 0 1 4 14.767V7.191c.404-.075.825-.126 1.263-.152C5.7 7.013 6.076 7 6.39 7c.666 0 1.27.075 1.813.224.542.15 1.008.386 1.397.707.39.322.688.734.898 1.235.21.501.314 1.103.314 1.807zm3.867 3.996c-.426 0-.851-.013-1.274-.04a9.002 9.002 0 0 1-1.318-.184V7.202c.359-.067.752-.118 1.178-.151.426-.034.823-.051 1.19-.051.493 0 .948.036 1.363.107a3.23 3.23 0 0 1 1.072.364c.3.172.533.399.701.68.169.28.253.626.253 1.037 0 .621-.3 1.111-.898 1.47.494.188.83.442 1.01.764.18.321.27.684.27 1.088 0 .816-.298 1.43-.893 1.84-.595.412-1.48.618-2.654.618zm-.887-3.49v2.009c.128.015.266.026.416.033.15.008.314.012.493.012.524 0 .947-.075 1.269-.225.321-.15.482-.426.482-.83 0-.36-.134-.616-.404-.77-.27-.153-.654-.23-1.156-.23h-1.1zm0-1.336h.853c.54 0 .924-.069 1.156-.207.232-.139.348-.361.348-.668 0-.314-.12-.535-.359-.662-.24-.127-.591-.191-1.055-.191-.15 0-.31.002-.482.006a5.24 5.24 0 0 0-.46.028v1.694z"/>
                        </g>
                        ${
                          hasIssues
                            ? '<circle cx="24.375" cy="5.625" r="4.375" fill="#E54360"/>'
                            : ''
                        }
                    </g>
                </svg>`,
    },
    Wireless: {
      Configured: (hasIssues: boolean) =>
        `<svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 30 30">
                    <defs>
                        <filter id="a" width="241.7%" height="241.7%" x="-70.8%" y="-70.8%" filterUnits="objectBoundingBox">
                            <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
                            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5"/>
                            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
                            <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter2"/>
                            <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="1"/>
                            <feComposite in="shadowBlurOuter2" in2="SourceAlpha" operator="out" result="shadowBlurOuter2"/>
                            <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feOffset in="SourceAlpha" result="shadowOffsetOuter3"/>
                            <feGaussianBlur in="shadowOffsetOuter3" result="shadowBlurOuter3" stdDeviation="1"/>
                            <feComposite in="shadowBlurOuter3" in2="SourceAlpha" operator="out" result="shadowBlurOuter3"/>
                            <feColorMatrix in="shadowBlurOuter3" result="shadowMatrixOuter3" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1"/>
                                <feMergeNode in="shadowMatrixOuter2"/>
                                <feMergeNode in="shadowMatrixOuter3"/>
                                <feMergeNode in="SourceGraphic"/>
                            </feMerge>
                        </filter>
                    </defs>
                    <g fill="none" fill-rule="evenodd">
                        <g fill-rule="nonzero" filter="url(#a)" transform="translate(3 3)">
                            <path fill="#78BE20" stroke="#78BE20" stroke-width="2.4" d="M1.2 1.2v10.3c0 6.24 5.06 11.3 11.3 11.3h10.3V12.5c0-6.24-5.06-11.3-11.3-11.3H1.2z"/>
                            <path fill="#FFF" d="M6.75 14.442c.083.007.178.013.287.017.108.003.237.005.387.005.875 0 1.524-.22 1.947-.662.423-.441.634-1.051.634-1.83 0-.815-.202-1.432-.606-1.85-.404-.42-1.043-.63-1.919-.63-.12 0-.243.002-.37.006a3.5 3.5 0 0 0-.36.028v4.916zm5.062-2.47c0 .674-.105 1.261-.314 1.763-.21.501-.507.916-.892 1.245-.386.33-.855.577-1.409.741a6.53 6.53 0 0 1-1.863.247c-.314 0-.68-.013-1.1-.04A8.985 8.985 0 0 1 5 15.767V8.191c.404-.075.825-.126 1.263-.152C6.7 8.013 7.076 8 7.39 8c.666 0 1.27.075 1.813.224.542.15 1.008.386 1.397.707.39.322.688.734.898 1.235.21.501.314 1.103.314 1.807zm3.867 3.996c-.426 0-.851-.013-1.274-.04a9.002 9.002 0 0 1-1.318-.184V8.202c.359-.067.752-.118 1.178-.151.426-.034.823-.051 1.19-.051.493 0 .948.036 1.363.107a3.23 3.23 0 0 1 1.072.364c.3.172.533.399.701.68.169.28.253.626.253 1.037 0 .621-.3 1.111-.898 1.47.494.188.83.442 1.01.764.18.321.27.684.27 1.088 0 .816-.298 1.43-.893 1.84-.595.412-1.48.618-2.654.618zm-.887-3.49v2.009c.128.015.266.026.416.033.15.008.314.012.493.012.524 0 .947-.075 1.269-.225.321-.15.482-.426.482-.83 0-.36-.134-.616-.404-.77-.27-.153-.654-.23-1.156-.23h-1.1zm0-1.336h.853c.54 0 .924-.069 1.156-.207.232-.139.348-.361.348-.668 0-.314-.12-.535-.359-.662-.24-.127-.591-.191-1.055-.191-.15 0-.31.002-.482.006a5.24 5.24 0 0 0-.46.028v1.694z"/>
                        </g>
                        ${
                          hasIssues
                            ? '<circle cx="24.375" cy="5.625" r="4.375" fill="#E54360"/>'
                            : ''
                        }
                    </g>
                </svg> `,
      Unconfigured: (hasIssues: boolean) =>
        `<svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 30 30">
                    <defs>
                        <filter id="a" width="241.7%" height="241.7%" x="-70.8%" y="-70.8%" filterUnits="objectBoundingBox">
                            <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
                            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5"/>
                            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
                            <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter2"/>
                            <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="1"/>
                            <feComposite in="shadowBlurOuter2" in2="SourceAlpha" operator="out" result="shadowBlurOuter2"/>
                            <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feOffset in="SourceAlpha" result="shadowOffsetOuter3"/>
                            <feGaussianBlur in="shadowOffsetOuter3" result="shadowBlurOuter3" stdDeviation="1"/>
                            <feComposite in="shadowBlurOuter3" in2="SourceAlpha" operator="out" result="shadowBlurOuter3"/>
                            <feColorMatrix in="shadowBlurOuter3" result="shadowMatrixOuter3" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1"/>
                                <feMergeNode in="shadowMatrixOuter2"/>
                                <feMergeNode in="shadowMatrixOuter3"/>
                                <feMergeNode in="SourceGraphic"/>
                            </feMerge>
                        </filter>
                    </defs>
                    <g fill="none" fill-rule="evenodd">
                        <g fill-rule="nonzero" filter="url(#a)" transform="translate(3 3)">
                            <path fill="#FFF" stroke="#2A9AD1" stroke-width="2.4" d="M1.2 1.2v10.3c0 6.24 5.06 11.3 11.3 11.3h10.3V12.5c0-6.24-5.06-11.3-11.3-11.3H1.2z"/>
                            <path fill="#2A9AD1" d="M6.75 14.442c.083.007.178.013.287.017.108.003.237.005.387.005.875 0 1.524-.22 1.947-.662.423-.441.634-1.051.634-1.83 0-.815-.202-1.432-.606-1.85-.404-.42-1.043-.63-1.919-.63-.12 0-.243.002-.37.006a3.5 3.5 0 0 0-.36.028v4.916zm5.062-2.47c0 .674-.105 1.261-.314 1.763-.21.501-.507.916-.892 1.245-.386.33-.855.577-1.409.741a6.53 6.53 0 0 1-1.863.247c-.314 0-.68-.013-1.1-.04A8.985 8.985 0 0 1 5 15.767V8.191c.404-.075.825-.126 1.263-.152C6.7 8.013 7.076 8 7.39 8c.666 0 1.27.075 1.813.224.542.15 1.008.386 1.397.707.39.322.688.734.898 1.235.21.501.314 1.103.314 1.807zm3.867 3.996c-.426 0-.851-.013-1.274-.04a9.002 9.002 0 0 1-1.318-.184V8.202c.359-.067.752-.118 1.178-.151.426-.034.823-.051 1.19-.051.493 0 .948.036 1.363.107a3.23 3.23 0 0 1 1.072.364c.3.172.533.399.701.68.169.28.253.626.253 1.037 0 .621-.3 1.111-.898 1.47.494.188.83.442 1.01.764.18.321.27.684.27 1.088 0 .816-.298 1.43-.893 1.84-.595.412-1.48.618-2.654.618zm-.887-3.49v2.009c.128.015.266.026.416.033.15.008.314.012.493.012.524 0 .947-.075 1.269-.225.321-.15.482-.426.482-.83 0-.36-.134-.616-.404-.77-.27-.153-.654-.23-1.156-.23h-1.1zm0-1.336h.853c.54 0 .924-.069 1.156-.207.232-.139.348-.361.348-.668 0-.314-.12-.535-.359-.662-.24-.127-.591-.191-1.055-.191-.15 0-.31.002-.482.006a5.24 5.24 0 0 0-.46.028v1.694z"/>
                        </g>
                        ${
                          hasIssues
                            ? '<circle cx="24.375" cy="5.625" r="4.375" fill="#E54360"/>'
                            : ''
                        }
                    </g>
                </svg>`,
    },
  },
  Gateway: {
    Wireless: {
      Configured: (hasIssues: boolean) =>
        `<svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 30 30">
                <defs>
                    <filter id="a" width="221.4%" height="230.8%" x="-60.7%" y="-65.4%" filterUnits="objectBoundingBox">
                        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
                        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5"/>
                        <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
                        <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter2"/>
                        <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="1"/>
                        <feComposite in="shadowBlurOuter2" in2="SourceAlpha" operator="out" result="shadowBlurOuter2"/>
                        <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                        <feOffset in="SourceAlpha" result="shadowOffsetOuter3"/>
                        <feGaussianBlur in="shadowOffsetOuter3" result="shadowBlurOuter3" stdDeviation="1"/>
                        <feComposite in="shadowBlurOuter3" in2="SourceAlpha" operator="out" result="shadowBlurOuter3"/>
                        <feColorMatrix in="shadowBlurOuter3" result="shadowMatrixOuter3" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                        <feMerge>
                            <feMergeNode in="shadowMatrixOuter1"/>
                            <feMergeNode in="shadowMatrixOuter2"/>
                            <feMergeNode in="shadowMatrixOuter3"/>
                            <feMergeNode in="SourceGraphic"/>
                        </feMerge>
                    </filter>
                </defs>
                <g fill="none" fill-rule="evenodd">
                    <g filter="url(#a)" transform="translate(1 1)">
                        <path fill="#78BE20" stroke="#78BE20" stroke-width="2.4" d="M14 1.362L3.076 7.218v11.564L14 24.638l10.924-5.856V7.218L14 1.362z"/>
                        <path fill="#FFF" fill-rule="nonzero" d="M9.18 10.545c-.826 0-1.422.229-1.79.687-.367.458-.55 1.084-.55 1.88 0 .386.045.736.136 1.05.091.314.227.585.409.812.182.227.409.403.681.528.273.125.591.187.954.187.197 0 .366-.003.506-.01.14-.008.263-.024.369-.046v-2.737h1.772v3.895c-.212.083-.553.172-1.022.267-.47.095-1.05.142-1.738.142-.59 0-1.126-.09-1.607-.273a3.36 3.36 0 0 1-1.232-.795 3.55 3.55 0 0 1-.79-1.283C5.093 14.342 5 13.763 5 13.111c0-.658.102-1.241.307-1.749.204-.507.484-.937.84-1.289a3.543 3.543 0 0 1 1.255-.8C7.882 9.09 8.396 9 8.941 9a6.224 6.224 0 0 1 1.777.244 3.584 3.584 0 0 1 .835.358l-.51 1.42a4.619 4.619 0 0 0-.836-.335 3.55 3.55 0 0 0-1.027-.142zm9.001-.523c.09.31.191.66.301 1.05.11.39.227.794.352 1.21.125.417.252.833.38 1.25.13.416.254.802.375 1.158.099-.386.2-.806.301-1.26.103-.455.199-.924.29-1.409.09-.485.18-.967.267-1.448.087-.48.168-.945.244-1.391h1.84a81.58 81.58 0 0 1-.914 4.009 58.254 58.254 0 0 1-1.12 3.861h-1.623a52.053 52.053 0 0 1-.772-2.288c-.25-.799-.496-1.6-.739-2.402a1914.7 1914.7 0 0 1-.727 2.402 43.806 43.806 0 0 1-.76 2.288H14.25a58.254 58.254 0 0 1-1.118-3.861 81.58 81.58 0 0 1-.915-4.01h1.92c.076.447.159.911.25 1.392.09.48.185.963.284 1.448.098.485.198.954.3 1.408.103.455.207.875.313 1.261.136-.371.269-.765.397-1.181.13-.417.254-.833.375-1.25.121-.416.233-.817.335-1.203.102-.386.195-.731.278-1.034h1.511z"/>
                    </g>
                    ${
                      hasIssues
                        ? '<circle cx="24.375" cy="5.625" r="4.375" fill="#E54360"/>'
                        : ''
                    }
                </g>
            </svg>`,
      Unconfigured: (hasIssues: boolean) =>
        `<svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 30 30">
                <defs>
                    <filter id="a" width="221.4%" height="230.8%" x="-60.7%" y="-65.4%" filterUnits="objectBoundingBox">
                        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
                        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5"/>
                        <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
                        <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter2"/>
                        <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="1"/>
                        <feComposite in="shadowBlurOuter2" in2="SourceAlpha" operator="out" result="shadowBlurOuter2"/>
                        <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                        <feOffset in="SourceAlpha" result="shadowOffsetOuter3"/>
                        <feGaussianBlur in="shadowOffsetOuter3" result="shadowBlurOuter3" stdDeviation="1"/>
                        <feComposite in="shadowBlurOuter3" in2="SourceAlpha" operator="out" result="shadowBlurOuter3"/>
                        <feColorMatrix in="shadowBlurOuter3" result="shadowMatrixOuter3" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                        <feMerge>
                            <feMergeNode in="shadowMatrixOuter1"/>
                            <feMergeNode in="shadowMatrixOuter2"/>
                            <feMergeNode in="shadowMatrixOuter3"/>
                            <feMergeNode in="SourceGraphic"/>
                        </feMerge>
                    </filter>
                </defs>
                <g fill="none" fill-rule="evenodd">
                    <g filter="url(#a)" transform="translate(1 1)">
                        <path fill="#FFF" stroke="#2A9AD1" stroke-width="2.4" d="M14 1.362L3.076 7.218v11.564L14 24.638l10.924-5.856V7.218L14 1.362z"/>
                        <path fill="#2A9AD1" fill-rule="nonzero" d="M9.18 10.545c-.826 0-1.422.229-1.79.687-.367.458-.55 1.084-.55 1.88 0 .386.045.736.136 1.05.091.314.227.585.409.812.182.227.409.403.681.528.273.125.591.187.954.187.197 0 .366-.003.506-.01.14-.008.263-.024.369-.046v-2.737h1.772v3.895c-.212.083-.553.172-1.022.267-.47.095-1.05.142-1.738.142-.59 0-1.126-.09-1.607-.273a3.36 3.36 0 0 1-1.232-.795 3.55 3.55 0 0 1-.79-1.283C5.093 14.342 5 13.763 5 13.111c0-.658.102-1.241.307-1.749.204-.507.484-.937.84-1.289a3.543 3.543 0 0 1 1.255-.8C7.882 9.09 8.396 9 8.941 9a6.224 6.224 0 0 1 1.777.244 3.584 3.584 0 0 1 .835.358l-.51 1.42a4.619 4.619 0 0 0-.836-.335 3.55 3.55 0 0 0-1.027-.142zm9.001-.523c.09.31.191.66.301 1.05.11.39.227.794.352 1.21.125.417.252.833.38 1.25.13.416.254.802.375 1.158.099-.386.2-.806.301-1.26.103-.455.199-.924.29-1.409.09-.485.18-.967.267-1.448.087-.48.168-.945.244-1.391h1.84a81.58 81.58 0 0 1-.914 4.009 58.254 58.254 0 0 1-1.12 3.861h-1.623a52.053 52.053 0 0 1-.772-2.288c-.25-.799-.496-1.6-.739-2.402a1914.7 1914.7 0 0 1-.727 2.402 43.806 43.806 0 0 1-.76 2.288H14.25a58.254 58.254 0 0 1-1.118-3.861 81.58 81.58 0 0 1-.915-4.01h1.92c.076.447.159.911.25 1.392.09.48.185.963.284 1.448.098.485.198.954.3 1.408.103.455.207.875.313 1.261.136-.371.269-.765.397-1.181.13-.417.254-.833.375-1.25.121-.416.233-.817.335-1.203.102-.386.195-.731.278-1.034h1.511z"/>
                    </g>
                    ${
                      hasIssues
                        ? '<circle cx="24.375" cy="5.625" r="4.375" fill="#E54360"/>'
                        : ''
                    }
                </g>
            </svg>`,
    },
  },
  Ir: {
    Wired: {
      Configured: (hasIssues: boolean) =>
        `<svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 30 30">
                    <defs>
                        <filter id="a" width="241.7%" height="241.7%" x="-70.8%" y="-70.8%" filterUnits="objectBoundingBox">
                            <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
                            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5"/>
                            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
                            <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter2"/>
                            <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="1"/>
                            <feComposite in="shadowBlurOuter2" in2="SourceAlpha" operator="out" result="shadowBlurOuter2"/>
                            <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feOffset in="SourceAlpha" result="shadowOffsetOuter3"/>
                            <feGaussianBlur in="shadowOffsetOuter3" result="shadowBlurOuter3" stdDeviation="1"/>
                            <feComposite in="shadowBlurOuter3" in2="SourceAlpha" operator="out" result="shadowBlurOuter3"/>
                            <feColorMatrix in="shadowBlurOuter3" result="shadowMatrixOuter3" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1"/>
                                <feMergeNode in="shadowMatrixOuter2"/>
                                <feMergeNode in="shadowMatrixOuter3"/>
                                <feMergeNode in="SourceGraphic"/>
                            </feMerge>
                        </filter>
                    </defs>
                    <g fill="none" fill-rule="evenodd">
                        <g fill-rule="nonzero" filter="url(#a)" transform="translate(3 3)">
                            <rect width="21.6" height="21.6" x="1.2" y="1.2" fill="#78BE20" stroke="#78BE20" stroke-width="2.4" rx="10.8"/>
                            <path fill="#FFF" d="M7 8.1h1.944v8.637H7V8.1zm6.264-.1c1.296 0 2.289.23 2.979.692.69.46 1.034 1.178 1.034 2.15 0 .606-.14 1.099-.418 1.477-.278.378-.679.675-1.202.89.174.217.357.464.548.742.191.279.38.57.567.873.187.303.368.619.542.947.175.328.337.65.486.966h-2.18l-.48-.86a23.287 23.287 0 0 0-1.003-1.626 8.44 8.44 0 0 0-.5-.667h-.959v3.153h-1.944V8.224c.423-.083.862-.14 1.315-.174.452-.033.857-.05 1.215-.05zm.112 1.658c-.141 0-.268.004-.38.012l-.318.025v2.343h.548c.732 0 1.255-.091 1.57-.274.316-.183.474-.494.474-.935 0-.424-.16-.725-.48-.903-.32-.179-.79-.268-1.414-.268z"/>
                        </g>
                        ${
                          hasIssues
                            ? '<circle cx="24.375" cy="5.625" r="4.375" fill="#E54360"/>'
                            : ''
                        }
                    </g>
                </svg>`,
      Unconfigured: (hasIssues: boolean) =>
        `<svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 30 30">
                    <defs>
                        <filter id="a" width="241.7%" height="241.7%" x="-70.8%" y="-70.8%" filterUnits="objectBoundingBox">
                            <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
                            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5"/>
                            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
                            <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter2"/>
                            <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="1"/>
                            <feComposite in="shadowBlurOuter2" in2="SourceAlpha" operator="out" result="shadowBlurOuter2"/>
                            <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feOffset in="SourceAlpha" result="shadowOffsetOuter3"/>
                            <feGaussianBlur in="shadowOffsetOuter3" result="shadowBlurOuter3" stdDeviation="1"/>
                            <feComposite in="shadowBlurOuter3" in2="SourceAlpha" operator="out" result="shadowBlurOuter3"/>
                            <feColorMatrix in="shadowBlurOuter3" result="shadowMatrixOuter3" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1"/>
                                <feMergeNode in="shadowMatrixOuter2"/>
                                <feMergeNode in="shadowMatrixOuter3"/>
                                <feMergeNode in="SourceGraphic"/>
                            </feMerge>
                        </filter>
                    </defs>
                    <g fill="none" fill-rule="evenodd">
                        <g fill-rule="nonzero" filter="url(#a)" transform="translate(3 3)">
                            <rect width="21.6" height="21.6" x="1.2" y="1.2" fill="#FFF" stroke="#2A9AD1" stroke-width="2.4" rx="10.8"/>
                            <path fill="#2A9AD1" d="M7 8.1h1.944v8.637H7V8.1zm6.264-.1c1.296 0 2.289.23 2.979.692.69.46 1.034 1.178 1.034 2.15 0 .606-.14 1.099-.418 1.477-.278.378-.679.675-1.202.89.174.217.357.464.548.742.191.279.38.57.567.873.187.303.368.619.542.947.175.328.337.65.486.966h-2.18l-.48-.86a23.287 23.287 0 0 0-1.003-1.626 8.44 8.44 0 0 0-.5-.667h-.959v3.153h-1.944V8.224c.423-.083.862-.14 1.315-.174.452-.033.857-.05 1.215-.05zm.112 1.658c-.141 0-.268.004-.38.012l-.318.025v2.343h.548c.732 0 1.255-.091 1.57-.274.316-.183.474-.494.474-.935 0-.424-.16-.725-.48-.903-.32-.179-.79-.268-1.414-.268z"/>
                        </g>
                        ${
                          hasIssues
                            ? '<circle cx="24.375" cy="5.625" r="4.375" fill="#E54360"/>'
                            : ''
                        }
                    </g>
                </svg>`,
    },
    Wireless: {
      Configured: (hasIssues: boolean) =>
        `<svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 30 30">
                    <defs>
                        <filter id="a" width="241.7%" height="241.7%" x="-70.8%" y="-70.8%" filterUnits="objectBoundingBox">
                            <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
                            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5"/>
                            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
                            <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter2"/>
                            <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="1"/>
                            <feComposite in="shadowBlurOuter2" in2="SourceAlpha" operator="out" result="shadowBlurOuter2"/>
                            <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feOffset in="SourceAlpha" result="shadowOffsetOuter3"/>
                            <feGaussianBlur in="shadowOffsetOuter3" result="shadowBlurOuter3" stdDeviation="1"/>
                            <feComposite in="shadowBlurOuter3" in2="SourceAlpha" operator="out" result="shadowBlurOuter3"/>
                            <feColorMatrix in="shadowBlurOuter3" result="shadowMatrixOuter3" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1"/>
                                <feMergeNode in="shadowMatrixOuter2"/>
                                <feMergeNode in="shadowMatrixOuter3"/>
                                <feMergeNode in="SourceGraphic"/>
                            </feMerge>
                        </filter>
                    </defs>
                    <g fill="none" fill-rule="evenodd">
                        <g fill-rule="nonzero" filter="url(#a)" transform="translate(3 3)">
                            <rect width="21.6" height="21.6" x="1.2" y="1.2" fill="#78BE20" stroke="#78BE20" stroke-width="2.4" rx="4"/>
                            <path fill="#FFF" d="M7 8.095h1.847V16.3H7V8.095zM12.95 8c1.232 0 2.175.219 2.83.657.656.438.983 1.119.983 2.043 0 .576-.132 1.043-.397 1.403-.264.359-.645.641-1.142.846.166.205.34.44.52.705.182.264.362.54.54.829.177.288.349.588.515.9.165.311.32.617.461.917h-2.072c-.15-.268-.302-.54-.455-.817a22.123 22.123 0 0 0-.953-1.545 8.017 8.017 0 0 0-.474-.634h-.912V16.3h-1.847V8.213c.403-.079.819-.134 1.25-.166.43-.031.814-.047 1.154-.047zm.107 1.575c-.134 0-.254.004-.361.012-.107.007-.207.015-.302.023v2.226h.521c.695 0 1.192-.087 1.492-.26.3-.174.45-.47.45-.888 0-.403-.152-.689-.456-.859-.304-.17-.752-.254-1.344-.254z"/>
                        </g>
                        ${
                          hasIssues
                            ? '<circle cx="24.375" cy="5.625" r="4.375" fill="#E54360"/>'
                            : ''
                        }
                    </g>
                </svg>`,
      Unconfigured: (hasIssues: boolean) =>
        `<svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 30 30">
                    <defs>
                        <filter id="a" width="241.7%" height="241.7%" x="-70.8%" y="-70.8%" filterUnits="objectBoundingBox">
                            <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
                            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5"/>
                            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
                            <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter2"/>
                            <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="1"/>
                            <feComposite in="shadowBlurOuter2" in2="SourceAlpha" operator="out" result="shadowBlurOuter2"/>
                            <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feOffset in="SourceAlpha" result="shadowOffsetOuter3"/>
                            <feGaussianBlur in="shadowOffsetOuter3" result="shadowBlurOuter3" stdDeviation="1"/>
                            <feComposite in="shadowBlurOuter3" in2="SourceAlpha" operator="out" result="shadowBlurOuter3"/>
                            <feColorMatrix in="shadowBlurOuter3" result="shadowMatrixOuter3" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1"/>
                                <feMergeNode in="shadowMatrixOuter2"/>
                                <feMergeNode in="shadowMatrixOuter3"/>
                                <feMergeNode in="SourceGraphic"/>
                            </feMerge>
                        </filter>
                    </defs>
                    <g fill="none" fill-rule="evenodd">
                        <g fill-rule="nonzero" filter="url(#a)" transform="translate(3 3)">
                            <rect width="21.6" height="21.6" x="1.2" y="1.2" fill="#FFF" stroke="#2A9AD1" stroke-width="2.4" rx="4"/>
                            <path fill="#2A9AD1" d="M7 8.095h1.847V16.3H7V8.095zM12.95 8c1.232 0 2.175.219 2.83.657.656.438.983 1.119.983 2.043 0 .576-.132 1.043-.397 1.403-.264.359-.645.641-1.142.846.166.205.34.44.52.705.182.264.362.54.54.829.177.288.349.588.515.9.165.311.32.617.461.917h-2.072c-.15-.268-.302-.54-.455-.817a22.123 22.123 0 0 0-.953-1.545 8.017 8.017 0 0 0-.474-.634h-.912V16.3h-1.847V8.213c.403-.079.819-.134 1.25-.166.43-.031.814-.047 1.154-.047zm.107 1.575c-.134 0-.254.004-.361.012-.107.007-.207.015-.302.023v2.226h.521c.695 0 1.192-.087 1.492-.26.3-.174.45-.47.45-.888 0-.403-.152-.689-.456-.859-.304-.17-.752-.254-1.344-.254z"/>
                        </g>
                        ${
                          hasIssues
                            ? '<circle cx="24.375" cy="5.625" r="4.375" fill="#E54360"/>'
                            : ''
                        }
                    </g>
                </svg>`,
    },
  },
  Rf: {
    Wired: {
      Configured: (hasIssues: boolean) =>
        `<svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 30 30">
                    <defs>
                        <filter id="a" width="230.8%" height="288.9%" x="-65.4%" y="-94.4%" filterUnits="objectBoundingBox">
                            <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
                            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5"/>
                            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
                            <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter2"/>
                            <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="1"/>
                            <feComposite in="shadowBlurOuter2" in2="SourceAlpha" operator="out" result="shadowBlurOuter2"/>
                            <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feOffset in="SourceAlpha" result="shadowOffsetOuter3"/>
                            <feGaussianBlur in="shadowOffsetOuter3" result="shadowBlurOuter3" stdDeviation="1"/>
                            <feComposite in="shadowBlurOuter3" in2="SourceAlpha" operator="out" result="shadowBlurOuter3"/>
                            <feColorMatrix in="shadowBlurOuter3" result="shadowMatrixOuter3" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1"/>
                                <feMergeNode in="shadowMatrixOuter2"/>
                                <feMergeNode in="shadowMatrixOuter3"/>
                                <feMergeNode in="SourceGraphic"/>
                            </feMerge>
                        </filter>
                    </defs>
                    <g fill="none" fill-rule="evenodd">
                        <g filter="url(#a)" transform="translate(2 6)">
                            <ellipse cx="13" cy="9" fill="#78BE20" stroke="#78BE20" stroke-width="2.4" rx="11.8" ry="7.8"/>
                            <path fill="#FFF" fill-rule="nonzero" d="M9.204 5c1.231 0 2.174.219 2.83.657.655.438.982 1.119.982 2.043 0 .576-.132 1.043-.397 1.403-.264.359-.645.641-1.142.846.166.205.34.44.52.705.182.264.362.54.54.829.177.288.349.588.515.9.165.311.32.617.461.917h-2.072c-.15-.268-.302-.54-.455-.817a22.123 22.123 0 0 0-.953-1.545 8.017 8.017 0 0 0-.474-.634h-.912V13.3H6.8V5.213c.403-.079.819-.134 1.25-.166C8.48 5.016 8.863 5 9.203 5zm.106 1.575c-.134 0-.254.004-.361.012-.107.007-.207.015-.302.023v2.226h.521c.695 0 1.192-.087 1.492-.26.3-.174.45-.47.45-.888 0-.403-.152-.689-.456-.859-.304-.17-.752-.254-1.344-.254zm5.193 6.725V5.095h5.494v1.55H16.35v1.718h3.244v1.55H16.35V13.3h-1.847z"/>
                        </g>
                        ${
                          hasIssues
                            ? '<circle cx="24.375" cy="5.625" r="4.375" fill="#E54360"/>'
                            : ''
                        }
                    </g>
                </svg>`,
      Unconfigured: (hasIssues: boolean) =>
        `<svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 30 30">
                    <defs>
                        <filter id="a" width="230.8%" height="288.9%" x="-65.4%" y="-94.4%" filterUnits="objectBoundingBox">
                            <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
                            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5"/>
                            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
                            <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter2"/>
                            <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="1"/>
                            <feComposite in="shadowBlurOuter2" in2="SourceAlpha" operator="out" result="shadowBlurOuter2"/>
                            <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feOffset in="SourceAlpha" result="shadowOffsetOuter3"/>
                            <feGaussianBlur in="shadowOffsetOuter3" result="shadowBlurOuter3" stdDeviation="1"/>
                            <feComposite in="shadowBlurOuter3" in2="SourceAlpha" operator="out" result="shadowBlurOuter3"/>
                            <feColorMatrix in="shadowBlurOuter3" result="shadowMatrixOuter3" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1"/>
                                <feMergeNode in="shadowMatrixOuter2"/>
                                <feMergeNode in="shadowMatrixOuter3"/>
                                <feMergeNode in="SourceGraphic"/>
                            </feMerge>
                        </filter>
                    </defs>
                    <g fill="none" fill-rule="evenodd">
                        <g filter="url(#a)" transform="translate(2 6)">
                            <ellipse cx="13" cy="9" fill="#FFF" stroke="#2A9AD1" stroke-width="2.4" rx="11.8" ry="7.8"/>
                            <path fill="#2A9AD1" fill-rule="nonzero" d="M9.204 5c1.231 0 2.174.219 2.83.657.655.438.982 1.119.982 2.043 0 .576-.132 1.043-.397 1.403-.264.359-.645.641-1.142.846.166.205.34.44.52.705.182.264.362.54.54.829.177.288.349.588.515.9.165.311.32.617.461.917h-2.072c-.15-.268-.302-.54-.455-.817a22.123 22.123 0 0 0-.953-1.545 8.017 8.017 0 0 0-.474-.634h-.912V13.3H6.8V5.213c.403-.079.819-.134 1.25-.166C8.48 5.016 8.863 5 9.203 5zm.106 1.575c-.134 0-.254.004-.361.012-.107.007-.207.015-.302.023v2.226h.521c.695 0 1.192-.087 1.492-.26.3-.174.45-.47.45-.888 0-.403-.152-.689-.456-.859-.304-.17-.752-.254-1.344-.254zm5.193 6.725V5.095h5.494v1.55H16.35v1.718h3.244v1.55H16.35V13.3h-1.847z"/>
                        </g>
                        ${
                          hasIssues
                            ? '<circle cx="24.375" cy="5.625" r="4.375" fill="#E54360"/>'
                            : ''
                        }
                    </g>
                </svg>`,
    },
    Wireless: {
      Configured: (hasIssues: boolean) =>
        `<svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 30 30">
                    <defs>
                        <filter id="a" width="230.8%" height="288.9%" x="-65.4%" y="-94.4%" filterUnits="objectBoundingBox">
                            <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
                            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5"/>
                            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
                            <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter2"/>
                            <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="1"/>
                            <feComposite in="shadowBlurOuter2" in2="SourceAlpha" operator="out" result="shadowBlurOuter2"/>
                            <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feOffset in="SourceAlpha" result="shadowOffsetOuter3"/>
                            <feGaussianBlur in="shadowOffsetOuter3" result="shadowBlurOuter3" stdDeviation="1"/>
                            <feComposite in="shadowBlurOuter3" in2="SourceAlpha" operator="out" result="shadowBlurOuter3"/>
                            <feColorMatrix in="shadowBlurOuter3" result="shadowMatrixOuter3" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1"/>
                                <feMergeNode in="shadowMatrixOuter2"/>
                                <feMergeNode in="shadowMatrixOuter3"/>
                                <feMergeNode in="SourceGraphic"/>
                            </feMerge>
                        </filter>
                    </defs>
                    <g fill="none" fill-rule="evenodd">
                        <g filter="url(#a)" transform="translate(2 6)">
                            <ellipse cx="13" cy="9" fill="#FFF" stroke="#2A9AD1" stroke-width="2.4" rx="11.8" ry="7.8"/>
                            <path fill="#2A9AD1" fill-rule="nonzero" d="M9.204 5c1.231 0 2.174.219 2.83.657.655.438.982 1.119.982 2.043 0 .576-.132 1.043-.397 1.403-.264.359-.645.641-1.142.846.166.205.34.44.52.705.182.264.362.54.54.829.177.288.349.588.515.9.165.311.32.617.461.917h-2.072c-.15-.268-.302-.54-.455-.817a22.123 22.123 0 0 0-.953-1.545 8.017 8.017 0 0 0-.474-.634h-.912V13.3H6.8V5.213c.403-.079.819-.134 1.25-.166C8.48 5.016 8.863 5 9.203 5zm.106 1.575c-.134 0-.254.004-.361.012-.107.007-.207.015-.302.023v2.226h.521c.695 0 1.192-.087 1.492-.26.3-.174.45-.47.45-.888 0-.403-.152-.689-.456-.859-.304-.17-.752-.254-1.344-.254zm5.193 6.725V5.095h5.494v1.55H16.35v1.718h3.244v1.55H16.35V13.3h-1.847z"/>
                        </g>
                        ${
                          hasIssues
                            ? '<circle cx="24.375" cy="5.625" r="4.375" fill="#E54360"/>'
                            : ''
                        }
                    </g>
                </svg>`,
      Unconfigured: (hasIssues: boolean) =>
        `<svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 30 30">
                    <defs>
                        <filter id="a" width="230.8%" height="312.5%" x="-65.4%" y="-106.2%" filterUnits="objectBoundingBox">
                            <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
                            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5"/>
                            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
                            <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter2"/>
                            <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="1"/>
                            <feComposite in="shadowBlurOuter2" in2="SourceAlpha" operator="out" result="shadowBlurOuter2"/>
                            <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feOffset in="SourceAlpha" result="shadowOffsetOuter3"/>
                            <feGaussianBlur in="shadowOffsetOuter3" result="shadowBlurOuter3" stdDeviation="1"/>
                            <feComposite in="shadowBlurOuter3" in2="SourceAlpha" operator="out" result="shadowBlurOuter3"/>
                            <feColorMatrix in="shadowBlurOuter3" result="shadowMatrixOuter3" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1"/>
                                <feMergeNode in="shadowMatrixOuter2"/>
                                <feMergeNode in="shadowMatrixOuter3"/>
                                <feMergeNode in="SourceGraphic"/>
                            </feMerge>
                        </filter>
                    </defs>
                    <g fill="none" fill-rule="evenodd">
                        <g fill-rule="nonzero" filter="url(#a)" transform="translate(2 7)">
                            <rect width="23.6" height="13.6" x="1.2" y="1.2" fill="#FFF" stroke="#2A9AD1" stroke-width="2.4" rx="4"/>
                            <path fill="#2A9AD1" d="M16.545 10.695V12.3H11V4h1.868v6.695z"/>
                        </g>
                        ${
                          hasIssues
                            ? '<circle cx="24.375" cy="5.625" r="4.375" fill="#E54360"/>'
                            : ''
                        }
                    </g>
                </svg>`,
    },
  },
  BleSensor: {
    Wired: {
      Configured: (hasIssues: boolean) =>
        `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 494.296 494.296"  width="128" height="128">
            <defs>
                <filter id="a" width="230.8%" height="288.9%" x="-65.4%" y="-94.4%" filterUnits="objectBoundingBox">
                    <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
                    <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5"/>
                    <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
                    <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                    <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter2"/>
                    <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="1"/>
                    <feComposite in="shadowBlurOuter2" in2="SourceAlpha" operator="out" result="shadowBlurOuter2"/>
                    <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                    <feOffset in="SourceAlpha" result="shadowOffsetOuter3"/>
                    <feGaussianBlur in="shadowOffsetOuter3" result="shadowBlurOuter3" stdDeviation="1"/>
                    <feComposite in="shadowBlurOuter3" in2="SourceAlpha" operator="out" result="shadowBlurOuter3"/>
                    <feColorMatrix in="shadowBlurOuter3" result="shadowMatrixOuter3" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1"/>
                        <feMergeNode in="shadowMatrixOuter2"/>
                        <feMergeNode in="shadowMatrixOuter3"/>
                        <feMergeNode in="SourceGraphic"/>
                    </feMerge>
                </filter>
            </defs>
            <g fill="none" fill-rule="evenodd">
                <g>
                    <path style="fill:#165788;" d="M20.6,17H3.4C2.6,17,2,16.4,2,15.6V8.4C2,7.6,2.6,7,3.4,7h17.2C21.4,7,22,7.6,22,8.4v7.2
                        C22,16.4,21.4,17,20.6,17z"/>
                    <path style="fill:#FFFFFF;" d="M14,10.5l-2-2h-0.3v2.6l-1.6-1.6l-0.5,0.5l1.9,1.9l-1.9,1.9l0.5,0.5l1.6-1.6v2.6H12l2-2
                        L12.5,12L14,10.5z M12.3,9.9l0.6,0.6l-0.6,0.6V9.9z M13,13.5l-0.6,0.6v-1.3C12.3,12.8,13,13.5,13,13.5z"/>
                </g>
                    ${
                      hasIssues
                        ? '<circle cx="21" cy="7" r="4.375" fill="#E54360"/>'
                        : ''
                    }
            </g>
        </svg>`,
      Unconfigured: (hasIssues: boolean) =>
        `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 494.296 494.296"  width="128" height="128">
            <defs>
                <filter id="a" width="230.8%" height="288.9%" x="-65.4%" y="-94.4%" filterUnits="objectBoundingBox">
                    <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
                    <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5"/>
                    <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
                    <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                    <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter2"/>
                    <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="1"/>
                    <feComposite in="shadowBlurOuter2" in2="SourceAlpha" operator="out" result="shadowBlurOuter2"/>
                    <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                    <feOffset in="SourceAlpha" result="shadowOffsetOuter3"/>
                    <feGaussianBlur in="shadowOffsetOuter3" result="shadowBlurOuter3" stdDeviation="1"/>
                    <feComposite in="shadowBlurOuter3" in2="SourceAlpha" operator="out" result="shadowBlurOuter3"/>
                    <feColorMatrix in="shadowBlurOuter3" result="shadowMatrixOuter3" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1"/>
                        <feMergeNode in="shadowMatrixOuter2"/>
                        <feMergeNode in="shadowMatrixOuter3"/>
                        <feMergeNode in="SourceGraphic"/>
                    </feMerge>
                </filter>
            </defs>
            <g fill="none" fill-rule="evenodd">
                <g>
                    <path style="fill:#2A9AD1;" d="M20.6,17H3.4C2.6,17,2,16.4,2,15.6V8.4C2,7.6,2.6,7,3.4,7h17.2C21.4,7,22,7.6,22,8.4v7.2
                        C22,16.4,21.4,17,20.6,17z"/>
                    <path style="fill:#FFFFFF;" d="M14,10.5l-2-2h-0.3v2.6l-1.6-1.6l-0.5,0.5l1.9,1.9l-1.9,1.9l0.5,0.5l1.6-1.6v2.6H12l2-2
                        L12.5,12L14,10.5z M12.3,9.9l0.6,0.6l-0.6,0.6V9.9z M13,13.5l-0.6,0.6v-1.3C12.3,12.8,13,13.5,13,13.5z"/>
                </g>
                    ${
                      hasIssues
                        ? '<circle cx="21" cy="7" r="4.375" fill="#E54360"/>'
                        : ''
                    }
            </g>
        </svg>`,
    },
    Wireless: {
      Configured: (hasIssues: boolean) =>
        `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 494.296 494.296"  width="128" height="128">
            <defs>
                <filter id="a" width="230.8%" height="288.9%" x="-65.4%" y="-94.4%" filterUnits="objectBoundingBox">
                    <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
                    <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5"/>
                    <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
                    <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                    <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter2"/>
                    <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="1"/>
                    <feComposite in="shadowBlurOuter2" in2="SourceAlpha" operator="out" result="shadowBlurOuter2"/>
                    <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                    <feOffset in="SourceAlpha" result="shadowOffsetOuter3"/>
                    <feGaussianBlur in="shadowOffsetOuter3" result="shadowBlurOuter3" stdDeviation="1"/>
                    <feComposite in="shadowBlurOuter3" in2="SourceAlpha" operator="out" result="shadowBlurOuter3"/>
                    <feColorMatrix in="shadowBlurOuter3" result="shadowMatrixOuter3" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1"/>
                        <feMergeNode in="shadowMatrixOuter2"/>
                        <feMergeNode in="shadowMatrixOuter3"/>
                        <feMergeNode in="SourceGraphic"/>
                    </feMerge>
                </filter>
            </defs>
            <g fill="none" fill-rule="evenodd">
                <g>
                    <path style="fill:#165788;" d="M20.6,17H3.4C2.6,17,2,16.4,2,15.6V8.4C2,7.6,2.6,7,3.4,7h17.2C21.4,7,22,7.6,22,8.4v7.2
                        C22,16.4,21.4,17,20.6,17z"/>
                    <path style="fill:#FFFFFF;" d="M14,10.5l-2-2h-0.3v2.6l-1.6-1.6l-0.5,0.5l1.9,1.9l-1.9,1.9l0.5,0.5l1.6-1.6v2.6H12l2-2
                        L12.5,12L14,10.5z M12.3,9.9l0.6,0.6l-0.6,0.6V9.9z M13,13.5l-0.6,0.6v-1.3C12.3,12.8,13,13.5,13,13.5z"/>
                </g>
                    ${
                      hasIssues
                        ? '<circle cx="21" cy="7" r="4.375" fill="#E54360"/>'
                        : ''
                    }
            </g>
        </svg>`,
      Unconfigured: (hasIssues: boolean) =>
        `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
            <defs>
                <filter id="a" width="230.8%" height="288.9%" x="-65.4%" y="-94.4%" filterUnits="objectBoundingBox">
                    <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
                    <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5"/>
                    <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
                    <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                    <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter2"/>
                    <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="1"/>
                    <feComposite in="shadowBlurOuter2" in2="SourceAlpha" operator="out" result="shadowBlurOuter2"/>
                    <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                    <feOffset in="SourceAlpha" result="shadowOffsetOuter3"/>
                    <feGaussianBlur in="shadowOffsetOuter3" result="shadowBlurOuter3" stdDeviation="1"/>
                    <feComposite in="shadowBlurOuter3" in2="SourceAlpha" operator="out" result="shadowBlurOuter3"/>
                    <feColorMatrix in="shadowBlurOuter3" result="shadowMatrixOuter3" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1"/>
                        <feMergeNode in="shadowMatrixOuter2"/>
                        <feMergeNode in="shadowMatrixOuter3"/>
                        <feMergeNode in="SourceGraphic"/>
                    </feMerge>
                </filter>
            </defs>
            <g fill="none" fill-rule="evenodd">
                <g>
                    <path style="fill:#2A9AD1;" d="M20.6,17H3.4C2.6,17,2,16.4,2,15.6V8.4C2,7.6,2.6,7,3.4,7h17.2C21.4,7,22,7.6,22,8.4v7.2
                        C22,16.4,21.4,17,20.6,17z"/>
                    <path style="fill:#FFFFFF;" d="M14,10.5l-2-2h-0.3v2.6l-1.6-1.6l-0.5,0.5l1.9,1.9l-1.9,1.9l0.5,0.5l1.6-1.6v2.6H12l2-2
                        L12.5,12L14,10.5z M12.3,9.9l0.6,0.6l-0.6,0.6V9.9z M13,13.5l-0.6,0.6v-1.3C12.3,12.8,13,13.5,13,13.5z"/>
                </g>
                    ${
                      hasIssues
                        ? '<circle cx="21" cy="7" r="4.375" fill="#E54360"/>'
                        : ''
                    }
            </g>
        </svg>`,
    },
  },
}
