import { AgentLocationModel } from '.'
import { FetchingState } from '../interfaces/fetchingState'
import { HttpParams } from '../services/http'
import { SignalStatus } from './assetSignal'
import { AssetType } from './assetTypes'
import { BadgeState } from './badgeState'
import { BaseBadgeTelemetryMessage } from './badgeTelemetry'
import { BadgeTelemetryMessageWithAgentEvent } from './telemetry'

export enum AssetPageView {
  List,
  Import,
}

export interface GetAssetByLocationSuccessModel {
  locationId: string
  signalStatus: SignalStatus
  assets: Asset[]
}

export interface AssetTrafficRequest {
  signalStatus: SignalStatus
  locationGuid: string
}

export interface AssetPaginatedTrafficRequest {
  filters: HttpParams
}

export interface AssetTrafficByIdsRequest {
  agentGuids: string[]
  locationGuid: string
  badgeTelemetry: (
    | BadgeState
    | BaseBadgeTelemetryMessage
    | BadgeTelemetryMessageWithAgentEvent<TelemetryAsset>
    | undefined
  )[]
}
export interface GetAssetsByIdsSuccessModel
  extends Pick<GetAssetByLocationSuccessModel, 'assets' | 'locationId'> {
  badgeTelemetry: (BadgeState | BaseBadgeTelemetryMessage | undefined)[]
}
export interface Asset extends AgentLocationModel {
  name: string
  assetTypeName: string
  assetTypeId: number
  assetSubTypeName: string
  assetSubTypeId: number
  serialNumber: string
  model: string
  preventativeMaintenanceDate?: Date
  hasWorkOrder?: boolean
  manufacturerGuid?: string
  manufacturerName?: string
  owner: string
  externalUniqueId: string
  hasRecall?: boolean
  unitId?: string | null
  unitName?: string | null
  cmmsManaged: boolean
}

export interface AssetCreateOrUpdate {
  agentGuid?: string
  badgeId: string
  displayName: string
  name: string | null
  assetType: AssetType | null
  assetTypeId: number
  assetSubTypeId?: number
  serialNumber: string
  model: string
  preventativeMaintenanceDate?: Date
  tagBatteryChangeDate?: Date
  hasWorkOrder?: boolean
  manufacturerGuid?: string
  owner: string
  externalUniqueId: string
  unitId?: string | null
}

export interface Assets {
  [agentGuid: string]: Asset
}

export type AssetsByLocations = {
  [locationId: string]: Asset[]
}

export interface AssetLocations extends FetchingState<Assets> {
  assetsByLocation?: AssetsByLocations
  assetsByLocationLastRefreshTime?: Date
  recordCount?: number
  page?: number
  prevLink?: string
  nextLink?: string
}

export type AssetsState = Readonly<AssetLocations>

export type TelemetryAsset = Omit<
  Asset,
  | 'badgeIds'
  | 'badgeTelemetry'
  | 'roomLocation'
  | 'floorLocation'
  | 'buildingLocation'
  | 'buildingGroupLocation'
>
