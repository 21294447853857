import { BadgeTelemetryMessageWithAgentEvent } from '../../models/telemetry'

export function groupHasActiveDuress(
  agentsInGroup: BadgeTelemetryMessageWithAgentEvent[]
): boolean {
  return agentsInGroup.some((telemetry) => telemetry.hasActiveDuress)
}

export function groupHasActiveAssist(
  agentsInGroup: BadgeTelemetryMessageWithAgentEvent[]
): boolean {
  return agentsInGroup.some((telemetry) => telemetry.hasActiveAssist)
}
