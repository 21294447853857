import { isEmpty } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { calculateInitialAgentAndGroupToCreate } from '../../helpers/calculateRTMapUpdates'
import { deleteAllInpixonAssets } from '../../helpers/inpixon'
import { getIconScale } from '../../helpers/inpixonIcons'
import {
  processMapUpdatesForAgentsAlreadyOnMap,
  processRemoveAgentsLeavingMap,
  updateDetailDrawerOnMapOnAgentMoved,
} from '../../helpers/processRTMapUpdates'
import { executeInitialMapRender } from '../../helpers/processRTMapUpdates/executeInitialMapRender'
import { focusOnPreselectedAgentOnMap } from '../../helpers/processRTMapUpdates/focusOnPreselectedAgentOnMap'
import { processAddingNewAgentsEnteringMap } from '../../helpers/processRTMapUpdates/processAddingNewAgentsEnteringMap'
import {
  filterLeavingAgentsCurrentlyOnMap,
  isInpixonLoaded,
} from '../../helpers/telemetry'
import { AssetInstance, AssetKitInstance, Geofences } from '../../models'
import {
  MapRenderByGeofence,
  SelectAgentInGroupPayload,
  SelectedAgent,
  SortedLiveMapTelemetry,
} from '../../models/realtimeMap'
import { AgentTelemetriesGroupedByTrackingId } from '../../models/telemetry'
import { BadgeTelemetryMessageWithAgentEvent } from '../../models/telemetry/baseBadgeTelemetry'
import useRealTimeMapRenderContext from '../../pages/RealTime/Map/useRealTimeMapRenderContext'
import { selectAllFeatureFlags } from '../../selectors'
import { useGeofenceHighlighting } from '../inpixon/useGeofenceHighlighting'
import { InpixonState } from '../inpixon/useInpixonContext'

export function useRealTimeMapUpdates(
  inpixonKit: InpixonState,
  initialMapRender: MapRenderByGeofence | undefined,
  reloadedMapRender: MapRenderByGeofence | undefined,
  liveMapTelemetryUpdates: SortedLiveMapTelemetry | undefined,
  newAgentsEnteringMapTelemetry: AgentTelemetriesGroupedByTrackingId,
  mapId: number | undefined,
  agentTypes: string[],
  currentSelectedAgentTrackingId: string | undefined,
  currentSelectedGroupId: number | undefined,
  fetchedGeofences: Geofences,
  selectAgent: (
    inpixonAsset: AssetInstance,
    agentTelemetry: BadgeTelemetryMessageWithAgentEvent
  ) => void,
  selectAgentInGroup: (payload: SelectAgentInGroupPayload) => void,
  updateSelectedAgentTelemetry: (
    updatedAgent: BadgeTelemetryMessageWithAgentEvent
  ) => void,
  moveSelectedAgentOutOfGroup: (updatedAgent: SelectedAgent) => void,
  removeAgentFromSelectedGroup: (
    agentTelemtry: BadgeTelemetryMessageWithAgentEvent
  ) => void,
  removeSelectedAgent: (
    agentTelemtry: BadgeTelemetryMessageWithAgentEvent
  ) => void,
  addAgentToSelectedGroup: (
    agentTelemtry: BadgeTelemetryMessageWithAgentEvent
  ) => void,
  updateAgentInSelectedGroup: (
    agentTelemetry: BadgeTelemetryMessageWithAgentEvent
  ) => void
): {
  liveTelemetryToUpdateQueue: SortedLiveMapTelemetry[]
  isProcessing: boolean
} {
  const {
    jibestream,
    assetKit,
    geofenceKit: jibestreamGeofenceKit,
    geofenceInstances,
  } = inpixonKit

  const jibestreamController = useMemo(() => {
    if (jibestream) {
      return jibestream.control
    }
  }, [jibestream])

  const jibestreamCore = useMemo(() => {
    if (jibestream) {
      return jibestream.core
    }
  }, [jibestream])

  const allFeatureFlags = useSelector(selectAllFeatureFlags)

  const {
    addGeofenceHighlight,
    addGeofenceHighlights,
    removeGeofenceHighlight,
    removeAllGeofenceHighlights,
  } = useGeofenceHighlighting(
    jibestreamController,
    jibestreamCore,
    jibestreamGeofenceKit,
    geofenceInstances,
    fetchedGeofences,
    mapId
  )
  const { setMapRender } = useRealTimeMapRenderContext()
  const { search: urlSearchString } = useLocation()

  const inpixonLoaded = useMemo(() => {
    return isInpixonLoaded(
      jibestreamController,
      jibestreamCore,
      assetKit,
      jibestreamGeofenceKit
    )
  }, [assetKit, jibestreamController, jibestreamCore, jibestreamGeofenceKit])

  const [liveTelemetryToUpdateQueue, setLiveTelemetryToUpdateQueue] = useState<
    SortedLiveMapTelemetry[]
  >([])
  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  const isInitialLoadedRef = useRef<boolean>(false)
  const jibestreamControllerRef = useRef<any>(jibestreamController)
  const assetKitRef = useRef<AssetKitInstance | undefined>(assetKit)
  const geofenceInstancesRef = useRef<any[]>(geofenceInstances)
  const currentMapRenderRef = useRef<MapRenderByGeofence>({})
  const nextInpixonAssetIdRef = useRef<number>(1)
  const agentTypesRef = useRef<string[]>(agentTypes)
  const useGeofenceCentroidRef = useRef<boolean>(true)
  const iconScaleRef = useRef<number>(0)
  const currentSelectedTrackingIdRef = useRef<string | undefined>(
    currentSelectedAgentTrackingId
  )
  const currentSelectedGroupIdRef = useRef<number | undefined>(
    currentSelectedGroupId
  )
  const urlSearchStringRef = useRef<string>()

  const dispatch = useDispatch()

  jibestreamControllerRef.current = jibestreamController
  assetKitRef.current = assetKit
  geofenceInstancesRef.current = geofenceInstances
  useGeofenceCentroidRef.current =
    Object.values(allFeatureFlags).filter(
      (x) => x.name.includes('GeofenceCentroid') && x.enabled
    ).length > 0
  iconScaleRef.current = getIconScale(
    jibestreamController?.currentMap?.mmPerPixel
  )
  currentSelectedTrackingIdRef.current = currentSelectedAgentTrackingId
  currentSelectedGroupIdRef.current = currentSelectedGroupId
  agentTypesRef.current = agentTypes

  useEffect(() => {
    if (!initialMapRender || isInitialLoadedRef.current) {
      return
    }
    currentMapRenderRef.current = initialMapRender
    setMapRender(initialMapRender)
  }, [initialMapRender, setMapRender])

  useEffect(() => {
    if (!reloadedMapRender) {
      return
    }
    currentMapRenderRef.current = reloadedMapRender
    setMapRender(reloadedMapRender)
  }, [reloadedMapRender, setMapRender])

  useEffect(() => {
    if (!liveMapTelemetryUpdates) {
      return
    }
    setLiveTelemetryToUpdateQueue((prevState) => [
      ...prevState,
      liveMapTelemetryUpdates,
    ])
  }, [liveMapTelemetryUpdates])

  // Initial map render
  useEffect(() => {
    if (
      isInitialLoadedRef.current ||
      !initialMapRender ||
      !inpixonLoaded ||
      !assetKitRef.current
    ) {
      return
    }
    const initialMapUpdates = calculateInitialAgentAndGroupToCreate(
      initialMapRender,
      iconScaleRef.current,
      currentSelectedTrackingIdRef.current,
      currentSelectedGroupIdRef.current
    )
    nextInpixonAssetIdRef.current = executeInitialMapRender(
      initialMapUpdates.agentsToCreate,
      initialMapUpdates.groupsToCreate,
      assetKitRef.current,
      nextInpixonAssetIdRef.current,
      useGeofenceCentroidRef.current,
      iconScaleRef.current,
      geofenceInstancesRef.current,
      jibestreamControllerRef.current,
      addGeofenceHighlights,
      removeAllGeofenceHighlights
    )
    isInitialLoadedRef.current = true
    focusOnPreselectedAgentOnMap(
      urlSearchString,
      currentSelectedTrackingIdRef.current,
      assetKitRef.current,
      jibestreamControllerRef.current,
      isInitialLoadedRef.current,
      currentMapRenderRef.current,
      selectAgent,
      selectAgentInGroup
    )
  }, [
    initialMapRender,
    inpixonLoaded,
    urlSearchString,
    selectAgent,
    selectAgentInGroup,
    addGeofenceHighlights,
    removeAllGeofenceHighlights,
  ])

  // Rerender the map on agent types changed, filter changed
  useEffect(() => {
    if (
      !inpixonLoaded ||
      !assetKitRef.current ||
      !isInitialLoadedRef.current ||
      !reloadedMapRender
    ) {
      return
    }
    deleteAllInpixonAssets(assetKitRef.current)
    const mapUpdates = calculateInitialAgentAndGroupToCreate(
      reloadedMapRender,
      iconScaleRef.current,
      currentSelectedTrackingIdRef.current,
      currentSelectedGroupIdRef.current
    )
    nextInpixonAssetIdRef.current = executeInitialMapRender(
      mapUpdates.agentsToCreate,
      mapUpdates.groupsToCreate,
      assetKitRef.current,
      nextInpixonAssetIdRef.current,
      useGeofenceCentroidRef.current,
      iconScaleRef.current,
      geofenceInstancesRef.current,
      jibestreamControllerRef.current,
      addGeofenceHighlights,
      removeAllGeofenceHighlights
    )
  }, [
    reloadedMapRender,
    inpixonLoaded,
    addGeofenceHighlights,
    removeAllGeofenceHighlights,
  ])

  // Live telemetry updates
  useEffect(() => {
    // mapRenderRefString is a hack to address a race condition we were seeing where this
    // useEffect would begin running, but the currentMapRenderRef would be changed
    // prior to its completion. This hack will prevent overwriting the new value of currentMapRenderRef
    // if it has changed while running this useEffect. Cancelling promises is not currently supported in ECMAScript 6
    // #SorryToFutureDevs
    const mapRenderRefString = JSON.stringify(currentMapRenderRef.current)

    const processLiveTelemetryUpdates = async () => {
      if (
        liveTelemetryToUpdateQueue.length === 0 ||
        isProcessing ||
        !isInitialLoadedRef.current ||
        !assetKitRef.current
      ) {
        return
      }
      setIsProcessing(true)
      try {
        const liveTelemetryToUpdate = liveTelemetryToUpdateQueue[0]
        if (
          isEmpty(
            liveTelemetryToUpdate.agentTelemetriesForAgentsAlreadyOnMap
          ) &&
          isEmpty(liveTelemetryToUpdate.agentsLeavingMap)
        ) {
          return
        }
        const { agentTelemetriesForAgentsAlreadyOnMap, agentsLeavingMap } =
          liveTelemetryToUpdate
        const filteredLeavingAgentTelemetries =
          filterLeavingAgentsCurrentlyOnMap(
            agentsLeavingMap,
            currentMapRenderRef.current,
            agentTypesRef.current
          )

        if (!isEmpty(filteredLeavingAgentTelemetries)) {
          const removedAgentResult = processRemoveAgentsLeavingMap(
            filteredLeavingAgentTelemetries,
            currentMapRenderRef.current,
            assetKitRef.current,
            jibestreamControllerRef.current,
            geofenceInstancesRef.current,
            useGeofenceCentroidRef.current,
            iconScaleRef.current,
            currentSelectedTrackingIdRef.current,
            currentSelectedGroupIdRef.current,
            nextInpixonAssetIdRef.current,
            removeGeofenceHighlight,
            removeSelectedAgent,
            removeAgentFromSelectedGroup
          )

          currentMapRenderRef.current = removedAgentResult.updatedMapRender

          if (removedAgentResult.nextInpixonAssetId) {
            nextInpixonAssetIdRef.current =
              removedAgentResult.nextInpixonAssetId
          }
        }
        const existingAgentUpdateResult =
          await processMapUpdatesForAgentsAlreadyOnMap(
            Object.values(agentTelemetriesForAgentsAlreadyOnMap),
            currentMapRenderRef.current,
            assetKitRef.current,
            jibestreamControllerRef.current,
            geofenceInstancesRef.current,
            useGeofenceCentroidRef.current,
            iconScaleRef.current,
            currentSelectedTrackingIdRef.current,
            currentSelectedGroupIdRef.current,
            nextInpixonAssetIdRef.current,
            updateSelectedAgentTelemetry,
            updateAgentInSelectedGroup,
            addGeofenceHighlight,
            removeGeofenceHighlight
          )
        updateDetailDrawerOnMapOnAgentMoved(
          agentTelemetriesForAgentsAlreadyOnMap,
          currentMapRenderRef.current,
          currentSelectedTrackingIdRef.current,
          currentSelectedGroupIdRef.current,
          assetKitRef.current,
          updateSelectedAgentTelemetry,
          moveSelectedAgentOutOfGroup,
          removeAgentFromSelectedGroup,
          addAgentToSelectedGroup
        )

        // only update refs if the value of currentMapRenderRef has not been changed by
        // another useEffect while running this useEffect
        if (
          JSON.stringify(currentMapRenderRef.current) === mapRenderRefString
        ) {
          currentMapRenderRef.current =
            existingAgentUpdateResult.updatedMapRender

          if (existingAgentUpdateResult.nextInpixonAssetId) {
            nextInpixonAssetIdRef.current =
              existingAgentUpdateResult.nextInpixonAssetId
          }

          setMapRender(currentMapRenderRef.current)
        }
      } finally {
        setLiveTelemetryToUpdateQueue((prevState) => prevState.slice(1))
        setIsProcessing(false)
      }
    }

    processLiveTelemetryUpdates().catch((error) => {
      console.error(error)
      setLiveTelemetryToUpdateQueue((prevState) => prevState.slice(1))
      setIsProcessing(false)
    })
  }, [
    dispatch,
    liveTelemetryToUpdateQueue,
    isProcessing,
    removeAgentFromSelectedGroup,
    setMapRender,
    updateSelectedAgentTelemetry,
    moveSelectedAgentOutOfGroup,
    addGeofenceHighlight,
    removeGeofenceHighlight,
    addAgentToSelectedGroup,
    updateAgentInSelectedGroup,
    removeSelectedAgent,
  ])

  // Add new agents entering the map
  useEffect(() => {
    if (!assetKitRef.current) {
      return
    }
    if (isEmpty(newAgentsEnteringMapTelemetry)) {
      return
    }
    const newAgentUpdateResult = processAddingNewAgentsEnteringMap(
      newAgentsEnteringMapTelemetry,
      currentMapRenderRef.current,
      assetKitRef.current,
      jibestreamControllerRef.current,
      geofenceInstancesRef.current,
      useGeofenceCentroidRef.current,
      iconScaleRef.current,
      currentSelectedTrackingIdRef.current,
      currentSelectedGroupIdRef.current,
      nextInpixonAssetIdRef.current,
      addGeofenceHighlight,
      addAgentToSelectedGroup
    )
    if (newAgentUpdateResult.nextInpixonAssetId) {
      nextInpixonAssetIdRef.current = newAgentUpdateResult.nextInpixonAssetId
    }
    currentMapRenderRef.current = newAgentUpdateResult.updatedMapRender
    setMapRender(newAgentUpdateResult.updatedMapRender)
  }, [
    newAgentsEnteringMapTelemetry,
    setMapRender,
    addGeofenceHighlight,
    addAgentToSelectedGroup,
  ])

  useEffect(() => {
    if (!isInitialLoadedRef.current) {
      return
    }
    if (urlSearchStringRef.current !== urlSearchString) {
      urlSearchStringRef.current = urlSearchString
      focusOnPreselectedAgentOnMap(
        urlSearchString,
        currentSelectedTrackingIdRef.current,
        assetKitRef.current,
        jibestreamControllerRef.current,
        isInitialLoadedRef.current,
        currentMapRenderRef.current,
        selectAgent,
        selectAgentInGroup
      )
    }
  }, [urlSearchString, selectAgent, selectAgentInGroup])

  return {
    liveTelemetryToUpdateQueue,
    isProcessing,
  }
}
