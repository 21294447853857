import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useCurrentLocation } from '..'
import { getStaffByLocationIdAction } from '../../actions/staff'
import { filterAgentsByLocation } from '../../helpers'
import { shouldDispatch } from '../../helpers/shouldDispatch'
import {
  FetchingStatus,
  Location,
  SignalStatus,
  Staff,
  StaffMembers,
  StaffState,
} from '../../models'

// used to detect location changes and prevent unnecessary calls out to fetch staff
let cachedLocation: Location | null = null
let cachedSignalStatus: SignalStatus | null = null

const useFetchStaffByLocation = (
  signalStatusId: SignalStatus,
  shouldRefresh = false
): {
  staffsByLocation: Staff[]
  staffs: StaffMembers
  staffsFetchingStatus: FetchingStatus | undefined
  isFetched: boolean
} => {
  const dispatch = useDispatch()
  const { currentLocation } = useCurrentLocation()

  const {
    status: staffsFetchingStatus,
    staffByLocationLastRefreshTime,
    data: staffs,
    staffByLocation,
  } = useSelector(({ staff }: { staff: StaffState }) => staff)

  const staffsByLocationFetched =
    staffsFetchingStatus !== FetchingStatus.Request && !!currentLocation

  useEffect(() => {
    if (currentLocation) {
      if (currentLocation !== cachedLocation) {
        cachedSignalStatus = signalStatusId
        cachedLocation = currentLocation
        dispatch(
          getStaffByLocationIdAction.request({
            locationGuid: cachedLocation.id,
            signalStatus: signalStatusId,
          })
        )
      } else if (cachedSignalStatus !== signalStatusId) {
        cachedSignalStatus = signalStatusId
        dispatch(
          getStaffByLocationIdAction.request({
            locationGuid: currentLocation.id,
            signalStatus: cachedSignalStatus ?? null,
          })
        )
      } else if (
        shouldDispatch(staffsFetchingStatus, staffByLocationLastRefreshTime) ||
        shouldRefresh
      ) {
        cachedSignalStatus = signalStatusId
        dispatch(
          getStaffByLocationIdAction.request({
            locationGuid: cachedLocation.id,
            signalStatus: signalStatusId,
          })
        )
      }
    }
  }, [
    staffByLocationLastRefreshTime,
    currentLocation,
    dispatch,
    staffsFetchingStatus,
    shouldRefresh,
    signalStatusId,
  ])

  const filteredStaff = useMemo(() => {
    return filterAgentsByLocation(
      staffsFetchingStatus,
      currentLocation,
      staffByLocation
    )
  }, [staffsFetchingStatus, staffByLocation, currentLocation])

  return {
    staffsByLocation: filteredStaff,
    staffs,
    staffsFetchingStatus,
    isFetched: staffsByLocationFetched,
  }
}

export { useFetchStaffByLocation }
