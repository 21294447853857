export enum BadgeSignalStatus {
  Current = 'Current',
  Recent = 'Recent',
  TimedOut = 'Timed-Out',
}

//String assigned Enums don't auto assign integer ids. Backend is using the same enum as a number.
export enum SignalStatus {
  Current,
  Recent,
  TimedOut,
}

export enum BadgeSignalAccuracy {
  InRoom = 'In-Room',
  NearRoom = 'Near Room',
}
