import { mswApiUrl } from '../../msw/commonHandlers'
import { FetchClient, MockServiceWorkerInterceptor } from '../services/http'
import { AgentEventLocationApi } from './agentEventsLocation'
import { AgentEventViewsApi } from './agentEventViews'
import { AlertsApi } from './alerts'
import { AlertSubscriptionKeysApi } from './alertSubscriptionKeys'
import { AlertTypesApi } from './alertTypes'
import { AltitudeMapsApi } from './altitudeMaps'
import { AssetsApi } from './assets'
import { AssetSubTypesApi } from './assetSubTypes'
import { AssetTypesApi } from './assetTypes'
import { BadgeAssignedCountApi } from './badgeAssignedCount'
import { BadgesApi } from './badges'
import { BadgeSensorStatesApi } from './badgeSensorStates'
import { BuildingsApi } from './buildings'
import { ChannelSoundsApi } from './channelSounds'
import { ChannelTypesApi } from './channelTypes'
import { CurrentUserLocationApi } from './currentUserLocation'
import { CurrentUserApi } from './currentUser'
import { DevicesApi } from './devices'
import { ExternalLinksApi } from './externalLinks'
import { FeatureFlagApi } from './featureFlags'
import { GeofenceApi } from './geofence'
import { HardwareApi } from './hardware'
import { IssuesApi } from './issues'
import { IssueTypesApi } from './issueTypes'
import { LocationGeofenceApi } from './locationGeofences'
import { LocationMetricsApi } from './locationMetrics'
import { LocationRoutersApi } from './locationRouters'
import { LocationsApi } from './locations'
import { LogLevelTypesApi } from './logLevelTypes'
import { ManufacturersApi } from './manufacturers'
import { MapPresetApi } from './mapPresets'
import { MapReferenceViewApi } from './mapReferenceViews'
import { MapsApi } from './maps'
import { ModelsApi } from './models'
import { OwnersApi } from './owners'
import { PermissionsApi } from './permissions'
import { QualifiersApi } from './qualifiers'
import { ReleaseApi } from './release'
import { RolesApi } from './roles'
import { SecurityRoutersApi } from './securityRouters'
import { SelectableLocationsApi } from './selectableLocations'
import { SensorsApi } from './sensors'
import { SensoryReferenceApi } from './sensoryReferences'
import { StaffApi } from './staff'
import { StaffSubTypesApi } from './staffSubTypes'
import { StaffTypesApi } from './staffTypes'
import { SubUnitsApi } from './subUnits'
import { TenantConfigurationApi } from './tenantConfiguration'
import { TriggersApi } from './triggers'
import { UnitsApi } from './units'
import { UserPreferencesApi } from './userPreferences'
import { UserRoutersApi } from './userRouters'
import { UsersApi } from './users'
import { UserSettingsApi } from './userSettings'
import { VenuesApi } from './venues'
import { WatchlistApi } from './watchlist'
import { CloudReportingApi } from './cloudReporting'

const commonMiddlewares = {
  agentEventLocationApi: new AgentEventLocationApi(),
  agentEventViewsApi: new AgentEventViewsApi(),
  altitudeMapsApi: new AltitudeMapsApi(),
  assetsApi: new AssetsApi(),
  assetSubTypesApi: new AssetSubTypesApi(),
  assetTypesApi: new AssetTypesApi(),
  badgeAssignedCountApi: new BadgeAssignedCountApi(),
  badgesApi: new BadgesApi(),
  badgeSensorStatesApi: new BadgeSensorStatesApi(),
  buildingsApi: new BuildingsApi(),
  channelTypesApi: new ChannelTypesApi(),
  currentUserApi: new CurrentUserApi(),
  devicesApi: new DevicesApi(),
  externalLinksApi: new ExternalLinksApi(),
  featureFlagApi: new FeatureFlagApi(),
  geofenceApi: new GeofenceApi(),
  hardwareApi: new HardwareApi(),
  issuesApi: new IssuesApi(),
  issueTypesApi: new IssueTypesApi(),
  locationsApi: new LocationsApi(),
  locationGeofencesApi: new LocationGeofenceApi(),
  locationMetricsApi: new LocationMetricsApi(),
  logLevelTypesApi: new LogLevelTypesApi(),
  manufacturersApi: new ManufacturersApi(),
  mapsApi: new MapsApi(),
  mapPresetApi: new MapPresetApi(),
  mapReferenceViewApi: new MapReferenceViewApi(),
  modelsApi: new ModelsApi(),
  ownersApi: new OwnersApi(),
  permissionsApi: new PermissionsApi(),
  releaseApi: new ReleaseApi(),
  rolesApi: new RolesApi(),
  selectableLocationsApi: new SelectableLocationsApi(),
  sensorsApi: new SensorsApi(),
  sensoryReferenceApi: new SensoryReferenceApi(),
  staffApi: new StaffApi(),
  staffTypesApi: new StaffTypesApi(),
  staffSubTypesApi: new StaffSubTypesApi(),
  subUnitsApi: new SubUnitsApi(),
  tenantConfigurationApi: new TenantConfigurationApi(),
  usersApi: new UsersApi(),
  userPreferencesApi: new UserPreferencesApi(),
  userSettingsApi: new UserSettingsApi(),
  unitsApi: new UnitsApi(),
  venuesApi: new VenuesApi(),
  watchlistApi: new WatchlistApi(),
  alertsApi: new AlertsApi(),
  alertTypesApi: new AlertTypesApi(),
  qualifiersApi: new QualifiersApi(),
  triggersApi: new TriggersApi(),
  currentUserLocationApi: new CurrentUserLocationApi(),
  locationRoutersApi: new LocationRoutersApi(),
  securityRoutersApi: new SecurityRoutersApi(),
  userRoutersApi: new UserRoutersApi(),
  ChannelSoundsApi: new ChannelSoundsApi(),
  alertSubscriptionKeysApi: new AlertSubscriptionKeysApi(),
  cloudReportingApi: new CloudReportingApi(),
}

export const mockCommonMiddlewares = {
  ...commonMiddlewares,
  agentEventLocationApi: new AgentEventLocationApi(
    { apiRoot: mswApiUrl },
    new FetchClient([new MockServiceWorkerInterceptor()])
  ),
  cloudReportingApi: new CloudReportingApi(
    { apiRoot: mswApiUrl },
    new FetchClient([new MockServiceWorkerInterceptor()])
  ),
}

export * from './agentEventsLocation'
export * from './agentEventViews'
export * from './altitudeMaps'
export * from './assets'
export * from './assetSubTypes'
export * from './assetTypes'
export * from './badgeAssignedCount'
export * from './badges'
export * from './badgeSensorStates'
export * from './baseService'
export * from './buildings'
export * from './channelTypes'
export * from './currentUser'
export * from './devices'
export * from './externalLinks'
export * from './featureFlags'
export * from './geofence'
export * from './hardware'
export * from './issues'
export * from './issueTypes'
export * from './locations'
export * from './locationGeofences'
export * from './locationMetrics'
export * from './logLevelTypes'
export * from './manufacturers'
export * from './maps'
export * from './mapPresets'
export * from './mapReferenceViews'
export * from './models'
export * from './owners'
export * from './permissions'
export * from './release'
export * from './releaseUserNotices'
export * from './roles'
export * from './sensors'
export * from './selectableLocations'
export * from './sensoryReferences'
export * from './staff'
export * from './staffTypes'
export * from './staffSubTypes'
export * from './subUnits'
export * from './tenantConfiguration'
export * from './units'
export * from './users'
export * from './userPreferences'
export * from './userSettings'
export * from './venues'
export * from './watchlist'
export * from './alerts'
export * from './alertTypes'
export * from './qualifiers'
export * from './triggers'
export * from './currentUserLocation'
export * from './locationRouters'
export * from './securityRouters'
export * from './userRouters'
export * from './cloudReporting'
export * from './channelSounds'
export * from './alertSubscriptionKeys'

export default commonMiddlewares
