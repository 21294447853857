import React from 'react'
import { AssetRow } from '../../../../models/assetRow'
import {
  Asset,
  BadgeCurrentLocation,
  MapUpdate,
  Watchlist,
} from '../../../../models'
import { useAgentLocation } from '../../../../hooks/useAgentLocation'
import DetailsPaneHeader from '../../../Drawer/DetailsPaneHeader'
import DeprecatedAgentDetailDrawerActionButtons from './DeprecatedAgentDetailDrawerActionButtons'
import { AssetFilters } from '../../../../models/filters'

interface Props<T = AssetRow | Asset> {
  asset: T
  onClose: () => void
  isMap: boolean
  watchlist: Watchlist
  liveTelemetry?: BadgeCurrentLocation[]
  mapUpdates?: MapUpdate[]
  assetFilterState: AssetFilters
  onEditAsset?: () => void
  setFilterButtonToggled: (filterButtonStates: any) => void
  forceRefresh: () => void
}

function DeprecatedAssetDetailPaneHeader(props: Props): React.ReactElement {
  const {
    asset,
    onClose,
    onEditAsset,
    isMap,
    watchlist,
    liveTelemetry,
    mapUpdates,
    assetFilterState,
    setFilterButtonToggled,
    forceRefresh,
  } = props

  const assetLocation = useAgentLocation(asset, liveTelemetry, mapUpdates)

  return (
    <>
      <DetailsPaneHeader handleClose={onClose} headerText={asset.displayName} />
      <DeprecatedAgentDetailDrawerActionButtons
        isMap={isMap}
        agent={asset}
        watchlist={watchlist}
        floorLocationId={assetLocation?.floorLocation?.id ?? ''}
        isPrivate={false}
        hasWatchListFilter={assetFilterState.isWatchlist ?? false}
        onRemovedFromWatchList={onClose}
        onEditAgent={onEditAsset}
        setFilterButtonToggled={setFilterButtonToggled}
        forceRefresh={forceRefresh}
      />
    </>
  )
}

export default DeprecatedAssetDetailPaneHeader
