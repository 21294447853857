import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { Epic } from 'redux-observable'
import { UserSettingsAction } from '../actions'
import { UserSettingsApi } from '../middleware/userSettings'
import { UserSettingsState, UserSettingsType } from '../models'
import { isActionOf } from 'typesafe-actions'
import {
  deleteUserSettingsAction,
  getUserSettingsAction,
  patchUserSettingsAction,
} from '../actions/userSettings'

type UserSettingsEpic = Epic<
  UserSettingsAction,
  UserSettingsAction,
  UserSettingsState,
  { userSettingsApi: UserSettingsApi }
>

export const getUserSettingsEpic: UserSettingsEpic = (
  action$,
  state$,
  { userSettingsApi }
) => {
  return action$.pipe(
    filter(isActionOf(getUserSettingsAction.request)),
    mergeMap(() =>
      from(userSettingsApi.getUserSettings()).pipe(
        map((userSettings) => getUserSettingsAction.success(userSettings)),
        catchError((error) => of(getUserSettingsAction.failure(error)))
      )
    )
  )
}

export const patchUserSettingsEpic: UserSettingsEpic = (
  action$,
  state$,
  { userSettingsApi }
) => {
  return action$.pipe(
    filter(isActionOf(patchUserSettingsAction.request)),
    mergeMap((action) =>
      from(userSettingsApi.patchUserSettings(action.payload)).pipe(
        map((userSettings) => patchUserSettingsAction.success(userSettings)),
        catchError((error) => of(patchUserSettingsAction.failure(error)))
      )
    )
  )
}

export const deleteUserSettingsEpic: UserSettingsEpic = (
  action$,
  state$,
  { userSettingsApi }
) => {
  return action$.pipe(
    filter(isActionOf(deleteUserSettingsAction.request)),
    mergeMap((action) =>
      from(userSettingsApi.deleteUserSetting(action.payload)).pipe(
        map((userSettings) => deleteUserSettingsAction.success(userSettings)),
        catchError((error) => of(deleteUserSettingsAction.failure(error)))
      )
    )
  )
}
