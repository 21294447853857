import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { focusView } from '../../helpers/inpixon'
import {
  InpixonAssetRef,
  InpixonAssetRefStore,
  MapGroupState,
} from '../../models'

export function useFocusView(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  assetKit: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any,
  urlBadgeId?: string,
  mapIsLoaded?: boolean,
  assetsCreated?: boolean,
  focusOnAgent?: (itemId: string) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  const assetFocused = useRef<boolean>(false)
  const [focusedAgent, setFocusedAgent] = useState<string>()

  const mapGroups: MapGroupState = useSelector(
    ({ mapGroups }: { mapGroups: MapGroupState }) => mapGroups
  )
  const { agentReferences } = useSelector(
    ({ agentReferences }: { agentReferences: InpixonAssetRefStore }) =>
      agentReferences
  )

  const findMatchingGroup = useCallback(
    (inpixonAssets: any[]) => {
      if (mapGroups.selectedGroupId) {
        const groupAsset = inpixonAssets.find(
          (x: any) => Number(x.id) === Number(mapGroups.selectedGroupId)
        )

        return groupAsset
      }
    },
    [mapGroups]
  )

  const findMatchingInpixonAsset = useCallback(
    (inpixonAssets: any[]) => {
      if (agentReferences && urlBadgeId) {
        if (inpixonAssets.length) {
          const assetKitId = agentReferences.find((s: InpixonAssetRef) =>
            s.trackingIds.includes(urlBadgeId)
          )?.assetKitId

          if (assetKitId) {
            const asset = inpixonAssets.find(
              (x: any) => Number(x.id) === Number(assetKitId)
            )

            return asset
          }
        }
      }
    },
    [focusedAgent, agentReferences, urlBadgeId]
  )

  useEffect(() => {
    if (
      !assetFocused.current &&
      assetKit &&
      mapIsLoaded &&
      urlBadgeId &&
      urlBadgeId !== focusedAgent &&
      assetsCreated &&
      focusOnAgent
    ) {
      const inpixonAssets = assetKit?._getAllAssets()
      const matchingInpixonAsset =
        findMatchingInpixonAsset(inpixonAssets) ??
        findMatchingGroup(inpixonAssets)

      if (urlBadgeId !== mapGroups.selectedTrackingId) {
        focusOnAgent(urlBadgeId)
      }

      if (matchingInpixonAsset) {
        focusView(
          control,
          matchingInpixonAsset._position[0],
          matchingInpixonAsset._position[1],
          2,
          10,
          0
        )
        setFocusedAgent(urlBadgeId)
        assetFocused.current = true
      }
    }

    return (): void => {
      assetFocused.current = false
    }
  }, [
    assetKit,
    urlBadgeId,
    mapIsLoaded,
    assetsCreated,
    control,
    mapGroups,
    focusOnAgent,
    findMatchingInpixonAsset,
    findMatchingGroup,
  ])
}
