import { createAction } from 'typesafe-actions'
import { PermissionsEnum } from '../models'
import { B2CUser } from '../models/users'

export const setAuthenticatedUserAction = createAction(
  'SET_AUTHENTICATED_USER'
)<B2CUser>()

export const setCurrentCustomerIdAction = createAction(
  'SET_CURRENT_CUSTOMER_ID'
)<string>()

export const setCurrentPermissionsAction = createAction(
  'SET_CURRENT_PERMISSION'
)<Array<PermissionsEnum>>()

export const setIsCustomerSelectedAction = createAction(
  'SET_IS_CUSTOMER_SELECTED'
)<boolean>()

export const setChangeTenantConfirmedAction = createAction(
  'SET_CHANGE_TENANT_CONFIRMED'
)<boolean>()
