import {
  PageContent,
  PageLayout,
  PageToolbar,
} from '@midmarkrtls/common/components/Layout'
import { PageLayoutState } from '@midmarkrtls/common/models/pageLayout'
import { useSelector } from 'react-redux'
import React from 'react'
import { CreateEventComponent } from 'components/DevTools/CreateEvent'
import { BadgeAssignedCountComponent } from 'components/DevTools/BadgeAssignedCount'
import { RemoveAllVenueComponent } from 'components/DevTools/RemoveAllVenue'

const DevToolsPage = (): JSX.Element => {
  const maxHeight = useSelector(
    ({ pageLayout }: { pageLayout: PageLayoutState }) => pageLayout.maxHeight
  )

  return (
    <PageLayout>
      <PageToolbar />
      <PageContent
        maxHeight={maxHeight}
        content={
          <>
            <CreateEventComponent />
            <BadgeAssignedCountComponent />
            <RemoveAllVenueComponent />
          </>
        }
      />
    </PageLayout>
  )
}
export default DevToolsPage
