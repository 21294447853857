import { getType } from 'typesafe-actions'
import { BadgeAction } from '../actions'
import {
  getAllBadgesAction,
  getBadgeAction,
  putBadgeAction,
  postBadgeAction,
} from '../actions/badges'
import { Badge, Badges, BadgesState } from '../models'
import { FetchingStatus } from '../models/fetchingStatus'
import { mergeRecords, mergeRecord, setFetchingStatus } from '../utils'

const initialState: BadgesState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

export default (
  state: BadgesState = initialState,
  action: BadgeAction
): BadgesState => {
  switch (action.type) {
    case getType(putBadgeAction.success):
      return {
        data: state.data,
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getAllBadgesAction.success):
      return {
        ...state,
        data: mergeRecords<Badges, Badge, 'id'>(
          state.data,
          action.payload,
          'id'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(postBadgeAction.success):
    case getType(getBadgeAction.success):
      return {
        ...state,
        data: mergeRecord<Badges, Badge, 'badgeId'>(
          state.data,
          action.payload,
          'badgeId'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getAllBadgesAction.request):
    case getType(getBadgeAction.request):
    case getType(putBadgeAction.request):
    case getType(postBadgeAction.request):
      return setFetchingStatus<Badges>(state, FetchingStatus.Request)

    case getType(getAllBadgesAction.failure):
    case getType(getBadgeAction.failure):
    case getType(putBadgeAction.failure):
    case getType(postBadgeAction.failure):
      return setFetchingStatus<Badges>(state, FetchingStatus.Failure)

    default:
      return state
  }
}
