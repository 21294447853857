import React, { createContext, useContext, useReducer } from 'react'
import { AlertActiveConfig, defaultAlertActiveConfig } from '../../models/alert'

interface ProviderProps {
  children: React.ReactNode
}

export type AlertActiveConfigState = AlertActiveConfig

const AlertActiveConfigurationStateContext = createContext<
  AlertActiveConfigState | undefined
>(undefined)
const AlertActiveConfigDispatchContext = createContext<
  React.Dispatch<AlertActiveConfigState | undefined> | undefined
>(undefined)

function alertActiveConfigReducer(
  activeConfig: AlertActiveConfigState | undefined,
  nextState: AlertActiveConfigState | undefined
): AlertActiveConfigState | undefined {
  return nextState
}

export function AlertActiveConfigProvider({
  children,
}: ProviderProps): JSX.Element {
  const [activeConfig, setActiveConfig] = useReducer(
    alertActiveConfigReducer,
    defaultAlertActiveConfig
  )

  return (
    <AlertActiveConfigurationStateContext.Provider value={activeConfig}>
      <AlertActiveConfigDispatchContext.Provider value={setActiveConfig}>
        {children}
      </AlertActiveConfigDispatchContext.Provider>
    </AlertActiveConfigurationStateContext.Provider>
  )
}

function useAlertActiveConfigState(): AlertActiveConfigState {
  const context = useContext(AlertActiveConfigurationStateContext)
  if (context === undefined) {
    throw new Error('fail')
  }
  return context
}

function useAlertActiveConfigDispatch(): React.Dispatch<
  AlertActiveConfigState | undefined
> {
  const context = useContext(AlertActiveConfigDispatchContext)
  if (context === undefined) {
    throw new Error('fail')
  }
  return context
}

export default function useAlertActiveConfig(): {
  activeConfig: AlertActiveConfigState
  setActiveConfig: React.Dispatch<AlertActiveConfigState | undefined>
} {
  const activeConfig = useAlertActiveConfigState()
  const dispatch = useAlertActiveConfigDispatch()

  return {
    activeConfig: activeConfig,
    setActiveConfig: dispatch,
  }
}
