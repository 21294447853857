import { useCallback, useEffect, useReducer } from 'react'
import { DrawerType } from '../../../components/Layout/PageContent'
import { AgentType, AgentTypePlural } from '../../../models'
import {
  MapRenderByGeofence,
  SelectAgentInGroupPayload,
  SelectedAgent,
  SelectedGroup,
} from '../../../models/realtimeMap'
import { BadgeTelemetryMessageWithAgentEvent } from '../../../models/telemetry/baseBadgeTelemetry'

export interface RightDrawerState {
  currentDrawerType: DrawerType
  selectedAgent: SelectedAgent | undefined
  selectedGroup: SelectedGroup | undefined
}

enum Actions {
  CloseDetailDrawer,
  ToggleFilterDrawer,
  CloseFilterDrawer,
  ResetDrawer,
  OpenAssetDetailDrawer,
  OpenStaffDetailDrawer,
  OpenGroupDetailDrawer,
  SelectAgentInGroup,
  UpdateSelectedAgentTelemetry,
  UpdateAgentInSelectedGroup,
  RemoveAgentFromSelectedGroup,
  RemoveSelectedAgent,
  AddAgentToSelectedGroup,
  SelectAgentFromGroupDetailDrawer,
  UpdateDrawerByAgentTypes,
  MoveSelectedAgentOutOfGroup,
}

type SelectAgentAction = {
  type: Actions.OpenAssetDetailDrawer | Actions.OpenStaffDetailDrawer
  payload: SelectedAgent
}

type SelectGroupAction = {
  type: Actions.OpenGroupDetailDrawer
  payload: SelectedGroup
}

type SelectAgentInGroupAction = {
  type: Actions.SelectAgentInGroup
  payload: SelectAgentInGroupPayload
}

type ActionWithTelemetryPayload = {
  type:
    | Actions.SelectAgentFromGroupDetailDrawer
    | Actions.UpdateSelectedAgentTelemetry
    | Actions.RemoveAgentFromSelectedGroup
    | Actions.RemoveSelectedAgent
    | Actions.AddAgentToSelectedGroup
    | Actions.UpdateAgentInSelectedGroup
  payload: BadgeTelemetryMessageWithAgentEvent
}

type MoveSelectedAgentOutOfGroupAction = {
  type: Actions.MoveSelectedAgentOutOfGroup
  payload: SelectedAgent
}

type UpdateDrawerByAgentTypesPayload = {
  agentTypes: string[]
  updatedMapRender: MapRenderByGeofence
}

type UpdateDrawerByAgentTypeAction = {
  type: Actions.UpdateDrawerByAgentTypes
  payload: UpdateDrawerByAgentTypesPayload
}

type ActionsWithNoPayload = {
  type:
    | Actions.CloseDetailDrawer
    | Actions.ToggleFilterDrawer
    | Actions.CloseFilterDrawer
    | Actions.ResetDrawer
}

type RightDrawerActions =
  | ActionsWithNoPayload
  | SelectAgentAction
  | SelectGroupAction
  | MoveSelectedAgentOutOfGroupAction
  | UpdateDrawerByAgentTypeAction
  | ActionWithTelemetryPayload
  | SelectAgentInGroupAction

function rightDrawerReducer(
  state: RightDrawerState,
  action: RightDrawerActions
): RightDrawerState {
  switch (action.type) {
    case Actions.OpenAssetDetailDrawer:
      if (state.currentDrawerType === DrawerType.RealTimeAssetDetailDrawer) {
        return {
          ...state,
          selectedAgent: action.payload,
          selectedGroup: undefined,
        }
      } else {
        return {
          ...state,
          currentDrawerType: DrawerType.RealTimeAssetDetailDrawer,
          selectedAgent: action.payload,
          selectedGroup: undefined,
        }
      }
    case Actions.OpenStaffDetailDrawer:
      if (state.currentDrawerType === DrawerType.RealTimeStaffDetailDrawer) {
        return {
          ...state,
          selectedAgent: action.payload,
          selectedGroup: undefined,
        }
      } else {
        return {
          ...state,
          currentDrawerType: DrawerType.RealTimeStaffDetailDrawer,
          selectedAgent: action.payload,
          selectedGroup: undefined,
        }
      }
    case Actions.OpenGroupDetailDrawer:
      if (state.currentDrawerType === DrawerType.RealTimeGroupDetailDrawer) {
        if (
          state.selectedGroup?.groupTelemetries.geoFenceId ===
          action.payload.groupTelemetries.geoFenceId
        ) {
          return {
            ...state,
            currentDrawerType: DrawerType.None,
            selectedGroup: undefined,
          }
        } else {
          return {
            ...state,
            selectedGroup: action.payload,
          }
        }
      } else {
        return {
          ...state,
          currentDrawerType: DrawerType.RealTimeGroupDetailDrawer,
          selectedGroup: action.payload,
          selectedAgent: undefined,
        }
      }
    case Actions.SelectAgentInGroup:
      return {
        ...state,
        currentDrawerType:
          action.payload.selectedAgent.agent.agentType === AgentType.Asset
            ? DrawerType.RealTimeAssetDetailDrawer
            : DrawerType.RealTimeStaffDetailDrawer,
        selectedGroup: action.payload.group,
        selectedAgent: {
          inpixonAsset: undefined,
          telemetry: action.payload.selectedAgent,
        },
      }
    case Actions.SelectAgentFromGroupDetailDrawer:
      if (state.currentDrawerType === DrawerType.RealTimeGroupDetailDrawer) {
        return {
          ...state,
          currentDrawerType:
            action.payload.agent.agentType === AgentType.Asset
              ? DrawerType.RealTimeAssetDetailDrawer
              : DrawerType.RealTimeStaffDetailDrawer,
          selectedAgent: {
            telemetry: action.payload,
            inpixonAsset: undefined,
          },
        }
      }
      return state
    case Actions.CloseDetailDrawer:
      if (
        state.currentDrawerType === DrawerType.RealTimeAssetDetailDrawer ||
        state.currentDrawerType === DrawerType.RealTimeStaffDetailDrawer ||
        state.currentDrawerType === DrawerType.RealTimeGroupDetailDrawer
      ) {
        return {
          ...state,
          currentDrawerType: DrawerType.None,
          selectedAgent: undefined,
          selectedGroup: undefined,
        }
      }
      return state
    case Actions.CloseFilterDrawer:
      return {
        ...state,
        currentDrawerType: DrawerType.None,
      }
    case Actions.ToggleFilterDrawer:
      if (state.currentDrawerType === DrawerType.FilterDrawer) {
        return {
          ...state,
          currentDrawerType: DrawerType.None,
        }
      } else if (
        state.currentDrawerType === DrawerType.RealTimeAssetDetailDrawer ||
        state.currentDrawerType === DrawerType.RealTimeStaffDetailDrawer ||
        state.currentDrawerType === DrawerType.RealTimeGroupDetailDrawer
      ) {
        return {
          ...state,
          currentDrawerType: DrawerType.FilterDrawer,
          selectedAgent: undefined,
          selectedGroup: undefined,
        }
      } else {
        return {
          ...state,
          currentDrawerType: DrawerType.FilterDrawer,
        }
      }
    case Actions.UpdateSelectedAgentTelemetry:
      if (!state.selectedAgent) {
        return state
      }
      return {
        ...state,
        selectedAgent: {
          ...state.selectedAgent,
          telemetry: action.payload,
        },
      }
    case Actions.MoveSelectedAgentOutOfGroup:
      if (!state.selectedAgent) {
        return state
      }
      if (state.selectedGroup) {
        return {
          ...state,
          selectedAgent: action.payload,
          selectedGroup: undefined,
        }
      }
      return state
    case Actions.UpdateAgentInSelectedGroup:
      if (!state.selectedGroup) {
        return state
      }
      const remainingAgents =
        state.selectedGroup.groupTelemetries.renderedAgents.filter(
          (telemetry) => telemetry.trackingId !== action.payload.trackingId
        )
      return {
        ...state,
        selectedGroup: {
          ...state.selectedGroup,
          groupTelemetries: {
            ...state.selectedGroup.groupTelemetries,
            renderedAgents: [...remainingAgents, action.payload],
          },
        },
      }
    case Actions.RemoveAgentFromSelectedGroup:
      if (!state.selectedGroup) {
        return state
      }
      const updatedAgentsInGroupAfterRemovingAgent =
        state.selectedGroup.groupTelemetries.renderedAgents.filter(
          (telemetry) => telemetry.trackingId !== action.payload.trackingId
        )
      return {
        ...state,
        selectedGroup: {
          ...state.selectedGroup,
          groupTelemetries: {
            ...state.selectedGroup.groupTelemetries,
            renderedAgents: updatedAgentsInGroupAfterRemovingAgent,
          },
        },
      }
    case Actions.RemoveSelectedAgent:
      if (!state.selectedAgent) {
        return state
      }
      if (
        state.currentDrawerType === DrawerType.RealTimeAssetDetailDrawer ||
        state.currentDrawerType === DrawerType.RealTimeStaffDetailDrawer
      ) {
        return {
          ...state,
          currentDrawerType: DrawerType.None,
          selectedAgent: undefined,
        }
      }
      return state
    case Actions.AddAgentToSelectedGroup:
      if (!state.selectedGroup) {
        return state
      }
      const updatedAgentsInGroupAfterAddingAgent = [
        ...state.selectedGroup.groupTelemetries.renderedAgents,
        action.payload,
      ]
      return {
        ...state,
        selectedGroup: {
          ...state.selectedGroup,
          groupTelemetries: {
            ...state.selectedGroup.groupTelemetries,
            renderedAgents: updatedAgentsInGroupAfterAddingAgent,
          },
        },
      }
    case Actions.ResetDrawer:
      return {
        currentDrawerType: DrawerType.None,
        selectedAgent: undefined,
        selectedGroup: undefined,
      }
    case Actions.UpdateDrawerByAgentTypes:
      if (
        action.payload.agentTypes.includes(
          AgentTypePlural[AgentTypePlural.Assets]
        ) &&
        action.payload.agentTypes.includes(
          AgentTypePlural[AgentTypePlural.Staff]
        )
      ) {
        if (state.currentDrawerType === DrawerType.RealTimeGroupDetailDrawer) {
          if (state.selectedGroup) {
            const selectedGroupMapRender =
              action.payload.updatedMapRender[
                state.selectedGroup.groupTelemetries.geoFenceId
              ]
            return {
              ...state,
              selectedGroup: {
                ...state.selectedGroup,
                groupTelemetries: {
                  ...state.selectedGroup.groupTelemetries,
                  renderedAgents: selectedGroupMapRender.renderedAgents,
                },
              },
            }
          }
        }
      } else if (
        !action.payload.agentTypes.includes(
          AgentTypePlural[AgentTypePlural.Assets]
        ) ||
        !action.payload.agentTypes.includes(
          AgentTypePlural[AgentTypePlural.Staff]
        )
      ) {
        if (
          state.currentDrawerType === DrawerType.RealTimeAssetDetailDrawer &&
          !action.payload.agentTypes.includes(
            AgentTypePlural[AgentTypePlural.Assets]
          )
        ) {
          return {
            ...state,
            currentDrawerType: DrawerType.None,
            selectedAgent: undefined,
            selectedGroup: undefined,
          }
        } else if (
          state.currentDrawerType === DrawerType.RealTimeStaffDetailDrawer &&
          !action.payload.agentTypes.includes(
            AgentTypePlural[AgentTypePlural.Staff]
          )
        ) {
          return {
            ...state,
            currentDrawerType: DrawerType.None,
            selectedAgent: undefined,
            selectedGroup: undefined,
          }
        } else if (
          state.currentDrawerType === DrawerType.RealTimeGroupDetailDrawer
        ) {
          if (state.selectedGroup) {
            return {
              ...state,
              selectedGroup: {
                ...state.selectedGroup,
                groupTelemetries: {
                  ...state.selectedGroup.groupTelemetries,
                  renderedAgents:
                    action.payload.updatedMapRender[
                      state.selectedGroup.groupTelemetries.geoFenceId
                    ].renderedAgents,
                },
              },
            }
          }
        }
      }
      return state
    default:
      return state
  }
}

export const useRealTimeMapRightDrawer = (): {
  rightDrawerState: RightDrawerState
  openAssetDetailDrawer: (payload: SelectedAgent) => void
  openStaffDetailDrawer: (payload: SelectedAgent) => void
  openGroupDetailDrawer: (group: SelectedGroup) => void
  selectAgentFromGroupDetailDrawer: (
    agentTelemetry: BadgeTelemetryMessageWithAgentEvent
  ) => void
  selectAgentInGroup: (payload: SelectAgentInGroupPayload) => void
  closeDetailDrawer: () => void
  toggleFilterDrawer: () => void
  updateSelectedAgentTelemetry: (
    updatedAgent: BadgeTelemetryMessageWithAgentEvent
  ) => void
  moveSelectedAgentOutOfGroup: (updatedAgent: SelectedAgent) => void
  closeFilterDrawer: () => void
  updateAgentInSelectedGroup: (
    agentTelemetry: BadgeTelemetryMessageWithAgentEvent
  ) => void
  removeAgentFromSelectedGroup: (
    agentTelemtry: BadgeTelemetryMessageWithAgentEvent
  ) => void
  removeSelectedAgent: (
    agentTelemtry: BadgeTelemetryMessageWithAgentEvent
  ) => void
  addAgentToSelectedGroup: (
    agentTelemtry: BadgeTelemetryMessageWithAgentEvent
  ) => void
  updateDrawerByAgentTypes: (payload: UpdateDrawerByAgentTypesPayload) => void
  resetRightDrawerState: () => void
} => {
  const initialState: RightDrawerState = {
    currentDrawerType: DrawerType.None,
    selectedAgent: undefined,
    selectedGroup: undefined,
  }
  const [rightDrawerState, dispatch] = useReducer(
    rightDrawerReducer,
    initialState
  )

  const openAssetDetailDrawer = useCallback((payload: SelectedAgent): void => {
    dispatch({ type: Actions.OpenAssetDetailDrawer, payload })
  }, [])

  const openStaffDetailDrawer = useCallback((payload: SelectedAgent): void => {
    dispatch({ type: Actions.OpenStaffDetailDrawer, payload })
  }, [])

  const openGroupDetailDrawer = useCallback((group: SelectedGroup): void => {
    dispatch({ type: Actions.OpenGroupDetailDrawer, payload: group })
  }, [])

  const selectAgentFromGroupDetailDrawer = useCallback(
    (agentTelemetry: BadgeTelemetryMessageWithAgentEvent) => {
      dispatch({
        type: Actions.SelectAgentFromGroupDetailDrawer,
        payload: agentTelemetry,
      })
    },
    []
  )

  const selectAgentInGroup = useCallback(
    (payload: SelectAgentInGroupPayload) => {
      dispatch({
        type: Actions.SelectAgentInGroup,
        payload,
      })
    },
    []
  )

  const closeDetailDrawer = useCallback((): void => {
    dispatch({ type: Actions.CloseDetailDrawer })
  }, [])

  const toggleFilterDrawer = useCallback(() => {
    dispatch({ type: Actions.ToggleFilterDrawer })
  }, [])

  const updateSelectedAgentTelemetry = useCallback(
    (updatedAgent: BadgeTelemetryMessageWithAgentEvent) => {
      dispatch({
        type: Actions.UpdateSelectedAgentTelemetry,
        payload: updatedAgent,
      })
    },
    []
  )

  const moveSelectedAgentOutOfGroup = useCallback(
    (updatedAgent: SelectedAgent) => {
      dispatch({
        type: Actions.MoveSelectedAgentOutOfGroup,
        payload: updatedAgent,
      })
    },
    []
  )

  const removeAgentFromSelectedGroup = useCallback(
    (agentTelemtry: BadgeTelemetryMessageWithAgentEvent) => {
      dispatch({
        type: Actions.RemoveAgentFromSelectedGroup,
        payload: agentTelemtry,
      })
    },
    []
  )

  const removeSelectedAgent = useCallback(
    (agentTelemtry: BadgeTelemetryMessageWithAgentEvent) => {
      dispatch({
        type: Actions.RemoveSelectedAgent,
        payload: agentTelemtry,
      })
    },
    []
  )

  const addAgentToSelectedGroup = useCallback(
    (agentTelemtry: BadgeTelemetryMessageWithAgentEvent) => {
      dispatch({
        type: Actions.AddAgentToSelectedGroup,
        payload: agentTelemtry,
      })
    },
    []
  )

  const updateAgentInSelectedGroup = useCallback(
    (agentTelemtry: BadgeTelemetryMessageWithAgentEvent) => {
      dispatch({
        type: Actions.UpdateAgentInSelectedGroup,
        payload: agentTelemtry,
      })
    },
    []
  )

  const closeFilterDrawer = useCallback(() => {
    dispatch({ type: Actions.CloseFilterDrawer })
  }, [])

  const updateDrawerByAgentTypes = useCallback(
    (payload: UpdateDrawerByAgentTypesPayload) => {
      dispatch({
        type: Actions.UpdateDrawerByAgentTypes,
        payload,
      })
    },
    []
  )

  const resetRightDrawerState = useCallback(() => {
    dispatch({ type: Actions.ResetDrawer })
  }, [])

  // Clean up on navigating away
  useEffect(() => {
    return () => {
      dispatch({ type: Actions.ResetDrawer })
    }
  }, [])

  return {
    rightDrawerState,
    openAssetDetailDrawer,
    openStaffDetailDrawer,
    openGroupDetailDrawer,
    selectAgentFromGroupDetailDrawer,
    closeDetailDrawer,
    toggleFilterDrawer,
    updateSelectedAgentTelemetry,
    moveSelectedAgentOutOfGroup,
    closeFilterDrawer,
    removeAgentFromSelectedGroup,
    removeSelectedAgent,
    addAgentToSelectedGroup,
    updateAgentInSelectedGroup,
    updateDrawerByAgentTypes,
    selectAgentInGroup,
    resetRightDrawerState,
  }
}
