import { getType } from 'typesafe-actions'
import {
  BadgeSensorState,
  BadgeSensorStateState,
} from '../models/badgeSensorState'
import { BadgeSensorStateAction } from '../actions'
import {
  fetchBadgeSensorStatesByBadgeIdsAction,
  fetchBadgeSensorStatesByLocationIdAction,
} from '../actions/badgeSensorStates'
import { FetchingStatus } from '../models'

const withLastUpdatedDate = (
  badgeAssignment: BadgeSensorState
): BadgeSensorState => ({ ...badgeAssignment, lastUpdated: Date.now() })

const initialState: BadgeSensorStateState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

export default (
  state = initialState,
  action: BadgeSensorStateAction
): BadgeSensorStateState => {
  if (!action.payload) {
    return state
  }
  switch (action.type) {
    case getType(fetchBadgeSensorStatesByLocationIdAction.request):
      return {
        ...state,
        status: FetchingStatus.Request,
      }
    case getType(fetchBadgeSensorStatesByLocationIdAction.failure):
      return {
        ...state,
        status: FetchingStatus.Failure,
      }
    case getType(fetchBadgeSensorStatesByBadgeIdsAction.success):
      return {
        data: action.payload.reduce(
          (badgeAssignments, badgeAssignment) => ({
            ...badgeAssignments,
            [badgeAssignment.badgeID]: withLastUpdatedDate(badgeAssignment),
          }),
          { ...state.data }
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(fetchBadgeSensorStatesByLocationIdAction.success):
      return {
        data: action.payload.reduce(
          (badgeAssignments, badgeAssignment) => ({
            ...badgeAssignments,
            [badgeAssignment.badgeID]: withLastUpdatedDate(badgeAssignment),
          }),
          {}
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    default:
      return state
  }
}
