import { EditOutlined } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import { ColumnDef } from '@tanstack/react-table'
import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  deleteUserSettingsAction,
  patchUserSettingsAction,
} from '../../actions/userSettings'
import { mapAlertToAlertActiveConfig } from '../../helpers/alert'
import {
  useFetchAlertSubscriptionKeys,
  useFetchUserSettings,
  usePermissionCheck,
} from '../../hooks'
import { useSearchAndFilters } from '../../hooks/filters'
import {
  AlertConfigSteps,
  FetchingStatus,
  PermissionsEnum,
  TableColumnSettings,
  UserSettingsType,
  defaultAlertConfigSteps,
} from '../../models'
import { Alert, AlertStatus } from '../../models/alert'
import AlertSubscriptionKeyTextBox from '../../pages/AlertManagement/AlertSubscriptionKeyTextBox'
import useAlertActiveConfig from '../../pages/AlertManagement/useAlertActiveConfig'
import { DataTestIds } from '../../utils/test-utils/dataTestIds'
import { PrimaryButton } from '../Buttons'
import { ReactTable } from '../Tables'
import { SelectRowActionPayload } from '../Tables/useReactTableRightDrawer'
import { QueryFilter } from '../../filters'
import { renderUTCDate } from '../../helpers/reactTable'

interface RowSelectionOptions {
  selectedAlert: AlertRow | undefined
  selectedRowIndex: number | undefined
  onSelectAlert: (data: SelectRowActionPayload<AlertRow>) => void
  clearSelectedAlert: () => void
  resetSelectedRowIndex: (index: number) => void
}

interface Props {
  alerts: Alert[]
  isFetching: boolean
  canEdit: boolean
  onEditAlert: () => void
  tableRowSelectionOptions: RowSelectionOptions
  onCreateAlert: () => void
  alertFilterQuery: QueryFilter<Alert>
}

export interface AlertRow extends Alert {
  buildingGroupLocationQualifier: string
  buildingLocationQualifier: string
  floorLocationQualifier: string
  unitQualifierName: string
  formattedAlertStatus: string
}

const AlertList = (props: Props): JSX.Element => {
  const {
    alerts,
    isFetching,
    canEdit,
    onEditAlert,
    onCreateAlert,
    tableRowSelectionOptions,
    alertFilterQuery,
  } = props
  const {
    selectedAlert,
    selectedRowIndex,
    onSelectAlert,
    clearSelectedAlert,
    resetSelectedRowIndex,
  } = tableRowSelectionOptions

  const { data: userSettings, status: userSettingsStatus } =
    useFetchUserSettings()
  const hasWritePermissions = usePermissionCheck([
    PermissionsEnum.AlertAdminWrite,
  ])
  const { alertSubscriptionKeys, status: alertSubscriptionKeyStatus } =
    useFetchAlertSubscriptionKeys()

  const dispatch = useDispatch()
  const history = useNavigate()
  const { setActiveConfig } = useAlertActiveConfig()

  const alertRows: AlertRow[] = useMemo((): AlertRow[] => {
    return alerts.map((alert) => {
      return {
        ...alert,
        buildingGroupLocationQualifier:
          alert.locationQualifier?.campus?.name ?? '',
        buildingLocationQualifier:
          alert.locationQualifier?.building?.name ?? '',
        floorLocationQualifier: alert.locationQualifier?.floor?.name ?? '',
        unitQualifierName: alert.unitQualifier?.name ?? '',
        formattedAlertStatus: AlertStatus[alert.alertStatus],
      }
    })
  }, [alerts])

  const tableData = useSearchAndFilters(
    alertRows,
    alertFilterQuery.criteria,
    [],
    {
      lastModified: 'datetime',
      lastRun: 'datetime',
    }
  )

  const columns = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const columnDefinitions: ColumnDef<AlertRow, any>[] = [
      {
        accessorKey: 'name',
        header: 'Alert Name',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'alertTypeName',
        header: 'Type',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'description',
        header: 'Description',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'buildingGroupLocationQualifier',
        header: 'Building Group',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'buildingLocationQualifier',
        header: 'Building',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'floorLocationQualifier',
        header: 'Floor',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'unitQualifierName',
        header: 'Unit',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'modifiedDate',
        header: 'Last Modified',
        // Render the date in UTC, or it will be changed by the local timezone
        cell: (cell) => renderUTCDate(cell.getValue(), 'MM/DD/YYYY'),
        sortingFn: 'rtlsDateTime',
        size: 125,
      },
      {
        accessorKey: 'lastRun',
        header: 'Last Run',
        // Render the date in UTC, or it will be changed by the local timezone
        cell: (cell) => renderUTCDate(cell.getValue(), 'MM/DD/YYYY'),
        sortingFn: 'rtlsDateTime',
        size: 125,
      },
      {
        accessorKey: 'formattedAlertStatus',
        header: 'Status',
        sortingFn: 'alphanumeric',
      },
      {
        id: 'edit',
        header: 'Edit',
        accessorFn: (row) => (
          <Tooltip title='Edit Alert'>
            <IconButton
              size='small'
              color='primary'
              onClick={() => {
                if (row.id) {
                  const alertActiveConfig = mapAlertToAlertActiveConfig(row)
                  setActiveConfig(alertActiveConfig)
                  onEditAlert()
                }
                history(
                  `/admin/alerts/${row.id}/${
                    defaultAlertConfigSteps[
                      AlertConfigSteps[AlertConfigSteps.CONDITIONS]
                    ].path
                  }`
                )
              }}
            >
              <EditOutlined />
            </IconButton>
          </Tooltip>
        ),
        enableSorting: false,
        enablePinning: true,
        enableHiding: !canEdit,
        cell: (cell) => cell.getValue(),
        meta: {
          align: 'right',
        },
      },
    ]
    return columnDefinitions
  }, [canEdit, onEditAlert, history, setActiveConfig])

  const handleSave = (
    settings: TableColumnSettings,
    saveDefault: boolean
  ): void => {
    if (!saveDefault) {
      dispatch(
        patchUserSettingsAction.request({
          alertAdmin: settings,
        })
      )
    } else {
      dispatch(deleteUserSettingsAction.request(UserSettingsType.AlertAdmin))
    }
  }

  const actions = hasWritePermissions
    ? [
        <AlertSubscriptionKeyTextBox
          key={'ask-text'}
          loading={alertSubscriptionKeyStatus === FetchingStatus.Request}
          text={
            alertSubscriptionKeys &&
            Object.values(alertSubscriptionKeys).length > 0 &&
            Object.values(alertSubscriptionKeys)[0]?.alertSubscriptionKey
              ? Object.values(alertSubscriptionKeys)[0]?.alertSubscriptionKey
              : 'Subscription Key not configured'
          }
          leadingText={
            alertSubscriptionKeys &&
            Object.values(alertSubscriptionKeys).length > 0
              ? 'Alert Subscription Key: '
              : ''
          }
        ></AlertSubscriptionKeyTextBox>,
        <PrimaryButton
          key={`action-0`}
          variant='outlined'
          onClick={onCreateAlert}
          style={{ marginRight: '0px' }}
        >
          New Alert
        </PrimaryButton>,
      ]
    : []

  return (
    <div data-testid={DataTestIds.alertAdminPageAlertList}>
      <ReactTable
        tableToolbarOptions={{
          title: 'Alert Administration',
          actions: actions,
        }}
        tableOptions={{
          data: tableData ?? [],
          columns: columns,
          showLoading:
            isFetching || userSettingsStatus !== FetchingStatus.Success,
        }}
        tableRowOptions={{
          rowSelectionOptions: {
            selectedRow: selectedAlert,
            selectedRowIndex,
            onSelectRow: onSelectAlert,
            clearSelectedRow: clearSelectedAlert,
            resetSelectedRowIndex,
            dataMatchPredicate: (alertA: AlertRow, alertB: AlertRow) =>
              alertA.id === alertB.id,
          },
        }}
      />
    </div>
  )
}

export default AlertList
