import { SecurityRouter } from '../models/securityRouter'
import { BaseService } from './baseService'

export class SecurityRoutersApi extends BaseService {
  constructor() {
    super({ entityType: 'securityRouters' })
  }
  route = 'alert/securityRouters'

  async getAllSecurityRouters(): Promise<SecurityRouter[]> {
    return await this.get<SecurityRouter[]>(this.route)
  }
}
