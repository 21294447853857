import React from 'react'
import useRealTimeMapControl from '../../hooks/useRealTimeMapControl'
import { ZoomControl } from './ZoomControl'

const InpixonMapPlaceholder: React.FC = () => {
  const mapOptions = useRealTimeMapControl()
  return mapOptions.shouldShow ? (
    <div
      className='map'
      style={{
        position: 'fixed',
        height: mapOptions?.rectangle.height,
        width: '100%',
        top: mapOptions?.rectangle.top,
        justifyContent: 'center',
        overflow: 'hidden',
        zIndex: 10,
      }}
    >
      <>
        {mapOptions?.jibestreamControl && (
          <ZoomControl control={mapOptions?.jibestreamControl} />
        )}
      </>
    </div>
  ) : (
    <div />
  )
}

export { InpixonMapPlaceholder }
