import React, { ReactNode, PropsWithChildren } from 'react'
import DialogBox from './Dialogs/DialogBox'
import { Button } from './Buttons'
import { colors } from '../styles'

interface Props extends PropsWithChildren {
  errorMessage?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  fullContainer?: boolean
  title?: string
  message?: string
  customHeader?: ReactNode
}

interface State {
  error: any // eslint-disable-line @typescript-eslint/no-explicit-any
  errorInfo: any // eslint-disable-line @typescript-eslint/no-explicit-any
  hasError: boolean
}

export default class ErrorBoundaryModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      error: null,
      errorInfo: null,
      hasError: false,
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public componentDidCatch(error: any, errorInfo: any): void {
    this.setState({
      error,
      errorInfo,
      hasError: true,
    })
  }

  public render(): JSX.Element | ReactNode {
    const { hasError, error, errorInfo } = this.state
    const { title, message } = this.props

    const handleClose = () => {
      window.location.reload()
    }

    if (hasError) {
      console.log(error)
      console.log(errorInfo)
    }

    if (hasError) {
      return (
        <div style={{ flexGrow: 1, padding: 24, overflowY: 'auto' }}>
          <DialogBox
            maxWidth='xs'
            open={hasError}
            handleClose={handleClose}
            title={'Map Loading Error'}
            customHeader={
              <div
                style={{
                  fontFamily: 'Ubuntu',
                  fontSize: '20px',
                  fontWeight: 'bold',
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                  lineHeight: 'normal',
                  letterSpacing: 'normal',
                  textAlign: 'left',
                  color: '#333',
                  marginLeft: '24px',
                  marginRight: '24px',
                  marginTop: '24px',
                  marginBottom: '8px',
                }}
              >
                {title}
              </div>
            }
          >
            <div>
              <div
                style={{
                  fontFamily: 'Ubuntu',
                  fontSize: '16px',
                  fontWeight: 'normal',
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                  lineHeight: 'normal',
                  letterSpacing: 'normal',
                  textAlign: 'left',
                  color: '#333',
                }}
              >
                {message}
              </div>
              <div
                style={{
                  justifyContent: 'flex-end',
                  float: 'right',
                }}
              >
                <Button
                  variant='text'
                  onClick={handleClose}
                  bgColor={colors.transparent}
                  textColor={colors.primary}
                >
                  Try Again
                </Button>
              </div>
            </div>
          </DialogBox>
        </div>
      )
    }

    return this.props.children
  }
}
