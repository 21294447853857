export const replaceNbsps = (str: string | undefined): string | undefined => {
  if (!str) {
    return str
  }
  const re = new RegExp(String.fromCharCode(160), 'g')
  return str.replace(re, ' ')
}
export const replaceLowBatterySvg = (
  str: string | undefined
): string | undefined => {
  if (!str) {
    return str
  }
  return str.replace(
    `</svg>`,
    `<circle id="lowBattery" cx="26.00" cy="3.947" r="4" style="fill: rgb(255, 0, 0)"/></svg>`
  )
}
export const sanitizeLogString = (str: string | undefined): string => {
  if (!str) {
    return ''
  }
  const re = new RegExp(/[<|>|\n|\r|\r\n]/)
  return str.replace(re, '')
}

export const capitalizeFirstChar = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1)

export const lowercaseFirstChar = (str: string): string =>
  str.charAt(0).toLowerCase() + str.slice(1)

export const camelCaseToArray = (str: string): RegExpMatchArray | null =>
  str.match(/[A-Z][a-z]+/g)

export const stringToNumber = (s: string | undefined): number | undefined => {
  const xAsNumber = Number(s)
  return isNaN(xAsNumber) ? undefined : xAsNumber
}

export const isEmptyOrSpaces = (s: string): boolean => {
  return s === null || s.match(/^ *$/) !== null
}
