import { Role } from '@midmarkrtls/common/models'
import { createAsyncAction } from 'typesafe-actions'

export const getOperationsRolesAction = createAsyncAction(
  'GET_OPERATIONS_ROLES_REQUEST',
  'GET_OPERATIONS_ROLES_SUCCESS',
  'GET_OPERATIONS_ROLES_FAILURE'
)<void, Role[], Error>()

export const getOperationsRolesByUserIdAction = createAsyncAction(
  'GET_OPERATIONS_ROLES_BY_USERID_REQUEST',
  'GET_OPERATIONS_ROLES_BY_USERID_SUCCESS',
  'GET_OPERATIONS_ROLES_BY_USERID_FAILURE'
)<string, Role[], Error>()

export const getOperationsRoleByIdAction = createAsyncAction(
  'GET_OPERATIONS_ROLE_BY_ID_REQUEST',
  'GET_OPERATIONS_ROLE_BY_ID_SUCCESS',
  'GET_OPERATIONS_ROLE_BY_ID_FAILURE'
)<number, Role, Error>()

export const postOperationsRoleAction = createAsyncAction(
  'POST_OPERATIONS_ROLE_REQUEST',
  'POST_OPERATIONS_ROLE_SUCCESS',
  'POST_OPERATIONS_ROLE_FAILURE'
)<Role, Role, Error>()

export const putOperationsRoleAction = createAsyncAction(
  'PUT_OPERATIONS_ROLE_REQUEST',
  'PUT_OPERATIONS_ROLE_SUCCESS',
  'PUT_OPERATIONS_ROLE_FAILURE'
)<Role, Role, Error>()

export const deleteOperationsRoleAction = createAsyncAction(
  'DELETE_OPERATIONS_ROLE_REQUEST',
  'DELETE_OPERATIONS_ROLE_SUCCESS',
  'DELETE_OPERATIONS_ROLE_FAILURE'
)<number, number, Error>()
