import { useEffect } from 'react'
import { v1 as uuidv1 } from 'uuid'
import useSignalRSubscription from './useSignalRSubscription'
import { BaseBadgeTelemetryMessage as DeprecatedBaseBadgeTelemetryMessage } from '../models'
import { useTelemetrySubscription } from '../hooks'
import { Authentication } from '../authentication/AuthenticationManager'
import { fetchAccessTokenAsync } from './useFetchAccessToken'
import { setBadgeTelemetryAction } from '../actions/badgeTelemetry'
import { useDispatch } from 'react-redux'
import { BaseBadgeTelemetryMessage } from '../models/telemetry'
import { setBaseBadgeTelemetryAction } from '../actions/baseBadgeTelemetry'

const url = process.env.REACT_APP_SIGNALR_CONNECTION_MANAGER
const id = uuidv1()

export function useLiveTelemetry(): DeprecatedBaseBadgeTelemetryMessage[] {
  const { enabled, mapId, previousMapId } = useTelemetrySubscription()
  const dispatch = useDispatch()

  const handleSubscription = async (
    token: string,
    currentMapId: number | undefined,
    endpoint: string
  ) => {
    await fetch(
      `${process.env.REACT_APP_SIGNALR_CONNECTION_MANAGER}telemetry/${endpoint}`,
      {
        method: 'POST',
        headers: {
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          userId: id,
          mapId: currentMapId,
          tenantId: Authentication.currentCustomerId,
        }),
      }
    )
  }

  useEffect(() => {
    fetchAccessTokenAsync().then((token) => {
      if (enabled) {
        if (previousMapId !== mapId) {
          handleSubscription(token, previousMapId, 'unsubscribe')
        }
        handleSubscription(token, mapId, 'subscribe')
      } else {
        handleSubscription(token, previousMapId, 'unsubscribe')
        handleSubscription(token, mapId, 'unsubscribe')
      }
    })
  }, [mapId, enabled])

  // signalr feed
  const telemetry = useSignalRSubscription<DeprecatedBaseBadgeTelemetryMessage>(
    {
      url,
      method: 'RCVTLMY',
      id,
      enabled,
    }
  )

  useEffect(() => {
    if (telemetry?.length && enabled) {
      dispatch(setBadgeTelemetryAction(telemetry))
      // Temporary data transform until we can refactor the RTList to switch to using BaseBadgeTelemetryMessage type
      const baseBadgeTelemetry = telemetry as BaseBadgeTelemetryMessage[]
      dispatch(setBaseBadgeTelemetryAction(baseBadgeTelemetry))
    }
  }, [dispatch, telemetry, enabled])

  return telemetry ?? []
}
