import { AssetType } from '../models'
import { BaseService } from './baseService'

export class AssetTypesApi extends BaseService {
  constructor() {
    super({ entityType: 'assetsTypes' })
  }
  route = 'assets/types'

  async getAllAssetTypes(): Promise<AssetType[]> {
    return await this.get<AssetType[]>(this.route)
  }
}
