import { useEffect } from 'react'
import { v1 as uuidv1 } from 'uuid'
import useEventSignalRSubscription from './useEventSignalRSubscription'
import { AgentEventType, TelemetryEventLocation } from '../models'
import { Authentication } from '../authentication/AuthenticationManager'
import { fetchAccessTokenAsync } from '.'
import { useDispatch } from 'react-redux'
import { setBadgeEventTelemetryAction } from '../actions/badgeEventTelemetry'

const url = process.env.REACT_APP_SIGNALR_CONNECTION_MANAGER
const id = uuidv1()

export function useLiveEventTelemetry(
  enabled?: boolean,
  assetAlertEnabled?: boolean
): TelemetryEventLocation[] | null {
  const handleSubscription = async (token: string, endpoint: string) => {
    await fetch(
      `${process.env.REACT_APP_SIGNALR_CONNECTION_MANAGER}events/${endpoint}`,
      {
        method: 'POST',
        headers: {
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          userId: id,
          tenantId: Authentication.currentCustomerId,
        }),
      }
    )
  }

  useEffect(() => {
    fetchAccessTokenAsync().then((token) => {
      if (enabled) {
        handleSubscription(token, 'subscribe')
      } else {
        handleSubscription(token, 'unsubscribe')
      }
    })
  }, [enabled])

  // signalr feed
  const telemetry = useEventSignalRSubscription<TelemetryEventLocation>({
    url,
    method: 'RCVEVNT',
    id,
    enabled,
  })

  const dispatch = useDispatch()
  useEffect(() => {
    if (telemetry?.length) {
      //const filteredTelemetry = assetAlertEnabled
      //  ? telemetry
      //  : telemetry.filter(
      //      (x) =>
      //        Number(x.agentEventType) !== AgentEventType.AssetEnter &&
      //        Number(x.agentEventType) !== AgentEventType.AssetExit
      //    )
      const filteredTelemetry = telemetry.filter(
        (x) =>
          Number(x.agentEventType) !== AgentEventType.AssetEnter &&
          Number(x.agentEventType) !== AgentEventType.AssetExit
      )
      if (filteredTelemetry.length > 0) {
        dispatch(setBadgeEventTelemetryAction(filteredTelemetry))
      }
    }
  }, [assetAlertEnabled, dispatch, telemetry])

  return telemetry ?? []
}
