import { FetchingState } from '../interfaces/fetchingState'

export interface ChannelSound {
  id: string
  name: string
  cdnPath: string
}

export interface ChannelSounds {
  [id: string]: ChannelSound
}

export type ChannelSoundsState = Readonly<FetchingState<ChannelSounds>>

export const noSoundChannel: ChannelSound = {
  id: '-1',
  name: 'No Sound',
  cdnPath: '',
}
