import { Collapse, List } from '@mui/material'
import { ClassNameMap } from '@mui/styles'
import clsx from 'clsx'
import React, { ReactElement, useMemo } from 'react'
import { Page } from '../../../interfaces/modules'
import RouterLink from '../../RouterLink'
import SideNavLink from './SideNavLink'

interface Props {
  classes: Partial<ClassNameMap<string>>
  icon: ReactElement
  isGroupedNavLinksExpanded: boolean
  isSideNavCollapsed: boolean
  name: string
  pages: Page[]
  selectedChildPage: string | undefined
  onSelectPage: () => void
  onPageGroupClick: () => void
}

const GroupedNavLinks: React.FC<Props> = (props: Props) => {
  const {
    name,
    icon,
    pages,
    classes,
    isSideNavCollapsed,
    selectedChildPage,
    isGroupedNavLinksExpanded,
    onSelectPage,
    onPageGroupClick,
  } = props

  const carretClass = useMemo(() => {
    if (selectedChildPage) {
      return null
    }
    return isGroupedNavLinksExpanded
      ? classes.caretExpanded
      : classes.caretCollapsed
  }, [
    selectedChildPage,
    isGroupedNavLinksExpanded,
    classes.caretCollapsed,
    classes.caretExpanded,
  ])

  return (
    <List disablePadding className={classes.pageGroup}>
      {/* Page Group Row */}
      <SideNavLink
        component={'li'}
        name={name}
        icon={icon}
        isCollapsed={isSideNavCollapsed}
        classes={{
          listItem: {
            root: clsx(
              selectedChildPage ? classes.subSelectedRow : '',
              classes.row,
              carretClass
            ),
          },
          listItemIcon: { root: classes.iconCell },
          listItemText: { primary: classes.pageListItemText },
        }}
        onSelectPage={onPageGroupClick}
      />
      {/* Child Page Rows */}
      <Collapse in={isGroupedNavLinksExpanded} component='li'>
        <ul style={{ padding: 0 }}>
          {pages.map((page) => {
            return (
              <li key={page.key}>
                <SideNavLink
                  component={RouterLink(`/${page.link}`)}
                  name={page.name}
                  icon={page.icon}
                  isCollapsed={isSideNavCollapsed}
                  classes={{
                    listItem: {
                      root: clsx(
                        classes.row,
                        page.key === selectedChildPage
                          ? classes.selectedRow
                          : !!selectedChildPage
                          ? classes.subSelectedRow
                          : classes.row
                      ),
                    },
                    listItemIcon: {
                      root: clsx(
                        classes.iconCell,
                        page.key !== selectedChildPage ? classes.opaqueIcon : ''
                      ),
                    },
                  }}
                  onSelectPage={onSelectPage}
                />
              </li>
            )
          })}
        </ul>
      </Collapse>
    </List>
  )
}

export default GroupedNavLinks
