import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPastReleasesAction } from '../../actions/release'
import { shouldDispatch } from '../../helpers'
import { ReleasesState } from '../../models/release'

const useFetchPastReleases = (applicationTypeId: number): ReleasesState => {
  const dispatch = useDispatch()
  const status = useSelector(
    ({ releases }: { releases: ReleasesState }) => releases.status
  )
  const lastRefreshTime = useSelector(
    ({ releases }: { releases: ReleasesState }) => releases.lastRefreshTime
  )

  useEffect(() => {
    if (shouldDispatch(status, lastRefreshTime)) {
      dispatch(getPastReleasesAction.request(applicationTypeId))
    }
  }, [status, lastRefreshTime, dispatch])

  const releases: ReleasesState = useSelector(
    ({ releases }: { releases: ReleasesState }) => releases
  )

  return releases
}

export { useFetchPastReleases }
