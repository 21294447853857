import { useState, useEffect } from 'react'
import { WidthBreakpoints } from '../models/widthBreakpoints'

function getWidthBreakpoint() {
  // Tim Groven - 1/26/2023 - https://versuscarina.visualstudio.com/Carina/_workitems/edit/23136
  // Using documentElement.clientWidth instead of window.innerWidth due to a bug in webkit in Safari
  // https://github.com/necolas/react-native-web/issues/1905
  const width = document.documentElement.clientWidth
  if (width >= 1300) {
    return WidthBreakpoints.ExtraExtraLarge
  } else if (width >= 1200) {
    return WidthBreakpoints.ExtraLarge
  } else if (width >= 1050) {
    return WidthBreakpoints.Large
  } else if (width >= 875) {
    return WidthBreakpoints.Medium
  } else if (width >= 768) {
    return WidthBreakpoints.Small
  } else {
    return WidthBreakpoints.ExtraSmall
  }
}

export default function useWidthBreakpoints() {
  const [widthBreakPoint, setWidthBreakpoint] = useState(getWidthBreakpoint())

  useEffect(() => {
    function handleResize() {
      setWidthBreakpoint(getWidthBreakpoint())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return widthBreakPoint
}
