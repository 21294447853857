import {
  IconType,
  SignalType,
  SignalTypePriority,
  StaffDuressAndAssistOptions,
} from '../../models'
import { ConfidenceConfig } from '../../models/inpixonMap'
import { colors } from '../../styles'

export const defaultConfidenceBubbleColor = '#6495ED'

export function getDefaultConfidenceBubble(): ConfidenceConfig {
  return {
    pulseColor: defaultConfidenceBubbleColor,
    confidenceMax: 9,
    confidencePercent: 1,
    confidenceAlpha: 0.000001,
    confidenceColor: defaultConfidenceBubbleColor,
    pulseVisible: false,
  }
}

export function updateConfidenceConfigForActiveDuress(
  config: ConfidenceConfig
): ConfidenceConfig {
  config.confidenceAlpha = 0.3
  config.confidenceColor = colors.duress
  config.pulseColor = colors.white
  config.pulseVisible = true
  return config
}

export function getConfidenceBubbleConfig(
  signalTypeId: number,
  iconType: IconType,
  staffDuressAndAssistOptions: Omit<
    StaffDuressAndAssistOptions,
    'hasResolvedDuressOrAssist' | 'staffDuressAndAssistFeatureFlagEnabled'
  >
): ConfidenceConfig {
  const { hasActiveDuress, hasActiveAssist, isSelected } =
    staffDuressAndAssistOptions
  let confidenceBubbleConfig = getDefaultConfidenceBubble()

  confidenceBubbleConfig.confidenceMax = getConfidenceBubbleMaxBySignalType([
    signalTypeId,
  ])
  if (isSelected) {
    confidenceBubbleConfig.confidenceAlpha = 0.3
    confidenceBubbleConfig.confidencePercent = 1
  }

  if (iconType === IconType.Staff) {
    confidenceBubbleConfig.confidenceColor = colors.staff
    if (hasActiveDuress) {
      confidenceBubbleConfig = updateConfidenceConfigForActiveDuress(
        confidenceBubbleConfig
      )
    } else if (hasActiveAssist) {
      if (isSelected) {
        confidenceBubbleConfig.confidenceColor = colors.assistConfidenceBubble
      }
    }
  }
  return confidenceBubbleConfig
}

export function getConfidenceBubbleMaxBySignalType(
  signalStatusIds: number[]
): number {
  const prioritizedSignalType: SignalType = Math.max(
    ...signalStatusIds.map((x) => SignalTypePriority[x as SignalType])
  )

  switch (prioritizedSignalType) {
    case SignalType.BLE:
      return 8
    case SignalType['IR/RF']:
    case SignalType.IR:
      return 6
    default:
      return 9
  }
}
