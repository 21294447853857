import { createAction } from 'typesafe-actions'
import { RouterPromptStatus } from '../models/routerPrompt'

export const setLogoutRouterPromptStatusAction = createAction(
  'SET_LOGOUT_ROUTER_PROMPT_STATUS'
)<RouterPromptStatus | undefined>()

export const setChangeTenantRouterPromptStatusAction = createAction(
  'SET_CHANGE_TENANT_ROUTER_PROMPT_STATUS'
)<RouterPromptStatus | undefined>()

export const setRouterPromptIsEnabledStatusAction = createAction(
  'SET_ROUTER_PROMPT_ISENABLED_STATUS'
)<boolean>()
