import { FetchingState } from '../interfaces/fetchingState'

export enum UserSettingsType {
  Dashboard = 1,
  AssetList = 2,
  AssetAdminList = 3,
  StaffList = 4,
  StaffAdminList = 5,
  UserList = 6,
  OpsUserList = 7,
  CustomerList = 8,
  BadgeAdminList = 9,
  UnitAdmin = 10,
  SubUnitAdmin = 11,
  AlertAdmin = 12,
}

export interface StringifiedUserSettings {
  dashboard?: StringUserSetting
  realtimeAsset?: StringUserSetting
  assetAdmin?: StringUserSetting
  staffAdmin?: StringUserSetting
  realtimeStaff?: StringUserSetting
  unitAdmin?: StringUserSetting
  subUnitAdmin?: StringUserSetting
  alertAdmin?: StringUserSetting
}

export interface StringUserSetting {
  settingsType: UserSettingsType | undefined
  settingValue: string
}

export function stringifySettings(
  userSettings: UserSettings
): StringifiedUserSettings {
  const settingsAsStrings: StringifiedUserSettings = {
    dashboard: userSettings.dashboard
      ? {
          settingsType: UserSettingsType.Dashboard,
          settingValue: JSON.stringify(userSettings.dashboard),
        }
      : undefined,
    realtimeAsset: userSettings.realtimeAsset
      ? {
          settingsType: UserSettingsType.AssetList,
          settingValue: JSON.stringify(userSettings.realtimeAsset),
        }
      : undefined,
    assetAdmin: userSettings.assetAdmin
      ? {
          settingsType: UserSettingsType.AssetAdminList,
          settingValue: JSON.stringify(userSettings.assetAdmin),
        }
      : undefined,
    realtimeStaff: userSettings.realtimeStaff
      ? {
          settingsType: UserSettingsType.StaffList,
          settingValue: JSON.stringify(userSettings.realtimeStaff),
        }
      : undefined,
    unitAdmin: userSettings.unitAdmin
      ? {
          settingsType: UserSettingsType.UnitAdmin,
          settingValue: JSON.stringify(userSettings.unitAdmin),
        }
      : undefined,
    subUnitAdmin: userSettings.subUnitAdmin
      ? {
          settingsType: UserSettingsType.SubUnitAdmin,
          settingValue: JSON.stringify(userSettings.subUnitAdmin),
        }
      : undefined,
    staffAdmin: userSettings.staffAdmin
      ? {
          settingsType: UserSettingsType.StaffAdminList,
          settingValue: JSON.stringify(userSettings.staffAdmin),
        }
      : undefined,
    alertAdmin: userSettings.alertAdmin
      ? {
          settingsType: UserSettingsType.AlertAdmin,
          settingValue: JSON.stringify(userSettings.alertAdmin),
        }
      : undefined,
  }
  return settingsAsStrings
}

export function parseSettings(
  userSettings: StringifiedUserSettings
): UserSettings {
  const parsedSettings: UserSettings = {
    dashboard: userSettings.dashboard?.settingValue
      ? JSON.parse(userSettings.dashboard.settingValue)
      : undefined,
    realtimeAsset: userSettings.realtimeAsset
      ? JSON.parse(userSettings.realtimeAsset.settingValue)
      : undefined,
    assetAdmin: userSettings.assetAdmin
      ? JSON.parse(userSettings.assetAdmin.settingValue)
      : undefined,
    realtimeStaff: userSettings.realtimeStaff
      ? JSON.parse(userSettings.realtimeStaff.settingValue)
      : undefined,
    unitAdmin: userSettings.unitAdmin
      ? JSON.parse(userSettings.unitAdmin.settingValue)
      : undefined,
    subUnitAdmin: userSettings.subUnitAdmin
      ? JSON.parse(userSettings.subUnitAdmin.settingValue)
      : undefined,
    staffAdmin: userSettings.staffAdmin
      ? JSON.parse(userSettings.staffAdmin.settingValue)
      : undefined,
    alertAdmin: userSettings.alertAdmin
      ? JSON.parse(userSettings.alertAdmin.settingValue)
      : undefined,
  }
  return parsedSettings
}

export interface UserSettings {
  dashboard?: QuickLinkSettings
  realtimeAsset?: TableColumnSettings
  assetAdmin?: TableColumnSettings
  realtimeStaff?: TableColumnSettings
  unitAdmin?: TableColumnSettings
  subUnitAdmin?: TableColumnSettings
  staffAdmin?: TableColumnSettings
  alertAdmin?: TableColumnSettings
}

export interface QuickLinkSetting {
  order: number
  show: boolean
}

export type QuickLinkSettings = {
  [id: number]: QuickLinkSetting
}

export type UserSettingsState = Readonly<FetchingState<UserSettings>>

export interface TableColumnSetting {
  columnName: string
  columnDisplayName: string
  order: number
  show: boolean
  canModify: boolean
}

export type TableColumnSettings = {
  [columnName: string]: TableColumnSetting
}
