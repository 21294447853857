import moment, { Moment } from 'moment'
import { TimeDisplayPreferenceIds } from '../models'

export type DateFormatter = (localDate: Moment) => string

/**
 * Applies format to a moment object
 * @param format
 */
export const customDateFormatter =
  (format: string): DateFormatter =>
  (localDate: Moment): string =>
    localDate.format(format)

/**
 * Formatter that display date as time from now
 * @param localDate
 */
export const fromNowFormatter = (localDate: Moment): string =>
  localDate.fromNow()

/**
 * Converts date to local time
 * @param date
 */
export const localMoment = (
  date?: Date | Moment | string | undefined
): Moment => moment(date).local()

/**
 * Converts date to UTC time
 * @param date
 */
export const utcMoment = (date?: Date | Moment | string | undefined): Moment =>
  moment(date).utc()

/**
 * Converts date to local time with optional format
 * @param date
 * @param format
 */
export const localMomentFormatted = (
  date: Date | Moment | string | undefined,
  format: string | DateFormatter = 'MM/DD/YYYY'
): string => {
  const formatter =
    typeof format === 'string'
      ? customDateFormatter(format as string)
      : (format as DateFormatter)
  return formatter(localMoment(date))
}

/**
 * Returns date with optional format
 * @param date
 * @param format
 */
export const momentUTCFormatted = (
  date: Date | Moment | string | undefined,
  format: string | DateFormatter = 'MM/DD/YYYY'
): string => {
  const formatter =
    typeof format === 'string'
      ? customDateFormatter(format as string)
      : (format as DateFormatter)
  return formatter(moment.utc(date))
}

/**
 * Converts elapsed time into a readable string
 * @param millisecondsElapsed
 */
export const fromNow = (millisecondsElapsed: number): string =>
  moment(new Date()).subtract(millisecondsElapsed).fromNow()

/**
 * Converts date/time to the appropriate format
 */
export const formatLocalDateTimeByUserPreference = (
  dateTime?: Date | string,
  preferredFormat?: TimeDisplayPreferenceIds
): string => {
  if (preferredFormat === TimeDisplayPreferenceIds.TwelveHour) {
    return dateTime ? localMomentFormatted(dateTime, 'MM/DD/YYYY hh:mm A') : ''
  }

  return dateTime ? localMomentFormatted(dateTime, 'MM/DD/YYYY HH:mm') : ''
}

export const formatDate = (date?: Date, format?: string): string => {
  return date ? localMomentFormatted(date, format ?? 'MM/DD/YYYY') : ''
}
