import { createSelector } from 'reselect'
import memoize from 'lodash/memoize'
import { Locations, LocationsState } from '../models'

const locationSelector = ({
  locations,
}: {
  locations: LocationsState
}): Locations => locations.data

export const getLocation = createSelector(
  locationSelector,
  (locations: Locations) =>
    memoize((locationId: string) => {
      const location = locations[locationId]

      // we don't have a location
      if (location == null) {
        return null
      }

      // if the location doesn't have a locationId it isn't valid
      if (location.id == null || location.id.length === 0) {
        return null
      }

      return locations[location.id]
    })
)

export const getBaseLocation = createSelector(
  locationSelector,
  (locations: Locations) =>
    memoize(() => {
      const baseLocation = Object.values(locations).find(
        (location) => !location.parentId
      )

      return baseLocation ?? null
    })
)
