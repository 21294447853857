import { useCallback, useState } from 'react'
import { TelemetryAsset, TelemetryStaff } from '../../models'
import {
  AgentTelemetriesGroupedByTrackingId,
  BadgeTelemetryMessageWithAgentEvent,
} from '../../models/telemetry'
import { deleteRecords, mergeRecords } from '../../utils'

export type NewAgentByType = {
  assetTelemetries: AgentTelemetriesGroupedByTrackingId<TelemetryAsset>
  staffTelemetries: AgentTelemetriesGroupedByTrackingId<TelemetryStaff>
}

/**
 * This hook is used to store cache for new agents (assets/staffs) entering the map
 * detected via the received telemetry update. Once it's determined that a telemetry is
 * received for a new agent that is not currently rendered on the map, a request is sent
 * out to the API to fetch the complete Asset/Staff data for the new agent. We need to cache
 * already-sent API requests to avoid throttling the API as we keep receiving telemetry update
 * for the new agents while the API response is still being processed
 */
export function useAwaitingFetchedNewAgentsEnteringMap(): {
  awaitingFetchedAgents: NewAgentByType
  addAwaitingFetchedAssets: (
    newAssetsToFetch: BadgeTelemetryMessageWithAgentEvent<TelemetryAsset>[]
  ) => void
  addAwaitingFetchedStaffs: (
    newStaffsToFetch: BadgeTelemetryMessageWithAgentEvent<TelemetryStaff>[]
  ) => void
  removeFetchedAssets: (fetchedAssetTrackingIds: string[]) => void
  removeFetchedStaffs: (fetchedStaffTrackingIds: string[]) => void
} {
  const [awaitingFetchedAgents, setAwaitingFetchedAgents] =
    useState<NewAgentByType>({
      assetTelemetries: {},
      staffTelemetries: {},
    })

  const addAwaitingFetchedAssets = useCallback(
    (
      newAssetsToFetch: BadgeTelemetryMessageWithAgentEvent<TelemetryAsset>[]
    ) => {
      setAwaitingFetchedAgents((prevState) => {
        const updatedAssetTelemetries = mergeRecords<
          AgentTelemetriesGroupedByTrackingId<TelemetryAsset>,
          BadgeTelemetryMessageWithAgentEvent<TelemetryAsset>,
          'trackingId'
        >(prevState.assetTelemetries, newAssetsToFetch, 'trackingId')
        return {
          ...prevState,
          assetTelemetries: updatedAssetTelemetries,
        }
      })
    },
    []
  )

  const addAwaitingFetchedStaffs = useCallback(
    (
      newStaffsToFetch: BadgeTelemetryMessageWithAgentEvent<TelemetryStaff>[]
    ) => {
      setAwaitingFetchedAgents((prevState) => {
        const updatedAssetTelemetries = mergeRecords<
          AgentTelemetriesGroupedByTrackingId<TelemetryStaff>,
          BadgeTelemetryMessageWithAgentEvent<TelemetryStaff>,
          'trackingId'
        >(prevState.staffTelemetries, newStaffsToFetch, 'trackingId')
        return {
          ...prevState,
          staffTelemetries: updatedAssetTelemetries,
        }
      })
    },
    []
  )

  const removeFetchedAssets = useCallback(
    (fetchedAssetTrackingIds: string[]) => {
      setAwaitingFetchedAgents((prevState) => {
        const updatedAssetTelemetries = deleteRecords<
          AgentTelemetriesGroupedByTrackingId<TelemetryAsset>,
          BadgeTelemetryMessageWithAgentEvent<TelemetryAsset>
        >(prevState.assetTelemetries, fetchedAssetTrackingIds)
        return {
          ...prevState,
          assetTelemetries: updatedAssetTelemetries,
        }
      })
    },
    []
  )

  const removeFetchedStaffs = useCallback(
    (fetchedStaffTrackingIds: string[]) => {
      setAwaitingFetchedAgents((prevState) => {
        const updatedStaffTelemetries = deleteRecords<
          AgentTelemetriesGroupedByTrackingId<TelemetryStaff>,
          BadgeTelemetryMessageWithAgentEvent<TelemetryStaff>
        >(prevState.staffTelemetries, fetchedStaffTrackingIds)
        return {
          ...prevState,
          staffTelemetries: updatedStaffTelemetries,
        }
      })
    },
    []
  )

  return {
    awaitingFetchedAgents,
    addAwaitingFetchedAssets,
    addAwaitingFetchedStaffs,
    removeFetchedAssets,
    removeFetchedStaffs,
  }
}
