import { getType } from 'typesafe-actions'
import { AssetSubTypeAction } from '../actions'
import { getAllAssetSubTypesAction } from '../actions/assetSubTypes'
import { AssetSubType, AssetSubTypes, AssetSubTypesState } from '../models'
import { FetchingStatus } from '../models/fetchingStatus'
import { mergeRecords, setFetchingStatus } from '../utils'

const initialState: AssetSubTypesState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

export default (
  state: AssetSubTypesState = initialState,
  action: AssetSubTypeAction
): AssetSubTypesState => {
  switch (action.type) {
    case getType(getAllAssetSubTypesAction.success):
      return {
        data: mergeRecords<AssetSubTypes, AssetSubType, 'assetSubTypeId'>(
          state.data,
          action.payload,
          'assetSubTypeId'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getAllAssetSubTypesAction.request):
      return setFetchingStatus<AssetSubTypes>(state, FetchingStatus.Request)
    case getType(getAllAssetSubTypesAction.failure):
      return setFetchingStatus<AssetSubTypes>(state, FetchingStatus.Failure)
    default:
      return state
  }
}
