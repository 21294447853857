import { BaseService } from '@midmarkrtls/common/middleware/baseService'
import {
  JibestreamDestination,
  JibestreamDestinations,
} from '../models/destination'
import { JibestreamUsers } from '../models/user'
import {
  JibestreamVenues,
  JibestreamVenueImportSuccessPayload,
} from '../models/venue'
import { VenueImport } from '../models/venueImport'

export class JibestreamApi extends BaseService {
  constructor() {
    super({ entityType: 'jibestream' })
  }

  /* Imports Jibestream data to Ops db */
  public async importVenue(
    data: VenueImport
  ): Promise<JibestreamVenueImportSuccessPayload> {
    const url = `${this.baseUrl()}/${this.byVenue(data.venueId)}/import${
      data.buildingGroupLocationId
        ? `?locationId=${data.buildingGroupLocationId}`
        : ''
    }`
    return await this.get<JibestreamVenueImportSuccessPayload>(url)
  }

  /* Destinations */
  public async getDestinationsByVenue(
    venueId: number
  ): Promise<JibestreamDestinations> {
    const url = `${this.baseUrl()}/${this.byVenue(venueId)}/destinations`
    return await this.get<JibestreamDestinations>(url)
  }

  public async updateDestination(
    destination: JibestreamDestination
  ): Promise<JibestreamDestination> {
    const url = `${this.baseUrl()}/${this.byVenue(
      destination.venueId
    )}/${this.byDestination(destination.id)}`
    return await this.put<JibestreamDestination>(url, destination)
  }

  /* Users */
  public async getAllUsers(): Promise<JibestreamUsers> {
    const url = `${this.baseUrl()}/users`
    return await this.get<JibestreamUsers>(url)
  }

  /* Venues */
  public async getAllVenues(): Promise<JibestreamVenues> {
    const url = `${this.baseUrl()}/venues`
    return await this.get<JibestreamVenues>(url)
  }

  /* Helpers */
  private byDestination(destinationId: number) {
    return `destinations/${destinationId}`
  }

  private byVenue(venueId: number) {
    return `venues/${venueId}`
  }

  private baseUrl() {
    return `jibestream`
  }
}
