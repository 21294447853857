import { PayloadActionCreator } from 'typesafe-actions'
import { useFilterState } from '.'
import { FilterStateManager } from '../../filters'
import { GlobalFiltersState } from '../../models/filters'

const useGlobalFilterState = <TState, TAction extends string>(
  selector: (globalFilters: GlobalFiltersState) => TState,
  actionCreator: PayloadActionCreator<TAction, TState>
): FilterStateManager<TState> => {
  return useFilterState(
    ({ globalFilters }: { globalFilters: GlobalFiltersState }) =>
      selector(globalFilters),
    actionCreator
  )
}

export default useGlobalFilterState
