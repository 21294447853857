import { getType } from 'typesafe-actions'
import { ImportUsersAction } from 'v2/actions'
import { Imports, Import, ImportsUsersState } from 'v2/models'
import { mergeRecords, mergeRecord } from '@midmarkrtls/common/utils'
import {
  getAllUsersImportsAction,
  postImportUsersAction,
} from 'v2/actions/importsUsers'
import { FetchingStatus } from '@midmarkrtls/common/models'

const initialState: ImportsUsersState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

const importUsersReducer = (
  state: ImportsUsersState = initialState,
  action: ImportUsersAction
): ImportsUsersState => {
  switch (action.type) {
    case getType(getAllUsersImportsAction.request):
    case getType(postImportUsersAction.request):
      return {
        ...state,
        status: FetchingStatus.Request,
      }

    case getType(postImportUsersAction.success):
      return {
        data: mergeRecord<Imports, Import, 'importGuid'>(
          state.data,
          action.payload,
          'importGuid'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getAllUsersImportsAction.success):
      return {
        data: mergeRecords<Imports, Import, 'importGuid'>(
          state.data,
          action.payload,
          'importGuid'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getAllUsersImportsAction.failure):
    case getType(postImportUsersAction.failure):
      return {
        ...state,
        status: FetchingStatus.Failure,
      }
    default:
      return state
  }
}

export default importUsersReducer
