import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { putAgentEventAction } from '../../actions/agentEventsLocation'
import { useFetchCurrentUser, useStaffVisibility } from '../../hooks'
import {
  AgentEventLocationUpdate,
  AuthState,
  FetchingStatus,
  StaffEvent,
} from '../../models'
import {
  selectAgentEventLocationFetchingStatus,
  selectStaffDuressState,
} from '../../selectors'
import StaffDuressBanner from './StaffDuressBanner'
import { StaffDuressModal } from './StaffDuressModal'
import {
  ResolveDuressState,
  StaffDuressResolveConfirmationModal,
} from './StaffDuressResolveConfirmationModal'
import { isAnySecurityRole } from '../../helpers'

const StaffDuressBannerAndModal = (): JSX.Element => {
  const {
    activeDuressEvents,
    showNextStaffDuressEvent,
    showNowStaffDuressEvent,
  } = useSelector(selectStaffDuressState)

  const dispatch = useDispatch()

  const { currentCustomerId } = useSelector(
    ({ auth }: { auth: AuthState }) => auth
  )
  const agentEventStatus = useSelector(selectAgentEventLocationFetchingStatus)
  const { user } = useSelector(({ auth }: { auth: AuthState }) => auth)
  const { data: userInfo, status: userInfoStatus } = useFetchCurrentUser(
    user.id
  )

  const isSecurityUser: boolean = useMemo(() => {
    return isAnySecurityRole(userInfo)
  }, [userInfo])
  const hasStaffVisibility = useStaffVisibility()

  const [duressResolution, setDuressResolution] = useState<ResolveDuressState>({
    isAccidentalDuress: null,
    openConfirmationModal: false,
    duressToResolve: null,
    isConfirmed: false,
    isResolved: false,
  })
  const [showDuressModal, setShowDuressModal] = useState<boolean>(true)

  const setIsAccidentalDuress = (isAccidental: boolean) => {
    setDuressResolution((prev) => ({
      ...prev,
      isAccidentalDuress: isAccidental,
    }))
  }

  const handleCancelResolveDuress = () => {
    setShowDuressModal(true)
    setDuressResolution({
      isAccidentalDuress: null,
      openConfirmationModal: false,
      duressToResolve: null,
      isConfirmed: false,
      isResolved: false,
    })
  }

  const handleResolveDuress = () => {
    if (
      duressResolution.duressToResolve &&
      duressResolution.isAccidentalDuress !== null
    ) {
      const agentEventLocationUpdate: AgentEventLocationUpdate = {
        Id: duressResolution.duressToResolve.id,
        ClosedBy: user.id,
        ClosedDate: new Date(),
        AgentEventTypeId: duressResolution.duressToResolve.agentEventTypeId,
        IsActive: false,
        Accidental: duressResolution.isAccidentalDuress,
      }
      dispatch(putAgentEventAction.request(agentEventLocationUpdate))
      setDuressResolution((prev) => ({
        ...prev,
        isConfirmed: true,
      }))
    } else {
      handleCancelResolveDuress()
    }
  }

  const openResolveDuressConfirmationModal = (duressToResolve: StaffEvent) => {
    setDuressResolution((prevState) => ({
      ...prevState,
      openConfirmationModal: true,
      duressToResolve: duressToResolve,
      isResolved: false,
    }))
    if (showDuressModal) {
      setShowDuressModal(false)
    }
  }

  const handleSuccessfulDuressResolution = () => {
    if (agentEventStatus === FetchingStatus.Success) {
      if (duressResolution.isConfirmed) {
        setDuressResolution({
          isAccidentalDuress: null,
          openConfirmationModal: false,
          duressToResolve: null,
          isConfirmed: false,
          isResolved: true,
        })
        if (showNowStaffDuressEvent) {
          setShowDuressModal(true)
        }
      }
    }
  }

  useEffect(() => {
    if (
      !showDuressModal &&
      showNowStaffDuressEvent &&
      !duressResolution.duressToResolve
    ) {
      setShowDuressModal(true)
    }
  }, [
    duressResolution.duressToResolve,
    showDuressModal,
    showNowStaffDuressEvent,
  ])

  return (
    <>
      {currentCustomerId && userInfoStatus !== FetchingStatus.Request && (
        <>
          {showNowStaffDuressEvent && (
            <StaffDuressModal
              staffDuressEventToShowNow={showNowStaffDuressEvent}
              staffDuressEventToShowNext={showNextStaffDuressEvent}
              showDuressModal={showDuressModal}
              onResolve={openResolveDuressConfirmationModal}
              isSecurityUser={isSecurityUser}
              canViewOnMap={hasStaffVisibility}
            />
          )}
          {activeDuressEvents.length > 0 && (
            <StaffDuressBanner
              staffDuressEvents={activeDuressEvents}
              onResolve={openResolveDuressConfirmationModal}
              duressResolved={duressResolution.isResolved}
              isSecurityUser={isSecurityUser}
              canViewOnMap={hasStaffVisibility}
            />
          )}
        </>
      )}
      {duressResolution.duressToResolve && (
        <StaffDuressResolveConfirmationModal
          open={duressResolution.openConfirmationModal}
          duressToResolve={duressResolution.duressToResolve}
          setIsAccidentalDuress={setIsAccidentalDuress}
          onCancelResolve={handleCancelResolveDuress}
          onResolve={handleResolveDuress}
          handleSuccessfulDuressResolution={handleSuccessfulDuressResolution}
        />
      )}
    </>
  )
}

export { StaffDuressBannerAndModal }
