import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import React, { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { putLocationsAction } from '../../actions/locations'
import { nullGuid } from '../../helpers'
import {
  useFetchSubUnits,
  useFetchUnits,
  useSaveSuccessFailureUtility,
} from '../../hooks'
import {
  FetchingStatus,
  Location,
  LocationsState,
  SubUnit,
  Unit,
} from '../../models'
import { UtilityStatus } from '../../models/utilityStatus'
import { UtilityStatusType } from '../../models/utilityStatusType'
import { colors, useFormStyle } from '../../styles'
import { Button, SaveButton } from '../Buttons'
import { CreateOrEditModalProps } from '../Common/HOC'
import { requiredMessage } from '../Forms'

export interface EditRoomForm {
  selectedRooms: Location[]
  multiSelectEnabled: boolean
}

export interface EditRoomFormFields {
  name: string
  unit: Unit | null
  subUnit: SubUnit | null
  utilityStatus: UtilityStatus | null
  isPointOfCareArea: boolean
  isPatientCareArea: boolean
  isPrivate: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    checkBoxSubText: {
      fontFamily: 'Ubuntu',
      fontSize: '14px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'left',
      color: '#333',
      paddingLeft: '31px',
      marginTop: '-6px',
      paddingBottom: '15px',
    },
    bottomText: {
      fontFamily: 'Ubuntu',
      fontSize: '14px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'left',
      color: '#333',
      paddingTop: '20px',
    },
  })
)

const utilityStatusOptions: UtilityStatus[] = [
  {
    id: UtilityStatusType.Soiled.toString(),
    name: UtilityStatusType[UtilityStatusType.Soiled],
  },
  {
    id: UtilityStatusType.Clean.toString(),
    name: UtilityStatusType[UtilityStatusType.Clean],
  },
]

const RoomEditForm: React.ComponentType<
  CreateOrEditModalProps<EditRoomForm>
> = (props: CreateOrEditModalProps<EditRoomForm>) => {
  const { data: roomsData, handleClose } = props

  const { selectedRooms, multiSelectEnabled } = roomsData

  const { status: locationStatus } = useSelector(
    ({ locations }: { locations: LocationsState }) => locations
  )

  const dispatch = useDispatch()
  const customClasses = useStyles()
  const classes = useFormStyle()

  const { data: units, status: unitsStatus } = useFetchUnits()
  const { data: subUnits, status: subUnitStatus } = useFetchSubUnits()

  const sortedUnits = useMemo(() => {
    return Object.values(units).sort((a, b) =>
      String(a.name).toLowerCase().localeCompare(String(b.name).toLowerCase())
    )
  }, [units])

  const sortedSubUnits = useMemo(() => {
    return Object.values(subUnits).sort((a, b) =>
      String(a.subUnitName)
        .toLowerCase()
        .localeCompare(String(b.subUnitName).toLowerCase())
    )
  }, [subUnits])

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<EditRoomFormFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: !multiSelectEnabled ? selectedRooms[0]?.name : '',
      unit:
        selectedRooms[0]?.unitId && selectedRooms[0]?.unitId !== nullGuid
          ? Object.values(units).find(
              (unit) => unit.id === selectedRooms[0]?.unitId
            )
          : null,
      subUnit:
        selectedRooms[0]?.subUnitId && selectedRooms[0]?.subUnitId !== nullGuid
          ? Object.values(subUnits).find(
              (subUnit) => subUnit.id === selectedRooms[0]?.subUnitId
            )
          : null,
      utilityStatus:
        selectedRooms[0]?.utilityStatus !== null &&
        selectedRooms[0]?.utilityStatus !== undefined
          ? {
              id: selectedRooms[0]?.utilityStatus,
              name:
                +selectedRooms[0]?.utilityStatus === UtilityStatusType.Soiled
                  ? UtilityStatusType[UtilityStatusType.Soiled]
                  : UtilityStatusType[UtilityStatusType.Clean],
            }
          : null,
      isPointOfCareArea: selectedRooms[0]?.isPointOfCareArea ?? false,
      isPatientCareArea: selectedRooms[0]?.isPatientCareArea ?? false,
      isPrivate: selectedRooms[0]?.isPrivate ?? false,
    } as EditRoomFormFields,
  })

  const onSubmit = (formData: EditRoomFormFields): void => {
    setIsSaving(true)
    if (showError) {
      setShowError(false)
    }
    const roomsToUpdate = selectedRooms.map((room) => {
      return {
        ...room,
        name: multiSelectEnabled ? room.name : formData.name,
        unitId: formData.unit ? formData.unit.id : undefined,
        subUnitId: formData.subUnit ? formData.subUnit.id : undefined,
        utilityStatus: formData.utilityStatus
          ? formData.utilityStatus.id
          : undefined,
        isPointOfCareArea: formData.isPointOfCareArea,
        isPatientCareArea: formData.isPatientCareArea,
        isPrivate: formData.isPrivate,
      }
    })
    dispatch(putLocationsAction.request(roomsToUpdate))
  }

  const { isSaving, setIsSaving, showError, setShowError } =
    useSaveSuccessFailureUtility(
      locationStatus === FetchingStatus.Success,
      locationStatus === FetchingStatus.Failure,
      `${
        multiSelectEnabled ? 'Rooms have' : 'Room has'
      } been updated successfully.`,
      `There was an error saving ${
        multiSelectEnabled ? 'Rooms' : 'Room'
      } information, please try again. If the problem persists, please contact support for assistance.`,
      handleClose
    )

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {!multiSelectEnabled && (
        <Controller
          name='name'
          control={control}
          rules={{
            required: true,
            maxLength: {
              value: 50,
              message: 'Room name must be between 1 and 50 characters',
            },
          }}
          render={({ field }) => {
            return (
              <TextField
                {...field}
                margin='normal'
                id='locationEditName'
                label='Room Name'
                variant='filled'
                fullWidth
                required
                error={!!errors.name}
                helperText={
                  errors.name?.message ? errors.name?.message : requiredMessage
                }
                className={classes.inputStyle}
              />
            )
          }}
        />
      )}

      <Controller
        name='unit'
        control={control}
        render={({ field: { ref, ...field } }) => {
          return (
            <Autocomplete
              {...field}
              style={{ width: '100%' }}
              getOptionLabel={(option: Unit): string => String(option?.name)}
              isOptionEqualToValue={(option, value) => {
                return String(option?.id) === String(value?.id)
              }}
              className={classes.inputStyle}
              options={sortedUnits}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              onChange={(event, newValue) => {
                field.onChange(newValue)
                return newValue
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              )}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    margin='normal'
                    variant='filled'
                    error={!!errors.unit}
                    label={'Unit'}
                    inputRef={ref}
                  />
                )
              }}
            />
          )
        }}
      />

      <Controller
        name='subUnit'
        control={control}
        render={({ field: { ref, ...field } }) => {
          return (
            <Autocomplete
              {...field}
              style={{ width: '100%' }}
              getOptionLabel={(option: SubUnit): string =>
                String(option?.subUnitName)
              }
              isOptionEqualToValue={(option, value) => {
                return String(option?.id) === String(value?.id)
              }}
              className={classes.inputStyle}
              options={sortedSubUnits}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.id}>
                  {option.subUnitName}
                </li>
              )}
              onChange={(event, newValue) => {
                field.onChange(newValue)
                return newValue
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    margin='normal'
                    variant='filled'
                    error={!!errors.subUnit}
                    label={'Sub-Unit'}
                    ref={ref}
                  />
                )
              }}
            />
          )
        }}
      />

      <Controller
        name='utilityStatus'
        control={control}
        render={({ field: { ref, ...field } }) => {
          return (
            <Autocomplete
              {...field}
              style={{ width: '100%' }}
              getOptionLabel={(option: UtilityStatus): string =>
                String(option?.name)
              }
              isOptionEqualToValue={(option, value) => {
                return String(option?.id) === String(value?.id)
              }}
              className={classes.inputStyle}
              options={utilityStatusOptions}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              )}
              onChange={(event, newValue) => {
                field.onChange(newValue)
                return newValue
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    margin='normal'
                    variant='filled'
                    error={!!errors.utilityStatus}
                    label={'Utility Status'}
                    ref={ref}
                  />
                )
              }}
            />
          )
        }}
      />

      <Controller
        control={control}
        name='isPointOfCareArea'
        render={({ field: { onChange, value, ...field } }) => (
          <FormControlLabel
            label='Point of Care Room'
            control={
              <Checkbox
                onChange={onChange}
                checked={value}
                color='primary'
                icon={<CheckBoxOutlineBlankIcon color='primary' />}
                {...field}
              />
            }
          />
        )}
      />
      <div className={customClasses.checkBoxSubText}>
        Point of Care rooms are locations that patients are treated and cared
        for.
      </div>

      <Controller
        control={control}
        name='isPatientCareArea'
        render={({ field: { onChange, value, ...field } }) => (
          <FormControlLabel
            label='Patient Care Room'
            control={
              <Checkbox
                onChange={onChange}
                checked={value}
                color='primary'
                icon={<CheckBoxOutlineBlankIcon color='primary' />}
                {...field}
              />
            }
          />
        )}
      />
      <div className={customClasses.checkBoxSubText}>
        Patient Care rooms are locations that patients will stop at during their
        care process.
      </div>

      <Controller
        control={control}
        name='isPrivate'
        render={({ field: { onChange, value, ...field } }) => (
          <FormControlLabel
            label='Private Room'
            control={
              <Checkbox
                onChange={onChange}
                checked={value}
                color='primary'
                icon={<CheckBoxOutlineBlankIcon color='primary' />}
                {...field}
              />
            }
          />
        )}
      />
      <div className={customClasses.checkBoxSubText}>
        Private locations do not show staff unless they have an active duress
        call.
      </div>

      <div className={customClasses.bottomText}>
        *Room Name changes may take 30 seconds to appear on the map.
      </div>

      {showError && (
        <Typography
          style={{
            color: colors.error,
            padding: '10px 0',
          }}
        >
          There was an error saving {multiSelectEnabled ? 'Rooms' : 'Room'}{' '}
          information, please try again. If the problem persists, contact
          support.
        </Typography>
      )}

      <Grid
        container
        justifyContent='space-between'
        style={{ padding: '10px 0' }}
      >
        <Grid item>
          <Button
            id='location-edit-cancel-button'
            onClick={handleClose}
            styleOverride={{ wrapper: { margin: 0 } }}
            bgColor={colors.transparent}
            textColor={colors.error}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <SaveButton
            id='location-edit-save-button'
            loading={isSaving}
            disabled={Object.values(errors).length > 0}
          >
            Save Room
          </SaveButton>
        </Grid>
      </Grid>
    </form>
  )
}

export default RoomEditForm
