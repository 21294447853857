import { getType } from 'typesafe-actions'
import { HardwareAction } from '../actions'
import { getAllHardwareAction } from '../actions/hardware'
import {
  HardwareRecords,
  HardwareState,
  Hardware,
  FetchingStatus,
} from '../models'
import { mergeRecords } from '../utils'

const initialState: HardwareState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

export default (
  state: HardwareState = initialState,
  action: HardwareAction
): HardwareState => {
  switch (action.type) {
    case getType(getAllHardwareAction.request):
      return {
        ...state,
        status: FetchingStatus.Request,
      }

    case getType(getAllHardwareAction.failure):
      return {
        ...state,
        status: FetchingStatus.Failure,
      }

    case getType(getAllHardwareAction.success):
      return {
        data: mergeRecords<HardwareRecords, Hardware, 'hardwareGuid'>(
          state.data,
          action.payload,
          'hardwareGuid'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }

    default:
      return state
  }
}
