import React, { useCallback } from 'react'
import {
  InfiniteScrollList,
  AgentEventListItem,
} from '../../components/InfiniteScrollList'
import { AgentEventView, FetchingStatus, LocationsState } from '../../models'
import { useNavigate } from 'react-router'
import { buildURL } from '../../helpers'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentLocationAction } from '../../actions/currentLocation'
import { useFetchAgentEventViews, useFetchUserPreferences } from '../../hooks'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { colors, tableColors } from '../../styles/MidmarkTheme'
import { Typography } from '@mui/material'
import PageInformationMessage from '../../components/Common/PageInformationMessage'

const useStyles = makeStyles(() =>
  createStyles({
    listContainer: {
      marginLeft: 24,
      marginRight: 24,
    },
  })
)

const NotificationsList = (): JSX.Element => {
  const classes = useStyles()
  const { data: locations } = useSelector(
    ({ locations }: { locations: LocationsState }) => locations
  )
  const { data: userPreferences } = useFetchUserPreferences()
  const history = useNavigate()
  const dispatch = useDispatch()
  const zoom = 10

  const {
    fetchNextPage,
    agentEventViews: items,
    fetchingStatus,
    hasMore,
  } = useFetchAgentEventViews()

  const handleClick = useCallback(
    (clickEvent: React.SyntheticEvent) => {
      clickEvent.preventDefault()
      clickEvent.stopPropagation()

      if (items) {
        const agentEventId = clickEvent.currentTarget.getAttribute(
          'data-agent-event-id'
        )
        const agentEvent = items.find((item) => item.eventId === agentEventId)

        if (locations && agentEvent?.latestFloorId && agentEvent.trackingId) {
          dispatch(
            setCurrentLocationAction(locations[agentEvent.latestFloorId])
          )

          history(
            buildURL('/realtime', [
              ['view', 'map'],
              ['badgeId', agentEvent.trackingId],
              ['zoom', zoom.toString()],
            ])
          )
        }
      }
    },
    [dispatch, history, items, locations]
  )

  const renderItem = useCallback(
    (item: AgentEventView) => {
      const isFirst = items && item.eventId === items[0].eventId
      const isLast = items && item.eventId === items[items.length - 1].eventId
      const borderRadius = isFirst ? 10 : 0
      const border = `1px solid ${tableColors.borderColor}`
      const bottomBorder = isLast
        ? `1px solid ${tableColors.borderColor}`
        : 'none'

      return (
        <AgentEventListItem
          agentEvent={item}
          userPreferencesTimeDisplayId={userPreferences.timeDisplayId}
          handleButtonClick={handleClick}
          style={{
            borderTopLeftRadius: borderRadius,
            borderTopRightRadius: borderRadius,
            borderTop: border,
            borderBottom: bottomBorder,
            borderRight: border,
            borderLeft: `8px solid ${colors.primary}`,
          }}
        />
      )
    },
    [handleClick, items, userPreferences.timeDisplayId]
  )

  const showNoItemsMessage =
    fetchingStatus === FetchingStatus.Success && items && items.length === 0

  const showNoMoreMessage =
    fetchingStatus === FetchingStatus.Success && items.length > 0 && !hasMore

  const isLoading = fetchingStatus === FetchingStatus.Request

  return (
    <>
      <div className={classes.listContainer}>
        <InfiniteScrollList<AgentEventView>
          items={items}
          renderItem={renderItem}
          fetchMoreItems={fetchNextPage}
          isLoading={isLoading}
          hasMore={hasMore}
        />
      </div>
      {showNoMoreMessage && (
        <Typography align='center' variant='h6' style={{ margin: '48px 0' }}>
          No more results
        </Typography>
      )}
      {showNoItemsMessage && (
        <PageInformationMessage
          primaryMessage='No results found'
          secondaryMessage='Reduce your filters to expland your results.'
        />
      )}
    </>
  )
}

export { NotificationsList }
