import { Typography } from '@mui/material'
import React from 'react'

const LocationSelectionError = (): JSX.Element => {
  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography variant='h5'>
        Please select a Floor from the Location Selector Above
      </Typography>
    </div>
  )
}

export default LocationSelectionError
