import { useDispatch, useSelector } from 'react-redux'
import { Filters, FiltersState } from '../models/filters'
import { getFiltersAction } from '../actions/filters'
import { shouldDispatch } from '../helpers/shouldDispatch'
import { useAuth } from '../hooks'

const useFetchFilters = () => {
  const { user } = useAuth()
  const dispatch = useDispatch()
  const status = useSelector(
    ({ filters }: { filters: FiltersState }) => filters.status
  )
  const lastRefreshTime = useSelector(
    ({ filters }: { filters: FiltersState }) => filters.lastRefreshTime
  )

  if (shouldDispatch(status, lastRefreshTime)) {
    dispatch(getFiltersAction.request(user.id))
  }

  const filters: Filters = useSelector(
    ({ filters }: { filters: FiltersState }) => filters?.data
  )

  return {
    filters,
    status,
  }
}

export { useFetchFilters }
