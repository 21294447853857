import { DialogActions, Divider, IconButton, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Close, ExpandLess, ExpandMore } from '@mui/icons-material'
import { Alert, AlertTitle } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { putAgentEventAction } from '../../../actions/agentEventsLocation'
import { setCurrentLocationAction } from '../../../actions/currentLocation'
import {
  setSelectedGroupIdAction,
  setSelectedTrackingIdAction,
} from '../../../actions/mapGroups'
import { dismissStaffEvent } from '../../../actions/staffEvents'
import { buildURL, isAnySecurityRole } from '../../../helpers'
import {
  formatLocalDateTimeByUserPreference,
  fromNow,
} from '../../../helpers/date'
import {
  useCurrentView,
  useEventListener,
  useFetchCurrentUser,
  useFetchUserPreferences,
  useInterval,
  useStaffVisibility,
} from '../../../hooks'
import {
  AgentEventLocationUpdate,
  AgentType,
  AuthState,
  LocationsState,
  RealTimePageViews,
  StaffEvent,
  UserRoles,
} from '../../../models'
import { colors, tableColors } from '../../../styles'
import DialogActionButtons from '../../Dialogs/DialogActionButtons'
import { DialogActionButtonType } from '../../Dialogs/DialogActionButtonType'
import { FlexBox } from '../../Layout'
import { fixedDuressTimerInterval } from '../../StaffDuress/constants'

interface Props {
  staffAssistEvent: StaffEvent
  totalStaffAssistEvents?: number
  showExpansionPanelFooter: boolean
  isShowMoreExpanded: boolean
  setIsShowMoreExpanded: (isOpen: boolean) => void
}

enum ModalActionButton {
  DISMISS = 'DISMISS',
  VIEW_ON_MAP = 'VIEW ON MAP',
  RESOLVE = 'RESOLVE ASSIST',
}

const StaffAssistModal = (props: Props): JSX.Element | null => {
  const {
    staffAssistEvent,
    totalStaffAssistEvents,
    showExpansionPanelFooter,
    isShowMoreExpanded,
    setIsShowMoreExpanded,
  } = props

  const { data: userPreferences } = useFetchUserPreferences()
  const [isOpen, setIsOpen] = useState<boolean>(true)
  const [friendlyAgoText, setFriendlyAgoText] = useState<string>(
    staffAssistEvent?.reportedTime
      ? fromNow(
          new Date().getTime() -
            new Date(staffAssistEvent?.reportedTime).getTime()
        )
      : ''
  )

  const { user } = useSelector(({ auth }: { auth: AuthState }) => auth)

  const createStaffAssistMessage = (staffAssistEvent: StaffEvent) => {
    const title = 'Staff Assist'
    const modalMessage = `${
      staffAssistEvent.reportedBy
    } has asked for assistance on ${
      staffAssistEvent.badgeEventTelemetry?.floorName
    } in ${staffAssistEvent.badgeEventTelemetry?.roomName} at ${
      staffAssistEvent.reportedTime
        ? formatLocalDateTimeByUserPreference(
            staffAssistEvent.reportedTime,
            userPreferences.timeDisplayId
          )
        : 'Unknown Time'
    }`

    return {
      title,
      modalMessage,
    }
  }

  const staffAssistNotification = createStaffAssistMessage(staffAssistEvent)

  useEffect(() => {
    if (staffAssistEvent) {
      setIsOpen(true)
    }
  }, [staffAssistEvent])

  const dispatch = useDispatch()
  const history = useNavigate()
  const locations = useSelector(
    ({ locations }: { locations: LocationsState }) => locations.data
  )

  const url = useLocation()
  const { currentView } = useCurrentView<RealTimePageViews>()

  const handleClose = () => {
    setIsOpen(false)
    updateStaffAssistEvent(ModalActionButton.DISMISS)
  }

  const handleShowMore = () => {
    setIsShowMoreExpanded(!isShowMoreExpanded)
  }

  const onFocus = useCallback(() => {
    if (staffAssistEvent?.reportedTime) {
      const actualInterval =
        new Date().getTime() - new Date(staffAssistEvent.reportedTime).getTime()
      resetInterval(actualInterval)
    }
  }, [staffAssistEvent?.reportedTime])

  useEventListener('focus', onFocus)

  const { data: userInfo } = useFetchCurrentUser(user.id)
  const hasStaffVisibility = useStaffVisibility()
  const canViewOnMap = useMemo(() => {
    return isAnySecurityRole(userInfo) || hasStaffVisibility
  }, [userInfo, hasStaffVisibility])

  const updateStaffAssistEvent = useCallback(
    (actionButton: ModalActionButton) => {
      switch (actionButton) {
        case ModalActionButton.RESOLVE:
          const agentEventLocationUpdate: AgentEventLocationUpdate = {
            Id: staffAssistEvent.id,
            ClosedBy: user.id,
            ClosedDate: new Date(),
            AgentEventTypeId: staffAssistEvent.agentEventTypeId,
            IsActive: false,
          }
          dispatch(putAgentEventAction.request(agentEventLocationUpdate))
          break
        case ModalActionButton.DISMISS:
          dispatch(dismissStaffEvent(staffAssistEvent))
          break
        default:
          break
      }
      onFocus()
    },
    [dispatch, onFocus, staffAssistEvent, user.id]
  )

  const actionButtons: DialogActionButtonType[] = useMemo(() => {
    const handleResolve = () => {
      setIsOpen(false)
      updateStaffAssistEvent(ModalActionButton.RESOLVE)
    }

    const handleViewOnMap = (event: any) => {
      updateStaffAssistEvent(ModalActionButton.VIEW_ON_MAP)
      if (staffAssistEvent.badgeEventTelemetry?.floorId) {
        dispatch(
          setCurrentLocationAction(
            locations[staffAssistEvent.badgeEventTelemetry?.floorId]
          )
        )

        const newUrl = buildURL('/realtime', [
          ['view', 'map'],
          ['agentType', AgentType[AgentType.Staff]],
          ['badgeId', staffAssistEvent.badgeId],
          ['zoom', '10'],
        ])

        if (url.pathname !== '/realtime' || currentView === 'list') {
          history(newUrl)
        } else if (newUrl !== url.pathname + url.search) {
          history(newUrl)
          dispatch(setSelectedGroupIdAction(undefined))
          dispatch(setSelectedTrackingIdAction(staffAssistEvent.badgeId))
        }
      }
    }
    const resolveButton: DialogActionButtonType = {
      label: ModalActionButton.RESOLVE,
      action: (): void => handleResolve(),
      variant: 'contained',
      color: '#ffffff',
      backgroundColor: '#165788',
    }
    if (canViewOnMap) {
      return [
        {
          label: ModalActionButton.VIEW_ON_MAP,
          action: handleViewOnMap,
          variant: 'outlined',
        },
        resolveButton,
      ]
    } else {
      return [resolveButton]
    }
  }, [
    canViewOnMap,
    currentView,
    dispatch,
    history,
    locations,
    staffAssistEvent.badgeEventTelemetry?.floorId,
    staffAssistEvent.badgeId,
    updateStaffAssistEvent,
    url.pathname,
    url.search,
  ])

  const handleIntervalChange = useCallback(() => {
    if (staffAssistEvent?.reportedTime) {
      const actualInterval =
        new Date().getTime() - new Date(staffAssistEvent.reportedTime).getTime()
      setFriendlyAgoText(`${fromNow(actualInterval)}`)
    }
  }, [staffAssistEvent?.reportedTime])

  const { resetInterval } = useInterval(
    handleIntervalChange,
    fixedDuressTimerInterval
  )

  const useAlertStyles = makeStyles((theme) => ({
    root: () => ({
      width: 374,
      backgroundColor: theme.palette.background.paper,
      borderRadius: '4px',
      borderLeft: `8px solid ${colors.warning}`,
      borderTop: `1px solid ${tableColors.borderColor}`,
      borderRight: `1px solid ${tableColors.borderColor}`,
      borderBottom: `1px solid ${tableColors.borderColor}`,
      padding: '0px 16px',
    }),
  }))

  const alertClasses = useAlertStyles()

  const customHeader = (
    <FlexBox
      flex='basis'
      layout='row'
      layoutAlign={{ item: 'center', content: 'center', main: 'flex-start' }}
    >
      <FlexBox
        flex='grow'
        layout='row'
        layoutAlign={{
          item: 'center',
          content: 'center',
          main: 'flex-start',
        }}
      >
        <Typography
          variant='body1'
          style={{
            fontWeight: 'bold',
            color: colors.darkSurface,
            paddingTop: 8,
          }}
        >
          {staffAssistNotification.title}
        </Typography>
      </FlexBox>
      <FlexBox
        flex='grow'
        layout='row-reverse'
        layoutAlign={{
          item: 'center',
          content: 'center',
          main: 'flex-start',
        }}
      >
        <IconButton
          aria-label='Close'
          onClick={handleClose}
          style={{ padding: 0 }}
          disableRipple
          disableFocusRipple
          disableTouchRipple
          size='large'
        >
          <Close />
        </IconButton>
        <Typography
          variant='body2'
          style={{
            fontWeight: 'normal',
            color: '#979797',
            marginRight: '8px',
          }}
        >
          {staffAssistEvent.reportedTime ? friendlyAgoText : 'Unknown Time'}
        </Typography>
      </FlexBox>
    </FlexBox>
  )

  const customFooter = (
    <div onClick={handleShowMore}>
      <FlexBox
        flex='basis'
        layout='row'
        layoutAlign={{ item: 'center', content: 'center', main: 'flex-start' }}
        style={{
          paddingTop: 10,
          paddingBottom: 2,
          cursor: 'pointer',
        }}
      >
        <FlexBox
          flex='grow'
          layout='row'
          layoutAlign={{
            item: 'center',
            content: 'center',
            main: 'flex-start',
          }}
        >
          <Typography
            variant='body2'
            style={{
              fontWeight: 'bold',
              color: colors.staffTimeOut,
            }}
          >
            {isShowMoreExpanded ? 'SHOW LESS' : 'SHOW MORE'}
          </Typography>
        </FlexBox>
        <FlexBox
          flex='grow'
          layout='row-reverse'
          layoutAlign={{
            item: 'center',
            content: 'center',
            main: 'flex-start',
          }}
        >
          <IconButton
            aria-label='Close'
            style={{
              padding: 0,
              color: colors.darkBlue,
            }}
            disableRipple
            disableFocusRipple
            disableTouchRipple
            size='large'
          >
            {isShowMoreExpanded ? (
              <ExpandLess></ExpandLess>
            ) : (
              <ExpandMore></ExpandMore>
            )}
          </IconButton>
          <Typography
            variant='body2'
            style={{
              fontWeight: 'bold',
              color: colors.staffTimeOut,
              marginRight: '8px',
            }}
          >
            1 OF {totalStaffAssistEvents}
          </Typography>
        </FlexBox>
      </FlexBox>
    </div>
  )

  return isOpen ? (
    <Alert
      severity='info'
      icon={false}
      variant='outlined'
      classes={{
        root: alertClasses.root,
      }}
    >
      <>
        <AlertTitle>{customHeader}</AlertTitle>
        <Typography
          variant='body1'
          style={{
            fontSize: '16px',
            color: '#000',
          }}
        >
          {staffAssistNotification.modalMessage}
        </Typography>
        <FlexBox
          flex='grow'
          layout='row-reverse'
          layoutAlign={{
            item: 'center',
            content: 'center',
            main: 'flex-start',
          }}
        >
          {actionButtons && (
            <DialogActions
              style={{
                padding: '16px 0px',
              }}
            >
              <DialogActionButtons actionButtons={actionButtons} />
            </DialogActions>
          )}
        </FlexBox>
        {showExpansionPanelFooter && (
          <>
            <Divider
              style={{
                margin: '0 -16px',
              }}
            />
            {customFooter}
          </>
        )}
      </>
    </Alert>
  ) : null
}

export { StaffAssistModal }
