import { FetchingState } from '../interfaces/fetchingState'

export interface ChannelType {
  id: number
  name: string
}

export interface ChannelTypes {
  [channelTypeIdd: string]: ChannelType
}

export type ChannelTypesState = Readonly<FetchingState<ChannelTypes>>

export enum ChannelTypeEnum {
  'Duress Banner',
  'Duress Modal',
  'Assist Modal',
  Sound,
  'CareFlow Desktop App',
}
