import { AssetKitInstance, IconType } from '../../models'
import { InpixonAssetAgentPositionUpdate } from '../../models/inpixonMap'
import { EnteringGeofenceGroupUpdates } from '../../models/realtimeMap'
import {
  calculateAssetPositionCoords,
  hideAgentInpixonAsset,
  updateInpixonAssetPositionWithCallback,
  updateInpixonGroupIconByType,
} from '../inpixon'

/**
 * Move agent to the new geofence followed by hiding all agents at the entering geofence and
 * replace them with the group icon
 */
export async function moveAgentToAGroup(
  leavingAgentToUpdatePosition: InpixonAssetAgentPositionUpdate,
  enteringGeofenceGroupUpdates: EnteringGeofenceGroupUpdates,
  assetKit: AssetKitInstance,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  geofenceInstances: any[],
  useGeofenceCentroid: boolean,
  addGeofenceHighlight: (geofenceId: number) => void,
  removeGeofenceHighlight: (geofenceId: number) => void,
  leavingGroupHasActiveDuress: boolean | undefined
): Promise<void> {
  const { enteringAgentsToHide, groupIconToUpdate } =
    enteringGeofenceGroupUpdates
  const onAgentPositionChanged = () => {
    if (enteringAgentsToHide.length > 0) {
      enteringAgentsToHide.forEach((agent) => {
        hideAgentInpixonAsset(assetKit, jibestreamController, agent)
      })
    }
    updateInpixonGroupIconByType(
      assetKit,
      jibestreamController,
      {
        iconType: IconType.Group,
        geofenceId: groupIconToUpdate.geofenceId,
      },
      groupIconToUpdate.url
    )
    if (leavingAgentToUpdatePosition.hasActiveDuress) {
      addGeofenceHighlight(leavingAgentToUpdatePosition.geoFenceId)
    }
  }
  const positionCoords = calculateAssetPositionCoords(
    jibestreamController,
    geofenceInstances,
    leavingAgentToUpdatePosition.lat,
    leavingAgentToUpdatePosition.lon,
    leavingAgentToUpdatePosition.mapId,
    useGeofenceCentroid
  )
  if (
    leavingAgentToUpdatePosition.hasActiveDuress &&
    !leavingGroupHasActiveDuress
  ) {
    removeGeofenceHighlight(leavingAgentToUpdatePosition.leavingGeofenceId)
  }
  await updateInpixonAssetPositionWithCallback(
    {
      trackingId: leavingAgentToUpdatePosition.trackingId,
      position: positionCoords,
      iconType: leavingAgentToUpdatePosition.iconType,
    },
    assetKit,
    jibestreamController,
    onAgentPositionChanged
  )
}
