import makeStyles from '@mui/styles/makeStyles'
import React, { useEffect, useState } from 'react'
import { UpcomingReleaseNotification } from '../../hooks'
import { StaffEvent } from '../../models'
import { alertModalGap } from './constants'
import { StaffAssistAlert } from './StaffAssist/StaffAssistAlerts'
import { SystemMaintenanceAlert } from './SystemMaintenanceModal'

interface Props {
  staffAssistEvents: StaffEvent[]
  showAllStaffAssistNotifications: boolean
  upcomingRelease: UpcomingReleaseNotification | undefined
  dismissUpcomingReleaseNotification: (
    notification: UpcomingReleaseNotification
  ) => void
  ignoreUpcomingReleaseNotification: (
    notification: UpcomingReleaseNotification
  ) => void
  setMostRecentStaffAssistModalHeight: (height: number) => void
  setSystemMaintenanceModalHeight: (height: number) => void
  setShowAllStaffAssistNotifications: (showAll: boolean) => void
}

const useStyles = makeStyles(() => ({
  root: {
    height: alertModalGap,
  },
}))

const Alerts = (props: Props): JSX.Element | null => {
  const {
    staffAssistEvents,
    upcomingRelease,
    setSystemMaintenanceModalHeight,
    dismissUpcomingReleaseNotification,
    ignoreUpcomingReleaseNotification,
  } = props

  const [
    showStaffAssistExpansionPanelFooter,
    setShowStaffAssistExpansionPanelFooter,
  ] = useState(false)

  const [showStaffAssistAlertsOnTop, setShowStaffAssistAlertsOnTop] =
    useState<boolean>(true)

  const systemMaintenanceModalRef =
    React.useRef<HTMLDivElement>() as React.RefObject<HTMLDivElement>

  useEffect(() => {
    if (staffAssistEvents.length > 1) {
      setShowStaffAssistExpansionPanelFooter((isShown) => {
        return !isShown ? true : isShown
      })
    } else if (staffAssistEvents.length === 1) {
      setShowStaffAssistExpansionPanelFooter((isShown) => {
        return isShown ? false : isShown
      })
    }
  }, [staffAssistEvents, setShowStaffAssistExpansionPanelFooter])

  useEffect(() => {
    if (upcomingRelease?.receivedOn) {
      setShowStaffAssistAlertsOnTop(false)
    }
  }, [upcomingRelease?.receivedOn])

  useEffect(() => {
    if (staffAssistEvents.length > 0) {
      const mostRecentStaffAssistEvent = staffAssistEvents[0]
      if (
        mostRecentStaffAssistEvent.reportedTime &&
        upcomingRelease?.receivedOn
      ) {
        setShowStaffAssistAlertsOnTop(
          mostRecentStaffAssistEvent.reportedTime.getTime() -
            upcomingRelease.receivedOn.getTime() >=
            0
        )
      } else {
        setShowStaffAssistAlertsOnTop(true)
      }
    } else {
      if (upcomingRelease?.receivedOn) {
        setShowStaffAssistAlertsOnTop(false)
      }
    }
  }, [staffAssistEvents, upcomingRelease?.receivedOn])

  useEffect(() => {
    if (systemMaintenanceModalRef.current?.clientHeight) {
      setSystemMaintenanceModalHeight(
        systemMaintenanceModalRef.current.clientHeight
      )
    }
    // eslint-disable-next-line
  }, [systemMaintenanceModalRef.current?.clientHeight])

  const classes = useStyles()

  return showStaffAssistAlertsOnTop ? (
    <>
      {staffAssistEvents.map((staffAssistEvent, index) => (
        <StaffAssistAlert
          {...props}
          key={staffAssistEvent.id}
          staffAssistEvent={staffAssistEvent}
          totalStaffAssistEvents={staffAssistEvents.length}
          showStaffAssistExpansionPanelFooter={
            showStaffAssistExpansionPanelFooter
          }
          isMostRecentStaffAssistAlert={index === 0}
        />
      ))}
      {upcomingRelease?.isVisible && (
        <div ref={systemMaintenanceModalRef}>
          <SystemMaintenanceAlert
            notification={upcomingRelease}
            dismiss={dismissUpcomingReleaseNotification}
            ignore={ignoreUpcomingReleaseNotification}
          />
        </div>
      )}
    </>
  ) : (
    <>
      {upcomingRelease?.isVisible && (
        <div ref={systemMaintenanceModalRef}>
          <SystemMaintenanceAlert
            notification={upcomingRelease}
            dismiss={dismissUpcomingReleaseNotification}
            ignore={ignoreUpcomingReleaseNotification}
          />
        </div>
      )}
      <div className={classes.root}></div>
      {staffAssistEvents.map((staffAssistEvent, index) => (
        <StaffAssistAlert
          {...props}
          key={staffAssistEvent.id}
          staffAssistEvent={staffAssistEvent}
          totalStaffAssistEvents={staffAssistEvents.length}
          showStaffAssistExpansionPanelFooter={
            showStaffAssistExpansionPanelFooter
          }
          isMostRecentStaffAssistAlert={index === 0}
        />
      ))}
    </>
  )
}

export default Alerts
