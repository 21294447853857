import { makeStyles, createStyles } from '@mui/styles'
import { rightDrawerWidth } from './constants'
import { Divider, Paper } from '@mui/material'
import React, { ReactNode } from 'react'

interface Props {
  header?: ReactNode
  content: ReactNode
}

const RightDrawerLayout = (props: Props): JSX.Element => {
  const { header, content } = props
  const useStyles = makeStyles(() =>
    createStyles({
      container: {
        right: 0,
        minWidth: rightDrawerWidth,
        maxWidth: rightDrawerWidth,
        height: '100%',
        maxHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        alignItems: 'stretch',
        flex: '0 1 auto',
        zIndex: 999,
        boxShadow:
          '0 0 3px 1px rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.16)',
      },
      headerContainer: {
        right: 0,
        backgroundColor: '#fff',
        width: '100%',
        zIndex: 999,
        display: !!header ? 'initial' : 'none',
        flex: '0 1 auto',
        padding: 16,
      },
      header: {
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        zIndex: 999,
      },
      contentScrollContainer: {
        width: '100%',
        overflowY: 'hidden',
        zIndex: 998,
        flex: '1 1 auto',
      },
      contentContainer: {
        height: '100%',
        right: 0,
        width: '100%',
        overflowY: 'auto',
        backgroundColor: '#fff',
        padding: 16,
      },
      content: {
        width: '100%',
        zIndex: 999,
      },
    })
  )

  const classes = useStyles()

  return (
    <Paper variant='outlined' square={true} className={classes.container}>
      {header && (
        <>
          <div className={classes.headerContainer}>
            <div className={classes.header}>{header}</div>
          </div>
          <Divider style={{ zIndex: 999 }} />
        </>
      )}
      <div className={classes.contentScrollContainer}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{content}</div>
        </div>
      </div>
    </Paper>
  )
}

export default RightDrawerLayout
