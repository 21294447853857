import { createAction, createAsyncAction } from 'typesafe-actions'
import { OperationsUser } from '../models/operationsUsers'

export const acknowledgeHttpError = createAction(
  'ACK_OPERATIONS_USERS_HTTP_ERROR'
)<boolean>()

export const getAllOperationsUsersAction = createAsyncAction(
  'GET_ALL_OPERATIONS_USERS_REQUEST',
  'GET_ALL_OPERATIONS_USERS_SUCCESS',
  'GET_ALL_OPERATIONS_USERS_FAILURE'
)<void, OperationsUser[], Error>()

export const getOperationsUserByIdAction = createAsyncAction(
  'GET_OPERATIONS_USER_BY_ID_REQUEST',
  'GET_OPERATIONS_USER_BY_ID_SUCCESS',
  'GET_OPERATIONS_USER_BY_ID_FAILURE'
)<string, OperationsUser, Error>()

export const getOperationsUsersByEmailAction = createAsyncAction(
  'GET_OPERATIONS_USERS_BY_EMAIL_REQUEST',
  'GET_OPERATIONS_USERS_BY_EMAIL_SUCCESS',
  'GET_OPERATIONS_USERS_BY_EMAIL_FAILURE'
)<string, OperationsUser[], Error>()

export const postOperationsUserAction = createAsyncAction(
  'POST_OPERATIONS_USER_REQUEST',
  'POST_OPERATIONS_USER_SUCCESS',
  'POST_OPERATIONS_USER_FAILURE'
)<OperationsUser, OperationsUser, Error>()

export const putOperationsUserAction = createAsyncAction(
  'PUT_OPERATIONS_USER_REQUEST',
  'PUT_OPERATIONS_USER_SUCCESS',
  'PUT_OPERATIONS_USER_FAILURE'
)<OperationsUser, OperationsUser, Error>()

export const deleteOperationsUserAction = createAsyncAction(
  'DELETE_OPERATIONS_USER_REQUEST',
  'DELETE_OPERATIONS_USER_SUCCESS',
  'DELETE_OPERATIONS_USER_FAILURE'
)<string, string, Error>()

export const getOperationsUserByNameAction = createAsyncAction(
  'GET_OPERATIONS_USER_BY_NAME_REQUEST',
  'GET_OPERATIONS_USER_BY_NAME_SUCCESS',
  'GET_OPERATIONS_USER_BY_NAME_FAILURE'
)<string, OperationsUser, Error>()
