import { AgentType, IconType } from '../../models'
import {
  InpixonAssetAgentCreateOptions,
  InpixonAssetAgentPositionUpdate,
  InpixonAssetGroupDeleteOptions,
} from '../../models/inpixonMap'
import { BadgeTelemetryMessageWithAgentEvent } from '../../models/telemetry'
import { buildInpixonAssetAgentCreateOptions } from '../inpixon'

/**
 * Calculate config values to remove an existing group icon in the leaving geofence, config
 * values to rerender remaining agents in the leaving geofence as either Asset or Staff icon, and
 * config value to rerender the leaving agent as either an Asset or Staff icon followed by showing
 * agent movement on the map
 * @param prevAgentTelemetry
 * @param newAgentTelemetry
 * @param agentTelemetriesInLeavingGeofence
 * @param iconScale
 * @param selectedTrackingId
 * @returns config values to remove an existing group icon in the leaving geofence, config
 * values to rerender remaining agents in the leaving geofence as either Asset or Staff icon, and
 * config value to rerender the leaving agent as either an Asset or Staff icon followed by showing
 * agent movement on the map
 */
export function calculateDegroupingAgents(
  prevAgentTelemetry: BadgeTelemetryMessageWithAgentEvent,
  newAgentTelemetry: BadgeTelemetryMessageWithAgentEvent,
  agentTelemetriesInLeavingGeofence: BadgeTelemetryMessageWithAgentEvent[],
  iconScale: number,
  selectedTrackingId: string | undefined
): {
  groupToRemove: InpixonAssetGroupDeleteOptions
  agentsToDegroup: InpixonAssetAgentCreateOptions[]
  degroupedAgentToUpdatePosition: InpixonAssetAgentPositionUpdate
} {
  const groupToRemove: InpixonAssetGroupDeleteOptions = {
    geofenceId: prevAgentTelemetry.geoFenceId,
  }

  const agentsToDegroup: InpixonAssetAgentCreateOptions[] = []
  agentTelemetriesInLeavingGeofence.forEach((telemetry) => {
    const inpixonAssetAgentCreate = buildInpixonAssetAgentCreateOptions(
      telemetry,
      iconScale,
      selectedTrackingId
    )
    if (inpixonAssetAgentCreate) {
      agentsToDegroup.push(inpixonAssetAgentCreate)
    }
  })
  const degroupedAgentToUpdatePosition: InpixonAssetAgentPositionUpdate = {
    lat: newAgentTelemetry.lat,
    lon: newAgentTelemetry.lon,
    trackingId: newAgentTelemetry.trackingId,
    geoFenceId: newAgentTelemetry.geoFenceId,
    leavingGeofenceId: prevAgentTelemetry.geoFenceId,
    hasActiveDuress: newAgentTelemetry.hasActiveDuress,
    iconType:
      newAgentTelemetry.agent.agentType === AgentType.Asset
        ? IconType.Asset
        : IconType.Staff,
    mapId: newAgentTelemetry.mapId,
  }
  return {
    groupToRemove,
    agentsToDegroup,
    degroupedAgentToUpdatePosition,
  }
}
