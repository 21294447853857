import { Qualifier } from '../models/qualifier'
import { BaseService } from './baseService'

export class QualifiersApi extends BaseService {
  constructor() {
    super({ entityType: 'qualifiers' })
  }
  route = 'alert/qualifiers'

  async getAllQualifiers(): Promise<Qualifier[]> {
    return await this.get<Qualifier[]>(this.route)
  }
}
