import React from 'react'
import { ActionBar } from '../ActionBar'
import { Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Timer } from '@mui/icons-material'
import { PrimaryButton } from '../Buttons'
import { DataTestIds } from '../../utils/test-utils/dataTestIds'

const useActionBarContainerStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: '#d6e7f5',
      color: 'black',
    },
  })
)
const useStyles = makeStyles(() =>
  createStyles({
    lastUpdated: {
      fontStyle: 'italic',
    },
  })
)

interface Props {
  isLoading: boolean
  notificationMessage: string
  rightText: string
  buttonText: string
  onClick: (...args: any[]) => void
}

const TelemetryNotificationBar: React.FC<Props> = (props: Props) => {
  const { isLoading, notificationMessage, rightText, buttonText, onClick } =
    props

  const classes = useStyles()
  const actionBarContainerStyles = useActionBarContainerStyles()

  return (
    <ActionBar
      containerClasses={actionBarContainerStyles}
      left={
        <>
          <Timer />
          <Typography
            data-testid={`${DataTestIds.notificationBarMessageTypoghaphy}`}
          >
            {notificationMessage}
          </Typography>
        </>
      }
      middle={null}
      right={
        <>
          <Typography
            data-testid={`${DataTestIds.notificationBarRightTextTypoghaphy}`}
            className={classes.lastUpdated}
          >
            {rightText}
          </Typography>
          <PrimaryButton type={'button'} onClick={onClick} loading={isLoading}>
            {buttonText}
          </PrimaryButton>
        </>
      }
    />
  )
}

export default TelemetryNotificationBar
