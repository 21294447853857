import { useState, useEffect, useCallback } from 'react'

export function useMapResize(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jibestreamControl: any
): void {
  //Inspiration: https://stackoverflow.com/a/73366256
  const getSize = () => {
    return {
      // Tim Groven - 1/26/2023 - https://versuscarina.visualstudio.com/Carina/_workitems/edit/23136
      // Using documentElement.clientWidth instead of window.innerWidth due to a bug in webkit in Safari
      // https://github.com/necolas/react-native-web/issues/1905
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    }
  }

  const [size, setSize] = useState(getSize())

  const handleResize = useCallback(() => {
    let ticking = false
    if (!ticking) {
      window.requestAnimationFrame(() => {
        setSize(getSize())
        ticking = false
      })
      ticking = true
    }
  }, [])

  //Subscribe and unsubscribe from handleResize events
  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  //When size changes, call jibestream snapToBounds
  useEffect(() => {
    jibestreamControl?.control?.currentMapView?.snapToBounds()
  }, [jibestreamControl?.control?.currentMapView, size])
}
