import { Checkbox, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React, { useCallback, useEffect, useState } from 'react'
import {
  Control,
  Controller,
  FieldError,
  UseFormGetValues,
  UseFormRegister,
  UseFormTrigger,
} from 'react-hook-form'
import { useFetchCloudReportingPartner } from '../../hooks/useFetchCloudReportingPartner'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { FetchingStatus, LinkedUserModel, User } from '../../models'
import { colors } from '../../styles'
import { FetchCloudReportingErrorTypes } from '../../models/cloudReporting'

interface Props {
  user: User
  currentCustomerId: string
  control: Control<LinkedUserModel>
  error: FieldError | undefined
  register: UseFormRegister<LinkedUserModel>
  trigger: UseFormTrigger<LinkedUserModel>
  getValues: UseFormGetValues<LinkedUserModel>
}

const errors = {
  serviceNotFound:
    'Cloud Reporting is not configured. Please contact an administrator to enable this feature.',
  serviceUnknownError:
    'An unknown error occurred while retrieving Cloud Reporting information. Please contact an administrator if the problem persists.',
  noLicensesAvailable:
    'There are no available Analytics licenses. Please remove an existing Analytics user or contact support to add additional users.',
}

function UserCloudReportingForm(props: Props): JSX.Element {
  const {
    user,
    currentCustomerId,
    control,
    error,
    register,
    trigger,
    getValues,
  } = props

  const useCheckboxStyles = makeStyles(() =>
    createStyles({
      root: {
        padding: '0px',
        height: '19px',
        width: '19px',
      },
    })
  )

  const checkboxClasses = useCheckboxStyles()

  const {
    status: cloudReportingPartnerFetchingStatus,
    partner: cloudReportingPartner,
    errorType: cloudReportingErrorType,
  } = useFetchCloudReportingPartner(currentCustomerId)

  const [helperText, setHelperText] = useState('')

  const getHelperText = (value: boolean): string => {
    if (!value) return ''
    else {
      const isNewAssignment = !user?.isCloudReportingEnabled
      let assignedLicenses = cloudReportingPartner?.assignedLicenses ?? 0
      const userDisplayName =
        user.id !== '' ? `${user.firstName} ${user.lastName}` : 'New user'

      if (isNewAssignment && value) {
        assignedLicenses++
      }

      return `${userDisplayName} is Analytics user ${assignedLicenses} of ${cloudReportingPartner?.licenseLimit}.`
    }
  }

  const validate = useCallback(
    (value: boolean | undefined) => {
      if (cloudReportingErrorType === FetchCloudReportingErrorTypes.NotFound)
        return errors.serviceNotFound

      if (cloudReportingErrorType === FetchCloudReportingErrorTypes.Unknown)
        return errors.serviceUnknownError

      const isNewAssignment = !user?.isCloudReportingEnabled && value

      let assignedLicenses = cloudReportingPartner?.assignedLicenses ?? 0

      const licenseLimit =
        cloudReportingPartner?.licenseLimit ?? Number.MAX_SAFE_INTEGER

      if (isNewAssignment && value) {
        assignedLicenses++
      }

      if (assignedLicenses > licenseLimit) {
        return errors.noLicensesAvailable
      }

      return true
    },
    [
      cloudReportingErrorType,
      cloudReportingPartner?.assignedLicenses,
      cloudReportingPartner?.licenseLimit,
      user?.isCloudReportingEnabled,
    ]
  )

  useEffect(() => {
    if (
      cloudReportingPartnerFetchingStatus !== undefined &&
      cloudReportingPartnerFetchingStatus !== FetchingStatus.Request
    ) {
      setHelperText(
        getHelperText(getValues('isCloudReportingEnabled') ?? false)
      )

      // trigger validation
      trigger('isCloudReportingEnabled')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cloudReportingPartnerFetchingStatus])

  const handleChange = (value: boolean | undefined) => {
    setHelperText(getHelperText(value ?? false))
  }

  return (
    <>
      {cloudReportingErrorType === FetchCloudReportingErrorTypes.NotFound ? (
        ''
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Controller
              control={control}
              name='isCloudReportingEnabled'
              render={({ field: { onChange, value, ...field } }) => (
                <Checkbox
                  {...field}
                  {...register('isCloudReportingEnabled', {
                    validate: validate,
                  })}
                  color='primary'
                  icon={<CheckBoxOutlineBlankIcon color='primary' />}
                  classes={checkboxClasses}
                  disabled={
                    cloudReportingPartnerFetchingStatus ===
                      FetchingStatus.Request ||
                    cloudReportingErrorType !==
                      FetchCloudReportingErrorTypes.None
                  }
                  checked={value}
                  onChange={(_, value) => {
                    handleChange(value)
                    onChange(value)
                  }}
                />
              )}
            />
            <Typography
              style={{
                fontSize: '16px',
                padding: '0px 0px 0px 9px',
              }}
            >
              Add User to Analytics
            </Typography>
          </div>
        </>
      )}
      {cloudReportingPartnerFetchingStatus !== FetchingStatus.Request && (
        <Typography
          style={{
            fontSize: '16px',
            padding: '0px 0px 12px 27px',
            color: `${error ? colors.error : 'inherit'}`,
            display: 'inherit',
          }}
        >
          {error ? error.message : helperText}
        </Typography>
      )}
    </>
  )
}

export default UserCloudReportingForm
