import { useCallback, useEffect, useState } from 'react'
import { localMoment } from '../helpers/date'
import { Release } from '../models'
import { useFetchUpcomingRelease } from './entities/useFetchUpcomingRelease'
import useInterval from './useInterval'

export interface UpcomingReleaseNotification extends Release {
  isVisible: boolean
  isDismissed: boolean
  isIgnored: boolean
  receivedOn: Date
}

const millisecondsToShow = 15 * 60000
const localStorageKeyPrefix = `upcomingReleaseNotification`

const getLocalStorageNotification = (
  releaseId: number
): UpcomingReleaseNotification | null => {
  const localNotification = localStorage.getItem(
    `${localStorageKeyPrefix}:${releaseId}`
  )
  return localNotification
    ? (JSON.parse(localNotification) as UpcomingReleaseNotification)
    : null
}

const setLocalStorageNotification = (
  notification: UpcomingReleaseNotification
): void => {
  localStorage.setItem(
    `${localStorageKeyPrefix}:${notification.releaseId}`,
    JSON.stringify(notification)
  )
}

const useUpcomingReleaseNotification = (
  applicationTypeId: number
): {
  notification: UpcomingReleaseNotification | undefined
  dismiss: (notification: UpcomingReleaseNotification) => void
  ignore: (notification: UpcomingReleaseNotification) => void
} => {
  const [notification, setNotification] =
    useState<UpcomingReleaseNotification>()

  const { upcomingRelease } = useFetchUpcomingRelease(applicationTypeId)

  const updateNotificationStates = useCallback(
    (notification: UpcomingReleaseNotification) => {
      setNotification(notification)
      setLocalStorageNotification(notification)
    },
    [setNotification]
  )

  // map release to notification
  useEffect(() => {
    if (upcomingRelease) {
      const localStorageNotification = getLocalStorageNotification(
        upcomingRelease.releaseId
      )

      // we want this to not be visible initially in case it is old
      // the visibility is calculated by the useInterval below
      const isVisible = false

      // should not be dismissed on initial load
      const isDismissed = false

      const notification = localStorageNotification
        ? {
            ...localStorageNotification,
            isVisible,
            isDismissed,
          }
        : // this notification has not been seen before
          // initialize all properties
          {
            ...upcomingRelease,
            isVisible,
            isIgnored: false,
            isDismissed,
            receivedOn: new Date(),
          }

      updateNotificationStates(notification)
    }
  }, [upcomingRelease, updateNotificationStates])

  const evaluateVisibility = useCallback(() => {
    if (!notification) return

    // check time visibility
    const currentTime = localMoment(new Date())
    const updateTime = localMoment(notification.plannedReleasedDate)
    const diff = updateTime.diff(currentTime)
    const isVisibleTime = diff > 0 && diff <= millisecondsToShow

    // overall visibility check
    const isVisible =
      isVisibleTime && !notification.isIgnored && !notification.isDismissed

    updateNotificationStates({ ...notification, isVisible })
  }, [notification, updateNotificationStates])

  useInterval(evaluateVisibility, 5000)

  const dismiss = (notification: UpcomingReleaseNotification): void => {
    if (notification) {
      const updatedNotification = {
        ...notification,
        isDismissed: true,
        isVisible: false,
      }

      updateNotificationStates(updatedNotification)
    }
  }

  const ignore = (notification: UpcomingReleaseNotification): void => {
    if (notification) {
      const updatedNotification = {
        ...notification,
        isVisible: false,
        isIgnored: true,
      }

      updateNotificationStates(updatedNotification)
    }
  }

  return {
    notification,
    dismiss,
    ignore,
  }
}

export default useUpcomingReleaseNotification
