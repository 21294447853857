import { useDispatch, useSelector } from 'react-redux'
import { AssetTypes, AssetTypesState } from '../models'
import { getAllAssetTypesAction } from '../actions/assetTypes'
import { shouldDispatch } from '../helpers/shouldDispatch'
import { useEffect } from 'react'

const useFetchAssetTypes = (shouldRefresh: boolean = false) => {
  const dispatch = useDispatch()
  const status = useSelector(
    ({ assetTypes }: { assetTypes: AssetTypesState }) => assetTypes.status
  )
  const lastRefreshTime = useSelector(
    ({ assetTypes }: { assetTypes: AssetTypesState }) =>
      assetTypes.lastRefreshTime
  )

  useEffect(() => {
    if (shouldDispatch(status, lastRefreshTime) || shouldRefresh) {
      dispatch(getAllAssetTypesAction.request())
    }
  }, [status, lastRefreshTime, shouldRefresh, dispatch])

  const assetTypes: AssetTypes = useSelector(
    ({ assetTypes }: { assetTypes: AssetTypesState }) => assetTypes.data
  )

  return {
    assetTypes,
    status,
  }
}

export { useFetchAssetTypes }
