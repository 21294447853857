import { IconType } from '../../models'
import {
  InpixonAssetAgentCreateOptions,
  InpixonAssetGroupCreateOptions,
} from '../../models/inpixonMap'
import { MapRenderByGeofence } from '../../models/realtimeMap'
import { buildInpixonAssetAgentCreateOptions } from '../inpixon'

export function calculateInitialAgentAndGroupToCreate(
  initialMapRenderingTelemetries: MapRenderByGeofence,
  iconScale: number,
  selectedTrackingId: string | undefined,
  selectedGroupId: number | undefined
): {
  agentsToCreate: InpixonAssetAgentCreateOptions[]
  groupsToCreate: InpixonAssetGroupCreateOptions[]
} {
  const agentsToCreate: InpixonAssetAgentCreateOptions[] = []
  const groupsToCreate: InpixonAssetGroupCreateOptions[] = []
  const groupedTelemetries = Object.values(
    initialMapRenderingTelemetries
  ).filter((mapRenderByGeofence) => {
    return (
      mapRenderByGeofence.renderedAgents.length >=
      mapRenderByGeofence.maxAgentsAllowedInGeofence
    )
  })
  const ungroupedTelemetries = Object.values(
    initialMapRenderingTelemetries
  ).filter((mapRenderByGeofence) => {
    return (
      mapRenderByGeofence.renderedAgents.length <
      mapRenderByGeofence.maxAgentsAllowedInGeofence
    )
  })
  ungroupedTelemetries.forEach((mapRender) => {
    mapRender.renderedAgents.forEach((telemetry) => {
      const inpixonAssetAgentCreate = buildInpixonAssetAgentCreateOptions(
        telemetry,
        iconScale,
        selectedTrackingId
      )
      if (inpixonAssetAgentCreate) {
        agentsToCreate.push(inpixonAssetAgentCreate)
      }
    })
  })
  groupedTelemetries.forEach((mapRender) => {
    const arbitraryAssetWithinGeofence = mapRender.renderedAgents[0]
    const hasActiveDuress = mapRender.renderedAgents.some(
      (telemetry) => telemetry.hasActiveDuress
    )
    const hasActiveAssist = mapRender.renderedAgents.some(
      (telemetry) => telemetry.hasActiveAssist
    )
    groupsToCreate.push({
      iconType: IconType.Group,
      iconScale,
      geoFenceId: mapRender.geoFenceId,
      lat: arbitraryAssetWithinGeofence.lat,
      lon: arbitraryAssetWithinGeofence.lon,
      mapId: arbitraryAssetWithinGeofence.mapId,
      hasActiveDuress,
      hasActiveAssist,
      isSelected: mapRender.geoFenceId === selectedGroupId,
      groupCount: mapRender.renderedAgents.length,
    })
  })
  return {
    agentsToCreate,
    groupsToCreate,
  }
}
