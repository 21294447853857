export function getIconScale(mmPerPixel: number | undefined): number {
  if (!mmPerPixel) {
    return 0
  }
  // scaleValue: The baseline value for 100% scale is 183.6952515 pixels.
  // As defined by Nathan Loding in PBI 11264 (https://versuscarina.visualstudio.com/Carina/_workitems/edit/11264)
  const scaleValue = 183.6952515
  const iconScale = (100.0 * mmPerPixel) / scaleValue

  return iconScale
}
