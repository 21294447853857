import { FormControl, TextField } from '@mui/material'
import React, { useState } from 'react'
import { colors, leftButton, rightButton, useFormStyle } from '../../styles'
import { Button } from '../Buttons'
import { FlexBox } from '../Layout'

interface Props {
  itemId: string
  itemType: string
  handleSubmitClick: (itemId: string) => void
  handleCancelClick: () => void
}

const DeleteConfirmationForm = (props: Props): JSX.Element => {
  const { itemId, itemType, handleSubmitClick, handleCancelClick } = props
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const classes = useFormStyle()

  const onSubmit = (e: any): void => {
    e.preventDefault()
    if (deleteConfirmation) {
      handleSubmitClick(itemId)
    }
  }

  const onCancel = (): void => {
    handleCancelClick()
  }

  const onNameChange = (e: React.ChangeEvent<{ value: unknown }>): void => {
    if (String(e.target.value).toLocaleLowerCase() !== 'delete') {
      setDeleteConfirmation(false)
    } else {
      setDeleteConfirmation(true)
    }
  }

  const textFieldId = `${itemType}-edit-name`
  const textFieldLabel = `Confirm ${itemType} Deletion`
  const cancelButtonId = `${itemType}-edit-cancel-button`
  const deleteButtonId = `${itemType}-edit-delete-button`

  return (
    <form
      onSubmit={(e): void => {
        e.preventDefault()
      }}
    >
      <FlexBox layout='column'>
        <FormControl
          variant='filled'
          margin='normal'
          className={classes.inputStyle}
        >
          <TextField
            error={!deleteConfirmation}
            id={textFieldId}
            label={textFieldLabel}
            variant='filled'
            onChange={onNameChange}
          />
        </FormControl>
      </FlexBox>
      <FlexBox layout='row'>
        <FlexBox layout='column' layoutAlign={{ item: 'flex-start' }}>
          <Button
            id={cancelButtonId}
            onClick={onCancel}
            style={{
              ...leftButton,
              fontWeight: 'bold',
            }}
            bgColor={colors.transparent}
            textColor={colors.primary}
          >
            Cancel
          </Button>
        </FlexBox>
        <FlexBox
          layout='column'
          layoutAlign={{ item: 'flex-end', main: 'flex-end' }}
        >
          <Button
            id={deleteButtonId}
            onClick={onSubmit}
            style={{ ...rightButton, fontWeight: 'bold' }}
            bgColor={
              !deleteConfirmation
                ? colors.disabledButtonBackground
                : colors.error
            }
            textColor={colors.white}
            disabled={!deleteConfirmation}
          >
            DELETE {itemType}
          </Button>
        </FlexBox>
      </FlexBox>
    </form>
  )
}

export default DeleteConfirmationForm
