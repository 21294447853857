import { createAction, createAsyncAction } from 'typesafe-actions'
import {
  StaffTrafficRequest,
  GetStaffByLocationSuccessModel,
  Staff,
  StaffCreateOrUpdate,
  StaffTrafficByIdsRequest,
  GetStaffsByIdsSuccessModel,
  StaffPaginatedTrafficRequest,
} from '../models'
import { PaginationResponse } from '../models/paginationResponse'
import { HttpErrorResponse } from '../services/http'

export const removeStaffFromMapAction = createAction(
  'REMOVE_STAFF_FROM_MAP'
)<string>()
export const removeStaffMembersFromMapAction = createAction(
  'REMOVE_STAFF_MEMBERS_FROM_MAP'
)<string[]>()

export const getStaffByLocationIdAction = createAsyncAction(
  'GET_STAFF_BY_LOC_REQUEST',
  'GET_STAFF_BY_LOC_SUCCESS',
  'GET_STAFF_BY_LOC_FAILURE'
)<StaffTrafficRequest, GetStaffByLocationSuccessModel, HttpErrorResponse>()

export const getPaginatedStaffAction = createAsyncAction(
  'GET_PAGE_STAFd_REQUEST',
  'GET_PAGE_STAFF_SUCCESS',
  'GET_PAGE_STAFF_FAILURE'
)<
  StaffPaginatedTrafficRequest,
  PaginationResponse<Staff[]>,
  HttpErrorResponse
>()

export const getAllStaffAction = createAsyncAction(
  'GET_ALL_STAFF_REQUEST',
  'GET_ALL_STAFF_SUCCESS',
  'GET_ALL_STAFF_FAILURE'
)<undefined, Staff[], HttpErrorResponse>()

export const putStaffAction = createAsyncAction(
  'PUT_STAFF_REQUEST',
  'PUT_STAFF_SUCCESS',
  'PUT_STAFF_FAILURE'
)<StaffCreateOrUpdate, Staff, HttpErrorResponse>()

export const postStaffAction = createAsyncAction(
  'POST_STAFF_REQUEST',
  'POST_STAFF_SUCCESS',
  'POST_STAFF_FAILURE'
)<StaffCreateOrUpdate, Staff, HttpErrorResponse>()

export const deleteStaffAction = createAsyncAction(
  'DELETE_STAFF_REQUEST',
  'DELETE_STAFF_SUCCESS',
  'DELETE_STAFF_FAILURE'
)<string, string, HttpErrorResponse>()

// Use cancelArg as SILENT_UPDATE_STAFF_COMPLETE action type to take advantages of the createAsyncAction method
export const silentUpdateStaffAction = createAsyncAction(
  'SILENT_UPDATE_STAFF_REQUEST',
  'SILENT_UPDATE_STAFF_SUCCESS',
  'SILENT_UPDATE_STAFF_FAILURE',
  'SILENT_UPDATE_STAFF_COMPLETE'
)<StaffTrafficByIdsRequest, GetStaffsByIdsSuccessModel, HttpErrorResponse>()
