import { CurrentUserState, User } from '../../models'
import { EntityResult, useEntities } from '../useEntities'
import { getCurrentUserByIdAction } from '../../actions/currentUser'

const useFetchCurrentUser = (userId: string): EntityResult<User | undefined> =>
  useEntities<CurrentUserState, User | undefined>(
    () => getCurrentUserByIdAction.request(userId),
    (state: any) => state.currentUser // eslint-disable-line @typescript-eslint/no-explicit-any
  )

export { useFetchCurrentUser }
