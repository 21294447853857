import { getType } from 'typesafe-actions'
import { InpixonAssetRefStore } from '../models'
import { addAgentReferenceAction } from '../actions/agentReference'
import { AgentReferenceAction } from '../actions'
const initialState: InpixonAssetRefStore = {
  agentReferences: [],
  currentReference: 10000,
}

export default (
  state: InpixonAssetRefStore = initialState,
  action: AgentReferenceAction
): InpixonAssetRefStore => {
  switch (action.type) {
    case getType(addAgentReferenceAction):
      return {
        ...state,
        agentReferences: [
          ...state.agentReferences,
          ...action.payload.agentReferences,
        ],
        currentReference: action.payload.currentReference,
      }
    default:
      return state
  }
}
