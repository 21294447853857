import { getType } from 'typesafe-actions'
import { BadgeLocation } from '@midmarkrtls/common/models/badgeLocation'
import { TrafficSensors } from '../../models/traffic'
import { getLastSeenBadgesAction } from '@midmarkrtls/common/actions/sensors'
import { SensorAction } from '@midmarkrtls/common/actions'
import { SensorBadgePayload } from '@midmarkrtls/common/models'

const trafficReducer = (
  state: TrafficSensors = {},
  action: SensorAction
): TrafficSensors => {
  switch (action.type) {
    case getType(getLastSeenBadgesAction.success):
      return Object.values(action.payload as SensorBadgePayload).reduce(
        (acc: TrafficSensors, badgeLocations: BadgeLocation[]) => {
          acc[badgeLocations[0]?.sensorID] = badgeLocations[0]
          return acc
        },
        { ...state }
      )

    default:
      return state
  }
}

export default trafficReducer
