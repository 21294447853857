import { Location, Locations, LocationType, SimpleLocation } from '../models'

export function generateLocationHierarchy(
  locations: Locations,
  locationId: string
): Location[] {
  const parentId = locations[locationId]?.parentId
  if (parentId) {
    return [
      ...generateLocationHierarchy(locations, parentId),
      locations[locationId],
    ]
  }
  return [locations[locationId]]
}

export const getAncestors = (
  locationId: string,
  locations: Locations,
  ancestors: Location[] = []
): Location[] => {
  if (ancestors.length === 0) {
    const location = locations[locationId]
    if (location) {
      location.children = getLocationChildren(location, locations)
      ancestors.push(location)
    }
  }
  const parentId = locations[locationId]?.parentId
  const parentLocation = (parentId && locations[parentId]) as Location
  if (!parentLocation) {
    return ancestors
  }

  parentLocation.children = getLocationChildren(parentLocation, locations)

  return getAncestors(parentLocation.id, locations, [
    ...ancestors,
    parentLocation,
  ])
}

export const getVenueLocationFor = (
  locationId: string,
  locations: Locations
): Location | undefined => {
  const location = locations[locationId]
  const ancestors = getAncestors(locationId, locations)

  return [location, ...ancestors].find(
    (x) => x?.locationType === LocationType.BuildingGroup
  )
}

export const getLocationAndChildren = (
  location: Location,
  locations: Locations,
  includeRooms?: boolean
): SimpleLocation => {
  const newSimpleLocation: SimpleLocation = {
    id: location.id,
    name: location.name,
    unitId: location.unitId,
    locationType: location.locationType,
    displayOrder: location.displayOrder,
    subLocations:
      includeRooms == true || location.locationType != LocationType.Floor
        ? Object.values(locations)
            .filter((x) => x.parentId == location.id)
            .map((x) => getLocationAndChildren(x, locations, includeRooms))
        : [],
  }

  return newSimpleLocation
}

export const getLocationChildren = (
  location: Location,
  locations: Locations
) => {
  if (location.locationType !== LocationType.Floor) {
    return Object.values(locations).filter((x) => x.parentId == location.id)
  }
  return []
}
