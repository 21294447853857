import { Epic } from 'redux-observable'
import { JibestreamUserAction } from '../actions'
import { JibestreamUserState } from '../reducers/user'
import { JibestreamApi } from '../middleware/jibestream'
import { isActionOf } from 'typesafe-actions'
import { getAllUsersAction } from '../actions/user'
import { filter, mergeMap, catchError, map } from 'rxjs/operators'
import { from, of } from 'rxjs'

type JibestreamUserEpic = Epic<
  JibestreamUserAction,
  JibestreamUserAction,
  JibestreamUserState,
  { jibestreamApi: JibestreamApi }
>

export const getAllUsersEpic: JibestreamUserEpic = (
  action$,
  state,
  { jibestreamApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllUsersAction.request)),
    mergeMap(() =>
      from(jibestreamApi.getAllUsers()).pipe(
        map((user) => getAllUsersAction.success(user)),
        catchError((error) => of(getAllUsersAction.failure(error)))
      )
    )
  )
}
