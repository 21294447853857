import { useEffect, useRef, useState } from 'react'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import GeofenceKit from '@jibestream-dev/jmap-geofence-kit'
import { getCenterOfBounds, isPointInBounds } from '../../helpers'

type NewType = any

const geoFenceInstances: NewType[] = []

/**
 * @deprecated
 * @param jibestreamControl
 * @param core
 * @param setGeoFenceKitLoaded
 * @returns
 */
export function useGeoFenceKit(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jibestreamControl: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  core: any,
  setGeoFenceKitLoaded: (loaded: boolean) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  // this hook uses an internal initialized state to trigger a render cycle
  // refs don't cause renders by themselves. because the GeoKit doesn't
  // issue a callback event, like jmap.init does, we need to force a rerender
  // when the ref becomes available or the updated ref is never sent to
  // other places it is needed, such as useDevices
  const [initialized, setInitialized] = useState(false)
  const [geoFencesRead, setGeoFencesRead] = useState(false)
  const [geoFencesLoaded, setGeoFencesLoaded] = useState(false)

  const geoFenceKit = useRef()

  useEffect(() => {
    if (jibestreamControl && core) {
      geoFenceKit.current = new GeofenceKit(core, jibestreamControl)
      setInitialized(true)
    }

    return (): void => {
      geoFenceKit.current = undefined
      setInitialized(false)
      setGeoFencesRead(false)
    }
  }, [jibestreamControl, core])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const kit: any = geoFenceKit.current
    if (initialized && !geoFencesRead && kit) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      kit.getGeofences((error: any, geofences: any) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        geofences?.getAll().forEach((y: any) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          y.instances.forEach((x: any) => {
            geoFenceInstances.push(x)
          })
        })
        setGeoFencesRead(true)
      })
    }
  }, [jibestreamControl, core, initialized, geoFenceKit, geoFencesRead])

  useEffect(() => {
    if (geoFencesRead && !geoFencesLoaded && geoFenceInstances.length) {
      setGeoFencesLoaded(true)
      setGeoFenceKitLoaded(true)
    }
  }, [geoFencesLoaded, geoFencesRead, setGeoFenceKitLoaded])

  return geoFenceKit.current
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getGeoFenceInstancesCenterByPoint(
  point: number[],
  mapId: number
): any[] {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let geoFence: any
  if (geoFenceInstances?.length) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    geoFenceInstances
      .filter((x) => x.floor.map.id === mapId)
      .forEach((x: any) => {
        let match = false
        if (x?.localCoordinates.length) {
          match = isPointInBounds(point, x.localCoordinates[0])
        }

        if (match) {
          geoFence = x
        }
      })
  }
  if (geoFence) {
    return getCenterOfBounds(geoFence.localCoordinates[0])
  } else {
    return []
  }
}
