import { createSelector } from 'reselect'
import memoize from 'lodash/memoize'
import { Device, Issue, Issues } from '../models'
import { deviceSelector } from './'

export const issueSelector = (state: Issues): Issues => state

// Array of Issues by DeviceGuid
const allIssuesForDeviceGuid = (issues: Issues, deviceGuid: string): Issue[] =>
  Object.values(issues).filter(
    (issue: Issue) => issue.deviceGuid === deviceGuid
  )

// Array of Issues by DeviceGuid that don't have a resolveDate
const openIssuesForDeviceGuid = (issues: Issues, deviceGuid: string): Issue[] =>
  Object.values(issues).filter(
    (issue: Issue) =>
      issue.deviceGuid === deviceGuid && !Boolean(issue.resolveDate)
  )

export const getOpenIssuesByDeviceGuidSelector = createSelector(
  issueSelector,
  (issues) =>
    memoize((deviceGuid: string) => openIssuesForDeviceGuid(issues, deviceGuid))
)

export const getIssuesByDeviceGuid = createSelector(issueSelector, (issues) =>
  memoize((deviceGuid: string) => allIssuesForDeviceGuid(issues, deviceGuid))
)

export const getIssuesByFloormapGuid = createSelector(
  issueSelector,
  deviceSelector,
  (issues, devices) =>
    memoize((floormapGuid: string) => {
      const devicesForFloor: Device[] = Object.values(devices).filter(
        (device: Device) => device.floormapGuid === floormapGuid
      )
      return Object.values(issues).filter((issue: Issue) =>
        devicesForFloor.some(
          (device: Device) => issue.deviceGuid === device.deviceGuid
        )
      )
    })
)

// Boolean Devices that have issues with or without resolveDate
export const deviceHasOpenOrClosedIssues = createSelector(
  issueSelector,
  (issues) =>
    memoize((deviceGuid: string) =>
      Boolean(allIssuesForDeviceGuid(issues, deviceGuid).length)
    )
)

// Boolean Devices that have issues ONLY without resolveDate
export const deviceHasOpenIssues = createSelector(issueSelector, (issues) =>
  memoize((deviceGuid: string) =>
    Boolean(openIssuesForDeviceGuid(issues, deviceGuid).length)
  )
)
