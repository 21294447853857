import React from 'react'
import {
  Manufacturer,
  RealTimePageViews,
  TelemetryAsset,
  Watchlist,
} from '../../../../models'
import { DetailDrawerAgentTelemetry } from '../../../../models/telemetry'
import { RightDrawerLayout } from '../../RightDrawer'
import AssetDetailPane from './AssetDetailPane'
import AssetDetailPaneHeader from './AssetDetailPaneHeader'
import { AssetFilters } from '../../../../models/filters'

interface Props {
  selectedAsset: DetailDrawerAgentTelemetry<TelemetryAsset>
  watchList: Watchlist
  manufacturers: Manufacturer[]
  currentPageView: RealTimePageViews
  assetFilterState: AssetFilters
  onCloseDetailDrawer: () => void
  onEditAsset?: () => void
  setFilterButtonToggled: (filterButtonStates: any) => void
}

const AssetDetailDrawer = (props: Props): JSX.Element => {
  const {
    selectedAsset,
    watchList,
    manufacturers,
    currentPageView,
    assetFilterState,
    onCloseDetailDrawer,
    onEditAsset,
    setFilterButtonToggled,
  } = props

  return (
    <RightDrawerLayout
      header={
        <AssetDetailPaneHeader
          data={selectedAsset}
          watchlist={watchList}
          onClose={onCloseDetailDrawer}
          onEditAsset={onEditAsset}
          pageView={currentPageView === 'list' ? 'map' : 'list'}
          setFilterButtonToggled={setFilterButtonToggled}
          assetFilterState={assetFilterState}
        />
      }
      content={
        <AssetDetailPane data={selectedAsset} manufacturers={manufacturers} />
      }
    />
  )
}

export default AssetDetailDrawer
