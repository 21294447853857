import { AssetKitInstance } from '../../models'
import { MapRenderByGeofence } from '../../models/realtimeMap'
import { BadgeTelemetryMessageWithAgentEvent } from '../../models/telemetry'
import {
  buildInpixonAssetAgentCreateOptions,
  buildInpixonAssetConfigForNewAgent,
  createInpixonAssets,
} from '../inpixon'
import { calculateMapRenderToUpdateForAgentsEnteringMap } from '../realtimeMap'

/**
 * Once it's determined that the agent is entering a geofence that currently does NOT have any
 * other agent, create a new Asset/Staff icon on the map and add geofence highlighting if the
 * agent has an active duress
 * @param agentTelemetry
 * @param currentMapRender
 * @param assetKit
 * @param assetKitId
 * @param useGeofenceCentroid
 * @param iconScale
 * @param geofenceInstances
 * @param jibestreamController
 * @param selectedTrackingId
 * @param addGeofenceHighlight
 * @returns updated map render state after map rerendering and the next inpixonAssetId to use
 * for subsequent Inpixon Asset creation
 */
export function createNewAgentEnteringMap(
  agentTelemetry: BadgeTelemetryMessageWithAgentEvent,
  currentMapRender: MapRenderByGeofence,
  assetKit: AssetKitInstance,
  assetKitId: number,
  useGeofenceCentroid: boolean,
  iconScale: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  geofenceInstances: any[],
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any,
  selectedTrackingId: string | undefined,
  addGeofenceHighlight: (geofenceId: number) => void
): {
  nextInpixonAssetId: number | undefined
  updatedMapRender: MapRenderByGeofence
} {
  let nextInpixonAssetId = assetKitId
  const inpixonAssetAgentCreate = buildInpixonAssetAgentCreateOptions(
    agentTelemetry,
    iconScale,
    selectedTrackingId
  )
  if (!inpixonAssetAgentCreate) {
    return {
      nextInpixonAssetId: undefined,
      updatedMapRender: currentMapRender,
    }
  }
  const { inpixonAssetConfig, nextInpixonAssetId: updatedInpixonAssetId } =
    buildInpixonAssetConfigForNewAgent(
      inpixonAssetAgentCreate,
      nextInpixonAssetId,
      useGeofenceCentroid,
      geofenceInstances,
      jibestreamController
    )
  createInpixonAssets(assetKit, [inpixonAssetConfig], jibestreamController)
  if (updatedInpixonAssetId) {
    nextInpixonAssetId = updatedInpixonAssetId
  }
  if (agentTelemetry.hasActiveDuress) {
    addGeofenceHighlight(agentTelemetry.geoFenceId)
  }
  const updatedMapRender = calculateMapRenderToUpdateForAgentsEnteringMap(
    currentMapRender,
    agentTelemetry
  )
  return {
    nextInpixonAssetId,
    updatedMapRender,
  }
}
