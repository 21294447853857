import { FetchingState } from '../interfaces/fetchingState'

export interface UserRouter {
  id: string
  name: string
  routeUserTypeId: number
}

export interface UserRouters {
  [id: string]: UserRouter
}

export type UserRouterState = Readonly<FetchingState<UserRouters>>

export enum RouteUserType {
  Role,
}
