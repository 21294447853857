import {
  Staff,
  StaffCreateOrUpdate,
  StaffTrafficByIdsRequest,
  StaffTrafficRequest,
  StaffPaginatedTrafficRequest,
} from '../models'
import { PaginationResponse } from '../models/paginationResponse'
import { HttpParams, HttpParamsOptions } from '../services/http'
import { BaseService } from './baseService'

export class StaffApi extends BaseService {
  constructor() {
    super({ entityType: 'staff' })
  }
  staffRoute = 'staff'
  staffsByIdsRoute = 'staffsByIds'
  locRoute = 'locations'

  async getStaffByLocationId(
    staffTrafficRequest: StaffTrafficRequest
  ): Promise<Staff[]> {
    return await this.get<Staff[]>(
      `${this.locRoute}/${staffTrafficRequest.locationGuid}/${this.staffRoute}/traffic`,
      { params: { signalStatusId: staffTrafficRequest.signalStatus } }
    )
  }

  async getStaffsByIds(
    staffTrafficRequest: StaffTrafficByIdsRequest
  ): Promise<Staff[]> {
    const paramsOptions: HttpParamsOptions = {
      fromObject: {
        staffGuids: staffTrafficRequest.agentGuids as string[],
      },
    }
    return await this.get<Staff[]>(
      `${this.staffRoute}/${this.staffsByIdsRoute}`,
      { params: new HttpParams(paramsOptions) }
    )
  }

  async getPaginatedStaff(
    staffPaginatedTrafficRequest: StaffPaginatedTrafficRequest
  ): Promise<PaginationResponse<Staff[]>> {
    return await this.get<PaginationResponse<Staff[]>>(
      `${this.locRoute}/staff/traffic`,
      {
        params: staffPaginatedTrafficRequest.filters,
      }
    )
  }

  async getAllStaff(): Promise<Staff[]> {
    return await this.get<Staff[]>(`${this.staffRoute}`, {})
  }

  async putStaff(staff: StaffCreateOrUpdate): Promise<Staff> {
    return await this.put<Staff>(`${this.staffRoute}/${staff.agentGuid}`, staff)
  }

  async postStaff(staff: StaffCreateOrUpdate): Promise<Staff> {
    return await this.post<Staff>(`${this.staffRoute}`, staff)
  }

  async deleteStaff(staffGuid: string): Promise<boolean> {
    return await this.deleteNoResult(`${this.staffRoute}/${staffGuid}`)
  }
}
