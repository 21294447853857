import React from 'react'
import { FilterDrawerHeader, RightDrawerLayout } from '../Common/RightDrawer'
import { FilterDrawerHeaderProps } from '../Common/RightDrawer/FilterDrawerHeader'
import AlertListFilterContent from './AlertListFilterContent'
import { AlertFilters } from '../../models'

interface Props {
  headerProps: FilterDrawerHeaderProps
  onFilterChanged: (filter: AlertFilters) => void
  alertFilterState: AlertFilters
}

const AlertListFilterDrawer = (props: Props): JSX.Element => {
  const { headerProps, alertFilterState, onFilterChanged } = props

  return (
    <RightDrawerLayout
      header={<FilterDrawerHeader {...headerProps} />}
      content={
        <AlertListFilterContent
          onFilterChanged={onFilterChanged}
          alertFilters={alertFilterState}
        />
      }
    />
  )
}

export default AlertListFilterDrawer
