import { Epic } from 'redux-observable'
import { ChannelSoundsAction } from '../actions'
import { ChannelSoundsState } from '../models'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { getAllChannelSoundsAction } from '../actions/channelSounds'
import { ChannelSoundsApi } from '../middleware'
import { from, of } from 'rxjs'

type ChannelSoundEpic = Epic<
  ChannelSoundsAction,
  ChannelSoundsAction,
  ChannelSoundsState,
  { ChannelSoundsApi: ChannelSoundsApi }
>

export const getAllChannelSoundsEpic: ChannelSoundEpic = (
  action$,
  state,
  { ChannelSoundsApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllChannelSoundsAction.request)),
    mergeMap((action) =>
      from(ChannelSoundsApi.getAllChannelSounds()).pipe(
        map((ChannelSounds) =>
          getAllChannelSoundsAction.success(ChannelSounds)
        ),
        catchError((error) => of(getAllChannelSoundsAction.failure(error)))
      )
    )
  )
}
