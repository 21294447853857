import { BaseService } from './baseService'
import { User } from '../models/users'
import { FilterCreateModel, FilterDeleteModel, FilterModel } from '../models'

export class UsersApi extends BaseService {
  constructor() {
    super({ entityType: 'users' })
  }
  route = 'users'

  async getAllUsers(): Promise<User[]> {
    return await this.get(this.route)
  }

  async getUserById(id: string): Promise<User> {
    return await this.get(`${this.route}/${id}`)
  }
  async getUserByEmail(email: string): Promise<User> {
    return await this.get(`${this.route}/${email}`)
  }

  async createUser(record: User): Promise<User> {
    return await this.post(this.route, record)
  }

  async updateUser(record: User): Promise<User> {
    return await this.patch(`${this.route}/${record.id}`, record)
  }

  async deleteUser(userId: string): Promise<string> {
    return await this.delete(`${this.route}/${userId}`)
  }

  async postUserFiltersById(record: FilterCreateModel): Promise<FilterModel> {
    return await this.post(`${this.route}/${record.userGuid}/filters`, record)
  }

  async getUserFiltersById(userGuid: string): Promise<FilterModel[]> {
    return await this.get(`${this.route}/${userGuid}/filters`)
  }

  async deleteUserFilterById(record: FilterDeleteModel): Promise<boolean> {
    return await this.deleteNoResult(
      `${this.route}/${record.userGuid}/filters/${record.id}`
    )
  }
}
