import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateGroupsAction } from '../../actions/mapGroups'
import { mapLabelState } from '../../helpers/inpixon/assetUpdate'
import { processUpdates } from '../../helpers/inpixon/mapProcess'
import {
  Assets,
  FeatureFlags,
  Geofences,
  LocationGeofences,
  Locations,
  MapGroups,
  MapGroupState,
  StaffMembers,
  MapOutline,
  MapOutlineState,
  StaffEvent,
  MapUpdateState,
  InpixonAssetRefStore,
  AssetInstance,
  StaffEvents,
} from '../../models'
import { getMapTelemetryState } from '../useMapTelemetry'

export function useMapUpdates(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jibestream: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  assetKit: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mapId: any,
  setAssetsCreated: (loaded: boolean) => void,
  allDuressEvents: StaffEvents | undefined,
  allAssistEvents: StaffEvents | undefined,
  agentTypes?: string[],
  setSelectedGroupId?: (groupId: number | undefined) => void,
  assets?: Assets,
  staff?: StaffMembers,
  geofences?: Geofences,
  locations?: Locations,
  locationGeofences?: LocationGeofences,
  geoFenceKitLoaded?: boolean,
  featureFlags?: FeatureFlags,
  GeofenceKitRef?: (geofenceKit: any) => void
): void {
  const initialLoad = useRef(true)
  const dispatch = useDispatch()
  const [agentTypeState, setAgentTypeState] = useState<string[]>([])
  const [assetState, setAssetState] = useState<Assets | undefined>(assets)
  const [staffState, setStaffState] = useState<StaffMembers | undefined>(staff)
  const [duressState, setDuressState] = useState<
    { [k: string]: StaffEvent } | undefined
  >(undefined)
  const [assistState, setAssistState] = useState<
    { [k: string]: StaffEvent } | undefined
  >(undefined)
  const { agentReferences: inpixonReference, currentReference } = useSelector(
    ({ agentReferences }: { agentReferences: InpixonAssetRefStore }) =>
      agentReferences
  )

  const [currentGeofenceMapState, setCurrentGeofenceMapState] =
    useState<MapOutlineState>({})
  const [desiredGeofenceMapState, setDesiredGeofenceMapState] =
    useState<MapOutlineState>({})
  const mapUpdates = useSelector(
    ({ mapUpdates }: { mapUpdates: MapUpdateState }) => mapUpdates
  )

  const handleGroupStateUpdate = useCallback((groups: MapGroups) => {
    dispatch(updateGroupsAction(groups))
  }, [])

  const handleUpdateDesiredGeofenceMapState = useCallback(
    (mapOutline: MapOutline) => {
      setDesiredGeofenceMapState((prev) => {
        return {
          ...prev,
          [mapOutline.badgeId]: mapOutline,
        }
      })
    },
    []
  )

  const handleDeleteDesiredGeofenceMapState = useCallback((badgeId: string) => {
    setDesiredGeofenceMapState((prev) => {
      const state = { ...prev }
      delete state[badgeId]
      return {
        ...state,
      }
    })
  }, [])

  const handleClearDesiredGeofenceMapState = useCallback(() => {
    setDesiredGeofenceMapState({})
  }, [])

  const mapGroups: MapGroupState = useSelector(
    ({ mapGroups }: { mapGroups: MapGroupState }) => mapGroups
  )

  useEffect(() => {
    if (
      assetKit &&
      (assets || staff) &&
      geoFenceKitLoaded &&
      setSelectedGroupId &&
      inpixonReference.length
    ) {
      // live updates
      if (mapUpdates) {
        processUpdates(
          jibestream,
          assetKit,
          mapId,
          mapUpdates?.mapUpdates ?? null,
          inpixonReference,
          currentReference,
          mapGroups.selectedGroupId,
          mapGroups.selectedTrackingId ?? '',
          mapGroups.groups,
          setSelectedGroupId,
          handleGroupStateUpdate,
          dispatch,
          currentGeofenceMapState,
          desiredGeofenceMapState,
          handleUpdateDesiredGeofenceMapState,
          handleDeleteDesiredGeofenceMapState,
          handleClearDesiredGeofenceMapState,
          geofences,
          locations,
          locationGeofences,
          assets,
          staff,
          featureFlags,
          allDuressEvents,
          allAssistEvents,
          agentTypes
        )
      }

      // initial load & full state refreshes
      if (
        inpixonReference.length &&
        (initialLoad.current ||
          JSON.stringify(agentTypes) !== JSON.stringify(agentTypeState) ||
          assets !== assetState ||
          staff !== staffState ||
          JSON.stringify(allDuressEvents) !== JSON.stringify(duressState) ||
          JSON.stringify(allAssistEvents) !== JSON.stringify(assistState))
      ) {
        const mapTelemetryState = getMapTelemetryState(mapId)?.filter(
          (x) => x.mapId === mapId
        )
        setAgentTypeState(agentTypes ?? [])

        // Tim Groven - 08/23/2023 - https://versuscarina.visualstudio.com/Carina/_workitems/edit/28548
        // Since this is an intitial load/full state refresh we want to clear the map (this is specifically
        // for the case of removing assets or staff from the real-time map view.)
        const assetKitAssets: AssetInstance[] = assetKit._getAllAssets()
        assetKitAssets.forEach((x) => {
          assetKit.removeAsset(x)
        })

        if (mapTelemetryState) {
          initialLoad.current = false
          setAssetsCreated(true)
          setAssetState(assets)
          setStaffState(staff)
          setDuressState(allDuressEvents)
          setAssistState(allAssistEvents)

          processUpdates(
            jibestream,
            assetKit,
            mapId,
            mapTelemetryState,
            inpixonReference,
            currentReference,
            mapGroups.selectedGroupId,
            mapGroups.selectedTrackingId ?? '',
            mapGroups.groups,
            setSelectedGroupId,
            handleGroupStateUpdate,
            dispatch,
            currentGeofenceMapState,
            desiredGeofenceMapState,
            handleUpdateDesiredGeofenceMapState,
            handleDeleteDesiredGeofenceMapState,
            handleClearDesiredGeofenceMapState,
            geofences,
            locations,
            locationGeofences,
            assets,
            staff,
            featureFlags,
            allDuressEvents,
            allAssistEvents,
            agentTypes
          )
        }
      }
    }
  }, [
    inpixonReference,
    assets,
    mapGroups.selectedGroupId,
    mapGroups.selectedTrackingId,
    JSON.stringify(mapUpdates),
    geoFenceKitLoaded,
    jibestream,
    assetKit,
    mapId,
    geofences,
    staff,
    allDuressEvents,
    allAssistEvents,
    agentTypes,
  ])

  useEffect(() => {
    mapLabelState(
      setCurrentGeofenceMapState,
      currentGeofenceMapState,
      desiredGeofenceMapState,
      geoFenceKitLoaded,
      GeofenceKitRef,
      jibestream,
      geofences,
      staff
    )
  }, [
    desiredGeofenceMapState,
    staff,
    geofences,
    allDuressEvents,
    geoFenceKitLoaded,
  ])
}
