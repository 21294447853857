import { generateAssetConfig, getCoordinates } from '.'
import { getGeoFenceInstancesCenterByPoint } from '../../hooks/inpixon'
import { getAgentGroupCount } from './getAgentGroupCount'
import {
  InpixonAssetConfig,
  Assets,
  Coordinates,
  Geofences,
  IconType,
  InpixonAssetRef,
  MapGroups,
  MapUpdate,
  StaffMembers,
  FeatureFlags,
  StaffEvents,
  BadgeSignalStatus,
} from '../../models'
import { getAccuracyConfig } from '../accuracy'
import { getDuressInGeoFence, getAssistInGeoFence } from '..'
import { getIconScale, buildIconURL } from './assetCreate'
import { isLowBattery } from '../battery'
import { colors } from '../../styles/MidmarkTheme'
import { trackingIdToAssetKitId } from './mapProcess'

const createGroupConfig = (
  geoFenceId: number,
  badgeCount: number,
  map: any, // jibestream.control.map object,
  control: any,
  center: Coordinates,
  isDuress?: boolean,
  isAssist?: boolean,
  isSelected?: boolean
) => {
  return generateAssetConfig({
    assetID: geoFenceId,
    name: badgeCount.toString(),
    coordinates: [center.x, center.y],
    type: IconType.Group,
    height: 10,
    showIcon: true,
    map,
    isConfigured: false,
    hasIssues: false,
    isLowBattery: false,
    confidenceAlpha: 0,
    snapToGrid: false,
    iconScale: getIconScale(control.currentMap.mmPerPixel),
    isDuress: isDuress,
    isAssist: isAssist,
    isSelected: isSelected,
  })
}

function createNewGroupConfig(
  geoFence: MapUpdate,
  groupCount: number,
  jibestream: any,
  isHighlighted: boolean,
  isDuress = false,
  isAssist = false,
  mapId: number,
  isSelected = false
): InpixonAssetConfig | undefined {
  // create or update
  const arbitraryAssetWithinGeofence = geoFence.agents.find(
    (x) => x.lat && x.lon
  )

  if (arbitraryAssetWithinGeofence) {
    const coords = getCoordinates(
      arbitraryAssetWithinGeofence.lat,
      arbitraryAssetWithinGeofence.lon,
      jibestream
    )

    const center = getGeoFenceInstancesCenterByPoint(coords, mapId)
    const config = createGroupConfig(
      geoFence.geoFenceId,
      groupCount,
      jibestream.control?.map,
      jibestream.control,
      { x: center[0], y: center[1] } as Coordinates,
      isDuress,
      isAssist
    )
    return config
  }
}

export interface groupIconUpdate {
  id: number
  iconUrl: string
}

export interface agentHideUpdate {
  trackingIds: string[]
  inpixonReference: InpixonAssetRef[]
}

export interface agentAccuracyUpdate {
  assetId: string
  signalTypeIds: number[]
}

export interface groupUpdates {
  groupsToCreate: InpixonAssetConfig[]
  stateGroupsToUpdate: MapGroups
  agentsToHide: agentHideUpdate[]
  groupIconsToUpdate: groupIconUpdate[]
  agentsToDelete: number[]
  agentsToShow: string[]
  agentsToShowAccuracy: agentAccuracyUpdate[]
}

export function processGeoFenceUpdates(
  jibestream: any,
  assetKit: any,
  privateGeofenceIds: number[],
  inpixonReference: InpixonAssetRef[],
  mapUpdates: MapUpdate[],
  selectedGroupId: number | undefined,
  selectedBadgeId: string,
  geofences: Geofences,
  existingMapGroups: MapGroups,
  setSelectedGroupId: (groupId: number | undefined) => void,
  mapId: number,
  assets?: Assets,
  staff?: StaffMembers,
  featureFlags?: FeatureFlags,
  duressNotifications?: StaffEvents,
  assistNotifications?: StaffEvents,
  agentTypes?: string[]
): groupUpdates | undefined {
  const groupsToCreate: InpixonAssetConfig[] = []
  const stateGroupsToUpdate: MapGroups = {}
  const agentsToHide: agentHideUpdate[] = []
  const groupIconsToUpdate: groupIconUpdate[] = []
  const agentsToDelete: number[] = []
  const agentsToShow: string[] = []
  const agentsToShowAccuracy: agentAccuracyUpdate[] = []

  const duressStaffShow = featureFlags
    ? Object.values(featureFlags).filter(
        (x) => x.name.includes('/duress/staff') === true && x.enabled === true
      ).length > 0
    : false

  mapUpdates.map((geoFence: MapUpdate) => {
    const isPrivate = privateGeofenceIds.includes(geoFence.geoFenceId)

    const badgesInGroup = getAgentGroupCount(
      geoFence.geoFenceId,
      agentTypes,
      assets,
      staff,
      duressStaffShow,
      duressStaffShow,
      duressNotifications,
      assistNotifications,
      isPrivate
    )

    const groupAsset = assetKit.getAssetById(geoFence.geoFenceId)
    const groupCount =
      Object.values(geofences).find(
        (x) => x.coreReferenceId === geoFence.geoFenceId
      )?.groupCount ?? 2

    if (badgesInGroup.length >= groupCount) {
      // if existing group, update count
      // else create new group icon
      if (groupAsset) {
        if (
          existingMapGroups[geoFence.geoFenceId]?.trackingIds.length !==
            badgesInGroup.length ||
          (duressNotifications != null &&
            Object.values(duressNotifications).length > 0) ||
          (assistNotifications != null &&
            Object.values(assistNotifications).length > 0)
        ) {
          const duressInGeoFence = getDuressInGeoFence(
            geoFence.geoFenceId,
            staff,
            duressNotifications
          )
          const assistInGeoFence = getAssistInGeoFence(
            geoFence.geoFenceId,
            staff,
            assistNotifications
          )
          groupIconsToUpdate.push({
            id: geoFence.geoFenceId,
            iconUrl: buildIconURL(
              {
                name: badgesInGroup.length.toString(),
                assetID: 0,
                coordinates: [0, 0],
                type: IconType.Group,
                height: 0,
                showIcon: false,
                isConfigured: false,
                isLowBattery: false,
                hasIssues: false,
                iconScale: 0,
              },
              BadgeSignalStatus.Current,
              duressInGeoFence,
              assistInGeoFence
            ),
          })
        }
      } else {
        const newGroup = createNewGroupConfig(
          geoFence,
          badgesInGroup.length,
          jibestream,
          false,
          getDuressInGeoFence(geoFence.geoFenceId, staff, duressNotifications),
          getAssistInGeoFence(geoFence.geoFenceId, staff, assistNotifications),
          mapId,
          geoFence.geoFenceId === selectedGroupId
        )
        if (newGroup) {
          groupsToCreate.push(newGroup)
        }
      }

      agentsToHide.push({
        trackingIds: badgesInGroup,
        inpixonReference: inpixonReference,
      })
    } else {
      // delete group & show assets that were in it
      if (badgesInGroup.length > 0) {
        trackingIdToAssetKitId(badgesInGroup, inpixonReference).map((x) => {
          const asset = assetKit?.getAssetById(Number(x))
          const assetName = asset?.name || ''
          const matchingStaff = Object.values(staff || []).find(
            (x) => x.displayName === assetName.replace(' ', ' ')
          )
          const assetPulse = asset?._pulseVisible || false
          if (
            assetPulse &&
            matchingStaff !== undefined &&
            groupAsset !== null
          ) {
            const accuracyConfig = getAccuracyConfig(
              [Number(matchingStaff?.signalTypeIds) ?? 0],
              true
            )

            accuracyConfig.confidenceColor = colors.duressBubble

            const iconScale = getIconScale(
              jibestream.control.currentMap.mmPerPixel
            )

            groupsToCreate.push(
              generateAssetConfig({
                assetID: x,
                name: asset.name ?? x,
                coordinates: [
                  Number(asset.position[0]),
                  Number(asset.position[1]),
                ],
                type: IconType.Staff,
                height: 4,
                isLowBattery: isLowBattery(matchingStaff?.isLowBattery),
                showIcon: true,
                image: matchingStaff?.iconUrl,
                iconScale: iconScale,
                map: jibestream.control?.map,
                isConfigured: false,
                isDuress: true,
                hasIssues: false,
                isSelected: selectedGroupId === geoFence.geoFenceId,
                ...accuracyConfig,
              })
            )

            agentsToDelete.push(Number(x))
          } else {
            if (asset?.Id) {
              agentsToShowAccuracy.push({
                assetId: asset.Id,
                signalTypeIds: asset.signalTypeIds ?? [],
              })
            }
            agentsToShow.push(`Asset_${x}`)
            if (matchingStaff?.badgeTelemetry.mapId === mapId) {
              agentsToShow.push(`Staff_${x}`)
            }
          }
        })
      }
      agentsToDelete.push(geoFence.geoFenceId)
    }

    stateGroupsToUpdate[geoFence.geoFenceId] = {
      groupId: geoFence.geoFenceId,
      trackingIds: [...badgesInGroup],
    }
  })

  return {
    groupsToCreate: groupsToCreate,
    stateGroupsToUpdate: stateGroupsToUpdate,
    agentsToHide: agentsToHide,
    groupIconsToUpdate: groupIconsToUpdate,
    agentsToDelete: agentsToDelete,
    agentsToShow: agentsToShow,
    agentsToShowAccuracy: agentsToShowAccuracy,
  }
}
