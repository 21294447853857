import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { deleteAlertAction, putAlertAction } from '../../actions/alerts'
import { setCurrentLocationAction } from '../../actions/currentLocation'
import { clearFormErrorsAction } from '../../actions/forms'
import AlertDetailDrawer from '../../components/AlertList/AlertDetailDrawer'
import AlertList, { AlertRow } from '../../components/AlertList/AlertList'
import AlertListFilterDrawer from '../../components/Filters/AlertListFilterDrawer'
import { PageContent } from '../../components/Layout'
import DeleteConfirmationModal from '../../components/Modals/DeleteConfirmationModal'
import { useReactTableRightDrawer } from '../../components/Tables/useReactTableRightDrawer'
import { QueryFilter } from '../../filters'
import { nullGuid } from '../../helpers'
import {
  useFetchLocations,
  useFetchUserPreferences,
  usePermissionCheck,
} from '../../hooks'
import {
  AlertConfigSteps,
  AlertFilters,
  PermissionsEnum,
  defaultAlertConfigSteps,
} from '../../models'
import { Alert, AlertStatus, Alerts } from '../../models/alert'
import { FetchingStatus } from '../../models/fetchingStatus'
import { PageLayoutState } from '../../models/pageLayout'
import { DataTestIds } from '../../utils/test-utils/dataTestIds'
import { DrawerType } from '../../components/Layout/PageContent'

interface Props {
  alerts: Alerts
  alertsStatus: FetchingStatus | undefined
  alertFilterQuery: QueryFilter<Alert>
  alertFilterState: AlertFilters
  setAlertFilters: (filter: AlertFilters) => void
  filterButtonToggled: boolean | undefined
  setActiveStep: React.Dispatch<React.SetStateAction<number>>
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const AlertListPage = (props: Props): JSX.Element => {
  const {
    alerts,
    alertsStatus,
    alertFilterQuery,
    alertFilterState,
    filterButtonToggled,
    setAlertFilters,
    setActiveStep,
  } = props

  const {
    rightDrawerState,
    selectRow,
    closeDetailDrawer,
    clearSelectedItem,
    resetSelectedRowIndex,
    closeFilterDrawer,
    toggleFilterDrawer,
  } = useReactTableRightDrawer<AlertRow>()
  const {
    currentDrawerType,
    item: selectedAlert,
    tableSelectedRowIndex,
  } = rightDrawerState
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false)
  const dispatch = useDispatch()
  const history = useNavigate()
  const hasWritePermissions = usePermissionCheck([
    PermissionsEnum.AlertAdminWrite,
  ])

  // Set location to user's preferred location
  const { data: userPreferences, status: userPreferencesStatus } =
    useFetchUserPreferences()
  const { data: locations, status: locationsFetchingStatus } =
    useFetchLocations()
  const [locationInitialized, setLocationInitialized] = useState(false)

  const maxHeight = useSelector(
    ({ pageLayout }: { pageLayout: PageLayoutState }) => pageLayout.maxHeight
  )

  useEffect(() => {
    if (
      userPreferencesStatus === FetchingStatus.Success &&
      locationsFetchingStatus === FetchingStatus.Success &&
      userPreferences.locationId &&
      !locationInitialized
    ) {
      const userPreferredLocation = locations[userPreferences.locationId]
      dispatch(setCurrentLocationAction(userPreferredLocation))
      setLocationInitialized(true)
    }
  }, [userPreferencesStatus, locationsFetchingStatus])

  const isFetching = useMemo(() => {
    return alertsStatus === FetchingStatus.Request
  }, [alertsStatus])

  const handleDeleteAlert = useCallback(
    (id: string): void => {
      if (id) {
        dispatch(deleteAlertAction.request(id))
      }
      closeDetailDrawer()
      dispatch(clearFormErrorsAction({ formName: 'alertEdit' }))
      setDeleteConfirmationOpen(false)
    },
    [closeDetailDrawer, dispatch]
  )

  const handleDisableRequest = (): void => {
    if (selectedAlert) {
      selectedAlert.alertStatus =
        selectedAlert.alertStatus === AlertStatus.Enabled
          ? AlertStatus.Disabled
          : AlertStatus.Enabled

      dispatch(
        putAlertAction.request({
          id: selectedAlert.id,
          name: selectedAlert.name,
          description: selectedAlert.description,
          alertTypeId: selectedAlert.alertTypeId,
          alertStatus: selectedAlert.alertStatus,
          triggers: selectedAlert.triggers,
          qualifiers: selectedAlert.qualifiers,
          channels: selectedAlert.channels,
          locationRouters: selectedAlert.locationRouters,
          securityRouters: selectedAlert.securityRouters,
          userRouters: selectedAlert.userRouters,
          alertResolutionId: selectedAlert.alertResolution.id,
          alertResolution: selectedAlert.alertResolution,
        })
      )
    }
  }

  const handleCreateAlert = useCallback(() => {
    setActiveStep(0)
    history(
      `/admin/alerts/${nullGuid}/${
        defaultAlertConfigSteps[AlertConfigSteps[AlertConfigSteps.CONDITIONS]]
          .path
      }`
    )
  }, [history, setActiveStep])

  useEffect(() => {
    if (filterButtonToggled !== undefined) {
      toggleFilterDrawer()
    }
  }, [filterButtonToggled, toggleFilterDrawer])

  return (
    <PageContent
      maxHeight={maxHeight}
      currentRightDrawerType={currentDrawerType}
      content={
        <div data-testid={DataTestIds.alertAdminPageAlertListContainer}>
          <AlertList
            alerts={Object.values(alerts)}
            isFetching={isFetching}
            onEditAlert={() => setActiveStep(0)}
            canEdit={hasWritePermissions}
            onCreateAlert={handleCreateAlert}
            tableRowSelectionOptions={{
              selectedAlert,
              selectedRowIndex: tableSelectedRowIndex,
              onSelectAlert: selectRow,
              clearSelectedAlert: clearSelectedItem,
              resetSelectedRowIndex,
            }}
            alertFilterQuery={alertFilterQuery}
          />
          {deleteConfirmationOpen && selectedAlert && (
            <div>
              <DeleteConfirmationModal
                handleClose={() => setDeleteConfirmationOpen(false)}
                handleSubmitClick={handleDeleteAlert}
                itemId={selectedAlert.id}
                itemName={selectedAlert.name}
                itemType='Alert'
                includeDescription={false}
              />
            </div>
          )}
        </div>
      }
      rightDrawer={
        currentDrawerType === DrawerType.DetailDrawer && selectedAlert ? (
          <AlertDetailDrawer
            canEdit={hasWritePermissions}
            selectedAlert={selectedAlert}
            onClose={closeDetailDrawer}
            onDeleteAlert={() => setDeleteConfirmationOpen(true)}
            onDisableAlert={handleDisableRequest}
          />
        ) : currentDrawerType === DrawerType.FilterDrawer ? (
          <AlertListFilterDrawer
            headerProps={{
              onCloseDrawer: closeFilterDrawer,
              clearFilters: () => setAlertFilters({}),
            }}
            onFilterChanged={setAlertFilters}
            alertFilterState={alertFilterState}
          />
        ) : null
      }
    />
  )
}

export default AlertListPage
