import React, { CSSProperties, ReactNode } from 'react'

export interface ConfirmationMessageStyles {
  additionalMessage?: CSSProperties
}

interface Props {
  primaryMessage: ReactNode
  additionalMessage?: ReactNode
  styleOverride?: ConfirmationMessageStyles
}

const ActionConfirmationMessage = (props: Props) => {
  const { primaryMessage, additionalMessage, styleOverride } = props

  return (
    <>
      {primaryMessage}
      {!additionalMessage && <>&nbsp; This action cannot be undone. </>}
      {additionalMessage && (
        <div
          style={
            styleOverride && styleOverride.additionalMessage
              ? styleOverride.additionalMessage
              : {
                  padding: '20px 0',
                }
          }
        >
          {additionalMessage}
        </div>
      )}
    </>
  )
}

export default ActionConfirmationMessage
