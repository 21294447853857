import { createAction } from 'typesafe-actions'
import {
  AlertFilters,
  BadgeTrafficFilters,
  DeviceFilters,
  UserFilters,
} from '../models'
import { AssetFilters, SearchFilter, StaffFilters } from '../models/filters'

export const setGlobalUserFiltersAction =
  createAction('SET_USER_FILTERS')<UserFilters>()

export const setGlobalAssetFiltersAction =
  createAction('SET_ASSET_FILTERS')<AssetFilters>()

export const setGlobalStaffFiltersAction =
  createAction('SET_STAFF_FILTERS')<StaffFilters>()

export const setGlobalDeviceFiltersAction =
  createAction('SET_DEVICE_FILTERS')<DeviceFilters>()

export const setGlobalBadgeTrafficFiltersAction = createAction(
  'SET_BADGE_TRAFFIC_FILTERS'
)<BadgeTrafficFilters>()

export const setGlobalSearchFilterAction =
  createAction('SET_SEARCH_FILTER')<SearchFilter>()

export const setGlobalAlertFiltersAction =
  createAction('SET_ALERT_FILTERS')<AlertFilters>()
