import { BaseService } from './baseService'
import { LocationGeofence } from '../models/locationGeofence'
import { GeofenceExport } from '../models/geofenceExport'

export class LocationGeofenceApi extends BaseService {
  constructor() {
    super({ entityType: 'locationGeofences' })
  }

  route = 'geofences'
  locRoute = 'locations'

  async getAllLocationGeofences(
    locationGuid: string
  ): Promise<LocationGeofence[]> {
    return await this.get<LocationGeofence[]>(
      `${this.locRoute}/${locationGuid}/${this.route}`
    )
  }

  async postAssociateGeofences(
    geofenceExport: GeofenceExport
  ): Promise<LocationGeofence[]> {
    return await this.post<LocationGeofence[]>(
      `${this.locRoute}/${geofenceExport.locationGuid}/${this.route}/Associate`,
      geofenceExport.geofenceExport
    )
  }
}
