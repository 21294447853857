import { getAllHardwareAction } from '../../actions/hardware'
import { HardwareRecords, HardwareState } from '../../models'
import { EntityResult, useEntities } from '../useEntities'

const useFetchHardware = (): EntityResult<HardwareRecords> =>
  useEntities<HardwareState, HardwareRecords>(
    () => getAllHardwareAction.request(),
    (state: any) => state.hardware // eslint-disable-line @typescript-eslint/no-explicit-any
  )

export { useFetchHardware }
