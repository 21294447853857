import { getType } from 'typesafe-actions'
import { OperationsFilterAction } from 'v2/actions'
import { setOperationsUserFiltersAction } from 'v2/actions/operationsFilters'
import { OperationsFiltersState } from 'v2/models/operationsFilters'

const initialState: OperationsFiltersState = {
  operationsUsers: {
    selectedRoles: [],
  },
}

const operationsFilterReducer = (
  state = initialState,
  action: OperationsFilterAction
): OperationsFiltersState => {
  switch (action.type) {
    case getType(setOperationsUserFiltersAction):
      return {
        ...state,
        operationsUsers: action.payload,
      }
    default:
      return state
  }
}

export default operationsFilterReducer
