import { combineEpics, Epic } from 'redux-observable'
import RootAction from '../actions'
import { RootState } from '../reducers'

import {
  AdUsersEpics,
  CreateEventsEpics,
  ImportsAssetsEpics,
  ImportsBadgesEpics,
  ImportsStaffEpics,
  ImportsUsersEpics,
  ConfigureSensorsEpics,
  JibestreamEpics,
  OperationsPermissionsEpics,
  OperationsRolesEpics,
  OperationsUsersEpics,
  TenantsEpics,
  TenantTagNumbersEpics,
} from 'v2/epics'
import commonEpics from '@midmarkrtls/common/epics'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RootEpic = Epic<RootAction, RootAction, RootState, any>

export default combineEpics(
  ...commonEpics,
  ...Object.values(ConfigureSensorsEpics),
  ...Object.values(CreateEventsEpics),
  // v2
  ...Object.values(AdUsersEpics),
  ...Object.values(ImportsAssetsEpics),
  ...Object.values(ImportsBadgesEpics),
  ...Object.values(ImportsStaffEpics),
  ...Object.values(ImportsUsersEpics),
  ...Object.values(JibestreamEpics),
  ...Object.values(OperationsPermissionsEpics),
  ...Object.values(OperationsRolesEpics),
  ...Object.values(OperationsUsersEpics),
  ...Object.values(TenantsEpics),
  ...Object.values(TenantTagNumbersEpics)
)
