import { getType } from 'typesafe-actions'
import { BadgeTelemetryState, BadgeTelemetryStore } from '../models'
import { setBadgeTelemetryAction } from '../actions/badgeTelemetry'
import { BadgeTelemetryAction } from '../actions'
const initialState: BadgeTelemetryStore = {
  badgeTelemetry: [],
}

/**
 * @deprecated
 */
export default (
  state: BadgeTelemetryStore = initialState,
  action: BadgeTelemetryAction
): BadgeTelemetryState => {
  switch (action.type) {
    case getType(setBadgeTelemetryAction):
      return {
        ...state,
        badgeTelemetry: action.payload,
      }
    default:
      return state
  }
}
