import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { stringToNumber } from '../../helpers'
import { focusView, hideLayers } from '../../helpers/inpixon'
import { useQueryParams } from '../../hooks'
import {
  useAssetKit,
  useInpixonContext,
  useJibestream,
  useJibestreamGeofenceKit,
  useMapPresets,
} from '../../hooks/inpixon'
import { AssetInstance, MapPresets, SideNavState } from '../../models'
import { DrawerType } from '../Layout/PageContent'
import LoadingIndicator from '../LoadingIndicator'
import { config } from './inpixonConfig'

interface Props {
  venueId: number
  mapPresets: MapPresets
  mapId: number | undefined
  mapIsLoaded: boolean
  setMapLoaded: (loaded: boolean) => void
  onMapChanged: (mapId: number) => void
  handleAssetClickDelegate: (asset: AssetInstance) => void
  currentRightDrawerType?: DrawerType
}

const InpixonMap: React.FC<Props> = ({
  venueId,
  mapPresets,
  mapId,
  mapIsLoaded,
  setMapLoaded,
  onMapChanged,
  handleAssetClickDelegate,
  currentRightDrawerType,
}: Props) => {
  const [mapInitialized, setMapInitialized] = useState(false)
  const { badgeId, x, y, zoom } = useQueryParams()
  const { setJibestream, setAssetKit, setGeofenceKit, setGeofenceInstances } =
    useInpixonContext()

  const onMapShow = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (jibestream: any): void => {
      if (!mapInitialized) {
        const hiddenLayers = [
          'CustomArtLayer-1',
          'CustomArtLayer-2',
          'CustomArtLayer-3',
          'CustomArtLayer-4',
          'lBox',
        ]
        jibestream.control.setMaxScale(30)
        jibestream.control.currentMapView?.guaranteeMapLayer('Geofences')
        jibestream.control.currentMapView?.guaranteeMapLayer('Moving-Objects')

        hideLayers(jibestream.control, hiddenLayers)
        focusView(jibestream.control, 745, 940, 2, 4.8, 0)
        setMapInitialized(true)
      }

      if (mapId !== jibestream.control.currentMap.id) {
        setMapLoaded(false)
        onMapChanged(jibestream.control.currentMap.id)
        setMapLoaded(true)
      } else if (!mapIsLoaded) {
        setMapLoaded(true)
      }
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const jibestream = useJibestream({
    config,
    venueId,
    mapId,
    onMapShow,
  })

  const assetKit = useAssetKit(jibestream?.control, mapIsLoaded)

  const { geofenceKit, geofenceInstances } = useJibestreamGeofenceKit(
    jibestream.control,
    jibestream.core,
    mapId
  )

  useEffect(() => {
    setJibestream(jibestream)
  }, [jibestream, setJibestream])

  useEffect(() => {
    setAssetKit(assetKit)
  }, [assetKit, setAssetKit])

  useEffect(() => {
    setGeofenceKit(geofenceKit)
  }, [geofenceKit, setGeofenceKit])

  useEffect(() => {
    setGeofenceInstances(geofenceInstances)
  }, [geofenceInstances, setGeofenceInstances])

  useMapPresets(
    jibestream?.control,
    assetKit,
    mapPresets,
    badgeId,
    stringToNumber(x),
    stringToNumber(y),
    stringToNumber(zoom)
  )

  useEffect(() => {
    if (assetKit) {
      const handler = (item: any) => {
        const asset = assetKit?.getAssetById(item.id)
        if (asset) {
          handleAssetClickDelegate(asset)
        }
      }

      // enableAssetTap registers a new callback in the tapHandlers array
      // by default, the assetKit comes with two tapHandlers already enabled
      // any tapHandlers > 2 are callbacks we added
      const tapEventsAdded = (): boolean =>
        Boolean(assetKit?._control.stage.events.tapHandlers.length > 2)

      // only register our click handler once, remove old one, then add new one
      if (tapEventsAdded()) {
        assetKit.disableAssetTap(handler)
      }

      assetKit.enableAssetTap(handler)
    }
  }, [assetKit, handleAssetClickDelegate])

  const isSideNavOpen = useSelector(
    ({ sideNav }: { sideNav: SideNavState }) => sideNav.isOpen
  )

  useEffect(() => {
    jibestream?.control?.stage?.resize()
  }, [isSideNavOpen, currentRightDrawerType, jibestream?.control?.stage])

  const cleanup = () => {
    if (assetKit !== undefined) {
      if (assetKit) {
        assetKit?._getAllAssets()?.forEach((asset) => {
          assetKit?.removeAsset(asset)
        })
      }
    }
  }

  useEffect(() => {
    cleanup()
  }, [])

  return (
    <div>
      {!mapIsLoaded && (
        <div
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            height: '100%',
            width: '100%',
          }}
        >
          <LoadingIndicator color='primary' />
        </div>
      )}
    </div>
  )
}

export { InpixonMap }
