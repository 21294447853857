import { AssetKitInstance, FetchingStatus } from '../../models'
import { GeofenceKitInstance } from '../../models/jibestream'
import { BaseBadgeTelemetryMessage } from '../../models/telemetry'

export function shouldCalculateInitialMapRender(
  isAssetsByLocationFetched: boolean,
  isStaffsByLocationFetched: boolean,
  featureFlagFetchingStatus: FetchingStatus | undefined,
  geofencesFetchingStatus: FetchingStatus | undefined,
  staffEventFetchingStatus: FetchingStatus | undefined,
  mapId: number | undefined,
  filteredDuressLoaded: boolean,
  isInitialLoaded: boolean
): boolean {
  return (
    !(
      featureFlagFetchingStatus === FetchingStatus.Request ||
      geofencesFetchingStatus === FetchingStatus.Request ||
      staffEventFetchingStatus === FetchingStatus.Request
    ) &&
    filteredDuressLoaded &&
    isAssetsByLocationFetched &&
    isStaffsByLocationFetched &&
    mapId !== undefined &&
    !isInitialLoaded
  )
}

export function shouldCalculateLiveTelemetryUpdates(
  baseTelemetry: BaseBadgeTelemetryMessage[],
  enabled: boolean,
  mapId: number | undefined,
  isInitialLoaded: boolean
): boolean {
  return (
    baseTelemetry.length > 0 &&
    enabled &&
    mapId !== undefined &&
    isInitialLoaded
  )
}

export function isInpixonLoaded(
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamCore: any,
  assetKit: AssetKitInstance | undefined,
  jibestreamGeofenceKit: GeofenceKitInstance | undefined
): boolean {
  return (
    !!jibestreamController &&
    !!jibestreamCore &&
    !!assetKit &&
    !!jibestreamGeofenceKit
  )
}
