import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { StaffSubTypeAction } from '../actions'
import { getAllStaffSubTypesAction } from '../actions/staffSubTypes'
import { StaffSubTypesApi } from '../middleware'
import { StaffSubTypesState } from '../models'

type StaffSubTypeEpic = Epic<
  StaffSubTypeAction,
  StaffSubTypeAction,
  StaffSubTypesState,
  { staffSubTypesApi: StaffSubTypesApi }
>

export const getAllStaffSubTypesEpic: StaffSubTypeEpic = (
  action$,
  state,
  { staffSubTypesApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllStaffSubTypesAction.request)),
    mergeMap((action) =>
      from(staffSubTypesApi.getAllStaffSubTypes()).pipe(
        map((staffSub) => getAllStaffSubTypesAction.success(staffSub)),
        catchError((error) => of(getAllStaffSubTypesAction.failure(error)))
      )
    )
  )
}
