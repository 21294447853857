import configureStore from '@midmarkrtls/common/store/configureStore'
import { Persistor, persistStore } from 'redux-persist'
import rootEpic from '../epics'
import rootReducer from '../reducers'
import setupMiddleware from './setupMiddleware'
import { Store } from 'redux'

const middlewares = setupMiddleware()

const AppStore = configureStore(rootReducer, middlewares, rootEpic)

let persistor = persistStore(AppStore)

export function getPersistor(store?: Store): Persistor {
  if (!persistor) {
    persistor = persistStore(store ?? AppStore)
  }
  return persistor
}

export default AppStore
