import React, { useEffect } from 'react'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Divider, Typography } from '@mui/material'
import { Locations, LocationType, SimpleLocation } from '../../models'
import { LocationListItem } from './LocationListItem'
import {
  getLocationAndChildren,
  sortSimpleLocationsByDisplayOrderOrName,
} from '../../helpers'

const drawerOpenedWidth = 310
const headerHeight = 86

interface Props {
  locations: Locations
  locationSelected: (locationId: string) => void
  selectedLocationId?: string
}

const LocationList: React.FC<Props> = (props: Props) => {
  const { locations, locationSelected, selectedLocationId } = props

  const isFloorSelected =
    locations[selectedLocationId ?? '']?.locationType === LocationType.Floor

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      container: {
        position: 'absolute',
        height: '100%',
        width: drawerOpenedWidth,
        backgroundColor: '#fff',
        boxShadow:
          '0 0 3px 1px rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.16)',
        '@media print': {
          display: 'none',
        },
      },
      headerContainer: {
        backgroundColor: '#fff',
        width: drawerOpenedWidth,
        zIndex: 100,
        position: 'absolute',
      },
      header: {
        fontSize: 24,
        margin: '24px 22px',
      },
      listScrollContainer: {
        height: '100%',
      },
      listContainer: {
        position: 'absolute',
        height: isFloorSelected ? 'calc(100% - 86px)' : '100%',
        top: isFloorSelected ? headerHeight : 0,
        overflowY: 'auto',
      },
      list: {
        paddingTop: isFloorSelected ? 0 : headerHeight,
        height: '100%',
      },
    })
  )

  const classes = useStyles()
  const [simpleLocations, setSimpleLocations] = React.useState<
    SimpleLocation[]
  >([])

  useEffect(() => {
    const newSimpleLocations = Object.values(locations)
      .filter((x) => x.locationType == LocationType.BuildingGroup)
      .map((x) => getLocationAndChildren(x, locations))

    setSimpleLocations(newSimpleLocations)
  }, [locations])

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <Typography className={classes.header}>
          Location Administration
        </Typography>
        <Divider />
      </div>
      <div className={classes.listScrollContainer}>
        <div className={classes.listContainer}>
          <div className={classes.list}>
            {sortSimpleLocationsByDisplayOrderOrName(simpleLocations).map(
              (location, index) => {
                return (
                  <LocationListItem
                    id={location.id}
                    key={location.id}
                    location={location}
                    locationSelected={locationSelected}
                    selectedLocationId={selectedLocationId}
                    listIndex={index}
                  />
                )
              }
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export { LocationList }
