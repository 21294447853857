import { FetchingState } from '../interfaces/fetchingState'

export enum LocationType {
  Root = 1,
  IDN = 2,
  BuildingGroup = 3,
  Building = 4,
  Floor = 5,
  Room = 6,
  Care = 7,
}

export enum LocationStatus {
  Soiled,
  Clean,
  ' ',
}

export interface Location {
  id: string
  name: string
  parentId: string | null
  level: number
  locationType: LocationType
  slug?: string
  isPrivate: boolean | false
  isPatientCareArea?: boolean | false
  isPointOfCareArea?: boolean | false
  subUnitId?: string
  subUnitName?: string
  unitId?: string
  unitName?: string
  utilityStatus?: string
  displayOrder?: number
  children?: Location[]
}

export interface SimpleLocation {
  id: string
  name: string
  unitId?: string
  locationType: LocationType
  displayOrder?: number
  subLocations: SimpleLocation[]
}

export interface HierarchicalLocation {
  id: string
  name: string
  parentId: string | null
  level: number
  locationType: LocationType
  slug?: string
  isPrivate: boolean | false
  isPatientCareArea?: boolean | false
  isPointOfCareArea?: boolean | false
  subUnitId?: string
  subUnitName?: string
  unitId?: string
  unitName?: string
  utilityStatus?: string
  parent?: HierarchicalLocation
}

export interface Locations {
  [locationId: string]: Location
}

export interface SelectableLocations {
  idn?: Location
}

export type SelectableLocationsState = Readonly<
  FetchingState<SelectableLocations>
>

export type LocationsState = Readonly<FetchingState<Locations>>
