import { getType } from 'typesafe-actions'
import { AdUserAction } from '../actions'
import { getAllAdUsersAction, getAllB2CUsersAction } from '../actions/adUsers'
import { mergeRecords } from '@midmarkrtls/common/utils'
import { UsersState, User, Users } from '@midmarkrtls/common/models/users'
import { FetchingStatus } from '@midmarkrtls/common/models/fetchingStatus'
const initialState: UsersState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

const key = 'id'
const adUsersReducer = (
  state: UsersState = initialState,
  action: AdUserAction
): UsersState => {
  switch (action.type) {
    case getType(getAllAdUsersAction.success):
    case getType(getAllB2CUsersAction.success):
      return {
        data: mergeRecords<Users, User, typeof key>(
          state.data,
          action.payload,
          key
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getAllAdUsersAction.request):
    case getType(getAllB2CUsersAction.request):
      return {
        ...state,
        status: FetchingStatus.Request,
      }
    case getType(getAllAdUsersAction.failure):
    case getType(getAllB2CUsersAction.failure):
      return {
        ...state,
        status: FetchingStatus.Failure,
      }

    default:
      return state
  }
}

export default adUsersReducer
