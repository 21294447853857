import {
  DialogActions,
  DialogContent,
  IconButton,
  Modal,
  Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Close } from '@mui/icons-material'
import React, { ReactNode } from 'react'
import { colors, tableColors } from '../../styles'
import { DataTestIds } from '../../utils/test-utils/dataTestIds'
import DialogActionButtons from '../Dialogs/DialogActionButtons'
import { DialogActionButtonType } from '../Dialogs/DialogActionButtonType'
import ActionConfirmationForm, {
  ConfirmationFormInput,
} from '../Forms/ActionConfirmationForm'
import { FlexBox } from '../Layout'

interface Props {
  isOpen: boolean
  handleClose: () => void
  title: string
  content: ReactNode
  leftActionButtons: DialogActionButtonType[]
  rightActionButtons: DialogActionButtonType[]
  confirmationInput?: ConfirmationInput
  disableEnterButtonFormSubmission?: boolean
}

export interface ConfirmationInput extends ConfirmationFormInput {
  setIsConfirmed: (isConfirmed: boolean) => void
}

const useDialogContentStyles = makeStyles((theme) => ({
  root: {
    padding: '0 0 20px 0',
  },
}))

const useStyles = makeStyles((theme) => ({
  paper: (props: Props) => ({
    position: 'absolute',
    width: 450,
    backgroundColor: theme.palette.background.paper,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '4px',
    outline: 'none',
    border: `1px solid ${tableColors.borderColor}`,
  }),
}))

const useDialogStyles = makeStyles((theme) => ({
  paper: {
    padding: '16px',
  },
}))

const ConfirmationModal = (props: Props) => {
  const {
    isOpen,
    handleClose,
    title,
    content,
    leftActionButtons,
    rightActionButtons,
    confirmationInput,
    disableEnterButtonFormSubmission = false,
  } = props

  const customHeader = (
    <FlexBox
      style={{
        paddingBottom: 20,
      }}
      flex='basis'
      layout='row'
      layoutAlign={{ item: 'center', content: 'center', main: 'space-between' }}
    >
      <Typography
        variant='h5'
        style={{
          fontWeight: 'normal',
          color: colors.darkSurface,
          fontSize: 24,
        }}
      >
        {title}
      </Typography>
      <IconButton
        aria-label='Close'
        onClick={handleClose}
        style={{ padding: 0 }}
        disableRipple
        disableFocusRipple
        disableTouchRipple
        size='large'
      >
        <Close />
      </IconButton>
    </FlexBox>
  )

  const classes = useStyles(props)
  const dialogContainerClasses = useDialogStyles(props)
  const dialogContentClasses = useDialogContentStyles()

  return (
    <Modal // `disableBackdropClick` is removed by codemod.
      // You can find more details about this breaking change in [the migration guide](https://mui.com/material-ui/migration/v5-component-changes/#modal)

      disableRestoreFocus
      open={isOpen}
      onClose={() => {}}
      aria-labelledby='parent-modal-title'
      aria-describedby='parent-modal-description'
    >
      <div
        className={`${classes?.paper} ${dialogContainerClasses?.paper}`}
        data-testid={DataTestIds.actionConfirmationModal}
      >
        {customHeader}
        <DialogContent classes={dialogContentClasses}>
          {content}
          {confirmationInput && (
            <ActionConfirmationForm
              inputLabel={confirmationInput.inputLabel}
              confirmationKeyword={confirmationInput.keyword}
              setIsConfirmed={confirmationInput.setIsConfirmed}
              disableEnterButtonFormSubmission={
                disableEnterButtonFormSubmission
              }
            />
          )}
        </DialogContent>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {leftActionButtons && (
            <DialogActions
              style={{
                padding: 0,
              }}
            >
              <DialogActionButtons actionButtons={leftActionButtons} />
            </DialogActions>
          )}

          {rightActionButtons && (
            <DialogActions
              style={{
                padding: 0,
              }}
            >
              <DialogActionButtons actionButtons={rightActionButtons} />
            </DialogActions>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmationModal
