import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  useFetchStaffByLocation,
  useFetchUserPreferences,
  useSearchAndFilters,
} from '.'
import { FeatureFlagLabels } from '../constants'
import { batteryStatusSearch } from '../filters'
import { createStaffRow, isCurrentLocationOrChild } from '../helpers'
import { localMomentFormatted } from '../helpers/date'
import {
  BadgeCurrentLocation,
  FetchingStatus,
  LocationType,
  SignalStatus,
  StaffEvent,
} from '../models'
import { StaffRow } from '../models/staffRow'
import { useFetchFeatureFlags } from './entities/useFetchFeatureFlags'
import { useFetchGeofencesByLocation } from './entities/useFetchGeofencesByLocation'
import { useFetchLocations } from './entities/useFetchLocations'
import { useFetchVenues } from './entities/useFetchVenues'
import { useFetchWatchlist } from './entities/useFetchWatchlist'
import useDeprecatedStaffFilters from './filters/useDeprecatedStaffFilters'
import { useAuth } from './useAuth'
import useBadgeLocationMonitor from './useBadgeLocationMonitor'
import { useCurrentLocation } from './useCurrentLocation'
import useCurrentUnitLocations from './useCurrentUnitLocations'
import { useListTelemetry } from './useListTelemetry'

const useRealTimeStaffTableData = (
  refreshTable: boolean,
  activeDuressEvents: StaffEvent[],
  activeAssistEvents: StaffEvent[]
) => {
  const { user } = useAuth()
  const { data: watchlist } = useFetchWatchlist(user.id)
  const { data: venues } = useFetchVenues()
  const { data: locations } = useFetchLocations()
  const { currentLocation } = useCurrentLocation()
  const { filter: staffFilter } = useDeprecatedStaffFilters<StaffRow>()
  const { data: userPreferences } = useFetchUserPreferences()

  const { data: featureFlags } = useFetchFeatureFlags(
    FeatureFlagLabels.Navigation,
    true
  )
  const { staffsByLocation, staffsFetchingStatus: staffStatus } =
    useFetchStaffByLocation(SignalStatus.Recent, refreshTable)

  const [staffRows, setStaffRows] = useState<StaffRow[]>([])
  const [lastliveTelemetry, setLastLiveTelemetry] = useState<Date>(new Date())
  const { currentUnitLocations, allUnits } = useCurrentUnitLocations()

  const formatDate = (date?: Date, format?: string): string => {
    return date ? localMomentFormatted(date, format ?? 'MM/DD/YYYY') : ''
  }

  const { data: geofences, status: geofenceStatus } =
    useFetchGeofencesByLocation(
      Object.values(locations).find((x) => x.level === LocationType.Root)?.id ??
        ''
    )
  //////////////////////////////////// STAFF TABLE DATA /////////////////////////////

  const duressStaffShow =
    Object.values(featureFlags).filter(
      (x) => x.name.includes('/duress/staff') && x.enabled === true
    ).length > 0

  const {
    badgeLocations: staffBadgeLocations,
    lastLiveTelemetry: staffLastLiveTelemetry,
    badgeLocationsChangedAt,
  } = useListTelemetry(
    locations,
    geofences,
    venues,
    [],
    staffsByLocation ?? [],
    FetchingStatus.Success,
    staffStatus || FetchingStatus.Success,
    activeDuressEvents,
    activeAssistEvents
  )

  const allStaffBadges = useMemo(() => {
    return staffsByLocation
      ? staffsByLocation
          .filter((staff) => !!staff.badgeIds.length)
          .map((staff) => staff.badgeIds[0])
      : []
  }, [staffsByLocation])

  const shouldMonitorBadgeLocation = useCallback(
    (badgeLocation: BadgeCurrentLocation): boolean => {
      return allStaffBadges.includes(badgeLocation.badgeId)
    },
    [allStaffBadges]
  )

  const { state: staffBadgeLocationsState } = useBadgeLocationMonitor(
    staffBadgeLocations ?? [],
    shouldMonitorBadgeLocation
  )

  useEffect(() => {
    if (
      staffLastLiveTelemetry > lastliveTelemetry &&
      lastliveTelemetry.toString() !== staffLastLiveTelemetry.toString()
    ) {
      setLastLiveTelemetry(staffLastLiveTelemetry)
    }
  }, [staffLastLiveTelemetry, lastliveTelemetry])

  const watchlistItems = useMemo(
    () => (watchlist ? Object.values(watchlist) : []),
    [watchlist]
  )

  useEffect(() => {
    if (
      staffsByLocation.length > 0 &&
      Object.values(geofences).length &&
      Object.values(locations).length
    ) {
      const rows = staffsByLocation
        .map((staff) =>
          createStaffRow(
            staff,
            staffBadgeLocationsState,
            geofences,
            watchlistItems,
            duressStaffShow,
            activeDuressEvents,
            activeAssistEvents,
            locations,
            userPreferences
          )
        )
        .filter((staff) => {
          return (
            allUnits ||
            currentUnitLocations?.includes(staff?.roomLocation?.id ?? '')
          )
        })
      setStaffRows(rows)
    }
  }, [
    staffsByLocation,
    staffBadgeLocationsState,
    locations,
    geofences,
    watchlist,
    activeDuressEvents,
    currentLocation?.id,
    watchlistItems,
    currentUnitLocations,
    duressStaffShow,
    activeAssistEvents,
    venues,
    userPreferences,
    allUnits,
  ])

  const staffTableData = useSearchAndFilters(
    staffRows.filter((x) => {
      return isCurrentLocationOrChild(
        x?.floorLocation?.id ?? '',
        currentLocation,
        locations
      )
    }),
    staffFilter.criteria,
    [
      'agentGuid',
      'badgeIds',
      'staffTypeId',
      'iconUrl',
      'badgeTelemetry',
      'icon',
      'badgeLocation',
      'signalTypeIds',
    ],
    {
      tagBatteryChangeDate: 'datetime',
      isLowBattery: batteryStatusSearch,
    }
  )

  return {
    staffTableData,
    lastliveTelemetry,
    badgeLocations: staffBadgeLocations,
    badgeLocationsChangedAt,
  }
}

export default useRealTimeStaffTableData
