import React, { useEffect, useState } from 'react'
import MenuItem from '@mui/material/MenuItem'
import { Box, IconButton, MenuList, Typography } from '@mui/material'
import { Location, LocationType } from '../../models'
import { Close } from '@mui/icons-material'
import { sortLocationsByDisplayOrderOrName } from '../../helpers'

export class LocationMenuOption {
  id: string
  name: string
  location?: Location
  renderFunc: () => React.ReactNode
  constructor(id: string, name: string, location?: Location) {
    this.id = id
    this.name = name
    this.location = location
    this.renderFunc = () => {
      return <span>{this.name}</span>
    }
  }
}

interface Props {
  options?: Location[]
  selectedLocation?: Location
  onChange: (selection: Location | undefined) => void
  isDeselectable?: boolean
}

const LocationMenuList = (props: Props): JSX.Element => {
  const {
    options = [],
    selectedLocation: selectedLocationExternal,
    onChange: onChangeExternal,
    isDeselectable,
  } = props

  const [selectedOption, setSelectedOption] = useState<Location | undefined>(
    selectedLocationExternal
  )

  // There are certain instances when switching buildings when on the map that the
  // floor is sent down in selectedLocationExternal, but not getting set to the
  // selectedOption, which means the floor isn't getting highlighted.
  // This fixes that issue. (Only for the floor component).
  // Tim Groven - 12/14/2022 (Bug #23894) (https://versuscarina.visualstudio.com/Carina/_workitems/edit/23894)
  if (
    selectedLocationExternal &&
    selectedLocationExternal.locationType == LocationType.Floor
  ) {
    options.map((option) => {
      if (
        option.id == selectedLocationExternal?.id &&
        typeof selectedOption === 'undefined'
      ) {
        setSelectedOption(option)
      }
    })
  }

  useEffect(() => {
    setSelectedOption(selectedLocationExternal)
  }, [selectedLocationExternal])

  useEffect(() => {
    if (!options.find((o) => o.id === selectedOption?.id)) {
      onChange()
    }
  }, [options, selectedOption])

  const onChange = (option?: Location | undefined) => {
    setSelectedOption(option)
    onChangeExternal(option)
  }

  return (
    <Box style={{ height: '161px', overflow: 'auto' }}>
      <MenuList style={{ padding: 0 }}>
        {sortLocationsByDisplayOrderOrName(options)?.map((option) => (
          <MenuItem
            key={option.id}
            value={option.id}
            selected={selectedOption?.id === option.id}
            onClick={() => onChange(option)}
            disableGutters
            disableRipple
            style={{
              padding: '0px 0px 0px 8px',
              height: '40px',
              backgroundColor:
                selectedOption?.id === option.id ? '#bfe1f1' : 'inherit',
              cursor: selectedOption?.id !== option.id ? 'pointer' : 'inherit',
            }}
          >
            <Typography
              variant='body2'
              noWrap={false}
              style={{ flexGrow: 1, whiteSpace: 'normal' }}
            >
              {option.name}
            </Typography>
            {selectedOption?.id === option.id && isDeselectable && (
              <IconButton
                style={{ marginRight: '8px' }}
                onClick={(event) => {
                  event.stopPropagation()
                  onChange()
                }}
                size='small'
              >
                <Close fontSize='small' />
              </IconButton>
            )}
          </MenuItem>
        ))}
      </MenuList>
    </Box>
  )
}

export default LocationMenuList
