import React, { useMemo } from 'react'
import { useFetchRoles, useFetchUsers } from '../../hooks'
import { allRoles, UserRoles } from '../../models'
import { AlertActiveConfig, AlertTypes } from '../../models/alert'
import { DetailedItem } from '../../models/DetailPaneItems'
import DetailsList from '../DetailsList/DetailsList'

interface Props {
  alert: AlertActiveConfig
  isLoading?: boolean
  headerStylesOverrides?: any
  listItemStylesOverrides?: any
}

const AlertResolutionDetail = (props: Props) => {
  const {
    alert,
    isLoading = false,
    headerStylesOverrides,
    listItemStylesOverrides,
  } = props

  const { data: roles } = useFetchRoles()
  const { data: users } = useFetchUsers()

  const resolutionPermissionItems = useMemo(() => {
    if (!alert?.alertResolution) {
      return []
    } else {
      const results: any[] = []

      if (
        alert.alertResolution?.resolverRoles?.length &&
        alert.alertType?.id !== AlertTypes.AssetAlert
      ) {
        alert.alertResolution?.resolverRoles?.map((x, index) => {
          const role = Object.values(roles).find((y) => y.id == x.roleId)
          results.push({
            field: index === 0 ? 'User Role' : '',
            value: role?.name,
          })
        })
      } else if (alert.alertType?.id !== AlertTypes.AssetAlert) {
        results.push({
          field: 'User Role',
          value:
            alert?.alertType?.id !== AlertTypes.StaffAssist
              ? Object.values(roles)
                  .filter(
                    (r) =>
                      r.id === UserRoles.SecurityAdmin ||
                      r.id === UserRoles.SecurityUser
                  )
                  .map((x) => x.name)
                  .join(' ,')
              : allRoles.name,
        })
      } else if (alert.alertType?.id === AlertTypes.AssetAlert) {
        results.push({
          field: 'Routing',
          value: 'Not applicable',
        })
      }

      return results
    }
  }, [alert, roles, users])

  const resolutionAutomaticExpirationItem = useMemo(() => {
    return [
      {
        field: 'Expiration',
        value: `${alert?.alertResolution?.automaticResolveTimeoutInMinutes} Minutes`,
      },
    ]
  }, [alert?.alertResolution?.automaticResolveTimeoutInMinutes])

  const alertResolutionDetailItems: DetailedItem[] = []
  if (alert.alertType?.id !== AlertTypes.AssetAlert) {
    alertResolutionDetailItems.push(
      {
        header: 'ALERT RESOLVE PERMISSIONS',
        items: resolutionPermissionItems,
      },
      {
        header: 'AUTOMATIC EXPIRATION',
        items: resolutionAutomaticExpirationItem,
      }
    )
  } else if (alert.alertType?.id === AlertTypes.AssetAlert) {
    alertResolutionDetailItems.push({
      header: '',
      items: resolutionPermissionItems,
    })
  }

  return (
    <DetailsList
      detailedItems={alertResolutionDetailItems}
      narrowRows={true}
      headerStylesOverrides={headerStylesOverrides}
      listItemStylesOverrides={listItemStylesOverrides}
      isLoading={isLoading}
    />
  )
}

export default AlertResolutionDetail
