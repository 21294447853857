import { createSelector } from 'reselect'
import { AgentEventViewsByUnitsAndLocationState } from '../models'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const agentEventViewsState = (
  state: any
): AgentEventViewsByUnitsAndLocationState => state.agentEventViews

export const selectAgentEventViewsState = createSelector(
  agentEventViewsState,
  (agentEventViewsState) => agentEventViewsState
)
