import { BadgeDestinationAction } from '../actions'
import { BadgeDestination } from '@midmarkrtls/common/models'
import {
  setBadgeDestinationAction,
  setBadgesDestinationsAction,
} from '../actions/badgeDestination'
import { getType } from 'typesafe-actions'

export type BadgeDestinationState = Readonly<BadgeDestination>

const badgeDestinationReducer = (
  state: BadgeDestinationState = {},
  action: BadgeDestinationAction
): BadgeDestinationState => {
  switch (action.type) {
    case getType(setBadgeDestinationAction):
      if (!action.payload.destinationId || !action.payload.badgeID) {
        return state
      }
      return {
        ...state,
        [action.payload.badgeID]: action.payload.destinationId,
      }
    case getType(setBadgesDestinationsAction):
      return action.payload
    default:
      return state
  }
}

export default badgeDestinationReducer

/*
{
  '11111': '93044',
  '22222': '93044',
  '33333': '93045',
  '44444': '93045',
  '55555': '93046',
  '66666': '93047',
  '77777': '93048',
  '88888': '93048',
}
*/
