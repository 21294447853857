import { Close, EditOutlined } from '@mui/icons-material'
import { IconButton, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { ReactElement } from 'react'
import { Location } from '../../../models'

const useStyles = makeStyles(() =>
  createStyles({
    headerTextDiv: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    headerTextTypography: {
      fontFamily: 'Ubuntu',
      fontSize: '20px',
      fontWeight: 'bold',
      fontstretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      height: '24px',
    },
  })
)
interface Props {
  handleClose: () => void
  handleEdit?: () => void
  roomLocation: Location
}

function AssetMapGroupPaneHeader(props: Props): ReactElement {
  const { handleClose, handleEdit, roomLocation } = props
  const classes = useStyles()

  return (
    <div className={classes.headerTextDiv}>
      <Typography
        variant='h6'
        classes={{ root: clsx(classes.headerTextTypography) }}
      >
        {roomLocation.name}
      </Typography>
      {handleEdit && (
        <IconButton onClick={handleEdit} size='large'>
          <EditOutlined />
        </IconButton>
      )}
      {handleClose && (
        <IconButton onClick={handleClose} size='large'>
          <Close />
        </IconButton>
      )}
    </div>
  )
}

export default AssetMapGroupPaneHeader
