import { FetchingState } from '../interfaces/fetchingState'
import { CloudReportingPartner } from './cloudReportingPartner'
import { CloudReportingViews } from './cloudReportingViews'

export interface CloudReporting {
  embedUrl: string | undefined
  partner: CloudReportingPartner | undefined
  views: CloudReportingViews | undefined
}

export enum FetchCloudReportingErrorTypes {
  None,
  Forbidden,
  Unknown,
  NotFound,
}

export type CloudReportingState = Readonly<FetchingState<CloudReporting>>
