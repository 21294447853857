import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { RootState } from '../../reducers'
import { CreateEventApi } from 'v2/middleware'
import { createEventAction } from 'v2/actions/createEvent'
import { CreateEventAction } from 'v2/actions'

type CreateEventsEpic = Epic<
  CreateEventAction,
  CreateEventAction,
  RootState,
  { createEventApi: CreateEventApi }
>

export const postCreateEvent: CreateEventsEpic = (
  action$,
  state,
  { createEventApi }
) => {
  return action$.pipe(
    filter(isActionOf(createEventAction.request)),
    mergeMap((action) =>
      from(createEventApi.createEvent(action.payload)).pipe(
        map(() => createEventAction.success()),
        catchError((error) => of(createEventAction.failure(error)))
      )
    )
  )
}
