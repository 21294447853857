import { AssetKitInstance, AgentType, IconType } from '../../models'
import { InpixonAssetAgentDeleteOptions } from '../../models/inpixonMap'
import { MapRenderByGeofence } from '../../models/realtimeMap'
import { BadgeTelemetryMessageWithAgentEvent } from '../../models/telemetry'
import { deleteAgentInpixonAsset } from '../inpixon'
import { calculateMapRenderToUpdateForAgentLeavingMap } from '../realtimeMap'

/**
 * Remove agent icon from the map.
 * If agent has an active duress, remove geofence highlighting.
 * If agent is currently selected, close the Detail Drawer
 * @param agentTelemetry
 * @param currentMapRender
 * @param leavingGeofenceId
 * @param assetKit
 * @param selectedTrackingId
 * @param removeGeofenceHighlight
 * @param removeSelectedAgent
 * @returns updated map render state after map rerendering
 */
export function removeAgentLeavingMap(
  agentTelemetry: BadgeTelemetryMessageWithAgentEvent,
  currentMapRender: MapRenderByGeofence,
  leavingGeofenceId: number,
  assetKit: AssetKitInstance,
  selectedTrackingId: string | undefined,
  removeGeofenceHighlight: (geofenceId: number) => void,
  removeSelectedAgent: (
    agentTelemtry: BadgeTelemetryMessageWithAgentEvent
  ) => void
): MapRenderByGeofence {
  const agentToRemove: InpixonAssetAgentDeleteOptions = {
    trackingId: agentTelemetry.trackingId,
    iconType:
      agentTelemetry.agent.agentType === AgentType.Asset
        ? IconType.Asset
        : IconType.Staff,
  }
  deleteAgentInpixonAsset(assetKit, agentToRemove)
  const updatedMapRender = calculateMapRenderToUpdateForAgentLeavingMap(
    currentMapRender,
    agentTelemetry
  )
  if (agentTelemetry.hasActiveDuress) {
    removeGeofenceHighlight(leavingGeofenceId)
  }
  if (agentTelemetry.trackingId === selectedTrackingId) {
    removeSelectedAgent(agentTelemetry)
  }
  return updatedMapRender
}
