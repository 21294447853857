import { BaseService } from './baseService'
import { ReleaseUserNoticeDismiss } from '../models/releaseUserNoticeDismiss'

export class ReleaseUserNoticesApi extends BaseService {
  constructor() {
    super({ entityType: 'releaseUserNotices' })
  }
  route = 'releaseUserNotices'

  async dismissAllReleaseNotices(
    releaseUserNoticeDismiss: ReleaseUserNoticeDismiss
  ): Promise<void> {
    return await this.post(`${this.route}/dismiss`, releaseUserNoticeDismiss)
  }
}

export const releaseUserNoticesApi = new ReleaseUserNoticesApi()
