/* eslint-disable @typescript-eslint/no-explicit-any */
import jmap from 'jmap.js'
import { isEqual } from 'lodash'
import {
  AssetInstance,
  AssetKitInstance,
  InpixonAssetConfig as DeprecatedInpixonAssetConfig,
  Geofences,
  InpixonAssetPositionUpdate,
  InpixonAssetVisibilityUpdate,
  MapOutline,
  MapOutlineState,
  StaffMembers,
} from '../../models'
import {
  AgentInpixonAssetByType,
  ConfidenceConfig,
  GroupInpixonAssetByType,
  InpixonAssetConfig,
  InpixonAssetPositionUpdateOptions,
} from '../../models/inpixonMap'
import { colors } from '../../styles/MidmarkTheme'
import { getAccuracyConfig } from '../accuracy'
import { updateStaffDuressAssistIconUrl } from '../inpixonIcons'
import {
  getDefaultConfidenceBubble,
  updateConfidenceConfigForActiveDuress,
} from './confidenceBubble'
import { groupIconUpdate } from './mapProcessGroups'
import {
  renderCurrentMapView,
  renderCurrentMapViewControl,
} from './renderCurrentMap'

/**
 * @deprecated
 */
export function updateAssetPosition(
  control: any,
  assetKit: AssetKitInstance,
  assetID: number,
  coordinates: [number, number]
): void {
  const asset = assetKit.getAssetById(assetID)

  if (asset) {
    const fullMapPoint = {
      x: coordinates[0],
      y: coordinates[1],
      mapId: control.currentMap.id,
    }

    assetKit.updateAssetWithPosition(
      asset,
      fullMapPoint,
      new jmap.Animation({ duration: 2 })
    ) as AssetInstance
  }
}

/**
 * @deprecated
 */
export function updateAssetPositions(
  control: any,
  assetKit: AssetKitInstance,
  updates: InpixonAssetPositionUpdate[]
): void {
  updates.map((update) => {
    const asset = assetKit.getAssetById(update.id)

    if (asset) {
      const fullMapPoint = {
        x: update.position[0],
        y: update.position[1],
        mapId: control.currentMap.id,
      }

      assetKit.updateAssetWithPosition(
        asset,
        fullMapPoint,
        new jmap.Animation({ duration: 2 })
      ) as AssetInstance
    }
  })
}

/**
 * @deprecated
 */
export function updateAssetVisibilitys(
  assetKit: AssetKitInstance,
  updates: InpixonAssetVisibilityUpdate[]
): void {
  updates.map((update) => {
    const asset = assetKit.getAssetById(update.id)
    assetKit.updateAsset(
      asset,
      {
        confidencePercent: update.confidencePercent,
        pulseVisible: update.pulseVisible,
        confidenceMax: update.confidenceMax,
      },
      new jmap.Animation({ duration: 1 })
    )
  })
}

/**
 * @deprecated
 */
export function updateAsset(
  assetKit: AssetKitInstance,
  id: number,
  config: Partial<DeprecatedInpixonAssetConfig>
): void {
  const asset = assetKit.getAssetById(id)

  if (asset) {
    assetKit.updateAsset(asset, config, new jmap.Animation({ duration: 1 }))
  }
}

/**
 * @deprecated
 */
export function updateAssets(
  assetKit: AssetKitInstance,
  updates: DeprecatedInpixonAssetConfig[]
): void {
  updates.map((update) => {
    const asset = assetKit.getAssetById(update.id)

    if (asset) {
      assetKit.updateAsset(asset, update, new jmap.Animation({ duration: 1 }))
    }
  })
}

/**
 * @deprecated
 */
export function updateAssetsShow(
  assetKit: AssetKitInstance,
  updates: string[]
): void {
  updates.map((update) => {
    assetKit.showAssetsOfType(update)
  })
}

/**
 * @deprecated
 */
export function updateAssetsHide(
  assetKit: AssetKitInstance,
  updates: number[]
): void {
  updates.map((update) => {
    const asset = assetKit.getAssetById(update)

    if (asset && asset._visible) {
      // this is a hack until they prevent hidden assets from being clicked
      assetKit.updateAsset(
        asset,
        {
          confidenceAlpha: 0.0000001,
          confidencePercent: 0.1,
          confidenceMax: 0.1,
        },
        new jmap.Animation({ duration: 0 })
      )
      assetKit.hideAssetsOfType(`Asset_${update}`)
      assetKit.hideAssetsOfType(`Staff_${update}`)
    }
  })
}

/**
 * @deprecated
 */
export function updateAssetShowAccuracy(
  assetKit: AssetKitInstance,
  assetId: string,
  signalTypeIds: number[]
): void {
  if (assetId) {
    const asset = assetKit?.getAssetById(Number(assetId))
    if (asset) {
      const accuracyConfig = getAccuracyConfig(signalTypeIds)
      asset.confidenceMax = accuracyConfig.confidenceMax
      asset.confidencePercent = accuracyConfig.confidencePercent
      asset.confidenceAlpha = 0.3
      assetKit.updateAsset(
        asset,
        {
          confidencePercent: accuracyConfig.confidencePercent,
          pulseVisible: accuracyConfig.pulseVisible,
          confidenceMax: accuracyConfig.confidenceMax,
          confidenceAlpha: 0.3,
        },
        new jmap.Animation({ duration: 1 })
      )
    }
  }
}

/**
 * @deprecated
 */
export function updateAssetHideAccuracy(
  assetKit: AssetKitInstance,
  assetId: string
): void {
  if (assetId) {
    const asset = assetKit?.getAssetById(Number(assetId))
    if (asset) {
      // confidenceAlpha cannot be set to 0 after asset creation - known inpixon "bug"
      assetKit.updateAsset(
        asset,
        {
          confidenceAlpha: 0.0000001,
        },
        new jmap.Animation({ duration: 1 })
      )
    }
  }
}

/**
 * @deprecated
 */
export function updateGroupIcon(
  assetKit: AssetKitInstance,
  asset: any,
  highlightGroup = false
): void {
  let url = asset.url
  if (highlightGroup) {
    url = url.replace('selected=false', 'selected=true')
  } else {
    url = url.replace('selected=true', 'selected=false')
  }
  updateAssetIcon(assetKit, asset.id, url)
}

/**
 * @deprecated
 */
export function updateAssetIcon(
  assetKit: AssetKitInstance,
  id: number,
  iconUrl: string
): void {
  updateAsset(assetKit, id, { url: iconUrl })
}

/**
 * @deprecated
 */
export function updateAssetIcons(
  assetKit: AssetKitInstance,
  updates: groupIconUpdate[]
): void {
  updates.map((update) => {
    updateAsset(assetKit, update.id, { url: update.iconUrl })
  })
}

/**
 * @deprecated
 */
export function mapUnitOutLinesHighlighting(
  desiredGeofenceMapState: MapOutline[],
  jibestreamRef: any,
  geofenceKitRef: any,
  geofences?: Geofences,
  outlines?: boolean
): void {
  if (geofenceKitRef && geofences) {
    if (geofenceKitRef?.geofences?._items) {
      if (Object.values(geofences).length) {
        if (outlines === false || desiredGeofenceMapState.length === 0) {
          jibestreamRef?.control?.currentMapView?.mapLayers
            ?.find((x: any) => x.name === 'Geofences')
            ?.clearShapes()
        }
        desiredGeofenceMapState.forEach((x) => {
          const geofenceState = Object.values(geofences).find(
            (y) => y.coreReferenceId == Number(x.geoLocationId)
          )
          if (geofenceState) {
            geofenceKitRef.geofences._items.forEach((item: any) => {
              if (item?.instances) {
                item.instances.forEach((instance: any) => {
                  if (instance.id === geofenceState.id) {
                    if (outlines === false) {
                      const color = desiredGeofenceMapState.find(
                        (t) =>
                          Number(t.geoLocationId) ===
                          Number(geofenceState?.coreReferenceId)
                      )
                      geofenceKitRef?.drawPolygonOfGeofenceInstance(
                        instance,
                        new jmap.Style({
                          fill: color?.outlineColor || colors.transparent,
                          opacity: 0.2,
                          stroke: colors.darkGrey,
                          strokeWidth: 0.4,
                          strokeOpacity: 10,
                        })
                      )
                    } else {
                      geofenceKitRef?.drawPolygonOfGeofenceInstance(
                        instance,
                        new jmap.Style({
                          fill: colors.secondaryBlue,
                          opacity: 0.2,
                          stroke: colors.darkGrey,
                          strokeWidth: 0.4,
                          strokeOpacity: 10,
                        })
                      )
                    }
                  }
                })
              }
            })
          }
        })
      }
    }
  }
  renderCurrentMapView(jibestreamRef)
}

/**
 * @deprecated
 */
export function updateMapOutLines(
  desiredGeofenceMapState: MapOutline[],
  jibestreamRef: any,
  geofenceKitRef: any,
  geofences?: Geofences
): void {
  if (geofenceKitRef && geofences) {
    if (geofenceKitRef?.geofences?._items) {
      if (Object.values(geofences).length) {
        jibestreamRef?.control?.currentMapView?.mapLayers
          ?.find((x: any) => x.name === 'Geofences')
          ?.clearShapes()

        desiredGeofenceMapState.forEach((x) => {
          const geofenceState = Object.values(geofences).find(
            (y) => y.coreReferenceId == Number(x.geoLocationId)
          )
          if (geofenceState) {
            geofenceKitRef.geofences._items.forEach((item: any) => {
              if (item?.instances) {
                item.instances.forEach((instance: any) => {
                  if (instance.id === geofenceState.id) {
                    geofenceKitRef?.drawPolygonOfGeofenceInstance(
                      instance,
                      new jmap.Style({
                        fill: colors.duress,
                        opacity: 0.2,
                        stroke: colors.duress,
                        strokeWidth: 0.4,
                        strokeOpacity: 10,
                      })
                    )
                  }
                })
              }
            })
          }
        })
      }
    }
  }
}

/**
 * @deprecated
 */
export function mapLabelState(
  setCurrentGeofenceMapState: (
    currentGeofenceMapState: MapOutlineState
  ) => void,
  currentGeofenceMapState: MapOutlineState,
  desiredGeofenceMapState: MapOutlineState,
  geoFenceKitLoaded?: boolean,
  geofenceKitRef?: any,
  jibestreamRef?: any,
  geofences?: Geofences,
  staff?: StaffMembers
): void {
  if (geoFenceKitLoaded && jibestreamRef && staff && geofences) {
    if (
      Object.values(staff).length > 0 &&
      Object.values(geofences).length > 0
    ) {
      if (currentGeofenceMapState && desiredGeofenceMapState) {
        if (!isEqual(currentGeofenceMapState, desiredGeofenceMapState)) {
          updateMapOutLines(
            Object.values(desiredGeofenceMapState),
            jibestreamRef,
            geofenceKitRef,
            geofences
          )
          setCurrentGeofenceMapState(desiredGeofenceMapState)
          renderCurrentMapView(jibestreamRef)
        }
      }
    } else {
      renderCurrentMapView(jibestreamRef)
      setCurrentGeofenceMapState({})
    }
  }
}

/**
 * Show a currently hidden asset(icon) on the map.
 * After showing the icon, update the confidence bubble config for the icon
 * based on whether the icon is currently selected and/or has an active duress or not.
 * Also, reset the confidencePercent to re-enable asset click.
 * @param assetKit
 * @param jibestreamController
 * @param update
 * @param isSelected
 * @param hasActiveDuress
 * @returns
 */
export function showAgentInpixonAssets(
  assetKit: AssetKitInstance,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any,
  update: AgentInpixonAssetByType,
  isSelected: boolean,
  hasActiveDuress: boolean,
  hasActiveAssist: boolean,
  hasResolvedDuressOrAssist: boolean
): void {
  const asset = assetKit.getAssetsByType(
    `${update.iconType.toString()}_${update.trackingId}`
  )
  if (asset.length === 0) {
    console.warn('Asset not found in asset kit. Unable to update!')
    return
  }
  assetKit.showAssetsOfType(
    `${update.iconType.toString()}_${update.trackingId}`
  )
  let config = getDefaultConfidenceBubble()
  let updatedUrl = asset[0].url
  if (isSelected || hasActiveDuress) {
    config.confidenceAlpha = 0.3
  }
  if (hasActiveDuress) {
    config = updateConfidenceConfigForActiveDuress(config)
  }
  if (hasActiveDuress || hasActiveAssist || hasResolvedDuressOrAssist) {
    updatedUrl = updateStaffDuressAssistIconUrl(
      updatedUrl,
      hasActiveDuress,
      hasActiveAssist,
      hasResolvedDuressOrAssist
    )
  }
  const animation = new jmap.Animation({ duration: 0 })
  assetKit.updateAsset(asset[0], { ...config, url: updatedUrl }, animation)
  renderCurrentMapViewControl(jibestreamController)
}

export function updateInpixonAssetPosition(
  update: InpixonAssetPositionUpdateOptions,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  assetKit: any,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any
): void {
  const asset: AssetInstance[] = assetKit.getAssetsByType(
    `${update.iconType.toString()}_${update.trackingId}`
  )

  if (asset.length === 0) {
    console.warn('Asset not found in asset kit. Unable to update!')
    return
  }
  const fullMapPoint = {
    x: update.position[0],
    y: update.position[1],
    mapId: jibestreamController.currentMap.id,
  }
  const jMapAnimation = new jmap.Animation({
    duration: 2,
  })

  assetKit.updateAssetWithPosition(
    asset[0],
    fullMapPoint,
    jMapAnimation
  ) as AssetInstance
  renderCurrentMapViewControl(jibestreamController)
}

export async function updateInpixonAssetPositionWithCallback(
  update: InpixonAssetPositionUpdateOptions,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  assetKit: any,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any,
  onComplete: () => void
): Promise<void> {
  return new Promise<void>((resolve) => {
    const asset: AssetInstance[] = assetKit.getAssetsByType(
      `${update.iconType.toString()}_${update.trackingId}`
    )

    if (asset.length === 0) {
      console.warn('Asset not found in asset kit. Unable to update!')
      resolve()
      return
    }

    const fullMapPoint = {
      x: update.position[0],
      y: update.position[1],
      mapId: jibestreamController.currentMap.id,
    }

    const jMapAnimation = new jmap.Animation({
      duration: 2,
      onComplete: () => {
        if (onComplete) {
          onComplete()
        }
        resolve()
      },
    })

    assetKit.updateAssetWithPosition(
      asset[0],
      fullMapPoint,
      jMapAnimation
    ) as AssetInstance
    renderCurrentMapViewControl(jibestreamController)
  })
}

/**
 * Hide an asset(icon) on the map.
 * Before hiding the asset, update the confidence bubble config for the asset to prevent
 * clicking of hidden assets (this is a known issue as Inpixon does not prevent asset clicking
 * for hidden assets).
 * @param assetKit
 * @param jibestreamController
 * @param update
 * @returns
 */
export function hideAgentInpixonAsset(
  assetKit: AssetKitInstance,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any,
  update: AgentInpixonAssetByType
): void {
  // this is a hack until they prevent hidden assets from being clicked
  const asset = assetKit.getAssetsByType(
    `${update.iconType.toString()}_${update.trackingId}`
  )
  if (asset.length === 0) {
    console.warn('Asset not found in asset kit. Unable to update!')
    return
  }
  const animation = new jmap.Animation({ duration: 0 })
  assetKit.updateAsset(
    asset[0],
    {
      confidenceAlpha: 0.0000001,
      confidencePercent: 0.0000001,
    },
    animation
  )
  assetKit.hideAssetsOfType(
    `${update.iconType.toString()}_${update.trackingId}`
  )
  renderCurrentMapViewControl(jibestreamController)
}

export function updateInpixonGroupIconByType(
  assetKit: AssetKitInstance,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any,
  groupInpixonAssetType: GroupInpixonAssetByType,
  url: string
): void {
  updateInpixonGroupAsset(
    assetKit,
    jibestreamController,
    groupInpixonAssetType,
    { url }
  )
}

export function updateInpixonGroupAsset(
  assetKit: AssetKitInstance,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any,
  groupInpixonAssetType: GroupInpixonAssetByType,
  config: Partial<InpixonAssetConfig>
): void {
  const asset: AssetInstance[] = assetKit.getAssetsByType(
    `${groupInpixonAssetType.iconType.toString()}_${groupInpixonAssetType.geofenceId.toString()}`
  )
  if (asset.length === 0) {
    console.warn('Asset not found in asset kit. Unable to update!')
    return
  }
  const animation = new jmap.Animation({ duration: 1 })
  assetKit.updateAsset(asset[0], config, animation)
  renderCurrentMapViewControl(jibestreamController)
}

export function updateAgentIconWithActiveStaffEvent(
  assetKit: AssetKitInstance,
  assetType: string,
  confidenceBubbleConfig: ConfidenceConfig,
  hasActiveDuress: boolean,
  hasActiveAssist: boolean,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any
): void {
  const asset = assetKit.getAssetsByType(assetType)
  if (asset.length === 0) {
    console.warn('Asset not found in asset kit. Unable to update!')
    return
  }

  const url = updateStaffDuressAssistIconUrl(
    asset[0].url,
    hasActiveDuress,
    hasActiveAssist,
    false
  )
  const animation = new jmap.Animation({ duration: 1 })
  assetKit.updateAsset(asset[0], { url, ...confidenceBubbleConfig }, animation)
  renderCurrentMapViewControl(jibestreamController)
}

export function updateGroupIconWithActiveStaffEvent(
  assetKit: AssetKitInstance,
  assetType: string,
  hasActiveDuress: boolean,
  hasActiveAssist: boolean,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any
): void {
  const asset = assetKit.getAssetsByType(assetType)
  if (asset.length === 0) {
    console.warn('Asset not found in asset kit. Unable to update!')
    return
  }
  const url = updateStaffDuressAssistIconUrl(
    asset[0].url,
    hasActiveDuress,
    hasActiveAssist,
    false
  )
  const animation = new jmap.Animation({ duration: 1 })
  assetKit.updateAsset(asset[0], { url }, animation)
  renderCurrentMapViewControl(jibestreamController)
}

export function updateAgentIconWithResolvedEvent(
  assetKit: AssetKitInstance,
  assetType: string,
  confidenceBubbleConfig: ConfidenceConfig,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any
): void {
  const asset = assetKit.getAssetsByType(assetType)
  if (asset.length === 0) {
    console.warn('Asset not found in asset kit. Unable to update!')
    return
  }
  const url = updateStaffDuressAssistIconUrl(asset[0].url, false, false, true)
  const animation = new jmap.Animation({ duration: 1 })
  assetKit.updateAsset(asset[0], { url, ...confidenceBubbleConfig }, animation)
  renderCurrentMapViewControl(jibestreamController)
}

export function updateGroupIconWithResolvedEvent(
  assetKit: AssetKitInstance,
  assetType: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any
): void {
  const asset = assetKit.getAssetsByType(assetType)
  if (asset.length === 0) {
    console.warn('Asset not found in asset kit. Unable to update!')
    return
  }
  const url = updateStaffDuressAssistIconUrl(asset[0].url, false, false, true)
  const animation = new jmap.Animation({ duration: 1 })
  assetKit.updateAsset(asset[0], { url }, animation)
  renderCurrentMapViewControl(jibestreamController)
}

export function updateInpixonGroupIcon(
  assetKit: AssetKitInstance,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any,
  assetType: string,
  url: string
): void {
  const asset = assetKit.getAssetsByType(assetType)
  if (asset.length > 0) {
    const animation = new jmap.Animation({ duration: 1 })
    assetKit.updateAsset(asset[0], { url }, animation)
    renderCurrentMapViewControl(jibestreamController)
  }
}

export function showAgentConfidenceBubble(
  assetKit: AssetKitInstance,
  asset: AssetInstance,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any,
  hasActiveDuress: boolean,
  hasActiveAssist: boolean
): void {
  let config: Partial<InpixonAssetConfig> = {
    confidenceAlpha: 0.3,
  }
  if (hasActiveDuress) {
    config = {
      ...config,
      confidenceColor: colors.duress,
    }
  } else if (hasActiveAssist) {
    config = {
      ...config,
      confidenceColor: colors.assistConfidenceBubble,
    }
  }
  const animation = new jmap.Animation({ duration: 1 })
  assetKit.updateAsset(asset, config, animation)
  renderCurrentMapViewControl(jibestreamController)
}

export function removeAgentConfidenceBubble(
  assetKit: AssetKitInstance,
  assetType: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any
): void {
  const animation = new jmap.Animation({ duration: 1 })
  const activeAsset = assetKit.getAssetsByType(assetType)
  if (activeAsset.length > 0) {
    assetKit.updateAsset(
      activeAsset[0],
      { confidenceAlpha: 0.0000001 },
      animation
    )
    renderCurrentMapViewControl(jibestreamController)
  }
}
