import { getAllDevicesAction } from '../../actions/devices'
import { Devices, DevicesState } from '../../models'
import { EntityResult, useEntities } from '../useEntities'

const useFetchDevices = (): EntityResult<Devices> =>
  useEntities<DevicesState, Devices>(
    () => getAllDevicesAction.request(),
    (state: any) => state.devices // eslint-disable-line @typescript-eslint/no-explicit-any
  )

export { useFetchDevices }
