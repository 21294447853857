import { useDispatch, useSelector } from 'react-redux'
import { Manufacturers, ManufacturersState } from '../models'
import { getAllManufacturersAction } from '../actions/manufacturers'
import { shouldDispatch } from '../helpers/shouldDispatch'
import { useEffect } from 'react'

const useFetchManufacturers = (shouldRefresh: boolean = false) => {
  const dispatch = useDispatch()
  const status = useSelector(
    ({ manufacturers }: { manufacturers: ManufacturersState }) =>
      manufacturers.status
  )
  const lastRefreshTime = useSelector(
    ({ manufacturers }: { manufacturers: ManufacturersState }) =>
      manufacturers.lastRefreshTime
  )

  useEffect(() => {
    if (shouldDispatch(status, lastRefreshTime) || shouldRefresh) {
      dispatch(getAllManufacturersAction.request())
    }
  }, [status, lastRefreshTime, shouldRefresh, dispatch])

  const manufacturers: Manufacturers = useSelector(
    ({ manufacturers }: { manufacturers: ManufacturersState }) =>
      manufacturers.data
  )

  return {
    manufacturers,
    status,
  }
}

export { useFetchManufacturers }
