import { Typography } from '@mui/material'
import { ColumnDef } from '@tanstack/react-table'
import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import {
  deleteUserSettingsAction,
  patchUserSettingsAction,
} from '../../../actions/userSettings'
import { PrimaryButton } from '../../../components/Buttons'
import {
  EditButton,
  ReactTable,
  TableRowSelectionOptions,
} from '../../../components/Tables'
import { useFetchUserSettings, useGlobalSearch } from '../../../hooks'
import {
  FetchingStatus,
  SubUnit,
  TableColumnSettings,
  UserSettingsType,
} from '../../../models'

interface Props {
  subUnits: SubUnit[]
  subUnitFetchingStatus: FetchingStatus | undefined
  onEditSubUnit: (subUnit: SubUnit | undefined) => void
  onCreateSubUnit: () => void
  tableRowSelectionOptions: Omit<
    TableRowSelectionOptions<SubUnit>,
    'dataMatchPredicate'
  >
}

const SubUnitManagementList: React.FC<Props> = (props: Props) => {
  const {
    subUnits,
    subUnitFetchingStatus,
    tableRowSelectionOptions,
    onEditSubUnit,
    onCreateSubUnit,
  } = props

  const dispatch = useDispatch()
  const { data: userSettings, status: userSettingStatus } =
    useFetchUserSettings()

  // apply global search and filters
  const tableData = useGlobalSearch(subUnits)

  const actions = [
    <PrimaryButton
      key='create'
      styleOverride={{
        wrapper: {
          margin: 0,
        },
      }}
      variant='contained'
      type={'button'}
      onClick={onCreateSubUnit}
      loading={false}
    >
      <Typography noWrap style={{ fontWeight: 'bold' }}>
        NEW SUB-UNIT
      </Typography>
    </PrimaryButton>,
  ]

  const columns = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const columnDefinitions: ColumnDef<SubUnit, any>[] = [
      {
        accessorKey: 'subUnitName',
        header: 'Sub-Unit Name',
        sortingFn: 'alphanumeric',
        enablePinning: true,
      },
      {
        id: 'edit',
        enableSorting: false,
        accessorFn: (row) => (
          <EditButton item={row} onEditClick={onEditSubUnit} />
        ),
        cell: (cell) => cell.getValue(),
        header: 'Edit',
        enablePinning: true,
        meta: {
          align: 'right',
          headerStyle: {
            paddingRight: 19,
          },
        },
      },
    ]
    return columnDefinitions
  }, [onEditSubUnit])

  const handleSave = (
    settings: TableColumnSettings,
    saveDefault: boolean
  ): void => {
    if (!saveDefault) {
      dispatch(
        patchUserSettingsAction.request({
          subUnitAdmin: settings,
        })
      )
    } else {
      dispatch(deleteUserSettingsAction.request(UserSettingsType.SubUnitAdmin))
    }
  }

  return (
    <>
      <ReactTable
        tableToolbarOptions={{
          title: 'Sub-Unit Administration',
          actions: actions,
        }}
        tableOptions={{
          data: tableData ?? [],
          columns: columns,
          showLoading:
            subUnitFetchingStatus === FetchingStatus.Request ||
            userSettingStatus === FetchingStatus.Request,
        }}
        tableRowOptions={{
          rowSelectionOptions: {
            ...tableRowSelectionOptions,
            dataMatchPredicate: (subUnitA: SubUnit, subUnitB: SubUnit) =>
              subUnitA.id === subUnitB.id,
          },
        }}
        tableColumnUserCustomizationOptions={{
          userSettings: Object.values(userSettings.subUnitAdmin ?? {}),
          onSaveColumnCustomization: handleSave,
        }}
      />
    </>
  )
}

export { SubUnitManagementList }
