import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import {
  getAllSensorsAction,
  getLastSeenBadgesAction,
  getAllSensorsBySensorIdAction,
  getAllSensorsByLocationIdAction,
} from '../actions/sensors'
import { SensorsApi } from '../middleware/sensors'
import { Sensor, SensorBadgePayload, SensorsState } from '../models/sensors'
import { SensorAction } from '../actions'

type SensorEpic = Epic<
  SensorAction,
  SensorAction,
  SensorsState,
  { sensorsApi: SensorsApi }
>

export const getAllSensorsByLocationIdEpic: SensorEpic = (
  action$,
  state,
  { sensorsApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllSensorsByLocationIdAction.request)),
    mergeMap((action) =>
      from(sensorsApi.getAllSensors(action.payload)).pipe(
        map((sensor) => getAllSensorsByLocationIdAction.success(sensor)),
        catchError((error) =>
          of(getAllSensorsByLocationIdAction.failure(error))
        )
      )
    )
  )
}

export const getAllSensorsEpic: SensorEpic = (
  action$,
  state,
  { sensorsApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllSensorsAction.request)),
    mergeMap(() =>
      from(sensorsApi.getAllSensors()).pipe(
        map((sensor) => getAllSensorsAction.success(sensor)),
        catchError((error) => of(getAllSensorsAction.failure(error)))
      )
    )
  )
}

export const getLastSeenBadgesEpic: SensorEpic = (
  action$,
  state,
  { sensorsApi }
) => {
  return action$.pipe(
    filter(isActionOf(getLastSeenBadgesAction.request)),
    mergeMap((action) => {
      const selectedSensors = Object.values(state.value.data?.sensors ?? [])
        .filter(
          (sensor: Sensor) => sensor && sensor.hardwareGuid === action.payload
        )
        .map((sensor: Sensor) => sensor.sensorId)
      if (selectedSensors.length) {
        return from(sensorsApi.getLastSeenBadges(selectedSensors)).pipe(
          map((sensorBadges: SensorBadgePayload) =>
            getLastSeenBadgesAction.success(sensorBadges)
          ),
          catchError((error) => of(getLastSeenBadgesAction.failure(error)))
        )
      } else {
        return []
      }
    })
  )
}

export const getAllSensorsBySensorIdEpic: SensorEpic = (
  action$,
  state,
  { sensorsApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllSensorsBySensorIdAction.request)),
    mergeMap((action) =>
      from(sensorsApi.getAllSensorBySensorId(action.payload)).pipe(
        map((sensorLocation) =>
          getAllSensorsBySensorIdAction.success(sensorLocation)
        ),
        catchError((error) => of(getAllSensorsBySensorIdAction.failure(error)))
      )
    )
  )
}
