import { Row } from '@tanstack/react-table'
import { BadgeSignalStatus, Staff, StaffEvent, StaffEvents } from '../models'
import { StaffRow } from '../models/staffRow'
import { hasStaffAssist, hasStaffDuress, staffAssistEnabled, staffDuressEnabled } from './staffEvents'
import { colors } from '../styles'

export function GetManagerDisplayOption(
  staff: Staff,
  includeEmail = false
): string {
  let returnArray: string[] = [staff.lastName, ', ', staff.firstName]

  if (includeEmail) {
    const email: string[] = [' - ', staff.email]
    returnArray = returnArray.concat(email)
  }

  return staff ? returnArray.join('') : ''
}

export function stripManagerEmail(managerNameAndEmail: string): string {
  const emailSplitIndex = managerNameAndEmail.indexOf(' - ')

  if (emailSplitIndex == -1) {
    return managerNameAndEmail
  }

  return managerNameAndEmail.substring(0, emailSplitIndex)
}

export const getRowStyles = (
  row: Row<StaffRow>,
  activeDuressEvents: StaffEvent[],
  activeAssistEvents: StaffEvent[],
  selectedStaff: StaffRow | undefined
): React.CSSProperties => {
  let style = {}
  if (row.getValue('signalStatus') === BadgeSignalStatus.Recent) {
    style = { fontStyle: 'italic' }
  }

  if (hasStaffDuress(row.getValue('badgeNumber'), activeDuressEvents)) {
    style = {
      ...style,
      backgroundColor:
        row.original['agentGuid'] === selectedStaff?.agentGuid
          ? colors.duressTableSelect
          : colors.duressBubble,
    }
  } else if (hasStaffAssist(row.getValue('badgeNumber'), activeAssistEvents)) {
    style = {
      ...style,
      backgroundColor:
        row.original['agentGuid'] === selectedStaff?.agentGuid
          ? colors.assistTableSelect
          : colors.assistTableHighlight,
    }
  }
  return style
}
