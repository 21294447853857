import { MapRenderByGeofence } from '../../models/realtimeMap'
import {
  AgentTelemetriesGroupedByTrackingId,
  BadgeTelemetryMessageWithAgentEvent,
} from '../../models/telemetry'
import { mergeRecords } from '../../utils'
import { findAgentInCurrentMapRender } from '../realtimeMap'
import { filterBadgeTelemetryMessagesByAgentTypesPlural } from './mapTelemetry'

export function filterLeavingAgentsCurrentlyOnMap(
  telemetries: AgentTelemetriesGroupedByTrackingId,
  currentMapRender: MapRenderByGeofence,
  agentTypes: string[]
): AgentTelemetriesGroupedByTrackingId {
  let filteredAgentTelemetriesByTrackingId: AgentTelemetriesGroupedByTrackingId =
    {}
  const filteredLeavingAgentsByTypes =
    filterBadgeTelemetryMessagesByAgentTypesPlural(
      Object.values(telemetries),
      agentTypes
    )
  const leavingAgentsCurrentlyOnMap = filteredLeavingAgentsByTypes.filter(
    (telemetry) => {
      const currentRenderedAgent = findAgentInCurrentMapRender(
        telemetry.trackingId,
        currentMapRender
      )
      return !!currentRenderedAgent
    }
  )
  filteredAgentTelemetriesByTrackingId = mergeRecords<
    AgentTelemetriesGroupedByTrackingId,
    BadgeTelemetryMessageWithAgentEvent,
    'trackingId'
  >(
    filteredAgentTelemetriesByTrackingId,
    leavingAgentsCurrentlyOnMap,
    'trackingId'
  )
  return filteredAgentTelemetriesByTrackingId
}
