import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { AltitudeMapAction } from '../actions'
import {
  createAltitudeMapAction,
  deleteAltitudeMapAction,
  getAllAltitudeMapsAction,
  updateAltitudeMapAction,
} from '../actions/altitudeMaps'
import { AltitudeMapsApi } from '../middleware/altitudeMaps'
import { AltitudeMapsState } from '../models/altitudeMap'

type AltitudeMapEpic = Epic<
  AltitudeMapAction,
  AltitudeMapAction,
  AltitudeMapsState,
  { altitudeMapsApi: AltitudeMapsApi }
>

export const getAllAltitudeMapsEpic: AltitudeMapEpic = (
  action$,
  state,
  { altitudeMapsApi }
) => {
  return action$.pipe(
    filter(isActionOf(getAllAltitudeMapsAction.request)),
    mergeMap((action) =>
      from(altitudeMapsApi.getAllAltitudeMaps()).pipe(
        map((altitudeMaps) => getAllAltitudeMapsAction.success(altitudeMaps)),
        catchError((error) => of(getAllAltitudeMapsAction.failure(error)))
      )
    )
  )
}

export const putAltitudeMapEpic: AltitudeMapEpic = (
  action$,
  state$,
  { altitudeMapsApi }
) => {
  return action$.pipe(
    filter(isActionOf(updateAltitudeMapAction.request)),
    mergeMap((action) =>
      from(altitudeMapsApi.updateAltitudeMap(action.payload)).pipe(
        mergeMap((altitudeMap) => [
          updateAltitudeMapAction.success(altitudeMap),
        ]),
        catchError((error) => of(updateAltitudeMapAction.failure(error)))
      )
    )
  )
}

export const postAltitudeMapEpic: AltitudeMapEpic = (
  action$,
  state$,
  { altitudeMapsApi }
) => {
  return action$.pipe(
    filter(isActionOf(createAltitudeMapAction.request)),
    mergeMap((action) =>
      from(altitudeMapsApi.createAltitudeMap(action.payload)).pipe(
        mergeMap((altitudeMap) => [
          createAltitudeMapAction.success(altitudeMap),
        ]),
        catchError((error) => of(createAltitudeMapAction.failure(error)))
      )
    )
  )
}

export const deleteAltitudeMapEpic: AltitudeMapEpic = (
  action$,
  state$,
  { altitudeMapsApi }
) => {
  return action$.pipe(
    filter(isActionOf(deleteAltitudeMapAction.request)),
    mergeMap((action) =>
      from(altitudeMapsApi.deleteAltitudeMap(action.payload)).pipe(
        map((altitudeMap) => deleteAltitudeMapAction.success(altitudeMap)),
        catchError((error) => of(deleteAltitudeMapAction.failure(error)))
      )
    )
  )
}
