import { PropTypes } from '@mui/material'
import React, { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  postAssetWatchlistItemAction,
  postStaffWatchlistItemAction,
  deleteAssetWatchlistItemAction,
  deleteStaffWatchlistItemAction,
} from '../../actions/watchlists'
import { useAuth } from '../../hooks'
import { AgentType, WatchlistItem, WatchlistState } from '../../models'
import { PlaylistToggleIconButton } from '../ToggleIconButtons'

interface WatchAgentIconButtonProps {
  agentId: string
  size?: 'small' | 'medium'
  agentType: AgentType
  isWatchlisted: boolean
  color?: PropTypes.Color
  style?: React.CSSProperties | undefined
  forceRefresh: () => void
  hasWatchListFilter: boolean
}

const WatchAgentIconButton = (
  props: WatchAgentIconButtonProps
): ReactElement => {
  const {
    agentId,
    size,
    isWatchlisted,
    color,
    agentType,
    style,
    forceRefresh,
    hasWatchListFilter,
  } = props
  const { user } = useAuth()
  const dispatch = useDispatch()
  const watchlist = useSelector(
    ({ watchlist }: { watchlist: WatchlistState }) => watchlist.data
  )

  const handleChange = (isSelected: boolean): void => {
    if (isSelected) {
      const watchlistItem: WatchlistItem = {
        userId: user.id,
        agentId,
      }
      switch (agentType) {
        case AgentType.Asset:
          dispatch(postAssetWatchlistItemAction.request(watchlistItem))
          break
        case AgentType.Staff:
          dispatch(postStaffWatchlistItemAction.request(watchlistItem))
          break
        default:
          break
      }
    } else {
      const watchlistItem: WatchlistItem | undefined = Object.values(
        watchlist
      ).find((x) => x.agentId === agentId)
      if (watchlistItem) {
        switch (agentType) {
          case AgentType.Asset:
            dispatch(deleteAssetWatchlistItemAction.request(watchlistItem))
            break
          case AgentType.Staff:
            dispatch(deleteStaffWatchlistItemAction.request(watchlistItem))
            break
          default:
            break
        }
        if (hasWatchListFilter) {
          forceRefresh()
        }
      }
    }
  }

  return (
    <div style={style}>
      <div>
        <PlaylistToggleIconButton
          size={size}
          onChange={handleChange}
          isSelected={isWatchlisted}
          shouldPreventDefault={true}
          color={color}
        />
      </div>
    </div>
  )
}

export { WatchAgentIconButton as WatchAgentIconButton }
