import { Tenants, TenantsState } from '../../v2/models'
import { getAllTenantsAction } from '../../v2/actions/tenants'
import { EntityResult, useEntities } from '@midmarkrtls/common/hooks'

const useFetchTenants = (): EntityResult<Tenants> =>
  useEntities<TenantsState, Tenants>(
    () => getAllTenantsAction.request(),
    (state: any) => state.tenants // eslint-disable-line @typescript-eslint/no-explicit-any
  )

export { useFetchTenants }
