import { getType } from 'typesafe-actions'
import { TriggerAction } from '../actions'
import { getAllTriggersAction } from '../actions/triggers'
import { FetchingStatus } from '../models/fetchingStatus'
import { Trigger, Triggers, TriggerState } from '../models/trigger'
import { mergeRecords, setFetchingStatus } from '../utils'

const initialState: TriggerState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

export default (
  state: TriggerState = initialState,
  action: TriggerAction
): TriggerState => {
  switch (action.type) {
    case getType(getAllTriggersAction.success):
      return {
        data: mergeRecords<Triggers, Trigger, 'id'>(
          state.data,
          action.payload,
          'id'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getAllTriggersAction.request):
      return setFetchingStatus<Triggers>(state, FetchingStatus.Request)
    case getType(getAllTriggersAction.failure):
      return setFetchingStatus<Triggers>(state, FetchingStatus.Failure)
    default:
      return state
  }
}
