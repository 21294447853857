import React from 'react'
import { Typography, IconButton, Link } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Close, EditOutlined } from '@mui/icons-material'

interface Props {
  handleClose?: () => void
  handleEdit?: () => void
  handleAction?: () => void
  headerText: string
  unboldHeader?: boolean
  actionText?: string
}

const useStyles = makeStyles((theme) =>
  createStyles({
    actionLink: {
      marginLeft: 8,
      cursor: 'pointer',
      fontSize: '12px',
    },
  })
)

function DetailsPaneHeader(props: Props) {
  const {
    handleClose,
    handleEdit,
    handleAction,
    headerText,
    unboldHeader,
    actionText,
  } = props

  const classes = useStyles()

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography
        variant='h6'
        style={{
          fontWeight: unboldHeader ? 'normal' : 'bold',
          fontSize: unboldHeader ? '24px' : '20px',
        }}
      >
        {headerText}
        {handleAction && (
          <Link
            className={classes.actionLink}
            variant='body2'
            onClick={handleAction}
            underline='none'
          >
            {actionText}
          </Link>
        )}
      </Typography>
      {handleEdit && (
        <IconButton onClick={handleEdit} size='small'>
          <EditOutlined />
        </IconButton>
      )}
      {handleClose && (
        <IconButton onClick={handleClose} size='small'>
          <Close />
        </IconButton>
      )}
    </div>
  )
}

export default DetailsPaneHeader
