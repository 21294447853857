import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { LocationMetricsAction } from '../actions'
import { getLocationMetricsAction } from '../actions/locationMetrics'
import { LocationMetricsApi } from '../middleware'
import { LocationMetricsState } from '../models'

type LocationMetricsEpic = Epic<
  LocationMetricsAction,
  LocationMetricsAction,
  LocationMetricsState,
  { locationMetricsApi: LocationMetricsApi }
>

export const getLocationMetricsEpic: LocationMetricsEpic = (
  action$,
  state,
  { locationMetricsApi }
) => {
  return action$.pipe(
    filter(isActionOf(getLocationMetricsAction.request)),
    mergeMap((action) =>
      from(locationMetricsApi.getLocationMetrics(action.payload)).pipe(
        map((payload) => getLocationMetricsAction.success(payload)),
        catchError((error) => of(getLocationMetricsAction.failure(error)))
      )
    )
  )
}
