import { hasActiveAgentEvent, hasResolvedDuressOrAssistEvent } from '..'
import {
  AgentEventType,
  StaffDuressAndAssistOptions,
  StaffEvent,
} from '../../models'
import { BaseBadgeTelemetryMessage } from '../../models/telemetry'

export function buildConfidenceBubbleAgentEventOptionsForFetchedAgents(
  trackingId: string,
  allDuressEvents: StaffEvent[],
  allAssistEvents: StaffEvent[],
  staffDuressAndAssistFeatureFlagEnabled: boolean
): Omit<
  StaffDuressAndAssistOptions,
  'staffDuressAndAssistFeatureFlagEnabled' | 'isSelected'
> {
  let hasActiveDuress = false
  let hasActiveAssist = false
  let hasResolvedDuressOrAssist = false
  if (!staffDuressAndAssistFeatureFlagEnabled) {
    return {
      hasActiveAssist,
      hasActiveDuress,
      hasResolvedDuressOrAssist,
    }
  }
  hasActiveDuress = hasActiveAgentEvent(trackingId, allDuressEvents)
  hasActiveAssist = hasActiveAgentEvent(trackingId, allAssistEvents)
  hasResolvedDuressOrAssist = hasResolvedDuressOrAssistEvent(trackingId, [
    ...allDuressEvents,
    ...allAssistEvents,
  ])
  return {
    hasActiveAssist,
    hasActiveDuress,
    hasResolvedDuressOrAssist,
  }
}

export function buildConfidenceBubbleAgentEventOptionsForLiveTelemetry(
  telemetry: BaseBadgeTelemetryMessage,
  allDuressEvents: StaffEvent[],
  allAssistEvents: StaffEvent[],
  staffDuressAndAssistFeatureFlagEnabled: boolean
): Omit<
  StaffDuressAndAssistOptions,
  'staffDuressAndAssistFeatureFlagEnabled' | 'isSelected'
> {
  let hasActiveDuress = false
  let hasActiveAssist = false
  let hasResolvedDuressOrAssist = false
  if (!staffDuressAndAssistFeatureFlagEnabled) {
    return {
      hasActiveAssist,
      hasActiveDuress,
      hasResolvedDuressOrAssist,
    }
  }
  if (telemetry.isAgentEventTelemetry) {
    if (telemetry.agentEvent && telemetry.agentEvent.agentEventClosedDate) {
      hasResolvedDuressOrAssist = true
    } else {
      if (telemetry.agentEvent?.agentEventTypeId === AgentEventType.Duress) {
        hasActiveDuress = true
      } else if (
        telemetry.agentEvent?.agentEventTypeId === AgentEventType.Assist
      ) {
        hasActiveAssist = true
      }
    }
  } else {
    hasActiveDuress = hasActiveAgentEvent(telemetry.trackingId, allDuressEvents)
    hasActiveAssist = hasActiveAgentEvent(telemetry.trackingId, allAssistEvents)
    hasResolvedDuressOrAssist = hasResolvedDuressOrAssistEvent(
      telemetry.trackingId,
      [...allDuressEvents, ...allAssistEvents]
    )
  }
  return {
    hasActiveAssist,
    hasActiveDuress,
    hasResolvedDuressOrAssist,
  }
}
