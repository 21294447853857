import { BaseService } from './baseService'
import { IssueType } from '../models'

export class IssueTypesApi extends BaseService {
  constructor() {
    super({ entityType: 'issueTypes' })
  }
  route = 'issueTypes'

  async getAllIssueTypes(): Promise<IssueType[]> {
    return await this.get<IssueType[]>(this.route)
  }
}
