/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode } from 'react'
import { Chip, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Clear } from '@mui/icons-material'
import { colors, flexLayout, padding } from '../styles'
import { UserFilters } from '../models'
import { nameof } from './nameof'
import { localMoment } from './date'
import moment from 'moment'
import { AssetFilters, StaffFilters } from '../models/filters'

export const isInList = <D extends {}, V>(
  value: V,
  items: D[] | undefined,
  selector: (item: D) => V
): boolean => {
  return !!items?.length ? items.some((item) => selector(item) === value) : true
}

export const isMatch = <D extends unknown, F extends unknown = D>(
  filterValue?: F,
  value?: D,
  comparer?: (filter: F, data?: D) => boolean
): boolean => {
  const defaultComparer = (filter: F, data?: D): boolean => filter === data
  return (
    filterValue === undefined ||
    filterValue === false ||
    (comparer ?? defaultComparer)(filterValue as F, value)
  )
}

export const isPmDateThisMonth = <
  D extends Date | string,
  M extends boolean | string
>(
  pmDate?: D,
  isCurrent?: M
): boolean => {
  if (
    pmDate === undefined ||
    pmDate === null ||
    isCurrent === undefined ||
    isCurrent === null
  ) {
    return false
  }
  const dateValue = localMoment(pmDate).toDate()
  const thisMoment = localMoment()
  const endOfMonth = localMoment(dateValue).endOf('month')
  const startOfMonth = localMoment(dateValue).startOf('month')

  return thisMoment >= startOfMonth && thisMoment <= endOfMonth
}

export const isDateMatch = <D extends Date | string, F extends Date>(
  filterValue?: F,
  value?: D | string,
  isUtc = false
): boolean => {
  const valueString = (isUtc ? moment(value).utc() : moment(value)).format(
    'MM/DD/YYYY'
  )
  const filterString = moment(filterValue).format('MM/DD/YYYY')
  return filterValue === undefined || (!!value && valueString === filterString)
}

export const contains = <D extends string, F extends string>(
  filterValue?: string,
  value?: string,
  comparer?: (filter: string, data?: string) => boolean
): boolean => {
  const defaultComparer = (filter: string, data?: string): boolean =>
    data ? data.toLowerCase().includes(filter.toLowerCase()) : false
  return (
    filterValue === undefined ||
    filterValue === '' ||
    (comparer ?? defaultComparer)(filterValue as string, value)
  )
}

export const defaultAutocompleteProps = () => {
  const renderTags =
    (selector: (option: any) => any) =>
    (tagValue: any, getTagProps: (props: any) => any): ReactNode =>
      tagValue.map((option: any, index: number) => (
        <Typography key={index} style={{ marginRight: 5 }}>
          {`${selector(option)}`}
          {tagValue.length === index + 1 ? '' : ','}
        </Typography>
      ))

  const props = {
    fullWidth: true,
    limitTags: 1,
    classes: makeStyles({
      option: {
        ...padding(2, 4),
      },
    })(),
    disableCloseOnSelect: true,
    renderTags: renderTags((option) => option.name),
  }

  return { props, renderTags }
}

export const defaultAutocompleteStyles = createStyles({
  root: {
    ...flexLayout({ direction: 'column' }),
  },
  filterControl: {
    marginBottom: 12,
    marginTop: 12,
  },
  commonFilter: {
    width: '100%',
    height: '19px',
    flexGrow: 0,
    fontFamily: 'Ubuntu',
    fontSize: '12px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#1c1b1b',
    display: 'inline-block',
    alignItems: 'center',
  },
})

export const buildChip = (
  key: string | number,
  label: string,
  onDelete: () => void
): JSX.Element => {
  return (
    <Chip
      color='primary'
      style={{
        marginTop: 5,
        marginRight: 5,
      }}
      key={key}
      label={label}
      deleteIcon={<Clear />}
      onDelete={onDelete}
    />
  )
}

export const buildChipByColor = (
  key: string | number,
  label: string,
  onDelete: () => void,
  color: string
): JSX.Element => {
  return (
    <Chip
      style={{
        marginTop: 5,
        marginRight: 5,
        backgroundColor: color,
        color: colors.white,
      }}
      key={key}
      label={label}
      deleteIcon={<Clear style={{ color: colors.white }} />}
      onDelete={onDelete}
    />
  )
}

export const filterDivStyle = {
  width: '326px',
}

export function determineFilterCount(
  filters: any,
  exclude: string[] = [
    nameof<AssetFilters>('columnSort'),
    nameof<AssetFilters>('filterGuid'),
    nameof<AssetFilters>('filterName'),
    nameof<UserFilters>('filterGuid'),
    nameof<UserFilters>('filterName'),
    nameof<StaffFilters>('filterGuid'),
    nameof<StaffFilters>('filterName'),
  ]
) {
  let count = 0
  if (filters) {
    Object.keys(filters)
      .filter((key) => !exclude?.includes(key))
      .forEach((key) => {
        const value = filters[key]
        if (value) {
          if (Array.isArray(value)) {
            count += (value as Array<any>).length
          } else {
            count++
          }
        }
      })
  }
  return count
}
