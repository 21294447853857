import { getType } from 'typesafe-actions'
import { LocationRouterAction } from '../actions'
import { getAllLocationRoutersAction } from '../actions/locationRouters'
import { FetchingStatus } from '../models/fetchingStatus'
import {
  LocationRouter,
  LocationRouters,
  LocationRouterState,
} from '../models/locationRouter'
import { mergeRecords, setFetchingStatus } from '../utils'

const initialState: LocationRouterState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

export default (
  state: LocationRouterState = initialState,
  action: LocationRouterAction
): LocationRouterState => {
  switch (action.type) {
    case getType(getAllLocationRoutersAction.success):
      return {
        data: mergeRecords<LocationRouters, LocationRouter, 'id'>(
          state.data,
          action.payload,
          'id'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getAllLocationRoutersAction.request):
      return setFetchingStatus<LocationRouters>(state, FetchingStatus.Request)
    case getType(getAllLocationRoutersAction.failure):
      return setFetchingStatus<LocationRouters>(state, FetchingStatus.Failure)
    default:
      return state
  }
}
