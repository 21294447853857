import { BaseService } from '@midmarkrtls/common/middleware/baseService'
import { OperationsUser, Tenant } from 'v2/models'

export class OperationsUsersApi extends BaseService {
  constructor() {
    super({ entityType: 'operationsUsers' })
  }
  route = 'operationsUsers'

  async getTenants(id: string): Promise<Tenant[]> {
    return await this.get(`${this.route}/${id}/tenants`)
  }

  async getAllOperationsUsers(): Promise<OperationsUser[]> {
    return await this.get(this.route)
  }

  async getOperationsUserById(id: string): Promise<OperationsUser> {
    return await this.get(`${this.route}/${id}`)
  }

  async getOperationsUsersByEmail(email: string): Promise<OperationsUser[]> {
    return await this.get(`${this.route}/${email}`)
  }

  async getOperationsUserByName(name: string): Promise<OperationsUser> {
    return await this.get(`${this.route}/${name}`)
  }

  async createOperationsUser(record: OperationsUser): Promise<OperationsUser> {
    return await this.post(this.route, record)
  }

  async updateOperationsUser(record: OperationsUser): Promise<OperationsUser> {
    return await this.patch(`${this.route}/${record.id}`, record)
  }

  async deleteOperationsUser(id: string): Promise<boolean> {
    return await this.deleteNoResult(`${this.route}/${id}`)
  }
}

export const operationsUsersApi = new OperationsUsersApi()
