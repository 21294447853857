import { useMemo } from 'react'
import { useMapReferenceViews } from '.'
import { Location } from '../models'

const useCurrentMap = (
  venueId?: number,
  currentFloor?: Location | null
): number | undefined => {
  const { data: mapReferenceViews } = useMapReferenceViews(venueId)

  const mapId = useMemo(() => {
    if (currentFloor) {
      const { mapId: mapReferenceMapId } =
        Object.values(mapReferenceViews).find(
          (x) => x.floorLocationId === currentFloor.id
        ) || {}

      if (mapReferenceMapId) {
        return mapReferenceMapId
      }
    }
  }, [currentFloor, mapReferenceViews])

  return mapId
}

export default useCurrentMap
