import React, {
  Dispatch,
  ProviderProps,
  createContext,
  useContext,
  useReducer,
} from 'react'
import { MapRenderByGeofence } from '../../../models/realtimeMap'

const MapRenderStateContext = createContext<MapRenderByGeofence | undefined>(
  undefined
)
const MapRenderDispatchContext = createContext<
  React.Dispatch<MapRenderByGeofence | undefined> | undefined
>(undefined)

function useMapRenderState(): MapRenderByGeofence {
  const context = useContext(MapRenderStateContext)
  if (context === undefined) {
    throw new Error('Context missing for useMapRenderState')
  }
  return context
}

function useMapRenderDispatch(): Dispatch<MapRenderByGeofence | undefined> {
  const context = useContext(MapRenderDispatchContext)
  if (context === undefined) {
    throw new Error('Context missing for useMapRenderDispatch')
  }
  return context
}

function mapRenderReducer(
  currentState: MapRenderByGeofence | undefined,
  nextState: MapRenderByGeofence | undefined
): MapRenderByGeofence | undefined {
  return nextState
}

export function MapRenderProvider({
  children,
}: Pick<ProviderProps<Record<string, never>>, 'children'>): JSX.Element {
  const initialState = {}
  const [mapRender, setMapRender] = useReducer(mapRenderReducer, initialState)
  return (
    <MapRenderStateContext.Provider value={mapRender}>
      <MapRenderDispatchContext.Provider value={setMapRender}>
        {children}
      </MapRenderDispatchContext.Provider>
    </MapRenderStateContext.Provider>
  )
}

/**
 * Context Provider to store the current map render state.
 * MapRender state is essentially telemetry grouped by geofenceId, which is used
 * to determine whether to render an icon on the map as a group or agent icon.
 * Upon receiving a new telemetry data, this state is used to calculate how to render the update.
 * Once the update is determined and the map is updated, the map render state is then updated accordingly.
 * @returns
 */
export default function useRealTimeMapRenderContext(): {
  mapRenderState: MapRenderByGeofence
  setMapRender: Dispatch<MapRenderByGeofence>
} {
  return {
    mapRenderState: useMapRenderState(),
    setMapRender: useMapRenderDispatch(),
  }
}
