import { Locations, LocationsState } from '../../models'
import { getAllLocationsAction } from '../../actions/locations'
import { EntityResult, useEntities } from '../useEntities'

const useFetchLocations = (canFetch: boolean = true): EntityResult<Locations> =>
  useEntities<LocationsState, Locations>(
    () => getAllLocationsAction.request(),
    (state: any) => state.locations, // eslint-disable-line @typescript-eslint/no-explicit-any
    () => false,
    () => canFetch
  )

export { useFetchLocations }
