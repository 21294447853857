import { getType } from 'typesafe-actions'
import { AgentEventsLocationAction } from '../actions'
import {
  getAllAgentEventsAction,
  getAgentEventAction,
  putAgentEventAction,
  postAgentEventAction,
} from '../actions/agentEventsLocation'
import {
  AgentEventsLocationState,
  AgentEventsLocation,
  AgentEventLocation,
} from '../models'
import { FetchingStatus } from '../models/fetchingStatus'
import { mergeRecords, mergeRecord, setFetchingStatus } from '../utils'

const initialState: AgentEventsLocationState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

export default (
  state: AgentEventsLocationState = initialState,
  action: AgentEventsLocationAction
): AgentEventsLocationState => {
  switch (action.type) {
    case getType(getAllAgentEventsAction.success):
      return {
        ...state,
        data: mergeRecords<AgentEventsLocation, AgentEventLocation, 'id'>(
          state.data,
          action.payload,
          'id'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(putAgentEventAction.success):
    case getType(postAgentEventAction.success):
    case getType(getAgentEventAction.success):
      return {
        ...state,
        data: mergeRecord<AgentEventsLocation, AgentEventLocation, 'id'>(
          state.data,
          action.payload,
          'id'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }
    case getType(getAllAgentEventsAction.request):
    case getType(getAgentEventAction.request):
    case getType(putAgentEventAction.request):
    case getType(postAgentEventAction.request):
      return setFetchingStatus<AgentEventsLocation>(
        state,
        FetchingStatus.Request
      )

    case getType(getAllAgentEventsAction.failure):
    case getType(getAgentEventAction.failure):
    case getType(putAgentEventAction.failure):
    case getType(postAgentEventAction.failure):
      return setFetchingStatus<AgentEventsLocation>(
        state,
        FetchingStatus.Failure
      )
    default:
      return state
  }
}
