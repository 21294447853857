import { getType } from 'typesafe-actions'
import { mergeRecords } from '../utils'
import { LogLevelTypesState, LogLevelTypes, LogLevelType } from '../models'
import { getAllLogLevelTypesAction } from '../actions/logLevelTypes'
import { LogLevelTypeAction } from '../actions'

export default (
  state: LogLevelTypes = {},
  action: LogLevelTypeAction
): LogLevelTypesState => {
  switch (action.type) {
    case getType(getAllLogLevelTypesAction.success):
      return mergeRecords<LogLevelTypes, LogLevelType, 'logLevelTypeId'>(
        state,
        action.payload,
        'logLevelTypeId'
      )
    default:
      return state
  }
}
