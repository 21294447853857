import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { pathMatches, routeEnabled } from '../helpers'
import { PageRoute } from '../interfaces/modules'
import { FetchingStatus } from '../models'
import { useFetchFeatureFlags } from './entities/useFetchFeatureFlags'
import { FeatureFlagLabels } from '../constants'

const routeExists = (pathName: string, pageRoutes: PageRoute[]): boolean => {
  const matchedRoute = pageRoutes.find((route) => pathMatches(route, pathName))

  return !!matchedRoute
}

export const useRouteRedirect = (
  pathName: string,
  errorPath: string,
  pageRoutes: PageRoute[]
) => {
  const { data: featureFlags, status: featureFlagStatus } =
    useFetchFeatureFlags(FeatureFlagLabels.Navigation)
  const history = useNavigate()

  useEffect(() => {
    const routeIsEnabled = routeEnabled(pathName, featureFlags)
    const routeFound = routeExists(pathName, pageRoutes)

    if (
      featureFlagStatus === FetchingStatus.Success &&
      !(routeIsEnabled && routeFound) &&
      pathName !== '/'
    ) {
      console.log(`Unable to verify path: Redirecting`)
      history(errorPath)
    }
  }, [errorPath, pageRoutes, pathName, featureFlags])
}
