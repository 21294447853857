import { AssetKitInstance } from '../../models'
import { MapRenderByGeofence } from '../../models/realtimeMap'
import {
  AgentTelemetriesGroupedByTrackingId,
  BadgeTelemetryMessageWithAgentEvent,
} from '../../models/telemetry'
import {
  createGroupForNewAgentEnteringMap,
  createNewAgentEnteringMap,
  updateGroupIconForNewAgentEnteringMap,
} from '.'

/**
 * For each new agent entering map telemetry, determine whether the agent is to be rendered as a Group icon
 * or an Asset/Staff icon and render accordingly.
 *    - If the agent is entering a geofence with no other agent currently rendered, render the new agent as
 *    an Asset/Staff icon
 *    - If the agent is entering a geofence that already has one other Asset/Staff icon, remove that icon and
 *    rerender both agents as a Group icon
 *    - If the agent is entering a geofence that already has a Group icon, update that Group icon to increase
 *    the group count
 * @param newAgentsTelemetries
 * @param currentMapRender
 * @param assetKit
 * @param jibestreamController
 * @param geofenceInstances
 * @param useGeofenceCentroid
 * @param iconScale
 * @param selectedTrackingId
 * @param selectedGroupId
 * @param assetKitId
 * @param addGeofenceHighlight
 * @param addAgentToSelectedGroup
 * @returns updated map render state after map rerendering and the next inpixonAssetId to use
 * for subsequent Inpixon Asset creation
 */
export function processAddingNewAgentsEnteringMap(
  newAgentsTelemetries: AgentTelemetriesGroupedByTrackingId,
  currentMapRender: MapRenderByGeofence,
  assetKit: AssetKitInstance,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  geofenceInstances: any[],
  useGeofenceCentroid: boolean,
  iconScale: number,
  selectedTrackingId: string | undefined,
  selectedGroupId: number | undefined,
  assetKitId: number,
  addGeofenceHighlight: (geofenceId: number) => void,
  addAgentToSelectedGroup: (
    agentTelemtry: BadgeTelemetryMessageWithAgentEvent
  ) => void
): {
  nextInpixonAssetId: number | undefined
  updatedMapRender: MapRenderByGeofence
} {
  let updatedMapRender = currentMapRender
  let nextInpixonAssetId = assetKitId
  Object.values(newAgentsTelemetries).forEach((telemetry) => {
    const enteringGeofenceMapRender = currentMapRender[telemetry.geoFenceId]
    if (!enteringGeofenceMapRender) {
      console.warn('Geofence not found!')
      return
    }
    const currentGroupCount = enteringGeofenceMapRender.renderedAgents.length
    if (
      currentGroupCount >= enteringGeofenceMapRender.maxAgentsAllowedInGeofence
    ) {
      updatedMapRender = updateGroupIconForNewAgentEnteringMap(
        telemetry,
        updatedMapRender,
        enteringGeofenceMapRender,
        assetKit,
        jibestreamController,
        iconScale,
        selectedGroupId,
        selectedTrackingId,
        addGeofenceHighlight,
        addAgentToSelectedGroup
      )
    } else {
      const updatedGroupCount =
        enteringGeofenceMapRender.renderedAgents.length + 1
      if (
        updatedGroupCount < enteringGeofenceMapRender.maxAgentsAllowedInGeofence
      ) {
        // Entering geofence is NOT currently grouped and remained ungrouped after agent enters the geofence
        const createNewAgentResult = createNewAgentEnteringMap(
          telemetry,
          updatedMapRender,
          assetKit,
          nextInpixonAssetId,
          useGeofenceCentroid,
          iconScale,
          geofenceInstances,
          jibestreamController,
          selectedTrackingId,
          addGeofenceHighlight
        )
        if (createNewAgentResult.nextInpixonAssetId) {
          nextInpixonAssetId = createNewAgentResult.nextInpixonAssetId
        }
        updatedMapRender = createNewAgentResult.updatedMapRender
      } else {
        // Entering geofence is NOT currently grouped but becomes grouped after agent enters the geofence
        updatedMapRender = createGroupForNewAgentEnteringMap(
          telemetry,
          updatedMapRender,
          enteringGeofenceMapRender,
          assetKit,
          useGeofenceCentroid,
          iconScale,
          geofenceInstances,
          jibestreamController,
          selectedTrackingId,
          addGeofenceHighlight
        )
      }
    }
  })
  return {
    nextInpixonAssetId,
    updatedMapRender,
  }
}
