import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useCurrentIDN } from '..'
import { postAssociateGeofencesAction } from '../../actions/locationGeofences'
import { GeofenceData } from '../../models/geofenceExport'

export function useGeofenceExport(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  geoFenceKit: any,
  setExportGeofence?: (loaded: boolean) => void,
  exportGeofences?: boolean
): void {
  const dispatch = useDispatch()
  //const [geoFencesExported, setGeoFencesExported] = useState(false)
  const idnLocation = useCurrentIDN()

  useEffect(() => {
    if (exportGeofences && setExportGeofence && geoFenceKit) {
      geoFenceKit?.getGeofences((error: any, geofences: any) => {
        if (error) throw new Error(error)
        const geofenceExportData: GeofenceData[] = []
        geofences?.getAll().forEach((y: any) => {
          y.instances.forEach((x: any) => {
            if (x.waypoints.length) {
              geofenceExportData.push({
                instanceId: x.id,
                waypoints: x.waypoints.reduce(
                  (a: any, o: any) => (a.push(o.id), a),
                  []
                ),
              })
            } else {
              console.log(`Could not find waypoint for ${x.id}`)
            }
          })
        })
        dispatch(
          postAssociateGeofencesAction.request({
            locationGuid: idnLocation?.id ?? '',
            geofenceExport: geofenceExportData,
          })
        )
        console.log(JSON.stringify(geofenceExportData))
      })
      setExportGeofence(false)
    }
  }, [
    exportGeofences,
    geoFenceKit,
    dispatch,
    idnLocation?.id,
    setExportGeofence,
  ])
}
