import { getType } from 'typesafe-actions'
import { StaffAction } from '../actions'
import { getAllStaffAction } from '../actions/staff'
import { Staff, StaffMembers, StaffNoLocationState } from '../models'
import { FetchingStatus } from '../models/fetchingStatus'
import { mergeRecords } from '../utils'

const initialState: StaffNoLocationState = {
  data: {},
  status: undefined,
  lastRefreshTime: undefined,
}

export default (
  state: StaffNoLocationState = initialState,
  action: StaffAction
): StaffNoLocationState => {
  switch (action.type) {
    case getType(getAllStaffAction.success):
      return {
        ...state,
        data: mergeRecords<StaffMembers, Staff, 'agentGuid'>(
          {},
          action.payload,
          'agentGuid'
        ),
        status: FetchingStatus.Success,
        lastRefreshTime: new Date(),
      }

    default:
      return state
  }
}
