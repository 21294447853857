import { NavigateNext } from '@mui/icons-material'
import {
  Breadcrumbs,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { setRouterPromptIsEnabledStatusAction } from '../../../../actions/routerPrompt'
import { CancelButton, PrimaryButton } from '../../../../components/Buttons'
import RouterPrompt from '../../../../components/Common/RouterPrompt'
import { requiredMessage } from '../../../../components/Forms'
import { PageContent } from '../../../../components/Layout'
import LocationSelectMenu from '../../../../components/LocationSelectMenu/LocationSelectMenu'
import FormSkeleton from '../../../../components/Skeleton/FormSkeleton'
import { nullGuid } from '../../../../helpers'
import { mapLocationsWithChildren } from '../../../../helpers/alert'
import {
  useFetchRoles,
  useFetchSecurityRouters,
  useFetchSelectableLocations,
  useFetchUnits,
  useFetchUserRouters,
} from '../../../../hooks'
import { useAlertConfigPageReload } from '../../../../hooks/useAlertConfigPageReload'
import {
  AlertConfigSteps,
  FetchingStatus,
  Role,
  Unit,
  UserRoles,
  allRoles,
  allUnits,
} from '../../../../models'
import { AlertTypes } from '../../../../models/alert'
import { AlertRouteFormFields } from '../../../../models/alertRouteFormFields'
import { AlertUserRouter } from '../../../../models/alertRouter'
import { Location } from '../../../../models/locations'
import { PageLayoutState } from '../../../../models/pageLayout'
import { RouterPromptState } from '../../../../models/routerPrompt'
import { RouteSecurityType } from '../../../../models/securityRouter'
import { RouteUserType } from '../../../../models/userRouter'
import { inputLabelStyles, useFormStyle } from '../../../../styles'
import { AlertConfigStepperHeaderHeight } from '../../constants'
import useAlertActiveConfig from '../../useAlertActiveConfig'
import AlertConfigStepperHeader from '../AlertConfigStepperHeader'
import { AlertRoleDropDown } from '../Inputs/AlertRoleDropDown'
import { AlertUnitDropDown } from '../Inputs/AlertUnitDropDown'
import AlertConfigRightDrawer from './AlertConfigRightDrawer'
import { formWidth } from './constants'

const useSelectStyles = makeStyles(() =>
  createStyles({
    icon: {
      right: '9px',
    },
    selectMenu: {
      minHeight: '19px',
    },
  })
)

const useInputLabelStyles = makeStyles(() => inputLabelStyles)

const useBreadcrumbsStyles = makeStyles(() =>
  createStyles({
    root: {
      paddingRight: '24px',
    },
  })
)

export const AlertConfigRoutingPage = () => {
  const maxHeight = useSelector(
    ({ pageLayout }: { pageLayout: PageLayoutState }) => pageLayout.maxHeight
  )
  const { activeConfig, setActiveConfig } = useAlertActiveConfig()
  const location = useLocation()
  const history = useNavigate()
  const {
    control,
    formState: { isDirty, errors },
    setError,
    handleSubmit,
    reset,
    clearErrors,
    register,
  } = useForm<AlertRouteFormFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      routedLocation: activeConfig.locationRouter?.floor?.id ?? '',
      unit: activeConfig.unitRouter,
      fixedRoleIds: activeConfig.userRouters?.map((x) => +x.ruleValue),
      roleIds: activeConfig.securityRouters?.map((x) => +x.ruleValue),
    },
  })
  const activeConfigLoaded = useAlertConfigPageReload(
    activeConfig,
    setActiveConfig
  )

  const classes = useFormStyle()
  const selectClasses = useSelectStyles()
  const inputLabelClasses = useInputLabelStyles()
  const breadcrumbsClasses = useBreadcrumbsStyles()

  const [isInitialLocationRouterLoaded, setIsInitialLocationRouterLoaded] =
    useState<boolean>(false)
  const [locationRouter, setLocationRouter] = useState<Location[]>([])
  const [openLocationSelect, setOpenLocationSelect] = useState<boolean>(false)
  const [selectedCampus, setSelectedCampus] = useState<Location | undefined>()
  const [selectedBuilding, setSelectedBuilding] = useState<
    Location | undefined
  >()
  const [selectedFloor, setSelectedFloor] = useState<Location | undefined>()
  const { data: selectableLocations } = useFetchSelectableLocations()
  const { data: roles, status: rolesStatus } = useFetchRoles()

  const idn: Location | null = selectableLocations.idn ?? null

  const { data: units, status: unitStatus } = useFetchUnits()
  const { userRouters } = useFetchUserRouters()
  const { securityRouters } = useFetchSecurityRouters()
  const dispatch = useDispatch()

  const [savedUserRouters, setSavedUserRouters] = useState<AlertUserRouter[]>(
    activeConfig.userRouters ?? []
  )

  const routerPromptState = useSelector(
    ({ routerPrompt }: { routerPrompt: RouterPromptState }) => routerPrompt
  )

  const handleNext = () => {
    const path = `${location.pathname.slice(
      0,
      location.pathname.lastIndexOf('/')
    )}`
    history(
      `${path}/${AlertConfigSteps[AlertConfigSteps.RESOLUTION].toLowerCase()}`
    )
  }

  const handlePrevious = () => {
    const path = `${location.pathname.slice(
      0,
      location.pathname.lastIndexOf('/')
    )}`
    history(
      `${path}/${AlertConfigSteps[AlertConfigSteps.CHANNELS].toLowerCase()}`
    )
  }

  const onSubmit = () => {
    reset()
    if (
      activeConfig.alertType?.id !== AlertTypes.AssetAlert &&
      selectedFloor === undefined
    ) {
      setError('routedLocation', {
        type: 'validate',
        message: 'A floor must be selected',
      })
    }
    if (selectedFloor !== undefined) {
      if (
        !activeConfig.securityRouters ||
        activeConfig.securityRouters?.length === 0
      ) {
        const securityRole = Object.values(roles).find(
          (r) => r.id === UserRoles.SecurityAdmin
        )
        setActiveConfig({
          ...activeConfig,
          securityRouters:
            securityRole && activeConfig.alertType?.id === AlertTypes.AssetAlert
              ? [
                  {
                    id: nullGuid,
                    alertId: activeConfig.id,
                    joinTypeId: 1,
                    ruleValue: `${securityRole.id}`,
                    securityRouterId:
                      Object.values(securityRouters).find(
                        (x) => x.routeSecurityTypeId === RouteSecurityType.Role
                      )?.id ?? '',
                    securityRouterTypeId: RouteSecurityType.Role,
                  },
                ]
              : [],
        })
      }
      handleNext()
    } else if (activeConfig.alertType?.id === AlertTypes.AssetAlert) {
      handleNext()
    }
  }

  const handleClose = () => {
    setOpenLocationSelect(false)
  }

  const handleOpen = () => {
    setOpenLocationSelect(true)
  }

  useEffect(() => {
    if (!routerPromptState.isEnabled && isDirty) {
      dispatch(setRouterPromptIsEnabledStatusAction(isDirty))
    }
  }, [isDirty])

  useEffect(() => {
    if (activeConfigLoaded && !isInitialLocationRouterLoaded) {
      if (!idn || !activeConfig.locationRouter) {
        return
      }

      const { selectedCampusWithChildren, selectedBuildingWithChildren } =
        mapLocationsWithChildren(idn, activeConfig.locationRouter)

      if (selectedCampusWithChildren !== undefined) {
        setSelectedCampus(selectedCampusWithChildren)
      }

      if (selectedBuildingWithChildren !== undefined) {
        setSelectedBuilding(selectedBuildingWithChildren)
      }

      setSelectedFloor(activeConfig.locationRouter?.floor)

      if (activeConfig.userRouters) {
        setSavedUserRouters(activeConfig.userRouters)
      }

      setIsInitialLocationRouterLoaded(true)
    }
  }, [activeConfigLoaded, activeConfig.locationRouter, idn])

  useEffect(() => {
    if (activeConfigLoaded && isInitialLocationRouterLoaded) {
      const location = [selectedCampus, selectedBuilding, selectedFloor].filter(
        (x) => x !== undefined
      ) as Location[]
      setLocationRouter(location)

      if (selectedFloor) {
        clearErrors('routedLocation')
      }

      if (
        selectedCampus &&
        selectedBuilding &&
        selectedFloor &&
        activeConfig.locationRouter.campus &&
        activeConfig.locationRouter.building &&
        activeConfig.locationRouter.floor &&
        selectedCampus.id === activeConfig.locationRouter.campus.id &&
        selectedBuilding.id === activeConfig.locationRouter.building.id &&
        selectedFloor.id === activeConfig.locationRouter.floor.id
      ) {
        return
      }

      setActiveConfig({
        ...activeConfig,
        locationRouter: {
          campus: selectedCampus,
          building: selectedBuilding,
          floor: selectedFloor,
        },
      })
    }
  }, [
    selectedCampus,
    selectedBuilding,
    selectedFloor,
    activeConfigLoaded,
    isInitialLocationRouterLoaded,
  ])

  useEffect(() => {
    register('routedLocation')
  }, [register])

  return (
    <PageContent
      maxHeight={maxHeight}
      openRightDrawer={true}
      style={{ maxHeight: '100%' }}
      hideScrollbar={true}
      content={
        <>
          <AlertConfigStepperHeader activeStep={AlertConfigSteps.ROUTING} />
          <div
            style={{
              overflow: 'auto',
              height: '100%',
              maxHeight: `calc(100% - ${AlertConfigStepperHeaderHeight})`,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                position: 'absolute',
                width: '100%',
                marginTop: '12px',
                paddingRight: '12px',
              }}
            >
              <CancelButton onClick={handlePrevious}>Previous</CancelButton>
              <PrimaryButton onClick={onSubmit}>Next</PrimaryButton>
            </div>
            <form>
              <div
                style={{
                  margin: '22px 0px 10px 24px',
                }}
              >
                <Typography variant='h5'>Alert Builder: Routing</Typography>
              </div>
              {activeConfig.alertType?.id === AlertTypes.AssetAlert && (
                <div
                  style={{
                    margin: '22px 0px 10px 24px',
                  }}
                >
                  <Typography>
                    Asset alert notifications are not routed to users in
                    CareFlow™ Cloud Platform. Click Next to continue.
                  </Typography>
                  <br />
                  <Typography style={{ fontStyle: 'italic' }}>
                    Subscribe to alert notifications in the Midmark CareFlow™
                    Desktop Notification Center app
                  </Typography>
                  <Typography style={{ fontStyle: 'italic' }}>
                    (available for download from the Microsoft Store)
                  </Typography>
                </div>
              )}
              {activeConfig.alertType?.id !== AlertTypes.AssetAlert && (
                <div
                  style={{
                    minWidth: formWidth,
                    margin: '0 24px',
                  }}
                >
                  <Typography
                    variant='h6'
                    style={{
                      fontWeight: 'bold',
                      fontSize: '20px',
                    }}
                  >
                    Route to People in a Fixed Location
                  </Typography>

                  <div>
                    {activeConfigLoaded ? (
                      <FormControl
                        variant='standard'
                        style={{
                          minWidth: formWidth,
                          marginTop: '10px',
                        }}
                        required
                      >
                        <InputLabel
                          classes={inputLabelClasses}
                          id='location-router-label'
                        >
                          Routed Location
                        </InputLabel>
                        <Select
                          variant='standard'
                          labelId='location-router-label'
                          id='location-router'
                          multiple
                          autoWidth
                          required
                          name='locationRouter'
                          value={locationRouter}
                          input={<OutlinedInput required />}
                          classes={selectClasses}
                          open={openLocationSelect}
                          onOpen={handleOpen}
                          onClose={handleClose}
                          error={!!errors.routedLocation}
                          renderValue={(selected: any) => {
                            return (
                              <Breadcrumbs
                                separator={<NavigateNext fontSize='small' />}
                                classes={breadcrumbsClasses}
                              >
                                {locationRouter.map((x) => (
                                  <div key={x.id}>{x.name}</div>
                                ))}
                              </Breadcrumbs>
                            )
                          }}
                        >
                          <LocationSelectMenu
                            isIDNRestrictedHierarchy={false}
                            open={openLocationSelect}
                            selectedCampus={selectedCampus}
                            selectedBuilding={selectedBuilding}
                            selectedFloor={selectedFloor}
                            campuses={idn?.children}
                            onCampusChange={setSelectedCampus}
                            onBuildingChange={setSelectedBuilding}
                            onFloorChange={setSelectedFloor}
                            handleClose={handleClose}
                            isDeselectable={true}
                          />
                        </Select>
                        {errors.routedLocation ? (
                          <FormHelperText
                            error
                            variant='outlined'
                          >{`*${errors.routedLocation.message}`}</FormHelperText>
                        ) : (
                          <FormHelperText variant='outlined'>
                            {requiredMessage}
                          </FormHelperText>
                        )}
                      </FormControl>
                    ) : (
                      <FormSkeleton width={formWidth} />
                    )}
                  </div>

                  <div
                    style={{
                      width: formWidth,
                    }}
                  >
                    {activeConfigLoaded ? (
                      <AlertUnitDropDown
                        value={
                          Object.values(units).find((x) => {
                            return x.id === activeConfig?.unitRouter?.id
                          }) ?? undefined
                        }
                        loading={unitStatus === FetchingStatus.Request}
                        error={!!errors.unit}
                        className={classes.inputStyle}
                        data={[allUnits, ...Object.values(units)] ?? []}
                        fieldName='Unit Qualifier'
                        unselectedValue={'-1'}
                        helperText={requiredMessage}
                        handleChange={(event: any, value: Unit | null) => {
                          setActiveConfig({
                            ...activeConfig,
                            unitRouter: value ?? allUnits,
                          })
                          return value
                        }}
                      />
                    ) : (
                      <FormSkeleton width={formWidth} />
                    )}
                  </div>

                  <div
                    style={{
                      width: formWidth,
                    }}
                  >
                    {activeConfigLoaded ? (
                      <AlertRoleDropDown
                        value={
                          Object.values(roles).filter((x) =>
                            activeConfig?.userRouters
                              ?.map((r) => +r.ruleValue)
                              .includes(x.id)
                          ) ?? []
                        }
                        loading={rolesStatus === FetchingStatus.Request}
                        error={false}
                        className={classes.inputStyle}
                        data={[allRoles, ...Object.values(roles)] ?? []}
                        fieldName='RTLS Role'
                        unselectedValue={-1}
                        helperText={requiredMessage}
                        onChange={(event, newValue: Role[]) => {
                          let newData = newValue
                            .map((x: Role) => x.id)
                            .filter((x) => x !== -1)

                          if (
                            newValue.map((x: Role) => x.id).includes(-1) &&
                            (activeConfig?.userRouters?.length ?? 0) > 0
                          ) {
                            newData = []
                          }

                          let updatedUserRouters = activeConfig.userRouters
                            ? [...activeConfig.userRouters]
                            : []
                          const deselectedRoleId = updatedUserRouters
                            .filter((x) => !newData.includes(+x.ruleValue))
                            .map((x) => x.ruleValue)
                          if (deselectedRoleId?.length > 0) {
                            updatedUserRouters = updatedUserRouters.filter(
                              (x) => !deselectedRoleId.includes(x.ruleValue)
                            )
                          }

                          const selectedRoleId = newData
                            .filter(
                              (x) =>
                                !updatedUserRouters
                                  .map((r) => +r.ruleValue)
                                  .includes(x)
                            )
                            .shift()
                          const userRouterType = Object.values(
                            userRouters
                          ).find(
                            (x) => x.routeUserTypeId === RouteUserType.Role
                          )
                          if (selectedRoleId && userRouterType) {
                            const id =
                              savedUserRouters.find(
                                (x) => +x.ruleValue === selectedRoleId
                              )?.id ?? nullGuid
                            updatedUserRouters.push({
                              id: id,
                              alertId: activeConfig.id,
                              joinTypeId: 1,
                              ruleValue: `${selectedRoleId}`,
                              userRouterId: userRouterType?.id,
                              userRouterTypeId: RouteUserType.Role,
                            })
                          }
                          setActiveConfig({
                            ...activeConfig,
                            userRouters: updatedUserRouters,
                          })
                        }}
                      />
                    ) : (
                      <FormSkeleton width={formWidth} />
                    )}
                  </div>
                </div>
              )}
              {activeConfig.alertType?.id === AlertTypes.StaffDuress && (
                <div
                  style={{
                    width: formWidth,
                    margin: '0 24px',
                  }}
                >
                  <Typography
                    variant='h6'
                    style={{
                      fontWeight: 'bold',
                      fontSize: '20px',
                    }}
                  >
                    Route to People Regardless of Location
                  </Typography>

                  <div
                    style={{
                      width: formWidth,
                    }}
                  >
                    {activeConfigLoaded ? (
                      <AlertRoleDropDown
                        value={
                          activeConfig.alertType?.id === AlertTypes.StaffDuress
                            ? Object.values(roles).filter(
                                (x: Role) =>
                                  x.id === UserRoles.SecurityAdmin ||
                                  x.id === UserRoles.SecurityUser
                              )
                            : []
                        }
                        error={false}
                        loading={rolesStatus === FetchingStatus.Request}
                        className={classes.inputStyle}
                        data={Object.values(roles) ?? []}
                        fieldName='RTLS Role'
                        disabled={true}
                        helperText={requiredMessage}
                        onChange={(event, newValue: Role[]) => {}}
                      />
                    ) : (
                      <FormSkeleton width={formWidth} />
                    )}
                  </div>
                </div>
              )}
            </form>
          </div>
          <RouterPrompt />
        </>
      }
      rightDrawer={
        <AlertConfigRightDrawer
          activeConfig={activeConfig}
          activeConfigLoaded={activeConfigLoaded}
          canEdit={false}
        />
      }
    />
  )
}

export default AlertConfigRoutingPage
