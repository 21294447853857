import { AssetKitInstance, IconType } from '../../models'
import { InpixonAssetGroupUpdate } from '../../models/inpixonMap'
import { MapRenderByGeofence, MapRender } from '../../models/realtimeMap'
import { BadgeTelemetryMessageWithAgentEvent } from '../../models/telemetry'
import { updateInpixonGroupIconByType } from '../inpixon'
import { buildGroupIconUrl } from '../inpixonIcons'
import { calculateMapRenderToUpdateForAgentsEnteringMap } from '../realtimeMap'
import { groupHasActiveDuress, groupHasActiveAssist } from '../telemetry'

/**
 * Once it's determined that the new agent is entering a geofence that already has a Group icon rendered,
 *    - Update the existing group icon to increase the group count
 *    - If the current group icon is selected on the map, add the new agent to the Detail Drawer agent list
 *    - If the updated group has an active duress, add geofence highlighting
 * @param agentTelemetry
 * @param currentMapRender
 * @param enteringGeofenceMapRender
 * @param assetKit
 * @param jibestreamController
 * @param iconScale
 * @param selectedGroupId
 * @param selectedTrackingId
 * @param addGeofenceHighlight
 * @param addAgentToSelectedGroup
 * @returns updated map render state after map rerendering
 */
export function updateGroupIconForNewAgentEnteringMap(
  agentTelemetry: BadgeTelemetryMessageWithAgentEvent,
  currentMapRender: MapRenderByGeofence,
  enteringGeofenceMapRender: MapRender,
  assetKit: AssetKitInstance,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  jibestreamController: any,
  iconScale: number,
  selectedGroupId: number | undefined,
  selectedTrackingId: string | undefined,
  addGeofenceHighlight: (geofenceId: number) => void,
  addAgentToSelectedGroup: (
    agentTelemtry: BadgeTelemetryMessageWithAgentEvent
  ) => void
): MapRenderByGeofence {
  const isAnyExistingAgentSelected =
    enteringGeofenceMapRender.renderedAgents.some(
      (agent) => agent.trackingId === selectedTrackingId
    )
  const updatedAgentsInEnteringGeofence = [
    ...enteringGeofenceMapRender.renderedAgents,
    agentTelemetry,
  ]
  const updatedGroupHasActiveDuress = groupHasActiveDuress(
    updatedAgentsInEnteringGeofence
  )
  const updatedGroupHasActiveAssist = groupHasActiveAssist(
    updatedAgentsInEnteringGeofence
  )
  const groupToUpdateIcon: InpixonAssetGroupUpdate = {
    geoFenceId: agentTelemetry.geoFenceId,
    hasActiveDuress: updatedGroupHasActiveDuress,
    hasActiveAssist: updatedGroupHasActiveAssist,
    isSelected:
      agentTelemetry.geoFenceId === selectedGroupId ||
      isAnyExistingAgentSelected,
    groupCount: updatedAgentsInEnteringGeofence.length,
  }
  const url = buildGroupIconUrl(groupToUpdateIcon.groupCount, iconScale, {
    hasActiveDuress: groupToUpdateIcon.hasActiveDuress,
    hasActiveAssist: groupToUpdateIcon.hasActiveAssist,
    isSelected: groupToUpdateIcon.isSelected,
  })
  updateInpixonGroupIconByType(
    assetKit,
    jibestreamController,
    {
      iconType: IconType.Group,
      geofenceId: groupToUpdateIcon.geoFenceId,
    },
    url
  )
  if (updatedGroupHasActiveDuress) {
    addGeofenceHighlight(agentTelemetry.geoFenceId)
  }
  if (selectedGroupId === agentTelemetry.geoFenceId) {
    addAgentToSelectedGroup(agentTelemetry)
  }
  const updatedMapRender = calculateMapRenderToUpdateForAgentsEnteringMap(
    currentMapRender,
    agentTelemetry
  )
  return updatedMapRender
}
