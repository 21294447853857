import { createAsyncAction } from 'typesafe-actions'
import { AltitudeMap } from '../models/altitudeMap'
import { HttpErrorResponse } from '../services/http'

export const getAllAltitudeMapsAction = createAsyncAction(
  'GET_ALL_ALTITUDE_MAPS_REQUEST',
  'GET_ALL_ALTITUDE_MAPS_SUCCESS',
  'GET_ALL_ALTITUDE_MAPS_FAILURE'
)<void, AltitudeMap[], HttpErrorResponse>()

export const createAltitudeMapAction = createAsyncAction(
  'CREATE_ALTITUDE_MAP_REQUEST',
  'CREATE_ALTITUDE_MAP_SUCCESS',
  'CREATE_ALTITUDE_MAP_FAILURE'
)<AltitudeMap, AltitudeMap, HttpErrorResponse>()

export const updateAltitudeMapAction = createAsyncAction(
  'UPDATE_ALTITUDE_MAP_REQUEST',
  'UPDATE_ALTITUDE_MAP_SUCCESS',
  'UPDATE_ALTITUDE_MAP_FAILURE'
)<AltitudeMap, AltitudeMap, HttpErrorResponse>()

export const deleteAltitudeMapAction = createAsyncAction(
  'DELETE_ALTITUDE_MAP_REQUEST',
  'DELETE_ALTITUDE_MAP_SUCCESS',
  'DELETE_ALTITUDE_MAP_FAILURE'
)<number, AltitudeMap, HttpErrorResponse>()
